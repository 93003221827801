/* eslint-disable no-console */
/* eslint-disable react/no-deprecated */
/* eslint-disable prettier/prettier */
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Axios from 'axios'
import store from './lib/store'
// core components
import Admin from "layouts/Admin.jsx";
import RTL from "layouts/RTL.jsx";
import Login from "./layouts/login";
//Assets
import "./assets/css/material-dashboard-react.css?v=1.6.0";
import swal from 'sweetalert'
import servidor from './lib/server'
import Loader from './components/Loader'
import moment from 'moment'

class AppRoutes extends React.Component {
    constructor() {
        super();
        this.state = {
            login: false,
            usuario: [],
            autoguardado: false,
            datosAutoguardado: [],
            loader: true
        }
        store.subscribe(() => {
            this.setState({
                login: store.getState().token,
                usuario: store.getState().usuario,
                autoguardado: store.getState().autoguardado,
                datosAutoguardado: store.getState().datosAutoguardado
            })
            if (this.state.autoguardado) {
                let n = ""
                switch (this.state.datosAutoguardado.tipo) {
                    case 1:
                        n = "Creacion de producto";
                        break;

                    default:
                        break;
                }
                swal({
                    title: "Al parecer no has podido terminar un proceso ",
                    text: "Nombre del proceso:" + n,
                    icon: "warning",
                    buttons: {
                        Descartar: {
                            text: "Descartar",
                            value: "descartar",
                        },
                        Seguir: {
                            text: "Continuar con el proceso",
                            value: "continuar"
                        }
                    },
                })
                    .then((value) => {
                        switch (value) {

                            case "continuar":
                                swal("Pikachu fainted! You gained 500 XP!");
                                break;

                            default:

                        }
                    });
            }
        })

    }

    componentWillMount() {
        var dominio = window.location.hostname;
        var n = servidor.hostPruebasinssl + servidor.puertoAutenticacion;
        Axios.post(n + '/dominio', { "dominio": dominio })
            .then(response => {
                //console.log(response)
                if (response.status == 200) {
                    store.dispatch({
                        type: "OBTENCION_DOMINIO",
                        dominio: response.data.id,
                        detalleDominio:response.data.data.razon_social,
                        tipoDominio:response.data.data.dominio_tipo,
                    })
                    store.dispatch({
                        type: "ACTUALIZACION_DATOS",
                        actualizarvista: true
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            })


        //console.log(localStorage.getItem('token'))
        if (localStorage.getItem('token')) {
            //console.log(localStorage.getItem('token'))
            var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
            Axios.get(n, { headers: { Authorization: ntoken } })
                .then(response => {
                    if (response.status === 205) {
                        store.dispatch({
                            type: "VALIDAR_SESION",
                            token: true,
                            tiempo:''
                        })
                        //console.log(store.getState())
                        this.setState({ loader: false })
                    } else if (response.status === 206) {
                        store.dispatch({
                            type: "VALIDAR_SESION",
                            token: true,
                            tiempo:''
                        })
                        this.setState({ loader: false })
                    } else if (response.status === 200) {
                        store.dispatch({
                            type: "VALIDAR_SESION",
                            token: false,
                            tiempo:moment(response.data.data.fechavencimiento).format('hh:mm:ss a')
                        })
                        this.setState({ loader: false }) 
                    }
                })
        } else {
            store.dispatch({
                type: "VALIDAR_SESION",
                token: true
            })
            this.setState({ loader: false })
        }
    }

    render() {
        return (
            <Switch>
                {this.state.loader
                    ?
                    <Loader />
                    :
                    <Route path="/" component={this.state.login ? Login : Admin} />
                }
                <Route path="/rtl" component={this.state.login ? Login : RTL} />
                <Redirect from="/" to="/" />
                <Redirect to="/admin/dashboard" />
            </Switch >
        )
    }

}

export default AppRoutes;