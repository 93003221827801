import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Button from '@material-ui/core/Button';
import FormularioContraseña from './FormularioContraseña';
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import DialogColor from './dialogs/dialogColor'
import Slide from "@material-ui/core/Slide";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChangeImage from '../../assets/img/Formularios/change_foto.png'
import "./producto.css"
import getCroppedImg from './getCroppedImg'
import Slider from '@material-ui/lab/Slider'
import Cropper from 'react-easy-crop'
import img from "../../assets/img/faces/marc.jpg"
import Avatar from '@material-ui/core/Avatar';
import Selects from 'react-select';
import Axios from 'axios'
import DialogTelefono from './dialogs/dialogTelefono'
import DialogCorreo from './dialogs/dialogCorreo'
import TelefonoIcon from '@material-ui/icons/Phone'
import LocationIcon from '@material-ui/icons/LocationOn'
import MailIcon from '@material-ui/icons/Mail'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import store from '../../lib/store'
import Server from '../../lib/server'
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import swal from 'sweetalert'
import PerfilIcon from '@material-ui/icons/AssignmentInd'
import DeleteIcon from '@material-ui/icons/Delete';
import FormularioGeografia from './FormularioGeografia';
import { getWeekYearWithOptions } from 'date-fns/esm/fp';
import moment from 'moment'
import "moment/locale/es";
import { isUndefined } from 'util';
import DateFnsUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
const tiposDeSAngre = [
    { label: 'O+' },
    { label: 'O-' },
    { label: 'A-' },
    { label: 'A+' },
    { label: 'B-' },
    { label: 'B+' },
    { label: 'AB-' },
    { label: 'AB+' }
].map(suggestion => ({
    value: suggestion.label,
    label: suggestion.label,
}));
var perfiles = []
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))

class FormularioPersona extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Traidas desde bd
            perfilesbd: [],
            identificacionesbd: [],
            provinciasbd: [],
            ciudadesbd: [],
            empresasbd: [],
            sucursalesbd: [],
            tiendabd: [],
            //controles vistas
            vistaEdicion: null,
            //Datos seleccionados
            modovista: false,
            //
            //Datos a guardar
            nombreGuardar: '',
            fechaInicial:new Date(),
            fechaFinal:new Date(),
            //Vectores
            bonosAgregados:[],
            response: false,
        };
        store.subscribe(() => {
            this.setState({
                telefonos: store.getState().telefonos,
                dialogTelefono: store.getState().creacionTelefono,
                corrreos: store.getState().correos,
                dialogCorreo: store.getState().creacionCorreo,
                modovista: store.getState().modovista,
                crearGeografia: store.getState().creacionGeografia,
                actualizarDatos: store.getState().actualizarvista,
                ventanaContra: store.getState().ventanaContrseña,
                background1: store.getState().color,
                dialogColor1: store.getState().ventanaColor,
            })
        })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
        
    };



    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizarDatos) {
            this.obtenerDatos()
            return true
        }
        return true;
    }


    obtenerDatos = () => {
        //this.setState({ actualizarDatos: false })
        store.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: false
        })
        var datos = store.getState().usuario
        var hijos = store.getState().empresasHijos
        var padres = store.getState().empresasPadres
        const { userEdit } = this.props
        switch (datos.tipo) {
            case "2":
            case "3":
            case "4":
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/3,4,5/empresas/' + hijos + "," + datos.empresas_id, { headers: { Authorization: ntoken } })
                    .then(responseee => {
                        var empresas = []

                        if (responseee.status == 200 && responseee.data != null) {
                            responseee.data.empresas.map((dato) => (
                                empresas.push({
                                    value: dato.id,
                                    label: dato.razon_social + " -- " + dato.nit
                                })
                            ))
                        } else if (responseee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 5000);
                        }
                        this.setState({ empresasbd: empresas })

                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/perfil/' + datos.empresas_id, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        this.setState({ perfilesbd: responsee.data.rows })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break;
            case "5":
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/5/empresas/' + datos.empresas_id + "," + padres, { headers: { Authorization: ntoken } })
                    .then(responseee => {
                        var empresas = []
                        if (responseee.status == 200 && responseee.data != null) {
                            responseee.data.empresas.map((dato) => (
                                empresas.push({
                                    value: dato.id,
                                    label: dato.razon_social + " -- " + dato.nit
                                })
                            ))
                        } else if (responseee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 5000);
                        }
                        this.setState({ empresasbd: empresas })

                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/perfil/' + padres + "," + datos.empresas_id, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee)
                        console.log(userEdit)
                        var perfbd = []
                        if (responsee.status == 200 && responsee.data.rows != null) {
                            responsee.data.rows.map((perfil) => {
                                if (perfil.tipo == "3") {
                                    perfbd.push(perfil)
                                }
                            })
                        }
                        this.setState({ perfilesbd: perfbd })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break;
        }
        console.log(hijos)
        Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/provincias/pais/' + datos.empresas.paises_id, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var provincias = []
                responsee.data.map((provincia) => (
                    provincias.push({
                        value: provincia.provincia_id,
                        label: provincia.provincia
                    })
                ))
                this.setState({ provinciasbd: provincias })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })

        Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/tipos/identificaciones/', { headers: { Authorization: ntoken } })
            .then(responseee => {
                var tid = []
                responseee.data.map((dato) => (
                    tid.push({
                        value: dato.id,
                        label: dato.descripcion
                    })
                ))
                this.setState({ tipo_identificacion: tid[0] })
                this.setState({ identificacionesbd: tid })

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        this.update();
    }

    componentWillMount() {
        this.obtenerDatos()
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
      
    }

    update() {
        const { userEdit } = this.props
        //console.log(userEdit)
        if (userEdit != null) {
            const { contacto, perfil, persona, usuario } = userEdit
            //var canvas = new canvas();
            this.setState({
                nombreGuardar: persona.nombres,
                apellidoGuardar: persona.apellidos,
                identificacionGuardar: persona.identificacion,
                croppedImage: "nn",
                ciudadSeleccionada: {
                    value: persona.ciudad.id,
                    label: persona.ciudad.descripcion,
                },
                departamentoSeleccionado: {
                    value: persona.departamento.id,
                    label: persona.departamento.descripcion,
                },
                tiposangreSeleccionada: {
                    value: persona.sangre,
                    label: persona.sangre,
                },
                tipo_identificacion_Seleccionada: {
                    value: persona.tipo_identificacion.id,
                    label: persona.tipo_identificacion.descripcion,
                },
                empresaSeleccionada: {
                    value: persona.empresa.id,
                    label: persona.empresa.razon_social,
                },
                usuarion: usuario.usuario,
                idUsuario: usuario.id,
                pinGuardar: usuario.pin
            });
            var tel = []
            var mails = []
            var did = ''
            var address = ""
            var idsp = []
            if (perfil != null) {
                var perf = []
                var perfID = []
                perfil.map((perfiless) => {
                    console.log(perfiless)
                    perf.push({
                        relacion_id: perfiless.id,
                        id: perfiless.perfil_id,
                        descripcion: perfiless.descripcion,
                        color: perfiless.color,
                        estado: "A"
                    })
                    idsp.push(perfiless.perfil_id)
                })
                this.setState({ listaPerfiles: perf, prefilesGuardar: perf, idsPerfilesDeUsuario: idsp })
            }
            if (contacto != null) {
                contacto.map((contact, key) => {
                    switch (contact.tipo) {
                        case 1:
                            mails.push({
                                id: contact.id,
                                etiqueta: contact.etiqueta,
                                correo: contact.contacto,
                                principal: contact.principal,
                                estado: "A"
                            })
                            break;
                        case 2:
                            tel.push({
                                id: contact.id,
                                etiqueta: contact.etiqueta,
                                telefono: contact.contacto,
                                principal: contact.principal,
                                estado: "A"
                            })
                            break;
                        case 3:
                            address = contact.contacto
                            did = contact.id
                            break;
                    }
                })
                store.dispatch({
                    type: "AGREGAR_TELEFONO_USUARIO",
                    telefonos: tel,
                    creacionTelefono: false
                })
                store.dispatch({
                    type: "AGREGAR_CORREO_USUARIO",
                    correos: mails,
                    creacionCorreo: false
                })
                this.setState({
                    DireccionGuardar: address,
                    telefonos: tel,
                    corrreos: mails,
                    idDireccion: did
                })
            }
        }
    }



    handleChangemoment = date => {
        this.setState({ fechaInicial: date, cambbioInicio: true });
    }
    handleChangemomentFinal = date => {
        this.setState({ fechaFinal: date });
    }

    actualizarUsuario = (id) => {
        //console.log(id)
        var usuario = store.getState().usuario
        var dominio = store.getState().dominio
        var validar = this.validarFormularioeDITAR()
        var datosActualizar = {
            "persona": {
                "tipos_identificaciones_id": this.state.tipo_identificacion_Seleccionada.value,
                "identificacion": this.state.identificacionGuardar,
                "nombres": this.state.nombreGuardar,
                "apellidos": this.state.apellidoGuardar,
                correo:
                    (typeof this.state.corrreos[0] != "undefined"
                        ? this.state.corrreos[0].correo : ''),
                "estado": "A",
                "empresas_id": this.state.empresaSeleccionada.value,
                "sangre": this.state.tiposangreSeleccionada.value,
                "ciudades_id": this.state.ciudadSeleccionada.value,
                "url_foto": this.state.cambioImagen ? this.state.croppedImage != null ? this.state.imagePreviewUrl : 'NC' : 'NC',
                "update_user": usuario.p_id
            }, "empresa": {
                "dominio_id": dominio
            }, "telefono":
                this.state.telefonos.map((telefono, key) => {
                    return (
                        {
                            "id": telefono.id,
                            "etiqueta": telefono.etiqueta,
                            "contacto": telefono.telefono,
                            "tipo": 2,
                            "principal": key == 0 ? "S" : "N",
                            "estado": telefono.estado
                        }
                    )
                })
            , "correo":
                this.state.corrreos.map((correo, key) => {
                    return (
                        {
                            "id": correo.id,
                            "etiqueta": correo.etiqueta,
                            "contacto": correo.correo,
                            "tipo": 1,
                            "principal": key == 0 ? "S" : "N",
                            "estado": correo.estado
                        }
                    )
                })
            , "direccion": [{
                "id": this.state.idDireccion == null || this.state.idDireccion == 'NT' ? 0 : this.state.idDireccion,
                "etiqueta": "Principal",
                "contacto": this.state.DireccionGuardar,
                "tipo": 3,
                "principal": "S",
                "estado": "A"
            }],
            "perfiles":
                this.state.prefilesGuardar.map((perfil) => {
                    return (
                        {
                            "perfil_id": perfil.id,
                            "id": perfil.relacion_id,
                            "estado": perfil.estado
                        }
                    )
                }),
            "usuario": {
                "id": this.state.idUsuario,
                "usuario": this.state.usuarion,
                "pin": this.state.pinGuardar
            }
        }
        if (validar) {
            Axios.put(Server.hostPruebasinssl + + Server.puertoEmpleado + '/api/persona/' + id, datosActualizar, { headers: { Authorization: ntoken } })
                .then((res) => {
                    this.setState({
                        response: false
                    })
                    if (res.status == 201) {
                        store.dispatch({
                            type: "AGREGAR_TELEFONO_USUARIO",
                            telefonos: [],
                            creacionTelefono: false
                        })
                        store.dispatch({
                            type: "AGREGAR_CORREO_USUARIO",
                            correos: [],
                            creacionCorreo: false
                        })
                        swal({
                            title: 'Usuario actualizado correctamente',
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "CREACION_USUARIO",
                                        creacionUsuario: false,
                                        usuarioEdicion: null,
                                        modovista: false
                                    })
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })

                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al actualizar", "Por favor verifique los datos", "warning");
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    validarFormulario = () => {
        if (this.state.nombreGuardar.trim == "" || this.state.nombreGuardar.length == 0) {
            swal("Fallo al actualizar", "EL NOMBRE ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (moment(new Date(this.state.fechaFinal.toString())).format("YYYY-MM-DD HH:mm:ss") <= moment(new Date(this.state.fechaInicial.toString())).format("YYYY-MM-DD HH:mm:ss")) {
            swal("Fallo al actualizar", "LA FECHA INICIAL DEBE SER MAYOR A LA FECHA FINAL", "warning")
            return false
        }  else {
            this.setState({
                response: true
            })
            return true
        }

    }

    validarFormularioeDITAR = () => {
        if (this.state.nombreGuardar.trim == "" || this.state.nombreGuardar.length == 0) {
            swal("Fallo al actualizar", "EL NOMBRE ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.apellidoGuardar.trim == "" || this.state.apellidoGuardar.length == 0) {
            swal("Fallo al actualizar", "EL APELLIDO ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (JSON.stringify(this.state.tiposangreSeleccionada) == '{}') {
            swal("Fallo al actualizar", "ES NECESARIO EL TIPO DE SANGRE", "warning")
            return false
        } else if (JSON.stringify(this.state.tipo_identificacion_Seleccionada) == '{}') {
            swal("Fallo al actualizar", "DEBE ESCOGER EL TIPO DE IDENTIFICACION", "warning")
            return false
        } else if (this.state.identificacionGuardar.trim == "" || this.state.identificacionGuardar.length == 0) {
            swal("Fallo al actualizar", "EL NUMERO DE IDENTIFICACION ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (JSON.stringify(this.state.departamentoSeleccionado) == '{}') {
            swal("Fallo al actualizar", "POR FAVOR ESCOJA EL DEPARTAMENTO", "warning")
            return false
        } else if (JSON.stringify(this.state.ciudadSeleccionada) == '{}') {
            swal("Fallo al actualizar", "POR FAVOR ESCOJA LA CIUDAD", "warning")
            return false
        } else if (JSON.stringify(this.state.empresaSeleccionada) == '{}') {
            swal("Fallo al actualizar", "POR FAVOR ESCOJA LA EMPRESA", "warning")
            return false
        } else {
            this.setState({
                response: true
            })
            return true
        }

    }

    consultaEstado = () => {
        var dominio = store.getState().dominio
        var usuario = store.getState().usuario
        var validado = this.validarFormulario()
        var datos ={
          "descripcion": this.state.nombreGuardar,
          "empresas_id": usuario.empresas_id,
          "fecha_inicio": moment(new Date(this.state.fechaInicial)).format("YYYY-MM-DD ")+"00:00:00",
          "fecha_fin": moment(new Date(this.state.fechaFinal)).format("YYYY-MM-DD ")+"23:59:00",
          "estado": "A",
          "create_user": usuario.p_id
        }
        console.log(datos)
        if (validado) {
            Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/campanas', datos, { headers: { Authorization: ntoken } })
                .then((res) => {
                    this.setState({
                        response: false
                    })
                    console.log(res)
                    if (res.status == 200) {
                        swal({
                            title: res.data.message,
                            text: res.data.message2,
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "CREACION_USUARIO",
                                        creacionUsuario: false,
                                        usuarioEdicion: null,
                                        modovista: false
                                    })
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })

                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }

    cerrarVentanaCreacion() {
        store.dispatch({
            type: "CREACION_USUARIO",
            creacionUsuario: false,
            usuarioEdicion: null,
            modovista: false
        })
        store.dispatch({
            type: "AGREGAR_TELEFONO_USUARIO",
            telefonos: [],
            creacionTelefono: false
        })
        store.dispatch({
            type: "AGREGAR_CORREO_USUARIO",
            correos: [],
            creacionCorreo: false
        })
    }

    handleChangeCheck = name => key => event => {
        var estado = (event.target.value == 'false' ? false : true)
        this.setState({
            [name]: [key] = !estado
        });

    }

   

    render() {
        const { userEdit } = this.props
        //console.log(userEdit)
        var dominio = store.getState().dominio
        var def = Server.hostMultimedia + dominio + "/persona/default.png"
        var contactos = [];
        var persona = []
        var perfiles = []

        var telefonos = ""
        var imagen = ""
        var correos = ""


        this.state.telefonos.map((contacto) => {
            if (contacto.estado != 'I') {
                telefonos += contacto.telefono + ", "
            }
        })
        this.state.corrreos.map((contacto2) => {
            if (contacto2.estado != 'I') {
                correos += contacto2.correo + ", "
            }
        })
        if (userEdit != null) {
            persona = userEdit.persona
            imagen = Server.hostMultimedia + dominio + "/persona/" + (persona.url_foto == null || (persona.url_foto == 'N') ? 'default.png' : persona.id + '.png')
            perfiles = userEdit.perfil
            contactos = userEdit.contacto
            //JsBarcode("#barcode", "CN" + persona.identificacion + "-" + persona.id + "-" + persona.empresa.id);
            //console.log(userEdit)
        }
        var modoVista = store.getState().modovista
        return (
            <div>
                <AppBar style={{ backgroundColor: '#f44336' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={this.handleClose}
                            aria-label="Close"
                            onClick={this.cerrarVentanaCreacion.bind(this)} >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            style={{ marginLeft: "theme.spacing(2)", flex: 1, color: '#fff' }}
                        >
                            {modoVista ? persona.nombres + " " + persona.apellidos : userEdit ? "Editar " + (persona.nombres + " " + persona.apellidos) : 'Creacion de vehiculos'}
                        </Typography>
                        {modoVista
                            ?
                            null
                            :
                            <Button color="inherit" disabled={this.state.response} style={{ border: "0.5px solid", "box-shadow": "1px 1px 3px 1px azure", padding: "3px" }}
                                onClick={userEdit != null ? () => this.actualizarUsuario(persona.id) : this.consultaEstado}>
                                Guardar</Button>
                        }

                    </Toolbar>
                </AppBar>
                <div className="row mx-auto" style={{ marginTop: '5%', background: "$c3c3c3" }}>

                    <div className="col-md-6 col-sm-12">
                        <label htmlFor="numordencompra" className="col-form-label">NOMBRE DE LA CAMPAÑA</label>
                        <input autocomplete="off" id="numordencompra" disabled={modoVista} value={this.state.nombreGuardar} onChange={this.handleChange('nombreGuardar')} className="form-control" type="text" />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="numordencompra" className="col-form-label">FECHA DE INICIO</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                            <DatePicker
                                format="yyyy-MM-dd"
                                id="datetime"
                                value={this.state.fechaInicial}
                                onChange={this.handleChangemoment}
                                className="rounded form-control"
                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="numordencompra" className="col-form-label">FECHA FIN</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                            <DatePicker
                                format="yyyy-MM-dd"
                                id="datetime"
                                value={this.state.fechaFinal}
                                onChange={this.handleChangemomentFinal}
                                className="rounded form-control"
                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className="row mx-auto" style={{ marginTop: '5%', background: "$c3c3c3" }}>
                    
                </div>



            </div>
        )
    }
}


export default FormularioPersona
