
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios'
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import Server from '../../lib/server'
import DialogColor from './dialogs/dialogColor'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import store from '../../lib/store'
import './estilos.css'
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Typography from '@material-ui/core/Typography';
import { Button } from 'react-bootstrap'
import swal from "sweetalert"
var todos = []
var todosPadre = []
var permisosTodospadre = []
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
var colorDominio = store.getState().colorDominio
class FormularioCategoria extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            permisosTodospadre: [],
            modulospadre: [],
            moduloshijo: [],
            todosPadre: [],
            permisosTodos: [],
            permisosPadres: [],
            datosaInsertar: [],
            hijosMostrar: [],
            padreSeleccionado: 0,
            modulosmostrar: false,
            indexMostrar: 0,
            background: '#f44336',
            dialogColor: false,
            perfilSelecionado: {},
            perfilesbd: [],
            checkCrear: false,
            checkActualizar: false,
            checkVer: false,
            checkEliminar: false,
            modulosAgregados: [],
            modulosEliminados: [],
            perfilesGuardar: [],
            nperfil: '',
            idPerfil: 0,
            modoVista: false,
            tipoPerfil: 0,
            checkAplicaTiendas: false,
            response: false

        };
        store.subscribe(() => {
            this.setState({
                background: store.getState().color,
                dialogColor: store.getState().ventanaColor,
                modoVista: store.getState().vistaPerfil

            })
        })
    }

    handledialogCorreo = () => {
        this.setState({ dialogColor: !this.state.dialogColor });
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };

    handleChangeCheck = name => event => {
        var estado = (event.target.value == 'false' ? false : true)
        this.setState({
            [name]: !estado
        });
    }

    componentWillMount() {
        var usuario = store.getState().usuario
        Axios.get(Server.hostPrueba + Server.puertoSSLPrueba + '/modulos', { headers: { Authorization: ntoken } })
            .then(responsee => {
                var perf = []
                if (responsee.data.modulos != null) {
                    responsee.data.modulos.map((modulo) => {
                        if (modulo.nivel != null) {
                            switch (usuario.tipo) {
                                case "1":
                                case "2":
                                    perf.push({
                                        id: modulo.id,
                                        descripcion: modulo.descripcion,
                                        accionesaguardar: '',
                                        relacion_id: 0,
                                        estado: "A"
                                    })
                                    break
                                case "3":
                                    if (modulo.nivel == 2 || modulo.nivel == 3 || modulo.nivel == 5 || modulo.nivel == 6) {

                                        perf.push({
                                            id: modulo.id,
                                            descripcion: modulo.descripcion,
                                            accionesaguardar: '',
                                            relacion_id: 0,
                                            estado: "A"
                                        })
                                    }
                                    break
                                case "4":
                                case "5":
                                    if (modulo.nivel == 3 || modulo.nivel == 6) {

                                        perf.push({
                                            id: modulo.id,
                                            descripcion: modulo.descripcion,
                                            accionesaguardar: '',
                                            relacion_id: 0,
                                            estado: "A"
                                        })
                                    }
                                    break
                            }
                        }


                    })
                    //console.log(JSON.stringify(perf))
                    /*let perf2 = perf.sort((a, b) => { 
                        
                    })*/
                    let perf2 = perf.sort((a, b)=>{
                        const bandA = a.descripcion.toUpperCase();
                        const bandB = b.descripcion.toUpperCase();
                      
                        let comparison = 0;
                        if (bandA > bandB) {
                          comparison = 1;
                        } else if (bandA < bandB) {
                          comparison = -1;
                        }
                        return comparison;
                    })
                    this.setState({ perfilesbd: perf2 })
                    this.update()
                }

                //this.setState({ categoriasbd: responsee.data })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

  

    handleCheckAllModulo(idmodulo) {
        if (todos.includes(idmodulo)) {
            var indice = todos.indexOf(idmodulo); // obtenemos el indice
            todos.splice(indice, idmodulo)
        } else {
            todos.push(
                idmodulo
            )
        }
        this.setState({ permisosTodos: todos })
    }

    handleCheckPermiso(idmodulo) {
        if (todos.includes(idmodulo)) {
            var indice = todos.indexOf(idmodulo); // obtenemos el indice
            todos.splice(indice, idmodulo)
        } else {
            todos.push(
                idmodulo
            )
        }
        this.setState({ permisosTodos: todos })
    }

    obtenerhijos(id) {
        var amostrar = []
        this.state.moduloshijo.map((modulo) => {
            if (modulo.modulos_id == id) {
                amostrar.push(modulo)
            }
        })
        if (this.state.indexMostrar == id) {
            this.setState({ indexMostrar: 0, modulosmostrar: false })
        } else {
            this.setState({ hijosMostrar: amostrar, modulosmostrar: true, indexMostrar: id })
        }
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });


    };

    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
    };

    handleCheckAllModuloPadre(idmodulo) {
        //Seteando a los hijos
        if (todosPadre.includes(idmodulo)) {
            var indice = todosPadre.indexOf(idmodulo);
            todosPadre.splice(indice, idmodulo)
        } else {
            todosPadre.push(
                idmodulo
            )
        }
        //Seteando lso atributos
        if (permisosTodospadre.includes(idmodulo)) {
            var indice = permisosTodospadre.indexOf(idmodulo);
            permisosTodospadre.splice(indice, idmodulo)
        } else {
            permisosTodospadre.push(
                idmodulo
            )
        }
        this.setState({ permisosPadres: todosPadre, permisosTodospadre: permisosTodospadre })
    }

    agregarModulo = () => {
        var modulo = this.state.modulosAgregados
        var perf = this.state.perfilesbd
        var eliminados = this.state.modulosEliminados
        var accion = ''
        accion += this.state.checkVer ? 'R' : ''
        accion += this.state.checkCrear ? 'C' : ''
        accion += this.state.checkActualizar ? 'U' : ''
        accion += this.state.checkEliminar ? 'D' : ''
        modulo.push({
            descripcion: this.state.perfilSelecionado.label,
            id: this.state.perfilSelecionado.value,
            acciones: accion
        })
        perf.map((mod, key) => {
            if (mod.value == this.state.perfilSelecionado.value) {
                eliminados.push(mod)
                perf.splice(key, 1)
            }
        })
        this.setState({ modulosAgregados: modulo, modulosEliminados: eliminados, perfilSelecionado: {}, checkVer: false, checkCrear: false, checkActualizar: false, checkEliminar: false, perfilesbd: perf })
    }

    eliminarModulo = (e) => {
        console.log(e)
        var modulostotal = this.state.perfilesbd
        modulostotal.push(this.state.modulosEliminados[e])
        var modulos = this.state.modulosAgregados
        modulos.splice(e, 1)
        this.setState({ perfilesbd: modulostotal, modulosAgregados: modulos })
    }

    editarModulo = (e) => {
        var modulos = this.state.modulosAgregados
        var eliminados = this.state.modulosEliminados
        var moduloEditar = modulos[e]
        modulos.splice(e, 1)
        eliminados.splice(e, 1)
        this.setState({
            perfilSelecionado: {
                value: moduloEditar.id,
                label: moduloEditar.descripcion
            },
            checkCrear: moduloEditar.acciones.includes('C'),
            checkVer: moduloEditar.acciones.includes('R'),
            checkActualizar: moduloEditar.acciones.includes('U'),
            checkEliminar: moduloEditar.acciones.includes('D'),
            modulosAgregados: modulos,
            modulosEliminados: eliminados
        })
    }
    cancelar = () => {
        store.dispatch({
            type: "CREACION_PERFIL",
            ventanaPerfil: false,
            perfilEditar: null,
            vistaPerfil: false
        })
    }

    actualizarPerfil = () => {
        var usuario = store.getState().usuario
        var validado = this.validarFromulario()
        var datos = {
            "perfiles": {
                "id": this.state.idPerfil,
                "descripcion": this.state.nperfil,
                "empresas_id": parseInt(usuario.empresas_id),
                "color": this.state.background,
                "tipo": this.state.checkAplicaTiendas ? '3' : '2',
                "update_user": parseInt(usuario.p_id),
            },
            "modulos_perfiles":
                this.state.perfilesGuardar.map((perfil, key) => {
                    return {
                        "id": perfil.relacion_id,
                        "estado": perfil.estado,
                        "modulos_id": parseInt(perfil.id),
                        "acciones": perfil.accionesaguardar,
                    }
                })

        }
        console.log(datos)
        if (validado) {
            Axios.put(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/perfiles', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    this.setState({
                        response: false
                    })
                    console.log(response)
                    if (response.status === 201) {
                        swal({
                            title: "Perfil actualizado correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {
                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_PERFIL",
                                        ventanaPerfil: false
                                    })
                                    //this.setState({loader:true,descripcionN:'',selectedDate:new Date(),selectedDateFin:new Date(),checkIPorcentaje:true,checkIValor:false,valorn:false,cambbioInicio: false,cambbioFin:false})
                                    break;
                                default:
                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }

    }
    agregarcheck = (e, key) => () => {
        var perfiles = this.state.perfilesbd
        var perfilesGuardar = []
        if (key === 'CRUD') {
            if (perfiles[e].accionesaguardar === 'CRUD') {
                perfiles[e].accionesaguardar = ''
            } else {
                perfiles[e].accionesaguardar = key
            }
        } else if (perfiles[e].accionesaguardar.includes(key)) {
            perfiles[e].accionesaguardar = perfiles[e].accionesaguardar.replace(key, '')
        } else {
            perfiles[e].accionesaguardar += key
        }
        perfiles.map((p) => {
            if (p.relacion_id != 0) {
                if (p.accionesaguardar != '') {
                    perfilesGuardar.push({
                        id: p.id,
                        relacion_id: p.relacion_id,
                        descripcion: p.descripcion,
                        accionesaguardar: p.accionesaguardar,
                        estado: "A"
                    })
                } else {
                    perfilesGuardar.push({
                        id: p.id,
                        relacion_id: p.relacion_id,
                        descripcion: p.descripcion,
                        accionesaguardar: p.accionesaguardar,
                        estado: "I"
                    })
                }
            } else {
                if (p.accionesaguardar != '') {
                    perfilesGuardar.push(p)
                }
            }
        })
        this.setState({ perfilesbd: perfiles, perfilesGuardar: perfilesGuardar })

    }

    update() {
        const perfilEditar = store.getState().perfilEditar
        if (perfilEditar != null) {
            const { perfiles, modulos_empresas } = perfilEditar
            //var tipo = 
            //console.log(perfiles)
            this.setState({
                idPerfil: perfiles.id,
                background: perfiles.color,
                nperfil: perfiles.descripcion,
                checkAplicaTiendas: perfiles.tipo == 3 ? true : false
            })
            //console.log(this.state)
            var perfilesbd = this.state.perfilesbd
            var perfilGuardar = this.state.perfilesGuardar
            if (modulos_empresas != null) {
                modulos_empresas.map((modulo, key) => {
                    perfilesbd.map((modulobd, key2) => {
                        if (modulo.modulos_id == parseInt(modulobd.id)) {
                            perfilesbd[key2].accionesaguardar = modulo.acciones
                            perfilesbd[key2].relacion_id = modulo.id
                            perfilGuardar.push({
                                relacion_id: modulo.id,
                                id: perfilesbd[key2].id,
                                descripcion: perfilesbd[key2].descripcion,
                                accionesaguardar: perfilesbd[key2].accionesaguardar,
                            })
                        }
                    })
                })
            }

            this.setState({ perfilesbd: perfilesbd, perfilesGuardar: perfilGuardar })
        }
    }


    validarFromulario = () => {
        if (this.state.nperfil.trim == "" || this.state.nperfil.length == 0) {
            swal("FALLO AL CREAR", "EL NOMBRE DEL PERFIL ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.perfilesGuardar.length == 0) {
            swal("FALLO AL CREAR", "POR LO MENOS DEBES ASIGAR UN MODULO", "warning")
            return false
        } else {
            this.setState({
                response: true
            })
            return true
        }
    }

    guardarenbd = () => {
        var usuario = store.getState().usuario
        var validar = this.validarFromulario()
        var datos = {
            "perfiles": {
                "descripcion": this.state.nperfil,
                "empresas_id": parseInt(usuario.empresas_id),
                "color": this.state.background,
                "tipo": this.state.checkAplicaTiendas ? '3' : '2',
                "update_user": parseInt(usuario.p_id),
            },
            "modulos_perfiles":
                this.state.perfilesGuardar.map((perfil, key) => {
                    return {
                        "modulos_id": parseInt(perfil.id),
                        "acciones": perfil.accionesaguardar,
                    }
                })

        }
        if (validar) {
            Axios.post(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/perfiles', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    console.log(response)
                    this.setState({
                        response: false
                    })
                    if (response.status === 201) {
                        swal({
                            title: "Perfil creado correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_PERFIL",
                                        ventanaPerfil: false
                                    })
                                    //this.setState({loader:true,descripcionN:'',selectedDate:new Date(),selectedDateFin:new Date(),checkIPorcentaje:true,checkIValor:false,valorn:false,cambbioInicio: false,cambbioFin:false})
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }
    }


    render() {
        var modovista = store.getState().vistaPerfil
        const perfilEditar = store.getState().perfilEditar
        return (
            <div className="row">
                <AppBar style={{ backgroundColor: colorDominio }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Close"
                            onClick={this.cancelar.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            style={{ marginLeft: "theme.spacing(2)", flex: 1, color: '#fff' }}
                        >
                            CREACION DE PERFIL
                        </Typography>
                        {modovista ?
                            null
                            :
                            perfilEditar != null
                                ?
                                <Button disabled={this.state.response} style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} onClick={this.actualizarPerfil.bind(this)}>GUARDAR</Button>
                                : <Button disabled={this.state.response} style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} onClick={this.guardarenbd.bind(this)}>GUARDAR</Button>
                        }



                    </Toolbar>
                </AppBar>
                <div className="col-md-11 col-lg-11 col-sm-12 mx-auto">
                    <div className="row" style={{ marginTop: '12VH' }}>
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <label htmlFor="numordencompra" className="col-form-label">Nombre del perfil</label>
                            <input id="numordencompra" disabled={modovista} className="form-control" type="text" value={this.state.nperfil} onChange={this.handleChange('nperfil')} />
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12">
                            <label htmlFor="numordencompra" className="col-form-label">Color del perfil</label>
                            <input id="numordencompra" disabled={modovista} className="form-control" type="text" autoComplete="off" style={{ backgroundColor: this.state.background, cursor: 'pointer' }} onClick={this.handledialogCorreo} value="" />
                        </div>
                        <div className="col-lg-2 col-md-2 bottom-aligned">
                            <div className="row">
                                <div className="col-md-12 bottom-aligned">
                                    <FormControlLabel
                                        control={<Checkbox value={this.state.checkAplicaTiendas} checked={this.state.checkAplicaTiendas} style={{ padding: '5px' }} onChange={this.handleChangeCheck('checkAplicaTiendas')} />}
                                        label='APLICA A TIENDAS'
                                        style={{ margin: '0' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-11 col-lg-11 col-sm-12 mx-auto">
                    <div className="row" style={{ marginTop: '10px' }}>

                        <div className="col col-md-12 mx-auto">
                            <MDBTable bordered striped>
                                <MDBTableHead color="default" textWhite style={{ backgroundColor: "#f44336" }}>
                                    <tr>
                                        <th style={{ textAlign: 'center' }}>Modulo</th>
                                        <th style={{ textAlign: 'center', maxWidth: '60px' }}>Todos</th>
                                        <th style={{ textAlign: 'center', maxWidth: '60px' }}>Ver</th>
                                        <th style={{ textAlign: 'center', maxWidth: '60px' }}>Crear</th>
                                        <th style={{ textAlign: 'center', maxWidth: '60px' }}>Actualizar</th>
                                        <th style={{ textAlign: 'center', maxWidth: '60px' }}>Eliminar</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.perfilesbd.length != 0
                                        ?
                                        this.state.perfilesbd.map((row, key) => (
                                            <tr key={key}>
                                                <td component="th" style={{ padding: '5px !IMPORTANT' }} scope="row">
                                                    {row.descripcion}
                                                </td>
                                                <td style={{ padding: '5px !IMPORTANT', textAlign: 'center', maxWidth: '60px' }}>{<Checkbox disabled={modovista} style={{ padding: "0" }} checked={row.accionesaguardar.includes('C') && row.accionesaguardar.includes('R') && row.accionesaguardar.includes('U') && row.accionesaguardar.includes('D')} value={row.accionesaguardar.includes('C') && row.accionesaguardar.includes('R') && row.accionesaguardar.includes('U') && row.accionesaguardar.includes('D')} onChange={this.agregarcheck(key, "CRUD")} />}</td>
                                                <td style={{ padding: '5px !IMPORTANT', textAlign: 'center', maxWidth: '60px' }}>{<Checkbox disabled={modovista} style={{ padding: "0" }} checked={row.accionesaguardar.includes('R')} value={row.accionesaguardar.includes('R')} onChange={this.agregarcheck(key, "R")} />}</td>
                                                <td style={{ padding: '5px !IMPORTANT', textAlign: 'center', maxWidth: '60px' }}>{<Checkbox disabled={modovista} style={{ padding: "0" }} checked={row.accionesaguardar.includes('C')} value={row.accionesaguardar.includes('C')} onChange={this.agregarcheck(key, "C")} />}</td>
                                                <td style={{ padding: '5px !IMPORTANT', textAlign: 'center', maxWidth: '60px' }}>{<Checkbox disabled={modovista} style={{ padding: "0" }} checked={row.accionesaguardar.includes('U')} value={row.accionesaguardar.includes('U')} onChange={this.agregarcheck(key, "U")} />}</td>
                                                <td style={{ padding: '5px !IMPORTANT', textAlign: 'center', maxWidth: '60px' }}>{<Checkbox disabled={modovista} style={{ padding: "0" }} checked={row.accionesaguardar.includes('D')} value={row.accionesaguardar.includes('D')} onChange={this.agregarcheck(key, "D")} />}</td>
                                            </tr>
                                        ))
                                        : null
                                    }
                                </MDBTableBody>
                            </MDBTable>
                        </div>
                    </div>
                </div>
                <Dialog
                    open={this.state.dialogColor}
                    onClose={this.handledialogCorreo}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'md'}
                    style={{ padding: '0' }}
                >
                    <DialogContent style={{ padding: '0' }}>
                        <DialogColor color={this.state.background} />
                    </DialogContent>

                </Dialog>
            </div>

        )
    }
}


export default FormularioCategoria