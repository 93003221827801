import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Button from '@material-ui/core/Button';
import FormularioContraseña from './FormularioContraseña';
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import DialogColor from './dialogs/dialogColor'
import Slide from "@material-ui/core/Slide";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChangeImage from '../../assets/img/Formularios/change_foto.png'
import "./producto.css"
import getCroppedImg from './getCroppedImg'
import Slider from '@material-ui/lab/Slider'
import Cropper from 'react-easy-crop'
import img from "../../assets/img/faces/marc.jpg"
import Avatar from '@material-ui/core/Avatar';
import Selects from 'react-select';
import Axios from 'axios'
import DialogTelefono from './dialogs/dialogTelefono'
import DialogCorreo from './dialogs/dialogCorreo'
import TelefonoIcon from '@material-ui/icons/Phone'
import LocationIcon from '@material-ui/icons/LocationOn'
import MailIcon from '@material-ui/icons/Mail'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import store from '../../lib/store'
import Server from '../../lib/server'
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import swal from 'sweetalert'
import PerfilIcon from '@material-ui/icons/AssignmentInd'
import DeleteIcon from '@material-ui/icons/Delete';
import FormularioGeografia from './FormularioGeografia';
import { getWeekYearWithOptions } from 'date-fns/esm/fp';
import moment from 'moment'
import "moment/locale/es";
import { isUndefined } from 'util';
import DateFnsUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
var colorDominio = store.getState().colorDominio
const tiposDeSAngre = [
    { label: 'O+' },
    { label: 'O-' },
    { label: 'A-' },
    { label: 'A+' },
    { label: 'B-' },
    { label: 'B+' },
    { label: 'AB-' },
    { label: 'AB+' }
].map(suggestion => ({
    value: suggestion.label,
    label: suggestion.label,
}));
var perfiles = []
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))

class FormularioPersona extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Traidas desde bd
            perfilesbd: [],
            identificacionesbd: [],
            provinciasbd: [],
            ciudadesbd: [],
            empresasbd: [],
            sucursalesbd: [],
            tiendabd: [],
            //controles vistas
            vistaEdicion: null,
            //Datos seleccionados
            modovista: false,
            //
            croppedImage: null,
            dialogphoto: false,
            dialogTelefono: false,
            dialogCorreo: false,
            crop: { x: 0, y: 0 },
            zoom: 1,
            croppedAreaPixels: null,
            croppedImage: null,
            aspect: 1 / 1,
            imagenCreate: false,
            imageSrc: null,
            //Datos a guardar
            nombreGuardar: '',
            apellidoGuardar: '',
            identificacionGuardar: '',
            DireccionGuardar: '',
            pinGuardar: '',
            //Vectores
            telefonos: [],
            corrreos: [],
            //Checkbox
            prefilesGuardar: [],
            changePerfiles: false,
            perfilesSelected: [],
            //Selects
            tiposangreSeleccionada: {},
            empresaSeleccionada: {},
            tipo_identificacion_Seleccionada: {},
            departamentoSeleccionado: {},
            ciudadSeleccionada: {},
            //Perfiles a editar
            listaPerfiles: [],
            cambioImagen: false,
            usuarion: '',
            idDireccion: 'NT',
            idUsuario: '',
            crearGeografia: false,
            tipoGeografia: 0,
            actualizarDatos: false,
            idsPerfilesDeUsuario: [],
            ventanaContra: false,
            response: false,
            //color
            background1: '#f44336',
            dialogColor1: false,
        };
        store.subscribe(() => {
            this.setState({
                telefonos: store.getState().telefonos,
                dialogTelefono: store.getState().creacionTelefono,
                corrreos: store.getState().correos,
                dialogCorreo: store.getState().creacionCorreo,
                modovista: store.getState().modovista,
                crearGeografia: store.getState().creacionGeografia,
                actualizarDatos: store.getState().actualizarvista,
                ventanaContra: store.getState().ventanaContrseña,
                background1: store.getState().color,
                dialogColor1: store.getState().ventanaColor,
            })
        })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
        var value = event.target.value
        switch (name) {
            case 'pinGuardar':
                break;
        }
    };

    handleFormulario = () => {
        console.log('funciona');
        store.dispatch({
            type: "CAMBIO_CONTRASEÑA",
            ventanaContrseña: !this.state.ventanaContra
        })
    }


    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizarDatos) {
            this.obtenerDatos()
            return true
        }
        return true;
    }


    obtenerDatos = () => {
        //this.setState({ actualizarDatos: false })
        store.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: false
        })
        var datos = store.getState().usuario
        var hijos = store.getState().empresasHijos
        var padres = store.getState().empresasPadres
        const { userEdit } = this.props
        switch (datos.tipo) {
            case "2":
            case "3":
            case "4":
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/3,4,5/empresas/' + hijos + "," + datos.empresas_id, { headers: { Authorization: ntoken } })
                    .then(responseee => {
                        var empresas = []

                        if (responseee.status == 200 && responseee.data != null) {
                            responseee.data.empresas.map((dato) => (
                                empresas.push({
                                    value: dato.id,
                                    label: dato.razon_social + " -- " + dato.nit
                                })
                            ))
                        } else if (responseee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 5000);
                        }
                        this.setState({ empresasbd: empresas })

                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/perfil/' + datos.empresas_id, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        this.setState({ perfilesbd: responsee.data.rows })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break;
            case "5":
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/5/empresas/' + datos.empresas_id + "," + padres, { headers: { Authorization: ntoken } })
                    .then(responseee => {
                        var empresas = []
                        if (responseee.status == 200 && responseee.data != null) {
                            responseee.data.empresas.map((dato) => (
                                empresas.push({
                                    value: dato.id,
                                    label: dato.razon_social + " -- " + dato.nit
                                })
                            ))
                        } else if (responseee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 5000);
                        }
                        this.setState({ empresasbd: empresas })

                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/perfil/' + padres + "," + datos.empresas_id, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee)
                        console.log(userEdit)
                        var perfbd = []
                        if (responsee.status == 200 && responsee.data.rows != null) {
                            responsee.data.rows.map((perfil) => {
                                if (perfil.tipo == "3") {
                                    perfbd.push(perfil)
                                }
                            })
                        }
                        this.setState({ perfilesbd: perfbd })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break;
        }
        console.log(hijos)
        Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/provincias/pais/' + datos.empresas.paises_id, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var provincias = []
                responsee.data.map((provincia) => (
                    provincias.push({
                        value: provincia.provincia_id,
                        label: provincia.provincia
                    })
                ))
                this.setState({ provinciasbd: provincias })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })

        Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/tipos/identificaciones/', { headers: { Authorization: ntoken } })
            .then(responseee => {
                var tid = []
                responseee.data.map((dato) => (
                    tid.push({
                        value: dato.id,
                        label: dato.descripcion
                    })
                ))
                this.setState({ tipo_identificacion: tid[0] })
                this.setState({ identificacionesbd: tid })

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        this.update();
    }

    componentWillMount() {
        this.obtenerDatos()
        console.log("Se esta ejecutandoa capapi")
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        switch (name) {
            case 'departamentoSeleccionado':
                var ciudades = []
                Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/provincias/ciudades/' + value.value, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee)
                        responsee.data.map((dato) => (
                            ciudades.push({
                                value: dato.ciudades_id,
                                label: dato.ciudades
                            })
                        ))
                        this.setState({ ciudadesbd: ciudades })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })

                break
        }
    }

    update() {
        const { userEdit } = this.props
        //console.log(userEdit)
        if (userEdit != null) {
            const { contacto, perfil, persona, usuario } = userEdit
            //var canvas = new canvas();
            this.setState({
                nombreGuardar: persona.nombres,
                apellidoGuardar: persona.apellidos,
                identificacionGuardar: persona.identificacion,
                croppedImage: "nn",
                ciudadSeleccionada: {
                    value: persona.ciudad.id,
                    label: persona.ciudad.descripcion,
                },
                departamentoSeleccionado: {
                    value: persona.departamento.id,
                    label: persona.departamento.descripcion,
                },
                tiposangreSeleccionada: {
                    value: persona.sangre,
                    label: persona.sangre,
                },
                tipo_identificacion_Seleccionada: {
                    value: persona.tipo_identificacion.id,
                    label: persona.tipo_identificacion.descripcion,
                },
                empresaSeleccionada: {
                    value: persona.empresa.id,
                    label: persona.empresa.razon_social,
                },
                usuarion: usuario.usuario,
                idUsuario: usuario.id,
                pinGuardar: usuario.pin
            });
            var tel = []
            var mails = []
            var did = ''
            var address = ""
            var idsp = []
            if (perfil != null) {
                var perf = []
                var perfID = []
                perfil.map((perfiless) => {
                    console.log(perfiless)
                    perf.push({
                        relacion_id: perfiless.id,
                        id: perfiless.perfil_id,
                        descripcion: perfiless.descripcion,
                        color: perfiless.color,
                        estado: "A"
                    })
                    idsp.push(perfiless.perfil_id)
                })
                this.setState({ listaPerfiles: perf, prefilesGuardar: perf, idsPerfilesDeUsuario: idsp })
            }
            if (contacto != null) {
                contacto.map((contact, key) => {
                    switch (contact.tipo) {
                        case 1:
                            mails.push({
                                id: contact.id,
                                etiqueta: contact.etiqueta,
                                correo: contact.contacto,
                                principal: contact.principal,
                                estado: "A"
                            })
                            break;
                        case 2:
                            tel.push({
                                id: contact.id,
                                etiqueta: contact.etiqueta,
                                telefono: contact.contacto,
                                principal: contact.principal,
                                estado: "A"
                            })
                            break;
                        case 3:
                            address = contact.contacto
                            did = contact.id
                            break;
                    }
                })
                store.dispatch({
                    type: "AGREGAR_TELEFONO_USUARIO",
                    telefonos: tel,
                    creacionTelefono: false
                })
                store.dispatch({
                    type: "AGREGAR_CORREO_USUARIO",
                    correos: mails,
                    creacionCorreo: false
                })
                this.setState({
                    DireccionGuardar: address,
                    telefonos: tel,
                    corrreos: mails,
                    idDireccion: did
                })
            }
        }
    }

    handleCheckPerfil(idperfil) {
        if (perfiles.includes(idperfil)) {
            var indice = perfiles.indexOf(idperfil); // obtenemos el indice
            perfiles.splice(indice, idperfil)
        } else {
            perfiles.push(
                idperfil
            )
        }
        this.setState({ prefilesGuardar: perfiles })
    }

    handleChangemoment = date => {
        this.setState({ selectedDate: date, cambbioInicio: true });
    }

    handleCheckNewPerfil = (perfil) => {
        var nperfil = this.state.prefilesGuardar
        var ids = this.state.idsPerfilesDeUsuario
        console.log(perfil)
        console.log(ids)
        if (ids.includes(parseInt(perfil.id))) {
            nperfil.map((pp, key) => {
                if (pp.relacion_id != 0 && pp.id == parseInt(perfil.id)) {
                    console.log(pp)
                    if (nperfil[key].estado == "I") {
                        nperfil[key].estado = "A"
                    } else {
                        nperfil[key].estado = "I"
                    }
                    ids.splice(key, 1)
                } else if (pp.id == parseInt(perfil.id)) {
                    nperfil.splice(key, 1)
                    ids.splice(key, 1)
                }
            })
        } else {
            nperfil.push({
                relacion_id: 0,
                id: perfil.id,
                descripcion: perfil.descripcion,
                color: perfil.color,
                estado: "A"
            })
            ids.push(parseInt(perfil.id))
        }

        this.setState({ prefilesGuardar: nperfil, idsPerfilesDeUsuario: ids })
    }


    onZoomChange = zoom => {
        this.setState({ zoom })
    }

    onZoomChange = zoom => {
        this.setState({ zoom })
    }
    onCropChange = crop => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels })
    }
    handledialogTelefono = () => {
        store.dispatch({
            type: "AGREGAR_TELEFONO_USUARIO",
            telefonos: this.state.telefonos,
            creacionTelefono: !this.state.dialogTelefono
        })
        //this.setState({ dialogTelefono: !this.state.dialogTelefono });
    }
    handledialogCorreo = () => {
        store.dispatch({
            type: "AGREGAR_CORREO_USUARIO",
            correos: this.state.corrreos,
            creacionCorreo: !this.state.dialogCorreo
        })
    }

    _handleImageChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        }

        reader.readAsDataURL(file)
        this.setState({ dialogphoto: !this.state.dialogphoto });
    }

    actualizarUsuario = (id) => {
        //console.log(id)
        var usuario = store.getState().usuario
        var dominio = store.getState().dominio
        var validar = this.validarFormularioeDITAR()
        var datosActualizar = {
            "persona": {
                "tipos_identificaciones_id": this.state.tipo_identificacion_Seleccionada.value,
                "identificacion": this.state.identificacionGuardar,
                "nombres": this.state.nombreGuardar,
                "apellidos": this.state.apellidoGuardar,
                correo:
                    (typeof this.state.corrreos[0] != "undefined"
                        ? this.state.corrreos[0].correo : ''),
                "estado": "A",
                "empresas_id": this.state.empresaSeleccionada.value,
                "sangre": this.state.tiposangreSeleccionada.value,
                "ciudades_id": this.state.ciudadSeleccionada.value,
                "url_foto": this.state.cambioImagen ? this.state.croppedImage != null ? this.state.imagePreviewUrl : 'NC' : 'NC',
                "update_user": usuario.p_id
            }, "empresa": {
                "dominio_id": dominio
            }, "telefono":
                this.state.telefonos.map((telefono, key) => {
                    return (
                        {
                            "id": telefono.id,
                            "etiqueta": telefono.etiqueta,
                            "contacto": telefono.telefono,
                            "tipo": 2,
                            "principal": key == 0 ? "S" : "N",
                            "estado": telefono.estado
                        }
                    )
                })
            , "correo":
                this.state.corrreos.map((correo, key) => {
                    return (
                        {
                            "id": correo.id,
                            "etiqueta": correo.etiqueta,
                            "contacto": correo.correo,
                            "tipo": 1,
                            "principal": key == 0 ? "S" : "N",
                            "estado": correo.estado
                        }
                    )
                })
            , "direccion": [{
                "id": this.state.idDireccion == null || this.state.idDireccion == 'NT' ? 0 : this.state.idDireccion,
                "etiqueta": "Principal",
                "contacto": this.state.DireccionGuardar,
                "tipo": 3,
                "principal": "S",
                "estado": "A"
            }],
            "perfiles":
                this.state.prefilesGuardar.map((perfil) => {
                    return (
                        {
                            "perfil_id": perfil.id,
                            "id": perfil.relacion_id,
                            "estado": perfil.estado
                        }
                    )
                }),
            "usuario": {
                "id": this.state.idUsuario,
                "usuario": this.state.usuarion,
                "pin": this.state.pinGuardar
            }
        }
        if (validar) {
            Axios.put(Server.hostPruebasinssl + + Server.puertoEmpleado + '/api/persona/' + id, datosActualizar, { headers: { Authorization: ntoken } })
                .then((res) => {
                    this.setState({
                        response: false
                    })
                    if (res.status == 201) {
                        store.dispatch({
                            type: "AGREGAR_TELEFONO_USUARIO",
                            telefonos: [],
                            creacionTelefono: false
                        })
                        store.dispatch({
                            type: "AGREGAR_CORREO_USUARIO",
                            correos: [],
                            creacionCorreo: false
                        })
                        swal({
                            title: 'Usuario actualizado correctamente',
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "CREACION_USUARIO",
                                        creacionUsuario: false,
                                        usuarioEdicion: null,
                                        modovista: false
                                    })
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })

                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al actualizar", "Por favor verifique los datos", "warning");
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    validarFormulario = () => {
        if (this.state.nombreGuardar.trim == "" || this.state.nombreGuardar.length == 0) {
            swal("Fallo al actualizar", "EL NOMBRE ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.apellidoGuardar.trim == "" || this.state.apellidoGuardar.length == 0) {
            swal("Fallo al actualizar", "EL APELLIDO ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (JSON.stringify(this.state.tiposangreSeleccionada) == '{}') {
            swal("Fallo al actualizar", "ES NECESARIO EL TIPO DE SANGRE", "warning")
            return false
        } else if (JSON.stringify(this.state.tipo_identificacion_Seleccionada) == '{}') {
            swal("Fallo al actualizar", "DEBE ESCOGER EL TIPO DE IDENTIFICACION", "warning")
            return false
        } else if (this.state.identificacionGuardar.trim == "" || this.state.identificacionGuardar.length == 0) {
            swal("Fallo al actualizar", "EL NUMERO DE IDENTIFICACION ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (JSON.stringify(this.state.departamentoSeleccionado) == '{}') {
            swal("Fallo al actualizar", "POR FAVOR ESCOJA EL DEPARTAMENTO", "warning")
            return false
        } else if (JSON.stringify(this.state.ciudadSeleccionada) == '{}') {
            swal("Fallo al actualizar", "POR FAVOR ESCOJA LA CIUDAD", "warning")
            return false
        } else if (JSON.stringify(this.state.empresaSeleccionada) == '{}') {
            swal("Fallo al actualizar", "POR FAVOR ESCOJA LA EMPRESA", "warning")
            return false
        } else {
            this.setState({
                response: true
            })
            return true
        }

    }

    validarFormularioeDITAR = () => {
        if (this.state.nombreGuardar.trim == "" || this.state.nombreGuardar.length == 0) {
            swal("Fallo al actualizar", "EL NOMBRE ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.apellidoGuardar.trim == "" || this.state.apellidoGuardar.length == 0) {
            swal("Fallo al actualizar", "EL APELLIDO ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (JSON.stringify(this.state.tiposangreSeleccionada) == '{}') {
            swal("Fallo al actualizar", "ES NECESARIO EL TIPO DE SANGRE", "warning")
            return false
        } else if (JSON.stringify(this.state.tipo_identificacion_Seleccionada) == '{}') {
            swal("Fallo al actualizar", "DEBE ESCOGER EL TIPO DE IDENTIFICACION", "warning")
            return false
        } else if (this.state.identificacionGuardar.trim == "" || this.state.identificacionGuardar.length == 0) {
            swal("Fallo al actualizar", "EL NUMERO DE IDENTIFICACION ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (JSON.stringify(this.state.departamentoSeleccionado) == '{}') {
            swal("Fallo al actualizar", "POR FAVOR ESCOJA EL DEPARTAMENTO", "warning")
            return false
        } else if (JSON.stringify(this.state.ciudadSeleccionada) == '{}') {
            swal("Fallo al actualizar", "POR FAVOR ESCOJA LA CIUDAD", "warning")
            return false
        } else if (JSON.stringify(this.state.empresaSeleccionada) == '{}') {
            swal("Fallo al actualizar", "POR FAVOR ESCOJA LA EMPRESA", "warning")
            return false
        } else {
            this.setState({
                response: true
            })
            return true
        }

    }

    consultaEstado = () => {
        var dominio = store.getState().dominio
        var usuario = store.getState().usuario
        var validado = this.validarFormulario()
        var datos = {
            "persona": {
                "tipos_identificaciones_id": this.state.tipo_identificacion_Seleccionada.value,
                "identificacion": this.state.identificacionGuardar,
                "nombres": this.state.nombreGuardar,
                "apellidos": this.state.apellidoGuardar,
                correo:
                    (typeof this.state.corrreos[0] != "undefined"
                        ? this.state.corrreos[0].correo : ''),
                "estado": "A",
                "empresas_id": this.state.empresaSeleccionada.value,
                "sangre": this.state.tiposangreSeleccionada.value,
                "ciudades_id": this.state.ciudadSeleccionada.value,
                "pin": this.state.pinGuardar,
                "url_foto": this.state.croppedImage != null ? this.state.imagePreviewUrl : 'N',
                "update_user": usuario.p_id
            }, "empresa": {
                "dominio_id": dominio
            }, "telefono":
                this.state.telefonos.map((telefono, key) => {
                    return (
                        {
                            "etiqueta": telefono.etiqueta,
                            "contacto": telefono.telefono,
                            "tipo": 2,
                            "principal": key == 0 ? "S" : "N",
                            "estado": "A"
                        }
                    )
                })
            , "correo":
                this.state.corrreos.map((correo, key) => {
                    return (
                        {
                            "etiqueta": correo.etiqueta,
                            "contacto": correo.correo,
                            "tipo": 1,
                            "principal": key == 0 ? "S" : "N",
                            "estado": "A"
                        }
                    )
                })
            , "direccion": [{
                "etiqueta": "Principal",
                "contacto": this.state.DireccionGuardar,
                "tipo": 3,
                "principal": "S",
                "estado": "A"
            }],
            "perfiles":
                this.state.prefilesGuardar.map((perfil) => {
                    return (
                        {
                            "perfil_id": perfil
                        }
                    )
                }),
        }
        if (validado) {
            Axios.post(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/persona', datos, { headers: { Authorization: ntoken } })
                .then((res) => {
                    this.setState({
                        response: false
                    })
                    console.log(res)
                    if (res.status == 200) {
                        store.dispatch({
                            type: "AGREGAR_TELEFONO_USUARIO",
                            telefonos: [],
                            creacionTelefono: false
                        })
                        store.dispatch({
                            type: "AGREGAR_CORREO_USUARIO",
                            correos: [],
                            creacionCorreo: false
                        })
                        swal({
                            title: res.data.message,
                            text: res.data.message2,
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "CREACION_USUARIO",
                                        creacionUsuario: false,
                                        usuarioEdicion: null,
                                        modovista: false
                                    })
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })

                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }

    showCroppedImage = async () => {
        console.log(this.state.croppedImage)
        const croppedImage = await getCroppedImg(
            this.state.imagePreviewUrl,
            this.state.croppedAreaPixels
        )
        console.log(croppedImage)
        this.setState({ croppedImage, dialogphoto: !this.state.dialogphoto })
    }

    cerrarVentanaCreacion() {
        store.dispatch({
            type: "CREACION_USUARIO",
            creacionUsuario: false,
            usuarioEdicion: null,
            modovista: false
        })
        store.dispatch({
            type: "AGREGAR_TELEFONO_USUARIO",
            telefonos: [],
            creacionTelefono: false
        })
        store.dispatch({
            type: "AGREGAR_CORREO_USUARIO",
            correos: [],
            creacionCorreo: false
        })
    }

    handleChangeCheck = name => key => event => {
        var estado = (event.target.value == 'false' ? false : true)
        this.setState({
            [name]: [key] = !estado
        });

    }

    borrarImagen = () => {
        this.setState({ croppedImage: null, cambioImagen: true })
    }

    eliminarTelefono = (e) => {
        var mtelefono = this.state.telefonos
        mtelefono.splice(e, 1)
        this.setState({ telefonos: mtelefono })
    }
    eliminarTelefonobd = (e) => {
        var mtelefono = this.state.telefonos
        if (mtelefono[e].id == 0) {
            mtelefono.splice(e, 1)
        } else {
            mtelefono[e].estado = 'I'
        }
        this.setState({ telefonos: mtelefono })
    }
    eliminarPerfilbd = (e) => {
        var mperfil = this.state.prefilesGuardar
        console.log(mperfil)
        if (mperfil[e].id == 0) {
            mperfil.splice(e, 1)
        } else {
            mperfil[e].estado = 'I'
        }
        this.setState({ prefilesGuardar: mperfil })
    }

    cancelar = () => {
        this.setState({ crearGeografia: false })
    }

    eliminarCorreos = (e) => {
        var mcorreos = this.state.corrreos
        mcorreos.splice(e, 1)
        this.setState({ corrreos: mcorreos })
    }

    crearGeografia = (tipo) => {
        this.setState({ tipoGeografia: tipo })
        store.dispatch({
            type: "CREACION_GEOGRAFIA",
            creacionGeografia: true
        })
    }

    eliminarCorreobd = (e) => {
        var mcorreos = this.state.corrreos
        if (mcorreos[e].id == 0) {
            mcorreos.splice(e, 1)
        } else {
            mcorreos[e].estado = 'I'
        }
        this.setState({ corrreos: mcorreos })
    }

    eliminarPerfil = (e) => {
        var mperfil = this.state.listaPerfiles
        mperfil.splice(e, 1)
        this.setState({ listaPerfiles: mperfil })
    }


    handledialogColor = (n) => {
        console.log(n)
        if (n === 1) {
            this.setState({
                dialogColor1: !this.state.dialogColor1
            });
        } else {
            this.setState({
                dialogColor2: !this.state.dialogColor2
            });
        }

    }

    render() {
        const { userEdit } = this.props
        //console.log(userEdit)
        var dominio = store.getState().dominio
        var def = Server.hostMultimedia + dominio + "/persona/default.png"
        var contactos = [];
        var persona = []
        var perfiles = []

        var telefonos = ""
        var imagen = ""
        var correos = ""


        this.state.telefonos.map((contacto) => {
            if (contacto.estado != 'I') {
                telefonos += contacto.telefono + ", "
            }
        })
        this.state.corrreos.map((contacto2) => {
            if (contacto2.estado != 'I') {
                correos += contacto2.correo + ", "
            }
        })
        if (userEdit != null) {
            persona = userEdit.persona
            imagen = Server.hostMultimedia + dominio + "/persona/" + (persona.url_foto == null || (persona.url_foto == 'N') ? 'default.png' : persona.id + '.png')
            perfiles = userEdit.perfil
            contactos = userEdit.contacto
            //JsBarcode("#barcode", "CN" + persona.identificacion + "-" + persona.id + "-" + persona.empresa.id);
            //console.log(userEdit)
        }
        var modoVista = store.getState().modovista
        return (
            <div>
                <AppBar style={{ backgroundColor: colorDominio }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={this.handleClose}
                            aria-label="Close"
                            onClick={this.cerrarVentanaCreacion.bind(this)} >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            style={{ marginLeft: "theme.spacing(2)", flex: 1, color: '#fff' }}
                        >
                            {modoVista ? persona.nombres + " " + persona.apellidos : userEdit ? "Editar " + (persona.nombres + " " + persona.apellidos) : 'Creacion de vehiculos'}
                        </Typography>
                        {modoVista
                            ?
                            null
                            :
                            <Button color="inherit" disabled={this.state.response} style={{ border: "0.5px solid", "box-shadow": "1px 1px 3px 1px azure", padding: "3px" }}
                                onClick={userEdit != null ? () => this.actualizarUsuario(persona.id) : this.consultaEstado}>
                                Guardar</Button>
                        }

                    </Toolbar>
                </AppBar>
                <div className="row mx-auto" style={{ marginTop: '5%', background: "$c3c3c3" }}>
                    <div className="rounded rounded-circle col-md-3 col-sm-12 mx-auto">
                        {userEdit != null
                            ?
                            <div className={this.state.croppedImage != null ? "rounded rounded-circle border border-dark mx-auto" : "userImg rounded rounded-circle border border-dark mx-auto"} style={{ height: '200px', width: '200px' }} >
                                {modoVista ?
                                    <img onError={(e) => { e.target.onerror = null; e.target.src = def }} className="img-user rounded-circle border border border-dark" height="200" width="200" src={imagen} />
                                    :
                                    !this.state.cambioImagen ?
                                        <img className="img-user rounded-circle border border border-dark" onError={(e) => { e.target.onerror = null; e.target.src = def }} height="200" width="200" src={imagen} />
                                        :
                                        this.state.croppedImage != null ? < img o className="img-user rounded-circle border border border-dark" height="200" width="200" src={this.state.croppedImage} />
                                            :
                                            <input autocomplete="off" type="file" accept="image/png" onChange={(e) => this._handleImageChange(e)} style={{ opacity: "0.0", height: "100%", cursor: "pointer" }} />}
                                {modoVista ? null : <img src={ChangeImage} height="30" width="30" onClick={() => this.borrarImagen()} style={{ cursor: 'pointer', float: 'right' }}></img>}
                            </div>
                            :
                            <div>
                                <div className="userImg rounded rounded-circle border border-dark mx-auto" style={{ height: '200px', width: '200px' }} >
                                    {this.state.croppedImage != null ? <img className="img-user rounded-circle border border border-dark" height="200" width="200" src={this.state.croppedImage} /> : <input autocomplete="off" type="file" accept="image/png" onChange={(e) => this._handleImageChange(e)} style={{ opacity: "0.0", height: "100%", cursor: "pointer" }} />}
                                </div>
                                {this.state.croppedImage != null ? <img src={ChangeImage} height="30" width="30" onClick={() => this.borrarImagen()} style={{ cursor: 'pointer', float: 'right' }}></img> : null}
                            </div>

                        }
                    </div>
                    <div className="col-md-9 col-sm-12 ">
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Placa *</label>
                                <input autocomplete="off" id="numordencompra" disabled={modoVista} value={this.state.nombreGuardar} onChange={this.handleChange('nombreGuardar')} className="form-control" type="text" />
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Marca *</label>
                                <input autocomplete="off" id="numordencompra" disabled={modoVista} className="form-control" type="text" value={this.state.apellidoGuardar} onChange={this.handleChange('apellidoGuardar')} />
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Linea *</label>
                                <input autocomplete="off" id="numordencompra" disabled={modoVista} className="form-control" type="text" value={this.state.apellidoGuardar} onChange={this.handleChange('apellidoGuardar')} />
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Modelo *</label>
                                <input autocomplete="off" id="numordencompra" disabled={modoVista} className="form-control" type="number" min="1" value={this.state.apellidoGuardar} onChange={this.handleChange('apellidoGuardar')} />
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-4 col-lg-4 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Tipo vehiculo *</label>
                                {modoVista ?
                                    <input autocomplete="off" id="numordencompra" disabled={modoVista} className="form-control" type="text" value={persona.tipo_identificacion.descripcion} />
                                    :
                                    <Selects
                                        isDisabled={modoVista}
                                        value={this.state.tipo_identificacion_Seleccionada}
                                        onChange={this.handleChanges('tipo_identificacion_Seleccionada')}
                                        options={this.state.identificacionesbd}
                                    />
                                }
                            </div>
                            <div className="col-md-2 col-lg-2 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Cilindrada *</label>
                                <input autocomplete="off" id="numordencompra" disabled={modoVista} className="form-control" type="number" min="1" value={this.state.identificacionGuardar} onChange={this.handleChange('identificacionGuardar')} />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <div className="row" >
                                    <label htmlFor="nombreprov" className="col-form-label mx-auto">COLOR</label>
                                </div>
                                <div className="row" >
                                    <div className="rounded-circle mx-auto" style={{ cursor: 'pointer', height: '40px', width: '40px', backgroundColor: this.state.background1 }} onClick={() => this.handledialogColor(1)}></div>
                                </div>
                                <Dialog
                                    open={this.state.dialogColor1}
                                    onClose={() => this.handledialogColor(1)}
                                    aria-labelledby="form-dialog-title"
                                    maxWidth={'md'}
                                    style={{ padding: '0' }}
                                >
                                    <DialogContent style={{ padding: '0' }}>
                                        <DialogColor color={this.state.background1} />
                                    </DialogContent>

                                </Dialog>
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Tipo servicio *</label>
                                {modoVista ?
                                    <input autocomplete="off" id="numordencompra" disabled={modoVista} className="form-control" type="text" value={persona.tipo_identificacion.descripcion} />
                                    :
                                    <Selects
                                        isDisabled={modoVista}
                                        value={this.state.tipo_identificacion_Seleccionada}
                                        onChange={this.handleChanges('tipo_identificacion_Seleccionada')}
                                        options={this.state.identificacionesbd}
                                    />
                                }
                            </div>
                        </div>
                    </div>


                </div>
                <div className="row mx-auto" style={{ marginTop: '2%', background: "$c3c3c3" }}>
                    <div className="col-md-6 col-sm-12 mx-auto" style={{ borderRight: '3px solid #c3c3c3' }}>
                        <div className="row">
                            <div className="col" >
                                <h5 className="mx-auto" style={{ marginBottom: 0 }}><b>Datos del vehiculo</b></h5>
                            </div>
                        </div>
                        {
                            modoVista
                                ?
                                <div className="row">

                                    <div className="col col-md-12">
                                        <label htmlFor="numordencompra" className="col-form-label">Empresa </label>
                                        <input autocomplete="off" id="numordencompra" disabled={modoVista} value={persona.empresa.razon_social} className="form-control" type="text" />
                                    </div>
                                    <div className="col col-md-6">
                                        <label htmlFor="numordencompra" className="col-form-label">Usuario</label>
                                        <input autocomplete="off" id="numordencompra" disabled={modoVista} value={this.state.usuarion} className="form-control" type="text" />
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <label htmlFor="numordencompra" className="col-form-label">Pin *</label>
                                        <input autocomplete="off" id="numordencompra" disabled={modoVista} value={this.state.pinGuardar} maxLength="4" onChange={this.handleChange('pinGuardar')} className="form-control" type="number" min="1" />
                                    </div>
                                </div>
                                :
                                userEdit != null
                                    ?
                                    <div className="row" style={{ marginBottom: '15px' }}>
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="numordencompra" className="col-form-label">Empresa *</label>
                                            <Selects
                                                value={this.state.empresaSeleccionada}
                                                onChange={this.handleChanges('empresaSeleccionada')}
                                                options={this.state.empresasbd}
                                                isDisabled={modoVista}
                                            />
                                        </div>

                                        <div className="col-md-5">
                                            <label htmlFor="numordencompra" className="col-form-label">Usuario</label>
                                            <input autocomplete="off" id="numordencompra" disabled={modoVista} value={this.state.usuarion} onChange={this.handleChange('usuarion')} className="form-control" type="text" />
                                        </div>
                                        <Dialog
                                            open={this.state.ventanaContra}
                                            onClose={() => this.handleFormulario()}
                                            aria-labelledby="form-dialog-title"
                                            maxWidth={'md'}
                                            fullWidth={true}

                                            style={{ padding: '0' }}
                                            disableBackdropClick={false}
                                        >
                                            <DialogContent style={{ padding: '30px' }}>
                                                <FormularioContraseña usuario={userEdit} />
                                            </DialogContent>
                                        </Dialog>
                                        <div className="col-lg-1 col-md-1 col-sm-2 bottom-aligned " style={{ padding: 0 }}>
                                            <button className="btn btn-primary" onClick={() => this.handleFormulario()}>
                                                <i class="material-icons" style={{ fontSize: '14px' }}>
                                                    vpn_key
                                        </i>
                                            </button>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <label htmlFor="numordencompra" className="col-form-label">Pin *</label>
                                            <input autocomplete="off" id="numordencompra" disabled={modoVista} value={this.state.pinGuardar} maxLength="4" onChange={this.handleChange('pinGuardar')} className="form-control" type="number" min="1" />
                                        </div>

                                    </div>
                                    :
                                    <div className="row" style={{ marginBottom: '15px' }}>
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="numordencompra" className="col-form-label">Empresas *</label>
                                            <Selects
                                                value={this.state.empresaSeleccionada}
                                                onChange={this.handleChanges('empresaSeleccionada')}
                                                options={this.state.empresasbd}
                                                isDisabled={modoVista}
                                            />
                                        </div>

                                    </div>
                        }
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Combustible *</label>
                                {modoVista ?
                                    <input autocomplete="off" id="numordencompra" disabled={modoVista} className="form-control" type="text" value={persona.tipo_identificacion.descripcion} />
                                    :
                                    <Selects
                                        isDisabled={modoVista}
                                        value={this.state.tipo_identificacion_Seleccionada}
                                        onChange={this.handleChanges('tipo_identificacion_Seleccionada')}
                                        options={this.state.identificacionesbd}
                                    />
                                }
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Cap. Maxima combustible *</label>
                                <input autocomplete="off" id="numordencompra" disabled={modoVista} className="form-control" type="number" min="1" value={this.state.identificacionGuardar} onChange={this.handleChange('identificacionGuardar')} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" >
                                <h5 className="mx-auto" style={{ marginBottom: 0 }}><b>Identificadores</b></h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Tag *</label>
                                <input autocomplete="off" id="numordencompra" disabled={modoVista} value={this.state.nombreGuardar} onChange={this.handleChange('nombreGuardar')} className="form-control" type="text" />
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">IDRUM *</label>
                                <input autocomplete="off" id="numordencompra" disabled={modoVista} value={this.state.nombreGuardar} onChange={this.handleChange('nombreGuardar')} className="form-control" type="text" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 ">
                        <div className="row">
                            <div className="col" >
                                <h5 className="mx-auto" style={{ marginBottom: 0 }}><b>Otros datos</b></h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Documento conductor</label>
                                <input autocomplete="off" id="numordencompra" disabled={modoVista} value={this.state.nombreGuardar} onChange={this.handleChange('nombreGuardar')} className="form-control" type="text" />
                            </div>
                            <div className="col-md-8 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Nombre conductor</label>
                                <input autocomplete="off" id="numordencompra" disabled={modoVista} value={this.state.nombreGuardar} onChange={this.handleChange('nombreGuardar')} className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-6 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Nombre aseguradora SOAT</label>
                                <input autocomplete="off" id="numordencompra" disabled={modoVista} value={this.state.nombreGuardar} onChange={this.handleChange('nombreGuardar')} className="form-control" type="text" />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="numordencompra" className="col-form-label">Fecha de expedicion del SOAT</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                    <DatePicker
                                        format="yyyy-MM-dd"
                                        id="datetime"
                                        value={this.state.selectedDate}
                                        onChange={this.handleChangemoment}
                                        className="rounded form-control"
                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="numordencompra" className="col-form-label">Desde las 00:00 del</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                    <DatePicker
                                        format="yyyy-MM-dd"
                                        id="datetime"
                                        value={this.state.selectedDate}
                                        onChange={this.handleChangemoment}
                                        className="rounded form-control"
                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="numordencompra" className="col-form-label">Hasta las 24:00 del</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                    <DatePicker
                                        format="yyyy-MM-dd"
                                        id="datetime"
                                        value={this.state.selectedDate}
                                        onChange={this.handleChangemoment}
                                        className="rounded form-control"
                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>

                    </div>
                </div>
                <Dialog
                    open={this.state.dialogphoto}
                    //onClose={this.handledialog('foto')}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'sm'}
                    fullWidth={true}
                    disableBackdropClick={true}
                >
                    <DialogTitle id="form-dialog-title">Recortar la foto</DialogTitle>
                    <DialogContent >
                        <div className="row mx-auto">
                            <div className="col col-md-12" style={{ height: "150px" }}>
                                <Cropper
                                    image={this.state.imagePreviewUrl}
                                    crop={this.state.crop}
                                    zoom={this.state.zoom}
                                    aspect={this.state.aspect}
                                    onCropChange={this.onCropChange}
                                    onCropComplete={this.onCropComplete}
                                    onZoomChange={this.onZoomChange}
                                />
                            </div>
                        </div>
                        <div className="row mx-auto">
                            <div className="col col-md-12" style={{ height: "100%", margin: "15px" }}>
                                <Slider
                                    value={this.state.zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e, zoom) => this.onZoomChange(zoom)}
                                    classes={{ container: 'slider' }}
                                />
                            </div>
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-primary">
                            Cancelar
                                                        </button>
                        <button onClick={this.showCroppedImage} className="btn btn-primary">
                            Agregar foto
                                                        </button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.dialogTelefono}
                    onClose={this.handledialogTelefono}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'sm'}
                    style={{ padding: '0' }}
                >
                    <DialogContent style={{ padding: '0' }}>
                        <DialogTelefono />
                    </DialogContent>

                </Dialog>
                <Dialog
                    open={this.state.dialogCorreo}
                    onClose={this.handledialogCorreo}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'sm'}
                    style={{ padding: '0' }}
                >
                    <DialogContent style={{ padding: '0' }}>
                        <DialogCorreo correos={this.state.corrreos} />
                    </DialogContent>

                </Dialog>
            </div>
        )
    }
}


export default FormularioPersona
