import React from 'react';
import Axios from 'axios'
import Avatar from '@material-ui/core/Avatar';
import Server from '../../lib/server'
import store from '../../lib/store'
import Paper from '@material-ui/core/Paper';
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide"
import Ojoicon from "@material-ui/icons/RemoveRedEye"
import DeleteIcon from '@material-ui/icons/Delete';
import EditICon from '@material-ui/icons/Edit';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Loader from '../../components/Loader'
import moment from 'moment'
import swal from 'sweetalert'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from "@material-ui/core";
import Selects from 'react-select';
import { MuiPickersUtilsProvider, DateTimePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
const idModulo = 29
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
var permisos = ''
class Entradas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actualizarDatos: false,
      auditoriabd: [],
      auditoriaSeleccionada: {},
      camposAnterior: [],
      valueAnterior: [],
      camposActual: [],
      valueActual: [],
      jsonAnterior: {},
      jsonActual: {},
      tipo: 0,
      ventamnaDetalles: false,
      loader: true,
      //nUEVA VISTA
      expanded: true,
      modulosbd: [{
        value: 4,
        label: "KIOSCOS (4)"
      }],
      moduloSeleccionado: [],
      responsablesbd: [],
      responsableSeleccionado: [],
      kioscosbd: [],
      kioscosSeleccionado: [],
      fechaInicio: moment(new Date()).subtract("day", 7).format("YYYY-MM-DD ") + "00:00:00",
      fechaFin: new Date(),
      limitesbd: [
        {
          value: 100,
          label: 100
        },
        {
          value: 250,
          label: 250
        },
        {
          value: 500,
          label: 500
        },
        {
          value: 1000,
          label: 1000
        },
      ],
      limiteSeleccionado: {
        value: 100,
        label: 100
      },
      busquedaUsuario: false,
      multiEmpresas: "",
      multiUsuarios: "",
      multiModulos: "",
      auditoriasbd: []
    }


  }

  //
  expandir = () => {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  handleChanges = name => value => {
    this.setState({
      [name]: value,
    });
    switch (name) {
      case "kioscosSeleccionado":
        var empresas = ""
        value.map((empresa, key) => {
          if (key === 0) {
            empresas += empresa.value
          } else {
            empresas += "," + empresa.value
          }
        })
        this.setState({
          multiEmpresas: empresas,
          busquedaUsuario: true
        })
        if (empresas === "") {
          this.setState({
            responsablesbd: [],
            busquedaUsuario: false
          })
        } else {
          Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/persona/empresas/' + empresas, { headers: { Authorization: ntoken } })
            .then(response => {
              if (response.status == 200) {
                var datos = response.data.rows
                var users = []
                datos.map((usuario) => {
                  users.push({
                    value: usuario.persona.id,
                    label: usuario.persona.nombres + " " + usuario.persona.apellidos + " - " + usuario.persona.identificacion
                  })
                })
              } else if (response.status == 205) {
                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                setTimeout(function () { window.location.href = '/'; }, 3000);
              }
              this.setState({
                responsablesbd: users,
                busquedaUsuario: false
              })
            })
            .catch((err) => {
              this.setState({
                responsablesbd: [],
                busquedaUsuario: false
              })
            })
        }
        break;
      case "responsableSeleccionado":
        var usuarios = ""
        value.map((empresa, key) => {
          if (key === 0) {
            usuarios += empresa.value
          } else {
            usuarios += "," + empresa.value
          }
        })
        this.setState({
          multiUsuarios: usuarios
        })
        break;
      case "moduloSeleccionado":
        var usuarios = ""
        value.map((empresa, key) => {
          if (key === 0) {
            usuarios += empresa.value
          } else {
            usuarios += "," + empresa.value
          }
        })
        this.setState({
          multiModulos: usuarios
        })
        break;
    }
  }

  handleChangemoment = date => {
    this.setState({ fechaInicio: date });
  }
  handleChangemomentFinal = date => {
    this.setState({ fechaFin: date });
  }

  busquedaFiltros = () => {
    this.setState({
      loader: true
    })
    var hijos = store.getState().empresasHijos
    var filtros = {
      "accion": "reporte_auditoria",
      "empresas_id": this.state.multiEmpresas === "" ? hijos : this.state.multiEmpresas,
      "responsables_id": this.state.multiUsuarios,
      "modulos_id": this.state.multiModulos,
      "limit": this.state.limiteSeleccionado.value,
      "fecha_inicial": moment(new Date(this.state.fechaInicio.toString())).format("YYYY-MM-DD hh:mm:ss"),
      "fecha_final": moment(new Date(this.state.fechaFin.toString())).format("YYYY-MM-DD hh:mm:ss"),
    }
    console.log(filtros)
    Axios.post(Server.hostPrueba + Server.puertoEmpresas + '/api/reportes', filtros, { headers: { Authorization: ntoken } })
      .then(response => {
        console.log(response)
        var auditorias = []
        if (response.status === 200) {

          if (response.data.data != null) {
            response.data.data.map((aud) => {
              auditorias.push({
                'fecha': moment(aud.fecha).format("DD/MM/YYYY hh:mm a"),
                'modulo': aud.entidad,
                'accion': aud.accion,
                'kiosco': aud.alias,
                'responsable': aud.responsable_nombres,
                'cambios': aud.cambios,
              })
            })
            this.setState({
              auditoriasbd: auditorias,
              loader: false
            })
          }
        } else if (response.status == 205) {
          swal("ERROR", "LA SESION HA CADUCADO", "warning")
          setTimeout(function () { window.location.href = '/'; }, 3000);

        } else {
          swal("Error", "Por favor verifique los datos", "warning");
          this.setState({
            loader: false
          })
        }
      })
  }

  obtenerDatos = () => {
    //Permisos usuarios
    var modulos = store.getState().perfilesUSuario
    modulos.map((modulo, key) => {
      if (modulo.modulos_id == idModulo) {
        permisos = modulo.acciones
      }
    })
    var hijos = store.getState().empresasHijos
    this.setState({ actualizarDatos: false })
    var usuario = store.getState().usuario
    //Obtener las tiendas
    Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/5/padre/' + hijos, { headers: { Authorization: ntoken } })
      .then(responsee => {
        var tiendas = []
        if (responsee.status == 200) {
          responsee.data.empresas.map((dato, key) => (
            tiendas.push({
              value: dato.id,
              label: dato.alias
            })
          ))
        } else if (responsee.status == 205) {
          swal("ERROR", "LA SESION HA CADUCADO", "warning")
          setTimeout(function () { window.location.href = '/'; }, 5000);
        }
        this.setState({ kioscosbd: tiendas })
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })

    switch (usuario.tipo) {
      case "1":
      case "2":
      case "3":
      case "4":
        var filtros = {
          "accion": "reporte_auditoria",
          "empresas_id": this.state.multiEmpresas === "" ? hijos : this.state.multiEmpresas,
          "responsables_id": this.state.multiUsuarios,
          "modulos_id": this.state.multiModulos,
          "limit": this.state.limiteSeleccionado.value,
          "fecha_inicial": moment(new Date(this.state.fechaInicio.toString())).format("YYYY-MM-DD hh:mm:ss"),
          "fecha_final": moment(new Date(this.state.fechaFin.toString())).format("YYYY-MM-DD hh:mm:ss"),
        }
        Axios.post(Server.hostPrueba + Server.puertoEmpresas + '/api/reportes', filtros, { headers: { Authorization: ntoken } })
          .then(response => {
            console.log(response)
            var auditorias = []
            if (response.status === 200) {

              if (response.data.data != null) {
                response.data.data.map((aud) => {
                  auditorias.push({
                    'fecha': moment(aud.fecha).format("DD/MM/YYYY hh:mm a"),
                    'modulo': aud.entidad,
                    'accion': aud.accion,
                    'kiosco': aud.alias,
                    'responsable': aud.responsable_nombres,
                    'cambios': aud.cambios,
                  })
                })
                this.setState({
                  auditoriasbd: auditorias,
                  loader: false
                })
              }
            } else if (response.status == 205) {
              swal("ERROR", "LA SESION HA CADUCADO", "warning")
              setTimeout(function () { window.location.href = '/'; }, 3000);
            } else {
              swal("Error", "Por favor verifique los datos", "warning");
            }
          })
        break;
      case "5":
        var filtros = {
          "accion": "reporte_auditoria",
          "empresas_id": hijos,
          "responsables_id": this.state.multiUsuarios,
          "modulos_id": this.state.multiModulos,
          "limit": this.state.limiteSeleccionado.value,
          "fecha_inicial": moment(new Date(this.state.fechaInicio.toString())).format("YYYY-MM-DD hh:mm:ss"),
          "fecha_final": moment(new Date(this.state.fechaFin.toString())).format("YYYY-MM-DD hh:mm:ss"),
        }
        Axios.post(Server.hostPrueba + Server.puertoEmpresas + '/api/reportes', filtros, { headers: { Authorization: ntoken } })
          .then(response => {
            console.log(response)
            var auditorias = []
            if (response.status === 200) {
              if (response.data.data != null) {
                response.data.data.map((aud) => {
                  auditorias.push({
                    'fecha': moment(aud.fecha).format("DD/MM/YYYY hh:mm a"),
                    'modulo': aud.entidad,
                    'accion': aud.accion,
                    'kiosco': aud.alias,
                    'responsable': aud.responsable_nombres,
                    'cambios': aud.cambios,
                  })
                })
                this.setState({
                  auditoriasbd: auditorias,
                  loader: false
                })
              }
            } else if (response.status == 205) {
              swal("ERROR", "LA SESION HA CADUCADO", "warning")
              setTimeout(function () { window.location.href = '/'; }, 3000);
            } else {
              swal("Error", "Por favor verifique los datos", "warning");
            }
          })
        break;
    }


  }

  detallesAuditoria = (id) => {
    Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/auditoria/detalles/' + id, { headers: { Authorization: ntoken } })
      .then(responsee => {
        if (responsee.data.data != null) {
          var auditoriaSeleciconada = responsee.data.data[0]
          var actual = {}
          var anterior = {}
          var tipo = auditoriaSeleciconada.tipo_accion
          if (auditoriaSeleciconada.actual != null) {
            actual = JSON.parse(auditoriaSeleciconada.actual)
          }
          if (auditoriaSeleciconada.anterior != null) {
            anterior = JSON.parse(auditoriaSeleciconada.anterior)
          }
          this.setState({ auditoriaSeleccionada: auditoriaSeleciconada, jsonActual: actual, jsonAnterior: anterior, tipo: tipo, ventamnaDetalles: true, camposAnterior: Object.keys(anterior), valueAnterior: Object.values(anterior), camposActual: Object.keys(actual), valueActual: Object.values(actual) })

        }
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })

  }
  cancelar = () => {
    this.setState({ ventamnaDetalles: false })
  }

  componentWillMount() {
    this.obtenerDatos()
  }

  render() {
    var data = {
      columns: [
        {
          label: 'Fecha',
          field: 'fecha',
          sort: 'asc'
        }, {
          label: 'Modulo',
          field: 'modulo',
          sort: 'asc'
        }, {
          label: 'Accion',
          field: 'accion',
          sort: 'asc'
        }, {
          label: 'Kiosco',
          field: 'kiosco',
          sort: 'asc'
        }, {
          label: 'Responsable',
          field: 'responsable',
          sort: 'asc'
        }, {
          label: 'Cambios',
          field: 'cambios',
          sort: 'asc'
        }
      ], rows: this.state.auditoriasbd


    }
    var fecha = new Date()
    var fechan = fecha.toISOString()
    var n = moment(fechan).format('YYYY-MM-DD hh:mm')
    //console.log(n)
    return (
      <div>
        <div className="row" style={{ marginTop: "15px" }}>
          <ExpansionPanel expanded={this.state.expanded} className="col-md-12" style={{ zIndex: 1098 }} onChange={() => this.expandir()}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography >FILTROS</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <div className="row mx-auto">
                  <div className="col-md-4" >
                    <label htmlFor="nombreprov" className="col-form-label">KIOSCO</label>
                    <Selects
                      placeholder="Seleccione o escriba"
                      value={this.state.kioscosSeleccionado}
                      onChange={this.handleChanges('kioscosSeleccionado')}
                      options={this.state.kioscosbd}
                      isMulti
                    />
                  </div>
                  <div className="col-md-5" >
                    <label htmlFor="nombreprov" className="col-form-label">RESPONSABLE</label>
                    <Selects
                      placeholder="Seleccione o escriba"
                      value={this.state.responsableSeleccionado}
                      onChange={this.handleChanges('responsableSeleccionado')}
                      options={this.state.responsablesbd}
                      isMulti
                      isLoading={this.state.busquedaUsuario}
                    />
                  </div>
                  <div className="col-md-3" >
                    <label htmlFor="nombreprov" className="col-form-label">MODULO</label>
                    <Selects
                      placeholder="Seleccione o escriba"
                      value={this.state.moduloSeleccionado}
                      onChange={this.handleChanges('moduloSeleccionado')}
                      options={this.state.modulosbd}
                      isMulti
                    />
                  </div>

                </div>
                <div className="row mx-auto">
                  <div className="col-md-3" >
                    <label htmlFor="numordencompra" className="col-form-label">FECHA DE INICIO</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                      <DateTimePicker
                        format="yyyy-MM-dd hh:mm a"
                        id="datetime"
                        value={this.state.fechaInicio}
                        onChange={this.handleChangemoment}
                        ampm={false}
                        disableFuture={true}
                        className="rounded form-control"
                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="col-md-3" >
                    <label htmlFor="nombreprov" className="col-form-label">FECHA FIN</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                      <DateTimePicker
                        format="yyyy-MM-dd hh:mm a"
                        id="datetime"
                        value={this.state.fechaFin}
                        onChange={this.handleChangemomentFinal}
                        ampm={false}
                        className="rounded form-control"
                        disableFuture={true}
                        minDate={moment(new Date(this.state.fechaInicio.toString())).format("YYYY-MM-DD HH:mm")}
                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="col-md-3" >
                    <label htmlFor="nombreprov" className="col-form-label">LIMITE</label>
                    <Selects
                      placeholder="Seleccione o escriba"
                      value={this.state.limiteSeleccionado}
                      onChange={this.handleChanges('limiteSeleccionado')}
                      options={this.state.limitesbd}
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 bottom-aligned" >
                    <button className="btn btn-success btn-block" onClick={() => this.busquedaFiltros()}>CONSULTAR</button>
                  </div>
                </div>


              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        <div className="row" style={{ marginTop: "15px" }}>
          <div className="col col-md-12" style={{ padding: 0 }}>
            <Paper style={{ padding: '5px' }}>

              {this.state.loader ? <Loader /> : <MDBDataTable
                //striped
                className="col-md-12"
                searchLabel="Buscar"
                paginationLabel={["Anterior", "Siguente"]}
                infoLabel={["Mostrando", "a", "de", "entradas"]}
                entriesLabel="Filas a mostrar"
                tbodyColor="primary-color"
                theadColor="#FF8800"
                exportToCSV={true}
                striped
                bordered
                autoWidth={false}
                responsive={true}
                hover
                data={data}
                style={{ fontSize: '13px' }}
              />}
              <Dialog
                open={this.state.ventamnaDetalles}
                onClose={() => this.cancelar()}
                aria-labelledby="form-dialog-title"
                maxWidth={'lg'}
                fullWidth={true}

                style={{ padding: '0' }}
                disableBackdropClick={false}
              >
                <DialogContent style={{ padding: '0' }}>
                  <div className="row mx-auto" style={{ marginTop: '10px', marginBottom: '10px', height: '100%' }}>
                    <div id="prueba" className="col col-md-11 mx-auto" style={{ padding: 0 }}>
                      <Paper style={{ padding: '10px' }}>
                        <div className="row mx-auto">
                          <h5 >{this.state.auditoriaSeleccionada.accion}</h5>
                        </div>
                        <div className="row mx-auto">
                          <h6>{"TABLA: " + this.state.auditoriaSeleccionada.entidad}</h6>
                        </div>
                        <div className="row mx-auto">
                          <h6>{"RESPONSABLE: " + this.state.auditoriaSeleccionada.responsable}</h6>
                        </div>
                        {this.state.tipo == 2
                          ?
                          <div className="row">
                            <div className="col-md-6">
                              <MDBTable bordered striped>
                                <MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
                                  <tr>
                                    <th colSpan="2" align="center">DATOS ANTERIORES</th>
                                  </tr>
                                  <tr>
                                    <th>CAMPO</th>
                                    <th>VALOR</th>

                                  </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                  {this.state.camposAnterior.map((campo, key) => (
                                    <tr key={key} style={this.state.valueActual[key] != this.state.valueAnterior[key] ? { backgroundColor: 'yellow' } : {}}>
                                      <td>{campo}</td>
                                      <td>{campo == 'create_date' || campo == 'update_date' && this.state.valueAnterior[key] != null ? moment(this.state.valueAnterior[key]).format('DD-MM-YYYY hh:mm:ss') : this.state.valueAnterior[key]}</td>
                                    </tr>
                                  ))}

                                </MDBTableBody>
                              </MDBTable>
                            </div>
                            <div className="col-md-6">
                              <MDBTable bordered striped>
                                <MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
                                  <tr>
                                    <th colSpan="2" align="center">DATOS ACTUALES</th>
                                  </tr>
                                  <tr>
                                    <th>CAMPO</th>
                                    <th>VALOR</th>

                                  </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                  {this.state.camposActual.map((campo, key) => (
                                    <tr key={key} style={this.state.valueActual[key] != this.state.valueAnterior[key] ? { backgroundColor: 'orange' } : {}}>
                                      <td>{campo}</td>
                                      <td>{campo == 'create_date' || campo == 'update_date' && this.state.valueActual[key] != null ? moment(this.state.valueActual[key]).format('DD-MM-YYYY hh:mm:ss') : this.state.valueActual[key]}</td>
                                    </tr>
                                  ))}

                                </MDBTableBody>
                              </MDBTable>
                            </div>
                          </div>
                          :
                          <MDBTable bordered striped>
                            <MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
                              <tr>
                                <th>CAMPO</th>
                                <th>VALOR</th>

                              </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                              {this.state.camposActual.map((campo, key) => (
                                <tr key={key}>
                                  <td>{campo}</td>
                                  <td>{campo == 'create_date' || campo == 'update_date' && this.state.valueActual[key] != null ? moment(this.state.valueActual[key]).format('DD-MM-YYYY hh:mm:ss') : this.state.valueActual[key]}</td>
                                </tr>
                              ))}

                            </MDBTableBody>
                          </MDBTable>}

                      </Paper>
                    </div>
                  </div>
                  <div className="row mx-auto">
                    <div className="col-md-8 bottom-aligned mx-auto"></div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <button className="btn btn-danger " onClick={() => this.cancelar()} style={{ margin: '10px', float: 'right' }}>Cerrar</button>
                </DialogActions>
              </Dialog>
            </Paper>
          </div>
        </div>

      </div>
    )
  }
}
export default Entradas