import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { MDBDataTable } from 'mdbreact';

import GridItem from "components/Grid/GridItem.jsx";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide"
import Axios from 'axios'
import FormularioProveedor from '../../../components/Formularios/FormularioProvedores'
import Server from '../../../lib/server'
import store from '../../../lib/store'
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import EditICon from '@material-ui/icons/Edit';
import Ojoicon from "@material-ui/icons/RemoveRedEye"
import ActivarIcon from "@material-ui/icons/Check"
import { Avatar } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import ListIcon from '@material-ui/icons/List';
import swal from 'sweetalert'
import moment from 'moment';
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
const B = (props) => <p style={{ fontWeight: 'bold', marginBottom: "0" }}>{props.children}</p>
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const idModulo = 9
var permisos = ''
class Proveedores extends Component {
    constructor() {
        super();
        this.state = {
            createbodega: false,
            proveedoresbd: [],
            proveedoresTotal: [],
            proveedoresfiltro: [],
            actualizarDatos: false,
            value: 0

        }
        store.subscribe(() => {
            console.log(store.getState().actualizarvista)
            this.setState({
                createbodega: store.getState().creacionUsuario,
                actualizarDatos: store.getState().actualizarvista,
            })
        })

    }

    decirAlgo = () => {
        console.log("algooo")
    }

    crearbodega = () => {
        store.dispatch({
            type: "CREACION_USUARIO",
            creacionUsuario: !this.state.createbodega,
            usuarioEdicion: null,
            modovista: false
        })
    }

    editarUsuario = (usuario) => {
        console.log(usuario)
        store.dispatch({
            type: "CREACION_USUARIO",
            creacionUsuario: !this.state.createbodega,
            usuarioEdicion: usuario,
            modovista: false
        })
    }
    detallesUsuario = (usuario) => {
        store.dispatch({
            type: "CREACION_USUARIO",
            creacionUsuario: !this.state.createbodega,
            usuarioEdicion: usuario,
            modovista: true
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        console.log("aquii")
        if (this.state.actualizarDatos) {
            this.obtenerDatos()
            return true
        }
        return true;
    }
    inactivarUsuario = (id) => {
        var usuario = store.getState().usuario
        var datos = {
            "id": id,
            "entidad_nombre": "personas",
            "estado": "I",
            "personas_id": usuario.p_id,
            "empresas_id": usuario.empresas_id
        }
        console.log(datos)
        swal({
            title: "Estas seguro de que quieres deseas inactivar este proveedor?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/estados/', datos, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 201) {
                                swal({
                                    title: "Proveedor inactivado correctamente",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            store.dispatch({
                                                type: "ACTUALIZACION_DATOS",
                                                actualizarvista: true
                                            })

                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            } else {
                                swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }
    ActivarUsuario = (id) => {
        var usuario = store.getState().usuario
        var datos = {
            "id": id,
            "entidad_nombre": "personas",
            "estado": "A",
            "personas_id": usuario.p_id,
            "empresas_id": usuario.empresas_id
        }
        console.log(datos)
        Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/estados/', datos, { headers: { Authorization: ntoken } })
            .then((res) => {
                console.log(res)
                if (res.status == 201) {
                    swal({
                        title: "Proveedor activado correctamente",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })

                                break;
                            default:

                        }
                    });
                } else if (res.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                }
            })
    }

    changetab = (newTab) => {
        console.log("Aqui")
        this.setState({ value: newTab })
        var prove = []
        var dominio = store.getState().dominio
        const imagen = Server.hostMultimedia + dominio + "/persona/default.png"
        switch (newTab) {
            case 0:
                this.state.proveedoresTotal.map((proveedores, key) => {
                    var imagenUSer = Server.hostMultimedia + dominio + "/persona/" + (proveedores.persona.url_foto == null || (proveedores.persona.url_foto == 'N') ? 'default.png' : proveedores.persona.id + '.png')+"?"+moment(new Date()).format("YYYYMMMDDHHmmss")
                    prove.push({
                        'imagen': (proveedores.persona.url_foto != null && proveedores.persona.url_foto != 'N'
                            ?
                            <Avatar alt={''} onClick={() => this.detallesUsuario(proveedores)} onEronError={(e) => { e.target.onerror = null; e.target.src = imagen }} title={''} src={imagenUSer} style={{ margin: 10, width: 40, height: 40, background: '#ffffff', cursor: 'pointer' }} />
                            :
                            <Avatar alt={''} onClick={() => this.detallesUsuario(proveedores)} title={''} src={imagen} style={{ margin: 10, width: 40, height: 40, background: '#ffffff', cursor: 'pointer' }} />),
                        'cedula': proveedores.persona.identificacion,
                        'combre': proveedores.persona.nombres + " " + proveedores.persona.apellidos,
                        'telefono': proveedores.contacto != null ? proveedores.contacto.map((contacto) => {
                            if (contacto.tipo == 2 && contacto.principal == 'S') {
                                return (contacto.contacto)
                            }
                        }) :'',
                        'Correo': proveedores.contacto != null ?proveedores.contacto.map((contacto) => {
                            if (contacto.tipo == 1 && contacto.principal == 'S') {
                                return (contacto.contacto)
                            }
                        }):null,
                        'Direccion':proveedores.contacto != null ?proveedores.contacto.map((contacto) => {
                            if (contacto.tipo == 3 && contacto.principal == 'S') {
                                return (contacto.contacto)
                            }
                        }):null,
                        "acciones": [
                            <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesUsuario(proveedores)}><Ojoicon /></button>,
                            permisos.includes('U') ? <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.editarUsuario(proveedores)}><EditICon /></button> : null,
                            permisos.includes('D') ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.inactivarUsuario(proveedores.persona.id)}><DeleteIcon /></button> : null
                        ]
                    })
                })
                break
            case 1:
                this.state.proveedoresTotal.map((proveedores, key) => {
                    var imagenUSer = Server.hostMultimedia + dominio + "/persona/" + (proveedores.persona.url_foto == null || (proveedores.persona.url_foto == 'N') ? 'default.png' : proveedores.persona.id + '.png')
                    if (proveedores.persona.estado === "A") {
                        prove.push({
                            'imagen': (proveedores.persona.url_foto != null && proveedores.persona.url_foto != 'N'
                                ?
                                <Avatar alt={''} onClick={() => this.detallesUsuario(proveedores)} onEronError={(e) => { e.target.onerror = null; e.target.src = imagen }} title={''} src={imagenUSer} style={{ margin: 10, width: 40, height: 40, background: '#ffffff', cursor: 'pointer' }} />
                                :
                                <Avatar alt={''} onClick={() => this.detallesUsuario(proveedores)} title={''} src={imagen} style={{ margin: 10, width: 40, height: 40, background: '#ffffff', cursor: 'pointer' }} />),
                            'cedula': proveedores.persona.identificacion,
                            'combre': proveedores.persona.nombres + " " + proveedores.persona.apellidos,
                            'telefono':proveedores.contacto != null ? proveedores.contacto.map((contacto) => {
                                if (contacto.tipo == 2 && contacto.principal == 'S') {
                                    return (contacto.contacto)
                                }
                            }):null,
                            'Correo':proveedores.contacto != null ? proveedores.contacto.map((contacto) => {
                                if (contacto.tipo == 1 && contacto.principal == 'S') {
                                    return (contacto.contacto)
                                }
                            }):null,
                            'Direccion':proveedores.contacto != null ? proveedores.contacto.map((contacto) => {
                                if (contacto.tipo == 3 && contacto.principal == 'S') {
                                    return (contacto.contacto)
                                }
                            }):null,
                            "acciones": [
                                <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesUsuario(proveedores)}><Ojoicon /></button>,
                                permisos.includes('U') ? <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.editarUsuario(proveedores)}><EditICon /></button> : null,
                                permisos.includes('D') ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.inactivarUsuario(proveedores.persona.id)}><DeleteIcon /></button> : null
                            ]
                        })
                    }
                })
                break
            case 2:
                this.state.proveedoresTotal.map((proveedores, key) => {
                    var imagenUSer = Server.hostMultimedia + dominio + "/persona/" + (proveedores.persona.url_foto == null || (proveedores.persona.url_foto == 'N') ? 'default.png' : proveedores.persona.id + '.png')
                    if (proveedores.persona.estado === "I") {
                        prove.push({
                            'imagen': (proveedores.persona.url_foto != null && proveedores.persona.url_foto != 'N'
                                ?
                                <Avatar alt={''} onClick={() => this.detallesUsuario(proveedores)} onEronError={(e) => { e.target.onerror = null; e.target.src = imagen }} title={''} src={imagenUSer} style={{ margin: 10, width: 40, height: 40, background: '#ffffff', cursor: 'pointer' }} />
                                :
                                <Avatar alt={''} onClick={() => this.detallesUsuario(proveedores)} title={''} src={imagen} style={{ margin: 10, width: 40, height: 40, background: '#ffffff', cursor: 'pointer' }} />),
                            'cedula': proveedores.persona.identificacion,
                            'combre': proveedores.persona.nombres + " " + proveedores.persona.apellidos,
                            'telefono':proveedores.contacto != null ? proveedores.contacto.map((contacto) => {
                                if (contacto.tipo == 2 && contacto.principal == 'S') {
                                    return (contacto.contacto)
                                }
                            }):null,
                            'Correo':proveedores.contacto != null ? proveedores.contacto.map((contacto) => {
                                if (contacto.tipo == 1 && contacto.principal == 'S') {
                                    return (contacto.contacto)
                                }
                            }):null,
                            'Direccion':proveedores.contacto != null ? proveedores.contacto.map((contacto) => {
                                if (contacto.tipo == 3 && contacto.principal == 'S') {
                                    return (contacto.contacto)
                                }
                            }):null,
                            "acciones": [
                                permisos.includes('U') ? <button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.ActivarUsuario(proveedores.persona.id)}><ActivarIcon /></button> : null
                            ]
                        })
                    }
                })
                break
        }
        this.setState({ proveedoresbd: prove })
    }
    obtenerDatos = () => {
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        //proveedores
        var datos = store.getState().usuario
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        let proveedoresNegocios = Server.hostPruebasinssl + Server.puertoEmpleado + '/api/persona/empresas/' + parseInt(datos.empresas_id, 10) + '/proveedores/'
        let proveedoresTiendas = Server.hostPruebasinssl + Server.puertoEmpleado + '/api/proveedores/empresas/' + parseInt(datos.empresas_id, 10)
        Axios.get(datos.tipo === '5' ? proveedoresTiendas : proveedoresNegocios, { headers: { Authorization: ntoken } })
            .then(responsee => {
                console.log(responsee.data)
                if (responsee.status === 200) {
                    this.changetab(0)
                    datos.tipo === '5' ?
                        this.setState({
                            proveedoresTotal: responsee.data.proveedores
                        })
                        :
                        this.setState({
                            proveedoresTotal: responsee.data.rows
                        })


                    setTimeout(this.changetab(0), 2000);
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        store.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: false
        })
    }


    componentWillMount = () => {
        this.obtenerDatos()
    }





    render() {
        //console.log(this.state.proveedoresbd)
        var proveedoresbd = {
            columns: [
                {
                    label: 'FOTO',
                    field: 'foto',
                    sort: 'asc',
                    width: 'auto'
                }, {
                    label: 'Nit/Cedula',
                    field: 'codigo',
                    sort: 'asc',
                    width: 'auto'
                }, {
                    label: 'Nombre',
                    field: 'nbodega',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Telefono',
                    field: 'dimensiones',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Correo',
                    field: 'ubicacion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Direccion',
                    field: 'direccion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Acciones',
                    field: 'acciones',
                    sort: 'asc',
                    width: 150
                }
            ],
            rows: this.state.proveedoresbd
        }
        return (
            <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                {this.state.createbodega ? <button className="btn btn-danger" style={{ margin: '10px' }} onClick={this.crearbodega}>Cancelar</button> : permisos.includes('C') ? <button className="btn btn-success" style={{ margin: '10px' }} onClick={this.crearbodega}>Crear nuevo proveedor</button> : null}
                {this.state.createbodega ? <GridItem xs={12} sm={12} md={12} id="crearOrden" style={{ overflow: "auto", marginTop: '10px' }}>
                    <Dialog fullScreen open={this.state.createbodega} onClose={this.crearbodega} TransitionComponent={Transition}>
                        <FormularioProveedor userEdit={this.state.usuarioEdicion} />
                    </Dialog>
                </GridItem> : null}
                {permisos.includes('R') ? <AppBar position="static" color="default">
                    <Tabs
                        value={this.state.value}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="TODOS" onClick={() => this.changetab(0)} icon={<ListIcon />} />
                        <Tab label="ACTIVOS" onClick={() => this.changetab(1)} icon={<ListIcon />} />
                        <Tab label="INACTIVOS" onClick={() => this.changetab(2)} icon={<ListIcon />} />

                    </Tabs>
                </AppBar> : null}

                {permisos.includes('R') ? <Paper style={{ padding: '5px' }}>

                    <MDBDataTable
                        //striped
                        className="col-md-12"
                        searchLabel="Buscar"
                        paginationLabel={["Anterior", "Siguente"]}
                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                        entriesLabel="Filas a mostrar"
                        tbodyColor="primary-color"
                        theadColor="#FF8800"
                        exportToCSV={true}
                        striped
                        bordered
                        autoWidth={false}
                        responsive={true}
                        hover
                        data={proveedoresbd}
                    />
                </Paper> : <h5>USTED NO TIENE PERMISOS PARA VER LOS PROVEDORES</h5>}

            </div>
        )
    }
}



export default Proveedores;