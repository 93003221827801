import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import GridItem from "components/Grid/GridItem.jsx";
import Card from '@material-ui/core/Card';
import CardTittle from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment'
import Selects from 'react-select'
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia'
import Server from '../../../lib/server'
import store from '../../../lib/store'
import Axios from 'axios'
import imgCombo from '../../../assets/img/Formularios/combos.png'
import img2x1 from '../../../assets/img/Formularios/pague1lleve2.png'
import FormularioPromocion from "../../../components/Formularios/FormularioPromocion"
import imgcupo from '../../../assets/img/Formularios/cupon_quemable.png'
import DateFnsUtils from '@date-io/luxon';
import DeleteIcon from '@material-ui/icons/Delete';
import EditICon from '@material-ui/icons/Edit';
import swal from 'sweetalert'
import Paper from '@material-ui/core/Paper';
import Ojoicon from "@material-ui/icons/RemoveRedEye"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import ListIcon from '@material-ui/icons/List';
import ActivarIcon from "@material-ui/icons/Check"
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide"
import Loader from 'components/Loader'
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker } from 'material-ui-pickers';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
const idModulo = 27
var permisos = ''
class Promociones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //Fecha inicio promocion
            selectedDateInicio: new Date(),
            //Fecha fin promocion
            selectedDateFin: new Date(),
            //para las fechas
            moment: moment(),
            fechainicio: new Date(),
            fechafin: new Date(),
            cambbioFin: false,
            cambbioInicio: false,
            nueva: false,
            TipoPromocion: 0,
            loader: true,
            valorCombo: '',
            estimado: 0,
            productosSeleccionado: {},
            productosTotal: [],
            productosbd: [],
            productosbdInicio: [],
            valorN: '',
            precioN: '',
            totalN: '',
            productoN: '',
            pluN: '',
            imagePreviewUrl: null,
            //Cupones
            cuponInicial: '',
            cuponFinal: '',
            cuponTexto: '',
            modoDetalles: false,
            modoEdicion: false,
            promocionEditarID: 0,
            value: 0,
            prodcutosEnGeneralbd: [],
            actualizarDatos: false,
            empresaPAdre: ''
        }
        store.subscribe(() => {
            console.log("aquii")
            this.setState({
                nueva: store.getState().creacionPromocion,
                actualizarDatos: store.getState().actualizarvista,
            })
        })
    }

    componentWillMount = () => {
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        this.obtenerDatos()
    }

    componentWillReceiveProps() {
        if (this.state.actualizarDatos) {
            this.obtenerDatos()
        }
    }

    setearMiles = (numero) => {
        return (
            (new Intl.NumberFormat("de-DE").format(parseInt(numero)))
        )
    }

    nuevaPromocion = (tipo) => {
        console.log(tipo)
        store.dispatch({
            type: "CREACION_PROMOCION",
            creacionPromocion: true,
            detallesPromocion: false,
            promocionEditar: null,
            tipoPromocion: tipo,
        })
    }

    detallesPromocion = (producto, tipo) => {
        console.log(tipo)
        store.dispatch({
            type: "CREACION_PROMOCION",
            creacionPromocion: true,
            detallesPromocion: true,
            promocionEditar: producto,
            tipoPromocion: tipo === "C" ? 1 : 2,
        })
    }

    edicionPromocion = (producto, tipo) => {
        store.dispatch({
            type: "CREACION_PROMOCION",
            creacionPromocion: true,
            detallesPromocion: false,
            promocionEditar: producto,
            tipoPromocion: tipo === "C" ? 1 : 2,
        })
    }

    obtenerDatos = () => {
        this.setState({ actualizarDatos: false })
        var datos = store.getState().usuario
        //PROMOCIONES
        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/' + parseInt(datos.empresas_id, 10) + '/promocion', { headers: { Authorization: ntoken } })
            .then((res) => {
                var produc = []
                var prod2 = []
                if (res.status === 200) {
                    res.data.Productos.map((producto, key) => {
                        produc.push({
                            value: producto.id,
                            label: producto.descripcion + " -- " + producto.plu,
                            precio: producto.precio,
                            producto: producto.descripcion,
                            plu: producto.plu
                        })
                        if (producto.promocion != null) {
                            prod2.push(producto)
                        }

                    })
                } else if (res.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
                this.setState({ productosbd: produc, loader: false, productosbdInicio: prod2 })
                this.changetab(0)
            })
            .catch((err) => {
                console.log(err)
            })

    }


    activarPromocion = (id) => {
        var usuario = store.getState().usuario
        var datos = {
            "id": id,
            "entidad_nombre": "productos",
            "estado": "A",
            "personas_id": usuario.p_id,
            "empresas_id": usuario.empresas_id
        }
        console.log(datos)

        Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/estados/', datos, { headers: { Authorization: ntoken } })
            .then((res) => {
                console.log(res)
                if (res.status == 201) {
                    swal({
                        title: "Promocion activada correctamente",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {
                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                this.obtenerDatos()
                                break;
                            default:

                        }
                    });
                } else if (res.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                }
            })

    }


    inactivarPromocion = (id) => {
        var usuario = store.getState().usuario
        var datos = {
            "id": id,
            "entidad_nombre": "productos",
            "estado": "I",
            "personas_id": usuario.p_id,
            "empresas_id": usuario.empresas_id
        }
        console.log(datos)
        swal({
            title: "Estas seguro de que quieres deseas inactivar esta promocion?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/estados/', datos, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 201) {
                                swal({
                                    title: "Promocion inactivada correctamente",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            store.dispatch({
                                                type: "ACTUALIZACION_DATOS",
                                                actualizarvista: true
                                            })
                                            this.obtenerDatos()
                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            } else {
                                swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }



    //De aca
    changetab = (newTab) => {
        this.setState({ value: newTab })
        var prod2 = []
        switch (newTab) {
            case 0:
                this.state.productosbdInicio.map((producto) => (
                    prod2.push({
                        'plu': producto.plu,
                        'nombre': producto.descripcion,
                        'tipo': producto.promocion == 'C' ? 'COMBO' : '2X1',
                        'finicio': moment(producto.vigencia_inicio).format('DD-MM-YYYY hh:mm a'),
                        'ffin': moment(producto.vigencia_fin).format('DD-MM-YYYY hh:mm a'),
                        'precio': '$' + this.setearMiles(parseInt(producto.precio)),
                        "acciones": producto.estado === "I" || moment(new Date()).format("YYYY-MM-DD HH:mm:ss") > producto.vigencia_fin || moment(new Date()).format("YYYY-MM-DD HH:mm:ss") < producto.vigencia_inicio ?
                            [<button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesPromocion(producto, producto.promocion)}><Ojoicon /></button>,
                            permisos.includes('U') ? <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.edicionPromocion(producto, producto.promocion)}><EditICon /></button> : null,
                            producto.estado === "I" ? permisos.includes('U') ? <button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.activarPromocion(producto.id)}><ActivarIcon /></button> : <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.inactivarPromocion(producto.id)}><DeleteIcon /></button> : null]
                            :
                            [
                                <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesPromocion(producto, producto.promocion)}><Ojoicon /></button>,
                                permisos.includes('U') ? <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.edicionPromocion(producto, producto.promocion)}><EditICon /></button> : null,
                                permisos.includes('D') ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.inactivarPromocion(producto.id)}><DeleteIcon /></button> : null
                            ]
                    })
                ))
                break
            case 1:
                this.state.productosbdInicio.map((producto) => {
                    if (producto.estado === "A" || moment(new Date()).format("YYYY-MM-DD HH:mm:ss") < producto.vigencia_fin || moment(new Date()).format("YYYY-MM-DD HH:mm:ss") > producto.vigencia_inicio) {
                        prod2.push({
                            'plu': producto.plu,
                            'nombre': producto.descripcion,
                            'tipo': producto.promocion == 'C' ? 'COMBO' : '2X1',
                            'finicio': moment(producto.vigencia_inicio).format('DD-MM-YYYY hh:mm a'),
                            'ffin': moment(producto.vigencia_fin).format('DD-MM-YYYY hh:mm a'),
                            'precio': '$' + this.setearMiles(parseInt(producto.precio)),
                            "acciones": [
                                <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesPromocion(producto, producto.promocion)}><Ojoicon /></button>,
                                permisos.includes('U') ? <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.edicionPromocion(producto, producto.promocion)}><EditICon /></button> : null,
                                permisos.includes('D') ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.inactivarPromocion(producto.id)}><DeleteIcon /></button> : null
                            ]
                        })
                    }
                })
                break
            case 2:
                this.state.productosbdInicio.map((producto) => {
                    if (producto.estado === "I" || moment(new Date()).format("YYYY-MM-DD HH:mm:ss") > producto.vigencia_fin || moment(new Date()).format("YYYY-MM-DD HH:mm:ss") < producto.vigencia_inicio) {
                        prod2.push({
                            'plu': producto.plu,
                            'nombre': producto.descripcion,
                            'tipo': producto.promocion == 'C' ? 'COMBO' : '2X1',
                            'finicio': moment(producto.vigencia_inicio).format('DD-MM-YYYY hh:mm a'),
                            'ffin': moment(producto.vigencia_fin).format('DD-MM-YYYY hh:mm a'),
                            'precio': '$' + this.setearMiles(parseInt(producto.precio)),
                            "acciones": [
                                permisos.includes('U') ? <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.edicionPromocion(producto, producto.promocion)}><EditICon /></button> : null,
                                producto.estado === "I" ? permisos.includes('U') ? <button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.activarPromocion(producto.id)}><ActivarIcon /></button> : null : null
                            ]
                        })
                    }
                })
                break
        }
        this.setState({ prodcutosEnGeneralbd: prod2 })
    }


    cerrar = () => {
        store.dispatch({
            type: "CREACION_PROMOCION",
            creacionPromocion: false,
            detallesPromocion: false,
            promocionEditar: null,
            tipoPromocion: 0,
        })
    }


    render() {
        //console.log(this.state.selectedDateInicio.toISOString())
        const promociones = {
            columns: [
                {
                    label: 'PLU',
                    field: 'descripcion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'DESCRIPCION',
                    field: 'descripcion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'TIPO',
                    field: 'cantidad',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'FECHA INICIAL',
                    field: 'vlrunitario',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'FECHA DE VENCIMIENTO',
                    field: 'subtotal',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'PRECIO',
                    field: 'totalproducto',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'ACCIONES',
                    field: 'acciones',
                    sort: 'asc',
                    width: 150
                }
            ],
            rows: this.state.prodcutosEnGeneralbd
        }
        const {loader} = this.state
        return (
            <div>
                {this.state.nueva ?

                    <Dialog fullScreen open={this.state.nueva} onClose={this.cerrar} TransitionComponent={Transition}>
                        <FormularioPromocion />
                    </Dialog>

                    :
                    <div>
                        {permisos.includes('C') ? <div className="row mx-auto">
                            <Card className="col col-md-3" style={{ margin: '10px' }}>
                                <CardActionArea onClick={() => this.nuevaPromocion(1)}>
                                    <div className="row">
                                        <div className="col-md-12" style={{ padding: '0' }}>
                                            <CardMedia
                                                component="img"
                                                alt="Contemplative Reptile"
                                                style={{ width: '100%' }}
                                                src={imgCombo}
                                                title="Promocion de combo"
                                            />
                                        </div>
                                    </div>
                                </CardActionArea>
                            </Card >
                            <Card className="col col-md-3" style={{ margin: '10px' }}>
                                <CardActionArea onClick={() => this.nuevaPromocion(2)}>
                                    <div className="row">
                                        <div className="col-md-12" style={{ padding: '0' }}>
                                            <CardMedia
                                                component="img"
                                                alt="Contemplative Reptile"
                                                style={{ width: '100%' }}
                                                src={img2x1}
                                                title="Pague 1 y lleva 2"
                                            />
                                        </div>
                                    </div>
                                </CardActionArea>
                            </Card >
                            {/*<Card className="col col-md-3" style={{ margin: '10px', backgroundColor: '#e1dedd' }}>
                                <CardActionArea onClick={() => this.nuevaPromocion(3)}>
                                    <div className="row">
                                        <div className="col-md-12" style={{ padding: '0' }}>
                                            <CardMedia
                                                component="img"
                                                alt="Contemplative Reptile"
                                                style={{ width: '100%' }}
                                                src={imgcupo}
                                                title="Cupon quemable"
                                            />
                                        </div>
                                    </div>
                                </CardActionArea>
                </Card >*/}

                        </div> : null}
                        <div className="row" style={{ marginTop: "15px" }}>

                            {
                                loader
                                    ? <Loader />
                                    : <div className="col col-md-12" style={{ padding: 0 }}>
                                        <AppBar position="static" color="default">
                                            <Tabs
                                                value={this.state.value}
                                                variant="scrollable"
                                                scrollButtons="on"
                                                indicatorColor="primary"
                                                textColor="primary"
                                            >
                                                <Tab label="TODOS" onClick={() => this.changetab(0)} icon={<ListIcon />} />
                                                <Tab label="ACTIVOS" onClick={() => this.changetab(1)} icon={<ListIcon />} />
                                                <Tab label="INACTIVOS" onClick={() => this.changetab(2)} icon={<ListIcon />} />

                                            </Tabs>
                                        </AppBar>
                                        {permisos.includes('R') ? <Paper style={{ padding: '5px' }}>

                                            <MDBDataTable
                                                //striped
                                                className="col-md-12"
                                                searchLabel="Buscar"
                                                paginationLabel={["Anterior", "Siguente"]}
                                                infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                entriesLabel="Filas a mostrar"
                                                tbodyColor="primary-color"
                                                theadColor="#FF8800"
                                                exportToCSV={true}
                                                striped
                                                bordered
                                                autoWidth={false}
                                                responsive={true}
                                                hover
                                                data={promociones}
                                            />


                                        </Paper> : <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>}
                                    </div>

                            }

                        </div>
                    </div>
                }

            </div>
        )
    }
}
export default Promociones