
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios'
import TelefonoIcon from '@material-ui/icons/Phone'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import swal from 'sweetalert'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import store from '../../../lib/store';
import Server from '../../../lib/server';
import cardUsuario from '../../Card/card/cardProducto';

var ntoken = 'Bearer '.concat(localStorage.getItem('token'))

class dialogTelefono extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cantidadMinima: '',
            cantidadMaxima: '',
            precio: '',
            precioSugerencia: '',
            enObservacion: false,
            precioMin: '',
            precioMax: '',
            precioFlex: false,
            contrato_id: ''
        };
    }

    componentWillMount() {
        var { datosProducto } = this.props
        console.log(datosProducto)
        var cproducto = datosProducto.contratos_productos
        var productoEmp = datosProducto
        if (datosProducto != null) {
            this.setState({
                cantidadMinima: productoEmp.cantidad_minima,
                cantidadMaxima: productoEmp.cantidad_maxima,
                precioSugerencia: productoEmp.precio,
                enObservacion: productoEmp.sequimiento == 'N' ? false : true,
                precioMin: productoEmp.precio_tipo_limite == "$" ? productoEmp.precio_minimo : parseFloat(productoEmp.precio - ((productoEmp.precio * productoEmp.precio_minimo)/100)).toFixed(3),
                precioMax: productoEmp.precio_tipo_limite == "$" ? productoEmp.precio_maximo : parseFloat(productoEmp.precio + ((productoEmp.precio * productoEmp.precio_maximo)/100)).toFixed(3),
                precio: productoEmp.precio,
                precioFlex: productoEmp.precio_flexible == 'S' ? true : false,
                contrato_id: productoEmp.id

            })
        }
    }

    handleChangeCheck = name => event => {
        var estado = (event.target.value == 'false' ? false : true)
        this.setState({
            [name]: !estado
        });
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    validarFormulario = () => {
        if (this.state.precioFlex) {
            if (parseInt(this.state.precio) < this.state.precioMin) {
                swal("Fallo al guardar", "El precio debe ser mayor o igual al precio minimo", "warning")
                return false
            } else if (parseInt(this.state.precio) > this.state.precioMax) {
                swal("Fallo al guardar", "El precio debe ser menor o igual al precio maximo", "warning")
                return false
            } else {
                return true
            }
        } else{
            return true
        }


    }

    cerrar = () => {
        store.dispatch({
            type: "OBTENCION_PRODUCTOS",
            obtencionProductos: false,
        })
    }

    guardar = () => {
        //console.log(this.validarFormulario())
        var usuario = store.getState().usuario
        var validar = this.validarFormulario()
        var datos = {
            "cantidad_minima": parseInt(this.state.cantidadMinima),
            "cantidad_maxima": parseInt(this.state.cantidadMaxima),
            "sequimiento": this.state.enObservacion ? 'S' : 'N',
            "precio": this.state.precio,
            "id": this.state.contrato_id,
            "update_user": usuario.p_id
        }
        console.log(datos)
        if (validar) {
            Axios.put(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/convenios/productos_empresas', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    console.log(response)
                    if (response.status === 201) {
                        swal({
                            title: "Producto actualizado correctamente",
                            icon: "success",
closeOnClickOutside:false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "OBTENCION_PRODUCTOS",
                                        obtencionProductos: false,
                                    })
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }

    }


    render() {

        //this.obtenerTelefonos(telefonos)
        return (
            <Card style={{ marginTop: "0", paddingBottom: "15px" }}>
                <DialogTitle id="form-dialog-title">Configuracion de producto</DialogTitle>
                <CardContent>
                    <div className="row bottom-aligned">
                        <div className="col-md-4">
                            <label htmlFor="numordencompra" className="col-form-label">Cantidad minima en inventario</label>
                            <input id="numordencompra" value={this.state.cantidadMinima} autoComplete="off" className="form-control" type="number" min="1" onChange={this.handleChange('cantidadMinima')} />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="numordencompra" className="col-form-label">Cantidad maxima en inventario</label>
                            <input id="numordencompra" value={this.state.cantidadMaxima} autoComplete="off" className="form-control" type="number" min="1" onChange={this.handleChange('cantidadMaxima')} />
                        </div>
                        <div className="col-md-4">
                            <FormControlLabel
                                control={<Checkbox value={this.state.enObservacion} checked={this.state.enObservacion} style={{ padding: '5px' }} onChange={this.handleChangeCheck('enObservacion')} />}
                                label='Seguimiento'
                                style={{ margin: '0' }}
                            />
                        </div>
                    </div>
                    <div className="row bottom-aligned">
                        <div className="col-md-4">
                            <label htmlFor="numordencompra" className="col-form-label">Precio minimo</label>
                            <input id="numordencompra" autoComplete="off" value={'$' + this.state.precioMin} disabled={true} className="form-control" type="text" onChange={this.handleChange('precioMin')} />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="numordencompra" className="col-form-label">Precio</label>
                            <input id="numordencompra" autoComplete="off" disabled={!this.state.precioFlex} value={this.state.precio} placeholder={'Precio base actual ( $' + this.state.precioSugerencia + ')'} className="form-control" type="number" min="1" onChange={this.handleChange('precio')} />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="numordencompra" className="col-form-label">Precio maximo</label>
                            <input id="numordencompra" autoComplete="off" value={'$' + this.state.precioMax} disabled={true} className="form-control" type="text" onChange={this.handleChange('precioMax')} />
                        </div>
                    </div>
                    <div className="row bottom-aligned" style={{ marginTop: '10px' }}>
                        <div className="col col-md-9">
                            <button className="btn btn-danger" onClick={this.cerrar} style={{ float: 'right' }}>CANCELAR</button>
                        </div>
                        <div className="col col-md-3">
                            <button className="btn btn-success" onClick={this.guardar} style={{ float: 'right' }}>GUARDAR</button>
                        </div>
                    </div>
                </CardContent>
            </Card>
        )
    }
}


export default dialogTelefono