import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import store from "../../lib/store";
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.jsx";
import RTLNavbarLinks from "./RTLNavbarLinks.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";
import imagenFondo from "../../assets/img/1.jpg";

function Header({ ...props }) {
  var colorDominio = store.getState().colorDominio
  function makeBrand() {
    var name;
    //console.log(props.routes)
    var vauxiliar = []
    var vector1 = []
    var vector2 = []
    var tipoDominio = store.getState().tipoDominio
    
    props.routes.map((prop, key) => {
      if (prop.submodulos != null) {
        prop.submodulos.map((sub, key) => {
          if (sub.layout + sub.path === props.location.pathname) {
            name = props.rtlActive ?
              sub.rtlName
              :
              sub.id === 4
                ?
                tipoDominio === 'E' || tipoDominio === 'V'
                  ?
                  sub.name2
                  :
                  sub.name
                :
                sub.name
          }
        })
      } else {
        if (prop.layout + prop.path === props.location.pathname) {
          name = props.rtlActive ? prop.rtlName : prop.name;
        }
      }
      return null;
    });
    return name;
  }
  const { classes, color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  return (
    <AppBar className={classes.appBar + appBarClasses} style={{ backgroundColor: colorDominio, color: "#fff", margin: 0, padding: 0, zIndex: 1099 }}>
      <Toolbar className={window.innerWidth > 720 ? classes.container + ' row' : classes.container} children={null}>
        <div className="col-md-8">
          {/* Here we create navbar brand, based on route name */}
          <Button children={null} color="transparent" href="#" className={classes.title}>
            {makeBrand()}
          </Button>
        </div>
        <Hidden smDown implementation="css" className="col-md-4" >
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
            children={null}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(headerStyle)(Header);
