import 'bootstrap/dist/css/bootstrap.min.css';
import './usuarios.css'
import React, { Component } from 'react'
import CardUsuario from '../components/Card/card/cardUsuario'
import CardUsuarioBack from '../components/Card/card/cardUsuarioBack'
import Axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import store from '../lib/store'
import Switch from '@material-ui/core/Switch';
import { MDBDataTable } from 'mdbreact';
import "../components/Card/card/cardUsuario.css"
import FormularioUsuario from '../components/Formularios/FormularioPersona'
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide"
import Paper from '@material-ui/core/Paper';
import Loader from '../components/Loader'
import Server from '../lib/server'
import { Avatar } from '@material-ui/core';
import JsBarcode from 'jsbarcode'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import h1 from '../assets/img/h1.jpg'
import h2 from '../assets/img/h2.jpg'
import swal from 'sweetalert'
import DialogContent from '@material-ui/core/DialogContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from "@material-ui/core";
import Selects from 'react-select';
import LupaIcon from '@material-ui/icons/Search'
import PersonIcon from '@material-ui/icons/Person'
// @material-ui/core
var busquedaUsuario = []
var datosMostrarUsuario = []
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const idModulo = 25
var permisos = ''
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class usuariosVista extends Component {
  constructor() {
    super();
    this.state = {
      createUSer: false,
      setOpen: false,
      userdetails: -1,
      users: [],
      usersByName: [],
      tabla: false,
      busqueda: false,
      value: 0,
      ventanaEdicion: false,
      usuarioEdicion: null,
      campoBusqueda: '',
      //Busqueda de datos
      busqueda: false,
      paginadorIndex: 0,
      paginadorMinimo: 0,
      paginadorMaximo: 15,
      paginadorTotal: 16,
      //
      animacionIzquierda: false,
      animacionDerecha: false,
      loader: true,
      actualizacionVista: false,
      carnetUsuario: '',
      mostrarCarnet: false,
      detallesCarnet: null,
      empresaSeleccionadaUsuarios: [],
      empresasbd: [],
      multiEmpresasUsuarios: "",
      expanded: false

    }
    store.subscribe(() => {
      console.log("s el storee")
      console.log("cambio estado:", store.getState().actualizarvista)
      this.setState({
        createUSer: store.getState().creacionUsuario,
        usuarioEdicion: store.getState().usuarioEdicion,
        actualizacionVista: store.getState().actualizarvista
      })
    })
  }

  buscarUsuarioByEmpresas = () => {
    var hijos = store.getState().empresasHijos
    console.log(this.state.multiEmpresasUsuarios)
    if (this.state.multiEmpresasUsuarios === "") {
      this.obtenerDatos(hijos)
    } else {
      this.setState({
        loader:true
      })
      this.obtenerDatos(this.state.multiEmpresasUsuarios)
    }

  }

  handleChanges = name => value => {
    this.setState({
      [name]: value,
    });
    switch (name) {
      case "empresaSeleccionadaUsuarios":
        var empresas = ""
        value.map((empresa, key) => {
          if (key === 0) {
            empresas += empresa.value
          } else {
            empresas += "," + empresa.value
          }
        })
        this.setState({
          multiEmpresasUsuarios: empresas
        })
        break;
    }
  }

  handleChangeTab = (event, value) => {
    this.setState({ value });
  };
  handleChange = () => {
    this.setState({
      tabla: !this.state.tabla
    })
  };
  infoUsusarioClosed = () => {
    console.log("define is equals to ")
  }



  handleUsername(e) {
    let s = ""
    s = e.target.value
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.actualizacionVista) {
      var hijos = store.getState().empresasHijos
      this.obtenerDatos(hijos)
      this.setState({
        busqueda: false
      })
      return true
    }
    return true;
  }



  obtenerDatos = (by) => {
    //this.setState({ actualizacionVista: false })
    store.dispatch({
      type: "ACTUALIZACION_DATOS",
      actualizarvista: false
    })
    var datos = store.getState().usuario
    switch (datos.tipo) {
      case "1":
      case "2":
      case "3":
      case "4":
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/persona/empresas/' + by, { headers: { Authorization: ntoken } })
          .then(response => {
            if (response.status == 200) {
              var datos = response.data.rows
            } else if (response.status == 205) {
              swal("ERROR", "LA SESION HA CADUCADO", "warning")
              setTimeout(function () { window.location.href = '/'; }, 3000);
            }
            this.setState({ users: datos, loader: false })
          })
        break;
      case "5":
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/persona/empresas/' + parseInt(datos.empresas_id, 10), { headers: { Authorization: ntoken } })
          .then(response => {
            //console.log(response)
            if (response.status == 200) {
              var datos = response.data.rows
            } else if (response.status == 205) {
              swal("ERROR", "LA SESION HA CADUCADO", "warning")
              setTimeout(function () { window.location.href = '/'; }, 3000);
            }
            this.setState({ users: datos, loader: false })
          })
        break;
    }

  }
  componentWillMount() {
    var modulos = store.getState().perfilesUSuario
    var hijos = store.getState().empresasHijos
    modulos.map((modulo, key) => {
      if (modulo.modulos_id == idModulo) {
        permisos = modulo.acciones
      }
    })
    var usuario = store.getState().usuario
    var hijos = store.getState().empresasHijos
    var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
    Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/3,4,5/empresas/' + hijos + "," + usuario.empresas_id, { headers: { Authorization: ntoken } })
      .then(responseee => {
        var empresas = []
        ////console.log(responseee)
        if (responseee.data != null) {
          responseee.data.empresas.map((dato) => (
            empresas.push({
              value: dato.id,
              label: dato.alias + " -- " + dato.nit
            })
          ))
        } else if (responseee.status == 205) {
          swal("ERROR", "LA SESION HA CADUCADO", "warning")
          setTimeout(function () { window.location.href = '/'; }, 3000);
        }
        this.setState({ empresasbd: empresas })

      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
    this.obtenerDatos(hijos)
  }

  obtenerCarnet = (persona) => {
    console.log(persona)
    this.setState({ mostrarCarnet: true, detallesCarnet: persona })
    setTimeout(function () {
      JsBarcode("#barcode6", "CN" + persona.persona.identificacion + "I" + persona.persona.id + "E" + persona.persona.empresa.id, {
        width: 0.8,
        height: 40,
        format: "CODE39",
        displayValue: false,
        background: 'rgba(255, 255, 255, 0)'
      });
    }, 1000);


    setTimeout(function () {
      var n = document.getElementById('capture');
      console.log(n)
      html2canvas(n)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/jpg');
          const pdf = new jsPDF({ format: "A4" });
          var link = document.createElement('a');
          link.download = 'filename.jpg';
          link.href = imgData;
          link.click();
          pdf.addImage(imgData, 'JPG', 0, 0);
          pdf.save("download.pdf");
        })
    }, 3000);
  }

  handleClickOpen = () => {
    store.dispatch({
      type: "CREACION_USUARIO",
      creacionUsuario: !this.state.createUSer,
      usuarioEdicion: null,
      modovista: false
    })
    if (this.state.createUSer) {
      this.setState({ usuarioEdicion: null })
    }
  };
  buscar = (name) => event => {
    var cadena = event.target.value.toUpperCase()
    if (cadena.length > 0) {
      this.setState({ busqueda: true })
    } else {
      this.setState({ busqueda: false })
    }
    busquedaUsuario = []
    this.state.users.map((tipo, key) => {
      //console.log(tipo.persona)
      if ((tipo.persona.nombres + " " + tipo.persona.apellidos).includes(cadena)) {
        busquedaUsuario.push(tipo)
      } else {

      }
    })
  }

  handleChangeText = name => event => {
    this.setState({
      [name]: event.target.value.toUpperCase(),
    });
  };

  buscar2 = () => {
    var cadena = this.state.campoBusqueda.toUpperCase()
    var categorias = []
    busquedaUsuario = []
    this.state.users.map((tipo, key) => {
      if ((tipo.persona.nombres + " " + tipo.persona.apellidos).includes(cadena) || tipo.persona.identificacion.includes(cadena)) {
        busquedaUsuario.push(tipo)
        console.log(tipo.persona)
      }
    })
    this.setState({ busqueda: true })
  }

  limpiar = () => {
    this.setState({
      busqueda: false,
      campoBusqueda: ''
    })
  }

  keyBuscar = (e) => {
    if (e.key === 'Enter') {
      this.buscar2()
    } else if (e.key === 'Escape') {
      this.limpiar()
    }
  }

  detallesUsuario = (usuario) => {
    store.dispatch({
      type: "CREACION_USUARIO",
      creacionUsuario: !this.state.edicicionUsuario,
      usuarioEdicion: usuario,
      modovista: true,
      edicicionUsuario: false
    })
    if (this.state.createUSer) {
      this.setState({ usuarioEdicion: null })
    }
  }

  pagination = (name, str) => {
    switch (name) {
      case 'tipo':
        this.setState({ animacionDerecha: false, animacionIzquierda: false })
        if (str == 'S') {
          this.setState({ animacionDerecha: true, animacionIzquierda: false, paginadorIndex: this.state.paginadorIndex + 1, paginadorMinimo: this.state.paginadorMaximo + 1, paginadorMaximo: this.state.paginadorMaximo + this.state.paginadorTotal })
        } else {
          this.setState({ animacionDerecha: false, animacionIzquierda: true, paginadorIndex: this.state.paginadorIndex - 1, paginadorMinimo: (this.state.paginadorMinimo - this.state.paginadorTotal), paginadorMaximo: this.state.paginadorMinimo - 1 })
        }
        this.datos()
        break;
    }
  }

  datos = () => {
    datosMostrarUsuario = []
    for (var i = this.state.paginadorMinimo; i <= this.state.paginadorMaximo; i++) {
      if (this.state.users[i] != null) {
        datosMostrarUsuario.push(this.state.users[i])
      } else {
        //console.log(this.state.tipoEquipo[i])
      }
    }
    //console.log("Minimno:" + this.state.paginadorMinimo + " - maximo:" + this.state.paginadorMaximo)
  }

  expandir = () => {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  cancelarCarnet = () => {
    this.setState({ mostrarCarnet: !this.state.mostrarCarnet })
  }

  render() {
    this.datos()
    var dominio = store.getState().dominio
    var usuario = store.getState().usuario
    const tipoDominio = store.getState().tipoDominio
    const imagen = Server.hostMultimedia + dominio + "/persona/default.png"
    var data = {
      columns: [
        {
          label: 'Imagen',
          field: 'imagen',
          sort: 'asc'
        }, {
          label: 'Identificacion',
          field: 'identificacion',
          sort: 'asc'
        }, {
          label: 'Nombre',
          field: 'nombre',
          sort: 'asc'
        }, {
          label: 'Empresa',
          field: 'descripcion',
          sort: 'asc'
        }, {
          label: 'Correo',
          field: 'correo',
          sort: 'asc'
        }, {
          label: 'Celular',
          field: 'celular',
          sort: 'asc'
        }
      ], rows:
        this.state.users.map((persona, key) => {
          var imagenUSer = Server.hostMultimedia + dominio + "/persona/" + (persona.persona.url_foto == null || (persona.persona.url_foto == 'N') ? 'default.png' : persona.persona.id + '.png')
          return (
            {
              'imagen': (persona.persona.url_foto != null && persona.persona.url_foto != 'N'
                ?
                <Avatar alt={''} onEronError={(e) => { e.target.onerror = null; e.target.src = imagen }} title={''} onClick={() => this.detallesUsuario(persona)} src={imagenUSer} style={{ margin: 10, width: 40, height: 40, background: '#ffffff', cursor: 'pointer' }} />
                :
                <Avatar alt={''} title={''} src={imagen} onClick={() => this.detallesUsuario(persona)} style={{ margin: 10, width: 40, height: 40, background: '#ffffff', cursor: 'pointer' }} />),
              'id': persona.persona.identificacion,
              'nombre': persona.persona.nombres + " " + persona.persona.apellidos,
              'empresa': persona.persona.empresa.razon_social,
              'correo': persona.contacto != null ? persona.contacto.map((contact) => {
                if (contact.tipo == 1 && contact.principal == "S") {
                  return (
                    contact.contacto
                  )
                } else {
                  return (
                    ''
                  )
                }
              }) : 'N/A',
              'telefono': persona.contacto != null ? persona.contacto.map((contact) => {
                if (contact.tipo == 2 && contact.principal == "S") {
                  return (
                    contact.contacto
                  )
                } else {
                  return (
                    ''
                  )
                }
              }) : 'N/A',
              //'acciones': <button className="btn-sm btn-danger" onClick={() => this.obtenerCarnet(persona)}>X</button>
            }
          )
        })

    }
    var animacion = ""
    if (this.state.animacionIzquierda) {
      animacion = "row siguientePAaina"
    } else if (this.state.animacionDerecha) {
      animacion = "row anteriorPagina"
    } else {
      animacion = "row "
    }
    const { value } = this.state;
    //CArnet
    var dCarnet = null
    if (this.state.detallesCarnet != null) {
      dCarnet = this.state.detallesCarnet
    }
    return (
      this.state.loader
        ?
        <div style={{ height: '100vh', width: '100%', paddingLeft: '15px', paddingRight: '15px' }} className="mx-auto my-auto">
          <Loader style={{ height: '100vh', width: '100%' }} />
        </div>
        :
        <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
          <div className="row">
            <FormControlLabel
              control={
                <Switch
                  onChange={this.handleChange.bind(this)}
                  value="checkedA"
                />
              }
              label={this.state.tabla ? "Cambiar a tarjetas" : "Cambiar a tabla"}
            />
          </div>
          <div className="row">
            {permisos.includes('C') ? <button className="btn btn-success" onClick={this.handleClickOpen}>Agregar persona</button> : null}
          </div>
          <Dialog fullScreen open={this.state.createUSer} onClose={this.handleClickOpen} TransitionComponent={Transition}>
            <FormularioUsuario userEdit={this.state.usuarioEdicion} />
          </Dialog>
          {permisos.includes('R') ? this.state.tabla ?
            <div className="row" style={{ marginTop: "15px", marginTop: '15px' }}>
              <div className="col col-md-12" style={{ padding: 0 }}>
                <Paper style={{ padding: '10px' }}>
                  <Dialog
                    open={this.state.mostrarCarnet}
                    onClose={() => this.cancelarCarnet()}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'md'}
                    style={{ padding: '0' }}
                    disableBackdropClick={false}
                  >
                    <DialogContent style={{ padding: '0' }}>
                      {dCarnet != null ?
                        <div id="capture" style={{ height: '300px' }} className="bg-white mx-auto my-auto" >
                          <table border="0" cellspacing="0" cellpadding="0" className="ttt3" style={{ margin: '20px' }}>
                            <tr>
                              <td><table className="ttt3" width="324" height="210" border="0" cellpadding="0" cellspacing="0">
                                <tr>
                                  <td width="324" height="210" valign="top" background={h1} style={{ border: '0 !IMPORTANT' }}><table width="315" height="207" border="0" align="center" cellpadding="0" cellspacing="0">
                                    <tr>
                                      <td width="112" align="center" valign="top"><table width="100" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                          <td height="10"></td>
                                        </tr>
                                        <tr>
                                          <td height="101">{(dCarnet.persona.url_foto != null && dCarnet.persona.url_foto != 'N'
                                            ?
                                            <div className="rounded-circle"><img alt={''} className="rounded-circle" onEronError={(e) => { e.target.onerror = null; e.target.src = imagen }} title={''} src={Server.hostMultimedia + dominio + "/persona/" + (dCarnet.persona.url_foto == null || (dCarnet.persona.url_foto == 'N') ? 'default.png' : dCarnet.persona.id + '.png')} style={{ margin: 3, width: 95, height: 95 }} /></div>
                                            :
                                            <div className="rounded-circle"><img alt={''} className="rounded-circle" title={''} src={imagen} style={{ margin: 3, width: 95, height: 95 }} /></div>)},</td>
                                        </tr>
                                      </table></td>
                                      <td width="203" align="center" valign="top"><table width="175" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                          <td width="175" height="45"> </td>
                                        </tr>
                                        <tr>
                                          <td height="40" align="center" valign="bottom" style={{ lineHeight: '90%' }} ><font color="#FFFFFF" face="Trebuchet MS" size="3px"><b>{dCarnet.persona.nombres}</b></font></td>
                                        </tr>
                                        <tr>
                                          <td height="47" align="center" valign="bottom" style={{ lineHeight: '90%' }}><font color="#FFFFFF" face="Trebuchet MS" size="3px"><b>{dCarnet.persona.apellidos}</b></font></td>
                                        </tr>
                                        <tr>
                                          <td height="33" align="center" valign="bottom"><font color="#FFFFFF" face="Trebuchet MS" size="3px"><b>{dCarnet.persona.identificacion}</b></font></td>
                                        </tr>
                                      </table></td>
                                    </tr>
                                  </table></td>
                                </tr>
                              </table></td>
                              <td>&nbsp;</td>
                              <td><table width="324" height="210" border="0" cellpadding="0" cellspacing="0" className="ttt3">
                                <tr>
                                  <td width="324" height="210" valign="top" background={h2} style={{ border: '0 !IMPORTANT' }}><table width="90%" border="0" align="center" cellpadding="0" cellspacing="0">
                                    <tr>
                                      <td width="23%" height="33" align="center" valign="bottom"><font color="#FFFFFF" face="Trebuchet MS" size="3px"><b>{dCarnet.persona.sangre}</b></font></td>
                                      <td width="77%" align="center" valign="bottom"><font color="#FFFFFF" face="Trebuchet MS" size="2px" style={{ lineHeight: '90%' }} ><b>{dCarnet.persona.empresa.razon_social}</b></font></td>
                                    </tr>
                                  </table>
                                    <table width="90%" border="0" align="center" cellpadding="0" cellspacing="0">
                                      <tr>
                                        <td>&nbsp;</td>
                                      </tr>
                                      <tr>
                                        <td height="72" align="center" valign="top"><img id="barcode6" className="mx-auto" /></td>
                                      </tr>
                                    </table>
                                    <table width="88%" border="0" align="center" cellpadding="0" cellspacing="0">
                                      <tr>
                                        <td height="50" align="center" style={{ lineHeight: '85%', fontSize: '11px', verticalAlign: "middle" }}><font color="#FFFFFF" face="Arial" size="1px" >Este carnet es de uso personal e intransferible, debe portarse dentro de las instalaciones de las mismas (Debe ser devuelto, al retirarse de la compañía)</font></td>
                                      </tr>
                                    </table></td>
                                </tr>
                              </table></td>
                            </tr>
                          </table>
                        </div> : null}
                    </DialogContent>
                  </Dialog>
                  <MDBDataTable
                    //striped
                    className="col-md-12"
                    searchLabel="Buscar"
                    paginationLabel={["Anterior", "Siguente"]}
                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                    entriesLabel="Filas a mostrar"
                    tbodyColor="primary-color"
                    theadColor="#FF8800"
                    exportToCSV={true}
                    striped
                    bordered
                    autoWidth={false}
                    responsive={true}
                    hover
                    data={data}
                  />
                </Paper>
              </div>
            </div>

            :
            <div>
              {usuario.tipo != "5" ? <ExpansionPanel expanded={this.state.expanded} style={{ zIndex: 1098, marginTop: '20px' }} onChange={() => this.expandir()}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography >FILTROS</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>

                  <div className="row" style={{ width: '100%' }}>
                    <div className="col-md-8">
                      <label htmlFor="numordencompra" className="col-form-label">EMPRESAS</label>
                      <Selects
                        value={this.state.empresaSeleccionadaUsuarios}
                        onChange={this.handleChanges('empresaSeleccionadaUsuarios')}
                        options={this.state.empresasbd}
                        isMulti
                      />
                    </div>
                    <div className="col-md-4  bottom-aligned">
                      <button className="btn btn-success btn-block" onClick={() => this.buscarUsuarioByEmpresas()}>CONSULTAR</button>
                    </div>
                  </div>

                </ExpansionPanelDetails>
              </ExpansionPanel>
                :
                null}
              <div className="row mx-auto" style={{ marginTop: '15px' }}>
                <button disabled={this.state.paginadorMinimo == 0} className="btn btn-sm btn-outline-primary mx-auto" onClick={() => this.pagination('tipo', 'N')}>{"<<"}</button>
                <div className="col col-md-6 mx-auto">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1"><PersonIcon /></span>
                    </div>
                    <input type="text" placeholder="Busqueda por nombre o identificacion" onKeyDown={this.keyBuscar} value={this.state.campoBusqueda} onChange={this.handleChangeText('campoBusqueda')} className="form-control mx-auto" />
                    {this.state.busqueda ? <div className="input-group-append">
                      <button className="btn btn-outline-primary" disabled={!this.state.busqueda} type="button" onClick={() => this.limpiar()}>x</button>
                    </div> : null}
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="button" onClick={() => this.buscar2()}><LupaIcon /></button>
                    </div>
                  </div>
                </div>
                <button disabled={this.state.paginadorMaximo + 1 >= this.state.users.length} className="btn btn-sm btn-outline-primary mx-auto" onClick={() => this.pagination('tipo', 'S')}>{">>"}</button>
              </div>
              <div className={animacion}>

                {this.state.busqueda
                  ?
                  busquedaUsuario.map((user, key) => {
                    return (
                      <div key={key} className={window.innerWidth <= 1400 ? "col-md-4" : "col-md-3"} style={{ marginTop: "10px", padding: "5px" }} >
                        <CardUsuario usuario={user}></CardUsuario>
                      </div>
                    )
                  })
                  :


                  datosMostrarUsuario.map((user, key) => {
                    return (
                      <div key={key} className={window.innerWidth <= 1400 ? "col-md-4" : "col-md-3"} style={{ marginTop: "10px", padding: "5px" }} >
                        <CardUsuario usuario={user}></CardUsuario>
                      </div>
                    )
                  })

                }
              </div>
              <div className="row mx-auto">
                <div className="col col-md-4  mx-auto">
                  {datosMostrarUsuario.length > 0
                    ?
                    <p className="mx-auto">Mostrando {this.state.paginadorMinimo + 1} a {this.state.paginadorMaximo > this.state.users.length ? this.state.users.length : this.state.paginadorMaximo + 1} de {this.state.users.length} usuarios</p>
                    :
                    <p className="mx-auto">No hay usuarios para mostrar</p>
                  }

                </div>
              </div>
            </div>
            : <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>}
        </div>
    );
  }
}



export default usuariosVista;
