import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react'
import CardUsuario from '../../../components/Card/card/cardUsuario'
import Axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import store from '../../../lib/store'
import Switch from '@material-ui/core/Switch';
import { MDBDataTable } from 'mdbreact';
import "../../../components/Card/card/cardUsuario.css"
import FormularioUsuario from '../../../components/Formularios/FormularioConfiguracionFlota.js'
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide"
import Paper from '@material-ui/core/Paper';
import Loader from '../../../components/Loader'
import Server from '../../../lib/server'
import { Avatar } from '@material-ui/core';
import swal from 'sweetalert'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from "@material-ui/core";
import DialogIdentificador from '../../../components/Formularios/dialogs/dialogIdentificador'
import DialogContent from '@material-ui/core/DialogContent';
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker, TimePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Selects from 'react-select';
import moment from 'moment'
import "moment/locale/es";
import BonosIcon from "@material-ui/icons/ConfirmationNumber"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import ActivarIcon from "@material-ui/icons/Check"
import IconButton from "@material-ui/core/IconButton";
import EditICon from '@material-ui/icons/Edit';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
var busquedaUsuario = []
var datosMostrarUsuario = []
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const idModulo = 41
var permisos = ''
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class usuariosVista extends Component {
    constructor() {
        super();
        this.state = {
            createUSer: false,
            setOpen: false,
            userdetails: -1,
            users: [],
            usersByName: [],
            tabla: false,
            busqueda: false,
            value: 0,
            ventanaEdicion: false,
            usuarioEdicion: null,
            //Busqueda de datos
            busqueda: false,
            paginadorIndex: 0,
            paginadorMinimo: 0,
            paginadorMaximo: 15,
            paginadorTotal: 16,
            //
            animacionIzquierda: false,
            animacionDerecha: false,
            loader: false,
            actualizacionVista: false,
            carnetUsuario: '',
            mostrarCarnet: false,
            detallesCarnet: null,
            //FORM
            clientesbd: [],
            flotasbd: [],
            flotasbdForm: [],
            // bonosbd: [],
            expanded: false,
            nombreGuardar: '',
            subFlota: false,
            flotaPadre: true,
            clienteSeleccionado: {},
            flotaSelecionada: {},
            identificadores: [],
            fechaInicial: new Date(),
            fechaFinal: new Date(),
            dialogIDentificafor: false,
            modovista: false,
            modoEditar: false,
            idEditar: '',
            encabezadoGuardar: '',
            conCupo: false,
            cupo: 0


        }
        store.subscribe(() => {
            this.setState({
                createUSer: store.getState().creacionUsuario,
                usuarioEdicion: store.getState().usuarioEdicion,
                actualizacionVista: store.getState().actualizarvista,
                identificadores: store.getState().codigos,
                dialogIDentificafor: store.getState().creacionCodigo,
            })
        })
    }


    handleChangeTab = (event, value) => {
        this.setState({ value });
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });

    };


    handleChangemoment = date => {
        this.setState({ fechaInicial: date });
    }
    handleChangemomentFinal = date => {
        this.setState({ fechaFinal: date });
    }
    infoUsusarioClosed = () => {
        console.log("define is equals to ")
    }
    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
    }


    handledialogIdentificador = () => {
        store.dispatch({
            type: "AGREGAR_IDENTIFICADOR",
            codigos: this.state.identificadores,
            creacionCodigo: !this.state.dialogIDentificafor

        })
    }

    expandir = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    handleUsername(e) {
        let s = ""
        s = e.target.value
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizacionVista) {
            console.log("desde vista")
            this.obtenerDatos()
            return true
        }
        return true;
    }

    cancelar = () => {
        this.setState({
            nombreGuardar: "",
            productoSeleccionado: {},
            expanded: false,
            modovista: false,
            modoEditar: false,
            idEditar: '',
            encabezadoGuardar: ''
        })
    }

    verDetalles = (bono) => {
        this.setState({
            expanded: true,
            modovista: true,
            modoEditar: false,
            nombreGuardar: bono.descripcion,
            productoSeleccionado: {
                "value": bono.producto._id,
                "label": bono.producto.plu + " - " + bono.producto.descripcion,
                "codigo": bono.codigo_barras,
                "producto": bono.producto
            },
            encabezadoGuardar: bono.encabezado
        })
    }

    verEditar = (bono) => {
        this.setState({
            expanded: true,
            modovista: false,
            modoEditar: true,
            nombreGuardar: bono.descripcion,
            productoSeleccionado: {
                "value": bono.producto._id,
                "label": bono.producto.plu + " - " + bono.producto.descripcion,
                "codigo": bono.codigo_barras,
                "producto": bono.producto
            },
            idEditar: bono._id,
            encabezadoGuardar: bono.encabezado
        })
    }

    validarFormulario = () => {
        if (this.state.nombreGuardar.trim == "" || this.state.nombreGuardar.length == 0) {
            swal("Fallo al crear", "EL NOMBRE ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (JSON.stringify(this.state.productoSeleccionado) === "{}") {
            swal("Fallo al crear", "POR FAVOR SELECCIONE UN PRODUCTO", "warning")
            return false
        } else {
            this.setState({
                response: true
            })
            return true
        }

    }

    registrarBono = () => {
        var validar = false
        validar = this.validarFormulario()
        var usuario = store.getState().usuario
        var datos = {
            "descripcion": this.state.nombreGuardar,
            "empresas_id": usuario.empresas_id,
            "productos_id": this.state.productoSeleccionado.value,
            "codigo_barras": this.state.productoSeleccionado.codigo,
            "create_user": parseInt(usuario.p_id),
            "encabezado": this.state.encabezadoGuardar
        }
        if (validar) {
            console.log(datos)
            Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/bonos', datos, { headers: { Authorization: ntoken } })
                .then((res) => {
                    console.log(res)
                    this.setState({
                        peticion: false
                    })
                    if (res.status === 200) {
                        if (res.data.success) {
                            swal({
                                title: "Bono creado correctamente",
                                icon: "success",
                                closeOnClickOutside: false,
                                buttons: {
                                    Seguir: {
                                        text: "OK",
                                        value: "continuar"
                                    }
                                },
                            }).then((value) => {
                                switch (value) {

                                    case "continuar":
                                        store.dispatch({
                                            type: "ACTUALIZACION_DATOS",
                                            actualizarvista: true
                                        })
                                        //this.obtenerDatos()
                                        this.cancelar()
                                        break;

                                    default:

                                }
                            });
                        } else {
                            swal("Fallo al guardar", res.data.message, "warning");
                        }
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                    }
                })
                .catch((err) => {
                    swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                })
        }
    }

    editarBono = () => {
        var validar = false
        validar = this.validarFormulario()
        var usuario = store.getState().usuario
        var datos = {
            "_id": this.state.idEditar,
            "descripcion": this.state.nombreGuardar,
            "empresas_id": usuario.empresas_id,
            "productos_id": this.state.productoSeleccionado.value,
            "codigo_barras": this.state.productoSeleccionado.codigo,
            "create_user": parseInt(usuario.p_id),
            "encabezado": this.state.encabezadoGuardar
        }
        if (validar) {
            console.log(datos)
            Axios.put(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/bonos', datos, { headers: { Authorization: ntoken } })
                .then((res) => {
                    console.log(res)
                    console.log(res.status)
                    console.log(res.data.response)
                    this.setState({
                        peticion: false
                    })
                    if (res.status === 200) {
                        if (res.data.response.success) {
                            swal({
                                title: "Bono editado correctamente",
                                icon: "success",
                                closeOnClickOutside: false,
                                buttons: {
                                    Seguir: {
                                        text: "OK",
                                        value: "continuar"
                                    }
                                },
                            }).then((value) => {
                                switch (value) {

                                    case "continuar":
                                        store.dispatch({
                                            type: "ACTUALIZACION_DATOS",
                                            actualizarvista: true
                                        })
                                        //this.obtenerDatos()
                                        this.cancelar()
                                        break;

                                    default:

                                }
                            });
                        } else {
                            swal("Fallo al editar", res.data.message, "warning");
                        }
                    } else {
                        swal("Fallo al editar", "Por favor verifique los datos", "warning");
                    }
                })
                .catch((err) => {
                    swal("Fallo al editar", "Por favor verifique los datos", "warning");
                })
        }
    }

    inactivarBono = (id) => {
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        var usuario = store.getState().usuario
        var data = {
            "_id": id,
            "estado": "I",
            "create_user": parseInt(usuario.p_id),
        }
        swal({
            title: "Estas seguro de que quieres inactivar este bono?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.put(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/bonos', data, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 200) {
                                swal({
                                    title: "BONO INACTIVADO CORRECTAMENTE",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            /*store.dispatch({
                                                type: "ACTUALIZACION_DATOS",
                                                actualizarvista: true
                                            })*/
                                            this.obtenerDatos()
                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            } else {
                                swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }

    activarBono = (id) => {
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        var usuario = store.getState().usuario
        var data = {
            "_id": id,
            "estado": "A",
            "create_user": parseInt(usuario.p_id),
        }
        swal({
            title: "Estas seguro de que quieres activar este bono?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.put(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/bonos', data, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 200) {
                                swal({
                                    title: "BONO ACTIVADO CORRECTAMENTE",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            /*store.dispatch({
                                                 type: "ACTUALIZACION_DATOS",
                                                 actualizarvista: true
                                             })*/
                                            this.obtenerDatos()
                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            } else {
                                swal("Fallo al activar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }



    obtenerDatos = () => {
        //this.setState({ actualizacionVista: false })
        store.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: false
        })
        var datos = store.getState().usuario
        var hijos = store.getState().empresasHijos
        switch (datos.tipo) {
            case "1":
            case "2":
            case "3":
            case "4":
                Axios.get(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/bonos/empresas_id/' + datos.empresas_id, { headers: { Authorization: ntoken } })
                    .then(response => {
                        var bonosb = []
                        console.log(response)
                        if (response.status == 200) {
                            if (response.data.data != null) {
                                response.data.data.map((bn) => {
                                    bonosb.push(bn.bono)
                                })
                            }
                        } else if (response.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }
                        this.setState({ bonosbd: bonosb })
                        let t = this
                        setTimeout(function () { t.datos() }, 200)
                    })
                Axios.get(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/productos', { headers: { Authorization: ntoken } })
                    .then(response => {
                        var productosp = []
                        console.log(response)
                        if (response.status == 200) {
                            if (response.data.data != null) {
                                response.data.data.map((bono) => {
                                    productosp.push({
                                        "value": bono._id,
                                        "label": bono.plu + " - " + bono.descripcion,
                                        "codigo": bono.identificador != null && bono.identificador.length > 0 ? bono.identificador[0].identificador : "",
                                        "producto": bono
                                    })
                                })
                            }
                        } else if (response.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }
                        this.setState({ productosbd: productosp })
                    })
                break;
            case "5":
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/persona/empresas/' + parseInt(datos.empresas_id, 10), { headers: { Authorization: ntoken } })
                    .then(response => {
                        //console.log(response)
                        if (response.status == 200) {
                            var datos = response.data.rows
                        } else if (response.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }
                        this.setState({ users: datos, loader: false })
                        let t = this
                        setTimeout(function () { t.datos() }, 200)
                    })
                break;
        }

    }
    componentWillMount() {
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        this.obtenerDatos()
    }


    handleClickOpen = () => {
        store.dispatch({
            type: "CREACION_USUARIO",
            creacionUsuario: !this.state.createUSer,
            usuarioEdicion: null,
            modovista: false
        })
        if (this.state.createUSer) {
            this.setState({ usuarioEdicion: null })
        }
    };
    buscar = (name) => event => {



        var cadena = event.target.value.toUpperCase()
        if (cadena.length > 0) {
            this.setState({ busqueda: true })
        } else {
            this.setState({ busqueda: false })
        }
        busquedaUsuario = []
        this.state.bonosbd.map((tipo, key) => {
            console.log(tipo.persona)
            if (tipo.descripcion.includes(cadena)) {
                busquedaUsuario.push(tipo)
            } else {

            }
        })
    }

    detallesUsuario = (usuario) => {
        store.dispatch({
            type: "CREACION_USUARIO",
            creacionUsuario: !this.state.edicicionUsuario,
            usuarioEdicion: usuario,
            modovista: true,
            edicicionUsuario: false
        })
        if (this.state.createUSer) {
            this.setState({ usuarioEdicion: null })
        }
    }

    pagination = (name, str) => {
        switch (name) {
            case 'tipo':
                this.setState({ animacionDerecha: false, animacionIzquierda: false })
                if (str == 'S') {
                    this.setState({ animacionDerecha: true, animacionIzquierda: false, paginadorIndex: this.state.paginadorIndex + 1, paginadorMinimo: this.state.paginadorMaximo + 1, paginadorMaximo: this.state.paginadorMaximo + this.state.paginadorTotal })
                } else {
                    this.setState({ animacionDerecha: false, animacionIzquierda: true, paginadorIndex: this.state.paginadorIndex - 1, paginadorMinimo: (this.state.paginadorMinimo - this.state.paginadorTotal), paginadorMaximo: this.state.paginadorMinimo - 1 })
                }
                this.datos()
                break;
        }
    }

    datos = () => {
        datosMostrarUsuario = []
        for (var i = this.state.paginadorMinimo; i <= this.state.paginadorMaximo; i++) {
            if (this.state.users[i] != null) {
                datosMostrarUsuario.push(this.state.users[i])
            } else {
                //console.log(this.state.tipoEquipo[i])
            }
        }
        //console.log("Minimno:" + this.state.paginadorMinimo + " - maximo:" + this.state.paginadorMaximo)
    }

    changeSelect = name => () => {
        switch (name) {
            case 'subFlota':
                this.setState({ subFlota: true, flotaPadre: false })
                break
            case 'flotaPadre':
                this.setState({ subFlota: false, flotaPadre: true })
                break
            case 'conCupo':
                if (this.state.conCupo) {
                    this.setState({ conCupo: false, cupo: 0 })
                } else {
                    this.setState({ conCupo: true, cupo: 0 })
                }
                break
        }
    }



    render() {
        var data = {}
        var dominio = store.getState().dominio
        const imagen = Server.hostMultimedia + dominio + "/persona/default.png"
        const { value } = this.state;
        //CArnet
        var dCarnet = null
        if (this.state.detallesCarnet != null) {
            dCarnet = this.state.detallesCarnet
        }
        var identificadores = '';
        this.state.identificadores.map((contacto) => {
            identificadores += contacto.codigos + ", "
        })
        identificadores = identificadores.substring(0, identificadores.length - 2)
        return (
            this.state.loader
                ?
                <div style={{ height: '100vh', width: '100%', paddingLeft: '15px', paddingRight: '15px' }} className="mx-auto my-auto">
                    <Loader style={{ height: '100vh', width: '100%' }} />
                </div>
                :
                <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>

                    <div className="row">
                        {
                            permisos.includes('C')
                                ?
                                <ExpansionPanel expanded={this.state.expanded} className="col-md-12" style={{ zIndex: 1098 }} onChange={() => this.expandir()}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography >CREAR NUEVA FLOTA</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div style={{ width: '100%' }}>

                                            <div className="row mx-auto">
                                                <div className="col-md-6 col-sm-12">
                                                    <label htmlFor="numordencompra" className="col-form-label">NOMBRE DE LA FLOTA</label>
                                                    <input autocomplete="off" id="numordencompra" value={this.state.nombreGuardar} disabled={this.state.modovista} onChange={this.handleChange('nombreGuardar')} className="form-control" type="text" />
                                                </div>
                                                <div className="col-md-6" >
                                                    <label htmlFor="nombreprov" className="col-form-label">CLIENTE</label>
                                                    <Selects
                                                        placeholder="Seleccione o escriba"
                                                        value={this.state.clienteSeleccionado}
                                                        onChange={this.handleChanges('clienteSeleccionado')}
                                                        options={this.state.clientesbd}
                                                        isDisabled={this.state.modovista}
                                                    />

                                                </div>
                                            </div>
                                            <div className="row mx-auto  bottom-aligned">
                                                <div className="col-md-6 mx-auto my-auto">
                                                    <div className="row">
                                                        <h6 className="mx-auto"><b>TIPO DE FLOTA</b></h6>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <Tooltip title="SI SELECCIONA FLOTA, SIGNIFICA QUE LA FLOTA A CREAR ES UNA FLOTA PRINCIPAL" placement="top">
                                                                <FormControlLabel
                                                                    control={<Checkbox value={this.state.flotaPadre} checked={this.state.flotaPadre} style={{ padding: '5px' }} onChange={this.changeSelect('flotaPadre')} />}
                                                                    label='FLOTA'
                                                                    style={{ margin: '0' }}
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Tooltip title="SI SELECCIONA SUBFLOTA, SIGNIFICA QUE LA FLOTA A CREAR ES UNA SUBFLOTA PERTENECIENTE A OTRA FLOTA" placement="top">
                                                                <FormControlLabel
                                                                    control={<Checkbox value={this.state.subFlota} checked={this.state.subFlota} style={{ padding: '5px' }} onChange={this.changeSelect('subFlota')} />}
                                                                    label='SUBFLOTA'
                                                                    style={{ margin: '0' }}
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-6" >
                                                    <label htmlFor="nombreprov" className="col-form-label">FLOTA PADRE</label>
                                                    <Selects
                                                        placeholder="Seleccione o escriba"
                                                        value={this.state.flotaSelecionada}
                                                        onChange={this.handleChanges('flotaSelecionada')}
                                                        options={this.state.flotasbdForm}
                                                        isDisabled={this.state.flotaPadre}
                                                    />

                                                </div>
                                            </div>
                                            <div className="row mx-auto bottom-aligned">

                                                <div className="col-md-3">
                                                    <label htmlFor="numordencompra" className="col-form-label">FECHA DE INICIO</label>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                        <DatePicker
                                                            format="yyyy-MM-dd"
                                                            id="datetime"
                                                            value={this.state.fechaInicial}
                                                            onChange={this.handleChangemoment}
                                                            ampm={false}
                                                            disabled={this.state.modovista}
                                                            className="rounded form-control"
                                                            style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="numordencompra" className="col-form-label">FECHA FIN</label>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                        <DatePicker
                                                            format="yyyy-MM-dd"
                                                            id="datetime"
                                                            value={this.state.fechaFinal}
                                                            onChange={this.handleChangemomentFinal}
                                                            ampm={false}
                                                            disabled={this.state.modovista}
                                                            className="rounded form-control"
                                                            style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                                <div className="col-md-3">
                                                    <Tooltip title="SI SELECCIONA CON CUPO," placement="top">
                                                        <FormControlLabel
                                                            control={<Checkbox value={this.state.conCupo} checked={this.state.conCupo} style={{ padding: '5px' }} onChange={this.changeSelect('conCupo')} />}
                                                            label='CON CUPO'
                                                            style={{ margin: '0' }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="numordencompra" className="col-form-label">CUPO</label>
                                                    <div class="input-group " style={{ marginBottom: '0!important' }}>
                                                        <div class="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                                            <span class="input-group-text" id="basic-addon1">$</span>
                                                        </div>
                                                        <input autocomplete="off" id="precioMinimoNuevo" className="form-control" type="number" min="1" value={this.state.cupo} disabled={!this.state.conCupo} onChange={this.handleChange('cupo')} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mx-auto  bottom-aligned" style={{ marginTop: '10px' }}>
                                                <div className="col-md-6">

                                                </div>
                                                {
                                                    this.state.modovista
                                                        ?
                                                        <div className="col-md-6">
                                                            <button className="btn btn-danger btn-block" onClick={() => this.cancelar()}>CERRAR</button>
                                                        </div>
                                                        :
                                                        <div className="col-md-3">
                                                            <button className="btn btn-danger btn-block" onClick={() => this.cancelar()}>CANCELAR</button>
                                                        </div>
                                                }
                                                {
                                                    this.state.modovista
                                                        ?
                                                        null
                                                        :
                                                        <div className="col-md-3">
                                                            <button className="btn btn-success btn-block" onClick={this.state.modoEditar ? () => this.editarBono() : () => this.registrarBono()}>GUARDAR</button>
                                                        </div>
                                                }

                                            </div>

                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                :
                                null
                        }
                    </div>
                    <Dialog fullScreen open={this.state.createUSer} onClose={this.handleClickOpen} TransitionComponent={Transition}>
                        <FormularioUsuario userEdit={this.state.usuarioEdicion} />
                    </Dialog>
                    {permisos.includes('R') ? this.state.tabla ?
                        <div className="row" style={{ marginTop: "15px", marginTop: '15px' }}>
                            <div className="col col-md-12" style={{ padding: 0 }}>
                                <Paper style={{ padding: '10px' }}>

                                    <MDBDataTable
                                        //striped
                                        className="col-md-12"
                                        searchLabel="Buscar"
                                        paginationLabel={["Anterior", "Siguente"]}
                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                        entriesLabel="Filas a mostrar"
                                        tbodyColor="primary-color"
                                        theadColor="#FF8800"
                                        exportToCSV={true}
                                        striped
                                        bordered
                                        autoWidth={false}
                                        responsive={true}
                                        hover
                                        data={data}
                                    />
                                </Paper>
                            </div>
                        </div>

                        :
                        <div>
                            <div className="row mx-auto" style={{ marginTop: '15px' }}>
                                <button disabled={this.state.paginadorMinimo == 0} className="btn btn-sm btn-outline-primary mx-auto" onClick={() => this.pagination('tipo', 'N')}>{"<<"}</button>
                                <div className="col col-md-6 mx-auto">
                                    <input type="text" placeholder="Buscar" className="form-control mx-auto" onChange={this.buscar('tipo')} />
                                </div>
                                <button disabled={this.state.paginadorMaximo + 1 >= this.state.users.length} className="btn btn-sm btn-outline-primary mx-auto" onClick={() => this.pagination('tipo', 'S')}>{">>"}</button>
                            </div>
                            <div className="row" style={{ marginTop: '15px' }}>

                                {this.state.busqueda
                                    ?
                                    busquedaUsuario.map((bono, key) => {
                                        return (
                                            <div className="col-md-6">
                                                <List className="border" style={{ backgroundColor: "#fff", padding: '0', height: '100px', marginTop: '10px' }} className="border rounded">
                                                    <ListItem>
                                                        <ListItemAvatar style={{ cursor: 'pointer' }} onClick={() => this.verDetalles(bono)}>
                                                            <Avatar className={bono.estado === "A" ? "succes" : ""}><BonosIcon /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={bono.descripcion}
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="textPrimary"
                                                                    >
                                                                        {'PRODUCTO: ' + bono.producto.descripcion}
                                                                    </Typography>
                                                                    {'CODIGO DE BARRAS: ' + bono.codigo_barras}
                                                                </React.Fragment>
                                                            }
                                                        />
                                                        {permisos.includes('D') ?
                                                            bono.estado === "A" ?
                                                                <ListItemSecondaryAction>
                                                                    <IconButton aria-label="Update" onClick={() => this.verEditar(bono)}>
                                                                        <EditICon />
                                                                    </IconButton>
                                                                    <IconButton aria-label="Delete" onClick={() => this.inactivarBono(bono._id)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                                :
                                                                <ListItemSecondaryAction>
                                                                    <IconButton aria-label="Update" onClick={() => this.verEditar(bono)}>
                                                                        <EditICon />
                                                                    </IconButton>
                                                                    <IconButton aria-label="Delete" onClick={() => this.activarBono(bono._id)}>
                                                                        <ActivarIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            : null
                                                        }
                                                    </ListItem>

                                                </List>
                                            </div>
                                        )
                                    })
                                    :
                                    this.state.flotasbd.map((bono, key) => {
                                        return (
                                            <div className="col-md-6">
                                                <List className="border" style={{ backgroundColor: "#fff", padding: '0', height: '100px', marginTop: '10px' }} className="border rounded">
                                                    <ListItem>
                                                        <ListItemAvatar style={{ cursor: 'pointer' }} onClick={() => this.verDetalles(bono)}>
                                                            <Avatar className={bono.estado === "A" ? "succes" : ""}><BonosIcon /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={bono.descripcion}
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="textPrimary"
                                                                    >
                                                                        {'PRODUCTO: ' + bono.producto.descripcion}
                                                                    </Typography>
                                                                    {'CODIGO DE BARRAS: ' + bono.codigo_barras}
                                                                </React.Fragment>
                                                            }
                                                        />
                                                        {permisos.includes('D') ?
                                                            bono.estado === "A" ?
                                                                <ListItemSecondaryAction>
                                                                    <IconButton aria-label="Update" onClick={() => this.verEditar(bono)}>
                                                                        <EditICon />
                                                                    </IconButton>
                                                                    <IconButton aria-label="Delete" onClick={() => this.inactivarBono(bono._id)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                                :
                                                                <ListItemSecondaryAction>
                                                                    <IconButton aria-label="Update" onClick={() => this.verEditar(bono)}>
                                                                        <EditICon />
                                                                    </IconButton>
                                                                    <IconButton aria-label="Delete" onClick={() => this.activarBono(bono._id)}>
                                                                        <ActivarIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            : null
                                                        }
                                                    </ListItem>

                                                </List>
                                            </div>
                                        )
                                    })

                                }
                            </div>
                            <div className="row" style={{ marginTop: '15px' }}>

                                <button className="btn btn-warning" onClick={() => this.handleClickOpen()}>CONFIGURACION DE FLOTAS</button>
                            </div>

                        </div>
                        : <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>}
                </div>
        );
    }
}



export default usuariosVista;
