/* eslint-disable react/no-deprecated */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unreachable */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */

import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { MDBDataTable } from 'mdbreact';
import Paper from '@material-ui/core/Paper';
import store from '../../../lib/store'
import moment from 'moment';
import Loader from '../../../components/Loader'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import swal from 'sweetalert'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from "@material-ui/core";
import Selects from 'react-select';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Snackbar from '@material-ui/core/Snackbar';
import { getEmpresasByTipo } from "services/serviciosEmpresa"
import { actualizarVenta, getMonitor, reenviarVenta } from "./serviciosResolucion"
import XMLViewer from "react-xml-viewer";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from "@material-ui/core/Slide"
import XMLicon from "assets/icons/xml.png"
import excelIcon from "assets/img/excel.png";
import {Update} from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip';
import ReactExport from "react-export-excel";
import ReplayIcon from '@material-ui/icons/Replay';
import EditICon from '@material-ui/icons/Edit';
import './index.css'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const idModulo = 50
var permisos = ''
class FacturacionElectronica extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Desde bd
            loader: false,
            selectedDate: moment(new Date()).subtract(6, "day").format("YYYY-MM-DD"),
            selectedDateFin: moment(new Date()).format("YYYY-MM-DD"),
            empresasbd: [],
            empresasSeleccionadas: [],
            estadosbd: [
                {
                    label: "No autorizadas",
                    value: 3
                }, {
                    label: "Devuelta en Recepcion",
                    value: 6
                }, {
                    label: "Recibida en espera de Autorizacion",
                    value: 5
                }, {
                    label: "Espera de Respuesta",
                    value: 7
                }, {
                    label: "Error al enviar a la DIAN",
                    value: 4
                }/*, {
                    label: "Aprobado",
                    value: 2
                }*/, {
                    label: "Sin Factura",
                    value: 99
                }
            ],
            estadosSeleccionados: [],
            columnTable: [
                {
                    label: 'Tienda',
                    field: 'alias',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Factura',
                    field: 'factura',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Estado PE',
                    field: 'estado',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Fecha Factura',
                    field: 'fecha',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Total Bruto',
                    field: 'tbruto',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Total Impuestos',
                    field: 'timpuestos',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Total Descuentos',
                    field: 'tdescuentos',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Total Factura',
                    field: 'tfactura',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'CUFE',
                    field: 'cufe',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Tipo Identificación',
                    field: 'tipoid',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Num. Identificación',
                    field: 'nidentificacion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Cliente',
                    field: 'cliente',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'XML',
                    field: 'acc',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Reenviar',
                    field: 'reenviar',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Acciones',
                    field: 'acciones',
                    sort: 'asc',
                    width: 150
                },
            ],
            datasetTable: [],
            expanded: true,
            multiEmpresas: "",
            multiEstado: "",
            datosMonitor: [],
            dialogXML: false,
            xmlSeleciconado: null,
            titleXML: "",
            excel: [],
            //
            dataEditar: null
        };
        store.subscribe(() => {
            this.setState({
                actualizarDatos: store.getState().actualizarvista,
                ventanaCreacion: store.getState().creacionConsecutivo,
            })
        })
    }


    openXML = (xml, data) => {
        const txt = `XML DE LA FACTURA ${data.prefijo + data.consecutivo}`
        this.setState({
            dialogXML: true,
            xmlSeleciconado: xml,
            titleXML: txt
        })
    }

    closeXML = () => {
        this.setState({
            dialogXML: false,
            xmlSeleciconado: null,
            titleXML: ""
        })
    }

    downloadXML = () => {
        const { xmlSeleciconado, titleXML } = this.state
        const element = document.createElement("a");
        const file = new Blob([xmlSeleciconado], { type: 'text/xml' });
        element.href = URL.createObjectURL(file);
        element.download = `${titleXML}.xml`;
        document.body.appendChild(element);
        element.click();
    }

    reenviarVenta =async (venta_id) =>{
        const venta_reenviada = await reenviarVenta(venta_id);
        if(venta_reenviada){
            swal("OK", "La venta ha sido reenviada correctamente", "success")
            this.ontenerDatos()
        }
    }
    actualizarVenta =async (venta_id) =>{
        const venta_reenviada = await actualizarVenta({id:venta_id});
        if(venta_reenviada){
            swal("OK", "factura enviada a actualizar en gurosoft", "success")
            this.ontenerDatos()
        }
    }


    componentWillMount() {
        //Permisos usuarios
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        this.ontenerDatos()
    }



    setearMiles = (numero) => {
        return (
            (new Intl.NumberFormat("de-DE").format(parseInt(numero)))
        )
    }

    ontenerDatos = async () => {
        this.setState({
            empresasbd: await getEmpresasByTipo(5)
        })
        this.buscarDatos()
    }

    handleChangemoment = (state) => date => {
        this.setState({
            [state]: date
        });
    }


    expandir = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    getEstado = (estado) => {
        let nombreEstado = ""
        switch (estado) {
            case 2:
                nombreEstado = "Aprobado"
                break;
            case 3:
                nombreEstado = "No autorizadas"
                break;
            case 4:
                nombreEstado = "Error al enviar a la DIAN"
                break;
            case 5:
                nombreEstado = "Devuelta en Recepcion"
                break;
            case 6:
                nombreEstado = "Recibida en espera de Autorizacion"
                break;
            case 7:
                nombreEstado = "Espera de Respuesta"
                break;
            case 99:
                nombreEstado = "Sin Facturacion"
                break;
        }
        return nombreEstado;
    }

    getTipoDocumento = (estado) => {
        let nombreDocumento = ""
        switch (estado) {
            case 11:
                nombreDocumento = "Registro civil"
                break;
            case 12:
                nombreDocumento = "Tarjeta de identidad"
                break;
            case 13:
                nombreDocumento = "Cedula de ciudadania"
                break;
            case 21:
                nombreDocumento = "Tarjeta de extranjeria"
                break;
            case 22:
                nombreDocumento = "Cedula de extranjeria"
                break;
            case 31:
                nombreDocumento = "NIT"
                break;
            case 41:
                nombreDocumento = "Pasaporte"
                break;
            case 42:
                nombreDocumento = "Documento de identificacion extranjero"
                break;
            case 50:
                nombreDocumento = "NIT de otro pais"
                break;
            case 91:
                nombreDocumento = "NUIP *"
                break;
        }
        return nombreDocumento;
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        switch (name) {
            case "empresasSeleccionadas":
                let empresas = ""
                value.map((empresa, key) => {
                    if (key === 0) {
                        empresas += empresa.value
                    } else {
                        empresas += "," + empresa.value
                    }
                })
                this.setState({
                    multiEmpresas: empresas
                })
                break
            case "estadosSeleccionados":
                let dato = ""
                value.map((item, key) => {
                    if (key === 0) {
                        dato += item.value
                    } else {
                        dato += "," + item.value
                    }
                })
                this.setState({
                    multiEstado: dato
                })
                break

        }
    }

    setearMiles = (numero) => {
        const { usuario } = store.getState()
        let moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case "COP":
                return (
                    (new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(2)))
                )
                break;
            case "USD":
                return (new Intl.NumberFormat("en-IN").format(parseFloat(numero).toFixed(3)))
                break;

        }
    }

    buscarDatos = async () => {
        const { selectedDate, selectedDateFin, multiEmpresas, multiEstado } = this.state
        const { empresasHijos } = store.getState()
        const data = {
            "tiendas": multiEmpresas === "" ? empresasHijos : multiEmpresas,
            "estado": multiEstado,
            fecha_inicial: typeof selectedDate === "string" ?
                (moment(selectedDate, "YYYY-MM-DD").format("YYYY-MM-DD ") + "00:00:00")
                : (moment(new Date(selectedDate)).format("YYYY-MM-DD ") + "00:00:00"),
            fecha_final: moment(selectedDateFin).format("YYYY-MM-DD ") + "24:00:00"
        }
        this.setState({
            datosMonitor: await getMonitor(data)
        })
        this.cargarTabla()
    }

    cargarTabla = () => {
        const { datosMonitor } = this.state
        let datasetTable = []
        let datosVentas = {}
        if (datosMonitor !== null) {
            datosMonitor.map((data) => {
                let datosTramas =""
                datosTramas = data.trama

                try {
                    datosVentas = data.atributos.factura.venta
                } catch (error) {
                    datosVentas = {}
                }
                datosVentas =
                    datasetTable.push({
                        tienda: data.alias,
                        factura: data.prefijo + data.consecutivo,
                        estado: this.getEstado(data.estado),
                        fecha: moment(data.fecha).format("DD/MM/YYYY"),
                        tbruto: "$ " + this.setearMiles((datosVentas.venta_total - datosVentas.impuesto_total) || 0),
                        timpuestos: "$ " + this.setearMiles(datosVentas.impuesto_total || 0),
                        tdescuentos: "$ " + this.setearMiles(datosVentas.descuento_total || 0),
                        tfactura: "$ " + this.setearMiles(datosVentas.venta_total || 0),
                        cufe: data.cufe,
                        tipoid: this.getTipoDocumento(datosVentas.tercero_tipo_documento),
                        nidentificacion: datosVentas.tercero_nit || "",
                        cliente: datosVentas.tercero_nombre || "",
                        acc: [<Tooltip title="VER EL XML" placement="top">
                            <button  className="btn btn-warning btn-sm" onClick={() => this.openXML(datosTramas, data)}><img height="25" width="25" src={XMLicon} /></button>
                        </Tooltip>],
                        reenviar:[
                        <Tooltip title="REENVIAR FACTURA" placement="top">
                            <button className="btn btn-primary btn-sm" onClick={() => this.reenviarVenta(data.id)}><ReplayIcon /></button>
                        </Tooltip>
                        ],
                        acciones:[ 
                        <Tooltip title={"ACTUALIZAR DATOS"} placement='top'>
                            <button className='btn btn-success' onClick={()=>this.actualizarVenta(data.id)} ><Update /></button>
                        </Tooltip>
                        ]
                    })
            })
        }
        this.setState({
            datasetTable
        })
    }


    editarDato = (data) => {
        this.setState({
            dataEditar: data
        })
    }


    render() {
        const { usuario } = store.getState()
        const { expanded, datasetTable, loader, selectedDate, selectedDateFin,
            empresasSeleccionadas, empresasbd, columnTable, estadosSeleccionados, estadosbd, dialogXML, xmlSeleciconado, titleXML, dataEditar } = this.state
        return (
            loader
                ?
                <Loader />
                :
                <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                    <div className="row" style={{ marginTop: "15px" }}>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            variant="error"
                            open={datasetTable.length === 0}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            message="NO HAY FACTURAS ELECTRONICAS PARA ESTOS PARAMETROS DE BUSQUEDA."
                        />
                        <ExpansionPanel expanded={expanded} className="col-md-12" style={{ zIndex: 1098 }} onChange={() => this.expandir()}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography >FILTROS</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div style={{ width: '100%' }}>
                                    <div className="row mx-auto">
                                        {
                                            usuario.tipo === "5"
                                                ?
                                                null
                                                :
                                                <div className="col-md-4" >
                                                    <label htmlFor="nombreprov" className="col-form-label">KIOSCO</label>
                                                    <Selects
                                                        placeholder="Seleccione o escriba"
                                                        value={empresasSeleccionadas}
                                                        onChange={this.handleChanges('empresasSeleccionadas')}
                                                        options={empresasbd}
                                                        isMulti
                                                    />
                                                </div>
                                        }
                                        <div className={usuario.tipo === "5" ? "col-md-3" : "col-md-2"} >
                                            <label htmlFor="nombreprov" className="col-form-label">ESTADOS</label>
                                            <Selects
                                                placeholder="Seleccione o escriba"
                                                value={estadosSeleccionados}
                                                onChange={this.handleChanges('estadosSeleccionados')}
                                                options={estadosbd}
                                                isMulti
                                            />
                                        </div>
                                        <div className={usuario.tipo === "5" ? "col-md-3" : "col-md-2"} >
                                            <label htmlFor="numordencompra" className="col-form-label">FECHA DE INICIO</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                <DatePicker
                                                    format="dd/MM/yyyy"
                                                    id="datetime"
                                                    value={selectedDate}
                                                    onChange={this.handleChangemoment("selectedDate")}
                                                    ampm={false}
                                                    disableFuture={true}
                                                    className="rounded form-control"
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className={usuario.tipo === "5" ? "col-md-3" : "col-md-2"}  >
                                            <label htmlFor="nombreprov" className="col-form-label">FECHA FIN</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                <DatePicker
                                                    format="dd/MM/yyyy"
                                                    id="datetime"
                                                    value={selectedDateFin}
                                                    onChange={this.handleChangemoment("selectedDateFin")}
                                                    ampm={false}
                                                    className="rounded form-control"
                                                    disableFuture={true}
                                                    minDate={moment(new Date(selectedDate.toString())).format("YYYY-MM-DD HH:mm")}
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className={usuario.tipo === "5" ? "col-md-3 col-sm-12 bottom-aligned" : "col-md-2 col-sm-12 bottom-aligned"}  >
                                            <button className="btn btn-success btn-block" onClick={() => this.buscarDatos()}>CONSULTAR</button>
                                        </div>
                                    </div>


                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>

                    <div className="row" style={{ marginTop: '10px', height: '100%' }}>
                        {permisos.includes('R') ? <div className="col col-md-12" style={{ padding: 0 }}>
                            <Paper style={{ padding: '15px' }}>
                                <div className="row">
                                    <h3 className="mx-auto"><b>Monitor Factura Electronica</b></h3>
                                </div>
                                <div className="row">
                                    {/*<div className="col col-md-12">
                                        <div className="row">
                                            <h5 className="mx-auto"><b>Estado Factura</b></h5>
                                        </div>

                                    </div>
                                    <div className="col col-md-4">
                                        <div className="row">
                                            <h5 className="mx-auto"><b>Fecha factura</b></h5>
                                        </div>
                                    </div>*/}
                                </div>
                                {/*<div className="row">
                                    <div className="col col-md-12">
                                        <div className="row">
                                            <div className="col col-md-2">
                                                <label htmlFor="numordencompra" className="col-form-label">No Autorizadas</label>
                                                <input id="numordencompra" disabled className="form-control" type="text" value={0} />
                                            </div>
                                            <div className="col col-md-2">
                                                <label htmlFor="numordencompra" className="col-form-label">Devuelta en recepcion</label>
                                                <input id="numordencompra" disabled className="form-control" type="text" value={0} />
                                            </div>
                                            {/*</div>
                                        <div className="row">
                                            <div className="col col-md-4">
                                                <label htmlFor="numordencompra" className="col-form-label">Recibida en espera de autorizacion</label>
                                                <input id="numordencompra" disabled className="form-control" type="text" value={0} />
                                            </div>
                                            <div className="col col-md-4">
                                                <label htmlFor="numordencompra" className="col-form-label">En espera de Autorizacion</label>
                                                <input id="numordencompra" disabled className="form-control" type="text" value={0} />
                                            </div>
                                        </div>
                                    </div>*/}
                                {/*<div className="col col-md-3">
                                        <div className="row">
                                            <div className="col col-md-6">
                                                <label htmlFor="numordencompra" className="col-form-label">Desde</label>
                                                <input id="numordencompra" disabled className="form-control" type="text" value={0} />
                                            </div>
                                            <div className="col col-md-6">
                                                <label htmlFor="numordencompra" className="col-form-label">Hasta</label>
                                                <input id="numordencompra" disabled className="form-control" type="text" value={0} />
                                            </div>

                                        </div>
                                    </div>
                                </div>*/}
                                {
                                    dataEditar != null
                                    && <div className="row">
                                        <div className="col-md-3">
                                            <label htmlFor="numordencompra" className="col-form-label">Desde</label>
                                            <input id="numordencompra" disabled className="form-control" type="text" value={0} />
                                        </div>
                                    </div>
                                }
                                <Dialog
                                    open={dialogXML}
                                    fullScreen
                                    aria-labelledby="form-dialog-title"
                                    style={{ padding: '0' }}
                                    TransitionComponent={Transition}
                                >
                                    <DialogTitle style={{ padding: " 5px", background: "#f44336" }} >
                                        <div className="row mx-auto">
                                            <h5 className="mx-auto" style={{ color: "white", fontWeight: "bold" }}>{titleXML}</h5>
                                        </div>
                                    </DialogTitle>
                                    <DialogContent style={{ padding: '0' }}>
                                        <div style={{ margin: "20px" }}>
                                            <XMLViewer xml={xmlSeleciconado} />
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <button className="btn btn-primary" onClick={() => this.downloadXML()}>
                                            DESCARGAR
                                            </button>
                                        <button className="btn btn-danger" onClick={() => this.closeXML()}>
                                            CERRAR
                                            </button>
                                    </DialogActions>
                                </Dialog>
                                <ExcelFile
                                    filename={"FACTURACIONES ELECTRONICAS"}
                                    element={<Tooltip title="EXPORTAR A EXCEL" placement="top">
                                        <button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button>
                                    </Tooltip>}
                                >
                                    <ExcelSheet data={datasetTable} name="FACTURACIONES">
                                        <ExcelColumn label="Tienda" value="tienda" />
                                        <ExcelColumn label="Factura" value="factura" />
                                        <ExcelColumn label="Estado PE" value="estado" />
                                        <ExcelColumn label="Fecha" value="fecha" />
                                        <ExcelColumn label="Total Bruto" value="tbruto" />
                                        <ExcelColumn label="Total Impuestos" value="timpuestos" />
                                        <ExcelColumn label="Total Descuentos" value="tdescuentos" />
                                        <ExcelColumn label="Total Factura" value="tfactura" />
                                        <ExcelColumn label="CUFE" value="cufe" />
                                        <ExcelColumn label="Tipo identificacion" value="tipoid" />
                                        <ExcelColumn label="Num. Identificacion" value="nidentificacion" />
                                        <ExcelColumn label="Cliente" value="cliente" />

                                    </ExcelSheet>
                                </ExcelFile>
                                <MDBDataTable
                                    className="col-md-12"
                                    searchLabel="Buscar"
                                    paginationLabel={["Anterior", "Siguente"]}
                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                    entriesLabel="Filas a mostrar"
                                    tbodyColor="primary-color"
                                    theadColor="#FF8800"
                                    exportToCSV={true}
                                    striped
                                    bordered
                                    autoWidth={false}
                                    responsive={true}
                                    hover
                                    data={{
                                        columns: columnTable,
                                        rows: datasetTable
                                    }}
                                    style={{ fontSize: '11px' }}
                                    sortable={true}
                                />
                            </Paper>
                        </div> : <h5 className="mx-auto"><b>NO TIENE PERMISOS PARA VER LOS DATOS</b></h5>}
                    </div>
                </div >
        )
    }
}


export default FacturacionElectronica