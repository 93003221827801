import React from 'react';
import { SketchPicker } from 'react-color';
import store from '../../../lib/store'


var colorprin = "#f44336"
class DialogColor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            background: '#f44336',
        };
    }


    componentWillMount(){
        var {color} = this.props
        this.setState({background:color})
    }

    handleChangeComplete = (color) => {
        var col = color.hex
        this.setState({background:col})
    };

    guardarColor = () => {
        store.dispatch({
            type: "OBTENCION_COLOR",
            color: this.state.background,
            ventanaColor: false
        })
    }

    render() {
        var { color } = this.props
        colorprin = color
        return (
            <div>
                <SketchPicker
                    color={this.state.background}
                    onChangeComplete={this.handleChangeComplete}
                    width="400px"
                />
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn btn-success" style={{ float: 'right', marginRight: '15px', marginTop: '10px' }} onClick={this.guardarColor.bind(this)}>Guardar</button>
                    </div>
                </div>
            </div>
        );
    }
}
export default DialogColor