import React from "react";
import PropTypes from "prop-types";
import Selects from 'react-select';
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import Update from "@material-ui/icons/Update";
import Accessibility from "@material-ui/icons/Accessibility";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import logoTerpel from '../../assets/img/logos/logo_terpel.png'
import logoDeuna from '../../assets/img/logos/logoo_r2_c2.png'
import Tooltip from '@material-ui/core/Tooltip';
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import { Bar, HorizontalBar } from 'react-chartjs-2';
import * as Highcharts from 'highcharts';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Axios from 'axios'
import Server from '../../lib/server'
import store from '../../lib/store'
import ordenCompraIcon from "@material-ui/icons/LocalGroceryStore"
import BonosIcon from "@material-ui/icons/LocalActivity"
import Person from "@material-ui/icons/Person";
import ProdcutoIcon from "@material-ui/icons/Fastfood"
import BajaRotacionICon from "@material-ui/icons/TrendingDown"
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';
import moment from 'moment'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import resolucionIcon from "@material-ui/icons/FormatListNumbered"
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';
import 'moment/locale/es';
import jsPDF from 'jspdf';
import { Typography } from "@material-ui/core";
import ReactExport from "react-export-excel";
import { isUndefined } from "util";
import DominioIcon from "@material-ui/icons/LocalGasStation";
import pdfIcon from "../../assets/img/pdf.png";
import excelIcon from "../../assets/img/excel.png";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Dashboard extends React.Component {
	state = {
		value: 0,
		numTiendas: 0,
		numVentas: 0,
		numEmpleados: 0,
		ventasDiarias: 0,
		productosTiendas: 0,
		productosMasVendidosLabels: [],
		productosMasVendidosCantidades: [],
		productosMenosVendidosLabels: [],
		productosMenosVendidosCantidades: [],
		productosMasVendidos: [],
		filtoProductosMasVendidos: 5,
		movimientosbd: [],
		sesionesActivas: [],
		productosEnAlerta: [],
		productoBajoRotamiento: [],
		ventasPorDiaLabel: [],
		ventasPorDiaCant: [],
		fechaActual: new Date(),
		venta_meses_tiempo: 2020,
		bonos_meses_tiempo: 2020,
		//Productos mas vendidos
		productoMasVendidoTiempo: 0,
		promotoresTiempo: 0,
		productoMenosVendidoTiempo: 0,
		promotoresMasLabels: [],
		promotoresMasCants: [],
		//
		ventaMesesLabels: [],
		ventaMesesCant: [],
		alertaConsecutivos: [],
		//
		masRentablesLabels: [],
		masRentablesCants: [],
		filtroMAsVendidos: false,
		multiEmpresasMAsVendidos: "",
		empresaSeleccionadaMasVendidos: [],
		expanded: false,
		excelMasVendidos: [],
		excelMenosVendidos: [],
		excelVentas: [],
		excelMeses: [],
		excelRentables: [],
		excelPromotoresMas: [],
		imprimirMasVendidos: false,
		imprimirPromotores: false,
		imprimirMenosVendidos: false,
		imprimirdias: false,
		imprimirMeses: false,
		//voucher
		bonosImpresos: 0,
		campanasVigentes: 0,
		impresionesPorDiaLabel: [],
		impresionesPorDiaCant: [],
		impresionesMesesLabel: [],
		impresionesMesesCant: [],
		excelBonosXDia: [],
		excelBonoxMeses: [],
		bonosTiendasCant: [],
		bonosTiendasLabel: [],
		tipoReporteBonos: 0,
		excelBonosTiendas: [],
		imprimirBonosEstaciones: false


	};
	handleChange = (event, value) => {
		this.setState({ value });
	};

	handleChangeIndex = index => {
		this.setState({ value: index });
	};

	timer() {
		this.setState({
			currentCount: this.state.currentCount - 1
		})
		if (this.state.currentCount < 1) {
			clearInterval(this.intervalId);
		}
		const tipoDominio = store.getState().tipoDominio
		if (tipoDominio === "V") {
			this.obtenerDashboardVoucher()
		} else {
			this.obtenerDashBoard()
		}
	}

	setearMiles = (numero) => {
		var usuario = store.getState().usuario
		var moneda = usuario.empresas.paises_moneda
		switch (moneda) {
			case "COP":
				return (
					(new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(3)))
				)
				break;
			case "USD":
				return (new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(numero))
				break;

		}
	}

	componentDidMount() {
		this.intervalId = setInterval(this.timer.bind(this), 15000);
		//console.clear()
	}

	componentWillUnmount() {
		clearInterval(this.intervalId);
	}

	imprimirMasVendidos = (estado, referencia, grafican, div, nombre) => {
		this.setState({
			[estado]: true
		})
		const nd = document.getElementById(div);
		let chart = this.refs[referencia].chartInstance
		var grafica = `<img width="520px" src="${chart.toBase64Image(chart)}"/>`
		document.getElementById(grafican).innerHTML = grafica
		var download = {
			"template": nd.innerHTML,
			"filename": nombre + ".pdf"
		}
		//console.log(download)
		Axios.post(Server.HostPDF + Server.puertoPdf + '/api/pdf/download', download)
			.then(response => {
				//console.log(response)
				var url = response.data.url
				var link = document.createElement('a');
				link.setAttribute("target", "_blank")
				link.download = response.data.filename;
				link.href = url;
				link.click();
				this.setState({
					[estado]: false
				})
			})
	}


	obtenerDashboardVoucher() {
		var fechaActuall = moment().format('YYYY-MM-DD')
		var usuario = store.getState().usuario
		var hijos = store.getState().empresasHijos
		var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
		this.setState({ fechaActual: new Date() })
		//
		switch (usuario.tipo) {
			case "1":
			case "2":
			case "3":
			case "4":
				var dataIndicadores = {
					"empresas_id": this.state.filtroMAsVendidos ? this.state.multiEmpresasMAsVendidos : hijos,
					"dominio_tipo": "V",
					"current_date": fechaActuall,
					"empresas_tipos": 5
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/indicadores/', dataIndicadores, { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.status == 200) {
							this.setState({
								numTiendas: res.data.tiendas,
								numVentas: res.data.numero_ventas,
								numEmpleados: res.data.empleados,
								ventasDiarias: res.data.ventas,
								campanasVigentes: res.data.campanasVigentes,
								bonosImpresos: res.data.bonosImpresos
							})
						} else if (res.status == 205) {
							swal("ERROR", "LA SESION HA CADUCADO", "warning")
							setTimeout(function () { window.location.href = '/'; }, 5000);
						}
					})
					.catch(() => {
						//console.log(err)
					})
				var dataMeses = {
					"empresas_id": this.state.filtroMAsVendidos ? this.state.multiEmpresasMAsVendidos : hijos,
					"accion": "reporte_voucher",
					"tipo_reporte": 4,
					"query_year": this.state.bonos_meses_tiempo,
					"current_date": fechaActuall
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataMeses, { headers: { Authorization: ntoken } })
					.then((res) => {
						//console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							var excel = []
							res.data.data.map((producto) => {

								productosLabels.push(producto.label)
								productosCants.push(producto.bonosImpresos)
								excel.push({
									"mes": producto.label,
									"bonos_impresos": producto.bonosImpresos,
								})
							})
							this.setState({ impresionesMesesLabel: productosLabels, impresionesMesesCant: productosCants, excelBonoxMeses: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				var dataDias = {
					"empresas_id": this.state.filtroMAsVendidos ? this.state.multiEmpresasMAsVendidos : hijos,
					"accion": "reporte_voucher",
					"tipo_reporte": 2,
					"current_date": fechaActuall
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataDias, { headers: { Authorization: ntoken } })
					.then((res) => {
						//console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							var excel = []
							res.data.data.map((producto) => {

								productosLabels.push(producto.dia)
								productosCants.push(producto.bonosImpresos)
								excel.push({
									"dia": producto.label,
									"bonos_impresos": producto.bonosImpresos,
								})
							})
							this.setState({ impresionesPorDiaLabel: productosLabels, impresionesPorDiaCant: productosCants, excelBonosXDia: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				var dataEmpresas = {
					"empresas_id": this.state.filtroMAsVendidos ? this.state.multiEmpresasMAsVendidos : hijos,
					"accion": "reporte_voucher_estacion",
					"tipo_reporte": this.state.tipoReporteBonos,
					"current_date": fechaActuall
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataEmpresas, { headers: { Authorization: ntoken } })
					.then((res) => {
						console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							var excel = []
							res.data.data.map((producto) => {

								productosLabels.push(producto.estacion.alias)
								productosCants.push(producto.bonos_impresos)
								excel.push({
									"codigo": producto.estacion.codigo,
									"estacion": producto.estacion.alias,
									"bonos_impresos": producto.bonos_impresos,
								})
							})
							this.setState({ bonosTiendasLabel: productosLabels, bonosTiendasCant: productosCants, excelBonosTiendas: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				break
			case "5":
				//indicadores
				var dataIndicadores = {
					"empresas_id": usuario.empresas_id,
					"dominio_tipo": "V",
					"current_date": fechaActuall,
					"empresas_tipos": 5
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/indicadores/', dataIndicadores, { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.status == 200) {
							this.setState({
								numTiendas: res.data.tiendas,
								numVentas: res.data.numero_ventas,
								numEmpleados: res.data.empleados,
								ventasDiarias: res.data.ventas,
								productosTiendas: res.data.productos_empresas,
								campanasVigentes: res.data.campanasVigentes,
								bonosImpresos: res.data.bonosImpresos
							})
						} else if (res.status == 205) {
							swal("ERROR", "LA SESION HA CADUCADO", "warning")
							setTimeout(function () { window.location.href = '/'; }, 3000);
						}
					})
					.catch(() => {
						//console.log(err)
					})
				var data = {
					"empresas_id": usuario.empresas_id,
					"accion": "reporte_voucher",
					"tipo_reporte": 4,
					"query_year": this.state.bonos_meses_tiempo,
					"current_date": fechaActuall
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', data, { headers: { Authorization: ntoken } })
					.then((res) => {
						console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							var excel = []
							res.data.data.map((producto) => {

								productosLabels.push(producto.label)
								productosCants.push(producto.bonosImpresos)
								excel.push({
									"mes": producto.label,
									"bonos_impresos": producto.bonosImpresos,
								})
							})
							this.setState({ impresionesMesesLabel: productosLabels, impresionesMesesCant: productosCants, excelBonoxMeses: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				var dataDias = {
					"empresas_id": usuario.empresas_id,
					"accion": "reporte_voucher",
					"tipo_reporte": 2,
					"current_date": fechaActuall
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataDias, { headers: { Authorization: ntoken } })
					.then((res) => {
						console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							var excel = []
							res.data.data.map((producto) => {

								productosLabels.push(producto.dia)
								productosCants.push(producto.bonosImpresos)
								excel.push({
									"dia": producto.label,
									"bonos_impresos": producto.bonosImpresos,
								})
							})
							this.setState({ impresionesPorDiaLabel: productosLabels, impresionesPorDiaCant: productosCants, excelBonosXDia: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				break
		}
	}

	obtenerDashBoard() {
		var fechaActuall = moment().format('YYYY-MM-DD')
		var usuario = store.getState().usuario
		var hijos = store.getState().empresasHijos
		var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
		this.setState({ fechaActual: new Date() })
		switch (usuario.tipo) {
			case "1":
			case "2":
			case "3":
			case "4":
				var dataIndicadores = {
					empresas_id: this.state.filtroMAsVendidos ? this.state.multiEmpresasMAsVendidos : hijos,
					tipo_reporte: 0,
					empresas_tipos: 5,
					current_date: fechaActuall
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/indicadores/', dataIndicadores, { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.status == 200) {
							this.setState({ numTiendas: res.data.tiendas, numVentas: res.data.numero_ventas, numEmpleados: res.data.empleados, ventasDiarias: res.data.ventas })
						} else if (res.status == 205) {
							swal("ERROR", "LA SESION HA CADUCADO", "warning")
							setTimeout(function () { window.location.href = '/'; }, 5000);
						}
					})
					.catch(() => {
						//console.log(err)
					})
				//Producto mas vendidos
				var dataMasVendidos = {
					accion: 'productos_mas_vendidos',
					empresas_id: this.state.filtroMAsVendidos ? this.state.multiEmpresasMAsVendidos : hijos,
					tipo_reporte: this.state.productoMasVendidoTiempo,
					empresas_tipos: 5,
					current_date: fechaActuall,
					limit: 10
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataMasVendidos, { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							var excel = []
							res.data.data.map((producto) => {
								productosLabels.push(producto.descripcion)
								productosCants.push(producto.mas_vendidos)
								excel.push({
									plu: producto.plu,
									descripcion: producto.descripcion,
									mas_vendidos: parseInt(producto.mas_vendidos),
									total: '$' + this.setearMiles(producto.total)
								})
							})
							this.setState({ productosMasVendidosLabels: productosLabels, productosMasVendidosCantidades: productosCants, excelMasVendidos: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				//Promotores que mas venden
				var dataPromotoresMas = {
					accion: 'ventas_promotor',
					empresas_id: this.state.filtroMAsVendidos ? this.state.multiEmpresasMAsVendidos : hijos,
					tipo_reporte: this.state.promotoresTiempo,
					current_date: fechaActuall,
					limit: 10
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataPromotoresMas, { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							//console.log(res)
							var excel = []
							res.data.data.map((producto) => {
								productosLabels.push(producto.operador)
								productosCants.push(producto.total)
								excel.push({
									tienda: producto.tienda,
									descripcion: producto.operador,
									total: '$' + this.setearMiles(producto.total)
								})
							})
							this.setState({ promotoresMasLabels: productosLabels, promotoresMasCants: productosCants, excelPromotoresMas: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				//Productos menos vendidos
				Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/productos_menos_vendidos/5/' + (this.state.filtroMAsVendidos ? this.state.multiEmpresasMAsVendidos : hijos) + '/' + this.state.productoMenosVendidoTiempo + '/null/null/10', { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							var excel = []
							res.data.data.map((producto) => {
								productosLabels.push(producto.descripcion)
								productosCants.push(producto.cantidad_vendida)
								excel.push({
									plu: producto.plu,
									descripcion: producto.descripcion,
									cantidad_vendida: parseInt(producto.cantidad_vendida),
									precio: '$' + this.setearMiles(producto.precio)
								})
							})
							this.setState({ productosMenosVendidosLabels: productosLabels, productosMenosVendidosCantidades: productosCants, excelMenosVendidos: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				//meses
				var data = {
					"accion": "ventas_meses",
					"tipo_reporte": 1,
					"query_year": this.state.venta_meses_tiempo,
					"current_date": fechaActuall,
					"empresas_id": this.state.filtroMAsVendidos ? this.state.multiEmpresasMAsVendidos : hijos
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', data, { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							var excel = []
							res.data.data.map((producto) => {
								productosLabels.push(producto.mes_label)
								excel.push({
									"mes": producto.mes_label,
									"venta_neta": isUndefined(producto.total_venta_neta) ? '$' + 0 : '$' + this.setearMiles(producto.total_venta_neta),
									"impuestos": isUndefined(producto.impuestos_total) ? '$' + 0 : '$' + this.setearMiles(producto.impuestos_total),
									"total": isUndefined(producto.total_venta) ? '$' + 0 : '$' + this.setearMiles(producto.total_venta)
								})
								productosCants.push(producto.total_venta)
							})
							this.setState({ ventaMesesLabels: productosLabels, ventaMesesCant: productosCants, excelMeses: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				//VENTAS
				Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/reporte_ventas/5/' + (this.state.filtroMAsVendidos ? this.state.multiEmpresasMAsVendidos : hijos) + '/2/null/null/0', { headers: { Authorization: ntoken } })
					.then((res) => {
						var fecha = new Date()
						var ventas = []
						var labels = []
						var excel = []
						if (res.data != null) {
							//console.log(res.data)
							//preparar dias 
							for (var r = 1; r <= fecha.getDate(); r++) {
								ventas[r] = 0
								labels[r] = r < 10 ? "0" + r : r;
							}

							res.data.data.map((ventaDia) => {

								if (moment(ventaDia.fecha.replace("T00:00:00.000Z", "")).format("MM") === moment().format("MM")) {
									////console.log(moment(ventaDia.fecha.replace("T00:00:00.000Z", "")).format("MM"), ventaDia.fecha.replace("T00:00:00.000Z", ""))
									excel.push({
										fecha: moment(new Date(ventaDia.fecha)).add("day", 1).format("DD/MM/YYYY"),
										tienda: ventaDia.razon_social,
										ventas: '$' + this.setearMiles(ventaDia.total)
									})
									let diaVenta = moment(ventaDia.fecha.replace("T00:00:00.000Z", "")).format("D")
									ventas[parseInt(diaVenta)] += parseFloat(ventaDia.total);
								}
							})
							//console.log(excel)
							this.setState({ ventasPorDiaCant: ventas, ventasPorDiaLabel: labels, excelVentas: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				break
			case "5":
				//indicadores
				var dataIndicadores = {
					empresas_id: usuario.empresas_id,
					tipo_reporte: 0,
					empresas_tipos: 5,
					current_date: fechaActuall
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/indicadores/', dataIndicadores, { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.status == 200) {
							this.setState({ numTiendas: res.data.tiendas, numVentas: res.data.numero_ventas, numEmpleados: res.data.empleados, ventasDiarias: res.data.ventas, productosTiendas: res.data.productos_empresas })
						} else if (res.status == 205) {
							swal("ERROR", "LA SESION HA CADUCADO", "warning")
							setTimeout(function () { window.location.href = '/'; }, 3000);
						}
					})
					.catch(() => {
						//console.log(err)
					})
				//USUARIOS ACTIVOS
				Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/alarmas_usuarios_activos/' + usuario.tipo + '/' + usuario.empresas_id + '/100/null/null/10', { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.data != null) {
							var sesiones = []
							res.data.data.map((producto) => {
								sesiones.push(producto)
							})
							this.setState({ sesionesActivas: sesiones })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				//PRODUCTOS EN ALERTA
				Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/alarmas_productos_baja_existencia/' + usuario.tipo + '/' + usuario.empresas_id + '/100/null/null/10', { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.data != null) {
							var productosAlerta = []
							//
							res.data.data.map((producto) => {
								productosAlerta.push(producto)
							})
							this.setState({ productosEnAlerta: productosAlerta })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				//meses
				var data = {
					"accion": "ventas_meses",
					"tipo_reporte": 1,
					"query_year": this.state.venta_meses_tiempo,
					"current_date": fechaActuall,
					"empresas_id": usuario.empresas_id
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', data, { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							var excel = []
							res.data.data.map((producto) => {
								productosLabels.push(producto.mes_label)
								excel.push({
									"mes": producto.mes_label,
									"venta_neta": isUndefined(producto.total_venta_neta) ? '$' + 0 : '$' + this.setearMiles(producto.total_venta_neta),
									"impuestos": isUndefined(producto.impuestos_total) ? '$' + 0 : '$' + this.setearMiles(producto.impuestos_total),
									"total": isUndefined(producto.total_venta) ? '$' + 0 : '$' + this.setearMiles(producto.total_venta)
								})
								productosCants.push(producto.total_venta)
							})
							this.setState({ ventaMesesLabels: productosLabels, ventaMesesCant: productosCants, excelMeses: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				// ALERTA DE CONSECUTIVOS
				Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/alarmas_consecutivos/' + usuario.tipo + '/' + usuario.empresas_id + '/100/null/null/10', { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.data.data != null) {
							var productosAlerta = []
							//
							res.data.data.map((producto) => {
								productosAlerta.push(producto)
							})
							this.setState({ alertaConsecutivos: productosAlerta })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				//VENTAS
				Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/reporte_ventas/5/' + usuario.empresas_id + '/1/null/null/0', { headers: { Authorization: ntoken } })
					.then((res) => {
						var fecha = new Date()
						var ventas = []
						var labels = []
						var excel = []
						if (res.data != null) {

							//preparar dias 
							for (var r = 1; r <= fecha.getDate(); r++) {
								ventas[r] = 0
								labels[r] = r < 10 ? "0" + r : r;
							}


							res.data.data.map((ventaDia, k) => {

								if (moment(ventaDia.fecha.replace("T00:00:00.000Z", "")).format("MM") === moment().format("MM")) {
									////console.log(moment(ventaDia.fecha.replace("T00:00:00.000Z", "")).format("MM"), ventaDia.fecha.replace("T00:00:00.000Z", ""))
									excel.push({
										fecha: moment(new Date(ventaDia.fecha)).add("day", 1).format("DD/MM/YYYY"),
										tienda: ventaDia.razon_social,
										ventas: '$' + this.setearMiles(ventaDia.total)
									})
									let diaVenta = moment(ventaDia.fecha.replace("T00:00:00.000Z", "")).format("D")
									ventas[parseInt(diaVenta)] += parseFloat(ventaDia.total);
								}
							})
							this.setState({ ventasPorDiaCant: ventas, ventasPorDiaLabel: labels, excelVentas: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				//PRODUCTOS BAJO ROTAMIENTO
				Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/alarmas_productos_no_se_han_venden/' + usuario.tipo + '/' + usuario.empresas_id + '/100/null/null/10', { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.data != null) {
							var productosbr = []
							res.data.data.map((producto) => {
								productosbr.push(producto)
							})
							this.setState({ productoBajoRotamiento: productosbr })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				//Productos mas vendidos
				var dataMasVendidos = {
					accion: 'productos_mas_vendidos',
					empresas_id: usuario.empresas_id,
					tipo_reporte: this.state.productoMasVendidoTiempo,
					empresas_tipos: 5,
					current_date: fechaActuall,
					limit: 10
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataMasVendidos, { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							var excel = []
							res.data.data.map((producto) => {
								productosLabels.push(producto.descripcion)
								productosCants.push(producto.mas_vendidos)
								excel.push({
									plu: producto.plu,
									descripcion: producto.descripcion,
									mas_vendidos: parseInt(producto.mas_vendidos),
									total: '$' + this.setearMiles(producto.total)
								})
							})
							this.setState({ productosMasVendidosLabels: productosLabels, productosMasVendidosCantidades: productosCants, excelMasVendidos: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				//Promotores que mas venden
				var dataPromotoresMas = {
					accion: 'ventas_promotor',
					empresas_id: usuario.empresas_id,
					tipo_reporte: this.state.promotoresTiempo,
					current_date: fechaActuall,
					limit: 10
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataPromotoresMas, { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							//console.log(res)
							var excel = []
							res.data.data.map((producto) => {
								productosLabels.push(producto.operador)
								productosCants.push(producto.total)
								excel.push({
									tienda: producto.tienda,
									descripcion: producto.operador,
									total: '$' + this.setearMiles(producto.total)
								})
							})
							this.setState({ promotoresMasLabels: productosLabels, promotoresMasCants: productosCants, excelPromotoresMas: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				//Productos menos vendidos
				Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/productos_menos_vendidos/' + usuario.tipo + '/' + usuario.empresas_id + '/' + this.state.productoMenosVendidoTiempo + '/null/null/10', { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							var excel = []
							res.data.data.map((producto) => {
								productosLabels.push(producto.descripcion)
								productosCants.push(producto.cantidad_vendida)
								excel.push({
									plu: producto.plu,
									descripcion: producto.descripcion,
									cantidad_vendida: parseInt(producto.cantidad_vendida),
									precio: '$' + this.setearMiles(producto.precio)
								})
							})
							this.setState({ productosMenosVendidosLabels: productosLabels, productosMenosVendidosCantidades: productosCants, excelMenosVendidos: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				//Productos mas rentables
				Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/productos_mas_rentables/' + usuario.tipo + '/' + usuario.empresas_id + '/1/null/null/0', { headers: { Authorization: ntoken } })
					.then((res) => {
						if (res.data != null) {
							//console.log(res.data)
							var lables = []
							var cants = []
							res.data.data.map((producto) => {
								lables.push(producto.descripcion)
								cants.push({
									costo: producto.costo_producto,
									precio: producto.precio_venta,
									ganancia: producto.ganancia,
								})
							})
							//console.log(lables)
							//console.log(cants)
							this.cargarGraficaRentables(lables, cants)
							this.setState({
								masRentablesLabels: lables,
								masRentablesCants: cants
							})
						}
					})
					.catch(() => {
						//console.log(err)
					})
				//Ordenes de compra
				Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/movimiento/lista/' + parseInt(usuario.empresas_id, 10), { headers: { Authorization: ntoken } })
					.then(responsee => {
						var prove = []
						////console.log(responsee)
						if (responsee.status == 200) {
							responsee.data.movimientos.map((mov) => {
								if (mov.movimiento.operacion == 2 && (mov.movimiento.movimiento_estado == 51 || mov.movimiento.movimiento_estado == 52)) {
									prove.push(mov)
								}
							})
							this.setState({ movimientosbd: prove })
						}
					})
					.catch(function (error) {
						// handle error
						console.error(error);
					})
				break
		}


	}

	buscarDashboardVoucher = () => {
		var hijos = store.getState().empresasHijos
		var fechaActuall = moment().format('YYYY-MM-DD')
		if (this.state.multiEmpresasMAsVendidos === "") {
			var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
			//indicadores
			var dataIndicadores = {
				"empresas_id": hijos,
				"dominio_tipo": "V",
				"current_date": fechaActuall,
				"empresas_tipos": 5

			}
			Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/indicadores/', dataIndicadores, { headers: { Authorization: ntoken } })
				.then((res) => {
					////console.log(res)
					if (res.status == 200) {
						this.setState({
							numTiendas: res.data.tiendas,
							numVentas: res.data.numero_ventas,
							numEmpleados: res.data.empleados,
							ventasDiarias: res.data.ventas,
							campanasVigentes: res.data.campanasVigentes,
							bonosImpresos: res.data.bonosImpresos
						})
					} else if (res.status == 205) {
						swal("ERROR", "LA SESION HA CADUCADO", "warning")
						setTimeout(function () { window.location.href = '/'; }, 5000);
					}
				})
				.catch(() => {
					//console.log(err)
				})

			var data = {
				"empresas_id": hijos,
				"accion": "reporte_voucher",
				"tipo_reporte": 4,
				"query_year": this.state.bonos_meses_tiempo,
				"current_date": fechaActuall
			}
			Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', data, { headers: { Authorization: ntoken } })
				.then((res) => {
					console.log(res)
					if (res.data != null) {
						var productosLabels = []
						var productosCants = []
						var excel = []
						res.data.data.map((producto) => {

							productosLabels.push(producto.label)
							productosCants.push(producto.bonosImpresos)
							excel.push({
								"mes": producto.label,
								"bonos_impresos": producto.bonosImpresos,
							})
						})
						this.setState({ impresionesMesesLabel: productosLabels, impresionesMesesCant: productosCants, excelBonoxMeses: excel })
					}
				})
				.catch(() => {
					//console.log(err)
				})

			var dataDias = {
				"empresas_id": hijos,
				"accion": "reporte_voucher",
				"tipo_reporte": 2,
				"current_date": fechaActuall
			}
			Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataDias, { headers: { Authorization: ntoken } })
				.then((res) => {
					console.log(res)
					if (res.data != null) {
						var productosLabels = []
						var productosCants = []
						var excel = []
						res.data.data.map((producto) => {

							productosLabels.push(producto.dia)
							productosCants.push(producto.bonosImpresos)
							excel.push({
								"dia": producto.label,
								"bonos_impresos": producto.bonosImpresos,
							})
						})
						this.setState({ impresionesPorDiaLabel: productosLabels, impresionesPorDiaCant: productosCants, excelBonosXDia: excel })
					}
				})
				.catch(() => {
					//console.log(err)
				})
			var dataEmpresas = {
				"empresas_id": hijos,
				"accion": "reporte_voucher_estacion",
				"tipo_reporte": this.state.tipoReporteBonos,
				"current_date": fechaActuall
			}
			Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataEmpresas, { headers: { Authorization: ntoken } })
				.then((res) => {
					console.log(res)
					if (res.data != null) {
						var productosLabels = []
						var productosCants = []
						var excel = []
						res.data.data.map((producto) => {

							productosLabels.push(producto.estacion.alias)
							productosCants.push(producto.bonos_impresos)
							excel.push({
								"codigo": producto.estacion.codigo,
								"estacion": producto.estacion.alias,
								"bonos_impresos": producto.bonos_impresos,
							})
						})
						this.setState({ bonosTiendasLabel: productosLabels, bonosTiendasCant: productosCants, excelBonosTiendas: excel })
					}
				})
				.catch(() => {
					//console.log(err)
				})

		} else {
			var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
			//indicadores
			var dataIndicadores = {
				"empresas_id": this.state.multiEmpresasMAsVendidos,
				"dominio_tipo": "V",
				"current_date": fechaActuall,
				"empresas_tipos": 5
			}
			Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/indicadores/', dataIndicadores, { headers: { Authorization: ntoken } })
				.then((res) => {
					////console.log(res)
					if (res.status == 200) {
						this.setState({
							numTiendas: res.data.tiendas,
							numVentas: res.data.numero_ventas,
							numEmpleados: res.data.empleados,
							ventasDiarias: res.data.ventas,
							campanasVigentes: res.data.campanasVigentes,
							bonosImpresos: res.data.bonosImpresos
						})
					} else if (res.status == 205) {
						swal("ERROR", "LA SESION HA CADUCADO", "warning")
						setTimeout(function () { window.location.href = '/'; }, 5000);
					}
				})
				.catch(() => {
					//console.log(err)
				})
			var data = {
				"empresas_id": this.state.multiEmpresasMAsVendidos,
				"accion": "reporte_voucher",
				"tipo_reporte": 4,
				"query_year": this.state.bonos_meses_tiempo,
				"current_date": fechaActuall
			}
			Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', data, { headers: { Authorization: ntoken } })
				.then((res) => {
					console.log(res)
					if (res.data != null) {
						var productosLabels = []
						var productosCants = []
						var excel = []
						res.data.data.map((producto) => {

							productosLabels.push(producto.label)
							productosCants.push(producto.bonosImpresos)
							excel.push({
								"mes": producto.label,
								"bonos_impresos": producto.bonosImpresos,
							})
						})
						this.setState({ impresionesMesesLabel: productosLabels, impresionesMesesCant: productosCants, excelBonoxMeses: excel })
					}
				})
				.catch(() => {
					//console.log(err)
				})
			var dataDias = {
				"empresas_id": this.state.multiEmpresasMAsVendidos,
				"accion": "reporte_voucher",
				"tipo_reporte": 2,
				"current_date": fechaActuall
			}
			Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataDias, { headers: { Authorization: ntoken } })
				.then((res) => {
					console.log(res)
					if (res.data != null) {
						var productosLabels = []
						var productosCants = []
						var excel = []
						res.data.data.map((producto) => {

							productosLabels.push(producto.dia)
							productosCants.push(producto.bonosImpresos)
							excel.push({
								"dia": producto.label,
								"bonos_impresos": producto.bonosImpresos,
							})
						})
						this.setState({ impresionesPorDiaLabel: productosLabels, impresionesPorDiaCant: productosCants, excelBonosXDia: excel })
					}
				})
				.catch(() => {
					//console.log(err)
				})
			var dataEmpresas = {
				"empresas_id": this.state.multiEmpresasMAsVendidos,
				"accion": "reporte_voucher_estacion",
				"tipo_reporte": this.state.tipoReporteBonos,
				"current_date": fechaActuall
			}
			Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataEmpresas, { headers: { Authorization: ntoken } })
				.then((res) => {
					console.log(res)
					if (res.data != null) {
						var productosLabels = []
						var productosCants = []
						var excel = []
						res.data.data.map((producto) => {

							productosLabels.push(producto.estacion.alias)
							productosCants.push(producto.bonos_impresos)
							excel.push({
								"codigo": producto.estacion.codigo,
								"estacion": producto.estacion.alias,
								"bonos_impresos": producto.bonos_impresos,
							})
						})
						this.setState({ bonosTiendasLabel: productosLabels, bonosTiendasCant: productosCants, excelBonosTiendas: excel })
					}
				})
				.catch(() => {
					//console.log(err)
				})
		}
	}

	buscarmasVendidosEmpresas = () => {
		var hijos = store.getState().empresasHijos
		var fechaActuall = moment().format('YYYY-MM-DD')
		if (this.state.multiEmpresasMAsVendidos === "") {
			var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
			//indicadores
			var dataIndicadores = {
				empresas_id: hijos,
				tipo_reporte: 0,
				empresas_tipos: 5,
				current_date: fechaActuall
			}
			Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/indicadores/', dataIndicadores, { headers: { Authorization: ntoken } })
				.then((res) => {
					////console.log(res)
					if (res.status == 200) {
						this.setState({ numTiendas: res.data.tiendas, numVentas: res.data.numero_ventas, numEmpleados: res.data.empleados, ventasDiarias: res.data.ventas })
					} else if (res.status == 205) {
						swal("ERROR", "LA SESION HA CADUCADO", "warning")
						setTimeout(function () { window.location.href = '/'; }, 5000);
					}
				})
				.catch(() => {
					//console.log(err)
				})
			//mas vendidos
			var dataMasVendidos = {
				accion: 'productos_mas_vendidos',
				empresas_id: hijos,
				tipo_reporte: this.state.productoMasVendidoTiempo,
				empresas_tipos: 5,
				current_date: fechaActuall,
				limit: 10
			}
			Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataMasVendidos, { headers: { Authorization: ntoken } })
				.then((res) => {
					////console.log(res)
					if (res.data != null) {
						var productosLabels = []
						var productosCants = []
						var excel = []
						res.data.data.map((producto) => {
							productosLabels.push(producto.descripcion)
							productosCants.push(producto.mas_vendidos)
							excel.push({
								plu: producto.plu,
								descripcion: producto.descripcion,
								mas_vendidos: parseInt(producto.mas_vendidos),
								total: '$' + this.setearMiles(producto.total)
							})
						})
						this.setState({ productosMasVendidosLabels: productosLabels, productosMasVendidosCantidades: productosCants, excelMasVendidos: excel })
					}
				})
				.catch(() => {
					//console.log(err)
				})
			this.setState({
				filtroMAsVendidos: false
			})
			//Promotores que mas venden
			var dataPromotoresMas = {
				accion: 'ventas_promotor',
				empresas_id: hijos,
				tipo_reporte: this.state.promotoresTiempo,
				current_date: fechaActuall,
				limit: 10
			}
			Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataPromotoresMas, { headers: { Authorization: ntoken } })
				.then((res) => {
					////console.log(res)
					if (res.data != null) {
						var productosLabels = []
						var productosCants = []
						//console.log(res)
						var excel = []
						res.data.data.map((producto) => {
							productosLabels.push(producto.operador)
							productosCants.push(producto.total)
							excel.push({
								tienda: producto.tienda,
								descripcion: producto.operador,
								total: '$' + this.setearMiles(producto.total)
							})
						})
						this.setState({ promotoresMasLabels: productosLabels, promotoresMasCants: productosCants, excelPromotoresMas: excel })
					}
				})
				.catch(() => {
					//console.log(err)
				})
			//Productos menos vendidos
			Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/productos_menos_vendidos/5/' + hijos + '/' + this.state.productoMenosVendidoTiempo + '/null/null/10', { headers: { Authorization: ntoken } })
				.then((res) => {
					////console.log(res)
					if (res.data != null) {
						var productosLabels = []
						var productosCants = []
						var excel = []
						res.data.data.map((producto) => {
							productosLabels.push(producto.descripcion)
							productosCants.push(producto.cantidad_vendida)
							excel.push({
								plu: producto.plu,
								descripcion: producto.descripcion,
								cantidad_vendida: parseInt(producto.cantidad_vendida),
								precio: '$' + this.setearMiles(producto.precio)
							})
						})
						this.setState({ productosMenosVendidosLabels: productosLabels, productosMenosVendidosCantidades: productosCants, excelMenosVendidos: excel })
					}
				})
				.catch(() => {
					//console.log(err)
				})
			//meses
			var data = {
				"accion": "ventas_meses",
				"tipo_reporte": 1,
				"query_year": this.state.venta_meses_tiempo,
				"current_date": fechaActuall,
				"empresas_id": hijos
			}
			Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', data, { headers: { Authorization: ntoken } })
				.then((res) => {
					////console.log(res)
					if (res.data != null) {
						var productosLabels = []
						var productosCants = []
						var excel = []
						res.data.data.map((producto) => {
							productosLabels.push(producto.mes_label)
							productosCants.push(producto.total_venta)
							excel.push({
								"mes": producto.mes_label,
								"venta_neta": isUndefined(producto.total_venta_neta) ? '$' + 0 : '$' + this.setearMiles(producto.total_venta_neta),
								"impuestos": isUndefined(producto.impuestos_total) ? '$' + 0 : '$' + this.setearMiles(producto.impuestos_total),
								"total": isUndefined(producto.total_venta) ? '$' + 0 : '$' + this.setearMiles(producto.total_venta)
							})
						})
						this.setState({ ventaMesesLabels: productosLabels, ventaMesesCant: productosCants, excelMeses: excel })
					}
				})
				.catch(() => {
					//console.log(err)
				})
			//VENTAS
			Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/reporte_ventas/5/' + hijos + '/2/null/null/10', { headers: { Authorization: ntoken } })
				.then((res) => {
					var fecha = new Date()
					var ventas = []
					var labels = []
					var excel = []
					if (res.data != null) {
						//console.log(res.data)
						//preparar dias 
						for (var r = 1; r <= fecha.getDate(); r++) {
							ventas[r] = 0
							labels[r] = r < 10 ? "0" + r : r;
						}

						res.data.data.map((ventaDia) => {

							if (moment(ventaDia.fecha.replace("T00:00:00.000Z", "")).format("MM") === moment().format("MM")) {
								//console.log(moment(ventaDia.fecha.replace("T00:00:00.000Z", "")).format("MM"), ventaDia.fecha.replace("T00:00:00.000Z", ""))
								excel.push({
									fecha: moment(new Date(ventaDia.fecha)).add("day", 1).format("DD/MM/YYYY"),
									tienda: ventaDia.razon_social,
									ventas: '$' + this.setearMiles(ventaDia.total)
								})
								let diaVenta = moment(ventaDia.fecha.replace("T00:00:00.000Z", "")).format("D")
								ventas[parseInt(diaVenta)] += parseFloat(ventaDia.total);
							}
						})
						this.setState({ ventasPorDiaCant: ventas, ventasPorDiaLabel: labels, excelVentas: excel })
					}
				})
				.catch(() => {
					//console.log(err)
				})
		} else {
			var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
			//indicadores
			var dataIndicadores = {
				empresas_id: this.state.multiEmpresasMAsVendidos,
				tipo_reporte: 0,
				empresas_tipos: 5,
				current_date: fechaActuall
			}
			Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/indicadores/', dataIndicadores, { headers: { Authorization: ntoken } })
				.then((res) => {
					////console.log(res)
					if (res.status == 200) {
						this.setState({ numTiendas: res.data.tiendas, numVentas: res.data.numero_ventas, numEmpleados: res.data.empleados, ventasDiarias: res.data.ventas })
					} else if (res.status == 205) {
						swal("ERROR", "LA SESION HA CADUCADO", "warning")
						setTimeout(function () { window.location.href = '/'; }, 5000);
					}
				})
				.catch(() => {
					//console.log(err)
				})
			//MAS vendidos
			var dataMasVendidos = {
				accion: 'productos_mas_vendidos',
				empresas_id: this.state.multiEmpresasMAsVendidos,
				tipo_reporte: this.state.productoMasVendidoTiempo,
				empresas_tipos: 5,
				current_date: fechaActuall,
				limit: 10
			}
			Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataMasVendidos, { headers: { Authorization: ntoken } })
				.then((res) => {
					////console.log(res)
					if (res.data != null) {
						var productosLabels = []
						var productosCants = []
						var excel = []
						res.data.data.map((producto) => {
							productosLabels.push(producto.descripcion)
							productosCants.push(producto.mas_vendidos)
							excel.push({
								plu: producto.plu,
								descripcion: producto.descripcion,
								mas_vendidos: parseInt(producto.mas_vendidos),
								total: '$' + this.setearMiles(producto.total)
							})
						})
						this.setState({ productosMasVendidosLabels: productosLabels, productosMasVendidosCantidades: productosCants, excelMasVendidos: excel })
					}
				})
				.catch(() => {
					//console.log(err)
				})
			this.setState({
				filtroMAsVendidos: true
			})
			//Promotores que mas venden
			var dataPromotoresMas = {
				accion: 'ventas_promotor',
				empresas_id: this.state.multiEmpresasMAsVendidos,
				tipo_reporte: this.state.promotoresTiempo,
				current_date: fechaActuall,
				limit: 10
			}
			Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataPromotoresMas, { headers: { Authorization: ntoken } })
				.then((res) => {
					////console.log(res)
					if (res.data != null) {
						var productosLabels = []
						var productosCants = []
						//console.log(res)
						var excel = []
						res.data.data.map((producto) => {
							productosLabels.push(producto.operador)
							productosCants.push(producto.total)
							excel.push({
								tienda: producto.tienda,
								descripcion: producto.operador,
								total: '$' + this.setearMiles(producto.total)
							})
						})
						this.setState({ promotoresMasLabels: productosLabels, promotoresMasCants: productosCants, excelPromotoresMas: excel })
					}
				})
				.catch(() => {
					//console.log(err)
				})
			//Productos menos vendidos
			Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/productos_menos_vendidos/5/' + this.state.multiEmpresasMAsVendidos + '/' + this.state.productoMenosVendidoTiempo + '/null/null/10', { headers: { Authorization: ntoken } })
				.then((res) => {
					////console.log(res)
					if (res.data != null) {
						var productosLabels = []
						var productosCants = []
						var excel = []
						res.data.data.map((producto) => {
							productosLabels.push(producto.descripcion)
							productosCants.push(producto.cantidad_vendida)
							excel.push({
								plu: producto.plu,
								descripcion: producto.descripcion,
								cantidad_vendida: parseInt(producto.cantidad_vendida),
								precio: '$' + this.setearMiles(producto.precio)
							})
						})
						this.setState({ productosMenosVendidosLabels: productosLabels, productosMenosVendidosCantidades: productosCants, excelMasVendidos: excel })
					}
				})
				.catch(() => {
					//console.log(err)
				})
			//meses
			var data = {
				"accion": "ventas_meses",
				"tipo_reporte": 1,
				"query_year": this.state.venta_meses_tiempo,
				"current_date": fechaActuall,
				"empresas_id": this.state.multiEmpresasMAsVendidos
			}
			Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', data, { headers: { Authorization: ntoken } })
				.then((res) => {
					////console.log(res)
					if (res.data != null) {
						var productosLabels = []
						var productosCants = []
						var excel = []
						res.data.data.map((producto) => {
							productosLabels.push(producto.mes_label)
							productosCants.push(producto.total_venta)
							excel.push({
								"mes": producto.mes_label,
								"venta_neta": isUndefined(producto.total_venta_neta) ? '$' + 0 : '$' + this.setearMiles(producto.total_venta_neta),
								"impuestos": isUndefined(producto.impuestos_total) ? '$' + 0 : '$' + this.setearMiles(producto.impuestos_total),
								"total": isUndefined(producto.total_venta) ? '$' + 0 : '$' + this.setearMiles(producto.total_venta)
							})
						})
						this.setState({ ventaMesesLabels: productosLabels, ventaMesesCant: productosCants, excelMeses: excel })
					}
				})
				.catch(() => {
					//console.log(err)
				})
			//VENTAS
			Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/reporte_ventas/5/' + this.state.multiEmpresasMAsVendidos + '/2/null/null/10', { headers: { Authorization: ntoken } })
				.then((res) => {
					var fecha = new Date()
					var ventas = []
					var labels = []
					var excel = []
					if (res.data != null) {
						//console.log(res.data)
						//preparar dias 
						for (var r = 1; r <= fecha.getDate(); r++) {
							ventas[r] = 0
							labels[r] = r < 10 ? "0" + r : r;
						}

						res.data.data.map((ventaDia) => {

							if (moment(ventaDia.fecha.replace("T00:00:00.000Z", "")).format("MM") === moment().format("MM")) {
								//console.log(moment(ventaDia.fecha.replace("T00:00:00.000Z", "")).format("MM"), ventaDia.fecha.replace("T00:00:00.000Z", ""))
								excel.push({
									fecha: moment(new Date(ventaDia.fecha)).add("day", 1).format("DD/MM/YYYY"),
									tienda: ventaDia.razon_social,
									ventas: '$' + this.setearMiles(ventaDia.total)
								})
								let diaVenta = moment(ventaDia.fecha.replace("T00:00:00.000Z", "")).format("D")
								ventas[parseInt(diaVenta)] += parseFloat(ventaDia.total);
							}
						})
						this.setState({ ventasPorDiaCant: ventas, ventasPorDiaLabel: labels, excelVentas: excel })
					}
				})
				.catch(() => {
					//console.log(err)
				})
		}

	}

	cargarGraficaRentables = (labl, cantidades) => {
		var precios = []
		var costo = []
		var ganancia = []
		//console.log(labl)
		//console.log(cantidades)
		cantidades.map((producto) => {
			precios.push(parseInt(producto.precio))
			costo.push(parseInt(producto.costo))
			ganancia.push(parseInt(producto.ganancia))
		})
		//console.log(precios)
		Highcharts.chart('ganancias', {
			chart: {
				type: 'column'
			},
			title: {
				text: 'PRODUCTOS MAS RENTABLES'
			},
			xAxis: {
				categories: labl,
				crosshair: true
			},
			yAxis: {
				min: 0,
				title: {
					text: 'DINERO ($)'
				}
			},
			tooltip: {
				headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
				pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
					'<td style="padding:0"><b>${point.y:.1f}</b></td></tr>',
				footerFormat: '</table>',
				shared: true,
				useHTML: true
			},
			plotOptions: {
				column: {
					pointPadding: 0.2,
					borderWidth: 0
				}
			},
			series: [{
				name: 'COSTO',
				data: costo

			}, {
				name: 'PRECIO',
				data: precios

			}, {
				name: 'GANANCIAS',
				data: ganancia

			}]
		});
	}

	componentWillMount() {
		const tipoDominio = store.getState().tipoDominio
		if (tipoDominio === "V") {
			this.obtenerDashboardVoucher()
		} else {
			this.obtenerDashBoard()
		}
		var usuario = store.getState().usuario
		var hijos = store.getState().empresasHijos
		var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
		Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/5/empresas/' + hijos + "," + usuario.empresas_id, { headers: { Authorization: ntoken } })
			.then(responseee => {
				var empresas = []
				////console.log(responseee)
				if (responseee.data != null) {
					responseee.data.empresas.map((dato) => (
						empresas.push({
							value: dato.id,
							label: dato.alias + " -- " + dato.nit
						})
					))
				} else if (responseee.status == 205) {
					swal("ERROR", "LA SESION HA CADUCADO", "warning")
					setTimeout(function () { window.location.href = '/'; }, 3000);
				}
				this.setState({ empresasbd: empresas })

			})
			.catch(function (error) {
				// handle error
				console.error(error);
			})
	}

	handleChanges = name => value => {
		this.setState({
			[name]: value,
		});
		switch (name) {
			case "empresaSeleccionadaMasVendidos":
				var empresas = ""
				value.map((empresa, key) => {
					if (key === 0) {
						empresas += empresa.value
					} else {
						empresas += "," + empresa.value
					}
				})
				this.setState({
					multiEmpresasMAsVendidos: empresas
				})
				break;
		}
	}


	//CAMBIO CHECK
	changeSelect = (name, tipo) => () => {
		var usuario = store.getState().usuario
		var hijos = store.getState().empresasHijos
		var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
		var filtro = this.state.filtroMAsVendidos
		var fechaActuall = moment().format('YYYY-MM-DD')
		this.setState({
			[name]: tipo
		});
		switch (name) {
			case 'productoMasVendidoTiempo':
				//Productos mas vendidos
				var dataMasVendidos = {
					accion: 'productos_mas_vendidos',
					empresas_id: usuario.tipo === "5" ? usuario.empresas_id : filtro ? this.state.multiEmpresasMAsVendidos : hijos,
					tipo_reporte: tipo,
					empresas_tipos: 5,
					current_date: fechaActuall,
					limit: 10
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataMasVendidos, { headers: { Authorization: ntoken } })
					.then((res) => {
						//console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							var excel = []
							res.data.data.map((producto) => {
								productosLabels.push(producto.descripcion)
								productosCants.push(producto.mas_vendidos)
								excel.push({
									plu: producto.plu,
									descripcion: producto.descripcion,
									mas_vendidos: parseInt(producto.mas_vendidos),
									total: '$' + this.setearMiles(producto.total)
								})
							})
							this.setState({ productosMasVendidosLabels: productosLabels, productosMasVendidosCantidades: productosCants, excelMasVendidos: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				break;
			case 'productoMenosVendidoTiempo':
				//Productos mas vendidos
				//Productos mas vendidos
				var dataMenosVendidos = {
					accion: 'productos_menos_vendidos',
					empresas_id: usuario.tipo === "5" ? usuario.empresas_id : filtro ? this.state.multiEmpresasMAsVendidos : hijos,
					tipo_reporte: tipo,
					empresas_tipos: 5,
					current_date: fechaActuall,
					limit: 10
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataMenosVendidos, { headers: { Authorization: ntoken } })
					.then((res) => {
						//console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							var excel = []
							res.data.data.map((producto) => {
								productosLabels.push(producto.descripcion)
								productosCants.push(producto.cantidad_vendida)
								excel.push({
									plu: producto.plu,
									descripcion: producto.descripcion,
									cantidad_vendida: parseInt(producto.cantidad_vendida),
									precio: '$' + this.setearMiles(producto.precio)
								})
							})
							this.setState({ productosMenosVendidosLabels: productosLabels, productosMenosVendidosCantidades: productosCants, excelMenosVendidos: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				break;
			case 'promotoresTiempo':
				//Promotores que mas venden
				var dataPromotoresMas = {
					accion: 'ventas_promotor',
					empresas_id: usuario.tipo === "5" ? usuario.empresas_id : filtro ? this.state.multiEmpresasMAsVendidos : hijos,
					tipo_reporte: tipo,
					current_date: fechaActuall,
					limit: 10
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataPromotoresMas, { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							//console.log(res)
							var excel = []
							res.data.data.map((producto) => {
								productosLabels.push(producto.operador)
								productosCants.push(producto.total)
								excel.push({
									tienda: producto.tienda,
									descripcion: producto.operador,
									total: '$' + this.setearMiles(producto.total)
								})
							})
							this.setState({ promotoresMasLabels: productosLabels, promotoresMasCants: productosCants, excelPromotoresMas: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				break;
			case 'venta_meses_tiempo':
				//Vnetas por meses
				var data = {
					"accion": "ventas_meses",
					"tipo_reporte": 1,
					"query_year": tipo,
					"current_date": fechaActuall,
					"empresas_id": usuario.tipo === "5" ? usuario.empresas_id : this.state.filtroMAsVendidos ? this.state.multiEmpresasMAsVendidos : hijos
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', data, { headers: { Authorization: ntoken } })
					.then((res) => {
						////console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							var excel = []
							res.data.data.map((producto) => {
								productosLabels.push(producto.mes_label)
								excel.push({
									"mes": producto.mes_label,
									"venta_neta": isUndefined(producto.total_venta_neta) ? '$' + 0 : '$' + this.setearMiles(producto.total_venta_neta),
									"impuestos": isUndefined(producto.impuestos_total) ? '$' + 0 : '$' + this.setearMiles(producto.impuestos_total),
									"total": isUndefined(producto.total_venta) ? '$' + 0 : '$' + this.setearMiles(producto.total_venta)
								})
								productosCants.push(producto.total_venta)
							})
							this.setState({ ventaMesesLabels: productosLabels, ventaMesesCant: productosCants, excelMeses: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				break;
			case 'bonos_meses_tiempo':
				//Bonos por meses
				var data = {
					"empresas_id": usuario.tipo === "5" ? usuario.empresas_id : this.state.filtroMAsVendidos ? this.state.multiEmpresasMAsVendidos : hijos,
					"accion": "reporte_voucher",
					"tipo_reporte": 4,
					"query_year": tipo,
					"current_date": fechaActuall
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', data, { headers: { Authorization: ntoken } })
					.then((res) => {
						console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							var excel = []
							res.data.data.map((producto) => {

								productosLabels.push(producto.label)
								productosCants.push(producto.bonosImpresos)
								excel.push({
									"mes": producto.label,
									"bonos_impresos": producto.bonosImpresos,
								})
							})
							this.setState({ impresionesMesesLabel: productosLabels, impresionesMesesCant: productosCants, excelBonoxMeses: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				break;
			case 'tipoReporteBonos':
				var dataEmpresas = {
					"empresas_id": this.state.filtroMAsVendidos ? this.state.multiEmpresasMAsVendidos : hijos,
					"accion": "reporte_voucher_estacion",
					"tipo_reporte": tipo,
					"current_date": fechaActuall
				}
				Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/dashboard/reporte/', dataEmpresas, { headers: { Authorization: ntoken } })
					.then((res) => {
						console.log(res)
						if (res.data != null) {
							var productosLabels = []
							var productosCants = []
							var excel = []
							res.data.data.map((producto) => {

								productosLabels.push(producto.estacion.alias)
								productosCants.push(producto.bonos_impresos)
								excel.push({
									"codigo": producto.estacion.codigo,
									"estacion": producto.estacion.alias,
									"bonos_impresos": producto.bonos_impresos,
								})
							})
							this.setState({ bonosTiendasLabel: productosLabels, bonosTiendasCant: productosCants, excelBonosTiendas: excel })
						}
					})
					.catch(() => {
						//console.log(err)
					})
				break;
		}
	}

	expandir = () => {
		this.setState({
			expanded: !this.state.expanded
		})
	}



	render() {
		const { classes } = this.props;
		var fechaActual = new Date()
		const tipoDominio = store.getState().tipoDominio
		var productosMasVendidos = {
			labels: this.state.productosMasVendidosLabels,
			datasets: [
				{
					label: 'Cantidades vendidas',
					backgroundColor: 'rgba(0,143,57,0.2)',
					borderColor: 'rgba(45,87,44,1)',
					borderWidth: 1,
					hoverBackgroundColor: 'rgba(0,143,57,0.5)',
					hoverBorderColor: 'rgba(45,87,44,1)',
					data: this.state.productosMasVendidosCantidades,
				}
			]
		};
		var años = []
		var añoActual = parseInt(moment(new Date()).format('YYYY').toUpperCase())
		for (let i = 2019; i <= añoActual; i++) {
			años.push(i)

		}
		var bonosXEmpresas = {
			labels: this.state.bonosTiendasLabel,
			datasets: [
				{
					label: 'Bonos impresos',
					backgroundColor: 'rgba(0,143,57,0.2)',
					borderColor: 'rgba(45,87,44,1)',
					borderWidth: 1,
					hoverBackgroundColor: 'rgba(0,143,57,0.5)',
					hoverBorderColor: 'rgba(45,87,44,1)',
					data: this.state.bonosTiendasCant,
				}
			]
		};
		var promotoresMas = {
			labels: this.state.promotoresMasLabels,
			datasets: [
				{
					label: 'Total ventas ($)',
					backgroundColor: 'rgba(0,143,57,0.2)',
					borderColor: 'rgba(45,87,44,1)',
					borderWidth: 1,
					hoverBackgroundColor: 'rgba(0,143,57,0.5)',
					hoverBorderColor: 'rgba(45,87,44,1)',
					data: this.state.promotoresMasCants,
				}
			]
		};
		var productosMenosVendidos = {
			labels: this.state.productosMenosVendidosLabels,
			datasets: [
				{
					label: 'Cantidades vendidas',
					backgroundColor: 'rgba(247,094,037,0.2)',
					borderColor: 'rgba(248,000,000,1)',
					borderWidth: 1,
					hoverBackgroundColor: 'rgba(247,094,037,0.5)',
					hoverBorderColor: 'rgba(248,000,000,1)',
					data: this.state.productosMenosVendidosCantidades
				}
			]
		};
		var VentasxDia = {
			labels: this.state.ventasPorDiaLabel,
			datasets: [
				{
					label: 'Total de ventas por dia en ($)',
					backgroundColor: 'rgba(255,99,132,0.2)',
					borderColor: 'rgba(255,99,132,1)',
					borderWidth: 1,
					hoverBackgroundColor: 'rgba(255,99,132,0.4)',
					hoverBorderColor: 'rgba(255,99,132,1)',
					data: this.state.ventasPorDiaCant
				}
			]
		};
		var VentasxDiaBonos = {
			labels: this.state.impresionesPorDiaLabel,
			datasets: [
				{
					label: 'Bonos impresos por dia',
					backgroundColor: 'rgba(255,99,132,0.2)',
					borderColor: 'rgba(255,99,132,1)',
					borderWidth: 1,
					hoverBackgroundColor: 'rgba(255,99,132,0.4)',
					hoverBorderColor: 'rgba(255,99,132,1)',
					data: this.state.impresionesPorDiaCant
				}
			]
		};
		var VentasxMes = {
			labels: this.state.ventaMesesLabels,
			datasets: [
				{
					label: 'Total de ventas por mes en ($)',
					backgroundColor: 'rgba(255,99,132,0.2)',
					borderColor: 'rgba(255,99,132,1)',
					borderWidth: 1,
					hoverBackgroundColor: 'rgba(255,99,132,0.4)',
					hoverBorderColor: 'rgba(255,99,132,1)',
					data: this.state.ventaMesesCant
				}
			]
		};
		var VentasxMesBonos = {
			labels: this.state.impresionesMesesLabel,
			datasets: [
				{
					label: 'Bonos impresos por mes',
					backgroundColor: 'rgba(255,99,132,0.2)',
					borderColor: 'rgba(255,99,132,1)',
					borderWidth: 1,
					hoverBackgroundColor: 'rgba(255,99,132,0.4)',
					hoverBorderColor: 'rgba(255,99,132,1)',
					data: this.state.impresionesMesesCant
				}
			]
		};
		var usuario = store.getState().usuario
		return (
			tipoDominio === "V"
				?
				<div>
					{usuario.tipo != "5" ? <ExpansionPanel expanded={this.state.expanded} style={{ zIndex: 1098 }} onChange={() => this.expandir()}>
						<ExpansionPanelSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1bh-content"
							id="panel1bh-header"
						>
							<Typography >FILTROS</Typography>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>

							<div className="row" style={{ width: '100%' }}>
								<div className="col-md-8">
									<label htmlFor="numordencompra" className="col-form-label">EMPRESAS</label>
									<Selects
										value={this.state.empresaSeleccionadaMasVendidos}
										onChange={this.handleChanges('empresaSeleccionadaMasVendidos')}
										options={this.state.empresasbd}
										isMulti
									/>
								</div>
								<div className="col-md-4  bottom-aligned">
									<button className="btn btn-success btn-block" onClick={tipoDominio === "V" ? () => this.buscarDashboardVoucher() : () => this.buscarmasVendidosEmpresas()}>CONSULTAR</button>
								</div>
							</div>

						</ExpansionPanelDetails>
					</ExpansionPanel>
						:
						null}
					<GridContainer>
						{usuario.tipo == "3"
							? <GridItem xs={12} sm={6} md={4}>
								<Card>
									<CardHeader color="warning" stats icon>
										<CardIcon color="warning">
											{
												tipoDominio === 'E' || tipoDominio === 'V'
													?
													<DominioIcon />
													:
													tipoDominio === 'T' ?
														<Store />
														:
														<Store />
											}
										</CardIcon>
										<p className={classes.cardCategory}>{tipoDominio === 'E' || tipoDominio === 'V' ? 'EDS actuales' : tipoDominio === 'T' ? 'Tiendas actuales' : 'Tiendas actuales'}</p>
										<h3 className={classes.cardTitle}>{this.state.numTiendas}</h3>
									</CardHeader>
									<CardFooter stats>
										<div className={classes.stats}>
											<DateRange />
											Actualizado cada 10 segundos
										</div>
									</CardFooter>
								</Card>
							</GridItem>
							: <GridItem xs={12} sm={6} md={4}>
								<Card>
									<CardHeader color="warning" stats icon>
										<CardIcon color="warning">
											<BonosIcon />
										</CardIcon>
										<p className={classes.cardCategory}>Numero de productos</p>
										<h3 className={classes.cardTitle}>{this.state.productosTiendas}</h3>
									</CardHeader>
									<CardFooter stats>
										<div className={classes.stats}>
											<DateRange />
											Actualizado cada 10 segundos
										</div>
									</CardFooter>
								</Card>
							</GridItem>}
						<GridItem xs={12} sm={6} md={4}>
							<Card>
								<CardHeader color="success" stats icon>
									<CardIcon color="success">
										<BonosIcon />
									</CardIcon>
									<p className={classes.cardCategory}>Bonos impresos</p>
									<h3 className={classes.cardTitle}>{this.state.bonosImpresos}</h3>
								</CardHeader>
								<CardFooter stats>
									<div className={classes.stats}>
										Campañas activas: {this.state.campanasVigentes}
									</div>
								</CardFooter>
							</Card>
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							<Card>
								<CardHeader color="info" stats icon>
									<CardIcon color="info">
										<Accessibility />
									</CardIcon>
									<p className={classes.cardCategory}>Usuarios</p>
									<h3 className={classes.cardTitle}>{this.state.numEmpleados}</h3>
								</CardHeader>
								<CardFooter stats>
									<div className={classes.stats}>
										<Update />
										{moment(this.state.fechaActual).format('YYYY/MM/DD hh:mm:ss a')}
									</div>
								</CardFooter>
							</Card>
						</GridItem>
					</GridContainer>
					{
						usuario.tipo === "5"
							?
							null :
							<GridItem xs={12} sm={12} md={12}>
								<Card>
									<CardHeader color="warning">
										<h6>BONOS IMPRESOS POR ESTACION </h6>
									</CardHeader>
									<CardBody>
										<div className="row">
											<div className="col-md-3">
												<FormControlLabel
													control={<Checkbox value={this.state.tipoReporteBonos == 0} checked={this.state.tipoReporteBonos == 0} style={{ padding: '5px' }} onChange={this.changeSelect('tipoReporteBonos', 0)} />}
													label='HOY'
													style={{ margin: '0' }}
												/>
											</div>
											<div className="col-md-3">
												<FormControlLabel
													control={<Checkbox value={this.state.tipoReporteBonos == 1} checked={this.state.tipoReporteBonos == 1} style={{ padding: '5px' }} onChange={this.changeSelect('tipoReporteBonos', 1)} />}
													label='HACE UNA SEMANA'
													style={{ margin: '0' }}
												/>
											</div>
											<div className="col-md-3">
												<FormControlLabel
													control={<Checkbox value={this.state.tipoReporteBonos == 2} checked={this.state.tipoReporteBonos == 2} style={{ padding: '5px' }} onChange={this.changeSelect('tipoReporteBonos', 2)} />}
													label='HACE UN  MES'
													style={{ margin: '0' }}
												/>
											</div>
											<div className="col-md-3">
												<FormControlLabel
													control={<Checkbox value={this.state.tipoReporteBonos == 3} checked={this.state.tipoReporteBonos == 3} onChange={this.changeSelect('tipoReporteBonos', 3)} />}
													label='HACE UN AÑO'
													style={{ margin: '0' }}
												/>
											</div>
										</div>
										<div className="row">
											<ExcelFile
												filename={"Bonos por estaciones"}
												element={
													<Tooltip title="EXPORTAR A:" placement="top">
														<button className="btn btn-success" style={{ marginLeft: '12PX' }}>
															<img src={excelIcon} />
														</button>
													</Tooltip>
												}
											>
												<ExcelSheet data={this.state.excelBonosTiendas} name="PRODUCTOS">
													<ExcelColumn label="CODIGO DE EDS" value="codigo" />
													<ExcelColumn label="ESTACION" value="estacion" />
													<ExcelColumn label="BONOS IMPRESOS" value="bonos_impresos" />
												</ExcelSheet>
											</ExcelFile>
											<Tooltip title="EXPORTAR A:" placement="top">
												<button className="btn btn-danger" style={{ marginLeft: '12PX' }} disabled={this.state.bonosTiendasCant.length === 0 || this.state.bonosTiendasCant === null} onClick={() => this.imprimirMasVendidos(this.state.imprimirBonosEstaciones, 'bonos_por_estaciones', 'grafica-bonos-empresas', 'bonos_estaciones', "BONOS POR ESTACIONES")}>
													<img src={pdfIcon} />
												</button>
											</Tooltip>
										</div>
										{
											this.state.bonosTiendasCant.length === 0 || this.state.bonosTiendasCant === null
												?
												<div className="row" style={{ width: '100%' }}>
													<h5 className="mx-auto">NO HAY DATOS</h5>
												</div>
												:
												<HorizontalBar data={bonosXEmpresas} ref="bonos_por_estaciones" />
										}
									</CardBody>


								</Card>
							</GridItem>
					}
					<div id="bonos_estaciones" style={this.state.imprimirBonosEstaciones ? { width: '800px', display: 'block', marginTop: '20px' } : { display: 'none' }}>
						<table className="tb-terpel" width="712px" height="773px" border="0" align="center" cellpadding="0" cellspacing="0">
							<tr>
								<td width="763px" valign="top" className="fondocuadro">
									<table width="712px" border="0" cellpadding="0" cellspacing="0">
										<tr>
											<td width="169"><img src={logoTerpel} width="138" height="63" alt="" /></td>
											<td width="335" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td align="center"><h6><b>
														{'BONOS IMPRESOS POR ESTACIONES'}
													</b></h6></td>

												</tr>

											</table></td>
											<td width="106" align="center"><img src={logoDeuna} width="64" height="64" alt="" /></td>
										</tr>
									</table>
									<table width="712px" border="0" cellpadding="0" cellspacing="0">
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td align="center"><div id="grafica-bonos-empresas"></div></td>
												</tr>
											</table></td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center"><table width="100%" style={{ border: '1px solid black' }} cellspacing="0" cellpadding="0">
												<tr style={{ border: '1px solid black' }}>
													<th width="40" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>CODIGO DE TIENDA</th>
													<th width="80" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>ESTACION</th>
													<th width="40" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>BONOS IMPRESOS</th>
												</tr>
												{
													this.state.excelBonosTiendas.map((producto) => {
														return (
															<tr style={{ border: '1px solid black' }}>
																<td width="120" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.codigo}</td>
																<td width="110" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.estacion}</td>
																<td width="110" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.bonos_impresos}</td>
															</tr>
														)
													})
												}
											</table>
											</td>
											<td width="60" align="center"></td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</div>

					<GridItem xs={12} sm={12} md={12}>
						<Card>
							<CardHeader color="warning">
								<h4>{'BONOS IMPRESOS DESDE EL 01 DE ' + moment(fechaActual).format('MMMM').toUpperCase() + ' AL ' + moment(fechaActual).format('DD') + ' DE ' + moment(fechaActual).format('MMMM').toUpperCase()}</h4>
							</CardHeader>
							<CardBody>
								<div className="row">
									<ExcelFile
										filename={"BONOS IMPRESOS DEL MES"}
										element={
											<Tooltip title="EXPORTAR A:" placement="top">
												<button className="btn btn-success" style={{ marginLeft: '12PX' }}>
													<img src={excelIcon} />
												</button>
											</Tooltip>
										}
									>
										<ExcelSheet data={this.state.excelBonosXDia} name="BONOS">
											<ExcelColumn label="FECHA" value="dia" />
											<ExcelColumn label="BONOS IMPRESOS" value="bonos_impresos" />
										</ExcelSheet>
									</ExcelFile>
									<Tooltip title="EXPORTAR A:" placement="top">
										<button className="btn btn-danger" style={{ marginLeft: '12PX' }}
											onClick={() => this.imprimirMasVendidos(this.state.imprimirPromotores, 'grafica_dias', 'grafica-4', 'dias', 'BONOS IMPRESOS DESDE EL 01 DE ' + moment(fechaActual).format('MMMM').toUpperCase() + ' AL ' + moment(fechaActual).format('DD') + ' DE ' + moment(fechaActual).format('MMMM').toUpperCase())}>
											<img src={pdfIcon} />
										</button>
									</Tooltip>
								</div>
								<div className="row">
									<Bar
										data={VentasxDiaBonos}
										width={100}
										height={300}
										options={{
											maintainAspectRatio: false
										}}
										ref="grafica_dias"
									/>
								</div>

							</CardBody>
						</Card>
					</GridItem>
					<div id="dias" style={this.state.imprimirMenosVendidos ? { width: '800px', display: 'block', marginTop: '20px' } : { display: 'none' }}>
						<table className="tb-terpel" width="712px" height="773px" border="0" align="center" cellpadding="0" cellspacing="0">
							<tr>
								<td width="763px" valign="top" className="fondocuadro">
									<table width="712px" border="0" cellpadding="0" cellspacing="0">
										<tr>
											<td width="169"><img src={logoTerpel} width="138" height="63" alt="" /></td>
											<td width="335" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td align="center"><h6><b>
														{'BONOS IMPRESOS DESDE EL 01 DE ' + moment(fechaActual).format('MMMM').toUpperCase() + ' AL ' + moment(fechaActual).format('DD') + ' DE ' + moment(fechaActual).format('MMMM').toUpperCase()}
													</b></h6></td>

												</tr>

											</table></td>
											<td width="106" align="center"><img src={logoDeuna} width="64" height="64" alt="" /></td>
										</tr>
									</table>
									<table width="712px" border="0" cellpadding="0" cellspacing="0">
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td align="center"><div id="grafica-4"></div></td>
												</tr>
											</table></td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center"><table width="100%" style={{ border: '1px solid black' }} cellspacing="0" cellpadding="0">
												<tr style={{ border: '1px solid black' }}>
													<th width="40" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>FECHA</th>
													<th width="80" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>BONOS IMPRESOS</th>
												</tr>
												{
													this.state.excelBonosXDia.map((producto) => {
														return (
															<tr style={{ border: '1px solid black' }}>
																<td width="120" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.dia}</td>
																<td width="110" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.bonos_impresos}</td>
															</tr>
														)
													})
												}
											</table>
											</td>
											<td width="60" align="center"></td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</div>
					<GridItem xs={12} sm={12} md={12}>
						<Card>
							<CardHeader color="warning">
								{
									/*this.state.bonos_meses_tiempo === 2020
										?
										<h4>{'BONOS IMPRESOS POR MES DE ENERO A ' + moment(fechaActual).format('MMMM').toUpperCase() + ' DE ' + moment(fechaActual).format('YYYY').toUpperCase()}</h4>
										:
										<h4>{'BONOS IMPRESOS POR MES DEL AÑO ' + this.state.bonos_meses_tiempo}</h4>*/
								}
								<h4>{'BONOS IMPRESOS POR MES DEL AÑO ' + this.state.bonos_meses_tiempo}</h4>

							</CardHeader>
							<CardBody>
								<div className="row">
									{
										años.map((anio) => {
											return (
												<div className="col-md-3">
													<FormControlLabel
														control={<Checkbox value={this.state.bonos_meses_tiempo == anio} checked={this.state.bonos_meses_tiempo == anio} style={{ padding: '5px' }} onChange={this.changeSelect('bonos_meses_tiempo', anio)} />}
														label={anio}
														style={{ margin: '0' }}
													/>
												</div>
											)
										})
									}


								</div>
								<div className="row">
									<ExcelFile
										filename={"BONOS POR MES"}
										element={
											<Tooltip title="EXPORTAR A:" placement="top">
												<button className="btn btn-success" style={{ marginLeft: '12PX' }}>
													<img src={excelIcon} />
												</button>
											</Tooltip>
										}>
										<ExcelSheet data={this.state.excelBonoxMeses} name="Meses">
											<ExcelColumn label="MES" value="mes" />
											<ExcelColumn label="BONOS IMPRESOS" value="bonos_impresos" />
										</ExcelSheet>
									</ExcelFile>
									<Tooltip title="EXPORTAR A:" placement="top">
										<button className="btn btn-danger" style={{ marginLeft: '12PX' }}
											onClick={() => this.imprimirMasVendidos(this.state.imprimirMeses, 'grafica_meses', 'grafica-5', 'meses', 'BONOS IMPRESOS POR MES DE ENERO A ' + moment(fechaActual).format('MMMM').toUpperCase() + ' DE ' + moment(fechaActual).format('YYYY').toUpperCase())}>
											<img src={pdfIcon} />
										</button>
									</Tooltip>
								</div>
								<div className="row">
									<Bar
										data={VentasxMesBonos}
										width={100}
										height={300}
										ref="grafica_meses"
										options={{
											maintainAspectRatio: false
										}}
									/>
								</div>

							</CardBody>
						</Card>
					</GridItem>
					<div id="meses" style={this.state.imprimirMenosVendidos ? { width: '800px', display: 'block', marginTop: '20px' } : { display: 'none' }}>
						<table className="tb-terpel" width="712px" height="773px" border="0" align="center" cellpadding="0" cellspacing="0">
							<tr>
								<td width="763px" valign="top" className="fondocuadro">
									<table width="712px" border="0" cellpadding="0" cellspacing="0">
										<tr>
											<td width="169"><img src={logoTerpel} width="138" height="63" alt="" /></td>
											<td width="335" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td align="center"><h6><b>
														{'BONOS IMPRESOS POR MES DE ENERO A ' + moment(fechaActual).format('MMMM').toUpperCase() + ' DE ' + moment(fechaActual).format('YYYY').toUpperCase()}
													</b></h6></td>

												</tr>

											</table></td>
											<td width="106" align="center"><img src={logoDeuna} width="64" height="64" alt="" /></td>
										</tr>
									</table>
									<table width="712px" border="0" cellpadding="0" cellspacing="0">
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td align="center"><div id="grafica-5"></div></td>
												</tr>
											</table></td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center"><table width="100%" style={{ border: '1px solid black' }} cellspacing="0" cellpadding="0">
												<tr style={{ border: '1px solid black' }}>
													<th width="40" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>MES</th>
													<th width="80" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>BONOS IMPRESOS</th>
												</tr>
												{
													this.state.excelBonoxMeses.map((producto) => {
														return (
															<tr style={{ border: '1px solid black' }}>
																<td width="120" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.mes}</td>
																<td width="110" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.bonos_impresos}</td>
															</tr>
														)
													})
												}
											</table>
											</td>
											<td width="60" align="center"></td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</div>

				</div>
				:
				<div>
					{usuario.tipo != "5" ? <ExpansionPanel expanded={this.state.expanded} style={{ zIndex: 1098 }} onChange={() => this.expandir()}>
						<ExpansionPanelSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1bh-content"
							id="panel1bh-header"
						>
							<Typography >FILTROS</Typography>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>

							<div className="row" style={{ width: '100%' }}>
								<div className="col-md-8 col-sm-12">
									<label htmlFor="numordencompra" className="col-form-label">EMPRESAS</label>
									<Selects
										value={this.state.empresaSeleccionadaMasVendidos}
										onChange={this.handleChanges('empresaSeleccionadaMasVendidos')}
										options={this.state.empresasbd}
										isMulti
									/>
								</div>
								<div className="col-md-4  col-sm-12 bottom-aligned">
									<button className="btn btn-success btn-block" onClick={() => this.buscarmasVendidosEmpresas()}>CONSULTAR</button>
								</div>
							</div>

						</ExpansionPanelDetails>
					</ExpansionPanel>
						:
						null}
					<GridContainer>
						{usuario.tipo == "3"
							? <GridItem xs={12} sm={6} md={4}>
								<Card>
									<CardHeader color="warning" stats icon>
										<CardIcon color="warning">
											{
												tipoDominio === 'E'
													?
													<DominioIcon />
													:
													tipoDominio === 'T' ?
														<Store />
														:
														<Store />
											}
										</CardIcon>
										<p className={classes.cardCategory}>{tipoDominio === 'E' ? 'EDS actuales' : tipoDominio === 'T' ? 'Tiendas actuales' : 'Tiendas actuales'}</p>
										<h3 className={classes.cardTitle}>{this.state.numTiendas}</h3>
									</CardHeader>
									<CardFooter stats>
										<div className={classes.stats}>
											<DateRange />
											Actualizado cada 10 segundos
										</div>
									</CardFooter>
								</Card>
							</GridItem>
							: <GridItem xs={12} sm={6} md={4}>
								<Card>
									<CardHeader color="warning" stats icon>
										<CardIcon color="warning">
											<ProdcutoIcon />
										</CardIcon>
										<p className={classes.cardCategory}>Numero de productos</p>
										<h3 className={classes.cardTitle}>{this.state.productosTiendas}</h3>
									</CardHeader>
									<CardFooter stats>
										<div className={classes.stats}>
											<DateRange />
											Actualizado cada 10 segundos
										</div>
									</CardFooter>
								</Card>
							</GridItem>}
						<GridItem xs={12} sm={6} md={4}>
							<Card>
								<CardHeader color="success" stats icon>
									<CardIcon color="success">
										<MoneyIcon />
									</CardIcon>
									<p className={classes.cardCategory}>Ventas del dia de hoy</p>
									<h3 className={classes.cardTitle}>${this.setearMiles(parseInt(this.state.ventasDiarias))}</h3>
								</CardHeader>
								<CardFooter stats>
									<div className={classes.stats}>
										<MoneyIcon />
										Numero de ventas: {this.state.numVentas}
									</div>
								</CardFooter>
							</Card>
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							<Card>
								<CardHeader color="info" stats icon>
									<CardIcon color="info">
										<Accessibility />
									</CardIcon>
									<p className={classes.cardCategory}>Usuarios</p>
									<h3 className={classes.cardTitle}>{this.state.numEmpleados}</h3>
								</CardHeader>
								<CardFooter stats>
									<div className={classes.stats}>
										<Update />
										{moment(this.state.fechaActual).format('YYYY/MM/DD HH:mm:ss a')}
									</div>
								</CardFooter>
							</Card>
						</GridItem>
					</GridContainer>
					{usuario.tipo === "5"
						? <GridItem xs={12} sm={12} md={12}>
							<CustomTabs
								title="ALERTAS:"
								headerColor="danger"
								tabs={[
									{
										tabName: "PRODUCTOS",
										tabIcon: Warning,
										tabContent: (
											<div className="row">
												<div className="col col-md-12">

													<MDBTable bordered striped>
														<MDBTableHead color="default-color" textWhite style={{ backgroundColor: "rgba(220,56,56,1)" }}>
															<tr>
																<th>PLU</th>
																<th>PRODUCTO</th>
																<th>BODEGA</th>
																<th >CANTIDAD EN BODEGA</th>
																<th >CANTIDAD MINIMA</th>
															</tr>
														</MDBTableHead>
														<MDBTableBody>
															{

																this.state.productosEnAlerta.map((row, key) => (
																	<tr key={key} style={parseFloat(row.saldo) < 0 ? { 'backgroundColor': 'rgba(252,177,103,1)', fontWeight: 'bold' } : parseFloat(row.saldo) === 0 ?{ 'backgroundColor': 'rgba(254,195,17,0.7)', fontWeight: 'bold' } : { }}>
															<td component="th" scope="row" align="center">
																{row.producto.plu}
															</td>
															<td>{row.producto.descripcion}</td>
															<td>{row.bodega.descripcion}</td>
															<td align="center">{this.setearMiles(row.saldo)}</td>
															<td align="center">{row.productos_empresas.cantidad_minima}</td>
																	</tr>
														))
															}
														</MDBTableBody>
													</MDBTable>
											</div>
											</div>
							)
									}, {
								tabName: "BAJA ROTACION",
							tabIcon: BajaRotacionICon,
							tabContent: (
							<div className="row">
								<div className="col col-md-12">

									<MDBTable bordered striped>
										<MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
											<tr>
												<th>Plu</th>
												<th>Producto</th>
												<th >Precio</th>
											</tr>
										</MDBTableHead>
										<MDBTableBody>
											{this.state.productoBajoRotamiento.map((row, key) => (
												<tr key={key}>
													<td align="center" component="th" scope="row">
														{row.plu}
													</td>
													<td align="center">{row.descripcion}</td>
													<td align="center">{'$' + parseInt(row.precio)}</td>
												</tr>
											))}
										</MDBTableBody>
									</MDBTable>
								</div>
							</div>
							)
									}, {
								tabName: "TURNOS ACTIVOS",
							tabIcon: Person,
							tabContent: (
							<div className="row">
								<div className="col col-md-12">

									<MDBTable bordered striped>
										<MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
											<tr>
												<th>Empleado</th>
												<th>Identificacion</th>
												<th >Inicio de jornada</th>
												<th >Saldo inicial</th>
												<th >Saldo actual</th>
											</tr>
										</MDBTableHead>
										<MDBTableBody>
											{this.state.sesionesActivas.map((row, key) => (
												<tr key={key}>
													<td align="center" component="th" scope="row">
														{row.empleado.nombres + " " + row.empleado.apellidos}
													</td>
													<td align="center">{row.empleado.identificacion}</td>
													<td align="center">{moment(new Date(row.jornada.fecha_inicio)).format("DD/MM/YYYY hh:mm a")}</td>
													<td align="center">{'$' + this.setearMiles(row.jornada.saldo_inicial)}</td>
													<td align="center">{'$' + this.setearMiles(row.jornada.saldo_actual)}</td>
												</tr>
											))}
										</MDBTableBody>
									</MDBTable>
								</div>
							</div>
							)
									}, {
								tabName: "ORDENES DE COMPRA",
							tabIcon: ordenCompraIcon,
							tabContent: (
							<div className="row">
								<div className="col col-md-12">

									<MDBTable bordered striped>
										<MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
											<tr>
												<th>Consecutivo</th>
												<th>Proveedor</th>
												<th >Fecha</th>
												<th >Costo total</th>
											</tr>
										</MDBTableHead>
										<MDBTableBody>
											{this.state.movimientosbd.map((row, key) => (
												<tr key={key}>
													<td align="center" component="th" scope="row">
														{row.movimiento.consecutivo}
													</td>
													<td align="center">{row.movimiento.tercero_id_detalles != null ? row.movimiento.tercero_id_detalles.nombres + " " + row.movimiento.tercero_id_detalles.apellidos : 'No aplica'}</td>
													<td align="center">{moment(new Date(row.movimiento.fecha)).format("DD/MM/YYYY hh:mm a")}</td>
													<td align="center">{'$' + this.setearMiles(row.movimiento.costo_total)}</td>
												</tr>
											))}
										</MDBTableBody>
									</MDBTable>
								</div>
							</div>
							)
									}, {
								tabName: "RESOLUCIONES",
							tabIcon: resolucionIcon,
							tabContent: (
							<div className="row">
								<div className="col col-md-12">

									<MDBTable bordered striped>
										<MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
											<tr>
												<th align="center">PREFIJO</th>
												<th align="center">RESOLUCION</th>
												<th align="center">CONS. INICIAL</th>
												<th align="center" >CONS. ACTUAL</th>
												<th align="center" >CONS. FINAL</th>
												<th align="center" >CONS. RESTANTES</th>
											</tr>
										</MDBTableHead>
										<MDBTableBody>
											{this.state.alertaConsecutivos.map((row, key) => (
												<tr key={key}>
													<td align="center" component="th" scope="row">
														{row.prefijo}
													</td>
													<td align="center">{row.resolucion}</td>
													<td align="center">{row.consecutivo_inicial}</td>
													<td align="center">{row.consecutivo_actual}</td>
													<td align="center">{row.consecutivo_final}</td>
													<td align="center">{parseInt(row.consecutivo_final) - parseInt(row.consecutivo_actual)}</td>
												</tr>
											))}
										</MDBTableBody>
									</MDBTable>
								</div>
							</div>
							)
									}
								]}
							/>
						</GridItem> : null}
					<GridItem xs={12} sm={12} md={12}>
						<Card>
							<CardHeader color="warning">
								<h6>PRODUCTOS MAS VENDIDOS </h6>
							</CardHeader>
							<CardBody>
								<div className="row">
									<div className="col-md-3 col-sm-12">
										<FormControlLabel
											control={<Checkbox value={this.state.productoMasVendidoTiempo == 0} checked={this.state.productoMasVendidoTiempo == 0} style={{ padding: '5px' }} onChange={this.changeSelect('productoMasVendidoTiempo', 0)} />}
											label='HOY'
											style={{ margin: '0' }}
										/>
									</div>
									<div className="col-md-3 col-sm-12">
										<FormControlLabel
											control={<Checkbox value={this.state.productoMasVendidoTiempo == 9} checked={this.state.productoMasVendidoTiempo == 9} style={{ padding: '5px' }} onChange={this.changeSelect('productoMasVendidoTiempo', 9)} />}
											label='ESTA SEMANA'
											style={{ margin: '0' }}
										/>
									</div>
									<div className="col-md-3 col-sm-12">
										<FormControlLabel
											control={<Checkbox value={this.state.productoMasVendidoTiempo == 2} checked={this.state.productoMasVendidoTiempo == 2} style={{ padding: '5px' }} onChange={this.changeSelect('productoMasVendidoTiempo', 2)} />}
											label='DURANTE ESTE MES'
											style={{ margin: '0' }}
										/>
									</div>
									<div className="col-md-3 col-sm-12">
										<FormControlLabel
											control={<Checkbox value={this.state.productoMasVendidoTiempo == 3} checked={this.state.productoMasVendidoTiempo == 3} style={{ padding: '5px' }} onChange={this.changeSelect('productoMasVendidoTiempo', 3)} />}
											label='DURANTE ESTE AÑO'
											style={{ margin: '0' }}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3 col-sm-12">
										<FormControlLabel
											control={<Checkbox value={this.state.productoMasVendidoTiempo == 1} checked={this.state.productoMasVendidoTiempo == 1} style={{ padding: '5px' }} onChange={this.changeSelect('productoMasVendidoTiempo', 1)} />}
											label='HACE UNA SEMANA'
											style={{ margin: '0' }}
										/>
									</div>
									<div className="col-md-3 col-sm-12">
										<FormControlLabel
											control={<Checkbox value={this.state.productoMasVendidoTiempo == 8} checked={this.state.productoMasVendidoTiempo == 8} style={{ padding: '5px' }} onChange={this.changeSelect('productoMasVendidoTiempo', 8)} />}
											label='HACE UN MES'
											style={{ margin: '0' }}
										/>
									</div>
									<div className="col-md-3 col-sm-12">
										<FormControlLabel
											control={<Checkbox value={this.state.productoMasVendidoTiempo == 7} checked={this.state.productoMasVendidoTiempo == 7} style={{ padding: '5px' }} onChange={this.changeSelect('productoMasVendidoTiempo', 7)} />}
											label='HACE UN AÑO'
											style={{ margin: '0' }}
										/>
									</div>
								</div>
								<div className="row">
									<ExcelFile
										filename={"PRODUCTOS_MAS_VENDIDOS"}
										element={
											<Tooltip title="EXPORTAR A:" placement="top">
												<button className="btn btn-success" style={{ marginLeft: '12PX' }}>
													<img src={excelIcon} />
												</button>
											</Tooltip>
										}
									>
										<ExcelSheet data={this.state.excelMasVendidos} name="PRODUCTOS">
											<ExcelColumn label="PLU" value="plu" />
											<ExcelColumn label="PRODUCTO" value="descripcion" />
											<ExcelColumn label="CANTIDAD" value="mas_vendidos" />
											<ExcelColumn label="TOTAL" value="total" />
										</ExcelSheet>
									</ExcelFile>
									<Tooltip title="EXPORTAR A:" placement="top">
										<button className="btn btn-danger" style={{ marginLeft: '12PX' }} disabled={this.state.productosMasVendidosCantidades.length === 0 || this.state.productosMasVendidosCantidades === null} onClick={() => this.imprimirMasVendidos(this.state.imprimirMasVendidos, 'grafica_mas_vendidos', 'grafica-1', 'mas_vendidos', "PRODUCTOS MAS VENDIDOS")}>
											<img src={pdfIcon} />
										</button>
									</Tooltip>
								</div>
								{
									this.state.productosMasVendidosCantidades.length === 0 || this.state.productosMasVendidosCantidades === null
										?
										<div className="row" style={{ width: '100%' }}>
											<h5 className="mx-auto">NO HAY DATOS</h5>
										</div>
										:
										window.innerWidth < 720 ?
											<HorizontalBar data={productosMasVendidos} height={300} ref="grafica_mas_vendidos" />
											:
											<HorizontalBar data={productosMasVendidos} ref="grafica_mas_vendidos" />

								}
							</CardBody>


						</Card>
					</GridItem>
					<div id="mas_vendidos" style={this.state.imprimirMasVendidos ? { width: '800px', display: 'block', marginTop: '20px' } : { display: 'none' }}>
						<table className="tb-terpel" width="712px" height="773px" border="0" align="center" cellpadding="0" cellspacing="0">
							<tr>
								<td width="763px" valign="top" className="fondocuadro">
									<table width="712px" border="0" cellpadding="0" cellspacing="0">
										<tr>
											<td width="169"><img src={logoTerpel} width="138" height="63" alt="" /></td>
											<td width="335" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td align="center"><h6><b>
														PRODUCTOS MAS VENDIDOS
														{
															this.state.productoMasVendidoTiempo === 0 ?
																' HOY'
																: this.state.productoMasVendidoTiempo === 1 ?
																	' ESTA SEMANA'
																	: this.state.productoMasVendidoTiempo === 2 ?
																		' ESTE MES'
																		: this.state.productoMasVendidoTiempo === 3 ?
																			' ESTE AÑO' : null
														}</b></h6></td>

												</tr>

											</table></td>
											<td width="106" align="center"><img src={logoDeuna} width="64" height="64" alt="" /></td>
										</tr>
									</table>
									<table width="712px" border="0" cellpadding="0" cellspacing="0">
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td align="center"><div id="grafica-1"></div></td>
												</tr>
											</table></td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center"><table width="100%" style={{ border: '1px solid black' }} cellspacing="0" cellpadding="0">
												<tr style={{ border: '1px solid black' }}>
													<th width="40" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>PLU</th>
													<th width="260" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>PRODUCTO</th>
													<th width="80" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>CANTIDAD</th>
													<th width="110" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>TOTAL VENTAS</th>
												</tr>
												{
													this.state.excelMasVendidos.map((producto) => {
														return (
															<tr style={{ border: '1px solid black' }}>
																<td width="40" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.plu}</td>
																<td width="260" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.descripcion}</td>
																<td width="80" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.mas_vendidos}</td>
																<td width="100" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.total}</td>
															</tr>
														)
													})
												}
											</table>
											</td>
											<td width="60" align="center"></td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</div>
					<GridItem xs={12} sm={12} md={12}>
						<Card>
							<CardHeader color="warning">
								<h6>PRODUCTOS MENOS VENDIDOS </h6>
							</CardHeader>
							<CardBody>
								<div className="row">
									<div className="col-md-3">
										<FormControlLabel
											control={<Checkbox value={this.state.productoMenosVendidoTiempo == 0} checked={this.state.productoMenosVendidoTiempo == 0} style={{ padding: '5px' }} onChange={this.changeSelect('productoMenosVendidoTiempo', 0)} />}
											label='HOY'
											style={{ margin: '0' }}
										/>
									</div>
									<div className="col-md-3">
										<FormControlLabel
											control={<Checkbox value={this.state.productoMenosVendidoTiempo == 9} checked={this.state.productoMenosVendidoTiempo == 9} style={{ padding: '5px' }} onChange={this.changeSelect('productoMenosVendidoTiempo', 9)} />}
											label='ESTA SEMANA'
											style={{ margin: '0' }}
										/>
									</div>
									<div className="col-md-3">
										<FormControlLabel
											control={<Checkbox value={this.state.productoMenosVendidoTiempo == 2} checked={this.state.productoMenosVendidoTiempo == 2} style={{ padding: '5px' }} onChange={this.changeSelect('productoMenosVendidoTiempo', 2)} />}
											label='ESTE MES'
											style={{ margin: '0' }}
										/>
									</div>
									<div className="col-md-3">
										<FormControlLabel
											control={<Checkbox value={this.state.productoMenosVendidoTiempo == 3} checked={this.state.productoMenosVendidoTiempo == 3} style={{ padding: '5px' }} onChange={this.changeSelect('productoMenosVendidoTiempo', 3)} />}
											label='ESTE AÑO'
											style={{ margin: '0' }}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<FormControlLabel
											control={<Checkbox value={this.state.productoMenosVendidoTiempo == 1} checked={this.state.productoMenosVendidoTiempo == 1} style={{ padding: '5px' }} onChange={this.changeSelect('productoMenosVendidoTiempo', 1)} />}
											label='HACE UNA SEMANA'
											style={{ margin: '0' }}
										/>
									</div>
									<div className="col-md-3">
										<FormControlLabel
											control={<Checkbox value={this.state.productoMenosVendidoTiempo == 8} checked={this.state.productoMenosVendidoTiempo == 8} style={{ padding: '5px' }} onChange={this.changeSelect('productoMenosVendidoTiempo', 8)} />}
											label='DURANTE ESTE MES'
											style={{ margin: '0' }}
										/>
									</div>
									<div className="col-md-3">
										<FormControlLabel
											control={<Checkbox value={this.state.productoMenosVendidoTiempo == 7} checked={this.state.productoMenosVendidoTiempo == 7} style={{ padding: '5px' }} onChange={this.changeSelect('productoMenosVendidoTiempo', 7)} />}
											label='DURANTE ESTE AÑO'
											style={{ margin: '0' }}
										/>
									</div>
								</div>
								<div className="row">
									<ExcelFile
										filename={"PRODUCTOS_MENOS_VENDIDOS"}
										element={
											<Tooltip title="EXPORTAR A:" placement="top">
												<button className="btn btn-success" style={{ marginLeft: '12PX' }}>
													<img src={excelIcon} />
												</button>
											</Tooltip>
										}
									>
										<ExcelSheet data={this.state.excelMenosVendidos} name="PRODUCTOS">
											<ExcelColumn label="PLU" value="plu" />
											<ExcelColumn label="PRODUCTO" value="descripcion" />
											<ExcelColumn label="CANTIDAD" value="cantidad_vendida" />
											<ExcelColumn label="PRECIO" value="precio" />
										</ExcelSheet>
									</ExcelFile>
									<Tooltip title="EXPORTAR A:" placement="top">
										<button className="btn btn-danger" style={{ marginLeft: '12PX' }} disabled={this.state.productosMenosVendidosCantidades.length === 0 || this.state.productosMenosVendidosCantidades === null} onClick={() => this.imprimirMasVendidos(this.state.imprimirMenosVendidos, 'grafica_menos_vendidos', 'grafica-2', 'menos_vendidos', "PRODUCTOS MENOS VENDIDOS")}>
											<img src={pdfIcon} />
										</button>
									</Tooltip>
								</div>
								{
									this.state.productosMenosVendidosCantidades.length === 0 || this.state.productosMenosVendidosCantidades === null
										?
										<div className="row" style={{ width: '100%' }}>
											<h5 className="mx-auto">NO HAY DATOS</h5>
										</div>
										:
										window.innerWidth < 720 ?
											<HorizontalBar data={productosMenosVendidos} ref="grafica_menos_vendidos" height={300} />
											:
											<HorizontalBar data={productosMenosVendidos} ref="grafica_menos_vendidos" />
								}
							</CardBody>


						</Card>
					</GridItem>
					<div id="menos_vendidos" style={this.state.imprimirMenosVendidos ? { width: '800px', display: 'block', marginTop: '20px' } : { display: 'none' }}>
						<table className="tb-terpel" width="712px" height="773px" border="0" align="center" cellpadding="0" cellspacing="0">
							<tr>
								<td width="763px" valign="top" className="fondocuadro">
									<table width="712px" border="0" cellpadding="0" cellspacing="0">
										<tr>
											<td width="169"><img src={logoTerpel} width="138" height="63" alt="" /></td>
											<td width="335" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td align="center"><h6><b>
														PRODUCTOS MENOS VENDIDOS
														{
															this.state.productoMenosVendidoTiempo === 0 ?
																' HOY'
																: this.state.productoMenosVendidoTiempo === 1 ?
																	' ESTA SEMANA'
																	: this.state.productoMenosVendidoTiempo === 2 ?
																		' ESTE MES'
																		: this.state.productoMenosVendidoTiempo === 3 ?
																			' ESTE AÑO' : null
														}</b></h6></td>

												</tr>

											</table></td>
											<td width="106" align="center"><img src={logoDeuna} width="64" height="64" alt="" /></td>
										</tr>
									</table>
									<table width="712px" border="0" cellpadding="0" cellspacing="0">
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td align="center"><div id="grafica-2"></div></td>
												</tr>
											</table></td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center"><table width="100%" style={{ border: '1px solid black' }} cellspacing="0" cellpadding="0">
												<tr style={{ border: '1px solid black' }}>
													<th width="40" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>PLU</th>
													<th width="260" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>PRODUCTO</th>
													<th width="80" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>CANTIDAD</th>
													<th width="110" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>PRECIO</th>
												</tr>
												{
													this.state.excelMenosVendidos.map((producto) => {
														return (
															<tr style={{ border: '1px solid black' }}>
																<td width="40" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.plu}</td>
																<td width="260" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.descripcion}</td>
																<td width="80" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.cantidad_vendida}</td>
																<td width="100" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.precio}</td>
															</tr>
														)
													})
												}
											</table>
											</td>
											<td width="60" align="center"></td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</div>
					<GridItem xs={12} sm={12} md={12}>
						<Card>
							<CardHeader color="warning">
								<h6>PROMOTORES QUE MAS VENDEN EN ($) </h6>
							</CardHeader>
							<CardBody>
								<div className="row">
									<div className="col-md-3">
										<FormControlLabel
											control={<Checkbox value={this.state.promotoresTiempo == 0} checked={this.state.promotoresTiempo == 0} style={{ padding: '5px' }} onChange={this.changeSelect('promotoresTiempo', 0)} />}
											label='HOY'
											style={{ margin: '0' }}
										/>
									</div>
									<div className="col-md-3">
										<FormControlLabel
											control={<Checkbox value={this.state.promotoresTiempo == 9} checked={this.state.promotoresTiempo == 9} style={{ padding: '5px' }} onChange={this.changeSelect('promotoresTiempo', 9)} />}
											label='ESTA SEMANA'
											style={{ margin: '0' }}
										/>
									</div>
									<div className="col-md-3">
										<FormControlLabel
											control={<Checkbox value={this.state.promotoresTiempo == 2} checked={this.state.promotoresTiempo == 2} style={{ padding: '5px' }} onChange={this.changeSelect('promotoresTiempo', 2)} />}
											label='ESTE MES'
											style={{ margin: '0' }}
										/>
									</div>
									<div className="col-md-3">
										<FormControlLabel
											control={<Checkbox value={this.state.promotoresTiempo == 3} checked={this.state.promotoresTiempo == 3} style={{ padding: '5px' }} onChange={this.changeSelect('promotoresTiempo', 3)} />}
											label='ESTE AÑO'
											style={{ margin: '0' }}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<FormControlLabel
											control={<Checkbox value={this.state.promotoresTiempo == 1} checked={this.state.promotoresTiempo == 1} style={{ padding: '5px' }} onChange={this.changeSelect('promotoresTiempo', 1)} />}
											label='HACE UNA SEMANA'
											style={{ margin: '0' }}
										/>
									</div>
									<div className="col-md-3">
										<FormControlLabel
											control={<Checkbox value={this.state.promotoresTiempo == 8} checked={this.state.promotoresTiempo == 8} style={{ padding: '5px' }} onChange={this.changeSelect('promotoresTiempo', 8)} />}
											label='HACE UN MES'
											style={{ margin: '0' }}
										/>
									</div>
									<div className="col-md-3">
										<FormControlLabel
											control={<Checkbox value={this.state.promotoresTiempo == 7} checked={this.state.promotoresTiempo == 7} style={{ padding: '5px' }} onChange={this.changeSelect('promotoresTiempo', 7)} />}
											label='HACE UN AÑO'
											style={{ margin: '0' }}
										/>
									</div>
								</div>
								<div className="row">
									<ExcelFile
										filename={"PROMOTORES_QUE_MAS_VENDEN"}
										element={
											<Tooltip title="EXPORTAR A:" placement="top">
												<button className="btn btn-success" style={{ marginLeft: '12PX' }}>
													<img src={excelIcon} />
												</button>
											</Tooltip>
										}
									>
										<ExcelSheet data={this.state.excelPromotoresMas} name="PRODUCTOS">
											<ExcelColumn label="TIENDA" value="tienda" />
											<ExcelColumn label="PROMOTOR" value="descripcion" />
											<ExcelColumn label="VENTAS ($)" value="total" />
										</ExcelSheet>
									</ExcelFile>
									<Tooltip title="EXPORTAR A:" placement="top">
										<button className="btn btn-danger" style={{ marginLeft: '12PX' }} disabled={this.state.promotoresMasCants.length === 0 || this.state.promotoresMasCants === null}
											onClick={() => this.imprimirMasVendidos(this.state.imprimirPromotores, 'grafica_promotores', 'grafica-3', 'promotores', "PROMOTORES QUE MAS VENDEN")}>
											<img src={pdfIcon} />
										</button>
									</Tooltip>
								</div>
								{
									this.state.promotoresMasCants.length === 0 || this.state.promotoresMasCants === null
										?
										<div className="row" style={{ width: '100%' }}>
											<h5 className="mx-auto">NO HAY DATOS</h5>
										</div>
										:
										window.innerWidth < 720 ?
											<HorizontalBar data={promotoresMas} ref="grafica_promotores" height={300} />
											:
											<HorizontalBar data={promotoresMas} ref="grafica_promotores" />
								}
							</CardBody>


						</Card>
					</GridItem>
					<div id="promotores" style={this.state.imprimirMenosVendidos ? { width: '800px', display: 'block', marginTop: '20px' } : { display: 'none' }}>
						<table className="tb-terpel" width="712px" height="773px" border="0" align="center" cellpadding="0" cellspacing="0">
							<tr>
								<td width="763px" valign="top" className="fondocuadro">
									<table width="712px" border="0" cellpadding="0" cellspacing="0">
										<tr>
											<td width="169"><img src={logoTerpel} width="138" height="63" alt="" /></td>
											<td width="335" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td align="center"><h6><b>
														PROMOTORES QUE MAS VENDIERON
														{
															this.state.promotoresTiempo === 0 ?
																' HOY'
																: this.state.promotoresTiempo === 1 ?
																	' ESTA SEMANA'
																	: this.state.promotoresTiempo === 2 ?
																		' ESTE MES'
																		: this.state.promotoresTiempo === 3 ?
																			' ESTE AÑO' : null
														}</b></h6></td>

												</tr>

											</table></td>
											<td width="106" align="center"><img src={logoDeuna} width="64" height="64" alt="" /></td>
										</tr>
									</table>
									<table width="712px" border="0" cellpadding="0" cellspacing="0">
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td align="center"><div id="grafica-3"></div></td>
												</tr>
											</table></td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center"><table width="100%" style={{ border: '1px solid black' }} cellspacing="0" cellpadding="0">
												<tr style={{ border: '1px solid black' }}>
													<th width="40" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>TIENDA</th>
													<th width="260" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>PROMOTOR</th>
													<th width="80" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>VENTAS ($)</th>
												</tr>
												{
													this.state.excelPromotoresMas.map((producto) => {
														return (
															<tr style={{ border: '1px solid black' }}>
																<td width="120" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.tienda}</td>
																<td width="260" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.descripcion}</td>
																<td width="110" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.total}</td>
															</tr>
														)
													})
												}
											</table>
											</td>
											<td width="60" align="center"></td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</div>
					<GridItem xs={12} sm={12} md={12}>
						<Card>
							<CardHeader color="warning">
								<h4>{'VENTAS POR DIA DEL 01 DE ' + moment(fechaActual).format('MMMM').toUpperCase() + ' AL ' + moment(fechaActual).format('DD') + ' DE ' + moment(fechaActual).format('MMMM').toUpperCase()}</h4>
							</CardHeader>
							<CardBody>
								<div className="row">
									<ExcelFile
										filename={"VENTAS_DEL_MES"}
										element={
											<Tooltip title="EXPORTAR A:" placement="top">
												<button className="btn btn-success" style={{ marginLeft: '12PX' }}>
													<img src={excelIcon} />
												</button>
											</Tooltip>
										}
									>
										<ExcelSheet data={this.state.excelVentas} name="PRODUCTOS">
											<ExcelColumn label="FECHA" value="fecha" />
											<ExcelColumn label="TIENDA" value="tienda" />
											<ExcelColumn label="VENTAS ($)" value="ventas" />
										</ExcelSheet>
									</ExcelFile>
									<Tooltip title="EXPORTAR A:" placement="top">
										<button className="btn btn-danger" style={{ marginLeft: '12PX' }}
											onClick={() => this.imprimirMasVendidos(this.state.imprimirPromotores, 'grafica_dias', 'grafica-4', 'dias', 'VENTAS POR DIA DEL 01 DE ' + moment(fechaActual).format('MMMM').toUpperCase() + ' AL ' + moment(fechaActual).format('DD') + ' DE ' + moment(fechaActual).format('MMMM').toUpperCase())}>
											<img src={pdfIcon} />
										</button>
									</Tooltip>
								</div>
								<div className="row">
									<Bar
										data={VentasxDia}
										width={100}
										height={300}
										options={{
											maintainAspectRatio: false
										}}
										ref="grafica_dias"
									/>
								</div>

							</CardBody>
						</Card>
					</GridItem>
					<div id="dias" style={this.state.imprimirMenosVendidos ? { width: '800px', display: 'block', marginTop: '20px' } : { display: 'none' }}>
						<table className="tb-terpel" width="712px" height="773px" border="0" align="center" cellpadding="0" cellspacing="0">
							<tr>
								<td width="763px" valign="top" className="fondocuadro">
									<table width="712px" border="0" cellpadding="0" cellspacing="0">
										<tr>
											<td width="169"><img src={logoTerpel} width="138" height="63" alt="" /></td>
											<td width="335" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td align="center"><h6><b>
														{'VENTAS POR DIA DEL 01 DE ' + moment(fechaActual).format('MMMM').toUpperCase() + ' AL ' + moment(fechaActual).format('DD') + ' DE ' + moment(fechaActual).format('MMMM').toUpperCase()}
													</b></h6></td>

												</tr>

											</table></td>
											<td width="106" align="center"><img src={logoDeuna} width="64" height="64" alt="" /></td>
										</tr>
									</table>
									<table width="712px" border="0" cellpadding="0" cellspacing="0">
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td align="center"><div id="grafica-4"></div></td>
												</tr>
											</table></td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center"><table width="100%" style={{ border: '1px solid black' }} cellspacing="0" cellpadding="0">
												<tr style={{ border: '1px solid black' }}>
													<th width="40" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>FECHA</th>
													<th width="260" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>TIENDA</th>
													<th width="80" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>VENTAS ($)</th>
												</tr>
												{
													this.state.excelVentas.map((producto) => {
														return (
															<tr style={{ border: '1px solid black' }}>
																<td width="120" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.fecha}</td>
																<td width="260" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.tienda}</td>
																<td width="110" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.ventas}</td>
															</tr>
														)
													})
												}
											</table>
											</td>
											<td width="60" align="center"></td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</div>
					<GridItem xs={12} sm={12} md={12}>
						<Card>
							<CardHeader color="warning">
								{/*
									this.state.venta_meses_tiempo === 2020
										?
										<h4>{'VENTAS POR MES DE ENERO A ' + moment(fechaActual).format('MMMM').toUpperCase() + ' DE ' + moment(fechaActual).format('YYYY').toUpperCase()}</h4>
										:
										<h4>{'VENTAS POR MES DEL AÑO ' + this.state.venta_meses_tiempo}</h4>
								*/}
								<h4>{'VENTAS POR MES DEL AÑO ' + this.state.venta_meses_tiempo}</h4>

							</CardHeader>
							<CardBody>
								<div className="row">
									{
										años.map((anio) => {
											return (
												<div className="col-md-3">
													<FormControlLabel
														control={<Checkbox value={this.state.venta_meses_tiempo == anio} checked={this.state.venta_meses_tiempo == anio} style={{ padding: '5px' }} onChange={this.changeSelect('venta_meses_tiempo', anio)} />}
														label={anio}
														style={{ margin: '0' }}
													/>
												</div>
											)
										})
									}


								</div>
								<div className="row">
									<ExcelFile
										filename={"VENTAS POR MES"}
										element={
											<Tooltip title="EXPORTAR A:" placement="top">
												<button className="btn btn-success" style={{ marginLeft: '12PX' }}>
													<img src={excelIcon} />
												</button>
											</Tooltip>
										}>
										<ExcelSheet data={this.state.excelMeses} name="Meses">
											<ExcelColumn label="MES" value="mes" />
											<ExcelColumn label="VENTA NETA" value="venta_neta" />
											<ExcelColumn label="IMPUESTOS" value="impuestos" />

											<ExcelColumn label="VENTA TOTAL" value="total" />
										</ExcelSheet>
									</ExcelFile>
									<Tooltip title="EXPORTAR A:" placement="top">
										<button className="btn btn-danger" style={{ marginLeft: '12PX' }}
											onClick={() => this.imprimirMasVendidos(this.state.imprimirMeses, 'grafica_meses', 'grafica-5', 'meses', 'VENTAS POR MES DE ENERO A ' + moment(fechaActual).format('MMMM').toUpperCase() + ' DE ' + moment(fechaActual).format('YYYY').toUpperCase())}>
											<img src={pdfIcon} />
										</button>
									</Tooltip>
								</div>
								<div className="row">
									<Bar
										data={VentasxMes}
										width={100}
										height={300}
										ref="grafica_meses"
										options={{
											maintainAspectRatio: false
										}}
									/>
								</div>

							</CardBody>
						</Card>
					</GridItem>
					<div id="meses" style={this.state.imprimirMenosVendidos ? { width: '800px', display: 'block', marginTop: '20px' } : { display: 'none' }}>
						<table className="tb-terpel" width="712px" height="773px" border="0" align="center" cellpadding="0" cellspacing="0">
							<tr>
								<td width="763px" valign="top" className="fondocuadro">
									<table width="712px" border="0" cellpadding="0" cellspacing="0">
										<tr>
											<td width="169"><img src={logoTerpel} width="138" height="63" alt="" /></td>
											<td width="335" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td align="center"><h6><b>
														{'VENTAS POR MES DE ENERO A ' + moment(fechaActual).format('MMMM').toUpperCase() + ' DE ' + moment(fechaActual).format('YYYY').toUpperCase()}
													</b></h6></td>

												</tr>

											</table></td>
											<td width="106" align="center"><img src={logoDeuna} width="64" height="64" alt="" /></td>
										</tr>
									</table>
									<table width="712px" border="0" cellpadding="0" cellspacing="0">
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
												<tr>
													<td align="center"><div id="grafica-5"></div></td>
												</tr>
											</table></td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center">
												&nbsp;
											</td>
											<td width="60" align="center"></td>
										</tr>
										<tr>
											<td width="60"></td>
											<td width="490" align="center"><table width="100%" style={{ border: '1px solid black' }} cellspacing="0" cellpadding="0">
												<tr style={{ border: '1px solid black' }}>
													<th width="40" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>MES</th>
													<th width="160" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>VENTA NETA</th>
													<th width="100" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>IMPUESTOS</th>
													<th width="80" align="center" valign="middle" style={{ textAlign: 'center', border: '1px solid black' }}>TOTAL ($)</th>
												</tr>
												{
													this.state.excelMeses.map((producto) => {
														return (
															<tr style={{ border: '1px solid black' }}>
																<td width="120" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.mes}</td>
																<td width="160" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.venta_neta}</td>
																<td width="100" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.impuestos}</td>
																<td width="110" align="center" class="numero2" style={{ border: '1px solid black' }}>{producto.total}</td>
															</tr>
														)
													})
												}
											</table>
											</td>
											<td width="60" align="center"></td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</div>
					{usuario.tipo == "5" ? <GridItem xs={12} sm={12} md={12}>
						<Card>
							<CardHeader color="warning">
								<h4>{'PRODUCTOS MAS RENTABLES'}</h4>
							</CardHeader>
							<CardBody>
								<div id="ganancias" style={{ minWidth: '310px', height: '500px', margin: '0 auto' }}></div>
							</CardBody>
						</Card>
					</GridItem> : null}
					{/*<GridItem xs={12} sm={12} md={12}>
		  <Card>
			<CardHeader color="warning">
			  <h2>VENTAS POR MES</h2>
			</CardHeader>
			<CardBody>
			  <Bar
				data={bar}
				width={100}
				height={300}
				options={{
				  maintainAspectRatio: false
				}}
			  />
			</CardBody>
		  </Card>
		</GridItem>
		<GridItem xs={12} sm={12} md={12}>
		  <Card>
			<CardHeader color="warning">
			  <h2>VENTAS POR AÑO</h2>
			</CardHeader>
			<CardBody>
			  <Bar
				data={bar}
				width={100}
				height={300}
				options={{
				  maintainAspectRatio: false
				}}
			  />
			</CardBody>
		  </Card>
		</GridItem>/*}
		{/*<GridContainer>
		  <GridItem xs={12} sm={12} md={6}>
			<CustomTabs
			  title="tareas:"
			  headerColor="primary"
			  tabs={[
				{
				  tabName: "Comercial",
				  tabIcon: BugReport,
				  tabContent: (
					<Tasks
					  checkedIndexes={[0, 3]}
					  tasksIndexes={[0, 1]}
					  tasks={bugs}
					/>
				  )
				},
				{
				  tabName: "Administrativas",
				  tabIcon: Code,
				  tabContent: (
					<Tasks
					  checkedIndexes={[0]}
					  tasksIndexes={[0]}
					  tasks={website}
					/>
				  )
				}
			  ]}
			/>
		  </GridItem>
		  <GridItem xs={12} sm={12} md={6}>
			<Card>
			  <CardHeader color="warning">
				<h2>Ventas del año </h2>
			  </CardHeader>
			  <CardBody>
				<Pie data={pie} />
			  </CardBody>


			</Card>
		  </GridItem>
		  <GridItem xs={12} sm={12} md={6}>
			<Card>
			  <CardHeader color="warning">
				<h2>BAR</h2>
			  </CardHeader>
			  <CardBody>
				<Bar
				  data={bar}
				  width={100}
				  height={300}
				  options={{
					maintainAspectRatio: false
				  }}
				/>
			  </CardBody>
			</Card>
		  </GridItem>

		  <GridItem xs={12} sm={12} md={6}>
			<Card>
			  <CardHeader color="warning">
				<h2>Ventas del año </h2>
			  </CardHeader>
			  <CardBody>
				<BarChart
				  width={500}
				  height={300}
				  data={barChartt2}
				  margin={{
					top: 5, right: 30, left: 20, bottom: 5,
				  }}
				>
				  <CartesianGrid strokeDasharray="3 3" />
				  <XAxis dataKey="name" />
				  <YAxis />
				  <Tooltip />
				  <Legend />
				  <Barra dataKey="VENTA" fill="#8884d8" />
				</BarChart>
			  </CardBody>


			</Card>
		  </GridItem>
		  <GridItem xs={12} sm={12} md={6}>
			<Card>
			  <CardHeader color="warning">
				<h2>Ventas del año </h2>
			  </CardHeader>
			  <CardBody>
				<Doughnut data={dona} />
			  </CardBody>


			</Card>
		  </GridItem>
				</GridContainer>*/}
				</div>
		);
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
