
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios'
import TelefonoIcon from '@material-ui/icons/Phone'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import store from '../../../lib/store'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import swal from 'sweetalert'
class dialogTelefono extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            telefonos: [],
            auxTelefonos: [],
            etiqueta: '',
            telefono: ''
        };
        store.subscribe(() => {
            this.setState({
                telefonos: store.getState().telefonos,
            })
        })
    }

    componentWillMount() {
        var { telefonos } = store.getState()
        this.setState({ telefonos: telefonos })
    }

    guardarTelefonos = () => {
        store.dispatch({
            type: "AGREGAR_TELEFONO_USUARIO",
            telefonos: this.state.telefonos,
            creacionTelefono: false,
        })
    }

    eliminarTelefono = (e) => {
        var mcorreo = this.state.telefonos
        var auxiliar = this.state.auxTelefonos
        auxiliar.push(mcorreo[e])
        mcorreo.splice(e, 1)
        this.setState({ telefonos: mcorreo, auxTelefonos: auxiliar })
    }

    cerrarVentana = () => {
        var telefonosGuardar = []
        this.state.telefonos.map((tel) => {
            telefonosGuardar.push(tel)
        })
        this.state.auxTelefonos.map((tel) => {
            telefonosGuardar.push(tel)
        })
        store.dispatch({
            type: "AGREGAR_TELEFONO_USUARIO",
            telefonos: telefonosGuardar,
            creacionTelefono: false,
        })
    }

    agregarTelefono = (etiqueta, telefono) => {
        var telefononuevo = this.state.telefonos
        if (etiqueta == "") {
            swal("LA ETIQUETA ES NECESARIA", "", "warning")
        } else if (telefono == "") {
            swal("EL TELEFONO ES NECESARIO", "", "warning")
        } else {
            telefononuevo.push({ id: 0, etiqueta, telefono })
            this.setState({ telefonos: telefononuevo, etiqueta: '', telefono: '' })
        }

    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };




    render() {
        return (
            <Card style={{ marginTop: "0", paddingBottom: "15px" }}>
                <DialogTitle id="form-dialog-title">Telefonos</DialogTitle>
                <CardContent>
                    <div className="row bottom-aligned">
                        <div className="col-md-5">
                            <label htmlFor="numordencompra" className="col-form-label">Etiqueta</label>
                            <input id="numordencompra" autoComplete="off" value={this.state.etiqueta} className="form-control" type="text" onChange={this.handleChange('etiqueta')} />                        </div>
                        <div className="col-md-5">
                            <label htmlFor="numordencompra" className="col-form-label">Telefono</label>
                            <input id="numordencompra" autoComplete="off" value={this.state.telefono} className="form-control" type="number" onChange={this.handleChange('telefono')} />
                        </div>
                        <div className="col-md-2 bottom-aligned">
                            <button className="btn btn-primary" onClick={() => this.agregarTelefono(this.state.etiqueta, this.state.telefono)}>+</button>
                        </div>
                    </div>
                    <div className="row mx-auto">
                        <List className="mx-auto" >
                            {this.state.telefonos.map((contacto, key) => {
                                if (contacto.estado != "I") {
                                    return (
                                        <ListItem className="mx-auto border" key={key}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <TelefonoIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={contacto.etiqueta} secondary={contacto.telefono} />
                                            <ListItemSecondaryAction>
                                                <IconButton aria-label="Delete" onClick={() => this.eliminarTelefono(key)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )
                                }
                            })}
                        </List>
                    </div>
                    <div className="row bottom-aligned">
                        <div className="col-md-9">
                            <button className="btn btn-danger" onClick={this.cerrarVentana} style={{ float: 'right' }}>CANCELAR</button>
                        </div>
                        <div className="col col-md-3">
                            <button className="btn btn-success" onClick={this.guardarTelefonos} style={{ float: 'right' }}>GUARDAR</button>
                        </div>
                    </div>
                </CardContent>
            </Card>
        )
    }
}


export default dialogTelefono