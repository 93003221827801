import React, { Component } from 'react';
//Librerias
import Axios from 'axios'
//Componentes
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

//assets
import "./login.css";
import img from '../assets/img/foto_login.jpg'
import img2 from '../assets/img/logo_big.png'
import store from '../lib/store'

//
import logoDevitech from '../assets/img/logo_device.png'
import logoTerpel from '../assets/img/logo_terpel.png'
//
import servidor from '../lib/server'
import swal from 'sweetalert'
import { Button, Form, FormLabel, FormControl, FormGroup, FormText, Row, Col } from 'react-bootstrap';
import { timingSafeEqual } from 'crypto';
import moment from 'moment';
var md5 = require('md5');

var imagenLogo = ''
var imagenFondo = ''
class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perfil: [],
      empresas: [],
      openDialog: false,
      username: '',
      usernameForget: '',
      password: '',
      mobileOpen: false,
      cedula: '',
      ventanaContra: false,
      actualizarVista: false
    }
    store.subscribe(() => {
      this.setState({
        actualizarVista: store.getState().actualizarvista,
      })
    })
  }


  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }
  handleClickOpen = () => {
    this.setState({ openDialog: true });
  };
  handleClose = () => {
    this.setState({ openDialog: false });
  };
  handleUsername(e) {
    this.setState({ username: e.target.value });
  }
  handleUsernameForget(e) {
    this.setState({ usernameForget: e.target.value.toUpperCase() });
  }
  handlePassword(e) {
    this.setState({ password: e.target.value });
  }
  handleCedula(e) {
    this.setState({ cedula: e.target.value.toUpperCase() });
  }



  async validarFormulario(e) {
    e.preventDefault()
    var dominio = store.getState().dominio
    var user = this.state.username
    var pass = (md5(this.state.password))
    var token = false
    var n = servidor.hostPrueba + servidor.puertoSSLPrueba
    var datos = {
      "user": user.toUpperCase(),
      "pass": pass,
      "empresas_id": dominio
      //"empresas_id": store.getState().dominio
    }
    Axios.post(n + '/login/', datos)
      .then(response => {
        console.log(response)
        if (response.status == 200) {
          token = true
          localStorage.removeItem('token');
          localStorage.setItem('token', response.data.json.token)
          this.actualizarEstado(token, response.data.usuario)
          //console.log(response.data)
          window.location.href = '/';
        } else if (response.status == 203) {
          alert(response.data.message)
        } else {
          alert("Credenciales incorrectas")
        }
      })
      .catch((err) => {
        alert(err)
      })
  }

  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };

  actualizarEstado(token, userall) {
    store.dispatch({
      type: "INICIO_DE_SESION",
      token,
      usuario: userall
    })
  }


  abrirFormulario() {
    this.setState({
      ventanaContra: !this.state.ventanaContra
    });
    this.setState({
      cedula: '',
      usernameForget: '',
    })
  }


  shouldComponentUpdate(nextProps, nextState) {
   
    if (this.state.actualizarVista) {

      return true
    }
    return true;
  }

  

  cerrarDialog = () => {
    store.dispatch({
      type: "OLVIDO_CONTRASEÑA",
      ventanaOlvideContra: false
    })
  }

  borrarDatos = () => {
    this.setState({
      cedula: '',
      usernameForget: '',
    })
  }


  enviarDatos = (evt) => {
    const { cedula, usernameForget } = this.state;
    evt.preventDefault();
    if (!/^([0-9])*$/.test(this.state.cedula) || cedula.trim === '' || cedula.length == 0) {
      swal("ERROR", "EL DATO INGRESADO DEBE SER UN NÚMERO Y NO PUEDE ESTAR VACIO", "warning")
    } else if (usernameForget.trim === '' || usernameForget.length == 0) {
      swal("ERROR", "EL CAMPO USUARIO NO PUEDE ESTAR VACIO", "warning")
    } else {
      var usuario = this.state.usernameForget;
      var num_documento = this.state.cedula;
      var data = {
        "identificacion": num_documento,
        "usuario": usuario,
      };
      Axios.put(servidor.hostPruebasinssl + servidor.puertoEmpleado + '/api/persona/usuario/forgetPassword', data)
        .then(function (response) {
          var mensaje = response.data.mensaje;
          console.log(response);
          if (response.status === 200) {
            if (response.data.success) {
              swal({
                title: mensaje,
                icon: "success",
                closeOnClickOutside: false,
                buttons: {
                  aceptar: {
                    text: "OK",
                    value: "continuar"
                  }
                },
              }).then((value) => {
                switch (value) {
                  case "continuar":
                    store.dispatch({
                      type: "OLVIDO_CONTRASEÑA",
                      ventanaOlvideContra: false
                    })
                    this.borrarDatos()
                    break;

                  default:
                }
              });
            } else {
              swal("ERROR", mensaje, "warning")
            }
          }
        })
    }
  }

  render() {
    var dominio = store.getState().dominio
    imagenLogo = servidor.hostMultimedia + dominio + '/pagina/logo_big.png'+"?"+moment(new Date()).format("YYYYMMMDDHHmmss")
    imagenFondo = servidor.hostMultimedia + dominio + '/pagina/foto_login.jpg'+"?"+moment(new Date()).format("YYYYMMMDDHHmmss")
    return (
      <div style={{ height: "100%", padding: "0", margin: "0" }}>
        <main style={{ height: "100%" }}>
          <div className="row container-fluid" style={{ height: "100%", width: "100%", padding: "0", margin: "0" }}>
            {
              store.getState().ismobile ? null : <div className="login-img col-md-8 form-login">
                <img src={imagenFondo} alt="DEUNA" style={{ display: "flex", height: "100vh", width: "100%", borderRight: '5px solid #f44336' }} />
              </div>
            }
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="form-login col col-md-4">
              <form className="row" style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                <div className="col col-md-12 my-auto" >
                  {/*<div className="row mx-auto text-center">
                    <h2 style={{ width: "100%", fontWeight: 'bold' }}>DEUNA</h2>
          </div>*/}
                  <div className="row mx-auto">
                    <div className="col-md-8  colimage mx-auto" style={{ marginBottom: "15px", marginTop: '20px' }}>
                      <img src={imagenLogo} alt="empresa-img" style={{ height: '200px', display: "flex" }} className="logo mx-auto" />
                    </div>
                  </div>
                  <div className="row mx-auto ">
                    <TextField
                      error
                      id="user"
                      label="Usuario"
                      placeholder="Usuario"
                      className="col-md-12"
                      margin="normal"
                      variant="outlined"
                      onChange={this.handleUsername.bind(this)}
                      required={true}
                      style={{ backgroundColor: '#fff' }}
                    />
                  </div>
                  <div className="row mx-auto ">
                    <TextField
                      error
                      style={{ backgroundColor: '#fff' }}
                      id="clave"
                      label="Contraseña"
                      placeholder="Contraseña"
                      type="password"
                      autoComplete="current-password"
                      className="col-md-12"
                      margin="normal"
                      variant="outlined"
                      onChange={this.handlePassword.bind(this)}
                      required={true}
                    />
                  </div>
                  <div className="row mx-auto ">
                    <button className="boton_personalizado col-md-12" disabled={!this.validateForm()} onClick={this.validarFormulario.bind(this)}>INGRESAR</button>
                  </div>
                  <div className="row mx-auto ">
                    <a href="#" onClick={() => this.abrirFormulario()} className="texto-contrasena mx-auto">OLVIDE MI CONTRASEÑA</a>
                  </div>
                  <Dialog
                    open={this.state.ventanaContra}
                    onClose={() => this.abrirFormulario()}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'md'}
                    fullWidth={true}
                    style={{ padding: '0' }}
                    disableBackdropClick={false}>
                    <DialogContent style={{ padding: '30px' }}>
                      <Form>
                        <Form.Group>
                          <Col sm="12">
                            <Form.Label column sm="12">IDENTIFICACIÓN</Form.Label>
                            <Form.Control type="number" placeholder="Escriba su cedula" autoComplete="off" value={this.state.cedula} onChange={this.handleCedula.bind(this)} required />
                          </Col>
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                          <Col sm="12">
                            <Form.Label column sm="12">USUARIO</Form.Label>
                            <Form.Control type="text" placeholder="Escriba su usuario" autoComplete="off" value={this.state.usernameForget} onChange={this.handleUsernameForget.bind(this)} required />
                          </Col>
                        </Form.Group>
                        <div className="col-md-3 mx-auto" style={{ float: "right" }}>
                          <Button variant="primary" onClick={this.enviarDatos} className="btn-lg btn-block">
                            ENVIAR
                              </Button>
                        </div>
                        <div className="col-md-3 mx-auto" style={{ float: "right" }}>
                          <Button variant="danger" className=" btn-lg btn-block" onClick={this.abrirFormulario.bind(this)}>
                            CANCELAR
                              </Button>
                        </div>
                      </Form>
                    </DialogContent>
                  </Dialog>
                  <div className="row mx-auto" style={{ marginTop: '20px' }}>
                    <div className="col-md mx-auto">
                      <img src={logoDevitech} className="logo mx-auto" alt="Devitech" style={{ display: "flex" }} />
                    </div>
                    <div className="col-md mx-auto">
                      <img src={logoTerpel} className="logo mx-auto" alt="Terpel" style={{ display: "flex" }} />
                    </div>
                  </div>
                 
                </div>
              </form>
            </div>
          </div>
        </main>

        <Dialog
          open={this.state.openDialog}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">ACCESO A SUB-EMPRESAS</DialogTitle>
          <List>
            <Divider />
            <ListItem button>
              <ListItemText primary="PRINCIPAL" />
            </ListItem>
            {
              this.state.empresas.map((empresas, key) => {
                return (
                  <div key={key}>
                    <Divider />
                    <ListItem button>
                      <ListItemText primary={empresas.razon_social} />
                    </ListItem>
                  </div>
                )
              })
            }
          </List>
        </Dialog>

      </div>
    );
  }
}

export default login;