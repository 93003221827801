import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { MDBDataTable } from 'mdbreact';
import Paper from '@material-ui/core/Paper';
//import FormularioParametros from '../../../components/Formularios/FormularioParametros'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormularioParametros from './formularioParametros'
import { getParametros, modifyParametros } from './services'
import Ojoicon from "@material-ui/icons/RemoveRedEye"
import DeleteIcon from '@material-ui/icons/Delete';
import EditICon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import store from '../../../lib/store'
import CheckIcon from "@material-ui/icons/Check"
const idModulo = 19
let permisos = ''
class Entradas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //Variables de componente principal
            createPARAMETROS: false,
            actualizarDatos: false,
            movimientosbd: [],
            columns: [
                {
                    label: 'DESCRIPCION',
                    field: 'descripcion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'TIPO',
                    field: 'tipo',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'PRIVACIDAD',
                    field: 'privacidad',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'COMENTARIOS',
                    field: 'comentarios',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'ACCIONES',
                    field: 'acciones',
                    sort: 'asc',
                    width: 150
                }
            ],
            rows: [],
            data: [],
            actualizarDatos:false
        }
        store.subscribe(() => {
            this.setState({
                createPARAMETROS: store.getState().creacionParametros,
                actualizarDatos: store.getState().actualizarvista,
            })
        })

    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizarDatos) {
            this.obtenerDatos()
            return true
        }
        return true;
    }


    componentWillMount = () => {
        //Permisos usuarios
        const modulos = store.getState().perfilesUSuario
        modulos.map((modulo) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        this.obtenerDatos()
    }

    obtenerDatos = async () => {
        store.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: false
        })
        const data = await getParametros()
        let params = []
        if (data != null) {
            data.map((dat) => {
                params.push({
                    descripcion: dat.codigo,
                    tipo: dat.tipo,
                    visibilidad: dat.visibilidad === 0 ? 'PRIVADO' : 'PUBLICO',
                    comentarios: dat.comentarios,
                    acciones: [
                        <Tooltip title="VER DETALLES" placement="top">
                            <button className="btn btn-primary" style={{ margin: '10px', padding: '5px' }}
                                onClick={() => this.handleCrearOrdenDeCompra({ id: dat, metodo: "GET" })}
                            ><Ojoicon /></button>
                        </Tooltip>, <Tooltip title="EDITAR" placement="top">
                            <button className="btn btn-warning" style={{ margin: '10px', padding: '5px' }}
                                onClick={() => this.handleCrearOrdenDeCompra({ id: dat, metodo: "UPDATE" })}
                            ><EditICon /></button>
                        </Tooltip>,
                        dat.estado === "A"
                            ? <Tooltip title="INACTIVAR" placement="top">
                                <button className="btn btn-danger" style={{ margin: '10px', padding: '5px' }}
                                    onClick={() => this.handleCrearOrdenDeCompra({ id: dat.id, metodo: "MODIFY", estado: "I" })}
                                ><DeleteIcon /></button>
                            </Tooltip>
                            : <Tooltip title="ACTIVAR" placement="top">
                                <button className="btn btn-success" style={{ margin: '10px', padding: '5px' }}
                                    onClick={() => this.handleCrearOrdenDeCompra({ id: dat.id, metodo: "MODIFY", estado: "A" })}
                                ><CheckIcon /></button>
                            </Tooltip>,
                    ]
                })
            })
        }
        this.setState({
            rows: params,
            data
        })
    }

    handleCrearOrdenDeCompra = async ({ id, metodo, estado }) => {
        let dat = {id, metodo }
        if (metodo === "POST" || metodo === "GET" || metodo === "UPDATE") {
            store.dispatch({
                type: "CREACION_PARAMETROS",
                creacionParametros: !this.state.createPARAMETROS,
                infoSurtidor: { id, metodo }
            })
        } else if (metodo === "MODIFY") {
            await modifyParametros({ id, estado })
        }

    }

    render() {
        const { createPARAMETROS, columns, rows } = this.state
        return (
            <div>
                {
                    createPARAMETROS
                        ? <button className="btn btn-danger" onClick={() => this.handleCrearOrdenDeCompra({ id: 0, metodo: "POST" })}> Cancelar</button >
                        : permisos.includes('C') ? <button className="btn btn-success" style={{ marginRight: '10px' }} onClick={() => this.handleCrearOrdenDeCompra({ id: 0, metodo: "POST" })}>CONFIGURAR PARAMETROS</button>
                            : null
                }
                <div className="row">
                    <ExpansionPanel
                        expanded={createPARAMETROS}
                        className="col"
                        style={{ margin: "15px" }}
                    >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            PARAMETROS
                                    </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ display: "block" }}>
                            {createPARAMETROS ? <FormularioParametros />:null}

                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
                <div>
                    <div className="row" style={{ marginTop: "15px" }}>
                        {permisos.includes('R') ? <div className="col col-md-12" style={{ padding: 0 }}>
                            <Paper style={{ padding: '5px' }}>

                                <MDBDataTable
                                    //striped
                                    className="col-md-12"
                                    searchLabel="Buscar"
                                    paginationLabel={["Anterior", "Siguente"]}
                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                    entriesLabel="Filas a mostrar"
                                    tbodyColor="primary-color"
                                    theadColor="#FF8800"
                                    exportToCSV={true}
                                    striped
                                    bordered
                                    autoWidth={false}
                                    responsive={true}
                                    hover
                                    data={{
                                        columns,
                                        rows
                                    }}
                                />


                            </Paper>
                        </div> : <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>}

                    </div>
                </div>
            </div>
        )
    }
}
export default Entradas