import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Paper from '@material-ui/core/Paper';
import Server from '../../../lib/server'
import store from '../../../lib/store'
import FormularioAjuste from '../../../components/Formularios/FormularioInventarioInicial'
import Loader from '../../../components/Loader'
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide"
import Axios from 'axios'
import swal from 'sweetalert'
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment'
import "moment/locale/es";
import Ojoicon from "@material-ui/icons/RemoveRedEye"
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import logoTerpel from '../../../assets/img/logos/logo_terpel.png'
import logoDeuna from '../../../assets/img/logos/logoo_r2_c2.png'
import linea1 from '../../../assets/img/linea.png'
import linea2 from '../../../assets/img/linea2.png'
import HojaIcon from "@material-ui/icons/PictureAsPdf"
import pdfIcon from "../../../assets/img/pdf.png";
import readXlsxFile from 'read-excel-file'
import Selects from 'react-select'
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';
import DeleteIcon from '@material-ui/icons/Delete';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const idModulo = 22
var permisos = ''
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class AjusteInicial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //Variables de componente principal
            createOrdenDeCompra: false,
            actualizarDatos: false,
            movimientosbd: [],
            bloqueado: false,
            entradaSeleccionada: null,
            modoDetalles: false,
            imprimirPdf: false,
            dialogImportar: false,
            bodegasbd: [],
            bodegaSeleccionada: {},
            productosAImportar: [],
            totalCosto: 0,
            subTotal: 0,
            response: false,
            loader: false
        }
        store.subscribe(() => {
            this.setState({
                createOrdenDeCompra: store.getState().ajusteInicial,
                actualizarDatos: store.getState().actualizarvista,
            })
        })
    }


    FormularioAjuste = () => {
        store.dispatch({
            type: "AJUSTE_INICIAL",
            ajusteInicial: true
        })
        this.actualizarToken()
    }

    actualizarToken = () => {
        Axios.get(Server.hostPruebasinssl + Server.puertoAutenticacion, { headers: { Authorization: ntoken } })
            .then(response => {
                if (response.status === 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 206) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 200) {
                    //RESPEUSTA OK
                }
            })
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizarDatos) {
            this.obtenerDatos()
            return true
        }
        return true;
    }

    detallesEntrada = (entrada) => {
        ////console.log(entrada)
        this.setState({
            entradaSeleccionada: entrada,
            modoDetalles: true
        })
    }
    handleDialogImportar = () => {
        ////console.log(entrada)

        this.setState({
            dialogImportar: !this.state.dialogImportar,
            totalCosto: 0,
            subTotal: 0,
            productosAImportar: [],
            bodegaSeleccionada: {}
        })
        let t = this
        setTimeout(function () { t.cargarAccion() }, 200)
    }

    cerrarEntrada = () => {
        this.setState({
            entradaSeleccionada: null,
            modoDetalles: false
        })
    }

    imprimirMasVendidos = (entrada, nombre) => {
        this.setState({
            imprimirPdf: true,
            entradaSeleccionada: entrada,
            loader:true
        })
        let t = this
        setTimeout(function () { t.imprimir(nombre) }, 200)
    }

    cargarAccion = () => {
        if (this.state.dialogImportar) {
            const input = document.getElementById('input')
            var plus = []
            var productos = []
            input.addEventListener('change', () => {
                readXlsxFile(input.files[0]).then((rows) => {
                    rows.map((row, key) => {
                        if (key > 0) {

                            productos.push(row)
                            plus.push(row[0])
                        }
                    })

                }).finally(() => {
                    console.log(plus)
                    console.log(productos)
                    var dominio = store.getState().dominio
                    var data = {
                        "plu": plus,
                        "empresas_id": dominio

                    }
                    Axios.post(Server.hostPrueba + Server.puertoProducto + '/api/producto/web/FullPlu/', data, { headers: { Authorization: ntoken } })
                        .then(responsee => {
                            console.log(responsee)
                            var datos = []
                            var pFinales = []
                            var costo = 0
                            var subTotal = 0
                            if (responsee.status === 200) {
                                if (responsee.data.success) {
                                    datos = responsee.data.data
                                    console.log(productos)
                                    console.log(datos)
                                    productos.map((producto) => {
                                        console.log(producto)
                                        datos.map((dato) => {
                                            console.log(dato)
                                            if (parseInt(producto[0]) === parseInt(dato.plu)) {
                                                pFinales.push({
                                                    id: parseInt(dato.id),
                                                    producto: dato.descripcion,
                                                    cantidad: parseInt(producto[2]),
                                                    costo: parseFloat(producto[3]),
                                                    subtotal: parseInt(producto[2]) * parseFloat(producto[3]),
                                                    plu: dato.plu
                                                })
                                                costo = costo + parseFloat(producto[3])
                                                subTotal = subTotal + (parseInt(producto[2]) * parseFloat(producto[3]))
                                            }
                                        })
                                    })
                                    console.log(pFinales)
                                    console.log(costo)
                                    console.log(subTotal)
                                    this.setState({
                                        productosAImportar: pFinales,
                                        totalCosto: costo,
                                        subTotal: subTotal,
                                        loader:false

                                    })
                                }
                            }
                            //this.setState({ bodegasbd: bdg })
                        })
                        .catch(function (error) {
                            // handle error
                            console.error(error);
                        })
                })
            })
        }
    }

    obtenerProductos = (plus, productos) => {
        console.log(plus)
        console.log(productos)
    }


    imprimir = (nombre) => {
        const nd = document.getElementById('capture');
        var estilos = `<style>
                        .numero{
                        font-family: 'Roboto', sans-serif;
                        font-size:18px;}
                        .numero2{
                        font-family: 'Roboto', sans-serif !IMPORTANT;
                        font-size:10px;}
        
                      
        
                        .tb-terpel td{
                        font-family: 'Raleway', sans-serif;
                        font-size:11px;
                        border: 0;
                        padding: 0;
                        }
                        </style>`
        //console.log(nd)
        var download = {
            "template": estilos + nd.innerHTML,
            "filename": nombre + ".pdf"
        }
        Axios.post(Server.HostPDF + Server.puertoPdf + '/api/pdf/download', download)
            .then(response => {
                //console.log(response)
                var url = response.data.url
                var link = document.createElement('a');
                link.setAttribute("target", "_blank")
                link.download = response.data.filename;
                link.href = url;
                link.click();
                this.setState({
                    imprimirPdf: false,
                    entradaSeleccionada: null,
                })
            })


    }

    setearMiles = (numero) => {
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case "COP":
                return (
                    (new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(3)))
                )
                break;
            case "USD":
                return (new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(numero))
                break;

        }
    }

    obtenerDatos = () => {
        this.setState({ actualizarDatos: false })
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/movimiento/lista/operacion/' + (usuario.tipo === "5" ? parseInt(usuario.empresas_id, 10) : hijos) + '/1', { headers: { Authorization: ntoken } })
            .then(responsee => {
                var movimientosTotal = []
                console.log(responsee.data.movimientos)
                if (responsee.status == 200) {
                    responsee.data.movimientos.map((mov, key) => {
                        usuario.tipo === "5" ?
                            movimientosTotal.push({
                                "consecutivo": mov.movimiento.consecutivo,
                                "fecha": moment(new Date(mov.movimiento.fecha)).format("DD/MM/YYYY hh:mm a"),
                                "responsable": mov.movimiento.personas_id_detalles.nombres + " " + mov.movimiento.personas_id_detalles.apellidos,
                                "bodega": mov.detalle[0] != null ? mov.detalle[0].bodega : '',
                                "total": '$' + this.setearMiles(mov.movimiento.costo_total),
                                "accion": [<button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesEntrada(mov)}><Ojoicon /></button>,
                                <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.imprimirMasVendidos(mov, "Ajuste inicial No. " + mov.movimiento.consecutivo)}><img src={pdfIcon}/></button>]
                            })
                            :
                            movimientosTotal.push({
                                "consecutivo": mov.movimiento.consecutivo,
                                "fecha": moment(new Date(mov.movimiento.fecha)).format("DD/MM/YYYY hh:mm a"),
                                "tienda": mov.empresa.razon_social,
                                "responsable": mov.movimiento.personas_id_detalles.nombres + " " + mov.movimiento.personas_id_detalles.apellidos,
                                "bodega": mov.detalle[0] != null ? mov.detalle[0].bodega : '',
                                "total": '$' + this.setearMiles(mov.movimiento.costo_total),
                                "accion": [<button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesEntrada(mov)}><Ojoicon /></button>,
                                <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.imprimirMasVendidos(mov, "Ajuste inicial No. " + mov.movimiento.consecutivo)}><img src={pdfIcon}/></button>],
                            })
                    })
                    console.log(movimientosTotal)
                    this.setState({ movimientosbd: movimientosTotal })
                    this.validarBloqueo()
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })

    }
    componentWillMount = () => {
        //Permisos usuarios
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        this.obtenerDatos()
        var usuario = store.getState().usuario
        Axios.get(Server.hostPrueba + Server.puertoSSLBodega + '/api/bodegas/empresa/' + usuario.empresas_id, { headers: { Authorization: ntoken } })
            .then(responsee => {
                console.log(responsee.data)
                var bdg = []
                responsee.data.map((bodega, key) => {
                    bdg.push({
                        value: bodega.bodega_id,
                        label: bodega.descripcion + " -- " + bodega.codigo
                    })
                })
                this.setState({ bodegasbd: bdg })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    eliminarProducto = (e) => {
        var mproducto = this.state.productosAImportar
        var ntotal = this.state.totalCosto
        var nsubtotal = this.state.subTotal
        ntotal = ntotal - parseFloat(mproducto[e].costo)
        nsubtotal = nsubtotal - parseFloat(mproducto[e].subtotal)
        mproducto.splice(e, 1)
        this.setState({ productosAImportar: mproducto, totalCosto: ntotal, subTotal: nsubtotal })
    }


    validarBloqueo = () => {
        if (this.state.movimientosbd.length > 0) {
            this.setState({
                bloqueado: true
            })
        }
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
    };


    validarFromulario = () => {
        if (JSON.stringify(this.state.bodegaSeleccionada) == '{}' || this.state.bodegaSeleccionada1 != null) {
            swal("Fallo al crear", "SE DEBE SELECCIONAR LA BODEGA", "warning")
            return false
        } else if (this.state.productosAImportar.length == 0) {
            swal("Fallo al crear", "POR LO MENOS DEBES REGISTRAR UN PRODUCTO", "warning")
            return false
        } else {
            this.setState({
                response: true
            })
            return true
        }
    }


    registrar = () => {
        var usuario = store.getState().usuario
        var validar = this.validarFromulario()
        let MOVIMIENTO_INVENTARIO_INICIAL = 1
        var datos = {
            "movimiento": {
                "empresas_id": parseInt(usuario.empresas_id),
                "operacion": MOVIMIENTO_INVENTARIO_INICIAL,
                "fecha": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "consecutivo": 0,
                "persona_id": usuario.p_id,
                "persona_nit": "",
                "persona_nombre": "",
                "tercero_id": usuario.p_id,
                "tercero_nit": "",
                "tercero_nombre": "",
                "costo_total": parseInt(this.state.subTotal),
                "venta_total": 0,
                "impuesto_total": 0,
                "descuento_total": 0,
                "origen_id": 0,
                "impreso": "N",
                "create_user": usuario.p_id,
                "create_date": "",
                "remoto_id": 0,
                "sincronizado": 0,
                "movimiento_estado": 0
            },
            "bodega": {
                "bodega_idI": this.state.bodegaSeleccionada.value,
                //"bodega_idI": 3,
                "bodega_idF": null
            },
            "detalle":
                this.state.productosAImportar.map((producto, key) => {
                    return ({
                        "productos_id": producto.id,
                        "cantidad": producto.cantidad,
                        "costo_producto": producto.costo,
                        "precio": 0,
                        "descuento_id": 0,
                        "descuento_producto": 0,
                        "remoto_id": 0,
                        "sincronizado": 0,
                        "subtotal": 0
                    })
                })

        }
        console.log(datos)
        if (validar) {
            Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/movimiento', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    this.setState({
                        response: false
                    })
                    console.log(response)
                    if (response.status === 201) {
                        swal({
                            title: response.data.message,
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    this.handleDialogImportar()
                                    //this.setState({loader:true,descripcionN:'',selectedDate:new Date(),selectedDateFin:new Date(),checkIPorcentaje:true,checkIValor:false,valorn:false,cambbioInicio: false,cambbioFin:false})
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }


    }

    render() {
        var usuario = store.getState().usuario
        var inventarioInicial = {
            columns:
                usuario.tipo === "5" ? [
                    {
                        label: 'Con.',
                        field: 'descripcion',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'Fecha',
                        field: 'cantidad',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'Responsable',
                        field: 'vlrunitario',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'Bodega',
                        field: 'subtotal',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'Total costo',
                        field: 'totalproducto',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'Acciones',
                        field: 'acciones',
                        sort: 'asc',
                        width: 150
                    }
                ] : [
                        {
                            label: 'Con.',
                            field: 'descripcion',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Fecha',
                            field: 'cantidad',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Tienda',
                            field: 'tienda',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Responsable',
                            field: 'vlrunitario',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Bodega',
                            field: 'subtotal',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Total costo',
                            field: 'totalproducto',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Acciones',
                            field: 'acciones',
                            sort: 'asc',
                            width: 150
                        }
                    ],
            rows: this.state.movimientosbd
        }
        var detalles = this.state.entradaSeleccionada
        var empresa = null
        var vendedor = null
        var movimiento = null
        var detalle = null
        if (detalles != null) {
            empresa = detalles.empresa
            vendedor = detalles.vendedor
            movimiento = detalles.movimiento
            detalle = detalles.detalle
        }
        return (
            <div>
                {this.state.createOrdenDeCompra ? <button className="btn btn-danger" onClick={this.FormularioAjuste.bind(this)}> Cancelar</button> : <button className="btn btn-success" style={{ margin: "10px" }} onClick={this.FormularioAjuste.bind(this)} > Ajuste de inventario inicial</button>}
                {this.state.createOrdenDeCompra ? <button className="btn btn-danger" onClick={this.FormularioAjuste.bind(this)}> Cancelar</button> : <button className="btn btn-success" style={{ margin: "10px" }} onClick={this.handleDialogImportar.bind(this)}>IMPORTAR</button>}
                {this.state.createOrdenDeCompra ?
                    <Dialog fullScreen open={this.state.createOrdenDeCompra} onClose={this.FormularioAjuste} TransitionComponent={Transition}>
                        <FormularioAjuste />
                    </Dialog>
                    :
                    null}
                <div>
                    <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col col-md-12" style={{ padding: 0 }}>
                            <Paper style={{ padding: '5px' }}>

                                <MDBDataTable
                                    //striped
                                    className="col-md-12"
                                    searchLabel="Buscar"
                                    paginationLabel={["Anterior", "Siguente"]}
                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                    entriesLabel="Filas a mostrar"
                                    tbodyColor="primary-color"
                                    theadColor="#FF8800"
                                    exportToCSV={true}
                                    striped
                                    bordered
                                    autoWidth={false}
                                    responsive={true}
                                    hover
                                    style={{ fontSize: '13px' }}
                                    data={inventarioInicial}
                                />

                                <Dialog
                                    open={this.state.modoDetalles}
                                    onClose={this.cerrarEntrada}
                                    aria-labelledby="form-dialog-title"
                                    maxWidth={'md'}
                                    fullWidth={true}
                                    disableBackdropClick={true}
                                >
                                    <DialogContent >
                                        {
                                            detalles != null ?
                                                <div className="row mx-auto">
                                                    <div className="col col-md-4">
                                                        <label htmlFor="numordencompra" className="col-form-label">RESPONSABLE</label>
                                                        <input id="numordencompra" disabled className="form-control" type="text" value={detalles.movimiento.personas_id_detalles.nombres + " " + detalles.movimiento.personas_id_detalles.apellidos} />
                                                    </div>
                                                    <div className="col col-md-3">
                                                        <label htmlFor="numordencompra" className="col-form-label">FECHA</label>
                                                        <input id="numordencompra" disabled className="form-control" type="text" value={moment(detalles.movimiento.fecha).format("DD/MM/YY hh:mm a")} />
                                                    </div>
                                                    <div className="col col-md-5">
                                                        <label htmlFor="numordencompra" className="col-form-label">PROVEEDOR</label>
                                                        <input id="numordencompra" disabled className="form-control" type="text" value={detalles.movimiento.tercero_id_detalles.nombres} />
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }


                                        <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                            <div className="col col-md-12">

                                                <MDBDataTable
                                                    //striped
                                                    className="col-md-12"
                                                    searchLabel="Buscar"
                                                    paginationLabel={["Anterior", "Siguente"]}
                                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                    entriesLabel="Filas a mostrar"
                                                    tbodyColor="primary-color"
                                                    theadColor="#FF8800"
                                                    exportToCSV={true}
                                                    striped
                                                    bordered
                                                    autoWidth={false}
                                                    responsive={true}
                                                    hover
                                                    data={
                                                        {
                                                            columns: [

                                                                {
                                                                    label: 'PLU',
                                                                    field: 'celular',
                                                                    sort: 'asc',
                                                                    width: 150
                                                                }, {
                                                                    label: 'PRODUCTO',
                                                                    field: 'celular',
                                                                    sort: 'asc',
                                                                    width: 150
                                                                }, {
                                                                    label: 'BODEGA',
                                                                    field: 'identificacion',
                                                                    sort: 'asc',
                                                                    width: 150
                                                                }, {
                                                                    label: 'CANTIDAD',
                                                                    field: 'direccion',
                                                                    sort: 'asc',
                                                                    width: 150
                                                                }, {
                                                                    label: 'C. UNITARIO',
                                                                    field: 'cant',
                                                                    sort: 'asc',
                                                                    width: 150
                                                                }, {
                                                                    label: ' C. TOTAL',
                                                                    field: 'cant',
                                                                    sort: 'asc',
                                                                    width: 150
                                                                }
                                                            ], rows:
                                                                detalles != null
                                                                    ?
                                                                    detalles.detalle != null
                                                                        ?
                                                                        detalles.detalle.map((p) => {
                                                                            return ({
                                                                                'plu': p.plu,
                                                                                'producto': p.descripcion,
                                                                                'bodega': p.bodega,
                                                                                'cantidad': p.cantidad,
                                                                                'costo': '$' + this.setearMiles(p.costo_producto),
                                                                                'costot': '$' + this.setearMiles(parseInt(p.cantidad) * parseFloat(p.costo_producto)),
                                                                            })
                                                                        })
                                                                        :
                                                                        []
                                                                    :
                                                                    []
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <button className="btn btn-danger" onClick={this.cerrarEntrada.bind(this)}>CERRAR</button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={this.state.dialogImportar}
                                    onClose={this.handleDialogImportar}
                                    aria-labelledby="form-dialog-title"
                                    maxWidth={'md'}
                                    fullWidth={true}
                                    disableBackdropClick={true}
                                >
                                    <DialogContent style={this.state.productosAImportar.length < 1 ? { height: '400px' } : {}}>
                                        <div className="row mx-auto bottom-aligned" style={{ marginTop: '10px' }}>
                                            <div className="col-md-5">
                                                <label htmlFor="numordencompra" className="col-form-label">Bodega</label>
                                                <Selects
                                                    placeholder="Seleccione"
                                                    value={this.state.bodegaSeleccionada}
                                                    onChange={this.handleChanges('bodegaSeleccionada')}
                                                    options={this.state.bodegasbd}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <input type="file" id="input" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" />
                                            </div>
                                        </div>
                                        {this.state.loader ? <Loader></Loader>:null}
                                        {this.state.productosAImportar.length < 1 ? null : <div className="row mx-auto bottom-aligned" style={{ marginTop: '10px' }}>
                                           
                                                <div className="col-md-3 mx-auto">
                                                    <label htmlFor="numordencompra" className="col-form-label text-center">Total de la operacion</label>
                                                    <input id="numordencompra" value={'$' + this.setearMiles(this.state.subTotal)} disabled={true} className="form-control text-center" type="text" />
                                                </div>
                                        </div>}
                                        {this.state.productosAImportar.length < 1 ? null : <div className="row mx-auto bottom-aligned" style={{ marginTop: '10px' }}>
                                            <div className="col col-md-11 mx-auto">
                                                <Paper style={{ padding: '5px' }}>

                                                    <MDBDataTable
                                                        //striped
                                                        className="col-md-12"
                                                        searchLabel="Buscar"
                                                        paginationLabel={["Anterior", "Siguente"]}
                                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                        entriesLabel="Filas a mostrar"
                                                        tbodyColor="primary-color"
                                                        theadColor="#FF8800"
                                                        exportToCSV={true}
                                                        striped
                                                        bordered
                                                        autoWidth={false}
                                                        responsive={true}
                                                        hover
                                                        data={
                                                            {
                                                                columns: [

                                                                    {
                                                                        label: 'PLU',
                                                                        field: 'plu',
                                                                        width: 150
                                                                    }, {
                                                                        label: 'PRODUCTO',
                                                                        field: 'producto',
                                                                        width: 150
                                                                    }, {
                                                                        label: 'CANTIDAD',
                                                                        field: 'cantidad',
                                                                        width: 150
                                                                    }, {
                                                                        label: 'COSTO',
                                                                        field: 'costo',
                                                                        width: 150
                                                                    }, {
                                                                        label: 'SUBTOTAL',
                                                                        field: 'subtotal',
                                                                        width: 150
                                                                    }, {
                                                                        label: 'ELIMINAR',
                                                                        field: 'eliminar',
                                                                        width: 150
                                                                    },
                                                                ],
                                                                rows:
                                                                    this.state.productosAImportar.map((producto, key) => {
                                                                        return ({
                                                                            'plu': producto.plu,
                                                                            'producto': producto.producto,
                                                                            'cantidad': producto.cantidad,
                                                                            'costo': '$ ' + producto.costo,
                                                                            'subtotal': '$ ' + producto.subtotal,
                                                                            'eliminar': <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.eliminarProducto(key)}><DeleteIcon /></button>,
                                                                        })
                                                                    })
                                                            }
                                                        }
                                                    />
                                                </Paper>
                                            </div>
                                        </div>}
                                        
                                    </DialogContent>
                                    <DialogActions>
                                        <button className="col-md-3 btn btn-success" onClick={this.registrar.bind(this)}>FINALIZAR</button>
                                        <button className="col-md-3  btn btn-danger" onClick={this.handleDialogImportar.bind(this)}>CERRAR</button>
                                    </DialogActions>
                                </Dialog>


                                {this.state.imprimirPdf ?
                                    <Loader />
                                    :
                                    null}
                                <div id="capture" style={{ width: '800px', display: 'block', marginTop: '20px' }}>
                                    {this.state.imprimirPdf ?
                                        <table className="tb-terpel" width="712px" height="773px" border="0" align="center" cellpadding="0" cellspacing="0">
                                            <tr>
                                                <td width="763px" valign="top" className="fondocuadro">
                                                    <table width="712px" border="0" cellpadding="0" cellspacing="0">
                                                        <tr>
                                                            <td width="169"><img src={logoTerpel} width="138" height="63" alt="" /></td>
                                                            <td width="335" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                <tr>
                                                                    <td align="center">AJUSTE DE INVENTARIO INICIAL</td>
                                                                </tr>
                                                            </table></td>
                                                            <td width="106" align="center"><img src={logoDeuna} width="64" height="64" alt="" /></td>
                                                        </tr>
                                                    </table>
                                                    <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                        <tr>
                                                            <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                        </tr>
                                                    </table>
                                                    <table width="712px" border="0" cellpadding="0" cellspacing="3">
                                                        <tr>
                                                            <td width="299" valign="top"><b className="fontipo">Tienda:</b> {empresa.alias}</td>
                                                            <td width="66" align="left" valign="top"><b>Razon social: </b></td>
                                                            <td width="233" align="left" valign="top">{empresa.razon_social}</td>
                                                        </tr>
                                                        <tr>
                                                            <td valign="top"><b>Nit: </b>{empresa.nit}</td>
                                                            <td align="left" valign="top"></td>
                                                            <td align="left" valign="top"><font className="numero2"></font></td>
                                                        </tr>
                                                    </table>
                                                    <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                        <tr>
                                                            <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                        </tr>
                                                    </table>
                                                    <table width="712px" border="0" cellpadding="3" cellspacing="0">
                                                        <tr>
                                                            <td width="80" className="fontipo"><b>Fecha: </b></td>
                                                            <td width="219" align="left" valign="top">{moment(movimiento.fecha).format("DD/MM/YYYY hh:mm a")}</td>
                                                            <td width="66" align="left" valign="top"><b>No.</b></td>
                                                            <td width="233" align="left" valign="top"><font className="numero2">{movimiento.consecutivo}</font></td>
                                                        </tr>
                                                    </table>
                                                    <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                        <tr>
                                                            <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                        </tr>
                                                    </table>
                                                    <table width="712px" border="0" cellpadding="3" cellspacing="0">
                                                        <tr >
                                                            <td width="80" className="fontipo"><b>Responsable</b></td>
                                                            <td width="219" align="left" valign="top">{movimiento.personas_id_detalles.nombres + ' ' + movimiento.personas_id_detalles.apellidos}</td>
                                                            <td width="66" align="left" valign="top"><b>Bodega:</b></td>
                                                            <td width="233" align="left" valign="top">{detalle[0].bodega}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>ID:</b></td>
                                                            <td align="left" valign="top" className="numero2">{movimiento.personas_id_detalles.identificacion}</td>
                                                            <td align="left" valign="top"><b>&nbsp;</b></td>
                                                            <td align="center" valign="top">&nbsp;</td>
                                                        </tr>
                                                    </table>
                                                    <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                        <tr>
                                                            <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                        </tr>
                                                    </table>
                                                    <table width="712px" border="0" cellspacing="0" cellpadding="3">
                                                        <tr>
                                                            <td width="40" align="center" bgcolor="#EEEEEE"><b>COD</b></td>
                                                            <td width="182" align="left" bgcolor="#EEEEEE"><b>PRODUCTO</b></td>
                                                            <td width="37" align="right" bgcolor="#EEEEEE"><b>CANT.</b></td>
                                                            <td width="46" align="right" bgcolor="#EEEEEE"><b>COSTO U.</b></td>
                                                            <td width="70" align="right" bgcolor="#EEEEEE"><b>COSTO T.</b></td>
                                                        </tr>
                                                    </table>
                                                    {detalle.map((row) => {
                                                        //console.log(row)
                                                        return (
                                                            <div>
                                                                <table width="712px" border="0" cellspacing="0" cellpadding="3" >
                                                                    <tr>
                                                                        <td width="40" align="center" valign="middle" className="numero2">{row.plu}</td>
                                                                        <td width="182" valign="middle" align="left" className="numero2">{row.descripcion}</td>
                                                                        <td width="37" align="right" valign="middle" className="numero2">{row.cantidad}</td>
                                                                        <td width="46" align="right" valign="middle" className="numero2">{'$ ' + this.setearMiles(row.costo_producto)}</td>
                                                                        <td width="70" align="right" valign="middle" className="numero2">{'$ ' + this.setearMiles(row.cantidad * row.costo_producto)}</td>
                                                                    </tr>
                                                                </table>
                                                                <table className="tb-2" width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                    <tr>
                                                                        <td><img src={linea2} width="712px" height="5" alt="" /></td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        )
                                                    }
                                                    )}
                                                    <table width="712px" border="0" cellpadding="0" cellspacing="0">
                                                        <tr >
                                                            <td width="100" valign="middle" ><table width="100" border="0" align="right" cellpadding="3" cellspacing="0">
                                                                <tr >
                                                                    <td width="54" align="right" valign="middle" class="numero2" >{'$ ' + this.setearMiles(movimiento.costo_total)}</td>
                                                                </tr>
                                                            </table></td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        :
                                        null}
                                </div>
                            </Paper>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
export default AjusteInicial