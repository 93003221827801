import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import FormularioEquipo from '../../../components/Formularios/FormularioEquipo';
import FormularioPreautorizacion from '../../../components/Formularios/FormularioPreequipo';
import FormularioAlmacenamiento from '../../../components/Formularios/FormularioAlmacenamiento';
import Axios from 'axios';
import { MDBDataTable } from 'mdbreact';
import { Typography } from "@material-ui/core";
import store from '../../../lib/store';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Loader from '../../../components/Loader';
import Server from '../../../lib/server';
import swal from 'sweetalert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import 'moment/locale/es';
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import AjusteIcon from '@material-ui/icons/SettingsApplications';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import INFOIcon from '@material-ui/icons/Info';
import FormularioConfiguracionEquipo from "../../../components/Formularios/FormularioConfiguracionEquipo";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import DeleteIcon from '@material-ui/icons/History';
import Tooltip from '@material-ui/core/Tooltip';
import { DialogTitle, DialogContent } from '@material-ui/core';
import FormularioPreequipo from '../../../components/Formularios/FormularioPreequipo';
import DominioIcon from "@material-ui/icons/LocalGasStation"
import Descequipo from "@material-ui/icons/NoEncryption"
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import Selects from 'react-select'
import sweetalert2 from 'sweetalert2'
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const idModulo = 3;
var permisos = '';
var ntoken = 'Bearer '.concat(localStorage.getItem('token'));
class Equipos extends Component {
    constructor() {
        super();
        this.state = {
            ventanaCreacionSd: false,
            ventanaCreacionEquipo: false,
            ventanaPreCreacionEquipo: false,
            ListaAlmacenamientos: [],
            ListaEquipos: [],
            actualizarDatos: false,
            tabla: false,
            loader: true,
            ventanaConfiguracion: false,
            equiposAlerta: [],
            dialogoAlerta: false,
            alertasSelecionadas: [],
            dialogoPropiedades: false,
            propiedadesSelecionadas: null,
            idPreautorizado: 0,
            dialogAsociacion: false,
            ventanaPreautorizacionEquipo: false,
            //Formulario de asociacion
            tiendasbd: [],
            tiendaSeleccionada: {},
            bodegasbd: [],
            bodegaSeleccionada: {},
            exc: [],
            propiedadesEmpresa: "",
            moreDetalles: false,
        };
        //console.log(store.getState());
        store.subscribe(() => {
            this.setState({
                ventanaCreacionSd: store.getState().ventanaCreacionSD,
                ventanaCreacionEquipo: store.getState().ventanaCreacionEquipo,
                actualizarDatos: store.getState().actualizarvista,
                ventanaConfiguracion: store.getState().creacionParametrosEquipos,
                ventanaMarcas: store.getState().ventanaMarcas,
                ventanaProtocolo: store.getState().ventanaProtocolo,
                ventanaPreCreacionEquipo: store.getState().ventanaPreCreacionEquipo,
            });
        });
    }

    timer() {
        this.setState({
            currentCount: this.state.currentCount - 1
        });
        if (this.state.currentCount < 1) {
            clearInterval(this.intervalId);
        }
        this.estadoEquipo();
    }

    componentWillReceiveProps() {
        if (this.state.actualizarDatos) {
            this.actualizarDatos();
        }
    }
    componentDidMount() {
        this.intervalId = setInterval(this.timer.bind(this), 15000);
    }

    handleMoreDetalles = () => {
        this.setState({
            moreDetalles: !this.state.moreDetalles
        })
    }

    componentWillMount() {
        this.actualizarDatos();
        this.estadoEquipo();
        Axios.get(Server.hostPrueba + Server.puertoEquipos + '/api/surtidor/tipos', { headers: { Authorization: ntoken } })
            .then(response => {
                var tipoSurtidor = [];
                if (response.status === 200) {
                    response.data.data.map((surtidor) => {
                        tipoSurtidor.push({
                            value: surtidor.id,
                            label: surtidor.descripcion,
                        });
                    });
                }
                this.setState({
                    tiposSurtidoresbd: tipoSurtidor
                });
                //console.log(response);
            });
        Axios.get(Server.hostPrueba + Server.puertoEquipos + '/api/surtidor/protocolos', { headers: { Authorization: ntoken } })
            .then(response => {
                var tipoSurtidor = [];
                if (response.status === 200) {
                    response.data.data.map((surtidor) => {
                        tipoSurtidor.push({
                            value: surtidor.id,
                            label: surtidor.descripcion,
                        });
                    });
                }
                this.setState({
                    tiposProtocolossbd: tipoSurtidor
                });
            });
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tiendas', { headers: { Authorization: ntoken } })
            .then(responsee => {
                var emp = []
                var res = responsee.data.data
                if (res != null) {
                    res.map((e) => {
                        emp.push({
                            value: e.id,
                            label: e.alias + " -- " + e.codigo,
                            title: e.alias,
                        })
                    })
                }
                this.setState({ tiendasbd: emp })
            })
            .catch(function (error) {
                console.error(error);
            })

    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        if (name === "tiendaSeleccionada") {
            Axios.get(Server.hostPrueba + Server.puertoSSLBodega + '/api/bodegas/empresa/' + value.value, { headers: { Authorization: ntoken } })
                .then(responsee => {
                    var bdgtotal = []
                    responsee.data.map((bodega, key) => {
                        bdgtotal.push({
                            value: bodega.bodega_id,
                            label: bodega.descripcion
                        })
                    })
                    this.setState({ bodegasbd: bdgtotal })
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                })
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    formularioSD = () => {
        store.dispatch({
            type: "CREACION_SD",
            ventanaCreacionSD: !this.state.ventanaCreacionSd
        })
    }

    formularioConfiguracion = (idEquipo) => {
        Axios.get(Server.hostPrueba + Server.puertoEquipos + '/api/surtidor/equipos/' + idEquipo, { headers: { Authorization: ntoken } })
            .then(response => {
                var tipoSurtidor = [];
                console.log(response);
                if (response.status === 200) {
                    store.dispatch({
                        type: "CREACION_PARAMETROS_EQUIPOS",
                        creacionParametrosEquipos: !this.state.ventanaConfiguracion,
                        infoSurtidor: response.data.data[0]
                    });
                }

            })

    }

    DialogasociarEquipo = (id) => {
        this.setState({
            idPreautorizado: id,
            dialogAsociacion: true
        })
    }

    cerrarAsociacionEquipo = () => {
        this.setState({
            idPreautorizado: 0,
            dialogAsociacion: false
        })
    }



    asociarEquipo = () => {
        var usuario = store.getState().usuario
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        var data = {
            "empresas_id": this.state.tiendaSeleccionada.value,
            "equipos_id": this.state.idPreautorizado,
            "bodegas_id": this.state.bodegaSeleccionada.value,
            "update_user": usuario.p_id,
        }
        Axios.put(Server.hostPrueba + Server.puertoEquipos + '/api/equipo/posautorizacion', data, { headers: { Authorization: ntoken } })
            .then(response => {
                console.log(response)
                this.setState({
                    response: false
                })
                if (response.status === 201) {
                    if (response.data.success) {
                        swal({
                            title: response.data.mensaje,
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    this.cerrarAsociacionEquipo()
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                    }

                } else {
                    swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                }
            })
    }

    restaurarEquipo = (id) => {
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        var usuario = store.getState().usuario
        swal({
            title: "Estas seguro de que quieres restaurar este equipo?",
            text: "Una vez restaurado, este equipo no contara con informacion anterior",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.put(Server.hostPruebasinssl + Server.puertoEquipos + '/api/equipo/restaurar/' + id, {}, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 201) {
                                swal({
                                    title: "EQUIPO RESTAURADO CORRECTAMENTE",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            store.dispatch({
                                                type: "ACTUALIZACION_DATOS",
                                                actualizarvista: true
                                            })
                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            } else {
                                swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }

    destruirEquipo = (id) => {
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        var usuario = store.getState().usuario
        swal({
            title: "Estas seguro de que quieres destruir este equipo?",
            text: "Una vez destruido, este equipo no contara con informacion anterior",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.delete(Server.hostPruebasinssl + Server.puertoEquipos + '/api/equipo/referencia/0/' + usuario.p_id + "/" + id, { headers: { Authorization: ntoken, key: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IlFWY2NrWU5COURla2dCb252cDlpSVNjZmpSbVRkakxKVVdZUmZWSEhNeFJDZ051Q0FVbnp5eklST2Jab1k5bWVpVGlEY0dobDNhell6VkswcVdETFFvTTExZzRwWEJxdXl5TDVpZ1ZHUWNscDFZdUo0Njc3ZGVpTTRaZnpFdHdwUXM5OTRBbUM1TGtad1RLNVNrajBIcE4ybEt4OElKdGlyUU1BdlNBQ2U0M0VoRkUyT0t2WW5FUHFiR21xamlodmJrWFRWdGhza2Z2enpYVjI5bkZWQzFsa2hWdk1QRUhPMW8wTjVHajNaT3dkS2s1Mkg3MGN0U0RzRkNJOE1COE11YzVMN09GQ2VRVmdGRzRLTWZKT0ZraDZ1Q2pJV3E4dFZxaWlYU295UTdkSktsb2RQQzhvZ1I2cjlYM0RnejNBbTNEWWtmdjZNQzh2Vk9oZmU0QnZsRjduWEJFdHF0UnJDUU1XRW5CYklWb2NoR2JCUkxrQVBBR3ZBTGdWR0hubW5iU05YcVp3VlZaSTVwT0lSMUpJNUtpMGMyY1ZDUWZrbzZ0MzlaeXBIR3dPa3FUTnBIWGJmeHVmQ1c0QkRYWjh6R09xeUFsa2RXN2VnbENIMFVURXpUU0R3RVR2SjExOWFWdk5SYnJKMzIyRDVhdWxTSVV6SXdKdkhLR2tnaldNVnI4SXJTVzhzTDBLelZRTXl4YU52aWJyTHI2cTV6RHBUdVBoV3RacHQzN0FXcFFSZjA3UjNPa0dJY3YwQThHcXRhYWxKS0k1UUxORnhzaWs3ZmE2Y1VIbDJadFVLbHE5blZVN1ZuOFdWcXFBTGNMcU03NU0zSmhITEdNWFJvWWJjcThrNFdYcUVZS3J2R0owcE84TWRkUVA4M0FMdHRMMlVNcmhheWZZVXE5SWNnUFdiRGR2Nmx1ZExFVFlLTmRNbzkyMUJDbVFGdFhTdmNuUEZwbWE1aFJXZ2RVZmJqOVBhWGVZd3FiYnhPUkZ4TGJScU83Yll5Q1dLNDZ2N3FRdXltMVhVb1NGcmVoY2pYWXBmV2J2U1NZNjQwbkhRWFFqWnJHNmxVT21hbWpRcEhzWTcxNVRKMzhVOEJLUmhtVldROEVWVFl3SzE2QzlWTnhIbXZXRndCd0Q4STFYWWUzRWhVdWpSVGxlWGNlM3hRSUdaTGhaUjhTdWFXcHd3NkpZSDBqYUp0cjRjSDB5RnpkVFZ4YUVuY1Vpb2Y1ZHVzQ2Jkc0tyYmxNOURvbVZHTFFOUzk4OVFPdk5jbzkyU3BtUnFER1FYYUt1eTBHRnYzdzBLS3ZYY3A2eWlXdkxqamxobW9YNElCeDBUazNsUlRLaTBaTzdPRGVvWDF1Q0k3UzRcbiIsImNyZWF0ZWRBdCI6MTU3Mzc2MzUzNzEzMCwiZXhwaXJlZEF0IjoxNzMxNDQzNTM3MTMwLCJpYXQiOjE1NzM3NjM1Mzd9.iM-CJmBnNozKDZC6DbuDHXNJp5nJ1G-F3F7tkiONn2o" } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 201) {
                                swal({
                                    title: "EQUIPO DESTRUIDO CORRECTAMENTE",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            store.dispatch({
                                                type: "ACTUALIZACION_DATOS",
                                                actualizarvista: true
                                            })
                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            } else {
                                swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }

    desasociarEquipo = (id) => {
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        var usuario = store.getState().usuario
        var negocios = {}
        //Obtener empresas negiocios
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/3/empresas/' + store.getState().empresasHijos, { headers: { Authorization: ntoken } })
            .then(responseee => {
                var empresas = []
                if (responseee.status == 200 && responseee.data != null) {
                    responseee.data.empresas.map((dato) => {
                        negocios[dato.id] = dato.alias
                    })
                    sweetalert2.fire({
                        title: 'Desasociación  de equipo',
                        text: 'Una vez desasociado el equipo se podra asignar a otra empresa del negocio que escoja.',
                        input: 'select',
                        inputOptions: negocios,
                        inputPlaceholder: 'Escoje el negocio',
                        showCancelButton: true, inputValidator: (value) => {
                            return new Promise((resolve) => {
                                if (value != '') {
                                    Axios.put(Server.hostPruebasinssl + Server.puertoEquipos + '/api/equipo/referencia/1/' + usuario.p_id + "/" + id, { "empresas_id": value }, { headers: { Authorization: ntoken } })
                                        .then((res) => {

                                            if (res.status == 201) {
                                                swal({
                                                    title: "EQUIPO DESASOCIADO CORRECTAMENTE",
                                                    icon: "success",
                                                    closeOnClickOutside: false,
                                                    buttons: {
                                                        Seguir: {
                                                            text: "OK",
                                                            value: "continuar"
                                                        }
                                                    },
                                                }).then((value) => {
                                                    switch (value) {

                                                        case "continuar":
                                                            store.dispatch({
                                                                type: "ACTUALIZACION_DATOS",
                                                                actualizarvista: true
                                                            })
                                                            resolve()
                                                            break;
                                                        default:

                                                    }
                                                });
                                            } else if (res.status == 200) {
                                                swal("ERROR", res.data.mensaje, "warning")
                                            } else if (res.status == 205) {
                                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                                setTimeout(function () { window.location.href = '/'; }, 3000);
                                            } else {
                                                swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                                            }
                                        })
                                } else {
                                    resolve('Necesitas seleccionar el negocio')
                                }
                            })
                        }
                    })
                } else if (responseee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
                this.setState({ empresasbd: empresas })

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        console.log(negocios)


    }

    handleDialogAlerta = (alertas) => {
        this.setState({
            dialogoAlerta: !this.state.dialogoAlerta,
            alertasSelecionadas: alertas
        });
    };

    handleDialogPropiedades = (propiedades, alias) => {
        this.setState({
            dialogoPropiedades: !this.state.dialogoPropiedades,
            propiedadesSelecionadas: propiedades,
            propiedadesEmpresa: alias,
            moreDetalles: false
        });
    };


    formularioEquipo = () => {
        store.dispatch({
            type: "CREACION_EQUIPO",
            ventanaCreacionEquipo: !this.state.ventanaCreacionEquipo
        });
    };

    preformularioEquipo = () => {
        store.dispatch({
            type: "PRE_CREACION_EQUIPO",
            ventanaPreCreacionEquipo: !this.state.ventanaPreCreacionEquipo
        });
    };


    obtenerPendientes = (propiedades) => {
        var pr = JSON.parse(propiedades.propiedades)
        return pr.pendientes
    }
    ObtenerCode = (propiedades) => {
        var pr = JSON.parse(propiedades.propiedades)
        return pr.version_code
    }

    estadoEquipo() {
        var excel = []
        var hijos = store.getState().empresasHijos;
        var data = {
            "empresas_id": store.getState().empresasHijos
        };
        Axios.get(Server.hostPrueba + Server.puertoEquipos + '/api/equipo/empresas/' + hijos, { headers: { Authorization: ntoken } })
            .then(response => {
                var equipos = [];
                if (response.status = 200) {
                    //console.log(response);
                    response.data.data.map((equipo) => {
                        if (equipo.estado != "P") {
                            excel.push({
                                "0": equipo.id,
                                "1": equipo.alias,
                                "2": moment(equipo.createdAt).format("DD/MM/YYYY"),
                                "3": moment(equipo.updatedAt).format("DD/MM/YYYY"),
                                "4": moment(equipo.ultimaConexion).format("DD/MM/YYYY"),
                            })
                        }
                        equipos.push({
                            "id": equipo.id,
                            "a4": <Tooltip title={"" + equipo.tipo_equipo} placement="top">
                                <h6 style={{ cursor: 'pointer' }}>{equipo.codigo_referencia}</h6>
                            </Tooltip>,
                            /* "b": <Tooltip title={"" + equipo.bodega} placement="top">
                                 <h6 style={{ cursor: 'pointer' }}>{equipo.alias}</h6>
                             </Tooltip>,*/
                            "b": equipo.alias,
                            "ultimaConexion": equipo.ultimaConexion === null ? '' : this.obtenerFecha(equipo.ultimaConexion),
                            "e": equipo.ultimaConexion === null ? '' : this.ultimaFecha(equipo.ultimaConexion),
                            "ef": equipo.propiedades === null ?
                                ''
                                :
                                <button className="btn btn-primary btn-sm" onClick={() => this.handleDialogPropiedades(equipo.propiedades, equipo.alias)}><INFOIcon /></button>,
                            "ef2": equipo.propiedades === null ?
                                ''
                                :
                                parseInt(this.obtenerPendientes(equipo.propiedades)),
                            "vs": equipo.propiedades === null ?
                                '80'
                                :
                                this.ObtenerCode(equipo.propiedades),
                            "inv": '0',
                            "f": [
                                <DropdownButton
                                    title={"OPCIONES"}
                                    variant={"danger"}
                                    id={`dropdown-variants-sdsddsd`}
                                    key={"sdsddsd"}
                                    style={{ color: '#fff !IMPORTANT' }}
                                    className="mx-auto"
                                >
                                    <Dropdown.Item key={equipo.id} onClick={() => this.restaurarEquipo(equipo.id)}>RESTAURAR EQUIPO</Dropdown.Item>
                                    <Dropdown.Divider />
                                    {equipo.estado != "P"
                                        ?
                                        <Dropdown.Item key={equipo.id} onClick={() => this.desasociarEquipo(equipo.id)}>DESASOCIAR EQUIPO</Dropdown.Item>
                                        :
                                        null}
                                    <Dropdown.Divider />
                                    <Dropdown.Item key={equipo.id} onClick={() => this.destruirEquipo(equipo.id)}>DESTRUIR EQUIPO</Dropdown.Item>

                                </DropdownButton>,
                                equipo.tipo_equipo === "SCOIL"
                                    ?
                                    <Tooltip title="CONFIGURAR PARAMETROS" placement="top">
                                        <button style={{ margin: '2px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.formularioConfiguracion(equipo.id)}>
                                            <AjusteIcon />
                                        </button>
                                    </Tooltip>
                                    : null,
                                equipo.estado === "P"
                                    ?
                                    <Tooltip title="ASOCIAR EQUIPO" placement="top">
                                        <button style={{ margin: '2px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.DialogasociarEquipo(equipo.id)}>
                                            <DominioIcon />
                                        </button>
                                    </Tooltip>
                                    : null
                            ],
                            "reporteError": equipo.alertas != null ?
                                <Tooltip title={equipo.alertas.descripcion} placement="top">
                                    <button className="btn btn-warning btn-sm" onClick={() => this.handleDialogAlerta(this.alertaEquipos(parseInt(equipo.id)))}><ErrorOutlineIcon /></button>
                                </Tooltip>
                                :
                                null
                        })
                    })
                } else if (response.status = 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
                this.setState({ ListaEquipos: equipos, loader: false, exc: excel })
            })
    }

    obtenerFecha = (fc) => {
        var fechaNew = '';
        fechaNew = moment(fc, "YYYY-MM-DDTHH:mm:ssZ").format('YYYY-MM-DD hh:mm:ss A');
        return fechaNew;
    };


    ultimaFecha = (fc) => {
        var fechaResult = '';
        fc = moment(fc);
        fechaResult = moment(fc, 'YYYYMMDD').fromNow();
        return fechaResult;

    };

    alertaEquipos = (id) => {
        var data = {
            "equipos_id": id
        }
        var encontrados = [];
        Axios.post(Server.hostPrueba + Server.puertoEquipos + '/api/equipo/alertasEquipos/', data, { headers: { Authorization: ntoken } })
            .then(response => {
                var alertas = [];
                if (response.status === 200) {
                    if (response.data.success) {
                        encontrados = response.data.data;
                        this.setState({
                            alertasSelecionadas: encontrados
                        })
                    }
                } else if (response.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning");
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
            });
    };



    actualizarDatos() {
        var modulos = store.getState().perfilesUSuario;
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        var data = {
            "empresas_id": store.getState().empresasHijos
        }
        modulos.map((modulo) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones;
            }
        });
        this.setState({ actualizarDatos: false });


        Axios.get(Server.hostPrueba + Server.puertoEquipos + '/api/almacenamiento', { headers: { Authorization: ntoken } })
            .then(response => {
                var almacenamientos = [];
                if (response.status === 200) {

                    response.data.data.map((almacenamiento) => {
                        almacenamientos.push({
                            'Serial': almacenamiento.serial,
                            'Marca': almacenamiento.marca,
                            'capacidad': almacenamiento.capacidad + ' GB',
                            'fecha': moment(almacenamiento.fecha_compra).format('YYYY/MM/DD HH:mm a'),
                            'estado': almacenamiento.estado
                        });
                    });
                    this.setState({ ListaAlmacenamientos: almacenamientos });
                } else if (response.status === 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning");
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            });
        /*Axios.post(Server.hostPrueba + Server.puertoEquipos + '/api/equipo/alertasEquipos/', data, { headers: { Authorization: ntoken } })
            .then(response => {
                var alertas = [];
                if (response.status === 200) {
                    if (response.data.success) {
                        alertas = response.data.data;
                    }
                } else if (response.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning");
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }

                this.setState({
                    equiposAlerta: alertas
                });
            });*/

    }

    handleChangeTABLA = () => {
        this.setState({
            tabla: !this.state.tabla
        });
    };

    obtenerInternas = (objeto) => {
        var camps = Object.keys(objeto)
        var valors = Object.values(objeto)
        return <div>
            {camps.map((campo, key) => (
                <tr key={key}>
                    <td>{campo}</td>
                    <td>{typeof valors[key] === 'object' ?
                        this.obtenerInternas(valors[key])
                        : valors[key]}</td>
                </tr>
            ))}
        </div>
    }

    eliminarAlerta = (_id, key) => {
        var data = {
            "alertas_id": _id
        }
        var al = this.state.alertasSelecionadas
        Axios.put(Server.hostPrueba + Server.puertoEquipos + '/api/equipo/alertasEquipos', data, { headers: { Authorization: ntoken } })
            .then((res) => {
                console.log(res)
                if (res.status == 201) {
                    //swal("ERROR", "Correcto", "warning")
                    al.splice(key, 1)
                    this.setState({
                        alertasSelecionadas: al
                    })
                } else if (res.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                }
            })
    }

    deleteAllAlertas = () => {
        var data = {
            "equipos_id": this.state.alertasSelecionadas[0].equipos_id
        }
        var al = this.state.alertasSelecionadas
        Axios.put(Server.hostPrueba + Server.puertoEquipos + '/api/equipo/alertasByEquiposId', data, { headers: { Authorization: ntoken } })
            .then((res) => {
                console.log(res)
                if (res.status == 201) {
                    swal("Borrado", "Se han borrado " + res.data.data.result.n + " notificaciones", "warning")
                    this.handleDialogAlerta()
                } else if (res.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                }
            })
    }

    render() {
        const almacenamientos = {
            columns: [
                {
                    label: 'Serial',
                    field: 'Serial',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Marca',
                    field: 'Marca',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Capacidad en Gb',
                    field: 'Capacidad',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Fecha de compra',
                    field: 'Fecha de compra',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Estado',
                    field: 'estado',
                    sort: 'asc',
                    width: 150
                }],
            rows: this.state.ListaAlmacenamientos

        };
        const equipos = {
            columns: [
                {
                    label: 'No.',
                    field: 'id',
                    sort: 'desc',
                    width: 150
                }, {
                    label: 'Tipo',
                    field: 'tipo',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Kiosco',
                    field: 'Serial',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Ult. Conexion',
                    field: 'ultimaConexion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Tiempo',
                    field: 'ultimaLinea',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Prop.',
                    field: 'propiedades',
                    sort: 'asc',
                }, {
                    label: 'Pend.',
                    field: 'ef2',
                    sort: 'desc',
                }, {
                    label: 'Version',
                    field: 've',
                    sort: 'desc',
                }, {
                    label: 'Inventario',
                    field: 'inv',
                    sort: 'desc',
                }, {
                    label: 'Acciones',
                    field: 'ACC',
                    sort: 'desc'
                }, {
                    label: 'Error',
                    field: 'reporteError',
                    sort: 'asc',
                    width: 150
                }],
            rows: this.state.ListaEquipos

        }
        var propiedades = null
        var campos = []
        var valores = []
        if (this.state.propiedadesSelecionadas != null) {
            if (this.state.propiedadesSelecionadas.propiedades != null) {
                propiedades = JSON.parse(this.state.propiedadesSelecionadas.propiedades)
                campos = Object.keys(propiedades)
                valores = Object.values(propiedades)
            }
        }
        return (
            this.state.loader
                ?
                <Loader />
                :
                <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                    <div className="row">
                        <div >
                            <ExcelFile
                                filename={"EQUIPOS"}
                                element={<button className="btn btn-success">EXPORTAR </button>}
                            >
                                <ExcelSheet data={this.state.exc} name="EQUIPOS">
                                    <ExcelColumn label="EQUIPO" value="0" />
                                    <ExcelColumn label="KIOSCO" value="1" />
                                    <ExcelColumn label="FECHA DE CREACION" value="2" />
                                    <ExcelColumn label="FECHA DE INICIO DE OPERACION (ASOCIACION)" value="3" />
                                    <ExcelColumn label="ULTIMA CONEXION" value="4" />
                                </ExcelSheet>
                            </ExcelFile>
                            {this.state.ventanaCreacionEquipo
                                ?
                                <button className="btn btn-danger" onClick={this.formularioEquipo.bind(this)}>Cancelar</button>
                                :
                                permisos.includes('C')
                                    ?
                                    <button className="btn btn-success" onClick={this.formularioEquipo.bind(this)}>Autorizar equipo</button>
                                    :
                                    null
                            }
                        </div>
                        <div style={{ marginLeft: "15px" }}>
                            {this.state.ventanaPreCreacionEquipo
                                ?
                                <button className="btn btn-danger" onClick={this.preformularioEquipo.bind(this)}>Cancelar</button>
                                :
                                permisos.includes('C')
                                    ?
                                    <button className="btn btn-success" onClick={this.preformularioEquipo.bind(this)}>Preautorizar equipo</button>
                                    :
                                    null
                            }
                        </div>
                        <div style={{ marginLeft: "15px" }}>{
                            this.state.ventanaCreacionSd
                                ?
                                <button className="btn btn-danger" onClick={this.formularioSD.bind(this)}>Cancelar</button>
                                :
                                permisos.includes('C') ? <button className="btn btn-success" onClick={this.formularioSD.bind(this)}>Crear SD nueva</button>
                                    :
                                    null}
                        </div>
                        <Dialog fullScreen open={this.state.ventanaConfiguracion} onClose={this.formularioConfiguracion} TransitionComponent={Transition}>
                            <FormularioConfiguracionEquipo />
                        </Dialog>
                    </div>
                    <div className="row">
                        {this.state.ventanaCreacionEquipo ? <FormularioEquipo /> : null}
                    </div>
                    <div className="row">
                        {this.state.ventanaPreCreacionEquipo ? <FormularioPreequipo /> : null}
                    </div>
                    <div className="row">
                        {this.state.ventanaCreacionSd ? <FormularioAlmacenamiento /> : null}
                    </div>
                    <div className="row">
                        <FormControlLabel
                            className="col-md-2"
                            control={
                                <Switch
                                    onChange={this.handleChangeTABLA.bind(this)}
                                    value="checkedA"
                                />
                            }
                            label={this.state.tabla ? "Ver los equipos" : "Ver los almacenamientos"}
                        />
                    </div>
                    {this.state.tabla ?
                        <div className="row">
                            <div className="col col-md-12" style={{ padding: 0 }}>
                                <Paper style={{ padding: '5px' }}>

                                    <MDBDataTable
                                        //striped
                                        className="col-md-12"
                                        searchLabel="Buscar"
                                        paginationLabel={["Anterior", "Siguente"]}
                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                        entriesLabel="Filas a mostrar"
                                        tbodyColor="primary-color"
                                        theadColor="#FF8800"
                                        exportToCSV={true}
                                        striped
                                        bordered
                                        autoWidth={false}
                                        responsive={true}
                                        hover
                                        data={almacenamientos}
                                    />

                                </Paper>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="col col-md-12" style={{ padding: 0 }}>
                                <Paper style={{ padding: '5px' }}>

                                    <MDBDataTable
                                        //striped
                                        className="col-md-12"
                                        searchLabel="Buscar"
                                        paginationLabel={["Anterior", "Siguente"]}
                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                        entriesLabel="Filas a mostrar"
                                        tbodyColor="primary-color"
                                        theadColor="#FF8800"
                                        exportToCSV={true}
                                        striped
                                        bordered
                                        autoWidth={false}
                                        responsive={true}
                                        hover
                                        data={equipos}
                                        sorting={true}
                                        order={['id', 'desc']}
                                        style={{ fontSize: '13px' }}
                                    />
                                    <Dialog
                                        open={this.state.dialogoAlerta}
                                        onClose={() => this.handleDialogAlerta()}
                                        aria-labelledby="form-dialog-title"
                                        maxWidth={'md'}
                                        fullWidth={true}
                                        style={{ padding: '0' }}
                                        disableBackdropClick={false}
                                    >
                                        <DialogContent style={{ padding: '10px' }}>
                                            <div className="row" style={{ marginBottom: '10px' }}>
                                                {this.state.alertasSelecionadas != null
                                                    ?
                                                    this.state.alertasSelecionadas.length > 0
                                                        ?
                                                        <button
                                                            className="btn btn-warning col-md-5 mx-auto"
                                                            type="reset"
                                                            onClick={() => this.deleteAllAlertas()}
                                                        >
                                                            BORRAR TODAS
                                                            </button>
                                                        :
                                                        null
                                                    :
                                                    null
                                                }
                                                <button className="btn btn-danger col-md-5 mx-auto" type="reset" onClick={() => this.handleDialogAlerta()}>
                                                    CERRAR</button>
                                            </div>

                                            <List className="border rounded">
                                                {
                                                    this.state.alertasSelecionadas != null ?
                                                        this.state.alertasSelecionadas.map((alerta, key) => {
                                                            var fecha = alerta.fecha;
                                                            return (
                                                                <ListItem className="border" alignItems="flex-start">
                                                                    <ListItemText
                                                                        primary={alerta.descripcion}
                                                                        secondary={
                                                                            <React.Fragment>
                                                                                <Typography
                                                                                    component="span"
                                                                                    variant="body2"
                                                                                    color="textPrimary"
                                                                                >
                                                                                    {'DETALLES: ' + alerta.detalles}
                                                                                </Typography>
                                                                                {'FECHA: ' + moment(fecha, "YYYY-MM-DDTHH:mm:ssZ").format('YYYY-MM-DD hh:mm:ss A')}
                                                                            </React.Fragment>
                                                                        }
                                                                    />
                                                                    <ListItemSecondaryAction>
                                                                        <IconButton aria-label="Delete" onClick={() => this.eliminarAlerta(alerta._id, key)}>
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            )
                                                        })
                                                        :
                                                        <ListItem alignItems="flex-start">
                                                            <ListItemText
                                                                primary={"ESTE EQUIPO NO TIENE ALERTAS"}
                                                            />

                                                        </ListItem>

                                                }
                                            </List>

                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        open={this.state.dialogoPropiedades}
                                        onClose={() => this.handleDialogPropiedades(null, "")}
                                        aria-labelledby="form-dialog-title"
                                        maxWidth={'md'}
                                        fullWidth={true}
                                        style={{ padding: '0' }}
                                        disableBackdropClick={false}
                                    >
                                        <DialogContent style={{ padding: '10px' }}>

                                            {

                                                propiedades != null
                                                    ?
                                                    <div>
                                                        <div className="row" >
                                                            <FormControlLabel
                                                                style={{ marginLeft: "10px" }}
                                                                control={
                                                                    <Switch
                                                                        onChange={this.handleMoreDetalles.bind(this)}
                                                                        value="checkedA"
                                                                    />
                                                                }
                                                                label={!this.state.moreDetalles ? "Menos detalles" : "Mas detalles"}
                                                            />
                                                        </div>
                                                        {
                                                            !this.state.moreDetalles ?
                                                                <div className="row mx-auto">
                                                                    <div className="col-md-10 mx-auto">
                                                                        <MDBTable bordered striped>
                                                                            <MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
                                                                                <tr>
                                                                                    <th colSpan="2" align="center">{"PROPIEDADES DEL EQUIPO " + this.state.propiedadesEmpresa}</th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>CAMPO</th>
                                                                                    <th>VALOR</th>

                                                                                </tr>
                                                                            </MDBTableHead>
                                                                            <MDBTableBody>
                                                                                {campos.map((campo, key) => (
                                                                                    <tr key={key}>
                                                                                        <td>{campo}</td>
                                                                                        <td>{typeof valores[key] === 'object' ?
                                                                                            this.obtenerInternas(valores[key])
                                                                                            : valores[key]}</td>
                                                                                    </tr>
                                                                                ))}

                                                                            </MDBTableBody>
                                                                        </MDBTable>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <div className="row mx-auto" >
                                                                        <h5 className="mx-auto">PROPIEDADES DEL EQUIPO {this.state.propiedadesEmpresa}</h5>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="numordencompra" className="col-form-label">Version code</label>
                                                                            <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.version_code + ""} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="numordencompra" className="col-form-label">Version APP</label>
                                                                            <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.version_app + ""} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="numordencompra" className="col-form-label">Fecha</label>
                                                                            <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.fecha + ""} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="numordencompra" className="col-form-label">Pendientes</label>
                                                                            <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.pendientes + ""} />
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        propiedades.anydesk != null ?
                                                                            <div className="row">
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="numordencompra" className="col-form-label">Anydesk</label>
                                                                                    <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.anydesk.id + ""} />
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="numordencompra" className="col-form-label">Alias</label>
                                                                                    <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.anydesk.alias + ""} />
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="numordencompra" className="col-form-label">Status</label>
                                                                                    <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.anydesk.status + ""} />
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                    <div className="row">
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="numordencompra" className="col-form-label">Impresora</label>
                                                                            <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.impresora + ""} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="numordencompra" className="col-form-label">RFID</label>
                                                                            <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.rfid + ""} />
                                                                        </div>
                                                                        {
                                                                            propiedades.ultimaVenta != null ?
                                                                                <div className="col-md-6">
                                                                                    <label htmlFor="numordencompra" className="col-form-label">Ultima venta</label>
                                                                                    <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={"Con. " + propiedades.ultimaVenta.consecutivo + ", Fecha: " + propiedades.ultimaVenta.fecha} />
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }

                                                                    </div>
                                                                    {
                                                                        propiedades.sistema != null ?
                                                                            <div className="row">
                                                                                <div className="col-md-9">
                                                                                    <label htmlFor="numordencompra" className="col-form-label">Sistema</label>
                                                                                    <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.sistema.sistema + ""} />
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <label htmlFor="numordencompra" className="col-form-label">Tiempo activo</label>
                                                                                    <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.sistema.uptime + ""} />
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        propiedades.wifis != null ?
                                                                            <div className="row mx-auto" >
                                                                                <h6 className="mx-auto">LISTA DE REDES WIFI</h6>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        propiedades.wifis != null ?
                                                                            <div className="row mx-auto" style={{ marginTop: "5px" }}>
                                                                                <List className="border rounded col-md-8 mx-auto">
                                                                                    {
                                                                                        propiedades.wifis != null || propiedades.length > 0 ?
                                                                                            propiedades.wifis.map((wifi) => {
                                                                                                return (
                                                                                                    <ListItem className="border" alignItems="flex-start">
                                                                                                        <ListItemText
                                                                                                            primary={"Nombre: " + wifi.ssid}
                                                                                                            secondary={"Clave: " + wifi.psk}
                                                                                                        />
                                                                                                    </ListItem>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            <ListItem alignItems="flex-start">
                                                                                                <ListItemText
                                                                                                    primary={"NO HAY WIFI CONFIGURADO"}
                                                                                                />

                                                                                            </ListItem>

                                                                                    }
                                                                                </List>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                        }



                                                    </div>
                                                    :
                                                    null
                                            }

                                            <div className="col-md-2 mx-auto" style={{ float: "right", padding: '8px' }}>
                                                <button className="btn btn-danger btn-block" type="reset" onClick={() => this.handleDialogPropiedades(null, "")}>
                                                    Cerrar
                                            </button>
                                            </div>
                                        </DialogContent>
                                    </Dialog>

                                    <Dialog
                                        open={this.state.dialogAsociacion}
                                        onClose={() => this.cerrarAsociacionEquipo()}
                                        aria-labelledby="form-dialog-title"
                                        maxWidth={'md'}
                                        fullWidth={true}
                                        style={{ padding: '0' }}
                                        disableBackdropClick={false}
                                    >
                                        <DialogContent style={{ padding: '10px' }}>
                                            <div className="row mx-auto">
                                                <div >
                                                    <h5>ASOCIACION DEL EQUIPO -- {this.state.idPreautorizado}</h5>
                                                </div>
                                            </div>
                                            <div className="row mx-auto" style={{ paddingBottom: '300px' }}>
                                                <div className="col-md-8">
                                                    <label htmlFor="numordencompra" className="col-form-label">EMPRESA</label>
                                                    <Selects
                                                        value={this.state.tiendaSeleccionada}
                                                        onChange={this.handleChanges('tiendaSeleccionada')}
                                                        options={this.state.tiendasbd}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="numordencompra" className="col-form-label">BODEGA</label>
                                                    <Selects
                                                        value={this.state.bodegaSeleccionada}
                                                        onChange={this.handleChanges('bodegaSeleccionada')}
                                                        options={this.state.bodegasbd}
                                                        isDisabled={JSON.stringify(this.state.tiendaSeleccionada) === "{}"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mx-auto">
                                                <div className="col-md-4 mx-auto" style={{ float: "right", padding: '8px' }}>
                                                    <button className="btn btn-primary" type="reset" onClick={() => this.asociarEquipo()}>
                                                        ASOCIAR
                                            </button>
                                                </div>
                                                <div className="col-md-2 mx-auto" style={{ float: "right", padding: '8px' }}>
                                                    <button className="btn btn-danger" type="reset" onClick={() => this.cerrarAsociacionEquipo()}>
                                                        Cerrar
                                            </button>
                                                </div>
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                </Paper>
                            </div>
                        </div>
                    }

                </div>

        );
    }
}


export default Equipos;