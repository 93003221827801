
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios'
import TelefonoIcon from '@material-ui/icons/Phone'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import store from '../../../lib/store'
import MailIcon from '@material-ui/icons/Mail'
import swal from 'sweetalert'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
class dialogTelefono extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Correos: [],
            auxCorreos: [],
            etiqueta: '',
            correo: ''
        };
        store.subscribe(() => {
            this.setState({
                Correos: store.getState().correos,
            })
        })
    }

    componentWillMount() {
        var { correos } = store.getState()
        this.setState({ Correos: correos })
    }

    cerrarVentana = () => {
        var correoGuardar = []
        this.state.Correos.map((mail) => {
            correoGuardar.push(mail)
        })
        this.state.auxCorreos.map((mail) => {
            correoGuardar.push(mail)
        })
        store.dispatch({
            type: "AGREGAR_CORREO_USUARIO",
            correos: correoGuardar,
            creacionCorreo: false
        })
    }
    guardarTelefonos = () => {
        store.dispatch({
            type: "AGREGAR_CORREO_USUARIO",
            correos: this.state.Correos,
            creacionCorreo: false
        })
    }

    isValidEmail = (mail) => {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(mail);
    }

    agregarTelefono = (etiqueta, correo) => {
        var telefononuevo = this.state.Correos
        if (etiqueta == "") {
            swal("LA ETIQUETA ES NECESARIA", "", "warning")
        } else if (!this.isValidEmail(correo)) {
            swal("FORMATO DE CORREO ELECTRONICO INVALIDO", "", "warning")
        } else {
            telefononuevo.push({ id: 0, etiqueta, correo })
            this.setState({ Correos: telefononuevo, etiqueta: '', correo: '' })

        }

    }

    eliminarCorreo = (e) => {
        var mcorreo = this.state.Correos
        var auxiliar = this.state.auxCorreos
        auxiliar.push(mcorreo[e])
        mcorreo.splice(e, 1)
        this.setState({ Correos: mcorreo, auxCorreos: auxiliar })
    }


    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
        //this.autoguardado()
    };


    render() {

        //this.obtenerTelefonos(telefonos)
        return (
            <Card style={{ marginTop: "0", paddingBottom: "15px" }}>
                <DialogTitle id="form-dialog-title">Correos</DialogTitle>
                <CardContent>
                    <div className="row bottom-aligned">
                        <div className="col-md-5">
                            <label htmlFor="numordencompra" className="col-form-label">Etiqueta</label>
                            <input id="numordencompra" value={this.state.etiqueta} autoComplete="off" className="form-control" type="text" onChange={this.handleChange('etiqueta')} />                        </div>
                        <div className="col-md-5">
                            <label htmlFor="numordencompra" className="col-form-label">Correo</label>
                            <input id="numordencompra" value={this.state.correo} autoComplete="off" className="form-control" type="text" onChange={this.handleChange('correo')} />
                        </div>
                        <div className="col-md-2 bottom-aligned">
                            <button className="btn btn-primary" onClick={() => this.agregarTelefono(this.state.etiqueta, this.state.correo)}>+</button>
                        </div>
                    </div>
                    <div className="row mx-auto">
                        <List className="mx-auto" >
                            {this.state.Correos.map((contacto, key) => {
                                if (contacto.estado != "I") {
                                    return (
                                        <ListItem className="mx-auto border" key={key}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <MailIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={contacto.etiqueta} secondary={contacto.correo} />
                                            <ListItemSecondaryAction>
                                                <IconButton aria-label="Delete" onClick={() => this.eliminarCorreo(key)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )
                                }
                            })}
                        </List>
                    </div>
                    <div className="row bottom-aligned">
                        <div className="col-md-9">
                            <button className="btn btn-danger" onClick={this.cerrarVentana} style={{ float: 'right' }}>CANCELAR</button>
                        </div>
                        <div className="col-md-3">
                            <button className="btn btn-success" onClick={this.guardarTelefonos} style={{ float: 'right' }}>Guardar</button>
                        </div>
                    </div>
                </CardContent>
            </Card>
        )
    }
}


export default dialogTelefono