import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardTittle from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent';
import GridItem from "components/Grid/GridItem.jsx";
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Server from '../../../lib/server'
import store from '../../../lib/store'
import Axios from 'axios'
import swal from 'sweetalert'
import DeleteIcon from '@material-ui/icons/Delete';
import EditICon from '@material-ui/icons/Edit';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Loader from '../../../components/Loader'
import FormularioImpuesto from '../../../components/Formularios/FormularioImpuesto';
import Activar from "@material-ui/icons/Check"
const B = (props) => <p style={{ fontWeight: 'bold', marginBottom: "0" }}>{props.children}</p>
const idModulo = 12
var permisos = ''
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class Impuestos extends Component {
    constructor() {
        super();
        this.state = {
            createbodega: false,
            checkIValor: false,
            checkIPorcentaje: true,
            loader: true,
            //DAtos a guardar
            descripcionN: '',
            selectedDate: new Date(),
            cambbioInicio: false,
            cambbioFin: false,
            selectedDateFin: new Date(),
            valorn: '',
            //Traidos desde bd
            impuestosbd: [],
            actualizarDatos: false,
            impuestosPorcentaje: [],
            impuestosPrecio: [],
            //edicion
            modoEdicion: false,
            idEditar: '',
            impuestoaEditar: null,
            modoBusquedaPorcentaje: false,
            modoBusquedaValor: false,
            campoBusquedaPorcentaje: '',
            campoBusquedaValor: '',
            impuestosPorcentajeBusqueda: [],
            impuestosValorBusqueda: []
        }
        store.subscribe(() => {
            this.setState({
                createbodega: store.getState().creacionImpuesto,
                actualizarDatos: store.getState().actualizarvista,
            })
        })

    }

    obtenerDatos = () => {
        //Permisos usuarios
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        var usuario = store.getState().usuario
        var dominio = store.getState().dominio
        switch (usuario.tipo) {
            case "1":
            case "2":
            case "3":
                Axios.get(Server.hostPrueba + Server.puertSSLParametrizacion + '/api/impuestos/empresas/' + parseInt(usuario.empresas_id, 10), { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee)
                        var porc = []
                        var prec = []
                        var totales = []
                        if (responsee.status === 200) {
                            responsee.data.map((impuesto, key) => {
                                totales.push(impuesto)
                                if (impuesto.porcentaje_valor == '$') {
                                    prec.push({
                                        id: impuesto.id,
                                        descripcion: impuesto.descripcion,
                                        valor: impuesto.valor,
                                        fecha_inicio: impuesto.fecha_inicio,
                                        fecha_fin: impuesto.fecha_fin,
                                        porcentaje_valor: impuesto.porcentaje_valor,
                                        estado: impuesto.estado

                                    })
                                } else {
                                    porc.push({
                                        id: impuesto.id,
                                        descripcion: impuesto.descripcion,
                                        valor: impuesto.valor,
                                        fecha_inicio: impuesto.fecha_inicio,
                                        fecha_fin: impuesto.fecha_fin,
                                        porcentaje_valor: impuesto.porcentaje_valor,
                                        estado: impuesto.estado
                                    })
                                }
                            })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }

                        this.setState({ impuestosPorcentaje: porc, impuestosPrecio: prec, impuestosbd: totales, loader: false })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break;
            case "4":
            case "5":
                Axios.get(Server.hostPrueba + Server.puertSSLParametrizacion + '/api/impuestos/empresas/' + parseInt(dominio, 10), { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee)
                        var porc = []
                        var prec = []
                        var totales = []
                        if (responsee.status === 200) {
                            responsee.data.map((impuesto, key) => {
                                totales.push(impuesto)
                                if (impuesto.porcentaje_valor == '$') {
                                    prec.push({
                                        id: impuesto.id,
                                        descripcion: impuesto.descripcion,
                                        valor: impuesto.valor,
                                        fecha_inicio: impuesto.fecha_inicio,
                                        fecha_fin: impuesto.fecha_fin,
                                        porcentaje_valor: impuesto.porcentaje_valor,
                                        estado: impuesto.estado

                                    })
                                } else {
                                    porc.push({
                                        id: impuesto.id,
                                        descripcion: impuesto.descripcion,
                                        valor: impuesto.valor,
                                        fecha_inicio: impuesto.fecha_inicio,
                                        fecha_fin: impuesto.fecha_fin,
                                        porcentaje_valor: impuesto.porcentaje_valor,
                                        estado: impuesto.estado
                                    })
                                }
                            })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }

                        this.setState({ impuestosPorcentaje: porc, impuestosPrecio: prec, impuestosbd: totales, loader: false })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break;
        }

    }

    componentWillMount() {
        this.obtenerDatos()
    }

    componentWillReceiveProps() {
        if (this.state.actualizarDatos) {
            console.log("estoy acaaa")
            this.obtenerDatos()
        }
    }


    changeSelect = name => event => {
        switch (name) {
            case 'checkIPorcentaje':
                this.setState({ checkIPorcentaje: true, checkIValor: false })
                break
            case 'checkIValor':
                this.setState({ checkIPorcentaje: false, checkIValor: true })
                break
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };

    handleChangemoment = date => {
        this.setState({ selectedDate: date, cambbioInicio: true });
    }

    handleChangemomentFin = date => {
        this.setState({ selectedDateFin: date, cambbioFin: true });
    }


    crearbodega = () => {
        this.setState({ createbodega: !this.state.createbodega, descripcionN: '', selectedDate: new Date(), selectedDateFin: new Date(), checkIPorcentaje: true, checkIValor: false, valorn: false, modoEdicion: false })
    }

    mostrarTabla = () => {
        document.getElementById("table_id").DataTable();
    }

    editarImpuesto = (id) => {
        this.setState({ modoEdicion: true, createbodega: true, impuestoaEditar: id })
    }

    eliminarImpuesto = (id) => {
        Axios.delete(Server.hostPrueba + Server.puertSSLParametrizacion + '/api/impuestos/' + id, { headers: { Authorization: ntoken } })
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    swal({
                        title: "Impuesto eliminado correctamente",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                store.dispatch({
                                    type: "CREACION_IMPUESTO",
                                    creacionImpuesto: false
                                })
                                this.setState({ loader: true, modoEdicion: false })
                                break;

                            default:

                        }
                    });
                } else if (response.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                }
            })
    }

    buscar = (name) => {
        console.log("jsjs")
        if (name == "porcentaje") {
            var cadena = this.state.campoBusquedaPorcentaje.toUpperCase()
            var impuestos = []
            this.state.impuestosPorcentaje.map((impuesto, key) => {
                if (impuesto.descripcion.includes(cadena) || impuesto.valor.includes(cadena)) {
                    impuestos.push(impuesto)
                }
                console.log(impuesto)
            })
            this.setState({ modoBusquedaPorcentaje: true, impuestosPorcentajeBusqueda: impuestos })
        } else if (name == "valor") {
            var cadena = this.state.campoBusquedaValor.toUpperCase()
            var impuestos = []
            this.state.impuestosPrecio.map((impuesto, key) => {
                if (impuesto.descripcion.includes(cadena) || impuesto.valor.includes(cadena)) {
                    impuestos.push(impuesto)
                }
                console.log(impuesto)
            })
            this.setState({ modoBusquedaValor: true, impuestosValorBusqueda: impuestos })
        }
    }

    limpiar = (name) => {
        if (name == "porcentaje") {
            this.setState({ modoBusquedaPorcentaje: false, impuestosPorcentajeBusqueda: [], campoBusquedaPorcentaje: '' })
        } else if (name == "valor") {
            this.setState({ modoBusquedaValor: false, impuestosValorBusqueda: [], campoBusquedaValor: '' })
        }
    }

    activarImpuesto = (id) => {
        var usuario = store.getState().usuario
        var datos = {
            "id": id,
            "entidad_nombre": "impuestos",
            "estado": "A",
            "personas_id": usuario.p_id,
            "empresas_id": usuario.empresas_id
        }
        console.log(datos)
        Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/estados/', datos, { headers: { Authorization: ntoken } })
            .then((res) => {
                console.log(res)
                if (res.status == 201) {
                    swal({
                        title: "IMPUESTO ACTIVADO CORRECTAMENTE",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })

                                break;
                            default:

                        }
                    });
                } else if (res.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    swal("Fallo al activar", "Por favor verifique los datos", "warning");
                }
            })
    }

    keyBuscarPorcentaje = (e) => {
        if (e.key === 'Enter') {
            this.buscar('porcentaje')
        } else if (e.key === 'Escape') {
            this.limpiar('porcentaje')
        }
    }


    keyBuscarValor = (e) => {
        console.log("asdsa")
        if (e.key === 'Enter') {
            this.buscar("valor")
        } else if (e.key === 'Escape') {
            this.limpiar('valor')
        }
    }

    render() {
        return (
            this.state.loader ? <Loader />
                :
                <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                    {this.state.createbodega ? <button className="btn btn-danger" onClick={this.crearbodega}>CANCELAR</button> : permisos.includes('C') ? <button className="btn btn-success" onClick={this.crearbodega}>CREAR NUEVO IMPUESTO</button> : null}
                    {this.state.createbodega ? <FormularioImpuesto impuestoEditar={this.state.modoEdicion ? this.state.impuestoaEditar : null} /> : null}

                    {permisos.includes('R') ? <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col col-md-6 border rounded" style={{ paddingBottom: '20px' }}>
                            <div className="row">
                                <div className="mx-auto">
                                    <h5>IMPUESTOS POR PORCENTAJE</h5>
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: '15px' }}>
                                <div className="col-md-11 mx-auto">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">#</span>
                                        </div>
                                        <input type="text" placeholder="Buscar" onKeyDown={this.keyBuscarPorcentaje} value={this.state.campoBusquedaPorcentaje} onChange={this.handleChange('campoBusquedaPorcentaje')} className="form-control mx-auto" />
                                        {this.state.modoBusquedaPorcentaje ? <div className="input-group-append">
                                            <button className="btn btn-outline-primary" disabled={!this.state.modoBusquedaPorcentaje} type="button" onClick={() => this.limpiar('porcentaje')}>x</button>
                                        </div> : null}
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" type="button" onClick={() => this.buscar('porcentaje')}>BUSCAR</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.modoBusquedaPorcentaje
                                    ?
                                    <List style={{ backgroundColor: "#fff", padding: '0' }} className="border rounded">
                                        {this.state.impuestosPorcentajeBusqueda.map((impuesto, key) => {
                                            console.log(impuesto)
                                            return (
                                                <ListItem key={key} className="border">
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            %
                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={impuesto.descripcion}
                                                        secondary={impuesto.valor + '%'}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        {permisos.includes('U') ? <button className="btn btn-sm btn-warning">
                                                            <EditICon onClick={() => this.editarImpuesto(impuesto)} />
                                                        </button> : null}
                                                        {impuesto.estado == "A" ? permisos.includes('D') ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }}>
                                                            <DeleteIcon onClick={() => this.eliminarImpuesto(impuesto.id)} />
                                                        </button> : null : permisos.includes('D') ? <button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }}>
                                                            <Activar onClick={() => this.activarImpuesto(impuesto.id)} />
                                                        </button> : null}
                                                    </ListItemSecondaryAction>

                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                    :
                                    <List style={{ backgroundColor: "#fff", padding: '0' }} className="border rounded">
                                        {this.state.impuestosPorcentaje.map((impuesto, key) => {
                                            console.log(impuesto)
                                            return (
                                                <ListItem key={key} className="border">
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            %
                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={impuesto.descripcion}
                                                        secondary={impuesto.valor + '%'}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        {permisos.includes('U') ? <button className="btn btn-sm btn-warning">
                                                            <EditICon onClick={() => this.editarImpuesto(impuesto)} />
                                                        </button> : null}
                                                        {impuesto.estado == "A" ? permisos.includes('D') ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }}>
                                                            <DeleteIcon onClick={() => this.eliminarImpuesto(impuesto.id)} />
                                                        </button> : null : permisos.includes('D') ? <button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }}>
                                                            <Activar onClick={() => this.activarImpuesto(impuesto.id)} />
                                                        </button> : null}
                                                    </ListItemSecondaryAction>

                                                </ListItem>
                                            )
                                        })}
                                    </List>

                            }

                        </div>
                        <div className="col col-md-6 border rounded" style={{ paddingBottom: '20px' }}>
                            <div className="row">
                                <div className="mx-auto">
                                    <h5>IMPUESTOS POR VALOR</h5>
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: '15px' }}>
                                <div className="col-md-11 mx-auto">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">$</span>
                                        </div>
                                        <input type="text" placeholder="Buscar" onKeyDown={this.keyBuscarValor} value={this.state.campoBusquedaValor} onChange={this.handleChange('campoBusquedaValor')} className="form-control mx-auto" />
                                        {this.state.modoBusquedaValor ? <div className="input-group-append">
                                            <button className="btn btn-outline-primary" disabled={!this.state.modoBusquedaValor} type="button" onClick={() => this.limpiar('valor')}>x</button>
                                        </div> : null}
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" type="button" onClick={() => this.buscar('valor')}>BUSCAR</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.modoBusquedaValor
                                    ?
                                    <List style={{ backgroundColor: "#fff", padding: '0' }} className="border rounded">
                                        {this.state.impuestosValorBusqueda.map((impuesto, key) => {
                                            return (
                                                <ListItem key={key} className="border">
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            $
                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={impuesto.descripcion}
                                                        secondary={'$' + impuesto.valor}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        {permisos.includes('U') ? <button className="btn btn-sm btn-warning">
                                                            <EditICon onClick={() => this.editarImpuesto(impuesto)} />
                                                        </button> : null}
                                                        {impuesto.estado == "A" ? permisos.includes('D') ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }}>
                                                            <DeleteIcon onClick={() => this.eliminarImpuesto(impuesto.id)} />
                                                        </button> : null : permisos.includes('D') ? <button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }}>
                                                            <Activar onClick={() => this.activarImpuesto(impuesto.id)} />
                                                        </button> : null}
                                                    </ListItemSecondaryAction>

                                                </ListItem>
                                            )
                                        })}

                                    </List>
                                    :
                                    <List style={{ backgroundColor: "#fff", padding: '0' }} className="border rounded">
                                        {this.state.impuestosPrecio.map((impuesto, key) => {
                                            return (
                                                <ListItem key={key} className="border">
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            $
                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={impuesto.descripcion}
                                                        secondary={'$' + impuesto.valor}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        {permisos.includes('U') ? <button className="btn btn-sm btn-warning">
                                                            <EditICon onClick={() => this.editarImpuesto(impuesto)} />
                                                        </button> : null}
                                                        {impuesto.estado == "A" ? permisos.includes('D') ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }}>
                                                            <DeleteIcon onClick={() => this.eliminarImpuesto(impuesto.id)} />
                                                        </button> : null : permisos.includes('D') ? <button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }}>
                                                            <Activar onClick={() => this.activarImpuesto(impuesto.id)} />
                                                        </button> : null}
                                                    </ListItemSecondaryAction>

                                                </ListItem>
                                            )
                                        })}

                                    </List>
                            }

                        </div>
                    </div> : <h5>USTED NO TIENE PERMISOS PARA VER LSO DATOS</h5>}
                </div>


        )
    }
}



export default Impuestos;