import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { GoogleApiWrapper } from 'google-maps-react';
import Axios from 'axios'
import Paper from '@material-ui/core/Paper';
import { MDBDataTable } from 'mdbreact';
import Loader from '../../../components/Loader'
import Server from '../../../lib/server'
import store from '../../../lib/store'
import FormularioEmpresa from '../../../components/Formularios/FormularioEmpresa';
import FormularioNegocio from '../../../components/Formularios/FormularioNegocio';
import FormularioConfiguracionEds from '../../../components/Formularios/FormularioConfiguracionEds';
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide"
import Ojoicon from "@material-ui/icons/RemoveRedEye"
import DeleteIcon from '@material-ui/icons/Delete';
import EditICon from '@material-ui/icons/Edit';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import ListIcon from '@material-ui/icons/List';
import ActivarIcon from "@material-ui/icons/Check"
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import swal from 'sweetalert'
import excelIcon from "../../../assets/img/excel.png";
import ReactExport from "react-export-excel";
import moment from 'moment';
import AjusteIcon from '@material-ui/icons/SettingsApplications';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const idModulo = 4
var permisos = ''
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class dominio extends Component {
    constructor() {
        super();
        this.state = {
            //BD
            empresasbd: [],
            provinciasbd: [],
            ciudadesbd: [],
            sucursalesbd: [],
            //
            centerDireccion: { lat: 10.9888859, lng: -74.82026589999998 },
            zoom: 13,
            createbodega: false,
            //mapa

            dialogDireccion: false,
            address: '',
            loader: true,
            actualizarDatos: false,
            modovista: false,
            empresasTotales: [],
            excelKioskos: [],
            excelTotals: [],
            //ConfigEDS
            configuracionEdsbool: false,
        }
        store.subscribe(() => {
            this.setState({
                actualizarDatos: store.getState().actualizarvista,
                createbodega: store.getState().creacionEmpresa,
                configuracionEdsbool: store.getState().configuracionEds,
            })
        })

    }

    cerrarConfiguracionEds = () => {
        store.dispatch({
            type: "CONFIGURACION_EDS",
            configuracionEds: false,
            edsDetalles: null,
            modoVistaEds: false
        })
    }

    abrirConfiguracionEds = (id) => {
        console.log(this.state.configuracionEdsbool)
        store.dispatch({
            type: "CONFIGURACION_EDS",
            configuracionEds: true,
            edsDetalles: null,
            modoVistaEds: false
        })
    }

    detallesEmpresa = (id) => {
        this.setState({ modovista: true })
        console.log('aqui')
        console.log(this.state.modovista)

        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/by/' + id, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var perf = []
                if (responsee.status === 200) {

                    store.dispatch({
                        type: "CREACION_EMPRESA",
                        creacionEmpresa: true,
                        empresaDetalles: responsee.data.data,
                        modoVistaEmpresas: true
                    })

                } else if (responsee.status === 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    inactivarTienda = (id) => {
        var usuario = store.getState().usuario
        var hijos = ''
        var datos = {
            "id": id,
            "entidad_nombre": "empresas",
            "estado": "I",
            "personas_id": usuario.p_id,
            "empresas_id": usuario.empresas_id
        }
        console.log(datos)
        swal({
            title: "Estas seguro de que quieres deseas inactivar esta tienda?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/estados/', datos, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 201) {
                                //Importador
                                Axios.get(Server.hostPruebasinssl + Server.puertoImportador + '/api/importador/empresas', { headers: { Authorization: ntoken } })
                                    .then((res) => {
                                        console.log(res)
                                    })
                                //Obtencion de empresas hijas
                                Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/node/' + usuario.empresas_id + '/0', { headers: { Authorization: ntoken } })
                                    .then(response => {

                                        if (response.status == 200) {
                                            response.data.data.map((item, key) => {
                                                if (key == 0) {
                                                    hijos = item.toString()
                                                } else {
                                                    hijos += ',' + item.toString()
                                                }
                                            })
                                            store.dispatch({
                                                type: "OBTENCION_JERARQUIA_HIJOS",
                                                empresasHijos: hijos,
                                                usuaurioObtenido: true
                                            })
                                        }
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    })

                                swal({
                                    title: "Tienda inactivada correctamente",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            store.dispatch({
                                                type: "ACTUALIZACION_DATOS",
                                                actualizarvista: true
                                            })

                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 5000);
                            } else {
                                swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }
    activarTienda = (id) => {
        var usuario = store.getState().usuario
        var hijos = ''
        var datos = {
            "id": id,
            "entidad_nombre": "empresas",
            "estado": "A",
            "personas_id": usuario.p_id,
            "empresas_id": usuario.empresas_id
        }
        console.log(datos)
        Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/estados/', datos, { headers: { Authorization: ntoken } })
            .then((res) => {
                console.log(res)
                if (res.status == 201) {
                    //Importador
                    Axios.get(Server.hostPruebasinssl + Server.puertoImportador + '/api/importador/empresas', { headers: { Authorization: ntoken } })
                        .then((res) => {
                            console.log(res)
                        })
                    //Obtencion de empresas hijas
                    Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/node/' + usuario.empresas_id + '/0', { headers: { Authorization: ntoken } })
                        .then(response => {
                            if (response.status == 200) {
                                response.data.data.map((item, key) => {
                                    if (key == 0) {
                                        hijos = item.toString()
                                    } else {
                                        hijos += ',' + item.toString()
                                    }
                                })
                                store.dispatch({
                                    type: "OBTENCION_JERARQUIA_HIJOS",
                                    empresasHijos: hijos,
                                    usuaurioObtenido: true
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                    swal({
                        title: "Tienda activada correctamente",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })

                                break;
                            default:

                        }
                    });
                } else if (res.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                } else {
                    swal("Fallo al activar", "Por favor verifique los datos", "warning");
                }
            })
    }

    editarEmpresa = (id) => {
        this.setState({ modovista: false })
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/by/' + id, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var perf = []
                if (responsee.status === 200) {
                    store.dispatch({
                        type: "CREACION_EMPRESA",
                        creacionEmpresa: true,
                        empresaDetalles: responsee.data.data,
                        modoVistaEmpresas: false
                    })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
                //this.setState({ perfilesbd: perf })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }


    changetab = (newTab) => {
        this.setState({ value: newTab })
        var prove = []
        var tipoDominio = store.getState().tipoDominio
        switch (newTab) {
            case 2:
                this.state.empresasTotales.map((tienda) => (
                    prove.push({
                        "nit": tienda.nit,
                        "razon_social": tienda.razon_social,
                        "regional": tienda.razon_social_padre,
                        "action": [
                            <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesEmpresa(tienda.id)}><Ojoicon /></button>,
                            tienda.estado == "A" ? permisos.includes("U") ? <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.editarEmpresa(tienda.id)}><EditICon /></button> : null : null,
                            permisos.includes("D") ? tienda.estado == "A" ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.inactivarTienda(tienda.id)}><DeleteIcon /></button> : <button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.activarTienda(tienda.id)}><ActivarIcon /></button> : null]
                        //"nit": tienda.nit,
                        //"Ciudad": tienda.razon_social,
                        //"Direccion": tienda.razon_social,
                        //"encargado": tienda.razon_social,
                    })
                ))
                break
            case 3:
                this.state.empresasTotales.map((tienda) => {
                    if (tienda.estado === "A") {
                        prove.push({
                            "nit": tienda.nit,
                            "razon_social": tienda.razon_social,
                            "regional": tienda.razon_social_padre,
                            "action": [
                                <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesEmpresa(tienda.id)}><Ojoicon /></button>,
                                permisos.includes("U") ? <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.editarEmpresa(tienda.id)}><EditICon /></button> : null,
                                permisos.includes("U") ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.inactivarTienda(tienda.id)}><DeleteIcon /></button> : null]

                        })
                    }
                })
                break
            case 4:
                this.state.empresasTotales.map((tienda) => {
                    if (tienda.estado === "I") {
                        prove.push({
                            "nit": tienda.nit,
                            "razon_social": tienda.razon_social,
                            "regional": tienda.razon_social_padre,
                            "action": [
                                permisos.includes("U") ? <button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.activarTienda(tienda.id)}><ActivarIcon /></button> : null]

                        })
                    }
                })
                break
            case 0:
                this.state.empresasTotales.map((tienda) => {
                    if (tienda.empresas_tipos_id === "5") {
                        prove.push({
                            "nit": tienda.nit,
                            "razon_social": tienda.razon_social,
                            "alias": tienda.alias,
                            "regional": tienda.razon_social_padre,
                            "action": [
                                <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesEmpresa(tienda.id)}><Ojoicon /></button>
                                ,
                                permisos.includes("U") ? <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.editarEmpresa(tienda.id)}><EditICon /></button> : null,
                                permisos.includes("U") ? tienda.estado === "I" ? <button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.activarTienda(tienda.id)}><ActivarIcon /></button> : <Tooltip title="INACTIVAR" placement="top"><button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.inactivarTienda(tienda.id)}><DeleteIcon /></button></Tooltip> : null,
                                tipoDominio === 'E' ? permisos.includes("U") ? <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.abrirConfiguracionEds(tienda.id)}><AjusteIcon /></button> : null : null]

                        })
                    }
                })
                break
            case 1:
                this.state.empresasTotales.map((tienda) => {
                    if (tienda.empresas_tipos_id === "4") {
                        prove.push({
                            "nit": tienda.nit,
                            "razon_social": tienda.razon_social,
                            "regional": tienda.razon_social_padre,
                            "action": [
                                <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesEmpresa(tienda.id)}><Ojoicon /></button>,
                                permisos.includes("U") ? <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.editarEmpresa(tienda.id)}><EditICon /></button> : null,
                                permisos.includes("U") ? tienda.estado === "I" ? <button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.activarTienda(tienda.id)}><ActivarIcon /></button> : <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.inactivarTienda(tienda.id)}><DeleteIcon /></button> : null]

                        })
                    }
                })
                break
        }
        this.setState({ empresasbd: prove })
    }

    actualizarDatos() {
        this.setState({ actualizarDatos: false, modovista: false })
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        //Tiendas
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/web/tipo/4,5/' + hijos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                console.log(responsee)
                if (responsee.status == 200) {
                    var empresasamostrar = []
                    var excelTodos = []
                    var excelTiendas = []
                    var empresasamostrar = []
                    responsee.data.empresas.map((tienda) => {
                        var telefonos = ""
                        var correos = ""
                        var direcciones = ""
                        if (tienda.contactos != null) {
                            tienda.contactos.map((contacto) => {
                                if (contacto.estado === "A" && contacto.tipo === 3 && contacto.principal === "S") {
                                    direcciones += contacto.contacto + ", "
                                } else if (contacto.estado === "A" && contacto.tipo === 2 && contacto.principal === "S") {
                                    telefonos += contacto.contacto + ", "
                                } else if (contacto.estado === "A" && contacto.tipo === 1 && contacto.principal === "S") {
                                    correos += contacto.contacto + ", "
                                }
                            })
                        }
                        empresasamostrar.push(tienda)
                        excelTodos.push(tienda)
                        if (tienda.empresas_tipos_id === "5") {
                            excelTiendas.push({
                                '0': tienda.razon_social,
                                '1': tienda.alias,
                                '2': tienda.nit,
                                '3': tienda.codigo,
                                '4': tienda.razon_social_padre,
                                '5': tienda.pais,
                                '6': tienda.provincia,
                                '7': tienda.ciudad,
                                '8': direcciones,
                                '9': telefonos,
                                '10': correos
                            })
                        }

                    })
                    this.setState({
                        empresasTotales: empresasamostrar,
                        excelKioskos: excelTiendas,
                        excelTotals: excelTodos,
                        loader: false
                    })
                    this.changetab(0)
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })

    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizarDatos) {

            this.actualizarDatos()
            return true
        }
        return true;
    }

    componentWillMount() {
        this.actualizarDatos()
        //Provincias
    }

    crearbodega = () => {
        store.dispatch({
            type: "CREACION_EMPRESA",
            creacionEmpresa: true,
            empresaDetalles: null,
            modoVistaEmpresas: false
        })
    }



    render() {
        var tiendasbd = {}
        var tipoDominio = store.getState().tipoDominio
        var usuario = store.getState().usuario
        switch (this.state.value) {
            case 0:
                tiendasbd = {
                    columns: [
                        {
                            label: 'Nit',
                            field: 'codigo',
                            sort: 'asc',
                            width: 'auto'
                        }, {
                            label: 'Razon social',
                            field: 'razon_social',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Alias',
                            field: 'alias',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Regional',
                            field: 'padre',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Acciones',
                            field: 'nit',
                            sort: 'asc',
                            width: 150
                        }
                    ],
                    rows: this.state.empresasbd
                }
                break
            default:
                tiendasbd = {
                    columns: [
                        {
                            label: 'Nit',
                            field: 'codigo',
                            sort: 'asc',
                            width: 'auto'
                        }, {
                            label: 'Razon social',
                            field: 'razon_social',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Regional',
                            field: 'padre',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Acciones',
                            field: 'nit',
                            sort: 'asc',
                            width: 150
                        }
                    ],
                    rows: this.state.empresasbd
                }
                break

        }
        return (
            this.state.loader
                ?
                <Loader />
                :
                <div>
                    {this.state.createbodega ? <button className="btn btn-danger" onClick={this.crearbodega}>Cancelar</button> : permisos.includes('C') ? <button className="btn btn-success" onClick={this.crearbodega}>CREAR EMPRESA</button> : null}
                    {permisos.includes('R')
                        ?
                        <ExcelFile
                            filename={"KIOSKOS"}
                            element={<button className="btn btn-success" style={{ margin: '5px' }}><img src={excelIcon} /></button>}
                        >
                            <ExcelSheet data={this.state.excelKioskos} name="KIOSKOS">
                                <ExcelColumn label="RAZON SOCIAL" value="0" />
                                <ExcelColumn label="ALIAS" value="1" />
                                <ExcelColumn label="NIT" value="2" />
                                <ExcelColumn label="CODIGO DE TIENDA" value="3" />
                                <ExcelColumn label="GRUPO" value="4" />
                                <ExcelColumn label="PAIS" value="5" />
                                <ExcelColumn label="DEPARTAMENTO" value="6" />
                                <ExcelColumn label="CIUDAD" value="7" />
                                <ExcelColumn label="DIRECCION" value="8" />
                                <ExcelColumn label="TELEFONOS" value="9" />
                                <ExcelColumn label="CORREOS" value="10" />
                            </ExcelSheet>
                        </ExcelFile>
                        :
                        null}
                    {this.state.createbodega ?
                        <Dialog fullScreen open={this.state.createbodega} onClose={this.crearbodega} TransitionComponent={Transition}>
                            {usuario.tipo === "1" ?
                                <FormularioNegocio modoVista={this.state.modovista} />
                                :
                                <FormularioEmpresa modoVista={this.state.modovista} />
                            }
                        </Dialog>
                        : null}
                    <Dialog fullScreen open={this.state.configuracionEdsbool} onClose={this.cerrarConfiguracionEds} TransitionComponent={Transition}>

                        <FormularioConfiguracionEds />

                    </Dialog>
                    <div className="row" style={{ marginTop: "15px" }}>
                        {permisos.includes('R') ? <div className="col col-md-12">
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={this.state.value}
                                    variant="scrollable"
                                    scrollButtons="on"
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Tab label={tipoDominio === 'E' || tipoDominio === 'V' ? "EDS" : "TIENDAS"} onClick={() => this.changetab(0)} icon={<ListIcon />} />
                                    <Tab label="GRUPOS" onClick={() => this.changetab(1)} icon={<ListIcon />} />
                                    <Tab label="TODOS" onClick={() => this.changetab(2)} icon={<ListIcon />} />
                                    <Tab label="ACTIVOS" onClick={() => this.changetab(3)} icon={<ListIcon />} />
                                    <Tab label="INACTIVOS" onClick={() => this.changetab(4)} icon={<ListIcon />} />
                                </Tabs>
                            </AppBar>
                            <Paper style={{ padding: '10px' }}>
                                <MDBDataTable
                                    className="col-md-12"
                                    searchLabel="Buscar"
                                    paginationLabel={["Anterior", "Siguente"]}
                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                    entriesLabel="Filas a mostrar"
                                    tbodyColor="primary-color"
                                    theadColor="#FF8800"
                                    exportToCSV={true}
                                    striped
                                    bordered
                                    autoWidth={false}
                                    responsive={true}
                                    hover
                                    data={tiendasbd}
                                />
                            </Paper>
                        </div> : <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>}
                    </div>
                </div>
        )
    }
}



export default GoogleApiWrapper({
    apiKey: 'AIzaSyDwX4ZKPWuQBM3cxGsXvz8MONBD0NtpoIo'
})(dominio)
