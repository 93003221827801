
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import 'bootstrap/dist/css/bootstrap.min.css';
import CardTittle from '@material-ui/core/CardHeader'
import GridItem from "components/Grid/GridItem.jsx";
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Server from '../../lib/server'
import store from '../../lib/store'
import Axios from 'axios'
import swal from 'sweetalert'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
var impuestoEditar = false
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class MediosPago extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkBoucher: false,
            checkCambio: false,
            checkCredito: false,
            idEditar: 0,
            //DAtos a guardar
            descripcionN: '',
            minimoValor: '',
            valorn: '',
            modoEditar: false,
            nimpuesto: null,
            response:false
        };
    }

    componentWillMount() {
        impuestoEditar = this.props.unidadActualizar
        console.log(this.props)
        if (impuestoEditar != null) {
            this.setState({ modoEdicion: true, descripcionN: impuestoEditar.descripcion, checkCambio: impuestoEditar.cambio == "S", checkBoucher: impuestoEditar.comprobante == "S", checkCredito: impuestoEditar.credito == "S", valorn: impuestoEditar.maximo_cambio + '', minimoValor: impuestoEditar.minimo_valor, idEditar: impuestoEditar.id })
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };


    changeSelect = name => event => {
        var estado = (event.target.value == 'false' ? false : true)
        this.setState({
            [name]: !estado
        });
    }


    enviarDatos = () => {
        var usuario = store.getState().usuario
        var dominio = store.getState().dominio
        var validar = this.validarFormulario()
        var datos = {
            "descripcion": this.state.descripcionN,
            "credito": this.state.checkCredito ? 'S' : 'N',
            "cambio": this.state.checkCambio ? 'S' : 'N',
            "minimo_valor": this.state.minimoValor == '' ? 0 :parseInt(this.state.minimoValor),
            "comprobante": this.state.checkBoucher ? 'S' : 'N',
            "maximo_cambio": this.state.checkCambio ? this.state.valorn : '0',
            "empresas_id": parseInt(dominio, 10)
        }
        if (validar) {
            Axios.post(Server.hostPrueba + Server.puertSSLParametrizacion + '/api/MediosPagos', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    console.log(response)
                    this.setState({
                        response:false
                    })
                    if (response.status === 200) {
                        swal({
                            title: "Medio de pago creado correctamente",
                            icon: "success",
closeOnClickOutside:false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_MEDIO_PAGO",
                                        CreacionMedioPago: false
                                    })
                                    //this.setState({loader:true,descripcionN:'',selectedDate:new Date(),selectedDateFin:new Date(),checkIPorcentaje:true,checkIValor:false,valorn:false,cambbioInicio: false,cambbioFin:false})
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }
    }

    /*editarImpuesto = (id) => {
        this.state.impuestosbd.map((impuesto) => {
            if (impuesto.id == id) {
                this.setState({ modoEdicion: true, createbodega: true, descripcionN: impuesto.descripcion, checkIValor: impuesto.porcentaje_valor == "$", checkIPorcentaje: impuesto.porcentaje_valor == "%", valorn: impuesto.valor + '', selectedDate: impuesto.fecha_inicio, selectedDateFin: impuesto.fecha_fin, idEditar: impuesto.id })
            }
        })
    }*/

    cancelar = () => {
        store.dispatch({
            type: "CREACION_IMPUESTO",
            creacionImpuesto: false
        })
    }
    validarFormulario = () => {
        if (this.state.descripcionN.trim == "" || this.state.descripcionN.length == 0) {
            swal("FALLO AL CREAR", "LA DESCRIPCION ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.minimoValor.trim == "" || this.state.minimoValor.length == 0) {
            swal("FALLO AL CREAR", "EL VALOR MINIMO ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.checkCambio && this.state.valorn.trim == "") {
            swal("FALLO AL CREAR", "COMO USTED HA MARCADO LA OPCION DE ENTRGA CAMBIO, POR FAVOR DEBE COLOCAR EL VALOR DEL MAXIMO CAMBIO", "warning")
            return false
        } else {
            this.setState({
                response:true
            })
            return true
        }

    }

    actualizarDatos = () => {
        var usuario = store.getState().usuario
        var dominio = store.getState().dominio
        var validar = this.validarFormulario()
        var datos = {
            "descripcion": this.state.descripcionN,
            "credito": this.state.checkCredito ? 'S' : 'N',
            "cambio": this.state.checkCambio ? 'S' : 'N',
            "minimo_valor": this.state.minimoValor == '' ? 0 :parseInt(this.state.minimoValor),
            "comprobante": this.state.checkBoucher ? 'S' : 'N',
            "maximo_cambio": this.state.checkCambio ? this.state.valorn : '0',
            "empresas_id": parseInt(dominio, 10)
        }
        console.log(datos)

        if (validar) {
            Axios.put(Server.hostPrueba + Server.puertSSLParametrizacion + '/api/MediosPagos/' + this.state.idEditar, datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    console.log(response)
                    this.setState({
                        response:false
                    })
                    if (response.status === 200) {
                        swal({
                            title: "Medio de pago actualizado correctamente",
                            icon: "success",
closeOnClickOutside:false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_MEDIO_PAGO",
                                        CreacionMedioPago: false
                                    })
                                    this.setState({ loader: true, })
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }
    }

    render() {
        return (
            <GridItem xs={12} sm={12} md={12} id="crearOrden" style={{ overflow: "auto", padding: '0' }}>
                <Card style={{ marginTop: "15px", paddingBottom: "15px" }}>
                    <CardTittle title="Registro medio de pago" style={{ marginBottom: "0", paddingBottom: "5px" }} />
                    <CardContent style={{ paddingTop: "5px" }}>
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="numordencompra" className="col-form-label">Descripcion</label>
                                <input id="numordencompra" className="form-control" autoComplete="off" type="text" value={this.state.descripcionN} onChange={this.handleChange('descripcionN')} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="numordencompra" className="col-form-label">Minimo valor</label>
                                <input id="numordencompra" className="form-control" autoComplete="off" type="number" min="1"  value={this.state.minimoValor} onChange={this.handleChange('minimoValor')} />
                            </div>
                        </div>
                        <div className="row mx-auto">
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <FormControlLabel
                                            control={<Checkbox value={this.state.checkCredito} checked={this.state.checkCredito} style={{ padding: '5px' }} onChange={this.changeSelect('checkCredito')} />}
                                            label='Es credito'
                                            style={{ margin: '0' }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <FormControlLabel
                                            control={<Checkbox value={this.state.checkCambio} checked={this.state.checkCambio} style={{ padding: '5px' }} onChange={this.changeSelect('checkCambio')} />}
                                            label='Entrega cambio'
                                            style={{ margin: '0' }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <FormControlLabel
                                            control={<Checkbox value={this.state.checkBoucher} checked={this.state.checkBoucher} style={{ padding: '5px' }} onChange={this.changeSelect('checkBoucher')} />}
                                            label='Maneja boucher'
                                            style={{ margin: '0' }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {this.state.checkCambio
                                ?
                                <div className="col-md-4">
                                    <label htmlFor="numordencompra" className="col-form-label">Maximo cambio</label>

                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">$</span>
                                        </div>
                                        <input type="number" min="1"  class="form-control" aria-describedby="basic-addon1" value={this.state.valorn} onChange={this.handleChange('valorn')} />
                                    </div>
                                </div>
                                :
                                null}
                            <div className="bottom-aligned">
                                {this.state.modoEdicion ? <button className="btn btn-success" style={{ float: "right", margin: '10px' }} disabled={this.state.response} onClick={() => this.actualizarDatos()}>Guardar</button> : <button className="btn btn-success" style={{ float: "right", margin: '10px' }} disabled={this.state.response} onClick={() => this.enviarDatos()}>Crear medio de pago</button>}
                            </div>
                            <div className="bottom-aligned">
                                <button className="btn btn-danger" style={{ float: "right", margin: '10px' }} onClick={() => this.cancelar()}>Cancelar</button>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </GridItem>
        )
    }
}


export default MediosPago
