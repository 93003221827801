import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react'
import CardUsuario from '../../../components/Card/card/cardUsuario'
import Axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import store from '../../../lib/store'
import Switch from '@material-ui/core/Switch';
import { MDBDataTable } from 'mdbreact';
import "../../../components/Card/card/cardUsuario.css"
import FormularioUsuario from '../../../components/Formularios/FormularioCliente'
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide"
import Paper from '@material-ui/core/Paper';
import Loader from '../../../components/Loader'
import Server from '../../../lib/server'
import { Avatar } from '@material-ui/core';
import swal from 'sweetalert'
import DialogContent from '@material-ui/core/DialogContent';
var busquedaUsuario = []
var datosMostrarUsuario = []
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const idModulo = 40
var permisos = ''
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class usuariosVista extends Component {
    constructor() {
        super();
        this.state = {
            createUSer: false,
            setOpen: false,
            userdetails: -1,
            users: [],
            usersByName: [],
            tabla: false,
            busqueda: false,
            value: 0,
            ventanaEdicion: false,
            usuarioEdicion: null,
            //Busqueda de datos
            busqueda: false,
            paginadorIndex: 0,
            paginadorMinimo: 0,
            paginadorMaximo: 15,
            paginadorTotal: 16,
            //
            animacionIzquierda: false,
            animacionDerecha: false,
            loader: false,
            actualizacionVista: false,
            carnetUsuario: '',
            mostrarCarnet: false,
            detallesCarnet: null

        }
        store.subscribe(() => {
            this.setState({
                createUSer: store.getState().creacionUsuario,
                usuarioEdicion: store.getState().usuarioEdicion,
                actualizacionVista: store.getState().actualizarvista
            })
        })
    }


    handleChangeTab = (event, value) => {
        this.setState({ value });
    };
    handleChange = () => {
        this.setState({
            tabla: !this.state.tabla
        })
    };
    infoUsusarioClosed = () => {
        console.log("define is equals to ")
    }



    handleUsername(e) {
        let s = ""
        s = e.target.value
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizacionVista) {
            console.log("desde vista")
            this.obtenerDatos()
            return true
        }
        return true;
    }



    obtenerDatos = () => {
        //this.setState({ actualizacionVista: false })
        store.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: false
        })
        var datos = store.getState().usuario
        var hijos = store.getState().empresasHijos
        switch (datos.tipo) {
            case "1":
            case "2":
            case "3":
            case "4":
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/persona/empresas/' + hijos, { headers: { Authorization: ntoken } })
                    .then(response => {
                        if (response.status == 200) {
                            var datos = response.data.rows
                        } else if (response.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }
                        this.setState({ users: datos, loader: false })
                    })
                break;
            case "5":
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/persona/empresas/' + parseInt(datos.empresas_id, 10), { headers: { Authorization: ntoken } })
                    .then(response => {
                        //console.log(response)
                        if (response.status == 200) {
                            var datos = response.data.rows
                        } else if (response.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }
                        this.setState({ users: datos, loader: false })
                    })
                break;
        }

    }
    componentWillMount() {
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        this.obtenerDatos()
    }


    handleClickOpen = () => {
        store.dispatch({
            type: "CREACION_USUARIO",
            creacionUsuario: !this.state.createUSer,
            usuarioEdicion: null,
            modovista: false
        })
        if (this.state.createUSer) {
            this.setState({ usuarioEdicion: null })
        }
    };
    buscar = (name) => event => {



        var cadena = event.target.value.toUpperCase()
        if (cadena.length > 0) {
            this.setState({ busqueda: true })
        } else {
            this.setState({ busqueda: false })
        }
        busquedaUsuario = []
        this.state.users.map((tipo, key) => {
            console.log(tipo.persona)
            if ((tipo.persona.nombres + " " + tipo.persona.apellidos).includes(cadena)) {
                busquedaUsuario.push(tipo)
            } else {

            }
        })
    }

    detallesUsuario = (usuario) => {
        store.dispatch({
            type: "CREACION_USUARIO",
            creacionUsuario: !this.state.edicicionUsuario,
            usuarioEdicion: usuario,
            modovista: true,
            edicicionUsuario: false
        })
        if (this.state.createUSer) {
            this.setState({ usuarioEdicion: null })
        }
    }

    pagination = (name, str) => {
        switch (name) {
            case 'tipo':
                this.setState({ animacionDerecha: false, animacionIzquierda: false })
                if (str == 'S') {
                    this.setState({ animacionDerecha: true, animacionIzquierda: false, paginadorIndex: this.state.paginadorIndex + 1, paginadorMinimo: this.state.paginadorMaximo + 1, paginadorMaximo: this.state.paginadorMaximo + this.state.paginadorTotal })
                } else {
                    this.setState({ animacionDerecha: false, animacionIzquierda: true, paginadorIndex: this.state.paginadorIndex - 1, paginadorMinimo: (this.state.paginadorMinimo - this.state.paginadorTotal), paginadorMaximo: this.state.paginadorMinimo - 1 })
                }
                this.datos()
                break;
        }
    }

    datos = () => {
        datosMostrarUsuario = []
        for (var i = this.state.paginadorMinimo; i <= this.state.paginadorMaximo; i++) {
            if (this.state.users[i] != null) {
                datosMostrarUsuario.push(this.state.users[i])
            } else {
                //console.log(this.state.tipoEquipo[i])
            }
        }
        //console.log("Minimno:" + this.state.paginadorMinimo + " - maximo:" + this.state.paginadorMaximo)
    }

    cancelarCarnet = () => {
        this.setState({ mostrarCarnet: !this.state.mostrarCarnet })
    }

    render() {
        this.datos()
        var data = {}
        var dominio = store.getState().dominio
        const imagen = Server.hostMultimedia + dominio + "/persona/default.png"
        const { value } = this.state;
        //CArnet
        var dCarnet = null
        if (this.state.detallesCarnet != null) {
            dCarnet = this.state.detallesCarnet
        }
        return (
            this.state.loader
                ?
                <div style={{ height: '100vh', width: '100%', paddingLeft: '15px', paddingRight: '15px' }} className="mx-auto my-auto">
                    <Loader style={{ height: '100vh', width: '100%' }} />
                </div>
                :
                <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                    <div className="row">
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={this.handleChange.bind(this)}
                                    value="checkedA"
                                />
                            }
                            label={this.state.tabla ? "Cambiar a tarjetas" : "Cambiar a tabla"}
                        />
                    </div>
                    <div className="row">
                        {permisos.includes('C') ? <button className="btn btn-success" onClick={this.handleClickOpen}>Agregar cliente</button> : null}
                    </div>
                    <Dialog fullScreen open={this.state.createUSer} onClose={this.handleClickOpen} TransitionComponent={Transition}>
                        <FormularioUsuario userEdit={this.state.usuarioEdicion} />
                    </Dialog>
                    {permisos.includes('R') ? this.state.tabla ?
                        <div className="row" style={{ marginTop: "15px", marginTop: '15px' }}>
                            <div className="col col-md-12" style={{ padding: 0 }}>
                                <Paper style={{ padding: '10px' }}>
                                
                                    <MDBDataTable
                                        //striped
                                        className="col-md-12"
                                        searchLabel="Buscar"
                                        paginationLabel={["Anterior", "Siguente"]}
                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                        entriesLabel="Filas a mostrar"
                                        tbodyColor="primary-color"
                                        theadColor="#FF8800"
                                        exportToCSV={true}
                                        striped
                                        bordered
                                        autoWidth={false}
                                        responsive={true}
                                        hover
                                        data={data}
                                    />
                                </Paper>
                            </div>
                        </div>

                        :
                        <div>
                            <div className="row mx-auto" style={{ marginTop: '15px' }}>
                                <button disabled={this.state.paginadorMinimo == 0} className="btn btn-sm btn-outline-primary mx-auto" onClick={() => this.pagination('tipo', 'N')}>{"<<"}</button>
                                <div className="col col-md-6 mx-auto">
                                    <input type="text" placeholder="Buscar" className="form-control mx-auto" onChange={this.buscar('tipo')} />
                                </div>
                                <button disabled={this.state.paginadorMaximo + 1 >= this.state.users.length} className="btn btn-sm btn-outline-primary mx-auto" onClick={() => this.pagination('tipo', 'S')}>{">>"}</button>
                            </div>
                            <div className="row">

                                {this.state.busqueda
                                    ?
                                    busquedaUsuario.map((user, key) => {
                                        return (
                                            <div key={key} className={window.innerWidth <= 1400 ? "col-md-4" : "col-md-3"} style={{ marginTop: "10px", padding: "5px" }} >
                                                <CardUsuario usuario={user}></CardUsuario>
                                            </div>
                                        )
                                    })
                                    :


                                    datosMostrarUsuario.map((user, key) => {
                                        return (
                                            <div key={key} className={window.innerWidth <= 1400 ? "col-md-4" : "col-md-3"} style={{ marginTop: "10px", padding: "5px" }} >
                                                <CardUsuario usuario={user}></CardUsuario>
                                            </div>
                                        )
                                    })

                                }
                            </div>

                        </div>
                        : <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>}
                </div>
        );
    }
}



export default usuariosVista;
