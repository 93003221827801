import { saveState } from './estadoStore'
import { createStore } from 'redux'


const reducer = (state, action) => {
    if (action.type === "INICIO_DE_SESION") {
        //
        return {
            ...state,
            token: action.token,
            usuario: action.usuario
        }
    } else if (action.type === "VALIDAR_SESION") {
        return {
            ...state,
            token:action.token,
            tiempo:action.tiempo
        }
    }else if (action.type === "OBTENCION_CREDENCIALES") {
        return {
            ...state,
            usuario: action.usuario
        }
    }else if (action.type === "OBTENCION_COLOR") {
        return {
            ...state,
            color: action.color,
            ventanaColor:action.ventanaColor
        }
    }else if (action.type === "OBTENCION_DOMINIO") {
        return {
            ...state,
            dominio:action.dominio,
            detalleDominio:action.detalleDominio,
            tipoDominio:action.tipoDominio
        }
    } else if (action.type === "ABRIR_MENU_LATERAL") {
        return {
            ...state,
            menulateral: action.menulateral
        }
    } else if (action.type === "ACTUALIZAR_VISTA") {
        return {
            ...state,
            actualizarvista: action.actualiizar
        }
    } else if (action.type === "CAMBIO_PANTALLA") {
        return {
            ...state,
            ismobile: action.ismobile,
            menulateral: action.menulateral
        }
    } else if (action.type === "AUTOGUARDADO") {
        return {
            ...state,
            autoguardado: action.autoguardado,
            datosAutoguardado: action.datosAutoguardado
        }
    } else if (action.type === "CREACION_USUARIO") {
        return {
            ...state,
            creacionUsuario:action.creacionUsuario,
            menulateral: action.menulateral,
            usuarioEdicion:action.usuarioEdicion,
            modovista:action.modovista
        }
    }else if (action.type === "CREACION_PRODUCTO") {
        return {
            ...state,
            creacionProducto:action.creacionProducto,
            productoEdicion:action.productoEdicion,
            vistaProducto:action.vistaProducto,
            vista:action.vista,

        }
    } else if (action.type === "CREACION_PROMOCION") {
        return {
            ...state,
            creacionPromocion:action.creacionPromocion,
            detallesPromocion:action.detallesPromocion,
            promocionEditar:action.promocionEditar,
            tipoPromocion:action.tipoPromocion

        }
    } else if (action.type === "CREACION_EQUIPO") {
        return {
            ...state,
            ventanaCreacionEquipo:action.ventanaCreacionEquipo,
        }
    }else if (action.type === "PRE_CREACION_EQUIPO") {
        return {
            ...state,
            ventanaPreCreacionEquipo:action.ventanaPreCreacionEquipo,
        }
    }else if (action.type === "DETALLES_MOVIMIENTO") {
        return {
            ...state,
            ventanaMovimiento:action.ventanaMovimiento,
            movimientoDetalles:action.movimientoDetalles,
            vistaMovimiento:action.vistaMovimiento
        }
    }  else if (action.type === "CREACION_SD") {
        return {
            ...state,
            ventanaCreacionSD:action.ventanaCreacionSD
        }
    }  else if (action.type === "CREACION_IMPUESTO") {
        return {
            ...state,
            creacionImpuesto:action.creacionImpuesto
        }
    } else if (action.type === "CREACION_EMPRESA") {
        return {
            ...state,
            creacionEmpresa:action.creacionEmpresa,
            empresaDetalles:action.empresaDetalles,
            modoVistaEmpresas:action.modoVistaEmpresas
        }
    }else if (action.type === "CONFIGURACION_EDS") {
        return {
            ...state,
            configuracionEds:action.configuracionEds,
            edsDetalles:action.edsDetalles,
            modoVistaEds:action.modoVistaEds
        }
    }   else if (action.type === "CREACION_GEOGRAFIA") {
        return {
            ...state,
            creacionGeografia:action.creacionGeografia
        }
    }else if (action.type === "CREACION_UNIDAD") {
        return {
            ...state,
            creacionUnidad:action.creacionUnidad
        }
    }else if (action.type === "AJUSTE_INICIAL") {
        return {
            ...state,
            ajusteInicial:action.ajusteInicial
        }
    }else if (action.type === "TRASLADO_BODEGA") {
        return {
            ...state,
            trasladoBodega:action.trasladoBodega
        }
    } else if (action.type === "CREACION_MEDIO_PAGO") {
        return {
            ...state,
            CreacionMedioPago:action.CreacionMedioPago
        }
    }else if (action.type === "CREACION_CONSECUTIVO") {
        return {
            ...state,
            creacionConsecutivo:action.creacionConsecutivo
        }
    }else if (action.type === "CREACION_CATEGORIA") {
        return {
            ...state,
            creacionCategoria:action.creacionCategoria
        }
    }else if (action.type === "CREACION_PARAMETROS") {
        return {
            ...state,
            creacionParametros:action.creacionParametros,
            infoSurtidor:action.infoSurtidor
        }
    }else if (action.type === "CREACION_PARAMETROS_EQUIPOS") {
        return {
            ...state,
            creacionParametrosEquipos:action.creacionParametrosEquipos,
            infoSurtidor:action.infoSurtidor
        }
    }else if (action.type === "CREACION_BODEGA") {
        return {
            ...state,
            ventanaCreacionBodega:action.ventanaCreacionBodega
        }
    }else if (action.type === "CREACION_PERFIL") {
        return {
            ...state,
            ventanaPerfil:action.ventanaPerfil,
            perfilEditar:action.perfilEditar,
            vistaPerfil:action.vistaPerfil
        }
    }else if (action.type === "CREACION_ACUERDO") {
        return {
            ...state,
            ventanaAcuerdo:action.ventanaAcuerdo
        }
    }else if (action.type === "CAMBIO_CONTRASEÑA") {
        return {
            ...state,
            ventanaContrseña:action.ventanaContrseña
        }
    }else if (action.type === "OLVIDO_CONTRASEÑA") {
            return {
                ...state,
                ventanaOlvideContra:action.ventanaOlvideContra
            }
    }else if (action.type === "AGREGAR_IDENTIFICADOR") {
        return {
            ...state,
            codigos:action.codigos,
            creacionCodigo:action.creacionCodigo
        }
    }else if (action.type === "AGREGAR_TELEFONO_USUARIO") {
        return {
            ...state,
            telefonos:action.telefonos,
            creacionTelefono:action.creacionTelefono,
            telefonosEntrada:action.telefonosEntrada
        }
    }else if (action.type === "AGREGAR_CORREO_USUARIO") {
        return {
            ...state,
            correos:action.correos,
            creacionCorreo:action.creacionCorreo
        }
        
    }else if (action.type === "OBTENCION_PERFILES") {
        return {
            ...state,
            perfilesUSuario:action.perfilesUSuario
        }
        
    }else if (action.type === "OBTENCION_PRODUCTOS") {
        return {
            ...state,
            obtencionProductos:action.obtencionProductos
        }
        
    }else if (action.type === "OBTENCION_JERARQUIA_PADRES") {
        return {
            ...state,
            empresasPadres:action.empresasPadres
        }
        
    }else if (action.type === "VECTORES_AUXILIARES") {
        return {
            ...state,
            auxiliar1:action.auxiliar1,
            auxiliar2:action.auxiliar2,
            auxiliar3:action.auxiliar3,
        }
    }else if (action.type === "OBTENCION_JERARQUIA_HIJOS") {
        return {
            ...state,
            empresasHijos:action.empresasHijos,
            usuaurioObtenido:action.usuaurioObtenido
        }
        
    }else if (action.type === "ACTUALIZACION_DATOS") {
        return {
            ...state,
            actualizarvista:action.actualizarvista
        }
    }else {

    }
    return state
}
const store = createStore(
    reducer,
    {
        //Auxiliares
        auxiliar1:[],
        auxiliar2:[],
        auxiliar3:[],
        //EMRPESAS
        empresasPadres:'',
        empresasHijos:'',
        empresaDetalles:null,
        //Ventanas de creacion
        obtencionProductos:false,
        CreacionMedioPago:false,
        creacionConsecutivo:false,
        dominio:0,
        detalleDominio:'',
        ventanaCreacionEquipo:false,
        ventanaCreacionSD:false,
        ventanaCreacionBodega:false,
        perfilEditar:null,
        vistaPerfil:false,
        ventanaColor:false,
        modovista:false,
        creacionCodigo:false,
        creacionImpuesto:false,
        creacionCategoria:false,
        creacionUnidad:false,
        ajusteInicial:false,
        creacionEmpresa:false,
        creacionGeografia:false,
        ventanaAcuerdo:false,
        vista:false,
        ventanaPerfil:false,
        ventanaMovimiento:false,
        movimientoDetalles:null,
        vistaMovimiento:false,
        ventanaContrseña:false,
        ventanaOlvideContra:false,
        //Actualizaciones
        actualizarvista:false,
        trasladoBodega:false,
        token: false,
        usuario: {},
        menulateral: true,
        ismobile: false,
        creacionUsuario:false,
        usuarioEdicion:null,
        autoguardado:false,
        datosAutoguardado:{},
        creacionPromocion:false,
        promocionEditar:false,
        modoVistaEmpresas:false,
        detallesPromocion:null,
        tipoPromocion:0,
        //usuarios
        perfilesUSuario:[],
        telefonos:[],
        telefonosEntrada:[],
        correos:[],
        codigos:[],
        creacionTelefono:false,
        creacionCorreo:false,
        //cREACION PRODUCRO
        creacionProducto:false,
        productoEdicion:null,
        vistaProducto:false,
        usuaurioObtenido:false,
        creacionParametros:false,
        color:'#f44336',
        //PARAMETRIZACION
        ventanaConfiguracion: false,
        creacionParametrosEquipos:false,
        infoSurtidor:null,
        tipoDominio:'T',
        ventanaPreCreacionEquipo:false,
        //Configuracion de eds
        modoVistaEds:false,
        edsDetalles:null,
        configuracionEds:false,
        //Dominio
        colorDominio:"#f44336"  //actual
        //colorDominio:"#7ECA19"  //FIREFUEL
        //colorDominio:"#f44336"        DEUNA
        //colorDominio:"#3B83BD"        azul claro

    }
)

store.subscribe(function () {
    saveState(store.getState())
})

export default store