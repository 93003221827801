import Axios from 'axios';
import Server from '../../../lib/server';
import store from '../../../lib/store';
import swal from 'sweetalert';

export const getParametros = async () => {
    return new Promise(function (resolve, reject) {
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        //Axios.get(Server.hostPrueba + Server.puertParametrizacion + '/parametrizacion/' , { headers: { Authorization: ntoken } })
        Axios.get(Server.hostNuevo+'/parametrizacion/' , { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.status === 200) {
                    var datos = res.data.parametros;
                    resolve(datos);
                } else if (res.status === 205) {
                    var datos = [];
                    resolve(datos);
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err, undefined);
            });
    });
};

export const modifyParametros = async (data) => {
    return new Promise(function (resolve, reject) {
        const {estado} = data
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        //Axios.get(Server.hostPrueba + Server.puertParametrizacion + '/parametrizacion/' , { headers: { Authorization: ntoken } })
        Axios.put(Server.hostNuevo+'/parametrizacion/' ,data, { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.status === 200) {
                    swal("OK", "PARAMETRO "+ (estado === "A" ? "ACTIVADO" : "INACTIVADO") , "success")
                    store.dispatch({
                        type: "ACTUALIZACION_DATOS",
                        actualizarvista: true
                    })
                } else if (res.status === 205) {
                    resolve([]);
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err, undefined);
            });
    });

};

export const createParametros = async (data) => {
    return new Promise(function (resolve, reject) {
        const {id} = data
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        //Axios.get(Server.hostPrueba + Server.puertParametrizacion + '/parametrizacion/' , { headers: { Authorization: ntoken } })
        Axios.post(Server.hostNuevo+'/parametrizacion/' ,data, { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.status === 200) {
                    swal("OK", "PARAMETRO "+ (id === 0 ? "CREADO" : "EDITADO") , "success")
                    store.dispatch({
                        type: "ACTUALIZACION_DATOS",
                        actualizarvista: true
                    })
                    store.dispatch({
                        type: "CREACION_PARAMETROS",
                        creacionParametros: false,
                        infoSurtidor: {}
                    })
                } else if (res.status === 205) {
                    resolve([]);
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err, undefined);
            });
    });

};