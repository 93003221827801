
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Server from '../../lib/server'
import store from '../../lib/store'
import Axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import swal from 'sweetalert'
import { Button } from 'react-bootstrap'
import Typography from '@material-ui/core/Typography';
import Selects from 'react-select'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import SacarIcon from '@material-ui/icons/Redo'
import DevolverIcon from '@material-ui/icons/Undo'
import moment from 'moment'
import "moment/locale/es";
var vistaUsuario = false
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
var colorDominio = store.getState().colorDominio
class MediosPago extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: new Date(),
            bodegasbd: [],
            productosbd: [],
            productoSeleccinado: '',
            bodegaSeleccionada: {},
            bodegaSalidaSeleccionada: {},
            costoN: '',
            cantidadN: '',
            productosdeentrada: [],
            productosdeSalida: [],
            totalCosto: 0,
            subTotal: 0,
            idEditar: 0,
            //DAtos a guardar
            descripcionN: '',
            minimoValor: '',
            valorn: '',
            modoEditar: false,
            nimpuesto: null,
            productoFoco: [],
            posicionFoco: 0,
            cambbioInicio: false,
            observacionN: '',
            tipoSalida: 0,
            checkSalidaDirecta: false,
            checkSalidaAveria: false,
            checkSalidaMerma: false,
            response: false,
            proveedoresbd: [],
            proveedorSeleccionado: {}
        };
    }

    componentWillMount() {
        //bODEGAS
        var usuario = store.getState().usuario
        Axios.get(Server.hostPrueba + Server.puertoSSLBodega + '/api/bodegas/empresa/' + usuario.empresas_id, { headers: { Authorization: ntoken } })
            .then(responsee => {
                console.log(responsee.data)
                var bdg = []
                responsee.data.map((bodega) => {
                    bdg.push({
                        value: bodega.bodega_id,
                        label: bodega.descripcion + " -- " + bodega.codigo
                    })
                })
                this.setState({ bodegasbd: bdg })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })

        //productos
        //Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/8')

    }

    devolvrProducto = (e) => {
        var pEntrada = this.state.productosdeentrada
        var psalida = this.state.productosdeSalida
        var producto = pEntrada[e]
        var boll = true
        psalida.map((producti, key) => {
            if (producti.id == producto.id) {
                psalida[key].saldo = parseInt(psalida[key].saldo) + parseInt(producto.saldo)
                pEntrada.splice(e, 1)
                boll = false
            }
        })
        if (boll) {
            psalida.push(producto)
            pEntrada.splice(e, 1)
        }
        this.setState({ productosdeSalida: psalida, productosdeentrada: pEntrada })
    }

    sacarProducto = (e) => {
        var mproducto = this.state.productosdeSalida
        this.setState({ productosdeSalida: mproducto, productoSeleccinado: mproducto[e].descripcion, cantidadN: mproducto[e].saldo, productoFoco: mproducto[e], posicionFoco: e })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };


    changeSelect = name => event => {
        var estado = (event.target.value == 'false' ? false : true)
        this.setState({
            [name]: !estado
        });
    }

    agregarProducto = () => {
        var pentrada = this.state.productosdeentrada
        var psalida = this.state.productosdeSalida
        var productof = this.state.productoFoco
        var ids = []
        this.state.productosdeentrada.map((producto, key) => {
            ids.push(parseInt(producto.id))
        })
        if (parseInt(this.state.cantidadN) > productof.saldo) {
            swal("ERROR", "ESTA INTENTANTO SACAR MAS UNIDADES QUE LAS QUE HAY EN BODEGA", "warning")
        } else if (parseInt(this.state.cantidadN) < 0) {
            swal("ERROR", "LA CANTIDAD A SALIR DEBE SER UN VALOR POSITIVO", "warning")
        } else if (!/^([0-9])*$/.test(this.state.cantidadN)) {
            swal("ERROR", "LA CANTIDAD DEBE SER UN VALOR ENTERO", "warning")
        } else if (parseInt(this.state.cantidadN) == 0) {
            this.setState({ productoSeleccinado: '', cantidadN: '', costoN: '' })
        } else if (ids.includes(parseInt(productof.id))) {
            pentrada.map((producto, key2) => {
                if (parseInt(producto.id) === parseInt(productof.id)) {
                    producto.saldo = producto.saldo + parseInt(this.state.cantidadN)
                }
            })
            psalida[this.state.posicionFoco].saldo = psalida[this.state.posicionFoco].saldo - this.state.cantidadN
        } else {
            pentrada.push({
                id: productof.id,
                descripcion: productof.descripcion,
                saldo: parseInt(this.state.cantidadN),
                plu: productof.plu
            })
            psalida[this.state.posicionFoco].saldo = psalida[this.state.posicionFoco].saldo - this.state.cantidadN
        }
        this.setState({ productosdeSalida: psalida, productosdeentrada: pentrada, productoSeleccinado: '', cantidadN: '', costoN: '' })
    }

    handleChangeCheck = name => event => {
        var estado = (event.target.value == 'false' ? false : true)
        this.setState({
            [name]: !estado
        });
        switch (name) {
            case 'checkSalidaDirecta':
                this.setState({ tipoSalida: 5, checkSalidaAveria: false, checkSalidaMerma: false })
                break;
            case 'checkSalidaAveria':
                this.setState({
                    tipoSalida: 6,
                    checkSalidaDirecta: false,
                    checkSalidaMerma: false,
                    bodegaSalidaSeleccionada: {},
                    productosdeentrada: [],
                    productosdeSalida: []

                })
                break;
            case 'checkSalidaMerma':
                this.setState({ tipoSalida: 11, checkSalidaAveria: false, checkSalidaDirecta: false })
                break;
        }

    }



    validarFormulario = () => {
        if (this.state.tipoSalida == 0 || this.state.tipoSalida == '') {
            swal("ERROR", "POR FAVOR ESCOJA EL TIPO DE SALIDA", "warning")
            return false
        } else if (this.state.observacionN.length == 0 || this.state.observacionN.trim() == '') {
            swal("ERROR", "ES NECESARIA LA OBSERVACION PARA LA SALIDA", "warning")
            return false
        } else if (this.state.productosdeentrada.length == 0 || this.state.observacionN.trim() == '') {
            swal("ERROR", "DEBE SACAR POR LO MENOS UN PRODUCTO", "warning")
            return false
        } else if (JSON.stringify(this.state.bodegaSalidaSeleccionada) == '{}' || this.state.bodegaSalidaSeleccionada == null) {
            swal("ERROR", "NO SE HA SELECCIONADO LA BODEGA", "warning")
            return false
        } else {
            this.setState({
                response: true
            })
            return true
        }
    }

    registrar = () => {
        var usuario = store.getState().usuario
        var validar = this.validarFormulario()
        let SALIDA_NORMAL = 5
        let DEVOLUCION_POR_AVERIA = 6
        let MERMA_EN_INVRNTARIO = 11
        var datos = {}
        switch (this.state.tipoSalida) {
            case SALIDA_NORMAL:
                datos = {
                    "movimiento": {
                        "empresas_id": parseInt(usuario.empresas_id),
                        "operacion": SALIDA_NORMAL,
                        "fecha": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        "consecutivo": 0,
                        "persona_id": usuario.p_id,
                        "persona_nit": "",
                        "persona_nombre": "",
                        "tercero_id": 1,
                        "tercero_nit": "",
                        "tercero_nombre": "",
                        "costo_total": 1,
                        "venta_total": 1,
                        "impuesto_total": 1,
                        "descuento_total": 0,
                        "origen_id": 1,
                        "impreso": "N",
                        "create_user": 1,
                        "create_date": "",
                        "remoto_id": 1,
                        "sincronizado": 1,
                        "movimiento_estado": 1,
                        "observaciones": this.state.observacionN
                    },
                    "bodega": {
                        "bodega_idI": this.state.bodegaSalidaSeleccionada.value,
                        "bodega_idF": null
                    },
                    "detalle":
                        this.state.productosdeentrada.map((producto) => {
                            return ({
                                "productos_id": producto.id,
                                "cantidad": producto.saldo,
                                "costo_producto": 0,
                                "precio": 0,
                                "descuento_id": 0,
                                "descuento_producto": 0,
                                "remoto_id": 0,
                                "sincronizado": 0,
                                "subtotal": 0
                            })
                        })

                }
                break;
            case DEVOLUCION_POR_AVERIA:
                datos = {
                    "movimiento": {
                        "empresas_id": parseInt(usuario.empresas_id),
                        "operacion": DEVOLUCION_POR_AVERIA,
                        "fecha": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        "consecutivo": 0,
                        "persona_id": usuario.p_id,
                        "persona_nit": "",
                        "persona_nombre": "",
                        "tercero_id": 1,
                        "tercero_nit": "",
                        "tercero_nombre": "",
                        "costo_total": 1,
                        "venta_total": 1,
                        "impuesto_total": 1,
                        "descuento_total": 0,
                        "origen_id": this.state.proveedorSeleccionado.value,
                        "impreso": "N",
                        "create_user": 1,
                        "create_date": "",
                        "remoto_id": 1,
                        "sincronizado": 1,
                        "movimiento_estado": 1,
                        "observaciones": this.state.observacionN
                    },
                    "bodega": {
                        "bodega_idI": this.state.bodegaSalidaSeleccionada.value,
                        "bodega_idF": null
                    },
                    "detalle":
                        this.state.productosdeentrada.map((producto) => {
                            return ({
                                "productos_id": producto.id,
                                "cantidad": producto.saldo,
                                "costo_producto": 0,
                                "precio": 0,
                                "descuento_id": 0,
                                "descuento_producto": 0,
                                "remoto_id": 0,
                                "sincronizado": 0,
                                "subtotal": 0
                            })
                        })

                }
                break;
            case MERMA_EN_INVRNTARIO:
                datos = {
                    "movimiento": {
                        "empresas_id": parseInt(usuario.empresas_id),
                        "operacion": MERMA_EN_INVRNTARIO,
                        "fecha": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        "consecutivo": 0,
                        "persona_id": usuario.p_id,
                        "persona_nit": "",
                        "persona_nombre": "",
                        "tercero_id": 1,
                        "tercero_nit": "",
                        "tercero_nombre": "",
                        "costo_total": 1,
                        "venta_total": 1,
                        "impuesto_total": 1,
                        "descuento_total": 0,
                        "origen_id": 1,
                        "impreso": "N",
                        "create_user": 1,
                        "create_date": "",
                        "remoto_id": 1,
                        "sincronizado": 1,
                        "movimiento_estado": 1,
                        "observaciones": this.state.observacionN
                    },
                    "bodega": {
                        "bodega_idI": this.state.bodegaSalidaSeleccionada.value,
                        "bodega_idF": null
                    },
                    "detalle":
                        this.state.productosdeentrada.map((producto) => {
                            return ({
                                "productos_id": producto.id,
                                "cantidad": producto.saldo,
                                "costo_producto": 0,
                                "precio": 0,
                                "descuento_id": 0,
                                "descuento_producto": 0,
                                "remoto_id": 0,
                                "sincronizado": 0,
                                "subtotal": 0
                            })
                        }),

                }
                break;
        }

        console.log(datos)
        if (validar) {
            Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/movimiento', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    console.log(response)
                    this.setState({
                        response: false
                    })
                    if (response.status === 201) {
                        swal({
                            title: response.data.message,
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "TRASLADO_BODEGA",
                                        trasladoBodega: false
                                    })
                                    //this.setState({loader:true,descripcionN:'',selectedDate:new Date(),selectedDateFin:new Date(),checkIPorcentaje:true,checkIValor:false,valorn:false,cambbioInicio: false,cambbioFin:false})
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
            console.log("Salio")
        }


    }

    //TIME
    componentDidMount() {
        this.intervalId = setInterval(this.timer.bind(this), 300000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    timer() {
        this.setState({
            currentCount: this.state.currentCount - 1
        })
        if (this.state.currentCount < 1) {
            clearInterval(this.intervalId);
        }
        this.actualizarToken()
    }

    actualizarToken = () => {
        Axios.get(Server.hostPruebasinssl + Server.puertoAutenticacion, { headers: { Authorization: ntoken } })
            .then(response => {
                if (response.status === 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 206) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 200) {
                    //RESPEUSTA OK
                }
            })
    }


    cancelar = () => {
        store.dispatch({
            type: "TRASLADO_BODEGA",
            trasladoBodega: false
        })
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        switch (name) {
            case 'bodegaSalidaSeleccionada':
                var padres = store.getState().empresasPadres
                if (this.state.checkSalidaAveria) {
                    Axios.get(Server.hostPrueba + Server.puertoInventario + '/api/bodegas/proveedores/' + value.value + '/' + padres, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            var proveedores = []
                            if (res.status === 200) {
                                if (res.data != null) {
                                    res.data.map((proveedor, key) => {
                                        console.log(proveedor)
                                        if (proveedor.productos.length > 0) {
                                            proveedores.push({
                                                value: proveedor.id,
                                                label: proveedor.proveedor_nombre,
                                                productos: proveedor.productos
                                            })
                                        }
                                    })
                                }
                            }
                            this.setState({
                                proveedoresbd: proveedores,
                                productosdeSalida: [],
                                productosdeentrada: [],
                                proveedorSeleccionado: {}
                            })
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } else {
                    Axios.get(Server.hostPrueba + Server.puertoInventario + '/api/bodegas/productos/' + value.value, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            console.log(res)
                            if (res.data != null) {
                                var productostotal = []
                                res.data.map((producto) => {
                                    productostotal.push(producto.productos)
                                })
                                this.setState({ productosdeSalida: productostotal, productosdeentrada: [] })
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                break;
            case 'proveedorSeleccionado':
                this.setState({
                    productosdeSalida: value.productos,
                    productosdeentrada: []
                })
                break;
        }
    };


    render() {
        return (
            <div>
                <AppBar style={{ backgroundColor: colorDominio }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Close"
                            onClick={this.cancelar.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            style={{ marginLeft: "theme.spacing(2)", flex: 1, color: '#fff' }}
                        >
                            {vistaUsuario ? 'Edicion de PRODUCTO' : 'SALIDAS DE INVENTARIO'}
                        </Typography>
                        {
                            vistaUsuario
                                ?
                                <Button style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} /*onClick={this.editarProducto.bind(this)}*/>
                                    GUARDAR
              </Button>
                                :
                                <Button style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} disabled={this.state.response} onClick={this.registrar.bind(this)}>
                                    TERMINAR Y GUARDAR
              </Button>
                        }

                    </Toolbar>
                </AppBar>
                <div className="row align-middle mx-auto" style={{ marginTop: '7%' }}>
                    <div className="col col-md-12">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label htmlFor="numordencompra" className="col-form-label">Fecha</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DatePicker
                                                disabled={true}
                                                format="yyyy-MM-dd HH:mm"
                                                id="datetime"
                                                value={this.state.selectedDate}
                                                //onChange={this.handleChangemoment}
                                                onChange={this.handleChangemoment}
                                                className="rounded form-control"
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={this.state.checkSalidaAveria ? "col-md-4" : "col-md-8"}>
                                        <label htmlFor="numordencompra" className="col-form-label">Bodega de salida</label>
                                        <Selects
                                            placeholder="Seleccione"
                                            value={this.state.bodegaSalidaSeleccionada}
                                            onChange={this.handleChanges('bodegaSalidaSeleccionada')}
                                            options={this.state.bodegasbd}
                                        />
                                    </div>
                                    {this.state.checkSalidaAveria
                                        ?
                                        <div className="col-md-4">
                                            <label htmlFor="numordencompra" className="col-form-label">Proveedor</label>
                                            <Selects
                                                placeholder="Seleccione"
                                                value={this.state.proveedorSeleccionado}
                                                onChange={this.handleChanges('proveedorSeleccionado')}
                                                options={this.state.proveedoresbd}
                                            />
                                        </div>
                                        :
                                        null}
                                </div>
                                <div className="row mx-auto">
                                    <h5 className="mx-auto">Ingrese los datos del producto a salir</h5>
                                </div>
                                <div className="row">
                                    <div className="col-md-7">
                                        <label htmlFor="numordencompra" className="col-form-label">PRODUCTO</label>
                                        <input id="numordencompra" value={this.state.productoSeleccinado} className="form-control" type="text" disabled={true} />
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="numordencompra" className="col-form-label">Cantidad</label>
                                        <input id="numordencompra" value={this.state.cantidadN} className="form-control" type="number" min="1" onChange={this.handleChange('cantidadN')} />
                                    </div>
                                    <div className="col-md-2 bottom-aligned">
                                        <button className="btn btn-primary bottom-aligned" disabled={this.state.productoSeleccinado == '' || this.state.cantidadN == ''} onClick={() => this.agregarProducto()}>SALIDA</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="row mx-auto">
                                    <div className="col-md-10 bottom-aligned">
                                        <FormControlLabel
                                            control={<Checkbox value={this.state.checkSalidaDirecta} checked={this.state.checkSalidaDirecta} style={{ padding: '5px' }} onChange={this.handleChangeCheck('checkSalidaDirecta')} />}
                                            label='SALIDA DIRECTA'
                                            style={{ margin: '0' }}
                                        />
                                    </div>
                                </div>
                                <div className="row mx-auto">
                                    <div className="col-md-10 bottom-aligned">
                                        <FormControlLabel
                                            control={<Checkbox value={this.state.checkSalidaAveria} checked={this.state.checkSalidaAveria} style={{ padding: '5px' }} onChange={this.handleChangeCheck('checkSalidaAveria')} />}
                                            label='SALIDA POR AVERIA'
                                            style={{ margin: '0' }}
                                        />

                                    </div>
                                </div>
                                <div className="row mx-auto">
                                    <div className="col-md-10 bottom-aligned">
                                        <FormControlLabel
                                            control={<Checkbox value={this.state.checkSalidaMerma} checked={this.state.checkSalidaMerma} style={{ padding: '5px' }} onChange={this.handleChangeCheck('checkSalidaMerma')} />}
                                            label='MERMA EN INVENTARIO'
                                            style={{ margin: '0' }}
                                        />

                                    </div>
                                </div>
                                <div className="row bottom-aligned">
                                    <div className="col-md-12">
                                        <label htmlFor="numordencompra" className="col-form-label">Observaciones</label>
                                        <textarea name="comment" className="form-control" rows="2" value={this.state.observacionN} onChange={this.handleChange('observacionN')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.productosdeSalida.length == 0
                            ? null :
                            <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                <div className="col col-md-6 mx-auto">
                                    <h5 className="mx-auto">{this.state.productosdeSalida.length == 0 ? null : 'PRODUCTOS EN BODEGA'}</h5>
                                    <MDBTable bordered striped>
                                        <MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
                                            <tr>
                                                <th>Producto</th>
                                                <th>Plu</th>
                                                <th >Cantidad</th>
                                                <th >Acciones</th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {this.state.productosdeSalida.map((row, key) => {
                                                if (parseInt(row.saldo) != 0) {
                                                    return (
                                                        <tr key={key}>
                                                            <td component="th" scope="row">
                                                                {row.descripcion}
                                                            </td>
                                                            <td>{row.plu}</td>
                                                            <td>{row.saldo}</td>
                                                            <td>{<button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.sacarProducto(key)}><SacarIcon /></button>}</td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </MDBTableBody>
                                    </MDBTable>
                                </div>
                                <div className="col col-md-6 mx-auto">
                                    <h5 className="mx-auto">PRODUCTOS A SALIR</h5>
                                    <MDBTable bordered striped>
                                        <MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
                                            <tr>
                                                <th>Producto</th>
                                                <th>Plu</th>
                                                <th >Cantidad</th>
                                                <th >Acciones</th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {this.state.productosdeentrada.map((row, key) => (
                                                <tr key={key}>
                                                    <td component="th" scope="row">
                                                        {row.descripcion}
                                                    </td>
                                                    <td>{row.plu}</td>
                                                    <td>{row.saldo}</td>
                                                    <td>{<button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.devolvrProducto(key)}><DevolverIcon /></button>}</td>
                                                </tr>
                                            ))}
                                        </MDBTableBody>
                                    </MDBTable>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div >



        )
    }
}


export default MediosPago
