import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { MDBDataTable } from 'mdbreact';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Slide from "@material-ui/core/Slide"
import store from '../../../lib/store'
import FormularioAcuerdo from '../../../components/Formularios/FormularioAcuerdo'
import EditICon from '@material-ui/icons/Edit';
import Ojoicon from "@material-ui/icons/RemoveRedEye"
import moment from 'moment';
import 'moment/locale/es';
import { columnasAcuerdos, getDetallesAcuerdo, getAcuerdos } from './helperAcuerdos';
import Loader from '../../../components/Loader'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const idModulo = 8
let permisos = ''
const ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class Acuerdos extends Component {
    constructor() {
        super();
        this.state = {
            acuerdosbd: [],
            ventanaAcuerdo: false,
            contrato: null,
            modovista: false,
            vistaAcuerdo: true,
            loader: true
        }
        store.subscribe(() => {
            this.setState({
                ventanaAcuerdo: store.getState().ventanaAcuerdo,
                actualizarDatos: store.getState().actualizarvista,
            })
        })
    }

    componentWillMount() {
        //Permisos usuarios
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        this.obtenerDatos()
    }

    componentWillReceiveProps() {
        if (this.state.actualizarDatos) {
            this.obtenerDatos()
        }
    }

    setearMiles = (numero) => {
        return (
            (new Intl.NumberFormat("de-DE").format(parseInt(numero)))
        )
    }


    obtenerDatos = async () => {
        const { usuario, dominio, empresasHijos } = store.getState()
        let datosBuscar = dominio
        switch (usuario.tipo) {
            case "1":
            case "2":
                datosBuscar = empresasHijos
                break;
            case "3":
                datosBuscar = usuario.empresas_id
                break;
            case "4":
            case "5":
                datosBuscar = dominio
                break;
        }
        this.setState({
            acuerdosbd: await getAcuerdos(datosBuscar),
            actualizarDatos: false,
            contrato: null,
            modovista: false,
            loader: false
        })
    }


    crearAcuerdo = () => {
        this.setState({ contrato: null, modovista: false })
        store.dispatch({
            type: "CREACION_ACUERDO",
            ventanaAcuerdo: true
        })
    }



    vistaAcuerdo = () => {
        this.setState({
            vistaAcuerdo: !this.state.vistaAcuerdo
        })
    }

    detallesAcuerdos = async (id, metodo) => {
        this.setState({
            loader: true
        })
        this.setState({
            contrato: await getDetallesAcuerdo(id),
            modovista: metodo,
            loader: false
        })
        store.dispatch({
            type: "CREACION_ACUERDO",
            ventanaAcuerdo: true
        })
    }


    render() {
        const { acuerdosbd, loader } = this.state
        return (
            <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                {this.state.ventanaAcuerdo ? <button className="btn btn-danger" onClick={this.crearAcuerdo}>Cancelar</button> : permisos.includes('C') ? <button className="btn btn-success" onClick={this.crearAcuerdo}>Crear nuevo acuerdo</button> : null}
                {this.state.ventanaAcuerdo ? <Dialog fullScreen open={this.state.ventanaAcuerdo} onClose={this.crearAcuerdo} TransitionComponent={Transition}>
                    <FormularioAcuerdo contratobd={this.state.contrato} modoVista={this.state.modovista} />
                </Dialog> : null}

                {
                    loader
                        ? <Loader />
                        : <div className="row" >
                            {permisos.includes('R') ? <div className="col col-md-12">
                                <Paper style={{ padding: '10px', marginTop: '15px' }}>
                                    <MDBDataTable
                                        className="col-md-12"
                                        searchLabel="Buscar"
                                        paginationLabel={["Anterior", "Siguente"]}
                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                        entriesLabel="Filas a mostrar"
                                        tbodyColor="primary-color"
                                        theadColor="#FF8800"
                                        exportToCSV={true}
                                        striped
                                        bordered
                                        autoWidth={false}
                                        responsive={true}
                                        hover
                                        style={{ fontSize: '13px' }}
                                        data={{
                                            columns: columnasAcuerdos,
                                            rows: acuerdosbd.map((acuerdo) => {
                                                return {
                                                    'codigo': acuerdo.contrato.numero_contrato,
                                                    'nombre': acuerdo.contrato.descripcion,
                                                    'fecha_inicio': moment(acuerdo.contrato.fecha_inicio).format("DD/MM/YYYY hh:mm a"),
                                                    'fecha_fin': moment(acuerdo.contrato.fecha_fin).format("DD/MM/YYYY hh:mm a"),
                                                    'responsable': acuerdo.responsable[0].nombres + " " + acuerdo.responsable[0].apellidos,
                                                    'proveedor': acuerdo.proveedores[0].nombres + " " + acuerdo.proveedores[0].apellidos,
                                                    "acciones": [
                                                        <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesAcuerdos(acuerdo.contrato.id, true)}><Ojoicon /></button>,
                                                        permisos.includes('U') ? <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.detallesAcuerdos(acuerdo.contrato.id, false)}><EditICon /></button> : null
                                                    ]
                                                }
                                            })
                                        }}
                                    />
                                </Paper>
                            </div> : <h5>USTED NO TIENE PERMISOS DE VER LOS DATOS</h5>}
                        </div>

                }
            </div>
        )
    }
}



export default Acuerdos;