import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react'
import CardUsuario from '../../../components/Card/card/cardUsuario'
import Axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import store from '../../../lib/store'
import Switch from '@material-ui/core/Switch';
import { MDBDataTable } from 'mdbreact';
import "../../../components/Card/card/cardUsuario.css"
import FormularioUsuario from '../../../components/Formularios/FormularioCampania'
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide"
import Paper from '@material-ui/core/Paper';
import Loader from '../../../components/Loader'
import Server from '../../../lib/server'
import { Avatar } from '@material-ui/core';
import swal from 'sweetalert'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from "@material-ui/core";
import DialogIdentificador from '../../../components/Formularios/dialogs/dialogIdentificador'
import DialogContent from '@material-ui/core/DialogContent';
import ProdcutoIcon from "@material-ui/icons/Fastfood"
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker, TimePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Selects from 'react-select';
import moment from 'moment'
import "moment/locale/es";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import ActivarIcon from "@material-ui/icons/Check"
import IconButton from "@material-ui/core/IconButton";
import EditICon from '@material-ui/icons/Edit';
import checksum from 'checksum'
var busquedaUsuario = []
var datosMostrarUsuario = []
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const idModulo = 44
var permisos = ''
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class usuariosVista extends Component {
    constructor() {
        super();
        this.state = {
            createUSer: false,
            setOpen: false,
            userdetails: -1,
            users: [],
            usersByName: [],
            tabla: false,
            busqueda: false,
            value: 0,
            ventanaEdicion: false,
            usuarioEdicion: null,
            //Busqueda de datos
            busqueda: false,
            paginadorIndex: 0,
            paginadorMinimo: 0,
            paginadorMaximo: 15,
            paginadorTotal: 16,
            //
            animacionIzquierda: false,
            animacionDerecha: false,
            loader: false,
            actualizacionVista: false,
            carnetUsuario: '',
            mostrarCarnet: false,
            detallesCarnet: null,
            //FORM
            expanded: false,
            productosbd: [],
            nombreGuardar: '',
            precio: 0,
            plu: '',
            productosBonosbd: [],
            productoSeleccionado: {},
            identificadores: [],
            dialogIDentificafor: false,
            modovista: false,
            modoEditar: false,
            idEditar: '',
            response: false

        }
        store.subscribe(() => {
            this.setState({
                actualizacionVista: store.getState().actualizarvista,
                identificadores: store.getState().codigos,
                dialogIDentificafor: store.getState().creacionCodigo,
            })
        })
    }

    cancelar = () => {
        this.setState({
            expanded: false,
            nombreGuardar: '',
            precio: 0,
            plu: '',
            productoSeleccionado: {},
            identificadores: [],
            modovista: false,
            modoEditar: false,
            idEditar: '',
            dialogDetalles: false
        })
        store.dispatch({
            type: "AGREGAR_IDENTIFICADOR",
            codigos: [],
            creacionCodigo: false

        })
    }

    verDetalles = (producto) => {
        var identificadoresbb = []
        if (producto.identificador != null) {
            producto.identificador.map((codigo) => {
                if (codigo.estado === "A") {
                    identificadoresbb.push({ codigos: codigo.identificador })
                }
            })
        }
        store.dispatch({
            type: "AGREGAR_IDENTIFICADOR",
            codigos: identificadoresbb,
            creacionCodigo: false

        })
        this.setState({
            expanded: true,
            modovista: true,
            modoEditar: false,
            nombreGuardar: producto.descripcion,
            precio: producto.precio,
            plu: producto.plu,
            identificadores: identificadoresbb
        })
    }

    verEditar = (producto) => {
        var identificadoresbb = []
        if (producto.identificador != null) {
            producto.identificador.map((codigo) => {
                if (codigo.estado === "A") {
                    identificadoresbb.push({ codigos: codigo.identificador })
                }
            })
        }
        store.dispatch({
            type: "AGREGAR_IDENTIFICADOR",
            codigos: identificadoresbb,
            creacionCodigo: false

        })
        this.setState({
            expanded: true,
            modovista: false,
            modoEditar: true,
            nombreGuardar: producto.descripcion,
            precio: producto.precio,
            plu: producto.plu,
            identificadores: identificadoresbb,
            idEditar: producto._id
        })
    }

    validarProducto = () => {
        if (this.state.nombreGuardar.trim == "" || this.state.nombreGuardar.length == 0) {
            swal("Fallo al crear", "EL NOMBRE ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.plu.trim == "" || this.state.plu.length == 0) {
            swal("Fallo al crear", "EL PLU ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.precio.trim == "" || this.state.precio.length == 0) {
            swal("Fallo al crear", "EL PLU ES UN PRECIO OBLIGATORIO", "warning")
            return false
        } else if (!/^([0-9])*$/.test(this.state.plu)) {
            swal("FALLO AL CREAR", "EL PLU DEBE SER UN ENTERO POSITIVO", "warning")
            return false
        } else {
            this.setState({
                response: true
            })
            return true
        }
    }

    registrarProducto = () => {
        var validar = false
        validar = this.validarProducto()
        var usuario = store.getState().usuario
        var datos = {

            "empresas_id": usuario.empresas_id,
            "plu": parseInt(this.state.plu),
            "descripcion": this.state.nombreGuardar,
            "precio": this.state.precio,
            "create_user": parseInt(usuario.p_id),
            "identificadores": this.state.identificadores.map((identificador) => {
                return (
                    {
                        "identificador": identificador.codigos
                    }
                )
            })

        }
        if (validar) {
            console.log(datos)
            Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/productos', datos, { headers: { Authorization: ntoken, key: Server.server_key } })
                .then((res) => {
                    console.log(res)
                    this.setState({
                        peticion: false,
                        response: false
                    })
                    if (res.status === 200) {
                        if (res.data.response.success) {
                            swal({
                                title: res.data.response.message,
                                icon: "success",
                                closeOnClickOutside: false,
                                buttons: {
                                    Seguir: {
                                        text: "OK",
                                        value: "continuar"
                                    }
                                },
                            }).then((value) => {
                                switch (value) {

                                    case "continuar":
                                        store.dispatch({
                                            type: "ACTUALIZACION_DATOS",
                                            actualizarvista: true
                                        })
                                        this.cancelar()
                                        break;

                                    default:

                                }
                            });
                        } else {
                            swal("Fallo al guardar", res.data.response.message, "warning");
                        }
                    } else if (res.status === 205) {
                        swal("ERROR", "LA SESION HA CADUCADO", "warning")
                        setTimeout(function () { window.location.href = '/'; }, 3000);
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                    }
                })
                .catch((err) => {
                    swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                })
        }
    }

    editarProducto = () => {
        var validar = false
        validar = this.validarProducto()
        var usuario = store.getState().usuario
        var datos = {
            "_id": this.state.idEditar,
            "empresas_id": usuario.empresas_id,
            "plu": parseInt(this.state.plu),
            "descripcion": this.state.nombreGuardar,
            "precio": this.state.precio,
            "create_user": parseInt(usuario.p_id),
            "identificadores": this.state.identificadores.map((identificador) => {
                return (
                    {
                        "identificador": identificador.codigos
                    }
                )
            })

        }
        if (validar) {
            console.log(datos)
            Axios.put(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/productos', datos, { headers: { Authorization: ntoken, key: Server.server_key } })
                .then((res) => {
                    this.setState({
                        peticion: false,
                        response: false
                    })
                    if (res.status === 200) {
                        if (res.data.response.success) {
                            Axios.get(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/bonos/productos_id/' + this.state.idEditar, { headers: { Authorization: ntoken, key: Server.server_key } })
                                .then(response => {
                                    var bonosb = []
                                    //console.log(response)  
                                    //console.log(this.state.identificadores[0])  
                                    if (response.status == 200) {
                                        if (response.data.data != null) {
                                            response.data.data.map((arr) => {
                                                console.log(arr)
                                                if (arr.bono.codigo_barras != null) {
                                                    arr.bono.codigo_barras = this.state.identificadores[0].codigos
                                                }
                                                bonosb.push(arr.bono)
                                            })
                                        }
                                        console.log(bonosb)
                                        if (bonosb != null) {
                                            bonosb.map((bono) => {
                                                var usuario = store.getState().usuario
                                                var datos = {
                                                    "_id": bono._id,
                                                    "descripcion": bono.descripcion,
                                                    "empresas_id": bono.empresas_id,
                                                    "productos_id": bono.productos_id,
                                                    "codigo_barras": bono.codigo_barras,
                                                    "create_user": parseInt(usuario.p_id),
                                                    "encabezado": bono.encabezado
                                                }
                                                console.log(datos)
                                                Axios.put(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/bonos', datos, { headers: { Authorization: ntoken, key: Server.server_key } })
                                                    .then((res) => {
                                                        if (res.status === 200) {
                                                            console.log(res)
                                                        } else if (res.status === 205) {
                                                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                                            setTimeout(function () { window.location.href = '/'; }, 3000);
                                                        } else {
                                                            swal("Fallo al editar los bonos de este producto", "Por favor verifique los datos", "warning");
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        swal("Fallo al editar los bonos de este producto", "Por favor verifique los datos", "warning");
                                                    })

                                            })
                                        }
                                    } else if (response.status == 205) {
                                        swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                        setTimeout(function () { window.location.href = '/'; }, 3000);
                                    }
                                    //this.setState({ productosbd: bonosb })
                                })
                            swal({
                                title: res.data.response.message,
                                icon: "success",
                                closeOnClickOutside: false,
                                buttons: {
                                    Seguir: {
                                        text: "OK",
                                        value: "continuar"
                                    }
                                },
                            }).then((value) => {
                                switch (value) {

                                    case "continuar":
                                        store.dispatch({
                                            type: "ACTUALIZACION_DATOS",
                                            actualizarvista: true
                                        })
                                        this.cancelar()
                                        break;

                                    default:

                                }
                            });

                        } else {
                            swal("Fallo al guardar", res.data.response.message, "warning");
                        }
                    } else if (res.status === 205) {
                        swal("ERROR", "LA SESION HA CADUCADO", "warning")
                        setTimeout(function () { window.location.href = '/'; }, 3000);
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                    }
                })
                .catch((err) => {
                    swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                })
        }
    }


    inactivarProducto = (id) => {
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        var usuario = store.getState().usuario
        var data = {
            "_id": this.toCheckSum(id+""),
            "estado": "I",
            "create_user": this.toCheckSum(usuario.p_id+"")
        }
        swal({
            title: "Estas seguro de que quieres inactivar este producto?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.put(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/productos/switch', data, { headers: { Authorization: ntoken, key: Server.server_key } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 200) {
                                swal({
                                    title: "PRODUCTO INACTIVADO CORRECTAMENTE",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            /*store.dispatch({
                                                type: "ACTUALIZACION_DATOS",
                                                actualizarvista: true
                                            })*/
                                            this.obtenerDatos()
                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            } else {
                                swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }

    activarProducto = (id) => {
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        var usuario = store.getState().usuario
        var data = {
            "_id": this.toCheckSum(id+""),
            "estado": "A",
            "create_user": this.toCheckSum(usuario.p_id+"")
        }
        swal({
            title: "Estas seguro de que quieres activar este producto?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.put(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/productos/switch ', data, { headers: { Authorization: ntoken, key: Server.server_key } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 200) {
                                swal({
                                    title: "PRODUCTO ACTIVADO CORRECTAMENTE",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            /*store.dispatch({
                                                 type: "ACTUALIZACION_DATOS",
                                                 actualizarvista: true
                                             })*/
                                            this.obtenerDatos()
                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            } else {
                                swal("Fallo al activar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }



    setearMiles = (numero) => {
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case "COP":
                return (
                    (new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(2)))
                )
                break;
            case "USD":
                return (new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(numero))
                break;

        }
    }


    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });

    };

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
    }


    handledialogIdentificador = () => {
        store.dispatch({
            type: "AGREGAR_IDENTIFICADOR",
            codigos: this.state.identificadores,
            creacionCodigo: !this.state.dialogIDentificafor

        })
    }

    expandir = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    handleUsername(e) {
        let s = ""
        s = e.target.value
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizacionVista) {
            console.log("desde vista")
            this.obtenerDatos()
            return true
        }
        return true;
    }


    obtenerDatos = () => {
        //this.setState({ actualizacionVista: false })
        store.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: false
        })
        var datos = store.getState().usuario
        var hijos = store.getState().empresasHijos
        switch (datos.tipo) {
            case "1":
            case "2":
            case "3":
            case "4":
                Axios.get(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/productos/empresas_id/' + datos.empresas_id, { headers: { Authorization: ntoken } })
                    .then(response => {
                        var bonosb = []
                        console.log(response)
                        if (response.status == 200) {
                            if (response.data.data != null) {
                                bonosb = response.data.data
                                /*response.data.data.map((bn) => {
                                    bonosb.push(bn.productos)
                                })*/
                            }
                        } else if (response.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }
                        this.setState({ productosbd: bonosb })
                    })
                break;
            case "5":
                Axios.get(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/empresas_final/productos/' + datos.empresas_id, { headers: { Authorization: ntoken } })
                    .then(response => {
                        var bonosb = []
                        console.log(response)
                        if (response.status == 200) {
                            if (response.data.data != null) {
                                bonosb = response.data.data
                            }
                        } else if (response.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }
                        this.setState({ productosbd: bonosb })
                    })
                break;
        }

    }

    toCheckSum = (id) => {
        try {
            let str1 = checksum(id);
            str1 += ' ' + Buffer.from(id).toString('base64')
            return str1;
        } catch (error) {
            console.log(error)
            return '';
        }
    }
    componentWillMount() {
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        this.obtenerDatos()
    }


    buscar = (name) => event => {
        var cadena = event.target.value.toUpperCase()
        if (cadena.length > 0) {
            this.setState({ busqueda: true })
        } else {
            this.setState({ busqueda: false })
        }
        busquedaUsuario = []
        this.state.productosbd.map((tipo, key) => {
            if (tipo.descripcion.includes(cadena) || tipo.plu.includes(cadena)) {
                busquedaUsuario.push(tipo)
            } else {

            }
        })
    }


    pagination = (name, str) => {
        switch (name) {
            case 'tipo':
                this.setState({ animacionDerecha: false, animacionIzquierda: false })
                if (str == 'S') {
                    this.setState({ animacionDerecha: true, animacionIzquierda: false, paginadorIndex: this.state.paginadorIndex + 1, paginadorMinimo: this.state.paginadorMaximo + 1, paginadorMaximo: this.state.paginadorMaximo + this.state.paginadorTotal })
                } else {
                    this.setState({ animacionDerecha: false, animacionIzquierda: true, paginadorIndex: this.state.paginadorIndex - 1, paginadorMinimo: (this.state.paginadorMinimo - this.state.paginadorTotal), paginadorMaximo: this.state.paginadorMinimo - 1 })
                }
                this.datos()
                break;
        }
    }

    datos = () => {
        datosMostrarUsuario = []
        for (var i = this.state.paginadorMinimo; i <= this.state.paginadorMaximo; i++) {
            if (this.state.users[i] != null) {
                datosMostrarUsuario.push(this.state.users[i])
            } else {
                //console.log(this.state.tipoEquipo[i])
            }
        }
        //console.log("Minimno:" + this.state.paginadorMinimo + " - maximo:" + this.state.paginadorMaximo)
    }



    render() {
        this.datos()
        var data = {}
        var dominio = store.getState().dominio
        var usuario = store.getState().usuario
        const imagen = Server.hostMultimedia + dominio + "/persona/default.png"
        const { value } = this.state;
        var identificadores = '';
        this.state.identificadores.map((contacto) => {
            identificadores += contacto.codigos + ", "
        })
        identificadores = identificadores.substring(0, identificadores.length - 2)
        return (
            this.state.loader
                ?
                <div style={{ height: '100vh', width: '100%', paddingLeft: '15px', paddingRight: '15px' }} className="mx-auto my-auto">
                    <Loader style={{ height: '100vh', width: '100%' }} />
                </div>
                :
                <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>

                    <div className="row">
                        {
                            permisos.includes('C')
                                ?
                                <ExpansionPanel expanded={this.state.expanded} className="col-md-12" style={{ zIndex: 1098 }} onChange={() => this.expandir()}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography >CREAR NUEVO PRODUCTO</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div style={{ width: '100%' }}>

                                            <div className="row mx-auto">
                                                <div className="col-md-2">
                                                    <label htmlFor="numordencompra" className="col-form-label">PLU</label>
                                                    <input autocomplete="off" id="precioMinimoNuevo" disabled={this.state.modovista} className="form-control" type="number" min="1" value={this.state.plu} onChange={this.handleChange('plu')} />
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <label htmlFor="numordencompra" className="col-form-label">NOMBRE DEL PRODUCTO</label>
                                                    <input autocomplete="off" disabled={this.state.modovista} id="numordencompra" value={this.state.nombreGuardar} onChange={this.handleChange('nombreGuardar')} className="form-control" type="text" />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="numordencompra" className="col-form-label">PRECIO</label>
                                                    <div class="input-group " style={{ marginBottom: '0!important' }}>
                                                        <div class="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                                            <span class="input-group-text" id="basic-addon1">$</span>
                                                        </div>
                                                        <input autocomplete="off" disabled={this.state.modovista} id="precioMinimoNuevo" className="form-control" type="number" min="1" value={this.state.precio} onChange={this.handleChange('precio')} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mx-auto  bottom-aligned">
                                                <div className="col-md-6">
                                                    <label htmlFor="numordencompra" className="col-form-label">CODIGOS DE BARRA</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1">#</span>
                                                        </div>
                                                        <input autocomplete="off" type="text" class="form-control" aria-describedby="basic-addon1" value={identificadores} disabled={true} onClick={this.handledialogIdentificador} />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-primary" disabled={this.state.modovista} type="button" onClick={this.handledialogIdentificador} >+</button>
                                                        </div>
                                                    </div>
                                                    <Dialog
                                                        open={this.state.dialogIDentificafor}
                                                        onClose={this.handledialogIdentificador}
                                                        aria-labelledby="form-dialog-title"
                                                        maxWidth={'sm'}
                                                        style={{ padding: '0' }}
                                                        fullWidth={true}
                                                        disableBackdropClick={true}
                                                    >
                                                        <DialogContent style={{ padding: '0' }}>
                                                            <DialogIdentificador codigosn={this.state.identificadores} />
                                                        </DialogContent>

                                                    </Dialog>
                                                </div>
                                                {
                                                    this.state.modovista
                                                        ?
                                                        <div className="col-md-6">
                                                            <button className="btn btn-danger btn-block" onClick={() => this.cancelar()}>CANCELAR</button>
                                                        </div>
                                                        :
                                                        <div className="col-md-3">
                                                            <button className="btn btn-danger btn-block" onClick={() => this.cancelar()}>CANCELAR</button>
                                                        </div>
                                                }
                                                {
                                                    this.state.modovista
                                                        ?
                                                        null
                                                        :
                                                        <div className="col-md-3">
                                                            <button className="btn btn-success btn-block" onClick={this.state.modoEditar ? () => this.editarProducto() : () => this.registrarProducto()} disabled={this.state.response}>GUARDAR</button>
                                                        </div>
                                                }

                                            </div>

                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                :
                                null
                        }
                    </div>
                    <Dialog fullScreen open={this.state.createUSer} onClose={this.handleClickOpen} TransitionComponent={Transition}>
                        <FormularioUsuario userEdit={this.state.usuarioEdicion} />
                    </Dialog>
                    {permisos.includes('R') ? this.state.tabla ?
                        <div className="row" style={{ marginTop: "15px", marginTop: '15px' }}>
                            <div className="col col-md-12" style={{ padding: 0 }}>
                                <Paper style={{ padding: '10px' }}>

                                    <MDBDataTable
                                        //striped
                                        className="col-md-12"
                                        searchLabel="Buscar"
                                        paginationLabel={["Anterior", "Siguente"]}
                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                        entriesLabel="Filas a mostrar"
                                        tbodyColor="primary-color"
                                        theadColor="#FF8800"
                                        exportToCSV={true}
                                        striped
                                        bordered
                                        autoWidth={false}
                                        responsive={true}
                                        hover
                                        data={data}
                                    />
                                </Paper>
                            </div>
                        </div>

                        :
                        <div>
                            <div className="row mx-auto" style={{ marginTop: '15px' }}>
                                <button disabled={this.state.paginadorMinimo == 0} className="btn btn-sm btn-outline-primary mx-auto" onClick={() => this.pagination('tipo', 'N')}>{"<<"}</button>
                                <div className="col col-md-6 mx-auto">
                                    <input type="text" placeholder="Buscar" className="form-control mx-auto" onChange={this.buscar('tipo')} />
                                </div>
                                <button disabled={this.state.paginadorMaximo + 1 >= this.state.users.length} className="btn btn-sm btn-outline-primary mx-auto" onClick={() => this.pagination('tipo', 'S')}>{">>"}</button>
                            </div>
                            <div className="row" style={{ marginTop: '15px' }}>
                                {this.state.busqueda
                                    ?

                                    busquedaUsuario.map((producto, key) => {
                                        return (
                                            <div className="col-md-6">
                                                <List className="border" style={{ backgroundColor: "#fff", padding: '0', height: '100px', marginTop: '10px' }} className="border rounded">
                                                    <ListItem >
                                                        <ListItemAvatar style={{ cursor: 'pointer' }} onClick={() => this.verDetalles(producto)}>
                                                            <Avatar className={producto.estado === "A" ? "succes" : ""} ><ProdcutoIcon /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={producto.descripcion}
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="textPrimary"
                                                                    >
                                                                        {'PLU:' + producto.plu}
                                                                    </Typography>
                                                                    {'$ ' + this.setearMiles(producto.precio)}
                                                                </React.Fragment>
                                                            }
                                                        />
                                                        {permisos.includes('D') ?
                                                            producto.estado === "A" ?
                                                                <ListItemSecondaryAction>
                                                                    <IconButton aria-label="Update" onClick={() => this.verEditar(producto)}>
                                                                        <EditICon />
                                                                    </IconButton>
                                                                    <IconButton aria-label="Delete" onClick={() => this.inactivarBono(producto._id)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                                :
                                                                <ListItemSecondaryAction>
                                                                    <IconButton aria-label="Update" onClick={() => this.verEditar(producto)}>
                                                                        <EditICon />
                                                                    </IconButton>
                                                                    <IconButton aria-label="Delete" onClick={() => this.activarBono(producto._id)}>
                                                                        <ActivarIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            : null
                                                        }
                                                    </ListItem>

                                                </List>
                                            </div>
                                        )
                                    })

                                    :
                                    this.state.productosbd.map((producto, key) => {
                                        return (
                                            <div className="col-md-6">
                                                <List className="border" style={{ backgroundColor: "#fff", padding: '0', height: '100px', marginTop: '10px' }} className="border rounded">
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className={producto.estado === "A" ? "succes" : ""} style={{ cursor: 'pointer' }} onClick={() => this.verDetalles(producto)}><ProdcutoIcon /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={producto.descripcion}
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="textPrimary"
                                                                    >

                                                                        {'PLU:' + producto.plu}
                                                                    </Typography>
                                                                    {'$ ' + this.setearMiles(producto.precio)}
                                                                </React.Fragment>
                                                            }
                                                        />
                                                        {permisos.includes('D') ?
                                                            producto.estado === "A" ?
                                                                <ListItemSecondaryAction>
                                                                    <IconButton aria-label="Update" onClick={() => this.verEditar(producto)}>
                                                                        <EditICon />
                                                                    </IconButton>
                                                                    <IconButton aria-label="Delete" onClick={() => this.inactivarProducto(producto._id)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                                :
                                                                <ListItemSecondaryAction>
                                                                    <IconButton aria-label="Update" onClick={() => this.verEditar(producto)}>
                                                                        <EditICon />
                                                                    </IconButton>
                                                                    <IconButton aria-label="Delete" onClick={() => this.activarProducto(producto._id)}>
                                                                        <ActivarIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            : null
                                                        }
                                                    </ListItem>

                                                </List>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                        : <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>}
                </div>
        );
    }
}



export default usuariosVista;
