import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete';
import EditICon from '@material-ui/icons/Edit';
import Server from '../../../lib/server'
import store from '../../../lib/store'
import Paper from '@material-ui/core/Paper';
import Activar from "@material-ui/icons/Check"
import { MDBDataTable } from 'mdbreact';
import FormularioUnidades from '../../../components/Formularios/FormularioMEdioPAgo';
import swal from 'sweetalert'
import Tooltip from '@material-ui/core/Tooltip';
import Loader from '../../../components/Loader'
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
const idModulo = 30
var permisos = ''
class Categorias extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Desde bd
            mpagosbd: [],
            loader: true,
            //
            ventanaCreacion: false,
            actualizarDatos: false,
            modoEdicion: false,
            unidadEditar: null
        };
        store.subscribe(() => {
            this.setState({
                actualizarDatos: store.getState().actualizarvista,
                ventanaCreacion: store.getState().CreacionMedioPago,
            })
        })
    }

    crearProducto = () => {
        this.setState({ ventanaCreacion: !this.state.ventanaCreacion, unidadEditar: null })
    }

    editarUnidad = (unidad) => {
        this.setState({ modoEdicion: true, unidadEditar: unidad })
        this.setState({ ventanaCreacion: !this.state.ventanaCreacion })
    }

    eliminarUnidad = (id) => {
        Axios.delete(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/MediosPagos/' + id, { headers: { Authorization: ntoken } })
            .then((res) => {
                console.log(res)
                if (res.status == 200) {
                    swal({
                        title: "Medio de pago eliminado correctamente",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                store.dispatch({
                                    type: "CREACION_MEDIO_PAGO",
                                    CreacionMedioPago: false
                                })
                                break;
                            default:

                        }
                    });
                }else if (res.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                }
            })
    }

    componentWillMount() {
        //Permisos usuarios
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        this.ontenerDatos()
    }

    componentWillReceiveProps() {

        if (this.state.actualizarDatos) {
            this.ontenerDatos()
        }
    }

    activarUnidad = (id) => {
        Axios.put(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/MediosPagos/activar/' + id, { headers: { Authorization: ntoken } })
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    swal({
                        title: "Medio de pago activada",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                store.dispatch({
                                    type: "CREACION_MEDIO_PAGO",
                                    CreacionMedioPago: false
                                })
                                this.setState({ cambbioInicio: false, cambbioFin: false })
                                break;

                            default:

                        }
                    });
                }else if (response.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                }
            })
    }

    ontenerDatos = () => {
        var dominio = store.getState().dominio
        var usuario = store.getState().usuario
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        //Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/MediosPagos/' + dominio)
        Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/MediosPagos/empresas/' + dominio, { headers: { Authorization: ntoken } })
            .then(responsee => {
                console.log(responsee)
                var perf = []
                if (responsee.status == 200) {
                    responsee.data.map((perfil) => {
                        console.log(perfil)
                        perf.push({
                            'descripcion': perfil.descripcion,
                            "credito": perfil.credito == 'S' ? 'Si' : 'No',
                            "cambio": perfil.cambio == 'S' ? 'Si' : 'No',
                            "maximoCambio": perfil.maximo_cambio = null ? 'No acepta' : perfil.maximo_cambio,
                            "valorminimo": perfil.minimo_valor,
                            "acciones": perfil.estado == "A" ? [
                                permisos.includes('U') ? <Tooltip title={'Editar'} placement="top"><button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.editarUnidad(perfil)}><EditICon /></button></Tooltip> : null,
                                permisos.includes('D') ? <Tooltip title={'Desactivar'} placement="top"><button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.eliminarUnidad(perfil.id)}><DeleteIcon /></button></Tooltip> : null
                            ]
                                :
                                permisos.includes('C') ? <Tooltip title={'Activar'} placement="top"><button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.activarUnidad(perfil.id)}><Activar /></button></Tooltip> : null
                        })
                    })
                    this.setState({ mpagosbd: perf, loader: false })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                } else {
                    this.setState({ loader: false })
                }

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }


    render() {
        var perfiles = {
            columns: [
                {
                    label: 'Descripcion',
                    field: 'descripcion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Es credito',
                    field: 'credito',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Entrega cambio',
                    field: 'cambio',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Maximo cambio',
                    field: 'maximo',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Valor minimo a recibir',
                    field: 'minimo',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Acciones',
                    field: 'acciones',
                    sort: 'asc',
                    width: 150
                }
            ],
            rows: this.state.mpagosbd
        }
        return (
            this.state.loader
                ?
                <Loader />
                :
                <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                    <div className="row">
                        {this.state.ventanaCreacion ? <button className="btn btn-danger" onClick={this.crearProducto.bind(this)}>Cancelar</button> : permisos.includes('C') ? <button className="btn btn-success" onClick={this.crearProducto.bind(this)}>Crear medio de pago</button> : null}
                    </div>
                    <div className="row" style={{ marginTop: '10px', height: '100%' }}>
                        {this.state.ventanaCreacion ?
                            <FormularioUnidades unidadActualizar={this.state.unidadEditar != null ? this.state.unidadEditar : null} />
                            :
                            null}
                    </div>
                    <div className="row" style={{ marginTop: '10px', height: '100%' }}>
                        {permisos.includes('R') ? <div className="col col-md-12" style={{ padding: 0 }}>
                            <Paper style={{ padding: '5px' }}>

                                <MDBDataTable
                                    //striped
                                    className="col-md-12"
                                    searchLabel="Buscar"
                                    paginationLabel={["Anterior", "Siguente"]}
                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                    entriesLabel="Filas a mostrar"
                                    tbodyColor="primary-color"
                                    theadColor="#FF8800"
                                    exportToCSV={true}
                                    striped
                                    bordered
                                    autoWidth={false}
                                    responsive={true}
                                    hover
                                    data={perfiles}
                                />
                            </Paper>
                        </div> : <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>}
                    </div>
                </div >
        )
    }
}


export default Categorias