import React from 'react'
import Loader from 'react-loader-spinner'
import 'bootstrap/dist/css/bootstrap.min.css';
export default class App extends React.Component {
   //other logic
   render() {
      return (
         <div className="row mx-auto my-auto" style={{ height: '100vh', width: '100vw' }}>
            <div className="col col-md-12">
               <Loader
                  className="mx-auto my-auto"
                  type="ThreeDots"
                  color="#f44336"
                  height="100"
                  width="100"
               />
            </div>

         </div>

      );
   }
}