
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import 'bootstrap/dist/css/bootstrap.min.css';
import CardTittle from '@material-ui/core/CardHeader'
import GridItem from "components/Grid/GridItem.jsx";
import Server from '../../lib/server'
import store from '../../lib/store'
import Axios from 'axios'
import swal from 'sweetalert'
import Selects from 'react-select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
var unidadActualizar = false
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class FormularioUnidades extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idEditar: 0,
            //DAtos a guardar
            descripcionN: '',
            valorn: '',
            modoEditar: false,
            checkUnidadBasica: false,
            unidadBAsicaSeleccionada: {},
            unidadesBasicasbd: [],
            unidadesExistentes: [],
            response:false
        };
    }

    componentWillMount() {
        var dominio = store.getState().dominio
        Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/unidades/empresas/' + dominio, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var unidades = []
                var existentes = []
                console.log(responsee)
                if (responsee.status === 200) {
                    responsee.data.map((unidad) => {
                        existentes.push(unidad.descripcion)
                        unidades.push({
                            value: unidad.id,
                            label: unidad.descripcion
                        })
                    })
                }
                this.setState({ unidadesBasicasbd: unidades, unidadesExistentes: existentes })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        unidadActualizar = this.props.unidadActualizar
        console.log(unidadActualizar)
        if (unidadActualizar != null) {
            this.setState({ modoEditar: true, descripcionN: unidadActualizar.descripcion, valorn: parseInt(unidadActualizar.valor) + '', idEditar: unidadActualizar.id, checkUnidadBasica: parseInt(unidadActualizar.id) === parseInt(unidadActualizar.unidad_basica), unidadBAsicaSeleccionada: { value: unidadActualizar.unidad_basica, label: unidadActualizar.unidad_basica_descripcion } })
        } else {
            this.setState({ modoEditar: false, descripcionN: '', valorn: '', idEditar: 0 })
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };

    handleChangemoment = date => {
        this.setState({ selectedDate: date, cambbioInicio: true });
    }

    handleChangemomentFin = date => {
        this.setState({ selectedDateFin: date, cambbioFin: true });
    }

    handleChangeCheck = name => event => {
        var estado = (event.target.value == 'false' ? false : true)
        this.setState({
            [name]: !estado
        });
    }


    changeSelect = name => event => {
        switch (name) {
            case 'checkIPorcentaje':
                this.setState({ checkIPorcentaje: true, checkIValor: false })
                break
            case 'checkIValor':
                this.setState({ checkIPorcentaje: false, checkIValor: true })
                break
        }
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
    };


    validarFormulario = () => {
        if (this.state.descripcionN.trim == "" || this.state.descripcionN.length == 0) {
            swal("FALLO AL CREAR", "LA DESCRIPCION DE LA UNIDAD ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.valorn.trim == "" || this.state.valorn.length == 0) {
            swal("FALLO AL CREAR", "EL VALOR ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.unidadesExistentes.includes(this.state.descripcionN)) {
            swal("FALLO AL CREAR", "ESTA UNIDAD YA ESTA CREADA", "warning")
        } else {
            this.setState({
                response:true
            })
            return true
        }

    }

    enviarDatos = () => {
        var dominio = store.getState().dominio
        var validar = this.validarFormulario()
        var datos = {
            "descripcion": this.state.descripcionN,
            "valor": parseInt(this.state.valorn),
            "empresas_id": parseInt(dominio, 10),
            "unidad_basica": JSON.stringify(this.state.unidadBAsicaSeleccionada) == '{}' ? 0 : this.state.unidadBAsicaSeleccionada.value
        }
        //console.log(Server.hostPrueba + Server.puertSSLParametrizacion + '/api/unidades')
        if (validar) {
            Axios.post(Server.hostPrueba + Server.puertSSLParametrizacion + '/api/unidades', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    //console.log(response)
                    this.setState({
                        response:false
                    })
                    if (response.status === 200) {
                        swal({
                            title: "Unidad creada correctamente",
                            icon: "success",
closeOnClickOutside:false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_UNIDAD",
                                        creacionUnidad: false
                                    })
                                    //this.setState({loader:true,descripcionN:'',selectedDate:new Date(),selectedDateFin:new Date(),checkIPorcentaje:true,checkIValor:false,valorn:false,cambbioInicio: false,cambbioFin:false})
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }

    }

    editarImpuesto = (id) => {
        this.state.impuestosbd.map((impuesto) => {
            if (impuesto.id == id) {
                this.setState({ modoEdicion: true, createbodega: true, descripcionN: impuesto.descripcion, checkIValor: impuesto.porcentaje_valor == "$", checkIPorcentaje: impuesto.porcentaje_valor == "%", valorn: impuesto.valor + '', selectedDate: impuesto.fecha_inicio, selectedDateFin: impuesto.fecha_fin, idEditar: impuesto.id })
            }
        })
    }

    cancelar = () => {
        store.dispatch({
            type: "CREACION_UNIDAD",
            creacionUnidad: false
        })
    }

    actualizarDatos = () => {
        var dominio = store.getState().dominio
        var datos = {
            "descripcion": this.state.descripcionN,
            "valor": parseInt(this.state.valorn),
            "empresas_id": parseInt(dominio, 10),
            "unidad_basica": JSON.stringify(this.state.unidadBAsicaSeleccionada) == '{}' ? this.state.idEditar : this.state.unidadBAsicaSeleccionada.value
        }
        console.log(datos)
        Axios.put(Server.hostPrueba + Server.puertSSLParametrizacion + '/api/unidades/' + this.state.idEditar, datos, { headers: { Authorization: ntoken } })
            .then(response => {
                console.log(response)
                this.setState({
                    response:false
                })
                if (response.status === 200) {
                    swal({
                        title: "Unidad actualizada correctamente",
                        icon: "success",
closeOnClickOutside:false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                store.dispatch({
                                    type: "CREACION_UNIDAD",
                                    creacionUnidad: false
                                })
                                this.setState({ loader: true, cambbioInicio: false, cambbioFin: false })
                                break;

                            default:

                        }
                    });
                } else {
                    swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                }
            })
    }

    render() {
        return (
            <GridItem xs={12} sm={12} md={12} id="crearOrden" style={{ padding: '0' }}>
                <Card style={{ marginTop: "15px", paddingBottom: "15px", height: '70vh' }}>
                    <CardTittle title="Registro de unidades" style={{ marginBottom: "0", paddingBottom: "5px" }} />
                    <CardContent style={{ paddingTop: "5px", overflow: 'auto', height: '100%' }}>
                        <div className="row bottom-aligned">
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">DESCRIPCION</label>
                                <input id="numordencompra" className="form-control" type="text" autoComplete="off" value={this.state.descripcionN} onChange={this.handleChange('descripcionN')} />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">VALOR</label>
                                <input id="numordencompra" className="form-control" type="number" min="1" autoComplete="off" value={this.state.valorn} onChange={this.handleChange('valorn')} />
                            </div>
                            <div className="col col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">UNIDAD BASICA</label>
                                <Selects
                                    placeholder="Seleccione o escriba"
                                    value={this.state.unidadBAsicaSeleccionada}
                                    onChange={this.handleChanges('unidadBAsicaSeleccionada')}
                                    options={this.state.unidadesBasicasbd}
                                //styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}

                                />
                            </div>
                        </div>
                        <div className="row bottom-aligned" style={{ paddingBottom: '30px' }}>
                            <div className=" col-md-8 bottom-aligned"></div>
                            <div className="bottom-aligned">
                                {this.state.modoEditar ? <button className="btn btn-success bottom-aligned"  style={{ float: "right", margin: '10px' }} disabled={this.state.response} onClick={() => this.actualizarDatos()}>GUARDAR</button> : <button className="btn btn-success" disabled={this.state.response} style={{ float: "right", margin: '10px' }} onClick={() => this.enviarDatos()}>CREAR UNIDAD</button>}
                            </div>
                            <div className="bottom-aligned">
                                <button className="btn btn-danger bottom-aligned" style={{ float: "right", margin: '10px' }} onClick={() => this.cancelar()}>CANCELAR</button>
                            </div>
                        </div>

                    </CardContent>
                </Card>
            </GridItem>
        )
    }
}


export default FormularioUnidades
