import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from "@material-ui/core/CardContent"
import Avatar from '@material-ui/core/Avatar';
import './cardUsuario.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import imagenUsuario from '../../../assets/img/userperfil.png'
import { Figure } from 'react-bootstrap'
import Typography from '@material-ui/core/Typography';
//iconos
import userIcon from '@material-ui/icons/Person'

class cardUsuario extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: '',
            correo: ''
        };
    }


    render() {
        const { nombre, correo, telefono } = this.props;
        const imagen = imagenUsuario
        return (
            <Card className="card" >
                <div className="row">
                    <div className="col col-md-3 col-sm-4 align-middle my-auto mx-auto">
                        <Figure className="align-middle my-auto mx-auto">
                            <Figure.Image
                                width={50}
                                height={50}
                                alt="Imagen de usuario"
                                src={imagenUsuario}
                            />
                        </Figure>
                    </div>
                    <div className="col col-md-9 col-sm-8" style={{ padding: "0" }}>
                        <CardContent>

                                    <Typography variant="button" gutterBottom>
                                        {nombre}
                                    </Typography>

                        </CardContent>
                    </div>
                </div>


            </Card>

        );
    }
}


export default cardUsuario;