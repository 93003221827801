import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import 'bootstrap/dist/css/bootstrap.min.css';
import CardTittle from '@material-ui/core/CardHeader'
import GridItem from "components/Grid/GridItem.jsx";
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Server from '../../lib/server'
import store from '../../lib/store'
import Axios from 'axios'
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import swal from 'sweetalert'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import Selects from 'react-select';
import Tiendas from "@material-ui/icons/StoreMallDirectory"
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';
import CloseIcon from "@material-ui/icons/Close";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import Avatar from '@material-ui/core/Avatar';
import { Button } from 'react-bootstrap'
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogTelefono from './dialogs/dialogTelefono'
import DialogCorreo from './dialogs/dialogCorreo'
import FormularioGeografia from './FormularioGeografia';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MailIcon from '@material-ui/icons/Mail'
import TelefonoIcon from '@material-ui/icons/Phone'
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import '../../views/empresa/Campanias/campana.css'
var colorDominio = store.getState().colorDominio
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}
var impuestoEditar = false
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class FormularioEmpresa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            islas: [],
            dialogAddSurtidor: false,
            marca: {},
            referencia: '',
            numeroCaras: {},
            tiposProtocolossbd: [],
            protocoloSeleccionado: {},
            tipoSeleccionado: {},
            tiposSurtidoresbd: [],
            carasSurtidor:[],
            carasbd: [
                {
                    value: 2,
                    label: "2 CARAS"
                }, {
                    value: 4,
                    label: "4 CARAS"
                }
            ]
        };
    }


    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizarDatos) {
            this.obtenerActualizados()
            return true
        }
        return true;
    }






    obtenerDatos() {
        this.setState({ actualizarDatos: false })
        //EMPRESAS
        var usuario = store.getState().usuario
        Axios.get(Server.hostPrueba + Server.puertoEquipos + '/api/surtidor/tipos', { headers: { Authorization: ntoken } })
            .then(response => {
                var tipoSurtidor = [];
                if (response.status === 200) {
                    response.data.data.map((surtidor) => {
                        tipoSurtidor.push({
                            value: surtidor.id,
                            label: surtidor.descripcion,
                        })
                    })
                }
                this.setState({
                    tiposSurtidoresbd: tipoSurtidor
                })
                console.log(response);
            })
        Axios.get(Server.hostPrueba + Server.puertoEquipos + '/api/surtidor/protocolos', { headers: { Authorization: ntoken } })
            .then(response => {
                var tipoSurtidor = [];
                if (response.status === 200) {
                    response.data.data.map((surtidor) => {
                        tipoSurtidor.push({
                            value: surtidor.id,
                            label: surtidor.descripcion,
                        })
                    })
                }
                this.setState({
                    tiposProtocolossbd: tipoSurtidor
                })
            })
    }


    componentWillMount() {
        this.obtenerDatos()
        //this.obtenerActualizados()
        //this.update()

    }

    handleChanges = name => value => {

        this.setState({
            [name]: value,
        });
        switch(name){
            case "numeroCaras":
                var manguerasbycara=[]
                for(let i = 0;i<= value.value;i++){
                    console.log("Se ha creado una cara")
                    manguerasbycara.push({"nmangueras":0})
                }
                this.setState({
                    carasSurtidor:manguerasbycara
                })
                break;
        }
    }

    cerrarDialogSurtidor = () => {
        this.setState({
            dialogAddSurtidor: false
        })
    }





    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };

    handleChange2 = (name, pos) => event => {
        var islas = this.state.islas
        console.log(islas[pos])
        console.log(islas)
        if (name === "impresoras") {
            islas[pos].impresoras = event.target.value
        } else {
            islas[pos].equipos = event.target.value
        }
    };






    //Funcion para setear el precio
    setearMiles = (numero) => {
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case "COP":
                return (
                    (new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(2)))
                )
                break;
            case "USD":
                return (new Intl.NumberFormat("en-IN").format(parseFloat(numero).toFixed(3)))
                break;

        }
    }






    cerrar = () => {
        store.dispatch({
            type: "CONFIGURACION_EDS",
            configuracionEds: false,
            edsDetalles: null,
            modoVistaEds: false
        })
    }
    addISla = () => {
        var isla = {
            "impresoras": 0,
            "equipos": 0,
            "surtidores": []
        }
        var islas = this.state.islas
        islas.push(isla)
        this.setState({
            islas
        })
    }

    addSurtidor = (pos) => {
        this.setState({
            dialogAddSurtidor: true
        })
    }

    removeIsla = (pos) => {
        var islas = this.state.islas
        islas.splice(pos, 1)
        this.setState({
            islas
        })
    }


    render() {
    var islas = this.state.islas
    console.log(islas)
        return (
            <div>
                <AppBar style={{ backgroundColor: colorDominio }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Close"
                            onClick={this.cerrar.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            style={{ marginLeft: "theme.spacing(2)", flex: 1, color: '#fff' }}
                        >
                            CONFIGURACION DE EDS
                        </Typography>
                        <Button style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} disabled={this.state.response} >GUARDAR</Button>
                    </Toolbar>
                </AppBar>
                <div className="row" style={{ marginTop: '7%', padding: '15px' }}>
                    <div className="col-md-12">
                        <div className="row mx-auto" onClick={this.addISla.bind(this)} style={{ marginLeft: '15px', marginRight: '15px', backgroundColor: '#c4d4e9', display: 'grid', cursor: 'pointer', border: '1.5px dashed gray' }}>
                            <h1 className="mx-auto" style={{ fontSize: '70px' }}>+</h1>
                            <h6 className="mx-auto">AGREGAR NUEVA ISLA</h6>
                        </div>
                        <Dialog
                            open={this.state.dialogAddSurtidor}
                            onClose={() => this.cerrarDialogSurtidor()}
                            aria-labelledby="form-dialog-title"
                            maxWidth={'md'}
                            fullWidth={true}
                            style={{ padding: '0' }}
                            disableBackdropClick={true}
                            className="no-overflow"
                        >
                            <DialogContent style={{ padding: '0', overflowY: 'initial !IMPORTANT' }} className="no-overflow">
                                <div className="row mx-auto">
                                    <div className="col-md-6">
                                        <label htmlFor="nombreprov" className="col-form-label">TIPO</label>
                                        <Selects
                                            placeholder="Seleccione o escriba"
                                            value={this.state.tipoSeleccionado}
                                            onChange={this.handleChanges('tipoSeleccionado')}
                                            options={this.state.tiposSurtidoresbd}

                                        />
                                    </div>
                                    <div className="col-md-6" >
                                        <label htmlFor="nombreprov" className="col-form-label">CARAS</label>
                                        <Selects
                                            placeholder="Seleccione o escriba"
                                            value={this.state.numeroCaras}
                                            onChange={this.handleChanges('numeroCaras')}
                                            options={this.state.carasbd}

                                        />
                                    </div>
                                </div>
                                <div className="row mx-auto">
                                    <div className="col-md-6" >
                                        <label htmlFor="nombreprov" className="col-form-label">PROTOCOLO</label>
                                        <Selects
                                            placeholder="Seleccione o escriba"
                                            value={this.state.protocoloSeleccionado}
                                            onChange={this.handleChanges('protocoloSeleccionado')}
                                            options={this.state.tiposProtocolossbd}

                                        />
                                    </div>
                                </div>
                                {
                                    JSON.stringify(this.state.numeroCaras) === "{}"
                                        ?
                                        null
                                        :
                                        this.state.numeroCaras.value === 2 ?
                                            <div className="row mx-auto">
                                                <div className="col-md-6" >
                                                    <label htmlFor="nombreprov" className="col-form-label">Numero de mangueras de la cara 1</label>
                                                    <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" min="0" max="9" value={this.state.carasSurtidor[0].nmangueras} onChange={this.handleChange('fPrecio')} />
                                                </div>
                                                <div className="col-md-6" >
                                                    <label htmlFor="nombreprov" className="col-form-label">Numero de mangueras de la cara 2</label>
                                                    <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" min="0" max="9" value={this.state.carasSurtidor[1].nmangueras} onChange={this.handleChange('fPrecio')} />
                                                </div>
                                            </div>
                                            :
                                            <div className="row mx-auto">
                                                <div className="col-md-6" >
                                                    <label htmlFor="nombreprov" className="col-form-label">Numero de mangueras de la cara 1</label>
                                                    <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" min="0" max="9" value={this.state.carasSurtidor[0].nmangueras} onChange={this.handleChange('fPrecio')} />
                                                </div>
                                                <div className="col-md-6" >
                                                    <label htmlFor="nombreprov" className="col-form-label">Numero de mangueras de la cara 2</label>
                                                    <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" min="0" max="9" value={this.state.carasSurtidor[1].nmangueras} onChange={this.handleChange('fPrecio')} />
                                                </div>
                                                <div className="col-md-6" >
                                                    <label htmlFor="nombreprov" className="col-form-label">Numero de mangueras de la cara 3</label>
                                                    <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" min="0" max="9" value={this.state.carasSurtidor[2].nmangueras} onChange={this.handleChange('fPrecio')} />
                                                </div>
                                                <div className="col-md-6" >
                                                    <label htmlFor="nombreprov" className="col-form-label">Numero de mangueras de la cara 4</label>
                                                    <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" min="0" max="9" value={this.state.carasSurtidor[3].nmangueras} onChange={this.handleChange('fPrecio')} />
                                                </div>
                                            </div>

                                }
                                <div className="row mx-auto" style={{marginBottom:'15px',marginTop:'15px'}}>
                                    <div className="col-md-6" >
                                        <button className="btn btn-danger btn-block" onClick={()=>this.cerrarDialogSurtidor()}>CERRAR</button>
                                    </div>
                                    <div className="col-md-6" >
                                        <button className="btn btn-success btn-block" onClick={()=>this.cerrarDialogSurtidor()}>AGREGAR</button>
                                    </div>
                                   
                                </div>
                            </DialogContent>
                        </Dialog>
                        {
                            islas != null
                                ?
                                islas.map((isla, k) => {
                                    console.log(isla)
                                    console.log(this.state.islas[k].impresoras)
                                    return (
                                        <div className="row mx-auto" style={{ marginTop: '20px', marginLeft: '15px', marginRight: '15px', backgroundColor: '#c4d4e9', display: 'grid', border: '1.5px dashed gray' }}>
                                            <div className="col-md-12">
                                                <div className="row" style={{ marginTop: '15px' }}>
                                                    <div className="col-md-3">
                                                        <h4>ISLA {k + 1}</h4>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group row">
                                                            <label for="staticEmail" className="col-sm-6 col-form-label">N. Impresoras</label>
                                                            <div className="col-sm-">
                                                                <input type="number" onChange={this.handleChange2("impresoras", k)} className="form-control plaintext" value={this.state.islas[k].impresoras} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group row">
                                                            <label for="staticEmail" className="col-sm-6 col-form-label">N. Equipos</label>
                                                            <div className="col-sm-6">
                                                                <input type="number" onChange={this.handleChange2("equipos", k)} className="form-control plaintext" value={isla.equipos} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button className="col-md-1 btn" onClick={() => this.removeIsla(k)}><CloseIcon /></button>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="row mx-auto" onClick={() => this.addSurtidor(k)} style={{ marginLeft: '15px', marginRight: '15px', marginBottom: '15px', backgroundColor: '#f7e9bf', display: 'grid', cursor: 'pointer', border: '1.5px dashed gray' }}>
                                                            <h1 className="mx-auto" style={{ fontSize: '70px' }}>+</h1>
                                                            <h6 className="mx-auto">AGREGAR SURTIDOR</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                null
                        }
                    </div>

                </div>
            </div>
        )
    }
}


export default FormularioEmpresa
