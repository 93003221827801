import React, { Component } from 'react';
import axios from 'axios';
import Server from '../../lib/server';
import 'bootstrap/dist/css/bootstrap.min.css';
import FormularioPersona from './FormularioPersona';
import { Button, Form, FormLabel, FormControl, FormGroup, FormText, Row, Col } from 'react-bootstrap'
import Axios from 'axios';
import md5 from 'md5';
import store from '../../lib/store';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import swal from 'sweetalert';

class NuevaContrasenia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nuevacontra: '',
            confirmacontra: '',
            adminPass: '',
            error: '',
            ventanaContra: false,
            response: false

        };

        this.handlePassChange = this.handlePassChange.bind(this);
        this.handlePassConfirm = this.handlePassConfirm.bind(this);
        this.handleAdminPass = this.handleAdminPass.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.dismissError = this.dismissError.bind(this);
    }

    dismissError() {
        this.setState({ error: '' });
    }
    abrirFormulario() {
        this.setState({
            ventanaContra: !this.state.ventanaContra
        });
    }


    handleSubmit(evt) {
        const { nuevacontra, confirmacontra } = this.state;
        evt.preventDefault();
        if (!nuevacontra || nuevacontra.trim == '' || nuevacontra.length == 0) {
            swal('Este campo no puede estar vacio');
        } else if (!confirmacontra || confirmacontra.trim == '' || confirmacontra.length == 0) {
            swal('Este campo no puede estar vacio');
        } else if (nuevacontra != confirmacontra) {
            swal('Las contraseñas no coinciden');
            this.setState({ error: '' });
        } else {
            return this.abrirFormulario();
        }

    }

    enviarDatos = () => {
        this.setState({
            response: true
        })
        var usuario = store.getState().usuario
        var admin = this.state.adminPass
        var npass = this.state.confirmacontra
        var password = (md5(admin));
        var newPassword = (md5(npass));
        var id_usuario = this.props.usuario.persona;
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        var data = {
            "personas_id": id_usuario.id,
            "password": password,
            "newPassword": newPassword,
            "personas_admin_id": usuario.p_id,
            "empresas_id": id_usuario.empresa.id,

        };
        Axios.put(Server.hostPrueba+Server.puertoEmpleado+'/api/persona/usuario/password', data, { headers: { Authorization: ntoken } })
            .then(function (response) {
                var mensaje = response.data.mensaje;
                console.log(response)
                
                if (response.status === 200) {
                    if (response.data.success) {
                        swal({
                            title: mensaje,
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CAMBIO_CONTRASEÑA",
                                        ventanaContrseña: false
                                    })
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("ERROR", mensaje, "warning");
                    }

                } else if (response.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }

            })
    }

    handleChange = (name) => event => {
        this.setState({
            [name]: event.target.value
        });
    }

    handlePassChange(evt) {
        this.setState({
            nuevacontra: evt.target.value,
        });
    }


    handlePassConfirm(evt) {
        this.setState({
            confirmacontra: evt.target.value,
        });
    }

    handleAdminPass(evt) {
        this.setState({
            adminPass: evt.target.value,
        });
    }

    cerrarDialog = () => {
        store.dispatch({
            type: "CAMBIO_CONTRASEÑA",
            ventanaContrseña: false
        })
    }

    render() {
        //console.log(this.props);
        return (
            <Form>
                <Form.Group>
                    <Col sm="12">
                        <Form.Label column sm="12">Clave</Form.Label>
                        <Form.Control type="password" placeholder="Escriba una clave" autoComplete="false" value={this.state.nuevacontra} onChange={this.handleChange('nuevacontra')} required />
                    </Col>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Col sm="12">
                        <Form.Label column sm="12">Repita clave</Form.Label>
                        <Form.Control type="password" placeholder="Repita la clave" autoComplete="false" value={this.state.confirmacontra} onChange={this.handleChange('confirmacontra')} required />
                    </Col>
                </Form.Group>
                <div className="col-md-3 mx-auto" style={{ float: "right" }}>
                    <Button variant="primary" onClick={this.handleSubmit} className="btn-lg btn-block" type="submit">
                        guardar
                        </Button>
                </div>
                <div className="col-md-3 mx-auto" style={{ float: "right" }}>
                    <Button variant="danger" className=" btn-lg btn-block" type="reset" onClick={this.cerrarDialog.bind(this)}>
                        Cancelar
                        </Button>
                </div>
                <Dialog
                    open={this.state.ventanaContra}
                    onClose={() => this.abrirFormulario()}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'md'}
                    fullWidth={true}
                    style={{ padding: '0' }}
                    disableBackdropClick={false}>
                    <DialogContent style={{ padding: '30px' }}>
                        <Form>
                            <Form.Group as={Row}>
                                <Col sm="12">
                                    <Form.Label column sm="12">Clave de usuario</Form.Label>
                                    <Form.Control type="password" placeholder="Escriba su clave de usuario" autoComplete="off" value={this.state.adminPass} onChange={this.handleChange('adminPass')} />
                                </Col>
                            </Form.Group>
                            <div className="col-md-3 mx-auto" style={{ float: "right" }}>
                                <Button variant="primary" onClick={this.enviarDatos} className="btn-lg btn-block">
                                    guardar
                                    </Button>
                            </div>
                            <div className="col-md-3 mx-auto" style={{ float: "right" }}>
                                <Button variant="danger" className=" btn-lg btn-block" type="reset" onClick={this.abrirFormulario.bind(this)}>
                                    Cancelar
                                    </Button>
                            </div>
                        </Form>
                    </DialogContent>
                </Dialog>
            </Form>
        )
    }
}

export default NuevaContrasenia;