import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Slide from "@material-ui/core/Slide"
import "moment/locale/es";
import formulario from './FormularioAutomativo.json'
//Componentes
import Selects from 'react-select'
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker, TimePicker } from 'material-ui-pickers';
//Libreria para las funciones
import moment from 'moment'
import "moment/locale/es";
import DateFnsUtils from '@date-io/luxon';
var form = formulario.formulario_empleado
class AjusteInicial extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    GetTexto = (indice) => event => {
        form.campos[indice].value = event.target.value.toUpperCase()
    };

    getSelect = indice => value => {
        form.campos[indice].value = value
    }

    getDate = indice => date => {
        form.campos[indice].value = date
    }

    validaciones = () => {
        console.log(form.campos)
        /*form.validaciones.map((val) => {
            form.campos.map((cmp) => {
                cmp.validaciones.map((v2) => {
                    if (val.id === v2) {
                        switch (val.type) {
                            case 0:
                                var funcion = eval(val.funcion)
                                if (!funcion(cmp.value)) {
                                    alert(cmp.label + " es obligatorio")
                                    return(false)
                                }
                                break;
                            case 1:
                                var funcion = eval(val.funcion)
                                if (!funcion(cmp.value, cmp.minimo_caracteres)) {
                                    alert("La longitud del campo " + cmp.label + " debe ser mayor a " + cmp.minimo_caracteres)
                                }
                                break;
                            case 2:
                                var funcion = eval(val.funcion)
                                if (!funcion(cmp.value, cmp.maximo_caracteres)) {
                                    alert("La longitud del campo " + cmp.label + " debe ser menor a " + cmp.maximo_caracteres)
                                }
                                break;
                            case 3:
                                var funcion = eval(val.funcion)
                                if (!funcion(cmp.value)) {
                                    alert(cmp.label + " Es obligatorio")
                                }
                                break;
                            case 4:
                                var funcion = eval(val.funcion)
                                console.log(funcion)
                                if (funcion(cmp.value, /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/)) {
                                    alert("El campo " + cmp.label + " es invalido")
                                }
                                break;
                        }
                    }
                })
            })
        })*/
    }



    render() {
        var formRender = form
        console.log(formRender)
        return (
            <div>
                {form.grupos.map((grupo) => {
                    return (
                        <div >
                            <div className="row">
                                <h3>{grupo.label}</h3>
                            </div>
                            <div className="row">
                                {
                                    form.campos.map((campo, key) => {
                                        if (campo.tipo === "text" && campo.grupo === grupo.id) {
                                            return (
                                                <div className={"col-md-" + campo.Ancho_grillas}>
                                                    <label htmlFor="numordencompra" className="col-form-label">{campo.label}</label>
                                                    <input id="numordencompra" autoComplete="off" className="form-control" onChange={this.GetTexto(key)} type="text" min={"" + campo.minimo_caracteres} max={"" + campo.maximo_caracteres} required={campo.requerido} />
                                                </div>
                                            )
                                        } else if (campo.tipo === "select" && campo.grupo === grupo.id) {
                                            return (
                                                <div className={"col-md-" + campo.Ancho_grillas}>
                                                    <label htmlFor="numordencompra" className="col-form-label">{campo.label}</label>
                                                    <Selects
                                                        placehol                        der="Seleccione"
                                                        onChange={this.getSelect(key)}
                                                        options={campo.options}
                                                        isMulti={campo.isMulti}
                                                    />
                                                </div>
                                            )
                                        } else if (campo.tipo === "mail" && campo.grupo === grupo.id) {
                                            return (
                                                <div className={"col-md-" + campo.Ancho_grillas}>
                                                    <label htmlFor="numordencompra" className="col-form-label">{campo.label}</label>
                                                    <input id="numordencompra" autoComplete="off" className="form-control" type="mail" min={"" + campo.minimo_caracteres} max={"" + campo.maximo_caracteres} required={campo.requerido} />
                                                </div>
                                            )
                                        } else if (campo.tipo === "date" && campo.grupo === grupo.id) {
                                            return (
                                                <div className={"col-md-" + campo.Ancho_grillas}>
                                                    <label htmlFor="numordencompra" className="col-form-label">{campo.label}</label>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                        <DatePicker
                                                            format="yyyy-MM-dd"
                                                            id="datetime"
                                                            onChange={this.getDate(key)}
                                                            value={formRender.campos[key].value}
                                                            className="rounded form-control"
                                                            style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    )
                })}
                <button className="btn btn-primary" style={{ margin: '5px' }} onClick={() => this.validaciones()}>GUARDAR</button>
            </div>
        )
    }
}
export default AjusteInicial