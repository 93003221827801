import React from 'react';
import './cardUsuario.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import store from '../../../lib/store'
import Collapse from "@material-ui/core/Collapse"
import Server from '../../../lib/server'
import Axios from 'axios'
import swal from 'sweetalert'
import moment from 'moment';
const B = (props) => <p style={{ fontWeight: 'bold', marginBottom: "0" }}>{props.children}</p>
var dominio = ''
const idModulo = 13
var permisos = ''
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class cardUsuario extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detalles: false
        };
    }

    componentWillMount() {
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        dominio = store.getState().dominio
    }

    detallesProducto = (producto) => {
        //console.log(producto)
        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/detalles/' + producto, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status === 200) {
                    store.dispatch({
                        type: "CREACION_PRODUCTO",
                        creacionProducto: !this.state.detalles,
                        productoEdicion: responsee.data.data,
                        vistaProducto: true,
                        vista: false
                    })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    eliminarProducto = (producto) => {
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        var usuario = store.getState().usuario
        swal({
            title: "Estas seguro de que quieres deseas eliminar un producto?",
            text: "Este producto sera eliminado, pero quedaran sus registros de ventas",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.put(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/' + producto + '/I/' + usuario.p_id, {}, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 201) {
                                swal({
                                    title: "PRODUCTO INACTIVADO CORRECTAMENTE",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            store.dispatch({
                                                type: "ACTUALIZACION_DATOS",
                                                actualizarvista: true
                                            })
                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            } else {
                                swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }
    activarProducto = (producto) => {
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        var usuario = store.getState().usuario
        Axios.put(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/' + producto + '/A/' + usuario.p_id, {}, { headers: { Authorization: ntoken } })
            .then((res) => {
                console.log(res)
                if (res.status == 201) {
                    swal({
                        title: "PRODUCTO ACTIVADO CORRECTAMENTE",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                break;
                            default:

                        }
                    });
                } else if (res.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                }
            })
    }

    setearMiles = (numero) => {
        var usuario = store.getState().usuario
        if (usuario.empresas.paises_moneda === "USD") {
            console.log("acaaaa")
            return (new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(numero))
        } else {
            return (new Intl.NumberFormat("de-DE").format(parseFloat(numero)))
        }
    }

    editarProducto = (producto) => {
        //console.log(producto)
        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/detalles/' + producto, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status === 200) {
                    store.dispatch({
                        type: "CREACION_PRODUCTO",
                        creacionProducto: !this.state.detalles,
                        productoEdicion: responsee.data.data,
                        vistaProducto: true,
                        vista: true
                    })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }


    render() {
        const { infoProducto } = this.props;
        //console.log(infoProducto)
        var imagenProducto = Server.hostMultimedia + dominio + "/productos/default.png"
        var imagenDefault = Server.hostMultimedia + dominio + "/productos/default.png"
        var vacio = false
        //console.log(infoProducto)
        if (infoProducto != null) {
            vacio = true
            imagenProducto = Server.hostMultimedia + dominio + "/productos/" + (infoProducto.url_foto == 'N' ? 'default.png' : infoProducto.id + '.png')+"?"+moment(new Date()).format("YYYYMMMDDHHmmss")
        }
        return (
            <Card className=" border">
                <CardActionArea>
                    <div className="row">
                        <div className="col col-md-4">
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                style={{ height: '100px', width: '100px' }}
                                onError={(e) => { e.target.onerror = null; e.target.src = imagenDefault }}
                                src={imagenProducto}
                                title={!vacio ? null : infoProducto.descripcion}
                            />
                        </div>
                        <div className="col col-md-8">
                            <CardContent style={{ paddingBottom: "0" }}>
                                <Typography component="p">
                                    <B> {!vacio ? null : infoProducto.descripcion}</B>
                                </Typography>
                                <Typography component="p">
                                    {!vacio ? null : infoProducto.razon_social}
                                </Typography>
                                <Typography component="p">
                                    PLU: {!vacio ? null : infoProducto.plu}
                                </Typography>
                                <Typography component="p">
                                    Precio: {!vacio ? null : ' $' + this.setearMiles(infoProducto.precio)}
                                </Typography>
                            </CardContent>
                        </div>
                    </div>

                </CardActionArea>
                {infoProducto.estado == "A"
                    ?
                    <CardActions>
                        <button size="small" className="btn btn-outline-primary" onClick={() => this.editarProducto(infoProducto.id)}>
                            Detalles
        </button>
                        {permisos.includes('U') ? infoProducto.promocion != null || infoProducto.promocion === "C" || infoProducto.promocion === "P" ? null : <button size="small" className="btn btn-outline-warning" onClick={() => this.detallesProducto(infoProducto.id)}>
                            Editar
        </button>:  null}
                        {permisos.includes('D') ? <button size="small" className="btn btn-outline-danger" onClick={() => this.eliminarProducto(infoProducto.id)}>
                            Eliminar
        </button> : null}
                    </CardActions>
                    :

                    permisos.includes('U') ? <CardActions>
                        <button size="small" className="btn btn-outline-primary" onClick={() => this.editarProducto(infoProducto.id)}>
                            Detalles
        </button>
                        {permisos.includes('U') ?infoProducto.promocion != null || infoProducto.promocion === "C" || infoProducto.promocion === "P" ? null : <button size="small" className="btn btn-outline-warning" onClick={() => this.detallesProducto(infoProducto.id)}>
                            Editar
        </button> : null}
                        <button size="small" className="btn btn-outline-success" onClick={() => this.activarProducto(infoProducto.id)}>
                            ACTIVAR
        </button>

                    </CardActions> : null}




            </Card >

        );
    }
}
export default cardUsuario;


