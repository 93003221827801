/*eslint-disable*/
import React, { Component } from "react";
import classnames from "classnames";

import imagine1 from "assets/img/sidebar-1.jpg";
import imagine2 from "assets/img/sidebar-2.jpg";
import imagine3 from "assets/img/sidebar-3.jpg";
import imagine4 from "assets/img/sidebar-4.jpg";
import TimeIcon from "./r1.gif";
import TimeIcon2 from "./time-left.png";
import Server from '../../lib/server'

import Axios from 'axios'
import Button from "components/CustomButtons/Button.jsx";
import moment from 'moment'
import "moment/locale/es";
import store from "../../lib/store";
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class FixedPlugin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: "dropdown show",
      bg_checked: true,
      bgImage: this.props.bgImage,
      tiempo: ''
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.props.handleFixedClick();
    Axios.get(Server.hostPrueba + Server.puertoAutenticacion + '/relojito', { headers: { Authorization: ntoken } })
      .then(responsee => {
        if (parseInt(responsee.status) == 200) {
          //console.log(moment(responsee.data.data.fechavencimiento).format('hh:mm:ss a'))
          this.setState({
            tiempo: moment(responsee.data.data.fechavencimiento).format('hh:mm:ss a')
          })
        } else if (responsee.status === 205) {
          swal("ERROR", "LA SESION HA CADUCADO", "warning")
          setTimeout(function () { window.location.href = '/'; }, 3000);
        } else if (responsee.status === 206) {
          swal("ERROR", "LA SESION HA CADUCADO", "warning")
          setTimeout(function () { window.location.href = '/'; }, 3000);
        }
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
  }

    actualizarToken = () => {
      Axios.get(Server.hostPruebasinssl + Server.puertoAutenticacion, { headers: { Authorization: ntoken } })
          .then(response => {
              if (response.status === 205) {
                  swal("ERROR", "LA SESION HA CADUCADO", "warning")
                  setTimeout(function () { window.location.href = '/'; }, 2000);
              } else if (response.status === 206) {
                  swal("ERROR", "LA SESION HA CADUCADO", "warning")
                  setTimeout(function () { window.location.href = '/'; }, 2000);
              } else if (response.status === 200) {
                  //RESPEUSTA OK
              }
          })
  }
  
  render() {
    var { tiempo } = this.state
    return (
      <div
        className={classnames("fixed-plugin", {
          "rtl-fixed-plugin": this.props.rtlActive
        })}
        style={{ cursor: 'pointer' }}
      >
        <div id="fixedPluginClasses" className={this.props.fixedClasses}>
          <div style={{ padding: '8px', color: 'white' }} onClick={this.handleClick}>
          <img className="mx-auto" style={{ width: '40px',height:'40px', display: 'block' }} src={TimeIcon2} />
          </div>
          <ul className="dropdown-menu">
            <li className="header-title">VENCIMIENTO DE SESION</li>
            <img className="mx-auto" style={{ width: '80px', display: 'block' }} src={TimeIcon} />
            <li className="header-title">{tiempo}</li>

            <li className="button-container">
              <div className="button-container">
                <Button
                  color="success"
                  href="#"
                  fullWidth
                  onClick={() =>this.actualizarToken()}
                >
                  ACTUALIZAR TOKEN
                </Button>
              </div>
            </li>

            <li className="adjustments-line" />
          </ul>
        </div>
      </div>
    );
  }
}

export default FixedPlugin;
