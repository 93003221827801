
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Server from '../../lib/server'
import store from '../../lib/store'
import Axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditICon from '@material-ui/icons/Edit';
import swal from 'sweetalert'
import { Button } from 'react-bootstrap'
import Typography from '@material-ui/core/Typography';
import Selects from 'react-select'
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import CheckboxTree from 'react-checkbox-tree';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import EstructuraIcon from "@material-ui/icons/Sort"
import DespleglabeIcon from "@material-ui/icons/ArrowDownward"
import moment from 'moment'
import Buttonn from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { actualizarToken, columnasProductosAcuerdos, getEmpresasAcuerdos, getProveedoresAcuerdos } from '../../views/configuraciones/acuerdos/helperAcuerdos';
import { MDBDataTable } from 'mdbreact';
import "moment/locale/es";
var ramasEmpresas = []
var totalesEmpresasbd = []
var nn = []
var contratobd = null
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
var colorDominio = store.getState().colorDominio
class MediosPago extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bodegasbd: [],
            productosbd: [],
            productoSeleccinado: {},
            bodegaSeleccionada: {},
            costoN: '',
            cantidadN: '',
            productostotal: [],
            totalCosto: 0,
            subTotal: 0,
            idEditar: 0,
            //DAtos a guardar
            descripcionN: '',
            minimoValor: '',
            valorn: '',
            modoEditar: false,
            nimpuesto: null,
            //Proveedores bd
            proveedoresbd: [],
            movimientosbd: [],
            checkOrdenCompra: false,
            facturaN: '',
            movimientoSeleccionado: [],
            edicionProducto: false,
            cambbioInicio: false,
            //Prueba
            nodeEmpresas: [],
            checked: [],
            expanded: [],
            precioFlexible: false,
            boolTipo: false,
            cambbioFin: false,
            //Datos a guardar
            codigoN: '',
            selectedDate: new Date(),
            selectedDateFin: new Date(),
            proveedorSeleccionado: {},
            nombreAcuerdoN: '',
            tipoPrecio: '$',
            precioMaximoNuevo: '',
            precioMinimoNuevo: '',
            actualizar: false,
            costoProveedor: '',
            sumaCostos: 0,
            idConvenio: 0,
            tipoEstructura: true,
            totalesEmpresasMultibd: [],
            multiEmpresasSelecionadas: [],
            response: false,
            auxiliarProductosSeleccionados: []
        };
    }

    buscarhijos = (id) => {
        var empresasss = ramasEmpresas
        if (ramasEmpresas.length === 0) {
            totalesEmpresasbd.map((empresas) => {
                if (empresas.empresas_id === id) {
                    empresasss.push({
                        value: empresas.id,
                        label: empresas.alias,
                        tipo: empresas.empresas_tipos_id,
                        children: this.buscar_Subhijos(empresas.id)
                    })
                }
            })
        }
        this.setState({ nodeEmpresas: empresasss })
    }

    buscar_Subhijos = (id) => {
        var hijiii = []
        totalesEmpresasbd.map((empresas) => {
            if (empresas.empresas_id === id) {
                hijiii.push({
                    value: empresas.id,
                    label: empresas.alias,
                    tipo: empresas.empresas_tipos_id,
                    children: this.buscar_Subhijos(empresas.id)

                })
                this.setState({ actualizar: true })
            }
        })
        return hijiii
    }


    agregarObjetosSeleccionados = (vector, stateVector, vectorAuxiliar, stateVectorAuxiliar, posicion) => {
        if (!vector[posicion]) {
            vectorAuxiliar.push(
                vector[posicion]
            )
        }
        vector.splice(posicion, 1)
        this.setState({
            [stateVector]: vector,
            [stateVectorAuxiliar]: vectorAuxiliar
        });
    }


    componentDidMount() {
        this.intervalId = setInterval(this.timer.bind(this), 300000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    timer() {
        this.setState({
            currentCount: this.state.currentCount - 1
        })
        if (this.state.currentCount < 1) {
            clearInterval(this.intervalId);
        }
        actualizarToken()
    }

    obtenerDatosContrato = () => {
        nn = []
        contratobd = this.props.contratobd
        if (contratobd != null) {
            var { contrato, proveedores, productos_empresas, contratos_empresas } = contratobd
            var productoAux = []
            var empresasAux = []
            var multiEmpresas = []
            if (productos_empresas != null) {
                productos_empresas.map((producto) => {
                    productoAux.push({
                        id: producto.contratos_producto.id,
                        estado: "A",
                        productos_id: producto.producto.id,
                        producto: producto.producto.descripcion,
                        precio_base: producto.contratos_producto.precio_base,
                        plu: producto.producto.plu,
                        precio_maximo: producto.contratos_producto.precio_maximo,
                        precio_minimo: producto.contratos_producto.precio_minimo,
                        precio_Flexible: producto.contratos_producto.precio_flexible,
                        tipo_precio: producto.contratos_producto.tipo_precio,
                        proveedores_productos_id: proveedores[0].id,
                        precio_costo: producto.contratos_producto.precio_base,
                        unidadContenidoValor: producto.producto.unidades_contenido_desc.valor,
                        unidadBasicaDesc: producto.producto.unidad_basica_desc
                    })
                })
            }
            contratos_empresas.map((empresas) => {
                multiEmpresas.push({
                    value: empresas.empresas.id,
                    label: empresas.empresas.alias,
                    empresa: empresas.empresas
                })
                empresasAux.push(empresas.empresas.id)
                if (!nn.includes(empresas.empresas)) {
                    nn.push(empresas.empresas)
                }
            })
            this.setState({
                idConvenio: contrato.id,
                codigoN: contrato.numero_contrato,
                selectedDate: contrato.fecha_inicio,
                selectedDateFin: contrato.fecha_fin,
                nombreAcuerdoN: contrato.descripcion,
                proveedorSeleccionado: {
                    id: proveedores[0].pr_id,
                    value: proveedores[0].id,
                    label: proveedores[0].nombres + " " + proveedores[0].apellidos + " -- " + proveedores[0].identificacion
                },
                productostotal: productoAux,
                checked: empresasAux,
                multiEmpresasSelecionadas: multiEmpresas
            })
            Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/proveedores/' + proveedores[0].pr_id, { headers: { Authorization: ntoken } })
                .then((res) => {
                    if (res.data != null) {
                        var prod = []
                        res.data.data.map((producto) => {
                            prod.push({
                                value: producto.id,
                                label: producto.plu + " -- " + producto.descripcion,
                                //label: producto.descripcion + " -- " + producto.plu,
                                producto: producto.descripcion,
                                plu: producto.plu,
                                ppid: producto.pp_id,
                                impuestos: producto.impuestos,
                                unidadContenidoValor: producto.unidades_contenido_desc.valor,
                                unidadBasicaDesc: producto.unidad_basica_desc
                            })
                        })
                        this.setState({ productosbd: prod })
                        this.revisarProductosRepetidos()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    componentWillMount() {
        this.obtenerDatosContrato()
        this.obtenerEmpresas()
        this.obtenerProveedores()
    }

    obtenerEmpresas = async () => {
        const { usuario, empresasHijos } = store.getState()
        const { datos, totales } = await getEmpresasAcuerdos(empresasHijos)
        totalesEmpresasbd = datos
        this.setState({
            totalesEmpresasMultibd: totales
        })
        this.buscarhijos(usuario.empresas_id)
    }

    obtenerProveedores = async () => {
        const { usuario, dominio } = store.getState()
        this.setState({
            proveedoresbd: await getProveedoresAcuerdos(usuario.empresas_id + "," + dominio)
        })
    }



    cambiarContacto = () => {
        this.setState({ boolTipo: !this.state.boolTipo })
        if (this.state.boolTipo) {
            this.setState({ tipoPrecio: '$' })
        } else {
            this.setState({ tipoPrecio: '%' })
        }
    }

    handleChangeCheck = name => event => {
        var estado = (event.target.value === 'false' ? false : true)
        this.setState({
            [name]: !estado
        });
    }

    eliminarSeleccionada = (id) => {
        var mempresas = this.state.checked
        var pos = mempresas.indexOf(id)
        var multiSelect = this.state.multiEmpresasSelecionadas
        nn.map((empres) => {
            if (empres.id === id) {
                nn.splice(nn.indexOf(empres), 1)
            }
        })
        this.state.multiEmpresasSelecionadas.map((emp, key) => {
            if (emp.value === id) {
                multiSelect.splice(key, 1)
            }
        })
        mempresas.splice(pos, 1)
        this.setState({ checked: mempresas, multiEmpresasSelecionadas: multiSelect })
    }

    setearMiles = (numero) => {
        var usuario = store.getState().usuario
        if (usuario.empresas.paises_moneda === "USD") {
            return (new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(numero))
        } else {
            return (new Intl.NumberFormat("de-DE").format(parseFloat(numero)))
        }

    }

    cambiarVistaEmprea = (bool) => {
        this.setState({ tipoEstructura: bool })
    }

    eliminarProducto = (e) => {
        var mproducto = this.state.productostotal
        var ntotal = this.state.totalCosto
        var nsubtotal = this.state.subTotal
        ntotal = ntotal - parseFloat(mproducto[e].costo)
        nsubtotal = nsubtotal - parseFloat(mproducto[e].subtotal)
        mproducto.splice(e, 1)
        this.agregarObjetosSeleccionados(this.state.auxiliarProductosSeleccionados, 'auxiliarProductosSeleccionados', this.state.productosbd, 'productosbd', e)
        this.setState({ productostotal: mproducto, totalCosto: ntotal, subTotal: nsubtotal })
    }

    eliminarProductobd = (e) => {
        var mproducto = this.state.productostotal
        var ntotal = this.state.totalCosto
        var nsubtotal = this.state.subTotal
        ntotal = ntotal - parseFloat(mproducto[e].costo)
        nsubtotal = nsubtotal - parseFloat(mproducto[e].subtotal)
        let rtienda = this.state.auxiliarProductosSeleccionados;
        let posicionReal = e;
        for (let r = 0; r < rtienda.length; r++) {
            let vt = rtienda[r];
            if (parseInt(vt.value) === parseInt(mproducto[e].productos_id)) {
                posicionReal = r;
                break;
            }

        }
        if (mproducto[e].id != 0) {
            this.agregarObjetosSeleccionados(this.state.auxiliarProductosSeleccionados, 'auxiliarProductosSeleccionados', this.state.productosbd, 'productosbd', posicionReal)
            mproducto.splice(e, 1)
        } else {
            this.agregarObjetosSeleccionados(this.state.auxiliarProductosSeleccionados, 'auxiliarProductosSeleccionados', this.state.productosbd, 'productosbd', posicionReal)
            mproducto.splice(e, 1)
        }
        this.setState({ productostotal: mproducto, totalCosto: ntotal, subTotal: nsubtotal })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
        switch (name) {
            case 'costoProveedor':
                var costo = event.target.value
                var costoTotal = costo
                if (this.state.productoSeleccinado.impuestos != null) {
                    this.state.productoSeleccinado.impuestos.map((impuesto) => {
                        if (impuesto.porcentaje_valor === "%") {
                            costoTotal = parseFloat(costoTotal) + ((parseFloat(costoTotal) * parseFloat(impuesto.valor) / 100))
                        }
                    })
                    this.state.productoSeleccinado.impuestos.map((impuesto) => {
                        if (impuesto.porcentaje_valor === "$") {
                            costoTotal = parseFloat(costoTotal) + parseFloat(impuesto.valor)
                        }
                    })
                }
                this.setState({ costoN: costoTotal })
                break;
        }

    };

    agregarProducto = () => {
        var p = this.state.productostotal
        var auxiliar = this.state.auxiliarProductosSeleccionados
        var idsTeindas = []
        auxiliar.map((prod) => {
            idsTeindas.push(prod.value)
        })
        if (this.state.productoSeleccinado == null || JSON.stringify(this.state.productoSeleccinado) == '{}') {
            swal("ERROR", "DEBE SELECCIONAR UN PRODUCTO", "warning")
        } else if (parseFloat(this.state.costoProveedor) < 0) {
            swal("ERROR", "DEBE INGRESAR EL COSTO Y SER MAYOR A CERO", "warning")
        } else if (this.state.costoProveedor.trim() == '') {
            swal("ERROR", "DEBE INGRESAR EL COSTO", "warning")
        } else {
            p.push({
                estado: "A",
                productos_id: this.state.productoSeleccinado.value,
                producto: this.state.productoSeleccinado.producto,
                precio_base: this.state.costoN,
                plu: this.state.productoSeleccinado.plu,
                precio_maximo: this.state.precioFlexible ? this.state.precioMaximoNuevo : this.state.costoN == '' ? 0 : this.state.costoN,
                precio_minimo: this.state.precioFlexible ? this.state.precioMinimoNuevo : this.state.costoN == '' ? 0 : this.state.costoN,
                precio_Flexible: this.state.precioFlexible,
                tipo_precio: this.state.tipoPrecio,
                ppid: this.state.productoSeleccinado.ppid,
                precio_costo: parseFloat(this.state.costoProveedor)
            })
            console.log(p)
            this.agregarObjetosSeleccionados(this.state.productosbd, 'productosbd', this.state.auxiliarProductosSeleccionados, 'auxiliarProductosSeleccionados', this.state.productosbd.indexOf(this.state.productoSeleccinado))
            this.setState({ costoProveedor: '', sumaCostos: 0, costoN: '', precioMaximoNuevo: '', precioMinimoNuevo: '', modoEditar: false, productostotal: p, totalCosto: this.state.totalCosto + parseFloat(this.state.costoN), subTotal: this.state.subTotal + parseFloat(this.state.costoN * this.state.cantidadN), productoSeleccinado: {}, cantidadN: '', costoN: '' })
        }
    }

    revisarProductosRepetidos = () => {
        var posiciones = []
        var ids = []
        this.state.productostotal.map((producto, key) => {
            ids.push(parseInt(producto.productos_id))
        })
        this.state.productosbd.map((ingrediente, key) => {
            //console.log(parseInt(ingrediente.value))
            if (ids.includes(parseInt(ingrediente.value))) {
                posiciones.push(key)
            }
        })
        posiciones.map((posicion, llave) => {
            this.agregarObjetosSeleccionados(this.state.productosbd, 'productosbd', this.state.auxiliarProductosSeleccionados, 'auxiliarProductosSeleccionados', posicion - llave)
        })
    }
    agregarProductoNBD = () => {
        var p = this.state.productostotal
        var auxiliar = this.state.auxiliarProductosSeleccionados
        var idsTeindas = []
        auxiliar.map((prod) => {
            idsTeindas.push(prod.value)
        })
        if (this.state.productoSeleccinado == null || JSON.stringify(this.state.productoSeleccinado) == '{}') {
            swal("ERROR", "DEBE SELECCIONAR UN PRODUCTO", "warning")
        } else if (idsTeindas.includes(this.state.productoSeleccinado.value)) {
            swal("ERROR", "ESTE PRODUCTO YA ESTA EN EL CONTRATO", "warning")
        } else if (parseFloat(this.state.costoProveedor) < 0) {
            swal("ERROR", "DEBE INGRESAR EL COSTO Y SER MAYOR A CERO", "warning")
        } else if (this.state.costoProveedor.trim() == '') {
            swal("ERROR", "DEBE INGRESAR EL COSTO", "warning")
        } else {
            p.push({
                id: 0,
                estado: "A",
                productos_id: this.state.productoSeleccinado.value,
                producto: this.state.productoSeleccinado.producto,
                precio_base: this.state.costoProveedor == '' ? 0 : this.state.costoProveedor,
                plu: this.state.productoSeleccinado.plu,
                precio_maximo: this.state.precioFlexible ? this.state.precioMaximoNuevo : this.state.costoProveedor == '' ? 0 : this.state.costoProveedor,
                precio_minimo: this.state.precioFlexible ? this.state.precioMinimoNuevo : this.state.costoProveedor == '' ? 0 : this.state.costoProveedor,
                precio_Flexible: this.state.precioFlexible,
                tipo_precio: this.state.tipoPrecio,
                ppid: this.state.productoSeleccinado.ppid,
                precio_costo: this.state.costoProveedor == '' ? 0 : this.state.costoProveedor
            })
            this.agregarObjetosSeleccionados(this.state.productosbd, 'productosbd', this.state.auxiliarProductosSeleccionados, 'auxiliarProductosSeleccionados', this.state.productosbd.indexOf(this.state.productoSeleccinado))
            this.setState({ costoProveedor: '', sumaCostos: 0, costoN: '', precioMaximoNuevo: '', precioMinimoNuevo: '', modoEditar: false, productostotal: p, totalCosto: this.state.totalCosto + parseFloat(this.state.costoN), subTotal: this.state.subTotal + parseFloat(this.state.costoN * this.state.cantidadN), productoSeleccinado: {}, cantidadN: '', costoN: '' })
        }
    }

    agregarCheck = (n) => {
        this.setState({ checked: n })
        var multiEmpresas = []
        this.state.totalesEmpresasMultibd.map((emp, key) => {
            if (n.includes(emp.value)) {
                multiEmpresas.push(emp)
            }
        })
        nn.map((dat, key) => {
            if (!n.includes(dat.id)) {
                nn.splice(dat)
            }
        })
        this.setState({
            multiEmpresasSelecionadas: multiEmpresas
        })
    }

    validarFormulario = () => {
        if (this.state.nombreAcuerdoN.trim == "" || this.state.nombreAcuerdoN.length == 0) {
            swal("FALLO AL CREAR", "EL NOMBRE ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (JSON.stringify(this.state.proveedorSeleccionado) == '{}') {
            swal("FALLO AL CREAR", "DEBE ESCOGER EL PROVEEDOR", "warning")
            return false
        } else if (this.state.codigoN.trim == "" || this.state.codigoN.length == 0) {
            swal("FALLO AL CREAR", "EL CODIGO ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (nn.length == 0) {
            swal("FALLO AL CREAR", "POR LO MENOS LE DEBES ASIGNAR EL ACUERDO A UNA EMPRESA", "warning")
            return false
        } else if (this.state.productostotal.length == 0) {
            swal("FALLO AL CREAR", "POR LO MENOS DEBES AGREGAR UN PRODUCTO", "warning")
            return false
        } else if (moment(new Date(this.state.selectedDateFin.toString())).format("YYYY-MM-DD HH:mm:ss") <= moment(new Date(this.state.selectedDate.toString())).format("YYYY-MM-DD HH:mm:ss")) {
            swal("FALLO AL CREAR", "LA FECHA FINAL DEL ACUERDO DEBE SER MAYOR A LA FECHA INICIAL", "warning")
            return false
        } else {
            this.setState({
                response: true
            })
            return true
        }

    }

    editar = () => {
        var usuario = store.getState().usuario
        var validar = this.validarFormulario()
        var datos = {
            "convenio": {
                "id": this.state.idConvenio,
                "empresas_id": usuario.empresas_id,  								//empresa tipo 3 dende se esta trabajando
                "responsable_id": usuario.p_id,							//el usuario que esta realizando el documento
                "descripcion": this.state.nombreAcuerdoN, 	//nombre del convenio
                "tercero_id": this.state.proveedorSeleccionado.value, 								//proveedor con que se esta haciendo el convenio
                "numero_contrato": this.state.codigoN, 					//numero del convenio
                "fecha_inicio": this.state.cambbioInicio ? moment(new Date(this.state.selectedDate.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "fecha_fin": this.state.cambbioFin ? moment(new Date(this.state.selectedDateFin.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date(this.state.selectedDateFin.toString())).format("YYYY-MM-DD HH:mm:ss"),
                "create_user": usuario.p_id
            },
            "empresas":
                nn.map((data) => {
                    return (
                        {
                            "empresas_final_id": data.id,
                        }
                    );
                })
            ,
            "productos":
                this.state.productostotal.map((data) => {
                    return {
                        "id": data.id,
                        "productos_id": data.productos_id,
                        "precio_flexible": data.precio_Flexible ? 'S' : 'N',
                        "tipo_precio": data.tipo_precio,
                        "precio_maximo": data.precio_maximo,
                        "precio_minimo": data.precio_minimo,
                        "precio_costo": data.precio_costo,
                        "precio_base": data.precio_costo != null ? data.precio_costo : 0,
                        "proveedores_productos_id": this.state.proveedorSeleccionado.value,
                        "update_user": usuario.p_id,
                        "estado": data.estado
                    }
                })
        }
        if (validar) {
            Axios.put(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/convenios', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    console.log(response)
                    this.setState({
                        response: false
                    })
                    if (response.status === 201) {
                        swal({
                            title: response.data.message,
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_ACUERDO",
                                        ventanaAcuerdo: false
                                    })
                                    //this.setState({loader:true,descripcionN:'',selectedDate:new Date(),selectedDateFin:new Date(),checkIPorcentaje:true,checkIValor:false,valorn:false,cambbioInicio: false,cambbioFin:false})
                                    break;

                                default:

                            }
                        });
                    } else if (response.status == 205) {
                        swal("ERROR", "LA SESION HA CADUCADO", "warning")
                        setTimeout(function () { window.location.href = '/'; }, 3000);
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    registrar = () => {
        var usuario = store.getState().usuario
        var validar = this.validarFormulario()
        var datos = {
            "convenio": {
                "empresas_id": usuario.empresas_id,  								//empresa tipo 3 dende se esta trabajando
                "responsable_id": usuario.p_id,							//el usuario que esta realizando el documento
                "descripcion": this.state.nombreAcuerdoN, 	//nombre del convenio
                "tercero_id": this.state.proveedorSeleccionado.value, 								//proveedor con que se esta haciendo el convenio
                "numero_contrato": this.state.codigoN, 					//numero del convenio
                "fecha_inicio": this.state.cambbioInicio ? moment(new Date(this.state.selectedDate.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "fecha_fin": this.state.cambbioFin ? moment(new Date(this.state.selectedDateFin.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "create_user": usuario.p_id
            },
            "empresas":
                nn.map((data) => {
                    return (
                        {
                            "empresas_final_id": data.id
                        }
                    );
                })
            ,
            "productos":
                this.state.productostotal.map((data) => {
                    return {
                        "productos_id": data.productos_id,
                        "precio_flexible": data.precio_Flexible ? 'S' : 'N',
                        "tipo_precio": data.tipo_precio,
                        "precio_maximo": data.precio_maximo,
                        "precio_minimo": data.precio_minimo,
                        "precio_costo": data.precio_costo,
                        "precio_base": data.precio_costo,
                        "proveedores_productos_id": data.ppid,
                        "update_user": usuario.p_id
                    }
                })

        }
        if (validar) {
            Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/convenios', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    this.setState({
                        response: false
                    })
                    if (response.status === 201) {
                        swal({
                            title: response.data.message,
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_ACUERDO",
                                        ventanaAcuerdo: false
                                    })
                                    //this.setState({loader:true,descripcionN:'',selectedDate:new Date(),selectedDateFin:new Date(),checkIPorcentaje:true,checkIValor:false,valorn:false,cambbioInicio: false,cambbioFin:false})
                                    break;

                                default:

                            }
                        });
                    } else if (response.status == 205) {
                        swal("ERROR", "LA SESION HA CADUCADO", "warning")
                        setTimeout(function () { window.location.href = '/'; }, 3000);
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                    }
                })
        }
    }

    cancelar = () => {
        store.dispatch({
            type: "CREACION_ACUERDO",
            ventanaAcuerdo: false
        })
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        switch (name) {
            case 'proveedorSeleccionado':
                Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/proveedores/' + value.id, { headers: { Authorization: ntoken } })
                    .then((res) => {
                        if (res.data != null) {
                            var prod = []
                            console.log(res.data)
                            res.data.data.map((producto) => {
                                if (producto.estado === "A") {
                                    prod.push({
                                        value: producto.id,
                                        label: producto.plu + " -- " + producto.descripcion,
                                        //label: producto.descripcion + " -- " + producto.plu,
                                        producto: producto.descripcion,
                                        plu: producto.plu,
                                        ppid: producto.pp_id,
                                        impuestos: producto.impuestos,
                                        unidadContenidoValor: producto.unidades_contenido_desc.valor,
                                        unidadBasicaDesc: producto.unidad_basica_desc
                                    })
                                }
                            })
                            this.setState({ productosbd: prod })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                break;
            case 'productoSeleccinado':
                this.setState({ costoN: '', costoProveedor: '' })
                break;
            case "multiEmpresasSelecionadas":
                var checke = []
                value.map((emp, key) => {
                    checke.push(emp.value)
                })
                this.setState({ checked: checke })
                break;
        }
    };

    cambiarFecha = (fecha, cambio) => date => {
        this.setState({
            [fecha]: date,
            [cambio]: true
        })
    }

    render() {
        const { modoVista } = this.props
        const { productostotal } = this.state
        totalesEmpresasbd.map((n) => {
            if (this.state.checked.includes(n.id)) {
                if (!nn.includes(n)) {
                    nn.push(n)
                }
            }
        })
        var contratobd = this.props.contratobd
        var sumaTotal = parseFloat(this.state.costoProveedor)
        //console.log(sumaTotal)
        const idpopover = this.state.popoverOpen ? 'simple-popover' : undefined;
        return (
            <div>
                <AppBar style={{ backgroundColor: colorDominio }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Close"
                            onClick={this.cancelar.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            style={{ marginLeft: "theme.spacing(2)", flex: 1, color: '#fff' }}
                        >
                            {modoVista ? 'ACUERDO ' + this.state.codigoN : this.props.contratobd != null ? 'EDICION DE ACUERDO' : 'CREACION DE ACUERDO'}
                        </Typography>
                        {
                            modoVista
                                ?
                                null
                                :
                                this.props.contratobd != null
                                    ?
                                    <Button style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} onClick={this.editar.bind(this)} disabled={this.state.response}
                                    >
                                        GUARDAR
              </Button>
                                    :
                                    <Button style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} onClick={this.registrar.bind(this)} disabled={this.state.response}
                                    >
                                        TERMINAR Y GUARDAR
              </Button>
                        }

                    </Toolbar>
                </AppBar>
                <div className="row align-middle mx-auto" style={{ marginTop: '7%' }}>
                    <div className="col col-md-12">
                        <div className="row mx-auto">
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">Codigo</label>
                                <input id="numordencompra" disabled={modoVista || contratobd != null} min="1" value={this.state.codigoN} className="form-control" type="number" min="1" autocomplete="off" autocomplete="off" onChange={this.handleChange('codigoN')} />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                    <DatePicker
                                        format="yyyy-MM-dd HH:mm"
                                        id="datetime"
                                        disabled={modoVista || contratobd != null}
                                        value={this.state.selectedDate}
                                        onChange={this.cambiarFecha('selectedDate', 'cambbioInicio')}
                                        className="rounded form-control"
                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                    <DatePicker
                                        format="yyyy-MM-dd HH:mm"
                                        id="datetime"
                                        disabled={modoVista}
                                        value={this.state.selectedDateFin}
                                        onChange={this.cambiarFecha('selectedDateFin', 'cambbioFin')}
                                        className="rounded form-control"
                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="row mx-auto">
                            <div className="col-md-6">
                                <label htmlFor="numordencompra" className="col-form-label">Nombre del acuerdo</label>
                                <input id="numordencompra" disabled={modoVista || contratobd != null} value={this.state.nombreAcuerdoN} className="form-control" type="text" autocomplete="off" onChange={this.handleChange('nombreAcuerdoN')} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="numordencompra" className="col-form-label">Proveedor</label>
                                <Selects
                                    placeholder="Seleccione"
                                    value={this.state.proveedorSeleccionado}
                                    isDisabled={modoVista || contratobd != null}
                                    onChange={this.handleChanges('proveedorSeleccionado')}
                                    options={this.state.proveedoresbd}
                                />
                            </div>
                        </div>
                        {modoVista
                            ?
                            null
                            :
                            <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                <div className="col-md-8 border rounded" style={{ marginTop: '10px' }}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                                <h5 className="mx-auto">Ingrese los datos del producto a agregar</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="numordencompra" className="col-form-label">PRODUCTO</label>
                                                    <Selects
                                                        placeholder="Seleccione"
                                                        value={this.state.productoSeleccinado}
                                                        onChange={this.handleChanges('productoSeleccinado')}
                                                        options={this.state.productosbd}
                                                        isDisabled={JSON.stringify(this.state.proveedorSeleccionado) == '{}'}
                                                    />
                                                </div>
                                                <div className="col-md-3 col-sm-8">
                                                    <label htmlFor="numordencompra" className="col-form-label">Costo</label>
                                                    <div class="input-group" >
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1">$</span>
                                                        </div>
                                                        <input id="numordencompra" min="1" value={this.state.costoProveedor} disabled={JSON.stringify(this.state.productoSeleccinado) == '{}'} className="form-control" type="number" min="1" autocomplete="off" onChange={this.handleChange('costoProveedor')} />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-8">
                                                    <label htmlFor="numordencompra" className="col-form-label">Costo total</label>
                                                    <div class="input-group" >
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1">$</span>
                                                        </div>
                                                        <input id="numordencompra" min="1" value={parseFloat(this.state.costoN).toFixed(3)} className="form-control" type="number" min="1" disabled={true} autocomplete="off" onChange={this.handleChange('costoN')} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginLeft: '10px' }}>
                                                <div className="col-md-2 col-sm-6 bottom-aligned" style={{ marginTop: "0" }}>
                                                    <FormControlLabel d control={<Checkbox style={{ padding: "0" }} className="mx-auto my-auto" value={this.state.precioFlexible} onChange={this.handleChangeCheck("precioFlexible")} />} label="Costo Flexible" />
                                                </div>
                                                <div className="col-md-2 col-sm-4">
                                                    <div className="row">
                                                        <label htmlFor="numordencompra" className="col-form-label">Tipo de costo</label>
                                                    </div>
                                                    <div className="row">
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    onChange={this.cambiarContacto.bind(this)}
                                                                    value="checkedA"
                                                                />
                                                            }
                                                            disabled={!this.state.precioFlexible}
                                                            label={this.state.boolTipo ? '$' : '%'}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-md-3">
                                                    <label htmlFor="numordencompra" className="col-form-label">{this.state.boolTipo ? 'Porcentaje minimo' : 'Costo minimo'}</label>
                                                    <div class="input-group " style={{ marginBottom: '0!important' }}>
                                                        <div class="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                                            <span class="input-group-text" id="basic-addon1">{this.state.tipoPrecio}</span>
                                                        </div>
                                                        <input id="numordencompra" min="1" disabled={!this.state.precioFlexible} className="form-control" type="number" min="1" autocomplete="off" value={this.state.precioMinimoNuevo} onChange={this.handleChange('precioMinimoNuevo')} />
                                                    </div>
                                                </div>
                                                <div className="col col-md-3">
                                                    <label htmlFor="numordencompra" className="col-form-label">{this.state.boolTipo ? 'Porcentaje maximo' : 'Costo maximo'}</label>
                                                    <div class="input-group " style={{ marginBottom: '0!important' }}>
                                                        <div class="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                                            <span class="input-group-text" id="basic-addon1">{this.state.tipoPrecio}</span>
                                                        </div>
                                                        <input id="numordencompra" min="1" disabled={!this.state.precioFlexible} className="form-control" type="number" min="1" autocomplete="off" value={this.state.precioMaximoNuevo} onChange={this.handleChange('precioMaximoNuevo')} />
                                                    </div>
                                                </div>
                                                <div className="col-md-2  col-sm-4 my-auto">
                                                    <button className="btn btn-primary bottom-aligned btn-block" onClick={contratobd != null ? () => this.agregarProductoNBD() : () => this.agregarProducto()}>AGREGAR</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 border rounded" style={{ marginTop: '10px' }}>
                                    <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                        <h5 className="mx-auto">LIQUIDACION DE COSTO</h5>
                                    </div>
                                    <div className="row mx-auto" >
                                        <div className="col-md-12">
                                            <h5 className="mx-auto">{this.state.productoSeleccinado.label}</h5>
                                        </div>
                                    </div>
                                    {this.state.costoProveedor != '' || this.state.costoProveedor != 0
                                        ?
                                        <div className="row mx-auto" >
                                            <div className="col-md-8">
                                                <h6 className="mx-auto">COSTO</h6>
                                            </div>
                                            <div className="col-md-4">
                                                <h6 className="mx-auto">{'$' + this.setearMiles(parseFloat(this.state.costoProveedor).toFixed(3))}</h6>
                                            </div>
                                        </div> : null}
                                    {this.state.costoProveedor != '' || this.state.costoProveedor != 0
                                        ?
                                        <div className="row mx-auto" >
                                            <div className="col-md-8">
                                                <h6 className="mx-auto">EMBALAJE</h6>
                                            </div>
                                            <div className="col-md-4">
                                                <h6 className="mx-auto">{parseInt(this.state.productoSeleccinado.unidadContenidoValor) + " " + this.state.productoSeleccinado.unidadBasicaDesc}</h6>
                                            </div>
                                        </div> : null}
                                    {this.state.costoProveedor != '' || this.state.costoProveedor != 0
                                        ?
                                        <div className="row mx-auto" >
                                            <div className="col-md-8">
                                                <h6 className="mx-auto">COSTO NETO</h6>
                                            </div>
                                            <div className="col-md-4">
                                                <h6 className="mx-auto">{'$' + this.setearMiles((parseFloat(this.state.costoProveedor) * parseInt(this.state.productoSeleccinado.unidadContenidoValor)).toFixed(3))}</h6>
                                            </div>
                                        </div> : null}
                                    {
                                        this.state.productoSeleccinado.impuestos != null && (this.state.costoProveedor != '' || this.state.costoProveedor != 0)
                                            ?
                                            this.state.productoSeleccinado.impuestos.map((impuesto) => {
                                                if (impuesto.porcentaje_valor == "%") {
                                                    sumaTotal = sumaTotal + ((sumaTotal * parseFloat(impuesto.valor)) / 100)
                                                    return (
                                                        <div className="row mx-auto" >
                                                            <div className="col-md-8">
                                                                <h6 className="mx-auto">{impuesto.descripcion}</h6>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <h6 className="mx-auto">{'$' + this.setearMiles((((parseFloat(this.state.costoProveedor) * parseFloat(impuesto.valor)) / 100) * parseInt(this.state.productoSeleccinado.unidadContenidoValor)).toFixed(3))}</h6>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            })
                                            :
                                            null
                                    }
                                    {
                                        this.state.productoSeleccinado.impuestos != null && (this.state.costoProveedor != '' || this.state.costoProveedor != 0)
                                            ?
                                            this.state.productoSeleccinado.impuestos.map((impuesto) => {
                                                if (impuesto.porcentaje_valor == "$") {
                                                    sumaTotal = sumaTotal + parseFloat(impuesto.valor)
                                                    return (
                                                        <div className="row mx-auto" >
                                                            <div className="col-md-8">
                                                                <h6 className="mx-auto">{impuesto.descripcion}</h6>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <h6 className="mx-auto">{'$' + this.setearMiles(parseFloat(parseFloat(impuesto.valor) * parseInt(this.state.productoSeleccinado.unidadContenidoValor)).toFixed(3))}</h6>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            })
                                            :
                                            null
                                    }
                                    {this.state.costoProveedor != '' || this.state.costoProveedor != 0
                                        ?
                                        <div className="row mx-auto" >
                                            <div className="col-md-8">
                                                <h6 className="mx-auto">COSTO TOTAL</h6>
                                            </div>
                                            <div className="col-md-4">
                                                <h6 className="mx-auto">{'$' + this.setearMiles((parseFloat(this.state.costoN) * parseInt(this.state.productoSeleccinado.unidadContenidoValor)).toFixed(3))}</h6>
                                            </div>
                                        </div> : null}
                                </div>
                            </div>
                        }


                        {
                            this.state.modoEditar
                                ?
                                <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                    <div className="col-md-12">
                                        <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                            <h5 className="mx-auto">Ingrese los datos del producto a agregar</h5>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <label htmlFor="numordencompra" className="col-form-label">PRODUCTO</label>
                                                <Selects
                                                    isDisabled={true}
                                                    placeholder="Seleccione"
                                                    value={this.state.productoSeleccinado}
                                                    onChange={this.handleChanges('productoSeleccinado')}
                                                    options={this.state.productosbd}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="numordencompra" className="col-form-label">Costo unitario</label>
                                                <div class="input-group" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">$</span>
                                                    </div>
                                                    <input id="numordencompra" min="1" value={this.state.costoN} className="form-control" type="number" min="1" autocomplete="off" onChange={this.handleChange('costoN')} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="numordencompra" className="col-form-label">Cantidad</label>
                                                <input id="numordencompra" value={this.state.cantidadN} className="form-control" type="number" min="1" autocomplete="off" onChange={this.handleChange('cantidadN')} />
                                            </div>
                                            <div className="col-md-1 bottom-aligned">
                                                <button className="btn btn-primary bottom-aligned" min="1" onClick={contratobd != null ? () => this.agregarProductoNBD() : () => this.agregarProducto()}>AGREGAR</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }

                        <MDBDataTable
                            className="col-md-12"
                            searchLabel="Buscar"
                            paginationLabel={["Anterior", "Siguente"]}
                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                            entriesLabel="Filas a mostrar"
                            tbodyColor="primary-color"
                            theadColor="#FF8800"
                            exportToCSV={true}
                            striped
                            bordered
                            autoWidth={false}
                            responsive={true}
                            hover
                            data={{
                                columns: columnasProductosAcuerdos,
                                rows: productostotal.map((row, key) => ({
                                    plu: row.plu,
                                    producto: row.producto,
                                    costo: '$' + this.setearMiles(parseFloat(row.precio_costo).toFixed(3)),
                                    flexible: row.precio_Flexible ? 'Si' : 'No',
                                    costo_minimo: row.precio_Flexible ? row.tipo_precio == '$' ? '$' + this.setearMiles(parseFloat(row.precio_minimo).toFixed(3)) : parseFloat(row.precio_minimo).toFixed(3) + '%' : 'NO APLICA',
                                    costo_maximo: row.precio_Flexible ? row.tipo_precio == '$' ? '$' + this.setearMiles(parseFloat(row.precio_maximo).toFixed(3)) : parseFloat(row.precio_maximo).toFixed(3) + '%' : 'NO APLICA',
                                    acciones: modoVista ? null
                                        : <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={contratobd != null ? () => this.eliminarProductobd(key) : () => this.eliminarProducto(key)}><DeleteIcon /></button>
                                }))
                            }} />

                        <div className="row mx-auto" style={{ marginTop: '10px', marginBottom: '20px' }}>
                            <div className="col-md-6 border rounded" style={{ marginTop: '10px' }}>
                                <div className="row mx-auto  bottom-aligned" style={{ marginTop: '10px' }}>
                                    <h5 className="mx-auto">Escoja las empresas</h5>
                                    <ButtonGroup aria-label="Basic example">
                                        <Buttonn variant="secondary" onClick={() => this.cambiarVistaEmprea(true)}><EstructuraIcon /></Buttonn>
                                        <Buttonn variant="secondary" onClick={() => this.cambiarVistaEmprea(false)}><DespleglabeIcon /></Buttonn>
                                    </ButtonGroup>
                                </div>
                                {this.state.tipoEstructura
                                    ?
                                    <div className="row mx-auto" style={{ marginBottom: '15px' }}>
                                        <CheckboxTree
                                            showNodeIcon={true}
                                            nodes={this.state.nodeEmpresas}
                                            checked={this.state.checked}
                                            disabled={modoVista}
                                            expanded={this.state.expanded}
                                            onCheck={checked => this.agregarCheck(checked)}
                                            onExpand={expanded => this.setState({ expanded })}
                                        />
                                    </div>
                                    :
                                    <div className="col-md-12" style={{ marginBottom: '15px' }}>
                                        <label htmlFor="nombreprov" className="col-form-label">TIENDAS</label>
                                        <Selects
                                            placeholder="Seleccione o escriba"
                                            value={this.state.multiEmpresasSelecionadas}
                                            onChange={this.handleChanges('multiEmpresasSelecionadas')}
                                            options={this.state.totalesEmpresasMultibd}
                                            isDisabled={modoVista}
                                            isMulti
                                        />

                                    </div>
                                }
                            </div>
                            <div className="col-md-6 border rounded" style={{ marginTop: '10px' }}>
                                {this.state.checked.length == 0
                                    ? null :
                                    <Paper className="border rounded" style={{ padding: '10px', margin: '10px' }}>
                                        <h5 className="mx-auto">Tiendas</h5>
                                        {nn.map((data, key) => {
                                            // console.log(data)
                                            return (
                                                <Chip
                                                    style={{ margin: '2px' }}
                                                    key={key}
                                                    label={data.alias}
                                                    onDelete={modoVista ? null : () => this.eliminarSeleccionada(data.id)}
                                                />
                                            );
                                        })}
                                    </Paper>
                                }
                            </div>
                        </div>
                        <div>
                        </div>

                    </div>
                </div>
            </div>



        )
    }
}


export default MediosPago
