
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import 'bootstrap/dist/css/bootstrap.min.css';
import CardTittle from '@material-ui/core/CardHeader'
import GridItem from "components/Grid/GridItem.jsx";
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Server from '../../lib/server'
import store from '../../lib/store'
import Axios from 'axios'
import swal from 'sweetalert'
import Selects from 'react-select'
import moment from 'moment'
import "moment/locale/es";
var impuestoEditar = false
var consecutivoFinal = 0
class MediosPago extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idEditar: 0,
            //DAtos a guardar
            descripcionN: '',
            minimoValor: '',
            valorn: '',
            modoEditar: false,
            nimpuesto: null,
            //nresoluciones
            nresoluciones: 0,
            resolucionesmap: [],
            prefijoN: '',
            inicioN: '',
            finN: '',
            resolucionN: '',
            actualN: '',
            selectedDate: new Date(),
            selectedDateFin: new Date(),
            cambbioInicio: false,
            cambbioFin: false,
            resolucionesGuardar: [],
            resolucionActual: 0,
            cambioConsecutivo: false,
            observacionN1: '',
            observacionN2: '',
            equipobd: [],
            equipoSeleccionado: {},
            response: false,
            empresa_id: 0,
            tiposResolucionesBD: [{
                label: "F. CONVENCIONAL",
                value: 9
            }, {
                label: "F. ELECTRONICA",
                value: 15
            }],
            tipoResolucionSeleccionada: {},
            parametrosEmpresa: []
        };
    }

    componentWillMount() {
        const { usuario, empresasHijos } = store.getState()
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        switch (usuario.tipo) {
            case "1":
            case "2":
            case "3":
            case "4":
                Axios.get(Server.hostPrueba + Server.puertoEquipos + '/api/equipo/empresas/' + empresasHijos, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        var provincias = []
                        if (responsee.status == 200) {
                            responsee.data.data.map((provincia) => (
                                provincias.push({
                                    value: provincia.id,
                                    label: provincia.bodega + " -- " + provincia.serial_equipo,
                                    razonSocial: provincia.alias,
                                    bodega: provincia.bodega,
                                    empresaId: provincia.empresas_id
                                })
                            ))
                            this.setState({ equipobd: provincias })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }

                    })
                    .catch(function (error) {
                        console.error(error);
                    })
                break;
            case "5":
                Axios.get(Server.hostPrueba + Server.puertoEquipos + '/api/equipo/empresas/' + usuario.empresas_id, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        var provincias = []
                        if (responsee.status == 200) {
                            responsee.data.data.map((provincia) => (
                                provincias.push({
                                    value: provincia.id,
                                    label: provincia.bodega + " -- " + provincia.serial_equipo,
                                    razonSocial: provincia.alias,
                                    bodega: provincia.bodega,
                                    empresaId: provincia.empresas_id
                                })
                            ))
                            this.setState({ equipobd: provincias })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }

                    })
                    .catch(function (error) {
                        console.error(error);
                    })
                break;
        }

        impuestoEditar = this.props.unidadActualizar
        consecutivoFinal = this.props.consecutivoFinal
        if (impuestoEditar != null) {
            var observaciones = impuestoEditar.observaciones
            var observaciones1 = ""
            var observaciones2 = ""
            if (observaciones.includes("@@RESOLUCION")) {
                var split = observaciones.split("@@RESOLUCION")
                observaciones1 = split[0]
                observaciones2 = split[1]
            } else {
                observaciones1 = ""
                observaciones2 = observaciones
            }
            this.setState({
                modoEdicion: true,
                prefijoN: impuestoEditar.prefijo,
                resolucionN: impuestoEditar.resolucion,
                inicioN: impuestoEditar.consecutivo_inicial,
                finN: impuestoEditar.consecutivo_final,
                actualN: impuestoEditar.consecutivo_actual,
                selectedDate: impuestoEditar.fecha_inicio,
                selectedDateFin: impuestoEditar.fecha_fin,
                observacionN1: observaciones1,
                observacionN2: observaciones2,
                equipoSeleccionado: {
                    value: impuestoEditar.equipos_id,
                    label: impuestoEditar.bodegas_desc + " -- " + impuestoEditar.serial_equipo,
                    razonSocial: impuestoEditar.empresa_alias,
                    bodega: impuestoEditar.bodegas_desc,
                    empresaId: impuestoEditar.empresas_id,
                },
                tipoResolucionSeleccionada: impuestoEditar.tipo_documento === 9 ? {
                    label: "F. CONVENCIONAL",
                    value: 9
                } : {
                    label: "F. ELECTRONICA",
                    value: 15
                },
                idEditar: impuestoEditar.id,
                empresa_id: impuestoEditar.empresas_id
            })
        }
    }




    validaciones = () => {
        const { inicioN, actualN, finN, prefijoN, equipoSeleccionado, selectedDateFin, selectedDate, observacionN1, observacionN2 } = this.state
        if (inicioN.trim() === '' || parseInt(inicioN) <= 0) {
            swal("Fallo al guardar", "DEBE INGRESAR EL CONSECUTIVO INICIAL", "warning")
            return false
        } else if (parseInt(finN) <= parseInt(inicioN)) {
            swal("Fallo al guardar", "EL CONSECUTIVO FINAL DEBE SER MAYOR AL CONSECUTIVO INICIAL", "warning")
            return false
        } else if (parseInt(finN) < parseInt(actualN)) {
            swal("Fallo al guardar", "EL CONSECUTIVO FINAL DEBE SER MAYOR AL CONSECUTIVO ACTUAL", "warning")
            return false
        } else if (parseInt(actualN) < parseInt(inicioN)) {
            swal("Fallo al guardar", "EL CONSECUTIVO ACTUAL DEBE SER MAYOR O IGUAL AL CONSECUTIVO INICIAL", "warning")
            return false
        } else if (prefijoN === '') {
            swal("Fallo al guardar", "EL PREFIJO ES NECESARIO", "warning")
            return false
        } else if (equipoSeleccionado === null || JSON.stringify(equipoSeleccionado) === '{}') {
            swal("Fallo al guardar", "DEBE SELECCIONAR EL EQUIPO", "warning")
            return false
        } else if (observacionN1.trim() === '' || parseInt(observacionN1) <= 0 || observacionN2.trim() === '' || parseInt(observacionN2) <= 0) {
            swal("Fallo al guardar", "AMBAS OBSERVACIONES SON REQUERIDAS", "warning")
            return false
        } else if (moment(new Date(selectedDateFin.toString())).format("YYYY-MM-DD HH:mm:ss") <= moment(new Date(selectedDate.toString())).format("YYYY-MM-DD HH:mm:ss")) {
            swal("Fallo al guardar", "LA FECHA FINAL DEBE SER MAYOR A LA FECHA INCIAL", "warning")
        } else {
            this.setState({
                response: true
            })
            return true
        }
    }

    handleChangemoment = date => {
        this.setState({ selectedDate: date, cambbioInicio: true });
    }

    handleChangemomentFin = date => {
        this.setState({ selectedDateFin: date, cambbioFin: true });
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
    }

    agregarResolucion = () => {
        //var validado = this.validaciones()
        const { equipoSeleccionado, prefijoN, resolucionN, observacionN1, observacionN2, inicioN, actualN, finN, tipoResolucionSeleccionada } = this.state
        var validado = this.validaciones()
        var usuario = store.getState().usuario
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        let TIPO_DOCUMENTO_RECIBO = 9
        var datos = {
            "empresas_id": usuario.tipo === "5" ? usuario.empresas_id : equipoSeleccionado.empresaId,
            "tipo_documento": tipoResolucionSeleccionada.value || TIPO_DOCUMENTO_RECIBO,
            "prefijo": prefijoN,
            "resolucion": resolucionN,
            "observaciones": observacionN1 + "@@RESOLUCION" + observacionN2,
            "consecutivo_inicial": parseInt(inicioN, 10),
            "consecutivo_actual": parseInt(actualN, 10),
            "consecutivo_final": parseInt(finN, 10),
            "fecha_inicio": this.state.cambbioInicio ? (moment(new Date(this.state.selectedDate.toString())).format("YYYY-MM-DD ") + "06:00:00") : (moment(new Date()).format("YYYY-MM-DD ") + "06:00:00"),
            "fecha_fin": this.state.cambbioFin ? (moment(new Date(this.state.selectedDateFin.toString())).format("YYYY-MM-DD ") + "24:00:00") : (moment(new Date()).format("YYYY-MM-DD ") + "24:00:00"),
            "equipos_id": this.state.equipoSeleccionado.value
        }
        if (validado) {
            Axios.post(Server.hostPruebasinssl + Server.puertoResoluciones + '/api/Consecutivo', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    this.setState({
                        response: false
                    })
                    if (response.status === 200) {
                        //this.saveParametros(equipoSeleccionado.empresaId)
                        swal({
                            title: "Resolucion creada correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_CONSECUTIVO",
                                        creacionConsecutivo: false
                                    })
                                    //this.setState({loader:true,descripcionN:'',selectedDate:new Date(),selectedDateFin:new Date(),checkIPorcentaje:true,checkIValor:false,valorn:false,cambbioInicio: false,cambbioFin:false})
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }

    }

    borrarResolucion = () => {
        var nresolucion = this.state.resolucionesGuardar
        nresolucion.splice(this.state.resolucionActual, 1)
        this.setState({ resolucionesGuardar: nresolucion, nresoluciones: this.state.nresoluciones - 1, resolucionActual: this.state.resolucionActual - 1 })
    }
    generar = () => {
        var map = this.state.resolucionesmap
        for (let i = 1; i <= this.state.nresoluciones; i++) {
            map.push({
                prefijo: "",
                consecutivoInicial: 0,
                consecutivoFinal: 0,
                fechaInicio: new Date(),
                fechaFin: new Date()
            })
        }
        this.setState({ resolucionesmap: map })
    }

    handleChange = (name) => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
        switch (name) {
            case 'nresoluciones':

                break;
        }
    };


    changeSelect = name => event => {
        var estado = (event.target.value == 'false' ? false : true)
        this.setState({
            [name]: !estado
        });
    }


    /*editarImpuesto = (id) => {
        this.state.impuestosbd.map((impuesto) => {
            if (impuesto.id == id) {
                this.setState({ modoEdicion: true, createbodega: true, descripcionN: impuesto.descripcion, checkIValor: impuesto.porcentaje_valor == "$", checkIPorcentaje: impuesto.porcentaje_valor == "%", valorn: impuesto.valor + '', selectedDate: impuesto.fecha_inicio, selectedDateFin: impuesto.fecha_fin, idEditar: impuesto.id })
            }
        })
    }*/

    cancelar = () => {
        store.dispatch({
            type: "CREACION_IMPUESTO",
            creacionImpuesto: false
        })
    }

    actualizarDatos = () => {
        const { tipoResolucionSeleccionada } = this.state
        var usuario = store.getState().usuario
        var validado = this.validaciones()
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        //let TIPO_DOCUMENTO_RECIBO = 8

        let TIPO_DOCUMENTO_VENTA = 9
        var datos = {
            "empresas_id": usuario.tipo === "5" ? usuario.empresas_id : this.state.equipoSeleccionado.empresaId,
            "tipo_documento": tipoResolucionSeleccionada.value || TIPO_DOCUMENTO_VENTA,
            "prefijo": this.state.prefijoN,
            "resolucion": this.state.resolucionN,
            "observaciones": this.state.observacionN1 + "@@RESOLUCION" + this.state.observacionN2,
            "consecutivo_inicial": parseInt(this.state.inicioN, 10),
            "consecutivo_actual": parseInt(this.state.actualN, 10),
            "consecutivo_final": parseInt(this.state.finN, 10),
            "fecha_inicio": this.state.cambbioInicio ? (moment(new Date(this.state.selectedDate.toString())).format("YYYY-MM-DD ") + "06:00:00") : (moment(this.state.selectedDate).format("YYYY-MM-DD ") + "06:00:00"),
            "fecha_fin": this.state.cambbioFin ? (moment(new Date(this.state.selectedDateFin.toString())).format("YYYY-MM-DD ") + "24:00:00") : (moment(this.state.selectedDateFin).format("YYYY-MM-DD ") + "24:00:00"),
            "equipos_id": impuestoEditar.equipos_id
        }
        if (validado) {
            Axios.put(Server.hostPruebasinssl + Server.puertoResoluciones + '/api/Consecutivo/' + this.state.idEditar, datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    this.setState({
                        response: false
                    })
                    if (response.status === 200) {
                        //this.saveParametros()
                        swal({
                            title: "Resolucion actualizada correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_CONSECUTIVO",
                                        creacionConsecutivo: false
                                    })
                                    this.setState({ loader: true, })
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }

    }



    render() {
        return (
            <GridItem xs={12} sm={12} md={12} id="crearOrden" style={{ overflow: "auto", padding: '0' }}>
                <Card style={{ marginTop: "15px", paddingBottom: "15px" }}>
                    <CardTittle title={this.state.modoEdicion ? "EDICION DE RESOLUCION DE DIAN" : "REGISTRO DE RESOLUCION DE LA DIAN"} style={{ marginBottom: "0", paddingBottom: "5px" }} />
                    <CardContent style={{ paddingTop: "5px" }}>

                        <div className="row" style={{ margin: 5, padding: 5 }}>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-2">
                                        <label htmlFor="numordencompra" className="col-form-label">PREFIJO</label>
                                        <input autoComplete="off" id="numordencompra" className="form-control" type="text" value={this.state.prefijoN} onChange={this.handleChange('prefijoN')} />
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="numordencompra" className="col-form-label">RESOLUCION</label>
                                        <input autoComplete="off" id="numordencompra" className="form-control" type="number" min="1" value={this.state.resolucionN} onChange={this.handleChange('resolucionN')} />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="numordencompra" className="col-form-label">FECHA INICIO</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DatePicker
                                                format="yyyy-MM-dd"
                                                id="datetime"
                                                value={this.state.selectedDate}
                                                //onChange={this.handleChangemoment}
                                                onChange={this.handleChangemoment}
                                                className="rounded form-control"
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="numordencompra" className="col-form-label">FECHA FIN</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DatePicker
                                                format="yyyy-MM-dd"
                                                id="datetime"
                                                value={this.state.selectedDateFin}
                                                onChange={this.handleChangemomentFin}
                                                className="rounded form-control"
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="numordencompra" className="col-form-label">TIPO</label>
                                        <Selects
                                            value={this.state.tipoResolucionSeleccionada}
                                            onChange={this.handleChanges('tipoResolucionSeleccionada')}
                                            options={this.state.tiposResolucionesBD}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <label htmlFor="numordencompra" className="col-form-label">NO. INICIAL</label>
                                        <input autoComplete="off" id="numordencompra" className="form-control" type="number" min="1" value={this.state.inicioN} onChange={this.handleChange('inicioN')} />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="numordencompra" className="col-form-label">NO. FINAL</label>
                                        <input autoComplete="off" id="numordencompra" className="form-control" type="number" min="1" value={this.state.finN} onChange={this.handleChange('finN')} />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="numordencompra" className="col-form-label">NO. ACTUAL</label>
                                        <input autoComplete="off" id="numordencompra" className="form-control" type="number" min="1" value={this.state.actualN} onChange={this.handleChange('actualN')} />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="numordencompra" className="col-form-label">EQUIPO</label>
                                        <Selects
                                            value={this.state.equipoSeleccionado}
                                            onChange={this.handleChanges('equipoSeleccionado')}
                                            options={this.state.equipobd}
                                        />
                                    </div>
                                </div>
                                {JSON.stringify(this.state.equipoSeleccionado) === "{}" ? null : <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="numordencompra" className="col-form-label">Tienda</label>
                                        <input autoComplete="off" id="numordencompra" className="form-control" type="text" value={this.state.equipoSeleccionado.razonSocial} disabled={true} />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="numordencompra" className="col-form-label">Bodega</label>
                                        <input autoComplete="off" id="numordencompra" className="form-control" type="text" value={this.state.equipoSeleccionado.bodega} disabled={true} />
                                    </div>
                                </div>}
                                <div className="row bottom-aligned">
                                    <div className="col-md-6" >
                                        <label htmlFor="numordencompra" className="col-form-label">OBSERVACION 1</label>
                                        <textarea name="comment" className="form-control" rows="2" value={this.state.observacionN1} onChange={this.handleChange('observacionN1')} />
                                    </div>
                                    <div className="col-md-6" >
                                        <label htmlFor="numordencompra" className="col-form-label">OBSERVACION 2</label>
                                        <textarea name="comment" className="form-control" rows="2" value={this.state.observacionN2} onChange={this.handleChange('observacionN2')} />
                                    </div>
                                </div>
                                {
                                    /*<div style={{ marginBottom: '10px' }}>
                                    <div className="row">
                                        <h5 className="mx-auto" >OBLIGACIONES FISCALES</h5>

                                    </div>
                                    {
                                        this.getParametros()

                                    }
                                </div>
                                */}
                                <div className="row bottom-aligned mt-2">
                                    {this.state.modoEdicion
                                        ?
                                        <button className="col-md-4  btn btn-success  btn-block mx-auto" disabled={this.state.response} onClick={() => this.actualizarDatos()}>
                                            GUARDAR
                                        </button>
                                        :
                                        <button className="col-md-4  btn btn-success btn-block mx-auto" disabled={this.state.response} onClick={() => this.agregarResolucion()}>
                                            AGREGAR
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>

                    </CardContent>
                </Card>
            </GridItem>
        )
    }
}


export default MediosPago
