import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment'
import Selects from 'react-select'
import Server from '../../lib/server'
import store from '../../lib/store'
import Axios from 'axios'
import DateFnsUtils from '@date-io/luxon';
import DeleteIcon from '@material-ui/icons/Delete';
import EditICon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import PersonaIcon from '@material-ui/icons/AccountCircle';
import swal from 'sweetalert'
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from 'react-bootstrap'
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import "moment/locale/es";
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { MuiPickersUtilsProvider, DateTimePicker } from 'material-ui-pickers';
import { isUndefined } from 'util';
import Chip from '@material-ui/core/Chip';
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
var colorDominio = store.getState().colorDominio
class Promociones extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Fecha inicio promocion
            selectedDateInicio: new Date(),
            //Fecha fin promocion
            selectedDateFin: new Date(),
            //para las fechas
            moment: moment(),
            fechainicio: new Date(),
            fechafin: new Date(),
            cambbioFin: false,
            cambbioInicio: false,
            nueva: false,
            TipoPromocion: 0,
            loader: true,
            valorCombo: 0,
            estimado: 0,
            productosSeleccionado: {},
            productosTotal: [],
            productosbd: [],
            productosbdInicio: [],
            valorN: '',
            precioN: '',
            precioSubN: '',
            totalN: '',
            productoN: '',
            pluN: '',
            imagePreviewUrl: null,
            //Cupones
            cuponInicial: '',
            cuponFinal: '',
            cuponTexto: '',
            modoDetalles: false,
            modoEdicion: false,
            promocionEditarID: 0,
            value: 0,
            prodcutosEnGeneralbd: [],
            actualizarDatos: false,
            empresaPAdre: '',
            //Empresas
            empresasbd: [],
            empresasGuardar: [],
            empresaSeleccionda: {},
            precioporEmpresa: '',
            //impuestos
            multiImpuestos: [],
            impuestosbd: [],
            impuestosGuardar: [],
            response: false,
            auxiliarTiendasSeleccionadas: [],
            auxiliarProductosSeleccionados: [],
            impuestosAgregados: [],

            impuestosCombosEnviar: []
        };
    }



    componentWillMount() {
        const { detallesPromocion, promocionEditar, usuario: { empresas_id }, empresasHijos, tipoPromocion } = store.getState()
        let tipo = tipoPromocion === 1 ? "C" : "P"
        let ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        if (detallesPromocion) {
            this.setState({ modoDetalles: true })
            this.detallesPromocion(promocionEditar, tipo)
        } else if (!detallesPromocion && promocionEditar != null) {
            this.setState({ modoEdicion: true })
            this.edicionPromocion(promocionEditar, tipo)
        } else {
            Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/plu/' + empresas_id, { headers: { Authorization: ntoken } })
                .then(responsee => {
                    var plu = ''
                    if (responsee.data.data != null) {
                        plu = responsee.data.data[0].next_plu
                    }
                    this.setState({ pluN: plu })
                })
                .catch(function (error) {
                    console.error(error);
                })
            this.setState({ TipoPromocion: '', nueva: true })

        }
        //Empresas
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/5/padre/' + empresasHijos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status == 200 && responsee.data.empresas != null) {
                    var tiendas = []
                    let rtinedad = responsee.data.empresas;
                    for (let r = 0; r < rtinedad.length; r++) {
                        let empresa = rtinedad[r];
                        tiendas.push({
                            value: empresa.id,
                            label: empresa.alias
                        })
                    }
                    this.setState({ empresasbd: tiendas })
                    this.revisarTiendasRepetidas()
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })

        //Impuestos
        /*Axios.get(Server.hostPrueba + Server.puertSSLParametrizacion + '/api/impuestos/empresas/' + empresas_id, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var impuesto = []
                responsee.data.map((unidad) => {
                    if (unidad.estado == "A") {
                        impuesto.push({
                            value: parseInt(unidad.id, 10),
                            label: unidad.descripcion + " -> " + (unidad.porcentaje_valor == '$' ? '$' + unidad.valor : unidad.valor + '%'),
                            descripcion: unidad.descripcion,
                            valor: unidad.valor,
                            porcentaje_valor: unidad.porcentaje_valor
                        })
                    }
                })
                this.setState({ impuestosbd: impuesto })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })*/
        //porductos
        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/' + empresas_id + "/select", { headers: { Authorization: ntoken } })
            .then((res) => {
                let produc = []
                var prod2 = []
                const pr = res.data.Productos
                if (pr != null) {
                    pr.map((producto) => produc.push({ ...producto }))
                }
                /* res.data.Productos.map((producto) => {
                     if (producto.puede_vender == "S" && parseInt(producto.precio) > 0) {
                         produc.push({
                             value: producto.id,
                             label: producto.descripcion + " -- " + producto.plu,
                             precio: producto.precio,
                             producto: producto.descripcion,
                             plu: producto.plu,
                             impuestos: producto.productos_impuestos,
                             tiendas: producto.tiendas
                         })
                         if (producto.promocion != null) {
                             prod2.push(producto)
                         }
                     }
                 })*/
                this.setState({ productosbd: produc, loader: false, productosbdInicio: prod2 })
                this.validarIngredienteMismo()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    validarImpuestos = (impuestos) => {
        const { impuestosAgregados, productosTotal } = this.state
        let bool = false
        if (productosTotal.length === 0) {
            if (impuestos != null) {
                impuestos.map((imp) => {
                    if (imp.porcentaje_valor === "%") {
                        impuestosAgregados.push(imp.valor)
                    }
                })
                this.setState({
                    impuestosAgregados
                })
            }
        } else {
            if (impuestos != null) {
                impuestos.map((imp) => {
                    let val = imp.valor
                    if (!impuestosAgregados.includes(val) && imp.porcentaje_valor === "%") {
                        bool = true
                    }
                })
            } else if (impuestosAgregados.length > 0) {
                bool = true
            }
        }
        return bool

    }

    //FECHA INICIO
    handleChangemoment = date => {
        this.setState({ selectedDate: date, cambbioInicio: true });
    }

    //FECHA FIN
    handleChangemomentFin = date => {
        this.setState({ selectedDateFin: date, cambbioFin: true });
    }

    //INPUT
    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };

    //Selects
    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        switch (name) {
            case "empresaSeleccionda":
                this.setState({ precioporEmpresa: parseInt(this.state.valorCombo) })
                break;
            case 'multiImpuestos':
                var cat = []
                value.map((categoria) => {
                    cat.push({
                        "impuestos_id": parseInt(categoria.value, 10),
                        "iva_incluido": "N",
                        "id": categoria.id == undefined ? '' : categoria.id,
                        "check": false,
                        "descripcion": categoria.descripcion,
                        "porcentaje_valor": categoria.porcentaje_valor,
                        "valor": categoria.valor,
                        "tipo_impuesto": "V"
                    })
                })
                this.setState({ impuestosGuardar: cat })
                break;
        }
    };


    //AGREGAR PRODUCTO
    agregarProducto = () => {
        const { tipoPromocion } = store.getState()
        const { valorN, productosTotal, productosSeleccionado, precioSubN } = this.state
        let { valorCombo } = this.state
        let errorImpuesto = this.validarImpuestos(productosSeleccionado.impuestos)
        if (tipoPromocion !== 2 && (valorN == '' || parseFloat(valorN) == 0)) {
            swal("ERROR", "DEBE INGRESAR LA CANTIDAD Y SER MAYOR A CERO", "warning")
        } else if (tipoPromocion !== 2 && parseFloat(valorN) < 0) {
            swal("ERROR", "LA CANTIDAD DEBE SER POSITIVO", "warning")
        } else if (tipoPromocion !== 2 &&  !/^([0-9])*$/.test(valorN)) {
            swal("ERROR", "LA CANTIDAD DEBE SER UN NUMERO ENTERO", "warning")
        } /*else if (tipoPromocion !== 2 && (precioSubN == '' || parseFloat(precioSubN) == 0)) {
            swal("ERROR", "DEBE INGRESAR EL PRECIO Y SER MAYOR A CERO", "warning")
        } else if (tipoPromocion !== 2 && (parseFloat(precioSubN) < 0)) {
            swal("ERROR", "EL PRECIODEBE SER POSITIVO", "warning")
        }*/ else if (JSON.stringify(productosSeleccionado) === "{}") {
            swal("ERROR", "DEBE SELECCIONAR EL PRODUCTO", "warning")
        } else if (errorImpuesto) {
            swal("ERROR", "LOS IMPUESTOS DE LOS PRODUCTOS DENTRO DE UN COMBO DEBEN TENER LA MISMA BASE", "warning")
        } else if (tipoPromocion !== 2 && parseFloat(productosSeleccionado.precio) < parseFloat(precioSubN)) {
            swal("ERROR", "EL PRECIO DEL PRODUCTO EN EL COMBO NO PUDE SER MAYOR AL PRECIO ORIGINAL DEL PRODUCTO", "warning")
        } else {
            valorCombo = parseFloat(valorCombo) + parseFloat(valorN * parseFloat(precioSubN))
            productosTotal.push({
                id: productosSeleccionado.value,
                producto: productosSeleccionado.producto,
                cantidad: tipoPromocion === 2 ? 2 :valorN,
                //precio: parseFloat(productosSeleccionado.precio),
                //subtotal: valorN * parseFloat(productosSeleccionado.precio),
                precio: tipoPromocion === 2 ? parseFloat(productosSeleccionado.precio) : parseFloat(precioSubN),
                precioOriginal: parseFloat(productosSeleccionado.precio),
                subtotal: valorN * (tipoPromocion === 2 ? parseFloat(productosSeleccionado.precio) : parseFloat(precioSubN)),
                plu: productosSeleccionado.plu,
                estado: "A",
                nuevo: "S",
                impuestos: productosSeleccionado.impuestos,
                tiendas: productosSeleccionado.tiendas
            })
            this.setState({
                modoEditar: false,
                productosTotal,
                productosSeleccionado: {},
                valorN: '',
                precioSubN: '',
                valorCombo
            })
            this.obtenerImpuestos()
        }

    }

    obtenerImpuestos = () => {
        let { productosTotal,impuestosCombosEnviar } = this.state
        impuestosCombosEnviar = []
        if (productosTotal != null && productosTotal.length > 0) {
          productosTotal=  productosTotal.forEach((ingrediente)=>{  
                if (ingrediente.impuestos != null) {   
                    ingrediente.impuestos.forEach((imp) => {
                        let obj= impuestosCombosEnviar.find((imCombo)=>{
                            return imCombo.impuestos_id === imp.impuestos_id;
                        });
                        if(!obj){
                            impuestosCombosEnviar.push(imp);
                        }
                    });
                }
            });
        }
        this.setState({ impuestosCombosEnviar })
    }

    //ELIMINAR PRODUCTO
    eliminarProducto = (e) => {
        const { productosTotal, impuestosAgregados } = this.state
        let { valorCombo } = this.state
        valorCombo = parseFloat(valorCombo) - productosTotal[e].precio
        productosTotal.splice(e, 1)
        if (productosTotal.length === 0 && impuestosAgregados.length > 0) {
            impuestosAgregados.splice(0, impuestosAgregados.length)
        }
        if (productosTotal.length === 0) {
            valorCombo = 0
        }
        this.setState({ productosTotal, impuestosAgregados, valorCombo })
        this.obtenerImpuestos()
    }


    //Edita rpodcuto
    editarProducto = (e) => {
        let mproducto = this.state.productosTotal
        let { valorCombo } = this.state
        let productoEditar = mproducto[e]
        mproducto.splice(e, 1)
        valorCombo = parseFloat(valorCombo) - productoEditar.precio
        this.setState({
            modoEditar: true,
            productosSeleccionado: {
                value: productoEditar.id,
                label: productoEditar.producto + " -- " + productoEditar.plu,
                ...productoEditar
                
            },
            valorN: productoEditar.cantidad,
            productosTotal: mproducto,
            precioSubN: productoEditar.precio,
            valorCombo
        })
    }

    //Valida el fomrulario
    validarFormulario = () => {
        const { tipoPromocion } = store.getState()
        const { empresasGuardar, productosTotal, productoN, pluN, selectedDateFin, selectedDateInicio } = this.state
        var validarIngredientes = true
        var mensajeError = ''
        var empresas_id = []
        empresasGuardar.map((tienda) => {
            if (tienda.estado === "A") {
                empresas_id.push({
                    id: parseInt(tienda.id),
                    nombre: tienda.empresa
                })
            }
        })
        productosTotal.map((ingrediente) => {
            if (ingrediente.tiendas != null) {
                let tingredientes = []
                ingrediente.tiendas.map((tienda) => {
                    tingredientes.push(parseInt(tienda.empresa))
                })
                empresas_id.map((empresa) => {
                    if (!tingredientes.includes(empresa.id)) {
                        validarIngredientes = false
                        mensajeError += "EL PRODUCTO " + ingrediente.producto + " NO ESTA EN LA TIENDA " + empresa.nombre + " \n"
                    }
                })
            }
        })
        if (tipoPromocion === 1 || tipoPromocion === 2) {
            if (productoN.trim == "" || productoN.length == 0) {
                swal("Fallo al guardar", "EL NOMBRE ES UN CAMPO OBLIGATORIO", "warning")
                return false
            } else if (pluN.trim == "" || pluN.length == 0) {
                swal("Fallo al guardar", "EL CODIGO PLU ES UN CAMPO OBLIGATORIO", "warning")
                return false
            } else if (productosTotal.length == 0) {
                swal("Fallo al guardar", "POR LO MENOS DEBES REGISTRAR UN INGREDIENTE", "warning")
                return false
            } else if (moment(new Date(selectedDateFin.toString())).format("YYYY-MM-DD HH:mm:ss") < moment(new Date(selectedDateInicio.toString())).format("YYYY-MM-DD HH:mm:ss")) {
                swal("Fallo al guardar", "LA FECHA FINAL DEBE SER MAYOR A LA FECHA INCIAL", "warning")
            } else if (!validarIngredientes) {
                swal("Fallo al guardar", mensajeError, "warning")
            } else {
                this.setState({
                    response: true
                })
                return true
            }
        }

    }

    //Agrega promocion
    crearPromocion = () => {
        const { tipoPromocion } = store.getState()
        const {impuestosCombosEnviar} = this.state
        var validado = this.validarFormulario()
        if (tipoPromocion === 1 || tipoPromocion === 2) {
            var usuario = store.getState().usuario
            var dominio = store.getState().dominio
            var datos = {
                "producto": {
                    "empresas_id": parseInt(usuario.empresas_id, 10),
                    "plu": this.state.pluN,
                    "descripcion": this.state.productoN,
                    "precio": tipoPromocion == 1 ? this.state.valorCombo == '' ? 0 : this.state.valorCombo : this.state.productosTotal[0].precio,
                    "puede_vender": 'S',
                    "puede_comprar": 'N',
                    "ingrediente": 'N',
                    "tipo": 2,
                    "url_foto": this.state.imagePreviewUrl != null ? "S" : "N",
                    "unidades_medida": 1,
                    "unidades_contenido": 1,
                    "usa_balanza": 'N',
                    "create_user": parseInt(usuario.p_id, 10),
                    "favorito": 'N',
                    "cantidad_ingredientes": this.state.productosTotal.length,
                    "dispensado": 'N',
                    "cantidad_maxima": 0,
                    "cantidad_minima": 0,
                    "tiempo_reorden": 0,
                    "precio_maximo": 0,
                    "precio_minimo": 0,
                    "precio_tipo_limite": '$',
                    "precio_flexible": 'N',
                    "desintegrable": 'N',
                    "promocion": tipoPromocion === 1 ? 'C' : 'P',
                    "vigencia_inicio": this.state.cambbioInicio ? moment(new Date(this.state.selectedDateInicio.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    "vigencia_fin": this.state.cambbioFin ? moment(new Date(this.state.selectedDateFin.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(this.state.selectedDateFin.toString()).format("YYYY-MM-DD HH:mm:ss"),
                },
                "categorias": [{
                    "grupo_id": 164
                }],
                "productos_impuestos": impuestosCombosEnviar.map((imp)=>{
                    return({
                        "impuestos_id": imp.impuestos_id,
                        "iva_incluido": "S",
                        "tipo": imp.tipo
    
                    })
                }),

                "ingredientes":
                    this.state.productosTotal.map((ingrediente) => {
                        return (
                            {
                                "ingredientes_id": parseInt(ingrediente.id, 10),
                                "cantidad": parseFloat(ingrediente.cantidad),
                                "ingrediente_precio_especial": parseFloat(ingrediente.precio)
                            }
                        )
                    })
                ,
                "proveedores_productos": [],
                "identificadores": [],
                "imagen": this.state.imagePreviewUrl != null ? this.state.imagePreviewUrl : 'N',
                "dominio": dominio,
                "tiendas": this.state.empresasGuardar.map((empresa) => {
                    return (
                        {
                            "empresas_finales_id": empresa.id,
                            "precio": tipoPromocion == 1 ? this.state.valorCombo == '' ? 0 : this.state.valorCombo : this.state.productosTotal[0].precio
                        }
                    )
                })
            }
            if (validado) {
                Axios.post(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto', datos, { headers: { Authorization: ntoken } })
                    .then((res) => {
                        this.setState({ nueva: false, TipoPromocion: 0, valorCombo: '', valorN: '', precioN: '', productoN: '', pluN: '', productosSeleccionado: {}, cambbioInicio: false, cambbioFin: false, fechainicio: new Date(), fechafin: new Date(), selectedDateInicio: new Date(), selectedDateFin: new Date(), productosTotal: [], response: false })
                        if (res.status == 201) {
                            swal({
                                title: tipoPromocion == 1 ? "Combo creado correctamente" : "2x1 creado correctamente",
                                icon: "success",
                                closeOnClickOutside: false,
                                buttons: {
                                    Seguir: {
                                        text: "OK",
                                        value: "continuar"
                                    }
                                },
                            }).then((value) => {
                                switch (value) {

                                    case "continuar":
                                        store.dispatch({
                                            type: "ACTUALIZACION_DATOS",
                                            actualizarvista: true
                                        })
                                        store.dispatch({
                                            type: "CREACION_PROMOCION",
                                            creacionPromocion: false,
                                            detallesPromocion: false,
                                            promocionEditar: null,
                                            tipoPromocion: 0,
                                        })
                                        break;

                                    default:

                                }
                            });
                        } else {
                            swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }

    }

    componentDidMount(){
        this.obtenerImpuestos()
    }
    //EDITAR PROMOCION
    editarPromocion = () => {
        const {impuestosCombosEnviar} = this.state
        var id = this.state.promocionEditarID
        var usuario = store.getState().usuario
        var dominio = store.getState().dominio
        var tipo = this.state.TipoPromocion
        var validado = this.validarFormulario()
        var datos = {
            "producto": {
                "id": id,
                "empresas_id": parseInt(usuario.empresas_id, 10),
                "plu": this.state.pluN,
                "descripcion": this.state.productoN,
                "precio": tipo == 1 ? this.state.valorCombo == '' ? 0 : this.state.valorCombo : this.state.productosTotal[0].precio,
                "puede_vender": 'S',
                "puede_comprar": 'N',
                "ingrediente": 'N',
                "tipo": 2,
                "url_foto": this.state.imagePreviewUrl != null ? "S" : "N",
                "unidades_medida": 1,
                "unidades_contenido": 1,
                "usa_balanza": 'N',
                "cantidad_ingredientes": this.state.productosTotal.length,
                "create_user": parseInt(usuario.p_id, 10),
                "favorito": 'N',
                "dispensado": 'N',
                "cantidad_maxima": 0,
                "cantidad_minima": 0,
                "tiempo_reorden": 0,
                "precio_maximo": 0,
                "precio_minimo": 0,
                "precio_tipo_limite": '$',
                "precio_flexible": 'N',
                "desintegrable": 'N',
                "promocion": tipo == 1 ? 'C' : 'P',
                "vigencia_inicio": this.state.cambbioInicio ? moment(new Date(this.state.selectedDateInicio.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "vigencia_fin": this.state.cambbioFin ? moment(new Date(this.state.selectedDateFin.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date(this.state.selectedDateFin.toString())).format("YYYY-MM-DD HH:mm:ss"),
            },
            "proveedores_productos_eliminados": [],
            "categorias": [{
                "grupo_id": 164
            }],
            "productos_impuestos": impuestosCombosEnviar.map((imp)=>{
                return({
                    "impuestos_id": imp.impuestos_id,
                    "iva_incluido": "S",
                    "tipo": imp.tipo,
                    "productos_id": id

                })
            }),
            "ingredientes":
                this.state.productosTotal.map((ingrediente) => {
                    return (
                        {
                            "ingredientes_id": parseInt(ingrediente.id, 10),
                            "cantidad": parseFloat(ingrediente.cantidad),
                            "id": parseInt(ingrediente.id, 10),
                            "productos_id": id,
                            "estado": ingrediente.estado,
                            "ingrediente_precio_especial": parseFloat(ingrediente.precio)

                        }
                    )
                })
            ,
            "proveedores_productos": [],
            "identificadores": [],
            "imagen": this.state.imagePreviewUrl != null ? this.state.imagePreviewUrl : 'N',
            "dominio": dominio,
            "tiendas": this.state.empresasGuardar.map((empresa) => {
                return (
                    {
                        "empresas_finales_id": empresa.id,
                        "precio": tipo == 1 ? this.state.valorCombo == '' ? 0 : this.state.valorCombo : this.state.productosTotal[0].precio,
                        "estado": empresa.estado,
                        "id": empresa.relacion_id,
                        "disponible": empresa.disponible,
                        "productos_id": id
                    }
                )
            })
        }
        if (validado) {
            Axios.put(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto', datos, { headers: { Authorization: ntoken } })
                .then((res) => {
                    this.setState({
                        response: false
                    })
                    if (res.status == 201) {
                        swal({
                            title: "Producto actualizado correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_PROMOCION",
                                        creacionPromocion: false,
                                        detallesPromocion: false,
                                        promocionEditar: null,
                                        tipoPromocion: 0,
                                    })
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al actualizar", "Por favor verifique los datos", "warning");
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }

    setearMiles = (numero) => {
        return (
            (new Intl.NumberFormat("de-DE").format(parseInt(numero)))
        )
    }

    edicionPromocion = (producto, tipo) => {
        this.setState({
            TipoPromocion: tipo === "C" ? 1 : 2,
            nueva: true,
            modoDetalles: false,
            modoEdicion: true
        })
        var ingredientesTotal = []
        if (tipo === "P") {
            if (producto.ingredientes != null) {
                producto.ingredientes.map((ingrediente) => {
                    ingredientesTotal.push({
                        ...ingrediente,
                        precio: ingrediente.ingrediente_precio_especial,
                        precioOriginal:ingrediente.precio,
                        producto: ingrediente.descripcion,
                        subtotal: ingrediente.ingrediente_precio_especial * ingrediente.cantidad,
                        estado: "A",
                        tiendas: ingrediente.tiendas,
                    })
                })
            }
            var tiendasPrecios = []
            if (producto.tiendas != null) {
                producto.tiendas.map((tiendas) => {
                    tiendasPrecios.push({
                        id: tiendas.empresa,
                        empresa: tiendas.alias,
                        precio: tiendas.precio,
                        estado: "A",
                        relacion_id: tiendas.id,
                        disponible: tiendas.disponible
                    })
                })

                this.setState({ empresasGuardar: tiendasPrecios })
            }
            this.setState({
                promocionEditarID: producto.id,
                selectedDateInicio: producto.vigencia_inicio,
                selectedDateFin: producto.vigencia_fin,
                productoN: producto.descripcion,
                pluN: producto.plu,
                productosTotal: ingredientesTotal,
                cambbioInicio: true,
                cambbioFin: true
            })

        } else if (tipo === "C") {
            if (producto.productos_impuestos != null) {
                var auxImpuestos = []
                var auxImpuestos2 = []
                producto.productos_impuestos.map((impuesto) => {

                    auxImpuestos.push({
                        impuestos_id: impuesto.impuestos_id,
                        iva_incluido: impuesto.iva_incluido,
                        check: impuesto.iva_incluido == 'S',
                        id: impuesto.id,
                        descripcion: impuesto.descripcion,
                        porcentaje_valor: impuesto.porcentaje_valor,
                        valor: impuesto.valor,
                        tipo_impuesto: "V",
                    })
                    auxImpuestos2.push({
                        id: impuesto.id,
                        iva_incluido: impuesto.iva_incluido,
                        check: impuesto.iva_incluido == 'S',
                        value: impuesto.impuestos_id,
                        label: impuesto.descripcion + " -> " + (impuesto.porcentaje_valor == '$' ? '$' + impuesto.valor : impuesto.valor + '%'),
                        descripcion: impuesto.descripcion,
                        porcentaje_valor: impuesto.porcentaje_valor,
                        valor: impuesto.valor,
                        tipo_impuesto: "V",

                    })
                })
                this.setState({ impuestosGuardar: auxImpuestos, multiImpuestos: auxImpuestos2 })
            }
            if (producto.ingredientes != null) {
                producto.ingredientes.map((ingrediente) => {
                    ingredientesTotal.push({
                        ...ingrediente,
                        precio: ingrediente.ingrediente_precio_especial,
                        precioOriginal:ingrediente.precio,
                        producto: ingrediente.descripcion,
                        subtotal: ingrediente.ingrediente_precio_especial * ingrediente.cantidad,
                        estado: "A",
                        tiendas: ingrediente.tiendas,
                    })
                    this.validarImpuestos(ingrediente.impuestos)
                })
            }
            var tiendasPrecios = []
            if (producto.tiendas != null) {
                producto.tiendas.map((tiendas) => {
                    tiendasPrecios.push({
                        id: tiendas.empresa,
                        empresa: tiendas.alias,
                        precio: tiendas.precio,
                        estado: "A",
                        relacion_id: tiendas.id,
                        disponible: tiendas.disponible
                    })
                })
                this.setState({ empresasGuardar: tiendasPrecios })
            }
            this.setState({
                promocionEditarID: producto.id,
                selectedDateInicio: producto.vigencia_inicio,
                selectedDateFin: producto.vigencia_fin,
                productoN: producto.descripcion,
                pluN: producto.plu,
                valorCombo: producto.precio,
                productosTotal: ingredientesTotal,
                cambbioInicio: true,
                cambbioFin: true
            })

        }
        
    }

    validarIngredienteMismo = () => {
        var ingredientes = this.state.productosbd
        ingredientes.map((ingrediente, key) => {
            if (parseInt(ingrediente.plu) === parseInt(this.state.pluN)) {
                ingredientes.splice(key, 1)
            }
        })
        this.setState({
            productosbd: ingredientes
        })
    }

    detallesPromocion = (producto, tipo) => {
        this.setState({
            TipoPromocion: tipo,
            nueva: true,
            modoDetalles: true,
            modoEdicion: false
        })
        var ingredientesTotal = []
        if (tipo === "P") {
            if (producto.ingredientes != null) {
                producto.ingredientes.map((ingrediente) => {
                    ingredientesTotal.push({
                        ...ingrediente,
                        precio: ingrediente.ingrediente_precio_especial,
                        precioOriginal:ingrediente.precio,
                        producto: ingrediente.descripcion,
                        subtotal: ingrediente.ingrediente_precio_especial * ingrediente.cantidad,
                        estado: "A",
                        tiendas: ingrediente.tiendas,
                    })
                    this.validarImpuestos(ingrediente.impuestos)
                })
            }

            var tiendasPrecios = []
            if (producto.tiendas != null) {
                producto.tiendas.map((tiendas) => {
                    tiendasPrecios.push({
                        id: tiendas.empresa,
                        empresa: tiendas.alias,
                        precio: tiendas.precio,
                        estado: "A",
                        relacion_id: tiendas.id,
                        disponible: tiendas.disponible
                    })
                })
                this.setState({ empresasGuardar: tiendasPrecios })
            }

            this.setState({
                promocionEditarID: producto.id,
                selectedDateInicio: producto.vigencia_inicio,
                selectedDateFin: producto.vigencia_fin,
                productoN: producto.descripcion,
                pluN: producto.plu,
                productosTotal: ingredientesTotal
            })

        } else if (tipo === "C") {

            if (producto.productos_impuestos != null) {
                var auxImpuestos = []
                var auxImpuestos2 = []
                producto.productos_impuestos.map((impuesto) => {
                    auxImpuestos.push({
                        impuestos_id: impuesto.impuestos_id,
                        iva_incluido: impuesto.iva_incluido,
                        check: impuesto.iva_incluido == 'S',
                        id: impuesto.id,
                        descripcion: impuesto.descripcion,
                        porcentaje_valor: impuesto.porcentaje_valor,
                        valor: impuesto.valor,
                        tipo_impuesto: "V",
                    })
                    auxImpuestos2.push({
                        id: impuesto.id,
                        iva_incluido: impuesto.iva_incluido,
                        check: impuesto.iva_incluido == 'S',
                        value: impuesto.impuestos_id,
                        label: impuesto.descripcion + " -> " + (impuesto.porcentaje_valor == '$' ? '$' + impuesto.valor : impuesto.valor + '%'),
                        descripcion: impuesto.descripcion,
                        porcentaje_valor: impuesto.porcentaje_valor,
                        valor: impuesto.valor,
                        tipo_impuesto: "V",

                    })
                })
                this.setState({ impuestosGuardar: auxImpuestos, multiImpuestos: auxImpuestos2 })
            }
            if (producto.ingredientes != null) {
                producto.ingredientes.map((ingrediente) => {
                    ingredientesTotal.push({
                        ...ingrediente,
                        precio: ingrediente.ingrediente_precio_especial,
                        precioOriginal:ingrediente.precio,
                        producto: ingrediente.descripcion,
                        subtotal: ingrediente.ingrediente_precio_especial * ingrediente.cantidad,
                        estado: "A",
                        tiendas: ingrediente.tiendas,
                    })
                    this.validarImpuestos(ingrediente.impuestos)
                })
            }
            var tiendasPrecios = []
            if (producto.tiendas != null) {
                producto.tiendas.map((tiendas) => {
                    tiendasPrecios.push({
                        id: tiendas.empresa,
                        empresa: tiendas.alias,
                        precio: tiendas.precio,
                        estado: "A",
                        relacion_id: tiendas.id,
                        disponible: tiendas.disponible
                    })
                })
                this.setState({ empresasGuardar: tiendasPrecios })
            }
            this.setState({
                promocionEditarID: producto.id,
                selectedDateInicio: producto.vigencia_inicio,
                selectedDateFin: producto.vigencia_fin,
                productoN: producto.descripcion,
                pluN: producto.plu,
                valorCombo: producto.precio,
                productosTotal: ingredientesTotal
            })
        }
        
    }


    cancelar = () => {
        store.dispatch({
            type: "CREACION_PROMOCION",
            creacionPromocion: false,
            detallesPromocion: false,
            promocionEditar: null,
            tipoPromocion: 0,
        })
    }



    agregarTienda = () => {
        var tipo = store.getState().tipoPromocion
        var tiendas = this.state.empresasGuardar
        if (tipo === 1) {
            if (this.state.precioporEmpresa.trim == '' || parseInt(this.state.precioporEmpresa) == 0) {
                swal("ERROR", "DEBE INGRESAR EL VALOR Y SER MAYOR A CERO", "warning")
            } else if (parseInt(this.state.precioporEmpresa) < 0) {
                swal("ERROR", "EL VALOR DEBE SER POSITIVO", "warning")
            } else if (!/^([0-9])*$/.test(this.state.precioporEmpresa)) {
                swal("ERROR", "EL VALOR DEBE SER UN NUMERO ENTERO", "warning")
            } else if (JSON.stringify(this.state.empresaSeleccionda) === "{}") {
                swal("ERROR", "DEBE SELECCIONAR LA TIENDA", "warning")
            } else {
                tiendas.push({
                    id: this.state.empresaSeleccionda.value,
                    empresa: this.state.empresaSeleccionda.label,
                    precio: tipo === 1 ? this.state.precioporEmpresa : 0,
                    relacion_id: 0,
                    estado: "A",
                    disponible: "S"
                })
                this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd.indexOf(this.state.empresaSeleccionda))
                this.setState({ empresasGuardar: tiendas, empresaSeleccionda: {}, precioporEmpresa: '' })
            }
        } else {
            tiendas.push({
                id: this.state.empresaSeleccionda.value,
                empresa: this.state.empresaSeleccionda.label,
                precio: tipo === 1 ? this.state.precioporEmpresa : 0,
                relacion_id: 0,
                estado: "A",
                disponible: "S"
            })
            this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd.indexOf(this.state.empresaSeleccionda))
            this.setState({ empresasGuardar: tiendas, empresaSeleccionda: {}, precioporEmpresa: '' })
        }
    }


    agregarObjetosSeleccionados = (vector, stateVector, vectorAuxiliar, stateVectorAuxiliar, posicion) => {
        if (!isUndefined(vector[posicion])) {
            vectorAuxiliar.push(
                vector[posicion]
            )
        }
        vector.splice(posicion, 1)
        this.setState({
            [stateVector]: vector,
            [stateVectorAuxiliar]: vectorAuxiliar
        });
    }


    agregarTiendabd = () => {
        let tipo = store.getState().tipoPromocion
        let tiendas = this.state.empresasGuardar
        if (tipo === 1) {
            if (this.state.precioporEmpresa.trim == '' || parseInt(this.state.precioporEmpresa) == 0) {
                swal("ERROR", "DEBE INGRESAR EL VALOR Y SER MAYOR A CERO", "warning")
            } else if (parseInt(this.state.precioporEmpresa) < 0) {
                swal("ERROR", "EL VALOR DEBE SER POSITIVO", "warning")
            } else if (!/^([0-9])*$/.test(this.state.precioporEmpresa)) {
                swal("ERROR", "EL VALOR DEBE SER UN NUMERO ENTERO", "warning")
            } else if (JSON.stringify(this.state.empresaSeleccionda) === "{}") {
                swal("ERROR", "DEBE SELECCIONAR LA TIENDA", "warning")
            } else {
                tiendas.push({
                    id: this.state.empresaSeleccionda.value,
                    empresa: this.state.empresaSeleccionda.label,
                    precio: tipo === 1 ? this.state.precioporEmpresa : 0,
                    relacion_id: 0,
                    estado: "A",
                    disponible: "S"
                })
                this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd.indexOf(this.state.empresaSeleccionda))
                this.setState({ empresasGuardar: tiendas, empresaSeleccionda: {}, precioporEmpresa: '' })
            }
        } else {
            tiendas.push({
                id: this.state.empresaSeleccionda.value,
                empresa: this.state.empresaSeleccionda.label,
                precio: tipo === 1 ? this.state.precioporEmpresa : 0,
                relacion_id: 0,
                estado: "A",
                disponible: "S"
            })
            this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd.indexOf(this.state.empresaSeleccionda))
            this.setState({ empresasGuardar: tiendas, empresaSeleccionda: {}, precioporEmpresa: '' })
        }
    }

    eliminarTienda = (e) => {
        var mtienda = this.state.empresasGuardar
        mtienda.splice(e, 1)
        this.agregarObjetosSeleccionados(this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd, 'empresasbd', e)
        this.setState({ empresasGuardar: mtienda })
    }

    eliminarTiendabd = (e) => {
        var mtienda = this.state.empresasGuardar
        let rtienda = this.state.auxiliarTiendasSeleccionadas;
        let posicionReal = e;
        for (let r = 0; r < rtienda.length; r++) {
            let vt = rtienda[r];
            if (parseInt(vt.value) === parseInt(mtienda[e].id)) {
                posicionReal = r;
                break;
            }

        }
        if (mtienda[e].relacion_id === 0) {
            this.agregarObjetosSeleccionados(this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd, 'empresasbd', posicionReal)
            mtienda.splice(e, 1)
        } else {
            mtienda[e].estado = "I"
            this.agregarObjetosSeleccionados(this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd, 'empresasbd', posicionReal)
        }
        this.setState({ empresasGuardar: mtienda })
    }

    handleChangeCheck = (name, llave) => event => {
        var estado = (event.target.value == 'false' ? false : true)
        this.setState({
            [name]: !estado
        });
        switch (name) {
            case 'checkDisponibleEmpresa':
                var mempresas = this.state.empresasGuardar
                if (mempresas[llave].disponible === "S") {
                    mempresas[llave].disponible = "N"
                } else {
                    mempresas[llave].disponible = "S"
                }
                break

        }
    }

    revisarTiendasRepetidas = () => {
        var existentes = []
        var posiciones = []
        this.state.empresasGuardar.map((tienda) => {
            existentes.push({
                value: tienda.id.toString(),
                label: tienda.empresa
            })
        })
        existentes.map((tiend, key) => {
            existentes[key] = JSON.stringify(tiend)
        })
        this.state.empresasbd.map((ingrediente, key) => {
            if (existentes.includes(JSON.stringify(ingrediente))) {
                posiciones.push(key)
            }
        })
        posiciones.map((posicion, llave) => {
            this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', posicion - llave)
        })
    }

    //Vistas
    Promociones = () => {
        const tipo = store.getState().tipoPromocion
        const { modoDetalles, precioSubN, pluN, productosSeleccionado, productoN, valorCombo, selectedDateInicio, selectedDateFin, productosbd, valorN, impuestosAgregados, impuestosCombosEnviar } = this.state
        switch (tipo) {
            case 1:
                return (
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="numordencompra" className="col-form-label">Nombre de la promocion</label>
                                <input autocomplete="off" id="numordencompra" disabled={modoDetalles} value={productoN} onChange={this.handleChange('productoN')} className="form-control" type="text" />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">Numero de plu</label>
                                <input autocomplete="off" id="numordencompra" value={pluN} disabled={true} onChange={this.handleChange('pluN')} className="form-control" type="text" />
                            </div>
                            <div className="col col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">Valor del combo</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">$</span>
                                    </div>
                                    <input autocomplete="off" disabled={modoDetalles} id="numordencompra" disabled value={valorCombo} className="form-control" type="number" min="1" onChange={this.handleChange('valorCombo')} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-md-12">
                                <div className="row">
                                    <div className="col col-md-3">
                                        <label htmlFor="numordencompra" className="col-form-label">Fecha inicio de promocion</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DateTimePicker
                                                format="yyyy/MM/dd HH:mm a"
                                                id="datetime"
                                                disabled={modoDetalles}
                                                value={selectedDateInicio}
                                                onChange={this.handleChangemoment}
                                                className="rounded form-control"
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col col-md-3">
                                        <label htmlFor="numordencompra" className="col-form-label">Fecha fin de promocion</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DateTimePicker
                                                format="yyyy/MM/dd HH:mm a"
                                                id="datetime"
                                                value={selectedDateFin}
                                                onChange={this.handleChangemomentFin}
                                                disabled={modoDetalles}
                                                className="rounded form-control"
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <Paper className="col-md-5 mx-auto" style={{ margin: "15px", padding: "10px" }}>
                                        <div className="row">
                                            <h5 className="mx-auto">IMPUESTOS</h5>
                                        </div>
                                        <div className="row" style={{ padding: "15px" }}>
                                            {
                                                impuestosCombosEnviar != null &&
                                                impuestosCombosEnviar.map((impuesto, k) => {
                                                    return <Chip label={impuesto.descripcion} variant="outlined" key={k} />
                                                })
                                            }
                                        </div>
                                    </Paper>
                                    {/*<div className="col-md-6" >
                                        <label htmlFor="nombreprov" className="col-form-label">Impuestos</label>
                                        <Selects
                                            placeholder="Seleccione o escriba"
                                            value={this.state.multiImpuestos}
                                            onChange={this.handleChanges('multiImpuestos')}
                                            options={this.state.impuestosbd}
                                            isMulti
                                            isDisabled={modoDetalles}
                                        />
                </div>*/}

                                </div>
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: '15px', marginLeft: '5px', marginRight: '5px' }}>
                            <div className="col-md-6 border rounded" style={{ paddingBottom: '10px' }}>
                                <div cassName="row mx-auto">
                                    <h6 className="mx-auto">PRODUCTOS DEL COMBO</h6>
                                </div>
                                {
                                    this.state.modoDetalles
                                        ? null :
                                        <div className="row">
                                            <div className="col col-md-9">
                                                <label htmlFor="numordencompra" className="col-form-label">Producto</label>
                                                <Selects
                                                    placeholder="Seleccione o escriba"
                                                    value={productosSeleccionado}
                                                    onChange={this.handleChanges('productosSeleccionado')}
                                                    options={productosbd}
                                                    disabled={modoDetalles}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="numordencompra" className="col-form-label">Cantidad</label>
                                                <input autocomplete="off" id="numordencompra" disabled={modoDetalles}
                                                    value={valorN} onChange={this.handleChange('valorN')} className="form-control" type="number" min="1" />
                                            </div>
                                            <div className="col-md-5">
                                                <label htmlFor="numordencompra" className="col-form-label">Precio U. Producto</label>
                                                <input autocomplete="off" id="numordencompra" disabled={modoDetalles}
                                                    value={"$" + (productosSeleccionado.precioOriginal ? this.setearMiles(productosSeleccionado.precioOriginal) : productosSeleccionado.precio ? this.setearMiles(productosSeleccionado.precio) : "0")} disabled className="form-control" type="text" />
                                            </div>
                                            <div className="col-md-5">
                                                <label htmlFor="numordencompra" className="col-form-label">Precio U. En Combo</label>
                                                <input autocomplete="off" id="numordencompra" disabled={modoDetalles}
                                                    value={precioSubN} onChange={this.handleChange('precioSubN')} className="form-control" type="text" />
                                            </div>


                                            <div className="col-md-2 bottom-aligned">
                                                <button className="btn btn-primary btn-block" disabled={modoDetalles} onClick={() => this.agregarProducto()}>
                                                    +
                                                            </button>
                                            </div>
                                        </div>
                                }

                                {this.state.productosTotal.length == 0
                                    ? null :
                                    <div className="row" style={{ marginTop: '10px' }}>
                                        <div className="col-md-12 mx-auto">
                                            <MDBTable bordered striped>
                                                <MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
                                                    <tr>
                                                        <th>Producto</th>
                                                        <th>Plu</th>
                                                        <th >Cantidad</th>
                                                        <th >Precio</th>
                                                        <th >Subtotal</th>
                                                        <th >Acciones</th>
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {this.state.productosTotal.map((row, key) => {
                                                        if (row.estado != "I") {
                                                            return (
                                                                <tr key={key}>
                                                                    <td component="th" scope="row">
                                                                        {row.producto}
                                                                    </td>
                                                                    <td>{row.plu}</td>
                                                                    <td>{row.cantidad}</td>
                                                                    <td>{'$' + this.setearMiles(parseInt(row.precio))}</td>
                                                                    <td>{'$' + this.setearMiles(parseInt(row.subtotal))}</td>
                                                                    <td>{[
                                                                        <button className="btn btn-sm btn-warning" disabled={this.state.modoDetalles} style={{ marginLeft: '5px' }} onClick={() => this.editarProducto(key)}><EditICon /></button>,
                                                                        <button className="btn btn-sm btn-danger" disabled={this.state.modoDetalles} style={{ marginLeft: '5px' }} onClick={() => this.eliminarProducto(key)}><DeleteIcon /></button>]
                                                                    }</td>
                                                                </tr>
                                                            )
                                                        }
                                                    })}
                                                </MDBTableBody>
                                            </MDBTable>
                                        </div>
                                    </div>}
                            </div>
                            <div className="col-md-6 border rounded" style={{ paddingBottom: '10px' }}>
                                <div cassName="row mx-auto">
                                    <h6>TIENDAS</h6>
                                </div>
                                {this.state.modoDetalles ? null : <div className="row mx-auto my-auto">

                                    <div className="col-md-6">
                                        <label htmlFor="numordencompra" className="col-form-label">Tienda</label>
                                        <Selects
                                            placeholder="Seleccione o escriba"
                                            value={this.state.empresaSeleccionda}
                                            onChange={this.handleChanges('empresaSeleccionda')}
                                            options={this.state.empresasbd}
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="numordencompra" className="col-form-label">Precio </label>
                                        <div class="input-group" style={{ marginBottom: '0!important' }}>
                                            <div class="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                                <span class="input-group-text" id="basic-addon1">$</span>
                                            </div>
                                            <input type="number" min="1" class="form-control" disabled value={valorCombo} onChange={this.handleChange('precioporEmpresa')} aria-label="Username" aria-describedby="basic-addon1" />
                                        </div>
                                    </div>

                                    <div className="bottom-aligned">
                                        <Tooltip title="AGREGAR TIENDA" placement="top">
                                            <button className="btn btn-primary" onClick={this.state.modoEdicion ? () => this.agregarTiendabd() : () => this.agregarTienda()}>AGREGAR</button>
                                        </Tooltip>
                                    </div>
                                </div>}

                                <div className="row mx-auto my-auto">
                                    <List className="col-md-12 mx-auto " >
                                        {this.state.empresasGuardar.map((empresa, key) => {
                                            if (empresa.estado != "I") {
                                                return (
                                                    <ListItem className="mx-auto border " key={key}>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <PersonaIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={empresa.empresa} secondary={'$' + this.setearMiles(parseInt(this.state.valorCombo))} />
                                                        {this.state.modoDetalles ? null : <ListItemSecondaryAction>
                                                            <Tooltip title="SI MARCA ESTA OPCION LE DA OPERMISO A LA EMPRESA PARA QUE PUEDA DISPONER DEL PRODUCTO" placement="top">
                                                                <FormControlLabel d control={<Checkbox style={{ padding: "0" }} checked={empresa.disponible === "S"} value={empresa.disponible === "S"} onChange={this.handleChangeCheck("checkDisponibleEmpresa", key)} />} label="DISPONIBLE" />
                                                            </Tooltip>
                                                            <IconButton aria-label="Delete" onClick={this.state.modoEdicion ? () => this.eliminarTiendabd(key) : () => this.eliminarTienda(key)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>}
                                                        {this.state.modoDetalles ? <ListItemSecondaryAction>
                                                            <Tooltip title={empresa.disponible === "S" ? "EL PRODUCTO ESTA ACTIVO PARA ESTA TIENDA" : "EL PRODUCTO ESTA INACTIVO PARA ESTA TIENDA"} placement="top">
                                                                <FormControlLabel disabled={true} control={<Checkbox style={{ padding: "0" }} checked={empresa.disponible === "S"} value={empresa.disponible === "S"} onChange={this.handleChangeCheck("checkDisponibleEmpresa", key)} />} label="DISPONIBLE" />
                                                            </Tooltip>
                                                        </ListItemSecondaryAction> : null}
                                                    </ListItem>
                                                )
                                            }
                                        })}

                                    </List>
                                </div>
                            </div>
                        </div>


                    </div>
                )
            case 2:
                return (
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col col-md-12">
                                <h5>PAGUE 1 Y LLEVE 2</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-md-12" style={{ height: '30%', overflow: 'auto' }}>
                                <div className="row">
                                    <div className="col col-md-6">
                                        <label htmlFor="numordencompra" className="col-form-label">Fecha inicio de promocion</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DateTimePicker
                                                format="yyyy/MM/dd HH:mm"
                                                id="datetime"
                                                value={this.state.selectedDateInicio}
                                                onChange={this.handleChangemoment}
                                                className="rounded form-control"
                                                disabled={this.state.modoDetalles}
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col col-md-6">
                                        <label htmlFor="numordencompra" className="col-form-label">Fecha fin de promocion</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DateTimePicker
                                                format="yyyy/MM/dd HH:mm"
                                                id="datetime"
                                                value={this.state.selectedDateFin}
                                                onChange={this.handleChangemomentFin}
                                                className="rounded form-control"
                                                disabled={this.state.modoDetalles}
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8">
                                <label htmlFor="numordencompra" className="col-form-label">Nombre de la promocion</label>
                                <input autocomplete="off" id="numordencompra" disabled={this.state.modoDetalles} value={this.state.productoN} onChange={this.handleChange('productoN')} className="form-control" type="text" />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">Numero de plu</label>
                                <input autocomplete="off" id="numordencompra" disabled={true} value={this.state.pluN} onChange={this.handleChange('pluN')} className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '15px', marginLeft: '5px', marginRight: '5px' }}>
                            <div className="col-md-6 border rounded" style={{ paddingBottom: '10px' }}>
                                <div cassName="row mx-auto">
                                    <h6>PRODUCTOS DEL COMBO</h6>
                                </div>
                                {/*
                                    this.state.modoDetalles
                                        ? null :
                                        <div className="row">
                                            <div className="col col-md-9">
                                                <label htmlFor="numordencompra" className="col-form-label">Producto</label>
                                                <Selects
                                                    placeholder="Seleccione o escriba"
                                                    value={this.state.productosSeleccionado}
                                                    onChange={this.handleChanges('productosSeleccionado')}
                                                    options={this.state.productosbd}
                                                    disabled={this.state.modoDetalles}
                                                    isLoading={this.state.productosbd.length == 0}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="numordencompra" className="col-form-label">Cantidad</label>
                                                <input autocomplete="off" id="numordencompra" disabled={this.state.modoDetalles} value={this.state.valorN} onChange={this.handleChange('valorN')} className="form-control" type="number" min="1" />
                                            </div>
                                            <div className="col-md-5">
                                                <label htmlFor="numordencompra" className="col-form-label">Precio U. Producto</label>
                                                <input autocomplete="off" id="numordencompra" disabled={modoDetalles}
                                                    value={"$" + (productosSeleccionado.precioOriginal ? this.setearMiles(productosSeleccionado.precioOriginal) : productosSeleccionado.precio ? this.setearMiles(productosSeleccionado.precio) : "0")} disabled className="form-control" type="text" />
                                            </div>
                                            <div className="col-md-5">
                                                <label htmlFor="numordencompra" className="col-form-label">Precio U. En Combo</label>
                                                <input autocomplete="off" id="numordencompra" disabled={modoDetalles}
                                                    value={precioSubN} onChange={this.handleChange('precioSubN')} className="form-control" type="text" />
                                            </div>
                                            <div className="col-md-2 bottom-aligned">
                                                <button className="btn btn-primary btn-block" disabled={this.state.modoDetalles || this.state.productosTotal.length == 1} onClick={() => this.agregarProducto()}>
                                                    Agregar
                                                            </button>
                                            </div>

                                        </div>
                                */ }
                                {
                                    this.state.modoDetalles
                                        ? null :
                                        <div className="row">
                                            <div className="col col-md-7">
                                                <label htmlFor="numordencompra" className="col-form-label">Producto</label>
                                                <Selects
                                                    placeholder="Seleccione o escriba"
                                                    value={this.state.productosSeleccionado}
                                                    onChange={this.handleChanges('productosSeleccionado')}
                                                    options={this.state.productosbd}
                                                    disabled={this.state.modoDetalles}
                                                    isLoading={this.state.productosbd.length == 0}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="numordencompra" className="col-form-label">Cantidad</label>
                                                <input autocomplete="off" id="numordencompra" disabled={true} value={2}  onChange={this.handleChange('valorN')} className="form-control" type="number" min="1" />
                                            </div>
                                            <div className="col-md-2 bottom-aligned">
                                                <button className="btn btn-primary btn-block" disabled={this.state.modoDetalles || this.state.productosTotal.length == 1} onClick={() => this.agregarProducto()}>
                                                    +
                                                            </button>
                                            </div>

                                        </div>
                                }

                                {this.state.productosTotal.length == 0
                                    ? null :
                                    <div className="row" style={{ marginTop: '10px' }}>
                                        <div className="col-md-12 mx-auto">
                                            <MDBTable bordered striped>
                                                <MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
                                                    <tr>
                                                        <th>Plu</th>
                                                        <th>Producto</th>
                                                        <th >Cantidad</th>
                                                        <th >Precio</th>
                                                        <th >Subtotal</th>
                                                        <th >Acciones</th>
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {this.state.productosTotal.map((row, key) => {
                                                        if (row.estado != "I") {
                                                            return (
                                                                <tr key={key}>
                                                                    <td component="th" scope="row">
                                                                        {row.plu}

                                                                    </td>
                                                                    <td>{row.producto}</td>
                                                                    <td>{row.cantidad}</td>
                                                                    <td>{'$' + this.setearMiles(row.precio)}</td>
                                                                    <td>{'$' + this.setearMiles(row.subtotal)}</td>
                                                                    <td >{[
                                                                        <button className="btn btn-sm btn-warning" disabled={this.state.modoDetalles} style={{ marginLeft: '5px' }} onClick={() => this.editarProducto(key)}><EditICon /></button>,
                                                                        <button className="btn btn-sm btn-danger" disabled={this.state.modoDetalles} style={{ marginLeft: '5px' }} onClick={() => this.eliminarProducto(key)}><DeleteIcon /></button>]
                                                                    }</td>
                                                                </tr>
                                                            )
                                                        }
                                                    })}
                                                </MDBTableBody>
                                            </MDBTable>
                                        </div>
                                    </div>}
                            </div>
                            <div className="col-md-6 border rounded" style={{ paddingBottom: '10px' }}>
                                <div cassName="row mx-auto">
                                    <h6>TIENDAS</h6>
                                </div>
                                {this.state.modoDetalles ? null : <div className="row mx-auto my-auto">

                                    <div className="col-md-8">
                                        <label htmlFor="numordencompra" className="col-form-label">Tienda</label>
                                        <Selects
                                            placeholder="Seleccione o escriba"
                                            value={this.state.empresaSeleccionda}
                                            onChange={this.handleChanges('empresaSeleccionda')}
                                            options={this.state.empresasbd}
                                        />
                                    </div>
                                    <div className="bottom-aligned">
                                        <Tooltip title="AGREGAR TIENDA" placement="top">
                                            <button className="btn btn-primary" onClick={this.state.modoEdicion ? () => this.agregarTiendabd() : () => this.agregarTienda()}>AGREGAR</button>
                                        </Tooltip>
                                    </div>
                                </div>}

                                <div className="row mx-auto my-auto">
                                    <List className="col-md-12 mx-auto " >
                                        {this.state.empresasGuardar.map((empresa, key) => {
                                            if (empresa.estado != "I") {
                                                return (
                                                    <ListItem className="mx-auto border " key={key}>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <PersonaIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={empresa.empresa} />
                                                        {this.state.modoDetalles ? null : <ListItemSecondaryAction>
                                                            <Tooltip title="SI MARCA ESTA OPCION LE DA OPERMISO A LA EMPRESA PARA QUE PUEDA DISPONER DEL PRODUCTO" placement="top">
                                                                <FormControlLabel d control={<Checkbox style={{ padding: "0" }} checked={empresa.disponible === "S"} value={empresa.disponible === "S"} onChange={this.handleChangeCheck("checkDisponibleEmpresa", key)} />} label="DISPONIBLE" />
                                                            </Tooltip>
                                                            <IconButton aria-label="Delete" onClick={this.state.modoEdicion ? () => this.eliminarTiendabd(key) : () => this.eliminarTienda(key)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>}
                                                        {this.state.modoDetalles ? <ListItemSecondaryAction>
                                                            <Tooltip title={empresa.disponible === "S" ? "EL PRODUCTO ESTA ACTIVO PARA ESTA TIENDA" : "EL PRODUCTO ESTA INACTIVO PARA ESTA TIENDA"} placement="top">
                                                                <FormControlLabel disabled={true} control={<Checkbox style={{ padding: "0" }} checked={empresa.disponible === "S"} value={empresa.disponible === "S"} onChange={this.handleChangeCheck("checkDisponibleEmpresa", key)} />} label="DISPONIBLE" />
                                                            </Tooltip>
                                                        </ListItemSecondaryAction> : null}
                                                    </ListItem>
                                                )
                                            }
                                        })}

                                    </List>
                                </div>
                            </div>
                        </div>

                    </div>
                )
            case 3:
                return (
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col col-md-12">
                                <h5>DESCUENTO</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-md-12" style={{ height: '30%', overflow: 'auto' }}>
                                <div className="row">
                                    <div className="col col-md-6">
                                        <label htmlFor="numordencompra" className="col-form-label">Fecha inicio de promocion</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DateTimePicker
                                                format="yyyy/MM/dd HH:mm"
                                                id="datetime"
                                                value={this.state.selectedDateInicio}
                                                onChange={this.handleChangemoment}
                                                className="rounded form-control"
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col col-md-6">
                                        <label htmlFor="numordencompra" className="col-form-label">Fecha fin de promocion</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DateTimePicker
                                                format="yyyy/MM/dd HH:mm"
                                                id="datetime"
                                                value={this.state.selectedDateFin}
                                                onChange={this.handleChangemomentFin}
                                                className="rounded form-control"
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">Texto</label>
                                <input autocomplete="off" id="numordencompra" value={this.state.cuponTexto} onChange={this.handleChange('cuponTexto')} className="form-control" type="number" min="1" />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">Inicial cupon</label>
                                <input autocomplete="off" id="numordencompra" value={this.state.cuponInicial} onChange={this.handleChange('cuponInicial')} className="form-control" type="number" min="1" />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">Final cupon</label>
                                <input autocomplete="off" id="numordencompra" value={this.state.cuponFinal} onChange={this.handleChange('cuponFinal')} className="form-control" type="number" min="1" />
                            </div>
                            <div className="col-md-3 bottom-aligned">
                                <button className="btn btn-success btn-lg btn-block" onClick={() => this.agregarProducto()}>
                                    Crear Promocion
                                                                </button>
                            </div>
                        </div>
                    </div>
                )
            default:
                return (
                    <div className="row mx-auto">

                    </div>
                )
        }
    }



    render() {
        return (
            <div>
                <AppBar style={{ backgroundColor: colorDominio }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Close"
                            onClick={this.cancelar.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            style={{ marginLeft: "theme.spacing(2)", flex: 1, color: '#fff' }}
                        >
                            {'CREACION DE PROMOCION'}
                        </Typography>
                        {
                            this.state.modoDetalles
                                ?
                                null
                                :
                                this.state.modoEdicion
                                    ?
                                    <Button style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} disabled={this.state.response} onClick={this.editarPromocion.bind(this)}>GUARDAR</Button>
                                    :
                                    <Button style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} className="btn btn-outline-light" disabled={this.state.response} onClick={() => this.crearPromocion()}>TERMINAR Y GUARDAR</Button>
                        }

                    </Toolbar>
                </AppBar>
                <div className="row align-middle mx-auto" style={{ marginTop: '7%' }}>
                    {this.Promociones()}
                </div>
            </div>



        )
    }
}


export default Promociones
