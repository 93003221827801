/* eslint-disable no-unreachable */
/* eslint-disable react/jsx-key */
/* eslint-disable react/no-deprecated */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardTittle from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent';
import GridItem from "components/Grid/GridItem.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Selects from 'react-select';
import Axios from 'axios'
import store from '../../../lib/store'
import swal from 'sweetalert'
import Server from '../../../lib/server'
import { MDBDataTable } from 'mdbreact';
import Paper from '@material-ui/core/Paper';
import Ojoicon from "@material-ui/icons/RemoveRedEye"
import DeleteIcon from '@material-ui/icons/Delete';
import EditICon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import ActivarIcon from "@material-ui/icons/Check"
import moment from 'moment'
import "moment/locale/es";
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
var hijos = ''
var usuario = ''
const idModulo = 10
var permisos = ''
class Bodega extends Component {
    constructor() {
        super();
        this.state = {
            numeroStam: [],
            createbodega: false,
            bodegasTotales: [],
            bodegasbd: [],
            tiendasbd: [],
            //Datos a insertar
            ndescripcion: '',
            nempresa_id: 0,
            ncodigo: '',
            nnivel: 2,
            ndimension: '',
            nubicacion: '',
            nnumerostam: 0,
            emporesaSeleccionada: {},
            //Actualizar datos
            actualizarDatos: false,
            modoEdicion: false,
            idEdicion: '',
            vistaProductos: false,
            productosBodega: [],
            idBodega: 0,
            loader: true,
            bodegaVista: null,
            ventanaKArdex: false,
            productoKArdex: null,
            movimientosProductosKArdex: [],
            COSTOkaRDEX: '',
            COSTObODEGAkaRDEX: '',
            CANTIDADkaRDEX: '',
            response: false,
            nombresActuales: [],
            cofigosActuales: []
        }
        store.subscribe(() => {
            this.setState({
                createbodega: store.getState().ventanaCreacionBodega,
                actualizarDatos: store.getState().actualizarvista,
                vistaProductos: store.getState().creacionGeografia
            })
        })

    }

    componentWillMount() {
        //Permisos usuarios
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        this.obtenerDatos()
    }

    obtenerToken() {
        return 'Bearer '.concat(localStorage.getItem('token'))
    }

    cerrarKArdex = () => {
        this.setState({ productoKArdex: null, ventanaKArdex: false })
    }

    obtenerKardex = (producto) => {
        this.setState({ productoKArdex: producto, ventanaKArdex: true })
        //console.log(producto)
        Axios.get(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/movimiento/' + producto.id + '/kardex/' + this.state.idBodega, { headers: { Authorization: ntoken } })
            .then(responsee => {
                console.log(responsee.data)
                var movimientos = []
                if (responsee.status === 200) {
                    var kardex = responsee.data.rows[0]
                    if (kardex.producto.movimientos != null) {
                        kardex.producto.movimientos.map((movimiento, key) => {
                            movimientos.push(movimiento)
                        })
                    }
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
                this.setState({ movimientosProductosKArdex: movimientos, COSTObODEGAkaRDEX: kardex.producto.bodegas_productos.costo_bodega, COSTOkaRDEX: kardex.producto.bodegas_productos.costo, CANTIDADkaRDEX: kardex.producto.bodegas_productos.cantidad })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    ventanaProducto = (bodega) => {
        console.log(bodega)
        this.setState({ idBodega: bodega.bodega_id, bodegaVista: bodega, loader: true })
        Axios.get(Server.hostPrueba + Server.puertoInventario + '/api/bodegas/productos/' + bodega.bodega_id, { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data != null) {
                        var productostotal = []
                        res.data.map((producto, key) => {
                            productostotal.push(producto.productos)
                        })
                        this.setState({ productosBodega: productostotal })
                    }
                } else if (res.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }

            })
            .catch((err) => {
                console.log(err)
            })
        store.dispatch({
            type: "CREACION_GEOGRAFIA",
            creacionGeografia: true
        })
    }

    componentWillReceiveProps() {
        if (this.state.actualizarDatos) {
            this.obtenerDatos()
        }
    }

    setearMiles = (numero) => {
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case "COP":
                return (
                    (new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(2)))
                )
                break;
            case "USD":
                return (new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(numero))
                break;

        }
    }

    obtenerDatos = () => {
        usuario = store.getState().usuario
        hijos = store.getState().empresasHijos
        //Obtener las tiendas
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/5/padre/' + hijos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var tiendas = []
                if (responsee.status == 200) {
                    responsee.data.empresas.map((dato, key) => (
                        tiendas.push({
                            value: dato.id,
                            label: dato.alias
                        })
                    ))
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }

                this.setState({ tiendasbd: tiendas, loader: false })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        //Obtener las bodegas
        switch (usuario.tipo) {
            case "1":
            case "2":
            case "3":
            case "4":
                Axios.get(Server.hostPrueba + Server.puertoSSLBodega + '/api/bodegas/empresa/' + hijos, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        var bdg = []
                        var bdgtotal = []
                        var nombres = []
                        var codigos = []
                        if (responsee.status === 200) {
                            responsee.data.map((bodega, key) => {
                                bdgtotal.push(bodega)
                                nombres.push(bodega.descripcion)
                                codigos.push(bodega.codigo)
                                bdg.push({
                                    "codigo": bodega.codigo,
                                    "descripcion": bodega.descripcion,
                                    "dimensiones": bodega.dimension,
                                    "ubicacion": bodega.ubicacion,
                                    "Nstan": bodega.numeros_stand,
                                    "empresa": bodega.alias,
                                    "acciones": [
                                        <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.ventanaProducto(bodega)}><Ojoicon /></button>,
                                        permisos.includes('U') ? <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.editarBodega(bodega.bodega_id)}><EditICon /></button> : null,
                                        permisos.includes('D') ?
                                            bodega.estado == "A"
                                                ?
                                                <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.eliminarBodega(bodega.bodega_id)}><DeleteIcon /></button>
                                                :
                                                <button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.activarBodega(bodega.bodega_id)}><ActivarIcon /></button>
                                            : null
                                    ]
                                })
                            })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }

                        this.setState({ bodegasbd: bdg, bodegasTotales: bdgtotal, nombresActuales: nombres, cofigosActuales: codigos })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break;
            case "5":
                Axios.get(Server.hostPrueba + Server.puertoSSLBodega + '/api/bodegas/empresa/' + usuario.empresas_id, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee.data)
                        var bdg = []
                        var bdgtotal = []
                        var nombres = []
                        var codigos = []
                        if (responsee.status === 200) {
                            responsee.data.map((bodega, key) => {
                                bdgtotal.push(bodega)
                                nombres.push(bodega.descripcion)
                                codigos.push(bodega.codigo)
                                bdg.push({
                                    "codigo": bodega.codigo,
                                    "descripcion": bodega.descripcion,
                                    "dimensiones": bodega.dimension,
                                    "ubicacion": bodega.ubicacion,
                                    "Nstan": bodega.numeros_stand,
                                    "empresa": bodega.razon_social,
                                    "acciones": [
                                        <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.ventanaProducto(bodega)}><Ojoicon /></button>,
                                        permisos.includes('U') ? <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.editarBodega(bodega.bodega_id)}><EditICon /></button> : null,
                                        permisos.includes('D') ?
                                            bodega.estado == "A"
                                                ?
                                                <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.eliminarBodega(bodega.bodega_id)}><DeleteIcon /></button>
                                                :
                                                <button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.activarBodega(bodega.bodega_id)}><ActivarIcon /></button>
                                            : null
                                    ]
                                })
                            })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }

                        this.setState({ bodegasbd: bdg, bodegasTotales: bdgtotal, nombresActuales: nombres, cofigosActuales: codigos })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break
        }
    }

    editarBodega = (id) => {
        this.state.bodegasTotales.map((bodega) => {
            console.log(bodega)
            if (bodega.bodega_id == id) {
                this.setState({ idEdicion: id, modoEdicion: true, createbodega: true, ndescripcion: bodega.descripcion, emporesaSeleccionada: { value: bodega.empresas_id, label: bodega.razon_social }, ncodigo: bodega.codigo, nnumerostam: bodega.numeros_stand, nubicacion: bodega.ubicacion, ndimension: bodega.dimension })
            }
        })
    }




    actualizarBodega = () => {

        var validado = this.validarFormulario()
        var datos = {
            'descripcion': this.state.ndescripcion,
            'empresas_id': usuario.tipo == "5" ? usuario.empresas_id : parseInt(this.state.emporesaSeleccionada.value, 10),
            'dimension': this.state.ndimension,
            'ubicacion': this.state.nubicacion,
            'numeros_stand': parseInt(this.state.nnumerostam, 10)
        }
        console.log(datos)
        if (validado) {
            Axios.put(Server.hostPrueba + Server.puertoSSLBodega + '/api/bodegas/' + this.state.idEdicion, datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    console.log(response)
                    this.setState({
                        response: false
                    })
                    if (response.status === 200) {
                        swal({
                            title: "Bodega actualizada correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_BODEGA",
                                        ventanaCreacionBodega: false
                                    })
                                    break;

                                default:

                            }
                        });
                    } else if (response.status == 205) {
                        swal("ERROR", "LA SESION HA CADUCADO", "warning")
                        setTimeout(function () { window.location.href = '/'; }, 3000);
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }

    }
    cancelar = () => {
        store.dispatch({
            type: "CREACION_GEOGRAFIA",
            creacionGeografia: false
        })
    }

    validarFormulario = () => {
        if (this.state.ndescripcion == '') {
            swal("ERROR", "LA DESCRIPCION ES NECESARIA", "warning")
            return false
        } else if (this.state.ncodigo == '') {
            swal("ERROR", "EL CODIGO ES NECESARIO", "warning")
            return false
        } else if (this.state.nombresActuales.includes(this.state.ndescripcion) && !this.state.modoEdicion) {
            swal("ERROR", "YA EXISTE UNA BODEGA CON ESTE NOMBRE", "warning")
            return false
        } else if (this.state.cofigosActuales.includes(this.state.ncodigo) && !this.state.modoEdicion) {
            swal("ERROR", "YA EXISTE UNA BODEGA CON ESTE CODIGO", "warning")
            return false
        } else {
            this.setState({
                response: true
            })
            return true
        }
    }

    guardarBodega = () => {

        var validar = this.validarFormulario()
        var datos = {
            'descripcion': this.state.ndescripcion,
            'empresas_id': usuario.tipo == "5" ? usuario.empresas_id : parseInt(this.state.emporesaSeleccionada.value, 10),
            'codigo': this.state.ncodigo,
            'dimension': this.state.ndimension,
            'ubicacion': this.state.nubicacion,
            'numeros_stand': parseInt(this.state.nnumerostam, 10)
        }
        console.log(datos)
        if (validar) {
            Axios.post(Server.hostPrueba + Server.puertoSSLBodega + '/api/bodegas', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    console.log(response)
                    this.setState({
                        response: false
                    })
                    if (response.status === 200) {
                        swal({
                            title: "Bodega creada correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_BODEGA",
                                        ventanaCreacionBodega: false
                                    })
                                    break;

                                default:

                            }
                        });
                    } else if (response.status == 205) {
                        swal("ERROR", "LA SESION HA CADUCADO", "warning")
                        setTimeout(function () { window.location.href = '/'; }, 3000);
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }

    }

    crearbodega = () => {
        store.dispatch({
            type: "CREACION_BODEGA",
            ventanaCreacionBodega: !this.state.createbodega
        })
        this.setState({ ndescripcion: '', ncodigo: '', emporesaSeleccionada: {}, ndimension: '', nubicacion: '', nnumerostam: '' })
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
    }

    mostrarTabla = () => {
        document.getElementById("table_id").DataTable();
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
        //this.autoguardado()
    };

    crearAlmacenes = (e) => {
        if (e.target.value > 0) {
            var n = this.state.numeroStam
            var nn = e.target.value
            for (let index = 0; index < nn; index++) {
                n.push({
                    numeroStam: n.length + 1,
                    placeholder: "Nombre del stan " + (n.length + 1)
                })
            }
            this.setState({
                numeroStam: n
            })
        } else {
            this.setState({
                numeroStam: []
            })
        }

    }

    eliminarBodega = (id) => {
        Axios.delete(Server.hostPrueba + Server.puertoInventario + '/api/bodegas/' + id, { headers: { Authorization: ntoken }, data:{} } )
            .then(response => {
                if (response.status === 200) {
                    swal({
                        title: "Bodega eliminada correctamente",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {
                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                store.dispatch({
                                    type: "CREACION_BODEGA",
                                    ventanaCreacionBodega: false
                                })
                                this.setState({ loader: true })
                                break;

                            default:

                        }
                    });
                } else if (response.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                }
            })
    }
    activarBodega = (id) => {
        var usuario = store.getState().usuario
        var datos = {
            "id": id,
            "entidad_nombre": "bodegas",
            "estado": "A",
            "personas_id": usuario.p_id,
            "empresas_id": usuario.empresas_id
        }
        console.log(datos)
        Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/estados/', datos, { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.status == 201) {
                    swal({
                        title: "Bodega activada correctamente",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                break;
                            default:

                        }
                    });
                } else if (res.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    swal("Fallo al activar", "Por favor verifique los datos", "warning");
                }
            })
    }

    render() {
        const bodegas = {
            columns: [
                {
                    label: 'codigo',
                    field: 'codigo',
                    sort: 'asc',
                    width: 'auto'
                }, {
                    label: 'Descripcion',
                    field: 'nbodega',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Dimensiones',
                    field: 'dimensiones',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Ubicacion',
                    field: 'ubicacion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'N° de stan',
                    field: 'nstan',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Kiosko',
                    field: 'tienda',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Acciones',
                    field: 'acciones',
                    sort: 'asc',
                    width: 150
                }
            ],
            rows: this.state.bodegasbd
        }
        var kardexProducto = {
            columns: [

                {
                    label: 'Movimiento',
                    field: 'celular',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Fecha',
                    field: 'identificacion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Operacion',
                    field: 'operacion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Tipo',
                    field: 'direccion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Cantidad',
                    field: 'cant',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Medida',
                    field: 'medida',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Costo del producto',
                    field: 'costo',
                    sort: 'asc',
                    aligned: 'center',
                    width: 150
                }, {
                    label: 'Costo del movimiento',
                    field: 'costototal',
                    sort: 'asc',
                    aligned: 'center',
                    width: 150
                }
            ], rows:
                this.state.movimientosProductosKArdex.map((producto, key) => {
                    return (
                        {

                            'movimineto': producto.consecutivo,
                            'Fecha': moment(producto.fecha).format("DD/MM/YYYY hh:mm a"),
                            'operacion': producto.operacion,
                            'tipo': producto.tipo_operacion,
                            'cantidad': producto.cantidad,
                            'unidad': producto.unidad_basica_desc,
                            'costo': '$' + this.setearMiles(producto.costo_producto),
                            'costoMov': '$' + this.setearMiles(producto.costo_movimiento),


                        }
                    )
                })
        }
        var productos = {
            columns: [

                {
                    label: 'Plu',
                    field: 'celular',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Producto',
                    field: 'identificacion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Precio unidad',
                    field: 'direccion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Saldo',
                    field: 'correo',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Medida',
                    field: 'med',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Kardex',
                    field: 'correo',
                    sort: 'asc',
                    aligned: 'center',
                    width: 150
                }
            ], rows:
                this.state.productosBodega.map((producto, key) => {
                    return (
                        {


                            'plu': producto.plu,
                            'nombre': producto.descripcion,
                            'precio': '$' + producto.precio,
                            'Saldo': producto.saldo,
                            'Medida': producto.unidad_basica_desc,
                            'accion': <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.obtenerKardex(producto)}><Ojoicon /></button>

                        }
                    )
                })
        }
        return (
            permisos.includes('R') ? <div>
                {this.state.createbodega ? <button className="btn btn-danger" onClick={this.crearbodega}>Cancelar</button> : permisos.includes('C') ? <button className="btn btn-success" onClick={this.crearbodega}>Crear nueva bodega</button> : null}
                {this.state.createbodega ? <GridItem xs={12} sm={12} md={12} id="crearOrden" style={{ overflow: "auto" }}>
                    <Card style={{ marginTop: "15px", paddingBottom: "15px" }}>
                        <CardTittle title="Registro de bodega" style={{ marginBottom: "0", paddingBottom: "5px" }} />
                        <CardContent style={{ paddingTop: "5px" }}>
                            <div className="row">
                                <div className="col-md-3">
                                    <label htmlFor="numordencompra" className="col-form-label">Codigo</label>
                                    <input autoComplete="off" id="numordencompra" className="form-control" disabled={this.state.modoEdicion} type="text" value={this.state.ncodigo} onChange={this.handleChange('ncodigo')} />
                                </div>
                                <div className={usuario.tipo == "5" ? "col-md-9" : "col-md-5"}>
                                    <label htmlFor="numordencompra" className="col-form-label">Nombre de la bodega</label>
                                    <input autoComplete="off" id="numordencompra" className="form-control" type="text" value={this.state.ndescripcion} onChange={this.handleChange('ndescripcion')} />
                                </div>
                                {usuario.tipo == "5"
                                    ? null
                                    :
                                    <div className="col-md-4">
                                        <label htmlFor="numordencompra" className="col-form-label">Tienda</label>
                                        <Selects
                                            value={this.state.emporesaSeleccionada}
                                            onChange={this.handleChanges('emporesaSeleccionada')}
                                            options={this.state.tiendasbd}
                                        />
                                    </div>}
                            </div>
                            <div className="row">
                                <ExpansionPanel expanded={true} className="col" style={{ margin: "15px" }}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        Propiedades de la bodega
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{ display: "block" }}>
                                        {/* <div className="row" style={{ width: "100%" }}>
                                            <div className="col col-md-4">
                                                <FormControlLabel control={<Checkbox value="dispositivo" onChange={this.decirAlgo} />} label="Punto de venta" />
                                            </div>
                                            <input autoComplete="off"  id="numordencompra" className="form-control col col-md-8" placeholder="Nombre del punto de venta" type="text" />
                                        </div>*/}
                                        <div className="row">
                                            <div className="col col-md-4">
                                                <label htmlFor="numordencompra" className="col-form-label">Dimensiones</label>
                                                <input autoComplete="off" id="numordencompra" className="form-control" value={this.state.ndimension} type="text" onChange={this.handleChange('ndimension')} />
                                            </div>
                                            <div className="col col-md-4">
                                                <label htmlFor="numordencompra" className="col-form-label">Ubicacion de la bodega</label>
                                                <input autoComplete="off" id="numordencompra" className="form-control" type="text" value={this.state.nubicacion} onChange={this.handleChange('nubicacion')} />
                                            </div>
                                            <div className="col col-md-4">
                                                <label htmlFor="numordencompra" className="col-form-label">Numero de stan</label>
                                                <input autoComplete="off" id="numordencompra" className="form-control" type="number" min="1" value={this.state.nnumerostam} onChange={this.handleChange('nnumerostam')} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            {this.state.numeroStam.map((n, key) => (
                                                <div className="col col-md-4 col-sm-6">
                                                    <label htmlFor="numordencompra" className="col-form-label">{n.placeholder}</label>
                                                    <input autoComplete="off" id="numordencompra" className="form-control" type="text" />
                                                </div>
                                            ))}
                                        </div>

                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                            <div className="row">
                                <div className="col col-md-9"></div>
                                <div className="col col-md-3">
                                    {this.state.modoEdicion ? <button className="btn btn-success" style={{ width: "100%" }} disabled={this.state.response} onClick={this.actualizarBodega.bind(this)}>Guardar</button> : <button className="btn btn-success" disabled={this.state.response} style={{ width: "100%" }} onClick={this.guardarBodega.bind(this)}>Crear Bodega</button>}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </GridItem> : null}
                <div className="row" style={{ marginTop: '10px', height: '100%' }}>
                    <div className="col col-md-12" style={{ padding: 0 }}>
                        <Paper style={{ padding: '5px' }}>

                            <MDBDataTable
                                //striped
                                className="col-md-12"
                                searchLabel="Buscar"
                                paginationLabel={["Anterior", "Siguente"]}
                                infoLabel={["Mostrando", "a", "de", "entradas"]}
                                entriesLabel="Filas a mostrar"
                                tbodyColor="primary-color"
                                theadColor="#FF8800"
                                exportToCSV={true}
                                striped
                                bordered
                                autoWidth={false}
                                responsive={true}
                                hover
                                data={bodegas}
                                style={{ fontSize: '13px' }}
                            />
                            <Dialog
                                open={this.state.vistaProductos}
                                onClose={() => this.cancelar()}
                                aria-labelledby="form-dialog-title"
                                maxWidth={'md'}
                                fullWidth={true}

                                style={{ padding: '0' }}
                                disableBackdropClick={false}
                            >
                                <DialogContent style={{ padding: '0' }}>
                                    <div className="row mx-auto" style={{ marginTop: '10px', marginBottom: '10px', height: '100%' }}>
                                        <div className="col col-md-11 mx-auto" style={{ padding: 0 }}>
                                            <Paper style={{ padding: '10px' }}>
                                                {this.state.bodegaVista != null ? <div className="row mx-auto">
                                                    <h5 className="col col-md-8">{'BODEGA: ' + this.state.bodegaVista.descripcion + " - " + this.state.bodegaVista.codigo}</h5>
                                                </div> : null}
                                                <MDBDataTable
                                                    //striped
                                                    className="col-md-12 mx-auto"
                                                    searchLabel="Buscar"
                                                    paginationLabel={["Anterior", "Siguente"]}
                                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                    entriesLabel="Filas a mostrar"
                                                    tbodyColor="primary-color"
                                                    theadColor="#FF8800"
                                                    exportToCSV={true}
                                                    striped
                                                    bordered
                                                    autoWidth={false}
                                                    responsive={true}
                                                    hover
                                                    data={productos}
                                                />
                                            </Paper>
                                        </div>
                                    </div>
                                    <div className="row mx-auto">
                                        <div className="col-md-8 bottom-aligned mx-auto"></div>

                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <button className="btn btn-danger " onClick={() => this.cancelar()} style={{ margin: '10px', float: 'right' }}>CERRAR</button>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                open={this.state.ventanaKArdex}
                                onClose={() => this.cerrarKArdex()}
                                aria-labelledby="form-dialog-title"
                                maxWidth={'md'}
                                fullWidth={true}
                                style={{ padding: '0' }}
                                disableBackdropClick={false}
                            >
                                <DialogContent style={{ padding: '0' }}>
                                    <div className="row mx-auto" style={{ marginTop: '10px', marginBottom: '10px', height: '100%' }}>
                                        <div className="col col-md-11 mx-auto" style={{ padding: 0 }}>
                                            <Paper style={{ padding: '10px' }}>
                                                {this.state.productoKArdex != null ? <div className="row mx-auto">
                                                    <div className="col col-md-4">
                                                        <label htmlFor="numordencompra" className="col-form-label">BODEGA</label>
                                                        <input autoComplete="off" id="numordencompra" disabled className="form-control" type="text" value={this.state.bodegaVista.descripcion} />
                                                    </div>
                                                    <div className="col col-md-2"></div>
                                                    <div className="col col-md-2">
                                                        <label htmlFor="numordencompra" className="col-form-label">PLU</label>
                                                        <input autoComplete="off" id="numordencompra" disabled className="form-control" type="text" value={this.state.productoKArdex.plu} />
                                                    </div>
                                                    <div className="col col-md-4">
                                                        <label htmlFor="numordencompra" className="col-form-label">Producto</label>
                                                        <input autoComplete="off" id="numordencompra" disabled className="form-control" type="text" value={this.state.productoKArdex.descripcion} />
                                                    </div>
                                                    <div className="col col-md-6">
                                                    </div>
                                                </div> : null}
                                                <MDBDataTable
                                                    //striped
                                                    className="col-md-12 mx-auto"
                                                    searchLabel="Buscar"
                                                    paginationLabel={["Anterior", "Siguente"]}
                                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                    entriesLabel="Filas a mostrar"
                                                    tbodyColor="primary-color"
                                                    theadColor="#FF8800"
                                                    exportToCSV={true}
                                                    striped
                                                    bordered
                                                    autoWidth={false}
                                                    responsive={true}
                                                    hover
                                                    style={{ fontSize: '13px' }}
                                                    data={kardexProducto}
                                                />
                                                {this.state.productoKArdex != null ? <div className="row mx-auto">
                                                    <div className="col col-md-4">
                                                        <label htmlFor="numordencompra" className="col-form-label">saldo en bodega</label>
                                                        <input autoComplete="off" id="numordencompra" disabled className="form-control" type="text" value={this.state.CANTIDADkaRDEX} />
                                                    </div>
                                                    <div className="col col-md-4">
                                                        <label htmlFor="numordencompra" className="col-form-label">Costo unitario</label>
                                                        <input autoComplete="off" id="numordencompra" disabled className="form-control" type="text" value={'$ ' + this.setearMiles(this.state.COSTOkaRDEX)} />
                                                    </div>
                                                    <div className="col col-md-4">
                                                        <label htmlFor="numordencompra" className="col-form-label">Costo total en bodega</label>
                                                        <input autoComplete="off" id="numordencompra" disabled className="form-control" type="text" value={'$ ' + this.setearMiles(this.state.COSTObODEGAkaRDEX)} />
                                                    </div>
                                                </div> : null}
                                            </Paper>

                                        </div>
                                    </div>
                                    <div className="row mx-auto">
                                        <div className="col-md-8 bottom-aligned mx-auto"></div>

                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <button className="btn btn-danger " onClick={() => this.cerrarKArdex()} style={{ margin: '10px', float: 'right' }}>CERRAR</button>
                                </DialogActions>
                            </Dialog>
                        </Paper>
                    </div>
                </div>
            </div> : <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>
        )
    }
}



export default Bodega;
