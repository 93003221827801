import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios';
import Server from '../../lib/server';
import store from '../../lib/store';
import Paper from '@material-ui/core/Paper';
import { MDBDataTable } from 'mdbreact';
import Loader from '../../components/Loader';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import ListIcon from '@material-ui/icons/List';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormularioGeografia from '../../components/Formularios/FormularioGeografia';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Dropdown, Button } from 'react-bootstrap';
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Selects from 'react-select';
import swal from 'sweetalert';
import EditICon from '@material-ui/icons/Edit';
import DeleteIcon from '../../assets/img/delete.png';
import AddIcon from '../../assets/img/plus.png';
import SyncIcon from '../../assets/img/sync.png';
import SuccessIcon from '../../assets/img/success.png';
import { FormGroup } from 'semantic-ui-react';
import { Switch, DialogTitle } from '@material-ui/core';
import moment from 'moment';
import "moment/locale/es";
const idModulo = 45;
var permisos = '';
const plataforma = [
	{value: 1, label: 'WEB'},
	{value: 2, label: 'POS'},
	{value: 3, label: 'TODOS'}
];
const estado = [
	{value: 'A', label: 'ACTIVO'},
	{value: 'I', label: 'INACTIVO'}
];
const tipo = [
	{value: 'string', label: 'TEXTO'},
	{value: 'number', label: 'NUMERICO'},
	{value: 'date', label: 'FECHA'},
	{value: 'time', label: 'HORA'},
	{value: 'boolean', label: 'BOOLEANO'}
];
var ntoken = 'Bearer '.concat(localStorage.getItem('token'));
class creacionPais extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//formulario geografia
			paises: [],
			provincias: [],
			ciudades: [],
			value: 0,
			value2 : 0,
			valor: 0,
			crearGeografia: false,
			tipoGeografia: 0,
			editarGeografia: false,
			//formulario parametros
			descripcion: '',
            tipo: '',
            informacion: '',
			orden: '',
			valor_default: '',
			cod_ref: '',
			entidad: '',
			obtener_parametros: [],
			parametros_empresa: [],
			parametros: [],
			parametrosEmpresas: [],
			tipoProductosbd: [],
			empresasTipos: [],
			equiposTipos: [],
			gruposTipos: [],
			surtidoresTipos: [],
			identificadoresOrig: [],
			valueProd: false,
			entidades: [{label: 'EMPRESAS TIPOS', tabla: 'empresas_tipos', id: 6},
			{label: 'EQUIPOS TIPOS', tabla: 'equipos_tipos', id: 7},
			{label: 'GRUPOS TIPOS', tabla: 'grupos_tipos', id: 8},
			{label: 'SURTIDORES TIPOS', tabla: 'surtidores_tipos', id: 9},
			{label: 'IDENTIFICADORES ORIGENES', tabla: 'identificadores_origenes', id: 10}],
			current_entidad: '',
			//formulario parametros empresas
			checked: false,
			vlr: '',
			selectedDate: new Date(),
			//selects parametros
            estadoSeleccionado: {},
			plataformaSeleccionada: {},
			tipoSeleccionado: {},
			//propiedades
			actualizarDatos: false,
			abrirDialog: false,
			abrirDialogProd: false,
			id: '',
			tablaParam: '',
		};
		store.subscribe(() => {
			this.setState({
				crearGeografia: store.getState().creacionGeografia,
				actualizarDatos: store.getState().actualizarvista,
			});
		});

		this.handleDescripcion = this.handleDescripcion.bind(this);
		this.handleCodRef = this.handleCodRef.bind(this);
		this.handleEntidad = this.handleEntidad.bind(this);
        this.handleTipo = this.handleTipo.bind(this);
        this.handleInformacion = this.handleInformacion.bind(this);
		this.handleOrden = this.handleOrden.bind(this);
		this.handleValorDefault = this.handleValorDefault.bind(this);
		this.handleEstado = this.handleEstado.bind(this);
		this.handlePlataforma = this.handlePlataforma.bind(this);
		this.handleCheck = this.handleCheck.bind(this);
	}

	changeTab(value){
		this.setState({value});
		this.limpiarForm();
	}

	changeTab2(value2){
		this.setState({value2});
		this.limpiarForm();
	}

	handlePlataforma(val){
		this.setState({
			plataformaSeleccionada: val
		});
	}
	
	handleEstado(val){
		this.setState({
			estadoSeleccionado: val
		});
	}

	handleTipo(val){
        this.setState({
			tipoSeleccionado : val
		});
	}

	handleEstadoParametros = (index) => value =>{
		var parametros = this.state.parametros_empresa;
		parametros[index].estado = value.value;
		this.setState({
			parametros_empresa: parametros
		});
	}

	handleCheck = (index) => event => {
		var parametros = this.state.parametros_empresa;
		parametros[index].valor = event.target.value;
		this.setState({
			parametros_empresa: parametros
		});
	};

	handleChange = (name) => event => {
		this.setState({
            [name]: event.target.value.toUpperCase()
        });
	};

	changeParametros = (name) => event =>{
		var parametros = this.state.parametros_empresa;
		parametros[name].valor = event.target.value.toUpperCase();
		this.setState({
			parametros_empresa: parametros
		});
	};

	handleChangemoment = (name) => date => {
		var parametros = this.state.parametros_empresa;
		parametros[name].valor = date;
        this.setState({ parametros_empresa: parametros});
    };

	handleCodRef(evt){
		this.setState({
			cod_ref: evt.target.value.toUpperCase()
		});
	}

	handleEntidad(evt){
		this.setState({
			entidad: evt.target.value.toUpperCase()
		});
	}

	handleDescripcion(evt){
		this.setState({
			descripcion: evt.target.value.toUpperCase()
		});
	}

	handleInformacion(evt){
        this.setState({
            informacion: evt.target.value.toUpperCase()
        });
	}
	
	handleValorDefault(evt){
		this.setState({
			valor_default: evt.target.value.toUpperCase()
		});
	}

    handleOrden(evt){
        this.setState({
            orden: evt.target.value.toUpperCase()
        });
    }
	
	validarFormulario = (evt) => {
		const { descripcion, plataforma, estadoSeleccionado, tipo, informacion, orden, valor_default } = this.state;
		evt.preventDefault();
        if(!descripcion || descripcion.trim == "" || descripcion.length == 0){
            swal("ERROR", "EL CAMPO DESCRIPCION NO PUEDE ESTAR VACIO", "warning");
            return false;
        }else if(JSON.stringify(plataforma) == '{}'){
            swal("ERROR", "DEBE SELECCIONAR UN TIPO DE PLATAFORMA", "warning");
            return false;
        }else if(JSON.stringify(estadoSeleccionado) == '{}' || !estadoSeleccionado){
            swal("ERROR", "DEBE SELECCIONAR UN ESTADO", "warning");
            return false;
        }else if(JSON.stringify(tipo) == '{}'){
            swal("ERROR", "EL CAMPO TIPO NO PUEDE ESTAR VACIO", "warning");
            return false;
        }else if(!informacion ||informacion.trim == "" || informacion.length == 0){
            swal("ERROR", "EL CAMPO INFORMACIÓN NO PUEDE ESTAR VACIO", "warning");
            return false;
        }else if(!orden || orden.length == 0 || isNaN(parseInt(orden))){
            swal("ERROR", "EL CAMPO ORDEN NO PUEDE ESTAR VACIO Y DEBE SER UN NÚMERO", "warning");
            return false;
		}else if(!valor_default || valor_default.trim == "" || valor_default.length == 0){
			swal("ERROR", "EL CAMPO VALOR DEFAULT NO PUEDE ESTAR VACIO", "warning");
			return false;
		}else{
            return this.state.abrirDialog ? this.updateForm() : this.enviarDatos();
        }
	};

	validarFormularioProd = (evt) => {
		const { descripcion, estadoSeleccionado, entidad, cod_ref, value } = this.state;
		evt.preventDefault();
		console.log("validando");

		if(JSON.stringify(estadoSeleccionado) == '{}' || !estadoSeleccionado){
			swal("ERROR", "DEBE SELECCIONAR UN ESTADO", "warning");
			return false;
		} else if(!descripcion || descripcion.trim == "" || descripcion.length == 0){
			swal("ERROR", "EL CAMPO DESCRIPCION NO PUEDE ESTAR VACIO", "warning");
			return false;
		} else if(value === 7){
			if(JSON.stringify(estadoSeleccionado) == '{}' || !estadoSeleccionado){
				swal("ERROR", "DEBE SELECCIONAR UN ESTADO", "warning");
				return false;
			}else if(!descripcion || descripcion.trim == "" || descripcion.length == 0){
				swal("ERROR", "EL CAMPO DESCRIPCION NO PUEDE ESTAR VACIO", "warning");
				return false;
			}else if(!cod_ref || cod_ref.trim == "" || cod_ref.length == 0){
				swal("ERROR", "EL CAMPO COD. REFERENCIA NO PUEDE ESTAR VACIO", "warning");
				return false;
			}else{
				return this.state.valueProd ? this.updateFormProd() : this.enviarDatosProd("equipos_tipos");
			}
		} else if(value === 8){
			if(JSON.stringify(estadoSeleccionado) == '{}' || !estadoSeleccionado){
				swal("ERROR", "DEBE SELECCIONAR UN ESTADO", "warning");
				return false;
			}else if(!descripcion || descripcion.trim == "" || descripcion.length == 0){
				swal("ERROR", "EL CAMPO DESCRIPCION NO PUEDE ESTAR VACIO", "warning");
				return false;
			}else if(!entidad || isNaN(parseInt(entidad)) || entidad.length == 0){
				swal("ERROR", "EL CAMPO ENTIDAD NO PUEDE ESTAR VACIO Y DEBE SER UN NÚMERO", "warning");
				return false;
			}else{
				return this.state.valueProd ? this.updateFormProd() : this.enviarDatosProd("grupos_tipos");
			}
		}else{
			return this.state.valueProd ? this.updateFormProd() : this.enviarDatosProd(value === 5 ? "productos_tipos" : value === 6 ? "empresas_tipos" : value === 9 ? "surtidores_tipos" : "identificadores_origenes");
		}
	};

	limpiarForm = () => {
		this.setState({
			descripcion: '',
			plataformaSeleccionada: '',
			estadoSeleccionado: '',
			tipoSeleccionado: '',
			informacion: '',
			orden: '',
			valor_default: '',
			cod_ref: '',
			entidad: ''
		});
	};

	obtenerDatos = () => {
		//Permisos usuarios
		store.dispatch({
			type: "ACTUALIZACION_DATOS",
			actualizarvista: false
		});
		var modulos = store.getState().perfilesUSuario;
		var datos = store.getState().usuario;
		modulos.map((modulo) => {
			if (modulo.modulos_id == idModulo) {
				permisos = modulo.acciones;
			}
		});
		
		Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/paises/', { headers: { Authorization: ntoken } })
		.then(responsee => {
			var pais = [];
			if(responsee.status == 200){
				responsee.data.map((provincia) => {
					pais.push(provincia);
				});
			}
			this.setState({ paises: pais, loader: false });
		});
		Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/ciudades/', { headers: { Authorization: ntoken } })
		.then(responsee => {
			var ciudad = [];
			if(responsee.status == 200){
				responsee.data.map((provincia) => {
					ciudad.push(provincia);
				});
			}
			this.setState({ ciudades: ciudad, loader: false });
		});
		Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/provincias/', { headers: { Authorization: ntoken } })
		.then(responsee => {
			var provincias = [];
			if(responsee.status == 200){
				responsee.data.map((provincia) => {
					provincias.push(provincia);
				});
			}
			this.setState({ provincias: provincias, loader: false });
		});
		Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion', { headers: { Authorization: ntoken } })
		.then(responsee => {
			var parametros = [];
			if(responsee.status == 200){
				responsee.data.parametros.map((descripcion) => {
					parametros.push(descripcion);
				});
			}
			this.setState({ obtener_parametros: parametros, loader: false });
		});
		Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion/parametros_empresas/' + parseInt(datos.empresas_id, 10), { headers: { Authorization: ntoken } })
		.then(responsee => {
			var parametrosEmpresas = [];
			if(responsee.status == 200){
				responsee.data.parametros.map((descripcion) => {
					parametrosEmpresas.push(descripcion);
				});
			}
			this.setState({ parametros_empresa: parametrosEmpresas, loader: false });
		});
		Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/tipo/productos', { headers: { Authorization: ntoken } })
		.then(responsee => {
			var tipos = [];
			if(responsee.status == 200){
				responsee.data.data.map((productos) => {
					tipos.push(productos);
				});
			}
			this.setState({ tipoProductosbd: tipos, loader: false });
		});
		Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion/entidades/empresas_tipos', { headers: { Authorization: ntoken } })
		.then(response => {
			var empresas = [];
			if(response.status == 200){
				response.data.parametros.rows.map((empresa) => {
					empresas.push(empresa);
				});
			}
			this.setState({ empresasTipos: empresas, loader: false });
		});
		Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion/entidades/equipos_tipos', { headers: { Authorization: ntoken } })
		.then(responsee => {
			var equipos_tipos = [];
			if(responsee.status == 200){
				responsee.data.parametros.rows.map((equipo) => {
					equipos_tipos.push(equipo);
				});
			}
			this.setState({ equiposTipos: equipos_tipos, loader: false });
		});
		Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion/entidades/grupos_tipos', { headers: { Authorization: ntoken } })
		.then(responsee => {
			var grupos_tipos = [];
			if(responsee.status == 200){
				responsee.data.parametros.rows.map((grupo) => {
					grupos_tipos.push(grupo);
				});
			}
			this.setState({ gruposTipos: grupos_tipos, loader: false });
		});
		Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion/entidades/surtidores_tipos', { headers: { Authorization: ntoken } })
		.then(response => {
			var surtidores_tipos = [];
			if(response.status == 200){
				response.data.parametros.rows.map((surtidor) => {
					surtidores_tipos.push(surtidor);
				});
				this.setState({ surtidoresTipos: surtidores_tipos, loader: false });
			} 
		});
		Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion/entidades/identificadores_origenes', { headers: { Authorization: ntoken } })
		.then(response => {
			var identificacion = [];
			if(response.status == 200){
				response.data.parametros.rows.map((identificador) => {
					identificacion.push(identificador);
				});
			}
			this.setState({ identificadoresOrig: identificacion, loader: false });
		})
		.catch(function (error) {
			console.error(error);
		});
	};

	enviarDatos = () => {
		var desc = this.state.descripcion;
		var vlr_default = this.state.valor_default;
		var plataforma_select = this.state.plataformaSeleccionada;
		var estado_select = this.state.estadoSeleccionado;
		var Tipo = this.state.tipoSeleccionado;
		var info = this.state.informacion;
		var Orden = this.state.orden;
		var self = this;
		var data = {
			"descripcion": desc,
			"valor_default": vlr_default,
			"plataforma": plataforma_select.value,
			"estado": estado_select.value,
			"tipo": Tipo.value,
			"informacion": info,
			"orden": Orden
		};
		Axios.post(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion', data, { headers: { Authorization: ntoken } })
		.then(function(response){
			var mensaje = "OPERACIÓN EJECUTADA CON ÉXITO";
			if(response.status === 200 || response.status === 201){
				if(response.data.success){
					swal({
						title: mensaje,
						icon: "success",
						closeOnClickOutside: false,
						buttons: {
							Seguir: {
								text: "OK",
								value: "continuar"
							}
						},
					}).then(function(value) {
                        switch (value) {

                            case "continuar":
								store.dispatch({
									type: "ACTUALIZACION_DATOS",
									actualizarvista: true
								});
                                self.cerrarDialog();
                                break;

                            default:
								break;
                        }
                    });
				} else {
					swal("ERROR", "NO SE PUDO COMPLETAR LA OPERACIÓN", "warning");
				}
			} else if (response.status == 205) {
				swal("ERROR", "LA SESION HA CADUCADO", "warning");
				setTimeout(function () { window.location.href = '/'; }, 3000);
			}
		});
	};

	enviarDatosProd = (tabla) => {
		var usuario = store.getState().usuario;
		console.log(tabla);
		if(this.state.value === 7){
			var desc = this.state.descripcion;
			var estado_select = this.state.estadoSeleccionado;
			var param_id = this.state.obtener_parametros;
			var cod_ref = this.state.cod_ref;
			console.log("update");
			var data = {
				"descripcion": desc,
				"id": param_id.id,
				"estado": estado_select.value,
				"create_user": usuario.p_id,
				"empresas_id": usuario.empresas_id,
				"codigo_referencia": cod_ref
			};
		}else if(this.state.value === 8){
			var desc = this.state.descripcion;
			var estado_select = this.state.estadoSeleccionado;
			var param_id = this.state.obtener_parametros;
			var entidad = this.state.entidad;
			console.log("isla");
			var data = {
				"descripcion": desc,
				"id": param_id.id,
				"estado": estado_select.value,
				"entidad": entidad,
				"create_user": usuario.p_id,
				"empresas_id": usuario.empresas_id,
			};
		}else {
			var desc = this.state.descripcion;
			var estado_select = this.state.estadoSeleccionado;
			var param_id = this.state.obtener_parametros;
			console.log("isla");
			var data = {
				"descripcion": desc,
				"id": param_id.id,
				"estado": estado_select.value,
				"create_user": usuario.p_id,
				"empresas_id": usuario.empresas_id,
			};			
		}
		var self = this;
		Axios.post(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion/entidades/' + tabla, data, { headers: { Authorization: ntoken } })
		.then(function(response){
			var mensaje = "OPERACIÓN EJECUTADA CON ÉXITO";
			if(response.status === 200 || response.status === 201){
				if(response.data.success){
					swal({
						title: mensaje,
						icon: "success",
						closeOnClickOutside: false,
						buttons: {
							Seguir: {
								text: "OK",
								value: "continuar"
							}
						},
					}).then(function(value) {
                        switch (value) {

                            case "continuar":
								store.dispatch({
									type: "ACTUALIZACION_DATOS",
									actualizarvista: true
								});
                                self.cerrarDialog();
                                break;

                            default:
								break;
                        }
                    });
				} else {
					swal("ERROR", "NO SE PUDO COMPLETAR LA OPERACIÓN", "warning");
				}
			} else if (response.status == 205) {
				swal("ERROR", "LA SESION HA CADUCADO", "warning");
				setTimeout(function () { window.location.href = '/'; }, 3000);
			}
		});
	};

	enviarParametrosIndividuales(parametro){
		var datos = store.getState().usuario;
		var data = [{
			"parametros_id": parametro._id,
			"valor": parametro.valor,
			"estado": parametro.estado,
			"empresas_id": parseInt(datos.empresas_id, 10)
		}];

		Axios.post(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion/parametros_empresas', data, { headers: { Authorization: ntoken } })
		.then(function(response){
			var mensaje = "Datos actualizados correctamente";
			if(response.status === 201){
				if(response.data.success){
					swal({
						title: mensaje,
						icon: "success",
						closeOnClickOutside: false,
						buttons: {
							Seguir: {
								text: "OK",
								value: "continuar"
							}
						},
					}).then(function(value) {
                        switch (value) {
                            case "continuar":
								store.dispatch({
									type: "ACTUALIZACION_DATOS",
									actualizarvista: true
								});
                                break;

                            default:
								break;
                        }
                    });
				} else {
					swal("ERROR", "No se han podido actualizar los datos", "warning");
				}
			} else if (response.status == 205) {
				swal("ERROR", "LA SESION HA CADUCADO", "warning");
				setTimeout(function () { window.location.href = '/'; }, 3000);
			}
		});
	}

	componentWillMount(){
		this.obtenerDatos();
	}

	FormularioParametros(){
		var data = {
			columns: [
				{
					label: 'Descripcion',
					field: 'desc',
					sort: 'asc'
				},
				{
					label: 'Default',
					field: 'vlr_def',
					sort: 'asc'
				},
				{
					label: 'Plataforma',
					field: 'plataforma',
					sort: 'asc'
				},
				{
					label: 'Estado',
					field: 'estado',
					sort: 'asc'
				},
				{
					label: 'Tipo',
					field: 'tipo',
					sort: 'asc'
				},
				{
					label: 'Información',
					field: 'info',
					sort: 'asc'
				},
				{
					label: 'Acciones',
					field: 'acciones',
					sort: 'asc'
				},
			], rows: this.state.obtener_parametros.map((parametros) => {
				return (parametros.estado !== null ? {
					"descripcion": parametros.descripcion,
					"vlr. default": parametros.valor_default,
					"plataforma": parametros.plataforma === 1 ? 'WEB' : parametros.plataforma === 2 ? 'POS' : 'TODOS',
					"estado": parametros.estado === 'A' ? 'ACTIVO' : 'INACTIVO',
					"tipo": parametros.tipo === 'string' ? 'TEXTO' : parametros.tipo === 'number' ? 'NUMERICO' : parametros.tipo === 'date' ? 'FECHA' : parametros.tipo === 'time' ? 'HORA' : 'BOOLEANO',
					"informacion": parametros.informacion,
					"acciones": [parametros.estado === 'I' ? null : <button className="btn btn-sm btn-primary" onClick={() => this.editarForm(parametros)} style={{ marginLeft: '5px' }}><EditICon /></button>,
					parametros.estado === 'A' ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.confirmarBorrar(parametros)}><img src={DeleteIcon}/></button>
					: <button className="btn btn-sm btn-success" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.activarDatos(parametros)}><img src={SuccessIcon}/></button>
				]
				} : {});
			})
		};
		return(
            <div>
				{this.state.abrirDialog ? null : <div className="col-md-12 col-sm-12">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <label htmlFor="descripcion" className="col-form-label">Descripcion</label>
                            <input autoComplete="off" id="descripcion" value={this.state.descripcion} onChange={this.handleChange('descripcion')} className="form-control" type="text"/>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <label htmlFor="plataforma" className="col-form-label">Tipo de plataforma</label>
                            <Selects
								placeholder="Seleccione el tipo de plataforma"
                                value={this.state.plataformaSeleccionada}
                                onChange={value => this.handlePlataforma(value)}
                                options={plataforma}
                            />
                        </div>
					</div>
					<div className="row">
						<div className="col-md-6 col-sm-12">
							<label htmlFor="estado" className="col-form-label">Estado</label>
							<Selects
								placeholder="Seleccione el estado"
								value={this.state.estadoSeleccionado}
								onChange={value => this.handleEstado(value)}
								options={estado}
							/>
						</div>
						<div className="col-md-6 col-sm-12">
							<label htmlFor="tipo" className="col-form-label">Tipo</label>
							<Selects
								placeholder="Seleccione el tipo de formato"
                                value={this.state.tipoSeleccionado}
                                onChange={value => this.handleTipo(value)}
                                options={tipo}
                            />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 col-sm-12">
							<label htmlFor="informacion" className="col-form-label">informacion</label>
							<input id="informacion" autoComplete="off" value={this.state.informacion} onChange={this.handleChange('informacion')} className="form-control" type="text" />
						</div>
						<div className="col-md-6 col-sm-12">
							<label htmlFor="orden" className="col-form-label">Orden</label>
							<input id="orden" autoComplete="off" value={this.state.orden} onChange={this.handleChange('orden')} className="form-control" type="number" min="1" max="10"/>
						</div>
					</div>
					<div className="row bottom-aligned">
						<div className="col-md-6 col-sm-12">
							<label htmlFor="orden" className="col-form-label">Valor por default</label>
							<input autoComplete="off" id="orden" value={this.state.valor_default} onChange={this.handleChange('valor_default')} className="form-control" type="text" />
						</div>
						<div className="col-md-3 col-sm-12">
							<button onClick={this.validarFormulario} className="col-md-12 btn btn-primary" style={{ float: "right", marginRight : "5px"}}>Guardar</button>
						</div>
						<div className="col-md-3 col-sm-12">
							<button onClick={this.limpiarForm} className="col-md-12 btn btn-danger" style={{ float: "right" }}>Borrar</button>
						</div>
					</div>
					<br/>
				</div>}
               	<div className="col col-md-12" style={{ padding: 0 }}>
					<Paper style={{ padding: '5px' }}>
						<MDBDataTable
							//striped
							className="col-md-12"
							searchLabel="Buscar"
							paginationLabel={["Anterior", "Siguente"]}
							infoLabel={["Mostrando", "a", "de", "entradas"]}
							entriesLabel="Filas a mostrar"
							tbodyColor="primary-color"
							theadColor="#FF8800"
							exportToCSV={true}
							striped
							bordered
							style={{fontSize: '13px'}}
							order={['descripcion', 'asc']}
							autoWidth={false}
							responsiveSm={true}
							responsive={true}
							hover
							data={data}
						/>
					</Paper>
				</div>
			</div>
        )
	}

	updateForm = () => {
		var desc = this.state.descripcion;
		var vlr_default = this.state.valor_default;
		var plataforma_select = this.state.plataformaSeleccionada;
		var estado_select = this.state.estadoSeleccionado;
		var Tipo = this.state.tipoSeleccionado;
		var info = this.state.informacion;
		var Orden = this.state.orden;
		var param_id = this.state.obtener_parametros;
		var data = {
			"descripcion": desc,
			"valor_default": vlr_default,
			"plataforma": plataforma_select.value,
			"_id": param_id.id,
			"estado": estado_select.value,
			"tipo": Tipo.value,
			"informacion": info,
			"orden": Orden
		}; 
		var self = this;
		Axios.put(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion', data, { headers: { Authorization: ntoken } })
		.then(function(response){
			var mensaje = "OPERACIÓN EJECUTADA CON ÉXITO";
			if(response.status === 200 || response.status === 201){
				if(response.data.success){
					swal({
						title: mensaje,
						icon: "success",
						closeOnClickOutside: false,
						buttons: {
							Seguir: {
								text: "OK",
								value: "continuar"
							}
						},
					}).then(function(value) {
                        switch (value) {
                            case "continuar":
								store.dispatch({
									type: "ACTUALIZACION_DATOS",
									actualizarvista: true
								});
                                self.cerrarDialog();
                                break;

                            default:
								break;
                        }
                    });
				} else {
					swal("ERROR", "NO SE PUDO COMPLETAR LA OPERACIÓN EN EL MOMENTO", "warning");
				}
			} else if (response.status == 205) {
				swal("ERROR", "LA SESION HA CADUCADO", "warning");
				setTimeout(function () { window.location.href = '/'; }, 3000);
			}
		}).catch(function (error) {
			// handle error
			console.error(error);
			swal("ERROR", "NO SE PUDO COMPLETAR LA OPERACIÓN EN EL MOMENTO", "warning")
		});
	};

	updateFormProd = () => {
		var usuario = store.getState().usuario
		var tabla = this.state.tablaParam;
		var id = this.state.id;
		console.log(tabla, id)
		if(this.state.value === 7){
			var desc = this.state.descripcion;
			var estado_select = this.state.estadoSeleccionado;
			var cod_ref = this.state.cod_ref;
			var data = {
				"descripcion": desc,
				"id": id,
				"estado": estado_select.value,
				"create_user": usuario.p_id,
				"empresas_id": usuario.empresas_id,
				"codigo_referencia": cod_ref
			};
		}else if(this.state.value === 8){
			var desc = this.state.descripcion;
			var estado_select = this.state.estadoSeleccionado;
			var entidad = this.state.entidad;
			var data = {
				"descripcion": desc,
				"id": id,
				"estado": estado_select.value,
				"entidad": entidad,
				"create_user": usuario.p_id,
				"empresas_id": usuario.empresas_id,
			};
		}else {
			var desc = this.state.descripcion;
			var estado_select = this.state.estadoSeleccionado;
			var data = {
				"descripcion": desc,
				"id": id,
				"estado": estado_select.value,
				"create_user": usuario.p_id,
				"empresas_id": usuario.empresas_id,
			};			
		}
		var self = this;
		Axios.put(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion/entidades/' + tabla, data, { headers: { Authorization: ntoken } })
		.then(function(response){
			var mensaje = "OPERACIÓN EJECUTADA CON ÉXITO";
			if(response.status === 200 || response.status === 201){
				if(response.data.success){
					swal({
						title: mensaje,
						icon: "success",
						closeOnClickOutside: false,
						buttons: {
							Seguir: {
								text: "OK",
								value: "continuar"
							}
						},
					}).then(function(value) {
                        switch (value) {
                            case "continuar":
								store.dispatch({
									type: "ACTUALIZACION_DATOS",
									actualizarvista: true
								});
                                self.cerrarDialog();
                                break;

                            default:
								break;
                        }
                    });
				} else {
					swal("ERROR", "NO SE PUDO COMPLETAR LA OPERACIÓN EN EL MOMENTO", "warning");
				}
			} else if (response.status == 205) {
				swal("ERROR", "LA SESION HA CADUCADO", "warning");
				setTimeout(function () { window.location.href = '/'; }, 3000);
			}
		}).catch(function (error) {
			// handle error
			console.error(error);
			swal("ERROR", "NO SE PUDO COMPLETAR LA OPERACIÓN EN EL MOMENTO", "warning")
		});
	};

	FormParametrosEmpresas(){
		var data = {
			columns: [
				{
					label: 'Descripcion',
					field: 'desc',
					sort: 'asc'
				},
				{
					label: 'valor',
					field: 'valor',
					sort: 'asc'
				},
				{
					label: 'P. Empresa',
					field: 'param_empresa',
					sort: 'asc'
				},
				{
					label: 'Estado',
					field: 'estado',
					sort: 'asc'
				},
				{
					label: 'Tipo',
					field: 'tipo',
					sort: 'asc'
				},
				{
					label: 'Información',
					field: 'info',
					sort: 'asc'
				},
				{
					label: 'Acciones',
					field: 'acciones',
					sort: 'asc'
				},
			], rows: this.state.parametros_empresa.map((parametros) => {
				return (parametros.estado !== null ? {
					"descripcion": parametros.descripcion,
					"valor": parametros.tipo === 'time' ? moment(parametros.valor).format('hh:mm:ss a') : parametros.valor,
					"P. Empresa": parametros.parametros_empresas_id,
					"estado": parametros.estado === 'A' ? 'ACTIVO' : 'INACTIVO',
					"tipo": parametros.tipo === 'string' ? 'TEXTO' : parametros.tipo === 'number' ? 'NUMERICO' : parametros.tipo === 'date' ? 'FECHA' : parametros.tipo === 'time' ? 'HORA' : 'BOOLEANO',
					"informacion": parametros.informacion,
					"acciones": [parametros.estado === 'A' ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.confirmarBorrarEmpresas(parametros)}><img src={DeleteIcon}/></button>
					: <button className="btn btn-sm btn-success" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.activarDatosEmpresas(parametros)}><img src={SuccessIcon}/></button>
					]
				} : {});
			})
		}
		return(
			<div>
				<AppBar position="static" color="default" style={{zIndex: 1}}>
					<Tabs
						value={this.state.value2}
						variant="scrollable"
						scrollButtons="on"
						indicatorColor="primary"
						textColor="primary">
						<Tab label="Formulario" onClick={() => this.changeTab2(0)} icon={<ListIcon />} />
						<Tab label="Tabla" onClick={() => this.changeTab2(1)} icon={<ListIcon />} />
					</Tabs>
				</AppBar> 
				{this.state.value2==0?
				<div className="col-md-12 col-sm-12 ">
				{this.state.parametros_empresa.map((parametros, index) => {
					return (
						<div className="row" style={{marginBottom: '5px'}}>
							{parametros.tipo.toUpperCase()=='STRING' ?
								<div className="col-md-6">
									<label  className="col-form-label">
										{parametros.descripcion}:
									</label>
									<input id={"parametro_" + parametros._id} value={parametros.valor} autoComplete="off" className="form-control" onChange={this.changeParametros(index)} type="text"/>
								</div>:
							parametros.tipo.toUpperCase()=='NUMBER' ?
								<div className="col-md-6">
									<label  className="col-form-label">
										{parametros.descripcion}: 
									</label>
									<input id={"parametro_" + parametros._id} value={parametros.valor} autoComplete="off" className="form-control" onChange={this.changeParametros(index)} type="number"/>
								</div>:
							parametros.tipo.toUpperCase()=='DATE' ?
								<div className="col-md-6">
									<label  className="col-form-label">
										{parametros.descripcion}: 
									</label> 
									<MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
										<DatePicker
											format="yyyy-MM-dd"
											id={"parametro_" + parametros._id}
											value={parametros.valor}
											onChange={this.handleChangemoment(index)}
											className="rounded form-control"
											style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
										/>
									</MuiPickersUtilsProvider>
								</div>:
							parametros.tipo.toUpperCase()=='TIME' ?
								<div className="col-md-6">
									<label  className="col-form-label">
										{parametros.descripcion}: 
									</label> 
									<MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
										<TimePicker
											format="HH:mm:ss"
											ampm={false}
											id={"parametro_" + parametros._id}
											value={parametros.valor}
											onChange={this.handleChangemoment(index)}
											className="rounded form-control"
											style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
										/>
									</MuiPickersUtilsProvider>
								</div>:
							parametros.tipo.toUpperCase()=='BOOLEAN' ?
								<div className="col-md-6">
									<label className="col-form-label">{parametros.descripcion}: </label>
									<FormGroup>
										<FormControlLabel style={{ padding: '0px', margin: '0px' }} control={
												<Switch onChange={this.handleCheck(index)} value={parametros.valor === 'false' ? false : true}/>
											} label={parametros.valor == 'true' ? 'VERDADERO' : 'FALSO' }
										/>
									</FormGroup>
								</div>  : null }
								<div className="col-md-5">
									<label htmlFor="estado" className="col-form-label">Estado</label>
									<Selects
										placeholder="Seleccione el estado"
										value={{label: parametros.estado=== 'A' ? 'ACTIVO' : 'INACTIVO', value: parametros.estado}}
										onChange={this.handleEstadoParametros(index)}
										options={estado}
									/>
								</div>
								{parametros.estado === 'A' ?
									<div className="col-md-1" style={{marginTop: '40px'}}>
										<button className="btn btn-sm btn-primary" id={"parametro_" + parametros._id} onClick={() => this.enviarParametrosIndividuales(parametros)}>
											<EditICon></EditICon>
										</button>
									</div>
								: null}
								<div style={{width: '100%', height: '0', border: '1px solid #80808066', marginTop: '5px', marginBottom: '5px'}}/>
						</div>
					);
				})}
				</div>:this.state.value2==1?
					<div className="col col-md-12" style={{ padding: 0 }}>
						<Paper style={{ padding: '5px' }}>
							<MDBDataTable
								//striped
								className="col-md-12"
								searchLabel="Buscar"
								paginationLabel={["Anterior", "Siguente"]}
								infoLabel={["Mostrando", "a", "de", "entradas"]}
								entriesLabel="Filas a mostrar"
								tbodyColor="primary-color"
								theadColor="#FF8800"
								exportToCSV={true}
								striped
								bordered
								style={{fontSize: '13px'}}
								order={['descripcion', 'asc']}
								autoWidth={false}
								responsiveSm={true}
								responsive={true}
								hover
								data={data}
							/>
						</Paper>
				</div>:null}
			</div>
		)
	}

	crearGeografia = (tipo) => {
        this.setState({ tipoGeografia: tipo })
        store.dispatch({
            type: "CREACION_GEOGRAFIA",
			creacionGeografia: true,
        })
	}

	editarGeografia = (tipo, data) =>{
		this.setState({ tipoGeografia: tipo })
		store.dispatch({
			type: "EDICION_GEOGRAFIA",
			creacionGeografia : true,
			geografiaEdit: data,
			modoEdicionGeografia: true
		})
	}

	cerrarGeografia = () => {
        this.setState({ tipoGeografia: 0 })
        store.dispatch({
            type: "CREACION_GEOGRAFIA",
            creacionGeografia: false
        })
	}

	confirmarBorrar(parametros){
		var mensaje = "¿ESTÁ SEGURO DE QUE DESEA INACTIVAR ESTE PARAMETRO?"
		var self = this;
		swal({
			title: mensaje,
			icon: "warning",
			closeOnClickOutside: true,
			buttons: {
				Seguir: {
					text: "INACTIVAR",
					value: "continuar"
				},
				cancel: true
			},
		}).then(function(value) {
			switch (value) {
				case "continuar":
					self.borrarDatos(parametros);
					break;

				case "salir":
					return false;
			}
		});
	}
	
	confirmarBorrarEmpresas(parametros){
		var mensaje = "¿ESTÁ SEGURO DE QUE DESEA INACTIVAR ESTE PARAMETRO?"
		var self = this;
		swal({
			title: mensaje,
			icon: "warning",
			closeOnClickOutside: true,
			buttons: {
				Seguir: {
					text: "INACTIVAR",
					value: "continuar"
				},
				cancel: true
			},
		}).then(function(value) {
			switch (value) {
				case "continuar":
					self.borrarDatosEmpresas(parametros);
					break;

				case "salir":
					return false;
			}
		});
	}

	borrarDatos = (parametros) => {
		var desc = parametros.descripcion;
		var vlr_default = parametros.valor_default;
		var plataforma_select = parametros.plataforma !== null ? {
			value: parametros.plataforma,
			label: parametros.plataforma
		} : {};
		var estado_select = 'I';
		var Tipo = parametros.tipo !== null ? {
			value: parametros.tipo,
			label: parametros.tipo
		} : {};
		var info = parametros.informacion;
		var Orden = parametros.orden;
		var param_id = this.state.obtener_parametros;
		var data = {
			"descripcion": desc,
			"valor_default": vlr_default,
			"plataforma": plataforma_select.value,
			"_id": param_id.id,
			"estado": estado_select,
			"tipo": Tipo.value,
			"informacion": info,
			"orden": Orden
		}; 
		var self = this;
		Axios.put(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion', data, { headers: { Authorization: ntoken } })
		.then(function(response){
			var mensaje = "OPERACIÓN EJECUTADA CON ÉXITO";
			if(response.status === 200 || response.status === 201){
				if(response.data.success){
					swal({
						title: mensaje,
						icon: "success",
						closeOnClickOutside: false,
						buttons: {
							Seguir: {
								text: "OK",
								value: "continuar"
							}
						},
					}).then(function(value) {
                        switch (value) {
                            case "continuar":
								store.dispatch({
									type: "ACTUALIZACION_DATOS",
									actualizarvista: true
								});
                                self.cerrarDialog();
                                break;

                            default:
								break;
                        }
                    });
				} else {
					swal("ERROR", "NO SE PUDO COMPLETAR LA OPERACIÓN EN EL MOMENTO", "warning");
				}
			} else if (response.status == 205) {
				swal("ERROR", "LA SESION HA CADUCADO", "warning");
				setTimeout(function () { window.location.href = '/'; }, 3000);
			}
		}).catch(function (error) {
			// handle error
			console.error(error);
			swal("ERROR", "NO SE PUDO COMPLETAR LA OPERACIÓN EN EL MOMENTO", "warning")
		});
	}

	activarDatos = (parametros) => {
		var desc = parametros.descripcion;
		var vlr_default = parametros.valor_default;
		var plataforma_select = parametros.plataforma !== null ? {
			value: parametros.plataforma,
			label: parametros.plataforma
		} : {};
		var estado_select = 'A';
		var Tipo = parametros.tipo !== null ? {
			value: parametros.tipo,
			label: parametros.tipo
		} : {};
		var info = parametros.informacion;
		var Orden = parametros.orden;
		var param_id = this.state.obtener_parametros;
		var data = {
			"descripcion": desc,
			"valor_default": vlr_default,
			"plataforma": plataforma_select.value,
			"_id": param_id.id,
			"estado": estado_select,
			"tipo": Tipo.value,
			"informacion": info,
			"orden": Orden
		}; 
		var self = this;
		Axios.put(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion', data, { headers: { Authorization: ntoken } })
		.then(function(response){
			var mensaje = "OPERACIÓN EJECUTADA CON ÉXITO";
			if(response.status === 200 || response.status === 201){
				if(response.data.success){
					swal({
						title: mensaje,
						icon: "success",
						closeOnClickOutside: false,
						buttons: {
							Seguir: {
								text: "OK",
								value: "continuar"
							}
						},
					}).then(function(value) {
                        switch (value) {
                            case "continuar":
								store.dispatch({
									type: "ACTUALIZACION_DATOS",
									actualizarvista: true
								});
                                self.cerrarDialog();
                                break;

                            default:
								break;
                        }
                    });
				} else {
					swal("ERROR", "NO SE PUDO COMPLETAR LA OPERACIÓN EN EL MOMENTO", "warning");
				}
			} else if (response.status == 205) {
				swal("ERROR", "LA SESION HA CADUCADO", "warning");
				setTimeout(function () { window.location.href = '/'; }, 3000);
			}
		}).catch(function (error) {
			// handle error
			console.error(error);
			swal("ERROR", "NO SE PUDO COMPLETAR LA OPERACIÓN EN EL MOMENTO", "warning")
		});
	}

	editarFormProductos = (productos, tabla) => {
		this.state.value === 7 ?
		this.setState({
			abrirDialogProd: true,
			valueProd: true,
			descripcion: productos.descripcion,
			cod_ref: productos.codigo_referencia,
			estadoSeleccionado: productos.estado !== null ? {
				value: productos.estado,
				label: productos.estado === 'A' ? 'ACTIVO' : 'INACTIVO'
			} : {}
		})
		: this.state.value === 8 ? this.setState({
			abrirDialogProd: true,
			valueProd: true,
			descripcion: productos.descripcion,
			entidad: productos.entidad,
			estadoSeleccionado: productos.estado !== null ? {
				value: productos.estado,
				label: productos.estado === 'A' ? 'ACTIVO' : 'INACTIVO'
			} : {}
		}) : this.setState({
			abrirDialogProd: true,
			valueProd: true,
			descripcion: productos.descripcion,
			estadoSeleccionado: productos.estado !== null ? {
				value: productos.estado,
				label: productos.estado === 'A' ? 'ACTIVO' : 'INACTIVO'
			} : {},
			tablaParam: tabla,
			id: productos.id
		})
	}

	abrirDialog(){
		console.log("form prod")
		this.setState({
			abrirDialogProd: true,
			valueProd: false
		})
	}

	editarForm = (parametros) => {
		this.setState({
			abrirDialog: true,
			descripcion: parametros.descripcion,
			plataformaSeleccionada: parametros.plataforma !== null ? {
				value: parametros.plataforma,
				label: parametros.plataforma === 1 ? 'WEB' : parametros.plataforma === 2 ? 'POS' : 'TODOS'
			} : {},
			estadoSeleccionado: parametros.estado !== null ? {
				value: parametros.estado,
				label: parametros.estado === 'A' ? 'ACTIVO' : 'INACTIVO'
			} : {},
            tipoSeleccionado: parametros.tipo !== null ? {
				value: parametros.tipo,
				label: parametros.tipo === 'string' ? 'TEXTO' : parametros.tipo === 'number' ? 'NUMERICO' : parametros.tipo === 'date' ? 'FECHA' : parametros.tipo === 'time' ? 'HORA' : 'BOOLEANO'
			} : {},
            informacion: parametros.informacion,
			orden: parametros.orden,
			valor_default: parametros.valor_default,
		})
	}

	activarDatosEmpresas = (parametros) => {
		var datos = store.getState().usuario;
		var valor = parametros.valor;
		var parametros_id = parametros._id;
		var estado_select = 'A';
		var data = [{
			"parametros_id": parametros_id,
			"valor": valor,
			"estado": estado_select,
			"empresas_id": parseInt(datos.empresas_id, 10)
		}];
		var self = this;
		Axios.post(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion/parametros_empresas', data, { headers: { Authorization: ntoken } })
		.then(function(response){
			var mensaje = "OPERACIÓN EJECUTADA CON ÉXITO";
			if(response.status === 200 || response.status === 201){
				if(response.data.success){
					swal({
						title: mensaje,
						icon: "success",
						closeOnClickOutside: false,
						buttons: {
							Seguir: {
								text: "OK",
								value: "continuar"
							}
						},
					}).then(function(value) {
                        switch (value) {
                            case "continuar":
								store.dispatch({
									type: "ACTUALIZACION_DATOS",
									actualizarvista: true
								});
                                self.cerrarDialog();
                                break;

                            default:
								break;
                        }
                    });
				} else {
					swal("ERROR", "NO SE PUDO COMPLETAR LA OPERACIÓN EN EL MOMENTO", "warning");
				}
			} else if (response.status == 205) {
				swal("ERROR", "LA SESION HA CADUCADO", "warning");
				setTimeout(function () { window.location.href = '/'; }, 3000);
			}
		}).catch(function (error) {
			// handle error
			console.error(error);
			swal("ERROR", "NO SE PUDO COMPLETAR LA OPERACIÓN EN EL MOMENTO", "warning")
		});
	}

	borrarDatosEmpresas = (parametros) => {
		var datos = store.getState().usuario;
		var valor = parametros.valor;
		var parametros_id = parametros._id;
		var estado_select = 'I';
		var data = [{
			"parametros_id": parametros_id,
			"valor": valor,
			"estado": estado_select,
			"empresas_id": parseInt(datos.empresas_id, 10)
		}]; 
		var self = this;
		Axios.post(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion/parametros_empresas', data, { headers: { Authorization: ntoken } })
		.then(function(response){
			var mensaje = "OPERACIÓN EJECUTADA CON ÉXITO";
			if(response.status === 200 || response.status === 201){
				if(response.data.success){
					swal({
						title: mensaje,
						icon: "success",
						closeOnClickOutside: false,
						buttons: {
							Seguir: {
								text: "OK",
								value: "continuar"
							}
						},
					}).then(function(value) {
                        switch (value) {
                            case "continuar":
								store.dispatch({
									type: "ACTUALIZACION_DATOS",
									actualizarvista: true
								});
                                self.cerrarDialog();
                                break;

                            default:
								break;
                        }
                    });
				} else {
					swal("ERROR", "NO SE PUDO COMPLETAR LA OPERACIÓN EN EL MOMENTO", "warning");
				}
			} else if (response.status == 205) {
				swal("ERROR", "LA SESION HA CADUCADO", "warning");
				setTimeout(function () { window.location.href = '/'; }, 3000);
			}
		}).catch(function (error) {
			// handle error
			console.error(error);
			swal("ERROR", "NO SE PUDO COMPLETAR LA OPERACIÓN EN EL MOMENTO", "warning")
		});
	}

	sincronizarModelos(){
		Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion/sync/parametros', { headers: { Authorization: ntoken } })
		.then(responsee => {
			if(responsee.status == 201){
				var mensaje = "Se han sincronizado los modelos correctamente"
				swal({
					title: mensaje,
					icon: "success",
					closeOnClickOutside: false,
					buttons: {
						Seguir: {
							text: "OK",
							value: "continuar"
						}
					},
				}).then(function(value) {
					switch (value) {
						case "continuar":
							store.dispatch({
								type: "ACTUALIZACION_DATOS",
								actualizarvista: true
							});
							break;

						default:
							break;
					}
				});
			} else if (responsee.status == 205) {
				swal("ERROR", "LA SESION HA CADUCADO", "warning");
				setTimeout(function () { window.location.href = '/'; }, 3000);
			}
		});
	}

	sincronizarModelosEmpresas(){
		Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion/sync/parametros_empresas', { headers: { Authorization: ntoken } })
		.then(response =>{
			if(response.status == 201){
				var mensaje = "Se han sincronizado los modelos correctamente"
				swal({
					title: mensaje,
					icon: "success",
					closeOnClickOutside: false,
					buttons: {
						Seguir: {
							text: "OK",
							value: "continuar"
						}
					},
				}).then(function(value) {
					switch (value) {
						case "continuar":
							store.dispatch({
								type: "ACTUALIZACION_DATOS",
								actualizarvista: true
							});
							break;

						default:
							break;
					}
				});
			} else if (response.status == 205) {
				swal("ERROR", "LA SESION HA CADUCADO", "warning");
				setTimeout(function () { window.location.href = '/'; }, 3000);
			}
		});
	}
	
	cerrarDialog = () => {
		this.setState({
			abrirDialog: false,
			abrirDialogProd: false
		})
		this.limpiarForm();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.state.actualizarDatos) {
		  this.obtenerDatos();
		  return true;
		}
		return true;
	}

	contenidoEntidad(){
		var data = {
			columns: this.state.value === 7 ? [
				{
					label: 'Descripcion',
					field: 'desc',
					sort: 'asc'
				},
				{
					label: 'Estado',
					field: 'estado',
					sort: 'asc'
				},
				{
					label: 'Cod. Referencia',
					field: 'cod_ref',
					sort: 'asc'
				},
				{
					label: 'Acciones',
					field: 'acciones',
					sort: 'asc'
				}
			] : this.state.value === 8 ? [
				{
					label: 'Descripcion',
					field: 'desc',
					sort: 'asc'
				},
				{
					label: 'Estado',
					field: 'estado',
					sort: 'asc'
				},
				{
					label: 'Entidad',
					field: 'entidad',
					sort: 'asc'
				},
				{
					label: 'Acciones',
					field: 'acciones',
					sort: 'asc'
				}
			] : [
				{
					label: 'Descripcion',
					field: 'desc',
					sort: 'asc'
				},
				{
					label: 'Estado',
					field: 'estado',
					sort: 'asc'
				},
				{
					label: 'Acciones',
					field: 'acciones',
					sort: 'asc'
				}
			],
			rows : this.state.value === 5 ? 
			this.state.tipoProductosbd.map((productos) => {
				return({
					descripcion: productos.descripcion,
					estado: productos.estado === 'A' ? 'ACTIVO' : 'INACTIVO',
					acciones: [productos.estado === 'I' ? null : <button className="btn btn-sm btn-primary" onClick={() => this.editarFormProductos(productos, "productos_tipos")} style={{ marginLeft: '5px' }}><EditICon /></button>,
					productos.estado === 'A' ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.inactivarGeografia(productos.id, "productos_tipos")}><img src={DeleteIcon}/></button>
					: <button className="btn btn-sm btn-success" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.activarGeografia(productos.id, "productos_tipos")}><img src={SuccessIcon}/></button>]
				})
			}) 
			: this.state.value === 6 ?
			this.state.empresasTipos.map((empresa) => {
				return({
					descripcion: empresa.descripcion,
					estado: empresa.estado === 'A' ? 'ACTIVO' : 'INACTIVO',
					acciones: [empresa.estado === 'I' ? null : <button className="btn btn-sm btn-primary" onClick={() => this.editarFormProductos(empresa, "empresas_tipos")} style={{ marginLeft: '5px' }}><EditICon /></button>,
					empresa.estado === 'A' ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.inactivarGeografia(empresa.id, "empresas_tipos")}><img src={DeleteIcon}/></button>
					: <button className="btn btn-sm btn-success" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.activarGeografia(empresa.id, "empresas_tipos")}><img src={SuccessIcon}/></button>]
				}) 
			})
			: this.state.value === 8 ? 
			this.state.gruposTipos.map((grupo) => {
				return({
					descripcion: grupo.descripcion,
					estado: grupo.estado === 'A' ? 'ACTIVO' : 'INACTIVO',
					entidad: grupo.entidad,
					acciones: [grupo.estado === 'I' ? null : <button className="btn btn-sm btn-primary" onClick={() => this.editarFormProductos(grupo, "grupos_tipo")} style={{ marginLeft: '5px' }}><EditICon /></button>,
					grupo.estado === 'A' ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.inactivarGeografia(grupo.id, "grupos_tipos")}><img src={DeleteIcon}/></button>
					: <button className="btn btn-sm btn-success" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.activarGeografia(grupo.id, "grupos_tipos")}><img src={SuccessIcon}/></button>]
				})
			}) 
			: this.state.value === 7 ?
			this.state.equiposTipos.map((equipo) => {
				return({
					descripcion: equipo.descripcion,
					estado: equipo.estado === 'A' ? 'ACTIVO' : 'INACTIVO',
					cod_ref: equipo.codigo_referencia,
					acciones: [equipo.estado === 'I' ? null : <button className="btn btn-sm btn-primary" onClick={() => this.editarFormProductos(equipo, "equipos_tipos")} style={{ marginLeft: '5px' }}><EditICon /></button>,
					equipo.estado === 'A' ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.inactivarGeografia(equipo.id, "equipos_tipos")}><img src={DeleteIcon}/></button>
					: <button className="btn btn-sm btn-success" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.activarGeografia(equipo.id, "equipos_tipos")}><img src={SuccessIcon}/></button>]
				})
			})
			: this.state.value === 9 ?
			this.state.surtidoresTipos.map((surtidor) => {
				return({
					descripcion: surtidor.descripcion,
					estado: surtidor.estado === 'A' ? 'ACTIVO' : 'INACTIVO',
					acciones: [surtidor.estado === 'I' ? null : <button className="btn btn-sm btn-primary" onClick={() => this.editarFormProductos(surtidor, "surtidores_tipos")} style={{ marginLeft: '5px' }}><EditICon /></button>,
					surtidor.estado === 'A' ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.inactivarGeografia(surtidor.id, "surtidores_tipos")}><img src={DeleteIcon}/></button>
					: <button className="btn btn-sm btn-success" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.activarGeografia(surtidor.id, "surtidores_tipos")}><img src={SuccessIcon}/></button>]
				})
			})
			: this.state.identificadoresOrig.map((identificador) => {
				return({
					descripcion: identificador.descripcion,
					estado: identificador.estado === 'A' ? 'ACTIVO' : 'INACTIVO',
					acciones: [identificador.estado === 'I' ? null : <button className="btn btn-sm btn-primary" onClick={() => this.editarFormProductos(identificador, "identificadores_origenes")} style={{ marginLeft: '5px' }}><EditICon /></button>,
					identificador.estado === 'A' ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.inactivarGeografia(identificador.id, "identificadores_origenes")}><img src={DeleteIcon}/></button>
					: <button className="btn btn-sm btn-success" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.activarGeografia(identificador.id, "identificadores_origenes")}><img src={SuccessIcon}/></button>]
				})
			})
		}

		return (
			<Paper style={{ padding: '5px' }}>
				<button className="btn btn-success" style={{ marginBottom: '5px', marginLeft: '5px', marginTop: '5px' }} onClick={() => this.abrirDialog()}>
					<img src={AddIcon} style={{marginRight: '3px', marginBottom: '3px'}}/>
					CREAR
				</button>
				<MDBDataTable
					//striped
					className="col-md-12"
					searchLabel="Buscar"
					paginationLabel={["Anterior", "Siguente"]}
					infoLabel={["Mostrando", "a", "de", "entradas"]}
					entriesLabel="Filas a mostrar"
					tbodyColor="primary-color"
					theadColor="#FF8800"
					exportToCSV={true}
					striped
					bordered
					style={{fontSize: '13px'}}
					order={['descripcion', 'asc']}
					autoWidth={false}
					responsiveSm={true}
					responsive={true}
					hover
					data={data}
				/>
			</Paper>
		)
	}

	inactivarGeografia = (id, tabla) => {
        var usuario = store.getState().usuario
        var datos = {
            "id": id,
            "entidad_nombre": tabla,
            "estado": "I",
            "personas_id": usuario.p_id,
            "empresas_id": usuario.empresas_id
        }
        swal({
            title: "Estas seguro de que deseas inactivar" + (tabla === 'paises' ? " este país?" : tabla === 'ciudades' ? " esta ciudad?" : tabla === 'provincias' ? " esta provincia?" : tabla === 'productos_tipos' ? " este producto?" : tabla === "empresas_tipos" ? " esta empresa?" : tabla === 'equipos_tipos' ? " este equipo?" : tabla === 'grupos_tipo' ? " este grupo?" : tabla === 'surtidores tipos' ? " este surtidor?" : " este identificador?"),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
			if (willDelete) {
				Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/estados/', datos, { headers: { Authorization: ntoken } })
				.then((res) => {
					if (res.status == 201) {
						swal({
							title: "OPERACIÓN EJECUTADA CON ÉXITO",
							icon: "success",
							closeOnClickOutside: false,
							buttons: {
								Seguir: {
									text: "OK",
									value: "continuar"
								}
							},
						}).then(function(value) {
							switch (value) {
								case "continuar":
									console.log("actualizar vista")
									store.dispatch({
										type: "ACTUALIZACION_DATOS",
										actualizarvista: true
									});
									break;
	
								default:
									break;
							}
						});
					} else if (res.status == 205) {
						swal("ERROR", "LA SESION HA CADUCADO", "warning")
						setTimeout(function () { window.location.href = '/'; }, 5000);
					} else {
						swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
					}
				})
			}
		});
	}

	activarGeografia = (id, tabla) => {
		var usuario = store.getState().usuario
		var datos = {
			"id": id,
			"entidad_nombre": tabla,
			"estado": "A",
			"personas_id": usuario.p_id,
			"empresas_id": usuario.empresas_id
		}
		
		Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/estados/', datos, { headers: { Authorization: ntoken } })
		.then((res) => {
			if (res.status == 201) {
				swal({
					title: (tabla === 'paises' ? "País activado" : tabla === 'ciudades' ? "Ciudad activada" : tabla === 'provincias' ? "departamento activado" : tabla === 'productos_tipos' ? "producto activado" : tabla === "empresas_tipos" ? "empresa activada" : tabla === 'equipos_tipos' ? "equipo activado" : tabla === 'grupos_tipo' ? "grupo activado" : tabla === 'surtidores tipos' ? "surtidor activado" : "identificador activado") + "  correctamente",
					icon: "success",
					closeOnClickOutside: false,
					buttons: {
						Seguir: {
							text: "OK",
							value: "continuar"
						}
					},
				}).then(function(value) {
					switch (value) {
						case "continuar":
							console.log("actualizar vista 2")
							store.dispatch({
								type: "ACTUALIZACION_DATOS",
								actualizarvista: true
							});
							break;

						default:
							break;
					}
				});
			} else if (res.status == 205) {
				swal("ERROR", "LA SESION HA CADUCADO", "warning")
				setTimeout(function () { window.location.href = '/'; }, 5000);
			} else {
				swal("Fallo al activar", "Por favor verifique los datos", "warning");
			}
		})
	}

	render() {
		var data = {
			columns: [
				{
					label: 'Descripcion',
					field: 'identificacion',
					sort: 'asc'
				}, {
					label: 'Nomenclatura',
					field: 'nombre',
					sort: 'asc'
				}, {
					label: 'Moneda',
					field: 'descripcion',
					sort: 'asc'
				}, {
					label: 'Estado',
					field: 'estado',
					sort: 'asc'
				},{
					label: 'Indicador',
					field: 'indicador',
					sort: 'asc'
				},{
					label: 'Acciones',
					field: 'acciones',		/* CAMBIO 1 */
					sort: 'asc'
				}
			], rows: this.state.paises.map((pais) => {
				return ({
					descripcion: pais.descripcion,
					nomenclatura: pais.nomenclatura,
					moneda: pais.moneda,
					estado: pais.estado === 'A' ? 'ACTIVO' : 'INACTIVO',
					indicador: "+" + pais.indicador,
					acciones: [pais.estado === 'I' ? null : <button className="btn btn-sm btn-primary" onClick={() => this.editarGeografia(1, pais)} style={{ marginLeft: '5px' }}><EditICon /></button>,
					pais.estado === 'A' ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.inactivarGeografia(pais.id, "paises")}><img src={DeleteIcon}/></button>
					: <button className="btn btn-sm btn-success" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.activarGeografia(pais.id, "paises")}><img src={SuccessIcon}/></button>] /* CAMBIO 2 */
				})
			})
		}

		var dataCiudad = {
			columns: [
				{
					label: 'Descripcion',
					field: 'imagen',
					sort: 'asc'
				}, {
					label: 'Departamento',
					field: 'identificacion',
					sort: 'asc'
				}, {
					label: 'Zona horaria',
					field: 'nombre',
					sort: 'asc'
				}, {
					label: 'Estado',
					field: 'descripcion',
					sort: 'asc'
				},{
					label: 'Acciones',			/* CAMBIO 3 */
					field: 'acciones',
					sort: 'asc'
				}
			], rows: this.state.ciudades.map((ciudad) => {
				return ({
					"descripcion": ciudad.descripcion,
					"departamento": ciudad.provincia_descripcion,
					"zona horaria":  ciudad.zona_horaria,
					"estado": ciudad.estado === 'A' ? 'ACTIVO' : 'INACTIVO',
					acciones: [ciudad.estado === 'I' ? null : <button className="btn btn-sm btn-primary" onClick={() => this.editarGeografia(3, ciudad)} style={{ marginLeft: '5px' }}><EditICon /></button>,
					ciudad.estado === 'A' ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.inactivarGeografia(ciudad.id, "ciudades")}><img src={DeleteIcon}/></button>
					: <button className="btn btn-sm btn-success" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.activarGeografia(ciudad.id, "ciudades")}><img src={SuccessIcon}/></button>] /* CAMBIO 4 */ 
				})
			})
		}

		var dataProvincia = {
			columns: [
				{
					label: 'País',
					field: 'imagen',
					sort: 'asc'
				}, {
					label: 'Departamento',
					field: 'identificacion',
					sort: 'asc'
				}, {
					label: 'Estado',
					field: 'estado',
					sort: 'asc'
				}, {
					label: 'Acciones',
					field: 'acciones',	/* CAMBIO 5 */
					sort: 'asc'
				}
			], rows: this.state.provincias.map((provincias) => {
				return ({
					"pais": provincias.pais,
					"departamento": provincias.provincias,
					"estado": provincias.estado === 'A' ? 'ACTIVO' : 'INACTIVO',
					acciones: [provincias.estado === 'I' ? null : <button className="btn btn-sm btn-primary" onClick={() => this.editarGeografia(2, provincias)} style={{ marginLeft: '5px' }}><EditICon /></button>,
					provincias.estado === 'A' ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.inactivarGeografia(provincias.provincias_id, "provincias")}><img src={DeleteIcon}/></button>
					: <button className="btn btn-sm btn-success" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.activarGeografia(provincias.provincias_id, "provincias")}><img src={SuccessIcon}/></button>]/* CAMBIO 6 */
				})
			})
		}
		return (
			<div>
				<div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
					<div className="row" style={{marginBottom: '10px'}}>
						<Dropdown onSelect={this.state.crearGeografia}>
							<Dropdown.Toggle variant="success" id="geografia">
								<img src={AddIcon} style={{marginRight: '3px', marginBottom: '3px'}}/>
								CREAR
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => this.crearGeografia(1)}>Crear País</Dropdown.Item>
								<Dropdown.Item onClick={() => this.crearGeografia(3)}>Crear Ciudad</Dropdown.Item>
								<Dropdown.Item onClick={() => this.crearGeografia(2)}>Crear Departamento</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<div>
							<button onClick={() => this.sincronizarModelos()} className="btn btn-secondary" style={{ float: "right", marginLeft: '4px'}}>
								<img src={SyncIcon} style={{marginRight: '3px', marginBottom: '3px'}}/>
								Sinc. Modelos
							</button>
						</div>
						<div>
							<button onClick={() => this.sincronizarModelosEmpresas()} className="btn btn-secondary" style={{ float: "right", marginLeft: '4px', color: 'white'}}>
								<img src={SyncIcon} style={{marginRight: '3px', marginBottom: '3px', color: 'white'}}/>
								Sinc. Mod. Empresas
							</button>
						</div>
					</div>
					<div className="row">
						<AppBar position="static" color="default" style={{zIndex: 1}}>
							<Tabs
								value={this.state.value}
								variant="scrollable"
								scrollButtons="on"
								indicatorColor="primary"
								textColor="primary">

								<Tab label="Paises" onClick={() => this.changeTab(0)} icon={<ListIcon />} />
								<Tab label="Ciudades" onClick={() => this.changeTab(1)} icon={<ListIcon />} />
								<Tab label="Departamentos" onClick={() => this.changeTab(2)} icon={<ListIcon />} />
								<Tab label="Parametros" onClick={() =>this.changeTab(3)} icon={<ListIcon />} />
								<Tab label="Parametros Empresas" onClick={() =>this.changeTab(4)} icon={<ListIcon />} />
								<Tab label="Productos tipos" onClick={() => this.changeTab(5)} icon={<ListIcon/>} />
								{this.state.entidades.map((entidad) =>{
									return(
										<Tab label={entidad.label} onClick={() => this.changeTab(entidad.id)} icon={<ListIcon/>} />
									)
								})}
							</Tabs>
						</AppBar>
						<div className="col col-md-12" style={{ padding: 0 }}>
							<Paper style={{ padding: '5px' }}>

								{this.state.loader ? <Loader /> : this.state.value === 3 ? this.FormularioParametros() : this.state.value === 4 ? this.FormParametrosEmpresas() : this.state.value >= 5 ? this.contenidoEntidad() : <MDBDataTable
									//striped
									className="col-md-12"
									searchLabel="Buscar"
									paginationLabel={["Anterior", "Siguente"]}
									infoLabel={["Mostrando", "a", "de", "entradas"]}
									entriesLabel="Filas a mostrar"
									tbodyColor="primary-color"
									theadColor="#FF8800"
									exportToCSV={true}
									style={{fontSize: '14px'}}
									striped
									bordered
									autoWidth={false}
									responsive={true}
									hover
									data={this.state.value === 0 ? data : this.state.value === 1 ? dataCiudad : dataProvincia}
								/>}

								<Dialog
									open={this.state.crearGeografia}
									onClose={() => this.cerrarGeografia()}
									aria-labelledby="form-dialog-title"
									maxWidth={'md'}
									fullWidth={true}

									style={{ padding: '0' }}
									disableBackdropClick={false}>
									<DialogContent style={{ padding: '0' }}>
										<FormularioGeografia tipoGeografia={this.state.tipoGeografia} />
									</DialogContent>
                            	</Dialog>

								<Dialog
									open={this.state.abrirDialogProd}
									onClose={() => this.cerrarDialog()}
									aria-labelledby="form-dialog-title"
									maxWidth={'md'}
									fullWidth={true}
									style={{ padding: '0' }}
									disableBackdropClick={false}>
									<DialogTitle>Editar datos</DialogTitle>
									<DialogContent style={{ padding: '0' }}>
										{
											this.state.value === 7 ? <div className="col col-md-12">
											<div className="form group">
												<div className="col-md-12">
													<label htmlFor="estado" className="col-form-label">Estado</label>
													<Selects
														placeholder="Seleccione el estado"
														value={this.state.estadoSeleccionado}
														onChange={value => this.handleEstado(value)}
														options={estado}
													/>
												</div>
												<div className="col-md-12">
													<label htmlFor="descripcion" className="col-form-label">Descripcion</label>
													<input autoComplete="off" id="descripcion" value={this.state.descripcion} onChange={this.handleDescripcion} className="form-control" type="text" required/>
												</div>
												<div className="col-md-12">
													<label htmlFor="cod_referencia" className="col-form-label">Cod. Referencia</label>
													<input autoComplete="off" id="cod_referencia" value={this.state.cod_ref} onChange={this.handleCodRef} className="form-control" type="text" required></input>
												</div>
											</div>
											<div className="row" style={{ marginTop: '20px', marginBottom: '20px', marginLeft: '3px'}}>
												<div className="col-md-3">
													<button onClick={this.validarFormularioProd} className="col-md-12 btn btn-primary" style={{ float: "right"}}>Guardar</button>
												</div>
												<div className="col-md-3">
													<button onClick={() => this.cerrarDialog()} className="col-md-12 btn btn-danger" style={{ float: "right" }}>Cerrar</button>
												</div>
											</div>
										</div> : this.state.value === 8 ? <div className="col col-md-12">
											<div className="form group">
												<div className="col-md-12">
													<label htmlFor="estado" className="col-form-label">Estado</label>
													<Selects
														placeholder="Seleccione el estado"
														value={this.state.estadoSeleccionado}
														onChange={value => this.handleEstado(value)}
														options={estado}
													/>
												</div>
												<div className="col-md-12">
													<label htmlFor="descripcion" className="col-form-label">Descripcion</label>
													<input autoComplete="off" id="descripcion" value={this.state.descripcion} onChange={this.handleDescripcion} className="form-control" type="text" required/>
												</div>
												<div className="col-md-12">
													<label htmlFor="cod_referencia" className="col-form-label">Entidad</label>
													<input autoComplete="off" id="cod_referencia" value={this.state.entidad} onChange={this.handleEntidad} className="form-control" type="number" required></input>
												</div>
											</div>
											<div className="row" style={{ marginTop: '20px', marginBottom: '20px', marginLeft: '3px'}}>
												<div className="col-md-3">
													<button onClick={this.validarFormularioProd} className="col-md-12 btn btn-primary" style={{ float: "right"}}>Guardar</button>
												</div>
												<div className="col-md-3">
													<button onClick={() => this.cerrarDialog()} className="col-md-12 btn btn-danger" style={{ float: "right" }}>Cerrar</button>
												</div>
											</div>
										</div> : <div className="col col-md-12">
											<div className="form group">
												<div className="col-md-12">
													<label htmlFor="estado" className="col-form-label">Estado</label>
													<Selects
														placeholder="Seleccione el estado"
														value={this.state.estadoSeleccionado}
														onChange={value => this.handleEstado(value)}
														options={estado}
													/>
												</div>
												<div className="col-md-12">
													<label htmlFor="descripcion" className="col-form-label">Descripcion</label>
													<input autoComplete="off" id="descripcion" value={this.state.descripcion} onChange={this.handleDescripcion} className="form-control" type="text" required/>
												</div>
											</div>
											<div className="row" style={{ marginTop: '20px', marginBottom: '20px', marginLeft: '3px'}}>
												<div className="col-md-3">
													<button onClick={this.validarFormularioProd} className="col-md-12 btn btn-primary" style={{ float: "right"}}>Guardar</button>
												</div>
												<div className="col-md-3">
													<button onClick={() => this.cerrarDialog()} className="col-md-12 btn btn-danger" style={{ float: "right" }}>Cerrar</button>
												</div>
											</div>
										</div>
										}
									</DialogContent>
                            	</Dialog>

								<Dialog
									open={this.state.abrirDialog}
									onClose={() => this.cerrarDialog()}
									aria-labelledby="form-dialog-title"
									maxWidth={'md'}
									fullWidth={true}
									style={{ padding: '0' }}
									disableBackdropClick={false}>
									<DialogTitle>Editar parametros</DialogTitle>
									<DialogContent>
										<div className="col-md-12 col-sm-12">
											<div className="row">
												<div className="col-md-6 col-sm-12">
													<label htmlFor="descripcion" className="col-form-label">Descripcion</label>
													<input autoComplete="off" id="descripcion" value={this.state.descripcion} onChange={this.handleDescripcion} className="form-control" type="text"/>
												</div>
												<div className="col-md-6 col-sm-12">
													<label htmlFor="plataforma" className="col-form-label">Tipo de plataforma</label>
													<Selects
														placeholder="Seleccione el tipo de plataforma"
														value={this.state.plataformaSeleccionada}
														onChange={value => this.handlePlataforma(value)}
														options={plataforma}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 col-sm-12">
													<label htmlFor="estado" className="col-form-label">Estado</label>
													<Selects
														placeholder="Seleccione el estado"
														value={this.state.estadoSeleccionado}
														onChange={value => this.handleEstado(value)}
														options={estado}
													/>
												</div>
												<div className="col-md-6 col-sm-12">
													<label htmlFor="tipo" className="col-form-label">Tipo</label>
													<Selects
														placeholder="Seleccione el tipo de formato"
														value={this.state.tipoSeleccionado}
														onChange={value => this.handleTipo(value)}
														options={tipo}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 col-sm-12">
													<label htmlFor="informacion" className="col-form-label">informacion</label>
													<input id="informacion" autoComplete="off" value={this.state.informacion} onChange={this.handleInformacion} className="form-control" type="text" />
												</div>
												<div className="col-md-6 col-sm-12">
													<label htmlFor="orden" className="col-form-label">Orden</label>
													<input id="orden" autoComplete="off" value={this.state.orden} onChange={this.handleOrden} className="form-control" type="number" min="1" max="10"/>
												</div>
											</div>
											<div className="row bottom-aligned">
												<div className="col-md-6 col-sm-12">
													<label htmlFor="orden" className="col-form-label">Valor por default</label>
													<input autoComplete="off" id="orden" value={this.state.valor_default} onChange={this.handleValorDefault} className="form-control" type="text"/>
												</div>
												<div className="col-md-3 col-sm-12">
													<button onClick={this.validarFormulario} className="col-md-12 btn btn-primary" style={{ float: "right", marginRight : "5px"}}>Guardar</button>
												</div>
												<div className="col-md-3 col-sm-12">
													<button onClick={() => this.cerrarDialog()} className="col-md-12 btn btn-danger" style={{ float: "right" }}>Cerrar</button>
												</div>
											</div>
										</div>
									</DialogContent>
								</Dialog>
							</Paper>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default creacionPais