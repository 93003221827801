
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios'
import { Tab, Tabs, TabContent } from 'react-bootstrap'
import Chip from '@material-ui/core/Chip';
import CardTittle from '@material-ui/core/CardHeader'
import GridItem from "components/Grid/GridItem.jsx";

import swal from 'sweetalert'
import { MuiPickersUtilsProvider, TimePicker, DatePicker, DateTimePicker } from 'material-ui-pickers';
import Selects from 'react-select';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CancelIcon from '@material-ui/icons/Cancel';
import DateFnsUtils from '@date-io/luxon';
import store from '../../lib/store'
import Server from '../../lib/server'
import moment from 'moment'
import "moment/locale/es";
const options = [
    { value: 16, label: '16GB' },
    { value: 32, label: '32GB' },
    { value: 64, label: '64GB' }
];
class FormularioAlmacenamiento extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serial: "",
            marca: "",
            capacidad: {},
            fechaCompra: new Date(),
            cambbioInicio:false,
            response:false
        };
    }

    handleChanges = name => value => {
        console.log(name)
        console.log(value.value)
        this.setState({
            [name]: value,
            validado: true
        });
        console.log(this.state)
    }

    handleChangemoment = date => {
        console.log(date.toISOString())
        this.setState({ fechaCompra: date,cambbioInicio:true });
    }



    handleChange = name => event => {
        //console.log(name)
        //console.log(event)
        this.setState({
            [name]: event.target.value,
        });
    };

    componentWillMount() {
        const n = []
    }


    registrarAlmacenamiento = () => {
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        this.setState({
            response:true
        })
        var res = this.state.cambbioInicio ? moment(new Date(this.state.fechaCompra.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
        //console.log(this.state.serial.length)
        const datos = {
            serial: this.state.serial,
            marca: this.state.marca,
            capacidad: this.state.capacidad.value,
            fecha_compra: res
        };

        Axios.post(Server.hostPrueba+Server.puertoEquipos+'/api/almacenamiento', datos, { headers: { Authorization: ntoken } })
            .then(res => {
                console.log(res);
                this.setState({
                    response:false
                })
                if (res.status === 201) {
                    swal({
                        title: "Almacenamiento guardado correctamente",
                        icon: "success",
closeOnClickOutside:false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                store.dispatch({
                                    type: "CREACION_SD",
                                    ventanaCreacionSD: false
                                })
                                break;

                            default:

                        }
                    });
                } else {
                    swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })

        console.log(datos)
    }

    render() {
        return (
            <GridItem xs={12} sm={12} md={12} id="crearOrden" style={{ overflow: "auto", padding: "10px" }} >
                <Card style={{ marginTop: "15px", paddingBottom: "15px" }}>

                    <CardTittle title="Creacion de Almcenamiento" style={{ marginBottom: "0", paddingBottom: "5px" }} />
                    <CardContent>
                        <div className="row">
                            <div className="col col-md-8">
                                <label htmlFor="nombreprov" className="col-form-label">Serial</label>
                                <input id="nombreprov" className="form-control" type="text" onChange={this.handleChange('serial')} />
                            </div>
                            <div className="col col-md-4">
                                <label htmlFor="nombreprov" className="col-form-label">Capacidad</label>
                                <Selects
                                    className="col col-md-12"
                                    value={this.state.capacidad}
                                    onChange={this.handleChanges('capacidad')}
                                    options={options}
                                    placeholder="Seleccione..."
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-md-6">
                                <label htmlFor="nombreprov" className="col-form-label">Marca</label>
                                <input id="nombreprov" className="form-control" type="text" onChange={this.handleChange('marca')} />
                            </div>
                            <div className="col col-md-6">
                                <label htmlFor="nombreprov" className="col-form-label">Fecha de compra</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}  locale={"es"}>
                                    <DatePicker
                                        format="yyyy-MM-dd HH:MM"
                                        id="datetime"
                                        value={this.state.fechaCompra}
                                        //onChange={this.handleChangemoment}
                                        onChange={this.handleChangemoment}
                                        className="rounded form-control"
                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>

                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <button className=" btn btn-danger">Cancelar</button>
                            <button className="btn btn-primary" onClick={this.registrarAlmacenamiento.bind(this)} disabled={this.state.response}>Guardar</button>
                        </div>
                    </CardContent>
                </Card>
            </GridItem>
        )
    }
}


export default FormularioAlmacenamiento
