import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery'
import tiendas from "@material-ui/icons/StoreMallDirectory"
import L from 'leaflet'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider';
import store from '../../lib/store'
import Server from '../../lib/server'
import Loader from '../../components/Loader'
import Axios from 'axios'
import GoogleMapReact from 'google-map-react';
import Image from 'react-bootstrap/Image'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import swal from 'sweetalert'
import Typography from '@material-ui/core/Typography';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MailIcon from '@material-ui/icons/Mail'
import TelefonoIcon from '@material-ui/icons/Phone'
import Avatar from '@material-ui/core/Avatar';
import LocationIcon from '@material-ui/icons/LocationOn'
import DeunaIcon from '../../assets/img/mapa/svg1-16.png'
import TerpelIcon from '../../assets/img/mapa/locator.png'
import Lupaicon from '@material-ui/icons/Search'
/*global google*/
import moment from 'moment';

export const suitcasePoint = new L.Icon({
    iconUrl: require('../../assets/img/mapa/svg1-32.svg'),
    iconAnchor: [20, 40],
    popupAnchor: [0, -35],
    iconSize: [40, 40],
    shadowSize: [29, 40],
    shadowAnchor: [7, 40],
})

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}
const B = (props) => <small style={{ fontWeight: 'bold', marginBottom: "0", display: 'inline' }}>{props.children}</small>
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class MisTiendas extends Component {
    constructor() {
        super();
        this.state = {

            //
            zoom: 12,
            center: {
                lat: 10.9888859,
                lng: -74.82026589999998
            },
            centerInicial: {
                lat: 10.9888859,
                lng: -74.82026589999998
            },
            empresasbd: [

            ],
            detallesTiendas: false,
            tiendaClickeada: [],
            //MArker
            showingInfoWindow: false,
            activeMarker: {},
            empresaSeleccionada: null,
            cargando: false,
            equipoTienda: null,
            //busqueda
            modoBusqueda: false,
            categoriaBusqueda: [],
            campoBusqueda: ''
        }
    }


    componentWillMount() {
        var dominio = store.getState().dominio
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/5/empresas/' + usuario.empresas_id + "," + hijos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var provincias = []
                console.log(responsee.data)
                if (responsee.status == 200) {
                    responsee.data.empresas.map((provincia, key) => (
                        provincias.push({
                            id: provincia.id,
                            nombre: provincia.alias,
                            nit: provincia.nit,
                            center: { lat: provincia.localizacion.split(",")[0], lng: provincia.localizacion.split(",")[1] }
                        })
                    ))
                    console.log(provincias)
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }

                this.setState({ empresasbd: provincias })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        /*var eq = {
            "empresas_id": store.getState().empresasHijos
        };
        Axios.post(Server.hostPruebasinssl + Server.puertoEquipos + '/api/equipo/estadoEquipos', eq, { headers: { Authorization: ntoken } })
            .then(response => {
                var mongo = [];
                if (response.status === 200) {
                    if (response.data.success) {
                        mongo = response.data.data;
                    }
                } else if (response.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning");
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
                this.setState({
                    equiposMongo: mongo
                });
            });*/
    }

    buscar = () => {

        var cadena = this.state.campoBusqueda.toUpperCase()
        var categorias = []
        this.state.empresasbd.map((categoria, key) => {
            if (categoria.nombre.includes(cadena)) {
                categorias.push(categoria)
            }
        })
        this.setState({ modoBusqueda: true, categoriaBusqueda: categorias })

    }

    limpiar = () => {
        this.setState({ modoBusqueda: false, categoriaBusqueda: [], campoBusqueda: '' })
    }

    keyBuscar = (e) => {
        if (e.key === 'Enter') {
            this.buscar()
        } else if (e.key === 'Escape') {
            this.limpiar()
        }
    }

    consultaTienda = (tienda) => {
        console.log(tienda)
        var usuario = store.getState().usuario
        this.setState({
            cargando: true
        })
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/by/' + tienda.id, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status === 200) {
                    this.setState({
                        detallesTiendas: true,
                        zoom: 15,
                        center: tienda.center,
                        empresaSeleccionada: responsee.data.data,
                        cargando: false
                    })
                    console.log(responsee)
                } else if (responsee.status === 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
            })
            .catch(function (error) {
                // handle error
                this.setState({
                    cargando: false
                })
                console.error(error);
            })

        /*Axios.get(Server.hostPrueba + Server.puertoEquipos + '/api/equipo/empresas/' + tienda.id, { headers: { Authorization: ntoken } })
            .then(response => {
                if (response.status = 200) {
                    var equipo = {}
                    response.data.data.map((equipo) => {
                        console.log(equipo)
                        equipo = {
                            "equipo": equipo.serial_equipo,
                            "ultima": this.obtenerEquipo(equipo.id)
                        }
                    })
                } else if (response.status = 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
                this.setState({ equipoTienda: equipo })
            })*/
    }

    informacionTiendas = (tienda, props, marker, e) => {
        this.setState({ activeMarker: marker, showingInfoWindow: true, tiendaClickeada: tienda, detallesTiendas: true, zoom: 15, center: tienda.center })
    }

    atras = () => {
        this.setState({ detallesTiendas: false, zoom: 12, center: this.state.centerInicial })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };

    obtenerEquipo = (id) => {
        var equipos = this.state.equiposMongo;
        var fecha = '';
        var fechaResult = '';
        equipos.map((equipo) => {
            if (id === parseInt(equipo.equipos_id)) {
                fecha = moment(equipo.ultimaConexion);
                fechaResult = moment(fecha, 'YYYYMMDD').fromNow();
                return fechaResult;
            }
        });
        return fechaResult;
    }

    render() {
        var tipoDominio = store.getState().tipoDominio
        const mapStyles = {
            width: '100%',
            height: '100%',
        };
        var tienda = null
        if (this.state.empresaSeleccionada != null) {
            tienda = this.state.empresaSeleccionada
        }
        return (
            <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>


                <div className="row">
                    {
                        this.state.cargando
                            ?
                            <Loader />
                            :
                            this.state.detallesTiendas ?
                                <div className="col col-md-3" style={{ backgroundColor: "#ffffff" }}>

                                    <div className="row border">
                                        <button className="col-md-2 btn btn-danger" onClick={this.atras}>X</button>
                                        <div className="col-md-10 mx-auto my-auto">{tienda.alias}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Typography variant="small" gutterBottom>
                                                <B>{"RAZON SOCIAL: " + tienda.razon_social}</B>
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Typography variant="small" gutterBottom>
                                                <B>{"NIT: " + tienda.nit}</B>
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {
                                            tienda.contactos.map((con, key) => {
                                                if (con.tipo === 3 && con.estado === "A") {
                                                    return (
                                                        < div className="col-md-12" >
                                                            <Typography component="small" style={{ fontSize: '12px' }}>
                                                                <LocationIcon />{con.contacto}
                                                            </Typography>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                    {/*<div className="row">
                                        <div className="col-md-12">
                                            <Typography component="small" style={{ fontSize: '12px' }}>
                                                {tienda.ciudad.descripcion + ", " + tienda.provincia.descripcion + ", " + tienda.pais.descripcion}
                                            </Typography>
                                        </div>
                                    </div>*/}
                                    <div className="row" style={{ marginTop: '10px' }}>
                                        <div className="col-md-12">
                                            <Typography component="small">
                                                <B>{"CONTACTOS"}</B>
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {
                                            tienda.contactos.map((con, key) => {
                                                if (con.tipo === 2 && con.estado === "A") {
                                                    return (
                                                        < div className="col-md-12" >
                                                            <Typography component="small" style={{ fontSize: '12px' }}>
                                                                <TelefonoIcon />{con.contacto}
                                                            </Typography>
                                                        </div>
                                                    )
                                                } else if (con.tipo === 1 && con.estado === "A") {
                                                    return (
                                                        < div className="col-md-12" >
                                                            <Typography component="small" style={{ fontSize: '12px' }}>
                                                                <MailIcon />{con.contacto}
                                                            </Typography>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                    <div className="row" style={{ marginTop: '10px' }}>
                                        <div className="col-md-12">
                                            <Typography component="small">
                                                <B>{"DESCRIPTORES"}</B>
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: '10px' }}>
                                        <div className="col-md-12">
                                            <Typography component="small" style={{ fontSize: '12px' }}>
                                                {tienda.descriptores != null ? "CABECERA: " + tienda.descriptores.header : ""}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: '10px' }}>
                                        <div className="col-md-12">
                                            <Typography component="small" style={{ fontSize: '12px' }}>
                                                {tienda.descriptores != null ? "PIECERA: " + tienda.descriptores.footer : ""}
                                            </Typography>
                                        </div>
                                    </div>
                                    {/*<div className="row" style={{ marginTop: '10px' }}>
                                        <List className="col col-md-12 border" style={{ backgroundColor: "#ffffff" }}>
                                            <ListItemLink >
                                                <ListItemText primary={this.state.equipoTienda != null ? this.state.equipoTienda.equipo : ''} secondary={this.state.equipoTienda != null ? this.state.equipoTienda.ultima : ''} />
                                            </ListItemLink>
                                        </List>
                                    </div>*/}
                                </div>
                                :
                                <div className="col-md-3 border" style={{ backgroundColor: "#ffffff",padding:0 }}>
                                    <div className="row" style={{margin:0}}>
                                        <div className="col-md-12 mx-auto">
                                            <div className="input-group">
                                                <input type="text" placeholder="Buscar" onKeyDown={this.keyBuscar} value={this.state.campoBusqueda} onChange={this.handleChange('campoBusqueda')} className="form-control mx-auto" />
                                                {this.state.modoBusqueda ? <div className="input-group-append">
                                                    <button className="btn btn-outline-primary" disabled={!this.state.modoBusqueda} type="button" onClick={() => this.limpiar()}>x</button>
                                                </div> : null}
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="button" onClick={() => this.buscar()}><Lupaicon/></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <List className="row" style={{ backgroundColor: "#ffffff" }}>
                                        {this.state.modoBusqueda ?
                                            this.state.categoriaBusqueda.map((map, key) => {
                                                return (
                                                    <div className="col-md-12" style={{padding:0}}>
                                                        <ListItemLink onClick={() => this.consultaTienda(map)}>
                                                            <ListItemText primary={map.nombre} />
                                                        </ListItemLink>
                                                        <Divider />
                                                    </div>
                                                )
                                            })
                                            :
                                            this.state.empresasbd.map((map, key) => {
                                                return (
                                                    <div className="col-md-12" style={{padding:0}} >
                                                        <ListItemLink onClick={() => this.consultaTienda(map)}>
                                                            <ListItemText primary={map.nombre} />
                                                        </ListItemLink>
                                                        <Divider />
                                                    </div>
                                                )
                                            })}
                                    </List>
                                </div>
                    }

                    <div className="col col-md-9" style={{ height: '80vh' }}>
                        <Map
                            google={this.props.google}
                            zoom={this.state.zoom}
                            style={mapStyles}
                            initialCenter={this.state.centerInicial}
                            center={this.state.center}
                        >
                            {
                                this.state.empresasbd.map((sucursal, key) => {
                                    return (
                                        <Marker key={key}
                                            icon={tipoDominio === "V" || tipoDominio === "E" ? TerpelIcon : DeunaIcon}
                                          /*onMouseover={() => this.informacionTiendas(sucursal)}*/ position={sucursal.center} onClick={() => this.consultaTienda(sucursal)} title={sucursal.nombre} name={sucursal.nombre}

                                        />
                                    )
                                })
                            }

                        </Map>
                    </div>
                </div>
            </div >
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDwX4ZKPWuQBM3cxGsXvz8MONBD0NtpoIo'
})(MisTiendas);