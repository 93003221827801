import Axios from 'axios';
import Server from '../../lib/server';
import store from '../../lib/store';
import swal from 'sweetalert';


//OBTIENE LOS PARAMETROS SEGUN UNA EMPRESA_ID Y ORIGEN DE PARAMETROS
export const getParametrosByEmpresa = async (empresa_id,origen) => {
    return new Promise(function (resolve, reject) {
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        Axios.get(Server.hostNuevo + '/parametrizacion/empresa/' + empresa_id, { headers: { Authorization: ntoken } })
            //Axios.get('http://192.168.0.7:7011/parametrizacion/empresa/'+empresa_id , { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.status === 200) {
                    const datos = res.data.data;
                    const parametros = [] 
                    if(datos != null){
                        datos.map((dato)=>{
                            if(origen.includes(parseInt(dato.origen))){
                                parametros.push(dato)
                            }
                        })
                    }
                    resolve(parametros);
                } else if (res.status === 205) {
                    resolve([]);
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err, undefined);
            });
    });
};

//GUARDA LOS PARAMETROS D EUNA EMPRESA
export const saveParametrosEmpresa = async (data) => {
    return new Promise(function (resolve, reject) {
        const { id } = data
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        Axios.post(Server.hostNuevo+'/parametrizacion/configuracion-entidad/', data, { headers: { Authorization: ntoken } })
            //Axios.post('http://192.168.0.7:7011/parametrizacion/configuracion-entidad/' ,data, { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.status === 200) {
                    //swal("OK", "PARAMETROS ACTUALIZADOS" , "success")
                    store.dispatch({
                        type: "ACTUALIZACION_DATOS",
                        actualizarvista: true
                    })
                } else if (res.status === 205) {
                    resolve([]);
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err, undefined);
            });
    });

};

//OBTIENE LOS DEPARTAMENTOS SEUGN PAIS Y LOS RETORNA EN UN OBJETO DE {LABEL,VALUE,KEY}
export const getDepartamentosByPais = async (pais_id) => {
    return new Promise(function (resolve, reject) {
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/provincias/pais/' + pais_id, { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.status === 200) {
                    let provincias = []
                    res.data.map((provincia, key) => (
                        provincias.push({
                            value: provincia.provincia_id,
                            label: provincia.provincia,
                            key: key
                        })
                    ))
                    resolve(provincias);
                } else if (res.status === 205) {
                    resolve([]);
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err, undefined);
            });
    });
};

//OBTIENE LAS CIUDADES SEGUN UN DEPARTAMENTO Y LOS RETORNA EN UN OBJETO DE {LABEL,VALUE,KEY}
export const getCiudadByDepartamento = async (departamento_id) => {
    return new Promise(function (resolve, reject) {
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/provincias/ciudades/' + departamento_id, { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.status === 200) {
                    let ciudades = []
                    res.data.map((dato, key) => (
                        ciudades.push({
                            value: dato.ciudades_id,
                            label: dato.ciudades,
                            key: key
                        })
                    ))
                    resolve(ciudades);
                } else if (res.status === 205) {
                    resolve([]);
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err, undefined);
            });
    });
};

