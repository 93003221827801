import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import AppRoutes from "./Approutes"
import './lib/loader'

const hist = createBrowserHistory();
ReactDOM.render(
  <Router history={hist}>
      <AppRoutes />
  </Router>,
  document.getElementById("root")
);
