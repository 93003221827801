import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Selects from 'react-select';
import store from '../../../lib/store'
import swal from 'sweetalert'
import "moment/locale/es";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import { createParametros } from './services'
import { ORIGEN_EMPRESA, ORIGEN_EQUIPO,ORIGEN_FACTURACION_CONVENCIONAL,ORIGEN_FACTURACION_ELECTRONICA,ORIGEN_FACTURACION } from './parametrosCostantes';
class Bodega extends Component {
    constructor() {
        super();
        this.state = {
            loader: true,
            optionsTipo: [
                {
                    label: "BOOLEAN",
                    value: 'boolean'
                }, {
                    label: "TEXT",
                    value: 'text'
                }, {
                    label: "JSON",
                    value: 'json'
                }, {
                    label: "NUMBER",
                    value: 'number'
                }, {
                    label: "DATE",
                    value: 'date'
                }, {
                    label: "SELECT",
                    value: 'select'
                }, {
                    label: "SELECT_MULTIPLE",
                    value: 'select_multiple'
                }, {
                    label: "CHECKBOX_MULTIPLE",
                    value: 'checkbox_multiple'
                }
            ],
            optionsOrigen: [
                {
                    label: "EMPRESA",
                    value: 1
                }, {
                    label: "FACTURACION",
                    value: 2
                }, {
                    label: "FACTURACION ELECTRONICA",
                    value: 22
                }, {
                    label: "FACTURACION CONVENCIONAL",
                    value: 21
                }, {
                    label: "EQUIPOS",
                    value: 3
                }
            ],
            origenSeleccionado: {},
            optionsPrivacidad: [
                {
                    label: "PRIVADO",
                    value: 0
                }, {
                    label: "PUBLICO",
                    value: 1
                }
            ],
            tipoSeleccionado: {},
            privacidadSeleccionada: {},
            nombreParametro: "",
            opciones: [],
            labelOpcion: "",
            valorOpcion: "",
            comentarios: "",
            id: 0,
            metodo: "",


        }
    }
    componentWillMount() {
        try {
            const { infoSurtidor: { id, metodo } } = store.getState()
            this.setState({
                id: id.id,
                metodo
            })
            if (id.id !== 0) {
                this.setearDatos(id)
            }
        } catch (error) { console.log(error) }

    }

    setearDatos = (id) => {
        id.origen = id.origen != null ?  parseInt(id.origen): 1
        try {
            this.setState({
                nombreParametro: id.codigo,
                comentarios: id.comentarios,
                tipoSeleccionado: {
                    label: id.tipo.toUpperCase(),
                    value: id.tipo
                },
                label: id.tipo.toUpperCase(),
                privacidadSeleccionada: this.state.optionsPrivacidad[id.visibilidad],
                opciones: id.opciones !== null ? JSON.parse(id.opciones) : [],
                origenSeleccionado: {
                    label: this.obtenerOrigenDescripcion(id.origen),
                    value: id.origen 
                }
            })
        } catch (error) {

        }

    }

    obtenerOrigenDescripcion = (valor) => {
        let label = ""
        switch (valor) {
            case ORIGEN_EMPRESA:
                label = "EMPRESA"
                break;
            case ORIGEN_FACTURACION:
                label = "FACTURACION"
                break;
            case ORIGEN_FACTURACION_ELECTRONICA:
                label = "FACTURACION ELECTRONICA"
                break;
            case ORIGEN_FACTURACION_CONVENCIONAL:
                label = "FACTURACION CONVENCIONAL"
                break;
            case ORIGEN_EQUIPO:
                label = "EQUIPOS"
                break;
            default:
                label = "EMPRESA"
                break;

        }
        return label;
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };


    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
    }
    validacionParametro = () => {
        const { comentarios, nombreParametro, origenSeleccionado, tipoSeleccionado, privacidadSeleccionada } = this.state
        let response = true
        let mensaje = ""
         if (nombreParametro === "") {
            response = false
            mensaje = "EL NOMBRE ES OBLIGATORIO"
        } else if (privacidadSeleccionada != null && JSON.stringify(privacidadSeleccionada) === "") {
            response = false
            mensaje = "SELECCIONE LA PRIVACIDAD DEL PARAMETRO"
        } else if (origenSeleccionado != null && JSON.stringify(origenSeleccionado) === "") {
            response = false
            mensaje = "DEBE SELECCIONAR EL ORIGEN"
        } else if (tipoSeleccionado != null && JSON.stringify(tipoSeleccionado) === "") {
            response = false
            mensaje = "DEBE SELECCIONAR EL TIPO DE PARAMETRO"
        }
        if(!response){
            swal("ERROR", mensaje, "warning")
        }
        return response;
    }

    crearParametro = async () => {
        const { usuario } = store.getState()
        const { comentarios, nombreParametro, opciones, origenSeleccionado, tipoSeleccionado, privacidadSeleccionada, id } = this.state
        const valiado = this.validacionParametro()
        if (valiado) {
            let optGuardar = ""
            if (opciones.length > 0) {
                optGuardar = JSON.stringify(opciones)
            }
            const data = {
                "id": id || 0,
                "codigo": nombreParametro,
                "visibilidad": privacidadSeleccionada.value,
                "tipo": tipoSeleccionado.value,
                "opciones": optGuardar,
                "comentarios": comentarios || "",
                "estado": "A",
                "persona_id": usuario.p_id,
                "empresa_id": usuario.empresas_id,
                "origen": origenSeleccionado.value
            }
            const response = await createParametros(data)
        }
    }


    agregarOpcion = () => {
        const { valorOpcion, labelOpcion, opciones } = this.state
        if (valorOpcion === "" || labelOpcion === "") {
            swal("ERROR", "DEBE AGREGAR EL LABEL Y EL VALOR", "warning")
        } else {
            opciones.push({
                label: labelOpcion,
                value: valorOpcion
            })
            this.setState({
                opciones,
                labelOpcion: "",
                valorOpcion: ""
            })
        }
    }

    eliminarOpcion = (pos) => {
        const { opciones } = this.state
        opciones.splice(pos, 1)
        this.setState({
            opciones
        })
    }


    render() {
        const { nombreParametro, optionsTipo, tipoSeleccionado, privacidadSeleccionada, origenSeleccionado, optionsOrigen, optionsPrivacidad, valorOpcion, labelOpcion, opciones, comentarios, metodo } = this.state
        return (
            <div>
                <div className="row">
                    <div className="col-md-12 col-12">
                        <label htmlFor="descripcionParametro" className="col-form-label">DESCRIPCION PARAMETRO</label>
                        <input id="descripcionParametro" autoComplete="off"
                            disabled={metodo === "GET"} value={nombreParametro} onChange={this.handleChange('nombreParametro')} className="form-control" type="text" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <label htmlFor="numordencompra" className="col-form-label">TIPO</label>
                        <Selects
                            value={tipoSeleccionado}
                            isDisabled={metodo === "GET"}
                            onChange={this.handleChanges('tipoSeleccionado')}
                            options={optionsTipo}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="numordencompra" className="col-form-label">PRIVACIDAD</label>
                        <Selects
                            value={privacidadSeleccionada}
                            isDisabled={metodo === "GET"}
                            onChange={this.handleChanges('privacidadSeleccionada')}
                            options={optionsPrivacidad}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="numordencompra" className="col-form-label">ORIGEN</label>
                        <Selects
                            value={origenSeleccionado}
                            isDisabled={metodo === "GET"}
                            onChange={this.handleChanges('origenSeleccionado')}
                            options={optionsOrigen}
                        />
                    </div>
                </div>
                {
                    JSON.stringify(tipoSeleccionado) !== "{}" && (tipoSeleccionado.value === 'select' || tipoSeleccionado.value === 'select_multiple'  || tipoSeleccionado.value === 'checkbox_multiple' ) &&
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="descripcionParametro" className="col-form-label">OPCION </label>
                                    <input id="descripcionParametro" disabled={metodo === "GET"} autoComplete="off" placeholder="LABEL"
                                        value={labelOpcion} onChange={this.handleChange('labelOpcion')} className="form-control" type="text" />
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "5px" }}>
                                <div className="col-md-7">
                                    <input id="descripcionParametro" disabled={metodo === "GET"} autoComplete="off" placeholder="ID"
                                        value={valorOpcion} onChange={this.handleChange('valorOpcion')} className="form-control" type="text" />
                                </div>
                                <div className="col-md-5  bottom-aligned">
                                    <button className="btn btn-success btn-block" disabled={metodo === "GET"} onClick={() => this.agregarOpcion()}>AGREGAR</button>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6" style={{ marginTop: "5px" }}>
                            <List style={{ backgroundColor: "#fff", padding: '0' }} className="border rounded">
                                {opciones.map((opcion, key) => {
                                    return (
                                        <ListItem key={key} className="border">
                                            <ListItemText
                                                primary={opcion.label}
                                                secondary={opcion.value}
                                            />
                                            <ListItemSecondaryAction>
                                                <button className="btn btn-sm btn-danger"
                                                    disabled={metodo === "GET"} >
                                                    <DeleteIcon onClick={() => this.eliminarOpcion(key)} />
                                                </button>
                                            </ListItemSecondaryAction>

                                        </ListItem>
                                    )
                                })}
                            </List>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-12 col-12">
                        <label htmlFor="descripcionParametro" className="col-form-label">COMENTARIOS</label>
                        <textarea disabled={metodo === "GET"} id="descripcionParametro" autoComplete="off" value={comentarios} onChange={this.handleChange('comentarios')} className="form-control" rows={3} />
                    </div>
                </div>
                {
                    metodo === "GET"
                        ? null
                        : <div className="row">
                            <div className="col-md-3 mx-auto" style={{ marginTop: "5px" }}>
                                <button className="btn btn-success btn-block" onClick={() => this.crearParametro()}>GUARDAR</button>
                            </div>
                        </div>

                }

            </div>
        )
    }
}



export default Bodega;
