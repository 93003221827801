import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { MDBDataTable } from 'mdbreact';
import Paper from '@material-ui/core/Paper';
import swal from 'sweetalert'
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class Produccion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //Variables de componente principal
            createOrdenDeCompra: false,
            selectedDate: new Date(),
           
            fechainicio: new Date(),
            fechafin: new Date(),
            ordencompra: {
                columns: [
                    {
                        label: 'consecutivo',
                        field: 'descripcion',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'Fecha',
                        field: 'cantidad',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'Acuerdo',
                        field: 'vlrunitario',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'Proveedor',
                        field: 'subtotal',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'Total',
                        field: 'totalproducto',
                        sort: 'asc',
                        width: 150
                    }
                ],
                rows: [
                    {
                        descripcion: "0000001223",
                        cantidad: "04 May. 2019 12:48:54 PM",
                        vlrunitario: "INDUSTRIA NACIONAL DE GASEOSAS",
                        subtotal: "INDUSTRIA NACIONAL DE GASEOSAS",
                        totalproducto: "$327.432"
                    }, {
                        descripcion: "0000001224",
                        cantidad: "04 May. 2019 12:48:54 PM",
                        vlrunitario: "GASEOSAS COLOMBIANAS",
                        subtotal: "GASEOSAS COLOMBIANAS SA",
                        totalproducto: "$70.843"
                    }, {
                        descripcion: "0000001225",
                        cantidad: "04 May. 2019 12:48:54 PM",
                        vlrunitario: "PRODUCTOS RAMO",
                        subtotal: "PRODUCTOS RAMO SA GONZALEZ",
                        totalproducto: "$86.409"
                    }
                ]
            },
        }
    }
    render() {
        return (
            <div>
                {this.state.createOrdenDeCompra ? <button className="btn btn-danger" /*onClick={this.handleCrearOrdenDeCompra.bind(this)}*/> Cancelar</button> : <button className="btn btn-success" /*onClick={this.handleCrearOrdenDeCompra.bind(this)}*/> Nueva Orden De Compra</button>}
                <div>
                    <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col col-md-12" style={{ padding: 0 }}>
                            <Paper style={{ padding: '5px' }}>

                                <MDBDataTable
                                    //striped
                                    className="col-md-12"
                                    searchLabel="Buscar"
                                    paginationLabel={["Anterior", "Siguente"]}
                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                    entriesLabel="Filas a mostrar"
                                    tbodyColor="primary-color"
                                    theadColor="#FF8800"
                                    exportToCSV={true}
                                    striped
                                    bordered
                                    autoWidth={false}
                                    responsive={true}
                                    hover
                                    data={this.state.ordencompra}
                                />


                            </Paper>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
export default Produccion