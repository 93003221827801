import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Button from '@material-ui/core/Button';
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./producto.css"
import getCroppedImg from './getCroppedImg'
import Slider from '@material-ui/lab/Slider'
import Cropper from 'react-easy-crop'
import Avatar from '@material-ui/core/Avatar';
import Selects from 'react-select';
import Axios from 'axios'
import DialogTelefono from './dialogs/dialogTelefono'
import DialogCorreo from './dialogs/dialogCorreo'
import TelefonoIcon from '@material-ui/icons/Phone'
import LocationIcon from '@material-ui/icons/LocationOn'
import MailIcon from '@material-ui/icons/Mail'
import store from '../../lib/store'
import Server from '../../lib/server'
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import swal from 'sweetalert'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import FormularioGeografia from './FormularioGeografia';
import ChangeImage from '../../assets/img/Formularios/change_foto.png'
import { Paper } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
var colorDominio = store.getState().colorDominio
class FormularioPersona extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Contactos
            DepartamentoDireccion: {},
            ciudadDireccion: {},
            controlContacto: false,
            etiquetatel: '',
            etiquetacorr: '',
            contactotel: '',
            contactocorr: '',
            telefonos: [],
            corrreos: [],
            direcciones: [],
            //Traidas desde bd
            identificacionesbd: [],
            provinciasbd: [],
            ciudadesbd: [],
            ciudadesbd2: [],
            //controles vistas
            ciudadesestado: false,
            vistaEdicion: null,
            //Datos seleccionados
            modovista: false,
            crearGeografia: false,
            //foto
            croppedImage: null,
            dialogphoto: false,
            crop: { x: 0, y: 0 },
            zoom: 1,
            croppedAreaPixels: null,
            croppedImage: null,
            aspect: 1 / 1,
            imagenCreate: false,
            imageSrc: null,
            //Datos a guardar
            nombreGuardar: '',
            apellidoGuardar: '',
            identificacionGuardar: '',
            DireccionGuardar: '',
            departamentoSeleccionada: {},
            ciudadSeleccionada: {},
            tipoIdSeleccionada: {},
            //Vectores
            actualizarDatos: false,
            idProveedor: 0,
            productosProveedor: null,
            response: false,
            //mapa
            centerDireccion: { lat: 10.9888859, lng: -74.82026589999998 },
            address: '',
        };
        store.subscribe(() => {
            this.setState({
                modovista: store.getState().modovista,
                crearGeografia: store.getState().creacionGeografia,
                actualizarDatos: store.getState().actualizarvista,
            })
        })
    }

    cambiarContacto = () => {
        this.setState({ controlContacto: !this.state.controlContacto })
    }

    isValidEmail = (mail) => {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(mail);
    }

    handleSelect = address => {

        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                console.log('Success', latLng)
                this.setState({ centerDireccion: latLng, zoom2: 16, address: address })
            })
            .catch(error => console.error('Error', error));
    };

    onMarkerDragEnd = (coord) => {
        const { latLng } = coord;
        const latn = latLng.lat();
        const lngn = latLng.lng();

        console.log(latn + "-" + lngn)
        this.setState({
            centerDireccion: {
                lat: latn,
                lng: lngn
            }
        })
    };

    agregarContactoProveedor = (tipo) => {
        switch (tipo) {
            case 1:
                var corr = this.state.corrreos
                if (this.isValidEmail(this.state.contactocorr)) {
                    corr.push({ etiqueta: this.state.etiquetacorr, contacto: this.state.contactocorr, estado: "A" })
                    this.setState({ corrreos: corr, etiquetacorr: '', contactocorr: '' })
                } else {
                    swal("FORMATO DE CORREO ELECTRONICO INVALIDO", "", "warning")
                }

                break;
            case 2:
                var tel = this.state.telefonos
                tel.push({ etiqueta: this.state.etiquetatel, contacto: this.state.contactotel, estado: "A" })
                this.setState({ telefonos: tel, etiquetatel: '', contactotel: '' })
                break;
            case 3:
                var address = this.state.direcciones
                address.push({ etiqueta: this.state.ciudadDireccion.label, contacto: this.state.DireccionGuardar, estado: "A" })
                console.log(address)
                this.setState({ direcciones: address, DepartamentoDireccion: {}, ciudadDireccion: {}, DireccionGuardar: '' })
                break;
        }
    }
    agregarContactoProveedorbd = (tipo) => {
        switch (tipo) {
            case 1:
                var corr = this.state.corrreos
                corr.push({ id: null, etiqueta: this.state.etiquetacorr, contacto: this.state.contactocorr, estado: "A" })
                this.setState({ corrreos: corr, etiquetacorr: '', contactocorr: '' })
                break;
            case 2:
                var tel = this.state.telefonos
                tel.push({ id: null, etiqueta: this.state.etiquetatel, contacto: this.state.contactotel, estado: "A" })
                this.setState({ telefonos: tel, etiquetatel: '', contactotel: '' })
                break;
            case 3:
                var address = this.state.direcciones
                address.push({ id: null, etiqueta: this.state.ciudadDireccion.label, contacto: this.state.DireccionGuardar, estado: "A" })
                this.setState({ direcciones: address, DepartamentoDireccion: {}, ciudadDireccion: {}, DireccionGuardar: '' })
                break;
        }
    }

    crearGeografia = (tipo) => {
        this.setState({ tipoGeografia: tipo })
        store.dispatch({
            type: "CREACION_GEOGRAFIA",
            creacionGeografia: true
        })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };


    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizarDatos) {
            this.obtenerDatos()
            return true
        }
        return true;
    }

    obtenerDatos = () => {
        var user = store.getState().usuario
        Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/provincias/pais/' + user.empresas.paises_id, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var provincias = []
                responsee.data.map((provincia) => (
                    provincias.push({
                        value: provincia.provincia_id,
                        label: provincia.provincia
                    })
                ))
                //console.log(provincias)
                this.setState({ provinciasbd: provincias })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })

        Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/tipos/identificaciones/', { headers: { Authorization: ntoken } })
            .then(responseee => {
                var tid = []
                responseee.data.map((dato) => (
                    tid.push({
                        value: dato.id,
                        label: dato.descripcion
                    })
                ))
                //console.log(tid)
                this.setState({ identificacionesbd: tid })

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        store.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: false
        })
    }

    componentWillMount() {
        this.obtenerDatos()
        this.update()
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        switch (name) {
            case 'departamentoSeleccionada':
                var ciudades = []
                Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/provincias/ciudades/' + value.value, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee.data)
                        responsee.data.map((dato) => (
                            ciudades.push({
                                value: dato.ciudades_id,
                                label: dato.ciudades
                            })
                        ))
                        this.setState({ ciudadesbd: ciudades })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })

                break
            case 'DepartamentoDireccion':
                var ciudades = []
                Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/provincias/ciudades/' + value.value, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee.data)
                        responsee.data.map((dato) => (
                            ciudades.push({
                                value: dato.ciudades_id,
                                label: dato.ciudades
                            })
                        ))
                        this.setState({ ciudadesbd2: ciudades })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break
        }
    }


    onZoomChange = zoom => {
        this.setState({ zoom })
    }

    onZoomChange = zoom => {
        this.setState({ zoom })
    }
    onCropChange = crop => {
        this.setState({ crop })
    }


    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels })
    }
    handledialogTelefono = () => {
        this.setState({ dialogTelefono: !this.state.dialogTelefono });
    }
    handledialogCorreo = () => {
        this.setState({ dialogCorreo: !this.state.dialogCorreo });
    }


    eliminarTelefono = (e) => {
        var mtelefono = this.state.telefonos
        console.log(e)
        mtelefono.splice(e, 1)
        console.log(mtelefono)
        this.setState({ telefonos: mtelefono })
    }

    eliminarTelefonobd = (e) => {
        var mtelefono = this.state.telefonos
        if (mtelefono[e].id != null) {
            mtelefono[e].estado = "I"
        } else {
            mtelefono.splice(e, 1)
        }
        this.setState({ telefonos: mtelefono })
    }


    eliminarCorreo = (e) => {
        var mcorreo = this.state.corrreos
        mcorreo.splice(e, 1)
        console.log(mcorreo)
        this.setState({ corrreos: mcorreo })
    }
    eliminarCorreobd = (e) => {
        var mcorreo = this.state.corrreos
        if (mcorreo[e].id != null) {
            mcorreo[e].estado = "I"
        } else {
            mcorreo.splice(e, 1)
        }
        this.setState({ corrreos: mcorreo })
    }

    eliminarDireccion = (e) => {
        var mdireccion = this.state.direcciones
        mdireccion.splice(e, 1)
        console.log(mdireccion)
        this.setState({ direcciones: mdireccion })
    }
    eliminarDireccionbd = (e) => {
        var mdireccion = this.state.direcciones
        if (mdireccion[e].id != null) {
            mdireccion[e].estado = "I"
        } else {
            mdireccion.splice(e, 1)
        }
        this.setState({ direcciones: mdireccion })
    }
    handleChangeM = address => {
        this.setState({ address });
    };


    _handleImageChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        }

        reader.readAsDataURL(file)
        this.setState({ dialogphoto: !this.state.dialogphoto });
    }

    cancelarFoto = () => {
        console.log("asds")
        this.setState({
            dialogphoto: !this.state.dialogphoto,
            file: null,
            imagePreviewUrl: null

        })
    }

    validarFormulario = () => {
        if (this.state.nombreGuardar.trim == "" || this.state.nombreGuardar.length == 0) {
            swal("FALLO AL CREAR", "EL NOMBRE ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (JSON.stringify(this.state.tipoIdSeleccionada) == '{}') {
            swal("FALLO AL CREAR", "DEBE ESCOGER EL TIPO DE IDENTIFICACION", "warning")
            return false
        } else if (this.state.identificacionGuardar.trim == "" || this.state.identificacionGuardar.length == 0) {
            swal("FALLO AL CREAR", "EL NUMERO DE IDENTIFICACION ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (JSON.stringify(this.state.ciudadSeleccionada) == '{}') {
            swal("FALLO AL CREAR", "POR FAVOR ESCOJA LA CIUDAD", "warning")
            return false
        } else if (this.state.telefonos.length == 0) {
            swal("FALLO AL CREAR", "POR LO MENOS DEBES REGISTRAR UN TELEFONO", "warning")
            return false
        } else if (this.state.corrreos.length == 0) {
            swal("FALLO AL CREAR", "POR LO MENOS DEBES REGISTRAR UN CORREO", "warning")
            return false
        } else if (this.state.direcciones.length == 0) {
            swal("FALLO AL CREAR", "POR LO MENOS DEBES REGISTRAR UNA DIRRECCION", "warning")
            return false
        } else {
            this.setState({
                response: true
            })
            return true
        }

    }

    update() {
        const proveedorEdit = store.getState().usuarioEdicion
        //console.log(proveedorEdit)
        //console.log(userEdit)
        if (proveedorEdit != null) {
            const { contacto, persona, proveedor, productos } = proveedorEdit
            this.setState({
                idProveedor: persona.id,
                nombreGuardar: persona.nombres,
                apellidoGuardar: persona.apellidos,
                identificacionGuardar: persona.identificacion,
                ciudadSeleccionada: {
                    value: persona.ciudad.id,
                    label: persona.ciudad.descripcion,
                },
                tipoIdSeleccionada: {
                    value: persona.tipo_identificacion.id,
                    label: persona.tipo_identificacion.descripcion,
                },
                productosProveedor: productos
            });
            var tel = []
            var mails = []
            var address = []
            if (contacto != null) {
                contacto.map((contact) => {
                    console.log(contact)
                    switch (contact.tipo) {
                        case 1:
                            mails.push({
                                id: contact.id,
                                etiqueta: contact.etiqueta,
                                contacto: contact.contacto,
                                principal: contact.principal,
                                estado: contact.estado
                            })
                            break;
                        case 2:
                            tel.push({
                                id: contact.id,
                                etiqueta: contact.etiqueta,
                                contacto: contact.contacto,
                                principal: contact.principal,
                                estado: contact.estado
                            })
                            break;
                        case 3:
                            address.push({
                                id: contact.id,
                                etiqueta: contact.etiqueta,
                                contacto: contact.contacto,
                                principal: contact.principal,
                                estado: contact.estado
                            })
                            break;
                    }
                })
                this.setState({
                    direcciones: address,
                    telefonos: tel,
                    corrreos: mails
                })
            }
        }
    }
    crearProveedor = () => {
        var dat = store.getState().usuario
        var validado = this.validarFormulario()
        var datos = {
            "persona": {
                "tipos_identificaciones_id": this.state.tipoIdSeleccionada.value,
                "identificacion": this.state.identificacionGuardar,
                "nombres": this.state.nombreGuardar,
                "apellidos": this.state.apellidoGuardar,
                "correo": this.state.corrreos.length > 0 ? this.state.corrreos[0].contacto : 'N/P',
                "estado": "A",
                "empresas_id": parseInt(dat.empresas_id, 10),
                "sangre": 'O+',
                "ciudades_id": this.state.ciudadSeleccionada.value,
                "url_foto": this.state.croppedImage != null || this.state.croppedImage == 'S' ? this.state.imagePreviewUrl : 'N',
                "create_user": dat.p_id
            }, "empresa": {
                "dominio_id": parseInt(store.getState().dominio, 10)
            }, "telefono":
                this.state.telefonos.length > 0 ?
                    this.state.telefonos.map((telefono, key) => {
                        return (
                            {
                                "etiqueta": telefono.etiqueta,
                                "contacto": telefono.contacto,
                                "tipo": 2,
                                "principal": key == 0 ? "S" : "N",
                                "estado": "A"
                            }
                        )
                    }) : []
            , "correo":
                this.state.corrreos.length > 0 ?
                    this.state.corrreos.map((correo, key) => {
                        return (
                            {
                                "etiqueta": correo.etiqueta,
                                "contacto": correo.contacto,
                                "tipo": 1,
                                "principal": key == 0 ? "S" : "N",
                                "estado": "A"
                            }
                        )
                    }) : []
            , "direccion":
                this.state.direcciones.length >= 0 ?
                    this.state.direcciones.map((correo, key) => {
                        return (
                            {
                                "etiqueta": correo.etiqueta,
                                "contacto": correo.contacto,
                                "tipo": 3,
                                "principal": key == 0 ? "S" : "N",
                                "estado": "A"
                            }
                        )
                    }) : []
            , "create_user": {
                "persona_id": dat.p_id
            }
        }
        console.log(datos)
        if (validado) {
            Axios.post(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/persona/proveedor', datos, { headers: { Authorization: ntoken } })
                .then((res) => {
                    console.log(res)
                    this.setState({
                        response: false
                    })
                    if (res.status == 200) {
                        swal({
                            title: "Proveedor creado correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "CREACION_USUARIO",
                                        creacionUsuario: false,
                                        usuarioEdicion: null,
                                        modovista: false
                                    })
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }


    }
    actualizarProveedor = () => {
        var dat = store.getState().usuario
        var validado = this.validarFormulario()
        var datos = {
            "persona": {
                "id": this.state.idProveedor,
                "tipos_identificaciones_id": this.state.tipoIdSeleccionada.value,
                "identificacion": this.state.identificacionGuardar,
                "nombres": this.state.nombreGuardar,
                "apellidos": this.state.apellidoGuardar,
                "correo": this.state.corrreos.length > 0 ? this.state.corrreos[0].contacto : 'N/P',
                "estado": "A",
                "empresas_id": parseInt(dat.empresas_id, 10),
                "sangre": 'O+',
                "ciudades_id": this.state.ciudadSeleccionada.value,
                "url_foto": this.state.croppedImage != null ? this.state.imagePreviewUrl : 'N',
                "update_user": dat.p_id
            }, "empresa": {
                "dominio_id": parseInt(store.getState().dominio, 10)
            }, "telefono":
                this.state.telefonos.length > 0 ?
                    this.state.telefonos.map((telefono, key) => {
                        return (
                            {
                                "id": telefono.id,
                                "etiqueta": telefono.etiqueta,
                                "contacto": telefono.contacto,
                                "tipo": 2,
                                "principal": key == 0 ? "S" : "N",
                                "estado": telefono.estado
                            }
                        )
                    }) : []
            , "correo":
                this.state.corrreos.length > 0 ?
                    this.state.corrreos.map((correo, key) => {
                        return (
                            {
                                "id": correo.id,
                                "etiqueta": correo.etiqueta,
                                "contacto": correo.contacto,
                                "tipo": 1,
                                "principal": key == 0 ? "S" : "N",
                                "estado": correo.estado
                            }
                        )
                    }) : []
            , "direccion":
                this.state.direcciones.length >= 0 ?
                    this.state.direcciones.map((correo, key) => {
                        return (
                            {
                                "id": correo.id,
                                "etiqueta": correo.etiqueta,
                                "contacto": correo.contacto,
                                "tipo": 3,
                                "principal": key == 0 ? "S" : "N",
                                "estado": correo.estado
                            }
                        )
                    }) : []
            , "create_user": {
                "persona_id": dat.p_id
            }
        }
        console.log(datos)
        if (validado) {
            Axios.put(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/persona/proveedor', datos, { headers: { Authorization: ntoken } })
                .then((res) => {
                    console.log(res)
                    this.setState({
                        response: false
                    })
                    if (res.status == 200) {
                        swal({
                            title: "Proveedor actualizado correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "CREACION_USUARIO",
                                        creacionUsuario: false,
                                        usuarioEdicion: null,
                                        modovista: false
                                    })
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }


    }

    showCroppedImage = async () => {
        const croppedImage = await getCroppedImg(
            this.state.imagePreviewUrl,
            this.state.croppedAreaPixels
        )
        //console.log(croppedImage)
        this.setState({ croppedImage, dialogphoto: !this.state.dialogphoto })
    }

    cancelar2 = () => {
        this.setState({ crearGeografia: false })
    }

    borrarImagen = () => {
        this.setState({ croppedImage: null })
    }

    cerrarVentanaCreacion() {
        store.dispatch({
            type: "CREACION_USUARIO",
            creacionUsuario: false,
            usuarioEdicion: null,
            modovista: false
        })
    }

    render() {
        const userEdit = store.getState().usuarioEdicion
        const dominio = store.getState().dominio
        var imagen = ''
        if (userEdit != null) {
            var persona = userEdit.persona
            imagen = Server.hostMultimedia + dominio + "/persona/" + (persona.url_foto == null || (persona.url_foto == 'N') ? 'default.png' : persona.id + '.png')
        }
        var modoVista = store.getState().modovista
        return (
            <div>
                <AppBar style={{ backgroundColor: colorDominio }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={this.handleClose}
                            aria-label="Close"
                            onClick={this.cerrarVentanaCreacion.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            style={{ marginLeft: "theme.spacing(2)", flex: 1, color: '#fff' }}
                        >
                            {modoVista ? persona.nombres + " " + persona.apellidos : 'Creacion de clientes'}
                        </Typography>
                        {modoVista
                            ?
                            null
                            :
                            userEdit != null ?
                                <Button color="inherit" className="border border-white" disabled={this.state.response} onClick={this.actualizarProveedor.bind(this)}>
                                    ACTUALIZAR
              </Button>
                                :
                                <Button color="inherit" className="border border-white" disabled={this.state.response} onClick={this.crearProveedor.bind(this)}>
                                    CREAR PROVEEDOR
</Button>
                        }

                    </Toolbar>
                </AppBar>
                <div className="row mx-auto" style={{ marginTop: '5%', background: "$c3c3c3" }}>
                    <div className="col-md-3 col-sm-12 mx-auto">
                        <div className="row">
                            {userEdit != null
                                ?
                                modoVista ?
                                    <div className="rounded rounded-circle border border-dark mx-auto" style={{ height: '180px', width: '180px' }} >
                                        <img className="img-user rounded-circle border border border-dark" height="180" width="180" src={imagen} />
                                    </div>
                                    :
                                    <div className={this.state.croppedImage != null ? "rounded rounded-circle border border-dark mx-auto" : "userImg rounded rounded-circle border border-dark mx-auto"} style={{ height: '180px', width: '180px' }} >
                                        {this.state.croppedImage != null ? this.state.croppedImage == 'S' ? <img className="img-user rounded-circle border border border-dark" height="180" width="180" src={imagen} /> : <img className="img-user rounded-circle border border border-dark" height="180" width="180" src={this.state.croppedImage} /> : <input type="file" onChange={(e) => this._handleImageChange(e)} style={{ opacity: "0.0", height: "100%", cursor: "pointer" }} />}
                                        {this.state.croppedImage != null ? <img src={ChangeImage} height="30" width="30" onClick={() => this.borrarImagen()} style={{ cursor: 'pointer', float: 'right' }} /> : null}
                                    </div>
                                :
                                <div className="userImg rounded rounded-circle border border-dark mx-auto" style={{ height: '180px', width: '180px' }} >
                                    {this.state.croppedImage != null ? <img className="img-user rounded-circle border border border-dark" height="180" width="180" src={this.state.croppedImage} /> : <input type="file" onChange={(e) => this._handleImageChange(e)} style={{ opacity: "0.0", height: "100%", cursor: "pointer" }} />}
                                    {this.state.croppedImage != null ? <img src={ChangeImage} height="30" width="30" onClick={() => this.borrarImagen()} style={{ cursor: 'pointer', float: 'right' }} /> : null}
                                </div>
                            }

                        </div>
                        {modoVista ? null : this.state.croppedImage != null ? null : <div className="row mx-auto">
                            <p className="mx-auto">SOLO IMAGENES DE FORMATO PNG</p>
                        </div>}
                    </div>
                    <div className="col-md-9 col-sm-12 ">
                        <div className="row">
                            <div className="col-md-9 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Nombre y/o Razon social</label>
                                <input id="numordencompra" autocomplete="off" disabled={modoVista} value={this.state.nombreGuardar} onChange={this.handleChange('nombreGuardar')} className="form-control" type="text" />
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Tipo ID.</label>
                                {modoVista ?
                                    <input id="numordencompra" disabled={modoVista} className="form-control" type="text" value={persona.tipo_identificacion.descripcion} />
                                    :
                                    <Selects
                                        isDisabled={modoVista}
                                        value={this.state.tipoIdSeleccionada}
                                        onChange={this.handleChanges('tipoIdSeleccionada')}
                                        options={this.state.identificacionesbd}
                                    />
                                }
                                <Dialog
                                    open={this.state.crearGeografia}
                                    onClose={() => this.cancelar2()}
                                    aria-labelledby="form-dialog-title"
                                    maxWidth={'md'}
                                    fullWidth={true}

                                    style={{ padding: '0' }}
                                    disableBackdropClick={false}
                                >
                                    <DialogContent style={{ padding: '0' }}>
                                        <FormularioGeografia tipoGeografia={this.state.tipoGeografia} />
                                    </DialogContent>
                                </Dialog>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-3 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">N.Identificacion</label>
                                <input id="numordencompra" autocomplete="off" disabled={modoVista} className="form-control" type="number" value={this.state.identificacionGuardar} onChange={this.handleChange('identificacionGuardar')} />
                            </div>
                            {modoVista ?
                                null
                                :
                                <div className="col-md-4 col-sm-12">
                                    <label htmlFor="numordencompra" className="col-form-label">Departamento principal</label>
                                    <Selects
                                        value={this.state.departamentoSeleccionada}
                                        onChange={this.handleChanges('departamentoSeleccionada')}
                                        options={this.state.provinciasbd}
                                        isDisabled={modoVista}
                                    />
                                </div>
                            }
                            {modoVista ? null : <div className="col-lg-1 col-md-1 col-sm-2 bottom-aligned " style={{ padding: 0 }}>
                                <button className="btn btn-primary" onClick={() => this.crearGeografia(2)}>+</button>
                            </div>}
                            <div className="col-md-3 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Ciudad principal</label>
                                {modoVista ?
                                    <input id="numordencompra" disabled={modoVista} className="form-control" type="text" value={persona.ciudad.descripcion} />
                                    :
                                    <Selects
                                        value={this.state.ciudadSeleccionada}
                                        onChange={this.handleChanges('ciudadSeleccionada')}
                                        options={this.state.ciudadesbd}
                                        isDisabled={modoVista}
                                    />}
                            </div>
                            {modoVista ? null : <div className="col-lg-1 col-md-1 col-sm-2 bottom-aligned " style={{ padding: 0 }}>
                                <button className="btn btn-primary" onClick={() => this.crearGeografia(3)}>+</button>
                            </div>}
                        </div>
                    </div>


                </div>
                <div className="row mx-auto" style={{ marginTop: '2%', background: "$c3c3c3" }}>
                    <div className="col-md-5 col-sm-12" >
                        <div className="row">
                            <h5 className="mx-auto" style={{ color: 'red' }}><b>DIRECCIONES</b></h5>
                        </div>
                        {modoVista ? null :
                            <div className="row rounded bg-light border" style={{ paddingBottom: '10px', marginLeft: '2px', marginRight: '2px' }}>
                                <div className="col-md-12 col-sm-12" >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <label htmlFor="numordencompra" className="col-form-label">Departamento</label>
                                            <Selects
                                                value={this.state.DepartamentoDireccion}
                                                onChange={this.handleChanges('DepartamentoDireccion')}
                                                options={this.state.provinciasbd}
                                                isDisabled={modoVista}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <label htmlFor="numordencompra" className="col-form-label">Ciudad</label>
                                            <Selects
                                                value={this.state.ciudadDireccion}
                                                onChange={this.handleChanges('ciudadDireccion')}
                                                options={this.state.ciudadesbd2}
                                                isDisabled={modoVista}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12" >
                                            <label htmlFor="numordencompra" className="col-form-label">Direccion de residencia</label>
                                            <div class="input-group ">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1"><LocationIcon /></span>
                                                </div>
                                                <input type="text" class="form-control" disabled={modoVista} value={this.state.DireccionGuardar} onChange={this.handleChange('DireccionGuardar')} aria-describedby="basic-addon1" />
                                                <div class="input-group-append">
                                                    <button class="btn btn-primary" type="button" disabled={this.state.DireccionGuardar == ''} onClick={userEdit != null ? () => this.agregarContactoProveedorbd(3) : () => this.agregarContactoProveedor(3)}>AGREGAR</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        <div className="row">
                            <List className="col-md-11 mx-auto" >
                                {this.state.direcciones.map((contacto, key) => {
                                    if (contacto.estado == "A") {
                                        return (
                                            <ListItem className="mx-auto border" key={key} value={key}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <LocationIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={contacto.etiqueta} secondary={contacto.contacto} />
                                                {modoVista ? null : <ListItemSecondaryAction>
                                                    <IconButton aria-label="Delete" onClick={userEdit != null ? () => this.eliminarDireccionbd(key) : () => this.eliminarDireccion(key)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>}
                                            </ListItem>
                                        )
                                    }
                                })}
                            </List>
                        </div>

                    </div>
                    <div className="col-md-4 col-sm-12" >
                        <div className="row mx-auto">
                            <h5 className="mx-auto" style={{ color: 'red' }}><b>CORREOS</b></h5>
                        </div >
                        {modoVista ? null :
                            <div className="row rounded bg-light border" style={{ paddingBottom: '10px', marginLeft: '2px', marginRight: '2px' }}>
                                <div className="col-md-12 col-sm-12" >
                                    <div className="row bottom-aligned">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="numordencompra" className="col-form-label">Etiqueta</label>
                                            <input type="text" class="form-control" aria-describedby="basic-addon1" value={this.state.etiquetacorr} onChange={this.handleChange('etiquetacorr')} />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="numordencompra" className="col-form-label">{'Correo'}</label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1">{<MailIcon />}</span>
                                                </div>
                                                <input type="email" class="form-control" value={this.state.contactocorr} onChange={this.handleChange('contactocorr')} aria-describedby="basic-addon1" />
                                                <div class="input-group-append">
                                                    <button class="btn btn-primary" type="button" disabled={this.state.contactocorr == '' || this.state.etiquetacorr == ''} onClick={userEdit != null ? () => this.agregarContactoProveedorbd(1) : () => this.agregarContactoProveedor(1)}>AGREGAR</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>}
                        <div className="row">
                            <List className="col col-md-11 mx-auto" >
                                {this.state.corrreos.map((contacto, key) => {
                                    if (contacto.estado == "A") {
                                        return (
                                            <ListItem className="mx-auto border" key={key}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <MailIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={contacto.etiqueta} secondary={contacto.contacto} />
                                                {modoVista ? null : <ListItemSecondaryAction>
                                                    <IconButton aria-label="Delete" onClick={userEdit != null ? () => this.eliminarCorreobd(key) : () => this.eliminarCorreo(key)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>}
                                            </ListItem>
                                        )
                                    }
                                })}
                            </List>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12" >
                        <div className="row mx-auto">
                            <h5 className="mx-auto" style={{ color: 'red' }}><b>TELEFONOS</b></h5>
                        </div>
                        {modoVista ? null :
                            <div className="row rounded bg-light border" style={{ paddingBottom: '10px', marginLeft: '2px', marginRight: '2px' }}>
                                <div className="col-md-12 col-sm-12" >
                                    <div className="row bottom-aligned">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="numordencompra" className="col-form-label">Etiqueta</label>
                                            <input type="text" class="form-control" aria-describedby="basic-addon1" value={this.state.etiquetatel} onChange={this.handleChange('etiquetatel')} />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="numordencompra" className="col-form-label">{'Telefono'}</label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1">{<TelefonoIcon />}</span>
                                                </div>
                                                <input type="number" min="1" class="form-control" value={this.state.contactotel} onChange={this.handleChange('contactotel')} aria-describedby="basic-addon1" />
                                                <div class="input-group-append">
                                                    <button class="btn btn-primary" type="button" disabled={this.state.contactotel == '' || this.state.etiquetatel == ''} onClick={userEdit != null ? () => this.agregarContactoProveedorbd(2) : () => this.agregarContactoProveedor(2)}>AGREGAR</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>}
                        <div className="row">
                            <List className="col col-md-11 mx-auto" >
                                {this.state.telefonos.map((contacto, key) => {
                                    if (contacto.estado == "A") {
                                        return (
                                            <ListItem className="mx-auto border" key={key} value={key}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <TelefonoIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={contacto.etiqueta} secondary={contacto.contacto} />
                                                {modoVista ? null : <ListItemSecondaryAction>
                                                    <IconButton aria-label="Delete" onClick={userEdit != null ? () => this.eliminarTelefonobd(key) : () => this.eliminarTelefono(key)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>}
                                            </ListItem>
                                        )
                                    }
                                })}
                            </List>
                        </div>
                    </div>
                    {modoVista ? <div className="row mx-auto">
                        <div>
                            <h5 className="mx-auto" style={{ color: 'red' }}><b>PRODUCTOS</b></h5>
                        </div>
                    </div> : null}
                    {modoVista ? <div className="row mx-auto">

                        <Paper className="col-md-12 col-sm-12" style={{ padding: '10px' }}>
                            {this.state.productosProveedor != null ? this.state.productosProveedor.map((data, key) => {
                                return (
                                    <Chip
                                        style={{ margin: '2px' }}
                                        key={key}
                                        label={data.descripcion}
                                    />
                                );
                            }) : <h5 className="mx-auto" style={{ color: 'red' }}><b>ESTE PROVEEDOR NO TIENE NINGUN PRODUCTO ASOCIADO</b></h5>}

                        </Paper>
                    </div> : null}

                </div>


                <div className="row">
                    <div className="col col-md-10 mx-auto">
                        <label htmlFor="numordencompra" className="col-form-label">Localizacion</label>
                        <PlacesAutocomplete
                            value={this.state.address}
                            onChange={this.handleChangeM}
                            onSelect={this.handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input autocomplete="off"
                                        {...getInputProps({
                                            placeholder: 'Buscar lugar...',
                                            className: 'location-search-input',
                                        })}
                                        className="form-control"
                                        style={{ width: '100%' }}
                                        disabled={modoVista}
                                    />
                                    <div >
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    style={{ padding: 0, width: '100%', zIndex: '1000' }}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <MenuItem style={{ padding: '5px', width: '100%', zIndex: '1000' }}>{suggestion.description}</MenuItem>
                                                    {/*<span>{suggestion.description}</span>*/}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </div>
                <div className="row" style={{marginBottom:'30px'}}>
                    <div className="col col-md-10 mx-auto">
                        <Map
                            className="rounded mx-auto"
                            google={this.props.google}
                            zoom={this.state.zoom2}
                            style={{ height: '400px' }}
                            initialCenter={this.state.centerDireccion}
                            center={this.state.centerDireccion}
                        >
                            <Marker draggable={true} position={this.state.centerDireccion} onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)} />
                        </Map>
                    </div>
                </div>

                <Dialog
                    open={this.state.dialogphoto}
                    onClose={this.cancelarFoto}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'sm'}
                    fullWidth={true}
                    disableBackdropClick={true}
                >
                    <DialogTitle id="form-dialog-title">Recortar la foto</DialogTitle>
                    <DialogContent >
                        <div className="row mx-auto">
                            <div className="col col-md-12" style={{ height: "150px" }}>
                                <Cropper
                                    image={this.state.imagePreviewUrl}
                                    crop={this.state.crop}
                                    zoom={this.state.zoom}
                                    aspect={this.state.aspect}
                                    onCropChange={this.onCropChange}
                                    onCropComplete={this.onCropComplete}
                                    onZoomChange={this.onZoomChange}
                                />
                            </div>
                        </div>
                        <div className="row mx-auto">
                            <div className="col col-md-12" style={{ height: "100%", margin: "15px" }}>
                                <Slider
                                    value={this.state.zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e, zoom) => this.onZoomChange(zoom)}
                                    classes={{ container: 'slider' }}
                                />
                            </div>
                        </div>

                    </DialogContent>
                    <DialogActions>
                        {/*<button className="btn btn-primary" onClick={this.cancelarFoto}>
                            Cancelar
                            </button>*/}
                        <button onClick={this.showCroppedImage} className="btn btn-primary">
                            Agregar foto
                                                        </button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.dialogTelefono}
                    onClose={this.handledialogTelefono}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'sm'}
                    style={{ padding: '0' }}
                >
                    <DialogContent style={{ padding: '0' }}>
                        <DialogTelefono telefonos={this.state.telefonos} />
                    </DialogContent>

                </Dialog>
                <Dialog
                    open={this.state.dialogCorreo}
                    onClose={this.handledialogCorreo}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'sm'}
                    style={{ padding: '0' }}
                >
                    <DialogContent style={{ padding: '0' }}>
                        <DialogCorreo correos={this.state.corrreos} />
                    </DialogContent>

                </Dialog>
            </div>
        )
    }
}


export default GoogleApiWrapper({
    apiKey: 'AIzaSyDwX4ZKPWuQBM3cxGsXvz8MONBD0NtpoIo'
})(FormularioPersona)


