import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import SortableTree from "react-sortable-tree";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-sortable-tree/style.css';
import Server from '../../../lib/server'
import store from '../../../lib/store'
import Axios from 'axios'
import swal from 'sweetalert'
const idModulo = 5
var permisos = ''
var ramasEmpresas = []
var totalesEmpresasbd = []
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class Estructura extends Component {
    constructor() {
        super();
        this.state = {
            modoEdicion: false,
            nodeEmpresas: [],
            treeData: [
                {
                    title: "TERPEL", expanded: true,
                    children: [
                        {
                            title: "TERPEL COLOMBIA",
                            expanded: true,
                            children: [
                                {
                                    title: "SUCURSAL NORTE ",
                                    expanded: true,
                                    children: [
                                        {
                                            title: "SUCURSAL ATLANTICO",
                                            expanded: true,
                                            children: [
                                                {
                                                    title: "MONTERIA"
                                                },
                                                {
                                                    title: "BOSTON"
                                                },
                                                {
                                                    title: "VIA 40"
                                                },
                                                {
                                                    title: "CIRCUNVALAR"
                                                },
                                            ]
                                        }, {
                                            title: "SUCURSAL RIOACHA",
                                            expanded: false,
                                            children: [
                                                {
                                                    title: "MAICAO"
                                                },
                                                {
                                                    title: "RIOACHA 1"
                                                }
                                            ]
                                        }
                                    ]
                                },
                            ]
                        },
                        {
                            title: "TERPEL PANAMA"
                        },
                    ]
                }
            ]
        };
    }
    editar = () => {
        this.setState({ modoEdicion: !this.state.modoEdicion })
    }

    buscarhijos = (id) => {
        var empresasss = ramasEmpresas
        var datos = store.getState().usuario
        if (ramasEmpresas.length == 0) {
            totalesEmpresasbd.map((empresas) => {
                if (empresas.empresas_id == id) {
                    empresasss.push({
                        value: empresas.id,
                        expanded: true,
                        title: empresas.alias,
                        tipo: empresas.empresas_tipos_id,
                        children: this.buscar_Subhijos(empresas.id)
                    })
                }
            })
        }
        var esta = [{
            value:datos.empresas_id,
            expanded: true,
            title: datos.empresas.alias,
            tipo: 3,
            children: empresasss
        }]
        this.setState({ nodeEmpresas: esta })
    }

    buscar_Subhijos = (id) => {
        var hijiii = []
        totalesEmpresasbd.map((empresas) => {
            if (empresas.empresas_id == id) {
                hijiii.push({
                    value: empresas.id,
                    expanded: true,
                    title: empresas.alias,
                    tipo: empresas.empresas_tipos_id,
                    children: this.buscar_Subhijos(empresas.id)

                })
                this.setState({ actualizar: true })
            }
        })
        return hijiii
    }

    componentWillMount() {
        //Permisos usuarios
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        //Empresas
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/3,4,5/padre/' + hijos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status == 200 && responsee.data.empresas != null) {
                    totalesEmpresasbd = responsee.data.empresas
                    this.buscarhijos(usuario.empresas_id)
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    render() {
        return (
            <div style={{ height: '100vh', margin: 0 }}>
                {/*<div className="row">
                    {this.state.modoEdicion ? <button className="btn btn-danger" onClick={this.editar}>Cancelar</button> : permisos.includes('U') ? <button className="btn btn-warning" onClick={this.editar}>Editar</button> : null}
                    {this.state.modoEdicion ? <button className="btn btn-success" onClick={this.editar}>Guardar</button> : null}
        </div>*/}
                {permisos.includes('R') ?
                    <SortableTree
                        canDrag={this.state.modoEdicion}
                        treeData={this.state.nodeEmpresas}
                        onChange={treeData => this.setState({ nodeEmpresas:treeData })}
                    />
                    :
                    <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>}
            </div>

        );
    }
}


export default Estructura;