import React from 'react';
import Paper from '@material-ui/core/Paper';
import { MDBDataTable } from 'mdbreact';
import Loader from '../../../components/Loader'
import { getEquipos } from "./serviciosEquipo"
import store from '../../../lib/store';
import Selects from 'react-select';
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog';
import INFOIcon from '@material-ui/icons/Info';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { DialogTitle, DialogContent } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import excelIcon from "../../../assets/img/excel.png";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const idModulo = 49
let permisos = ''
class Entradas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listadoEquipos: [],
            tiempoRefrescar: 0,
            refrescarBD: [{
                label: "NO SE REFRESCA",
                value: 0
            }, {
                label: "15 SEGUNDOS",
                value: 15000
            }, {
                label: "30 SEGUNDOS",
                value: 30000
            }, {
                label: "1 MINUTO",
                value: 60000
            }],
            referscarSeleccionado: {
                label: "NO SE REFRESCA",
                value: 0
            },
            loader: true,
            ultimaActualizacion: new Date(),
            data: [],
            dialogoPropiedades: false,
            propiedadesSelecionadas: null,
            propiedadesEmpresa: "",
            moreDetalles: false,
            excel: []
        }


    }

    //

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        this.llamarInterval(value.value)

    }

    componentWillMount() {
        this.obtenerDatos()
    }

    componentDidMount() {
        this.llamarInterval(0)
    }

    llamarInterval = (time) => {
        clearInterval(this.intervalId);
        if (time != 0) {
            this.intervalId = setInterval(this.timer.bind(this), time);
        } else {
            clearInterval(this.intervalId);
        }
    }

    timer() {
        this.setState({
            currentCount: this.state.currentCount - 1
        });
        if (this.state.currentCount < 1) {
            clearInterval(this.intervalId);
        }
        this.obtenerDatos();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    obtenerFecha = (fc) => {
        var fechaResult = '';
        fc = moment(fc);
        fechaResult = moment(fc, 'YYYYMMDD').fromNow();
        return fechaResult;
    };

    obtenerDatos = async () => {
        const modulos = store.getState().perfilesUSuario
        modulos.map((modulo) => {
            if (modulo.modulos_id === idModulo) {
                permisos = modulo.acciones
            }
        })
        const hijos = store.getState().empresasHijos;
        this.setState({
            data: await getEquipos(hijos),
            loader: false,
            ultimaActualizacion: new Date()
        })
        this.crearTabla()
    }

    handleDialogPropiedades = (propiedades, alias) => {
        this.setState({
            dialogoPropiedades: !this.state.dialogoPropiedades,
            propiedadesSelecionadas: propiedades,
            propiedadesEmpresa: alias,
            moreDetalles: false
        });
    };

    obtenerInternas = (objeto) => {
        var camps = Object.keys(objeto)
        var valors = Object.values(objeto)
        return <div>
            {camps.map((campo, key) => (
                <tr key={key}>
                    <td>{campo}</td>
                    <td>{typeof valors[key] === 'object' ?
                        this.obtenerInternas(valors[key])
                        : valors[key]}</td>
                </tr>
            ))}
        </div>
    }


    crearTabla = () => {
        const { data, } = this.state
        const datos = []
        const excel = []
        data.map((dt) => {
            let empresa = dt.empresa[0] || {}
            let propiedades = JSON.parse(dt.propiedades) || {}
            let obj = {
                numero: dt.equipos_id,
                empresa_id: 'e' + dt.empresas_id,
                kiosco: empresa.alias,
                tiempo: propiedades.fechaws && propiedades.fechaws != "" ? this.obtenerFecha(moment(propiedades.fechaws, "DD-MM-YYYY hh:mm:ss A").format("YYYY-MM-DD hh:mm:ss A")) : '',
                ultima_conexion: propiedades.fechaws ? moment(propiedades.fechaws, "DD-MM-YYYY hh:mm:ss A").format("YYYY-MM-DD hh:mm:ss A") : '',
                ultima_venta: propiedades.ultimaVenta ? moment(propiedades.ultimaVenta.fecha, "DD-MM-YYYY hh:mm:ss A").format("YYYY-MM-DD hh:mm:ss A") : '',
                ultimo_consecutivo: propiedades.ultimaVenta ? propiedades.ultimaVenta.consecutivo : '',
                props: permisos.includes('U') ? (dt.propiedades === null ?
                    ''
                    :
                    <button className="btn btn-primary btn-sm" onClick={() => this.handleDialogPropiedades(dt.propiedades, empresa.alias)}><INFOIcon /></button>) : 'N/A',
                pendientes: propiedades.pendientes,
                version: 'v' + propiedades.version_code
            }
            excel.push(obj)
            datos.push(obj)
        })
        this.setState({
            listadoEquipos: datos,
            excel
        })
    }

    handleMoreDetalles = () => {
        this.setState({
            moreDetalles: !this.state.moreDetalles
        })
    }

    render() {
        const { loader, listadoEquipos, refrescarBD, referscarSeleccionado, ultimaActualizacion, propiedadesSelecionadas, excel } = this.state
        var propiedades = null
        var campos = []
        var valores = []
        if (propiedadesSelecionadas != null) {
            propiedades = JSON.parse(this.state.propiedadesSelecionadas)
            campos = Object.keys(propiedades)
            valores = Object.values(propiedades)

        }
        return (
            <div>
                <div className="row mx-auto">
                    <div className="col-md-5" >
                        <label htmlFor="nombreprov" className="col-form-label">REFRESCAR CADA</label>
                        <Selects
                            placeholder="Seleccione o escriba"
                            value={referscarSeleccionado}
                            onChange={this.handleChanges('referscarSeleccionado')}
                            options={refrescarBD}
                        />
                    </div>
                    <div className="col-md-5 bottom-aligned" >
                        <h5 className="mx-auto ">ULTIMA ACTUALIZACIÓN {moment(ultimaActualizacion).format("hh:mm:ss A")}</h5>
                    </div>
                </div>
                <div className="row mx-auto">
                    <ExcelFile
                        filename={"SEGUIMIENTO DE EQUIPOS"}
                        element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /> EXPORTAR </button>}
                    >
                        <ExcelSheet data={excel} name="EQUIPOS">
                            <ExcelColumn label="EQUIPO" value="numero" />
                            <ExcelColumn label="EMPRESA" value="empresa_id" />
                            <ExcelColumn label="KIOSCO" value="kiosco" />
                            <ExcelColumn label="TIEMPO" value="tiempo" />
                            <ExcelColumn label="ULTIMA CONEXION" value="ultima_conexion" />
                            <ExcelColumn label="ULTIMA VENTA" value="ultima_venta" />
                            <ExcelColumn label="CONSECUTIVO" value="ultimo_consecutivo" />
                            <ExcelColumn label="PENDIENTES" value="pendientes" />
                            <ExcelColumn label="VERSION" value="version" />

                        </ExcelSheet>
                    </ExcelFile>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                    <div className="col col-md-12" style={{ padding: 0 }}>
                        <Paper style={{ padding: '5px' }}>
                            <Dialog
                                open={this.state.dialogoPropiedades}
                                onClose={() => this.handleDialogPropiedades(null, "")}
                                aria-labelledby="form-dialog-title"
                                maxWidth={'md'}
                                fullWidth={true}
                                style={{ padding: '0' }}
                                disableBackdropClick={false}
                            >
                                <DialogContent style={{ padding: '10px' }}>

                                    {

                                        propiedades != null
                                            ?
                                            <div>
                                                <div className="row" >
                                                    <FormControlLabel
                                                        style={{ marginLeft: "10px" }}
                                                        control={
                                                            <Switch
                                                                onChange={this.handleMoreDetalles.bind(this)}
                                                                value="checkedA"
                                                            />
                                                        }
                                                        label={!this.state.moreDetalles ? "Menos detalles" : "Mas detalles"}
                                                    />
                                                </div>
                                                {
                                                    !this.state.moreDetalles ?
                                                        <div className="row mx-auto">
                                                            <div className="col-md-10 mx-auto">
                                                                <MDBTable bordered striped>
                                                                    <MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
                                                                        <tr>
                                                                            <th colSpan="2" align="center">{"PROPIEDADES DEL EQUIPO " + this.state.propiedadesEmpresa}</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>CAMPO</th>
                                                                            <th>VALOR</th>

                                                                        </tr>
                                                                    </MDBTableHead>
                                                                    <MDBTableBody>
                                                                        {campos.map((campo, key) => (
                                                                            <tr key={key}>
                                                                                <td>{campo}</td>
                                                                                <td>{typeof valores[key] === 'object' ?
                                                                                    this.obtenerInternas(valores[key])
                                                                                    : valores[key]}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </MDBTableBody>
                                                                </MDBTable>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div>
                                                            <div className="row mx-auto" >
                                                                <h5 className="mx-auto">PROPIEDADES DEL EQUIPO {this.state.propiedadesEmpresa}</h5>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numordencompra" className="col-form-label">Version code</label>
                                                                    <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.version_code + ""} />
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numordencompra" className="col-form-label">Version APP</label>
                                                                    <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.version_app + ""} />
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numordencompra" className="col-form-label">Fecha</label>
                                                                    <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.fecha + ""} />
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numordencompra" className="col-form-label">Pendientes</label>
                                                                    <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.pendientes + ""} />
                                                                </div>
                                                            </div>
                                                            {
                                                                propiedades.anydesk != null ?
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="numordencompra" className="col-form-label">Anydesk</label>
                                                                            <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.anydesk.id + ""} />
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="numordencompra" className="col-form-label">Alias</label>
                                                                            <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.anydesk.alias + ""} />
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="numordencompra" className="col-form-label">Status</label>
                                                                            <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.anydesk.status + ""} />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numordencompra" className="col-form-label">Impresora</label>
                                                                    <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.impresora + ""} />
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numordencompra" className="col-form-label">RFID</label>
                                                                    <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.rfid + ""} />
                                                                </div>
                                                                {
                                                                    propiedades.ultimaVenta != null ?
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="numordencompra" className="col-form-label">Ultima venta</label>
                                                                            <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={"Con. " + propiedades.ultimaVenta.consecutivo + ", Fecha: " + propiedades.ultimaVenta.fecha} />
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                            </div>
                                                            {
                                                                propiedades.sistema != null ?
                                                                    <div className="row">
                                                                        <div className="col-md-9">
                                                                            <label htmlFor="numordencompra" className="col-form-label">Sistema</label>
                                                                            <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.sistema.sistema + ""} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="numordencompra" className="col-form-label">Tiempo activo</label>
                                                                            <input autoComplete="off" id="numordencompra" className="form-control" disabled={true} type="text" value={propiedades.sistema.uptime + ""} />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                propiedades.wifis != null ?
                                                                    <div className="row mx-auto" >
                                                                        <h6 className="mx-auto">LISTA DE REDES WIFI</h6>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                propiedades.wifis != null ?
                                                                    <div className="row mx-auto" style={{ marginTop: "5px" }}>
                                                                        <List className="border rounded col-md-8 mx-auto">
                                                                            {
                                                                                propiedades.wifis != null || propiedades.length > 0 ?
                                                                                    propiedades.wifis.map((wifi) => {
                                                                                        return (
                                                                                            <ListItem className="border" alignItems="flex-start">
                                                                                                <ListItemText
                                                                                                    primary={"Nombre: " + wifi.ssid}
                                                                                                    secondary={"Clave: " + wifi.psk}
                                                                                                />
                                                                                            </ListItem>
                                                                                        )
                                                                                    })
                                                                                    :
                                                                                    <ListItem alignItems="flex-start">
                                                                                        <ListItemText
                                                                                            primary={"NO HAY WIFI CONFIGURADO"}
                                                                                        />

                                                                                    </ListItem>

                                                                            }
                                                                        </List>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                }



                                            </div>
                                            :
                                            null
                                    }

                                    <div className="col-md-2 mx-auto" style={{ float: "right", padding: '8px' }}>
                                        <button className="btn btn-danger btn-block" type="reset" onClick={() => this.handleDialogPropiedades(null, "")}>
                                            Cerrar
                                            </button>
                                    </div>
                                </DialogContent>
                            </Dialog>
                            {loader ? <Loader /> : <MDBDataTable
                                //striped
                                className="col-md-12"
                                searchLabel="Buscar"
                                paginationLabel={["Anterior", "Siguente"]}
                                infoLabel={["Mostrando", "a", "de", "entradas"]}
                                entriesLabel="Filas a mostrar"
                                tbodyColor="primary-color"
                                theadColor="#FF8800"
                                exportToCSV={true}
                                striped
                                bordered
                                autoWidth={false}
                                responsive={true}
                                entries={15}
                                entriesOptions={[15, 50, 100, 500, -1]}
                                hover
                                data={{
                                    columns: [
                                        {
                                            label: 'No.',
                                            field: 'numero',
                                            sort: 'asc'
                                        }, {
                                            label: 'Empresa',
                                            field: 'empresas_id',
                                            sort: 'asc'
                                        }, {
                                            label: 'Kiosco',
                                            field: 'kiosco',
                                            sort: 'asc'
                                        }, {
                                            label: 'Tiempo',
                                            field: 'tiempo',
                                            sort: 'asc'
                                        }, {
                                            label: 'Ult. Conexion',
                                            field: 'ultima_conexion',
                                            sort: 'asc'
                                        }, {
                                            label: 'Ult. Venta',
                                            field: 'ultima_venta',
                                            sort: 'asc'
                                        }, {
                                            label: 'Cons.',
                                            field: 'ultimo_consecutivo',
                                            sort: 'asc'
                                        }, {
                                            label: 'Propiedades',
                                            field: 'props',
                                            sort: 'asc'
                                        }, {
                                            label: 'Pendientes',
                                            field: 'pendientes',
                                            sort: 'asc'
                                        }, {
                                            label: 'Version',
                                            field: 'version',
                                            sort: 'asc'
                                        }
                                    ],
                                    rows: listadoEquipos
                                }}
                                style={{ fontSize: '13px' }}
                            />}

                        </Paper>
                    </div>
                </div>

            </div>
        )
    }
}
export default Entradas