import Axios from 'axios';
import Server from '../../../lib/server';

export const getEquipos = async (id) => {
    return new Promise(function (resolve, reject) {
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
         Axios.get(Server.hostPrueba + Server.puertoEquipos + '/api/equipo/seguimientoEquipo/' + id, { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data.data);
                } else if (res.status === 205) {
                    resolve([]);
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err, undefined);
            });
    });

};