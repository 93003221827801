
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import 'bootstrap/dist/css/bootstrap.min.css';
import CardTittle from '@material-ui/core/CardHeader'
import GridItem from "components/Grid/GridItem.jsx";
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Server from '../../lib/server'
import store from '../../lib/store'
import Axios from 'axios'
import swal from 'sweetalert'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment'
import "moment/locale/es";
var impuestoEditar = false
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class FormularioImpuesto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkIValor: false,
            checkIPorcentaje: true,
            idEditar: 0,
            //DAtos a guardar
            descripcionN: '',
            selectedDate: new Date(),
            cambbioInicio: false,
            cambbioFin: false,
            selectedDateFin: new Date(),
            valorn: '',
            modoEditar: false,
            nimpuesto: null,
            impuestosActuales: [],
            nombesImpuestosActuales: [],
            response: false
        };
    }

    componentWillMount() {
        impuestoEditar = this.props.impuestoEditar
        var usuario = store.getState().usuario
        var dominio = store.getState().dominio
        //console.log(impuestoEditar)
        if (impuestoEditar != null) {
            this.setState({ modoEdicion: true, descripcionN: impuestoEditar.descripcion, checkIValor: impuestoEditar.porcentaje_valor == "$", checkIPorcentaje: impuestoEditar.porcentaje_valor == "%", valorn: impuestoEditar.valor + '', selectedDate: impuestoEditar.fecha_inicio, selectedDateFin: impuestoEditar.fecha_fin, idEditar: impuestoEditar.id })
        }
        //Impuestos
        Axios.get(Server.hostPrueba + Server.puertSSLParametrizacion + '/api/impuestos/empresas/' + parseInt(usuario.empresas_id, 10) + ',' + parseInt(dominio, 10), { headers: { Authorization: ntoken } })
            .then(responsee => {
                var nombresTotales = []
                var totales = []
                responsee.data.map((impuesto, key) => {
                    nombresTotales.push(impuesto.descripcion)
                    totales.push({
                        'porcentaje_valor': impuesto.porcentaje_valor,
                        'valor': parseFloat(impuesto.valor).toFixed(3),
                    })
                })
                console.log(totales)
                this.setState({ impuestosActuales: totales, nombesImpuestosActuales: nombresTotales })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }


    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };

    handleChangemoment = date => {
        this.setState({ selectedDate: date, cambbioInicio: true });
    }

    handleChangemomentFin = date => {
        this.setState({ selectedDateFin: date, cambbioFin: true });
    }


    changeSelect = name => event => {
        switch (name) {
            case 'checkIPorcentaje':
                this.setState({ checkIPorcentaje: true, checkIValor: false })
                break
            case 'checkIValor':
                this.setState({ checkIPorcentaje: false, checkIValor: true })
                break
        }
    }


    validarFormulario = () => {
        var existe = false
        console.log("porcentaje", this.state.checkIPorcentaje)
        var impuestoACrear = {
            'porcentaje_valor': this.state.checkIPorcentaje ? '%' : '$',
            'valor': parseFloat(this.state.valorn).toFixed(3),
        }
        this.state.impuestosActuales.map((impuesto, key) => {
            if (JSON.stringify(impuesto) == JSON.stringify(impuestoACrear)) {
                existe = true
            }
        })
        if (this.state.descripcionN.trim == "" || this.state.descripcionN.length == 0) {
            swal("FALLO AL CREAR", "LA DESCRIPCION DEL IMPUESTO ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.valorn.trim == "" || this.state.valorn.length == 0) {
            swal("FALLO AL CREAR", "EL VALOR ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.checkIPorcentaje && existe && !this.state.modoEdicion) {
            swal("FALLO AL CREAR", "YA EXISTE UN IMPUESTO CON ESTAS PROPIEDADES", "warning")
            return false
        } else if (this.state.nombesImpuestosActuales.includes(this.state.descripcionN) && !this.state.modoEdicion) {
            swal("FALLO AL CREAR", "YA EXISTE UN IMPUESTO CON ESTE NOMBRE", "warning")
            return false
        } else {
            console.log("Aquyi")
            this.setState({
                response: true
            })
            return true
        }

    }

    enviarDatos = () => {
        var dominio = store.getState().dominio
        var usuario = store.getState().usuario
        var validar = this.validarFormulario()
        var datos = {
            "descripcion": this.state.descripcionN,
            "porcentaje_valor": this.state.checkIPorcentaje ? '%' : '$',
            "nivel_id": parseInt(usuario.tipo),
            "valor": this.state.valorn.length == 0 ? 0 : parseFloat(this.state.valorn),
            "fecha_inicio": this.state.cambbioInicio ? moment(new Date(this.state.selectedDate.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "fecha_fin": this.state.cambbioFin ? moment(new Date(this.state.selectedDateFin.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "empresas_id": parseInt(usuario.empresas_id, 10),
            "update_user": usuario.p_id
        }
        console.log(datos)
        console.log(validar)
        if (validar) {
            Axios.post(Server.hostPrueba + Server.puertSSLParametrizacion + '/api/impuestos', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    this.setState({
                        response: false
                    })
                    console.log(response)
                    if (response.status === 200) {
                        swal({
                            title: "Impuesto creado correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_IMPUESTO",
                                        creacionImpuesto: false
                                    })
                                    //this.setState({loader:true,descripcionN:'',selectedDate:new Date(),selectedDateFin:new Date(),checkIPorcentaje:true,checkIValor:false,valorn:false,cambbioInicio: false,cambbioFin:false})
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }
    }

    editarImpuesto = (id) => {
        this.state.impuestosbd.map((impuesto) => {
            if (impuesto.id == id) {
                this.setState({ modoEdicion: true, createbodega: true, descripcionN: impuesto.descripcion, checkIValor: impuesto.porcentaje_valor == "$", checkIPorcentaje: impuesto.porcentaje_valor == "%", valorn: impuesto.valor + '', selectedDate: impuesto.fecha_inicio, selectedDateFin: impuesto.fecha_fin, idEditar: impuesto.id })
            }
        })
    }

    cancelar = () => {
        store.dispatch({
            type: "CREACION_IMPUESTO",
            creacionImpuesto: false
        })
    }

    actualizarDatos = () => {
        var usuario = store.getState().usuario
        var validar = this.validarFormulario()
        var datos = {
            "descripcion": this.state.descripcionN,
            "porcentaje_valor": this.state.checkIPorcentaje ? '%' : '$',
            "nivel_id": parseInt(usuario.tipo),
            "valor": this.state.valorn.length == 0 ? 0 : parseFloat(this.state.valorn),
            "fecha_inicio": this.state.cambbioInicio ? moment(new Date(this.state.selectedDate.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "fecha_fin": this.state.cambbioFin ? moment(new Date(this.state.selectedDateFin.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "empresas_id": parseInt(usuario.empresas_id, 10),
            "update_user": usuario.p_id
        }
        console.log(datos)
        if (validar) {
            Axios.put(Server.hostPrueba + Server.puertSSLParametrizacion + '/api/impuestos/' + this.state.idEditar, datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    this.setState({
                        response: false
                    })
                    console.log(response)
                    if (response.status === 200) {
                        swal({
                            title: "Impuesto actualizado correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_BODEGA",
                                        ventanaCreacionBodega: false
                                    })
                                    this.setState({ loader: true, cambbioInicio: false, cambbioFin: false })
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }

    }

    render() {
        return (
            <GridItem xs={12} sm={12} md={12} id="crearOrden" style={{ overflow: "auto", padding: '0' }}>
                <Card style={{ marginTop: "15px", paddingBottom: "15px", marginBottom: '15px' }}>
                    <CardTittle title="Registro de impuestos" style={{ marginBottom: "0", paddingBottom: "0" }} />
                    <CardContent style={{ paddingTop: "5px" }}>
                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="numordencompra" className="col-form-label">Descripcion</label>
                                <input id="numordencompra" className="form-control" autoComplete="off" type="text" value={this.state.descripcionN} onChange={this.handleChange('descripcionN')} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <FormControlLabel
                                            control={<Checkbox value={this.state.checkIPorcentaje} checked={this.state.checkIPorcentaje} style={{ padding: '5px' }} onChange={this.changeSelect('checkIPorcentaje')} />}
                                            label='Impuesto por porcentaje'
                                            style={{ margin: '0' }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <FormControlLabel
                                            control={<Checkbox value={this.state.checkIValor} checked={this.state.checkIValor} style={{ padding: '5px' }} onChange={this.changeSelect('checkIValor')} />}
                                            label='Impuesto por valor'
                                            style={{ margin: '0' }}
                                        />
                                    </div>
                                </div>


                            </div>
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">{this.state.checkIPorcentaje ? 'Porcentaje' : 'Valor'}</label>

                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">{this.state.checkIPorcentaje ? '%' : '$'}</span>
                                    </div>
                                    <input type="number" min="1" class="form-control" aria-describedby="basic-addon1" value={this.state.valorn} onChange={this.handleChange('valorn')} />
                                </div>
                            </div>
                            <div className="bottom-aligned">
                                {this.state.modoEdicion ? <button className="btn btn-success" style={{ float: "right", margin: '10px' }} disabled={this.state.response} onClick={() => this.actualizarDatos()}>Guardar</button> : <button className="btn btn-success" style={{ float: "right", margin: '10px' }} disabled={this.state.response} onClick={() => this.enviarDatos()}>Crear impuesto</button>}
                            </div>
                            <div className="bottom-aligned">
                                <button className="btn btn-danger" style={{ float: "right", margin: '10px' }} onClick={() => this.cancelar()}>Cancelar</button>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </GridItem>
        )
    }
}


export default FormularioImpuesto
