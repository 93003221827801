/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import moment from 'moment'
import "moment/locale/es";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import routes from "routes.js";
import swal from 'sweetalert'
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import store from '../lib/store'
import Axios from 'axios'
import Server from '../lib/server'
import Loader from '../components/Loader'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
//import CloseIcon from "@material-ui/icons/Close";
import CloseIcon from "@material-ui/icons/HighlightOff";
import { IconButton } from "@material-ui/core";
import logoDeuna from './logoo_r2_c2.png'
import buscar from './buscar.png'
import './style.css'
import AtrasIcon from '@material-ui/icons/Undo'
import CardDescripcion from '../components/Card/card/CardCntrlEspacio'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Footer from '../components/Footer/Footer';
import sweetalert2 from 'sweetalert2'
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
var deuna = ""
var logo = ""
var detalleDominio = ""
var routes2 = []
var modulosUsuario = []
const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (prop.submodulos === null) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          )
        } else if (prop.submodulos !== null) {
          prop.submodulos.map((sub) => {
            routes2.push(sub)
          }
          )
        }

      }

    })
    }
    {
      routes2.map((sub, key2) => {
        return (
          <Route
            path={sub.layout + sub.path}
            component={sub.component}
            key={key2}
          />
        )
      })
    }
    <Redirect from="/" to="/admin/dashboard" />
    <Redirect to="/admin/dashboard" />
  </Switch>
);
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: deuna,
      color: "red", ///firefuel es orange
      hasImage: true,
      fixedClasses: "dropdown",
      mobileOpen: false,
      autoguardado: false,
      routesbd: [],
      usuarioListo: false,
      controlEspacio: false,
      plus: [],
      ids: [],
      pluBuscar: '',
      productoBuscado: null,
      //Por descripcion
      ceDescripcion: false,
      productosxDescripcion: [],
      descripcionSeleccionada: {},
      modoDescripcion: false,
      pDescripcion: [],
      busquedaCodigo: false,
      //
      empresasbd: [],
      empresaSeleccionada: {}
    };
    store.subscribe(() => {
      this.setState({
        autoguardado: store.getState().autoguardado,
        usuarioListo: store.getState().usuaurioObtenido
      })
    })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value.toUpperCase(),
    });
  };


  setearMiles = (numero) => {
    return (
      (new Intl.NumberFormat("de-DE").format(parseInt(numero)))
    )
  }

  handleControlEspacio = () => {
    this.setState({
      controlEspacio: !this.state.controlEspacio,
      productoBuscado: null,
      pDescripcion: [],
      modoDescripcion: false
    })
  }

  componentWillMount() {
    //si deceto que el usuario es tipo 4 
    //PRegunto por las empresas hijas, y luego a la ruta 2020
    if (localStorage.getItem('token')) {
      var n = Server.hostPrueba + Server.puertoSSLPrueba + '/session'
      Axios.get(n, { headers: { Authorization: ntoken } })
        .then(response => {

          if (response.status === 200) {
            if (response.data.tipo === "4") {
              //Obtenemos los hijos
            
              var empresas = []
              Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/node/' + response.data.empresas_id + '/0', { headers: { Authorization: ntoken } })
                .then(response => {
                  if (response.status == 200) {
                    var hijos = []
                    response.data.data.map((item, key) => {
                      if (key == 0) {
                        hijos = item.toString()
                      } else {
                        hijos += ',' + item.toString()
                      }
                    })
                    Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/5/empresas/' + hijos, { headers: { Authorization: ntoken } })
                      .then(responseee => {

                        if (responseee.data != null) {
                          responseee.data.empresas.map((dato) => {
                            empresas[dato.id] = dato.alias
                          })
                          sweetalert2.fire({
                            title: 'ESCOJA EL KIOSCO',
                            text: 'ESCOJA EL KIOSCO QUE DESEA ADMINISTRAR',
                            input: 'select',
                            showCancelButton:false,
                            inputOptions: empresas,
                            allowOutsideClick:false,
                            inputPlaceholder: 'Escoje el kiosco',
                             inputValidator: (value) => {
                              return new Promise((resolve) => {
                                if (value != '') {
                                // console.log(value)
                                 Axios.get(Server.hostPrueba + Server.puertoEmpleado + '/api/autenticacion/session/'+value, { headers: { Authorization: ntoken } })
                                 .then(response => {
                                   //console.log(response)
                                   if (response.status === 200) {
                                    store.dispatch({
                                      type: "OBTENCION_CREDENCIALES",
                                      usuario: response.data.data,
                                    })
                                    this.obtencionDatosUsuarios(1)
                                    resolve()
                                   }
                                 })
                                } else {
                                  resolve('Necesitas seleccionar un kiosco')
                                }
                              })
                            }
                          })
                        } else if (responseee.status == 205) {
                          swal("ERROR", "LA SESION HA CADUCADO", "warning")
                          setTimeout(function () { window.location.href = '/'; }, 3000);
                        }
                      })
                      .catch(function (error) {
                        // handle error
                        console.error(error);
                      })
                  }
                })
                .catch(function (error) {
                  console.log(error);
                })

            } else {
              store.dispatch({
                type: "OBTENCION_CREDENCIALES",
                usuario: response.data,
              })
              this.obtencionDatosUsuarios(2)
            }
          }
        })

    }
  }

  obtencionDatosUsuarios = (t) => {
    //Obtencion de nodulos
    var jsonUsuario = store.getState().usuario
    var moduloss = []
    Axios.get(Server.hostPrueba + Server.puertoSSLPrueba + '/usuarios/' +(t === 2 ? jsonUsuario.empresas_id : jsonUsuario.empresas_id_usuarios )+ '/' + jsonUsuario.id, { headers: { Authorization: ntoken } })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.perfiles != null) {
            response.data.perfiles.map((perfiles) => {
              if (perfiles.modulos != null) {
                perfiles.modulos.map((modulo) => {
                  //console.log(modulo)
                  if (modulo.acciones != "") {
                    moduloss.push(modulo)
                  }
                })

              }
            })
          }
          this.setState({ routesbd: moduloss })
          store.dispatch({
            type: "OBTENCION_PERFILES",
            perfilesUSuario: this.state.routesbd
          })
          //Obtencion de empresas padres
          var hijos = ''
          var padres = ''
          var usuario = store.getState().usuario
          Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/node/' + usuario.empresas_id + '/1', { headers: { Authorization: ntoken } })
            .then(response => {
              if (response.status == 200) {
                //console.log(response)
                response.data.data.map((item, key) => {
                  if (key == 0) {
                    padres = item.toString()
                  } else {
                    padres += ',' + item.toString()
                  }
                })
                store.dispatch({
                  type: "OBTENCION_JERARQUIA_PADRES",
                  empresasPadres: padres
                })
              }
            })
            .catch(function (error) {
              console.log(error);
            })
          //Obtencion de empresas hijas
          Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/node/' + usuario.empresas_id + '/0', { headers: { Authorization: ntoken } })
            .then(response => {
              if (response.status == 200) {
                response.data.data.map((item, key) => {
                  if (key == 0) {
                    hijos = item.toString()
                  } else {
                    hijos += ',' + item.toString()
                  }
                })
                store.dispatch({
                  type: "OBTENCION_JERARQUIA_HIJOS",
                  empresasHijos: hijos,
                  usuaurioObtenido: true
                })
              }
            })
            .catch(function (error) {
              console.log(error);
            })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/admin/maps";
  }
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };
  keyBuscar = (e) => {
    if (e.which === 32 && e.ctrlKey) {
      this.setState({
        controlEspacio: true,
      })
      document.getElementById("search").focus()
      e.preventDefault()
    } else if (e.key === 'Escape') {
      //this.limpiar()
    }
  }
  componentDidMount() {
    //console.log(store.getState().autoguardado)
    document.addEventListener('keydown', this.keyBuscar);
    if (this.state.autoguardado) {
      //console.log(store.getState())
    } else {
      // console.log(store.getState())
    }
    if (navigator.platform.indexOf("Win") > -1) {
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  buscarPlu = () => {
    var actual = this.state.pluBuscar
    var dominio = store.getState().dominio
    var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
    if (this.state.busquedaCodigo) {
      var datos = {
        "empresas_id": dominio,
        "accion": "identificador",
        "data": actual
      }
      Axios.post(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/web/detalles/by', datos, { headers: { Authorization: ntoken } })
        .then(responsee => {
          console.log(responsee.data.data)
          this.setState({
            productoBuscado: responsee.data.data[0],
            pDescripcion: [],
            modoDescripcion: false
          })

        })
        .catch(function (error) {
          // handle error
          console.error(error);
        })
    } else if (isNaN(actual)) {
      if (actual.length <= 3) {
        swal('SI VA A BUSCAR POR DESCRIPCION, POR FAVOR ESCRIBA MINIMO 4 LETRAS', '', 'warning')
      } else {
        var datos = {
          "empresas_id": dominio,
          "accion": "desc",
          "data": actual
        }
        Axios.post(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/web/detalles/by', datos, { headers: { Authorization: ntoken } })
          .then(responsee => {
            console.log(responsee.data.data)
            if (responsee.status === 200) {
              this.setState({
                modoDescripcion: true,
                pDescripcion: responsee.data.data
              })
            }
            //this.setState({ productoBuscado: responsee.data.data[0] })

          })
          .catch(function (error) {
            // handle error
            console.error(error);
          })
      }
    } else {
      var datos = {
        "empresas_id": dominio,
        "accion": "plu",
        "data": actual
      }
      Axios.post(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/web/detalles/by', datos, { headers: { Authorization: ntoken } })
        .then(responsee => {
          console.log(responsee.data.data)
          this.setState({
            productoBuscado: responsee.data.data[0],
            pDescripcion: [],
            modoDescripcion: false
          })

        })
        .catch(function (error) {
          // handle error
          console.error(error);
        })
    }

  }

  seleccionarDescripcion = (producto) => {
    this.setState({
      modoDescripcion: false,
      productoBuscado: producto
    })
  }

  atras = () => {
    this.setState({
      modoDescripcion: true,
      productoBuscado: null
    })
  }

  keyBuscarPlu = (e) => {
    if (e.key === 'Enter') {
      this.buscarPlu()
    }
  }
  changeSelect = () => {
    this.setState({
      busquedaCodigo: !this.state.busquedaCodigo
    })
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
    document.removeEventListener('keydown', this.keyBuscar);
  }
  render() {
    var mod = this.state.routesbd
    var ids = []
    var productoe = this.state.productoBuscado
    var dominio = store.getState().dominio
    deuna = Server.hostMultimedia + dominio + '/pagina/sidebar.jpg'
    logo = Server.hostMultimedia + dominio + '/pagina/logoSidebar.png'
    detalleDominio = store.getState().detalleDominio
    var imagenProducto = Server.hostMultimedia + dominio + "/productos/default.png"
    if (productoe != null) {
      if (productoe.producto != null) {
        imagenProducto = Server.hostMultimedia + dominio + "/productos/" + (productoe.producto.url_foto == 'N' ? 'default.png' : productoe.producto.id + '.png') + "?" + moment(new Date()).format("YYYYMMMDDHHmmss")
      }
    }
    modulosUsuario = routes
    if (mod.length > 0) {
      mod.map((modulo) => {
        ids.push(modulo.modulos_id)
      })
      if (ids.length > 0) {
        routes.map((ruta, key) => {
          if (ruta.submodulos == null) {
            if (!ids.includes(ruta.id)) {
              modulosUsuario.splice(key, 1)
            } else {
              mod.map((modulo, key) => {
                if (modulo.modulos_id == ruta.id) {
                  if (modulosUsuario[key] != undefined) {
                    modulosUsuario[key].permisos = modulo.acciones
                  }
                }
              })
            }
          } else {
            ruta.submodulos.map((subruta, llave) => {
              if (!ids.includes(subruta.id)) {
                modulosUsuario[key].submodulos.splice(llave, 1)
              } else {
                mod.map((modulo) => {
                  if (modulo.modulos_id == subruta.id) {
                    if (modulosUsuario[key] != undefined) {
                      modulosUsuario[key].submodulos[llave].permisos = modulo.acciones
                    }
                  }
                })
              }
            })
          }
        })
      }
    } else {
      modulosUsuario = []
    }
    var mt = modulosUsuario
    mt.map((modul, key) => {
      //console.log(modul)
      if (modul.submodulos != null && modul.submodulos.length == 0) {
        mt.splice(key, 1)
      }
    })
    modulosUsuario = mt
    const { classes, ...rest } = this.props;
    return (
      <div id="prueba" className={classes.wrapper}>

        <Dialog
          open={this.state.controlEspacio}
          onClose={this.handleControlEspacio}
          aria-labelledby="form-dialog-title"
          maxWidth={'md'}
          disableBackdropClick={true}
        >
          <DialogTitle id="form-dialog-title" style={{ padding: '0' }}>
            <IconButton style={{ float: 'right' }} onClick={this.handleControlEspacio}><CloseIcon color="error" /></IconButton>
          </DialogTitle>
          <DialogContent >

            <table width="612px" border="0" align="center" cellpadding="0" cellspacing="0">
              <tr>
                <td>
                  <table width="610" border="0" cellpadding="0" cellspacing="0">
                    <tr>
                      <td width="123"><img src={logoDeuna} width="113" height="114" alt="" /></td>
                      <td align="center"><table className="rounded" width="95%" height="80" border="0" cellpadding="0" cellspacing="0">
                        <tr>
                          <td className="box rounded"><table width="95%" border="0" cellspacing="0" cellpadding="5">
                            <tr>
                              <td align="center">
                                <b font className="fontipo2">DIGITE EL PLU DEL PRODUCTO</b>
                              </td>
                            </tr>
                          </table>
                            <table width="95%" border="0" cellspacing="0" cellpadding="5">
                              <tr>
                                <td width="84%" className="tile">
                                  <center>
                                    <input type="search" autoComplete="off" name="search" onKeyDown={this.keyBuscarPlu} id="search" className="search mx-auto numero2" value={this.state.pluBuscar} onChange={this.handleChange('pluBuscar')} />
                                    <FormControlLabel
                                      control={<Checkbox value={this.state.busquedaCodigo} checked={this.state.busquedaCodigo} onChange={() => this.changeSelect()} />}
                                      label='CODIGO DE BARRA'
                                      style={{ margin: '0' }}
                                    />
                                  </center>
                                </td>
                                <td width="16%" className="tile"><img src={buscar} onClick={() => this.buscarPlu()} style={{ cursor: 'pointer' }} width="60" height="60" alt="" /></td>
                              </tr>
                            </table></td>
                        </tr>
                      </table></td>
                    </tr>
                  </table>
                  <table width="100%" border="0" cellspacing="4" cellpadding="0">
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </table>
                  {this.state.modoDescripcion
                    ?
                    null
                    :
                    <div className="row">
                      {
                        this.state.pDescripcion.length > 0
                          ?
                          <button className="btn btn-sm btn-danger" style={{ margin: '10px' }} onClick={this.atras.bind(this)}><AtrasIcon /></button>
                          :
                          null
                      }
                    </div>

                  }
                  {this.state.modoDescripcion
                    ?
                    <div className="row">
                      {
                        this.state.pDescripcion != null
                          ?
                          this.state.pDescripcion.map((proucton) => {
                            return (
                              <div className="col-md-6" onClick={() => this.seleccionarDescripcion(proucton)} style={{ marginTop: '5px' }}>
                                <CardDescripcion infoProducto={proucton.producto} />
                              </div>
                            )
                          })
                          :
                          null
                      }
                    </div>
                    :
                    productoe != null ? JSON.stringify(productoe) === '{}' ? <h3 className="mx-auto" style={{ color: 'red' }}><b>ESTE PLU NO EXISTE</b></h3> : <table width="100%" border="0" cellspacing="0" cellpadding="10">
                      <tr>
                        <td className="box2"><table width="610" border="0" cellpadding="0" cellspacing="3">
                          <tr>
                            <td width="172" valign="top">
                              <img src={imagenProducto} width="166" height="172" alt="" />
                            </td>
                            <td width="14" valign="top"></td>
                            <td width="412" valign="top">
                              <table width="100%" border="0" cellspacing="0" cellpadding="5" className="box2">
                                <tr>
                                  <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>PLU:</td>
                                  <td width="60%" style={{ textAlign: 'left' }}>{productoe.producto.plu}</td>
                                </tr>
                                <tr>
                                  <td className="fontipo" style={{ textAlign: 'left' }}>Nombre del Producto:</td>
                                  <td style={{ textAlign: 'left' }}>{productoe.producto.descripcion}</td>
                                </tr>
                                {productoe.identificadores != null ?
                                  productoe.identificadores.map((identificador, k) => {
                                    return (
                                      <tr>
                                        <td className="fontipo" style={{ textAlign: 'left' }}>Codigo dde barras ({(k + 1) + "):"}</td>
                                        <td style={{ textAlign: 'left' }}>{identificador.identificador}</td>
                                      </tr>
                                    )

                                  })
                                  :
                                  console.log("No tiene identificadores")
                                }
                                <tr>
                                  <td className="fontipo" style={{ textAlign: 'left' }}>Tipo:</td>
                                  <td style={{ textAlign: 'left' }}>{productoe.producto.tipo_desc}</td>
                                </tr>
                                <tr>
                                  <td className="fontipo" style={{ textAlign: 'left' }}>Se puede vender</td>
                                  <td style={{ textAlign: 'left' }}>{productoe.producto.puede_vender === 'S' ? 'SI' : 'NO'}</td>
                                </tr>
                                <tr>
                                  <td className="fontipo" style={{ textAlign: 'left' }}>Precio:</td>
                                  <td style={{ textAlign: 'left' }}>{'$' + this.setearMiles(parseInt(productoe.producto.precio))}</td>
                                </tr>
                                <tr>
                                  <td className="fontipo" style={{ textAlign: 'left' }}>Unid. de Medida:</td>
                                  <td style={{ textAlign: 'left' }}>{productoe.producto.unidades_medida}</td>
                                </tr>
                                <tr>
                                  <td className="fontipo" style={{ textAlign: 'left' }}>Unid. de Embalaje:</td>
                                  <td style={{ textAlign: 'left' }}>{productoe.producto.unidades_contenido}</td>
                                </tr>
                                <tr>
                                  <td className="fontipo" style={{ textAlign: 'left' }}>Valor de Embalaje.</td>
                                  <td style={{ textAlign: 'left' }}>{productoe.producto.unidades_contenido_valor}</td>
                                </tr>

                              </table>

                              <table width="100%" border="0" cellspacing="4" cellpadding="0">
                                <tr>
                                  <td>&nbsp;</td>
                                </tr>
                              </table>
                              {
                                productoe.productos_impuestos != null
                                  ?
                                  productoe.productos_impuestos.map((imp, key) => {
                                    return (
                                      <table width="100%" border="0" cellspacing="0" cellpadding="5" className="box2">
                                        <tr>
                                          <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>{'Impuesto de venta (' + (key + 1) + ') :'}</td>
                                          <td width="60%" style={{ textAlign: 'left' }}>{imp.descripcion}</td>
                                        </tr>

                                        <tr>
                                          <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>Valor</td>
                                          <td width="60%" style={{ textAlign: 'left' }}>{imp.porcentaje_valor === "$" ? '$' + this.setearMiles(parseFloat(imp.valor).toFixed(3)) : imp.valor + "%"}</td>
                                        </tr>
                                      </table>
                                    )
                                  })
                                  :
                                  <table width="100%" border="0" cellspacing="0" cellpadding="5" className="box2">
                                    <tr>
                                      <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>Impuestos:</td>
                                      <td width="60%" style={{ textAlign: 'left' }}>NINGUN Impuesto</td>
                                    </tr>
                                  </table>
                              }
                              {
                                productoe.ingredientes != null
                                  ?
                                  <table width="100%" border="0" cellspacing="4" cellpadding="0">
                                    <tr>
                                      <td>&nbsp;</td>
                                    </tr>
                                  </table>
                                  : null}


                              {
                                productoe.ingredientes != null
                                  ?
                                  productoe.ingredientes.map((ingrediente, keyc) => {
                                    return (
                                      <table width="100%" border="0" cellspacing="0" cellpadding="5" className="box2">
                                        <tr>
                                          <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>Ingrediente{' (' + (keyc + 1) + ') '}:</td>
                                          <td width="60%" style={{ textAlign: 'left' }}>{ingrediente.descripcion}</td>
                                        </tr>
                                        <tr>
                                          <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>Cantidad:</td>
                                          <td width="60%" style={{ textAlign: 'left' }}>{ingrediente.cantidad + " " + ingrediente.unidad_basica_desc}</td>
                                        </tr>
                                      </table>
                                    )
                                  })
                                  :
                                  <table width="100%" border="0" cellspacing="0" cellpadding="5" className="box2">
                                    <tr>
                                      <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>Ingredientes:</td>
                                      <td width="60%" style={{ textAlign: 'left' }}>{"Ningun ingrediente registrado"}</td>
                                    </tr>
                                  </table>

                              }


                              <table width="100%" border="0" cellspacing="4" cellpadding="0">
                                <tr>
                                  <td>&nbsp;</td>
                                </tr>
                              </table>
                              <table width="100%" border="0" cellspacing="0" cellpadding="5" className="box2">
                                {
                                  productoe.categorias != null
                                    ?
                                    productoe.categorias.map((categoria, keyc) => {
                                      return (
                                        <tr>
                                          <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>Categoria{' (' + (keyc + 1) + ') '}:</td>
                                          <td width="60%" style={{ textAlign: 'left' }}>{categoria.grupo}</td>
                                        </tr>
                                      )
                                    })
                                    :
                                    <tr>
                                      <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>Categoria:</td>
                                      <td width="60%" style={{ textAlign: 'left' }}>{"Ninguna categoria registrado"}</td>
                                    </tr>
                                }

                              </table>
                              <table width="100%" border="0" cellspacing="4" cellpadding="0">
                                <tr>
                                  <td>&nbsp;</td>
                                </tr>
                              </table>
                              <table width="100%" border="0" cellspacing="0" cellpadding="5" className="box2">
                                {
                                  productoe.proveedores != null
                                    ?
                                    productoe.proveedores.map((proveedor, keyp) => {
                                      return (
                                        <tr>
                                          <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>Proveedor{' (' + (keyp + 1) + ') '}:</td>
                                          <td width="60%" style={{ textAlign: 'left' }}>{proveedor.proveedor_nombre}</td>
                                        </tr>
                                      )
                                    })
                                    :
                                    <tr>
                                      <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>Proveedor:</td>
                                      <td width="60%" style={{ textAlign: 'left' }}>{"Ningun proveedor registrado"}</td>
                                    </tr>
                                }

                              </table>
                              <table width="100%" border="0" cellspacing="4" cellpadding="0">
                                <tr>
                                  <td>&nbsp;</td>
                                </tr>
                              </table>
                              {
                                productoe.acuerdos_productos != null
                                  ?
                                  productoe.acuerdos_productos.map((acuerdo, key) => {
                                    return (
                                      <table width="100%" border="0" cellspacing="0" cellpadding="5" className="box2">
                                        <tr>
                                          <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>{'Acuerdo (' + (key + 1) + ') :'}</td>
                                          <td width="60%" style={{ textAlign: 'left' }}>{acuerdo.contratos.descripcion}</td>
                                        </tr>
                                        <tr>
                                          <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>Proveedor</td>
                                          <td width="60%" style={{ textAlign: 'left' }}>{acuerdo.acuerdo_proveedor.nombres}</td>
                                        </tr>
                                        <tr>
                                          <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>Validez</td>
                                          <td width="60%" style={{ textAlign: 'left' }}>{moment(acuerdo.contratos.fecha_inicio).format("YYYY/MM/DD") + " - " + moment(acuerdo.contratos.fecha_fin).format("YYYY/MM/DD")}</td>
                                        </tr>
                                        <tr>
                                          <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>Costo</td>
                                          <td width="60%" style={{ textAlign: 'left' }}>{'$' + this.setearMiles(parseFloat(acuerdo.contratos_productos.precio_base).toFixed(3))}</td>
                                        </tr>
                                      </table>
                                    )
                                  })
                                  :
                                  <table width="100%" border="0" cellspacing="0" cellpadding="5" className="box2">
                                    <tr>
                                      <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>Acuerdo:</td>
                                      <td width="60%" style={{ textAlign: 'left' }}>NINGUN ACUERDO</td>
                                    </tr>
                                  </table>
                              }
                              <table width="100%" border="0" cellspacing="4" cellpadding="0">
                                <tr>
                                  <td>&nbsp;</td>
                                </tr>
                              </table>
                              {
                                productoe.tiendas != null
                                  ?
                                  productoe.tiendas.map((tienda, key) => {
                                    return (
                                      <table width="100%" border="0" cellspacing="0" cellpadding="5" className="box2">
                                        <tr>
                                          <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>{'Tienda (' + (key + 1) + ') :'}</td>
                                          <td width="60%" style={{ textAlign: 'left' }}>{tienda.razon_social}</td>
                                        </tr>
                                        <tr>
                                          <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>Precio</td>
                                          <td width="60%" style={{ textAlign: 'left' }}>{'$' + this.setearMiles(parseFloat(tienda.precio).toFixed(3))}</td>
                                        </tr>
                                      </table>
                                    )
                                  })
                                  :
                                  <table width="100%" border="0" cellspacing="0" cellpadding="5" className="box2">
                                    <tr>
                                      <td width="40%" className="fontipo" style={{ textAlign: 'left' }}>Acuerdo:</td>
                                      <td width="60%" style={{ textAlign: 'left' }}>NINGUN ACUERDO</td>
                                    </tr>
                                  </table>
                              }
                            </td>
                          </tr>
                        </table>
                        </td>
                      </tr>
                    </table>
                      :
                      null
                  }
                </td>
              </tr>
            </table>

          </DialogContent>
        </Dialog>
        <Sidebar
          modulosbd={this.state.routesbd}
          routes={modulosUsuario}
          logoText={detalleDominio}
          logo={logo}
          image={deuna}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel">
          <Navbar
            routes={modulosUsuario}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          <div className={classes.content} style={{ marginTop: '35px' }}>
            <div className={classes.container} >{this.state.usuarioListo ? switchRoutes : <Loader />}</div>
          </div>


          {this.getRoute() ? <Footer /> : null}
          <FixedPlugin
            handleImageClick={this.handleImageClick}
            handleColorClick={this.handleColorClick}
            bgColor={this.state["color"]}
            bgImage={this.state["image"]}
            handleFixedClick={this.handleFixedClick}
            fixedClasses={this.state.fixedClasses}
          />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
