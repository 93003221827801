import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import 'bootstrap/dist/css/bootstrap.min.css';
import CardTittle from '@material-ui/core/CardHeader'
import GridItem from "components/Grid/GridItem.jsx";
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Server from '../../lib/server'
import store from '../../lib/store'
import Axios from 'axios'
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import swal from 'sweetalert'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import Selects from 'react-select';
import Tiendas from "@material-ui/icons/StoreMallDirectory"
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';
import CloseIcon from "@material-ui/icons/Close";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import Avatar from '@material-ui/core/Avatar';
import { Button } from 'react-bootstrap'
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogTelefono from './dialogs/dialogTelefono'
import DialogCorreo from './dialogs/dialogCorreo'
import FormularioGeografia from './FormularioGeografia';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MailIcon from '@material-ui/icons/Mail'
import TelefonoIcon from '@material-ui/icons/Phone'
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import moment from 'moment';
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}
var colorDominio = store.getState().colorDominio
var impuestoEditar = false
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class FormularioEmpresa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkSucursal: false,
            checkTienda: true,
            checkPadre: false,
            idEditar: 0,
            address: '',
            //DAtos a guardar
            razonSocialN: '',
            aliasN: '',
            nitN: '',
            codigoN: '',
            dominioN: '',
            modoEditar: false,
            empresaSeleccionada: {},
            paisSeleccionada: {},
            departamentoSeleccionada: {},
            ciudadSeleccionada: {},
            centerDireccion: { lat: 10.9888859, lng: -74.82026589999998 },
            //Datos bd
            empresasbd: [],
            paisbd: [],
            departamentobd: [],
            ciudadbd: [],
            crearGeografia: false,
            tipoGeografia: 0,
            actualizarDatos: false,
            //Vectores
            telefonos: [],
            dialogTelefono: false,
            corrreos: [],
            dialogCorreo: false,
            dialogTiendaCopia: false,
            tiendasbd: [],
            cabecera: '',
            footer: '',
            idDescriptor: '',
            response: false,
            //copia
            productosEmpresasCopias: [],
            contratosEmpresasCopias: [],
            pasoCopiaEmpresas: 1,
            empresaSeleccionadaNombre: '',

        };
        store.subscribe(() => {
            this.setState({
                actualizarDatos: store.getState().actualizarvista,
                crearGeografia: store.getState().creacionGeografia,
                telefonos: store.getState().telefonos,
                dialogTelefono: store.getState().creacionTelefono,
                corrreos: store.getState().correos,
                dialogCorreo: store.getState().creacionCorreo,
            })
        })

    }


    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizarDatos) {
            this.obtenerActualizados()
            return true
        }
        return true;
    }
    crearGeografia = (tipo) => {
        this.setState({ tipoGeografia: tipo })
        store.dispatch({
            type: "CREACION_GEOGRAFIA",
            creacionGeografia: true
        })
    }

    obtenerActualizados = () => {
        //paises
        Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/paises/', { headers: { Authorization: ntoken } })
            .then(responsee => {
                var provincias = []
                console.log(responsee.data)
                responsee.data.map((provincia, key) => (
                    provincias.push({
                        value: provincia.id,
                        label: provincia.descripcion,
                        key: key
                    })
                ))
                this.setState({ paisbd: provincias })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    obtenerDatos() {
        this.setState({ actualizarDatos: false })
        //EMPRESAS
        var usuario = store.getState().usuario
        console.log(store.getState().empresasHijos)
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/4,5/padre/' + store.getState().empresasHijos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var provincias = []
                var tiendas = []
                console.log(responsee)
                if (responsee.status == 200) {
                    responsee.data.empresas.map((provincia, key) => {
                        if (provincia.empresas_tipos_id === "4") {
                            provincias.push({
                                value: provincia.id,
                                label: provincia.razon_social + " -- " + provincia.nit,
                                key: key
                            })
                        } else {
                            tiendas.push(provincia)
                        }

                    })
                    console.log(provincias)
                    this.setState({ empresasbd: provincias, tiendasbd: tiendas })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }


            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }


    componentWillMount() {
        this.obtenerDatos()
        this.obtenerActualizados()
        this.update()

    }

    handleChanges = name => value => {

        this.setState({
            [name]: value,
        });
        switch (name) {
            case 'paisSeleccionada':
                console.log(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/provincias/pais/' + value.value)
                Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/provincias/pais/' + value.value, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        var provincias = []
                        console.log(responsee.data)
                        responsee.data.map((provincia, key) => (
                            provincias.push({
                                value: provincia.provincia_id,
                                label: provincia.provincia,
                                key: key
                            })
                        ))
                        //console.log(provincias)
                        this.setState({ departamentobd: provincias })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })

                break
            case 'departamentoSeleccionada':
                var ciudades = []
                Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/provincias/ciudades/' + value.value, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee.data)
                        responsee.data.map((dato, key) => (
                            ciudades.push({
                                value: dato.ciudades_id,
                                label: dato.ciudades,
                                key: key
                            })
                        ))
                        this.setState({ ciudadbd: ciudades })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break
        }
    }

    handledialogTelefono = () => {
        store.dispatch({
            type: "AGREGAR_TELEFONO_USUARIO",
            telefonos: this.state.telefonos,
            creacionTelefono: !this.state.dialogTelefono
        })
        store.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: false
        })
        //this.setState({ dialogTelefono: !this.state.dialogTelefono });
    }
    handledialogCorreo = () => {
        store.dispatch({
            type: "AGREGAR_CORREO_USUARIO",
            correos: this.state.corrreos,
            creacionCorreo: !this.state.dialogCorreo
        })
        store.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: false
        })
        //this.setState({ dialogCorreo: !this.state.dialogCorreo });
    }
    handledialogCopia = () => {
        this.setState({
            dialogTiendaCopia: !this.state.dialogTiendaCopia
        });
    }


    handleChange = address => {
        this.setState({ address });
    };
    handleChangeT = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };

    handleSelect = address => {

        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                console.log('Success', latLng)
                this.setState({ centerDireccion: latLng, zoom: 16, address: address })
            })
            .catch(error => console.error('Error', error));
    };

    onMarkerDragEnd = (coord) => {
        const { latLng } = coord;
        const latn = latLng.lat();
        const lngn = latLng.lng();

        console.log(latn + "-" + lngn)
        this.setState({
            centerDireccion: {
                lat: latn,
                lng: lngn
            }
        })
    };

    changeSelect = name => event => {
        var estado = (event.target.value == 'false' ? false : true)
        this.setState({
            [name]: !estado
        });
        switch (name) {
            case 'checkSucursal':
                if (!estado) {
                    this.setState({ checkTienda: false })
                } else {
                    this.setState({ checkTienda: true })
                }
                break
            case 'checkTienda':
                if (!estado) {
                    this.setState({ checkSucursal: false, checkPadre: true })
                } else {
                    this.setState({ checkSucursal: true, checkPadre: false })
                }
                break
        }
    }

    obtenerCopia = (id, nombre) => {
        console.log("id")
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/convenios/getAll/' + id + '/modelo', { headers: { Authorization: ntoken } })
            .then(responsee => {
                console.log(responsee.data)
                console.log(responsee)
                var contratos = []
                var productos = []
                if (responsee.status === 200) {
                    if (responsee.data.success) {
                        contratos = responsee.data.contratos
                        productos = responsee.data.productos
                    }
                }
                this.setState({
                    contratosEmpresasCopias: contratos,
                    productosEmpresasCopias: productos,
                    pasoCopiaEmpresas: 2,
                    empresaSeleccionadaNombre: nombre

                })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    obtenerDireccion = () => {
        this.setState({ dialogDireccion: !this.state.dialogDireccion })
    }

    eliminarProductoCopia = (pos) => {
        var mproductos = this.state.productosEmpresasCopias
        mproductos.splice(pos, 1)
        this.setState({
            productosEmpresasCopias: mproductos
        })
    }

    eliminarAcuerdoCopia = (pos) => {
        var macuerdo = this.state.contratosEmpresasCopias
        macuerdo.splice(pos, 1)
        this.setState({
            contratosEmpresasCopias: macuerdo
        })
    }

    siguiente = (paso) => {
        if (paso === 2) {
            this.setState({
                pasoCopiaEmpresas: 3
            })
        } else {
            this.setState({
                pasoCopiaEmpresas: 4,
                dialogTiendaCopia: false
            })
        }
    }
    ANTERIOR = (paso) => {
        if (paso === 2) {
            this.setState({
                pasoCopiaEmpresas: 1
            })
        } else if (paso === 3) {
            this.setState({
                pasoCopiaEmpresas: 2
            })
        } else {
            this.setState({
                empresaSeleccionadaNombre: '',
                productosEmpresasCopias: [],
                contratosEmpresasCopias: [],
                dialogTiendaCopia: false
            })
        }
    }


    update() {
        const { empresaDetalles } = store.getState()
        console.log(empresaDetalles)
        if (empresaDetalles != null) {
            var direccion = {}
            var tel = []
            var mails = []
            if (empresaDetalles.contactos != null) {
                empresaDetalles.contactos.map((contacto, key) => {
                    if (contacto != null) {
                        switch (contacto.tipo) {
                            case 1:
                                mails.push({
                                    id: contacto.id,
                                    etiqueta: contacto.etiqueta,
                                    correo: contacto.contacto,
                                    principal: contacto.principal,
                                    estado: "A"
                                })
                                break;
                            case 2:
                                tel.push({
                                    id: contacto.id,
                                    etiqueta: contacto.etiqueta,
                                    telefono: contacto.contacto,
                                    principal: contacto.principal,
                                    estado: "A"
                                })
                                break;
                            case 3:
                                direccion = contacto
                                break;
                        }
                    }
                })
                store.dispatch({
                    type: "AGREGAR_TELEFONO_USUARIO",
                    telefonos: tel,
                    creacionTelefono: false
                })
                store.dispatch({
                    type: "AGREGAR_CORREO_USUARIO",
                    correos: mails,
                    creacionCorreo: false
                })
            }
            var descriptores = {
                header: '',
                footer: ''
            }
            if (empresaDetalles.descriptores != null) {
                descriptores = empresaDetalles.descriptores
            }
            this.setState({
                idEditar: empresaDetalles.id,
                checkSucursal: empresaDetalles.empresas_tipos_id === "4",
                checkTienda: empresaDetalles.empresas_tipos_id === "5",
                razonSocialN: empresaDetalles.razon_social,
                aliasN: empresaDetalles.alias != null ? empresaDetalles.alias : '',
                nitN: empresaDetalles.nit,
                checkPadre: empresaDetalles.empresas_tipos_id === "5",
                codigoN: empresaDetalles.codigo,
                empresaSeleccionada: {
                    value: empresaDetalles.parent.id,
                    label: empresaDetalles.parent.razon_social + " -- " + empresaDetalles.parent.nit,
                },
                address: direccion.contacto,
                telefonos: tel,
                corrreos: mails,
                cabecera: descriptores.header,
                footer: descriptores.footer,
                idDescriptor: descriptores.id,
                centerDireccion: { lat: empresaDetalles.localizacion.split(",")[0], lng: empresaDetalles.localizacion.split(",")[1] },
                paisSeleccionada: {
                    value: empresaDetalles.pais.id,
                    label: empresaDetalles.pais.descripcion,
                },
                departamentoSeleccionada: {
                    value: empresaDetalles.provincia.id,
                    label: empresaDetalles.provincia.descripcion,
                },
                ciudadSeleccionada: {
                    value: empresaDetalles.ciudad.id,
                    label: empresaDetalles.ciudad.descripcion,
                }
            });

        }
    }


    validarFormulario = () => {
        console.log(this.state)
        if (this.state.razonSocialN.trim == "" || this.state.razonSocialN.length == 0) {
            swal("FALLO AL CREAR", "LA RAZON SOCIAL ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.checkTienda && this.state.aliasN.trim == "") {
            swal("FALLO AL CREAR", "EL ALIAS ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.checkTienda && (this.state.nitN.trim == "" || this.state.nitN.length == 0)) {
            swal("FALLO AL CREAR", "EL NIT ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.checkTienda && (this.state.address.trim == "" || this.state.address.length == 0)) {
            swal("FALLO AL CREAR", "LA DIRECCION ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.checkTienda && this.state.cabecera.trim == "") {
            swal("FALLO AL CREAR", "LA CABECERA ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.checkTienda && this.state.footer.trim == "") {
            swal("FALLO AL CREAR", "EL PIE DE PAGINA ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.checkTienda && this.state.codigoN.trim == "") {
            swal("FALLO AL CREAR", "EL CODIGO DE TIENDA ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.checkPadre && JSON.stringify(this.state.empresaSeleccionada) == '{}') {
            swal("FALLO AL CREAR", "POR FAVOR ESCOJA EL GRUPO PADRE", "warning")
            return false
        } else if (JSON.stringify(this.state.paisSeleccionada) == '{}') {
            swal("FALLO AL CREAR", "POR FAVOR ESCOJA EL PAIS", "warning")
            return false
        } else if (JSON.stringify(this.state.departamentoSeleccionada) == '{}') {
            swal("FALLO AL CREAR", "POR FAVOR ESCOJA EL DEPARTAMENTO", "warning")
            return false
        } else if (JSON.stringify(this.state.ciudadSeleccionada) == '{}') {
            swal("FALLO AL CREAR", "POR FAVOR ESCOJA LA CIUDAD", "warning")
            return false
        } else if (!this.state.checkSucursal & this.state.telefonos.length == 0) {
            swal("FALLO AL CREAR", "POR LO MENOS DEBES REGISTRAR UN TELEFONO", "warning")
            return false
        } else if (!this.state.checkSucursal & this.state.corrreos.length == 0) {
            swal("FALLO AL CREAR", "POR LO MENOS DEBES REGISTRAR UN CORREO", "warning")
            return false
        } else {
            this.setState({
                response: true
            })
            return true
        }

    }

    //Funcion para setear el precio
    setearMiles = (numero) => {
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case "COP":
                return (
                    (new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(2)))
                )
                break;
            case "USD":
                return (new Intl.NumberFormat("en-IN").format(parseFloat(numero).toFixed(3)))
                break;

        }
    }

    crearEmpresa = () => {
        var usuario = store.getState().usuario
        var contactos = []
        var validar = this.validarFormulario()
        this.state.telefonos.map((telefono, key) => {
            contactos.push({
                "tipo": 2,
                "etiqueta": telefono.etiqueta,
                "contacto": telefono.telefono,
                "principal": key == 0 ? 'S' : 'N'
            })
        })
        this.state.corrreos.map((correo, key) => {
            contactos.push({
                "tipo": 1,
                "etiqueta": correo.etiqueta,
                "contacto": correo.correo,
                "principal": key == 0 ? 'S' : 'N'
            })
        })
        contactos.push({
            "tipo": 3,
            "etiqueta": 'PRINCIPAL',
            "contacto": this.state.address,
            "principal": 'S'
        })
        var datos = {
            "razon_social": this.state.razonSocialN,
            "alias": this.state.checkSucursal ? this.state.razonSocialN : this.state.aliasN,
            "nit": this.state.nitN,
            "cantidad_sucursales": 1,
            "empresas_tipos_id": this.state.checkSucursal ? 4 : 5,
            "empresas_id": this.state.checkSucursal ? this.state.checkPadre ? this.state.empresaSeleccionada.value : parseInt(usuario.empresas_id) : this.state.empresaSeleccionada.value,
            "ciudades_id": this.state.ciudadSeleccionada.value,
            "url_foto": 1,
            "localizacion": this.state.centerDireccion.lat + "," + this.state.centerDireccion.lng,
            "create_user": usuario.id,
            "dominio": '',
            "codigo": this.state.checkTienda ? this.state.codigoN : null,
            "create_user": usuario.p_id,
            "descriptor": {
                "header": this.state.cabecera,
                "footer": this.state.footer,
                "update_user": usuario.p_id,
            },
            "contactos": contactos
        }
        console.log(datos)
        if (validar) {
            Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/add/', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    console.log(response)
                    this.setState({
                        response: false
                    })
                    if (response.status === 201) {
                        store.dispatch({
                            type: "AGREGAR_TELEFONO_USUARIO",
                            telefonos: [],
                            creacionTelefono: false
                        })
                        store.dispatch({
                            type: "AGREGAR_CORREO_USUARIO",
                            correos: [],
                            creacionCorreo: false
                        })
                        if (this.state.pasoCopiaEmpresas === 4) {
                            console.log(response.data.id)
                            var user = store.getState().usuario
                            var datoss = {
                                "contratos":
                                    this.state.contratosEmpresasCopias.map((contrato) => {
                                        return ({
                                            "contratos_empresas": {
                                                "empresas_id": user.empresas_id,
                                                "contratos_id": contrato.contratos_empresas.contratos_id,
                                                "empresas_final_id": response.data.id
                                            }
                                        })
                                    }),
                                "productos":
                                    this.state.productosEmpresasCopias.map((producto) => {
                                        return ({
                                            "productos_empresas": {
                                                "productos_id": producto.productos.id,
                                                "empresas_id": response.data.id,
                                                "precio": producto.productos_empresas.precio,
                                                "cantidad_maxima": producto.productos_empresas.cantidad_maxima,
                                                "cantidad_minima": producto.productos_empresas.cantidad_minima,
                                                "sequimiento": 'N',
                                                "disponible": producto.productos_empresas.disponible
                                            }
                                        })
                                    }),
                            }
                            console.log(datoss)
                            Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/convenios/modelo/', datoss, { headers: { Authorization: ntoken } })
                                .then(responsee => {
                                    console.log(responsee)
                                })
                                .catch(err => {
                                    console.error(err)
                                })
                        }
                        //Obtencion de empresas padres
                        var hijos = ''
                        var padres = ''
                        var usuario = store.getState().usuario
                        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/node/' + usuario.empresas_id + '/1', { headers: { Authorization: ntoken } })
                            .then(response => {
                                if (response.status == 200) {
                                    response.data.data.map((item, key) => {
                                        if (key == 0) {
                                            padres = item.toString()
                                        } else {
                                            padres += ',' + item.toString()
                                        }
                                    })
                                    store.dispatch({
                                        type: "OBTENCION_JERARQUIA_PADRES",
                                        empresasPadres: padres
                                    })
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            })
                        //Obtencion de empresas hijas
                        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/node/' + usuario.empresas_id + '/0', { headers: { Authorization: ntoken } })
                            .then(response => {
                                if (response.status == 200) {
                                    response.data.data.map((item, key) => {
                                        if (key == 0) {
                                            hijos = item.toString()
                                        } else {
                                            hijos += ',' + item.toString()
                                        }
                                    })
                                    store.dispatch({
                                        type: "OBTENCION_JERARQUIA_HIJOS",
                                        empresasHijos: hijos,
                                        usuaurioObtenido: true
                                    })
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            })
                        swal({
                            title: "Empresa Creada correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_EMPRESA",
                                        creacionEmpresa: false,
                                        empresaDetalles: null
                                    })
                                    this.setState({ loader: true, })
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }
    }

    actualizarEmpresa = () => {
        var usuario = store.getState().usuario
        var contactos = []
        var validar = this.validarFormulario()
        this.state.telefonos.map((telefono, key) => {
            contactos.push({
                "tipo": 2,
                "etiqueta": telefono.etiqueta,
                "contacto": telefono.telefono,
                "principal": key == 0 ? 'S' : 'N'
            })
        })
        this.state.corrreos.map((correo, key) => {
            contactos.push({
                "tipo": 1,
                "etiqueta": correo.etiqueta,
                "contacto": correo.correo,
                "principal": key == 0 ? 'S' : 'N'
            })
        })
        contactos.push({
            "tipo": 3,
            "etiqueta": 'PRINCIPAL',
            "contacto": this.state.address,
            "principal": 'S'
        })
        var datos = {
            "id": this.state.idEditar,
            "razon_social": this.state.razonSocialN,
            "alias": this.state.checkSucursal ? this.state.razonSocialN : this.state.aliasN,
            "nit": this.state.nitN,
            "cantidad_sucursales": 1,
            "empresas_tipos_id": this.state.checkSucursal ? 4 : 5,
            "empresas_id": this.state.checkSucursal ? this.state.checkPadre ? this.state.empresaSeleccionada.value : usuario.empresas_id : this.state.empresaSeleccionada.value,
            "ciudades_id": this.state.ciudadSeleccionada.value,
            "url_foto": 1,
            "update_user": usuario.p_id,
            "estado": "A",
            "localizacion": this.state.centerDireccion.lat + "," + this.state.centerDireccion.lng,
            "dominio": '',
            "codigo": this.state.checkTienda ? this.state.codigoN : null,
            "create_user": usuario.p_id,
            "descriptor": {
                "id": this.state.idDescriptor,
                "header": this.state.cabecera,
                "footer": this.state.footer,
                "update_user": usuario.p_id,
            },
            "contactos": contactos
        }
        console.log(datos)
        if (validar) {
            Axios.put(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    console.log(response)
                    this.setState({
                        response: false
                    })
                    if (response.status === 201) {
                        store.dispatch({
                            type: "AGREGAR_TELEFONO_USUARIO",
                            telefonos: [],
                            creacionTelefono: false
                        })
                        store.dispatch({
                            type: "AGREGAR_CORREO_USUARIO",
                            correos: [],
                            creacionCorreo: false
                        })
                        //Obtencion de empresas padres
                        var hijos = ''
                        var padres = ''
                        var usuario = store.getState().usuario
                        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/node/' + usuario.empresas_id + '/1', { headers: { Authorization: ntoken } })
                            .then(response => {
                                if (response.status == 200) {
                                    response.data.data.map((item, key) => {
                                        if (key == 0) {
                                            padres = item.toString()
                                        } else {
                                            padres += ',' + item.toString()
                                        }
                                    })
                                    store.dispatch({
                                        type: "OBTENCION_JERARQUIA_PADRES",
                                        empresasPadres: padres
                                    })
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            })
                        //Obtencion de empresas hijas
                        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/node/' + usuario.empresas_id + '/0', { headers: { Authorization: ntoken } })
                            .then(response => {
                                if (response.status == 200) {
                                    response.data.data.map((item, key) => {
                                        if (key == 0) {
                                            hijos = item.toString()
                                        } else {
                                            hijos += ',' + item.toString()
                                        }
                                    })
                                    store.dispatch({
                                        type: "OBTENCION_JERARQUIA_HIJOS",
                                        empresasHijos: hijos,
                                        usuaurioObtenido: true
                                    })
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            })
                        swal({
                            title: "Empresa actualizada correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_EMPRESA",
                                        creacionEmpresa: false,
                                        empresaDetalles: null
                                    })
                                    this.setState({ loader: true, })
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }
    }


    cancelar = () => {
        store.dispatch({
            type: "CREACION_IMPUESTO",
            creacionImpuesto: false
        })
    }

    cerrar = () => {
        store.dispatch({
            type: "CREACION_EMPRESA",
            creacionEmpresa: false,
            empresaDetalles: null
        })
        store.dispatch({
            type: "AGREGAR_TELEFONO_USUARIO",
            telefonos: [],
            creacionTelefono: false
        })
        store.dispatch({
            type: "AGREGAR_CORREO_USUARIO",
            correos: [],
            creacionCorreo: false
        })
    }


    eliminarTelefono = (e) => {
        var mtelefono = this.state.telefonos
        mtelefono.splice(e, 1)
        this.setState({ telefonos: mtelefono })
    }

    eliminarCorreos = (e) => {
        var mcorreos = this.state.corrreos
        mcorreos.splice(e, 1)
        this.setState({ corrreos: mcorreos })
    }

    render() {
        const tipoDominio = store.getState().tipoDominio
        var telefonos = ""
        this.state.telefonos.map((contacto) => {
            telefonos += contacto.telefono + ", "
        })
        var correos = ""
        this.state.corrreos.map((contacto) => {
            correos += contacto.correo + ", "
        })
        var empresaEdit = store.getState().empresaDetalles
        var vistaEmpresa = store.getState().modoVistaEmpresas
        //console.log(empresaEdit)
        //console.log(this.state.centerDireccion)
        return (
            <div>
                <AppBar style={{ backgroundColor: colorDominio }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Close"
                            onClick={this.cerrar.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            style={{ marginLeft: "theme.spacing(2)", flex: 1, color: '#fff' }}
                        >
                            {vistaEmpresa ? 'DETALLES DE EMPRESA' : empresaEdit != null ? 'EDICION DE EMPRESA' : 'CREACION DE NEGOCIO'}
                        </Typography>
                        {vistaEmpresa ?
                            null : empresaEdit != null ?
                                <Button style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} disabled={this.state.response} onClick={this.actualizarEmpresa.bind(this)}>GUARDAR</Button>
                                :
                                <Button style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} disabled={this.state.response} onClick={this.crearEmpresa.bind(this)}>CREAR EMPRESA</Button>}


                    </Toolbar>
                </AppBar>
                <div className="row" style={{ marginTop: '7%', padding: '15px' }}>
                    <div className="col-md-6 col-sm-12" >
                        <div className="row mx-auto">
                            <div className="col-md-12">
                                <label htmlFor="numordencompra" className="col-form-label">Razon social</label>
                                <input autocomplete="off" id="numordencompra" className="form-control" type="text" disabled={vistaEmpresa} value={this.state.razonSocialN} onChange={this.handleChangeT('razonSocialN')} />
                            </div>
                        </div>
                        {this.state.checkSucursal ? null
                            :
                            <div className="row mx-auto">
                                <div className="col-md-12">
                                    <label htmlFor="numordencompra" className="col-form-label">Alias</label>
                                    <input autocomplete="off" id="numordencompra" className="form-control" type="text" disabled={vistaEmpresa} value={this.state.aliasN} onChange={this.handleChangeT('aliasN')} />
                                </div>
                            </div>
                        }
                        <div className="row mx-auto">
                            <div className="col-md-6">
                                <label htmlFor="numordencompra" className="col-form-label">Nit</label>
                                <input autocomplete="off" id="numordencompra" className="form-control" type="text" disabled={vistaEmpresa} value={this.state.nitN} onChange={this.handleChangeT('nitN')} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="numordencompra" className="col-form-label">Dominio web</label>
                                <input autocomplete="off" id="numordencompra" className="form-control" disabled={vistaEmpresa} type="text" value={this.state.dominioN} onChange={this.handleChangeT('codigoN')} />
                            </div>

                        </div>
                        <div className="row mx-auto">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Cliente</label>
                                <Selects
                                    isDisabled={vistaEmpresa}
                                    value={this.state.empresaSeleccionada}
                                    onChange={this.handleChanges('empresaSeleccionada')}
                                    options={this.state.empresasbd}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label htmlFor="numordencompra" className="col-form-label">Tipo de negocio</label>
                                <Selects
                                    isDisabled={vistaEmpresa}
                                    value={this.state.empresaSeleccionada}
                                    onChange={this.handleChanges('empresaSeleccionada')}
                                    options={this.state.empresasbd}
                                />
                            </div>
                        </div>
                        <div className="row mx-auto">
                            <Dialog
                                open={this.state.crearGeografia}
                                onClose={() => this.crearGeografia()}
                                aria-labelledby="form-dialog-title"
                                maxWidth={'md'}
                                fullWidth={true}

                                style={{ padding: '0' }}
                                disableBackdropClick={false}
                            >
                                <DialogContent style={{ padding: '0' }}>
                                    <FormularioGeografia tipoGeografia={this.state.tipoGeografia} />
                                </DialogContent>
                            </Dialog>
                            <div className="col-lg-5 col-md-5 col-sm-10">
                                <label htmlFor="numordencompra" className="col-form-label">Pais</label>
                                <Selects
                                    value={this.state.paisSeleccionada}
                                    onChange={this.handleChanges('paisSeleccionada')}
                                    options={this.state.paisbd}
                                    isDisabled={vistaEmpresa}
                                />
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-2 bottom-aligned">
                                <button className="btn btn-primary" disabled={vistaEmpresa} onClick={() => this.crearGeografia(1)}>+</button>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-10">
                                <label htmlFor="numordencompra" className="col-form-label">Departamento</label>
                                <Selects
                                    value={this.state.departamentoSeleccionada}
                                    onChange={this.handleChanges('departamentoSeleccionada')}
                                    options={this.state.departamentobd}
                                    isDisabled={JSON.stringify(this.state.paisSeleccionada) == '{}' || vistaEmpresa}
                                />
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-2 bottom-aligned ">
                                <button className="btn btn-primary" disabled={vistaEmpresa} onClick={() => this.crearGeografia(2)}>+</button>
                            </div>

                        </div>
                        <div className="row mx-auto">
                            <div className="col-lg-5 col-md-5 col-sm-10">
                                <label htmlFor="numordencompra" className="col-form-label">Ciudad</label>
                                <Selects
                                    value={this.state.ciudadSeleccionada}
                                    onChange={this.handleChanges('ciudadSeleccionada')}
                                    options={this.state.ciudadbd}
                                    isDisabled={JSON.stringify(this.state.departamentoSeleccionada) == '{}' || vistaEmpresa}
                                />
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-2 bottom-aligned ">
                                <button className="btn btn-primary" disabled={vistaEmpresa} onClick={() => this.crearGeografia(3)}>+</button>
                            </div>
                            <div className={"col-lg-6 col-md-6 col-sm-12"}>
                                <label htmlFor="numordencompra" className="col-form-label">Direccion</label>
                                <input autocomplete="off" id="numordencompra" disabled={true} className="form-control" type="text" value={this.state.address} />
                            </div>
                        </div>
                        {this.state.checkTienda
                            ?
                            <div className="row mx-auto">
                                <div className="col-md-6">
                                    <label htmlFor="numordencompra" className="col-form-label">Cabecera</label>
                                    <textarea rows="3" id="numordencompra" disabled={vistaEmpresa} className="form-control" type="text" value={this.state.cabecera} onChange={this.handleChangeT('cabecera')} />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="numordencompra" className="col-form-label">Pie de pagina</label>
                                    <textarea rows="3" id="numordencompra" disabled={vistaEmpresa} className="form-control" type="text" value={this.state.footer} onChange={this.handleChangeT('footer')} />
                                </div>
                            </div>
                            :
                            null
                        }
                        <div className="row mx-auto">
                            <div className="col-lg-6 col-md-6 col-sm-12" style={{ marginBottom: '1px' }}>
                                <label htmlFor="numordencompra" className="col-form-label">Telefono</label>
                                <div class="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><TelefonoIcon /></span>
                                    </div>
                                    <input autoComplete="off" type="text" className="form-control" aria-describedby="basic-addon1" value={telefonos} disabled={true} onClick={this.handledialogTelefono} />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" disabled={vistaEmpresa} type="button" onClick={this.handledialogTelefono}>+</button>
                                    </div>
                                </div>

                                <label htmlFor="numordencompra" className="col-form-label">Lista de telefonos</label>
                                <List className="mx-auto" >
                                    {this.state.telefonos.map((telefono, key) => {

                                        return (
                                            <ListItem className="mx-auto border" key={key}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <TelefonoIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={telefono.etiqueta} secondary={telefono.telefono} />
                                                {vistaEmpresa ? null : <ListItemSecondaryAction>
                                                    <IconButton aria-label="Delete" onClick={() => this.eliminarTelefono(key)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>}
                                            </ListItem>
                                        )

                                    })}
                                </List>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12" >
                                <label htmlFor="numordencompra" className="col-form-label">Correo</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1"><MailIcon /></span>
                                    </div>
                                    <input autocomplete="off" type="text" class="form-control" value={correos} disabled={true} onClick={this.handledialogCorreo} aria-describedby="basic-addon1" />
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" type="button" disabled={vistaEmpresa} onClick={this.handledialogCorreo}>+</button>
                                    </div>
                                </div>
                                <label htmlFor="numordencompra" className="col-form-label">Lista de correos</label>
                                <List className="mx-auto" >
                                    {this.state.corrreos.map((correo, key) => {
                                        return (
                                            <ListItem className="mx-auto border" key={key}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <MailIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={correo.etiqueta} secondary={correo.correo} />
                                                {vistaEmpresa ? null : <ListItemSecondaryAction>
                                                    <IconButton aria-label="Delete" onClick={() => this.eliminarCorreos(key)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>}
                                            </ListItem>
                                        )


                                    })}
                                </List>
                            </div>
                            <Dialog
                                open={this.state.dialogTelefono}
                                onClose={this.handledialogTelefono}
                                aria-labelledby="form-dialog-title"
                                maxWidth={'sm'}
                                style={{ padding: '0' }}
                            >
                                <DialogContent style={{ padding: '0' }}>
                                    <DialogTelefono telefonos={this.state.telefonos} />
                                </DialogContent>

                            </Dialog>
                            <Dialog
                                open={this.state.dialogCorreo}
                                onClose={this.handledialogCorreo}
                                aria-labelledby="form-dialog-title"
                                maxWidth={'sm'}
                                style={{ padding: '0' }}
                            >
                                <DialogContent style={{ padding: '0' }}>
                                    <DialogCorreo correos={this.state.corrreos} />
                                </DialogContent>

                            </Dialog>
                        </div>
                        <div className="row mx-auto">
                            {!vistaEmpresa && empresaEdit === null && this.state.checkTienda ?
                                <a className="mx-auto" href="#" onClick={() => this.handledialogCopia()}>DESEO HACER UNA COPIA</a>
                                : null}

                            <Dialog
                                open={this.state.dialogTiendaCopia}
                                onClose={this.handledialogCopia}
                                aria-labelledby="form-dialog-title"
                                maxWidth={'lg'}
                                fullWidth={true}
                                style={{ padding: '0' }}
                            >
                                <DialogContent >
                                    {
                                        this.state.pasoCopiaEmpresas === 1
                                            ?
                                            <div>
                                                <div className="row">
                                                    <List className="col-md-10 mx-auto" >
                                                        {this.state.tiendasbd.map((tienda, key) => {

                                                            return (
                                                                <ListItemLink className="mx-auto border" key={key} onClick={() => this.obtenerCopia(tienda.id, tienda.alias)}>
                                                                    <ListItemAvatar>
                                                                        <Avatar>
                                                                            <Tiendas />
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <ListItemText primary={tienda.alias + " -- " + tienda.nit} secondary={tienda.razon_social} />
                                                                </ListItemLink>
                                                            )

                                                        })}
                                                    </List>
                                                </div>
                                            </div>
                                            :
                                            this.state.pasoCopiaEmpresas === 2
                                                ?
                                                <div>
                                                    <div className="row">
                                                        <h5 className="mx-auto">{'PRODUCTOS DE ' + this.state.empresaSeleccionadaNombre}</h5>
                                                    </div>
                                                    <div className="row">
                                                        <Paper style={{ padding: '5px', width: '100%', marginTop: '10px' }}>
                                                            <MDBDataTable
                                                                //striped
                                                                className="col-md-11 mx-auto"
                                                                searchLabel="Buscar"
                                                                paginationLabel={["Anterior", "Siguente"]}
                                                                infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                entriesLabel="Filas a mostrar"
                                                                tbodyColor="primary-color"
                                                                theadColor="#FF8800"
                                                                exportToCSV={true}
                                                                striped
                                                                bordered
                                                                sortable={true}
                                                                autoWidth={false}
                                                                responsive={true}
                                                                responsiveSm={true}
                                                                style={{ fontSize: '13px' }}
                                                                hover
                                                                data={
                                                                    {
                                                                        columns: [
                                                                            {
                                                                                label: 'PLU',
                                                                                field: 'plu',
                                                                                width: 100
                                                                            }, {
                                                                                label: 'PRODUCTO',
                                                                                field: 'identificacion',
                                                                                width: 100
                                                                            }, {
                                                                                label: 'PRECIO',
                                                                                field: 'cant',
                                                                                width: 100
                                                                            }, {
                                                                                label: 'FLEXIBLE',
                                                                                field: 'operacion',
                                                                                width: 100
                                                                            }, {
                                                                                label: 'P. MINIMO',
                                                                                field: 'q9',
                                                                                width: 100
                                                                            }, {
                                                                                label: 'P. MAXIMO',
                                                                                field: 'q8',
                                                                                width: 100
                                                                            }, {
                                                                                label: 'ACCIONES',
                                                                                field: 'q88',
                                                                                width: 100
                                                                            }
                                                                        ], rows: this.state.productosEmpresasCopias.map((producto, key) => {
                                                                            return ({
                                                                                'plu': producto.productos.plu,
                                                                                'producto': producto.productos.descripcion,
                                                                                'precio': '$' + this.setearMiles(producto.productos_empresas.precio),
                                                                                'flexible': producto.productos.precio_flexible === 'S' ? 'SI' : 'NO',
                                                                                'pminimo': '$' + this.setearMiles(producto.productos.precio_minimo),
                                                                                'pmaximo': '$' + this.setearMiles(producto.productos.precio_maximo),
                                                                                'acciones': [
                                                                                    <button className="btn btn-sm btn-danger" onClick={() => this.eliminarProductoCopia(key)}><DeleteIcon /></button>
                                                                                ]
                                                                            })
                                                                        })
                                                                    }
                                                                }
                                                            />
                                                        </Paper>
                                                    </div>
                                                </div>
                                                :
                                                this.state.pasoCopiaEmpresas === 3
                                                    ?
                                                    <div>
                                                        <div className="row">
                                                            <h5 className="mx-auto">{'ACUERDOS DE ' + this.state.empresaSeleccionadaNombre}</h5>
                                                        </div>
                                                        <div className="row">
                                                            <Paper style={{ padding: '5px', width: '100%', marginTop: '10px' }}>
                                                                <MDBDataTable
                                                                    //striped
                                                                    className="col-md-11 mx-auto"
                                                                    searchLabel="Buscar"
                                                                    paginationLabel={["Anterior", "Siguente"]}
                                                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                    entriesLabel="Filas a mostrar"
                                                                    tbodyColor="primary-color"
                                                                    theadColor="#FF8800"
                                                                    exportToCSV={true}
                                                                    striped
                                                                    bordered
                                                                    sortable={true}
                                                                    autoWidth={false}
                                                                    responsive={true}
                                                                    responsiveSm={true}
                                                                    style={{ fontSize: '13px' }}
                                                                    hover
                                                                    data={
                                                                        {
                                                                            columns: [
                                                                                {
                                                                                    label: 'NUMERO',
                                                                                    field: 'plu',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'ACUERDO',
                                                                                    field: 'identificacion',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'PROVEEDOR',
                                                                                    field: 'cant',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'FECHA INICIO',
                                                                                    field: 'operacion',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'FECHA FIN',
                                                                                    field: 'q9',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'ACCIONES',
                                                                                    field: 'q88',
                                                                                    width: 100
                                                                                }
                                                                            ], rows: this.state.contratosEmpresasCopias.map((contrato, key) => {
                                                                                return ({
                                                                                    'plu': contrato.contratos.numero_contrato,
                                                                                    'producto': contrato.contratos.descripcion,
                                                                                    'producto1': contrato.proveedores.nombres,
                                                                                    'producto2': moment(contrato.contratos.fecha_inicio).format('DD/MM/YYYY'),
                                                                                    'producto3': moment(contrato.contratos.fecha_fin).format('DD/MM/YYYY'),
                                                                                    'acciones': [
                                                                                        <button className="btn btn-sm btn-danger" onClick={() => this.eliminarAcuerdoCopia(key)}><DeleteIcon /></button>
                                                                                    ]
                                                                                })
                                                                            })
                                                                        }
                                                                    }
                                                                />
                                                            </Paper>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                    }
                                </DialogContent>
                                <DialogActions>
                                    {this.state.pasoCopiaEmpresas === 1
                                        ?
                                        <button onClick={() => this.ANTERIOR(1)} className="btn btn-primary">
                                            CANCELAR
                                                        </button>
                                        :
                                        this.state.pasoCopiaEmpresas === 2 ?
                                            <button onClick={() => this.ANTERIOR(2)} className="btn btn-primary">
                                                ATRAS
                                                        </button>
                                            :
                                            this.state.pasoCopiaEmpresas === 3
                                                ?
                                                <button onClick={() => this.ANTERIOR(3)} className="btn btn-primary">
                                                    ATRAS
                                                        </button>
                                                :
                                                null
                                    }
                                    {this.state.pasoCopiaEmpresas === 1
                                        ?
                                        null
                                        :
                                        this.state.pasoCopiaEmpresas === 2 ?
                                            <button onClick={() => this.siguiente(2)} className="btn btn-primary">
                                                CONTINUAR
                                                        </button>
                                            :
                                            this.state.pasoCopiaEmpresas === 3
                                                ?
                                                <button onClick={() => this.siguiente(3)} className="btn btn-primary">
                                                    FINALIZAR
                                                        </button>
                                                :
                                                null
                                    }


                                </DialogActions>
                            </Dialog>
                        </div>
                    </div>
                    <div className="col-md-6  col-sm-12" >
                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="numordencompra" className="col-form-label">Localizacion</label>
                                <PlacesAutocomplete
                                    value={this.state.address}
                                    onChange={this.handleChange}
                                    onSelect={this.handleSelect}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input autocomplete="off"
                                                {...getInputProps({
                                                    placeholder: 'Buscar lugar...',
                                                    className: 'location-search-input',
                                                })}
                                                className="form-control"
                                                style={{ width: '100%' }}
                                                disabled={vistaEmpresa}
                                            />
                                            <div >
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map(suggestion => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div
                                                            style={{ padding: 0, width: '100%', zIndex: '1000' }}
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                        >
                                                            <MenuItem style={{ padding: '5px', width: '100%', zIndex: '1000' }}>{suggestion.description}</MenuItem>
                                                            {/*<span>{suggestion.description}</span>*/}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-md-12">
                                <Map
                                    className="rounded mx-auto col-md-12"
                                    google={this.props.google}
                                    zoom={this.state.zoom}
                                    style={{ height: '400px', width: '100%' }}
                                    initialCenter={this.state.centerDireccion}
                                    center={this.state.centerDireccion}
                                >
                                    <Marker draggable={true} position={this.state.centerDireccion} onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)} />
                                </Map>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}


export default GoogleApiWrapper({
    apiKey: 'AIzaSyDwX4ZKPWuQBM3cxGsXvz8MONBD0NtpoIo'
})(FormularioEmpresa)
