import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { MDBDataTable } from 'mdbreact';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import FormularioEntrada from '../../../components/Formularios/FormularioEntradaDirecta'
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide"
import store from '../../../lib/store'
import Server from '../../../lib/server'
import Axios from 'axios'
import swal from 'sweetalert'
import Ojoicon from "@material-ui/icons/RemoveRedEye"
import Selects from 'react-select';
import { Typography } from "@material-ui/core";
import moment from 'moment';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DeleteIcon from '@material-ui/icons/Delete';
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import CARGARQR from './qr-code-32.png'
import QrReader from 'react-qr-reader'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const idModulo = 37
var permisos = ''
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class Entradas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //Variables de componente principal
            createOrdenDeCompra: false,
            actualizarDatos: false,
            movimientosbd: [],
            entradaSeleccionada: null,
            modoDetalles: false,
            expanded: false,
            selectedDateVenta: moment(new Date()).format("YYYY-MM-DD ") + "00:00:00",
            selectedDateFinventa: moment(new Date()).format("YYYY-MM-DD ") + "23:59:59",
            cambbioInicioVenta: false,
            cambbioFinVenta: false,
            ventasXFecha: null,
            valueEmpresasFecha: 0,
            movimientoSeleccionado: null,
            dialogdetalles: false,
            multiEmpresasVentas: "",
            //cargar venta
            dialogCargarVenta: false,
            result: 'No result',
            qrsucces: 'Por favor acercar el codigo qr dentro del cuadro rojo',
            tipoSeleccionado: null,
            tiposBd: [
                {
                    label: "F. CONVENCIONAL",
                    value: 9
                }, {
                    label: "F. ELECTRONICA",
                    value: 15
                }
            ],
        }
        store.subscribe(() => {
            this.setState({
                createOrdenDeCompra: store.getState().ajusteInicial,
                actualizarDatos: store.getState().actualizarvista,
            })
        })

    }

    handleDialogCargarVenta = () => {
        this.setState({
            dialogCargarVenta: !this.state.dialogCargarVenta
        })
    }
    saveVenta = () => {
        var json = JSON.stringify(this.state.result)
    }

    cambiarTabs = (tab, valor) => {
        this.setState({
            [tab]: valor
        })
    }


    consultaVentasFecha = () => {
        const { usuario, empresasHijos } = store.getState()
        const { multiEmpresasVentas, cambbioInicioVenta, cambbioFinVenta, selectedDateFinventa, selectedDateVenta } = this.state
        const data = {
            "accion": "ventas",
            "empresas_id": usuario.tipo === "5" ? usuario.empresas_id : multiEmpresasVentas === "" ? empresasHijos : multiEmpresasVentas,
            "fecha_inicial": cambbioInicioVenta ? moment(new Date(selectedDateVenta.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD ") + "00:00:00",
            "fecha_final": cambbioFinVenta ? moment(selectedDateFinventa.toString()).format("YYYY-MM-DD  HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD ") + "24:00:00"
        }
        Axios.post(Server.hostPrueba + Server.puertoEmpresas + '/api/reportes', data, { headers: { Authorization: ntoken } })
            .then(responsee => {
                let datos = []
                if (responsee.status === 200) {
                    try {
                        datos = responsee.data.data[0].ventas_empresas
                    } catch (error) { }

                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                }
                this.setState({
                    ventasXFecha: datos,
                    buscoVenta: true
                })
                //this.AccionLoader()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizarDatos) {
            this.obtenerDatos()
            return true
        }
        return true;
    }

    cerrarVenta = () => {
        this.setState({ movimientoSeleccionado: null, dialogdetalles: false })
    }
    handleChangemomentventa = date => {
        this.setState({ selectedDateVenta: date, cambbioInicioVenta: true });
    }
    handleChangemomentFinVenta = date => {
        this.setState({ selectedDateFinventa: date, cambbioFinVenta: true });
    }

    setearMiles = (numero) => {
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case "COP":
                return (
                    (new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(2)))
                )
                break;
            case "USD":
                return (new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(numero))
                break;

        }
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        switch (name) {
            case 'multiEmpresasVentasSeleccionadas':
                var categorias = ""
                value.map((empresa, key) => {
                    if (key === 0) {
                        categorias += empresa.value
                    } else {
                        categorias += "," + empresa.value
                    }
                })
                this.setState({
                    multiEmpresasVentas: categorias
                })
                break;
        }
    }


    obtenerDatos = () => {
        this.setState({ actualizarDatos: false })
        var hijos = store.getState().empresasHijos
        var usuario = store.getState().usuario
        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/movimiento/lista/operacion/' + (usuario.tipo === "5" ? parseInt(usuario.empresas_id, 10) : hijos) + '/12', { headers: { Authorization: ntoken } })
            .then(responsee => {
                var movimientosTotal = []
                if (responsee.status == 200) {
                    responsee.data.movimientos.map((mov, key) => {
                        usuario.tipo === "5" ?
                            movimientosTotal.push({
                                "tipo": parseInt(mov.movimiento.tercero_id) === 3
                                    ? < Tooltip title={"NOTA A CREDITO"} placement="top" >
                                        <h6>NC</h6>
                                    </Tooltip>
                                    : <Tooltip title={"ANULACION DE VENTA"} placement="top">
                                        <h6>AV</h6>
                                    </Tooltip>,
                                "consecutivo": mov.movimiento.consecutivo,
                                "venta": mov.movimiento.consecutivo_venta || "",
                                "fecha": moment(mov.movimiento.fecha).format("DD/MM/YYY hh:mm a"),
                                "responsable": mov.movimiento.personas_id_detalles.nombres + " " + mov.movimiento.personas_id_detalles.apellidos,
                                "total": '$' + this.setearMiles(mov.movimiento.venta_total),
                                "accion": <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesEntrada(mov)}><Ojoicon /></button>,
                            })
                            :
                            movimientosTotal.push({
                                "tipo": parseInt(mov.movimiento.tercero_id) === 3
                                    ? < Tooltip title={"NOTA A CREDITO"} placement="top" >
                                        <h6>NC</h6>
                                    </Tooltip>
                                    : <Tooltip title={"ANULACION DE VENTA"} placement="top">
                                        <h6>AV</h6>
                                    </Tooltip>,
                                "consecutivo": mov.movimiento.consecutivo,
                                "venta": mov.movimiento.consecutivo_venta || "",
                                "fecha": moment(mov.movimiento.fecha).format("DD/MM/YYY hh:mm a"),
                                "tienda": mov.empresa.razon_social,
                                "responsable": mov.movimiento.personas_id_detalles.nombres + " " + mov.movimiento.personas_id_detalles.apellidos,
                                "total": '$' + this.setearMiles(mov.movimiento.venta_total),
                                "accion": <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesEntrada(mov)}><Ojoicon /></button>,
                            })

                    })
                    this.setState({ movimientosbd: movimientosTotal })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        if (usuario != "5") {
            Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/5/empresas/' + hijos + "," + usuario.empresas_id, { headers: { Authorization: ntoken } })
                .then(responseee => {
                    var empresas = []
                    //console.log(responseee)
                    if (responseee.data != null) {
                        responseee.data.empresas.map((dato) => (
                            empresas.push({
                                value: dato.id,
                                label: dato.alias + " -- " + dato.nit
                            })
                        ))
                    } else if (responseee.status == 205) {
                        swal("ERROR", "LA SESION HA CADUCADO", "warning")
                        setTimeout(function () { window.location.href = '/'; }, 5000);
                    }
                    this.setState({ empresasbd: empresas })

                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                })
        }



    }

    detallesEntrada = (entrada) => {
        this.setState({
            entradaSeleccionada: entrada,
            modoDetalles: true
        })
    }

    cerrarEntrada = () => {
        this.setState({
            entradaSeleccionada: null,
            modoDetalles: false
        })
    }
    componentWillMount = () => {
        //Permisos usuarios
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        this.obtenerDatos()
    }

    handleCrearOrdenDeCompra = () => {
        store.dispatch({
            type: "AJUSTE_INICIAL",
            ajusteInicial: true
        })
    }

    detallesVenta = (movimiento) => {
        this.setState({ movimientoSeleccionado: movimiento, dialogdetalles: true })
    }

    anularVentas = (venta, empresa_id) => {
        const { usuario, dominio } = store.getState()
        var id = venta.movimiento_id
        swal({
            title: "Estás seguro de realizar la nota crédito a la venta " + venta.consecutivo + " con valor de $" + this.setearMiles(venta.venta_total) + "?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    var data = {
                        "accion": "getventa",
                        "empresas_id": empresa_id,
                        "movimientos_id": id,
                        "fecha_inicial": moment(new Date()).format("MMYY")
                    }
                    Axios.post(Server.hostPrueba + Server.puertoEmpresas + '/api/reportes', data, { headers: { Authorization: ntoken } })
                        .then(responsee => {
                            var venta = []
                            if (responsee.status === 200) {
                                if (responsee.data.success) {
                                    venta = responsee.data.data[0]
                                    if (venta != null) {
                                        venta.venta.fecha = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                                        venta.venta.create_user = usuario.p_id
                                        venta.venta.operacion = 12
                                        venta.venta.dominios_id = dominio
                                        venta.venta.bodegas_id = venta.detalle[0].bodegas_id
                                        const ID_CLIENTE_FACTURACION_ELECTRONICA = 3
                                        if (venta.venta.tercero_id === ID_CLIENTE_FACTURACION_ELECTRONICA) {
                                            this.notaCredito(venta);
                                            //this.anulacionConvencional(venta);
                                        } else {
                                            this.anulacionConvencional(venta);
                                        }

                                    }
                                } else {
                                    swal("ERROR", responsee.data.message, "warning")
                                }

                            } else if (responsee.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 2000);
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })

                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }

    notaCredito = (venta) => {
        Axios.post(Server.hostNuevo + '/movimientos/anular_venta', venta, { headers: { Authorization: ntoken } })
            .then(responsee => {
                console.log(responsee)
                if (responsee.status === 201) {
                    swal("OK", "La venta ha sido anulada", "success")
                    this.obtenerDatos()
                    this.consultaVentasFecha()

                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else {
                    swal("ERROR", "INTENTELO MAS TARDE", "warning")
                }
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    swal("ERROR", err.response.data.mensaje, "warning")
                } else {
                    swal("ERROR", "NO HUBO COMUNICACION CON GURUSOFT, INTENTELO MAS TARDE", "warning")
                }
                console.log(err)
            })
    }

    anulacionConvencional = (venta) => {
        Axios.post(Server.hostPrueba + Server.puertoMovimiento + '/api/ventas', venta, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status === 201) {
                    if (responsee.data.success) {
                        swal("OK", "La venta ha sido anulada", "success")
                        this.obtenerDatos()
                        this.consultaVentasFecha()
                    }
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else {
                    swal("ERROR", "INTENTELO MAS TARDE", "warning")
                }
            })
            .catch((err) => {
                swal("ERROR", "INTENTELO MAS TARDE", "warning")
                console.log(err)
            })
    }

    expandir = () => {
        this.setState({
            expanded: !this.state.expanded,
            selectedDateVenta: moment(new Date()).format("YYYY-MM-DD ") + "00:00:00",
            selectedDateFinventa: moment(new Date()).format("YYYY-MM-DD ") + "23:59:59",
            cambbioInicioVenta: false,
            cambbioFinVenta: false,
            ventasXFecha: null
        })
    }

    handleScan = data => {
        if (data) {
            this.setState({
                result: data,
                qrsucces: 'Se ha leido el QR, de al boton GUARDAR'
            })
        }
    }
    handleError = err => {
        console.error(err)
    }

    obtenerDetallesVenta = (dato) => {
        if (dato.includes('{"venta"')) {
            var json = JSON.parse(dato)
            if (json.venta != null) {
                return 'VENTA No.' + json.venta.consecutivo + ' con valor de $' + this.setearMiles(parseFloat(json.venta.venta_total))
            }
        } else if (dato === "No result") {
            return ''
        } else {
            return 'POR FAVOR, CAPTURE EL CODIGO QR DE LA VENTA'
        }
    }

    render() {
        var usuario = store.getState().usuario
        var entradas = {
            columns: usuario.tipo === "5" ? [
                {
                    label: 'TIPO',
                    field: 'tipo',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'No.',
                    field: 'descripcion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Venta',
                    field: 'venta',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'FECHA',
                    field: 'cantidad',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'RESPONSABLE',
                    field: 'vlrunitario',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'TOTAL VENTA',
                    field: 'totalproducto',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'VER',
                    field: 'acciones',
                    sort: 'asc',
                    width: 150
                }
            ] : [
                    {
                        label: 'TIPO',
                        field: 'tipo',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'No.',
                        field: 'descripcion',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'Venta',
                        field: 'venta',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'FECHA',
                        field: 'cantidad',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'TIENDA',
                        field: 'tienda',
                        width: 150
                    }, {
                        label: 'RESPONSABLE',
                        field: 'vlrunitario',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'TOTAL VENTA',
                        field: 'totalproducto',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'VER',
                        field: 'acciones',
                        sort: 'asc',
                        width: 150
                    }
                ],
            rows: this.state.movimientosbd
        }
        var detalles = this.state.entradaSeleccionada
        //
        var ColumnasventasFechaTotales = [
            {
                label: 'TIPO',
                field: 'tipo',
                sort: 'desc',
                width: 150
            }, {
                label: 'Prefijo',
                field: 'prefijo',
                sort: 'desc',
                width: 150
            }, {
                label: 'Con.',
                field: 'consecutivo',
                sort: 'desc',
                width: 150
            }, {
                label: 'Fecha',
                field: 'fecha',
                width: 150
            }, {
                label: 'Promotor',
                field: 'promotor',
                width: 150
            }/*, {
                label: 'Sub. Venta',
                field: 'subventa',
                width: 150
            }*/, {
                label: 'Imp. venta',
                field: 'ipventa',
                width: 150
            }, {
                label: 'T. Venta',
                field: 'tventa',
                width: 150
            }, {
                label: 'Acciones',
                field: 'acciones',
                width: 150
            }
        ]
        var rowsVentasFechas = []
        var ventaFecha = this.state.ventasXFecha
        var ExcelVentaFecha = []
        var totalVentas = []
        var totalImpuestos = []
        var ventaSellecionada = null
        if (this.state.movimientoSeleccionado != null) {
            ventaSellecionada = this.state.movimientoSeleccionado
        }
        return (
            <div>
                {permisos.includes('C') ? <div className="row" style={{ marginBottom: "10px", paddingLeft: "13px" }}>
                    <button className="btn btn-success" onClick={this.handleDialogCargarVenta.bind(this)}>CARGAR VENTA<img src={CARGARQR} /></button>
                </div> : null}
                <Dialog
                    open={this.state.dialogCargarVenta}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'md'}
                    fullWidth={true}
                    style={{ padding: '0' }}
                    disableBackdropClick={false}
                >
                    <DialogContent style={{ padding: '5' }}>
                        <div className="row">
                            <h5>CARGAR VENTA</h5>
                        </div>
                        <div className="row">
                            <QrReader
                                delay={300}
                                onError={this.handleError}
                                onScan={this.handleScan}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="row">
                            <h6>{this.state.qrsucces}</h6>
                        </div>
                        <div className="row">
                            <h6>{this.obtenerDetallesVenta(this.state.result)}</h6>
                        </div>
                        <div className="row">
                            <button className="btn btn-danger col-md-3 col-sm-12" onClick={this.handleDialogCargarVenta.bind(this)}>CANCELAR</button>
                            <button className="btn btn-success col-md-3 col-sm-12" onClick={this.saveVenta.bind(this)}>GUARDAR</button>
                        </div>
                    </DialogContent>
                </Dialog>
                <ExpansionPanel expanded={this.state.expanded} style={{ zIndex: 1098 }} onChange={() => this.expandir()}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography >ANULAR VENTA</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div style={{ width: '100%' }}>
                            {
                                usuario.tipo === "5"
                                    ?
                                    <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                        <div className="col-md-4">
                                            <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"} >
                                                <DateTimePicker
                                                    format="yyyy-MM-dd hh:mm a"
                                                    id="datetime"
                                                    ampm={false}
                                                    value={this.state.selectedDateVenta}
                                                    onChange={this.handleChangemomentventa}
                                                    disableFuture={true}
                                                    className="rounded form-control"
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                <DateTimePicker
                                                    format="yyyy-MM-dd hh:mm a"
                                                    id="datetime"
                                                    ampm={false}
                                                    value={this.state.selectedDateFinventa}
                                                    onChange={this.handleChangemomentFinVenta}
                                                    disableFuture={true}
                                                    className="rounded form-control"
                                                    minDate={moment(new Date(this.state.selectedDateVenta.toString())).format("YYYY-MM-DD HH:mm")}
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        {/*<div className="col-md-3">
                                            <label htmlFor="numordencompra" className="col-form-label">TIPO</label>
                                            <Selects
                                                value={this.state.tipoSeleccionado}
                                                onChange={this.handleChanges('tipoSeleccionado')}
                                                options={this.state.tiposBd}
                                                isClearable
                                            />
                            </div>*/}
                                        <div className="col-md-4  bottom-aligned">
                                            <button className="btn btn-success btn-block" onClick={() => this.consultaVentasFecha()}>CONSULTAR</button>
                                        </div>
                                    </div>
                                    :
                                    <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                        <div className="col-md-3">
                                            <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                <DateTimePicker
                                                    format="yyyy-MM-dd hh:mm a"
                                                    id="datetime"
                                                    value={this.state.selectedDateVenta}
                                                    onChange={this.handleChangemomentventa}
                                                    ampm={false}
                                                    disableFuture={true}
                                                    className="rounded form-control"
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                <DateTimePicker
                                                    format="yyyy-MM-dd hh:mm a"
                                                    id="datetime"
                                                    value={this.state.selectedDateFinventa}
                                                    onChange={this.handleChangemomentFinVenta}
                                                    disableFuture={true}
                                                    ampm={false}
                                                    className="rounded form-control"
                                                    minDate={moment(new Date(this.state.selectedDateVenta.toString())).format("YYYY-MM-DD HH:mm")}
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="numordencompra" className="col-form-label">EMPRESA</label>
                                            <Selects
                                                value={this.state.multiEmpresasVentasSeleccionadas}
                                                onChange={this.handleChanges('multiEmpresasVentasSeleccionadas')}
                                                options={this.state.empresasbd}
                                                isMulti
                                            />
                                        </div>
                                        {/*<div className="col-md-2">
                                            <label htmlFor="numordencompra" className="col-form-label">TIPO</label>
                                            <Selects
                                                value={this.state.tipoSeleccionado}
                                                onChange={this.handleChanges('tipoSeleccionado')}
                                                options={this.state.tiposBd}
                                                isClearable
                                            />
                            </div>*/}
                                        <div className="col-md-2  bottom-aligned">
                                            <button className="btn btn-success btn-block" onClick={() => this.consultaVentasFecha()}>CONSULTAR</button>
                                        </div>
                                    </div>

                            }
                            {
                                ventaFecha != null
                                    ?
                                    <Tabs
                                        value={this.state.valueEmpresasFecha}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        aria-label="disabled tabs example"
                                    >
                                        {
                                            ventaFecha.map((venta, key) => {
                                                rowsVentasFechas[key] = []
                                                totalImpuestos[key] = 0
                                                totalVentas[key] = 0
                                                return (
                                                    <Tab label={venta.razon_social} onClick={() => this.cambiarTabs("valueEmpresasFecha", key)} />
                                                )
                                            })
                                        }

                                    </Tabs>
                                    :
                                    console.log("Venta por fecha es null")
                            }{
                                ventaFecha != null
                                    ?
                                    ventaFecha.map((empresa, key1) => {
                                        empresa.ventas != null
                                            ?
                                            empresa.ventas.map((venta) => {
                                                var ttventa = {
                                                    'tipo': venta.tercero_id === 3 ? 'F. ELECTRONICA' : 'F. CONVENCIONAL',
                                                    'prefijo': venta.prefijo,
                                                    'consecutivo': parseInt(venta.consecutivo),
                                                    'fecha': moment(new Date(venta.fecha)).format("DD/MM/YYYY hh:mm a"),
                                                    'promotor': venta.nombres,
                                                    /*'subtotalVenta': '$' + this.setearMiles(venta.venta_total),*/
                                                    'ImpuestosVenta': '$' + this.setearMiles((venta.impuesto_total).toFixed(3)),
                                                    'totalVenta': '$' + this.setearMiles(venta.venta_total),
                                                    'acciones': [
                                                        <button className="btn btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => this.detallesVenta(venta)}><Ojoicon /></button>,
                                                        <button className="btn btn-sm btn-danger" onClick={() => this.anularVentas(venta, empresa.empresas_id)}><DeleteIcon /></button>
                                                    ]
                                                }

                                                rowsVentasFechas[key1].push(ttventa)
                                                totalImpuestos[key1] += parseFloat(venta.impuesto_total)
                                                totalVentas[key1] += parseFloat(venta.venta_total)
                                            })
                                            :
                                            console.log("No hay ventas")
                                    })

                                    :
                                    console.log("Venta por fecha es null")
                            }
                            {
                                ventaFecha != null
                                    ?
                                    <div>
                                        <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                            <Paper style={{ padding: '5px', width: '100%' }}>

                                                <MDBDataTable
                                                    //striped
                                                    className="col-md-12"
                                                    searchLabel="Buscar"
                                                    paginationLabel={["Anterior", "Siguente"]}
                                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                    entriesLabel="Filas a mostrar"
                                                    tbodyColor="primary-color"
                                                    theadColor="#FF8800"
                                                    exportToCSV={true}
                                                    striped
                                                    bordered
                                                    autoWidth={false}
                                                    responsive={true}
                                                    hover
                                                    sorting={true}
                                                    order={['consecutivo', 'desc']}
                                                    data={
                                                        {
                                                            columns: ColumnasventasFechaTotales,
                                                            rows: rowsVentasFechas[this.state.valueEmpresasFecha]
                                                        }
                                                    }
                                                />
                                                <Dialog
                                                    open={this.state.dialogdetalles}
                                                    aria-labelledby="form-dialog-title"
                                                    maxWidth={'lg'}
                                                    fullWidth={true}
                                                    style={{ padding: '0' }}
                                                    disableBackdropClick={false}
                                                >
                                                    <DialogContent style={{ padding: '0' }}>
                                                        <div className="row mx-auto" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                            <div className="col col-md-11 mx-auto" style={{ padding: 0 }}>
                                                                <Paper style={{ padding: '10px' }}>
                                                                    <div className="row">
                                                                        <button className="btn btn-danger " onClick={() => this.cerrarVenta()} style={{ margin: '10px', float: 'right' }}>CERRAR</button>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="numordencompra" className="col-form-label">VENTA</label>
                                                                            <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? ventaSellecionada.consecutivo : '000'} className="form-control" />
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="numordencompra" className="col-form-label">FECHA</label>
                                                                            <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? moment(new Date(ventaSellecionada.fecha)).format("DD/MM/YYYY hh:mm a") : ''} className="form-control" />
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <label htmlFor="numordencompra" className="col-form-label">PROMOTOR</label>
                                                                            <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? ventaSellecionada.nombres : ''} className="form-control" />
                                                                        </div>
                                                                    </div>
                                                                    <MDBDataTable
                                                                        //striped
                                                                        className="col-md-12 mx-auto"
                                                                        searchLabel="Buscar"
                                                                        paginationLabel={["Anterior", "Siguente"]}
                                                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                        entriesLabel="Filas a mostrar"
                                                                        tbodyColor="primary-color"
                                                                        theadColor="#FF8800"
                                                                        exportToCSV={true}
                                                                        striped
                                                                        bordered
                                                                        autoWidth={false}
                                                                        responsive={true}
                                                                        hover
                                                                        order={['plu', 'asc']}
                                                                        data={
                                                                            {
                                                                                columns: [
                                                                                    {
                                                                                        label: 'Plu',
                                                                                        field: 'plu',
                                                                                        sort: 'asc',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'Descripcion',
                                                                                        field: 'descr',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'Precio',
                                                                                        field: 'punitario',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'Cant.',
                                                                                        field: 'cantidad',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'B. Gravable',
                                                                                        field: 'sproducto',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'IVA',
                                                                                        field: 'subventa',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'IPOCONSUMO',
                                                                                        field: 'imventa',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'Total',
                                                                                        field: 'venta',
                                                                                        width: 150
                                                                                    }
                                                                                ],
                                                                                rows: ventaSellecionada != null ?
                                                                                    ventaSellecionada.ventas_detalles != null
                                                                                        ?
                                                                                        ventaSellecionada.ventas_detalles.map((detalle) => {
                                                                                            return ({
                                                                                                'plu': detalle.plu,
                                                                                                'producto': detalle.descripcion,
                                                                                                'punitario': '$ ' + this.setearMiles(detalle.precio),
                                                                                                'cantidad': detalle.cantidad,
                                                                                                'subtotal': '$ ' + this.setearMiles(detalle.venta_neta),
                                                                                                'iva': [<td>{detalle.descripcion_iva != null ? detalle.descripcion_iva : ''}</td>, <td>{'$' + (detalle.valor_iva != null ? this.setearMiles(detalle.valor_iva) : 0)}</td>],
                                                                                                'ipoconsumo': '$' + (detalle.valor_no_gravable != null ? this.setearMiles(detalle.valor_no_gravable) : 0),
                                                                                                'total': '$' + this.setearMiles(detalle.venta_bruta),
                                                                                            })
                                                                                        })
                                                                                        :
                                                                                        []
                                                                                    :
                                                                                    []
                                                                            }
                                                                        }
                                                                    />
                                                                </Paper>
                                                            </div>
                                                        </div>
                                                        <div className="row mx-auto">
                                                            <div className="col-md-8 bottom-aligned mx-auto"></div>

                                                        </div>
                                                    </DialogContent>
                                                </Dialog>

                                            </Paper>
                                        </div>
                                        <div className="row" style={{ margin: '10px' }}>
                                            <div className="col-md-4">
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="numordencompra" className="col-form-label">Total impuestos</label>
                                                <input id="numordencompra" disabled={true} value={'$' + this.setearMiles(totalImpuestos[this.state.valueEmpresasFecha])} className="form-control" />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="numordencompra" className="col-form-label">Total ventas</label>
                                                <input id="numordencompra" disabled={true} value={'$' + this.setearMiles(totalVentas[this.state.valueEmpresasFecha])} className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    console.log("Venta por fecha es null")
                            }
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <div>
                    <div className="row" style={{ marginTop: "15px" }}>
                        {permisos.includes('R') ? <div className="col col-md-12" style={{ padding: 0 }}>
                            <Paper style={{ padding: '5px' }}>

                                <MDBDataTable
                                    //striped
                                    className="col-md-12"
                                    searchLabel="Buscar"
                                    paginationLabel={["Anterior", "Siguente"]}
                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                    entriesLabel="Filas a mostrar"
                                    tbodyColor="primary-color"
                                    theadColor="#FF8800"
                                    exportToCSV={true}
                                    striped
                                    bordered
                                    autoWidth={false}
                                    responsive={true}
                                    hover
                                    data={entradas}
                                />

                                <Dialog
                                    open={this.state.modoDetalles}
                                    onClose={this.cerrarEntrada}
                                    aria-labelledby="form-dialog-title"
                                    maxWidth={'md'}
                                    fullWidth={true}
                                    disableBackdropClick={true}
                                >
                                    <DialogContent >
                                        {
                                            detalles != null ?
                                                <div className="row mx-auto">
                                                    <div className="col col-md-4">
                                                        <label htmlFor="numordencompra" className="col-form-label">RESPONSABLE</label>
                                                        <input id="numordencompra" disabled className="form-control" type="text" value={detalles.movimiento.personas_id_detalles.nombres + " " + detalles.movimiento.personas_id_detalles.apellidos} />
                                                    </div>
                                                    <div className="col col-md-3">
                                                        <label htmlFor="numordencompra" className="col-form-label">FECHA</label>
                                                        <input id="numordencompra" disabled className="form-control" type="text" value={moment(detalles.movimiento.fecha).format("DD/MM/YY hh:mm a")} />
                                                    </div>
                                                    <div className="col col-md-5">
                                                        <label htmlFor="numordencompra" className="col-form-label">PROVEEDOR</label>
                                                        <input id="numordencompra" disabled className="form-control" type="text" value={detalles.movimiento.tercero_id_detalles.nombres} />
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }


                                        <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                            <div className="col col-md-12">

                                                <MDBDataTable
                                                    //striped
                                                    className="col-md-12"
                                                    searchLabel="Buscar"
                                                    paginationLabel={["Anterior", "Siguente"]}
                                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                    entriesLabel="Filas a mostrar"
                                                    tbodyColor="primary-color"
                                                    theadColor="#FF8800"
                                                    exportToCSV={true}
                                                    striped
                                                    bordered
                                                    autoWidth={false}
                                                    responsive={true}
                                                    hover
                                                    data={
                                                        {
                                                            columns: [

                                                                {
                                                                    label: 'PLU',
                                                                    field: 'celular',
                                                                    sort: 'asc',
                                                                    width: 150
                                                                }, {
                                                                    label: 'PRODUCTO',
                                                                    field: 'celular',
                                                                    sort: 'asc',
                                                                    width: 150
                                                                }, {
                                                                    label: 'BODEGA',
                                                                    field: 'identificacion',
                                                                    sort: 'asc',
                                                                    width: 150
                                                                }, {
                                                                    label: 'CANTIDAD',
                                                                    field: 'direccion',
                                                                    sort: 'asc',
                                                                    width: 150
                                                                }, {
                                                                    label: 'C. UNITARIO',
                                                                    field: 'cant',
                                                                    sort: 'asc',
                                                                    width: 150
                                                                }, {
                                                                    label: ' C. TOTAL',
                                                                    field: 'cant',
                                                                    sort: 'asc',
                                                                    width: 150
                                                                }
                                                            ], rows:
                                                                detalles != null
                                                                    ?
                                                                    detalles.detalle != null
                                                                        ?
                                                                        detalles.detalle.map((p) => {
                                                                            return ({
                                                                                'plu': p.plu,
                                                                                'producto': p.descripcion,
                                                                                'bodega': p.bodega,
                                                                                'cantidad': p.cantidad,
                                                                                'costo': '$' + this.setearMiles(p.precio),
                                                                                'costot': '$' + this.setearMiles(parseInt(p.cantidad) * parseFloat(p.precio)),
                                                                            })
                                                                        })
                                                                        :
                                                                        []
                                                                    :
                                                                    []
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <button className="btn btn-danger" onClick={this.cerrarEntrada.bind(this)}>CERRAR</button>
                                    </DialogActions>
                                </Dialog>

                            </Paper>
                        </div> : <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>}

                    </div>
                </div>
            </div>
        )
    }
}
export default Entradas