import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { MDBDataTable } from 'mdbreact';
import Paper from '@material-ui/core/Paper';
import FormularioTranslado from '../../../components/Formularios/FormularioTranslado'
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide"
import store from '../../../lib/store'
import Server from '../../../lib/server'
import Axios from 'axios'
import Ojoicon from "@material-ui/icons/RemoveRedEye"
import swal from 'sweetalert'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import logoTerpel from '../../../assets/img/logos/logo_terpel.png'
import logoDeuna from '../../../assets/img/logos/logoo_r2_c2.png'
import linea1 from '../../../assets/img/linea.png'
import linea2 from '../../../assets/img/linea2.png'
import HojaIcon from "@material-ui/icons/PictureAsPdf"
import pdfIcon from "../../../assets/img/pdf.png";
import moment from 'moment';
import Loader from '../../../components/Loader'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from "@material-ui/core";
import Selects from 'react-select';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Snackbar from '@material-ui/core/Snackbar';
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const idModulo = 20
var permisos = ''
class Traslados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //Variables de componente principal
            createOrdenDeCompra: false,
            actualizarDatos: false,
            movimientosbd: [],
            bodegaSalida: [],
            bodegaEntrada: [],
            productos: [],
            responsable: '',
            fecha: '',
            tienda: '',
            ventanaDetalles: false,
            imprimirPdf: false,
            trsladoSeleccionado: null,
            loader: true,
            //Filtros
            expanded: false,
            kioscosbd: [],
            multiEmpresas: "",
            fechaInicio: moment(new Date()).subtract("day", 6).format("YYYY-MM-DD ") + "00:00:00",
            fechaFin: new Date(),
            sinMovimientos: false
        }
        store.subscribe(() => {
            this.setState({
                createOrdenDeCompra: store.getState().trasladoBodega,
                actualizarDatos: store.getState().actualizarvista,
            })
        })
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        switch (name) {
            case "kioscosSeleccionado":
                var empresas = ""
                value.map((empresa, key) => {
                    if (key === 0) {
                        empresas += empresa.value
                    } else {
                        empresas += "," + empresa.value
                    }
                })
                this.setState({
                    multiEmpresas: empresas,
                    busquedaUsuario: true
                })


        }
    }

    handleChangemoment = date => {
        this.setState({ fechaInicio: date });
    }
    handleChangemomentFinal = date => {
        this.setState({ fechaFin: date });
    }

    expandir = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    setearMiles = (numero) => {
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case "COP":
                return (
                    (new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(3)))
                )
                break;
            case "USD":
                return (new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(numero))
                break;

        }
    }

    handleDetalles = () => {
        this.setState({
            ventanaDetalles: !this.state.ventanaDetalles
        })
    }

    handleCrearOrdenDeCompra = () => {
        store.dispatch({
            type: "TRASLADO_BODEGA",
            trasladoBodega: true
        })
        this.actualizarToken()
    }

    actualizarToken = () => {
        Axios.get(Server.hostPruebasinssl + Server.puertoAutenticacion, { headers: { Authorization: ntoken } })
            .then(response => {
                if (response.status === 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 206) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 200) {
                    //RESPEUSTA OK
                }
            })
    }

    detallesTranslado = (id, mov_empresa) => {
        var usuario = store.getState().usuario
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        var datos = {
            "tipo_reporte": -1,
            "accion": "traslados_bodegas_hist",
            "empresas_id": usuario === "5" ? usuario.empresas_id : mov_empresa,
            "id": id
        }
        Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes', datos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var detProductos = []
                console.log(responsee)
                if (responsee.status == 200) {
                    var detallesTraslado = responsee.data.data[0]
                    detallesTraslado.detalles.map((producto, key) => {
                        detProductos.push({
                            'plu': producto.plu,
                            'producto': producto.producto,
                            'bodega_inicial': producto.bodega_inicial,
                            'bodega_final': producto.bodega_final,
                            'cantidad_bodega_inicial': producto.cantidad_bodega_inicial,
                            'costo_unitario': '$' + this.setearMiles(parseInt(producto.costo_producto_bodega_inicial)),
                            'costo_producto_bodega_inicial': '$' + this.setearMiles(parseFloat(parseInt(producto.cantidad_bodega_inicial) * parseInt(producto.costo_producto_bodega_inicial)).toFixed(3)),
                        })
                    })
                    this.setState({
                        productos: detProductos,
                        fecha: detallesTraslado.movimiento.fecha,
                        tienda: detallesTraslado.movimiento.tienda,
                        responsable: detallesTraslado.movimiento.responsable,
                        ventanaDetalles: true
                    })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    detallesTransladoImprimir = (id, mov_empresa) => {
        var usuario = store.getState().usuario
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        var datos = {
            "tipo_reporte": -1,
            "accion": "traslados_bodegas_hist",
            "empresas_id": usuario === "5" ? usuario.empresas_id : mov_empresa,
            "id": id
        }
        Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes', datos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var detProductos = []
                console.log(responsee)
                if (responsee.status == 200) {
                    var detallesTraslado = responsee.data.data[0]
                    this.setState({
                        trsladoSeleccionado: responsee.data.data[0],
                        imprimirPdf: true
                    })
                    let t = this
                    setTimeout(function () { t.imprimir("Traslado entre bodega") }, 200)
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }
    imprimir = (nombre) => {
        const nd = document.getElementById('capture');
        var estilos = `<style>
                        .numero{
                        font-family: 'Roboto', sans-serif;
                        font-size:18px;}
                        .numero2{
                        font-family: 'Roboto', sans-serif !IMPORTANT;
                        font-size:10px;}
        
                      
        
                        .tb-terpel td{
                        font-family: 'Raleway', sans-serif;
                        font-size:11px;
                        border: 0;
                        padding: 0;
                        }
                        </style>`
        //console.log(nd)
        var download = {
            "template": estilos + nd.innerHTML,
            "filename": nombre + ".pdf"
        }
        Axios.post(Server.HostPDF + Server.puertoPdf + '/api/pdf/download', download)
            .then(response => {
                //console.log(response)
                var url = response.data.url
                var link = document.createElement('a');
                link.setAttribute("target", "_blank")
                link.download = response.data.filename;
                link.href = url;
                link.click();
                this.setState({
                    imprimirPdf: false,
                    trsladoSeleccionado: null,
                })
            })


    }

    componentWillMount = () => {
        //Permisos usuarios
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        var hijos = store.getState().empresasHijos
        //Obtener las tiendas
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/5/padre/' + hijos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var tiendas = []
                if (responsee.status == 200) {
                    responsee.data.empresas.map((dato, key) => (
                        tiendas.push({
                            value: dato.id,
                            label: dato.alias
                        })
                    ))
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
                this.setState({ kioscosbd: tiendas })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        this.obtenerDatos()
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizarDatos) {
            this.obtenerDatos()
            return true
        }
        return true;
    }

    obtenerDatos = () => {
        store.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: false
        })
        this.setState({
            loader: true
        })
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        var data = {
            empresas_id: this.state.multiEmpresas === "" ? hijos : this.state.multiEmpresas,
            operacion: "8",
            fecha_inicial: moment(new Date(this.state.fechaInicio.toString())).format("YYYY-MM-DD")+" 00:00:00",
            fecha_final: moment(new Date(this.state.fechaFin.toString())).format("YYYY-MM-DD")+" 24:00:00"
        }
        Axios.post(Server.hostPruebasinssl + Server.puertoProducto + '/api/movimiento/lista/operacion', data, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var movimientosTotal = []
                if (responsee.status == 200) {
                    if (responsee.data.movimientos.length === 0) {
                        this.setState({
                            sinMovimientos: true
                        })
                    } else {
                        responsee.data.movimientos.map((mov, key) => {
                            usuario.tipo === "5" ?
                                movimientosTotal.push({
                                    "consecutivo": mov.movimiento.consecutivo,
                                    "fecha": mov.movimiento.fecha,
                                    "responsable": mov.movimiento.personas_id_detalles.nombres + " " + mov.movimiento.personas_id_detalles.apellidos,
                                    "accion": [<button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesTranslado(mov.movimiento.id, mov.movimiento.empresas_id)}><Ojoicon /></button>,
                                    <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.detallesTransladoImprimir(mov.movimiento.id, mov.movimiento.empresas_id)}><img src={pdfIcon} /></button>]
                                })
                                :
                                movimientosTotal.push({
                                    "consecutivo": mov.movimiento.consecutivo,
                                    "fecha": mov.movimiento.fecha,
                                    "tienda": mov.empresa.alias,
                                    "responsable": mov.movimiento.personas_id_detalles.nombres + " " + mov.movimiento.personas_id_detalles.apellidos,
                                    "accion": [<button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesTranslado(mov.movimiento.id, mov.movimiento.empresas_id)}><Ojoicon /></button>, ,
                                    <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.detallesTransladoImprimir(mov.movimiento.id, mov.movimiento.empresas_id)}><img src={pdfIcon} /></button>]
                                })
                        })
                    }
                    this.setState({
                        movimientosbd: movimientosTotal,
                        loader: false
                    })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })

    }

    render() {
        var usuario = store.getState().usuario
        var translados = {
            columns: usuario.tipo === "5" ? [
                {
                    label: 'Con.',
                    field: 'descripcion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Fecha',
                    field: 'cantidad',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Responsable',
                    field: 'vlrunitario',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Acciones',
                    field: 'vlrunitario',
                    sort: 'asc',
                    width: 150
                }
            ] : [
                    {
                        label: 'Con.',
                        field: 'descripcion',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'Fecha',
                        field: 'cantidad',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'Tienda',
                        field: 'tienda',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'Responsable',
                        field: 'vlrunitario',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'Acciones',
                        field: 'vlrunitario',
                        sort: 'asc',
                        width: 150
                    }
                ],
            rows: this.state.movimientosbd
        }
        var detalles = {
            columns: [

                {
                    label: 'PLU',
                    field: 'celular',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'PRODUCTO',
                    field: 'celular',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'BODEGA INICIAL',
                    field: 'identificacion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'BODEGA FINAL',
                    field: 'operacion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'CANTIDAD',
                    field: 'direccion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'C. UNITARIO',
                    field: 'cant',
                    sort: 'asc',
                    width: 150
                }, {
                    label: ' C. TOTAL',
                    field: 'cantt',
                    sort: 'asc',
                    width: 150
                }
            ], rows: this.state.productos
        }
        var detallest = this.state.trsladoSeleccionado
        var movimiento = null
        var detalle = null
        var total = null
        if (detallest != null) {
            movimiento = detallest.movimiento
            detalle = detallest.detalles
            total = 0
        }
        return (
            <div>
                {this.state.createOrdenDeCompra ? <button className="btn btn-danger" onClick={this.handleCrearOrdenDeCompra.bind(this)}> Cancelar</button> : permisos.includes('C') ? <button className="btn btn-success" onClick={this.handleCrearOrdenDeCompra.bind(this)}> Registrar traslado</button> : null}
                {this.state.createOrdenDeCompra ?
                    <Dialog fullScreen open={this.state.createOrdenDeCompra} onClose={this.handleCrearOrdenDeCompra} TransitionComponent={Transition}>
                        <FormularioTranslado />
                    </Dialog> : null}
                <div className="row" style={{ marginTop: "15px" }}>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        variant="error"
                        open={this.state.sinMovimientos}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message="NO HAY TRASLADOS PARA ESTOS PARAMETROS DE BUSQUEDA."
                    />
                    <ExpansionPanel expanded={this.state.expanded} className="col-md-12" style={{ zIndex: 1098 }} onChange={() => this.expandir()}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >FILTROS</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div style={{ width: '100%' }}>
                                <div className="row mx-auto">
                                    {
                                        usuario.tipo === "5"
                                            ?
                                            null
                                            :
                                            <div className="col-md-5" >
                                                <label htmlFor="nombreprov" className="col-form-label">KIOSCO</label>
                                                <Selects
                                                    placeholder="Seleccione o escriba"
                                                    value={this.state.kioscosSeleccionado}
                                                    onChange={this.handleChanges('kioscosSeleccionado')}
                                                    options={this.state.kioscosbd}
                                                    isMulti
                                                />
                                            </div>
                                    }

                                    <div className={usuario.tipo === "5" ? "col-md-4" : "col-md-2"} >
                                        <label htmlFor="numordencompra" className="col-form-label">FECHA DE INICIO</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DatePicker
                                                format="yyyy-MM-dd"
                                                id="datetime"
                                                value={this.state.fechaInicio}
                                                onChange={this.handleChangemoment}
                                                ampm={false}
                                                disableFuture={true}
                                                className="rounded form-control"
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={usuario.tipo === "5" ? "col-md-4" : "col-md-2"}  >
                                        <label htmlFor="nombreprov" className="col-form-label">FECHA FIN</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DatePicker
                                                format="yyyy-MM-dd"
                                                id="datetime"
                                                value={this.state.fechaFin}
                                                onChange={this.handleChangemomentFinal}
                                                ampm={false}
                                                className="rounded form-control"
                                                disableFuture={true}
                                                minDate={moment(new Date(this.state.fechaInicio.toString())).format("YYYY-MM-DD HH:mm")}
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={usuario.tipo === "5" ? "col-md-4 col-sm-12 bottom-aligned" : "col-md-3 col-sm-12 bottom-aligned"}  >
                                        <button className="btn btn-success btn-block" onClick={() => this.obtenerDatos()}>CONSULTAR</button>
                                    </div>
                                </div>


                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
                <div>
                    <div className="row" style={{ marginTop: "15px" }}>
                        {permisos.includes('R') ?
                            this.state.loader ?
                                <Loader />
                                :
                                <div className="col col-md-12" style={{ padding: 0 }}>
                                    <Paper style={{ padding: '5px' }}>

                                        <MDBDataTable
                                            //striped
                                            className="col-md-12"
                                            searchLabel="Buscar"
                                            paginationLabel={["Anterior", "Siguente"]}
                                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                                            entriesLabel="Filas a mostrar"
                                            tbodyColor="primary-color"
                                            theadColor="#FF8800"
                                            exportToCSV={true}
                                            striped
                                            bordered
                                            autoWidth={false}
                                            responsive={true}
                                            hover
                                            data={translados}
                                        />
                                        <Dialog
                                            open={this.state.ventanaDetalles}
                                            onClose={this.handleDetalles}
                                            aria-labelledby="form-dialog-title"
                                            maxWidth={'lg'}
                                            fullWidth={true}
                                            disableBackdropClick={true}
                                        >
                                            <DialogContent >
                                                <div className="row mx-auto">
                                                    <div className="col col-md-3">
                                                        <label htmlFor="numordencompra" className="col-form-label">FECHA</label>
                                                        <input id="numordencompra" disabled className="form-control" type="text" value={this.state.fecha} />
                                                    </div>
                                                    <div className="col col-md-5">
                                                        <label htmlFor="numordencompra" className="col-form-label">TIENDA</label>
                                                        <input id="numordencompra" disabled className="form-control" type="text" value={this.state.tienda} />
                                                    </div>
                                                    <div className="col col-md-4">
                                                        <label htmlFor="numordencompra" className="col-form-label">RESPONSABLE</label>
                                                        <input id="numordencompra" disabled className="form-control" type="text" value={this.state.responsable} />
                                                    </div>
                                                </div>


                                                <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                                    <div className="col col-md-12">
                                                        <MDBDataTable
                                                            //striped
                                                            className="col-md-12"
                                                            searchLabel="Buscar"
                                                            paginationLabel={["Anterior", "Siguente"]}
                                                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                            entriesLabel="Filas a mostrar"
                                                            tbodyColor="primary-color"
                                                            theadColor="#FF8800"
                                                            exportToCSV={true}
                                                            striped
                                                            bordered
                                                            autoWidth={false}
                                                            responsive={true}
                                                            hover
                                                            data={detalles}
                                                        />
                                                    </div>
                                                </div>
                                            </DialogContent>
                                            <DialogActions>
                                                <button className="btn btn-danger" onClick={this.handleDetalles.bind(this)}>CERRAR</button>
                                            </DialogActions>
                                        </Dialog>

                                    </Paper>
                                    {this.state.imprimirPdf ?
                                        <Loader />
                                        :
                                        null}
                                    <div id="capture" style={{ width: '800px', display: 'block', marginTop: '20px' }}>
                                        {this.state.imprimirPdf ?
                                            <table className="tb-terpel" width="712px" height="773px" border="0" align="center" cellpadding="0" cellspacing="0">
                                                <tr>
                                                    <td width="763px" valign="top" className="fondocuadro">
                                                        <table width="712px" border="0" cellpadding="0" cellspacing="0">
                                                            <tr>
                                                                <td width="169"><img src={logoTerpel} width="138" height="63" alt="" /></td>
                                                                <td width="335" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                    <tr>
                                                                        <td align="center">TRASLADO ENTRE BODEGA</td>
                                                                    </tr>
                                                                </table></td>
                                                                <td width="106" align="center"><img src={logoDeuna} width="64" height="64" alt="" /></td>
                                                            </tr>
                                                        </table>
                                                        <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                            <tr>
                                                                <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                            </tr>
                                                        </table>
                                                        <table width="712px" border="0" cellpadding="0" cellspacing="3">
                                                            <tr>
                                                                <td width="299" valign="top"><b className="fontipo">Tienda:</b> {movimiento.empresa.alias}</td>
                                                                <td width="66" align="left" valign="top"><b>Razon social: </b></td>
                                                                <td width="233" align="left" valign="top">{movimiento.empresa.razon_social}</td>
                                                            </tr>
                                                            <tr>
                                                                <td valign="top"><b>Nit: </b>{movimiento.empresa.nit}</td>
                                                                <td align="left" valign="top"></td>
                                                                <td align="left" valign="top"><font className="numero2"></font></td>
                                                            </tr>
                                                        </table>
                                                        <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                            <tr>
                                                                <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                            </tr>
                                                        </table>
                                                        <table width="712px" border="0" cellpadding="3" cellspacing="0">
                                                            <tr>
                                                                <td width="80" className="fontipo"><b>Responsable</b></td>
                                                                <td width="219" align="left" valign="top">{movimiento.responsable_info.responsable}</td>
                                                                <td width="80" align="left" valign="top"><b>No.</b></td>
                                                                <td width="219" align="left" valign="top"><font className="numero2">{movimiento.consecutivo}</font></td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>ID</b></td>
                                                                <td align="left" valign="top" className="numero2">{movimiento.responsable_info.identificacion}</td>
                                                                <td align="left" valign="top"><b>Fecha: </b></td>
                                                                <td align="left" valign="top"><font className="numero2">{moment(movimiento.fecha).format("DD/MM/YYYY hh:mm a")}</font></td>
                                                            </tr>
                                                        </table>
                                                        <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                            <tr>
                                                                <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                            </tr>
                                                        </table>
                                                        <table width="712px" border="0" cellpadding="3" cellspacing="0">
                                                            <tr >
                                                                <td width="80" align="left" valign="top"><b>Bodega inicial:</b></td>
                                                                <td width="219" align="left" valign="top">{detalle[0].bodega_inicial}</td>
                                                                <td width="80" align="left" valign="top"><b>Bodega final:</b></td>
                                                                <td width="219" align="left" valign="top">{detalle[0].bodega_final}</td>
                                                            </tr>
                                                        </table>
                                                        <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                            <tr>
                                                                <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                            </tr>
                                                        </table>
                                                        <table width="712px" border="0" cellspacing="0" cellpadding="3">
                                                            <tr>
                                                                <td width="40" align="center" bgcolor="#EEEEEE"><b>COD</b></td>
                                                                <td width="182" align="left" bgcolor="#EEEEEE"><b>PRODUCTO</b></td>
                                                                <td width="37" align="right" bgcolor="#EEEEEE"><b>CANTIDAD</b></td>
                                                                <td width="46" align="right" bgcolor="#EEEEEE"><b>COSTO U.</b></td>
                                                                <td width="70" align="right" bgcolor="#EEEEEE"><b>COSTO T.</b></td>
                                                            </tr>
                                                        </table>
                                                        {detalle.map((row) => {

                                                            total = total + (row.cantidad_bodega_final * row.costo_producto_bodega_final)
                                                            return (
                                                                <div>
                                                                    <table width="712px" border="0" cellspacing="0" cellpadding="3" >
                                                                        <tr>
                                                                            <td width="40" align="center" valign="middle" className="numero2">{row.plu}</td>
                                                                            <td width="182" valign="middle" align="left" className="numero2">{row.producto}</td>
                                                                            <td width="37" align="right" valign="middle" className="numero2">{row.cantidad_bodega_final}</td>
                                                                            <td width="46" align="right" valign="middle" className="numero2">{'$ ' + this.setearMiles(row.costo_producto_bodega_final)}</td>
                                                                            <td width="70" align="right" valign="middle" className="numero2">{'$ ' + this.setearMiles(row.cantidad_bodega_final * row.costo_producto_bodega_final)}</td>
                                                                        </tr>
                                                                    </table>
                                                                    <table className="tb-2" width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                        <tr>
                                                                            <td><img src={linea2} width="712px" height="5" alt="" /></td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                        )}
                                                        <table width="712px" border="0" cellpadding="0" cellspacing="0">
                                                            <tr >
                                                                <td width="100" valign="middle" ><table width="100" border="0" align="right" cellpadding="3" cellspacing="0">
                                                                    <tr >
                                                                        <td width="54" align="right" valign="middle" class="numero2" >{'$ ' + this.setearMiles(total)}</td>
                                                                    </tr>
                                                                </table></td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                            :
                                            null}
                                    </div>
                                </div> : null}

                    </div>
                </div>
            </div>
        )
    }
}
export default Traslados