import React, { Component } from 'react'
import CardProducto from '../../../components/Card/card/cardProducto'
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios'

import { MDBDataTable } from 'mdbreact';
import Paper from '@material-ui/core/Paper';
import "./producto.css"
import FormularioProducto from "../../../components/Formularios/FormularioProducto"
import ObtencionProducto from "../../../components/Formularios/dialogs/dialogConfiguracionProducto"
import Server from '../../../lib/server'
import Loader from '../../../components/Loader'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide"
import store from '../../../lib/store'
import '../../usuarios.css'
import swal from 'sweetalert'
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditICon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import excelIcon from "../../../assets/img/excel.png";
import Codebar from "../../../assets/icons/codebar-24.png";
import DialogContent from '@material-ui/core/DialogContent';
//import OjoIcon from '@material-ui/icons/Ojo';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Ojoicon from "@material-ui/icons/RemoveRedEye"
import ActivarIcon from "@material-ui/icons/Check"
import ReactExport from "react-export-excel";
import moment from 'moment';
import DialogActions from '@material-ui/core/DialogActions';
import readXlsxFile from 'read-excel-file'
import { Store } from 'mqtt';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var busquedaUsuario = []
var datosMostrarUsuario = []
var catidadTotal = 0
var productosMostrar = []
var dominio = ''
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
var data = {}
var dataTienda = {}
var animacion = ""
var nivel = ""
const idModulo = 13
var permisos = ''
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
var usuario = ""
var moneda = ""
class Productos extends Component {
    constructor() {
        super();
        this.state = {
            //Variables para la creacion de nuevo producto
            ventanaCreacion: false,
            ventanaObtencion: false,
            tabla: false,
            prodcutosbd: [],
            //Para prueba es falsoooo
            loader: true,
            productoCrear: null,
            CAMPOBUSCAR: '',
            //PAginacion
            busqueda: false,
            paginadorIndex: 0,
            paginadorMinimo: 0,
            paginadorMaximo: 15,
            paginadorTotal: 16,
            animacionIzquierda: false,
            animacionDerecha: false,
            actualizarDatos: false,
            productoConfigurar: null,
            //tABS
            value: 0,
            datosExcel: [],
            datosExcel2: [],
            datosExcel3: [],
            //Importador
            dialogImportador: false,
            productosAImportar: [],
            loader2: false,
            datosImportador: [],
            datosExceltIENDA: [],
            dialogCodeBar: false,
            dataCodebar: []
        }
        store.subscribe(() => {
            //console.log(store.getState().actualizarvista)
            this.setState({
                ventanaCreacion: store.getState().creacionProducto,
                productoCrear: store.getState().productoEdicion,
                actualizarDatos: store.getState().actualizarvista,
                ventanaObtencion: store.getState().obtencionProductos,
            })
        })
    }


    handleChangeTabla = () => {
        this.setState({
            tabla: !this.state.tabla
        })
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (store.getState().actualizarvista) {
            console.log("acaaa")
            this.obtenerDatos()
            this.datos()
            this.setState({ productoConfigurar: null })
            this.setState({
                busqueda: false
            })
            return true
        }
        return true;
    }

    changetab = (newTab) => {
        this.setState({ value: newTab, busqueda: false, paginadorMinimo: 0, paginadorMaximo: 15, paginadorIndex: 0 })
    }


    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
        switch (name) {
            case 'CAMPOBUSCAR':
                if (this.state.CAMPOBUSCAR.length == 0) {
                    this.setState({ busqueda: false })
                } else {
                    //this.setState({CAMPOBUSCAR:this.state.CAMPOBUSCAR.toUpperCase()})
                }
                break;
        }
    };

    setearMiles = (numero) => {
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case "COP":
                return (
                    (new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(2)))
                )
                break;
            case "USD":
                return (new Intl.NumberFormat("en-IN").format(parseFloat(numero).toFixed(3)))
                break;

        }
    }


    obtenerDatos = () => {
        //this.setState({ actualizarDatos: false })
        var datos = store.getState().usuario
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        nivel = store.getState().usuario.tipo
        var excel = []
        switch (nivel) {
            case "1":
            case "2":
            case "3":
            case "4":
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/identificador/' + dominio, { headers: { Authorization: ntoken } })
                    .then((res) => {
                        //console.log(res)
                        var identf = []
                        if (res.status === 200) {
                            if (res.data.identificadores != null) {
                                res.data.identificadores.map((id) => {
                                    identf.push({
                                        'plu': id.plu,
                                        'descripcion': id.descripcion,
                                        'identificador': id.identificador,
                                        'accion': <button className="btn btn-danger mx-auto" onClick={() => this.eliminarCodebar(id.id)}>ELIMINAR</button>
                                    })
                                })
                            }
                        } else if (res.status === 205) {

                        }
                        this.setState({
                            dataCodebar: identf
                        })

                    })
                    .catch((err) => {
                        console.log(err)
                        this.setState({
                            loader: false
                        })
                    })
                Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/' + parseInt(datos.empresas_id, 10) + "/bash", { headers: { Authorization: ntoken } })
                    .then((res) => {
                        //console.log(res)
                        if (res.data != null) {
                            var codigos = []
                            var plu = []
                            var nombres = []
                            if (res.status === 200) {
                                res.data.Productos.map((producto, key) => {
                                    plu.push(producto.plu)
                                    //console.log(producto)
                                    var cat = ""
                                    if (producto.categorias != null) {
                                        producto.categorias.map((cate) => {
                                            //console.log(cate)
                                            cat += cate.grupo + ", "
                                        })
                                    }
                                    excel.push({
                                        "plu": producto.plu,
                                        "descripcion": producto.descripcion,
                                        "precio": '$ ' + parseInt(producto.precio),
                                        "u_medida": producto.unidades_medida,
                                        "m_valor": parseInt(producto.unidades_medida_valor),
                                        "u_embalaje": producto.unidades_contenido,
                                        "c_valor": parseInt(producto.unidades_contenido_valor),
                                        "categorias": cat,
                                        "c_minima": parseInt(producto.cantidad_minima),
                                        "c_maxima": parseInt(producto.cantidad_maxima),
                                    })

                                    nombres.push(producto.descripcion.toUpperCase())
                                    if (producto.identificadores != null) {
                                        producto.identificadores.map((codigo, key) => {
                                            if (codigo.estado === "A") {
                                                codigos.push(codigo.identificador)
                                            }
                                        })
                                    }
                                })
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            }

                            //console.log(excel)
                            this.setState({ prodcutosbd: res.data.Productos, loader: false, CAMPOBUSCAR: '', datosExcel: excel })
                            store.dispatch({
                                type: "VECTORES_AUXILIARES",
                                auxiliar1: plu,
                                auxiliar2: codigos,
                                auxiliar3: nombres,
                            })
                            this.obtenerVistas()
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        this.setState({
                            loader: false
                        })
                    })
                break
            case "5":
                //console.log(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/convenios/productos/' + parseInt(datos.empresas_id, 10) + '/contratos')
                Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/' + parseInt(datos.empresas_id, 10) + '/empresas', { headers: { Authorization: ntoken } })
                    .then((res) => {
                        //console.log(res.data.data[0].productos_empresas)
                        var data = []
                        var excel = []
                        if (res.status === 200) {
                            var respuesta = res.data.data[0].productos_empresas
                            //console.log(respuesta)
                            if (respuesta != null) {
                                //console.log("aqui")
                                respuesta.map((tienda, key) => {
                                    if (tienda.disponible == "S") {
                                        data.push(tienda)
                                        excel.push({
                                            "1": tienda.plu,
                                            "2": tienda.descripcion,
                                            "3": "$ " + this.setearMiles(tienda.precio),
                                            "4": tienda.cantidad_minima,
                                            "5": tienda.cantidad_maxima,
                                            "6": tienda.precio_flexible === "S" ? "SI" : "NO",
                                            "7": tienda.precio_flexible === "S" ? tienda.precio_tipo_limite === "$" ? ("$ " + this.setearMiles(tienda.precio_minimo)) : (tienda.precio_minimo + "%") : "NO",
                                            "8": tienda.precio_flexible === "S" ? tienda.precio_tipo_limite === "$" ? ("$ " + this.setearMiles(tienda.precio_maximo)) : (tienda.precio_maximo + "%") : "NO",

                                        })
                                    }
                                })
                                //console.log(data)
                                this.setState({ prodcutosbd: data, datosExceltIENDA: excel, loader: false })
                            }
                        } else if (res.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }

                    })
                    .catch((err) => {
                        console.log(err)
                    })
                break
        }

        if (this.state.ventanaCreacion) {
            this.setState({ actualizarDatos: false })
        } else {
            store.dispatch({
                type: "ACTUALIZACION_DATOS",
                actualizarvista: false
            })
        }


    }


    obtenerVistas = () => {
        switch (nivel) {
            case "1":
            case "2":
            case "3":
                return (
                    <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }} >
                        <div className="row">
                            <FormControlLabel
                                className="col-md-4"
                                control={
                                    <Switch
                                        onChange={this.handleChangeTabla.bind(this)}
                                        value="checkedA"
                                    />
                                }
                                label={this.state.tabla ? "CAMBIAR A TARJETAS" : "CAMBIAR A TABLA"}
                            />
                        </div>
                        {this.state.ventanaCreacion ? <button className="btn btn-danger" style={{ margin: '15px' }} onClick={this.crearProducto}>CANCELAR</button> : permisos.includes('C') ? <button className="btn btn-success" style={{ margin: '15px' }} onClick={this.crearProducto}>CREAR NUEVO PRODUCTO</button> : null}
                        <Dialog
                            open={this.state.dialogCodeBar}
                            onClose={this.handleDialogCodebar}
                            aria-labelledby="form-dialog-title"
                            maxWidth={'md'}
                            fullWidth={true}
                            disableBackdropClick={true}
                        >
                            <DialogContent style={this.state.productosAImportar.length < 1 ? { height: '400px' } : {}}>
                                <Paper style={{ padding: '5px' }}>

                                    <MDBDataTable
                                        //striped
                                        className="col-md-12"
                                        searchLabel="Buscar"
                                        paginationLabel={["Anterior", "Siguente"]}
                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                        entriesLabel="Filas a mostrar"
                                        tbodyColor="primary-color"
                                        theadColor="#FF8800"
                                        exportToCSV={true}
                                        striped
                                        bordered
                                        autoWidth={false}
                                        responsive={true}
                                        hover
                                        style={{ fontSize: '13px' }}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: 'PLU',
                                                        field: 'cb',
                                                        sort: 'asc',
                                                        width: 150
                                                    }, {
                                                        label: 'DESCRIPCION',
                                                        field: 'cb',
                                                        sort: 'asc',
                                                        width: 150
                                                    }, {
                                                        label: 'CODIGO DE BARRA',
                                                        field: 'cb',
                                                        sort: 'asc',
                                                        width: 150
                                                    }, {
                                                        label: 'ACCIONES',
                                                        field: 'acc',
                                                        sort: 'asc',
                                                        width: 150
                                                    }

                                                ],
                                                rows: this.state.dataCodebar
                                            }
                                        }
                                    />
                                </Paper>
                            </DialogContent>
                            <DialogActions>
                                <button className="col-md-3  btn btn-danger" onClick={this.handleDialogCodebar.bind(this)}>CERRAR</button>
                            </DialogActions>
                        </Dialog>
                        <ExcelFile
                            filename={"MAESTRO DE PRODUCTOS"}
                            element={<button className="btn btn-success">MAESTRO DE PRODUCTOS <img src={excelIcon} /></button>}
                        >
                            <ExcelSheet data={this.state.datosExcel} name="PRODUCTOS">
                                <ExcelColumn label="PLU" value="plu" />
                                <ExcelColumn label="PRODUCTO" value="descripcion" />
                                <ExcelColumn label="PRECIO" value="precio" />
                                <ExcelColumn label="U. MEDIDA" value="u_medida" />
                                <ExcelColumn label="VALOR" value="m_valor" />
                                <ExcelColumn label="U. CONTENIDO" value="u_embalaje" />
                                <ExcelColumn label="VALOR" value="c_valor" />
                                <ExcelColumn label="CATEGORIAS" value="categorias" />
                                <ExcelColumn label="CANT. MINIMA" value="c_minima" />
                                <ExcelColumn label="CANT. MAXIMA" value="c_maxima" />
                            </ExcelSheet>
                        </ExcelFile>
                        {/*<ExcelFile
                            filename={"LISTA DE PRECIOS"}
                            element={<button className="btn btn-success" style={{ marginLeft: '15px' }}>LISTA DE PRECIOS <img src={excelIcon} /></button>}
                        >
                            <ExcelSheet data={this.state.datosExcel2} name="PRODUCTOS">
                                <ExcelColumn label="CODIGO KIOSCO" value="0" />
                                <ExcelColumn label="KIOSCO" value="0.2" />
                                <ExcelColumn label="PLU" value="0.5" />
                                <ExcelColumn label="DESCRIPCION DEL PRODUCTO" value="1" />
                                <ExcelColumn label="SE PUEDE VENDER" value="1.5" />
                                <ExcelColumn label="PRECIO BASE (ACTUAL)" value="2" />
                                <ExcelColumn label="PRECIO VENTA EN EL KIOSCO (ACTUAL)" value="2.5" />
                                <ExcelColumn label="PRECIO FLEXIBLE" value="3" />
                                <ExcelColumn label="PRECIO MINIMO" value="4" />
                                <ExcelColumn label="PRECIO MAXIMO" value="5" />
                                <ExcelColumn label="NUEVO PRECIO BASE (2020)" value="6" />
                                <ExcelColumn label="NUEVO PRECIO VENTA EN LA ESTACION (2020)" value="7" />
                            </ExcelSheet>
                        </ExcelFile>*/}
                        {/*<ExcelFile
                            filename={"LISTA DE COSTOS"}
                            element={<button className="btn btn-success" style={{ marginLeft: '15px' }}>LISTA DE COSTOS <img src={excelIcon} /></button>}
                        >
                            <ExcelSheet data={this.state.datosExcel3} name="PRODUCTOS">
                                <ExcelColumn label="KIOSCO" value="0" />
                                <ExcelColumn label="PLU" value="0.2" />
                                <ExcelColumn label="EAN" value="0.5" />
                                <ExcelColumn label="PRODUCTO" value="1" />
                                <ExcelColumn label="PROVEEDOR" value="1.5" />
                                <ExcelColumn label="NETO" value="2" />
                                <ExcelColumn label="BRUTO" value="2.5" />
                                <ExcelColumn label="PVP" value="3" />
                                <ExcelColumn label="PVP FLEXIBLE MINIMO" value="4" />
                                <ExcelColumn label="PVP FLEXIBLE MAXIMO" value="5" />
                                <ExcelColumn label="PVP KCO" value="6" />
                                <ExcelColumn label="REGIONAL" value="7" />
                            </ExcelSheet>
                        </ExcelFile>*/}
                        {permisos.includes('U') ? <button className="btn btn-success" style={{ marginLeft: '15px' }} onClick={this.handleDialogImportar.bind(this)}>IMPORTAR <img src={excelIcon} /></button> : null}
                        {permisos.includes('U') ? <button className="btn btn-warning" style={{ marginLeft: '15px' }} onClick={this.handleDialogCodebar.bind(this)}>INACTIVOS <img src={Codebar} /></button> : null}
                        {this.state.ventanaCreacion ?
                            <Dialog fullScreen open={this.state.ventanaCreacion} onClose={this.crearProducto} TransitionComponent={Transition}>
                                <FormularioProducto producto={this.state.productoCrear} />
                            </Dialog>
                            :
                            null}
                        {!this.state.tabla
                            ?
                            <div className="row ">
                                <AppBar position="static" color="default">
                                    {
                                        moneda === "USD"
                                            ?
                                            <Tabs
                                                value={this.state.value}
                                                variant="scrollable"
                                                scrollButtons="on"
                                                indicatorColor="primary"
                                                textColor="primary"
                                                style={{ zIndex: '800 !IMPORTANT' }}
                                            >

                                                <Tab label="Todos" onClick={() => this.changetab(0)} icon={<ListIcon />} />
                                                <Tab label="Activos" onClick={() => this.changetab(1)} icon={<ListIcon />} />
                                                <Tab label="Inactivos" onClick={() => this.changetab(2)} icon={<ListIcon />} />
                                                <Tab label="Favoritos" onClick={() => this.changetab(6)} icon={<ListIcon />} />

                                            </Tabs>
                                            :
                                            <Tabs
                                                value={this.state.value}
                                                variant="scrollable"
                                                scrollButtons="on"
                                                indicatorColor="primary"
                                                textColor="primary"
                                                style={{ zIndex: '800 !IMPORTANT' }}
                                            >

                                                <Tab label="Todos" onClick={() => this.changetab(0)} icon={<ListIcon />} />
                                                <Tab label="Activos" onClick={() => this.changetab(1)} icon={<ListIcon />} />
                                                <Tab label="Inactivos" onClick={() => this.changetab(2)} icon={<ListIcon />} />
                                                <Tab label="Dispensados" onClick={() => this.changetab(3)} icon={<ListIcon />} />
                                                <Tab label="Producidos" onClick={() => this.changetab(4)} icon={<ListIcon />} />
                                                <Tab label="Compuestos" onClick={() => this.changetab(5)} icon={<ListIcon />} />
                                                <Tab label="Favoritos" onClick={() => this.changetab(6)} icon={<ListIcon />} />
                                                <Tab label="Ingredientes" onClick={() => this.changetab(7)} icon={<ListIcon />} />

                                            </Tabs>
                                    }

                                </AppBar>
                                <Paper style={{ width: '100vw' }}>

                                    <Dialog
                                        open={this.state.dialogImportador}
                                        onClose={this.handleDialogImportar}
                                        aria-labelledby="form-dialog-title"
                                        maxWidth={'lg'}
                                        fullWidth={true}
                                        disableBackdropClick={true}
                                    >
                                        <DialogContent style={this.state.productosAImportar.length < 1 ? { height: '400px' } : {}}>
                                            <div className="row mx-auto bottom-aligned" style={{ marginTop: '10px' }}>
                                                <div className="col-md-5">
                                                    <h3>ADJUNTE EL ARCHIVO</h3>
                                                </div>
                                                <div className="col-md-4">
                                                    <input type="file" id="input" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" />
                                                </div>
                                            </div>
                                            {this.state.loader2 ? <Loader></Loader> : null}
                                            {this.state.productosAImportar.length < 1 ? null : <div className="row mx-auto bottom-aligned" style={{ marginTop: '10px' }}>
                                                <div className="col col-md-11 mx-auto">
                                                    <Paper style={{ padding: '5px' }}>

                                                        <MDBDataTable
                                                            //striped
                                                            className="col-md-12"
                                                            searchLabel="Buscar"
                                                            paginationLabel={["Anterior", "Siguente"]}
                                                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                            entriesLabel="Filas a mostrar"
                                                            tbodyColor="primary-color"
                                                            theadColor="#FF8800"
                                                            exportToCSV={true}
                                                            striped
                                                            bordered
                                                            autoWidth={false}
                                                            responsive={true}
                                                            style={{ fontSize: '13px' }}
                                                            hover
                                                            data={
                                                                {
                                                                    columns: [

                                                                        {
                                                                            label: 'KIOSCO',
                                                                            field: 'kiosco',
                                                                            width: 150
                                                                        }, {
                                                                            label: 'PLU',
                                                                            field: 'plu',
                                                                            width: 150
                                                                        }, {
                                                                            label: 'PRODUCTO',
                                                                            field: 'producto',
                                                                            width: 150
                                                                        }, {
                                                                            label: 'SE VENDE',
                                                                            field: 'se_vende',
                                                                            width: 150
                                                                        }, {
                                                                            label: 'PRECIO BASE',
                                                                            field: 'precio',
                                                                            width: 150
                                                                        }, {
                                                                            label: 'FLEXIBLE',
                                                                            field: 'precio_flexible',
                                                                            width: 150
                                                                        }, {
                                                                            label: 'PRECIO MINIMO',
                                                                            field: 'precio_minimo',
                                                                            width: 150
                                                                        }, {
                                                                            label: 'PRECIO MAXIMO',
                                                                            field: 'precio_maximo',
                                                                            width: 150
                                                                        }, {
                                                                            label: 'PRECIO EN EL KIOSCO',
                                                                            field: 'precio_kiosco',
                                                                            width: 150
                                                                        },
                                                                    ],
                                                                    rows:
                                                                        this.state.productosAImportar.map(producto => {
                                                                            delete producto.kioscos
                                                                            return{
                                                                                ...producto,
                                                                                precio: `$ ${this.setearMiles(producto.precio)}`,
                                                                                precio_minimo: `$ ${this.setearMiles(producto.precio_minimo)}`,
                                                                                precio_maximo: `$ ${this.setearMiles(producto.precio_maximo)}`,
                                                                                precio_kiosco: `$ ${this.setearMiles(producto.precio_kiosco)}`,
                                                                            
                                                                            }
                                                                         })
                                                                }
                                                            }
                                                        />
                                                    </Paper>
                                                </div>

                                            </div>}

                                        </DialogContent>
                                        <DialogActions>
                                            <button className="col-md-3 btn btn-success" onClick={this.importarPrecios.bind(this)}>FINALIZAR</button>
                                            <button className="col-md-3  btn btn-danger" onClick={this.handleDialogImportar.bind(this)}>CERRAR</button>
                                        </DialogActions>
                                    </Dialog>
                                    {this.state.loader ?
                                        <Loader /> :
                                        <div className="row mx-auto" style={{ marginTop: '15px' }}>
                                            <button disabled={this.state.paginadorMinimo == 0} className="btn btn-sm btn-outline-primary mx-auto" onClick={() => this.pagination('tipo', 'N')}>{"<<"}</button>
                                            <div className="col col-md-6 mx-auto">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon1">#</span>
                                                    </div>
                                                    <input type="text" placeholder="Buscar" onKeyDown={this.keyBuscar} value={this.state.CAMPOBUSCAR} onChange={this.handleChange('CAMPOBUSCAR')} className="form-control mx-auto" />
                                                    {this.state.busqueda ? <div className="input-group-append">
                                                        <button className="btn btn-outline-primary" disabled={!this.state.busqueda} type="button" onClick={() => this.limpiar()}>x</button>
                                                    </div> : null}
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="button" onClick={() => this.buscar()}>BUSCAR</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <button disabled={this.state.paginadorMaximo + 1 >= catidadTotal} className="btn btn-sm btn-outline-primary mx-auto" onClick={() => this.pagination('tipo', 'S')}>{">>"}</button>
                                        </div>}
                                    <div className={animacion} style={{ margin: 10 }}>

                                        {this.state.busqueda
                                            ?
                                            busquedaUsuario.map((user, key) => {
                                                return (
                                                    <div key={key} className={window.innerWidth <= 1400 ? "col-md-4" : "col-md-3"} style={{ marginTop: "10px", padding: "5px" }} >
                                                        <CardProducto infoProducto={user}></CardProducto>
                                                    </div>
                                                )
                                            })
                                            :

                                            datosMostrarUsuario.map((user, key) => {

                                                return (
                                                    <div key={key} className={window.innerWidth <= 1400 ? "col-md-4" : "col-md-3"} style={{ marginTop: "10px", padding: "5px" }} >
                                                        <CardProducto infoProducto={user}></CardProducto>
                                                    </div>
                                                )
                                            })

                                        }
                                    </div>
                                    <div className="row mx-auto">
                                        <div className="col col-md-4  mx-auto          ">
                                            {datosMostrarUsuario.length > 0
                                                ?
                                                <p className="mx-auto">Mostrando {this.state.paginadorMinimo + 1} a {this.state.paginadorMaximo > catidadTotal ? catidadTotal : this.state.paginadorMaximo + 1} de {catidadTotal} productos</p>
                                                :
                                                <p className="mx-auto">No hay productos para mostrar</p>
                                            }

                                        </div>
                                    </div>
                                </Paper >
                            </div>
                            :
                            <div className="row ">
                                <div className="col col-md-12" style={{ padding: 0 }}>
                                    <Paper style={{ padding: '10px' }}>
                                        <Dialog
                                            open={this.state.ventanaObtencion}
                                            onClose={() => this.configurarProducto()}
                                            aria-labelledby="form-dialog-title"
                                            maxWidth={'md'}
                                            fullWidth={true}
                                            style={{ padding: '0' }}
                                            disableBackdropClick={false}
                                        >
                                            <DialogContent style={{ padding: '0' }}>
                                                <ObtencionProducto />
                                            </DialogContent>

                                        </Dialog>
                                        <MDBDataTable
                                            className="col-md-12"
                                            searchLabel="Buscar"
                                            paginationLabel={["Anterior", "Siguente"]}
                                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                                            entriesLabel="Filas a mostrar"
                                            tbodyColor="primary-color"
                                            theadColor="#FF8800"
                                            exportToCSV={true}
                                            striped
                                            bordered
                                            autoWidth={false}
                                            responsive={true}
                                            hover
                                            data={data}
                                        />
                                    </Paper>
                                </div>
                            </div>
                        }

                    </div>
                )
                break
            case "4":
            case "5":
                return (
                    <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                        {this.state.ventanaCreacion ?
                            <Dialog fullScreen open={this.state.ventanaCreacion} onClose={this.crearProducto} TransitionComponent={Transition}>
                                <FormularioProducto producto={this.state.productoCrear} />
                            </Dialog>
                            :
                            null}
                        <ExcelFile
                            filename={"MAESTRO DE PRODUCTOS"}
                            element={<button className="btn btn-success">MAESTRO DE PRODUCTOS <img src={excelIcon} /></button>}
                        >
                            <ExcelSheet data={this.state.datosExceltIENDA} name="PRODUCTOS">
                                <ExcelColumn label="PLU" value="1" />
                                <ExcelColumn label="PRODUCTO" value="2" />
                                <ExcelColumn label="PRECIO" value="3" />
                                <ExcelColumn label="CANT. MINIMA" value="4" />
                                <ExcelColumn label="CANT. MAXIMA" value="5" />
                                <ExcelColumn label="PRECIO FLEXIBLE" value="6" />
                                <ExcelColumn label="PRECIO MINIMO" value="7" />
                                <ExcelColumn label="PRECIO MAXIMO" value="8" />
                            </ExcelSheet>
                        </ExcelFile>
                        <div className="row ">
                            <div className="col col-md-12" style={{ padding: 0 }}>
                                <Paper style={{ padding: '10px' }}>
                                    <Dialog
                                        open={this.state.ventanaObtencion}
                                        onClose={() => this.configurarProducto()}
                                        aria-labelledby="form-dialog-title"
                                        maxWidth={'md'}
                                        fullWidth={true}
                                        style={{ padding: '0' }}
                                        disableBackdropClick={false}
                                    >
                                        <DialogContent style={{ padding: '0' }}>
                                            <ObtencionProducto datosProducto={this.state.productoConfigurar} />
                                        </DialogContent>

                                    </Dialog>
                                    <MDBDataTable
                                        className="col-md-12"
                                        searchLabel="Buscar"
                                        paginationLabel={["Anterior", "Siguente"]}
                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                        entriesLabel="Filas a mostrar"
                                        tbodyColor="primary-color"
                                        theadColor="#FF8800"
                                        exportToCSV={true}
                                        striped
                                        bordered
                                        autoWidth={false}
                                        responsive={true}
                                        hover
                                        order={['plu', 'asc']}
                                        data={dataTienda}
                                    />
                                </Paper>
                            </div>
                        </div>


                    </div>
                )
                break
            default:
                return (
                    <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                        <div className="row">
                            <FormControlLabel
                                className="col-md-12"
                                control={
                                    <Switch
                                        onChange={this.handleChangeTabla.bind(this)}
                                        value="checkedA"
                                    />
                                }
                                label={this.state.tabla ? "CAMBIAR A TRAJETAS" : "CAMBIAR A TABLA"}
                            />
                        </div>
                        {this.state.ventanaCreacion ?
                            <Dialog fullScreen open={this.state.ventanaCreacion} onClose={this.crearProducto} TransitionComponent={Transition}>
                                <FormularioProducto producto={this.state.productoCrear} />
                            </Dialog>
                            :
                            null}

                        <div className="row ">
                            <div className="col col-md-12" style={{ padding: 0 }}>
                                <Paper style={{ padding: '10px' }}>

                                    <MDBDataTable
                                        className="col-md-12"
                                        searchLabel="Buscar"
                                        paginationLabel={["Anterior", "Siguente"]}
                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                        entriesLabel="Filas a mostrar"
                                        tbodyColor="primary-color"
                                        theadColor="#FF8800"
                                        exportToCSV={true}
                                        striped
                                        bordered
                                        autoWidth={false}
                                        responsive={true}
                                        hover
                                        data={data}
                                    />
                                </Paper>
                            </div>
                        </div>


                    </div>
                )
                break
        }
    }

    componentWillMount() {
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        usuario = store.getState().usuario
        moneda = usuario.empresas.paises_moneda
        dominio = store.getState().dominio
        this.obtenerDatos()
        this.obtenerVistas()

        var excel2 = []
        var excel3 = []
        //2020
        //Axios.get('http://192.168.0.100:6005/api/producto/' + store.getState().empresasHijos + "/empresas2020", { headers: { Authorization: ntoken } })
        //Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/154,155,156,158,187,157,170,178,186,264,179/empresas2020', { headers: { Authorization: ntoken } })
        /*Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/' + store.getState().empresasHijos + '/empresas2020', { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.data.data != null) {
                    res.data.data.map((producto) => {
                        excel2.push({
                            "0": producto.codigo,
                            "0.2": producto.alias,
                            "0.5": producto.plu,
                            "1": producto.descripcion,
                            "1.5": producto.puede_vender === "S" ? 'SI' : 'NO',
                            "2": '$ ' + this.setearMiles(parseInt(producto.precio_base)),
                            "2.5": '$ ' + this.setearMiles(parseInt(producto.precio)),
                            "3": producto.precio_flexible === "S" ? 'SI' : 'NO',
                            "4": '$ ' + this.setearMiles(parseInt(producto.precio_minimo)),
                            "5": '$ ' + this.setearMiles(parseInt(producto.precio_maximo)),
                            "6": '$ 0',
                            "7": '$ 0',
                        })
                    })
                    this.setState({
                        datosExcel2: excel2
                    })
                }
            })
            .catch((err) => {
                console.log(err)

            })*/
        //20202
        /*var s2020 = {
            "empresas_id": store.getState().empresasHijos,
            "dominios_id": store.getState().dominio
        }
        Axios.post(Server.hostPrueba+Server.puertoProducto+'/api/producto/empresas2020', s2020, { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.data.data != null) {
                    res.data.data.map((producto) => {
                        var ean = ''
                        producto.ean.map((cod, n) => {
                            if (n === 0) {
                                ean +=cod.identificador
                            } else {
                                ean+= ', ' + cod.identificador
                            }
                        })
                        excel3.push({
                            "0": producto.kco,
                            "0.2": producto.plu,
                            "0.5": ean,
                            "1": producto.producto,
                            "1.5": producto.proveedor_nombre,
                            "2": '$ ' + this.setearMiles(producto.costo),
                            "2.5": '$ ' + this.setearMiles(producto.bruto),
                            "3": '$ ' + this.setearMiles(producto.pvp),
                            "4": '$ ' + this.setearMiles(producto.precio_minimo),
                            "5": '$ ' + this.setearMiles(producto.precio_maximo),
                            "6": '$ ' + this.setearMiles(producto.pvc_kco),
                            "7": producto.regional,
                        })
                    })
                    this.setState({
                        datosExcel3: excel3
                    })
                }


            })
            .catch((err) => {
                console.log(err)

            })*/
    }


    crearProducto = () => {
        store.dispatch({
            type: "CREACION_PRODUCTO",
            creacionProducto: !this.state.ventanaCreacion,
            productoEdicion: null,
            vistaProducto: false
        })
        this.setState({ CAMPOBUSCAR: '' })
    }
    configurarProducto = (producto) => {
        console.log(producto)
        this.setState({ productoConfigurar: producto })
        store.dispatch({
            type: "OBTENCION_PRODUCTOS",
            obtencionProductos: !this.state.ventanaObtencion,
        })
    }

    buscar = (name) => {
        var cadena = this.state.CAMPOBUSCAR.toUpperCase()
        if (cadena.length > 0) {
            this.setState({ busqueda: true })
        } else {
            this.setState({ busqueda: false })
        }
        busquedaUsuario = []
        console.log(productosMostrar)
        productosMostrar.map((tipo, key) => {

            //console.log(tipo)
            if ((tipo.descripcion.toUpperCase()).includes(cadena) || (tipo.plu.toUpperCase()).includes(cadena)) {
                busquedaUsuario.push(tipo)
            } else {

            }
        })
    }
    keyBuscar = (e) => {
        if (e.key === 'Enter') {
            this.buscar()
        } else if (e.key === 'Escape') {
            this.limpiar()
        }
    }

    pagination = (name, str) => {
        switch (name) {
            case 'tipo':
                this.setState({ animacionDerecha: false, animacionIzquierda: false })
                if (str == 'S') {
                    this.setState({ animacionDerecha: true, animacionIzquierda: false, paginadorIndex: this.state.paginadorIndex + 1, paginadorMinimo: this.state.paginadorMaximo + 1, paginadorMaximo: this.state.paginadorMaximo + this.state.paginadorTotal })
                } else {
                    this.setState({ animacionDerecha: false, animacionIzquierda: true, paginadorIndex: this.state.paginadorIndex - 1, paginadorMinimo: (this.state.paginadorMinimo - this.state.paginadorTotal), paginadorMaximo: this.state.paginadorMinimo - 1 })
                }

                this.datos()
                break;
        }
    }


    datos = () => {
        datosMostrarUsuario = []
        var productos = this.state.prodcutosbd
        productosMostrar = []
        var n = this.state.value
        productos.map((producto, key) => {
            //console.log(productos[key])
            switch (n) {
                case 0:
                    productosMostrar.push(productos[key])
                    break;
                case 1:
                    if (producto.estado == "A") {
                        productosMostrar.push(productos[key])
                    }
                    break;
                case 2:
                    if (producto.estado == "I") {
                        productosMostrar.push(productos[key])
                    }
                    break;
                case 3:
                    if (producto.dispensado == "S" && producto.estado == "A") {
                        productosMostrar.push(productos[key])
                    }
                    break;
                case 4:
                    if (producto.tipo == 3 && producto.estado == "A") {
                        productosMostrar.push(productos[key])
                    }
                    break;
                case 5:
                    if (producto.tipo == 2 && producto.estado == "A") {
                        productosMostrar.push(productos[key])
                    }
                    break;
                case 6:
                    if (producto.favorito == "S" && producto.estado == "A") {
                        productosMostrar.push(productos[key])
                    }
                    break;
                case 7:
                    if (producto.ingrediente == "S" && producto.estado == "A") {
                        productosMostrar.push(productos[key])
                    }
                    break;
            }
        })
        //console.log(productosMostrar)
        if (productos.length > 0) {
            for (var i = this.state.paginadorMinimo; i <= this.state.paginadorMaximo; i++) {
                if (productosMostrar[i] != null) {
                    datosMostrarUsuario.push(productosMostrar[i])

                } else {
                    //console.log(this.state.tipoEquipo[i])
                }
            }
        }
        //console.log(productosMostrar)
        catidadTotal = productosMostrar.length
        //console.log("Minimno:" + this.state.paginadorMinimo + " - maximo:" + this.state.paginadorMaximo)
    }



    detallesProducto = (producto) => {
        //console.log(producto)
        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/detalles/' + producto, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status === 200) {
                    store.dispatch({
                        type: "CREACION_PRODUCTO",
                        creacionProducto: !this.state.detalles,
                        productoEdicion: responsee.data.data,
                        vistaProducto: true,
                    })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }



    pulsar = (e) => {
        //console.log(e)
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            alert("pulsaste enter")
        }
    }

    eliminarProducto = (producto) => {
        var usuario = store.getState().usuario
        swal({
            title: "Estas seguro de que quieres desees eliminar un producto?",
            text: "Este producto sera eliminado, pero quedaran sus registros de ventas",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.put(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/' + producto + '/I/' + usuario.p_id, {}, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 201) {
                                swal({
                                    title: "PRODUCTO INACTIVADO CORRECTAMENTE",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            store.dispatch({
                                                type: "ACTUALIZACION_DATOS",
                                                actualizarvista: true
                                            })
                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            } else {
                                swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }

    activarProducto = (producto) => {
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        var usuario = store.getState().usuario

        Axios.put(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/' + producto + '/A/' + usuario.p_id, {}, { headers: { Authorization: ntoken } })
            .then((res) => {
                console.log(res)
                if (res.status == 201) {
                    swal({
                        title: "PRODUCTO ACTIVADO CORRECTAMENTE",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                break;
                            default:

                        }
                    });
                } else if (res.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                }
            })
    }

    editarProducto = (producto) => {
        //console.log(producto)
        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/detalles/' + producto, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status === 200) {
                    store.dispatch({
                        type: "CREACION_PRODUCTO",
                        creacionProducto: !this.state.detalles,
                        productoEdicion: responsee.data.data,
                        vistaProducto: true,
                        vista: true
                    })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    limpiar = () => {
        this.setState({ CAMPOBUSCAR: '', busqueda: false })
    }

    //dialogooo
    handleDialogImportar = () => {
        this.setState({
            dialogImportador: !this.state.dialogImportador,
            productosAImportar: [],
            datosImportador: []
        })
        let t = this
        setTimeout(function () { t.cargarAccion2() }, 200)
    }

    eliminarCodebar = (id) => {
        swal({
            title: "Estas seguro de que quieres eliminar este codigo de barras?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.delete(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/identificador/' + id + "/" + usuario.p_id, { headers: { Authorization: ntoken } })
                        .then(responsee => {
                            //console.log(responsee)
                            if (responsee.status === 200) {
                                swal("ERROR", "ESTE CODIGO DE BARRA NO SE PUEDE ELIMINAR", "warning")
                            } else if (responsee.status == 201) {
                                if (responsee.data.success) {
                                    swal("OK", "SE HA ELIMINADO EL CODIGO DE BARRAS CORRECTAMENTE", "success")
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                }
                            } else if (responsee.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            }

                        })
                        .catch(function (error) {
                            // handle error
                            console.error(error);
                        })
                }
            })
    }

    handleDialogCodebar = () => {
        this.setState({
            dialogCodeBar: !this.state.dialogCodeBar
        })
    }

    limpiarData = (number) => number ? parseFloat(`${number}`.replace(".", "").replace(" ", "").replace("$", "").replace(",", "")) : null

    cargarAccion2 = () => {
        const { dialogImportador } = this.state
        if (dialogImportador) {
            const input = document.getElementById('input')
            const plus = []
            // const all = []
            const productosall = []
            const arrayPositivas = ["SI", "si", "S", "s", "Si", "sI"]
            input.addEventListener('change', () => {
                this.setState({
                    loader2: true
                })
                readXlsxFile(input.files[0]).then((rows) => {
                    console.log(rows)
                    rows.forEach((row, key) => {
                        if (key > 0) {
                            if (!plus.includes(parseInt(row[2]))) {
                                plus.push(parseInt(row[2]))
                                productosall.push({
                                    'kiosco': row[1],
                                    "plu": parseInt(row[2]),
                                    'producto': row[3],
                                    'se_vende': row[4],
                                    "precio": this.limpiarData(row[10]) || 0,
                                    "precio_flexible": row[7] && arrayPositivas.includes(row[7]) ? 'S' : 'N',
                                    "precio_minimo": this.limpiarData(row[8]) || this.limpiarData(row[10]) || 0,
                                    "precio_maximo": this.limpiarData(row[9]) || this.limpiarData(row[10]) || 0,
                                    "precio_kiosco": this.limpiarData(row[11]) || this.limpiarData(row[10]) || 0,
                                    "kioscos": rows.filter(item => item[2] === row[2]).map(itemMap => ({
                                        "kco": itemMap[0],
                                        "precio": this.limpiarData(row[11]) || this.limpiarData(row[10]) || 0
                                    })),
                                })
                            }
                        }
                    })
                    /*rows.forEach((row, key) => {
                        if (key > 0) {
                            console.log(row)
                            productosall.push(row)
                            if (!plus.includes(parseInt(row[2]))) {
                                plus.push(parseInt(row[2]))
                                let precio = 0
                                let preciomin = 0
                                let preciomax = 0



                                    (isNaN(parseFloat(row[10])))
                                    ? precio = parseFloat(row[10].replace(".", "").replace(" ", "").replace("$", "").replace(",", ""))
                                    : precio = row[10]

                                        (isNaN(parseFloat(row[8])))
                                        ? preciomin = parseFloat(row[8].replace(".", "").replace(" ", "").replace("$", "").replace(",", ""))
                                        : preciomin = row[10]

                                            (isNaN(parseFloat(row[10])))
                                            ? preciomax = parseFloat(row[10].replace(".", "").replace(" ", "").replace("$", "").replace(",", ""))
                                            : preciomax = row[10]

                               
                                
                                if (precio >= preciomin && precio <= preciomax) {
                                    all.push({
                                        "plu": parseInt(row[2]),
                                        "precio": parseInt(precio),
                                        "precio_flexible": row[4] && arrayPositivas.includes(row[4]) ? 'S' : 'N',
                                        "precio_minimo": parseFloat(preciomin),
                                        "precio_maximo": parseFloat(preciomax),
                                        "kioscos": [],
                                    })
                                }

                            }
                        }
                    })*/

                }).finally(() => {

                    console.log(productosall)
                    console.log(plus)
                    console.log(JSON.stringify(productosall))
                    /*productosall.map((prr, key) => {
                        all.map((al) => {
                            var precio_kco = 0
                            if (isNaN(parseInt(prr[11]))) {
                                precio_kco = prr[11].replace(".", "").replace(" ", "").replace("$", "").replace(",", "")
                            } else {
                                precio_kco = prr[11]
                            }

                            if (parseInt(prr[2]) === parseInt(al.plu)
                            // && parseInt(precio_kco) >= al.precio_minimo && parseInt(precio_kco) <= al.precio_maximo
                            ) {
                                al.kioscos.push({
                                    "kco": prr[0],
                                    "precio": parseInt(precio_kco)
                                })
                            } else {
                                //productosall.splice(key,1)
                            }
                        })
                    })
                    //console.log(productosall)
                    this.setState({
                        loader2: false,
                        productosAImportar: productosall,
                        datosImportador: all
                    })*/
                    this.setState({
                        loader2: false,
                        productosAImportar: productosall,
                        datosImportador: productosall
                    })
                })
            })
        }
    }

    //Buscamos los id
    /* cargarAccion = () => {

        if (this.state.dialogImportador) {
            const input = document.getElementById('input')
            var plus = []
            console.log(plus)
            var all = []
            var productosall = []
            input.addEventListener('change', () => {
                this.setState({
                    loader2: true
                })
                readXlsxFile(input.files[0]).then((rows) => {
                    rows.map((row, key) => {
                        if (key > 0) {

                            productosall.push(row)
                            if (!plus.includes(parseInt(row[2]))) {
                                plus.push(parseInt(row[2]))
                                var precio = 0
                                var preciomin = 0
                                var preciomax = 0
                                if (isNaN(parseInt(row[10]))) {
                                    precio = row[10].replace(".", "").replace(" ", "").replace("$", "").replace(",", "")
                                } else {
                                    precio = row[10]
                                }
                                if (isNaN(parseInt(row[8]))) {
                                    preciomin = row[8].replace(".", "").replace(" ", "").replace("$", "").replace(",", "")
                                } else {
                                    preciomin = row[8]
                                }
                                if (isNaN(parseInt(row[9]))) {
                                    preciomax = row[9].replace(".", "").replace(" ", "").replace("$", "").replace(",", "")
                                } else {
                                    preciomax = row[9]
                                }

                                if (precio >= preciomin && precio <= preciomax) {
                                    all.push({
                                        "plu": parseInt(row[2]),
                                        "precio": parseInt(precio),
                                        "precio_flexible": row[4] === "SI" || row[4] === "si" || row[4] === "S" || row[4] === "s" ? 'S' : 'N',
                                        "precio_minimo": parseInt(preciomin),
                                        "precio_maximo": parseInt(preciomax),
                                        "kioscos": [],
                                    })
                                }

                            }
                        }
                    })

                }).finally(() => {
                    //console.log(productosall)
                    //console.log(plus)
                    //console.log(all)
                    productosall.map((prr, key) => {
                        all.map((al) => {
                            var precio_kco = 0
                            if (isNaN(parseInt(prr[11]))) {
                                precio_kco = prr[11].replace(".", "").replace(" ", "").replace("$", "").replace(",", "")
                            } else {
                                precio_kco = prr[11]
                            }

                            if (parseInt(prr[2]) === parseInt(al.plu)
                            // && parseInt(precio_kco) >= al.precio_minimo && parseInt(precio_kco) <= al.precio_maximo
                            ) {
                                al.kioscos.push({
                                    "kco": prr[0],
                                    "precio": parseInt(precio_kco)
                                })
                            } else {
                                //productosall.splice(key,1)
                            }
                        })
                    })
                    //console.log(productosall)
                    this.setState({
                        loader2: false,
                        productosAImportar: productosall,
                        datosImportador: all
                    })
                })
            })
        }
    } */

    importarPrecios = () => {
        //IMPORTAR

        var datos = {
            "update_user": store.getState().usuario.p_id,
            "dominios_id": store.getState().dominio,
            "productos": this.state.datosImportador
        }
        var config = {
            onUploadProgress: progressEvent => {
                // let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                // console.log('onUploadProgress',percentCompleted)
            },
            // Old version
            progress(progressEvent) {
                console.log(progressEvent);
            },
            onDownloadProgress: function (progressEvent) {
                //  let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                console.log('onDownloadProgress', progressEvent)
            },
            headers: { Authorization: ntoken }
        }
        Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/importador/lista_precios', datos, config)
            .then(response => {

                console.log(response)
                if (response.status === 201) {
                    swal({
                        title: response.data.mensaje,
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                this.handleDialogImportar()
                                //this.setState({loader:true,descripcionN:'',selectedDate:new Date(),selectedDateFin:new Date(),checkIPorcentaje:true,checkIValor:false,valorn:false,cambbioInicio: false,cambbioFin:false})
                                break;

                            default:

                        }
                    });
                } else if (response.status === 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                }
                else {
                    swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                }
            })

    }



    render() {
        //console.log(nivel)
        //console.log(this.state.prodcutosbd)
        if (nivel != '5') {
            this.datos()
        }
        var imagenProducto = Server.hostMultimedia + dominio + "/productos/default.png"
        var url = Server.hostMultimedia + dominio + "/productos/@id.png" + "?" + moment(new Date()).format("YYYYMMMDDHHmmss")
        if (this.state.animacionIzquierda) {
            animacion = "row siguientePAaina"
        } else if (this.state.animacionDerecha) {
            animacion = "row anteriorPagina"
        } else {
            animacion = "row "
        }
        data = {
            columns: [
                {
                    label: 'imagen',
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Plu',
                    field: 'celular',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Producto',
                    field: 'identificacion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Precio unidad',
                    field: 'direccion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Contenido',
                    field: 'correo',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Empresa',
                    field: 'nombre',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Acciones',
                    field: 'acciones',
                    sort: 'asc',
                    width: 150
                },
            ], rows:
                this.state.prodcutosbd.map((producto, key) => {
                    return (
                        {
                            'imagen': [
                                <img onEronError={(e) => { e.target.onerror = null; e.target.src = imagenProducto }} src={producto.url_foto != null ? (producto.url_foto == 'S' || producto.url_foto.includes('https')) ? ((producto.url_foto == 'S' ? (url.replace("@id", producto.id)) : producto.url_foto)) : imagenProducto : imagenProducto} height="70" width="70"></img>
                            ],
                            'plu': producto.plu,
                            'nombre': producto.descripcion,
                            'precio': '$' + producto.precio,
                            'Contenido': producto.unidades_contenido,
                            'empresa': producto.razon_social,
                            'acciones': [
                                permisos.includes('R') ? <Tooltip title={'Detalles producto'} placement="top"><button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.editarProducto(producto.id)}><Ojoicon /></button></Tooltip> : null,
                                permisos.includes('U') ? <Tooltip title={'Editar'} placement="top"><button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.detallesProducto(producto.id)}><EditICon /></button></Tooltip> : null,
                                permisos.includes('D') ? producto.estado == "A" ? <Tooltip title={'INACTIVAR'} placement="top"><button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.eliminarProducto(producto.id)}><DeleteIcon /></button></Tooltip> : <Tooltip title={'ACTIVAR'} placement="top"><button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.activarProducto(producto.id)}><ActivarIcon /></button></Tooltip> : null,
                            ]

                        }
                    )
                })
        }
        if (nivel == "5") {
            dataTienda = {
                columns: [
                    {
                        label: 'Imagen',
                        field: 'imagen',
                        width: 150
                    }, {
                        label: 'Plu',
                        field: 'plu',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'Producto',
                        field: 'identificacion',
                        width: 150
                    }, {
                        label: 'Precio',
                        field: 'precio',
                        width: 150
                    }, {
                        label: 'P. flexible',
                        field: 'precioflex',
                        width: 150
                    }, {
                        label: 'Cant. minima',
                        field: 'cminima',
                        width: 150
                    }, {
                        label: 'Cant. maxima',
                        field: 'cmaxima',
                        width: 150
                    }, {
                        label: 'Seguimiento',
                        field: 'seguimiento',
                        width: 150
                    }, {
                        label: 'Disponible',
                        field: 'disponible',
                        width: 150
                    }, {
                        label: 'Acciones',
                        field: 'acciones',
                        width: 150
                    },
                ], rows:
                    this.state.prodcutosbd.map((producto, key) => {
                        return (
                            {
                                'imagen': [
                                    <img onError={(e) => { e.target.onerror = null; e.target.src = imagenProducto }} src={producto.url_foto != null ? (producto.url_foto == 'S' || producto.url_foto.includes('https')) ? ((producto.url_foto == 'S' ? (url.replace("@id", producto.pr_id)) : (url.replace("@id", producto.pr_id)))) : imagenProducto : imagenProducto} height="70" width="70"></img>
                                ],
                                'plu': parseInt(producto.plu),
                                'nombre': producto.descripcion,
                                'precio': '$' + producto.precio,
                                'precioflex': producto.precio_flexible === 'S' ? 'SI' : 'NO',
                                'cminima': producto.cantidad_minima,
                                'cmaxima': producto.cantidad_maxima,
                                'sequimiento': producto.sequimiento === 'S' ? 'SI' : 'NO',
                                'disponible': producto.disponible === 'S' ? 'SI' : 'NO',
                                'acciones':
                                    permisos.includes('U') ? <Tooltip title={'Editar'} placement="top"><button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.configurarProducto(producto)}><EditICon /></button></Tooltip> : null

                            }
                        )
                    })

            }
        }




        return (
            this.state.loader
                ?
                <div style={{ height: '100vh', width: '100%', paddingLeft: '15px', paddingRight: '15px' }} className="mx-auto my-auto">
                    <Loader style={{ height: '100vh', width: '100%' }} />
                </div>
                :
                permisos.includes('R')
                    ?
                    this.obtenerVistas()
                    :
                    <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>
        );
    }
}

export default Productos;