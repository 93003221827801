import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react'
import CardUsuario from '../../../components/Card/card/cardUsuario'
import Axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import store from '../../../lib/store'
import Switch from '@material-ui/core/Switch';
import { MDBDataTable } from 'mdbreact';
import "../../../components/Card/card/cardUsuario.css"
import FormularioUsuario from '../../../components/Formularios/FormularioCampania'
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide"
import Paper from '@material-ui/core/Paper';
import Loader from '../../../components/Loader'
import Server from '../../../lib/server'
import { Avatar } from '@material-ui/core';
import swal from 'sweetalert'
import DialogContent from '@material-ui/core/DialogContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import JsBarcode from 'jsbarcode'
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker, TimePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Selects from 'react-select';
import moment from 'moment'
import "moment/locale/es";
import "./campana.css";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import BonosIcon from "@material-ui/icons/ConfirmationNumber"
import CampaniaIcon from "@material-ui/icons/LocalAtm"
import ActivarIcon from "@material-ui/icons/Check"
import EditICon from '@material-ui/icons/Edit';
import ReciboIcon from '@material-ui/icons/Receipt';
import checksum from 'checksum'
var busquedaUsuario = []
var datosMostrarUsuario = []
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}
const idModulo = 42
var permisos = ''
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
const products = [
    { label: '1 -- PAPAS NATURALES' },
    { label: '2 -- PAPAS NATURALES' },
    { label: '3 -- PAPAS NATURALES' },
    { label: '4 -- PAPAS NATURALES' },
    { label: '5 -- PAPAS NATURALES' },
    { label: '6 -- PAPAS NATURALES' },
].map(suggestion => ({
    value: suggestion.label,
    label: suggestion.label,
}));

class usuariosVista extends Component {
    constructor() {
        super();
        this.state = {
            createUSer: false,
            setOpen: false,
            userdetails: -1,
            users: [],
            usersByName: [],
            tabla: false,
            busqueda: false,
            value: 0,
            ventanaEdicion: false,
            usuarioEdicion: null,
            //Busqueda de datos
            busqueda: false,
            paginadorIndex: 0,
            paginadorMinimo: 0,
            paginadorMaximo: 15,
            paginadorTotal: 16,
            //
            animacionIzquierda: false,
            animacionDerecha: false,
            loader: false,
            actualizacionVista: false,
            carnetUsuario: '',
            mostrarCarnet: false,
            detallesCarnet: null,
            //
            expanded: false,
            nombreGuardar: '',
            fechaInicial: new Date(),
            fechaFinal: new Date(),
            dialogNewBono: false,
            bonosbd: [],
            totalBonos: [],
            bonoSeleccionado: {},
            horaInicial: new Date(),
            horaFinal: new Date(),
            valorMinimo: 0,
            valorMaximo: 0,
            tiendasbd: [],
            tiendasSeleccionadas: [],
            peticion: false,
            camapanasbd: [],
            pieDePAgina: '',
            modovista: false,
            modoEditar: false,
            idEditar: '',
            dialogDetalles: false,
            bonosEliminados: [],
            nombresUsados: [],
            response: false,
            //vista
            dialogVista: false,
            voucherSeleccionado: null,
            expandedVistaPrevia:false
        }
        store.subscribe(() => {
            this.setState({
                createUSer: store.getState().creacionUsuario,
                usuarioEdicion: store.getState().usuarioEdicion,
                actualizacionVista: store.getState().actualizarvista
            })
        })
    }

    dialogDetalles = (bono) => {
        this.setState({
            dialogDetalles: true
        })
        this.verDetalles(bono)
    }


    handleDialogBono = () => {
        this.setState({
            dialogNewBono: !this.state.dialogNewBono,
            bonoSeleccionado: {},
            horaInicial: new Date(),
            horaFinal: new Date(),
            valorMinimo: 0,
            valorMaximo: 0
        })
    }

    cerrarFormulario = () => {
        this.setState({
            expanded: false,
            nombreGuardar: '',
            tiendasSeleccionadas: [],
            totalBonos: [],
            fechaInicial: new Date(),
            fechaFinal: new Date(),
            pieDePAgina: '',
            modovista: false,
            modoEditar: false,
            idEditar: '',
            dialogDetalles: false
        })
        document.getElementById("tyttt").innerHTML = ""
    }

    abrirDialogVoucher = (bono) => {
        this.setState({
            voucherSeleccionado: bono,
            dialogVista: true
        })

        setTimeout(function () {
            var texto = bono.encabezado;
            var titulo = bono.bono
            var output = texto.split('');
            var mititulo = titulo.split('');
            var item = 0;
            var string = "<span class='estrellita'>";
            for (var i = 0, max = 47; i < max; i++) {
                string += "*";
            }
            string += "<span></br></br>";
            string += "<span class='title'>";
            for (i in mititulo) {
                string += mititulo[i];
                if (item == 47) {
                    string += "</br>";
                    item = 0;
                }
                item++;
            }
            string += "</span>";
            string += "</br></br>";
            string += "<span class='body'>";
            var string2 = "";
            item = 0;
            for (i in output) {
                string2 += output[i].replace("@","");
                if (item == 47) {
                    string2 += "</br>";
                    item = 0;
                }
                if(output[i] != "@"){
                    item++;   
                }
            }
            string += string2 + "</span>";
            string += "</br>";
            for (var i = 0, max = 47; i < max; i++) {
                string += "*";
            }
            document.getElementById("impresora").innerHTML = string
        }, 200)
        setTimeout(function () {
            //console.log(bono.codigo)
            console.log(bono.codigo.length)
            var codigo = bono.codigo
            while (codigo.length < 12) {
                codigo = "0" + codigo
            }
            //console.log(codigo.length)
            //console.log(codigo)
            JsBarcode("#barcodee", codigo, {
                format: "ean13",
                flat: true
            })
        }, 200)

    }




    cerrarDialogVoucher = () => {
        this.setState({
            voucherSeleccionado: null,
            dialogVista: false
        })
    }

    verDetalles = (campana) => {
        var empresasn = []
        var bonosn = []
        var bonoselim = []
        if (campana.empresas_final != null) {
            campana.empresas_final.map((dato) => {
                empresasn.push({
                    value: dato.id,
                    label: dato.alias
                })
            })
        }
        if (campana.bonos != null) {
            campana.bonos.map((dato) => {
                bonosn.push({
                    "id": dato._id,
                    "bono": dato.descripcion,
                    "valorMinimo": dato.valor_minimo,
                    "valorMaximo": dato.valor_maximo,
                    "horaInicio": "1900-01-01 " +dato.hora_inicio,
                    "horaFin": "1900-01-01 " +dato.hora_fin,
                    "encabezado": dato.encabezado,
                    "codigo": dato.codigo_barras
                })
                bonoselim.push({
                    value: dato._id,
                    label: dato.descripcion,
                    bono: dato
                })
            })
        }
        this.setState({
            expanded: true,
            modovista: true,
            modoEditar: false,
            nombreGuardar: campana.descripcion,
            fechaInicial: campana.fecha_inicio,
            fechaFinal: campana.fecha_fin,
            pieDePAgina: campana.pie_pagina,
            tiendasSeleccionadas: empresasn,
            totalBonos: bonosn,
            bonosEliminados: bonoselim
        })
        setTimeout(this.cargarVistaPrevia(campana.pie_pagina), 200)
        
    }

    verEditar = (campana) => {
        var empresasn = []
        var bonosn = []
        if (campana.empresas_final != null) {
            campana.empresas_final.map((dato) => {
                empresasn.push({
                    value: dato.id,
                    label: dato.alias
                })
            })
        }
        if (campana.bonos != null) {
            campana.bonos.map((dato) => {
                bonosn.push({
                    "id": dato._id,
                    "bono": dato.descripcion,
                    "valorMinimo": dato.valor_minimo,
                    "valorMaximo": dato.valor_maximo,
                    "horaInicio": "1900-01-01 " + dato.hora_inicio,
                    "horaFin": "1900-01-01 " + dato.hora_fin,
                })
            })
        }
        this.setState({
            expanded: true,
            modovista: false,
            modoEditar: true,
            idEditar: campana._id,
            nombreGuardar: campana.descripcion,
            fechaInicial: campana.fecha_inicio,
            fechaFinal: campana.fecha_fin,
            pieDePAgina: campana.pie_pagina,
            tiendasSeleccionadas: empresasn,
            totalBonos: bonosn
        })
        setTimeout(this.cargarVistaPrevia(campana.pie_pagina), 200)
    }


    handleChangeTab = (event, value) => {
        this.setState({ value });
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
        if(name === "pieDePAgina"){
            this.cargarVistaPrevia(this.state.pieDePAgina)
        }
    };

    cargarVistaPrevia = (txt) =>{
        var texto = txt;
        var output = texto.split('');
        var item = 0;
        var string = "";
        string += "<span class='tycondiciones'>";
        var string2 = "";
        item = 0;
        var i =0
        for (i in output) {
            string2 += output[i].replace("@","");
            if (item == 64) {
                string2 += "</br>";
                item = 0;
            }
            if(output[i] != "@"){
                item++;   
            }
        }
        string += string2 + "</span>";
        string += "</br>";
        document.getElementById("tyttt").innerHTML = "<pre>"+string+"</pre>"
    }

    handleChangemoment = date => {
        this.setState({ fechaInicial: date });
    }
    handleChangemomentFinal = date => {
        this.setState({ fechaFinal: date });
    }

    handleChangemomentHora = date => {
        this.setState({ horaInicial: date });
    }
    handleChangemomentFinalHora = date => {
        this.setState({ horaFinal: date });
    }

    infoUsusarioClosed = () => {
        console.log("define is equals to ")
    }



    handleUsername(e) {
        let s = ""
        s = e.target.value
    }

    expandir = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizacionVista) {
            console.log("desde vista")
            this.obtenerDatos()
            return true
        }
        return true;
    }


    validarCampana = () => {
        if (this.state.nombreGuardar.trim === "" || this.state.nombreGuardar.length === 0) {
            swal("FALLA AL CREAR", "EL NOMBRE DE LA CAMPAÑA ES OBLIGATORIO", "warning")
            return false
        } else if (this.state.nombreGuardar.length < 1 || this.state.nombreGuardar.length > 50) {
            swal("Fallo al crear", "LA LONGITUD DEL NOMBRE ES INVALIDA", "warning")
            return false
        }  else if (moment(new Date(this.state.fechaFinal.toString())).format("YYYY-MM-DD HH:mm:ss") <= moment(new Date(this.state.fechaInicial.toString())).format("YYYY-MM-DD HH:mm:ss")) {
            swal("FALLA AL CREAR", "LA FECHA INICIAL DEBE SER MAYOR A LA FECHA FINAL", "warning")
            return false
        } else if (!this.state.modoEditar && this.state.nombresUsados.includes(this.state.nombreGuardar)) {
            swal("FALLA AL CREAR", "YA EXISTE UNA CAMPAÑA CON ESTE NOMBRE", "warning")
            return false
        }else if (this.state.pieDePAgina.length < 1 || this.state.pieDePAgina.length > 255) {
            swal("Fallo al crear", "LA LONGITUD DEL PIE DE PAGINA ES INVALIDA", "warning")
            return false
        }  else {
            this.setState({
                response: true,
                peticion: true
            })
            return true
        }

    }

    inactivarCampana = (id) => {
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        var usuario = store.getState().usuario
        var data = {
            "_id": this.toCheckSum(id+""),
            "estado": "I",
            "create_user": this.toCheckSum(usuario.p_id+"")
        }
        swal({
            title: "Estas seguro de que quieres inactivar esta campaña?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.put(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/campanas/switch', data, { headers: { Authorization: ntoken, key: Server.server_key } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 200) {
                                swal({
                                    title: "CAMPAÑA INACTIVADA CORRECTAMENTE",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            /*store.dispatch({
                                                 type: "ACTUALIZACION_DATOS",
                                                 actualizarvista: true
                                             })*/
                                            this.obtenerDatos()
                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            } else {
                                swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }

    activarCampana = (id) => {
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        var usuario = store.getState().usuario
        var data = {
            "_id": this.toCheckSum(id+""),
            "estado": "A",
            "create_user": this.toCheckSum(usuario.p_id+"")
        }
        swal({
            title: "Estas seguro de que quieres activar esta campaña?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.put(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/campanas/switch', data, { headers: { Authorization: ntoken, key: Server.server_key } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 200) {
                                swal({
                                    title: "CAMPAÑA ACTIVADA CORRECTAMENTE",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            /*store.dispatch({
                                                 type: "ACTUALIZACION_DATOS",
                                                 actualizarvista: true
                                             })*/
                                            this.obtenerDatos()
                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            } else {
                                swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }

    registrarCamapana = () => {
        var validar = false
        validar = this.validarCampana()
        var usuario = store.getState().usuario
        var datos = {
            "descripcion": this.state.nombreGuardar,
            "empresas_id": this.toCheckSum(usuario.empresas_id+""),
            "fecha_inicio": moment(new Date(this.state.fechaInicial.toString())).format("YYYY-MM-DD"),
            "fecha_fin": moment(new Date(this.state.fechaFinal.toString())).format("YYYY-MM-DD"),
            "create_user": this.toCheckSum(usuario.p_id+""),
            "pie_pagina": this.state.pieDePAgina,
            "bonos":
                this.state.totalBonos.map((bono) => {
                    return (
                        {
                            "_id": this.toCheckSum(bono.id+""),
                            "valor_minimo": parseFloat(bono.valorMinimo),
                            "valor_maximo": parseFloat(bono.valorMaximo),
                            "hora_inicio": moment(new Date(bono.horaInicio.toString())).format("HH:mm:ss"),
                            "hora_fin": moment(new Date(bono.horaFin.toString())).format("HH:mm:ss")
                        }
                    )
                }),
            "empresas_final_id":
                this.state.tiendasSeleccionadas.map((tienda) => {
                    return (
                        {
                            "_id": this.toCheckSum(tienda.value+"")
                        }
                    )
                }),
        }
        if (validar) {
            console.log(datos)
            Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/campanas', datos, { headers: { Authorization: ntoken, key: Server.server_key } })
                .then((res) => {
                    console.log(res)
                    this.setState({
                        peticion: false,
                        response: false
                    })
                    if (res.status === 200) {
                        if (res.data.success) {
                            swal({
                                title: "Campaña creada correctamente",
                                icon: "success",
                                closeOnClickOutside: false,
                                buttons: {
                                    Seguir: {
                                        text: "OK",
                                        value: "continuar"
                                    }
                                },
                            }).then((value) => {
                                switch (value) {

                                    case "continuar":
                                        store.dispatch({
                                            type: "ACTUALIZACION_DATOS",
                                            actualizarvista: true
                                        })
                                        this.cerrarFormulario()
                                        break;

                                    default:

                                }
                            });
                        } else {
                            swal("Fallo al guardar", res.data.message, "warning");
                        }
                    } else if (res.status === 205) {
                        swal("ERROR", "LA SESION HA CADUCADO", "warning")
                        setTimeout(function () { window.location.href = '/'; }, 3000);
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                    }
                })
                .catch((err) => {
                    swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                })
        }
    }

    editarCampana = () => {
        var validar = false
        validar = this.validarCampana()
        var usuario = store.getState().usuario
        var datos = {
            "_id": this.toCheckSum(this.state.idEditar+""),
            "descripcion": this.state.nombreGuardar,
            "empresas_id": this.toCheckSum(usuario.empresas_id+""),
            "fecha_inicio": moment(new Date(this.state.fechaInicial.toString())).format("YYYY-MM-DD"),
            "fecha_fin": moment(new Date(this.state.fechaFinal.toString())).format("YYYY-MM-DD"),
            "create_user": this.toCheckSum(usuario.p_id+""),
            "pie_pagina": this.state.pieDePAgina,
            "bonos":
                this.state.totalBonos.map((bono) => {
                    console.log(bono)
                    return (
                        {
                            "_id": this.toCheckSum(bono.id+""),
                            "valor_minimo": parseFloat(bono.valorMinimo),
                            "valor_maximo": parseFloat(bono.valorMaximo),
                            "hora_inicio": moment(new Date(bono.horaInicio.toString())).format("HH:mm:ss"),
                            "hora_fin": moment(new Date(bono.horaFin.toString())).format("HH:mm:ss")
                        }
                    )
                }),
            "empresas_final_id":
                this.state.tiendasSeleccionadas.map((tienda) => {
                    return (
                        {
                            "_id": this.toCheckSum(tienda.value+"")
                        }
                    )
                }),
        }
        if (validar) {
            console.log(datos)
            Axios.put(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/campanas', datos, { headers: { Authorization: ntoken, key: Server.server_key } })
                .then((res) => {

                    this.setState({
                        peticion: false,
                        response: false
                    })
                    if (res.status === 200) {
                        if (res.data.response.success) {
                            swal({
                                title: "Campaña editada correctamente",
                                icon: "success",
                                closeOnClickOutside: false,
                                buttons: {
                                    Seguir: {
                                        text: "OK",
                                        value: "continuar"
                                    }
                                },
                            }).then((value) => {
                                switch (value) {

                                    case "continuar":
                                        store.dispatch({
                                            type: "ACTUALIZACION_DATOS",
                                            actualizarvista: true
                                        })
                                        this.cerrarFormulario()
                                        break;

                                    default:

                                }
                            });
                        } else {
                            swal("Fallo al guardar", res.data.message, "warning");
                        }
                    } else if (res.status === 205) {
                        swal("ERROR", "LA SESION HA CADUCADO", "warning")
                        setTimeout(function () { window.location.href = '/'; }, 3000);
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                    }
                })
                .catch((err) => {
                    swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                })
        }
    }

    agregarBono = () => {
        var bonosActuales = this.state.totalBonos
        var bonoRechazado = false
        var mensajeRechazado = ""
        bonosActuales.map((bono) => {
            if (moment(new Date(this.state.horaInicial.toString())).format("HH:mm:ss") >= moment(new Date(bono.horaInicio.toString())).format("HH:mm:ss") && moment(new Date(this.state.horaInicial.toString())).format("HH:mm:ss") <= moment(new Date(bono.horaFin.toString())).format("HH:mm:ss")) {
               
                if (parseFloat(this.state.valorMinimo) > parseFloat(bono.valorMaximo) || parseFloat(this.state.valorMaximo) < parseFloat(bono.valorMinimo)) {

                } else {
                    mensajeRechazado = "LA HORA DE INICIO NO PUEDE ESTAR DENTRO DEL RANGO DE HORAS DE UN BONO EXISTENTE EN ESTA CAMPAÑA"
                    bonoRechazado = true
                }
            } else if (moment(new Date(this.state.horaFinal.toString())).format("HH:mm:ss") >= moment(new Date(bono.horaInicio.toString())).format("HH:mm:ss") && moment(new Date(this.state.horaFinal.toString())).format("HH:mm:ss") <= moment(new Date(bono.horaFin.toString())).format("HH:mm:ss")) {
                if (parseFloat(this.state.valorMinimo) > parseFloat(bono.valorMaximo) || parseFloat(this.state.valorMaximo) < parseFloat(bono.valorMinimo)) {

                } else {
                    mensajeRechazado = "LA HORA FINAL NO PUEDE ESTAR DENTRO DEL RANGO DE HORAS DE UN BONO EXISTENTE EN ESTA CAMPAÑA"
                    bonoRechazado = true
                }
            } else if (parseInt(this.state.bonoSeleccionado.value) === parseInt(bono.id)) {
                mensajeRechazado = "NO PUEDES AGREGAR EL MISMO BONO DOS VECES A UNA CAMPAÑA"
                bonoRechazado = true
            }

        })
        if (moment(new Date(this.state.horaFinal.toString())).format("YYYY-MM-DD HH:mm:ss") <= moment(new Date(this.state.horaInicial.toString())).format("YYYY-MM-DD HH:mm:ss")) {
            swal("EROR", "LA HORA FINAL DEBE SER MAYOR A LA HORA INICIAL", "warning")
        } else if (parseFloat(this.state.valorMaximo) <= parseFloat(this.state.valorMinimo)) {
            swal("ERROR", "EL VALOR MAXIMO DEBE SER MAYOR AL VALOR MIXIMO", "warning")
        } else if (JSON.stringify(this.state.bonoSeleccionado) === "{}") {
            swal("ERROR", "DEBE SELECCIONAR EL BONO", "warning")
        } else if (this.state.valorMinimo.trim === "" || this.state.valorMinimo.length === 0) {
            swal("ERROR", "DEBE INGRESAR EL VALOR MINIMO DEL BONO", "warning")
        } else if (this.state.valorMaximo.trim === "" || this.state.valorMaximo.length === 0) {
            swal("ERROR", "DEBE INGRESAR EL VALOR MAXIMO DEL BONO", "warning")
        } else if (bonoRechazado) {
            swal("ERROR", mensajeRechazado, "warning")
        } else {
            var bonosTotales = this.state.bonosbd
            var bonosTotalesN = this.state.bonosEliminados
            bonosActuales.push({
                "id": /*this.checkSumId(this.state.bonoSeleccionado.value+"")*/this.state.bonoSeleccionado.value,
                "bono": this.state.bonoSeleccionado.label,
                "valorMinimo": this.state.valorMinimo,
                "valorMaximo": this.state.valorMaximo,
                "horaInicio": "1900-01-01 " + moment(new Date(this.state.horaInicial)).format("HH:mm:ss"),
                "horaFin": "1900-01-01 " + moment(new Date(this.state.horaFinal)).format("HH:mm:ss"),
            })
            bonosTotales.map((bono, key) => {
                if (JSON.stringify(bono) === JSON.stringify(this.state.bonoSeleccionado)) {
                    bonosTotalesN.push(bono)
                    bonosTotales.splice(key, 1)
                }
            })
            this.setState({
                totalBonos: bonosActuales,
                bonoSeleccionado: {},
                valorMinimo: 0,
                valorMaximo: 0,
                horaInicial: new Date(),
                horaFinal: new Date(),
                dialogNewBono: false,
                bonosbd: bonosTotales,
                bonosEliminados: bonosTotalesN
            })
        }
    }
    
    toCheckSum = (id) => {
        try {
            let str1 = checksum(id);
            str1 += ' ' + Buffer.from(id).toString('base64')
            return str1;
        } catch (error) {
            console.log(error)
            return '';
        }
    }

    editarBono = () => {
        this.setState({
            dialogNewBono: true,
        })
    }

    eliminarBono = (key) => {
        var bonos = this.state.totalBonos
        var bonosb = this.state.bonosbd
        var bonose = this.state.bonosEliminados
        bonose.map((bono, key2) => {
            if (parseInt(bono.value) === parseInt(bonos[key].id)) {
                bonosb.push(bono)
                bonose.splice(key2, 1)
            }
        })
        bonos.splice(key, 1)
        this.setState({
            totalBonos: bonos,
            bonosbd: bonosb,
            bonosEliminados: bonose
        })
    }




    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
    }


    obtenerDatos = () => {
        //this.setState({ actualizacionVista: false })
        store.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: false
        })
        var datos = store.getState().usuario
        var hijos = store.getState().empresasHijos
        //Obtener las tiendas
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/5/padre/' + hijos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var tiendas = []
                if (responsee.status == 200) {
                    responsee.data.empresas.map((dato, key) => (
                        tiendas.push({
                            value: dato.id,
                            label: dato.alias
                        })
                    ))
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }

                this.setState({ tiendasbd: tiendas })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        switch (datos.tipo) {
            case "1":
            case "2":
            case "3":
            case "4":
                Axios.get(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/campanas/empresas_id/' + datos.empresas_id, { headers: { Authorization: ntoken } })
                    .then(response => {
                        if (response.status == 200) {
                            var datos = []
                            var nombres = []
                            if (response.data.data != null) {
                                response.data.data.map((bn) => {
                                    datos.push(bn.campanas)
                                    nombres.push(bn.campanas.descripcion)
                                })
                            }
                        } else if (response.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }
                        this.setState({ camapanasbd: datos, loader: false, nombresUsados: nombres })
                        let t = this
                        setTimeout(function () { t.datos() }, 200)
                    })
                Axios.get(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/voucher/bonos/0/' + datos.empresas_id, { headers: { Authorization: ntoken } })
                    .then(response => {
                        var bonosb = []
                        if (response.status == 200) {
                            if (response.data.data != null) {
                                response.data.data.map((bono) => {
                                    if (bono.estado === "A") {
                                        bonosb.push({
                                            "value": bono._id,
                                            "label": bono.descripcion,
                                            "bono": bono
                                        })
                                    }
                                })
                            }

                        } else if (response.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }
                        this.setState({ bonosbd: bonosb })
                    })
                break;
            case "5":
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/campanas/pos/' + datos.empresas_id, { headers: { Authorization: ntoken } })
                    .then(response => {
                        var datos = []
                        if (response.status == 200) {
                            if (response.data.data != null) {
                                response.data.data.map((campana, key) => {
                                    datos.push({
                                        'descripcion': campana.campana.descripcion,
                                        'fecha_inicio': campana.campana.fecha_inicio,
                                        'fecha_fin': campana.campana.fecha_fin,
                                        'campana': campana
                                    })
                                })
                            }
                        } else if (response.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }
                        this.setState({ camapanasbd: datos, loader: false })
                        let t = this
                        setTimeout(function () { t.datos() }, 200)
                    })
                break;
        }

    }
    componentWillMount() {
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        this.obtenerDatos()
    }


    handleClickOpen = () => {
        store.dispatch({
            type: "CREACION_USUARIO",
            creacionUsuario: !this.state.createUSer,
            usuarioEdicion: null,
            modovista: false
        })
        if (this.state.createUSer) {
            this.setState({ usuarioEdicion: null })
        }
    };
    buscar = (name) => event => {
        var cadena = event.target.value.toUpperCase()
        if (cadena.length > 0) {
            this.setState({ busqueda: true })
        } else {
            this.setState({ busqueda: false })
        }
        busquedaUsuario = []
        this.state.camapanasbd.map((tipo, key) => {
            if ((tipo.descripcion).includes(cadena)) {
                busquedaUsuario.push(tipo)
            } else {

            }
        })
    }

    setearMiles = (numero) => {
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case "COP":
                return (
                    (new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(2)))
                )
                break;
            case "USD":
                return (new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(numero))
                break;

        }
    }

    detallesUsuario = (usuario) => {
        store.dispatch({
            type: "CREACION_USUARIO",
            creacionUsuario: !this.state.edicicionUsuario,
            usuarioEdicion: usuario,
            modovista: true,
            edicicionUsuario: false
        })
        if (this.state.createUSer) {
            this.setState({ usuarioEdicion: null })
        }
    }

    pagination = (name, str) => {
        switch (name) {
            case 'tipo':
                this.setState({ animacionDerecha: false, animacionIzquierda: false })
                if (str == 'S') {
                    this.setState({ animacionDerecha: true, animacionIzquierda: false, paginadorIndex: this.state.paginadorIndex + 1, paginadorMinimo: this.state.paginadorMaximo + 1, paginadorMaximo: this.state.paginadorMaximo + this.state.paginadorTotal })
                } else {
                    this.setState({ animacionDerecha: false, animacionIzquierda: true, paginadorIndex: this.state.paginadorIndex - 1, paginadorMinimo: (this.state.paginadorMinimo - this.state.paginadorTotal), paginadorMaximo: this.state.paginadorMinimo - 1 })
                }
                this.datos()
                break;
        }
    }

    datos = () => {
        datosMostrarUsuario = []
        for (var i = this.state.paginadorMinimo; i <= this.state.paginadorMaximo; i++) {
            if (this.state.users[i] != null) {
                datosMostrarUsuario.push(this.state.users[i])
            } else {
                //console.log(this.state.tipoEquipo[i])
            }
        }
        //console.log("Minimno:" + this.state.paginadorMinimo + " - maximo:" + this.state.paginadorMaximo)
    }

    cancelarCarnet = () => {
        this.setState({ mostrarCarnet: !this.state.mostrarCarnet })
    }

    render() {
        var data = {}
        var dominio = store.getState().dominio
        var usuario = store.getState().usuario
        const imagen = Server.hostMultimedia + dominio + "/persona/default.png"
        const { value } = this.state;
        //CArnet
        var dCarnet = null
        if (this.state.detallesCarnet != null) {
            dCarnet = this.state.detallesCarnet
        }
        return (
            this.state.loader
                ?
                <div style={{ height: '100vh', width: '100%', paddingLeft: '15px', paddingRight: '15px' }} className="mx-auto my-auto">
                    <Loader style={{ height: '100vh', width: '100%' }} />
                </div>
                :
                <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                    <div className="row">
                        {
                            permisos.includes('C')
                                ?
                                <ExpansionPanel expanded={this.state.expanded} className="col-md-12" style={{ zIndex: 1098 }} onChange={() => this.expandir()}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography >CREAR NUEVA CAMPAÑA</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div style={{ width: '100%' }}>

                                            <div className="row mx-auto">
                                                <div className="col-md-6 col-sm-12">
                                                    <label htmlFor="numordencompra" className="col-form-label">NOMBRE DE LA CAMPAÑA</label>
                                                    <input autocomplete="off" disabled={this.state.modovista} id="numordencompra" value={this.state.nombreGuardar} onChange={this.handleChange('nombreGuardar')} className="form-control" type="text" />
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="numordencompra" className="col-form-label">FECHA DE INICIO</label>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                        <DatePicker
                                                            format="yyyy-MM-dd"
                                                            id="datetime"
                                                            value={this.state.fechaInicial}
                                                            onChange={this.handleChangemoment}
                                                            ampm={false}
                                                            disabled={this.state.modovista}
                                                            className="rounded form-control"
                                                            style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="numordencompra" className="col-form-label">FECHA FIN</label>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                        <DatePicker
                                                            format="yyyy-MM-dd"
                                                            id="datetime"
                                                            value={this.state.fechaFinal}
                                                            onChange={this.handleChangemomentFinal}
                                                            ampm={false}
                                                            disabled={this.state.modovista}
                                                            className="rounded form-control"
                                                            style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div>
                                            <div className="row mx-auto">
                                                <div className="col-md-12" >
                                                    <label htmlFor="nombreprov" className="col-form-label">ESTACIONES</label>
                                                    <Selects
                                                        placeholder="Seleccione o escriba"
                                                        value={this.state.tiendasSeleccionadas}
                                                        onChange={this.handleChanges('tiendasSeleccionadas')}
                                                        options={this.state.tiendasbd}
                                                        isMulti
                                                        isDisabled={this.state.modovista}
                                                    />

                                                </div>
                                            </div>
                                            <div className="row mx-auto  bottom-aligned">
                                                <div className="col-md-12">
                                                    <label htmlFor="numordencompra" className="col-form-label">BONOS</label>
                                                </div>
                                            </div>
                                            <div className="row mx-auto" style={{ marginTop: '15px' }}>
                                                {this.state.modovista
                                                    ?
                                                    null
                                                    :
                                                    <div className="col-md-6">
                                                        <List style={{ backgroundColor: "#fff", padding: '0' }} className="border rounded">
                                                            <ListItemLink onClick={() => this.handleDialogBono()}>
                                                                <ListItem>
                                                                    <ListItemAvatar>
                                                                        <Avatar>+</Avatar>
                                                                    </ListItemAvatar>

                                                                    <ListItemText
                                                                        primary="AGREGAR NUEVO"
                                                                    />
                                                                </ListItem>
                                                            </ListItemLink>
                                                        </List>
                                                        <Dialog
                                                            open={this.state.dialogNewBono}
                                                            onClose={this.handleDialogBono}
                                                            aria-labelledby="form-dialog-title"
                                                            maxWidth={'md'}
                                                            fullWidth={true}
                                                            disableBackdropClick={true}
                                                            style={{ overflowY: 'initial !IMPORTANT' }}
                                                            className="no-overflow"
                                                        >
                                                            <DialogContent style={{ overflowY: 'initial !IMPORTANT' }} className="no-overflow">

                                                                <div className="row mx-auto">
                                                                    <div className="col-md-6" >
                                                                        <label htmlFor="nombreprov" className="col-form-label">BONO</label>
                                                                        <Selects
                                                                            placeholder="Seleccione o escriba"
                                                                            value={this.state.bonoSeleccionado}
                                                                            onChange={this.handleChanges('bonoSeleccionado')}
                                                                            options={this.state.bonosbd}

                                                                        />

                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <label htmlFor="numordencompra" className="col-form-label">HORA DE INICIO</label>
                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                                            <TimePicker
                                                                                format="hh:mm a"
                                                                                id="datetime"
                                                                                value={this.state.horaInicial}
                                                                                onChange={this.handleChangemomentHora}
                                                                                ampm={false}
                                                                                disableFuture={true}
                                                                                className="rounded form-control"
                                                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <label htmlFor="numordencompra" className="col-form-label">HORA FIN</label>
                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                                            <TimePicker
                                                                                format="hh:mm a"
                                                                                id="datetime"
                                                                                value={this.state.horaFinal}
                                                                                onChange={this.handleChangemomentFinalHora}
                                                                                ampm={false}
                                                                                disableFuture={true}
                                                                                className="rounded form-control"
                                                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </div>
                                                                </div>
                                                                <div className="row mx-auto  bottom-aligned">
                                                                    <div className="col-md-3">
                                                                        <label htmlFor="numordencompra" className="col-form-label">VALOR MINIMO</label>
                                                                        <div className="input-group " style={{ marginBottom: '0!important' }}>
                                                                            <div className="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                                                                <span className="input-group-text" id="basic-addon1">$</span>
                                                                            </div>
                                                                            <input autocomplete="off" id="precioMinimoNuevo" className="form-control" type="number" min="1" value={this.state.valorMinimo} onChange={this.handleChange('valorMinimo')} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <label htmlFor="numordencompra" className="col-form-label">VALOR MAXIMO</label>
                                                                        <div className="input-group " style={{ marginBottom: '0!important' }}>
                                                                            <div className="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                                                                <span className="input-group-text" id="basic-addon1">$</span>
                                                                            </div>
                                                                            <input autocomplete="off" id="precioMinimoNuevo" className="form-control" type="number" min="1" value={this.state.valorMaximo} onChange={this.handleChange('valorMaximo')} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <button className="btn btn-danger btn-block" onClick={() => this.handleDialogBono()}>CANCELAR</button>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <button className="btn btn-success btn-block" onClick={() => this.agregarBono()}>GUARDAR</button>
                                                                    </div>
                                                                </div>
                                                            </DialogContent>
                                                        </Dialog>
                                                    </div>
                                                }

                                                {
                                                    this.state.totalBonos.map((bono, key) => {
                                                        return (
                                                            <div className="col-md-6">
                                                                <List style={{ backgroundColor: "#fff", padding: '0' }} className="border rounded">
                                                                    <ListItem className="border">
                                                                        <ListItemAvatar>
                                                                            <Avatar><BonosIcon /></Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            primary={bono.bono}
                                                                            secondary={
                                                                                <React.Fragment>
                                                                                    <Typography
                                                                                        component="span"
                                                                                        variant="body2"
                                                                                        color="textPrimary"
                                                                                    >
                                                                                        {moment(new Date(bono.horaInicio)).format("hh:mm a") + " - " + moment(new Date(bono.horaFin)).format("hh:mm a")}
                                                                                    </Typography>
                                                                                    {"$ " + this.setearMiles(bono.valorMinimo) + " - $" + this.setearMiles(bono.valorMaximo)}
                                                                                </React.Fragment>
                                                                            }
                                                                        />
                                                                        {
                                                                            this.state.modovista
                                                                                ?
                                                                                <ListItemSecondaryAction>
                                                                                    <IconButton aria-label="Delete" onClick={() => this.abrirDialogVoucher(bono)}>
                                                                                        <ReciboIcon />
                                                                                    </IconButton>
                                                                                </ListItemSecondaryAction>
                                                                                :
                                                                                <ListItemSecondaryAction>
                                                                                    <IconButton aria-label="Delete" onClick={() => this.eliminarBono(key)}>
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                </ListItemSecondaryAction>
                                                                        }

                                                                    </ListItem>

                                                                </List>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <Dialog
                                                    open={this.state.dialogVista}
                                                    onClose={this.cerrarDialogVoucher}
                                                    aria-labelledby="form-dialog-title"
                                                    maxWidth={'md'}
                                                    disableBackdropClick={true}
                                                    style={{ overflowY: 'initial !IMPORTANT' }}
                                                >
                                                    <DialogContent style={{ overflowY: 'initial !IMPORTANT' }}>

                                                        <table width="350px" border="0" align="center" cellpadding="0" cellspacing="0">
                                                            <tr>  </tr>
                                                        </table>

                                                        <table width="350px" border="0" align="center" cellpadding="0" cellspacing="0">
                                                            <tr>  </tr>
                                                        </table>

                                                        <table width="350px" border="0" align="center" cellpadding="0" cellspacing="0">
                                                            <tr>  </tr>
                                                        </table>

                                                        <table width="350px" border="0" align="center" cellpadding="0" cellspacing="0">
                                                            <tr>  </tr>
                                                        </table>

                                                        <table width="350px" border="0" align="center" cellpadding="0" cellspacing="0">
                                                            <tr>
                                                                <td className="labelbebas">EDS BOSTON</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="labelbebasnit"><center>NIT 80440009279</center></td>
                                                            </tr>
                                                            <tr>  </tr>
                                                        </table>

                                                        <table width="350px" border="0" align="center" cellpadding="0" cellspacing="0">
                                                            <tr>  </tr>
                                                        </table>

                                                        <table width="350px" border="0" align="center" cellpadding="0" cellspacing="0">
                                                            <tr>  </tr>
                                                        </table>

                                                        <table width="350px" border="0" align="center" cellpadding="0" cellspacing="0">
                                                            <tr>
                                                                <td className="textnormal"><center><b>Dirección:</b> Autopista Medellin Km3</center></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="textnormal"><center>
                                                                    <b>Tels: </b>
                                                                    3602772
    </center></td>
                                                            </tr>
                                                            <tr>
                                                                <td>----------------------------------------------------------------</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="textnormal"><b>Ticket No: </b> <span className="labelbebasnit">8107</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td>----------------------------------------------------------------</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="textnormal"><table width="100%" border="0" cellspacing="0" cellpadding="2">
                                                                    <tr>
                                                                        <td width="37%" className="textnormal"><b>Fecha / Hora: </b></td>
                                                                        <td width="63%" className="textnormal">{moment(new Date()).format("DD-MM-YYYY hh:mm A")}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="textnormal"><b>Operador: </b></td>
                                                                        <td className="textnormal">Johana Chaparro</td>
                                                                    </tr>
                                                                </table></td>
                                                            </tr>
                                                            <tr>
                                                                <td><table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                    <tr>
                                                                        <td height="20" className="textnormal"><b> Isla: </b> 1 </td>
                                                                        <td className="textnormal"><b>Surtidor: </b> 1 </td>
                                                                        <td className="textnormal"><b>Cara: </b> 1 </td>
                                                                        <td className="textnormal"><b>Manguera: </b> 1 </td>
                                                                    </tr>
                                                                </table></td>
                                                            </tr>
                                                            <tr>
                                                                <td><table width="100%" border="0" cellspacing="0" cellpadding="1">
                                                                    <tr>
                                                                        <td>======================================</td>
                                                                    </tr>
                                                                </table></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="textnormal"><b>Producto:</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="textnormal"><center>117 - GASOLINA CORREINTE 8% OXIGENADA</center></td>
                                                            </tr>
                                                            <tr>
                                                                <td><table width="100%" border="0" cellspacing="0" cellpadding="2">
                                                                    <tr>
                                                                        <td className="textnormal"><b>Precio:</b></td>
                                                                        <td className="textnormal">$8.150,00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="textnormal"><b>Cantidad:</b></td>
                                                                        <td className="textnormal">27,120UGL</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="textnormal"><b>Total:</b></td>
                                                                        <td className="textnormal">$25.000,00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="textnormal"><b>Placa:</b></td>
                                                                        <td className="textnormal">&nbsp;</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width="37%" className="textnormal"><b>Metodo de Pago:</b> </td>
                                                                        <td width="63%" className="textnormal"> EFECTIVO</td>
                                                                    </tr>
                                                                </table></td>
                                                            </tr>
                                                            <tr>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="textnormal"><center>GRACIAS POR SU COMPRA, VUELVA PRONTO</center></td>
                                                            </tr>
                                                            <tr>
                                                                <td>======================================</td>
                                                            </tr>
                                                            <tr>
                                                            <div id="impresora"> </div>
                                                            </tr>
                                                            <tr>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td><center><img id="barcodee"></img>
                                                                </center></td>
                                                            </tr>
                                                            <tr>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="textnormal"><b>Terminos y condiciones.</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="textnormal">{this.state.pieDePAgina}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td>***************************************************</td>
                                                            </tr>
                                                            <tr>
                                                                <td>&nbsp;</td>
                                                            </tr>

                                                        </table>
                                                        <div className="row mx-auto" style={{ width: '350px' }}>

                                                            <button className="btn btn-danger btn-block" onClick={() => this.cerrarDialogVoucher()}>CERRAR</button>

                                                        </div>
                                                    </DialogContent>
                                                </Dialog>
                                            </div>
                                            <div className="row mx-auto bottom-aligned" style={{ marginTop: '15px' }}>
                                                <div className="col-md-6" >
                                                    <label htmlFor="numordencompra" className="col-form-label">PIE DE PAGINA </label>
                                                    <textarea name="comment" className="form-control" rows="2" value={this.state.pieDePAgina} onChange={this.handleChange('pieDePAgina')} disabled={this.state.modovista} />
                                                </div>
                                                <div className="col-md-6" >
                                                   <div id="tyttt"></div>
                                                </div>
                                          
                                            </div>
                                            <div className="row mx-auto bottom-aligned" style={{ marginTop: '15px' }}>
                                                <div className="col-md-6" >
                                                    
                                                </div>
                                                {
                                                    this.state.modovista
                                                        ?
                                                        <div className="col-md-6">
                                                            <button className="btn btn-danger btn-block" onClick={() => this.cerrarFormulario()}>CANCELAR</button>
                                                        </div>
                                                        :
                                                        <div className="col-md-3">
                                                            <button className="btn btn-danger btn-block" onClick={() => this.cerrarFormulario()}>CANCELAR</button>
                                                        </div>
                                                }
                                                {
                                                    this.state.modovista
                                                        ?
                                                        null
                                                        :
                                                        <div className="col-md-3">
                                                            <button className="btn btn-success btn-block" onClick={this.state.modoEditar ? () => this.editarCampana() : () => this.registrarCamapana()} disabled={this.state.response}>{this.state.modoEditar ? "GUARDAR" : "CREAR"}</button>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                :
                                null
                        }
                    </div>
                    {/* <Dialog
                        open={this.state.dialogDetalles}
                        onClose={this.cerrarFormulario}
                        aria-labelledby="form-dialog-title"
                        maxWidth={'md'}
                        style={{ padding: '0' }}
                        fullWidth={true}
                        disableBackdropClick={true}
                    >
                        <DialogContent >
                            <div className="row mx-auto">
                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="numordencompra" className="col-form-label">NOMBRE DE LA CAMPAÑA</label>
                                    <input autocomplete="off" disabled={this.state.modovista} id="numordencompra" value={this.state.nombreGuardar} onChange={this.handleChange('nombreGuardar')} className="form-control" type="text" />
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="numordencompra" className="col-form-label">FECHA DE INICIO</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                        <DatePicker
                                            format="yyyy-MM-dd"
                                            id="datetime"
                                            value={this.state.fechaInicial}
                                            onChange={this.handleChangemoment}
                                            ampm={false}
                                            disabled={this.state.modovista}
                                            className="rounded form-control"
                                            style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="numordencompra" className="col-form-label">FECHA FIN</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                        <DatePicker
                                            format="yyyy-MM-dd"
                                            id="datetime"
                                            value={this.state.fechaFinal}
                                            onChange={this.handleChangemomentFinal}
                                            ampm={false}
                                            disabled={this.state.modovista}
                                            className="rounded form-control"
                                            style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div className="row mx-auto" style={{ marginTop: '15px' }}>
                                {this.state.modovista
                                    ?
                                    null
                                    :
                                    <div className="col-md-4">
                                        <List style={{ backgroundColor: "#fff", padding: '0' }} className="border rounded">
                                            <ListItemLink onClick={() => this.handleDialogBono()}>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar>+</Avatar>
                                                    </ListItemAvatar>

                                                    <ListItemText
                                                        primary="AGREGAR NUEVO"
                                                    />
                                                </ListItem>
                                            </ListItemLink>
                                        </List>
                                        <Dialog
                                            open={this.state.dialogNewBono}
                                            onClose={this.handleDialogBono}
                                            aria-labelledby="form-dialog-title"
                                            maxWidth={'md'}
                                            fullWidth={true}
                                            disableBackdropClick={true}
                                            style={{ overflowY: 'initial !IMPORTANT' }}
                                            className="no-overflow"
                                        >
                                            <DialogContent style={{ overflowY: 'initial !IMPORTANT' }} className="no-overflow">

                                                <div className="row mx-auto">
                                                    <div className="col-md-6" >
                                                        <label htmlFor="nombreprov" className="col-form-label">BONO</label>
                                                        <Selects
                                                            placeholder="Seleccione o escriba"
                                                            value={this.state.bonoSeleccionado}
                                                            onChange={this.handleChanges('bonoSeleccionado')}
                                                            options={this.state.bonosbd}

                                                        />

                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="numordencompra" className="col-form-label">HORA DE INICIO</label>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                            <TimePicker
                                                                format="hh:mm a"
                                                                id="datetime"
                                                                value={this.state.horaInicial}
                                                                onChange={this.handleChangemomentHora}
                                                                ampm={false}
                                                                disableFuture={true}
                                                                className="rounded form-control"
                                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="numordencompra" className="col-form-label">HORA FIN</label>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                            <TimePicker
                                                                format="hh:mm a"
                                                                id="datetime"
                                                                value={this.state.horaFinal}
                                                                onChange={this.handleChangemomentFinalHora}
                                                                ampm={false}
                                                                disableFuture={true}
                                                                className="rounded form-control"
                                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                </div>
                                                <div className="row mx-auto  bottom-aligned">
                                                    <div className="col-md-3">
                                                        <label htmlFor="numordencompra" className="col-form-label">VALOR MINIMO</label>
                                                        <div className="input-group " style={{ marginBottom: '0!important' }}>
                                                            <div className="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                                                <span className="input-group-text" id="basic-addon1">$</span>
                                                            </div>
                                                            <input autocomplete="off" id="precioMinimoNuevo" className="form-control" type="number" min="1" value={this.state.valorMinimo} onChange={this.handleChange('valorMinimo')} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="numordencompra" className="col-form-label">VALOR MAXIMO</label>
                                                        <div className="input-group " style={{ marginBottom: '0!important' }}>
                                                            <div className="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                                                <span className="input-group-text" id="basic-addon1">$</span>
                                                            </div>
                                                            <input autocomplete="off" id="precioMinimoNuevo" className="form-control" type="number" min="1" value={this.state.valorMaximo} onChange={this.handleChange('valorMaximo')} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <button className="btn btn-danger btn-block" onClick={() => this.handleDialogBono()}>CANCELAR</button>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <button className="btn btn-success btn-block" onClick={() => this.agregarBono()}>GUARDAR</button>
                                                    </div>
                                                </div>
                                            </DialogContent>
                                        </Dialog>
                                    </div>
                                }

                                {
                                    this.state.totalBonos.map((bono, key) => {
                                        return (
                                            <div className="col-md-4" style={{ marginTop: '10px' }}>
                                                <List style={{ backgroundColor: "#fff", padding: '0' }} className="border rounded">
                                                    <ListItem className="border">
                                                        <ListItemAvatar>
                                                            <Avatar><BonosIcon /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={bono.bono}
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="textPrimary"
                                                                    >
                                                                        {moment(new Date(bono.horaInicio)).format("hh:mm a") + " - " + moment(new Date(bono.horaFin)).format("hh:mm a")}
                                                                    </Typography>
                                                                    {"$ " + this.setearMiles(bono.valorMinimo) + " - $" + this.setearMiles(bono.valorMaximo)}
                                                                </React.Fragment>
                                                            }
                                                        />
                                                        {
                                                            this.state.modovista
                                                                ?
                                                                <ListItemSecondaryAction>
                                                                    <IconButton aria-label="Delete" onClick={() => this.abrirDialogVoucher(bono)}>
                                                                        <ReciboIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                                :
                                                                <ListItemSecondaryAction>
                                                                    <IconButton aria-label="Delete" onClick={() => this.eliminarBono(key)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                        }

                                                    </ListItem>

                                                </List>
                                            </div>
                                        )
                                    })
                                }
                         

                            </div>
                            <div className="row mx-auto bottom-aligned" style={{ marginTop: '15px' }}>
                                <div className="col-md-6" >
                                    <label htmlFor="numordencompra" className="col-form-label">PIE DE PAGINA</label>
                                    <textarea name="comment" className="form-control" rows="2" value={this.state.pieDePAgina} onChange={this.handleChange('pieDePAgina')} disabled={this.state.modovista} />
                                </div>
                                {
                                    this.state.modovista
                                        ?
                                        <div className="col-md-6">
                                            <button className="btn btn-danger btn-block" onClick={() => this.cerrarFormulario()}>CANCELAR</button>
                                        </div>
                                        :
                                        <div className="col-md-3">
                                            <button className="btn btn-danger btn-block" onClick={() => this.cerrarFormulario()}>CANCELAR</button>
                                        </div>
                                }
                                {
                                    this.state.modovista
                                        ?
                                        null
                                        :
                                        <div className="col-md-3">
                                            <button className="btn btn-success btn-block" onClick={this.state.modoEditar ? () => this.editarCampana() : () => this.registrarCamapana()} disabled={this.state.peticion}>{this.state.modoEditar ? "GUARDAR" : "CREAR"}</button>
                                        </div>
                                }


                            </div>
                        </DialogContent>

                    </Dialog>*/}
                    {permisos.includes('R') ? this.state.tabla ?
                        <div className="row" style={{ marginTop: "15px", marginTop: '15px' }}>
                            <div className="col col-md-12" style={{ padding: 0 }}>
                                <Paper style={{ padding: '10px' }}>

                                    <MDBDataTable
                                        //striped
                                        className="col-md-12"
                                        searchLabel="Buscar"
                                        paginationLabel={["Anterior", "Siguente"]}
                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                        entriesLabel="Filas a mostrar"
                                        tbodyColor="primary-color"
                                        theadColor="#FF8800"
                                        exportToCSV={true}
                                        striped
                                        bordered
                                        autoWidth={false}
                                        responsive={true}
                                        hover
                                        data={data}
                                    />
                                </Paper>
                            </div>
                        </div>

                        :
                        <div>
                            <div className="row mx-auto" style={{ marginTop: '15px' }}>
                                <button disabled={this.state.paginadorMinimo == 0} className="btn btn-sm btn-outline-primary mx-auto" onClick={() => this.pagination('tipo', 'N')}>{"<<"}</button>
                                <div className="col col-md-6 mx-auto">
                                    <input type="text" placeholder="Buscar" className="form-control mx-auto" onChange={this.buscar('tipo')} />
                                </div>
                                <button disabled={this.state.paginadorMaximo + 1 >= this.state.users.length} className="btn btn-sm btn-outline-primary mx-auto" onClick={() => this.pagination('tipo', 'S')}>{">>"}</button>
                            </div>
                            <div className="row" style={{ marginTop: '15px' }}>
                                {this.state.busqueda
                                    ?

                                    busquedaUsuario.map((campana, key) => {
                                        return (
                                            <div className="col-md-6">
                                                <List className="border" style={{ backgroundColor: "#fff", padding: '0', height: '100px', marginTop: '10px' }} className="border rounded">
                                                    <ListItem>
                                                        <ListItemAvatar style={{ cursor: 'pointer' }} onClick={usuario.tipo === "5" ? () => this.dialogDetalles(campana) : () => this.verDetalles(campana)}>
                                                            <Avatar className={campana.estado === "A" ? "succes" : ""}><CampaniaIcon /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={campana.descripcion}
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="textPrimary"
                                                                    >
                                                                        {moment(new Date(campana.fecha_inicio)).format("DD/MM/YYYY") + " - " + moment(new Date(campana.fecha_fin)).format("DD/MM/YYYY")}
                                                                    </Typography>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                        {permisos.includes('D') ?
                                                            campana.estado === "A" ?
                                                                <ListItemSecondaryAction>
                                                                    <IconButton aria-label="Update" onClick={() => this.verEditar(campana)}>
                                                                        <EditICon />
                                                                    </IconButton>
                                                                    <IconButton aria-label="Delete" onClick={() => this.inactivarCampana(campana._id)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                                :
                                                                <ListItemSecondaryAction>
                                                                    <IconButton aria-label="Update" onClick={() => this.verEditar(campana)}>
                                                                        <EditICon />
                                                                    </IconButton>
                                                                    <IconButton aria-label="Delete" onClick={() => this.activarCampana(campana._id)}>
                                                                        <ActivarIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            : null
                                                        }
                                                    </ListItem>

                                                </List>
                                            </div>
                                        )
                                    })

                                    :
                                    this.state.camapanasbd.map((campana, key) => {
                                        return (
                                            <div className="col-md-6">
                                                <List className="border" style={{ backgroundColor: "#fff", padding: '0', height: '100px', marginTop: '10px' }} className="border rounded">
                                                    <ListItem >
                                                        <ListItemAvatar style={{ cursor: 'pointer' }} onClick={usuario.tipo === "5" ? () => this.dialogDetalles(campana) : () => this.verDetalles(campana)}>
                                                            <Avatar className={campana.estado === "A" ? "succes" : ""}><CampaniaIcon /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={campana.descripcion}
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="textPrimary"
                                                                    >
                                                                        {moment(new Date(campana.fecha_inicio)).format("DD/MM/YYYY") + " - " + moment(new Date(campana.fecha_fin)).format("DD/MM/YYYY")}
                                                                    </Typography>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </ListItem>
                                                    {permisos.includes('D') ?
                                                        campana.estado === "A" ?
                                                            <ListItemSecondaryAction>
                                                                <IconButton aria-label="Update" onClick={() => this.verEditar(campana)}>
                                                                    <EditICon />
                                                                </IconButton>
                                                                <IconButton aria-label="Delete" onClick={() => this.inactivarCampana(campana._id)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                            :
                                                            <ListItemSecondaryAction>
                                                                <IconButton aria-label="Update" onClick={() => this.verEditar(campana)}>
                                                                    <EditICon />
                                                                </IconButton>
                                                                <IconButton aria-label="Delete" onClick={() => this.activarCampana(campana._id)}>
                                                                    <ActivarIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        : null
                                                    }

                                                </List>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        : <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>}
                </div>
        );
    }
}



export default usuariosVista;
