/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */

let ipLuis = 'http://192.168.0.2:';
let ipLuis2 = 'http://192.168.43.196:';
let ipCamilo = 'http://192.168.0.4:';
let ipJorge = 'http://192.168.0.23:';
let ipLocalEquipos = 'http://localhost:6002'
let hostNuevo = 'https://ws.neobox.com.co/facturacion/'
let puertParametrizacion = '7001';
let puertSSLParametrizacion = '7001';
let puertoResoluciones = '7001';
let puertoEquipos = '7002';
let puertoEquiposSinSSSL = '6002';
let puertoEmpresas = '7003';
let puertoEmpleado = '7004';
let puertoProducto = '7005';
let puertoInventario = '7006';
let puertoSSLBodega = '7006';
let puertoMovimiento = '7008';
let puertoPdf = '7009';
let puertoAutenticacion = '7010'
let puertoSSLPrueba = '7010';
let puertoNotificaciones = '7012';/* sudo.starsh */
let puertoImportador = '7100';
let nuevoPuerto = '7011';
let isProduccion = true;
let respaldo = false
let pruebasLuis = false
let hostPrueba = ''
let HostPDF = ''
let hostPruebasinssl = ''
let hostMultimedia = ''
let server_key = "MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCoknaik3X9AwXfMIICWgIBAAKBgFfgP0Y/6I3A3+zQMpLVsfcP64KjuOyJGDuElO3xl6LK+CSpJIla2zPiUalrOVWl7NePuRekv/imD8H9nt+oqBwIcJJFY/fEm6LHb8PSWa+Utg3ouLgK1kuZDcTS/4RtuIA8+VQ4A51CYpTkyUeGuRIM3u26jj0E66hP8D+fzyLHAgMBAAECgYA7AHsbjN91BhKTq3q5cjfqncrQTWNllesqUyu306WvjT4tR1Ab1WAmpweTjEXTxvOODJcHU/v9VGpyIvrgSxqL5n1N+SN/SLsVKRmX7v0c/7uqe752i4q0nOr+woIz3RucfxTRB4ZZqIOPJnPlIWaFVHSjHX+qz6sMjWCskmBhIQJBAJk5mAEBFJbouY1Faq9npgPWo0pJCgwOsr7YL2NsIL/oj+sBXfhxpndgUSxFiGMuCoUIul9ndZrMjdQFNBHeA9cCQQCS0YNDArMTY4KzBoihJ/3YDiZE6Bsr+ISXWoXRZuqCmFE0nzMGVSTS+N1tNAEytM18mNHU7MAla4rDy6oPSfqRAkBGTay22T4PN9zbOvyGWrJLlJH7paOcgKn28s5beeNU6moOU+Q/+DV/FIxqGcVIbHmbLLQGzjuhuTViELkhcZKlAkBSaMuW54MA1RBd0MMMQlhX18lfUDFAQySZmDQYz/2+BCVAtkN7wHsE+K/BRjmN7rKQx26wZ5uK5YxkToNMRaghAkAhVewMheWAXIlcN/UzujD39VpjWOchF/RiL7CnzX6HpAZtmLHGJXiT12qYui+wtT9nV9blYogf9b8tcoFpgTuD"
if (isProduccion) {
     hostPrueba = 'https://nodosdeuna.devitech.group:'
     HostPDF = 'https://pdf.neobox.com.co:'
     hostNuevo = 'https://ws.neobox.com.co/facturacion'
     hostPruebasinssl = hostPrueba
     hostMultimedia = 'https://files.neobox.com.co/neoline/'
} else if (respaldo) {
     hostPrueba = 'https://nodosdeuna.devitech.group:'
     HostPDF = 'https://pdf.neobox.com.co:'
     hostPruebasinssl = hostPrueba
     hostMultimedia = 'https://kcos.neobox.com.co/img/'
} else if (pruebasLuis) {
     hostPrueba = 'https://ws.piloto.devitech.com.co:'
     HostPDF = 'https://web.piloto.devitech.com.co:'
     hostPruebasinssl = hostPrueba
     hostMultimedia = 'https://web.piloto.devitech.com.co/img/'
} else {
     hostPrueba = 'https://ws.pruebasdeuna.devitech.com.co:'
     HostPDF = 'https://ws.pruebasdeuna.devitech.com.co:'
     hostPruebasinssl = hostPrueba
     hostMultimedia = ' https://ws.pruebasdeuna.devitech.com.co/neoline/'
}

module.exports = {
     ipLuis,
     puertoAutenticacion,
     hostPrueba,
     hostPruebasinssl,
     puertoSSLPrueba,
     puertoMovimiento,
     puertParametrizacion,
     puertoEquipos,
     puertoEmpresas,
     puertoInventario,
     puertoNotificaciones,
     puertoEmpleado,
     puertoProducto,
     ipCamilo,
     ipJorge,
     ipLuis2,
     puertSSLParametrizacion,
     puertoSSLBodega,
     puertoResoluciones,
     hostMultimedia,
     puertoPdf,
     puertoImportador,
     HostPDF,
     puertoEquiposSinSSSL,
     server_key,
     ipLocalEquipos,
     nuevoPuerto,
     hostNuevo
}
