import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { MDBDataTable } from 'mdbreact';
import Paper from '@material-ui/core/Paper';
import FormularioParametros from '../../../components/Formularios/FormularioParametros'
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide"
import store from '../../../lib/store'
import Server from '../../../lib/server'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Axios from 'axios'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from "@material-ui/core";
import Selects from 'react-select';
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker, TimePicker } from 'material-ui-pickers';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import DateFnsUtils from '@date-io/luxon';
import swal from 'sweetalert'
import moment from 'moment'
import "moment/locale/es";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import WebIcon from '@material-ui/icons/Web'
import EquipoIcon from "@material-ui/icons/SettingsRemote"
import SitioIcon from "@material-ui/icons/Face"
import LupaIcon from '@material-ui/icons/Search'
import SoporteIcon from "@material-ui/icons/ContactSupport" 
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
var busqueda = []
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
var hijos = ''
const idModulo = 48
var permisos = ''
class Entradas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //Variables de componente principal
            sailForce: true,
            soportesbd: [1],
            //formulario
            expanded: false,
            encargadosbd: [
                {
                    value: 1,
                    label: 'ANDRES CAMELO ESCHUNCHO'
                }, {
                    value: 0,
                    label: 'OSCAR LORA DE SALES'
                }, {
                    value: 2,
                    label: 'ERICK GARCIA RUIZ'
                }, {
                    value: 3,
                    label: 'RODOLFO CANO'
                }, {
                    value: 4,
                    label: 'ALEJANDRA GUZMAN'
                }
            ],
            encargadoSeleccionado: {},
            tipobd: [
                {
                    value: 1,
                    label: 'EQUIPO (POS)'
                }, {
                    value: 0,
                    label: 'PLATAFORMA (WEB)'
                }, {
                    value: 2,
                    label: 'MANTENIMIENTO EN SITIO'
                }, {
                    value: 3,
                    label: 'ASESORIA GENERAL'
                }
            ],
            tipoSeleccionado: {},
            checkConcluido: false,
            fechaInicioSoporte: new Date(),
            fechafinSoporte: new Date(),
            solicitante: '',
            motivos: '',
            observaciones: '',
            empresasbd: [],
            empresaSeleccionada: {},
            modoEditar: false,
            modovista: false,
            enPeticion: false,
            campoBusqueda: '',
            busqueda: false,
            //Paginador
            paginadorIndex: 0,
            paginadorMinimo: 0,
            paginadorMaximo: 15,
            paginadorTotal: 16,
        }
        store.subscribe(() => {
            this.setState({
                createPARAMETROS: store.getState().creacionParametros,
                actualizarDatos: store.getState().actualizarvista,
            })
        })

    }

    handleChangemoment = date => {
        this.setState({ fechaInicioSoporte: date });
    }
    handleChangemomentFinal = date => {
        this.setState({ fechafinSoporte: date });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizarDatos) {
            this.obtenerDatos()
            return true
        }
        return true;
    }

    expandir = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    cancelar = () => {
        this.setState({
            expanded: false,
            modovista: false,
            modoEditar: false,
            tipoSeleccionado: {},
            checkConcluido: false,
            fechaInicioSoporte: new Date(),
            fechafinSoporte: new Date(),
            solicitante: '',
            motivos: '',
            observaciones: '',
            encargadoSeleccionado: {},
        })
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });

    };


    handleChangeOpt = () => {
        this.setState({
            sailForce: !this.state.sailForce
        })
    };

    changeSelect = () => {
        this.setState({
            checkConcluido: !this.state.checkConcluido
        })
    };

    validarFormulario = () => {
        /*if (this.state.nombreGuardar.trim == "" || this.state.nombreGuardar.length == 0) {
            swal("Fallo al crear", "EL NOMBRE ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (JSON.stringify(this.state.productoSeleccionado) === "{}") {
            swal("Fallo al crear", "POR FAVOR SELECCIONE UN PRODUCTO", "warning")
            return false
        } else {
            this.setState({
                response: true
            })
            return true
        }*/

        return true

    }



    registrarSoporte = () => {
        var validar = false
        validar = this.validarFormulario()
        var datos = {
            "empresas_id": parseInt(this.state.empresaSeleccionada.value),
            "fecha_soporte_inicio": moment(this.state.fechaInicioSoporte).format("YYYY-MM-DD HH:mm:ss"),
            "fecha_soporte_fin": moment(this.state.fechafinSoporte).format("YYYY-MM-DD HH:mm:ss"),
            "equipos": this.state.empresaSeleccionada.label,
            "solicitante": this.state.solicitante,
            "motivos": this.state.motivos,
            "observaciones": this.state.observaciones,
            "plataforma": this.state.tipoSeleccionado.value,
            "encargado": this.state.encargadoSeleccionado.value,
            "concluido": this.state.checkConcluido ? "S" : 'N'
        }
        if (validar) {
            Axios.post(Server.hostPruebasinssl + Server.puertSSLParametrizacion + '/api/parametrizacion/entidadesMongo/bitacoras_soporte', datos, { headers: { Authorization: ntoken } })
                .then((res) => {
                    console.log(res)
                    this.setState({
                        enPeticion: false
                    })

                    if (res.status === 201) {
                        if (res.data.success) {
                            swal({
                                title: "Soporte creado correctamente",
                                icon: "success",
                                closeOnClickOutside: false,
                                buttons: {
                                    Seguir: {
                                        text: "OK",
                                        value: "continuar"
                                    }
                                },
                            }).then((value) => {
                                switch (value) {

                                    case "continuar":
                                        store.dispatch({
                                            type: "ACTUALIZACION_DATOS",
                                            actualizarvista: true
                                        })
                                        this.cancelar()
                                        break;

                                    default:

                                }
                            });
                        } else {
                            swal("Fallo al guardar", res.data.message, "warning");
                        }
                    } else if (res.status === 205) {
                        swal("ERROR", "LA SESION HA CADUCADO", "warning")
                        setTimeout(function () { window.location.href = '/'; }, 3000);
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                    }
                })
                .catch((err) => {
                    swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                })
        }
    }


    obtenerDatos = () => {
        store.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: false
        })
        //Obtener los soportes
        var hijos = store.getState().empresasHijos
        var dataa = {
            'empresas_id': hijos,
            "fecha_soporte_inicio": "2010-01-29 11:00:00",
            "fecha_soporte_fin": moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
        }
        Axios.post(Server.hostPruebasinssl + Server.puertSSLParametrizacion + '/api/parametrizacion/entidadesMongoData/bitacoras_soporte', dataa, { headers: { Authorization: ntoken } })
            .then(responsee => {
                console.log(responsee)
                var tiendas = []
                if (responsee.status == 200) {
                    tiendas = responsee.data.data
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
                this.setState({ soportesbd: tiendas })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }
    componentWillMount = () => {
        //Permisos usuarios
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })

        this.obtenerDatos()
        var hijos = store.getState().empresasHijos
        //Obtener las tiendas
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/3,4,5/padre/' + hijos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var tiendas = []
                if (responsee.status == 200) {
                    responsee.data.empresas.map((dato, key) => {
                        tiendas.push({
                            value: dato.id,
                            label: dato.alias
                        })
                    })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
                this.setState({ empresasbd: tiendas })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    buscar = () => {
        console.log("aja")
        this.setState({
            busqueda: true
        })
        var cadena = this.state.campoBusqueda.toUpperCase()
        busqueda = []
        this.state.soportesbd.map((tipo) => {
            if (tipo.motivos.includes(cadena) || tipo.observaciones.includes(cadena) || tipo.solicitante.includes(cadena) || tipo.equipos.includes(cadena)) {
                busqueda.push(tipo)
            }
        })
    }

    pagination = (name, str) => {
        switch (name) {
            case 'tipo':
                if (str == 'S') {
                    this.setState({ paginadorIndex: this.state.paginadorIndex + 1, paginadorMinimo: this.state.paginadorMaximo + 1, paginadorMaximo: this.state.paginadorMaximo + this.state.paginadorTotal })
                } else {
                    this.setState({ paginadorIndex: this.state.paginadorIndex - 1, paginadorMinimo: (this.state.paginadorMinimo - this.state.paginadorTotal), paginadorMaximo: this.state.paginadorMinimo - 1 })
                }
                this.datos()
                break;
        }
    }

    keyBuscar = (e) => {
        if (e.key === 'Enter') {
            this.buscar()
        } else if (e.key === 'Escape') {
            this.limpiar()
        }
    }

    limpiar = () => {
        this.setState({
            busqueda: false,
            campoBusqueda: ''
        })
    }


    render() {
        return (
            permisos.includes('R') ? <div>
                <div>
                    {/*<div className="row">
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={this.handleChangeOpt.bind(this)}
                                    value="checkedA"
                                />
                            }
                            label={this.state.sailForce ? "Soporte para Sailforce" : "Soporte de equipos"}
                        />
                    </div>
                    <div className="row">
                        {this.state.sailForce ? "LISTADO DE SOPORTES" : "LISTADO DE SOPORTE PARA EQUIPOS"}
                        </div>*/}
                    {permisos.includes('C')
                        ?
                        <div className="row">
                            <ExpansionPanel expanded={this.state.expanded} className="col-md-12" style={{ zIndex: 1098 }} onChange={() => this.expandir()}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography >NUEVO SOPORTE</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div style={{ width: '100%' }}>
                                        <div className="row mx-auto">
                                            <div className="col-md-5" >
                                                <label htmlFor="nombreprov" className="col-form-label">KIOSCO</label>
                                                <Selects
                                                    placeholder="Seleccione o escriba"
                                                    value={this.state.empresaSeleccionada}
                                                    onChange={this.handleChanges('empresaSeleccionada')}
                                                    options={this.state.empresasbd}
                                                    isDisabled={this.state.modovista}
                                                />

                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <label htmlFor="numordencompra" className="col-form-label">SOLICITANTE</label>
                                                <input autocomplete="off" id="numordencompra" value={this.state.solicitante} disabled={this.state.modovista} onChange={this.handleChange('solicitante')} className="form-control" type="text" />
                                            </div>
                                            <div className="col-md-3" >
                                                <label htmlFor="nombreprov" className="col-form-label">TIPO</label>
                                                <Selects
                                                    placeholder="Seleccione o escriba"
                                                    value={this.state.tipoSeleccionado}
                                                    onChange={this.handleChanges('tipoSeleccionado')}
                                                    options={this.state.tipobd}
                                                    isDisabled={this.state.modovista}
                                                />

                                            </div>
                                        </div>
                                        <div className="row mx-auto">
                                            <div className="col-md-4" >
                                                <label htmlFor="nombreprov" className="col-form-label">ENCARGADO</label>
                                                <Selects
                                                    placeholder="Seleccione o escriba"
                                                    value={this.state.encargadoSeleccionado}
                                                    onChange={this.handleChanges('encargadoSeleccionado')}
                                                    options={this.state.encargadosbd}
                                                    isDisabled={this.state.modovista}
                                                />

                                            </div>
                                            <div className="col-md-8 col-sm-12">
                                                <label htmlFor="numordencompra" className="col-form-label">MOTIVO</label>
                                                <input autocomplete="off" id="numordencompra" value={this.state.motivos} disabled={this.state.modovista} onChange={this.handleChange('motivos')} className="form-control" type="text" />
                                            </div>
                                        </div>
                                        <div className="row mx-auto">

                                            <div className="col-md-4">
                                                <label htmlFor="numordencompra" className="col-form-label">INCIO DEL SOPORTE</label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                    <DateTimePicker
                                                        format="yyyy-MM-dd hh:mm a"
                                                        id="datetime"
                                                        value={this.state.fechaInicioSoporte}
                                                        onChange={this.handleChangemoment}
                                                        ampm={false}
                                                        disabled={this.state.modovista}
                                                        disableFuture={true}
                                                        className="rounded form-control"
                                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            {this.state.checkConcluido
                                                ?
                                                <div className="col-md-4">
                                                    <label htmlFor="numordencompra" className="col-form-label">INCIO DEL SOPORTE</label>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                        <DateTimePicker
                                                            format="yyyy-MM-dd hh:mm a"
                                                            id="datetime"
                                                            value={this.state.fechafinSoporte}
                                                            onChange={this.handleChangemomentFinal}
                                                            ampm={false}
                                                            disabled={this.state.modovista}
                                                            disableFuture={true}
                                                            className="rounded form-control"
                                                            style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                                :
                                                null}
                                            <div className="col-md-4  bottom-aligned">
                                                <Tooltip title="SI SELECCIONA ESTA CASILLA SIGNIFICA QUE EL SOPORTE HA SIDO CONCLUIDO" placement="top">
                                                    <FormControlLabel
                                                        control={<Checkbox value={this.state.checkConcluido} checked={this.state.checkConcluido} style={{ padding: '5px' }} onChange={() => this.changeSelect()} />}
                                                        label='CONCLUIDO'
                                                        style={{ margin: '0' }}
                                                    />
                                                </Tooltip>
                                            </div>

                                        </div>
                                        <div className="row mx-auto bottom-aligned" style={{ marginTop: '15px' }}>
                                            <div className="col-md-6" >
                                                <label htmlFor="numordencompra" className="col-form-label">OBSERVACIONES</label>
                                                <textarea name="comment" className="form-control" rows="2" value={this.state.observaciones} onChange={this.handleChange('observaciones')} disabled={this.state.modovista} />
                                            </div>
                                            {
                                                this.state.modovista
                                                    ?
                                                    <div className="col-md-6">
                                                        <button className="btn btn-danger btn-block" onClick={() => this.cancelar()}>CANCELAR</button>
                                                    </div>
                                                    :
                                                    <div className="col-md-3">
                                                        <button className="btn btn-danger btn-block" onClick={() => this.cancelar()}>CANCELAR</button>
                                                    </div>
                                            }
                                            {
                                                this.state.modovista
                                                    ?
                                                    null
                                                    :
                                                    <div className="col-md-3">
                                                        <button className="btn btn-success btn-block" onClick={this.state.modoEditar ? () => this.editarCampana() : () => this.registrarSoporte()} disabled={this.state.response}>{this.state.modoEditar ? "GUARDAR" : "CREAR"}</button>
                                                    </div>
                                            }
                                        </div>


                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>
                        :
                        null
                    }
                    <div className="row mx-auto" style={{ marginTop: '15px' }}>
                        <button disabled={this.state.busqueda} className="btn btn-sm btn-outline-primary mx-auto" onClick={() => this.pagination('tipo', 'N')}>{"<<"}</button>
                        <div className="col col-md-6 mx-auto">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1"><SoporteIcon /></span>
                                </div>
                                <input type="text" placeholder="Busqueda de soportes" onKeyDown={this.keyBuscar} value={this.state.campoBusqueda} onChange={this.handleChange('campoBusqueda')} className="form-control mx-auto" />
                                {this.state.busqueda ? <div className="input-group-append">
                                    <button className="btn btn-outline-primary" disabled={!this.state.busqueda} type="button" onClick={() => this.limpiar()}>x</button>
                                </div> : null}
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={() => this.buscar()}><LupaIcon /></button>
                                </div>
                            </div>
                        </div>
                        <button disabled={this.state.busqueda} className="btn btn-sm btn-outline-primary mx-auto" onClick={() => this.pagination('tipo', 'S')}>{">>"}</button>
                    </div>
                    <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col col-md-12" style={{ padding: 0 }}>
                            <Paper style={{ padding: '5px' }}>

                                {this.state.sailForce ?
                                    <List dense>
                                        {
                                            this.state.soportesbd != null ?
                                                this.state.busqueda ?
                                                    busqueda.map((soporte, key) => {
                                                        return (
                                                            <ListItem alignItems="flex-start" style={{ borderBottom: '2px solid #0000003d' }}>
                                                                <ListItemAvatar>
                                                                    <Avatar  >
                                                                        {
                                                                            soporte.plataforma === 0
                                                                                ?
                                                                                <WebIcon />
                                                                                : soporte.plataforma === 1
                                                                                    ?
                                                                                    <EquipoIcon />
                                                                                    :
                                                                                    <SitioIcon />
                                                                        }
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={'MOTIVO: ' + soporte.motivos}
                                                                    secondary={
                                                                        <React.Fragment>
                                                                            <Typography
                                                                                variant="subtitle2" gutterBottom
                                                                            >
                                                                                SOLUCION: {soporte.observaciones}
                                                                            </Typography>
                                                                            {"RESPONSABLE: " + (soporte.encargado === 0
                                                                                ?
                                                                                'OSCAR LORA DE SALES'
                                                                                :
                                                                                soporte.encargado === 1
                                                                                    ?
                                                                                    'ANDRES CAMELO ESCHUNCHO'
                                                                                    :
                                                                                    soporte.encargado === 2
                                                                                        ?
                                                                                        'ERICK GARCIA RUIZ'
                                                                                        :
                                                                                        'RODOLFO')}
                                                                            <br></br>
                                                                            {'SOLICITANTE: ' + soporte.solicitante + ' - ' + soporte.equipos}
                                                                            <br></br>
                                                                            {'FECHA: ' + soporte.fecha_soporte_inicio + ' - CONCLUIDO: ' + (soporte.concluido === 'S' ? soporte.fecha_soporte_fin : 'NO')}
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </ListItem>
                                                        );
                                                    })
                                                    :

                                                    this.state.soportesbd.map((soporte, key) => {
                                                        return (
                                                            <ListItem alignItems="flex-start" style={{ borderBottom: '2px solid #0000003d' }}>
                                                                <ListItemAvatar>
                                                                    <Avatar  >
                                                                        {
                                                                            soporte.plataforma === 0
                                                                                ?
                                                                                <WebIcon />
                                                                                : soporte.plataforma === 1
                                                                                    ?
                                                                                    <EquipoIcon />
                                                                                    :
                                                                                    <SitioIcon />
                                                                        }
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={'MOTIVO: ' + soporte.motivos}
                                                                    secondary={
                                                                        <React.Fragment>
                                                                            <Typography
                                                                                variant="subtitle2" gutterBottom
                                                                            >
                                                                                SOLUCION: {soporte.observaciones}
                                                                            </Typography>
                                                                            {"RESPONSABLE: " + (soporte.encargado === 0
                                                                                ?
                                                                                'OSCAR LORA DE SALES'
                                                                                :
                                                                                soporte.encargado === 1
                                                                                    ?
                                                                                    'ANDRES CAMELO ESCHUNCHO'
                                                                                    :
                                                                                    soporte.encargado === 2
                                                                                        ?
                                                                                        'ERICK GARCIA RUIZ'
                                                                                        :
                                                                                        'RODOLFO')}
                                                                            <br></br>
                                                                            {'SOLICITANTE: ' + soporte.solicitante + ' - ' + soporte.equipos}
                                                                            <br></br>
                                                                            {'FECHA: ' + soporte.fecha_soporte_inicio + ' - CONCLUIDO: ' + (soporte.concluido === 'S' ? soporte.fecha_soporte_fin : 'NO')}
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </ListItem>
                                                        );
                                                    })
                                                :
                                                null
                                        }

                                    </List>
                                    :
                                    null}


                            </Paper>
                        </div>

                    </div>
                </div>
            </div> : <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>
        )
    }
}
export default Entradas