
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios'
import swal from 'sweetalert'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import FormularioCategorias from '../../../components/Formularios/FormularioCategoria'
import DeleteIcon from '@material-ui/icons/Delete';
import EditICon from '@material-ui/icons/Edit';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Server from '../../../lib/server'
import store from '../../../lib/store'
import ActivarIcon from "@material-ui/icons/Check"
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
var dominio = ""
const idModulo = 11
var permisos = ''
class Categorias extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Desde bd
            categoriasbd: [],
            //
            ventanaCreacion: false,
            actualizarDatos: false,
            categoriaEditar: null,
            modoEdicion: false,
            modoBusqueda: false,
            modoBusqueda: false,
            categoriaBusqueda: [],
            campoBusqueda: ''
        };
        store.subscribe(() => {
            this.setState({
                actualizarDatos: store.getState().actualizarvista,
                ventanaCreacion: store.getState().creacionCategoria,
            })
        })
    }
    activarCategoria = (id) => {
        var usuario = store.getState().usuario
        var datos = {
            "id": id,
            "entidad_nombre": "grupos",
            "estado": "A",
            "personas_id": usuario.p_id,
            "empresas_id": usuario.empresas_id
        }
        console.log(datos)
        Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/estados/', datos, { headers: { Authorization: ntoken } })
            .then((res) => {
                console.log(res)
                if (res.status == 201) {
                    swal({
                        title: "Categoria activada correctamente",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                store.dispatch({
                                    type: "CREACION_CATEGORIA",
                                    creacionCategoria: false,
                                })
                                break;
                            default:

                        }
                    });
                } else if (res.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    swal("Fallo al activar", "Por favor verifique los datos", "warning");
                }
            })
    }

    eliminarCategoria = (id) => {

        var usuario = store.getState().usuario
        var datos = {
            "id": id,
            "entidad_nombre": "grupos",
            "estado": "I",
            "personas_id": usuario.p_id,
            "empresas_id": usuario.empresas_id
        }
        console.log(datos)
        swal({
            title: "Estas seguro de que quieres deseas inactivar esta categoria?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/estados/', datos, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 201) {
                                swal({
                                    title: "Categoria inactivada correctamente",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            store.dispatch({
                                                type: "ACTUALIZACION_DATOS",
                                                actualizarvista: true
                                            })
                                            store.dispatch({
                                                type: "CREACION_CATEGORIA",
                                                creacionCategoria: false,
                                            })

                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            } else {
                                swal("Fallo al inactivar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }

    editarCategoria = (cat) => {
        this.setState({ modoEdicion: true, categoriaEditar: cat })
        this.setState({ ventanaCreacion: !this.state.ventanaCreacion })
        window.scrollTo(0, 0)
    }

    crearProducto = () => {
        this.setState({ ventanaCreacion: !this.state.ventanaCreacion, modoEdicion: false })
    }

    obtenerDatos = () => {
        var ruta = ""
        var usuario = store.getState().usuario
        dominio = store.getState().dominio
        if (usuario.tipo === "1" || usuario.tipo === "2") {
            ruta = Server.hostPruebasinssl + Server.puertParametrizacion + '/api/grupos/categoria/' + store.getState().empresasHijos
        } else if (usuario.tipo === "3") {
            ruta = Server.hostPruebasinssl + Server.puertParametrizacion + '/api/grupos/categoria/' + dominio
        } else {
            ruta = Server.hostPruebasinssl + Server.puertParametrizacion + '/api/grupos/categoria/' + store.getState().empresasPadres
        }
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        Axios.get(ruta, { headers: { Authorization: ntoken } })
            .then(responsee => {
                console.log(responsee)
                if (responsee.status == 200) {
                    this.setState({ categoriasbd: responsee.data, actualizarDatos: false })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };

    componentWillMount() {
        //Permisos usuarios
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        this.obtenerDatos()
    }

    componentWillReceiveProps() {
        if (this.state.modoEdicion) {
            window.screenTop = 0
        }
        if (this.state.actualizarDatos) {
            console.log("estoy acaaa")
            this.obtenerDatos()
        }
    }

    buscar = () => {

        var cadena = this.state.campoBusqueda.toUpperCase()
        var categorias = []
        this.state.categoriasbd.map((categoria, key) => {
            if (categoria.grupo.includes(cadena)) {
                categorias.push(categoria)
            }
        })
        this.setState({ modoBusqueda: true, categoriaBusqueda: categorias })

    }

    limpiar = () => {
        this.setState({ modoBusqueda: false, categoriaBusqueda: [], campoBusqueda: '' })
    }

    keyBuscar = (e) => {
        if (e.key === 'Enter') {
            this.buscar()
        } else if (e.key === 'Escape') {
            this.limpiar()
        }
    }

    render() {
        var usuario = store.getState().usuario
        var dominio = store.getState().dominio
        return (
            <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                <div className="row">
                    {this.state.ventanaCreacion ? <button className="btn btn-danger" onClick={this.crearProducto.bind(this)}>Cancelar</button> : permisos.includes('C') ? <button className="btn btn-success" onClick={this.crearProducto.bind(this)}>Crear nueva categoria</button> : null}
                </div>
                <div className="row" id="form_categoria" style={{ marginTop: '10px', height: '100%' }}>
                    {this.state.ventanaCreacion ?
                        <FormularioCategorias categoriaEditar={this.state.modoEdicion ? this.state.categoriaEditar : null} />
                        :
                        null}
                </div>
                <div className="row" style={{ marginBottom: '15px', margin: '10px' }}>
                    <div className="col-md-11 mx-auto">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">#</span>
                            </div>
                            <input type="text" placeholder="Buscar" onKeyDown={this.keyBuscar} value={this.state.campoBusqueda} onChange={this.handleChange('campoBusqueda')} className="form-control mx-auto" />
                            {this.state.modoBusqueda ? <div className="input-group-append">
                                <button className="btn btn-outline-primary" disabled={!this.state.modoBusqueda} type="button" onClick={() => this.limpiar()}>x</button>
                            </div> : null}
                            <div className="input-group-append">
                                <button className="btn btn-primary" type="button" onClick={() => this.buscar()}>BUSCAR</button>
                            </div>
                        </div>
                    </div>
                </div>
                {permisos.includes('R') ?
                    this.state.modoBusqueda
                        ?
                        <div className="row" style={{ marginTop: '10px', height: '100%' }}>
                            {this.state.categoriaBusqueda.map((categoria, key) => {
                                var foto = ""
                                if (usuario.tipo === "1" || usuario.tipo === "2") {
                                    foto = categoria != null ? Server.hostMultimedia + categoria.empresas_id + '/categoria/' + (categoria.url_foto == 'N' ? 'default' : categoria.id) + '.png' : Server.hostMultimedia + categoria.empresas_id + '/categoria/default.png'
                                } else {
                                    foto = categoria != null ? Server.hostMultimedia + dominio + '/categoria/' + (categoria.url_foto == 'N' ? 'default' : categoria.id) + '.png' : Server.hostMultimedia + dominio + '/categoria/default.png'
                                }
                                return (
                                    <div className="col-md-6 rounded" style={{ paddingTop: '10px', paddingLeft: '2px' }} key={key}>
                                        <List style={{ backgroundColor: "#fff" }} className="rounded">
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar style={{ backgroundColor: '#fff' }}>
                                                        <img src={foto} height="100%"></img>
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={categoria.grupo + ' (' + (categoria.productos != null ? categoria.productos.length : '0') + ')'}
                                                />
                                                <ListItemSecondaryAction>
                                                    {categoria.estado === "A"
                                                        ? permisos.includes('U') ? <button className="btn btn-sm btn-warning">
                                                            <EditICon onClick={() => this.editarCategoria(categoria)} />
                                                        </button> : null : null}
                                                    {categoria.estado === "A" ? permisos.includes('D') ? <button className="btn btn-sm btn-danger" onClick={() => this.eliminarCategoria(categoria.id)} style={{ marginLeft: '5px' }}>
                                                        <DeleteIcon />
                                                    </button> : null : permisos.includes('U') ? <button className="btn btn-sm btn-success">
                                                        <ActivarIcon onClick={() => this.activarCategoria(categoria.id)} />
                                                    </button> : null}
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <div className="row" style={{ marginTop: '10px', height: '100%' }}>
                            {this.state.categoriasbd.map((categoria, key) => {
                                 var foto = ""
                                 if (usuario.tipo === "1" || usuario.tipo === "2") {
                                     foto = categoria != null ? Server.hostMultimedia + categoria.empresas_id + '/categoria/' + (categoria.url_foto == 'N' ? 'default' : categoria.id) + '.png' : Server.hostMultimedia + categoria.empresas_id + '/categoria/default.png'
                                 } else {
                                     foto = categoria != null ? Server.hostMultimedia + dominio + '/categoria/' + (categoria.url_foto == 'N' ? 'default' : categoria.id) + '.png' : Server.hostMultimedia + dominio + '/categoria/default.png'
                                 }
                                //var foto = categoria != null ? Server.hostMultimedia + dominio + '/categoria/' + (categoria.url_foto == 'N' ? 'default' : categoria.id) + '.png' : Server.hostMultimedia + dominio + '/categoria/default.png'
                                return (
                                    <div className="col-md-6 rounded" style={{ paddingTop: '10px', paddingLeft: '2px' }} key={key}>
                                        <List style={{ backgroundColor: "#fff" }} className="rounded">
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar style={{ backgroundColor: '#fff' }}>
                                                        <img src={foto} height="100%"></img>
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={categoria.grupo + ' (' + (categoria.productos != null ? categoria.productos.length : '0') + ')'}
                                                />
                                                <ListItemSecondaryAction>
                                                    {categoria.estado === "A"
                                                        ? permisos.includes('U') ? <button className="btn btn-sm btn-warning">
                                                            <EditICon onClick={() => this.editarCategoria(categoria)} />
                                                        </button> : null : null}
                                                    {categoria.estado === "A" ? permisos.includes('D') ? <button className="btn btn-sm btn-danger" onClick={() => this.eliminarCategoria(categoria.id)} style={{ marginLeft: '5px' }}>
                                                        <DeleteIcon />
                                                    </button> : null : permisos.includes('U') ? <button className="btn btn-sm btn-success">
                                                        <ActivarIcon onClick={() => this.activarCategoria(categoria.id)} />
                                                    </button> : null}
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List>
                                    </div>
                                )
                            })}
                        </div>
                    :
                    <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>}
            </div >
        )
    }
}


export default Categorias