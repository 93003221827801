import Axios from 'axios';
import Server from "lib/server";
import swal from 'sweetalert';
import store from 'lib/store'

export const getEmpresasByTipo = async (tipo) => {
    return new Promise(function (resolve, reject) {
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        const { empresasHijos } = store.getState()
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/'+tipo+'/padre/' + empresasHijos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var tiendas = []
                if (responsee.status == 200) {
                    responsee.data.empresas.map((dato, key) => (
                        tiendas.push({
                            value: dato.id,
                            label: dato.codigo+" - "+dato.alias
                        })
                    ))

                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
                resolve(tiendas);
            })
            .catch(function (error) {
                // handle error
                reject(error, undefined)
            })
    });

};