/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-redeclare */
/* eslint-disable prettier/prettier */

import Axios from 'axios';
import Server from '../../../lib/server';
import swal from 'sweetalert2';

export const getMonitor = async (data) => {
    return new Promise(function (resolve, reject) {
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        Axios.post(Server.hostNuevo+'/guru.facturacion/Facturas',data, { headers: { Authorization: ntoken } })
        // Axios.get(Server.hostPrueba + Server.puertoEquipos + '/api/equipo/seguimientoEquipo/' + id, { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.status === 200) {
                    var datos = res.data.facturas;
                    resolve(datos);
                } else if (res.status === 205) {
                    var datos = [];
                    resolve(datos);
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err, undefined);
            });
    });

};
export const actualizarVenta = async (data) => {
    return new Promise(function (resolve, reject) {
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        Axios.post(Server.hostNuevo+'/guru.facturacion/actualizarEstadoFactura',data, { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.status === 200) {
                    var datos = res.data.facturas;
                    resolve(datos);
                } else {
                    var datos = [];
                    swal.fire('ERROR!', 'la factura no pudo actualizarse en gurosoft', 'error')
                    resolve(datos);
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })
            .catch((err) => {
                swal.fire('ERROR!', 'la factura no pudo actualizarse en gurosoft', 'error')
                console.error(err);
                reject(err, undefined);
            });
    });

};

export const reenviarVenta = async (data) => {
    return new Promise(function (resolve, reject) {
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        Axios.get(Server.hostNuevo+'/guru.facturacion/ReenviarDatosDian/'+data, { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.status === 200) {
                    resolve(true);
                } else if (res.status === 205) {
                    resolve(false);
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }else{
                    resolve(false);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err, false);
            });
    });

};