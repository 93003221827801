/* eslint-disable no-unused-vars */
/* eslint-disable react/no-deprecated */
/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete';
import EditICon from '@material-ui/icons/Edit';
import Server from '../../../lib/server'
import store from '../../../lib/store'
import Paper from '@material-ui/core/Paper';
import Activar from "@material-ui/icons/Check"
import { MDBDataTable } from 'mdbreact';
import FormularioUnidades from '../../../components/Formularios/FormularioConsecutivo';
import swal from 'sweetalert'
import Tooltip from '@material-ui/core/Tooltip';
import Loader from '../../../components/Loader'
import moment from 'moment'
const idModulo = 31
var permisos = ''
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class Categorias extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Desde bd
            mpagosbd: [],
            loader: true,
            ventanaCreacion: false,
            actualizarDatos: false,
            modoEdicion: false,
            unidadEditar: null,
            consecutivoUltimoFinal: 0
        };
        store.subscribe(() => {
            this.setState({
                actualizarDatos: store.getState().actualizarvista,
                ventanaCreacion: store.getState().creacionConsecutivo,
            })
        })
    }




    crearProducto = () => {
        console.log(this.state.consecutivoUltimoFinal)
        this.setState({ ventanaCreacion: !this.state.ventanaCreacion, unidadEditar: null })
    }

    editarUnidad = (unidad) => {
        console.log(unidad)
        this.setState({ modoEdicion: true, unidadEditar: unidad })
        this.setState({ ventanaCreacion: !this.state.ventanaCreacion })
    }

    eliminarUnidad = (id) => {
        console.log(id)
        Axios.delete(Server.hostPruebasinssl + Server.puertoResoluciones + '/api/Consecutivo/' + id, { headers: { Authorization: ntoken } })
            .then((res) => {
                console.log(res)
                if (res.status == 200) {
                    swal({
                        title: "Resolucion eliminado correctamente",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                store.dispatch({
                                    type: "CREACION_CONSECUTIVO",
                                    creacionConsecutivo: false
                                })
                                break;
                            default:

                        }
                    });
                } else if (res.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                }
            })
    }

    componentWillMount() {
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        this.ontenerDatos()
    }

    componentWillReceiveProps() {

        if (this.state.actualizarDatos) {
            this.ontenerDatos()
        }
    }

    activarUnidad = (id) => {
        Axios.put(Server.hostPruebasinssl + Server.puertoResoluciones + '/api/MediosPagos/activar/' + id, {}, { headers: { Authorization: ntoken } })
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    swal({
                        title: "Resolucion activada correctamente",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                store.dispatch({
                                    type: "CREACION_CONSECUTIVO",
                                    creacionConsecutivo: false
                                })
                                this.setState({ cambbioInicio: false, cambbioFin: false })
                                break;

                            default:

                        }
                    });
                } else if (response.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                }
            })
    }


    setearMiles = (numero) => {
        return (
            (new Intl.NumberFormat("de-DE").format(parseInt(numero)))
        )
    }

    ontenerDatos = () => {
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        let TIPO_DOCUMENTO_VENTA = 9
        let TIPO_DOCUMENTO_FACTURACION_ELECTRONICA = 15
        Axios.get(Server.hostPruebasinssl + Server.puertoResoluciones + '/api/consecutivo/empresa/' + (usuario.tipo === "5" ? usuario.empresas_id : hijos), { headers: { Authorization: ntoken } })
            .then(responsee => {
                var perf = []
                var cfinal = this.state.consecutivoUltimoFinal
                if (responsee.status == 200) {
                    responsee.data.map((perfil) => {
                        if (parseInt(perfil.tipo_documento) === TIPO_DOCUMENTO_VENTA || parseInt(perfil.tipo_documento) === TIPO_DOCUMENTO_FACTURACION_ELECTRONICA) {
                            if (perfil.consecutivo_final >= cfinal) {
                                cfinal = perfil.consecutivo_final
                            }
                            usuario.tipo == "5"
                                ?
                                perf.push({
                                    'tipo': parseInt(perfil.tipo_documento) === TIPO_DOCUMENTO_FACTURACION_ELECTRONICA ? "F. ELECTRONICA" : "F. CONVENCIONAL",
                                    'prefijo': perfil.prefijo,
                                    'consecutivo_inicial': perfil.consecutivo_inicial,
                                    'consecutivo_actual': perfil.consecutivo_actual,
                                    'consecutivo_final': perfil.consecutivo_final,
                                    'fecha_inicio': moment(perfil.fecha_inicio).format("YYYY-MM-DD HH:mm a"),
                                    'fecha_fin': moment(perfil.fecha_fin).format("YYYY-MM-DD HH:mm a"),
                                    "estado": moment(perfil.fecha_fin).format("YYYY-MM-DD HH:mm") < moment(new Date()).format("YYYY-MM-DD HH:mm") ? 'VENCIDA' : perfil.estado === "I" ? 'INACTIVO' : perfil.estado === "A" ? 'ACTIVO' : perfil.estado === "U" ? 'EN USO' : 'AGOTADA',
                                    "acciones": perfil.estado === "A" ? [
                                        permisos.includes('U') ? <Tooltip title={'Editar'} placement="top"><button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.editarUnidad(perfil)}><EditICon /></button></Tooltip> : null,
                                        permisos.includes('D') ? <Tooltip title={'Desactivar'} placement="top"><button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.eliminarUnidad(perfil.id)}><DeleteIcon /></button></Tooltip> : null
                                    ] : perfil.estado == "U" ? [
                                        permisos.includes('D') ? <Tooltip title={'Desactivar'} placement="top"><button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.eliminarUnidad(perfil.id)}><DeleteIcon /></button></Tooltip> : null
                                    ]
                                        :
                                        permisos.includes('U') ? <Tooltip title={'Activar'} placement="top"><button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.activarUnidad(perfil.id)}><Activar /></button></Tooltip> : null
                                })
                                : perf.push({
                                    'tienda': perfil.empresa_alias,
                                    'tipo': parseInt(perfil.tipo_documento) === TIPO_DOCUMENTO_FACTURACION_ELECTRONICA ? "F. ELECTRONICA" : "F. CONVENCIONAL",
                                    'prefijo': perfil.prefijo,
                                    'consecutivo_inicial': perfil.consecutivo_inicial,
                                    'consecutivo_actual': perfil.consecutivo_actual,
                                    'consecutivo_final': perfil.consecutivo_final,
                                    'fecha_inicio': moment(perfil.fecha_inicio).format("YYYY-MM-DD HH:mm a"),
                                    'fecha_fin': moment(perfil.fecha_fin).format("YYYY-MM-DD HH:mm a"),
                                    "estado": moment(perfil.fecha_fin).format("YYYY-MM-DD HH:mm") < moment(new Date()).format("YYYY-MM-DD HH:mm") ? 'VENCIDA' : perfil.estado === "I" ? 'INACTIVO' : perfil.estado === "A" ? 'ACTIVO' : perfil.estado === "A" ? 'ACTIVO' : perfil.estado === "U" ? 'EN USO' : 'AGOTADA',
                                    "acciones": perfil.estado === "A" ? [
                                        permisos.includes('U') ? <Tooltip title={'Editar'} placement="top"><button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.editarUnidad(perfil)}><EditICon /></button></Tooltip> : null,
                                        permisos.includes('D') ? <Tooltip title={'Desactivar'} placement="top"><button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.eliminarUnidad(perfil.id)}><DeleteIcon /></button></Tooltip> : null
                                    ] : perfil.estado == "U" ? [
                                        permisos.includes('D') ? <Tooltip title={'Desactivar'} placement="top"><button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.eliminarUnidad(perfil.id)}><DeleteIcon /></button></Tooltip> : null
                                    ]
                                        :
                                        permisos.includes('U') ? <Tooltip title={'Activar'} placement="top"><button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.activarUnidad(perfil.id)}><Activar /></button></Tooltip> : null
                                })

                        }
                    })

                    this.setState({ mpagosbd: perf, loader: false, consecutivoUltimoFinal: perf.length == 0 ? 0 : cfinal })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    this.setState({ loader: false })
                }

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }


    render() {
        var usuario = store.getState().usuario
        var columns = []
        const tipoDominio = store.getState().tipoDominio
        if (usuario.tipo === "5") {
            columns = [
                {
                    label: 'PREFIJO',
                    field: 'descripcion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'TIPO',
                    field: 'tipo',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'CONS. INICIAL',
                    field: 'credito',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'CONS. ACTUAL',
                    field: 'cambio',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'CONS. FINAL',
                    field: 'maximo',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'FECHA INICIO',
                    field: 'minimo',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'FECHA FINAL',
                    field: 'minimo',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'ESTADO',
                    field: 'estado',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'ACCIONES',
                    field: 'acciones',
                    sort: 'asc',
                    width: 150
                }
            ]
        } else {
            columns = [
                {
                    label: tipoDominio === 'E' ? 'EDS' : 'TIENDA',
                    field: 'tienda',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'TIPO',
                    field: 'tipo',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'PREFIJO',
                    field: 'descripcion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'CONS. INICIAL',
                    field: 'credito',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'CONS. ACTUAL',
                    field: 'cambio',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'CONS. FINAL',
                    field: 'maximo',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'FECHA INICIO',
                    field: 'minimo',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'FECHA FINAL',
                    field: 'minimo',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'ESTADO',
                    field: 'estado',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'ACCIONES',
                    field: 'acciones',
                    sort: 'asc',
                    width: 150
                }
            ]
        }
        var perfiles = {

            columns: columns,
            rows: this.state.mpagosbd
        }
        return (
            this.state.loader
                ?
                <Loader />
                :
                <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                    <div className="row">
                        {this.state.ventanaCreacion ? <button className="btn btn-danger" onClick={this.crearProducto.bind(this)}>CANCELAR</button> : permisos.includes('C') ? <button className="btn btn-success" onClick={this.crearProducto.bind(this)}>CREAR RESOLUCION</button> : null}
                    </div>
                    <div className="row" style={{ marginTop: '10px', height: '100%' }}>
                        {this.state.ventanaCreacion ?
                            <FormularioUnidades unidadActualizar={this.state.unidadEditar != null ? this.state.unidadEditar : null} consecutivoFinal={this.state.consecutivoUltimoFinal} />
                            :
                            null}
                    </div>
                    <div className="row" style={{ marginTop: '10px', height: '100%' }}>
                        {permisos.includes('R') ? <div className="col col-md-12" style={{ padding: 0 }}>
                            <Paper style={{ padding: '5px' }}>

                                <MDBDataTable
                                    //striped
                                    className="col-md-12"
                                    searchLabel="Buscar"
                                    paginationLabel={["Anterior", "Siguente"]}
                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                    entriesLabel="Filas a mostrar"
                                    tbodyColor="primary-color"
                                    theadColor="#FF8800"
                                    exportToCSV={true}
                                    striped
                                    bordered
                                    autoWidth={false}
                                    responsive={true}
                                    hover
                                    data={perfiles}
                                    style={{ fontSize: '13px' }}
                                    sortable={true}
                                />
                            </Paper>
                        </div> : <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>}
                    </div>
                </div >
        )
    }
}


export default Categorias