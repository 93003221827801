import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { MDBDataTable } from 'mdbreact';
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog';
import Loader from '../../../components/Loader'
import Paper from '@material-ui/core/Paper';
import Server from '../../../lib/server'
import store from '../../../lib/store'
import Axios from 'axios'
import Slide from "@material-ui/core/Slide"
import FormularioOrdenCompra from '../../../components/Formularios/FormularioOrdenDEcompra'
import Ojoicon from "@material-ui/icons/RemoveRedEye";
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import swal from 'sweetalert'
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import pdfIcon from "../../../assets/img/pdf.png";
import DeleteIcon from '@material-ui/icons/Delete';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from "@material-ui/core";
import Selects from 'react-select';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Snackbar from '@material-ui/core/Snackbar';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const idModulo = 18
var permisos = ''
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class OrdenDeCompra extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //Variables de componente principal
            createOrdenDeCompra: false,
            //variables del componente de creacion
            dialogproveedor: false,
            dialogproducto: false,
            movimientosbd: [],
            actualizarDatos: false,
            vistahtml: false,
            htmlMostrar: '',
            consecutivo: 0,
            htmlDescarga: false,
            loader: true,
            //Filtros
            expanded: false,
            kioscosbd: [],
            multiEmpresas: "",
            fechaInicio: moment(new Date()).subtract("day", 6).format("YYYY-MM-DD ") + "00:00:00",
            fechaFin: new Date(),
            sinMovimientos:false
        }
        store.subscribe(() => {
            this.setState({
                createOrdenDeCompra: store.getState().ventanaMovimiento,
                actualizarDatos: store.getState().actualizarvista,
            })
        })
    }


    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        switch (name) {
            case "kioscosSeleccionado":
                var empresas = ""
                value.map((empresa, key) => {
                    if (key === 0) {
                        empresas += empresa.value
                    } else {
                        empresas += "," + empresa.value
                    }
                })
                this.setState({
                    multiEmpresas: empresas,
                    busquedaUsuario: true
                })


        }
    }

    handleChangemoment = date => {
        this.setState({ fechaInicio: date });
    }
    handleChangemomentFinal = date => {
        this.setState({ fechaFin: date });
    }



    shouldComponentUpdate(nextProps, nextState) {
        if (store.getState().actualizarvista) {
            this.setState({
                loader: true
            })
            this.obtenerDatos()
            return true
        }
        return true;
    }

    

    detallesMovimiento = (movimiento, datos) => {
        Axios.get(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/movimiento/' + movimiento, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status == 201) {
                    this.setState({ vistahtml: true })
                    var n = responsee.data.observacion.replace('ORDEN DE COMPRA No.', 'ORDEN DE COMPRA No. ' + datos.consecutivo)
                    this.setState({ htmlMostrar: n })
                    document.getElementById('capture').innerHTML = n;
                    this.setState({ consecutivo: datos.consecutivo })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
            })
            .catch(function (error) {
                console.error(error);
            })
    }

    ANULAROC = (id, emp, con) => {
        var usuario = store.getState().usuario
        swal({
            title: "Estas seguro de que deseas cancelar la orden de compra No. " + con,
            text: "Esta orden de compra se anulara",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var data = {
                        "id": id,
                        "movimiento_estado": 55,
                        "persona_id": usuario.p_id,
                        "empresas_id": emp
                    }

                    Axios.put(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/movimiento/updatestado', data, { headers: { Authorization: ntoken } })
                        .then(responsee => {
                            if (responsee.status === 200) {
                                if (responsee.data.response.success) {
                                    swal({
                                        title: "Se ha anulado la orden de compra",
                                        icon: "success",
                                        closeOnClickOutside: false,
                                        buttons: {
                                            Seguir: {
                                                text: "OK",
                                                value: "continuar"
                                            }
                                        },
                                    }).then((value) => {
                                        switch (value) {

                                            case "continuar":
                                                store.dispatch({
                                                    type: "ACTUALIZACION_DATOS",
                                                    actualizarvista: true
                                                })
                                                break;

                                            default:

                                        }
                                    });
                                } else {
                                    swal("Fallo", responsee.data.response.mensaje, "warning");
                                }
                            } else if (responsee.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            }
                        })
                        .catch(function (error) {
                            console.error(error);
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });

    }

    descargarPDF = () => {
        var scala = 5
        const nd = document.getElementById('capture');
        html2canvas(nd)
            .then((canvas) => {
                canvas.width = 800 * scala
                canvas.style.width = '800px'
                var context = canvas.getContext('2d')
                context.scale(scala, scala)
                const imgData = canvas.toDataURL('image/jpg');
                /*var link = document.createElement('a');
                link.download = 'filename.jpg';
                link.href = imgData;
                link.click();*/
                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'cm',
                    format: 'a4'
                });
                pdf.addImage(
                    imgData,
                    'JPG',
                    0,
                    0,
                    21,
                    27
                );
                pdf.save("ORDEN_DE_COMPRA_" + this.state.consecutivo + ".pdf");

            })
    }


    descargarPDF2 = (movimiento, datos) => {
        this.setState({
            htmlDescarga: true
        })
        var estilos = `<style>
        .table td{
            padding: 5px !IMPORTANT;
            align-items: center;
        }
        .ttt th,td{
            border: 1px solid black;
            border-collapse: collapse;
        }
        
        .ttt2{
            padding: 5px;
          text-align: left;    
        }
        
        .numero{
            font-family: 'Roboto', sans-serif;
            font-size:18px;}
        .numero2{
            font-family: 'Roboto', sans-serif !IMPORTANT;
            font-size:10px;}
            
        .fontipo{
            color:#C60000;
            font-family: 'Raleway', sans-serif;
            font-size:11px;
            }
            
            .tb-terpel td{
                font-family: 'Raleway', sans-serif;
            font-size:11px;
            border: 0;
            padding: 0;
            }
            .tile {
                font-family: 'Raleway', sans-serif;
                font-family:11px;
                font-style:bold;
                text-color: #0000; 
                }
        </style>`
        Axios.get(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/movimiento/' + movimiento, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status === 201) {
                    var n = responsee.data.observacion.replace('ORDEN DE COMPRA No.', 'ORDEN DE COMPRA No. ' + datos.consecutivo)
                    document.getElementById('descarga').innerHTML = n;
                    const nd = document.getElementById('descarga');
                    var download = {
                        "template": estilos + nd.innerHTML,
                        "filename": "OC_" + movimiento + ".pdf"
                    }
                    Axios.post(Server.HostPDF + Server.puertoPdf + '/api/pdf/download', download)
                        .then(response => {
                            var url = response.data.url
                            var link = document.createElement('a');
                            link.setAttribute("target", "_blank")
                            link.download = response.data.filename;
                            link.href = url;
                            link.click();
                            this.setState({
                                htmlDescarga: false
                            })
                        })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
            })
            .catch(function (error) {
                console.error(error);
            })

    }

    cancelarPDF = () => {
        this.setState({ vistahtml: !this.state.vistahtml, consecutivo: 0 })
    }

    obtenerDatos = () => {
        store.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: false
        })
        this.setState({
            loader: true
        })
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        var data = {
            empresas_id: this.state.multiEmpresas === "" ? hijos : this.state.multiEmpresas,
            operacion: "2",
            fecha_inicial: moment(new Date(this.state.fechaInicio.toString())).format("YYYY-MM-DD")+" 00:00:00",
            fecha_final: moment(new Date(this.state.fechaFin.toString())).format("YYYY-MM-DD")+" 24:00:00"
        }
        Axios.post(Server.hostPruebasinssl + Server.puertoProducto + '/api/movimiento/lista/operacion', data, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var movimientosTotal = []
                if (responsee.status == 200) {
                    if (responsee.data.movimientos.length === 0) {
                        this.setState({
                            sinMovimientos:true
                        })
                    } else {
                        responsee.data.movimientos.map((mov) => {
                            usuario.tipo === "5" ?
                                mov.movimiento.movimiento_estado === 55
                                    ?
                                    console.log("yhe")
                                    :
                                    movimientosTotal.push({
                                        "consecutivo": mov.movimiento.consecutivo,
                                        "fecha": moment(mov.movimiento.fecha).format("DD/MM/YYYY hh:mm a"),
                                        "acuerdo": mov.contratos != null ? mov.contratos.descripcion : 'NO APLICA',
                                        "responsable": mov.movimiento.personas_id_detalles.nombres + " " + mov.movimiento.personas_id_detalles.apellidos,
                                        "proveedores": mov.movimiento.tercero_id_detalles.nombres + " " + mov.movimiento.tercero_id_detalles.apellidos,
                                        "impuestos": '$' + this.setearMiles(parseInt(mov.movimiento.impuesto_total)),
                                        "total": '$' + this.setearMiles(parseInt(mov.movimiento.costo_total)),
                                        "acciones": [
                                            <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesMovimiento(mov.movimiento.id, mov.movimiento)}><Ojoicon /></button>,
                                            <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.descargarPDF2(mov.movimiento.id, mov.movimiento)} ><img src={pdfIcon} /></button>,
                                            permisos.includes('D') ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.ANULAROC(mov.movimiento.id, mov.movimiento.empresas_id, mov.movimiento.consecutivo)}><DeleteIcon /></button> : null
                                        ]
                                    })

                                :
                                mov.movimiento.movimiento_estado === 55
                                    ?
                                    console.log("yhe")
                                    :
                                    movimientosTotal.push({
                                        "consecutivo": mov.movimiento.consecutivo,
                                        "fecha": moment(mov.movimiento.fecha).format("DD/MM/YYYY hh:mm a"),
                                        "acuerdo": mov.contratos != null ? mov.contratos.descripcion : 'NO APLICA',
                                        "tienda": mov.empresa.alias,
                                        "responsable": mov.movimiento.personas_id_detalles.nombres + " " + mov.movimiento.personas_id_detalles.apellidos,
                                        "proveedores": mov.movimiento.tercero_id_detalles.nombres + " " + mov.movimiento.tercero_id_detalles.apellidos,
                                        "impuestos": '$' + this.setearMiles(parseInt(mov.movimiento.impuesto_total)),
                                        "total": '$' + this.setearMiles(parseInt(mov.movimiento.costo_total)),
                                        "acciones": [
                                            <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesMovimiento(mov.movimiento.id, mov.movimiento)}><Ojoicon /></button>,
                                            <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.descargarPDF2(mov.movimiento.id, mov.movimiento)}><img src={pdfIcon} /></button>,
                                            permisos.includes('D') ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.ANULAROC(mov.movimiento.id, mov.movimiento.empresas_id, mov.movimiento.consecutivo)}><DeleteIcon /></button> : null
                                        ]
                                    })

                        })
                        this.setState({
                            sinMovimientos:false
                        })
    
                    }
                    this.setState({
                        movimientosbd: movimientosTotal,
                        loader: false,
                    })

                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
            })
            .catch(function (error) {
                // handle error
                this.setState({
                    loader: false
                })
                console.error(error);
            })
    }

    componentWillMount = () => {
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        var hijos = store.getState().empresasHijos
        //Obtener las tiendas
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/5/padre/' + hijos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var tiendas = []
                if (responsee.status == 200) {
                    responsee.data.empresas.map((dato, key) => (
                        tiendas.push({
                            value: dato.id,
                            label: dato.alias
                        })
                    ))
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
                this.setState({ kioscosbd: tiendas })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })

        this.obtenerDatos()
    }



    setearMiles = (numero) => {
        return (
            (new Intl.NumberFormat("de-DE").format(parseInt(numero)))
        )
    }


    handleCrearOrdenDeCompra = () => {
        store.dispatch({
            type: "DETALLES_MOVIMIENTO",
            ventanaMovimiento: !this.state.createOrdenDeCompra,
            movimientoDetalles: null,
            vistaMovimiento: false
        })
        this.actualizarToken()
    }
    actualizarToken = () => {
        Axios.get(Server.hostPruebasinssl + Server.puertoAutenticacion, { headers: { Authorization: ntoken } })
            .then(response => {
                if (response.status === 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 206) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 200) {
                    //RESPEUSTA OK
                }
            })
    }
    expandir = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }
    render() {
        var usuario = store.getState().usuario
        var ordenes = {
            columns: usuario.tipo === "5" ? [
                {
                    label: 'CON.',
                    field: 'descripcion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'FECHA',
                    field: 'cantidad',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'ACUERDO',
                    field: 'vlrunitario',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'RESPONSABLE',
                    field: 'responsable',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'PROVEEDOR',
                    field: 'subtotal',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'IMPUESTOS',
                    field: 'impuestos',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'TOTAL COMPRA',
                    field: 'totalproducto',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'ACCIONES',
                    field: 'acciones',
                    sort: 'asc',
                    width: 150
                }
            ] : [
                    {
                        label: 'CON.',
                        field: 'descripcion',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'FECHA',
                        field: 'cantidad',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'ACUERDO',
                        field: 'vlrunitario',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'Tienda',
                        field: 'tienda',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'RESPONSABLE',
                        field: 'responsable',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'PROVEEDOR',
                        field: 'subtotal',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'IMPUESTOS',
                        field: 'impuestos',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'TOTAL COMPRA',
                        field: 'totalproducto',
                        sort: 'asc',
                        width: 150
                    }, {
                        label: 'ACCIONES',
                        field: 'acciones',
                        sort: 'asc',
                        width: 150
                    }
                ],
            rows: this.state.movimientosbd
        }
        return (
            <div>
                {this.state.createOrdenDeCompra ? <button className="btn btn-danger" onClick={this.handleCrearOrdenDeCompra.bind(this)}> CANCELAR</button> : permisos.includes('C') ? <button className="btn btn-success" onClick={this.handleCrearOrdenDeCompra.bind(this)}> NUEVA ORDEN DE COMPRA</button> : null}
                <div>

                    {this.state.createOrdenDeCompra
                        ?

                        <Dialog fullScreen open={this.state.createOrdenDeCompra} onClose={this.FormularioAjuste} TransitionComponent={Transition}>
                            <FormularioOrdenCompra />
                        </Dialog>
                        :
                        null
                    }
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        variant="error"
                        open={this.state.sinMovimientos}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message="NO HAY ORDENES DE COMPRAS PARA ESTOS PARAMETROS DE BUSQUEDA."
                    />
                    <ExpansionPanel expanded={this.state.expanded} className="col-md-12" style={{ zIndex: 1098 }} onChange={() => this.expandir()}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >FILTROS</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div style={{ width: '100%' }}>
                                <div className="row mx-auto">
                                {
                                        usuario.tipo === "5"
                                            ?
                                            null
                                            :
                                            <div className="col-md-5" >
                                                <label htmlFor="nombreprov" className="col-form-label">KIOSCO</label>
                                                <Selects
                                                    placeholder="Seleccione o escriba"
                                                    value={this.state.kioscosSeleccionado}
                                                    onChange={this.handleChanges('kioscosSeleccionado')}
                                                    options={this.state.kioscosbd}
                                                    isMulti
                                                />
                                            </div>
                                    }

                                    <div className={usuario.tipo === "5" ?"col-md-4" :"col-md-2"} >
                                        <label htmlFor="numordencompra" className="col-form-label">FECHA DE INICIO</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DatePicker
                                                format="yyyy-MM-dd"
                                                id="datetime"
                                                value={this.state.fechaInicio}
                                                onChange={this.handleChangemoment}
                                                ampm={false}
                                                disableFuture={true}
                                                className="rounded form-control"
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={usuario.tipo === "5" ?"col-md-4" :"col-md-2"}  >
                                        <label htmlFor="nombreprov" className="col-form-label">FECHA FIN</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DatePicker
                                                format="yyyy-MM-dd"
                                                id="datetime"
                                                value={this.state.fechaFin}
                                                onChange={this.handleChangemomentFinal}
                                                ampm={false}
                                                className="rounded form-control"
                                                disableFuture={true}
                                                minDate={moment(new Date(this.state.fechaInicio.toString())).format("YYYY-MM-DD HH:mm")}
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={usuario.tipo === "5" ?"col-md-4 col-sm-12 bottom-aligned" :"col-md-3 col-sm-12 bottom-aligned"}  >
                                        <button className="btn btn-success btn-block" onClick={() => this.obtenerDatos()}>CONSULTAR</button>
                                    </div>
                                </div>


                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
                {this.state.htmlDescarga
                    ?
                    <div id="ssss" className="mx-auto my-auto">
                        <div className="row"><Loader /></div>
                        <div className="row"><h5>DESCARGANDO LA ORDEN DE COMPRA</h5></div>
                    </div>
                    :
                    null}
                <div>
                    <div className="row" style={{ marginTop: "15px" }}>
                        {permisos.includes('R') ?
                            this.state.loader ?
                                <Loader />
                                :
                                <div className="col col-md-12" style={{ padding: 0 }}>
                                    <Paper style={{ padding: '5px' }}>
                                        <Dialog
                                            open={this.state.vistahtml}
                                            onClose={() => this.cancelarPDF()}
                                            aria-labelledby="form-dialog-title"
                                            maxWidth={'md'}
                                            style={{ padding: '0' }}
                                            disableBackdropClick={false}
                                        >
                                            <DialogContent style={{ padding: '10px' }}>
                                                {this.state.vistahtml ? <div id="capture" className="mx-auto my-auto" style={{ width: '800px', paddingTop: '30px' }}>
                                                </div> : null}
                                            </DialogContent>
                                            <DialogActions>
                                                {/*<button className="btn btn-primary" onClick={() => this.descargarPDF()}>  DESCARGAR</button>*/}
                                                <button className="btn btn-primary" onClick={() => this.cancelarPDF()}>CANCELAR</button>
                                            </DialogActions>
                                        </Dialog>
                                        <MDBDataTable
                                            //striped
                                            className="col-md-12"
                                            searchLabel="Buscar"
                                            paginationLabel={["Anterior", "Siguente"]}
                                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                                            entriesLabel="Filas a mostrar"
                                            tbodyColor="primary-color"
                                            theadColor="#FF8800"
                                            exportToCSV={true}
                                            striped
                                            bordered
                                            style={{ fontSize: '13px' }}
                                            autoWidth={false}
                                            responsive={true}
                                            hover
                                            data={ordenes}
                                        />

                                    </Paper>
                                </div> : <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>}

                    </div>
                    {this.state.htmlDescarga ? <div className="row" style={{ marginTop: "1000px" }}>
                        <div id="descarga" className="mx-auto my-auto" style={{ width: '800px', paddingTop: '30px' }}

                        >
                            <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>

                        </div>
                    </div>
                        :
                        null}
                </div>
            </div >
        )
    }
}
export default OrdenDeCompra