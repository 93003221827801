import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import Avatar from '@material-ui/core/Avatar';
import './cardUsuario.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import imagenUsuario from '../../../assets/img/Formularios/foto_default.png'
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import PhoneIcon from '@material-ui/icons/Phone'
import MailIcon from '@material-ui/icons/Mail'
import CedulaIcon from '@material-ui/icons/PersonOutline'
import store from '../../../lib/store'
import CodigoIcon from '@material-ui/icons/LineWeight'
import Tooltip from '@material-ui/core/Tooltip';
import Server from '../../../lib/server'
import Axios from 'axios'
import swal from 'sweetalert'
import moment from 'moment';
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
const B = (props) => <small style={{ fontWeight: 'bold', marginBottom: "0", display: 'inline' }}>{props.children}</small>
const idModulo = 25
var permisos = ''
class cardUsuario extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detalles: false,
            edicicionUsuario: false,
            vistaUsuario: true,
        };
    }

    componentWillMount() {
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
    }


    detallesUsuario = (usuario) => {
        var vista = true
        console.log(usuario)
        console.log('usuario: ' + this.state.vistaUsuario)
        if (this.state.vistaUsuario) {
            vista = true
        } else {
            vista = false
        }
        store.dispatch({
            type: "CREACION_USUARIO",
            creacionUsuario: true,
            usuarioEdicion: usuario,
            modovista: vista,
            edicicionUsuario: false
        })
    }


    editarUsuario = (usuario) => {
        store.dispatch({
            type: "CREACION_USUARIO",
            creacionUsuario: true,
            usuarioEdicion: usuario,
            modovista: false,
            edicicionUsuario: true
        })
    }

    inactivarUsuario = (usuariod) => {
        const { contacto, perfil, persona, usuario } = usuariod
        var user = store.getState().usuario
        var datos = {
            "empresas_id": usuario.empresas_id,
            "personas_id": persona.id,
            "usuario": usuario.usuario,
            "update_user": user.p_id
        }
        swal({
            title: "Estas seguro de que quieres deseas inactivar este usuario?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.put(Server.hostPrueba + Server.puertoEmpleado + '/api/persona/usuario/estado/0', datos, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 200) {
                                swal({
                                    title: "Usuario inactivado correctamente",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            store.dispatch({
                                                type: "ACTUALIZACION_DATOS",
                                                actualizarvista: true
                                            })
                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            } else {
                                swal("Fallo al inactivar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }


    activarUsuario = (usuariod) => {
        const { contacto, perfil, persona, usuario } = usuariod
        var user = store.getState().usuario
        var datos = {
            "empresas_id": usuario.empresas_id,
            "personas_id": persona.id,
            "usuario": usuario.usuario,
            "update_user": user.p_id
        }
        console.log(datos)
        swal({
            title: "Estas seguro de que quieres deseas activar este usuario?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.put(Server.hostPrueba + Server.puertoEmpleado + '/api/persona/usuario/estado/1', datos, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 200) {
                                swal({
                                    title: "Usuario activado correctamente",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            store.dispatch({
                                                type: "ACTUALIZACION_DATOS",
                                                actualizarvista: true
                                            })
                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            } else {
                                swal("Fallo al inactivar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }


    render() {
        const { usuario } = this.props;
        var persona = usuario.persona
        var user = usuario.usuario
        var perfiles = usuario.perfil
        var dominio = store.getState().dominio
        var contactos = usuario.contacto
        //console.log(persona)
        const imagen = Server.hostMultimedia + dominio + "/persona/default.png"
        const imagenUSer = Server.hostMultimedia + dominio + "/persona/" + (persona.url_foto == null || (persona.url_foto == 'N') ? 'default.png' : persona.id + '.png')+"?"+moment(new Date()).format("YYYYMMMDDHHmmss")
        return (
            <Card >
                <CardActionArea style={{ paddingBottom: '10px' }} >
                    <div className="row" style={{ padding: 0, height: "7px" }}>
                        {perfiles != null ?
                            perfiles.map((perfil, key) => {
                                return (
                                    <Tooltip title={perfil.descripcion} placement="top">
                                        <div className="col col-md-2" key={key} style={{ padding: 0, height: "7px", backgroundColor: perfil.color }}></div>
                                    </Tooltip>
                                )
                            })
                            :
                            null}
                    </div>
                    <div className="row">
                        <div className="col col-lg-3 col-md-3 col-sm-2 mx-auto my-auto">
                            {
                                persona.url_foto != null && persona.url_foto != 'N'
                                    ?
                                    <Avatar onError={(e) => { e.target.onerror = null; e.target.src = imagen }} alt={(persona.nombres + " " + persona.apellidos).toString().toUpperCase()} onEronError={(e) => { e.target.onerror = null; e.target.src = imagen }} title={persona.url_foto} src={imagenUSer} onClick={() => this.detallesUsuario(usuario)} style={{ margin: 10, width: 80, height: 80 }} />
                                    :
                                    <Avatar alt={(persona.nombres + " " + persona.apellidos).toString().toUpperCase()} title={persona.url_foto} src={imagen} onClick={() => this.detallesUsuario(usuario)} style={{ margin: 10, width: 80, height: 80 }} />
                            }
                        </div>
                        <div className=" col col-lg-8 col-md-8 col-sm-9" style={{ padding: "0" }}>
                            <CardContent style={{ paddingBottom: "0", paddingLeft: "5px", paddingRight: "5px", padding: '0' }}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Typography variant="small" gutterBottom>
                                            <B> {(persona.nombres + " " + persona.apellidos).toString().toUpperCase()}</B>{/*<button className="btn btn-sm btn-outline-dark" style={{ float: 'right' }} onClick={() => this.editarUsuario(usuario)}><SettingsICon /></button>*/}
                                        </Typography>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Typography component="small" style={{ fontSize: '12px' }}>
                                            <CedulaIcon />{persona.identificacion}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Typography component="small" style={{ fontSize: '12px' }}>
                                            <MailIcon />{contactos != null ? contactos.map((contacto, key) => {
                                                if (contacto.tipo == 1 && contacto.principal == 'S') {
                                                    return (contacto.contacto)
                                                }
                                            }) : 'N/A'}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Typography component="small" style={{ fontSize: '12px' }}>
                                            <PhoneIcon />{contactos != null ? contactos.map((contacto, key) => {
                                                if (contacto.tipo == 2 && contacto.principal == 'S') {
                                                    return (contacto.contacto)
                                                }
                                            }) : 'N/A'}
                                        </Typography>
                                    </div>
                                </div>


                            </CardContent>
                        </div>
                    </div>

                </CardActionArea>
                <CardActions>

                    <button size="small" className="btn btn-outline-primary" onClick={() => this.detallesUsuario(usuario)}>
                        Detalles
        </button>
                    {permisos.includes('U') ? <button size="small" className="btn btn-outline-warning" onClick={() => this.editarUsuario(usuario)}>
                        Editar
        </button> : null}
                    {permisos.includes('D') ? usuario.usuario.estado === "A" ? <button size="small" className="btn btn-outline-danger" onClick={() => this.inactivarUsuario(usuario)}>
                        Inactivar
        </button>: <button size="small" className="btn btn-outline-success" onClick={() => this.activarUsuario(usuario)}>
                        Activar
        </button>: null}
                </CardActions>
            </Card >

        );
    }
}


export default cardUsuario;