import React from 'react';
import Axios from 'axios'
import Avatar from '@material-ui/core/Avatar';
import FormularioPerfil from '../../../components/Formularios/FormularioPerfil'
import Server from '../../../lib/server'
import store from '../../../lib/store'
import Paper from '@material-ui/core/Paper';
import { MDBDataTable } from 'mdbreact';
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide"
import Ojoicon from "@material-ui/icons/RemoveRedEye"
import DeleteIcon from '@material-ui/icons/Delete';
import EditICon from '@material-ui/icons/Edit';
import Loader from '../../../components/Loader'
import ActivarIcon from "@material-ui/icons/Check"
import swal from 'sweetalert'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
const idModulo = 7
var permisos = ''
class Categorias extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Desde bd
            perfilesbd: [],
            ventanaCreacion: false,
            actualizarDatos: false,
            perfilDetalles: null,
            modovista: false,
            loader: true
        }
        store.subscribe(() => {
            this.setState({
                ventanaCreacion: store.getState().ventanaPerfil,
                actualizarDatos: store.getState().actualizarvista,
            })
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizarDatos) {
            this.obtenerDatos()
            return true
        }
        return true;
    }

    crearProducto = () => {
        store.dispatch({
            type: "CREACION_PERFIL",
            ventanaPerfil: !this.state.ventanaCreacion,
            perfilEditar: null,
            vistaPerfil: false
        })
    }

    activarPerfil = (id) => {
        console.log(id)
    }
    InActivarPerfil = (id) => {
        console.log(id)
    }

    obtenerDatos = () => {
        this.setState({ actualizarDatos: false })
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        switch (usuario.tipo) {
            case "1":
            case "2":
            case "3":
                Axios.get(Server.hostPruebasinssl + Server.puertoAutenticacion + '/perfilesDominios/' + parseInt(usuario.empresas_id, 10) + "," + hijos, { headers: { Authorization: ntoken } })
                    //Axios.get(Server.ipLuis+Server.puertoAutenticacion+'/perfilesDominios/8',{ headers: { Authorization: ntoken } })
                    .then(responsee => {
                        var perf = []
                        if (responsee.status === 200) {
                            responsee.data.data.map((perfil, key) => {
                                perf.push({
                                    'descripcion': perfil.descripcion,
                                    'color': <Avatar alt="Remy Sharp" style={{ margin: 10, width: 20, height: 20, backgroundColor: perfil.color }} />,
                                    'acciones': perfil.estado === "A" ? [
                                        <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesPerfil(perfil.id)}><Ojoicon /></button>,
                                        permisos.includes('U') ? <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.editarPerfil(perfil.id)}><EditICon /></button> : null,
                                        permisos.includes('D') ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.InActivarPerfil(perfil.id)}><DeleteIcon /></button> : null
                                    ] : permisos.includes('U') ? <button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.activarPerfil(perfil.id)}><ActivarIcon /></button> : null
                                })
                            })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }
                        this.setState({ perfilesbd: perf, loader: false })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break;
            case "4":
            case "5":
                Axios.get(Server.hostPruebasinssl + Server.puertoAutenticacion + '/perfilesDominios/' + parseInt(usuario.empresas_id, 10), { headers: { Authorization: ntoken } })
                    //Axios.get(Server.ipLuis+Server.puertoAutenticacion+'/perfilesDominios/8',{ headers: { Authorization: ntoken } })
                    .then(responsee => {
                        var perf = []
                        console.log(responsee)
                        if (responsee === 200) {
                            responsee.data.data.map((perfil, key) => {
                                perf.push({
                                    'descripcion': perfil.descripcion,
                                    'color': <Avatar alt="Remy Sharp" style={{ margin: 10, width: 20, height: 20, backgroundColor: perfil.color }} />,
                                    'acciones': perfil.estado === "A" ? [
                                        <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesPerfil(perfil.id)}><Ojoicon /></button>,
                                        permisos.includes('U') ? <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.editarPerfil(perfil.id)}><EditICon /></button> : null,
                                        permisos.includes('D') ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.InActivarPerfil(perfil.id)}><DeleteIcon /></button> : null
                                    ] : permisos.includes('U') ? <button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.activarPerfil(perfil.id)}><ActivarIcon /></button> : null
                                })
                            })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }

                        this.setState({ perfilesbd: perf, loader: false })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break;
        }

    }

    componentWillMount() {
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        this.obtenerDatos()
    }


    editarPerfil = (id) => {
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/perfiles/' + id, { headers: { Authorization: ntoken } })
            //Axios.get(Server.ipLuis+Server.puertoAutenticacion+'/perfilesDominios/8',{ headers: { Authorization: ntoken } })
            .then(responsee => {
                var perf = []
                if (responsee.status === 200) {
                    store.dispatch({
                        type: "CREACION_PERFIL",
                        ventanaPerfil: !this.state.ventanaCreacion,
                        perfilEditar: responsee.data.data,
                        vistaPerfil: false
                    })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
                //this.setState({ perfilesbd: perf })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    detallesPerfil = (id) => {
        this.setState({ modovista: true })
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/perfiles/' + id, { headers: { Authorization: ntoken } })
            //Axios.get(Server.ipLuis+Server.puertoAutenticacion+'/perfilesDominios/8',{ headers: { Authorization: ntoken } })
            .then(responsee => {
                var perf = []
                if (responsee.status === 200) {
                    store.dispatch({
                        type: "CREACION_PERFIL",
                        ventanaPerfil: !this.state.ventanaCreacion,
                        perfilEditar: responsee.data.data,
                        vistaPerfil: true
                    })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }

                //this.setState({ perfilesbd: perf })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }


    render() {
        var perfiles = {
            columns: [
                {
                    label: 'Descripcion',
                    field: 'descripcion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Color',
                    field: 'cantidad',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Acciones',
                    field: 'acciones',
                    sort: 'asc',
                    width: 150
                }
            ],
            rows: this.state.perfilesbd
        }
        return (
            <div style={{ height: '100%', width: '100%' }}>
                <div className="row">
                    {this.state.ventanaCreacion ? <button className="btn btn-danger" onClick={this.crearProducto.bind(this)}>Cancelar</button> : permisos.includes('C') ? <button className="btn btn-success" onClick={this.crearProducto.bind(this)}>Crear nuevo perfil</button> : null}
                </div>
                <div className="row" style={{ marginTop: '10px', height: '100%' }}>
                    <Dialog fullScreen open={this.state.ventanaCreacion} onClose={this.crearProducto} TransitionComponent={Transition}>
                        <FormularioPerfil />
                    </Dialog>
                </div>
                {permisos.includes('R') ? <div className="row" style={{ marginTop: '10px', height: '100%' }}>
                    <div className="col col-md-12" style={{ padding: 0 }}>
                        <Paper style={{ padding: '5px' }}>

                            {this.state.loader ? <Loader /> : <MDBDataTable
                                //striped
                                className="col-md-12"
                                searchLabel="Buscar"
                                paginationLabel={["Anterior", "Siguente"]}
                                infoLabel={["Mostrando", "a", "de", "entradas"]}
                                entriesLabel="Filas a mostrar"
                                tbodyColor="primary-color"
                                theadColor="#FF8800"
                                exportToCSV={true}
                                striped
                                bordered
                                autoWidth={false}
                                responsive={true}
                                hover
                                data={perfiles}
                            />}
                        </Paper>
                    </div>
                </div> : <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>}
            </div >
        )
    }
}


export default Categorias