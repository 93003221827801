import Axios from 'axios';
import Server from '../../../lib/server';
import swal from 'sweetalert'

export const getDetallesAcuerdo = async (identificadorAcuerdo) => {
    return new Promise(function (resolve, reject) {
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        Axios.get(Server.hostPrueba + Server.puertoEmpresas + '/api/convenios/detalles/' + identificadorAcuerdo, { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data.data);
                } else if (res.status === 205) {
                    resolve([]);
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err, undefined);
            });
    });

};

export const getAcuerdos = async (identificadorEmpresa) => {
    return new Promise(function (resolve, reject) {
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        Axios.get(Server.hostPrueba + Server.puertoEmpresas + '/api/convenios/empresas/' + identificadorEmpresa + '/contratos', { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data.data);
                } else if (res.status === 205) {
                    resolve([]);
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err, undefined);
            });
    });
};

export const getEmpresasAcuerdos = async (identificadorEmpresa) => {
    return new Promise(function (resolve, reject) {
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/4,5/padre/' + identificadorEmpresa, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status === 200 && responsee.data.empresas !== null) {
                    const totales = []
                    const datos = responsee.data.empresas
                    datos.map((emp) => {
                        if (emp.empresas_tipos_id === "5") {
                            totales.push({
                                value: emp.id,
                                label: emp.alias,
                                empresa: emp
                            })
                        }

                    })
                    resolve({ datos, totales })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
                reject(error, undefined);
            })
    });
};

export const getProveedoresAcuerdos = async (identificadorEmpresa) => { //proveedores-selects
    return new Promise(function (resolve, reject) {
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/persona/empresas/' + identificadorEmpresa + '/proveedores-selects/', { headers: { Authorization: ntoken } })
            .then(responsee => {
                const proveedores = responsee.data.rows
                
                resolve(proveedores || [])

            })
            .catch(function (error) {
                // handle error
                console.error(error);
                reject(error, undefined);
            })
    });
};

export const actualizarToken = async () => {
    return new Promise(function (resolve, reject) {
        const ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas, { headers: { Authorization: ntoken } })
            .then(response => {
                if (response.status === 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 206) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                }
                resolve(true);
            })
    });
};

export const columnasProductosAcuerdos = [
    {
        label: 'Plu',
        field: 'plu',
        sort: 'asc',
        width: 150
    }, {
        label: 'Producto',
        field: 'producto',
        sort: 'asc',
        width: 150
    }, {
        label: 'Costo',
        field: 'costo',
        sort: 'asc',
        width: 150
    }, {
        label: 'Flexible',
        field: 'flexible',
        sort: 'asc',
        width: 150
    }, {
        label: 'Costo min.',
        field: 'costo_minimo',
        sort: 'asc',
        width: 150
    }, {
        label: 'Costo max.',
        field: 'costo_maximo',
        sort: 'asc',
        width: 150
    }, {
        label: 'Acciones',
        field: 'acciones',
        sort: 'asc',
        width: 150
    }
]

export const columnasAcuerdos = [
    {
        label: 'CODIGO',
        field: 'codigo',
        sort: 'asc',
        width: 150
    }, {
        label: 'NOMBRE',
        field: 'nombre',
        sort: 'asc',
        width: 150
    }, {
        label: 'FECHA INICIO',
        field: 'fecha_inicio',
        sort: 'asc',
        width: 150
    }, {
        label: 'FECHA FIN',
        field: 'fecha_fin',
        sort: 'asc',
        width: 150
    }, {
        label: 'RESPONSABLE',
        field: 'responsable',
        sort: 'asc',
        width: 150
    }, {
        label: 'PROVEEDOR',
        field: 'proveedor',
        sort: 'asc',
        width: 150
    }, {
        label: 'ACCIONES',
        field: 'acciones',
        sort: 'asc',
        width: 150
    }
]