
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import 'bootstrap/dist/css/bootstrap.min.css';
import CardTittle from '@material-ui/core/CardHeader'
import GridItem from "components/Grid/GridItem.jsx";
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Server from '../../lib/server'
import store from '../../lib/store'
import Axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import swal from 'sweetalert'
import { Button } from 'react-bootstrap'
import Typography from '@material-ui/core/Typography';
import Selects from 'react-select'
import DeleteIcon from '@material-ui/icons/Delete';
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';
import { ms } from 'date-fns/esm/locale';
import SacarIcon from '@material-ui/icons/Redo'
import DevolverIcon from '@material-ui/icons/Undo'
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import "moment/locale/es";
import logoTerpel from '../../assets/img/logos/logo_terpel.png'
import logoDeuna from '../../assets/img/logos/logoo_r2_c2.png'
import linea1 from '../../assets/img/linea.png'
import linea2 from '../../assets/img/linea2.png'
import { Slide } from '@material-ui/core';
var vistaUsuario = false
var impuestoEditar = false
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
var colorDominio = store.getState().colorDominio
class MediosPago extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: new Date(),
            bodegasbd: [],
            productosbd: [],
            productoSeleccinado: '',
            bodegaSeleccionada: {},
            bodegaSalidaSeleccionada: {},
            costoN: '',
            cantidadN: '',
            productosdeentrada: [],
            productosdeSalida: [],
            totalCosto: 0,
            subTotal: 0,
            idEditar: 0,
            //DAtos a guardar
            descripcionN: '',
            minimoValor: '',
            valorn: '',
            modoEditar: false,
            nimpuesto: null,
            productoFoco: [],
            posicionFoco: 0,
            cambbioInicio: false,
            response: false,
            campoBusqueda1: '',
            pBusqueda1: [],
            modoBusqueda1: false,
            campoBusqueda2: '',
            pBusqueda2: [],
            modoBusqueda2: false,
            saldosAnterioresSalida: [],
            saldosAnterioresEntrada: [],
            imprimirPdf: false
        };
    }

    componentWillMount() {
        //bODEGAS
        var usuario = store.getState().usuario
        Axios.get(Server.hostPrueba + Server.puertoSSLBodega + '/api/bodegas/empresa/' + usuario.empresas_id, { headers: { Authorization: ntoken } })
            .then(responsee => {
                //console.log(responsee.data)
                var bdg = []
                responsee.data.map((bodega, key) => {
                    bdg.push({
                        value: bodega.bodega_id,
                        label: bodega.descripcion + " -- " + bodega.codigo
                    })
                })
                this.setState({ bodegasbd: bdg })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    componentDidMount() {
        this.intervalId = setInterval(this.timer.bind(this), 300000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    timer() {
        this.setState({
            currentCount: this.state.currentCount - 1
        })
        if (this.state.currentCount < 1) {
            clearInterval(this.intervalId);
        }
        this.actualizarToken()
    }

    actualizarToken = () => {
        Axios.get(Server.hostPruebasinssl + Server.puertoAutenticacion, { headers: { Authorization: ntoken } })
            .then(response => {
                if (response.status === 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 206) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 200) {
                    //RESPEUSTA OK
                }
            })
    }

    setearMiles = (numero) => {
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case "COP":
                return (
                    (new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(3)))
                )
                break;
            case "USD":
                return (new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(numero))
                break;

        }
    }

    devolvrProducto = (e) => {
        var pEntrada = this.state.productosdeentrada
        var psalida = this.state.productosdeSalida
        var producto = pEntrada[e]
        var boll = true
        psalida.map((producti, key) => {
            if (producti.id == producto.id) {
                psalida[key].saldo = parseInt(psalida[key].saldo) + parseInt(producto.saldo)
                pEntrada.splice(e, 1)
                boll = false
            }
        })
        if (boll) {
            psalida.push(producto)
            pEntrada.splice(e, 1)
        }
        this.setState({ productosdeSalida: psalida, productosdeentrada: pEntrada })
    }

    sacarProducto = (e) => {
        //this.inputRef.focus();
        var mproducto = this.state.productosdeSalida
        this.setState({ productosdeSalida: mproducto, productoSeleccinado: mproducto[e].descripcion, cantidadN: mproducto[e].saldo, productoFoco: mproducto[e], posicionFoco: e })
    }

    cancelarProducto = () => {
        this.setState({ productosdeSalida: [], productoSeleccinado: '', cantidadN: '', productoFoco: [], posicionFoco: 0 })
    }

    sacarProducto1 = (e) => {
        console.log(e)
        var mproducto = this.state.productosdeSalida
        this.setState({ productosdeSalida: mproducto, productoSeleccinado: mproducto[e].descripcion, cantidadN: mproducto[e].saldo, productoFoco: mproducto[e], posicionFoco: e })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };


    changeSelect = name => event => {
        var estado = (event.target.value == 'false' ? false : true)
        this.setState({
            [name]: !estado
        });
    }

    agregarProducto = () => {
        var pentrada = this.state.productosdeentrada
        var psalida = this.state.productosdeSalida
        var productof = this.state.productoFoco
        var seEncontro = false
        if (parseInt(this.state.cantidadN) == parseInt(productof.saldo)) {
            pentrada.map((p, key) => {
                if (p.id === productof.id) {
                    p.saldo = p.saldo + parseInt(this.state.cantidadN)
                    seEncontro = true
                }
            })
            if (!seEncontro) {
                pentrada.push({
                    id: productof.id,
                    descripcion: productof.descripcion,
                    saldo: this.state.cantidadN,
                    plu: productof.plu,
                    costo: productof.costo,
                    id: productof.id,
                })
            }

            psalida.splice(this.state.posicionFoco, 1)
        } else if (parseInt(this.state.cantidadN) == 0) {
            this.setState({ productoSeleccinado: '', cantidadN: '', costoN: '' })
        } else if (parseInt(this.state.cantidadN) > productof.saldo) {
            swal("ERROR", "ESTA INTENTANTO TRASLADAR MAS UNIDADES QUE LAS QUE HAY EN BODEGA", "warning")
        } else if (parseInt(this.state.cantidadN) < 0) {
            swal("ERROR", "LA CANTIDAD A TRANSLADAR DEBE SER UN VALOR POSITIVO", "warning")
        } else if (!/^([0-9])*$/.test(this.state.cantidadN)) {
            swal("ERROR", "LA CANTIDAD DEBE SER UN VALOR ENTERO", "warning")
        } else {
            pentrada.map((p, key) => {
                if (p.id === productof.id) {
                    p.saldo = p.saldo + parseInt(this.state.cantidadN)
                    seEncontro = true
                }
            })
            if (!seEncontro) {
                pentrada.push({
                    id: productof.id,
                    descripcion: productof.descripcion,
                    saldo: parseInt(this.state.cantidadN),
                    plu: productof.plu,
                    costo: productof.costo,
                    id: productof.id,
                })
            }

            psalida[this.state.posicionFoco].saldo = psalida[this.state.posicionFoco].saldo - this.state.cantidadN
        }
        this.setState({ productosdeSalida: psalida, productosdeentrada: pentrada, productoSeleccinado: '', cantidadN: '', costoN: '', modoBusqueda1: false, modoBusqueda2: false })
    }

    imprimir = (nombre) => {
        const nd = document.getElementById('captureee');
        //console.log(nd)
        //console.log(nd.innerHTML)
        //console.log(this.state.imprimirPdf)
        var estilos = `<style>
                        .numero{
                        font-family: 'Roboto', sans-serif;
                        font-size:18px;}
                        .numero2{
                        font-family: 'Roboto', sans-serif !IMPORTANT;
                        font-size:10px;}
        
                      
        
                        .tb-terpel td{
                        font-family: 'Raleway', sans-serif;
                        font-size:11px;
                        border: 0;
                        padding: 0;
                        }
                        </style>`
        //console.log(nd)
        var download = {
            "template": estilos+nd.innerHTML,
            "filename": nombre + ".pdf"
        }
        Axios.post(Server.HostPDF + Server.puertoPdf + '/api/pdf/download', download)
            .then(response => {
                //console.log(response)
                var url = response.data.url
                var link = document.createElement('a');
                link.setAttribute("target", "_blank")
                link.download = response.data.filename;
                link.href = url;
                link.click();
                this.setState({
                    imprimirPdf: false,
                })
            })


    }

    obtenerAnteriores = (tipo, id) => {
        var resultado = 0
        if (tipo === 'entrada') {
            this.state.saldosAnterioresEntrada.map((prod) => {
                if (prod.id === id) {
                    //console.log(prod.saldo, prod)
                    resultado = prod.saldo
                }
            })
        } else {
            this.state.saldosAnterioresSalida.map((prod) => {
                if (prod.id === id) {
                    //console.log(prod.saldo, prod)
                    resultado = prod.saldo
                }
            })
        }
        return resultado
    }

    validarFormulario = () => {
        if (this.state.productosdeentrada.length == 0) {
            swal("ERROR", "NO HA SELECCIONADO NINGUN PRODUCTO", "warning")
            return false
        } else if (JSON.stringify(this.state.bodegaSeleccionada) == '{}' || this.state.bodegaSeleccionada == null) {
            swal("ERROR", "NO SE HA SELECCIONADO LA BODEGA DESTINO", "warning")
            return false
        } else if (this.state.bodegaSalidaSeleccionada.value === this.state.bodegaSeleccionada.value) {
            swal("ERROR", "NO SE PUEDEN REALIZAR TRANSLADOS A LA MISMA BODEGA", "warning")
            return false
        } else {
            this.setState({
                response: true,
                imprimirPdf: true
            })
            return true
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };

    registrar = () => {
        var usuario = store.getState().usuario
        var validar = this.validarFormulario()
        var datos = {
            "movimiento": {
                "empresas_id": parseInt(usuario.empresas_id),
                "operacion": 8,
                "fecha": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "consecutivo": 0,
                "persona_id": usuario.p_id,
                "persona_nit": "",
                "persona_nombre": "",
                "tercero_id": 1,
                "tercero_nit": "",
                "tercero_nombre": "",
                "costo_total": 1,
                "venta_total": 1,
                "impuesto_total": 1,
                "descuento_total": 0,
                "origen_id": 0,
                "impreso": "N",
                "create_user": 8,
                "create_date": "",
                "remoto_id": 0,
                "sincronizado": 0,
                "movimiento_estado": 0
            },
            "bodega": {
                //"bodega_idI": this.state.bodegaSeleccionada.value,
                "bodega_idI": this.state.bodegaSalidaSeleccionada.value,
                "bodega_idF": this.state.bodegaSeleccionada.value
            },
            "detalle":
                this.state.productosdeentrada.map((producto, key) => {
                    return ({
                        "productos_id": producto.id,
                        "cantidad": producto.saldo,
                        "costo_producto": 0,
                        "precio": 0,
                        "descuento_id": 0,
                        "descuento_producto": 0,
                        "remoto_id": 0,
                        "sincronizado": 0,
                        "subtotal": 0
                    })
                })

        }
        console.log(datos)
        if (validar) {
            let t = this
            Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/movimiento', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    this.setState({
                        response: false
                    })
                    console.log(response)
                    if (response.status === 201) {

                        setTimeout(function () { t.imprimir("Traslado entre bodega") }, 200)
                        setTimeout(function () { t.swall(response.data.message) }, 300)
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }


    }


    swall = (mensaje) => {
        swal({
            title: mensaje,
            icon: "success",
            closeOnClickOutside: false,
            buttons: {
                Seguir: {
                    text: "OK",
                    value: "continuar"
                }
            },
        }).then((value) => {
            switch (value) {

                case "continuar":
                    store.dispatch({
                        type: "ACTUALIZACION_DATOS",
                        actualizarvista: true
                    })
                    store.dispatch({
                        type: "TRASLADO_BODEGA",
                        trasladoBodega: false
                    })
                    //this.setState({loader:true,descripcionN:'',selectedDate:new Date(),selectedDateFin:new Date(),checkIPorcentaje:true,checkIValor:false,valorn:false,cambbioInicio: false,cambbioFin:false})
                    break;

                default:

            }
        });
    }

    cancelar = () => {
        store.dispatch({
            type: "TRASLADO_BODEGA",
            trasladoBodega: false
        })
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        switch (name) {
            case 'bodegaSalidaSeleccionada':
                Axios.get(Server.hostPrueba + Server.puertoInventario + '/api/bodegas/productos/' + value.value, { headers: { Authorization: ntoken } })
                    .then((res) => {
                        console.log(res)
                        if (res.data != null) {
                            var productostotal = []
                            res.data.map((producto, key) => {
                                productostotal.push({
                                    id: producto.productos.id,
                                    plu: producto.productos.plu,
                                    costo: producto.productos.costo,
                                    descripcion: producto.productos.descripcion,
                                    idempresas_id: producto.productos.empresas_id,
                                    estado: producto.productos.estado,
                                    precio: producto.productos.precio,
                                    saldo: producto.productos.saldo,
                                    unidades_contenido_desc: producto.productos.unidades_contenido_desc,
                                    unidades_medida_desc: producto.productos.unidades_medida_desc,
                                    unidades_contenido: producto.productos.unidades_contenido,
                                    unidad_basica_desc: producto.productos.unidad_basica_desc,
                                    posicion: key,
                                })
                            })
                            this.setState({ productosdeSalida: productostotal, productosdeentrada: [], saldosAnterioresSalida: productostotal })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                break;
            case 'bodegaSeleccionada':
                Axios.get(Server.hostPrueba + Server.puertoInventario + '/api/bodegas/productos/' + value.value, { headers: { Authorization: ntoken } })
                    .then((res) => {
                        console.log(res)
                        if (res.data != null) {
                            var productostotal = []
                            res.data.map((producto, key) => {
                                productostotal.push({
                                    id: producto.productos.id,
                                    plu: producto.productos.plu,
                                    costo: producto.productos.costo,
                                    descripcion: producto.productos.descripcion,
                                    idempresas_id: producto.productos.empresas_id,
                                    estado: producto.productos.estado,
                                    precio: producto.productos.precio,
                                    saldo: producto.productos.saldo,
                                    unidades_contenido_desc: producto.productos.unidades_contenido_desc,
                                    unidades_medida_desc: producto.productos.unidades_medida_desc,
                                    unidades_contenido: producto.productos.unidades_contenido,
                                    unidad_basica_desc: producto.productos.unidad_basica_desc,
                                    posicion: key,
                                })
                            })
                            this.setState({ saldosAnterioresEntrada: productostotal })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                break;
        }
    };


    buscar1 = () => {
        var cadena = this.state.campoBusqueda1.toUpperCase()
        var ps = []
        this.state.productosdeSalida.map((producto, key) => {
            if (producto.descripcion.includes(cadena) || producto.plu.includes(cadena)) {
                ps.push(producto)
            }
        })
        this.setState({ modoBusqueda1: true, pBusqueda1: ps })

    }

    limpiar1 = () => {
        this.setState({ modoBusqueda1: false, pBusqueda1: [], campoBusqueda1: '' })
    }

    keyBuscar1 = (e) => {
        if (e.key === 'Enter') {
            this.buscar1()
        } else if (e.key === 'Escape') {
            this.limpiar1()
        }
    }

    buscar2 = () => {
        var cadena = this.state.campoBusqueda2.toUpperCase()
        var ps = []
        this.state.productosdeentrada.map((producto, key) => {
            if (producto.descripcion.includes(cadena)) {
                ps.push(producto)
            }
        })
        this.setState({ modoBusqueda2: true, pBusqueda2: ps })

    }

    limpiar2 = () => {
        this.setState({ modoBusqueda2: false, pBusqueda2: [], campoBusqueda2: '' })
    }

    keyBuscar2 = (e) => {
        if (e.key === 'Enter') {
            this.buscar2()
        } else if (e.key === 'Escape') {
            this.limpiar2()
        }
    }

    render() {
        var translados1 = {
            columns: [
                {
                    label: 'PLU',
                    field: 'descripcion',
                    width: 150
                }, {
                    label: 'PRODUCTO',
                    field: 'cantidad',
                    width: 150
                }, {
                    label: 'CANTIDAD',
                    field: 'tienda',
                    width: 150
                }, {
                    label: 'TRANSLADAR',
                    field: 'tras',
                    width: 150
                }
            ],
            rows: this.state.productosdeSalida.map((row, key) => {
                return (
                    {
                        'plu': row.plu,
                        'descipcion': row.descripcion,
                        'cantidad': row.saldo,
                        'acciones': <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.sacarProducto(key)}><SacarIcon /></button>,
                    }
                )
            })
        }
        var translados2 = {
            columns: [
                {
                    label: 'PLU',
                    field: 'descripcion',
                    width: 150
                }, {
                    label: 'PRODUCTO',
                    field: 'cantidad',
                    width: 150
                }, {
                    label: 'CANTIDAD',
                    field: 'tienda',
                    width: 150
                }, {
                    label: 'DEVOLVER',
                    field: 'dev',
                    width: 150
                }
            ],
            rows: this.state.productosdeentrada.map((row, key) => {
                //console.log(row)
                return (
                    {
                        'plu': row.plu,
                        'descipcion': row.descripcion,
                        'cantidad': row.saldo,
                        'acciones': <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.devolvrProducto(key)}><DevolverIcon /></button>,
                    }
                )
            })
        }
        var total = 0
        var usuario = store.getState().usuario
        const nd = document.getElementById('capture');
        var n = document.getElementById('capture');
        return (
            <div>
                <AppBar style={{ backgroundColor: colorDominio }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Close"
                            onClick={this.cancelar.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            style={{ marginLeft: "theme.spacing(2)", flex: 1, color: '#fff' }}
                        >
                            {vistaUsuario ? 'Edicion de PRODUCTO' : 'TRASLADO DE BODEGAS'}
                        </Typography>
                        {
                            vistaUsuario
                                ?
                                <Button style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} disabled={this.state.response} /*onClick={this.editarProducto.bind(this)}*/>
                                    GUARDAR
              </Button>
                                :
                                <Button style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} disabled={this.state.response} onClick={this.registrar.bind(this)}>
                                    TERMINAR Y GUARDAR
              </Button>
                        }

                    </Toolbar>
                </AppBar>
                <div className="row align-middle mx-auto" style={{ marginTop: '7%' }}>
                    <div className="col col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">Fecha</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>>
                                    <DatePicker
                                        disabled={true}
                                        format="yyyy-MM-dd HH:mm"
                                        id="datetime"
                                        value={this.state.selectedDate}
                                        //onChange={this.handleChangemoment}
                                        onChange={this.handleChangemoment}
                                        className="rounded form-control"
                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">Bodega de salida</label>
                                <Selects
                                    placeholder="Seleccione"
                                    value={this.state.bodegaSalidaSeleccionada}
                                    onChange={this.handleChanges('bodegaSalidaSeleccionada')}
                                    options={this.state.bodegasbd}
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">Bodega de entrada</label>
                                <Selects
                                    placeholder="Seleccione"
                                    value={this.state.bodegaSeleccionada}
                                    onChange={this.handleChanges('bodegaSeleccionada')}
                                    options={this.state.bodegasbd}
                                />
                            </div>
                        </div>
                        <div className="row mx-auto" style={{ marginTop: '10px' }}>
                            <h5 className="mx-auto">Ingrese los datos del producto a agregar</h5>
                        </div>
                        <div className="row">
                            <div className="col-md-5">
                                <label htmlFor="numordencompra" className="col-form-label">PRODUCTO</label>
                                <input id="numordencompra" value={this.state.productoSeleccinado} className="form-control" type="text" disabled={true} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">Cantidad</label>
                                <input id="cant_producto" value={this.state.cantidadN} className="form-control" type="number" min="1" disabled={this.state.productoSeleccinado == '' || this.state.productoSeleccinado.length == 0} onChange={this.handleChange('cantidadN')} />
                            </div>
                            <div className="col-md-1 bottom-aligned">
                                <button className="btn btn-primary bottom-aligned" disabled={this.state.productoSeleccinado == '' || this.state.cantidadN == ''} onClick={() => this.agregarProducto()}>TRANSALDAR</button>
                            </div>
                        </div>
                        {this.state.productosdeSalida.length == 0
                            ? null :
                            <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                <div className="col col-md-6 mx-auto">
                                    {/*<div className="row" style={{ marginBottom: '15px', margin: '10px' }}>
                                        <div className="col-md-11 mx-auto">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">#</span>
                                                </div>
                                                <input type="text" placeholder="Buscar" onKeyDown={this.keyBuscar1} value={this.state.campoBusqueda} onChange={this.handleChange('campoBusqueda1')} className="form-control mx-auto" />
                                                {this.state.modoBusqueda1 ? <div className="input-group-append">
                                                    <button className="btn btn-outline-primary" disabled={!this.state.modoBusqueda1} type="button" onClick={() => this.limpiar1()}>x</button>
                                                </div> : null}
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="button" onClick={() => this.buscar1()}>BUSCAR</button>
                                                </div>
                                            </div>
                                        </div>
                        </div>*/}
                                    <Paper style={{ padding: '10px' }}>
                                        <div className="row">
                                            <h5 className="mx-auto">{this.state.productosdeSalida.length == 0 ? null : 'Bodega de salida'}</h5>
                                        </div>
                                        <MDBDataTable
                                            //striped
                                            className="col-md-12 mx-auto"
                                            searchLabel="Buscar"
                                            paginationLabel={["Anterior", "Siguente"]}
                                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                                            entriesLabel="Filas a mostrar"
                                            tbodyColor="primary-color"
                                            theadColor="#FF8800"
                                            exportToCSV={true}
                                            striped
                                            bordered
                                            autoWidth={false}
                                            responsive={true}
                                            hover
                                            order={['plu', 'asc']}
                                            data={translados1}
                                        />
                                    </Paper>
                                </div>
                                <div className="col col-md-6 mx-auto">
                                    <Paper style={{ padding: '10px' }}>
                                        <div className="row">
                                            <h5 className="mx-auto">{this.state.productosdeSalida.length == 0 ? null : 'Bodega entrante'}</h5>
                                        </div>
                                        <MDBDataTable
                                            //striped
                                            className="col-md-12 mx-auto"
                                            searchLabel="Buscar"
                                            paginationLabel={["Anterior", "Siguente"]}
                                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                                            entriesLabel="Filas a mostrar"
                                            tbodyColor="primary-color"
                                            theadColor="#FF8800"
                                            exportToCSV={true}
                                            striped
                                            bordered
                                            autoWidth={false}
                                            responsive={true}
                                            hover
                                            order={['plu', 'asc']}
                                            data={translados2}
                                        />
                                    </Paper>

                                </div>
                            </div>
                        }

                    </div>
                    {this.state.imprimirPdf ?
                        <div id="captureee" style={{ width: '800px', display: 'block', height:'100%',marginTop: '20px' }}>
                            <table className="tb-terpel" width="712px" height="773px" border="0" align="center" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td width="763px" valign="top" className="fondocuadro">
                                        <table width="712px" border="0" cellpadding="0" cellspacing="0">
                                            <tr>
                                                <td width="169"><img src={logoTerpel} width="138" height="63" alt="" /></td>
                                                <td width="335" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                    <tr>
                                                        <td align="center">TRASLADO ENTRE BODEGA</td>
                                                    </tr>
                                                </table></td>
                                                <td width="106" align="center"><img src={logoDeuna} width="64" height="64" alt="" /></td>
                                            </tr>
                                        </table>
                                        <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                            <tr>
                                                <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                            </tr>
                                        </table>
                                        <table width="712px" border="0" cellpadding="0" cellspacing="3">
                                            <tr>
                                                <td width="299" valign="top"><b className="fontipo">Tienda:</b> {usuario.empresas.alias}</td>
                                                <td width="66" align="left" valign="top"><b>Razon social: </b></td>
                                                <td width="233" align="left" valign="top">{usuario.empresas.razon_social}</td>
                                            </tr>
                                            <tr>
                                                <td valign="top"><b>Nit: </b>{usuario.empresas.nit}</td>
                                                <td align="left" valign="top"></td>
                                                <td align="left" valign="top"><font className="numero2"></font></td>
                                            </tr>
                                        </table>
                                        <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                            <tr>
                                                <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                            </tr>
                                        </table>
                                        <table width="712px" border="0" cellpadding="3" cellspacing="0">
                                            <tr>
                                                <td width="80" className="fontipo"><b>Responsable</b></td>
                                                <td width="219" align="left" valign="top">{usuario.nombres + " " + usuario.apellidos}</td>
                                                <td align="left" valign="top"><b>Fecha: </b></td>
                                                <td align="left" valign="top"><font className="numero2">{moment(new Date()).format("DD/MM/YYYY hh:mm a")}</font></td>
                                            </tr>
                                          
                                        </table>
                                        <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                            <tr>
                                                <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                            </tr>
                                        </table>
                                        <table width="712px" border="0" cellpadding="3" cellspacing="0">
                                            <tr >
                                                <td width="80" align="left" valign="top"><b>Bodega inicial:</b></td>
                                                <td width="219" align="left" valign="top">{this.state.bodegaSalidaSeleccionada.label}</td>
                                                <td width="80" align="left" valign="top"><b>Bodega final:</b></td>
                                                <td width="219" align="left" valign="top">{this.state.bodegaSeleccionada.label}</td>
                                            </tr>
                                        </table>
                                        <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                            <tr>
                                                <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                            </tr>
                                        </table>
                                        <table width="712px" border="0" cellspacing="0" cellpadding="3">
                                            <tr>
                                                <td width="40" align="center" bgcolor="#EEEEEE"><b>COD</b></td>
                                                <td width="122" align="left" bgcolor="#EEEEEE"><b>PRODUCTO</b></td>
                                                <td width="37" align="right" bgcolor="#EEEEEE"><b>CANT.</b></td>
                                                <td width="60" align="right" bgcolor="#EEEEEE"><b>BOD. INICIAL</b></td>
                                                <td width="60" align="right" bgcolor="#EEEEEE"><b>BOD. FINAL</b></td>
                                                <td width="46" align="right" bgcolor="#EEEEEE"><b>COSTO U.</b></td>
                                                <td width="70" align="right" bgcolor="#EEEEEE"><b>COSTO T.</b></td>
                                            </tr>
                                        </table>
                                        {this.state.productosdeentrada.map((row, key) => {

                                            total = total + (row.saldo * row.costo)
                                            return (
                                                <div>
                                                    <table width="712px" border="0" cellspacing="0" cellpadding="3" >
                                                        <tr>
                                                            <td width="40" align="center" valign="middle" className="numero2">{row.plu}</td>
                                                            <td width="122" valign="middle" align="left" className="numero2">{row.descripcion}</td>
                                                            <td width="37" align="right" valign="middle" className="numero2">{row.saldo}</td>
                                                            <td width="60" align="right" valign="middle" className="numero2">{this.obtenerAnteriores('saliente', row.id)}</td>
                                                            <td width="60" align="right" valign="middle" className="numero2">{this.obtenerAnteriores('entrada', row.id) + row.saldo}</td>
                                                            <td width="46" align="right" valign="middle" className="numero2">{'$ ' + this.setearMiles(row.costo)}</td>
                                                            <td width="70" align="right" valign="middle" className="numero2">{'$ ' + this.setearMiles(row.saldo * row.costo)}</td>
                                                        </tr>
                                                    </table>
                                                    <table className="tb-2" width="100%" border="0" cellspacing="0" cellpadding="0">
                                                        <tr>
                                                            <td><img src={linea2} width="712px" height="5" alt="" /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            )
                                        }
                                        )}
                                        <table width="712px" border="0" cellpadding="0" cellspacing="0">
                                            <tr >
                                                <td width="100" valign="middle" ><table width="100" border="0" align="right" cellpadding="3" cellspacing="0">
                                                    <tr >
                                                        <td width="54" align="right" valign="middle" class="numero2" >{'$ ' + this.setearMiles(total)}</td>
                                                    </tr>
                                                </table></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        :
                                    null}
                </div >
            </div >



        )
    }
}


export default MediosPago
