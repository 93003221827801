import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./producto.css"
import Axios from 'axios'
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop'
import Selects from 'react-select';
import Slider from '@material-ui/lab/Slider'
import getCroppedImg from './getCroppedImg'
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from 'react-bootstrap'
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FormularioProveedor from './FormularioProvedores';
import FormularioCategoria from './FormularioCategoria';
import FormularioImpuesto from './FormularioImpuesto';
import productoDefault from "../../assets/img/Formularios/productos.png"
import Server from '../../lib/server'
import DialogIdentificador from './dialogs/dialogIdentificador'
import store from '../../lib/store'
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import swal from 'sweetalert'
import Switch from '@material-ui/core/Switch';
import FavoritoOn from '../../assets/img/Formularios/favorito_activo.png'
import FavoritoOff from '../../assets/img/Formularios/favorito_inactivo.png'
import ChangeImage from '../../assets/img/Formularios/change_foto.png'
import Tooltip from '@material-ui/core/Tooltip';
import PreguntaIcon from '@material-ui/icons/ContactSupport';
import PersonaIcon from '@material-ui/icons/AccountCircle';
import Slide from "@material-ui/core/Slide"
import EditICon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { Snackbar } from '@material-ui/core';
import { isThursday } from 'date-fns/esm';
import { isUndefined } from 'util';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
var imagenProducto = ""
var Detalles = false
//VAriables de vista y edicion
var producto = {}
var identificadores = []
var productos_provedor = []
var ingredientres = []
var productos_impuestos = []
var categorias = []
var tiendas = []
var ramasEmpresas = []
var totalesEmpresasbd = []
var nodes = [];
var nn = []
var usuario = ""
var moneda = ""
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
var colorDominio = store.getState().colorDominio
class FormularioProducto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Variables para la creacion de nuevo producto
            tipoEdicion: false,
            dialogIDentificafor: false,
            //Traidas de la bd
            //Foto del producto
            dialogphoto: false,
            crop: { x: 0, y: 0 },
            zoom: 1,
            croppedAreaPixels: null,
            croppedImage: null,
            aspect: 1 / 1,
            //
            nombreProductoNuevo: '',
            codigoInternoNuevo: '',
            codigodeBarrasNuevo: [],
            ventanaEdicion: false,
            imagenCreate: false,
            imageSrc: null,
            preciocompra: '',
            precioventa: '',
            chipData: [],
            contenidoProductoNuevo: '',
            //Check
            checkListaPrecios: false,
            favorito: false,
            checkIngrediente: false,
            checkIngredienteDisabled: false,
            checkIngredienteChecked: false,
            checkCompuesto: false,
            checkCompuestoDisabled: false,
            checkCompuestoChecked: false,
            checkProducido: false,
            checkProducidoDisabled: false,
            checkProducidoChecked: false,
            checkSeCompra: false,
            checkSeCompraDisabled: false,
            checkSeCompraChecked: false,
            checkSeVende: false,
            checkSeVendeDisabled: false,
            checkSeVendeChecked: false,
            checkDesintegrado: false,
            checkDesintegradoDisabled: false,
            checkDesintegradoChecked: false,
            multiCodigoBarra: null,
            multiCategorias: null,
            single: null,
            checkBalanza: false,
            checkDispensado: false,
            //
            ingredientesbd: [],
            proveedoresbd: [],
            identificadores: [],
            ingredientesProductoCompuesto: [],
            ningredienteProveedor: '',
            ningredienteProdcuto: {},
            ningredienteCantidad: '',
            proveedoresGuardar: [],
            //ExpansionPaneles
            panelDetallesProductos: true,
            panelIngredientes: false,
            panelProveedor: false,
            dialogproveedor: false,
            panelListaPrecio: false,
            precioLista: '',
            listaPrecios: [],
            //Select
            provedores: '',
            precioProvedor: '',
            idprovedor: '',
            proveedoresGuardar: [],
            //Datos seleccionados
            modovista: false,
            unidadesbd: [],
            unidadesSeleccionada: {},
            cantidadMinimaNuevo: '',
            cantidadMaximaNuevo: '',
            precioMaximoNuevo: '',
            precioMinimoNuevo: '',
            tipoPrecio: '%',
            boolTipo: false,
            tiempoReorden: '',
            precioFlexible: false,
            categoriasSeleccionadas: [],
            unidadContenido: {},
            //Tipos
            tipoProducto: [],
            tipoProductosbd: [],
            impuestosGuardar: [],
            impuestosbd: [],
            multiImpuestos: [],
            actualizarvista: false,
            tipoDetalles: 0,
            codigoValidado: false,
            impuestosProvedor: [],
            dialogCategoria: false,
            dialogImpuesto: false,
            ivaProveedorn: false,
            acomuladoCostProducto: 0,
            ingredientesTotales: [],
            cambio: false,
            productoEditar: null,
            vistaCargando: true,
            proveedores_productos_eliminados: [],
            ingredientes_eliminados: [],
            productos_impuestos_eliminados: [],
            cambioFoto: false,
            //
            xSucursal: false,
            vectorAuxiliar2: [],
            tiendasbd: [],
            sucursalesbd: [],
            sucursalSeleccionada: {},
            empresasxSucursalSeleccionada: [],
            cambioEmpresa: false,
            alertaEmpresas: false,
            checked: [],
            expanded: [],
            nodeEmpresas: [],
            proveedorSeleccionado: {},
            multiImpuestosProveedor: [],
            impuestosProveedorGuardar: [],
            impuestosProveedorTotal: [],
            empresasbd: [],
            empresasGuardar: [],
            empresaSeleccionda: {},
            precioporEmpresa: '',
            objetosSeleccionados: [],
            auxiliarProveedoresSeleecionados: [],
            auxiliarTiendasSeleccionadas: [],
            auxiliarIngredientesSeleccionadas: [],
            response: false,
            vuelveRenderizar: false
        };
        store.subscribe(() => {
            this.setState({
                identificadores: store.getState().codigos,
                dialogIDentificafor: store.getState().creacionCodigo,
                modovista: store.getState().vistaProducto,
                productoEditar: store.getState().productoEdicion,
                actualizarvista: store.getState().actualizarvista,
                dialogproveedor: store.getState().creacionUsuario,
                dialogCategoria: store.getState().creacionCategoria,
                dialogImpuesto: store.getState().creacionImpuesto
            })
        })

    }


    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.vistaCargando) {
            var productoe = this.state.productoEditar
            if (this.state.modovista) {
                producto = productoe.producto
                identificadores = productoe.identificadores
                productos_provedor = productoe.proveedores_productos
                ingredientres = productoe.ingredientes
                productos_impuestos = productoe.productos_impuestos
                categorias = productoe.categorias
                tiendas = productoe.tiendas
                var dominio = store.getState().dominio
                imagenProducto = Server.hostMultimedia + dominio + "/productos/" + (producto.url_foto == 'N' ? "default" : producto.id) + ".png"
                var auxIdentificador = []
                if (identificadores != null) {
                    identificadores.map((identificador) => {
                        auxIdentificador.push({ codigos: identificador.identificador })
                    })
                }
                store.dispatch({
                    type: "AGREGAR_IDENTIFICADOR",
                    codigos: auxIdentificador,
                    creacionCodigo: false

                })
                var auxCategorias = []
                var auxCategorias2 = []
                if (categorias != null) {
                    categorias.map((categoria) => {
                        auxCategorias2.push({
                            value: categoria.gp_id,
                            label: categoria.grupo
                        })
                        auxCategorias.push({
                            id: categoria.gp_id
                        })
                    })
                }
                this.setState({ multiCategorias: auxCategorias2 })
                var auxingredientes = []
                if (ingredientres != null) {
                    ingredientres.map((ingrediente) => {
                        ////console.log(ingrediente)
                        auxingredientes.push({
                            id: ingrediente.id,
                            producto: ingrediente.descripcion + " -- " + ingrediente.plu,
                            cantidad: ingrediente.cantidad,
                            nuevo: "S",
                            estado: "A",
                            plu: ingrediente.plu,
                            ucontenido: ingrediente.unidad_basica_desc,
                            tiendas: ingrediente.tiendas
                        })

                    })
                    this.setState({ ingredientesProductoCompuesto: auxingredientes })
                }
                var auxImpuestos = []
                var auxImpuestos2 = []
                if (productos_impuestos != null) {
                    productos_impuestos.map((impuesto) => {
                        ////console.log(impuesto)
                        auxImpuestos.push({
                            impuestos_id: impuesto.impuestos_id,
                            iva_incluido: impuesto.iva_incluido,
                            check: impuesto.iva_incluido == 'S',
                            id: impuesto.id,
                            descripcion: impuesto.descripcion,
                            porcentaje_valor: impuesto.porcentaje_valor,
                            valor: impuesto.valor,
                            tipo_impuesto: "V",
                        })
                        auxImpuestos2.push({
                            id: impuesto.id,
                            iva_incluido: impuesto.iva_incluido,
                            check: impuesto.iva_incluido == 'S',
                            value: impuesto.impuestos_id,
                            label: impuesto.descripcion + " -> " + (impuesto.porcentaje_valor == '$' ? '$' + impuesto.valor : impuesto.valor + '%'),
                            descripcion: impuesto.descripcion,
                            porcentaje_valor: impuesto.porcentaje_valor,
                            valor: impuesto.valor,
                            tipo_impuesto: "V",

                        })
                    })
                    this.setState({ impuestosGuardar: auxImpuestos, multiImpuestos: auxImpuestos2 })
                }
                var auxProveedores = []
                var auxProveedoresImpuestos = []
                if (productos_provedor != null) {
                    productos_provedor.map((proveedor) => {
                        auxProveedores.push({
                            id: proveedor.proveedores_id,
                            proveedor: proveedor.proveedor_nombre,
                            precio: proveedor.costo,
                            impuestos: proveedor.impuestos != null ? proveedor.impuestos.map((impuesto) => {
                                //console.log(impuesto)
                                auxProveedoresImpuestos.push(
                                    {
                                        impuestos_id: impuesto.impuestos_id,
                                        iva_incluido: impuesto.iva_incluido,
                                        id: impuesto.id == undefined ? '' : impuesto.id,
                                        check: false,
                                        descripcion: impuesto.descripcion,
                                        porcentaje_valor: impuesto.porcentaje_valor,
                                        valor: impuesto.valor,
                                        tipo_impuesto: "C",
                                        proveedor_id: proveedor.proveedores_id,
                                    }
                                )
                                return (
                                    {
                                        impuestos_id: impuesto.impuestos_id,
                                        iva_incluido: impuesto.iva_incluido,
                                        id: impuesto.id == undefined ? '' : impuesto.id,
                                        check: false,
                                        descripcion: impuesto.descripcion,
                                        porcentaje_valor: impuesto.porcentaje_valor,
                                        valor: impuesto.valor,
                                        tipo_impuesto: "C",
                                        proveedor_id: proveedor.proveedores_id,
                                    }
                                )

                            }) : []
                        })
                    })
                    this.setState({ proveedoresGuardar: auxProveedores, Correos: auxProveedores, impuestosProveedorTotal: auxProveedoresImpuestos })
                }
                var tiendasCheckeadas = []
                var tiendasPrecios = []
                if (tiendas != null) {
                    tiendas.map((tiendas, key) => {
                        //console.log(tiendas)
                        tiendasPrecios.push({
                            id: tiendas.empresa,
                            empresa: tiendas.alias,
                            precio: tiendas.precio,
                            estado: "A",
                            relacion_id: tiendas.id,
                            disponible: tiendas.disponible,

                        })
                        tiendasCheckeadas.push(tiendas.empresa)
                        if (!nn.includes(tiendas)) {
                            nn.push(tiendas)
                        }
                    })
                    this.setState({ empresasGuardar: tiendasPrecios })
                }
                this.setState({
                    croppedImage: 'S',
                    cambioFoto: false,
                    nombreProductoNuevo: producto.descripcion,
                    codigoInternoNuevo: producto.plu,
                    checkSeVende: producto.puede_vender == 'S' ? true : false,
                    panelListaPrecio: producto.puede_vender == 'S' ? true : false,
                    checkSeCompra: producto.puede_comprar == 'S' ? true : false,
                    panelProveedor: producto.puede_comprar == 'S' ? true : false,
                    checkIngrediente: producto.ingrediente == 'S' ? true : false,
                    checkDispensado: producto.dispensado == 'S' ? true : false,
                    checkBalanza: producto.usa_balanza == 'S' ? true : false,
                    favorito: producto.favorito == 'S' ? true : false,
                    checkDesintegrado: producto.desintegrable == 'S' ? true : false,
                    identificadores: auxIdentificador,
                    precioMaximoNuevo: producto.precio_maximo,
                    precioMinimoNuevo: producto.precio_minimo,
                    tipoPrecio: producto.precio_tipo_limite,
                    vistaCargando: false,
                    cantidadMaximaNuevo: producto.cantidad_maxima + "",
                    cantidadMinimaNuevo: producto.cantidad_minima,
                    tiempoReorden: producto.tiempo_reorden,
                    tipoDetalles: producto.tipo + '',
                    boolTipo: producto.precio_tipo_limite === '$',
                    precioFlexible: producto.precio_flexible === 'S' ? true : false,
                    precioventa: producto.precio + '',
                    unidadesSeleccionada: {
                        value: parseInt(producto.unidades_medida_id, 10),
                        label: producto.unidades_medida
                    },
                    unidadContenido: {
                        value: parseInt(producto.unidades_contenido_id, 10),
                        label: producto.unidades_contenido
                    },
                    tipoProducto: {
                        value: producto.tipo,
                        label: producto.tipo_desc
                    },
                    tiendasbd: tiendas,
                    checked: tiendasCheckeadas

                })
            }
        }
        if (store.getState().actualizarvista) {
            this.actualizarDatos()
            return true
        }
        return true;
    }

    cambiarContacto = () => {
        if (this.state.boolTipo) {
            this.setState({ tipoPrecio: '%' })
            if (parseInt(this.state.precioMinimoNuevo) > 100) {
                this.setState({
                    precioMinimoNuevo: 100
                })
            }
            if (parseInt(this.state.precioMaximoNuevo) > 100) {
                this.setState({
                    precioMaximoNuevo: 100
                })
            }
        } else {
            this.setState({ tipoPrecio: '$' })
        }
        this.setState({ boolTipo: !this.state.boolTipo })
    }

    buscarhijos = (id) => {
        var empresasss = ramasEmpresas
        if (ramasEmpresas.length == 0) {
            totalesEmpresasbd.map((empresas, key) => {
                if (empresas.empresas_id == id) {
                    empresasss.push({
                        value: empresas.id,
                        label: empresas.razon_social,
                        tipo: empresas.empresas_tipos_id,
                        children: this.buscar_Subhijos(empresas.id)
                    })
                }
            })
        }
        this.setState({ nodeEmpresas: empresasss })
    }

    buscar_Subhijos = (id) => {
        var hijiii = []
        totalesEmpresasbd.map((empresas, key) => {
            if (empresas.empresas_id == id) {
                hijiii.push({
                    value: empresas.id,
                    label: empresas.razon_social,
                    tipo: empresas.empresas_tipos_id,
                    children: this.buscar_Subhijos(empresas.id)

                })
                this.setState({ actualizar: true })
            }
        })
        return hijiii
    }

    actualizarDatos = () => {
        Detalles = store.getState().vista
        var datos = store.getState().usuario
        var dominio = store.getState().dominio
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        //Plu
        if (store.getState().productoEdicion == null) {
            Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/plu/' + datos.empresas_id, { headers: { Authorization: ntoken } })
                .then(responsee => {
                    var plu = ''
                    if (responsee.data.data != null) {
                        plu = responsee.data.data[0].next_plu
                    }
                    this.setState({ codigoInternoNuevo: plu })
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                })
        }
        //Unidades
        Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/unidades/empresas/' + dominio, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var unidades = []
                ////console.log(responsee.data)
                responsee.data.map((unidad) => {
                    if (unidad.estado === "A") {
                        unidades.push({
                            value: parseInt(unidad.id, 10),
                            label: unidad.descripcion
                        })
                    }

                })
                this.setState({ unidadesbd: unidades })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/grupos/categoria/' + datos.empresas_id, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var categorias = []
                responsee.data.map((categoria) => {
                    if (categoria.estado == "A") {
                        categorias.push({
                            value: categoria.id,
                            label: categoria.grupo
                        })
                    }
                })
                this.setState({ categoriasbd: categorias })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })

        //Tipos de producto
        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/tipo/productos', { headers: { Authorization: ntoken } })
            .then(responsee => {
                var tipos = []
                ////console.log(responsee)
                responsee.data.data.map((tipo) => {
                    tipos.push({
                        value: tipo.id,
                        label: tipo.descripcion
                    })
                })
                this.setState({ tipoProductosbd: tipos })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        //ingredientes
        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/ingredientes/' + parseInt(datos.empresas_id, 10), { headers: { Authorization: ntoken } })
            .then(responsee => {
                var ingredientes = []
                var impuestoTotal = []
                ////console.log(responsee.data)
                if (responsee.data.ingredientes != null) {
                    responsee.data.ingredientes.map((categoria, key) => {
                        impuestoTotal.push(categoria)
                        ingredientes.push({
                            value: categoria.id,
                            label: categoria.descripcion + " -- " + categoria.plu,
                            ucontenido: categoria.unidades_contenido_desc != null ? categoria.unidades_contenido_desc : '',
                            tiendas: categoria.tiendas,
                        })
                    })
                    this.setState({ ingredientesbd: ingredientes, ingredientesTotales: impuestoTotal })
                    this.revisarIngredientesRepetidos()
                }

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        /*Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/' + parseInt(datos.empresas_id, 10))
            .then((res) => {
                //console.log(res)
                if (res.data != null) {
                    var producto = []
                    var productoTotales = []
                    res.data.Productos.map((prod, key) => {
                        productoTotales.push(prod)
                        if (prod.ingrediente == "S") {
                            producto.push({
                                value: prod.id,
                                label: prod.descripcion + " -- " + prod.plu,
                                ucontenido: prod.unidades_medida,
                            })
                        }
                    })
                    this.setState({ ingredientesbd: producto, ingredientesTotales: productoTotales })

                }
            })
            .catch((err) => {
                //console.log(err)
            })*/

        var hijos = store.getState().empresasHijos
        //Empresas
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/4,5/padre/' + hijos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                ////console.log(responsee.data.empresas)
                if (responsee.status == 200 && responsee.data.empresas != null) {
                    totalesEmpresasbd = responsee.data.empresas
                    var tiendas = []
                    var sucursales = []
                    console.log(store.getState().vistaProducto)
                    responsee.data.empresas.map((empresa) => {
                        if (store.getState().vistaProducto) {
                            tiendas.push({
                                value: empresa.id,
                                label: empresa.alias,
                            })
                        } else {
                            if (empresa.empresas_tipos_id === "5") {
                                tiendas.push({
                                    value: empresa.id,
                                    label: empresa.alias,
                                    empresas_id: empresa.empresas_id,
                                    alias: empresa.alias
                                })
                            } else {
                                sucursales.push({
                                    value: empresa.id,
                                    label: empresa.alias,
                                    alias: empresa.alias
                                })
                            }

                        }

                    })
                    this.setState({ empresasbd: tiendas, sucursalesbd: sucursales })
                    //this.buscarhijos(datos.empresas_id)
                    this.revisarTiendasRepetidas()
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })

        //Impuestos
        Axios.get(Server.hostPrueba + Server.puertSSLParametrizacion + '/api/impuestos/empresas/' + parseInt(datos.empresas_id, 10), { headers: { Authorization: ntoken } })
            .then(responsee => {
                var impuesto = []
                responsee.data.map((unidad) => {
                    if (unidad.estado == "A") {
                        impuesto.push({
                            value: parseInt(unidad.id, 10),
                            label: unidad.descripcion + " -> " + (unidad.porcentaje_valor == '$' ? '$' + unidad.valor : unidad.valor + '%'),
                            descripcion: unidad.descripcion,
                            valor: unidad.valor,
                            porcentaje_valor: unidad.porcentaje_valor
                        })
                    }
                })
                this.setState({ impuestosbd: impuesto })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })

        //proveedores
        ////console.log(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/persona/empresas/' + parseInt(datos.empresas_id, 10), parseInt(datos.empresas_id, 10) + '/proveedores/')
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/persona/empresas/' + parseInt(datos.empresas_id, 10) + ',' + parseInt(store.getState().dominio, 10) + '/proveedores/', { headers: { Authorization: ntoken } })
            .then(responsee => {
                var prove = []
                ////console.log(responsee.data)
                if (responsee.data.rows != null) {
                    responsee.data.rows.map((item, key) => {
                        if (item.persona.estado === "A") {
                            prove.push({
                                value: item.proveedor.id,
                                label: item.persona.nombres + " " + item.persona.apellidos,
                            })
                        }
                    })
                    this.setState({ proveedoresbd: prove })
                    this.revisarProveedoresRepetidos()
                }
                this.setState({ proveedoresbd: prove })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        store.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: false
        })
    }


    componentWillMount() {
        usuario = store.getState().usuario
        moneda = usuario.empresas.paises_moneda
        this.actualizarDatos()
    }

    revisarProveedoresRepetidos = () => {
        var existentes = []
        var posiciones = []
        this.state.proveedoresGuardar.map((proveedor, key) => {
            existentes.push({
                value: proveedor.id,
                label: proveedor.proveedor
            })
        })
        existentes.map((tiend, key) => {
            existentes[key] = JSON.stringify(tiend)
        })
        this.state.proveedoresbd.map((proveedor, key) => {
            if (existentes.includes(JSON.stringify(proveedor))) {
                posiciones.push(key)
            }
        })
        posiciones.map((posicion, llave) => {
            this.agregarObjetosSeleccionados(this.state.proveedoresbd, 'proveedoresbd', this.state.auxiliarProveedoresSeleecionados, 'auxiliarProveedoresSeleecionados', posicion - llave)
        })
    }

    revisarTiendasRepetidas = () => {
        var existentes = []
        var posiciones = []
        this.state.empresasGuardar.map((tienda, key) => {
            existentes.push({
                value: tienda.id.toString(),
                label: tienda.empresa
            })
        })
        existentes.map((tiend, key) => {
            existentes[key] = tiend.value + ""
        })
        this.state.empresasbd.map((ingrediente, key) => {
            if (existentes.includes(ingrediente.value + "")) {
                posiciones.push(key)
            }
        })
        posiciones.map((posicion, llave) => {
            this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', posicion - llave)
        })
    }
    componentDidMount() {
        this.intervalId = setInterval(this.timer.bind(this), 300000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    timer() {
        this.setState({
            currentCount: this.state.currentCount - 1
        })
        if (this.state.currentCount < 1) {
            clearInterval(this.intervalId);
        }
        this.actualizarToken()
    }

    actualizarToken = () => {
        Axios.get(Server.hostPruebasinssl + Server.puertoAutenticacion, { headers: { Authorization: ntoken } })
            .then(response => {
                if (response.status === 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 206) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 200) {
                    //RESPEUSTA OK
                }
            })
    }

    revisarIngredientesRepetidos = () => {
        var ingredientes = this.state.ingredientesbd
        var id = 0
        if (this.state.productoEditar != null) {
            id = this.state.productoEditar.producto.id
        }
        this.state.ingredientesbd.map((ingrediente, key) => {
            if (parseInt(ingrediente.value) === parseInt(id)) {
                ingredientes.splice(key, 1)
            }
        })
        this.setState({
            ingredientesbd: ingredientes
        })
        var existentes = []
        var posiciones = []
        this.state.ingredientesProductoCompuesto.map((ingrediente) => {
            existentes.push({
                value: ingrediente.id.toString(),
                label: ingrediente.producto,
                ucontenido: ingrediente.ucontenido
            })
        })
        existentes.map((tiend, key) => {
            existentes[key] = JSON.stringify(tiend)
        })
        this.state.ingredientesbd.map((ingrediente, key) => {
            if (existentes.includes(JSON.stringify(ingrediente))) {
                posiciones.push(key)
            }
        })
        posiciones.map((posicion, llave) => {
            this.agregarObjetosSeleccionados(this.state.ingredientesbd, 'ingredientesbd', this.state.auxiliarIngredientesSeleccionadas, 'auxiliarIngredientesSeleccionadas', posicion - llave)
        })
    }

    calcularPum = (id, cantidad) => {
        var costo = 0
        this.state.ingredientesTotales.map((ingrediente) => {
            if (ingrediente.id == id) {
                costo = parseFloat(cantidad, 20) * (ingrediente.precio / ingrediente.unidades_contenido_valor)
            }
        })
        this.setState({ acomuladoCostProducto: this.state.acomuladoCostProducto + costo })
        this.mostrarCostoProducido()
    }

    restarPum = (id, cantidad) => {
        var costo = 0
        this.state.ingredientesTotales.map((ingrediente) => {
            if (ingrediente.id == id) {
                costo = parseFloat(cantidad, 20) * (ingrediente.precio / ingrediente.unidades_contenido_valor)
            }
        })
        this.setState({ acomuladoCostProducto: this.state.acomuladoCostProducto - costo })
        this.mostrarCostoProducido()
    }


    agregarIngrediente = () => {
        if (!isNaN(parseInt(this.state.ningredienteCantidad))) {
            var nproductos = this.state.ingredientesProductoCompuesto
            this.calcularPum(this.state.ningredienteProdcuto.value, this.state.ningredienteCantidad)
            nproductos.push({
                id: this.state.ningredienteProdcuto.value,
                producto: this.state.ningredienteProdcuto.label,
                cantidad: this.state.ningredienteCantidad,
                tiendas: this.state.ningredienteProdcuto.tiendas,
                estado: "A",
                nuevo: "S",
            })
            this.agregarObjetosSeleccionados(this.state.ingredientesbd, 'ingredientesbd', this.state.auxiliarIngredientesSeleccionadas, 'auxiliarIngredientesSeleccionadas', this.state.ingredientesbd.indexOf(this.state.ningredienteProdcuto))
            this.setState({ ingredientesProductoCompuesto: nproductos, ningredienteProdcuto: {}, ningredienteCantidad: '' })
        } else {
            swal(
                ' CANTIDA NO VALIDA PARA INGREDIENTE   ' + this.state.ningredienteProdcuto.label, '', 'warning'
            )
        }
    }

    /*agregarPrecio = precio => {
        var nprecios = this.state.listaPrecios
        nprecios.push({
            precio: precio,
        })
        this.setState({ listaPrecios: nprecios, precioLista: '' })
    }*/

    mostrarCostoProducido = () => {
        //alert("para produciur este nproducto te estas gastando:" + this.state.acomuladoCostProducto)
    }

    validarFormulario = () => {

        var vistaUsuario = this.state.modovista
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        var precioMinimo = 0
        var precioMaximo = 0
        var precioVenta = 0
        switch (moneda) {
            case 'COP':
                precioMinimo = parseInt(this.state.precioMinimoNuevo)
                precioMaximo = parseInt(this.state.precioMaximoNuevo)
                precioVenta = parseInt(this.state.precioventa)
                break;
            case 'USD':
                precioMinimo = parseFloat(this.state.precioMinimoNuevo)
                precioMaximo = parseFloat(this.state.precioMaximoNuevo)
                precioVenta = parseFloat(this.state.precioventa)
                break;
        }
        var tipo = this.state.boolTipo
        if (tipo) {
            precioMinimo = precioMinimo
            precioMaximo = precioMaximo
        } else {
            precioMinimo = precioVenta - ((precioVenta * precioMinimo) / 100)
            precioMaximo = precioVenta + ((precioVenta * precioMaximo) / 100)
        }
        var validarIngredientes = true
        var mensajeError = ''
        if (this.state.tipoDetalles === "2" || this.state.tipoDetalles === "3") {
            var empresas_id = []
            this.state.empresasGuardar.map((tienda) => {
                if (tienda.estado === "A") {
                    empresas_id.push({
                        id: parseInt(tienda.id),
                        nombre: tienda.empresa
                    })
                }
            })
            this.state.ingredientesProductoCompuesto.map((ingrediente) => {
                if (ingrediente.tiendas != null) {
                    var tingredientes = []
                    ingrediente.tiendas.map((tienda) => {
                        tingredientes.push(parseInt(tienda.empresa))
                    })
                    empresas_id.map((empresa) => {
                        if (!tingredientes.includes(empresa.id)) {
                            validarIngredientes = false
                            mensajeError += "EL PRODUCTO " + ingrediente.producto + " NO ESTA EN LA TIENDA " + empresa.nombre + " \n"
                        }
                    })

                }
            })
        }
        switch (moneda) {
            case 'COP':
                if (this.state.checkSeCompra && this.state.proveedoresGuardar.length == 0) {
                    swal("FALLO AL CREAR", "Usted marco la opcion de que el producto se puede comprar, asi que debe colocar por lo menos un proveedor", "warning")
                    return false
                } else if (store.getState().auxiliar3.includes(this.state.nombreProductoNuevo) && !vistaUsuario) {
                    swal("FALLO AL CREAR", "YA EXISTE UN PRODUCTO CON ESTE NOMBRE", "warning")
                    return false
                } else if (this.state.tipoDetalles == "3" && this.state.ingredientesProductoCompuesto.length == 0) {
                    swal("FALLO AL CREAR", "Para guardar este producto es necesario que agregue ingredientes", "warning")
                    return false
                } else if (this.state.tipoDetalles == "2" && this.state.ingredientesProductoCompuesto.length == 0) {
                    swal("FALLO AL CREAR", "Para guardar este producto es necesario que agregue ingredientes", "warning")
                    return false
                } else if (this.state.nombreProductoNuevo.length == 0) {
                    swal("FALLO AL CREAR", "El nombre del producto es necesario", "warning")
                    return false
                } else if (precioVenta < precioMinimo) {
                    swal("FALLO AL CREAR", "El precio de venta no puede ser menor al minimo configurado", "warning")
                    return false
                } else if (precioMinimo > precioMaximo) {
                    swal("FALLO AL CREAR", "El precio minimo debe ser menor al precio maximo configurado", "warning")
                    return false
                } else if (precioVenta > precioMaximo) {
                    swal("FALLO AL CREAR", "El precio de venta no puede ser mayor al maximo configurado", "warning")
                    return false
                } else if (this.state.checkSeVende && this.state.precioventa.length == 0) {
                    swal("FALLO AL CREAR", "Se debe colocar el precio del producto", "warning")
                    return false
                } else if (JSON.stringify(this.state.unidadesSeleccionada) == '{}') {
                    swal("FALLO AL CREAR", "Se debe seleccionar la unidad de medida", "warning")
                    return false
                } else if (JSON.stringify(this.state.unidadContenido) == '{}' && (this.state.tipoDetalles == "1" || this.state.tipoDetalles == "0")) {
                    swal("FALLO AL CREAR", "Se debe seleccionar la unidad de embalaje", "warning")
                    return false
                } else if (parseInt(this.state.precioventa) < 0) {
                    swal("FALLO AL CREAR", "El precio no puede ser negativo", "warning")
                    return false
                } else if (!validarIngredientes) {
                    swal("FALLO AL CREAR", mensajeError, "warning")
                    return false
                } else if (!/^([0-9])*$/.test(this.state.precioventa)) {
                    swal("FALLO AL CREAR", "El precio debe ser entero", "warning")
                    return false
                } else {
                    this.setState({
                        response: true
                    })
                    return true
                }
                break;
            case 'USD':
                if (this.state.checkSeCompra && this.state.proveedoresGuardar.length == 0) {
                    swal("FALLO AL CREAR", "Usted marco la opcion de que el producto se puede comprar, asi que debe colocar por lo menos un proveedor", "warning")
                    return false
                } else if (store.getState().auxiliar3.includes(this.state.nombreProductoNuevo) && !vistaUsuario) {
                    swal("FALLO AL CREAR", "YA EXISTE UN PRODUCTO CON ESTE NOMBRE", "warning")
                    return false
                } else if (this.state.tipoDetalles == "3" && this.state.ingredientesProductoCompuesto.length == 0) {
                    swal("FALLO AL CREAR", "Para guardar este producto es necesario que agregue ingredientes", "warning")
                    return false
                } else if (this.state.tipoDetalles == "2" && this.state.ingredientesProductoCompuesto.length == 0) {
                    swal("FALLO AL CREAR", "Para guardar este producto es necesario que agregue ingredientes", "warning")
                    return false
                } else if (this.state.nombreProductoNuevo.length == 0) {
                    swal("FALLO AL CREAR", "El nombre del producto es necesario", "warning")
                    return false
                } else if (precioVenta < precioMinimo) {
                    swal("FALLO AL CREAR", "El precio de venta no puede ser menor al minimo configurado", "warning")
                    return false
                } else if (precioMinimo > precioMaximo) {
                    swal("FALLO AL CREAR", "El precio minimo debe ser menor al precio maximo configurado", "warning")
                    return false
                } else if (precioVenta > precioMaximo) {
                    swal("FALLO AL CREAR", "El precio de venta no puede ser mayor al maximo configurado", "warning")
                    return false
                } else if (this.state.checkSeVende && this.state.precioventa.length == 0) {
                    swal("FALLO AL CREAR", "Se debe colocar el precio del producto", "warning")
                    return false
                } else if (JSON.stringify(this.state.unidadesSeleccionada) == '{}') {
                    swal("FALLO AL CREAR", "Se debe seleccionar la unidad de medida", "warning")
                    return false
                } else if (JSON.stringify(this.state.unidadContenido) == '{}' && (this.state.tipoDetalles == "1" || this.state.tipoDetalles == "0")) {
                    swal("FALLO AL CREAR", "Se debe seleccionar la unidad de embalaje", "warning")
                    return false
                } else if (parseFloat(this.state.precioventa) < 0) {
                    swal("FALLO AL CREAR", "El precio no puede ser negativo", "warning")
                    return false
                } else {
                    this.setState({
                        response: true
                    })
                    return true
                }
                break;
        }


    }


    editarProducto = () => {
        var id = this.state.productoEditar.producto.id
        var usuario = store.getState().usuario
        var dominio = store.getState().dominio
        var impuiestosTotales = []
        var validado = this.validarFormulario()
        this.state.impuestosGuardar.map((impuesto) => {
            impuiestosTotales.push(impuesto)
        })
        this.state.impuestosProveedorTotal.map((impuesto) => {
            //console.log("impuesto proveedor: ", impuesto)
            impuiestosTotales.push(impuesto)
        })
        var precioMinimo = 0
        var precioMaximo = 0
        var precioVenta = 0
        switch (moneda) {
            case 'COP':
                precioMinimo = parseInt(this.state.precioMinimoNuevo)
                precioMaximo = parseInt(this.state.precioMaximoNuevo)
                precioVenta = parseInt(this.state.precioventa)
                break;
            case 'USD':
                precioMinimo = parseFloat(this.state.precioMinimoNuevo)
                precioMaximo = parseFloat(this.state.precioMaximoNuevo)
                precioVenta = parseFloat(this.state.precioventa)
                break;
        }
        var tipo = this.state.boolTipo
        if (tipo) {
            precioMinimo = precioMinimo
            precioMaximo = precioMaximo
        } else {
            precioMinimo = precioVenta - ((precioVenta * precioMinimo) / 100)
            precioMaximo = precioVenta + ((precioVenta * precioMaximo) / 100)
        }
        var datos = {
            "producto": {
                "id": id,
                "empresas_id": parseInt(usuario.empresas_id, 10),
                "plu": this.state.codigoInternoNuevo,
                "descripcion": this.state.nombreProductoNuevo,
                "precio": this.state.precioventa != null ? this.state.precioventa == '' ? '0' : this.state.precioventa : 0,
                "puede_vender": this.state.checkSeVende ? 'S' : 'N',
                "puede_comprar": this.state.checkSeCompra ? 'S' : 'N',
                "ingrediente": this.state.checkIngrediente ? 'S' : 'N',
                "tipo": parseInt(this.state.tipoProducto.value, 10),
                "url_foto": this.state.modovista ? this.state.cambioFoto ? 'S' : "N" : this.state.imagePreviewUrl != null ? "S" : "N",
                "unidades_medida": this.state.unidadesSeleccionada.value,
                "unidades_contenido": this.state.tipoProducto.value == '2' ? this.state.unidadesSeleccionada.value : this.state.unidadContenido.value,
                "usa_balanza": this.state.checkBalanza ? 'S' : 'N',
                "create_user": parseInt(usuario.p_id, 10),
                "cantidad_ingredientes": this.state.ingredientesProductoCompuesto.length,
                "favorito": this.state.favorito ? 'S' : 'N',
                "dispensado": this.state.checkDispensado ? 'S' : 'N',
                "cantidad_maxima": this.state.cantidadMaximaNuevo != null ? this.state.cantidadMaximaNuevo.length == 0 ? 0 : parseInt(this.state.cantidadMaximaNuevo, 10) : 0,
                "cantidad_minima": this.state.cantidadMinimaNuevo != null ? this.state.cantidadMinimaNuevo.length == 0 ? 0 : parseInt(this.state.cantidadMinimaNuevo, 10) : 0,
                "tiempo_reorden": this.state.tiempoReorden != null ? this.state.tiempoReorden.length == 0 ? 0 : parseInt(this.state.tiempoReorden, 10) : 0,
                "precio_maximo": this.state.precioMaximoNuevo != null ? this.state.precioMaximoNuevo.length == 0 ? this.state.precioventa.length == 0 ? '0' : this.state.precioventa : parseFloat(this.state.precioMaximoNuevo) : 0,
                "precio_minimo": this.state.precioMinimoNuevo != null ? this.state.precioMinimoNuevo.length == 0 ? this.state.precioventa.length == 0 ? '0' : this.state.precioventa : parseFloat(this.state.precioMinimoNuevo) : 0,
                "precio_tipo_limite": this.state.tipoPrecio, "precio_flexible": this.state.precioFlexible ? 'S' : 'N',
                "desintegrable": this.state.checkDesintegrado ? 'S' : 'N'
            },
            "proveedores_productos_eliminados": this.state.proveedores_productos_eliminados,
            "categorias":
                this.state.multiCategorias.map((categoria) => {
                    return (
                        {
                            "grupo_id": parseInt(categoria.value, 10)

                        }
                    )
                }),
            "productos_impuestos":
                impuiestosTotales.map((impuesto) => {
                    //console.log("impuesto costo: ", impuesto)
                    return (
                        {
                            "impuestos_id": impuesto.impuestos_id,
                            "iva_incluido": "S",
                            "tipo": impuesto.tipo_impuesto,
                            "productos_id": id,
                            "proveedores_id": impuesto.tipo_impuesto == "C" ? impuesto.proveedor_id : null
                        }
                    )
                }),
            "ingredientes":
                this.state.ingredientesProductoCompuesto.map((ingrediente) => {
                    return (
                        {
                            "ingredientes_id": parseInt(ingrediente.id, 10),
                            "cantidad": parseFloat(ingrediente.cantidad),
                            "id": parseInt(ingrediente.id, 10),
                            "productos_id": id,
                            "estado": ingrediente.estado
                        }
                    )
                })
            ,
            "proveedores_productos":
                this.state.proveedoresGuardar.map((proveedor) => {
                    return (
                        {
                            "proveedores_id": proveedor.id,
                            "costo": 0,
                            "impuestos_id": '',
                            "iva_incluido": "S",
                            "productos_id": id
                        }
                    )
                }),
            "identificadores":
                this.state.identificadores.map((identificador) => {
                    return (
                        {
                            "identificador": identificador.codigos
                        }
                    )
                }),
            "imagen": this.state.modovista ? this.state.imagePreviewUrl != null ? this.state.imagePreviewUrl : 'N' : this.state.imagePreviewUrl != null ? this.state.imagePreviewUrl : 'N',
            "dominio": dominio,
            "tiendas": this.state.empresasGuardar.map((empresa) => {
                return (
                    {
                        "empresas_finales_id": empresa.id,
                        "precio": parseFloat(empresa.precio) < precioMinimo || parseFloat(empresa.precio) > precioMaximo ? precioVenta : empresa.precio,
                        "estado": empresa.estado,
                        "id": empresa.relacion_id,
                        "disponible": empresa.disponible,
                        "productos_id": id
                    }
                )
            })
        }
        //console.log(this.state.proveedoresGuardar)
        //console.log(datos)
        if (validado) {
            Axios.put(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto', datos, { headers: { Authorization: ntoken } })
                .then((res) => {
                    //console.log(res)
                    this.setState({
                        response: false
                    })
                    if (res.status == 201) {
                        swal({
                            title: "Producto actualizado correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_PRODUCTO",
                                        creacionProducto: false,
                                        productoEdicion: null,
                                        vistaProducto: false,
                                        vista: false
                                    })
                                    store.dispatch({
                                        type: "AGREGAR_IDENTIFICADOR",
                                        codigos: [],
                                        creacionCodigo: false
                                    })
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al actualizar", "Por favor verifique los datos", "warning");
                    }
                })
                .catch((err) => {
                    //console.log(err)
                })
        }
    }

    registrarProducto = () => {

        var validado = this.validarFormulario()
        var usuario = store.getState().usuario
        var dominio = store.getState().dominio
        var impuiestosTotales = []
        this.state.impuestosGuardar.map((impuesto) => {
            impuiestosTotales.push(impuesto)
        })
        this.state.impuestosProveedorTotal.map((impuesto) => {
            //console.log("impuesto proveedor: ", impuesto)
            impuiestosTotales.push(impuesto)
        })
        var precioMinimo = parseInt(this.state.precioMinimoNuevo)
        var precioMaximo = parseInt(this.state.precioMaximoNuevo)
        var precioVenta = parseInt(this.state.precioventa)
        var tipo = this.state.boolTipo
        if (tipo) {
            precioMinimo = precioMinimo
            precioMaximo = precioMaximo
        } else {
            precioMinimo = precioVenta - ((precioVenta * precioMinimo) / 100)
            precioMaximo = precioVenta + ((precioVenta * precioMaximo) / 100)
        }
        var datos = {
            "producto": {
                "empresas_id": parseInt(usuario.empresas_id, 10),
                "plu": this.state.codigoInternoNuevo,
                "descripcion": this.state.nombreProductoNuevo,
                "precio": this.state.precioventa != null ? this.state.precioventa == '' ? '0' : this.state.precioventa : 0,
                "puede_vender": this.state.checkSeVende ? 'S' : 'N',
                "puede_comprar": this.state.checkSeCompra ? 'S' : 'N',
                "ingrediente": this.state.checkIngrediente ? 'S' : 'N',
                "tipo": this.state.cambio ? parseInt(this.state.tipoProducto.value, 10) : 1,
                "url_foto": this.state.imagePreviewUrl != null ? "S" : "N",
                "unidades_medida": this.state.unidadesSeleccionada.value,
                "unidades_contenido": this.state.tipoProducto.value == '3' || this.state.tipoProducto.value == '2' ? this.state.unidadesSeleccionada.value : this.state.unidadContenido.value,
                "usa_balanza": this.state.checkBalanza ? 'S' : 'N',
                "create_user": parseInt(usuario.p_id, 10),
                "cantidad_ingredientes": this.state.ingredientesProductoCompuesto.length,
                "favorito": this.state.favorito ? 'S' : 'N',
                "dispensado": this.state.checkDispensado ? 'S' : 'N',
                "cantidad_maxima": this.state.cantidadMaximaNuevo.length == 0 ? 0 : parseInt(this.state.cantidadMaximaNuevo, 10),
                "cantidad_minima": this.state.cantidadMinimaNuevo.length == 0 ? 0 : parseInt(this.state.cantidadMinimaNuevo, 10),
                "tiempo_reorden": this.state.tiempoReorden.length == 0 ? 0 : parseInt(this.state.tiempoReorden, 10),
                "precio_maximo": this.state.precioMaximoNuevo.length == 0 ? this.state.precioventa.length == 0 ? '0' : this.state.precioventa : parseInt(this.state.precioMaximoNuevo, 10),
                "precio_minimo": this.state.precioMinimoNuevo.length == 0 ? this.state.precioventa.length == 0 ? '0' : this.state.precioventa : parseInt(this.state.precioMinimoNuevo, 10),
                "precio_tipo_limite": this.state.tipoPrecio,
                "precio_flexible": this.state.precioFlexible ? 'S' : 'N',
                "desintegrable": this.state.checkDesintegrado ? 'S' : 'N'
            },
            "categorias":
                this.state.categoriasSeleccionadas.map((categoria) => {
                    return (
                        {
                            "grupo_id": parseInt(categoria, 10)

                        }
                    )
                }),
            "productos_impuestos":
                impuiestosTotales.map((impuesto) => {
                    return (
                        {
                            "impuestos_id": impuesto.impuestos_id,
                            "iva_incluido": "S",
                            "tipo": impuesto.tipo_impuesto,
                            "proveedores_id": impuesto.tipo_impuesto == "C" ? impuesto.proveedor_id : null

                        }
                    )
                }),
            "ingredientes":
                this.state.ingredientesProductoCompuesto.map((ingrediente) => {
                    return (
                        {
                            "ingredientes_id": parseInt(ingrediente.id, 10),
                            "cantidad": parseFloat(ingrediente.cantidad)

                        }
                    )
                })
            ,
            "proveedores_productos":
                this.state.proveedoresGuardar.map((proveedor) => {
                    return (
                        {
                            "proveedores_id": proveedor.id,
                            "costo": 0,
                            "impuestos_id": '',
                            "iva_incluido": "N"

                        }
                    )
                }),
            "identificadores":
                this.state.identificadores.map((identificador) => {
                    return (
                        {
                            "identificador": identificador.codigos
                        }
                    )
                }),
            "imagen": this.state.imagePreviewUrl != null ? this.state.imagePreviewUrl : 'N',
            "dominio": dominio,
            "tiendas": this.state.empresasGuardar.map((empresa) => {
                return (
                    {
                        "empresas_finales_id": empresa.id,
                        "precio": parseInt(empresa.precio) < precioMinimo || parseInt(empresa.precio) > precioMaximo ? precioVenta : empresa.precio,
                    }
                )
            })
        }
        //console.log(datos)
        if (validado) {
            Axios.post(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto', datos, { headers: { Authorization: ntoken } })
                .then((res) => {
                    //console.log(res)
                    this.setState({
                        response: false
                    })
                    if (res.status == 201) {
                        swal({
                            title: "Producto creado correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_PRODUCTO",
                                        creacionProducto: false,
                                        productoEdicion: null,
                                        vistaProducto: false,
                                        vista: false
                                    })
                                    store.dispatch({
                                        type: "AGREGAR_IDENTIFICADOR",
                                        codigos: [],
                                        creacionCodigo: false
                                    })
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                    }
                })
                .catch((err) => {
                    //console.log(err)
                })
        }

    }

    disponibilidadEmpresa = (key) => {
        var act = this.state.tiendasbd
        if (act[key].disponible == 'N') {
            act[key].disponible = 'S'
        } else {
            act[key].disponible = 'N'
        }

        this.setState({ tiendasbd: act, cambioEmpresa: true })
        //console.log(this.state.tiendasbd)
    }
    cerrarAlerta = () => {
        this.setState({ alertaEmpresas: false })
    }

    guardarProductosEmpresas = () => {
        var usuario = store.getState().usuario
        var datos = []
        this.state.tiendasbd.map((tienda, key) => {
            datos.push({
                "disponible": tienda.disponible,
                "id": tienda.id,
                "update_user": usuario.empresas_id
            })
        })
        Axios.put(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/convenios/update_disponible', datos, { headers: { Authorization: ntoken } })
            .then(response => {
                //console.log(response)
                if (response.status === 201) {
                    this.setState({ alertaEmpresas: true, cambioEmpresa: false })
                }
            })
    }

    onCropChange = crop => {
        this.setState({ crop })
    }


    handleSelect = name => value => {
        this.setState({
            [name]: value,
        });
        switch (name) {

            case 'provedores':
                this.setState({ idprovedor: value.value, provedores: value.label })
                break
        }
    }
    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels })
    }


    showCroppedImage = async () => {
        const croppedImage = await getCroppedImg(
            this.state.imagePreviewUrl,
            this.state.croppedAreaPixels
        )
        this.setState({ croppedImage, dialogphoto: !this.state.dialogphoto })
    }

    _handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        }
        reader.readAsDataURL(file)
        this.setState({ dialogphoto: !this.state.dialogphoto, cambioFoto: true });
    }


    onZoomChange = zoom => {
        this.setState({ zoom })
    }

    fileSelected = event => {
        let reader = new FileReader();
        let file = event.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        }
        reader.readAsDataURL(file)
        //console.log(this.state.dialogphoto)
        this.setState({ dialogphoto: !this.state.dialogphoto });

    }

    eliminarIngrediente = (e, id, cantidad) => {
        //this.restarPum(id, cantidad)
        //console.log(e)
        var mingrediente = this.state.ingredientesProductoCompuesto
        if (mingrediente[e].nuevo == "S") {
            mingrediente.splice(e, 1)
        } else {
            mingrediente[e].estado = "I"
        }
        this.agregarObjetosSeleccionados(this.state.auxiliarIngredientesSeleccionadas, 'auxiliarIngredientesSeleccionadas', this.state.ingredientesbd, 'ingredientesbd', e)
        this.setState({ ingredientesProductoCompuesto: mingrediente })
    }


    eliminaPrecio = (e) => {
        var mproductos = this.state.listaPrecios
        mproductos.splice(e, 1)
        this.setState({ listaPrecios: mproductos })
    }

    eliminarProveedor = (e) => {
        var mproveedor = this.state.proveedoresGuardar
        var mtotalImpuestos = this.state.impuestosProveedorTotal
        if (mproveedor[e].impuestos != null) {
            mproveedor[e].impuestos.map((impuesto) => {
                this.state.impuestosProveedorTotal.map((imppoveedor, key) => {
                    if (impuesto.proveedor_id === imppoveedor.proveedor_id) {
                        mtotalImpuestos.splice(key, 1)
                    }
                })
            })
        }
        this.agregarObjetosSeleccionados(this.state.auxiliarProveedoresSeleecionados, 'auxiliarProveedoresSeleecionados', this.state.proveedoresbd, 'proveedoresbd', e)
        mproveedor.splice(e, 1)
        this.setState({ proveedoresGuardar: mproveedor, impuestosProveedorTotal: mtotalImpuestos })
    }

    eliminarTienda = (e) => {
        var mtienda = this.state.empresasGuardar
        mtienda.splice(e, 1)
        this.agregarObjetosSeleccionados(this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd, 'empresasbd', e)
        this.setState({ empresasGuardar: mtienda })
    }

    eliminarTiendabd = (e) => {
        var mtienda = this.state.empresasGuardar

        let rtienda = this.state.auxiliarTiendasSeleccionadas;
        let posicionReal = e;

        for (let r = 0; r < rtienda.length; r++) {
            let vt = rtienda[r];
            if (parseInt(vt.value) === parseInt(mtienda[e].id)) {
                posicionReal = r;
                break;
            }

        }
        if (mtienda[e].relacion_id === 0) {
            this.agregarObjetosSeleccionados(this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd, 'empresasbd', posicionReal)
            mtienda.splice(e, 1)
        } else {
            mtienda[e].estado = "I"
            this.agregarObjetosSeleccionados(this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd, 'empresasbd', posicionReal)
        }
        this.setState({ empresasGuardar: mtienda })
    }

    agregarProvedor = () => {
        var proveedores = this.state.proveedoresGuardar
        var totales = this.state.impuestosProveedorTotal
        this.state.impuestosProveedorGuardar.map((impuesto) => {
            //console.log(impuesto)
            totales.push(impuesto)
        })
        proveedores.push({
            id: this.state.proveedorSeleccionado.value,
            proveedor: this.state.proveedorSeleccionado.label,
            impuestos: this.state.impuestosProveedorGuardar
        })
        this.agregarObjetosSeleccionados(this.state.proveedoresbd, 'proveedoresbd', this.state.auxiliarProveedoresSeleecionados, 'auxiliarProveedoresSeleecionados', this.state.proveedoresbd.indexOf(this.state.proveedorSeleccionado))
        this.setState({ proveedoresGuardar: proveedores, impuestosProveedorTotal: totales, proveedorSeleccionado: {}, precioProvedor: '', multiImpuestosProveedor: [], impuestosProveedorGuardar: [] })

    }

    agregarTienda = () => {
        var precioMinimo = 0
        var precioMaximo = 0
        var precioVenta = 0
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case 'COP':
                precioMinimo = parseInt(this.state.precioMinimoNuevo)
                precioMaximo = parseInt(this.state.precioMaximoNuevo)
                precioVenta = parseInt(this.state.precioventa)
                break;
            case 'USD':
                precioMinimo = parseFloat(this.state.precioMinimoNuevo)
                precioMaximo = parseFloat(this.state.precioMaximoNuevo)
                precioVenta = parseFloat(this.state.precioventa)
                break;
        }
        var tipo = this.state.boolTipo
        if (tipo) {
            precioMinimo = precioMinimo
            precioMaximo = precioMaximo
        } else {
            precioMinimo = precioVenta - ((precioVenta * precioMinimo) / 100)
            precioMaximo = precioVenta + ((precioVenta * precioMaximo) / 100)
        }
        if (this.state.checkSeVende) {
            switch (moneda) {
                case 'COP':
                    if (this.state.precioporEmpresa.trim == '' || parseInt(this.state.precioporEmpresa) == 0) {
                        swal("ERROR", "DEBE INGRESAR EL VALOR Y SER MAYOR A CERO", "warning")
                    } else if (parseInt(this.state.precioporEmpresa) < 0) {
                        swal("ERROR", "EL VALOR DEBE SER POSITIVO", "warning")
                    } else if (!/^([0-9])*$/.test(this.state.precioporEmpresa)) {
                        swal("ERROR", "EL VALOR DEBE SER UN NUMERO ENTERO", "warning")
                    } else if (parseInt(this.state.precioporEmpresa) < precioMinimo) {
                        swal("ERROR", "EL VALOR DEBE SER MAYOR O IGUAL AL PRECIO MINIMO CONFIGURADO", "warning")
                    } else if (parseInt(this.state.precioporEmpresa) > precioMaximo) {
                        swal("ERROR", "EL VALOR DEBE SER MENOR O IGUAL AL PRECIO MAXIMO CONFIGURADO", "warning")
                    } else {
                        var tiendas = this.state.empresasGuardar
                        tiendas.push({
                            id: this.state.empresaSeleccionda.value,
                            empresa: this.state.empresaSeleccionda.label,
                            precio: this.state.precioporEmpresa == '' || this.state.precioporEmpresa == 0 ? 0 : this.state.precioporEmpresa
                        })
                        this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd.indexOf(this.state.empresaSeleccionda))
                        this.setState({ empresasGuardar: tiendas, empresaSeleccionda: {}, precioporEmpresa: '' })
                    }
                    break;
                case 'USD':
                    if (this.state.precioporEmpresa.trim == '' || parseFloat(this.state.precioporEmpresa) == 0) {
                        swal("ERROR", "DEBE INGRESAR EL VALOR Y SER MAYOR A CERO", "warning")
                    } else if (this.state.precioporEmpresa < 0) {
                        swal("ERROR", "EL VALOR DEBE SER POSITIVO", "warning")
                    } else if (this.state.precioporEmpresa < precioMinimo) {
                        swal("ERROR", "EL VALOR DEBE SER MAYOR O IGUAL AL PRECIO MINIMO CONFIGURADO", "warning")
                    } else if (this.state.precioporEmpresa > precioMaximo) {
                        swal("ERROR", "EL VALOR DEBE SER MENOR O IGUAL AL PRECIO MAXIMO CONFIGURADO", "warning")
                    } else {
                        var tiendas = this.state.empresasGuardar
                        tiendas.push({
                            id: this.state.empresaSeleccionda.value,
                            empresa: this.state.empresaSeleccionda.label,
                            precio: this.state.precioporEmpresa == '' || this.state.precioporEmpresa == 0 ? 0 : this.state.precioporEmpresa
                        })
                        this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd.indexOf(this.state.empresaSeleccionda))
                        this.setState({ empresasGuardar: tiendas, empresaSeleccionda: {}, precioporEmpresa: '' })
                    }
                    break;
            }

        } else {
            var tiendas = this.state.empresasGuardar
            tiendas.push({
                id: this.state.empresaSeleccionda.value,
                empresa: this.state.empresaSeleccionda.label,
                precio: 0
            })
            this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd.indexOf(this.state.empresaSeleccionda))
            this.setState({ empresasGuardar: tiendas, empresaSeleccionda: {}, precioporEmpresa: '' })
        }


    }

    //Aqruegar tienda todas
    agregarTiendaTodas = () => {
        var precioMinimo = 0
        var precioMaximo = 0
        var precioVenta = 0
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case 'COP':
                precioMinimo = parseInt(this.state.precioMinimoNuevo)
                precioMaximo = parseInt(this.state.precioMaximoNuevo)
                precioVenta = parseInt(this.state.precioventa)
                break;
            case 'USD':
                precioMinimo = parseFloat(this.state.precioMinimoNuevo)
                precioMaximo = parseFloat(this.state.precioMaximoNuevo)
                precioVenta = parseFloat(this.state.precioventa)
                break;
        }
        var tipo = this.state.boolTipo
        if (tipo) {
            precioMinimo = precioMinimo
            precioMaximo = precioMaximo
        } else {
            precioMinimo = precioVenta - ((precioVenta * precioMinimo) / 100)
            precioMaximo = precioVenta + ((precioVenta * precioMaximo) / 100)
        }
        if (this.state.checkSeVende) {
            var tiendas = this.state.empresasGuardar
            var cont = 0
            this.state.empresasbd.map((tienda, k) => {
                tiendas.push({
                    id: tienda.value,
                    empresa: tienda.label,
                    precio: this.state.precioventa == '' || this.state.precioventa == 0 ? 0 : this.state.precioventa
                })
                // this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', cont)
                cont++
            })
            this.setState({
                empresasGuardar: tiendas,
                auxiliarTiendasSeleccionadas: this.state.empresasbd,
                empresasbd: []
            })
        } else {
            var tiendas = this.state.empresasGuardar
            var cont = 0
            this.state.empresasbd.map((tienda, k) => {
                tiendas.push({
                    id: tienda.value,
                    empresa: tienda.label,
                    precio: 0
                })
                cont++
                //this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', cont)

            })
            this.setState({
                empresasGuardar: tiendas,
                auxiliarTiendasSeleccionadas: this.state.empresasbd,
                empresasbd: []
            })

        }
    }
    //Aqruegar tienda todas x sucursal
    agregarSucursalbd = () => {
        console.log(this.state.vectorAuxiliar2)
        var precioMinimo = 0
        var precioMaximo = 0
        var precioVenta = 0
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        var auxiliar1 = this.state.auxiliarTiendasSeleccionadas
        switch (moneda) {
            case 'COP':
                precioMinimo = parseInt(this.state.precioMinimoNuevo)
                precioMaximo = parseInt(this.state.precioMaximoNuevo)
                precioVenta = parseInt(this.state.precioventa)
                break;
            case 'USD':
                precioMinimo = parseFloat(this.state.precioMinimoNuevo)
                precioMaximo = parseFloat(this.state.precioMaximoNuevo)
                precioVenta = parseFloat(this.state.precioventa)
                break;
        }
        var tipo = this.state.boolTipo
        if (tipo) {
            precioMinimo = precioMinimo
            precioMaximo = precioMaximo
        } else {
            precioMinimo = precioVenta - ((precioVenta * precioMinimo) / 100)
            precioMaximo = precioVenta + ((precioVenta * precioMaximo) / 100)
        }
        if (this.state.checkSeVende) {
            switch (moneda) {
                case 'COP':
                    if (this.state.precioporEmpresa.trim == '' || parseInt(this.state.precioporEmpresa) == 0) {
                        swal("ERROR", "DEBE INGRESAR EL VALOR Y SER MAYOR A CERO", "warning")
                    } else if (parseInt(this.state.precioporEmpresa) < 0) {
                        swal("ERROR", "EL VALOR DEBE SER POSITIVO", "warning")
                    } else if (!/^([0-9])*$/.test(this.state.precioporEmpresa)) {
                        swal("ERROR", "EL VALOR DEBE SER UN NUMERO ENTERO", "warning")
                    } else if (parseInt(this.state.precioporEmpresa) < precioMinimo) {
                        swal("ERROR", "EL VALOR DEBE SER MAYOR O IGUAL AL PRECIO MINIMO CONFIGURADO", "warning")
                    } else if (parseInt(this.state.precioporEmpresa) > precioMaximo) {
                        swal("ERROR", "EL VALOR DEBE SER MENOR O IGUAL AL PRECIO MAXIMO CONFIGURADO", "warning")
                    } else {
                        var tiendas = this.state.empresasGuardar
                        var asacar = this.state.empresasbd
                        var cont = 0
                        this.state.empresasxSucursalSeleccionada.map((tienda, k) => {

                            tiendas.push({
                                id: tienda.value,
                                empresa: tienda.label,
                                precio: this.state.precioporEmpresa == '' || this.state.precioporEmpresa == 0 ? precioVenta : this.state.precioporEmpresa
                            })
                            auxiliar1.push(asacar[this.state.vectorAuxiliar2[k]])
                            asacar.splice(this.state.vectorAuxiliar2[k], 1)

                            // this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', cont)
                            cont++
                        })
                        this.setState({
                            empresasGuardar: tiendas,
                            auxiliarTiendasSeleccionadas: auxiliar1,
                            empresasbd: asacar,
                            empresasxSucursalSeleccionada: [],
                            sucursalSeleccionada: {},
                            xSucursal: false
                        })
                    }
                    break;
                case 'USD':
                    if (this.state.precioporEmpresa.trim == '' || parseFloat(this.state.precioporEmpresa) == 0) {
                        swal("ERROR", "DEBE INGRESAR EL VALOR Y SER MAYOR A CERO", "warning")
                    } else if (this.state.precioporEmpresa < 0) {
                        swal("ERROR", "EL VALOR DEBE SER POSITIVO", "warning")
                    } else if (this.state.precioporEmpresa < precioMinimo) {
                        swal("ERROR", "EL VALOR DEBE SER MAYOR O IGUAL AL PRECIO MINIMO CONFIGURADO", "warning")
                    } else if (this.state.precioporEmpresa > precioMaximo) {
                        swal("ERROR", "EL VALOR DEBE SER MENOR O IGUAL AL PRECIO MAXIMO CONFIGURADO", "warning")
                    } else {
                        var tiendas = this.state.empresasGuardar
                        var asacar = this.state.empresasbd
                        var cont = 0
                        this.state.empresasxSucursalSeleccionada.map((tienda, k) => {

                            tiendas.push({
                                id: tienda.value,
                                empresa: tienda.label,
                                precio: this.state.precioporEmpresa == '' || this.state.precioporEmpresa == 0 ? 0 : this.state.precioporEmpresa
                            })
                            auxiliar1.push(asacar[this.state.vectorAuxiliar2[k]])
                            asacar.splice(this.state.vectorAuxiliar2[k], 1)

                            // this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', cont)
                            cont++
                        })
                        this.setState({
                            empresasGuardar: tiendas,
                            auxiliarTiendasSeleccionadas: auxiliar1,
                            empresasbd: asacar,
                            empresasxSucursalSeleccionada: [],
                            sucursalSeleccionada: {},
                            xSucursal: false
                        })
                    }
                    break;
            }
        } else {
            var tiendas = this.state.empresasGuardar
            var cont = 0
            var asacar = this.state.empresasbd
            this.state.empresasxSucursalSeleccionada.map((tienda, k) => {


                tiendas.push({
                    id: tienda.value,
                    empresa: tienda.label,
                    precio: this.state.precioventa == '' || this.state.precioventa == 0 ? 0 : this.state.precioventa
                })
                auxiliar1.push(asacar[this.state.vectorAuxiliar2[k]])
                asacar.splice(this.state.vectorAuxiliar2[k], 1)
                cont++
                //this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', cont)

            })
            this.setState({
                empresasGuardar: tiendas,
                auxiliarTiendasSeleccionadas: auxiliar1,
                empresasbd: asacar,
                empresasxSucursalSeleccionada: [],
                sucursalSeleccionada: {},
                xSucursal: false
            })

        }
    }


    //Aqruegar tienda todas
    agregarTiendaTodasBD = () => {
        var precioMinimo = 0
        var precioMaximo = 0
        var precioVenta = 0
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case 'COP':
                precioMinimo = parseInt(this.state.precioMinimoNuevo)
                precioMaximo = parseInt(this.state.precioMaximoNuevo)
                precioVenta = parseInt(this.state.precioventa)
                break;
            case 'USD':
                precioMinimo = parseFloat(this.state.precioMinimoNuevo)
                precioMaximo = parseFloat(this.state.precioMaximoNuevo)
                precioVenta = parseFloat(this.state.precioventa)
                break;
        }
        var auxiliar = this.state.auxiliarTiendasSeleccionadas
        var idsTeindas = []
        auxiliar.map((tienda) => {
            idsTeindas.push(tienda.value)
        })
        var tipo = this.state.boolTipo
        if (tipo) {
            precioMinimo = precioMinimo
            precioMaximo = precioMaximo
        } else {
            precioMinimo = precioVenta - ((precioVenta * precioMinimo) / 100)
            precioMaximo = precioVenta + ((precioVenta * precioMaximo) / 100)
        }
        if (this.state.checkSeVende) {
            var tiendas = this.state.empresasGuardar
            var cont = 0
            this.state.empresasbd.map((tienda, k) => {
                tiendas.push({
                    id: tienda.value,
                    empresa: tienda.label,
                    precio: this.state.precioporEmpresa == '' || this.state.precioporEmpresa == 0 ? 0 : this.state.precioporEmpresa,
                    relacion_id: 0,
                    estado: "A",
                    disponible: "S"
                })
                // this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', cont)
                cont++
            })

            this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd.indexOf(this.state.empresaSeleccionda))
            this.setState({ empresasGuardar: tiendas, empresaSeleccionda: {}, precioporEmpresa: '' })
        } else {
            var tiendas = this.state.empresasGuardar
            var cont = 0
            this.state.empresasbd.map((tienda, k) => {
                tiendas.push({
                    id: tienda.value,
                    empresa: tienda.label,
                    precio: this.state.precioporEmpresa == '' || this.state.precioporEmpresa == 0 ? 0 : this.state.precioporEmpresa,
                    relacion_id: 0,
                    estado: "A",
                    disponible: "S"
                })
                // this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', cont)
                cont++
            })
            this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd.indexOf(this.state.empresaSeleccionda))
            this.setState({ empresasGuardar: tiendas, empresaSeleccionda: {}, precioporEmpresa: '' })
        }
    }


    agregarTiendabd = () => {
        //precioMinimoNuevo
        //precioMinimoNuevo
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        var precioMinimo = 0
        var precioMaximo = 0
        var precioVenta = 0
        switch (moneda) {
            case 'COP':
                precioMinimo = parseInt(this.state.precioMinimoNuevo)
                precioMaximo = parseInt(this.state.precioMaximoNuevo)
                precioVenta = parseInt(this.state.precioventa)
                break;
            case 'USD':
                precioMinimo = parseFloat(this.state.precioMinimoNuevo)
                precioMaximo = parseFloat(this.state.precioMaximoNuevo)
                precioVenta = parseFloat(this.state.precioventa)
                break;
        }
        var auxiliar = this.state.auxiliarTiendasSeleccionadas
        var idsTeindas = []
        auxiliar.map((tienda) => {
            idsTeindas.push(tienda.value)
        })
        var tipo = this.state.boolTipo
        if (tipo) {
            precioMinimo = precioMinimo
            precioMaximo = precioMaximo
        } else {
            precioMinimo = precioVenta - ((precioVenta * precioMinimo) / 100)
            precioMaximo = precioVenta + ((precioVenta * precioMaximo) / 100)
        }
        if (this.state.checkSeVende) {
            switch (moneda) {
                case 'COP':
                    if (this.state.precioporEmpresa.trim == '' || parseInt(this.state.precioporEmpresa) == 0) {
                        swal("ERROR", "DEBE INGRESAR EL VALOR Y SER MAYOR A CERO", "warning")
                    } else if (parseInt(this.state.precioporEmpresa) < 0) {
                        swal("ERROR", "EL VALOR DEBE SER POSITIVO", "warning")
                    } else if (!/^([0-9])*$/.test(this.state.precioporEmpresa)) {
                        swal("ERROR", "EL VALOR DEBE SER UN NUMERO ENTERO", "warning")
                    } else if (parseInt(this.state.precioporEmpresa) < precioMinimo) {
                        swal("ERROR", "EL VALOR DEBE SER MAYOR O IGUAL AL PRECIO MINIMO CONFIGURADO", "warning")
                    } else if (parseInt(this.state.precioporEmpresa) > precioMaximo) {
                        swal("ERROR", "EL VALOR DEBE SER MENOR O IGUAL AL PRECIO MAXIMO CONFIGURADO", "warning")
                    } else if (idsTeindas.includes(this.state.empresaSeleccionda.value)) {
                        swal("ERROR", "YA LA TIENDA ESTA ASIGANADA", "warning")
                    } else {
                        var tiendas = this.state.empresasGuardar
                        tiendas.push({
                            id: this.state.empresaSeleccionda.value,
                            empresa: this.state.empresaSeleccionda.label,
                            precio: this.state.precioporEmpresa == '' || this.state.precioporEmpresa == 0 ? 0 : this.state.precioporEmpresa,
                            relacion_id: 0,
                            estado: "A",
                            disponible: "S"
                        })
                        this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd.indexOf(this.state.empresaSeleccionda))
                        this.setState({ empresasGuardar: tiendas, empresaSeleccionda: {}, precioporEmpresa: '' })
                    }
                    break;
                case 'USD':
                    if (this.state.precioporEmpresa.trim == '' || parseFloat(this.state.precioporEmpresa) == 0) {
                        swal("ERROR", "DEBE INGRESAR EL VALOR Y SER MAYOR A CERO", "warning")
                    } else if (this.state.precioporEmpresa < 0) {
                        swal("ERROR", "EL VALOR DEBE SER POSITIVO", "warning")
                    } else if (this.state.precioporEmpresa < precioMinimo) {
                        swal("ERROR", "EL VALOR DEBE SER MAYOR O IGUAL AL PRECIO MINIMO CONFIGURADO", "warning")
                    } else if (this.state.precioporEmpresa > precioMaximo) {
                        swal("ERROR", "EL VALOR DEBE SER MENOR O IGUAL AL PRECIO MAXIMO CONFIGURADO", "warning")
                    } else if (idsTeindas.includes(this.state.empresaSeleccionda.value)) {
                        swal("ERROR", "YA LA TIENDA ESTA ASIGANADA", "warning")
                    } else {
                        var tiendas = this.state.empresasGuardar
                        tiendas.push({
                            id: this.state.empresaSeleccionda.value,
                            empresa: this.state.empresaSeleccionda.label,
                            precio: this.state.precioporEmpresa == '' || this.state.precioporEmpresa == 0 ? 0 : this.state.precioporEmpresa,
                            relacion_id: 0,
                            estado: "A",
                            disponible: "S"
                        })
                        this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd.indexOf(this.state.empresaSeleccionda))
                        this.setState({ empresasGuardar: tiendas, empresaSeleccionda: {}, precioporEmpresa: '' })
                    }
                    break;
            }

        } else {
            var tiendas = this.state.empresasGuardar
            tiendas.push({
                id: this.state.empresaSeleccionda.value,
                empresa: this.state.empresaSeleccionda.label,
                precio: 0,
                relacion_id: 0,
                estado: "A",
                disponible: "S"
            })
            this.agregarObjetosSeleccionados(this.state.empresasbd, 'empresasbd', this.state.auxiliarTiendasSeleccionadas, 'auxiliarTiendasSeleccionadas', this.state.empresasbd.indexOf(this.state.empresaSeleccionda))
            this.setState({ empresasGuardar: tiendas, empresaSeleccionda: {}, precioporEmpresa: '' })
        }
    }

    fotoChange = (e) => {
        let file = e.target.files;
        ////console.log(file)
        this.setState({ imagenCreate: file })
    }


    handleDelete = data => () => {
        this.setState(state => {
            const chipData = [...state.chipData];
            const chipToDelete = chipData.indexOf(data);
            chipData.splice(chipToDelete, 1);
            return { chipData };
        });
    };

    handledialogIdentificador = () => {
        //console.log("Yeahhh")
        store.dispatch({
            type: "AGREGAR_IDENTIFICADOR",
            codigos: this.state.identificadores,
            creacionCodigo: !this.state.dialogIDentificafor

        })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
        switch (name) {
            case 'codigoInternoNuevo':
                if (store.getState().auxiliar1.includes(event.target.value)) {
                    this.setState({ codigoValidado: false })
                } else {
                    this.setState({ codigoValidado: true })

                }
                break;
        }
    };

    autoguardado = () => {
        var datos = {
            'nombreProductoNuevo': this.state.nombreProductoNuevo,
            'codigoInternoNuevo': this.state.codigoInternoNuevo,
            'multiCodigoBarra': this.state.multiCodigoBarra,
            'checkCompuesto': this.state.checkCompuesto,
            'checkIngrediente': this.state.checkIngrediente,
            'checkProducido': this.state.checkProducido,
            'checkSeCompra': this.state.checkSeCompra,
            'checkSeVende': this.state.checkSeVende,

        }
        const params = {
            'idusuario': "1",
            'tipo': 1,
            'data': [datos, "/admin/productos"]
        };
        Axios.post('http://localhost:3001/autoguardado/', params)
            .then(response => {
                //console.log(response)
            })
    }


    ivaincluido = (id, id2) => event => {
        var estado = (event.target.value == 'false' ? false : true)
        var impuestos = this.state.impuestosGuardar
        var impuestos2 = []
        impuestos.map((impuesto, keys) => {
            if (impuesto.impuestos_id == id) {
                impuestos.push({
                    "impuestos_id": id,
                    "iva_incluido": estado ? 'S' : 'N',
                    "id": impuesto.id == undefined ? '' : impuesto.id,
                    "check": estado
                })
                impuestos.splice(keys, 1)
            }
        })
        this.setState({ impuestosGuardar: impuestos })

    }

    handleChangeCheck = (name, llave) => event => {
        var estado = (event.target.value == 'false' ? false : true)
        this.setState({
            [name]: !estado
        });
        switch (name) {
            case 'checkCompuesto':
                if (!this.state.checkCompuesto) {
                    this.setState({ checkProducidoDisabled: true, checkProducido: false, checkIngrediente: false, checkIngredienteDisabled: true, panelIngredientes: true })
                } else {
                    this.setState({ checkProducidoDisabled: false, panelIngredientes: false, checkIngredienteDisabled: false })
                }
                break
            case 'checkIngrediente':
                if (!this.state.checkIngrediente) {
                    this.setState({ checkCompuestoDisabled: true })
                } else {
                    this.setState({ checkCompuestoDisabled: false })
                }
                break
            case 'checkProducido':
                if (!this.state.checkProducido) {
                    this.setState({ checkCompuestoDisabled: true, })
                } else {
                    this.setState({ checkCompuestoDisabled: false, })
                }
                break
            case 'checkSeCompra':
                if (!this.state.checkSeCompra) {
                    this.setState({ panelProveedor: true })
                } else {
                    this.setState({ panelProveedor: false })
                }
                break
            case 'checkSeVende':
                if (!this.state.checkSeVende) {
                    this.setState({ panelListaPrecio: true, impuestosGuardar: [], multiImpuestos: [] })
                    if (Detalles) {
                        this.setState({ impuestosGuardar: [], multiImpuestos: [] })
                    }
                } else {
                    this.setState({ panelListaPrecio: false, impuestosGuardar: [], multiImpuestos: [] })
                    if (Detalles) {
                        this.setState({ impuestosGuardar: [], multiImpuestos: [] })
                    }
                }
                break
            case 'checkDisponibleEmpresa':
                var mempresas = this.state.empresasGuardar
                if (mempresas[llave].disponible === "S") {
                    mempresas[llave].disponible = "N"
                } else {
                    mempresas[llave].disponible = "S"
                }
            case 'precioFlexible':
                //console.log(moneda)
                if (!estado) {
                    this.setState({
                        precioMinimoNuevo: moneda === "USD" ? (parseFloat(this.state.precioventa) - 0.1) : (parseInt(this.state.precioventa) - 100),
                        precioMaximoNuevo: moneda === "USD" ? (parseFloat(this.state.precioventa) + 0.1) : (parseInt(this.state.precioventa) + 100),
                        boolTipo: true,
                        tipoPrecio: '$'
                    })
                } else {
                    this.setState({
                        precioMinimoNuevo: '',
                        precioMaximoNuevo: ''
                    })

                }
                break

        }
    }


    cambiarDetalles = (tipo) => {
        this.setState({ tipoDetalles: tipo, cambio: true })
    }

    agregarObjetosSeleccionados = (vector, stateVector, vectorAuxiliar, stateVectorAuxiliar, posicion) => {
        if (!isUndefined(vector[posicion])) {
            vectorAuxiliar.push(
                vector[posicion]
            )
        }
        vector.splice(posicion, 1)
        this.setState({
            [stateVector]: vector,
            [stateVectorAuxiliar]: vectorAuxiliar
        });
    }


    Detalles = () => {
        switch (this.state.tipoDetalles) {
            case '1':
                return (
                    <div className="col col-md-12">
                        <div className="row bottom-aligned">

                            <div className="col-md-7" >
                                <label htmlFor="nombreprov" className="col-form-label">CATEGORIAS</label>
                                <Selects
                                    placeholder="Seleccione o escriba"
                                    value={this.state.multiCategorias}
                                    onChange={this.handleChanges('multiCategorias')}
                                    options={this.state.categoriasbd}
                                    isMulti
                                    isDisabled={Detalles}
                                />

                            </div>
                            <div>
                                <button disabled={Detalles} className="btn btn-primary" onClick={() => this.crearCategoria()}>+</button>
                            </div>
                            <div className="col col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">MEDIDA *</label>
                                <Tooltip title="SELECCIONE LA UNIDAD CON LA QUE VA A VENDER EL PRODUCTO" placement="top">
                                    <PreguntaIcon color="disabled" />
                                </Tooltip>
                                <Selects
                                    placeholder="Seleccione"
                                    value={this.state.unidadesSeleccionada}
                                    onChange={this.handleChanges('unidadesSeleccionada')}
                                    options={this.state.unidadesbd}
                                    isDisabled={Detalles}
                                    isClearable={true}
                                />
                            </div>
                        </div>
                        <div className="row">

                            <div className="col col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">EMBALAJE *</label>
                                <Tooltip title="SELECCIONE LA CANTIDAD DEL EMPAQUE DE COMPRA DEL PRODUCTO" placement="top">
                                    <PreguntaIcon color="disabled" />
                                </Tooltip>
                                <Selects
                                    placeholder="Seleccione"
                                    value={this.state.unidadContenido}
                                    onChange={this.handleChanges('unidadContenido')}
                                    options={this.state.unidadesbd}
                                    isDisabled={Detalles}
                                    isClearable={true}
                                />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">CANT. MINIMA</label>
                                <Tooltip title="CANTIDAD MINIMA DEL PRODUCTO EN INVENTARIO (SUGERIDO)" placement="top">
                                    <PreguntaIcon color="disabled" />
                                </Tooltip>
                                <input autocomplete="off" disabled={Detalles} id="numordencompra" className="form-control" type="number" min="1" value={this.state.cantidadMinimaNuevo} onChange={this.handleChange('cantidadMinimaNuevo')} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">CANT. MAXIMA</label>
                                <Tooltip title="CANTIDAD MAXIMA DEL PRODUCTO EN INVENTARIO (SUGERIDO)" placement="top">
                                    <PreguntaIcon color="disabled" />
                                </Tooltip>
                                <input autocomplete="off" disabled={Detalles} id="numordencompra" className="form-control" type="number" min="1" value={this.state.cantidadMaximaNuevo} onChange={this.handleChange('cantidadMaximaNuevo')} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">TIEMPO DE ORDEN</label>
                                <Tooltip title="NUMERO DE DIAS PARA REORDENAR EL PRODUCTO (SUGERIDO)" placement="top">
                                    <PreguntaIcon color="disabled" />
                                </Tooltip>
                                <div class="input-group" style={{ marginBottom: '0!important' }}>

                                    <input autocomplete="off" disabled={Detalles} id="numordencompra" className="form-control" type="number" min="1" value={this.state.tiempoReorden} onChange={this.handleChange('tiempoReorden')} />
                                    <div class="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                        <span class="input-group-text" id="basic-addon1">{'DIAS'}</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                )
            case '2':
                return (
                    <div className="col col-md-12">
                        <div className="row">
                            <div className="col col-md-12">
                                <div className="row bottom-aligned">
                                    <div className="col-md-7" >
                                        <label htmlFor="nombreprov" className="col-form-label">CATEGORIAS</label>
                                        <Selects
                                            placeholder="Seleccione o escriba"
                                            value={this.state.multiCategorias}
                                            onChange={this.handleChanges('multiCategorias')}
                                            options={this.state.categoriasbd}
                                            isMulti
                                            isDisabled={Detalles}
                                        />
                                    </div>
                                    <div>
                                        <button disabled={Detalles} className="btn btn-primary" onClick={() => this.crearCategoria()}>+</button>
                                    </div>
                                    <div className="col col-md-4">
                                        <label htmlFor="numordencompra" className="col-form-label">MEDIDA *</label>
                                        <Tooltip title="SELECCIONE LA UNIDAD CON LA QUE VA A VENDER EL PRODUCTO" placement="top">
                                            <PreguntaIcon color="disabled" />
                                        </Tooltip>
                                        <Selects
                                            placeholder="Seleccione"
                                            value={this.state.unidadesSeleccionada}
                                            onChange={this.handleChanges('unidadesSeleccionada')}
                                            options={this.state.unidadesbd}
                                            isDisabled={Detalles}
                                            isClearable={true}
                                        />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col col-md-12">
                                        <div className="row">
                                            <div className="col col-md-5">
                                                <label htmlFor="numordencompra" className="col-form-label">INGREDIENTES *</label>
                                                <Selects
                                                    placeholder="Seleccione o escriba"
                                                    value={this.state.ningredienteProdcuto}
                                                    onChange={this.handleChanges('ningredienteProdcuto')}
                                                    options={this.state.ingredientesbd}
                                                    isDisabled={Detalles}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="numordencompra" className="col-form-label">CANTIDAD</label>
                                                <Tooltip title="UNIDAD DE CONTENIDO DEL INGREDIENTE" placement="top">
                                                    <PreguntaIcon color="disabled" />
                                                </Tooltip>
                                                <div class="input-group" style={{ marginBottom: '0!important' }}>

                                                    <input autocomplete="off" id="numordencompra" disabled={Detalles || JSON.stringify(this.state.ningredienteProdcuto) == "{}"} className="form-control" type="number" min="1" autoComplete="off" value={this.state.ningredienteCantidad} onChange={this.handleChange('ningredienteCantidad')} />
                                                    <div class="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                                        <span class="input-group-text" id="basic-addon1">{this.state.ningredienteProdcuto.ucontenido}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*
                                            <div className="col-md-4" style={{ marginTop: "0" }}>
                                                <FormControlLabel control={<Checkbox style={{ padding: "0" }} onChange={this.handleChanges('ivaProveedorn')} />} label="Iva incluido?" />
                                            </div>
                                            */}
                                            <div className="bottom-aligned">
                                                <button disabled={Detalles || JSON.stringify(this.state.ningredienteProdcuto) == "{}"} className="btn btn-success" onClick={this.agregarIngrediente.bind(this)}>AGREGAR INGREDIENTE</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                )
            case '3':
                return (
                    <div className="row">
                        <div className="col col-md-12">
                            <div className="row bottom-aligned">

                                <div className="col-md-6" >
                                    <label htmlFor="nombreprov" className="col-form-label">CATEGORIAS</label>
                                    <Selects
                                        placeholder="Seleccione o escriba"
                                        value={this.state.multiCategorias}
                                        onChange={this.handleChanges('multiCategorias')}
                                        options={this.state.categoriasbd}
                                        isMulti
                                        isDisabled={Detalles}
                                    />

                                </div>
                                <div>
                                    <button className="btn btn-primary" disabled={Detalles} onClick={() => this.crearCategoria()}>+</button>
                                </div>

                                <div className="col col-md-4">
                                    <label htmlFor="numordencompra" className="col-form-label">MEDIDA *</label>
                                    <Tooltip title="SELECCIONE LA UNIDAD CON LA QUE VA A VENDER EL PRODUCTO" placement="top">
                                        <PreguntaIcon color="disabled" />
                                    </Tooltip>
                                    <Selects
                                        placeholder="Seleccione"
                                        value={this.state.unidadesSeleccionada}
                                        onChange={this.handleChanges('unidadesSeleccionada')}
                                        options={this.state.unidadesbd}
                                        isDisabled={Detalles}
                                        isClearable={true}
                                    />
                                </div>


                            </div>
                            <div className="row">

                                <div className="col-md-4">
                                    <label htmlFor="numordencompra" className="col-form-label">CANT. MINIMA</label>
                                    <Tooltip title="CANTIDAD MINIMA DEL PRODUCTO EN INVENTARIO (SUGERIDO)" placement="top">
                                        <PreguntaIcon color="disabled" />
                                    </Tooltip>
                                    <input autocomplete="off" id="numordencompra" disabled={Detalles} className="form-control" type="number" min="1" value={this.state.cantidadMinimaNuevo} onChange={this.handleChange('cantidadMinimaNuevo')} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="numordencompra" className="col-form-label">CANT. MAXIMA</label>
                                    <Tooltip title="CANTIDAD MAXIMA DEL PRODUCTO EN INVENTARIO (SUGERIDO)" placement="top">
                                        <PreguntaIcon color="disabled" />
                                    </Tooltip>
                                    <input autocomplete="off" id="numordencompra" disabled={Detalles} className="form-control" type="number" min="1" value={this.state.cantidadMaximaNuevo} onChange={this.handleChange('cantidadMaximaNuevo')} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="numordencompra" className="col-form-label">TIEMPO DE ORDEN</label>
                                    <Tooltip title="TIEMPO PARA VOLVER A PEDIR EL PRODUCTO (SUGERIDO)" placement="top">
                                        <PreguntaIcon color="disabled" />
                                    </Tooltip>
                                    <div class="input-group" style={{ marginBottom: '0!important' }}>

                                        <input autocomplete="off" id="numordencompra" disabled={Detalles} className="form-control" type="number" min="1" value={this.state.tiempoReorden} onChange={this.handleChange('tiempoReorden')} />
                                        <div class="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                            <span class="input-group-text" id="basic-addon1">{'DIAS'}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col col-md-12">
                                    <div className="row">
                                        <div className="col col-md-6">
                                            <label htmlFor="numordencompra" className="col-form-label">INGREDIENTE *</label>
                                            <Selects
                                                placeholder="Seleccione o escriba"
                                                value={this.state.ningredienteProdcuto}
                                                onChange={this.handleChanges('ningredienteProdcuto')}
                                                options={this.state.ingredientesbd}
                                                isDisabled={Detalles}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="numordencompra" className="col-form-label">CANTIDAD</label>
                                            <Tooltip title="UNIDAD DE CONTENIDO DEL INGREDIENTE" placement="top">
                                                <PreguntaIcon color="disabled" />
                                            </Tooltip>
                                            <div class="input-group" style={{ marginBottom: '0!important' }}>

                                                <input autocomplete="off" id="numordencompra" disabled={Detalles || JSON.stringify(this.state.ningredienteProdcuto) == "{}"} className="form-control" type="number" min="1" autoComplete="off" value={this.state.ningredienteCantidad} onChange={this.handleChange('ningredienteCantidad')} />
                                                <div class="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                                    <span class="input-group-text" id="basic-addon1">{this.state.ningredienteProdcuto.ucontenido}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bottom-aligned">
                                            <button className="btn btn-success" disabled={Detalles || JSON.stringify(this.state.ningredienteProdcuto) == "{}"} onClick={this.agregarIngrediente.bind(this)}>AGREGAR INGREDIENTE</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case '4':
                return (
                    <div className="col col-md-12">
                        <div className="row bottom-aligned">
                            <div className="col-md-10" >
                                <label htmlFor="nombreprov" className="col-form-label">Categorias</label>
                                <Selects
                                    placeholder="Seleccione o escriba"
                                    value={this.state.multiCategorias}
                                    onChange={this.handleChanges('multiCategorias')}
                                    options={this.state.categoriasbd}
                                    isMulti
                                    isDisabled={Detalles}
                                />
                            </div>
                            <div>
                                <button className="btn btn-primary" onClick={() => this.crearCategoria()}>+</button>
                            </div>

                        </div>
                    </div>
                )
            case '5':
                return (
                    <div className="col col-md-12">
                        <div className="row bottom-aligned">

                            <div className="col-md-7" >
                                <label htmlFor="nombreprov" className="col-form-label">CATEGORIAS</label>
                                <Selects
                                    isDisabled={Detalles}
                                    placeholder="Seleccione o escriba"
                                    value={this.state.multiCategorias}
                                    onChange={this.handleChanges('multiCategorias')}
                                    options={this.state.categoriasbd}
                                    isMulti
                                />
                            </div>
                            <div>
                                <button className="btn btn-primary" onClick={() => this.crearCategoria()}>+</button>
                            </div>
                            <div className="col col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">MEDIDA *</label>
                                <Tooltip title="SELECCIONE LA UNIDAD CON LA QUE VA A VENDER EL PRODUCTO" placement="top">
                                    <PreguntaIcon color="disabled" />
                                </Tooltip>
                                <Selects
                                    placeholder="Seleccione"
                                    isDisabled={Detalles}
                                    value={this.state.unidadesSeleccionada}
                                    onChange={this.handleChanges('unidadesSeleccionada')}
                                    options={this.state.unidadesbd}
                                    isClearable={true}
                                />
                            </div>
                        </div>
                        <div className="row">

                            <div className="col col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">EMBALAJE*</label>
                                <Tooltip title="SELECCIONE LA CANTIDAD DEL EMPAQUE DE COMPRA DEL PRODUCTO" placement="top">
                                    <PreguntaIcon color="disabled" />
                                </Tooltip>
                                <Selects
                                    placeholder="Seleccione"
                                    isDisabled={Detalles}
                                    value={this.state.unidadContenido}
                                    onChange={this.handleChanges('unidadContenido')}
                                    options={this.state.unidadesbd}
                                    isClearable={true}
                                />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">CANT. MINIMA</label>
                                <Tooltip title="CANTIDAD MINIMA DEL PRODUCTO EN INVENTARIO (SUGERIDO)" placement="top">
                                    <PreguntaIcon color="disabled" />
                                </Tooltip>
                                <input autocomplete="off" id="numordencompra" disabled={Detalles} className="form-control" type="number" min="1" value={this.state.cantidadMinimaNuevo} onChange={this.handleChange('cantidadMinimaNuevo')} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">CANT. MAXIMA</label>
                                <Tooltip title="CANTIDAD MAXIMA DEL PRODUCTO EN INVENTARIO (SUGERIDO)" placement="top">
                                    <PreguntaIcon color="disabled" />
                                </Tooltip>
                                <input autocomplete="off" id="numordencompra" disabled={Detalles} className="form-control" type="number" min="1" value={this.state.cantidadMaximaNuevo} onChange={this.handleChange('cantidadMaximaNuevo')} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">TIEMPO DE ORDEN</label>
                                <Tooltip title="CANTIDAD DE DIAS PARA VOLVER A PEDIR EL PRODUCTO (SUGERIDO)" placement="top">
                                    <PreguntaIcon color="disabled" />
                                </Tooltip>
                                <div class="input-group" style={{ marginBottom: '0!important' }}>
                                    <input autocomplete="off" id="numordencompra" disabled={Detalles} className="form-control" type="number" min="1" value={this.state.tiempoReorden} onChange={this.handleChange('tiempoReorden')} />
                                    <div class="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                        <span class="input-group-text" id="basic-addon1">{'DIAS'}</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                )
            default:
                return (
                    <div className="col col-md-12">
                        <div className="row bottom-aligned">

                            <div className="col-md-7" >
                                <label htmlFor="nombreprov" className="col-form-label">CATEGORIAS</label>
                                <Selects
                                    isDisabled={Detalles}
                                    placeholder="Seleccione o escriba"
                                    value={this.state.multiCategorias}
                                    onChange={this.handleChanges('multiCategorias')}
                                    options={this.state.categoriasbd}
                                    isMulti
                                />

                            </div>
                            <div>
                                <button className="btn btn-primary" onClick={() => this.crearCategoria()}>+</button>
                            </div>
                            <div className="col col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">MEDIDA *</label>
                                <Tooltip title="SELECCIONE LA UNIDAD CON LA QUE VA A VENDER EL PRODUCTO" placement="top">
                                    <PreguntaIcon color="disabled" />
                                </Tooltip>
                                <Selects
                                    placeholder="Seleccione"
                                    isDisabled={Detalles}
                                    value={this.state.unidadesSeleccionada}
                                    onChange={this.handleChanges('unidadesSeleccionada')}
                                    options={this.state.unidadesbd}
                                    isClearable={true}
                                />
                            </div>
                        </div>
                        <div className="row">

                            <div className="col col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">EMBALAJE*</label>
                                <Tooltip title="SELECCIONE LA CANTIDAD DEL EMPAQUE DE COMPRA DEL PRODUCTO" placement="top">
                                    <PreguntaIcon color="disabled" />
                                </Tooltip>
                                <Selects
                                    placeholder="Seleccione"
                                    isDisabled={Detalles}
                                    value={this.state.unidadContenido}
                                    onChange={this.handleChanges('unidadContenido')}
                                    options={this.state.unidadesbd}
                                    isClearable={true}
                                />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">CANT. MINIMA</label>
                                <Tooltip title="CANTIDAD MINIMA DEL PRODUCTO EN INVENTARIO (SUGERIDO)" placement="top">
                                    <PreguntaIcon color="disabled" />
                                </Tooltip>
                                <input autocomplete="off" id="numordencompra" disabled={Detalles} className="form-control" type="number" min="1" value={this.state.cantidadMinimaNuevo} onChange={this.handleChange('cantidadMinimaNuevo')} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">CANT. MAXIMA</label>
                                <Tooltip title="CANTIDAD MAXIMA DEL PRODUCTO EN INVENTARIO (SUGERIDO)" placement="top">
                                    <PreguntaIcon color="disabled" />
                                </Tooltip>
                                <input autocomplete="off" id="numordencompra" disabled={Detalles} className="form-control" type="number" min="1" value={this.state.cantidadMaximaNuevo} onChange={this.handleChange('cantidadMaximaNuevo')} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">TIEMPO DE ORDEN</label>
                                <Tooltip title="CANTIDAD DE DIAS PARA VOLVER A PEDIR EL PRODUCTO (SUGERIDO)" placement="top">
                                    <PreguntaIcon color="disabled" />
                                </Tooltip>
                                <div class="input-group" style={{ marginBottom: '0!important' }}>
                                    <input autocomplete="off" id="numordencompra" disabled={Detalles} className="form-control" type="number" min="1" value={this.state.tiempoReorden} onChange={this.handleChange('tiempoReorden')} />
                                    <div class="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                        <span class="input-group-text" id="basic-addon1">{'DIAS'}</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                )
        }
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        switch (name) {
            case 'multiCategorias':
                var cat = []
                value.map((categoria) => {
                    cat.push(parseInt(categoria.value, 10))
                })
                this.setState({ categoriasSeleccionadas: cat })
                break;
            case 'multiImpuestos':
                var cat = []
                value.map((categoria) => {
                    cat.push({
                        "impuestos_id": parseInt(categoria.value, 10),
                        "iva_incluido": "N",
                        "id": categoria.id == undefined ? '' : categoria.id,
                        "check": false,
                        "descripcion": categoria.descripcion,
                        "porcentaje_valor": categoria.porcentaje_valor,
                        "valor": categoria.valor,
                        "tipo_impuesto": "V"
                    })
                })
                this.setState({ impuestosGuardar: cat })
                break;
            case "tipoProducto":
                this.cambiarDetalles(value.value)
                break
            case "multiImpuestosProveedor":
                var impuestosProveedor = []
                value.map((categoria) => {
                    impuestosProveedor.push({
                        "impuestos_id": parseInt(categoria.value, 10),
                        "iva_incluido": "N",
                        "id": categoria.id == undefined ? '' : categoria.id,
                        "check": false,
                        "descripcion": categoria.descripcion,
                        "porcentaje_valor": categoria.porcentaje_valor,
                        "valor": categoria.valor,
                        "tipo_impuesto": "C",
                        "proveedor_id": this.state.proveedorSeleccionado.value
                    })
                })
                this.setState({ impuestosProveedorGuardar: impuestosProveedor })
                break;
            case "empresaSeleccionda":
                this.setState({ precioporEmpresa: this.state.precioventa })
                break;
            case "sucursalSeleccionada":
                if (value != null) {
                    var kioscos = this.state.empresasbd
                    var chips = []
                    var vectorPosiciones = []
                    kioscos.map((kiosco, e) => {
                        if (kiosco.empresas_id === value.value) {
                            chips.push(kiosco)
                            vectorPosiciones.push(e)
                        }
                    })
                    this.setState({
                        empresasxSucursalSeleccionada: chips,
                        vectorAuxiliar2: vectorPosiciones,
                        precioporEmpresa: this.state.precioventa
                    })
                }

                break;
        }
        //this.autoguardado()
    };

    cerrarVentanaCreacion() {
        store.dispatch({
            type: "CREACION_PRODUCTO",
            creacionProducto: false,
            productoEdicion: null,
            vistaProducto: false
        })
        store.dispatch({
            type: "AGREGAR_IDENTIFICADOR",
            codigos: [],
            creacionCodigo: false
        })
        this.setState({ ingredientesProductoCompuesto: [], checked: [], productoEditar: null })
        nn = []
    }

    handledialogfoto = () => {
        this.setState({ dialogphoto: !this.state.dialogphoto });
    };
    favorito = () => {
        if (!Detalles) {
            this.setState({ favorito: !this.state.favorito })
        }
    }
    borrarImagen = () => {
        this.setState({ croppedImage: null })
    }

    setearMiles = (numero) => {
        switch (moneda) {
            case "COP":
                return (
                    (new Intl.NumberFormat("de-DE").format(parseInt(numero)))
                )
                break;
            case "USD":
                return (new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(numero))
                break;
        }

    }

    eliminarSeleccionada = (id) => {
        var mempresas = this.state.checked
        var pos = mempresas.indexOf(id)
        nn.map((empres) => {
            if (empres.id == id) {
                nn.splice(nn.indexOf(empres), 1)
            }
        })
        mempresas.splice(pos, 1)
        this.setState({ checked: mempresas })
    }

    crearProvedor = () => {
        store.dispatch({
            type: "CREACION_USUARIO",
            creacionUsuario: true,
            usuarioEdicion: null,
            modovista: false
        })
    }

    crearCategoria = () => {
        store.dispatch({
            type: "CREACION_CATEGORIA",
            creacionCategoria: true,
        })
    }

    crearImpuesto = () => {
        store.dispatch({
            type: "CREACION_IMPUESTO",
            creacionImpuesto: true
        })
    }


    validarPrecios = () => {
        var usuario = store.getState().usuario
        var precioMinimo = ""
        var precioMaximo = ""
        var precioVenta = ""
        switch (moneda) {
            case "USD":
                precioMinimo = this.state.precioMinimoNuevo != "" ? parseFloat(this.state.precioMinimoNuevo) : 0
                precioMaximo = this.state.precioMaximoNuevo != "" ? parseFloat(this.state.precioMaximoNuevo) : 0
                precioVenta = parseFloat(this.state.precioventa)
                break;
            case "COP":
                precioMinimo = this.state.precioMinimoNuevo != "" ? parseInt(this.state.precioMinimoNuevo) : 0
                precioMaximo = this.state.precioMaximoNuevo != "" ? parseInt(this.state.precioMaximoNuevo) : 0
                precioVenta = parseInt(this.state.precioventa)
                break;
        }
        var empresas = this.state.empresasGuardar
        var tipo = this.state.boolTipo
        if (tipo) {
            precioMinimo = precioMinimo
            precioMaximo = precioMaximo
        } else {
            precioMinimo = precioVenta - ((precioVenta * precioMinimo) / 100)
            precioMaximo = precioVenta + ((precioVenta * precioMaximo) / 100)
        }
        if (this.state.precioFlexible) {
            if (precioVenta === 0 || this.state.precioventa === '') {
                this.setState({
                    precioMaximoNuevo: '',
                    precioMinimoNuevo: '',
                    precioFlexible: false
                })
            } else if (parseFloat(this.state.precioventa) < 0 || isNaN(parseInt(this.state.precioventa))) {
                this.setState({
                    precioMaximoNuevo: '',
                    precioMinimoNuevo: '',
                    precioFlexible: false,
                    precioventa: 0,
                })
            } else {
                if (tipo) {
                    //console.log(moneda)
                    switch (moneda) {
                        case "USD":
                            if (precioVenta < precioMinimo) {
                                this.setState({
                                    precioMinimoNuevo: precioVenta - 0.1
                                })
                                document.getElementById("precioMinimoNuevo").focus()
                            } else if (precioMinimo > precioMaximo) {
                                this.setState({
                                    precioMaximoNuevo: precioVenta + 0.1
                                })
                                document.getElementById("precioMaximoNuevo").focus()
                            } else if (precioVenta > precioMaximo) {
                                this.setState({
                                    precioMaximoNuevo: precioVenta + 0.1
                                })
                                document.getElementById("precioMaximoNuevo").focus()
                            }
                            this.state.empresasGuardar.map((tienda, key) => {
                                if (parseFloat(tienda.precio) < precioMinimo) {
                                    empresas[key].precio = precioVenta
                                } else if (parseFloat(tienda.precio) > precioMaximo) {
                                    empresas[key].precio = precioVenta
                                }
                            })
                            break;
                        case "COP":
                            //console.log("aca")
                            if (precioVenta < precioMinimo) {
                                this.setState({
                                    precioMinimoNuevo: precioVenta - 100
                                })
                                document.getElementById("precioMinimoNuevo").focus()
                            } else if (precioMinimo > precioMaximo) {
                                this.setState({
                                    precioMaximoNuevo: precioVenta + 100
                                })
                                document.getElementById("precioMaximoNuevo").focus()
                            } else if (precioVenta > precioMaximo) {
                                this.setState({
                                    precioMaximoNuevo: precioVenta + 100
                                })
                                document.getElementById("precioMaximoNuevo").focus()
                            }
                            this.state.empresasGuardar.map((tienda, key) => {
                                if (parseInt(tienda.precio) < precioMinimo) {
                                    empresas[key].precio = precioVenta
                                } else if (parseInt(tienda.precio) > precioMaximo) {
                                    empresas[key].precio = precioVenta
                                }
                            })
                            break;
                    }

                } else {
                    if (parseInt(this.state.precioMaximoNuevo) > 100) {
                        this.setState({
                            precioMaximoNuevo: 100
                        })
                    } else if (parseInt(this.state.precioMinimoNuevo) > 100) {
                        this.setState({
                            precioMinimoNuevo: 100
                        })
                    } else if (parseInt(this.state.precioMaximoNuevo) < 0) {
                        this.setState({
                            precioMaximoNuevo: 0
                        })
                    } else if (parseInt(this.state.precioMinimoNuevo) < 0) {
                        this.setState({
                            precioMinimoNuevo: 0
                        })
                    }
                    if (parseInt(this.state.precioMinimoNuevo) > parseInt(this.state.precioMaximoNuevo) && parseInt(this.state.precioMinimoNuevo) === 100) {
                        this.setState({
                            precioMinimoNuevo: 99,
                            precioMaximoNuevo: 100
                        })
                    } else if (parseInt(this.state.precioMinimoNuevo) > parseInt(this.state.precioMaximoNuevo)) {
                        this.setState({
                            precioMaximoNuevo: parseInt(this.state.precioMinimoNuevo) + 1
                        })
                    }
                }
            }
        } else {
            this.state.empresasGuardar.map((tienda, key) => {
                //console.log(tienda)
                if (parseInt(tienda.precio) != precioVenta) {
                    empresas[key].precio = precioVenta
                }
            })
        }
        this.setState({
            empresasGuardar: empresas
        })

    }

    handleChangeTabla = () => {
        this.setState({
            xSucursal: !this.state.xSucursal
        })
    };

    render() {
        //const { producto } = this.props;
        var vistaUsuario = this.state.modovista
        var dominio = store.getState().dominio
        var imagenDefault = Server.hostMultimedia + dominio + "/productos/default.png"
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        var identificadores = '';
        this.state.identificadores.map((contacto) => {
            identificadores += contacto.codigos + ", "
        })
        identificadores = identificadores.substring(0, identificadores.length - 2)
        totalesEmpresasbd.map((n, k) => {
            if (this.state.checked.includes(n.id)) {
                if (!nn.includes(n)) {
                    nn.push(n)
                }
            }
        })
        return (
            <div>
                <AppBar style={{ backgroundColor: colorDominio }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Close"
                            onClick={this.cerrarVentanaCreacion.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            style={{ marginLeft: "theme.spacing(2)", flex: 1, color: '#fff' }}
                        >
                            {Detalles ? this.state.nombreProductoNuevo : vistaUsuario ? 'Edicion de PRODUCTO' : 'Creacion de producto'}
                        </Typography>
                        {
                            vistaUsuario
                                ?
                                Detalles
                                    ?
                                    null
                                    :
                                    <Button style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} disabled={this.state.response} onClick={this.editarProducto.bind(this)}>
                                        GUARDAR
              </Button>
                                :
                                <Button style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} disabled={this.state.response} onClick={this.registrarProducto.bind(this)}>
                                    CREAR PRODUCTO
              </Button>
                        }

                    </Toolbar>
                </AppBar>
                <div className="row align-middle mx-auto" style={{ marginTop: '7%' }}>
                    {vistaUsuario
                        ?
                        <div className="col-md-3 mx-auto rounded-circle ">
                            <div className={this.state.croppedImage != null ? "rounded-circle border border-dark mx-auto" : "productoImg rounded-circle border border-dark mx-auto"} style={{ height: '200px', width: '200px' }} >
                                {this.state.croppedImage != null ? <img onError={(e) => { e.target.onerror = null; e.target.src = imagenDefault }} className="rounded-circle border border border-dark" height="200" width="200" src={this.state.cambioFoto ? this.state.croppedImage : imagenProducto} /> : <input autocomplete="off" type="file" onChange={(e) => this._handleImageChange(e)} style={{ opacity: "0.0", height: "100%", cursor: "pointer" }} accept="image/png" />}
                            </div>
                            {Detalles ? null : <img src={ChangeImage} height="30" width="30" onClick={() => this.borrarImagen()} style={{ cursor: 'pointer', float: 'right' }} />}
                        </div>
                        :
                        <div className="col-md-3 mx-auto rounded-circle ">
                            <div className="productoImg rounded-circle border border-dark mx-auto" style={{ height: '200px', width: '200px' }} >
                                {this.state.croppedImage != null ? <img className="img-user rounded-circle border border border-dark" height="200" width="200" src={this.state.croppedImage} /> : <input autocomplete="off" type="file" onChange={(e) => this._handleImageChange(e)} style={{ opacity: "0.0", height: "100%", cursor: "pointer" }} accept="image/png" />}
                            </div>
                            {this.state.croppedImage != null ? <img src={ChangeImage} height="30" width="30" onClick={() => this.borrarImagen()} style={{ cursor: 'pointer', float: 'right' }}></img> : vistaUsuario ? <img src={ChangeImage} height="30" width="30" onClick={() => this.borrarImagen()} style={{ cursor: 'pointer', float: 'right' }}></img> : null}
                        </div>
                    }

                    <div className="col-md-1">
                        <img src={this.state.favorito ? FavoritoOn : FavoritoOff} height="50" width="50" onClick={() => this.favorito()} style={{ cursor: 'pointer' }}></img>
                    </div>
                    <Dialog
                        open={this.state.dialogphoto}
                        onClose={this.handledialogfoto}
                        aria-labelledby="form-dialog-title"
                        maxWidth={'sm'}
                        fullWidth={true}
                        disableBackdropClick={true}
                    >
                        <DialogTitle id="form-dialog-title">Recortar la foto</DialogTitle>
                        <DialogContent >
                            <div className="row mx-auto">
                                <div className="col col-md-12" style={{ height: "150px" }}>
                                    <Cropper
                                        image={this.state.imagePreviewUrl}
                                        crop={this.state.crop}
                                        zoom={this.state.zoom}
                                        aspect={this.state.aspect}
                                        onCropChange={this.onCropChange}
                                        onCropComplete={this.onCropComplete}
                                        onZoomChange={this.onZoomChange}
                                    />
                                </div>
                            </div>
                            <div className="row mx-auto">
                                <div className="col col-md-12" style={{ height: "100%", margin: "15px" }}>
                                    <Slider
                                        value={this.state.zoom}
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        aria-labelledby="Zoom"
                                        onChange={(e, zoom) => this.onZoomChange(zoom)}
                                        classes={{ container: 'slider' }}
                                    />
                                </div>
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <button onClick={this.handledialogfoto} className="btn btn-primary">
                                Cancelar
                                                        </button>
                            <button onClick={this.showCroppedImage} className="btn btn-primary">
                                Agregar foto
                                                        </button>
                        </DialogActions>
                    </Dialog>
                    <Dialog fullScreen open={this.state.dialogproveedor} onClose={this.crearProvedor} TransitionComponent={Transition}>
                        <FormularioProveedor userEdit={null} />
                    </Dialog>
                    <Dialog
                        open={this.state.dialogCategoria}
                        onClose={() => this.crearCategoria()}
                        aria-labelledby="form-dialog-title"
                        maxWidth={'lg'}
                        fullWidth={true}
                        style={{ padding: '0', overflow: 'auto' }}
                    >
                        <DialogContent style={{ padding: '0' }}>
                            <FormularioCategoria categoriaEditar={null} />
                        </DialogContent>

                    </Dialog>
                    <Dialog
                        open={this.state.dialogImpuesto}
                        onClose={() => this.crearImpuesto()}
                        aria-labelledby="form-dialog-title"
                        maxWidth={'lg'}
                        fullWidth={true}
                        style={{ padding: '0' }}
                        disableBackdropClick={false}
                    >
                        <DialogContent style={{ padding: '0' }}>
                            <FormularioImpuesto />
                        </DialogContent>

                    </Dialog>
                    <div className="col col-md-12 col-lg-12">
                        <div className="row">
                            <div className="col-md-5">
                                <label htmlFor="numordencompra" className="col-form-label">NOMBRE DEL PRODUCTO *</label>
                                <input autocomplete="off" id="numordencompra" disabled={Detalles} value={this.state.nombreProductoNuevo} className="form-control" type="text" onChange={this.handleChange('nombreProductoNuevo')} />
                            </div>
                            <div className="col-md-4 form-group">
                                <label htmlFor="numordencompra" className="col-form-label">CODIGO DE PLU *</label>
                                <Tooltip title="CODIGO INTERNO O CODIGO DE PLU PARA RECONOCER EL PRODUCTO" placement="top">
                                    <PreguntaIcon color="disabled" />
                                </Tooltip>
                                <Tooltip title={'Este es el posible plu para su producto'} placement="top"><input autocomplete="off" id="numordencompra" value={this.state.codigoInternoNuevo} className="form-control" type="text" disabled={true} onChange={this.handleChange('codigoInternoNuevo')} /></Tooltip>
                            </div>
                            <div className="col-md-3" >
                                <label htmlFor="nombreprov" className="col-form-label">TIPO DE PRODUCTO</label>
                                <Selects
                                    isDisabled={Detalles}
                                    placeholder="Seleccione"
                                    value={this.state.tipoProducto}
                                    onChange={this.handleChanges('tipoProducto')}
                                    options={this.state.tipoProductosbd}
                                />

                            </div>
                        </div>
                        <div className="row bottom-aligned">
                            {this.state.tipoDetalles === '5' ?
                                null
                                :
                                <div className="col-md-7">
                                    <label htmlFor="numordencompra" className="col-form-label">CODIGOS DE BARRA</label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">#</span>
                                        </div>
                                        <input autocomplete="off" type="text" class="form-control" aria-describedby="basic-addon1" value={identificadores} disabled={true} onClick={this.handledialogIdentificador} />
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" disabled={Detalles} type="button" onClick={this.handledialogIdentificador} >+</button>
                                        </div>
                                    </div>
                                </div>}
                            <div className={this.state.tipoDetalles === '5' ? "col col-md-6 mx-auto" : "col col-md-5 mx-auto"}>
                                <div className="row">

                                    {this.state.tipoDetalles === '5' ? null : <div className="col col-md-4" style={{ marginTop: "0" }}>
                                        <Tooltip title="SI SE MARCA INDICA QUE EL PRODUCTO SERA UN INGREDIENTE" placement="top">
                                            <FormControlLabel disabled={this.state.checkIngredienteDisabled || Detalles} control={<Checkbox style={{ padding: "0" }} checked={this.state.checkIngrediente} value={this.state.checkIngrediente} onChange={this.handleChangeCheck("checkIngrediente")} />} label="INGREDIENTE" />
                                        </Tooltip>
                                    </div>}
                                    {/*this.state.tipoDetalles == '2' || this.state.tipoDetalles == '3' ? null :
                                        <div className="col col-md-4" style={{ marginTop: "0" }}>
                                            <Tooltip title="SI SE MARCA INDICA QUE DEL PRODUCTO DE PUEDEN VENDER SUS INGREDIENTES POR SEPARADOS" placement="top">
                                                <FormControlLabel d control={<Checkbox style={{ padding: "0" }} checked={this.state.checkDesintegrado} value={this.state.checkDesintegrado} onChange={this.handleChangeCheck("checkDesintegrado")} />} label="Desintegrable" />
                                            </Tooltip>
                                        </div>

                */}
                                    {this.state.tipoDetalles === '2' || this.state.tipoDetalles === '5' ? null
                                        :

                                        <div className="col col-md-4" style={{ marginTop: "0" }}>
                                            <Tooltip title="SI SE MARCA INDICA QUE EL PRODUCTO USA BALANZA" placement="top">
                                                <FormControlLabel disabled={Detalles} control={<Checkbox style={{ padding: "0" }} checked={this.state.checkBalanza} value={this.state.checkBalanza} onChange={this.handleChangeCheck("checkBalanza")} />} label="USA BALANZA" />
                                            </Tooltip>
                                        </div>
                                    }

                                </div>
                                <div className="row">
                                    {this.state.tipoDetalles === '2' ? null :
                                        <div className="col col-md-4" style={{ marginTop: "0" }}>
                                            <Tooltip title="SI SE MARCA INDICA QUE EL PRODUCTO SE LE PUEDE COMPRAR A UNO O MAS PROVEEDORES" placement="top">
                                                <FormControlLabel disabled={this.state.checkSeCompraDisabled || Detalles} control={<Checkbox style={{ padding: "0" }} value={this.state.checkSeCompra} checked={this.state.checkSeCompra} onChange={this.handleChangeCheck("checkSeCompra")} />} label="SE PUEDE COMPRAR" />
                                            </Tooltip>
                                        </div>}
                                    <div className="col col-md-6" style={{ marginTop: "0" }}>
                                        <Tooltip title="SI SE MARCA INDICA QUE EL PRODUCTO SE PUEDE COMERCIALIZAR" placement="top">
                                            <FormControlLabel disabled={this.state.checkSeVendeDisabled || Detalles} control={<Checkbox style={{ padding: "0" }} value={this.state.checkSeVende} checked={this.state.checkSeVende} onChange={this.handleChangeCheck("checkSeVende")} />} label="SE PUEDE VENDER" />
                                        </Tooltip>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>


                </div>

                <div className="row mx-auto">
                    <ExpansionPanel className="col col-md-11  border  mx-auto" defaultExpanded style={{ margin: "15px" }}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            {this.state.checkSeVende ? 'PRECIOS' : 'KIOSCOS'}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ display: "block" }}>
                            <div className="row mx-auto my-auto">
                                {this.state.panelListaPrecio ? <div className="col col-md-6 border rounded" style={{ paddingBottom: '10px' }}>
                                    <div className="row mx-auto my-auto">
                                        <div className="col col-md-8">
                                            <label htmlFor="numordencompra" className="col-form-label">PRECIO DE VENTA</label>
                                            <div class="input-group" style={{ marginBottom: '0!important' }}>
                                                <div class="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                                    <span class="input-group-text" id="basic-addon1">$</span>
                                                </div>
                                                <input autocomplete="off" type="number" min="1" onBlur={() => this.validarPrecios()} id="precioventa" class="form-control" disabled={Detalles} value={this.state.precioventa} onChange={this.handleChange('precioventa')} aria-label="Username" aria-describedby="basic-addon1" />
                                            </div>
                                        </div>
                                        {
                                            moneda === "USD"
                                                ?
                                                null :
                                                <div className="col col-md-4 mx-auto bottom-aligned" style={{ marginTop: "0" }}>
                                                    <FormControlLabel disabled={Detalles || this.state.precioventa === '' || parseFloat(this.state.precioventa) === 0 || isNaN(parseInt(this.state.precioventa)) || parseInt(this.state.precioventa) < 0} className="mx-auto" control={<Checkbox style={{ padding: "0" }} className="mx-auto my-auto" checked={this.state.precioFlexible} value={this.state.precioFlexible} onChange={this.handleChangeCheck("precioFlexible")} />} label="PRECIO FLEXIBLE" />
                                                </div>
                                        }
                                    </div>
                                    {this.state.precioFlexible
                                        ?
                                        <div className="row mx-auto">
                                            <div className="col-md-4">
                                                <div className="row">
                                                    <label htmlFor="numordencompra" className="col-form-label">TIPO DE PRECIO</label>
                                                </div>
                                                <div className="row">

                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                onChange={this.cambiarContacto.bind(this)}
                                                                value={this.state.boolTipo}
                                                                checked={this.state.boolTipo}
                                                                disabled={Detalles}
                                                            />
                                                        }
                                                        label={this.state.boolTipo ? '$' : '%'}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col col-md-4">
                                                <label htmlFor="numordencompra" className="col-form-label">{this.state.boolTipo ? ' PRECIO MINIMO' : 'PORCENTAJE MINIMO'}</label>
                                                <div class="input-group " style={{ marginBottom: '0!important' }}>
                                                    <div class="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                                        <span class="input-group-text" id="basic-addon1">{this.state.tipoPrecio}</span>
                                                    </div>
                                                    <input autocomplete="off" onBlur={() => this.validarPrecios()} id="precioMinimoNuevo" className="form-control" type="number" min="1" disabled={Detalles} value={this.state.precioMinimoNuevo} onChange={this.handleChange('precioMinimoNuevo')} />
                                                </div>
                                            </div>
                                            <div className="col col-md-4">
                                                <label htmlFor="numordencompra" className="col-form-label">{this.state.boolTipo ? 'PRECIO MAXIMO' : 'PORCENTAJE MAXIMO'}</label>
                                                <div class="input-group " style={{ marginBottom: '0!important' }}>
                                                    <div class="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                                        <span class="input-group-text" id="basic-addon1">{this.state.tipoPrecio}</span>
                                                    </div>
                                                    <input autocomplete="off" onBlur={() => this.validarPrecios()} id="precioMaximoNuevo" className="form-control" type="number" min="1" disabled={Detalles} value={this.state.precioMaximoNuevo} onChange={this.handleChange('precioMaximoNuevo')} />
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    <div className="row mx-auto">
                                        <div className="col-md-10" >
                                            <label htmlFor="nombreprov" className="col-form-label">IMPUESTOS</label>
                                            <Selects
                                                placeholder="Seleccione o escriba"
                                                value={this.state.multiImpuestos}
                                                onChange={this.handleChanges('multiImpuestos')}
                                                options={this.state.impuestosbd}
                                                isMulti
                                                isDisabled={Detalles || !this.state.checkSeVende}
                                            />
                                        </div>

                                        <div className="bottom-aligned">
                                            <button disabled={Detalles} className="btn btn-primary" onClick={() => this.crearImpuesto()}>+</button>
                                        </div>
                                    </div>
                                </div> : null}
                                <div className={this.state.checkSeVende ? "col col-md-6 border rounded" : "col col-md-12 border rounded"} style={{ paddingBottom: '10px' }}>
                                    {Detalles || vistaUsuario ? null :
                                        <div className="row">
                                            <FormControlLabel
                                                className="col-md-6"
                                                control={
                                                    <Switch
                                                        onChange={this.handleChangeTabla.bind(this)}
                                                        value={this.state.xSucursal}
                                                        checked={this.state.xSucursal}
                                                    />
                                                }
                                                label={this.state.xSucursal ? "SELECCION POR SUCURSAL" : "SELECCION POR KIOSCO"}
                                            />
                                        </div>}

                                    {Detalles ? null : <div className="row mx-auto my-auto">

                                        {this.state.xSucursal ?
                                            <div className={this.state.checkSeVende ? "col col-md-12" : "col col-md-6"}>
                                                <label htmlFor="numordencompra" className="col-form-label">SELECCIONAR SUCURSAL</label>
                                                <Selects
                                                    placeholder="Seleccione o escriba"
                                                    value={this.state.sucursalSeleccionada}
                                                    onChange={this.handleChanges('sucursalSeleccionada')}
                                                    options={this.state.sucursalesbd}
                                                    isClearable={true}
                                                />
                                            </div>
                                            :
                                            <div className={this.state.checkSeVende ? "col col-md-12" : "col col-md-6"}>
                                                <label htmlFor="numordencompra" className="col-form-label">SELECCIONAR KIOSCO</label>
                                                <Selects
                                                    placeholder="Seleccione o escriba"
                                                    value={this.state.empresaSeleccionda}
                                                    onChange={this.handleChanges('empresaSeleccionda')}
                                                    options={this.state.empresasbd}
                                                    isClearable={true}
                                                />
                                            </div>}

                                        {this.state.checkSeVende ? <div className="col col-md-6">
                                            <label htmlFor="numordencompra" className="col-form-label">PRECIO DE VENTA</label>
                                            <div class="input-group" style={{ marginBottom: '0!important' }}>
                                                <div class="input-group-prepend" style={{ marginBottom: '0!important' }}>
                                                    <span class="input-group-text" id="basic-addon1">$</span>
                                                </div>
                                                <input autocomplete="off" type="number" min="1" class="form-control" value={this.state.precioporEmpresa} onChange={this.handleChange('precioporEmpresa')} aria-label="Username" aria-describedby="basic-addon1" />
                                            </div>
                                        </div> : null}
                                        <div className="col col-md-3 bottom-aligned">
                                            <Tooltip title={"AGREGAR A " + (JSON.stringify(this.state.empresaSeleccionda) == '{}' || this.state.empresaSeleccionda == null ? 'ESTE KIOSCO' : this.state.empresaSeleccionda.label)} placement="top">
                                                <button className="btn btn-primary btn-block" disabled={JSON.stringify(this.state.empresaSeleccionda) == '{}' || this.state.empresaSeleccionda == null || this.state.xSucursal} onClick={vistaUsuario ? () => this.agregarTiendabd() : () => this.agregarTienda()}>+ KCO</button>
                                            </Tooltip>
                                        </div>
                                        {this.state.xSucursal ? null : <div className="col col-md-3 bottom-aligned">
                                            <Tooltip title="AGREGAR A TODOS LOS KIOSCOS" placement="top">
                                                <button className="btn btn-primary btn-block" disabled={this.state.xSucursal} onClick={vistaUsuario ? () => this.agregarTiendaTodasBD() : () => this.agregarTiendaTodas()}>+ TODOS</button>
                                            </Tooltip>
                                        </div>}
                                        {vistaUsuario || !this.state.xSucursal ? null : <div className="col col-md-3 bottom-aligned">
                                            <Tooltip title="AGREGAR A TODOS LOS KIOSCOS" placement="top">
                                                <button className="btn btn-primary btn-block" onClick={() => this.agregarSucursalbd()}>+ REGIONAL</button>
                                            </Tooltip>
                                        </div>}
                                    </div>}
                                    {Detalles ||
                                        !this.state.xSucursal ||
                                        this.state.sucursalSeleccionada === null ||
                                        JSON.stringify(this.state.sucursalSeleccionada) === "{}" ? null :
                                        <div className="row mx-auto">
                                            <Paper className="col-md-10 col-sm-12  mx-auto" style={{ padding: '10px', marginTop: '10px' }}>
                                                {this.state.xSucursal ? this.state.empresasxSucursalSeleccionada.map((data, key) => {
                                                    console.log(data)
                                                    return (
                                                        <Chip
                                                            style={{ margin: '2px' }}
                                                            key={key}
                                                            label={data.label}
                                                        />
                                                    );
                                                }) : null}

                                            </Paper>
                                        </div>
                                    }
                                    <div className="row mx-auto my-auto">
                                        <List className="col-md-12 mx-auto " >
                                            {this.state.empresasGuardar.map((empresa, key) => {
                                                //console.log(moneda)
                                                if (empresa.estado != "I") {
                                                    return (
                                                        <ListItem className="mx-auto border " key={key}>
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    <PersonaIcon />
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={empresa.empresa} secondary={'$' + (moneda === "COP" ? this.setearMiles(parseInt(empresa.precio)) : this.setearMiles(parseFloat(empresa.precio)))} />
                                                            {
                                                                vistaUsuario
                                                                    ?
                                                                    Detalles
                                                                        ?
                                                                        <ListItemSecondaryAction>
                                                                            <Tooltip title={empresa.disponible === "S" ? "EL PRODUCTO ESTA ACTIVO PARA ESTA TIENDA" : "EL PRODUCTO ESTA INACTIVO PARA ESTA TIENDA"} placement="top">
                                                                                <FormControlLabel disabled={true} control={<Checkbox style={{ padding: "0" }} checked={empresa.disponible === "S"} value={empresa.disponible === "S"} onChange={this.handleChangeCheck("checkDisponibleEmpresa", key)} />} label="DISPONIBLE" />
                                                                            </Tooltip>
                                                                        </ListItemSecondaryAction>
                                                                        :
                                                                        <ListItemSecondaryAction>
                                                                            <Tooltip title="SI MARCA ESTA OPCION LE DA OPERMISO A LA EMPRESA PARA QUE PUEDA DISPONER DEL PRODUCTO" placement="top">
                                                                                <FormControlLabel d control={<Checkbox style={{ padding: "0" }} checked={empresa.disponible === "S"} value={empresa.disponible === "S"} onChange={this.handleChangeCheck("checkDisponibleEmpresa", key)} />} label="DISPONIBLE" />
                                                                            </Tooltip>
                                                                            <IconButton aria-label="Delete" onClick={vistaUsuario ? () => this.eliminarTiendabd(key) : () => this.eliminarTienda(key)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </ListItemSecondaryAction>
                                                                    :
                                                                    <ListItemSecondaryAction>
                                                                        <IconButton aria-label="Delete" onClick={vistaUsuario ? () => this.eliminarTiendabd(key) : () => this.eliminarTienda(key)}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </ListItemSecondaryAction>
                                                            }
                                                        </ListItem>
                                                    )
                                                }
                                            })}

                                        </List>
                                    </div>
                                </div>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
                {this.state.panelDetallesProductos ? <div className="row mx-auto">
                    <ExpansionPanel defaultExpanded className="col col-md-11 border mx-auto" style={{ margin: "15px" }}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                            PROPIEDADES DEL PRODUCTO
                                    </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ display: "block" }}>
                            {this.Detalles()}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div> : null}


                <div className="row">
                    {this.state.tipoDetalles == "2" || this.state.tipoDetalles == "3" ?
                        <ExpansionPanel expanded={this.state.expandedIngredientes} className="col col-md-5 border mx-auto" style={{ margin: "15px" }}>
                            <ExpansionPanelSummary expanded={true} expandIcon={<ExpandMoreIcon />} >
                                LISTA DE INGREDIENTES
                                    </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{ display: "block" }}>
                                <div className="row ">
                                    {this.state.ingredientesProductoCompuesto.length > 0 ?
                                        <List className="border rounded col">
                                            {this.state.ingredientesProductoCompuesto.map((row, key) => {
                                                if (row.estado != "I") {
                                                    return (
                                                        <ListItem>
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    <img src={productoDefault} height="100%"></img>
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={row.producto}
                                                                secondary={row.cantidad}
                                                            />
                                                            {Detalles ? null : <ListItemSecondaryAction>
                                                                <IconButton aria-label="Delete" onClick={() => this.eliminarIngrediente(key)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>}
                                                        </ListItem>
                                                    )
                                                }

                                            })}
                                        </List>
                                        : null}
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        : null}
                    {/*this.state.multiImpuestos.length >= 1 ?
                        <ExpansionPanel className="col col-md-5 border mx-auto" style={{ margin: "15px" }}>
                            <ExpansionPanelSummary expanded={true} expandIcon={<ExpandMoreIcon />} >
                                Lista de impuestos
                                    </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{ display: "block" }}>
                                <div className="row">
                                    {this.state.multiImpuestos.map((proveedor) => {
                                        ////console.log(proveedor)
                                        return (
                                            <div className="col-md-6 mx-auto" style={{ marginTop: "0" }}>
                                                {vistaUsuario
                                                    ?
                                                    <FormControlLabel disabled={true} control={<Checkbox style={{ padding: "0" }} checked={proveedor.check} value={proveedor.check} onChange={this.ivaincluido(proveedor.value)} />} label={proveedor.label} />
                                                    :
                                                    <FormControlLabel control={<Checkbox style={{ padding: "0" }} onChange={this.ivaincluido(proveedor.value)} />} label={proveedor.label} />
                                                }

                                            </div>
                                        )
                                    })}
                                </div>


                            </ExpansionPanelDetails>
                                </ExpansionPanel> : null*/}
                </div>
                {/*!Detalles && this.state.tipoDetalles === "2" ? <div className="row mx-auto">
                    <ExpansionPanel expanded={true} className="col col-md-11 border mx-auto" style={{ margin: "15px" }}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                            Tiendas
                                    </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ display: "block" }}>
                            <div className="col-md-12">
                                {this.state.tipoDetalles == "2" || this.state.tipoDetalles == "3" ?
                                    Detalles ?
                                        null :
                                        <div className="row">
                                            <Paper className=" col-md-6 border rounded" style={{ padding: '10px' }}>

                                                <CheckboxTree
                                                    showNodeIcon={true}
                                                    nodes={this.state.nodeEmpresas}
                                                    checked={this.state.checked}
                                                    expanded={this.state.expanded}
                                                    onCheck={checked => this.setState({ checked })}
                                                    onExpand={expanded => this.setState({ expanded })}
                                                />


                                            </Paper>
                                            <Paper className=" col-md-6 border rounded" style={{ padding: '10px' }}>
                                                {nn.map((data, key) => {
                                                    return (
                                                        <Chip
                                                            style={{ margin: '2px' }}
                                                            key={key}
                                                            label={data.razon_social}
                                                            onDelete={() => this.eliminarSeleccionada(data.id)}
                                                        />
                                                    );
                                                })}

                                            </Paper>
                                        </div>
                                    : this.state.tiendasbd == null
                                        ?
                                        <h5>Este producto no se a configurado para ninguna tienda</h5>
                                        :
                                        <Paper className="border rounded" style={{ padding: '10px' }}>
                                            {this.state.tiendasbd.map((data, key) => {
                                                return (
                                                    <Chip
                                                        style={data.disponible == "S" ? { margin: '2px', backgroundColor: '#28a745', color: '#ffffff' } : { margin: '2px', color: '#000000' }}
                                                        key={key}
                                                        label={data.razon_social}
                                                        color={data.disponible == "S" ? "#00c853" : "inherit"}
                                                        onDelete={Detalles ? null : () => this.disponibilidadEmpresa(key)}
                                                        deleteIcon={data.disponible == "S" ? <CancelIcon /> : <DoneIcon />}
                                                    />
                                                );
                                            })}
                                        </Paper>
                                }
                                {this.state.cambioEmpresa ? <button className="btn btn-success" onClick={() => this.guardarProductosEmpresas()} style={{ float: 'right', margin: '10px' }}>ACTUALIZAR</button> : null}

                            </div>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                open={this.state.alertaEmpresas}
                                autoHideDuration={2000}
                                message="Disponibilidad en empresas actualizada!"
                                variant="success"
                                onClose={this.cerrarAlerta}
                                action={[
                                    <IconButton
                                        key="close"
                                        aria-label="Close"
                                        color="inherit"
                                        onClick={this.cerrarAlerta}
                                    >
                                        <CancelIcon />
                                    </IconButton>,
                                ]}
                            >

                            </Snackbar>

                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                            </div> : null*/}
                {this.state.panelProveedor ? <div className="row mx-auto">
                    <ExpansionPanel className="col border col-md-11 border mx-auto" defaultExpanded style={{ margin: "15px" }}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            DETALLES DEL PROVEEDOR
                                    </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ display: "block" }}>
                            <div className="row">
                                <div className="col-md-12">
                                    {Detalles ? null : <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor="nombreprov" className="col-form-label">PROVEEDOR</label>
                                            <Selects
                                                placeholder="Seleccione o escriba"
                                                value={this.state.proveedorSeleccionado}
                                                onChange={this.handleSelect('proveedorSeleccionado')}
                                                options={this.state.proveedoresbd}
                                                isClearable={true}
                                            />

                                        </div>
                                        <div className="bottom-aligned">
                                            <Tooltip title="CREAR NUEVO PROVEEDOR" placement="top">
                                                <button className="btn btn-primary" onClick={() => this.crearProvedor()}>+</button>
                                            </Tooltip>
                                        </div>
                                        <div className="col-md-4" >
                                            <label htmlFor="nombreprov" className="col-form-label">IMPUESTOS</label>
                                            <Selects
                                                placeholder="Seleccione"
                                                value={this.state.multiImpuestosProveedor}
                                                onChange={this.handleChanges('multiImpuestosProveedor')}
                                                options={this.state.impuestosbd}
                                                isDisabled={JSON.stringify(this.state.proveedorSeleccionado) == '{}' || this.state.proveedorSeleccionado == null}
                                                isMulti
                                            />
                                        </div>
                                        <div className="bottom-aligned" style={{ marginRight: '10px' }}>
                                            <Tooltip title="CREAR NUEVO IMPUESTO" placement="top">
                                                <button className="btn btn-primary" disabled={Detalles} onClick={() => this.crearImpuesto()}>+</button>
                                            </Tooltip>
                                        </div>
                                        <div className="bottom-aligned">
                                            <Tooltip title="AGREGAR PROVEEDOR" placement="top">
                                                <button className="btn btn-primary" disabled={JSON.stringify(this.state.proveedorSeleccionado) == '{}' || this.state.proveedorSeleccionado == null} onClick={() => this.agregarProvedor()}>AGREGAR</button>
                                            </Tooltip>
                                        </div>
                                    </div>}
                                    <div className="row">

                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="row mx-auto">
                                        {/*<List className="col-md-12 mx-auto " >
                                            {this.state.proveedoresGuardar.map((proveedor, key) => {
                                                return (
                                                    <ListItem className="mx-auto border " key={key}>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <PersonaIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={proveedor.proveedor} secondary={'$' + proveedor.precio} />
                                                        <ListItemSecondaryAction>
                                                            <IconButton aria-label="Delete" onClick={() => this.eliminarProveedor(key)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )
                                            })}

                                        </List>*/}
                                        {this.state.proveedoresGuardar.map((proveedor, key) => {
                                            var total = parseFloat(proveedor.precio)
                                            return (
                                                <div className="col-md-5 border rounded mx-auto" style={{ margin: '10px', paddingTop: '10px' }}>
                                                    <div className="row">
                                                        <div className="col-md-10 mx-auto">
                                                            <h4><b>{proveedor.proveedor}</b></h4>
                                                        </div>
                                                        <div className="col-md-2">
                                                            {Detalles ? null : <IconButton aria-label="Delete" onClick={() => this.eliminarProveedor(key)}>
                                                                <DeleteIcon />
                                                            </IconButton>}
                                                        </div>
                                                    </div>
                                                    {proveedor.impuestos.map((impuesto) => {
                                                        ////console.log(impuesto)
                                                        total = total + parseFloat(impuesto.porcentaje_valor === "$" ? impuesto.valor : (parseFloat(parseFloat(proveedor.precio) * parseFloat(impuesto.valor) / 100)))
                                                        return (
                                                            <div className="row">
                                                                <div className="col-md-8"><h6>{impuesto.descripcion}</h6></div>
                                                                <div className="col-md-4"><h6>{(impuesto.porcentaje_valor === "$" ? '$ ' + impuesto.valor : impuesto.valor + ' %')}</h6></div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>


                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div> : null}

                {this.state.panelIngredientes ? <div className="row mx-auto">
                    <ExpansionPanel className="col col-md-11  border  mx-auto" defaultExpanded style={{ margin: "15px" }}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            INGREDIENTES
                                    </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ display: "block" }}>
                            <div className="row">
                                <div className="col col-md-8">
                                    <div className="row">
                                        <div className="col col-md-12">
                                            <label htmlFor="numordencompra" className="col-form-label">INGREDIENTE</label>
                                            <Selects
                                                placeholder="Seleccione o escriba"
                                                value={this.state.ningredienteProdcuto}
                                                onChange={this.handleChanges('ningredienteProdcuto')}
                                                options={this.state.ingredientesbd}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-md-10">
                                            <label htmlFor="numordencompra" className="col-form-label">CANTIDAD</label>
                                            <input autocomplete="off" id="numordencompra" className="form-control" type="text" onChange={this.handleChange('ningredienteCantidad')} />
                                        </div>
                                        <div className="col col-md-2  bottom-aligned">
                                            <button className="btn btn-success" onClick={this.agregarIngrediente.bind(this)}>+</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-md-4">
                                    <div className="row ">
                                        {this.state.ingredientesProductoCompuesto.length > 0 ?
                                            <List className="border rounded col">
                                                {this.state.ingredientesProductoCompuesto.map((row, key) => (
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <img src={productoDefault} height="100%"></img>
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={row.producto}
                                                            secondary={row.cantidad}
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <IconButton aria-label="Delete" onClick={() => this.eliminarIngrediente(key)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ))}
                                            </List>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div> : null}



                <Dialog
                    open={this.state.dialogIDentificafor}
                    onClose={this.handledialogIdentificador}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'sm'}
                    style={{ padding: '0' }}
                    fullWidth={true}
                    disableBackdropClick={true}
                >
                    <DialogContent style={{ padding: '0' }}>
                        <DialogIdentificador codigosn={this.state.identificadores} />
                    </DialogContent>

                </Dialog>
            </div>

        )
    }

}

FormularioProducto.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

export default withStyles({ withTheme: true })(FormularioProducto)
