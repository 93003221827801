import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete';
import EditICon from '@material-ui/icons/Edit';
import Server from '../../../lib/server'
import store from '../../../lib/store'
import Paper from '@material-ui/core/Paper';
import Activar from "@material-ui/icons/Check"
import { MDBDataTable } from 'mdbreact';
import FormularioUnidades from '../../../components/Formularios/FormularioUnidades';
import swal from 'sweetalert'
import Tooltip from '@material-ui/core/Tooltip';
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
const idModulo = 28
var permisos = ''
class Categorias extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Desde bd
            perfilesbd: [],
            //
            ventanaCreacion: false,
            actualizarDatos: false,
            modoEdicion: false,
            unidadEditar: null
        };
        store.subscribe(() => {
            this.setState({
                actualizarDatos: store.getState().actualizarvista,
                ventanaCreacion: store.getState().creacionUnidad,
            })
        })
    }




    crearProducto = () => {
        this.setState({ ventanaCreacion: !this.state.ventanaCreacion, unidadEditar: null })
    }

    editarUnidad = (unidad) => {
        this.setState({ modoEdicion: true, unidadEditar: unidad })
        this.setState({ ventanaCreacion: !this.state.ventanaCreacion })
    }

    eliminarUnidad = (id) => {
        Axios.delete(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/unidades/' + id, { headers: { Authorization: ntoken } })
            .then((res) => {
                console.log(res)
                if (res.status == 200) {
                    swal({
                        title: "unidad eliminada correctamente",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                store.dispatch({
                                    type: "CREACION_UNIDAD",
                                    creacionUnidad: false
                                })
                                break;
                            default:

                        }
                    });
                }  else if (res.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }else {
                    swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                }
            })
    }

    componentWillMount() {
        this.ontenerDatos()
    }

    componentWillReceiveProps() {

        if (this.state.actualizarDatos) {
            this.ontenerDatos()
        }
    }

    activarUnidad = (id) => {
        Axios.put(Server.hostPrueba + Server.puertSSLParametrizacion + '/api/unidades/activar/' + id, { headers: { Authorization: ntoken } })
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    swal({
                        title: "Unidad activada",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                store.dispatch({
                                    type: "CREACION_UNIDAD",
                                    creacionUnidad: false
                                })
                                this.setState({ loader: true, cambbioInicio: false, cambbioFin: false })
                                break;

                            default:

                        }
                    });
                } else if (response.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                } else {
                    swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                }
            })
    }

    ontenerDatos = () => {
        this.setState({ actualizarDatos: false })
        var dominio = store.getState().dominio
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/unidades/empresas/' + dominio, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var perf = []
                if (responsee.status === 200) {
                    responsee.data.map((perfil) => {
                        perf.push({
                            'descripcion': perfil.descripcion,
                            'valor': parseInt(perfil.valor),
                            "acciones": perfil.estado == "A" ? [
                                permisos.includes('U') ? <Tooltip title={'Editar'} placement="top"><button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.editarUnidad(perfil)}><EditICon /></button></Tooltip> : null,
                                permisos.includes('D') ? <Tooltip title={'Desactivar'} placement="top"><button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.eliminarUnidad(perfil.id)}><DeleteIcon /></button></Tooltip> : null
                            ]
                                :
                                [permisos.includes('U') ? <Tooltip title={'Editar'} placement="top"><button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.editarUnidad(perfil)}><EditICon /></button></Tooltip> : null,
                                permisos.includes('U') ? <Tooltip title={'Activar'} placement="top"><button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.activarUnidad(perfil.id)}><Activar /></button></Tooltip> : <h5>No puede realizar acciones</h5>]
                        })
                    })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }

                this.setState({ perfilesbd: perf })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }


    render() {
        //Permisos usuarios
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        console.log(permisos)
        var perfiles = {
            columns: [
                {
                    label: 'Descripcion',
                    field: 'descripcion',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Valor',
                    field: 'valor',
                    sort: 'asc',
                    width: 150
                }, {
                    label: 'Acciones',
                    field: 'acciones',
                    sort: 'asc',
                    width: 150
                }
            ],
            rows: this.state.perfilesbd
        }
        return (
            <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                <div className="row">
                    {this.state.ventanaCreacion ? <button className="btn btn-danger" onClick={this.crearProducto.bind(this)}>Cancelar</button> : permisos.includes('C') ? <button className="btn btn-success" onClick={this.crearProducto.bind(this)}>Crear nueva unidad</button> : null}
                </div>
                <div className="row" style={{ marginTop: '10px', zIndex: '999' }}>
                    {this.state.ventanaCreacion ?
                        <FormularioUnidades style={{ zIndex: '1000', height: '70vh' }} unidadActualizar={this.state.unidadEditar != null ? this.state.unidadEditar : null} />
                        :
                        null}
                </div>
                {this.state.ventanaCreacion ? null : <div className="row" style={{ marginTop: '10px', height: '100%' }}>
                    <div className="col col-md-12" style={{ padding: 0 }}>
                        <Paper style={{ padding: '5px' }}>

                        {permisos.includes('R') ? <MDBDataTable
                                //striped
                                className="col-md-12"
                                searchLabel="Buscar"
                                paginationLabel={["Anterior", "Siguente"]}
                                infoLabel={["Mostrando", "a", "de", "entradas"]}
                                entriesLabel="Filas a mostrar"
                                tbodyColor="primary-color"
                                theadColor="#FF8800"
                                exportToCSV={true}
                                striped
                                bordered
                                autoWidth={false}
                                responsive={true}
                                hover
                                data={perfiles}
                            /> : <h5>NO TIENE PERMISOS PARA VER LOS DATOS</h5>}
                        </Paper>
                    </div>
                </div>}
            </div >
        )
    }
}


export default Categorias