
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Server from '../../lib/server'
import store from '../../lib/store'
import Axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditICon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check'
import swal from 'sweetalert'
import { Button } from 'react-bootstrap'
import Typography from '@material-ui/core/Typography';
import Selects from 'react-select'
import DeleteIcon from '@material-ui/icons/Delete';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import ConfiguracionIcon from "@material-ui/icons/Settings"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment'
import "moment/locale/es";
import { isUndefined } from 'util';
import logoTerpel from '../../assets/img/logos/logo_terpel.png'
import logoDeuna from '../../assets/img/logos/logoo_r2_c2.png'
import linea1 from '../../assets/img/linea.png'
import linea2 from '../../assets/img/linea2.png'
var vistaUsuario = false
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
var colorDominio = store.getState().colorDominio
class MediosPago extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: new Date(),
            bodegasbd: [],
            productosbd: [],
            //Por proveedor
            productosporProveedor: [],
            productoporProveedorSeleccionado: {},
            productosTotalesProveedor: [],
            totalProductosProveedor: 0,
            totalImpuestoProductoProveedor: 0,
            costoP: '',
            cantidadP: '',
            totalCostoProveedor: 0,
            totalImpuestoProveedor: 0,
            subTotalCostoProveedor: 0,
            modoEditarP: false,
            //
            productoSeleccinado: {},
            bodegaSeleccionada: {},
            costoN: '',
            cantidadN: '',
            productostotal: [],
            totalCosto: 0,
            subTotal: 0,
            idEditar: 0,
            //DAtos a guardar
            descripcionN: '',
            minimoValor: '',
            valorn: '',
            modoEditar: false,
            nimpuesto: null,
            //Proveedores bd
            proveedoresbd: [],
            proveedorSeleccionado: {},
            movimientosbd: [],
            checkOrdenCompra: false,
            facturaN: '',
            movimientoSeleccionado: [],
            edicionProducto: false,
            cambbioInicio: false,
            impuestoTotal: 0,
            productosSegunAcuerdo: [],
            idSaldos: [],
            dialogCambiarCosto: false,
            costoAcambiar: 0,
            cambioCosto: false,
            entradaParcial: false,
            response: false,
            checkBonificacion: false,
            imprimir: false
        };
    }

    componentWillMount() {
        const usuario = store.getState().usuario
        //Movimientos
        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/movimiento/orden-de-compra-abierta/' + parseInt(usuario.empresas_id, 10), { headers: { Authorization: ntoken } })
            .then(responsee => {
                var prove = []
                if (responsee.status == 200) {
                    responsee.data.movimientos.map((mov) => {
                        if (mov.movimiento.operacion == 2 && (mov.movimiento.movimiento_estado == 51 || mov.movimiento.movimiento_estado == 52)) {
                            prove.push(mov)
                        }
                    })
                    this.setState({ movimientosbd: prove })
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        //Proveedores
        /* Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/persona/empresas/' + parseInt(usuario.empresas_id, 10) + ',' + padres + '/proveedores/', { headers: { Authorization: ntoken } })
             .then(responsee => {
                 var prove = []
                 //console.log(responsee.data.rows)
                 responsee.data.rows.map((proveedores) => {
                     prove.push({
                         id: proveedores.persona.id,
                         value: proveedores.proveedor.id,
                         label: proveedores.persona.nombres + " " + proveedores.persona.apellidos + " -- " + proveedores.persona.identificacion
                     })
 
                 })
                 //console.log(prove)
                 this.setState({ proveedoresbd: prove })
             })
             .catch(function (error) {
                 // handle error
                 console.error(error);
             })*/
        //bODEGAS
        Axios.get(Server.hostPrueba + Server.puertoSSLBodega + '/api/bodegas/empresa/' + usuario.empresas_id, { headers: { Authorization: ntoken } })
            .then(responsee => {
                var bdg = []
                responsee.data.map((bodega) => {
                    bdg.push({
                        value: bodega.bodega_id,
                        label: bodega.descripcion + " -- " + bodega.codigo
                    })
                })
                this.setState({ bodegasbd: bdg })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })

        //productos
        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/' + parseInt(usuario.empresas_id, 10), { headers: { Authorization: ntoken } })
            .then((res) => {
                if (res.data != null) {
                    var prod = []
                    console.log(res.data)
                    res.data.Productos.map((producto) => {
                        prod.push({
                            value: producto.id,
                            label: producto.descripcion + " -- " + producto.plu,
                            producto: producto.descripcion,
                            plu: producto.plu
                        })
                    })
                    this.setState({ productosbd: prod })
                }
            })
            .catch((err) => {
                console.log(err)
            })

        //Acuerdos
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/convenios/tiendas/' + parseInt(usuario.empresas_id, 10) + '/contratos/', { headers: { Authorization: ntoken } })
            .then(responsee => {
                var prove = []
                console.log(responsee)
                responsee.data.data.map((proveedores) => {
                    if (moment(new Date()).format("YYYY-MM-DD HH:mm:ss") > moment(new Date(proveedores.contrato.fecha_inicio)).format("YYYY-MM-DD HH:mm:ss") && moment(new Date()).format("YYYY-MM-DD HH:mm:ss") < moment(new Date(proveedores.contrato.fecha_fin)).format("YYYY-MM-DD HH:mm:ss")) {
                        prove.push({
                            id: proveedores.proveedores[0].id,
                            value: proveedores.contrato.id,
                            label: proveedores.contrato.descripcion + " -- " + proveedores.contrato.numero_contrato,
                            proveedor: proveedores.proveedores[0].nombres
                        })
                    } else {
                        console.log("Acuerdos no pasado ", proveedores)
                    }
                })
                this.setState({ proveedoresbd: prove })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    eliminarProducto = (e) => {
        var mproducto = this.state.productostotal
        var ntotal = this.state.totalCosto
        var nsubtotal = this.state.subTotal
        ntotal = ntotal - parseFloat(mproducto[e].subtotal)
        nsubtotal = nsubtotal - parseFloat(mproducto[e].totalProducto)
        mproducto.splice(e, 1)
        this.setState({ productostotal: mproducto, totalCosto: ntotal, subTotal: nsubtotal })
    }
    eliminarProductoP = (e) => {
        var mproducto = this.state.productosTotalesProveedor
        var ntotal = this.state.totalCostoProveedor
        var nsubtotal = this.state.subTotalCostoProveedor
        ntotal = ntotal - parseFloat(mproducto[e].subtotal)
        nsubtotal = nsubtotal - parseFloat(mproducto[e].totalProducto)
        mproducto.splice(e, 1)
        this.setState({ productosTotalesProveedor: mproducto, totalCostoProveedor: ntotal, subTotalCostoProveedor: nsubtotal })
    }

    editarProducto = (e) => {
        var mproducto = this.state.productostotal
        var productoEditar = mproducto[e]
        var ntotal = this.state.totalCosto
        var nsubtotal = this.state.subTotal
        console.log(productoEditar)
        //Calculo
        var costoProducto = parseFloat(productoEditar.costoN)
        var valorContenido = productoEditar.unidadContenidValor
        var costoNeto = parseFloat(productoEditar.cantidad) * parseFloat(valorContenido)
        costoNeto = costoNeto * parseFloat(costoProducto)
        console.log(costoNeto)
        //
        ntotal = ntotal - parseFloat(productoEditar.subtotal)
        nsubtotal = nsubtotal - parseFloat(productoEditar.totalProducto)
        mproducto.splice(e, 1)
        this.setState({
            totalCosto: ntotal,
            subTotal: nsubtotal,
            modoEditar: true,
            productoSeleccinado: {
                value: productoEditar.id,
                label: productoEditar.producto + " -- " + productoEditar.plu,
                producto: productoEditar.producto,
                plu: productoEditar.plu,
                cantidad: productoEditar.cantidad,
                costo: productoEditar.costo,
                subtotal: productoEditar.subtotal,
                unidadMEdidaDescripcion: productoEditar.unidadMEdidaDescripcion,
                unidadContenidValor: productoEditar.unidadContenidValor,
                costoN: productoEditar.costoN,
                impuestos: productoEditar.impuestos,
                totalProducto: productoEditar.totalProducto,
                cantidadAentrarEnBodega: productoEditar.cantidadAentrarEnBodega,
                impuestosPorProveedor: productoEditar.impuestosPorProveedor,
                costoFlexible: productoEditar.costoFlexible,
                costoMinimo: productoEditar.costoMinimo,
                costoMaximo: productoEditar.costoMaximo,
                tipo_precio: productoEditar.tipo_precio,
                cambio: productoEditar.cambio
            },
            cantidadN: productoEditar.cantidad,
            costoN: costoNeto,
            productostotal: mproducto
        })

    }
    editarProductoP = (e) => {
        var mproducto = this.state.productosTotalesProveedor
        var productoEditar = mproducto[e]
        var ntotal = this.state.totalCostoProveedor
        var nsubtotal = this.state.subTotalCostoProveedor
        //Calculo
        var costoProducto = parseFloat(productoEditar.costoN)
        var valorContenido = productoEditar.unidadContenidValor
        var costoNeto = parseFloat(productoEditar.cantidad) * parseFloat(valorContenido)
        costoNeto = costoNeto * parseFloat(costoProducto)
        console.log(costoNeto)
        //
        ntotal = ntotal - parseFloat(productoEditar.subtotal)
        nsubtotal = nsubtotal - parseFloat(productoEditar.totalProducto)
        mproducto.splice(e, 1)
        console.log(productoEditar)
        this.setState({
            totalCostoProveedor: ntotal,
            subTotalCostoProveedor: nsubtotal,
            modoEditarP: true,
            productoporProveedorSeleccionado: {
                value: productoEditar.id,
                label: productoEditar.producto + " -- " + productoEditar.plu,
                producto: productoEditar.producto,
                plu: productoEditar.plu,
                cantidad: productoEditar.cantidad,
                costo: productoEditar.costo,
                subtotal: productoEditar.subtotal,
                unidadMEdidaDescripcion: productoEditar.unidadMEdidaDescripcion,
                unidadContenidValor: productoEditar.unidadContenidValor,
                costoN: productoEditar.costoN,
                impuestos: productoEditar.impuestos,
                totalProducto: productoEditar.totalProducto,
                cantidadAentrarEnBodega: productoEditar.cantidadAentrarEnBodega,
                impuestosPorProveedor: productoEditar.impuestosPorProveedor
            },
            cantidadP: productoEditar.cantidad,
            costoP: costoNeto,
            productosTotalesProveedor: mproducto
        })

    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
        switch (name) {
            case 'cantidadN':
                var cantidad = event.target.value
                var costoProducto = parseFloat(this.state.productoSeleccinado.costoN)
                var valorContenido = this.state.productoSeleccinado.unidadContenidValor
                var costoNeto = parseFloat(cantidad) * parseFloat(valorContenido)
                costoNeto = costoNeto * parseFloat(costoProducto)
                console.log(costoNeto)
                /*this.state.productoSeleccinado.impuestos.map((impuesto) => {
                    if (impuesto.porcentaje_valor === "%") {
                        costoNeto = parseFloat(costoNeto) + ((parseFloat(costoNeto) * parseFloat(impuesto.valor)) / 100)
                    }
                })
                this.state.productoSeleccinado.impuestos.map((impuesto) => {
                    if (impuesto.porcentaje_valor === "$") {
                        costoNeto = parseFloat(costoNeto) + parseFloat(impuesto.valor)
                    }
                })*/
                this.setState({ costoN: costoNeto })
                break
            case 'cantidadP':
                var cantidad = event.target.value
                console.log(this.state.productoporProveedorSeleccionado)
                var costoProducto = parseFloat(this.state.productoporProveedorSeleccionado.costoN)
                var valorContenido = this.state.productoporProveedorSeleccionado.unidadContenidValor
                var costoNeto = parseFloat(cantidad) * parseFloat(valorContenido)
                console.log(costoNeto)
                costoNeto = costoNeto * parseFloat(costoProducto)
                console.log(costoNeto)
                /*this.state.productoSeleccinado.impuestos.map((impuesto) => {
                    if (impuesto.porcentaje_valor === "%") {
                        costoNeto = parseFloat(costoNeto) + ((parseFloat(costoNeto) * parseFloat(impuesto.valor)) / 100)
                    }
                })
                this.state.productoSeleccinado.impuestos.map((impuesto) => {
                    if (impuesto.porcentaje_valor === "$") {
                        costoNeto = parseFloat(costoNeto) + parseFloat(impuesto.valor)
                    }
                })*/
                this.setState({ costoP: costoNeto })
                break
        }

    };


    changeSelect = name => event => {
        var estado = (event.target.value == 'false' ? false : true)
        this.setState({
            [name]: !estado
        });
        switch (name) {
            case 'checkOrdenCompra':
                this.setState({
                    productostotal: [],
                    cantidadN: '',
                    costoN: '',
                    productoSeleccinado: [],
                    subTotal: 0,
                    totalCosto: 0,
                    movimientoSeleccionado: []
                })
                break;
        }
    }

    cambiarCosto = () => {
        //FALTA EL CAMBIO DE COSTOOOO
        var producto = this.state.productoSeleccinado
        var costoNetoind = parseFloat(this.state.costoAcambiar)
        if (parseFloat(this.state.costoAcambiar) < parseFloat(this.state.productoSeleccinado.costoMinimo)) {
            swal("ERROR", "EL COSTO ES MENOR AL COSTO MINIMO CONFIGURADO", "warning")
        } else if (parseFloat(this.state.costoAcambiar) > parseFloat(this.state.productoSeleccinado.costoMaximo)) {
            swal("ERROR", "EL COSTO ES MAYOR AL COSTO MAXIMO CONFIGURADO", "warning")
        } else if (parseFloat(this.state.costoAcambiar) < 0) {
            swal("ERROR", "EL COSTO DEBE SER POSITIVO", "warning")
        } else {
            var subtotal = 0
            var totalProducto = 0
            var impuestos = 0
            var costo = 0
            costo = parseFloat(this.state.costoAcambiar) * parseInt(producto.unidadContenidValor)
            subtotal = parseInt(producto.cantidad) * (parseFloat(this.state.costoAcambiar) * parseInt(producto.unidadContenidValor))
            console.log(costo)
            producto.impuestosPorProveedor.map((impuesto) => {
                console.log(impuesto)
                if (impuesto.porcentaje_valor === "%") {
                    impuestos = impuestos + ((parseFloat(costo) * parseFloat(impuesto.valor)) / 100)
                }
                console.log(impuestos)
            })
            producto.impuestosPorProveedor.map((impuesto) => {
                if (impuesto.porcentaje_valor === "$") {
                    impuestos = parseFloat(impuestos) + ((parseFloat(producto.cantidad) * parseFloat(producto.unidadContenidValor)) * parseFloat(impuesto.valor))
                }
                console.log(impuestos)
            })
            impuestos = impuestos * producto.cantidad
            totalProducto = subtotal + impuestos
            producto = {
                value: producto.value,
                label: producto.producto + " -- " + producto.plu,
                producto: producto.producto,
                plu: producto.plu,
                cantidad: producto.cantidad,
                costo: costo,
                subtotal: subtotal,
                unidadMEdidaDescripcion: producto.unidadMEdidaDescripcion,
                unidadContenidValor: producto.unidadContenidValor,
                costoN: parseFloat(this.state.costoAcambiar),
                impuestos: impuestos,
                totalProducto: totalProducto,
                cantidadAentrarEnBodega: producto.cantidadAentrarEnBodega,
                impuestosPorProveedor: producto.impuestosPorProveedor,
                costoFlexible: producto.costoFlexible,
                costoMinimo: producto.costoMinimo,
                costoMaximo: producto.costoMaximo,
                tipo_precio: producto.tipo_precio,
                cambio: true
            }
            console.log(producto)
            this.setState({
                cambioCosto: true,
                productoSeleccinado: producto,
                dialogCambiarCosto: false,
                costoN: subtotal
            })
        }
    }

    cerrarCambioCosto = () => {
        this.setState({ dialogCambiarCosto: false })
    }
    abrirCambioCosto = () => {
        this.setState({ dialogCambiarCosto: true, costoAcambiar: this.state.productoSeleccinado.costoN })
    }

    agregarProducto = () => {
        console.log(this.state.productoSeleccinado)
        var p = this.state.productostotal
        var valorImpuesto = 0
        var costoNeto = this.state.costoN
        var costoProducto = parseFloat(this.state.productoSeleccinado.costoN)
        var costoNetoind = this.state.costoN
        var impuestosTotales = 0
        if (this.state.costoN.trim == '' || parseInt(this.state.costoN) == 0) {
            swal("ERROR", "DEBE INGRESAR EL COSTO Y SER MAYOR A CERO", "warning")
        } else if (parseInt(this.state.costoN) < 0) {
            swal("ERROR", "EL COSTO DEBE SER POSITIVA", "warning")
        } else if (this.state.cantidadN.trim == '' || parseInt(this.state.cantidadN) == 0) {
            swal("ERROR", "DEBE INGRESAR LA CANTIDAD Y SER MAYOR A CERO", "warning")
        } else if (parseInt(this.state.cantidadN) < 0) {
            swal("ERROR", "LA CANTIDAD DEBE SER POSITIVA", "warning")
        } else if (!/^([0-9])*$/.test(this.state.cantidadN)) {
            swal("ERROR", "LA CANTIDAD DEBE SER UN NUMERO ENTERO", "warning")
        } else {
            this.state.productoSeleccinado.impuestosPorProveedor.map((impuesto) => {
                if (impuesto.porcentaje_valor === "%") {
                    costoNeto = parseFloat(costoNeto) + ((parseFloat(costoNeto) * parseFloat(impuesto.valor)) / 100)
                    impuestosTotales = impuestosTotales + (parseFloat(costoNetoind) * parseFloat(impuesto.valor)) / 100
                }
                console.log(impuestosTotales)
            })
            this.state.productoSeleccinado.impuestosPorProveedor.map((impuesto) => {
                if (impuesto.porcentaje_valor === "$") {
                    costoNeto = parseFloat(costoNeto) + parseFloat(impuesto.valor)
                    impuestosTotales = parseFloat(impuestosTotales) + ((parseFloat(this.state.cantidadN) * parseFloat(this.state.productoSeleccinado.unidadContenidValor)) * parseFloat(impuesto.valor))
                }
                console.log(impuestosTotales)
            })
            p.push({
                id: this.state.productoSeleccinado.value,
                producto: this.state.productoSeleccinado.producto,
                cantidad: this.state.cantidadN,
                plu: this.state.productoSeleccinado.plu,
                costo: this.state.productoSeleccinado.costo,
                subtotal: parseFloat(this.state.costoN),
                unidadMEdidaDescripcion: this.state.productoSeleccinado.unidadMEdidaDescripcion,
                unidadContenidValor: this.state.productoSeleccinado.unidadContenidValor,
                costoN: this.state.productoSeleccinado.costoN,
                impuestosPorProveedor: this.state.productoSeleccinado.impuestosPorProveedor,
                cantidadAentrarEnBodega: this.state.cantidadN * this.state.productoSeleccinado.unidadContenidValor,
                impuestos: impuestosTotales,
                totalProducto: parseFloat(this.state.costoN) + parseFloat(impuestosTotales),
                costoFlexible: this.state.productoSeleccinado.costoFlexible,
                costoMinimo: this.state.productoSeleccinado.costoMinimo,
                costoMaximo: this.state.productoSeleccinado.costoMaximo,
                tipo_precio: this.state.productoSeleccinado.tipo_precio,
                cambio: this.state.productoSeleccinado.cambio
            })
            console.log(p)
            this.setState({ modoEditar: false, productostotal: p, totalCosto: this.state.totalCosto + parseFloat(this.state.costoN), subTotal: this.state.subTotal + parseFloat(this.state.costoN) + parseFloat(impuestosTotales), productoSeleccinado: {}, cantidadN: '', costoN: '' })
        }
    }
    validaID = (id) => {
        var res = false
        this.state.productosTotalesProveedor.map((p) => {
            if (parseInt(p.id) === parseInt(id)) {
                res = true
            }
        })
        return res
    }

    agregarProductoP = () => {
        var p = this.state.productosTotalesProveedor

        if (this.state.checkBonificacion) {


            var valorImpuesto = 0
            var costoNeto = 0
            var costoProducto = 0
            var costoNetoind = 0
            var impuestosTotales = 0

            if (this.state.cantidadP.trim() == '' || parseInt(this.state.cantidadP) == 0) {
                swal("ERROR", "DEBE INGRESAR LA CANTIDAD Y SER MAYOR A CERO", "warning")
            } else if (parseInt(this.state.cantidadP) < 0) {
                swal("ERROR", "LA CANTIDAD DEBE SER POSITIVA", "warning")
            } else if (!/^([0-9])*$/.test(this.state.cantidadP)) {
                swal("ERROR", "LA CANTIDAD DEBE SER UN NUMERO ENTERO", "warning")
            } else if (this.validaID(this.state.productoporProveedorSeleccionado.value)) {
                swal("ERROR", "Este producto ya se encuentra en la lista, si quiere modificarlo elimine el registro anterior.", "warning")
            } else {
                this.state.productoporProveedorSeleccionado.impuestosPorProveedor.map((impuesto) => {
                    if (impuesto.porcentaje_valor === "%") {
                        costoNeto = parseFloat(costoNeto) + ((parseFloat(costoNeto) * parseFloat(impuesto.valor)) / 100)
                        impuestosTotales = impuestosTotales + (parseFloat(costoNetoind) * parseFloat(impuesto.valor)) / 100
                    }
                    console.log(impuestosTotales)
                })
                this.state.productoporProveedorSeleccionado.impuestosPorProveedor.map((impuesto) => {
                    if (impuesto.porcentaje_valor === "$") {
                        costoNeto = parseFloat(costoNeto) + parseFloat(impuesto.valor)
                        impuestosTotales = parseFloat(impuestosTotales) + ((parseFloat(this.state.cantidadP) * parseFloat(this.state.productoporProveedorSeleccionado.unidadContenidValor)) * parseFloat(impuesto.valor))
                    }
                    console.log(impuestosTotales)
                })
                console.log(impuestosTotales)
                p.push({
                    id: this.state.productoporProveedorSeleccionado.value,
                    producto: this.state.productoporProveedorSeleccionado.producto,
                    cantidad: this.state.cantidadP,
                    plu: this.state.productoporProveedorSeleccionado.plu,
                    costo: 0,
                    subtotal: 0,
                    unidadMEdidaDescripcion: this.state.productoporProveedorSeleccionado.unidadMEdidaDescripcion,
                    unidadContenidValor: this.state.productoporProveedorSeleccionado.unidadContenidValor,
                    costoN: 0,
                    impuestosPorProveedor: this.state.productoporProveedorSeleccionado.impuestosPorProveedor,
                    cantidadAentrarEnBodega: this.state.cantidadP * this.state.productoporProveedorSeleccionado.unidadContenidValor,
                    impuestos: 0,
                    totalProducto: 0,
                })
                this.setState({ productosTotalesProveedor: p, totalCostoProveedor: this.state.totalCostoProveedor + 0, subTotalCostoProveedor: this.state.subTotalCostoProveedor + 0, productoporProveedorSeleccionado: {}, cantidadP: '', costoP: '', checkBonificacion: false })
            }

        } else {
            var valorImpuesto = 0
            var costoNeto = this.state.costoP
            var costoProducto = parseFloat(this.state.productoporProveedorSeleccionado.costoN)
            var costoNetoind = this.state.costoP
            var impuestosTotales = 0

            if (this.state.cantidadP.trim() == '' || parseInt(this.state.cantidadP) == 0) {
                swal("ERROR", "DEBE INGRESAR LA CANTIDAD Y SER MAYOR A CERO", "warning")
            } else if (parseInt(this.state.cantidadP) < 0) {
                swal("ERROR", "LA CANTIDAD DEBE SER POSITIVA", "warning")
            } else if (!/^([0-9])*$/.test(this.state.cantidadP)) {
                swal("ERROR", "LA CANTIDAD DEBE SER UN NUMERO ENTERO", "warning")
            } else if (this.validaID(this.state.productoporProveedorSeleccionado.value)) {
                swal("ERROR", "Este producto ya se encuentra en la lista, si quiere modificarlo elimine el registro anterior.", "warning")
            } else {
                this.state.productoporProveedorSeleccionado.impuestosPorProveedor.map((impuesto) => {
                    if (impuesto.porcentaje_valor === "%") {
                        costoNeto = parseFloat(costoNeto) + ((parseFloat(costoNeto) * parseFloat(impuesto.valor)) / 100)
                        impuestosTotales = impuestosTotales + (parseFloat(costoNetoind) * parseFloat(impuesto.valor)) / 100
                    }
                    console.log(impuestosTotales)
                })
                this.state.productoporProveedorSeleccionado.impuestosPorProveedor.map((impuesto) => {
                    if (impuesto.porcentaje_valor === "$") {
                        costoNeto = parseFloat(costoNeto) + parseFloat(impuesto.valor)
                        impuestosTotales = parseFloat(impuestosTotales) + ((parseFloat(this.state.cantidadP) * parseFloat(this.state.productoporProveedorSeleccionado.unidadContenidValor)) * parseFloat(impuesto.valor))
                    }
                    console.log(impuestosTotales)
                })
                console.log(impuestosTotales)
                p.push({
                    id: this.state.productoporProveedorSeleccionado.value,
                    producto: this.state.productoporProveedorSeleccionado.producto,
                    cantidad: this.state.cantidadP,
                    plu: this.state.productoporProveedorSeleccionado.plu,
                    costo: parseFloat(this.state.productoporProveedorSeleccionado.unidadContenidValor) * parseFloat(this.state.productoporProveedorSeleccionado.costoN),
                    subtotal: parseFloat(this.state.costoP),
                    unidadMEdidaDescripcion: this.state.productoporProveedorSeleccionado.unidadMEdidaDescripcion,
                    unidadContenidValor: this.state.productoporProveedorSeleccionado.unidadContenidValor,
                    costoN: this.state.productoporProveedorSeleccionado.costoN,
                    impuestosPorProveedor: this.state.productoporProveedorSeleccionado.impuestosPorProveedor,
                    cantidadAentrarEnBodega: this.state.cantidadP * this.state.productoporProveedorSeleccionado.unidadContenidValor,
                    impuestos: impuestosTotales,
                    totalProducto: parseFloat(this.state.costoP) + parseFloat(impuestosTotales),
                })
                console.log(p)
                this.setState({ productosTotalesProveedor: p, totalCostoProveedor: this.state.totalCostoProveedor + parseFloat(this.state.costoP), subTotalCostoProveedor: this.state.subTotalCostoProveedor + parseFloat(this.state.costoP) + parseFloat(impuestosTotales), productoporProveedorSeleccionado: {}, cantidadP: '', costoP: '' })
            }
        }
    }


    validarFormulario = () => {
        if (this.state.checkOrdenCompra) {
            if (this.state.movimientoSeleccionado.length == 0) {
                swal("FALLO AL CREAR", "Es necesario escoger la orden de compra", "warning")
                return false
            } else if (this.state.facturaN.trim === "" || this.state.facturaN.length === 0) {
                swal("FALLO AL CREAR", "El numero de factura necesario", "warning")
                return false
            } else if (JSON.stringify(this.state.bodegaSeleccionada) == '{}') {
                swal("FALLO AL CREAR", "Se debe seleccionar la bodega", "warning")
                return false
            } else if (this.state.productostotal == 0) {
                swal("FALLO AL CREAR", "Debe agregar por lo menus un producto", "warning")
                return false
            } else if (this.state.modoEditar) {
                swal("FALLO AL CREAR", "No puedes guardar sin terminar la edicion del prodcuto", "warning")
                return false
            } else {
                this.setState({
                    response: true
                })
                return true
            }
        } else {
            if (this.state.facturaN.trim === "" || this.state.facturaN.length === 0) {
                swal("FALLO AL CREAR", "El numero de factura necesario", "warning")
                return false
            } else if (JSON.stringify(this.state.bodegaSeleccionada) == '{}') {
                swal("FALLO AL CREAR", "Se debe seleccionar la bodega", "warning")
                return false
            } else if (JSON.stringify(this.state.proveedorSeleccionado) == '{}') {
                swal("FALLO AL CREAR", "Debe seleccionar el proveedor", "warning")
                return false
            } else if (this.state.productosTotalesProveedor == 0) {
                swal("FALLO AL CREAR", "Debe agregar por lo menus un producto", "warning")
                return false
            } else {
                this.setState({
                    response: true
                })
                return true
            }
        }
    }

    componentDidMount() {
        this.intervalId = setInterval(this.timer.bind(this), 300000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    timer() {
        this.setState({
            currentCount: this.state.currentCount - 1
        })
        if (this.state.currentCount < 1) {
            clearInterval(this.intervalId);
        }
        this.actualizarToken()
    }

    actualizarToken = () => {
        Axios.get(Server.hostPruebasinssl + Server.puertoAutenticacion, { headers: { Authorization: ntoken } })
            .then(response => {
                if (response.status === 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 206) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 200) {
                    //RESPEUSTA OK
                }
            })
    }


    registrar = () => {
        var usuario = store.getState().usuario
        var validado = this.validarFormulario()
        const entradaOC = document.getElementById('capture-oc');
        const entradaED = document.getElementById('capture-ed');
        if (validado) {
            this.setState({ imprimir: true })
            var datos = {
                "movimiento": {
                    "empresas_id": parseInt(usuario.empresas_id),
                    "operacion": 3,
                    "fecha": this.state.cambbioInicio ? moment(new Date(this.state.selectedDate.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    "consecutivo": this.state.checkOrdenCompra ? 0 : this.state.facturaN,
                    "persona_id": usuario.p_id,
                    "persona_nit": "",
                    "persona_nombre": "",
                    "tercero_id": this.state.checkOrdenCompra ? this.state.movimientoSeleccionado.movimiento.tercero_id : this.state.proveedorSeleccionado.id,
                    "tercero_nit": "",
                    "tercero_nombre": "",
                    "costo_total": this.state.checkOrdenCompra ? this.state.subTotal : this.state.subTotalCostoProveedor,
                    "venta_total": 0,
                    "impuesto_total": 0,
                    "descuento_total": 0,
                    "origen_id": this.state.checkOrdenCompra ? this.state.movimientoSeleccionado.movimiento.id : 0,
                    "impreso": "N",
                    "create_user": usuario.p_id,
                    "create_date": "",
                    "remoto_id": 0,
                    "sincronizado": 0,
                    "movimiento_estado": this.state.checkOrdenCompra ? 54 : 0,
                    "observaciones": this.state.checkOrdenCompra ? entradaOC.innerHTML : entradaED.innerHTML
                },
                "anexos": this.state.facturaN === "" ? null : {
                    "anexos_tipos_id": 1,
                    "referencia": this.state.facturaN
                },
                "bodega": {
                    "bodega_idI": this.state.bodegaSeleccionada.value,
                    "bodega_idF": null
                },
                "detalle": this.state.checkOrdenCompra ?
                    this.state.productostotal.map((producto) => {
                        return ({
                            "productos_id": producto.id,
                            "cantidad": producto.cantidadAentrarEnBodega,
                            "costo_producto": producto.costoN,
                            "precio": 0,
                            "descuento_id": 0,
                            "descuento_producto": 0,
                            "remoto_id": 0,
                            "sincronizado": 0,
                            "subtotal": 0,
                            "impuestos":
                                producto.impuestosPorProveedor.map((impuesto) => {
                                    return ({
                                        "impuestos_id": impuesto.id,
                                        "valor_imp": impuesto.valor
                                    })

                                })
                        })
                    })
                    :
                    this.state.productosTotalesProveedor.map((producto) => {
                        return ({
                            "productos_id": producto.id,
                            "cantidad": producto.cantidadAentrarEnBodega,
                            "costo_producto": producto.costoN,
                            "precio": 0,
                            "descuento_id": 0,
                            "descuento_producto": 0,
                            "remoto_id": 0,
                            "sincronizado": 0,
                            "subtotal": 0,
                            "impuestos": producto.impuestosPorProveedor.map((impuesto) => {
                                return ({
                                    "impuestos_id": impuesto.id,
                                    "valor_imp": impuesto.valor
                                })
                            })
                        })
                    })


            }
            Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/movimiento', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    console.log(response)
                    this.setState({
                        response: false
                    })
                    if (response.status === 201) {
                        swal({
                            title: response.data.message,
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "AJUSTE_INICIAL",
                                        ajusteInicial: false
                                    })
                                    //this.setState({loader:true,descripcionN:'',selectedDate:new Date(),selectedDateFin:new Date(),checkIPorcentaje:true,checkIValor:false,valorn:false,cambbioInicio: false,cambbioFin:false})
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                    }
                })
        }
    }

    obtenerDetallesMovimientos = (movimiento_id) => {
        //console.log(this.state.movimientosbd[movimiento_id])
        var ordenDeCompraSeleccionada = this.state.movimientosbd[movimiento_id]
        var total = 0
        var totalSaldo = 0
        var movimientos = []
        var movimientosSaldo = []
        var bodega = {}
        var impuestos = 0
        var acuerdoID = ordenDeCompraSeleccionada.movimiento.origen_id
        var estado = ordenDeCompraSeleccionada.movimiento.movimiento_estado
        var idMovimiento = ordenDeCompraSeleccionada.movimiento.id
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/convenios/detalles/' + acuerdoID, { headers: { Authorization: ntoken } })
            .then(response => {
                if (response.status == 200 && response.data.data != null) {
                    var prod = []
                    if (this.state.movimientosbd[movimiento_id].detalle != null) {
                        this.state.movimientosbd[movimiento_id].detalle.map((movimiento) => {
                            if (response.data.data.productos_empresas != null) {
                                response.data.data.productos_empresas.map((producto) => {
                                    if (producto.producto.id == movimiento.productos_id) {
                                        var impuestosTotales = 0
                                        var costoNetoind = movimiento.costo_producto
                                        if (producto.producto.impuestos_proveedor != null) {
                                            producto.producto.impuestos_proveedor.map((impuesto) => {
                                                if (impuesto.porcentaje_valor === "%") {
                                                    impuestosTotales = impuestosTotales + (((parseFloat(costoNetoind) * parseFloat(impuesto.valor)) / 100) * parseFloat(movimiento.cantidad))
                                                }
                                            })
                                            producto.producto.impuestos_proveedor.map((impuesto) => {
                                                if (impuesto.porcentaje_valor === "$") {
                                                    impuestosTotales = impuestosTotales + ((parseFloat(movimiento.cantidad) * parseFloat(producto.producto.unidades_contenido_desc.valor)) * impuesto.valor)

                                                }
                                            })
                                        }
                                        movimientos.push({
                                            id: movimiento.productos_id,
                                            producto: movimiento.descripcion,
                                            plu: movimiento.plu,
                                            cantidad: movimiento.cantidad,
                                            costo: movimiento.costo_producto,
                                            subtotal: parseFloat(movimiento.costo_producto * movimiento.cantidad),
                                            unidadMEdidaDescripcion: producto.producto.unidades_medida_desc.descripcion,
                                            unidadContenidValor: producto.producto.unidades_contenido_desc.valor,
                                            costoN: producto.contratos_producto.precio_base,
                                            impuestos: impuestosTotales,
                                            totalProducto: parseFloat(movimiento.costo_producto * movimiento.cantidad) + impuestosTotales,
                                            cantidadAentrarEnBodega: movimiento.cantidad * producto.producto.unidades_contenido_desc.valor,
                                            impuestosPorProveedor: producto.producto.impuestos_proveedor != null ? producto.producto.impuestos_proveedor : [],
                                            costoFlexible: producto.contratos_producto.precio_flexible,
                                            costoMinimo: producto.contratos_producto.tipo_precio == "$" ? parseInt(producto.contratos_producto.precio_minimo) : parseInt(parseInt(producto.contratos_producto.precio_base) - ((parseInt(producto.contratos_producto.precio_base) * parseFloat(producto.contratos_producto.precio_minimo)) / 100)),
                                            costoMaximo: producto.contratos_producto.tipo_precio == "$" ? parseInt(producto.contratos_producto.precio_maximo) : parseInt(parseInt(producto.contratos_producto.precio_base) + ((parseInt(producto.contratos_producto.precio_base) * parseFloat(producto.contratos_producto.precio_maximo)) / 100)),
                                            tipo_precio: producto.contratos_producto.tipo_precio,
                                            cambio: false

                                        })
                                        total = total + parseFloat(movimiento.costo_producto * movimiento.cantidad) + impuestosTotales
                                        bodega = {
                                            value: movimiento.bodegas_id,
                                            label: movimiento.bodega + " -- " + movimiento.codigo
                                        }
                                    }
                                })
                                //console.log(movimiento)
                            }

                        })
                        if (estado == 52) {
                            Axios.get(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/estados/movimiento/' + idMovimiento + '/saldo', { headers: { Authorization: ntoken } })
                                .then((res) => {
                                    if (res.data.saldo_orden_compra != null) {
                                        var prod = []
                                        var saldosId = []
                                        total = 0
                                        res.data.saldo_orden_compra.map((saldo) => {
                                            saldosId.push(parseInt(saldo.productos_id))
                                            movimientos.map((producto, key) => {
                                                if (parseInt(saldo.productos_id) == parseInt(producto.id)) {
                                                    var impuestosParciales = 0
                                                    var costoNetoind = producto.costo
                                                    if (producto.impuestosPorProveedor != null) {
                                                        producto.impuestosPorProveedor.map((impuesto) => {
                                                            if (impuesto.porcentaje_valor === "%") {
                                                                impuestosParciales = impuestosParciales + (((parseFloat(costoNetoind) * parseFloat(impuesto.valor)) / 100) * parseFloat(saldo.cantidad))
                                                            }
                                                        })
                                                        producto.impuestosPorProveedor.map((impuesto) => {
                                                            if (impuesto.porcentaje_valor === "$") {
                                                                impuestosParciales = impuestosParciales + ((parseFloat(saldo.cantidad) * parseFloat(producto.unidadContenidValor)) * impuesto.valor)

                                                            }
                                                        })
                                                    }
                                                    movimientosSaldo.push({
                                                        id: producto.id,
                                                        producto: producto.producto,
                                                        plu: producto.plu,
                                                        cantidad: parseFloat(saldo.cantidad),
                                                        costo: producto.costo,
                                                        subtotal: parseFloat(saldo.cantidad) * producto.costo,
                                                        unidadMEdidaDescripcion: producto.unidadMEdidaDescripcion,
                                                        unidadContenidValor: producto.unidadContenidValor,
                                                        costoN: producto.costoN,
                                                        impuestos: impuestosParciales,
                                                        totalProducto: parseFloat(parseFloat(saldo.cantidad) * producto.costo) + impuestosParciales,
                                                        cantidadAentrarEnBodega: parseFloat(saldo.cantidad) * producto.unidadContenidValor,
                                                        impuestosPorProveedor: producto.impuestosPorProveedor,


                                                    })
                                                    movimientos[key].cantidad = parseFloat(saldo.cantidad)
                                                    movimientos[key].cantidadAentrarEnBodega = parseFloat(saldo.cantidad) * producto.unidadContenidValor
                                                    movimientos[key].subtotal = parseFloat(saldo.cantidad) * producto.costo
                                                    movimientos[key].impuestos = impuestosParciales
                                                    movimientos[key].totalProducto = parseFloat(parseFloat(saldo.cantidad) * producto.costo) + impuestosParciales
                                                    total = total + parseFloat(producto.costo * parseFloat(saldo.cantidad)) + impuestosParciales
                                                }
                                            })
                                        })
                                        /*movimientos.map((producto, key) => {
                                            if (!this.state.idSaldos.includes(parseInt(producto.id))) {
                                                movimientos.splice(key, 1)
                                            }
                                        })
                                        total = 0
                                        movimientos.map((producto, key) => {
                                                total = total + producto.totalProducto
                                            
                                        })*/
                                        this.setState({ productosporProveedor: prod, subTotal: total, entradaParcial: true })
                                    }
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                        if (estado == 52) {
                            this.setState({ bodegaSeleccionada: bodega, movimientoSeleccionado: this.state.movimientosbd[movimiento_id], productostotal: movimientosSaldo })
                        } else {
                            this.setState({ bodegaSeleccionada: bodega, movimientoSeleccionado: this.state.movimientosbd[movimiento_id], productostotal: movimientos, subTotal: total })
                        }
                    }
                }
            })
        console.log(acuerdoID)

    }


    cancelar = () => {
        store.dispatch({
            type: "AJUSTE_INICIAL",
            ajusteInicial: false
        })
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        switch (name) {
            case 'proveedorSeleccionado':
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/convenios/detalles/' + value.value, { headers: { Authorization: ntoken } })
                    .then(response => {
                        if (response.status == 200 && response.data.data != null) {
                            var prod = []
                            if (response.data.data.productos_empresas != null) {
                                response.data.data.productos_empresas.map((producto) => {
                                    console.log(producto)
                                    prod.push({
                                        value: producto.producto.id,
                                        label: producto.producto.descripcion + " -- " + producto.producto.plu,
                                        producto: producto.producto.descripcion,
                                        plu: producto.producto.plu,
                                        costo: producto.contratos_producto.precio_base,
                                        unidadMEdidaDescripcion: producto.producto.unidades_medida_desc.descripcion,
                                        unidadContenidValor: producto.producto.unidades_contenido_desc.valor,
                                        unidadBasica: producto.producto.unidad_basica_desc,
                                        costoN: producto.contratos_producto.precio_base,
                                        impuestosPorProveedor: producto.producto.impuestos_proveedor != null ? producto.producto.impuestos_proveedor : [],

                                    })


                                })
                            }
                            this.setState({ productosporProveedor: prod, productosTotalesProveedor: [], subTotalCostoProveedor: 0, totalCosto: 0 })
                        }
                    })
                break
            /*case 'proveedorSeleccionado':
                Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/proveedores/' + value.value, { headers: { Authorization: ntoken } })
                    .then((res) => {
                        if (res.data != null) {
                            var prod = []
                            console.log(res.data)
                            res.data.data.map((producto) => {
                                prod.push({
                                    value: producto.id,
                                    label: producto.descripcion + " -- " + producto.plu,
                                    producto: producto.descripcion,
                                    plu: producto.plu,
                                    costo: producto.costo,
                                    unidadMEdidaDescripcion: producto.unidades_medida_desc.descripcion,
                                    unidadContenidValor: producto.unidades_contenido_desc.valor,
                                    costoN: producto.costo,
                                    impuestosPorProveedor: producto.impuestos != null ? producto.impuestos : []
                                })
                            })
                            console.log(prod)
                            this.setState({ productosporProveedor: prod, productosTotalesProveedor: [], subTotalCostoProveedor: 0, totalCosto: 0 })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                break*/
        }
    };
    handleChangemoment = date => {
        this.setState({ selectedDate: date, cambbioInicio: true });
    }

    setearMiles = (numero) => {
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case "COP":
                return (
                    (new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(2)))
                )
                break;
            case "USD":
                return (new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(numero))
                break;

        }
    }


    render() {
        var { movimientoSeleccionado } = this.state
        var sumaTotal = parseFloat(this.state.costoN)
        var sumaTotalP = parseFloat(this.state.costoP)
        var usuario = store.getState().usuario
        //para tabla
        var subtotalPDF = 0
        var impuestosPDF = 0
        var totalPDF = 0
        return (
            <div>
                <AppBar style={{ backgroundColor: colorDominio }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Close"
                            onClick={this.cancelar.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            style={{ marginLeft: "theme.spacing(2)", flex: 1, color: '#fff' }}
                        >
                            {vistaUsuario ? 'Edicion de PRODUCTO' : 'ENTRADA DIRECTA'}
                        </Typography>
                        {
                            vistaUsuario
                                ?
                                <Button disabled={this.state.response} style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} /*onClick={this.editarProducto.bind(this)}*/>
                                    GUARDAR
                                </Button>
                                :
                                <Button disabled={this.state.response} style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} onClick={this.registrar.bind(this)}>
                                    TERMINAR Y GUARDAR
                                </Button>
                        }

                    </Toolbar>
                </AppBar>
                <div className="row align-middle mx-auto" style={{ marginTop: '7%' }}>
                    <div className="col col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="numordencompra" className="col-form-label">Fecha</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                    <DatePicker
                                        format="yyyy-MM-dd HH:mm"
                                        id="datetime"
                                        value={this.state.selectedDate}
                                        onChange={this.handleChangemoment}
                                        className="rounded form-control"
                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="numordencompra" className="col-form-label">Bodega</label>
                                <Selects
                                    isDisabled={this.state.checkOrdenCompra}
                                    placeholder="Seleccione"
                                    value={this.state.bodegaSeleccionada}
                                    onChange={this.handleChanges('bodegaSeleccionada')}
                                    options={this.state.bodegasbd}
                                />
                            </div>
                        </div>
                        <div className="row mx-auto" style={{ marginTop: '10px' }}>
                            <div className="col-md-3">
                                <FormControlLabel
                                    control={<Checkbox value={this.state.checkOrdenCompra} checked={this.state.checkOrdenCompra} style={{ padding: '5px' }} onChange={this.changeSelect('checkOrdenCompra')} />}
                                    label='Entrada por orden de compra'
                                    style={{ margin: '0' }}
                                />
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">Numero de la factura</label>
                                <input id="numordencompra" value={this.state.facturaN} autoComplete="off" className="form-control" type="number" min="1" onChange={this.handleChange('facturaN')} />
                            </div>
                            {this.state.checkOrdenCompra ? null :
                                <div className="col-md-4">
                                    <label htmlFor="numordencompra" className="col-form-label">Acuerdo</label>
                                    <Selects
                                        placeholder="Seleccione"
                                        value={this.state.proveedorSeleccionado}
                                        onChange={this.handleChanges('proveedorSeleccionado')}
                                        options={this.state.proveedoresbd}
                                    />
                                </div>}
                        </div>
                        {!this.state.checkOrdenCompra ? null :
                            <div className="row">
                                <div className="col col-md-12">
                                    <div className="row mx-auto">
                                        <h5 className="mx-auto">Escoja la orden de compra</h5>
                                    </div>
                                    <div className="row mx-auto">
                                        <div className="col col-md-10 mx-auto">
                                            <MDBTable bordered striped>
                                                <MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
                                                    <tr>
                                                        <th>Consecutivo</th>
                                                        <th>Proveedor</th>
                                                        <th >Fecha</th>
                                                        <th >Costo total</th>
                                                        <th >Entrada</th>
                                                        <th >Acciones</th>
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {movimientoSeleccionado.length == 0
                                                        ?
                                                        this.state.movimientosbd.map((row, key) => (
                                                            <tr key={key}>
                                                                <td component="th" scope="row">
                                                                    {row.movimiento.consecutivo}
                                                                </td>
                                                                <td>{row.movimiento.tercero_id_detalles != null ? row.movimiento.tercero_id_detalles.nombres + " " + row.movimiento.tercero_id_detalles.apellidos : 'No aplica'}</td>
                                                                <td>{row.movimiento.fecha}</td>
                                                                <td>{'$ ' + this.setearMiles(row.movimiento.costo_total)}</td>
                                                                <td>{row.movimiento.movimiento_estado == 51 ? 'No tiene' : 'Parcial'}</td>
                                                                <td>{
                                                                    <button className="btn btn-sm btn-success" style={{ marginLeft: '5px' }} onClick={() => this.obtenerDetallesMovimientos(key)}><CheckIcon /></button>

                                                                }</td>
                                                            </tr>
                                                        ))
                                                        :
                                                        <tr >
                                                            <td component="th" scope="row">
                                                                {movimientoSeleccionado.movimiento.consecutivo}
                                                            </td>
                                                            <td>{movimientoSeleccionado.movimiento.tercero_id_detalles != null ? movimientoSeleccionado.movimiento.tercero_id_detalles.nombres + " " + movimientoSeleccionado.movimiento.tercero_id_detalles.apellidos : 'No aplica'}</td>
                                                            <td>{movimientoSeleccionado.movimiento.fecha}</td>
                                                            <td>{'$ ' + this.setearMiles(movimientoSeleccionado.movimiento.costo_total)}</td>
                                                            <td>{movimientoSeleccionado.movimiento.movimiento_estado == 51 ? 'No tiene' : 'Parcial'}</td>
                                                            <td>{


                                                            }</td>
                                                        </tr>
                                                    }
                                                </MDBTableBody>
                                            </MDBTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {!this.state.checkOrdenCompra
                            ?
                            <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                <div className="col-md-8">
                                    <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                        <h5 className="mx-auto">Ingrese los datos del producto a agregar</h5>
                                    </div>
                                    <div className="row bottom-aligned">
                                        <div className="col-md-4">
                                            <label htmlFor="numordencompra" className="col-form-label">PRODUCTO</label>
                                            <Selects
                                                placeholder="Seleccione"
                                                value={this.state.productoporProveedorSeleccionado}
                                                onChange={this.handleChanges('productoporProveedorSeleccionado')}
                                                options={this.state.productosporProveedor}
                                            />
                                        </div>
                                        <div className="col-md-3" >
                                            <label htmlFor="numordencompra" className="col-form-label">Cantidad</label>
                                            <input id="numordencompra" value={this.state.cantidadP} disabled={JSON.stringify(this.state.productoporProveedorSeleccionado) == '{}'} className="form-control" type="number" min="1" max={this.state.productoporProveedorSeleccionado.cantidad} onChange={this.handleChange('cantidadP')} />
                                        </div>
                                        <div className="col-md-3">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <FormControlLabel
                                                        control={<Checkbox value={this.state.checkBonificacion} checked={this.state.checkBonificacion} style={{ padding: '5px' }} onChange={this.changeSelect('checkBonificacion')} />}
                                                        label='Bonificacion'
                                                        style={{ margin: '0' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label htmlFor="numordencompra" className="col-form-label">Costo neto</label>
                                                    <div class="input-group" >
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1">$</span>
                                                        </div>
                                                        <input id="numordencompra" value={parseFloat(this.state.costoP).toFixed(3)} disabled={true} className="form-control" type="number" min="1" onChange={this.handleChange('costoP')} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bottom-aligned">
                                            <button disabled={JSON.stringify(this.state.productoporProveedorSeleccionado) == '{}' || this.state.cantidadP == ''} className="btn btn-primary bottom-aligned" onClick={() => this.agregarProductoP()}>AGREGAR</button>
                                        </div>
                                    </div>
                                    <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                        <h5 className="mx-auto">{this.state.productostotal.length == 0 ? 'POR FAVOR CARGUE LOS PRODUCTOS' : 'PRODUCTOS A GUARDAR'}</h5>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                        <h5 className="mx-auto">CALCULO DEL PRODUCTO</h5>
                                    </div>

                                    <div className="row mx-auto" >
                                        <div className="col-md-12">
                                            <h5 className="mx-auto">{this.state.productoporProveedorSeleccionado.label}</h5>
                                        </div>
                                    </div>
                                    {this.state.productoporProveedorSeleccionado.unidadContenidValor != null
                                        ?
                                        <div className="row mx-auto" >
                                            <div className="col-md-8">
                                                <h6 className="mx-auto">EMBALAJE</h6>
                                            </div>
                                            <div className="col-md-4">
                                                <h6 className="mx-auto">{this.state.productoporProveedorSeleccionado.unidadContenidValor + " " + this.state.productoporProveedorSeleccionado.unidadBasica}</h6>
                                            </div>
                                        </div> : null}
                                    {this.state.costoP != '' || this.state.costoP.length != 0
                                        ?
                                        <div className="row mx-auto" >
                                            <div className="col-md-8">
                                                <h6 className="mx-auto">COSTO</h6>
                                            </div>
                                            <div className="col-md-4">
                                                <h6 className="mx-auto">{'$' + this.setearMiles(this.state.costoP)}</h6>
                                            </div>
                                        </div> : null}
                                    {
                                        this.state.productoporProveedorSeleccionado.impuestosPorProveedor != null && (this.state.costoP != '' || this.state.costoP.length != 0)
                                            ?
                                            this.state.productoporProveedorSeleccionado.impuestosPorProveedor.map((impuesto) => {
                                                if (impuesto.porcentaje_valor == "%") {
                                                    sumaTotalP = sumaTotalP + ((parseFloat(this.state.costoP) * parseFloat(impuesto.valor)) / 100)
                                                    return (
                                                        <div className="row mx-auto" >
                                                            <div className="col-md-8">
                                                                <h6 className="mx-auto">{impuesto.descripcion}</h6>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <h6 className="mx-auto">{'$' + this.setearMiles(((parseFloat(this.state.costoP) * parseFloat(impuesto.valor)) / 100))}</h6>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            })
                                            :
                                            null
                                    }
                                    {
                                        this.state.productoporProveedorSeleccionado.impuestosPorProveedor != null && (this.state.productoporProveedorSeleccionado.costoN != '' || this.state.productoporProveedorSeleccionado.costoN.length != 0 || isNaN(this.state.cantidadP))
                                            ?
                                            this.state.productoporProveedorSeleccionado.impuestosPorProveedor.map((impuesto) => {
                                                if (impuesto.porcentaje_valor == "$") {
                                                    sumaTotalP = sumaTotalP + ((parseFloat(this.state.cantidadP) * parseFloat(this.state.productoporProveedorSeleccionado.unidadContenidValor)) * parseFloat(impuesto.valor))
                                                    return (
                                                        <div className="row mx-auto" >
                                                            <div className="col-md-8">
                                                                <h6 className="mx-auto">{impuesto.descripcion}</h6>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <h6 className="mx-auto">{'$' + this.setearMiles(((parseFloat(this.state.cantidadP) * parseFloat(this.state.productoporProveedorSeleccionado.unidadContenidValor)) * parseFloat(impuesto.valor)))}</h6>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            })
                                            :
                                            null
                                    }
                                    {this.state.costoP != '' || this.state.costoP.length != 0 || isNaN(this.state.cantidadP)
                                        ?
                                        <div className="row mx-auto" >
                                            <div className="col-md-8">
                                                <h6 className="mx-auto">COSTO TOTAL</h6>
                                            </div>
                                            <div className="col-md-4">
                                                <h6 className="mx-auto">{'$' + this.setearMiles(sumaTotalP)}</h6>
                                            </div>
                                        </div> : null}

                                </div>
                            </div>
                            :
                            null
                        }
                        {
                            this.state.modoEditar
                                ?
                                <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                    <Dialog
                                        open={this.state.dialogCambiarCosto}
                                        onClose={this.cerrarCambioCosto}
                                        aria-labelledby="form-dialog-title"
                                        maxWidth={'md'}
                                        fullWidth={true}
                                        disableBackdropClick={true}
                                    >
                                        <DialogTitle id="form-dialog-title">
                                            <div className="row mx-auto">CONFIGURACION DEL COSTO DE {this.state.productoSeleccinado.label}</div>
                                        </DialogTitle>
                                        <DialogContent >
                                            <div className="row mx-auto">
                                                <div className="col col-md-4">
                                                    <label htmlFor="numordencompra" className="col-form-label">COSTO MINIMO</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={"$" + this.setearMiles(this.state.productoSeleccinado.costoMinimo)} />
                                                </div>
                                                <div className="col col-md-4">
                                                    <label htmlFor="numordencompra" className="col-form-label">COSTO ACTUAL</label>
                                                    <input id="numordencompra" className="form-control" type="text" value={this.state.costoAcambiar} autoComplete="off" onChange={this.handleChange('costoAcambiar')} />
                                                </div>
                                                <div className="col col-md-4">
                                                    <label htmlFor="numordencompra" className="col-form-label">COSTO MAXIMO</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={"$" + this.setearMiles(this.state.productoSeleccinado.costoMaximo)} />
                                                </div>
                                            </div>
                                        </DialogContent>
                                        <DialogActions>
                                            <button className="btn btn-success" onClick={this.cambiarCosto.bind(this)}>ACTUALIZAR</button>
                                            <button className="btn btn-danger" onClick={this.cerrarCambioCosto.bind(this)}>CERRAR</button>
                                        </DialogActions>
                                    </Dialog>
                                    <div className="col-md-8">
                                        <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                            <h5 className="mx-auto">EDICION DE ENTRADA DE PRODUCTO</h5>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label htmlFor="numordencompra" className="col-form-label">PRODUCTO</label>
                                                <Selects
                                                    isDisabled={true}
                                                    placeholder="Seleccione"
                                                    value={this.state.productoSeleccinado}
                                                    onChange={this.handleChanges('productoSeleccinado')}
                                                    options={this.state.productosbd}
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <label htmlFor="numordencompra" className="col-form-label">Cantidad</label>
                                                <input id="numordencompra" disabled={this.state.productoSeleccinado.cambio || this.state.entradaParcial} value={this.state.cantidadN} className="form-control" type="number" min="1" max={this.state.productoSeleccinado.cantidad} onChange={this.handleChange('cantidadN')} />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="numordencompra" className="col-form-label">Costo neto</label>
                                                <div class="input-group" >
                                                    <Tooltip title={"COSTO UNITARIO: $" + this.state.productoSeleccinado.costoN + " -- Unidad de empaque: " + this.state.productoSeleccinado.unidadContenidValor} placement="top">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1"><InfoIcon /></span>
                                                        </div>
                                                    </Tooltip>
                                                    <input id="numordencompra" value={parseFloat(this.state.costoN).toFixed(3)} className="form-control" disabled={true} type="number" min="1" onChange={this.handleChange('costoN')} />
                                                    <div class="input-group-prepend">
                                                        <button className="btn btn-warning btn-sm" onClick={() => this.abrirCambioCosto()} disabled={this.state.productoSeleccinado.costoFlexible == "N" || this.state.entradaParcial}><ConfiguracionIcon /></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bottom-aligned">
                                                <button className="btn btn-primary bottom-aligned" disabled={JSON.stringify(this.state.productoSeleccinado) == '{}' || this.state.cantidadN == ''} onClick={() => this.agregarProducto()}>GUARDAR</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                            <h5 className="mx-auto">CALCULO DEL PRODUCTO</h5>
                                        </div>

                                        <div className="row mx-auto" >
                                            <div className="col-md-12">
                                                <h5 className="mx-auto">{this.state.productoSeleccinado.label}</h5>
                                            </div>
                                        </div>
                                        {this.state.costoN != '' || this.state.costoN.length != 0
                                            ?
                                            <div className="row mx-auto" >
                                                <div className="col-md-8">
                                                    <h6 className="mx-auto">COSTO</h6>
                                                </div>
                                                <div className="col-md-4">
                                                    <h6 className="mx-auto">{'$' + this.setearMiles(this.state.costoN)}</h6>
                                                </div>
                                            </div> : null}
                                        {
                                            this.state.productoSeleccinado.impuestosPorProveedor != null && (this.state.costoN != '' || this.state.costoN.length != 0)
                                                ?
                                                this.state.productoSeleccinado.impuestosPorProveedor.map((impuesto) => {
                                                    if (impuesto.porcentaje_valor == "%") {
                                                        sumaTotal = sumaTotal + ((parseFloat(this.state.costoN) * parseFloat(impuesto.valor)) / 100)
                                                        return (
                                                            <div className="row mx-auto" >
                                                                <div className="col-md-8">
                                                                    <h6 className="mx-auto">{impuesto.descripcion}</h6>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <h6 className="mx-auto">{'$' + this.setearMiles(((parseFloat(this.state.costoN) * parseFloat(impuesto.valor)) / 100))}</h6>
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                })
                                                :
                                                null
                                        }
                                        {
                                            this.state.productoSeleccinado.impuestosPorProveedor != null && (this.state.productoSeleccinado.costoN != '' || this.state.productoSeleccinado.costoN.length != 0)
                                                ?
                                                this.state.productoSeleccinado.impuestosPorProveedor.map((impuesto) => {
                                                    if (impuesto.porcentaje_valor == "$") {
                                                        sumaTotal = sumaTotal + ((parseFloat(this.state.cantidadN) * parseFloat(this.state.productoSeleccinado.unidadContenidValor)) * parseFloat(impuesto.valor))
                                                        return (
                                                            <div className="row mx-auto" >
                                                                <div className="col-md-8">
                                                                    <h6 className="mx-auto">{impuesto.descripcion}</h6>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <h6 className="mx-auto">{'$' + this.setearMiles(((parseFloat(this.state.cantidadN) * parseFloat(this.state.productoSeleccinado.unidadContenidValor)) * parseFloat(impuesto.valor)).toFixed(3))}</h6>
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                })
                                                :
                                                null
                                        }
                                        {this.state.costoN != '' || this.state.costoN.length != 0
                                            ?
                                            <div className="row mx-auto" >
                                                <div className="col-md-8">
                                                    <h6 className="mx-auto">COSTO TOTAL</h6>
                                                </div>
                                                <div className="col-md-4">
                                                    <h6 className="mx-auto">{'$' + this.setearMiles(sumaTotal)}</h6>
                                                </div>
                                            </div> : null}

                                    </div>
                                </div>
                                : null
                        }

                        {this.state.productostotal.length == 0
                            ? null :
                            <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                <div className="col-md-3 mx-auto">
                                    <label htmlFor="numordencompra" className="col-form-label text-center">Total de la operacion </label>
                                    <input id="numordencompra" value={'$' + this.setearMiles(this.state.subTotal)} disabled={true} className="form-control text-center" type="text" />
                                </div>
                            </div>
                        }
                        {this.state.productostotal.length == 0
                            ? null :
                            <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                <div className="col col-md-11 mx-auto">
                                    <MDBTable bordered striped>
                                        <MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
                                            <tr>
                                                <th>PRODUCTO</th>
                                                <th>PLU</th>
                                                <th >CANTIDAD</th>
                                                <th >COSTO NETO</th>
                                                <th >SUBTOTAL</th>
                                                <th >IMPUESTO</th>
                                                <th >TOTAL</th>
                                                <th >ACCIONES</th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {this.state.productostotal.map((row, key) => (
                                                <tr key={key}>
                                                    <td component="th" scope="row">
                                                        {row.producto}
                                                    </td>
                                                    <td>{row.plu}</td>
                                                    <td>{row.cantidad}</td>
                                                    <td>{'$' + this.setearMiles(row.costo)}</td>
                                                    <td>{'$' + this.setearMiles(row.subtotal)}</td>
                                                    <td>{'$' + this.setearMiles(row.impuestos)}</td>
                                                    <td>{'$' + this.setearMiles(row.totalProducto)}</td>
                                                    <td>{[
                                                        this.state.checkOrdenCompra ? <button className="btn btn-sm btn-warning" disabled={this.state.modoEditar} style={{ marginLeft: '5px' }} onClick={() => this.editarProducto(key)}><EditICon /></button> : null,
                                                        <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} disabled={this.state.modoEditar} onClick={() => this.eliminarProducto(key)}><DeleteIcon /></button>]
                                                    }</td>
                                                </tr>
                                            ))}
                                        </MDBTableBody>
                                    </MDBTable>
                                </div>
                                <div id="capture-oc" style={this.state.imprimir ? { width: '800px', display: 'block', marginTop: '20px' } : { display: 'none' }}>
                                    <table className="tb-terpel" width="712px" height="773px" border="0" align="center" cellpadding="0" cellspacing="0">
                                        <tr>
                                            <td width="763px" valign="top" className="fondocuadro">
                                                <table width="712px" border="0" cellpadding="0" cellspacing="0">
                                                    <tr>
                                                        <td width="169"><img src={logoTerpel} width="138" height="63" alt="" /></td>
                                                        <td width="335" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                            <tr>
                                                                <td align="center">ENTRADA POR ORDEN DE COMPRA</td>
                                                            </tr>
                                                            {/*<tr>
                                                                <td align="center"><font className="numero"><b>{this.state.consecutivo}</b></font></td>
                                                            </tr>*/}
                                                        </table></td>
                                                        <td width="106" align="center"><img src={logoDeuna} width="64" height="64" alt="" /></td>
                                                    </tr>
                                                </table>
                                                <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                    <tr>
                                                        <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                    </tr>
                                                </table>
                                                <table width="712px" border="0" cellpadding="0" cellspacing="3">
                                                    <tr>
                                                        <td width="299" valign="top"><b className="fontipo">Tienda:</b> {usuario.empresas.alias}</td>
                                                        <td width="80" align="left" valign="top"><b>Razon social: </b></td>
                                                        <td width="219" align="left" valign="top">{usuario.empresas.razon_social}</td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="top" className="numero2"><b>Nit: </b>{usuario.empresas.nit}</td>
                                                        <td align="left" valign="top"><b>N. Factura: </b></td>
                                                        <td align="left" valign="top"><font className="numero2">{this.state.facturaN}</font></td>
                                                    </tr>
                                                </table>
                                                <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                    <tr>
                                                        <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                    </tr>
                                                </table>
                                                <table width="712px" border="0" cellpadding="3" cellspacing="0">
                                                    <tr>
                                                        <td width="80" className="fontipo"><b>Proveedor</b></td>
                                                        <td width="219" align="left" valign="top">{this.state.checkOrdenCompra ? movimientoSeleccionado.movimiento.tercero_id_detalles != null ? movimientoSeleccionado.movimiento.tercero_id_detalles.nombres + " " + movimientoSeleccionado.movimiento.tercero_id_detalles.apellidos : 'No aplica' : this.state.proveedorSeleccionado.proveedor}</td>
                                                        <td width="66" align="left" valign="top"><b>Fecha: </b></td>
                                                        <td width="233" align="left" valign="top"><font className="numero2">{moment(this.state.selectedDate).format("DD/MM/YYYY hh:mm a")}</font></td>
                                                    </tr>
                                                </table>
                                                <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                    <tr>
                                                        <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                    </tr>
                                                </table>
                                                <table width="712px" border="0" cellpadding="3" cellspacing="0">
                                                    <tr >
                                                        <td width="80" className="fontipo"><b>Responsable</b></td>
                                                        <td width="219" align="left" valign="top">{usuario.nombres + ' ' + usuario.apellidos}</td>
                                                        <td width="66" align="left" valign="top"><b>Bodega:</b></td>
                                                        <td width="233" align="left" valign="top">{this.state.bodegaSeleccionada.label}</td>
                                                    </tr>
                                                </table>
                                                <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                    <tr>
                                                        <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                    </tr>
                                                </table>
                                                <table width="712px" border="0" cellspacing="0" cellpadding="3">
                                                    <tr>
                                                        <td width="40" align="center" bgcolor="#EEEEEE"><b>COD</b></td>
                                                        <td width="182" align="center" bgcolor="#EEEEEE"><b>PRODUCTO</b></td>
                                                        <td width="35" align="center" bgcolor="#EEEEEE"><b>CANT.</b></td>
                                                        <td width="46" align="center" bgcolor="#EEEEEE"><b>V. UNID</b></td>
                                                        <td width="54" align="center" bgcolor="#EEEEEE"><b>EMB</b></td>
                                                        <td width="60" align="center" bgcolor="#EEEEEE"><b>SUBTOTAL</b></td>
                                                        <td width="70" align="center" bgcolor="#EEEEEE"><b>IMPUESTO</b></td>
                                                        <td width="90" align="center" bgcolor="#EEEEEE"><b>TOTAL</b></td>
                                                    </tr>
                                                </table>
                                                {this.state.productostotal.map((row) => {
                                                    //console.log(row)
                                                    subtotalPDF = subtotalPDF + parseFloat(row.subtotal)
                                                    impuestosPDF = impuestosPDF + parseFloat(row.impuestos)
                                                    totalPDF = totalPDF + parseFloat(row.totalProducto)
                                                    return (
                                                        <div>
                                                            <table width="712px" border="0" cellspacing="0" cellpadding="3" >
                                                                <tr>
                                                                    <td width="40" align="center" valign="middle" className="numero2">{row.plu}</td>
                                                                    <td width="182" valign="middle" align="center" className="numero2">{row.producto}</td>
                                                                    <td width="37" align="center" valign="middle" className="numero2">{row.cantidad}</td>
                                                                    <td width="46" align="center" valign="middle" className="numero2">{'$ ' + this.setearMiles(row.costo)}</td>
                                                                    <td width="54" align="center" valign="middle" className="numero2">{parseInt(row.unidadContenidValor) + "  " + row.unidadMEdidaDescripcion}</td>
                                                                    <td width="60" align="center" valign="middle" className="numero2" >{'$ ' + this.setearMiles(row.subtotal)}</td>
                                                                    <td width="70" align="center" valign="middle" className="numero2" >{'$ ' + this.setearMiles(row.impuestos)}</td>
                                                                    <td width="94" align="center" valign="middle" className="numero2" >{'$ ' + this.setearMiles(row.totalProducto)}</td>
                                                                </tr>
                                                            </table>
                                                            <table className="tb-2" width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                <tr>
                                                                    <td><img src={linea2} width="712px" height="5" alt="" /></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    )
                                                }
                                                )}
                                                <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                    <tr >
                                                        <td width="280" valign="middle" ><table width="280" border="0" align="right" cellpadding="3" cellspacing="0">
                                                            <tr >
                                                                <td width="60" align="center" valign="middle" class="numero2" >{'$ ' + this.setearMiles(subtotalPDF)}</td>
                                                                <td width="70" align="center" valign="middle" class="numero2" >{'$ ' + this.setearMiles(impuestosPDF)}</td>
                                                                <td width="94" align="center" valign="middle" class="numero2" >{'$ ' + this.setearMiles(totalPDF)}</td>
                                                            </tr>
                                                        </table></td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        }
                        {this.state.productosTotalesProveedor.length == 0
                            ? null :
                            <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                <div className="col-md-3 mx-auto">
                                    <label htmlFor="numordencompra" className="col-form-label text-center">Total de la operacion </label>
                                    <input id="numordencompra" value={'$' + this.setearMiles(this.state.subTotalCostoProveedor)} disabled={true} className="form-control text-center" type="text" />
                                </div>
                            </div>
                        }
                        {this.state.productosTotalesProveedor.length == 0
                            ? null :
                            <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                <div className="col col-md-11 mx-auto">
                                    <MDBTable bordered striped>
                                        <MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
                                            <tr>
                                                <th>PRODUCTO</th>
                                                <th>PLU</th>
                                                <th >CANTIDAD</th>
                                                <th >COSTO NETO</th>
                                                <th >SUBTOTAL</th>
                                                <th >IMPUESTO</th>
                                                <th >TOTAL</th>
                                                <th >ACCIONES</th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {this.state.productosTotalesProveedor.map((row, key) => (
                                                <tr key={key}>
                                                    <td component="th" scope="row">
                                                        {row.producto}
                                                    </td>
                                                    <td>{row.plu}</td>
                                                    <td>{row.cantidad}</td>
                                                    <td>{'$' + this.setearMiles(row.costo)}</td>
                                                    <td>{'$' + this.setearMiles(row.subtotal)}</td>
                                                    <td>{'$' + this.setearMiles(row.impuestos)}</td>
                                                    <td>{'$' + this.setearMiles(row.totalProducto)}</td>
                                                    <td>{[
                                                        <button className="btn btn-sm btn-warning" style={{ marginLeft: '5px' }} onClick={() => this.editarProductoP(key)}><EditICon /></button>,
                                                        <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.eliminarProductoP(key)}><DeleteIcon /></button>]
                                                    }</td>
                                                </tr>
                                            ))}
                                        </MDBTableBody>
                                    </MDBTable>
                                </div>

                                <div id="capture-ed" style={this.state.imprimir ? { width: '800px', display: 'block', marginTop: '20px' } : { display: 'none' }}>
                                    <table className="tb-terpel" width="712px" height="773px" border="0" align="center" cellpadding="0" cellspacing="0">
                                        <tr>
                                            <td width="763px" valign="top" className="fondocuadro">
                                                <table width="712px" border="0" cellpadding="0" cellspacing="0">
                                                    <tr>
                                                        <td width="169"><img src={logoTerpel} width="138" height="63" alt="" /></td>
                                                        <td width="335" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                            <tr>
                                                                <td align="center">ENTRADA DIRECTA</td>
                                                            </tr>
                                                            {/*<tr>
                                                                <td align="center"><font className="numero"><b>{this.state.consecutivo}</b></font></td>
                                                            </tr>*/}
                                                        </table></td>
                                                        <td width="106" align="center"><img src={logoDeuna} width="64" height="64" alt="" /></td>
                                                    </tr>
                                                </table>
                                                <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                    <tr>
                                                        <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                    </tr>
                                                </table>
                                                <table width="712px" border="0" cellpadding="0" cellspacing="3">
                                                    <tr>
                                                        <td width="299" valign="top"><b className="fontipo">Tienda:</b> {usuario.empresas.alias}</td>
                                                        <td width="66" align="left" valign="top"><b>Razon social: </b></td>
                                                        <td width="233" align="left" valign="top">{usuario.empresas.razon_social}</td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="top" className="numero2"><b>Nit: </b>{usuario.empresas.nit}</td>
                                                        <td align="left" valign="top"></td>
                                                        <td align="left" valign="top"><font className="numero2"></font></td>
                                                    </tr>
                                                </table>
                                                <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                    <tr>
                                                        <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                    </tr>
                                                </table>
                                                <table width="712px" border="0" cellpadding="3" cellspacing="0">
                                                    <tr>
                                                        <td width="80" className="fontipo"><b>Proveedor</b></td>
                                                        <td width="219" align="left" valign="top">{this.state.checkOrdenCompra ? '' : this.state.proveedorSeleccionado.proveedor}</td>
                                                        <td align="left" valign="top"><b>Fecha: </b></td>
                                                        <td align="left" valign="top"><font className="numero2">{moment(this.state.selectedDate).format("DD/MM/YYYY hh:mm a")}</font></td>
                                                    </tr>
                                                </table>
                                                <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                    <tr>
                                                        <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                    </tr>
                                                </table>
                                                <table width="712px" border="0" cellpadding="3" cellspacing="0">
                                                    <tr >
                                                        <td width="80" className="fontipo"><b>Responsable</b></td>
                                                        <td width="219" align="left" valign="top">{usuario.nombres + ' ' + usuario.apellidos}</td>
                                                        <td width="66" align="left" valign="top"><b>Bodega:</b></td>
                                                        <td width="233" align="left" valign="top">{this.state.bodegaSeleccionada.label}</td>
                                                    </tr>
                                                </table>
                                                <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                    <tr>
                                                        <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                    </tr>
                                                </table>
                                                <table width="712px" border="0" cellspacing="0" cellpadding="3">
                                                    <tr>
                                                        <td width="40" align="center" bgcolor="#EEEEEE"><b>COD</b></td>
                                                        <td width="182" align="center" bgcolor="#EEEEEE"><b>PRODUCTO</b></td>
                                                        <td width="35" align="center" bgcolor="#EEEEEE"><b>CANT.</b></td>
                                                        <td width="46" align="center" bgcolor="#EEEEEE"><b>V. UNID</b></td>
                                                        <td width="54" align="center" bgcolor="#EEEEEE"><b>EMB</b></td>
                                                        <td width="60" align="center" bgcolor="#EEEEEE"><b>SUBTOTAL</b></td>
                                                        <td width="70" align="center" bgcolor="#EEEEEE"><b>IMPUESTO</b></td>
                                                        <td width="90" align="center" bgcolor="#EEEEEE"><b>TOTAL</b></td>
                                                    </tr>
                                                </table>
                                                {this.state.productosTotalesProveedor.map((row) => {
                                                    //console.log(row)
                                                    subtotalPDF = subtotalPDF + parseFloat(row.subtotal)
                                                    impuestosPDF = impuestosPDF + parseFloat(row.impuestos)
                                                    totalPDF = totalPDF + parseFloat(row.totalProducto)
                                                    return (
                                                        <div>
                                                            <table width="712px" border="0" cellspacing="0" cellpadding="3" >
                                                                <tr>
                                                                    <td width="40" align="center" valign="middle" className="numero2">{row.plu}</td>
                                                                    <td width="182" valign="middle" align="center" className="numero2">{row.producto}</td>
                                                                    <td width="37" align="center" valign="middle" className="numero2">{row.cantidad}</td>
                                                                    <td width="46" align="center" valign="middle" className="numero2">{'$ ' + this.setearMiles(row.costo)}</td>
                                                                    <td width="54" align="center" valign="middle" className="numero2">{parseInt(row.unidadContenidValor) + "  " + row.unidadMEdidaDescripcion}</td>
                                                                    <td width="60" align="center" valign="middle" className="numero2" >{'$ ' + this.setearMiles(row.subtotal)}</td>
                                                                    <td width="70" align="center" valign="middle" className="numero2" >{'$ ' + this.setearMiles(row.impuestos)}</td>
                                                                    <td width="94" align="center" valign="middle" className="numero2" >{'$ ' + this.setearMiles(row.totalProducto)}</td>
                                                                </tr>
                                                            </table>
                                                            <table className="tb-2" width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                <tr>
                                                                    <td><img src={linea2} width="712px" height="5" alt="" /></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    )
                                                }
                                                )}
                                                <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                    <tr >
                                                        <td width="280" valign="middle" ><table width="280" border="0" align="right" cellpadding="3" cellspacing="0">
                                                            <tr >
                                                                <td width="60" align="center" valign="middle" class="numero2" >{'$ ' + this.setearMiles(subtotalPDF)}</td>
                                                                <td width="70" align="center" valign="middle" class="numero2" >{'$ ' + this.setearMiles(impuestosPDF)}</td>
                                                                <td width="94" align="center" valign="middle" class="numero2" >{'$ ' + this.setearMiles(totalPDF)}</td>
                                                            </tr>
                                                        </table></td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>
                                </div>

                            </div>
                        }

                    </div>
                </div>
            </div>



        )
    }
}


export default MediosPago
