
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios'
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import Server from '../../lib/server'
import DialogColor from './dialogs/dialogColor'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import store from '../../lib/store'
import './estilos.css'
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Typography from '@material-ui/core/Typography';
import { Button } from 'react-bootstrap'
import Selects from 'react-select';
import Login1 from '../../assets/img/esquema1.png'
import Login2 from '../../assets/img/esquema2.png'
import Login3 from '../../assets/img/esquema3.png'
import swal from "sweetalert"
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia'
var colorDominio = store.getState().colorDominio
const monedabd = [
    {
        label: '$ - DOLAR',
        value: '$'
    },
    {
        label: '£ - LIBRA',
        value: '£',
    },
    {
        label: '¥ - YEN',
        value: '¥',
    },
    {
        label: '€ - EURO',
        value: '€',
    }
]
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))

class FormularioCategoria extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            monedaSeleecionada: {},
            decimasCosto: 0,
            decimasPrecio: 0,
            background1: '#f44336',
            background2: '#41b21b',
            dialogColor1: false,
            dialogColor2: false,
            loginSelecionado: 0,


        };
        store.subscribe(() => {
            this.setState({
                background1: store.getState().color,
                dialogColor1: store.getState().ventanaColor,
                modoVista: store.getState().vistaPerfil

            })
        })
    }

    handledialogColor = (n) => {
        console.log(n)
        if (n === 1) {
            this.setState({
                dialogColor1: !this.state.dialogColor1
            });
        } else {
            this.setState({
                dialogColor2: !this.state.dialogColor2
            });
        }

    }


    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };

    handleChangeCheck = name => event => {
        var estado = (event.target.value == 'false' ? false : true)
        this.setState({
            [name]: !estado
        });
    }

    componentWillMount() {
        var usuario = store.getState().usuario
        Axios.get(Server.hostPrueba + Server.puertoSSLPrueba + '/modulos', { headers: { Authorization: ntoken } })
            .then(responsee => {

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
    };


    cancelar = () => {
        store.dispatch({
            type: "CREACION_PARAMETROS",
            creacionParametros: false
        })
    }

    seleccionar = (name, value) => {
        console.log(name)
        console.log(value)
        console.log(this.state.loginSelecionado)
        this.setState({
            [name]: value
        })
    }


    render() {
        var modovista = store.getState().vistaPerfil
        const perfilEditar = store.getState().perfilEditar
        return (
            <div className="row">
                <AppBar style={{ backgroundColor: colorDominio }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Close"
                            onClick={this.cancelar.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            style={{ marginLeft: "theme.spacing(2)", flex: 1, color: '#fff' }}
                        >
                            PARAMETROS
                        </Typography>
                        <Button disabled={this.state.response} style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} /*onClick={this.guardarenbd.bind(this)}*/>GUARDAR</Button>
                    </Toolbar>
                </AppBar>
                <div className="col-md-11 col-lg-11 col-sm-12 mx-auto">
                    <div className="row" style={{ marginTop: '12VH' }}>
                        <div className="col col-md-6">
                            <div className="row" >
                                <h5>1. Configuracion de moneda</h5>
                            </div>
                            <div className="row" >
                                <div className="col col-md-4">
                                    <label htmlFor="nombreprov" className="col-form-label">MONEDA</label>
                                    <Selects
                                        placeholder="Seleccione"
                                        value={this.state.monedaSeleecionada}
                                        onChange={this.handleChanges('monedaSeleecionada')}
                                        options={monedabd}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="numordencompra" className="col-form-label">DECIMAS COSTO *</label>
                                    <input autocomplete="off" value={this.state.decimasCosto} className="form-control" type="number" min="0" onChange={this.handleChange('decimasCosto')} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="numordencompra" className="col-form-label">DECIMAS PRECIO *</label>
                                    <input autocomplete="off" value={this.state.decimasPrecio} className="form-control" type="number" min="0" onChange={this.handleChange('decimasPrecio')} />
                                </div>
                            </div>
                        </div>
                        <div className="col col-md-6">
                            <div className="row" >
                                <h5>1. Configuracion de sistema</h5>
                            </div>
                            <div className="row" >
                                <label htmlFor="numordencompra" className="col-form-label">LOGIN</label>
                            </div>
                            <div className="row" >
                                <Card className="col col-md-5 mx-auto" onClick={() => this.seleccionar('loginSelecionado', 1)} style={this.state.loginSelecionado === 1 ? { margin: '10px', boxShadow: 'inset 0 0 1em gold' } : { margin: '10px' }}>
                                    <CardActionArea >
                                        <div className="row">
                                            <div className="col-md-12" style={{ padding: '0' }}>
                                                <CardMedia
                                                    component="img"
                                                    alt="Contemplative Reptile"
                                                    style={{ width: '100%' }}
                                                    src={Login1}
                                                    title="LOGIN 1"
                                                />
                                            </div>
                                        </div>
                                    </CardActionArea>
                                </Card >
                                <Card className="col col-md-5 mx-auto" onClick={() => this.seleccionar('loginSelecionado', 2)} style={this.state.loginSelecionado === 2 ? { margin: '10px', boxShadow: 'inset 0 0 1em gold' } : { margin: '10px' }}>
                                    <CardActionArea >
                                        <div className="row">
                                            <div className="col-md-12" style={{ padding: '0' }}>
                                                <CardMedia
                                                    component="img"
                                                    alt="Contemplative Reptile"
                                                    style={{ width: '100%' }}
                                                    src={Login2}
                                                    title="LOGIN 2"
                                                />
                                            </div>
                                        </div>
                                    </CardActionArea>
                                </Card >
                            </div>
                            <div className="row" >
                                <label htmlFor="numordencompra" className="col-form-label">PLATAFORMA</label>
                            </div>
                            <div className="row" >
                                <div className="col-md-3">
                                    <div className="row" >
                                        <label htmlFor="nombreprov" className="col-form-label mx-auto">COLOR PRINCIPAL</label>
                                    </div>
                                    <div className="row" >
                                        <div className="rounded-circle mx-auto" style={{ cursor: 'pointer', height: '40px', width: '40px', backgroundColor: this.state.background1 }} onClick={() => this.handledialogColor(1)}></div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="row" >
                                        <label htmlFor="nombreprov" className="col-form-label mx-auto">PAGINA DEFECTO</label>
                                    </div>
                                    <div className="row" >
                                        <Card className="col col-md-12 mx-auto" style={{ margin: '10px', backgroundColor: this.state.background1 }}>
                                            <CardActionArea >
                                                <div className="row">
                                                    <div className="col-md-12" style={{ padding: '0' }}>
                                                        <CardMedia
                                                            component="img"
                                                            alt="Contemplative Reptile"
                                                            style={{ width: '100%' }}
                                                            src={Login3}
                                                            title="POR DEFECTO"
                                                        />
                                                    </div>
                                                </div>
                                            </CardActionArea>
                                        </Card >
                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>
                </div>
                <Dialog
                    open={this.state.dialogColor1}
                    onClose={() => this.handledialogColor(1)}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'md'}
                    style={{ padding: '0' }}
                >
                    <DialogContent style={{ padding: '0' }}>
                        <DialogColor color={this.state.background1} />
                    </DialogContent>

                </Dialog>
                <Dialog
                    open={this.state.dialogColor2}
                    onClose={() => this.handledialogColor(2)}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'md'}
                    style={{ padding: '0' }}
                >
                    <DialogContent style={{ padding: '0' }}>
                        <DialogColor color={this.state.background2} />
                    </DialogContent>

                </Dialog>
            </div>

        )
    }
}


export default FormularioCategoria