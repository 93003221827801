import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios';
import { MDBDataTable } from 'mdbreact';
import store from '../../../lib/store';
import Loader from '../../../components/Loader';
import Server from '../../../lib/server';
import swal from 'sweetalert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import 'moment/locale/es';
import InfoIcon from '@material-ui/icons/Info';
import Slide from "@material-ui/core/Slide";
import Tooltip from '@material-ui/core/Tooltip';
import { DialogContent } from '@material-ui/core';
import DominioIcon from "@material-ui/icons/LocalGasStation"
import TiendasIcon from "@material-ui/icons/StoreMallDirectory"
import BonosIcon from "@material-ui/icons/ConfirmationNumber"
import { Avatar } from '@material-ui/core';
import Selects from 'react-select'
import { Typography } from "@material-ui/core";
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import * as mq from 'mqtt'
import { func } from 'prop-types';
import './css/styleweb.css'
import './css/digital-7.ttf'
import './css/BebasNeue.ttf'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import fondoBtn from './btCerrar.png'
import estadoimg from './activo.png'
import marcaimg from './marca.png'
import Tab from '@material-ui/core/Tab';
import ListIcon from '@material-ui/icons/List';
import Tabs from '@material-ui/core/Tabs';
import excelIcon from "../../../assets/img/excel.png";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const styles = {
    dialog: {
        backgroundImage: "url(https://voucher.ebisucloud.com/neoline//fondo-terpel.jpg)",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingTop: "10px",
        paddingBottom: "10px"
    }
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
var tipoDominio = store.getState().tipoDominio
const idModulo = 3;
var ntoken = 'Bearer '.concat(localStorage.getItem('token'));
var client = null
var mensajereal = null
function seterMiles(numero) {
    return (
        (new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(2)))
    )
}
class Equipos extends Component {
    constructor() {
        super();
        this.state = {
            ventanaCreacionSd: false,
            ventanaCreacionEquipo: false,
            ventanaPreCreacionEquipo: false,
            ListaAlmacenamientos: [],
            ListaEquipos: [],
            actualizarDatos: false,
            tabla: false,
            loader: true,
            ventanaConfiguracion: false,
            equiposAlerta: [],
            dialogoAlerta: false,
            alertasSelecionadas: [],
            idPreautorizado: 0,
            dialogAsociacion: false,
            ventanaPreautorizacionEquipo: false,
            //Formulario de asociacion
            tiendasbd: [],
            tiendaSeleccionada: {},
            bodegasbd: [],
            bodegaSeleccionada: {},
            edsCampana: '',
            dialogDetallesCampañas: false,
            detallesCampañas: [],
            surtidorTiempoReal: false,
            kioskoTiempoReal: false,
            surtidor1: true,
            value: 0,
            valueEquipo: 0,
            equiposSinActualizar: [],
            equiposSinActualizarPreautorizado: [],
            stop: false,
            excel: []
        };
        //console.log(store.getState());
        store.subscribe(() => {
            this.setState({
                ventanaCreacionSd: store.getState().ventanaCreacionSD,
                ventanaCreacionEquipo: store.getState().ventanaCreacionEquipo,
                actualizarDatos: store.getState().actualizarvista,
                ventanaConfiguracion: store.getState().creacionParametrosEquipos,
                ventanaMarcas: store.getState().ventanaMarcas,
                ventanaProtocolo: store.getState().ventanaProtocolo,
                ventanaPreCreacionEquipo: store.getState().ventanaPreCreacionEquipo,
            });
        });
    }

    setearMiles = (numero) => {
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case "COP":
                return (
                    (new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(2)))
                )
                break;
            case "USD":
                return (new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(numero))
                break;

        }
    }

    changetab = (newTab) => {
        this.setState({ value: newTab })
    }

    changetabEquipo = (newTab) => {
        this.setState({ valueEquipo: newTab })
    }



    timer() {
        var seacrh = document.getElementsByClassName("form-control-sm")[1];
        if (this.state.valueEquipo === 0 && seacrh.value === "") {
            this.setState({
                currentCount: this.state.currentCount - 1
            });
            if (this.state.currentCount < 1) {
                clearInterval(this.intervalId);
            }
            this.estadoEquipo();
        } else {
            console.log("Pero que ha pasao")
        }

    }

    componentWillReceiveProps() {
        if (this.state.actualizarDatos) {
            this.actualizarDatos();
        }
    }
    componentDidMount() {
        this.intervalId = setInterval(this.timer.bind(this), 15000);
    }

    componentWillMount() {
        this.actualizarDatos();
        this.estadoEquipo();
        var excel = []
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        ///
        var tipoDominio = store.getState().tipoDominio
        var hijos = store.getState().empresasHijos;
        Axios.get(Server.hostPrueba + Server.puertoEquipos + '/api/equipo/empresas/' + hijos, { headers: { Authorization: ntoken } })
            .then(response => {
                var equipos = [];
                var equipos2 = [];
                if (response.status = 200) {
                    if (tipoDominio === "V" && response.data.data != null) {
                        response.data.data.map((equipo) => {
                            equipos.push({
                                "a": equipo.id,
                                "b": equipo.alias,
                                "ultimaConexion": equipo.ultimaConexion === null ? '' : this.obtenerFecha(equipo.ultimaConexion,equipo.propiedades),
                                "e": equipo.ultimaConexion === null ? '' : this.ultimaFecha(equipo.ultimaConexion,equipo.propiedades),
                                "f": [
                                    equipo.estado === "P"
                                        ?
                                        <Tooltip title="ASOCIAR EQUIPO" placement="top">
                                            <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.DialogasociarEquipo(equipo.id)}>
                                                {tipoDominio === "V" || tipoDominio === "E" ? <DominioIcon /> : <TiendasIcon />}
                                            </button>
                                        </Tooltip>
                                        : null
                                    , <Tooltip title="DETALLES DE EQUIPO" placement="top">
                                        <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.campanasAterrizados(equipo.id, equipo.alias)}>
                                            <InfoIcon />
                                        </button>
                                    </Tooltip>
                                ]
                            })
                        })
                    } else if (response.data.data != null) {
                        if (window.screen.width < 720) {
                            response.data.data.map((equipo) => {

                                if (equipo.estado === "P") {
                                    equipos2.push({
                                        "a": equipo.id,
                                        "b": equipo.alias,
                                        "e": equipo.ultimaConexion === null ? '' : this.ultimaFecha(equipo.ultimaConexion,equipo.propiedades),
                                        "f": [
                                            equipo.estado === "P"
                                                ?
                                                <Tooltip title="ASOCIAR EQUIPO" placement="top">
                                                    <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.DialogasociarEquipo(equipo.id)}>
                                                        {tipoDominio === "V" || tipoDominio === "E" ? <DominioIcon /> : <TiendasIcon />}
                                                    </button>
                                                </Tooltip>
                                                : null
                                            ,
                                            tipoDominio === "E" ? <Tooltip title="VER EN TIEMPO REAL" placement="top">
                                                <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.abrirsurtidorTiempoReal(equipo.id)}>
                                                    <InfoIcon />
                                                </button>
                                            </Tooltip> : null
                                        ]
                                    })
                                }
                                equipos.push({
                                    "a": equipo.id,
                                    "b": equipo.alias,
                                    "e": equipo.ultimaConexion === null ? '' : this.ultimaFecha(equipo.ultimaConexion,equipo.propiedades),
                                    "f": [
                                        equipo.estado === "P"
                                            ?
                                            <Tooltip title="ASOCIAR EQUIPO" placement="top">
                                                <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.DialogasociarEquipo(equipo.id)}>
                                                    {tipoDominio === "V" || tipoDominio === "E" ? <DominioIcon /> : <TiendasIcon />}
                                                </button>
                                            </Tooltip>
                                            : null
                                        ,
                                        tipoDominio === "E" ? <Tooltip title="VER EN TIEMPO REAL" placement="top">
                                            <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.abrirsurtidorTiempoReal(equipo.id)}>
                                                <InfoIcon />
                                            </button>
                                        </Tooltip> : null
                                    ]
                                })


                            })
                        } else {
                            response.data.data.map((equipo) => {
                                if (equipo.estado === "P") {
                                    equipos2.push({
                                        "a": parseInt(equipo.id),
                                        "b": equipo.alias,
                                        "c": equipo.bodega,
                                        "ultimaConexion": equipo.ultimaConexion === null ? '' : this.obtenerFecha(equipo.ultimaConexion,equipo.propiedades),
                                        "e": equipo.ultimaConexion === null ? '' : this.ultimaFecha(equipo.ultimaConexion,equipo.propiedades),
                                        "e5": equipo.ultimaVenta === null ? '' : moment(equipo.ultimaVenta,"DD-MM-YYYY hh:mm:ss A").format("DD/MMM  hh:mm a"),
                                        "e1": equipo.versionCode === null ? '' : equipo.versionCode,
                                        "ef2": equipo.propiedades === null ?
                                            '0'
                                            :
                                            parseInt(this.obtenerPendientes(equipo.propiedades)),
                                        "ef3": equipo.notificacion_pendiente === undefined ?
                                            '0'
                                            :
                                            parseInt(equipo.notificacion_pendiente),
                                        "f": [
                                            equipo.estado === "P"
                                                ?
                                                <Tooltip title="ASOCIAR EQUIPO" placement="top">
                                                    <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.DialogasociarEquipo(equipo.id)}>
                                                        {tipoDominio === "V" || tipoDominio === "E" ? <DominioIcon /> : <TiendasIcon />}
                                                    </button>
                                                </Tooltip>
                                                : null
                                            ,
                                            tipoDominio === "E" ? <Tooltip title="VER EN TIEMPO REAL" placement="top">
                                                <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.abrirsurtidorTiempoReal(equipo.id)}>
                                                    <InfoIcon />
                                                </button>
                                            </Tooltip> : null
                                            /*,
                                            tipoDominio === "T" ? <Tooltip title="Monitorear equipo" placement="top">
                                                <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.abrirKioskoTiempoReal(equipo.empresas_id, equipo.id)}>
                                                    <InfoIcon />
                                                </button>
                                            </Tooltip> : null*/
                                        ]
                                    })

                                }
                                excel.push({
                                    "a": parseInt(equipo.id),
                                    "a2": equipo.razon_social,
                                    "a1": equipo.bodega,
                                    "b": equipo.alias,
                                    "c": equipo.bodega,
                                    "d": equipo.ultimaConexion === null ? '' : this.obtenerFecha(equipo.ultimaConexion,equipo.propiedades),
                                    "e": equipo.ultimaConexion === null ? '' : this.ultimaFecha(equipo.ultimaConexion,equipo.propiedades),
                                    "e5": equipo.ultimaVenta === null ? '' : moment(equipo.ultimaVenta,"DD-MM-YYYY hh:mm:ss A").format("DD/MMM  hh:mm a"),
                                    "f": equipo.versionCode === null ? '' : equipo.versionCode,
                                })
                                equipos.push({
                                    "a": parseInt(equipo.id),
                                    "b": equipo.alias,
                                    "c": equipo.bodega,
                                    "ultimaConexion": equipo.ultimaConexion === null ? '' : this.obtenerFecha(equipo.ultimaConexion,equipo.propiedades),
                                    "e": equipo.ultimaConexion === null ? '' : this.ultimaFecha(equipo.ultimaConexion,equipo.propiedades),
                                    "e5": equipo.ultimaVenta === null ? '' : moment(equipo.ultimaVenta,"DD-MM-YYYY hh:mm:ss A").format("DD/MMM  hh:mm a"),
                                    "e1": equipo.versionCode === null ? '' : equipo.versionCode,
                                    "ef2": equipo.propiedades === null ?
                                        '0'
                                        :
                                        parseInt(this.obtenerPendientes(equipo.propiedades)),
                                    "ef3": equipo.notificacion_pendiente === undefined ?
                                        '0'
                                        :
                                        parseInt(equipo.notificacion_pendiente),

                                    "f": [
                                        equipo.estado === "P"
                                            ?
                                            <Tooltip title="ASOCIAR EQUIPO" placement="top">
                                                <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.DialogasociarEquipo(equipo.id)}>
                                                    {tipoDominio === "V" || tipoDominio === "E" ? <DominioIcon /> : <TiendasIcon />}
                                                </button>
                                            </Tooltip>
                                            : null
                                        ,
                                        tipoDominio === "E" ? <Tooltip title="VER EN TIEMPO REAL" placement="top">
                                            <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.abrirsurtidorTiempoReal(equipo.id)}>
                                                <InfoIcon />
                                            </button>
                                        </Tooltip> : null
                                        /*,
                                        tipoDominio === "T" ? <Tooltip title="Monitorear equipo" placement="top">
                                            <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.abrirKioskoTiempoReal(equipo.empresas_id, equipo.id)}>
                                                <InfoIcon />
                                            </button>
                                        </Tooltip> : null*/
                                    ]
                                })


                            })
                        }

                    }

                } else if (response.status === 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
                this.setState({ equiposSinActualizar: equipos, equiposSinActualizarPreautorizado: equipos2, excel: excel })
            })
        ///
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/5/empresas/' + hijos + "," + usuario.empresas_id, { headers: { Authorization: ntoken } })
            .then(responseee => {
                var empresas = []
                if (responseee.data != null) {
                    var emp = []
                    var res = responseee.data.empresas
                    if (res != null) {
                        res.map((e) => {
                            emp.push({
                                value: e.id,
                                label: e.alias + " -- " + e.codigo,
                                title: e.alias,
                            })
                        })
                    }
                    this.setState({ tiendasbd: emp })
                } else if (responseee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
                this.setState({ empresasbd: empresas })

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })


    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        if (name === "tiendaSeleccionada") {
            this.setState({
                bodegaSeleccionada: {}
            })
            Axios.get(Server.hostPrueba + Server.puertoSSLBodega + '/api/bodegas/empresa/' + value.value, { headers: { Authorization: ntoken } })
                .then(responsee => {
                    var bdgtotal = []
                    responsee.data.map((bodega) => {
                        bdgtotal.push({
                            value: bodega.bodega_id,
                            label: bodega.descripcion
                        })
                    })
                    this.setState({ bodegasbd: bdgtotal })
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                })
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }


    DialogasociarEquipo = (id) => {
        this.setState({
            idPreautorizado: id,
            dialogAsociacion: true
        })
    }

    cerrarAsociacionEquipo = () => {
        this.setState({
            idPreautorizado: 0,
            dialogAsociacion: false
        })
    }



    asociarEquipo = () => {
        var usuario = store.getState().usuario
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'));
        if (JSON.stringify(this.state.tiendaSeleccionada) === "{}") {
            swal("Fallo al asociar", "Por favor escoja la tienda", "warning");
        } else if (JSON.stringify(this.state.bodegaSeleccionada) === "{}") {
            swal("Fallo al asociar", "Por favor escoja la bodega", "warning");
        } else {
            var data = {
                "empresas_id": this.state.tiendaSeleccionada.value,
                "equipos_id": this.state.idPreautorizado,
                "bodegas_id": this.state.bodegaSeleccionada.value,
                "update_user": usuario.p_id,
            }
            Axios.put(Server.hostPrueba + Server.puertoEquipos + '/api/equipo/posautorizacion', data, { headers: { Authorization: ntoken } })
                .then(response => {
                    console.log(response)
                    this.setState({
                        response: false
                    })
                    if (response.status === 201) {
                        if (response.data.success) {
                            swal({
                                title: response.data.mensaje,
                                icon: "success",
                                closeOnClickOutside: false,
                                buttons: {
                                    Seguir: {
                                        text: "OK",
                                        value: "continuar"
                                    }
                                },
                            }).then((value) => {
                                switch (value) {

                                    case "continuar":
                                        store.dispatch({
                                            type: "ACTUALIZACION_DATOS",
                                            actualizarvista: true
                                        })
                                        this.cerrarAsociacionEquipo()
                                        break;

                                    default:

                                }
                            });
                        } else {
                            swal("Fallo al asociar", "Por favor verifique los datos", "warning");
                        }

                    } else {
                        swal("Fallo al asociar", "Por favor verifique los datos", "warning");
                    }
                })
        }

    }

    restaurarEquipo = (id) => {
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        swal({
            title: "Estas seguro de que quieres restaurar este equipo?",
            text: "Una vez restaurado, este equipo no contara con informacion anterior",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    Axios.put(Server.hostPruebasinssl + Server.puertoEquipos + '/api/equipo/restaurar/' + id, {}, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            console.log(res)
                            if (res.status == 201) {
                                swal({
                                    title: "EQUIPO RESTAURADO CORRECTAMENTE",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            store.dispatch({
                                                type: "ACTUALIZACION_DATOS",
                                                actualizarvista: true
                                            })
                                            break;
                                        default:

                                    }
                                });
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 3000);
                            } else {
                                swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
                            }
                        })
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }

    campanasAterrizados = (id, eds) => {
        Axios.get(Server.hostPrueba + Server.puertoEmpresas + '/api/campanas/equipos/' + id, { headers: { Authorization: ntoken } })
            .then(response => {
                if (response.status = 200) {
                    //console.log(response);
                    if (response.data.campanas_x_equipo != null) {
                        this.setState({
                            dialogDetallesCampañas: true,
                            detallesCampañas: response.data.campanas_x_equipo,
                            idPreautorizado: id,
                            edsCampana: eds,
                        })
                    } else {
                        this.setState({
                            dialogDetallesCampañas: true,
                            detallesCampañas: [],
                            idPreautorizado: id,
                            edsCampana: '',
                        })
                    }
                } else if (response.status = 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
            })


    }
    abrirsurtidorTiempoReal = (id) => {
        this.setState({
            surtidorTiempoReal: true
        })
        this.surtidorTiempoReal()
    }

    abrirKioskoTiempoReal = (empresa, id) => {
        this.setState({
            kioskoTiempoReal: true
        })
        this.kioskoTiempoReal(empresa, id)
    }

    surtidorTiempoReal = () => {
        client = mq.connect('tcp://voucher.ebisucloud.com', {
            username: "mosquitoneo",
            port: 9001,
            password: "baygon",
            rejectUnauthorized: false,
            reconnectPeriod: 5000
        })

        client.on('connect', () => {
            client.subscribe("/pruebas/surtidor", {}, function (topic, qos) {
                console.log(qos.toString())
            })
        })
        client.on('error', (er) => {
            console.log("YUJUUU no ")
            console.log(er)
        })
        client.on('message', function (topic, message) {
            // message is Buffer
            /*var json = JSON.parse(message.toString())
            console.log(json)
            if (document.getElementById("surtCara") != null) {
                document.getElementById("surtCara").innerHTML = json[0].surtidor + " - " + json[0].cara
            }
            if (document.getElementById("surventa") != null) {
                document.getElementById("surventa").innerHTML = json[0].venta
            }
            if (document.getElementById("surgalones") != null) {
                document.getElementById("surgalones").innerHTML = json[0].volumen
            }
            if (document.getElementById("surprecio") != null) {
                document.getElementById("surprecio").innerHTML = json[0].precio
            }
            if (json[0] != null) {
                if (json[0].mangueras != null) {
                    json[0].mangueras.map((manguera, key) => {
                        //document.getElementById("manguera" + key).innerHTML = manguera.numero
                        //document.getElementById("estado" + key).innerHTML = manguera.estado
                    })
                }
            }*/
            var json2 = JSON.parse(message.toString())
            console.log(json2)
            if (document.getElementById("surtCara_2") != null) {
                document.getElementById("surtCara_2").innerHTML = json2[0].surtidor + " - " + json2[0].cara
            }
            if (document.getElementById("surtCara_3") != null) {
                document.getElementById("surtCara_3").innerHTML = json2[1].surtidor + " - " + json2[1].cara
            }
            if (document.getElementById("surtiVenta_2") != null) {
                document.getElementById("surtiVenta_2").innerHTML = seterMiles(json2[0].venta)
            }
            if (document.getElementById("surtiVenta_3") != null) {
                document.getElementById("surtiVenta_3").innerHTML = seterMiles(json2[1].venta)
            }
            if (document.getElementById("surtiVolumen_2") != null) {
                document.getElementById("surtiVolumen_2").innerHTML = seterMiles(json2[0].volumen)
            }
            if (document.getElementById("surtiVolumen_3") != null) {
                document.getElementById("surtiVolumen_3").innerHTML = seterMiles(json2[1].volumen)
            }
            if (document.getElementById("surtiPrecio_2") != null) {
                document.getElementById("surtiPrecio_2").innerHTML = seterMiles(json2[0].precio)
            }
            if (document.getElementById("surtiPrecio_3") != null) {
                document.getElementById("surtiPrecio_3").innerHTML = seterMiles(json2[1].precio)
            }
            if (document.getElementById("estado_2") != null) {
                document.getElementById("estado_2").innerHTML = json2[0].estado
            }
            if (document.getElementById("estado_3") != null) {
                document.getElementById("estado_3").innerHTML = json2[1].estado
            }
            if (document.getElementById("surventa") != null) {
                document.getElementById("surventa").innerHTML = json2[1].estado === "DESPACHO" ? json2[1].venta : json2[0].venta
            }
            if (document.getElementById("surgalones") != null) {
                document.getElementById("surgalones").innerHTML = json2[1].estado === "DESPACHO" ? json2[1].volumen : json2[0].volumen
            }
            if (document.getElementById("surprecio") != null) {
                document.getElementById("surprecio").innerHTML = json2[1].estado === "DESPACHO" ? json2[1].precio : json2[0].precio
            }
            if (document.getElementById("surtCara") != null) {
                document.getElementById("surtCara").innerHTML = json2[1].estado === "DESPACHO" ? (json2[1].surtidor + " - " + json2[1].cara) : (json2[0].surtidor + " - " + json2[0].cara)
            }
        })
    }

    kioskoTiempoReal = (empresa, id) => {
        client = mq.connect('tcp://voucher.ebisucloud.com', {
            username: "mosquitoneo",
            port: 9001,
            password: "baygon",
            rejectUnauthorized: false,
            reconnectPeriod: 5000
        })

        client.on('connect', () => {
            client.subscribe("/NEO/EMPRESA/" + empresa + "/EQUIPO/" + id + "/#", {}, function (topic, qos) {
                console.log(qos.toString())
            })
        })
        client.on('error', (er) => {
            console.log("YUJUUU no ")
            console.log(er)
        })
        client.on('message', function (topic, message) {
            // message is Buffer
            var json2 = JSON.parse(message.toString())
        })
    }

    cerrarSurtidorTiempoReal = () => {
        client.end()
        this.setState({
            surtidorTiempoReal: false
        })
    }
    cerrarKioskoTiempoReal = () => {
        client.end()
        this.setState({
            kioskoTiempoReal: false
        })
    }

    cerrarCampanasAterrizados = () => {
        this.setState({
            dialogDetallesCampañas: false,
            detallesCampañas: [],
            idPreautorizado: 0,
            edsCampana: ''
        })

    }

    ObtenerCode = (propiedades) => {
        var pr = JSON.parse(propiedades.propiedades)
        return pr.version_code
    }

    obtenerPendientes = (propiedades) => {
        var pr = JSON.parse(propiedades.propiedades)
        return pr.pendientes
    }

    estadoEquipo() {
        var hijos = store.getState().empresasHijos;
        var excelre = []
        Axios.get(Server.hostPrueba + Server.puertoEquipos + '/api/equipo/empresas/' + hijos, { headers: { Authorization: ntoken } })
            .then(response => {
                var equipos = [];
                if (response.status = 200) {
                    if (tipoDominio === "V" && response.data.data != null) {
                        response.data.data.map((equipo) => {
                            equipos.push({
                                "a": equipo.id,
                                "b": equipo.alias,
                                "ultimaConexion": equipo.ultimaConexion === null ? '' : this.obtenerFecha(equipo.ultimaConexion,equipo.propiedades),
                                "e": equipo.ultimaConexion === null ? '' : this.ultimaFecha(equipo.ultimaConexion,equipo.propiedades),
                                "f": [
                                    equipo.estado === "P"
                                        ?
                                        <Tooltip title="ASOCIAR EQUIPO" placement="top">
                                            <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.DialogasociarEquipo(equipo.id)}>
                                                {tipoDominio === "V" || tipoDominio === "E" ? <DominioIcon /> : <TiendasIcon />}
                                            </button>
                                        </Tooltip>
                                        : null
                                    , <Tooltip title="DETALLES DE EQUIPO" placement="top">
                                        <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.campanasAterrizados(equipo.id, equipo.alias)}>
                                            <InfoIcon />
                                        </button>
                                    </Tooltip>
                                ]
                            })
                        })
                    } else if (response.data.data != null) {
                        if (window.screen.width < 720) {
                            response.data.data.map((equipo) => {
                                equipos.push({
                                    "a": equipo.id,
                                    "b": equipo.alias,
                                    "e": equipo.ultimaConexion === null ? '' : this.ultimaFecha(equipo.ultimaConexion,equipo.propiedades),
                                    "f": [
                                        equipo.estado === "P"
                                            ?
                                            <Tooltip title="ASOCIAR EQUIPO" placement="top">
                                                <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.DialogasociarEquipo(equipo.id)}>
                                                    {tipoDominio === "V" || tipoDominio === "E" ? <DominioIcon /> : <TiendasIcon />}
                                                </button>
                                            </Tooltip>
                                            : null
                                        ,
                                        tipoDominio === "E" ? <Tooltip title="VER EN TIEMPO REAL" placement="top">
                                            <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.abrirsurtidorTiempoReal(equipo.id)}>
                                                <InfoIcon />
                                            </button>
                                        </Tooltip> : null
                                    ]
                                })
                            })
                        } else {
                            response.data.data.map((equipo) => {
                                if (equipo.estado != "P") {
                                    equipos.push({
                                        "a": parseInt(equipo.id),
                                        "re": equipo.razon_social,
                                        "b": <Tooltip title={"" + equipo.bodega} placement="top">
                                            <h6 style={{ cursor: 'pointer' }}>{equipo.alias}</h6>
                                        </Tooltip>,
                                        "ultimaConexion": equipo.ultimaConexion === null ? '' : this.obtenerFecha(equipo.ultimaConexion,equipo.propiedades),
                                        "e": equipo.ultimaConexion === null ? '' : this.ultimaFecha(equipo.ultimaConexion,equipo.propiedades),
                                        "e5": equipo.ultimaVenta === null ? '' : moment(equipo.ultimaVenta,"DD-MM-YYYY hh:mm:ss A").format("DD/MMM  hh:mm a"),
                                        "e1": equipo.propiedades === null ? '80' : this.ObtenerCode(equipo.propiedades),
                                        "ef2": equipo.propiedades === null ?
                                            '0'
                                            :
                                            parseInt(this.obtenerPendientes(equipo.propiedades)),
                                        "ef3": equipo.notificacion_pendiente === undefined ?
                                            '0'
                                            :
                                            parseInt(equipo.notificacion_pendiente),
                                        "f": [
                                            equipo.estado === "P"
                                                ?
                                                <Tooltip title="ASOCIAR EQUIPO" placement="top">
                                                    <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.DialogasociarEquipo(equipo.id)}>
                                                        {tipoDominio === "V" || tipoDominio === "E" ? <DominioIcon /> : <TiendasIcon />}
                                                    </button>
                                                </Tooltip>
                                                : null
                                            ,
                                            tipoDominio === "E" ? <Tooltip title="VER EN TIEMPO REAL" placement="top">
                                                <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.abrirsurtidorTiempoReal(equipo.id)}>
                                                    <InfoIcon />
                                                </button>
                                            </Tooltip> : null
                                            /*,
                                            tipoDominio === "T" ? <Tooltip title="Monitorear equipo" placement="top">
                                                <button style={{ margin: '5px' }} className="btn btn-primary btn-sm" key={equipo.id} onClick={() => this.abrirKioskoTiempoReal(equipo.empresas_id, equipo.id)}>
                                                    <InfoIcon />
                                                </button>
                                            </Tooltip> : null*/
                                        ]
                                    })
                                    excelre.push({
                                        "a": parseInt(equipo.id),
                                        "a2": equipo.razon_social,
                                        "a1": equipo.bodega,
                                        "b": equipo.alias,
                                        "c": equipo.bodega,
                                        "d": equipo.ultimaConexion === null ? '' : this.obtenerFecha(equipo.ultimaConexion,equipo.propiedades),
                                        "e": equipo.ultimaConexion === null ? '' : this.ultimaFecha(equipo.ultimaConexion,equipo.propiedades),
                                        "e5": equipo.ultimaVenta === null ? '' : moment(equipo.ultimaVenta,"DD-MM-YYYY hh:mm:ss A").format("DD/MMM  hh:mm a"),
                                        "f": equipo.propiedades === null ? '80' : this.ObtenerCode(equipo.propiedades),
                                    })
                                }
                            })
                        }

                    }

                } else if (response.status === 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 5000);
                }
                this.setState({ ListaEquipos: equipos, loader: false, excel: excelre })
            })


    }


    obtenerFecha = (fc, propiedades) => {
        let jsonProps = null
        try {
            jsonProps = JSON.parse(propiedades.propiedades)
        } catch (error) { }
        if (jsonProps) {
            return moment(jsonProps.fecha, "DD-MM-YYYY HH:mm:ss A").format('YYYY-MM-DD hh:mm:ss A');
        } else {
            return moment(fc, "YYYY-MM-DDTHH:mm:ssZ").format('YYYY-MM-DD hh:mm:ss A');
        }
    };


    ultimaFecha = (fc, propiedades) => {
        let jsonProps = null
        try {
            jsonProps = JSON.parse(propiedades.propiedades)
        } catch (error) { }
        if (jsonProps) {
            return moment(jsonProps.fecha, 'DD-MM-YYYY HH:mm:ss A').fromNow();
        } else {
            return moment(fc, 'YYYYMMDD').fromNow();
        }
    };

    actualizarDatos() {
        var modulos = store.getState().perfilesUSuario;
        modulos.map((modulo) => {
            if (modulo.modulos_id == idModulo) {
            }
        });
        this.setState({ actualizarDatos: false });


    }

    handleChangeTABLA = () => {
        this.setState({
            tabla: !this.state.tabla
        });
    };

    handleChangesURTIDOR = () => {
        this.setState({
            surtidor1: !this.state.surtidor1
        })
    };

    TABLA = () => {
        var equipos = {
            columns: tipoDominio === "V"
                ?
                [
                    {
                        label: 'No.',
                        field: 'id',
                        width: 150
                    }, {
                        label: 'Estacion',
                        field: 'Serial',
                        width: 150
                    }, {
                        label: 'Ult. Conexion',
                        field: 'ultimaConexion',
                        width: 150
                    }, {
                        label: 'Tiempo',
                        field: 'ultimaLinea',
                        width: 150
                    }, {
                        label: 'Acciones',
                        field: 'ACC',
                        width: 150
                    }]
                :
                window.screen.width < 720
                    ?
                    [
                        {
                            label: 'No.',
                            field: 'id',
                            width: 150
                        }, {
                            label: 'Tienda',
                            field: 'Serial',
                            width: 150
                        }, {
                            label: 'Tiempo',
                            field: 'ultimaLinea',
                            width: 150
                        }, {
                            label: 'Acciones',
                            field: 'ACC',
                            width: 150
                        }]
                    : [
                        {
                            label: 'No.',
                            field: 'id',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Razon Social',
                            field: 'rs',
                            sort: 'asc',
                            width: 150
                        }, tipoDominio === "E" ?
                            {
                                label: 'Estacion',
                                field: 'estacion',
                                width: 150,
                            } : {
                                label: 'Kiosco',
                                field: 'kiosco',
                                width: 150,
                            }
                        , {
                            label: 'Ultima Conexion',
                            field: 'ultimaConexion',
                            width: 150,
                        }
                        , {
                            label: 'Tiempo',
                            field: 'ultimaLinea',
                            width: 150,
                        }, {
                            label: 'Ultima Venta',
                            field: 'ultvenya',
                            width: 150,
                        }, {
                            label: 'Version POS',
                            field: 'version',
                            width: 150,
                        }, {
                            label: 'Pendientes Ventas',
                            field: 'pendientes',
                            width: 150,
                        }, {
                            label: 'Pendientes Inventarios',
                            field: 'pendientes',
                            width: 150,
                        }, {
                            label: 'Acciones',
                            field: 'ACC',
                            width: 150,
                        }],
            rows: this.state.valueEquipo === 0
                ?
                this.state.ListaEquipos
                :
                this.state.valueEquipo === 1
                    ?
                    this.state.equiposSinActualizar
                    :
                    this.state.equiposSinActualizarPreautorizado


        }
        var n = (
            <MDBDataTable
                //striped
                className="col-md-12"
                searchLabel="Buscar"
                paginationLabel={["Anterior", "Siguente"]}
                infoLabel={["Mostrando", "a", "de", "entradas"]}
                entriesLabel="Filas a mostrar"
                tbodyColor="primary-color"
                theadColor="#FF8800"
                striped
                bordered
                autoWidth={false}
                responsive={true}
                hover
                data={equipos}
                sortable={true}
                order={['id', 'asc']}
                style={{ fontSize: '13px' }}
                disableRetreatAfterSorting={false}
                onSearch={() => this.frenar()}
            />
        );
        return n
    }
    render() {


        return (
            this.state.loader
                ?
                <Loader />
                :
                <div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                    <div className="row mx-auto">
                        <ExcelFile
                            filename={"EQUIPOS"}
                            element={<button className="btn btn-success"><img src={excelIcon} /> EXPORTAR </button>}
                        >
                            <ExcelSheet data={this.state.excel} name="EQUIPOS">
                                <ExcelColumn label="EQUIPO" value="a" />
                                <ExcelColumn label="RAZON SOCIAL" value="a2" />
                                <ExcelColumn label="KIOSCO" value="b" />
                                <ExcelColumn label="BODEGA" value="a1" />
                                <ExcelColumn label="ULTIMA CONEXION" value="d" />
                                <ExcelColumn label="TIEMPO" value="e" />
                                <ExcelColumn label="ULTIMA VENTA" value="e5" />
                                <ExcelColumn label="VERSION" value="f" />

                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                    <div className="row">
                        <div className="col col-md-12" style={{ padding: 0 }}>

                            <Paper style={{ padding: '5px' }}>
                                <Tabs
                                    value={this.state.valueEquipo}
                                    className="border"
                                    variant="scrollable"
                                    scrollButtons="on"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    style={{ zIndex: '800 !IMPORTANT' }}
                                >

                                    <Tab label="TIEMPO REAL" className="border" onClick={() => this.changetabEquipo(0)} icon={<ListIcon />} />
                                    <Tab label="TODOS" className="border" onClick={() => this.changetabEquipo(1)} icon={<ListIcon />} />
                                    <Tab label="SIN ASOCIAR" className="border" onClick={() => this.changetabEquipo(2)} icon={<ListIcon />} />
                                </Tabs>

                                {this.TABLA(this.state.valueEquipo)}


                                <Dialog
                                    open={this.state.dialogAsociacion}
                                    onClose={() => this.cerrarAsociacionEquipo()}
                                    aria-labelledby="form-dialog-title"
                                    maxWidth={'md'}
                                    fullWidth={true}
                                    style={{ padding: '0' }}
                                    disableBackdropClick={false}
                                >
                                    <DialogContent style={{ padding: '10px' }}>
                                        <div className="row mx-auto">
                                            <div >
                                                <h5>ASOCIACION DEL EQUIPO -- {this.state.idPreautorizado}</h5>
                                            </div>
                                        </div>
                                        <div className="row mx-auto" style={{ paddingBottom: '300px' }}>
                                            <div className="col-md-8">
                                                <label htmlFor="numordencompra" className="col-form-label">EMPRESA</label>
                                                <Selects
                                                    value={this.state.tiendaSeleccionada}
                                                    onChange={this.handleChanges('tiendaSeleccionada')}
                                                    options={this.state.tiendasbd}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="numordencompra" className="col-form-label">BODEGA</label>
                                                <Selects
                                                    value={this.state.bodegaSeleccionada}
                                                    onChange={this.handleChanges('bodegaSeleccionada')}
                                                    options={this.state.bodegasbd}
                                                    isDisabled={JSON.stringify(this.state.tiendaSeleccionada) === "{}"}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mx-auto">
                                            <div className="col-md-4 mx-auto" style={{ float: "right", padding: '8px' }}>
                                                <button className="btn btn-primary" type="reset" onClick={() => this.asociarEquipo()}>
                                                    ASOCIAR
                                            </button>
                                            </div>
                                            <div className="col-md-2 mx-auto" style={{ float: "right", padding: '8px' }}>
                                                <button className="btn btn-danger" type="reset" onClick={() => this.cerrarAsociacionEquipo()}>
                                                    Cerrar
                                            </button>
                                            </div>
                                        </div>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    open={this.state.kioskoTiempoReal}
                                    onClose={() => this.cerrarKioskoTiempoReal()}
                                    aria-labelledby="form-dialog-title"
                                    maxWidth={'md'}
                                    fullWidth={true}
                                    style={{ padding: '0' }}
                                    disableBackdropClick={false}
                                >
                                    <DialogContent style={{ padding: '10px' }}>
                                        <div className="row mx-auto">
                                            <div >
                                                <h5><b>NOTIFICACIONES PENDIENTES DEL EQUIPO</b></h5>
                                            </div>
                                        </div>
                                        <div className="row mx-auto">
                                            <Tabs
                                                value={this.state.value}
                                                className="border"
                                                variant="scrollable"
                                                scrollButtons="on"
                                                indicatorColor="primary"
                                                textColor="primary"
                                                style={{ zIndex: '800 !IMPORTANT' }}
                                            >

                                                <Tab label="Detalles de producto" className="border" onClick={() => this.changetab(0)} icon={<ListIcon />} />
                                                <Tab label="Actualizacion de personas" className="border" onClick={() => this.changetab(1)} icon={<ListIcon />} />
                                                <Tab label="Actualizacion de productos" className="border" onClick={() => this.changetab(2)} icon={<ListIcon />} />
                                                <Tab label="Informacion de la bodega" className="border" onClick={() => this.changetab(3)} icon={<ListIcon />} />
                                                <Tab label="Actualizacion de empresa" className="border" onClick={() => this.changetab(4)} icon={<ListIcon />} />
                                                <Tab label="Consecutivos" className="border" onClick={() => this.changetab(5)} icon={<ListIcon />} />
                                            </Tabs>
                                            <Paper style={{ width: '100vw' }}>

                                            </Paper >
                                        </div>
                                        <div className="row mx-auto">
                                            <div className="col-md-2 mx-auto" style={{ float: "right", padding: '8px' }}>
                                                <button className="btn btn-danger" type="reset" onClick={() => this.cerrarKioskoTiempoReal()}>
                                                    Cerrar
                                            </button>
                                            </div>
                                        </div>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    open={this.state.dialogDetallesCampañas}
                                    onClose={() => this.cerrarCampanasAterrizados()}
                                    aria-labelledby="form-dialog-title"
                                    maxWidth={'md'}
                                    fullWidth={true}
                                    style={{ padding: '0' }}
                                    disableBackdropClick={false}
                                >
                                    <DialogContent style={{ padding: '10px' }}>
                                        <div className="row mx-auto">
                                            <div className="col-md-12">
                                                <h5>CAMPAÑAS ACTUALES DE {this.state.edsCampana}</h5>
                                            </div>
                                        </div>
                                        <div className="row mx-auto">
                                            <Paper style={{ padding: '5px', width: '100%' }}>

                                                <MDBDataTable
                                                    //striped
                                                    className="col-md-12"
                                                    searchLabel="Buscar"
                                                    paginationLabel={["Anterior", "Siguente"]}
                                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                    entriesLabel="Filas a mostrar"
                                                    tbodyColor="primary-color"
                                                    theadColor="#FF8800"
                                                    exportToCSV={true}
                                                    striped
                                                    bordered
                                                    autoWidth={false}
                                                    responsive={true}
                                                    hover
                                                    data={{
                                                        columns: [{
                                                            label: 'Campaña',
                                                            field: 'campaña',
                                                            width: 50
                                                        }, {
                                                            label: 'Ult. Actualizacion',
                                                            field: 'fecha',
                                                            width: 50
                                                        }, {
                                                            label: 'Bonos',
                                                            field: 'bonos',
                                                            width: 150
                                                        }
                                                        ], rows: this.state.detallesCampañas != null ?
                                                            this.state.detallesCampañas.map((campana) => {
                                                                return ({
                                                                    'campana': campana.descripcion,
                                                                    'fecha': campana.ultima_actualizacion != null || campana.ultima_actualizacion != '' ? moment(campana.ultima_actualizacion).format("DD/MM/YYYY hh:mm a") : '',
                                                                    'bonos': campana.bonos != null ?
                                                                        campana.bonos.map((bono) => {
                                                                            return (
                                                                                <div className="col-md-12">
                                                                                    <List style={{ backgroundColor: "#fff", padding: '0' }} className="border rounded">
                                                                                        <ListItem className="border">
                                                                                            <ListItemAvatar>
                                                                                                <Avatar><BonosIcon /></Avatar>
                                                                                            </ListItemAvatar>
                                                                                            <ListItemText
                                                                                                primary={bono.descripcion}
                                                                                                secondary={
                                                                                                    <React.Fragment>
                                                                                                        <Typography
                                                                                                            component="span"
                                                                                                            variant="body2"
                                                                                                            color="textPrimary"
                                                                                                        >
                                                                                                            {moment(new Date(bono.hora_inicio)).format("hh:mm a") + " - " + moment(new Date(bono.hora_fin)).format("hh:mm a")}
                                                                                                        </Typography>
                                                                                                        {"$ " + this.setearMiles(bono.valor_minimo) + " - $" + this.setearMiles(bono.valor_maximo)}
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                            />

                                                                                        </ListItem>

                                                                                    </List>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        'NO TIENE'
                                                                })
                                                            })
                                                            : []
                                                    }}
                                                    sorting={true ? 1 : 0}
                                                    style={{ fontSize: '13px' }}
                                                />
                                            </Paper>
                                        </div>
                                        <div className="row mx-auto">

                                            <div className="col-md-2 mx-auto" style={{ float: "right", padding: '8px' }}>
                                                <button className="btn btn-danger" type="reset" onClick={() => this.cerrarCampanasAterrizados()}>
                                                    Cerrar
                                            </button>
                                            </div>
                                        </div>
                                    </DialogContent>
                                </Dialog>
                                {
                                    window.screen.width < 720
                                        ?
                                        <Dialog fullScreen open={this.state.surtidorTiempoReal} onClose={this.cerrarSurtidorTiempoReal} TransitionComponent={Transition}>
                                            <DialogContent style={styles.dialog}>
                                                <div className="row">
                                                    <FormControlLabel
                                                        className="col-md-6 col-sm-6"
                                                        control={
                                                            <Switch
                                                                onChange={this.handleChangesURTIDOR.bind(this)}
                                                                value="checkedA"
                                                            />
                                                        }
                                                        label={this.state.surtidor1 ? <h6 style={{ color: "white" }} className="labelbebas">UNA CARA</h6> : <h6 style={{ color: "white" }} className="labelbebas">DOS CARAS</h6>}
                                                    />

                                                </div>
                                                <div className="row mx-auto">
                                                    {this.state.surtidor1
                                                        ?
                                                        <div id="body3" className="fndhead">

                                                            <div id="leftxx_2" style={{ float: 'left' }}>

                                                                <div className="cuadnum_uno">

                                                                    <div id="left2b4">
                                                                        <div><font className="surticara4">
                                                                            <center id="surtCara">1 - 1</center>
                                                                        </font>
                                                                        </div>
                                                                        <div style={{ paddingBottom: '3px', paddingTop: '35px' }}><font className="surticara2">
                                                                            <center>
                                                                                SURTIDOR - CARA
                                                                            </center>
                                                                        </font></div>

                                                                    </div>
                                                                </div>

                                                                <div id="labelnumeros" style={{ marginTop: '30px', marginBottom: '10px' }}>
                                                                    <div id="center_i3">
                                                                        <div>
                                                                            <div className="labelbebas" style={{ paddingRight: '5px', paddingTop: '3px', marginTop: '9px', color: '#666' }}>VENTA</div>
                                                                            <div className="labelbebas" style={{ paddingRight: '5px', paddingTop: '3px', marginTop: '9px', color: '#666' }}>GALONES</div>
                                                                            <div className="labelbebas" style={{ paddingRight: '5px', paddingTop: '3px', marginTop: '9px', color: '#666' }}>PRECIO $</div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="rigth">
                                                                        <div id="rigthcont">
                                                                            <div id="surventa" className="cuadnum">0</div>
                                                                            <div id="surgalones" className="cuadnum">0</div>
                                                                            <div id="surprecio" className="cuadnum">0</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="oilall" style={{ marginTop: '340px' }}>


                                                                <div style={{ width: '260px', float: 'left', marginTop: '7px' }}>
                                                                    <div id="left2b1_4" style={{ backgroundColor: '#000000', margin: '5px', marginLeft: '30px' }}>

                                                                        <div className="estado" id={'estado0'}>COLGADO</div>
                                                                        <div style={{ height: '20px', border: 'solid #000000 2px', backgroundColor: '#00F' }}><span className="estado"><center>EXTRA</center></span></div>
                                                                    </div>
                                                                    <div id="left2b1_4" style={{ backgroundColor: '#000000', margin: '5px', marginLeft: '30px' }}>

                                                                        <div className="estado" id={'estado1'}>COLGADO</div>
                                                                        <div style={{ height: '20px', border: 'solid #000000 2px', backgroundColor: '#093' }}><span className="estado"><center>
                                                                            DIESEL
                                                                    </center></span></div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ width: '260px', float: 'left', marginTop: '7px' }}>
                                                                <div id="left2b1_4" style={{ backgroundColor: '#000000', margin: '5px', marginLeft: '30px' }}>

                                                                    <div className="estado" id={'estado2'}>COLGADO</div>
                                                                    <div style={{ height: '20px', border: 'solid #000000 2px', backgroundColor: '#999' }}><span className="estado"><center>
                                                                        CORRIENTE
                                                                    </center></span></div>
                                                                </div>
                                                                <div id="left2b1_4" style={{ backgroundColor: '#000000', margin: '5px', marginLeft: '30px' }}>

                                                                    <div className="estado" id={'estado3'}>COLGADO</div>
                                                                    <div style={{ height: '20px', border: 'solid #000000 2px', backgroundColor: '#F00' }}><span className="estado"><center>
                                                                        CORRIENTE
                                                                    </center></span></div>
                                                                </div>
                                                            </div>


                                                            <div style={{ width: '260px', float: 'left', marginTop: '7px' }}>
                                                                <div id="left2b1_4" style={{ margin: '5px' }}>
                                                                    <img src={marcaimg} alt="" />
                                                                </div>
                                                                <div id="left2b1_4" style={{ margin: '20px 0px 0px 70px' }}>
                                                                    <img src={estadoimg} alt="" />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        :
                                                        <div id="body" className="fndhead">
                                                            <div className="etiquetas2">
                                                                <div id="left2b1" style={{ marginRight: '10px', alignItems: 'center' }}>
                                                                    <div id="left2b" style={{ background: '#516272', borderRadius: '20px 20px 20px 20px' }}>
                                                                        <div><font className="surticara"><center>1</center></font></div>
                                                                        <div style={{ paddingBottom: '3px', paddingTop: '10px' }}><font className="surticara2"><center>SURTIDOR</center></font></div>
                                                                        <div style={{ height: '20px' }}></div>
                                                                        <div><font className="surticara"><center>1</center></font></div>
                                                                        <div style={{ paddingBottom: '3px', paddingTop: '10px' }}><font className="surticara2"><center>CARA</center></font></div>
                                                                    </div>
                                                                    <div className="estado2b" style={{ marginTop: '8px', height: '30px' }}>DESCOLGADO</div>
                                                                </div>
                                                                <div id="center3">
                                                                    <div id="right">
                                                                        <div className="lablestext"> VENTA:</div>
                                                                        <div id="surtiVenta_2" className="cuadnum">0</div>
                                                                        <div className="lablestext"> VOLUMEN:</div>
                                                                        <div id="surtiVolumen_2" className="cuadnum">0</div>
                                                                        <div className="lablestext"> PRECIO:</div>
                                                                        <div id="surtiPrecio_2" className="cuadnum">0</div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div style={{ height: '5px', width: '95%', backgroundColor: '#000', float: 'left', marginTop: '6px', marginLeft: '10px', marginBottom: '10px' }}>
                                                            </div>


                                                            <div className="etiquetas2">
                                                                <div id="left2b1" style={{ marginRight: '10px', alignItems: 'center' }}>
                                                                    <div id="left2b" style={{ background: '#6296B3', borderRadius: ' 20px 20px 20px 20px' }}>
                                                                        <div><font className="surticara"><center>1</center></font></div>
                                                                        <div style={{ paddingBottom: '3px', paddingTop: '10px' }}><font className="surticara2"><center>SURTIDOR</center></font></div>
                                                                        <div style={{ height: '20px' }}></div>
                                                                        <div>< font className="surticara"><center>
                                                                            2
                                                                </center></font></div>
                                                                        <div style={{ paddingBottom: '3px', paddingTop: '10px' }}><font className="surticara2"><center>CARA</center></font></div>
                                                                    </div>
                                                                    <div className="estado2" style={{ marginTop: '8px', height: '30px' }}>COLGADO</div>
                                                                </div>
                                                                <div id="center3">
                                                                    <div id="right">
                                                                        <div className="lablestext"> VENTA:</div>
                                                                        <div id="surtiVenta_3" className="cuadnum">0</div>
                                                                        <div className="lablestext"> VOLUMEN:</div>
                                                                        <div id="surtiVolumen_3" className="cuadnum">0</div>
                                                                        <div className="lablestext"> PRECIO:</div>
                                                                        <div id="surtiPrecio_3" className="cuadnum">0</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ width: '100%', float: 'left', marginTop: '7px' }}>
                                                                <div id="left2b1_4" style={{ margin: '10px 50px 0px 20px' }}>
                                                                    <img src={marcaimg} alt="" />
                                                                </div>
                                                                <div id="left2b1_4" style={{ margin: '20px 0px 0px 80px' }}>
                                                                    <img src={estadoimg} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    }
                                                </div>

                                                <div className="mx-auto">
                                                    <center style={{ marginTop: '8px' }}>
                                                        <button onClick={() => this.cerrarSurtidorTiempoReal()} style={{ backgroundColor: 'transparent', border: 0 }}>
                                                            <img src={fondoBtn}></img>
                                                        </button>
                                                    </center>
                                                </div>
                                            </DialogContent >

                                        </Dialog >
                                        :
                                        <Dialog
                                            open={this.state.surtidorTiempoReal}
                                            onClose={() => this.cerrarSurtidorTiempoReal()}
                                            aria-labelledby="form-dialog-title"
                                            maxWidth={'md'}
                                            style={{ padding: '0' }}
                                            disableBackdropClick={false}
                                        >
                                            <DialogContent style={styles.dialog}>
                                                <div className="row">
                                                    <FormControlLabel
                                                        className="col-md-6"
                                                        control={
                                                            <Switch
                                                                onChange={this.handleChangesURTIDOR.bind(this)}
                                                                value="checkedA"
                                                            />
                                                        }
                                                        label={this.state.surtidor1 ? <h6 style={{ color: "white" }} className="labelbebas">UNA CARA</h6> : <h6 style={{ color: "white" }} className="labelbebas">DOS CARAS</h6>}
                                                    />
                                                    <div className="col-md-5" style={{ float: 'right' }}>
                                                        <img src={estadoimg}></img>
                                                    </div>
                                                </div>
                                                <div className="row mx-auto">
                                                    {this.state.surtidor1
                                                        ?
                                                        <div id="body3" className="fndhead">
                                                            <div id="leftxx">
                                                                <div className="cuadnum_uno">
                                                                    <div id="lefto">
                                                                        <div id="left2b4">
                                                                            <div>
                                                                                <font className="surticara4">
                                                                                    <center id="surtCara">{mensajereal != null ? mensajereal[0] != null ? (mensajereal[0].surtidor + " - " + mensajereal[0].cara) : "0 - 0" : "0 - 0"}</center>
                                                                                </font>
                                                                            </div>
                                                                            <div style={{ paddingBottom: '3px', paddingTop: '8px' }}>
                                                                                <font className="surticara2">
                                                                                    <center>
                                                                                        SURTIDOR - CARA
</center>
                                                                                </font>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ height: '135px', backgroundColor: '#000000' }}>
                                                                <div id="center_i3">
                                                                    <div>
                                                                        <div className="labelbebas" style={{ padding: '5px' }}>VENTA</div>
                                                                        <div className="labelbebas" style={{ padding: '5px', marginTop: '7px' }}>GALONES</div>
                                                                        <div className="labelbebas" style={{ padding: '5px', marginTop: '7px' }}>PRECIO</div>
                                                                    </div>
                                                                </div>
                                                                <div id="rigth">
                                                                    <div id="rigthcont">
                                                                        <div id="surventa" className="cuadnum">{mensajereal != null ? mensajereal[0] != null ? this.setearMiles(mensajereal[0].venta) : "0" : "0"}</div>
                                                                        <div id="surgalones" className="cuadnum">{mensajereal != null ? mensajereal[0] != null ? this.setearMiles(mensajereal[0].volumen) : '0' : "0"}</div>
                                                                        <div id="surprecio" className="cuadnum">{mensajereal != null ? mensajereal[0] != null ? this.setearMiles(mensajereal[0].precio) : '0' : "0"}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="totalizador4">

                                                                <div id="left2b1_4">
                                                                    <div id="left2_4">
                                                                        <div className="cuadnum4" id={'manguera0'}>8710</div>
                                                                    </div>
                                                                    <div id="left2b_4" style={{ backgroundColor: '#252C3C' }}>
                                                                        <div className="estado" id={'estado0'}>COLGADA</div>
                                                                    </div>
                                                                    <div style={
                                                                        { height: '25px', width: '90px', border: 'solid #000000 2px', backgroundColor: '#FF0000' }
                                                                    }>
                                                                        <font className="surticara2">
                                                                            <center>
                                                                                <b>CORRIENTE</b>
                                                                            </center>
                                                                        </font>
                                                                    </div>
                                                                </div>
                                                                <div id="left2b1_4">
                                                                    <div id="left2_4">
                                                                        <div className="cuadnum4" id={'manguera1'}>8710</div>
                                                                    </div>
                                                                    <div id="left2b_4" style={{ backgroundColor: '#252C3C' }}>
                                                                        <div className="estado" id={'estado1'}>COLGADA</div>
                                                                    </div>
                                                                    <div style={
                                                                        { height: '25px', width: '90px', border: 'solid #000000 2px', backgroundColor: '#1200FF' }
                                                                    }>
                                                                        <font className="surticara2">
                                                                            <center>
                                                                                <b> EXTRA</b>
                                                                            </center>
                                                                        </font>
                                                                    </div>
                                                                </div>
                                                                <div id="left2b1_4">
                                                                    <div id="left2_4">
                                                                        <div className="cuadnum4" id={'manguera2'}>8710</div>
                                                                    </div>
                                                                    <div id="left2b_4" style={{ backgroundColor: '#252C3C' }}>
                                                                        <div className="estado" id={'estado2'}>COLGADA</div>
                                                                    </div>
                                                                    <div style={
                                                                        { height: '25px', width: '90px', border: 'solid #000000 2px', backgroundColor: '#FFED00' }
                                                                    }>
                                                                        <font className="surticara2">
                                                                            <center style={{ color: 'black' }}>
                                                                                <b> DIESEL</b>
                                                                            </center>
                                                                        </font>
                                                                    </div>
                                                                </div>
                                                                <div id="left2b1_4">
                                                                    <div id="left2_4">
                                                                        <div className="cuadnum4" id={'manguera3'}>8710</div>
                                                                    </div>
                                                                    <div id="left2b_4" style={{ backgroundColor: '#252C3C' }}>
                                                                        <div className="estado" id={'estado3'}>COLGADA</div>
                                                                    </div>
                                                                    <div style={
                                                                        { height: '25px', width: '90px', border: 'solid #000000 2px', backgroundColor: '#FF0000' }
                                                                    }>
                                                                        <font className="surticara2">
                                                                            <center>
                                                                                <b>CORRIENTE</b>
                                                                            </center>
                                                                        </font>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div id="body" className="fndhead">

                                                            <div className="etiquetas2">
                                                                <div id="left2b1">
                                                                    <div id="left2b" style={{ background: "#516272", borderRadius: "20px 20px 0px 0px" }}>
                                                                        <div><font className="surticara"><center id="surtCara_2">1 - 1</center></font></div>
                                                                        <div style={{ paddingBottom: "3px" }}><font className="surticara2"><center>SURTIDOR - CARA</center></font></div>
                                                                    </div>
                                                                </div>

                                                                <div id="center3">
                                                                    <div style={{ height: "45px", align: "center" }}></div>
                                                                </div>
                                                                <div id="left2b1b">
                                                                    <div id="left2bb" style={{ background: "#516272", borderRadius: "20px 20px 0px 0px" }}>
                                                                        <div><font className="surticara"><center id="surtCara_3">1 - 1</center></font></div>
                                                                        <div style={{ paddingBottom: "3px" }}><font className="surticara2"><center>SURTIDOR - CARA</center></font></div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div id="left_2">
                                                                <div id="leftcont">
                                                                    <div id="surtiVenta_2" className="cuadnum_2">0000000</div>
                                                                    <div id="surtiVolumen_2" className="cuadnum_2">0000000</div>
                                                                    <div id="surtiPrecio_2" className="cuadnum_2">0000000</div>
                                                                </div>
                                                            </div>
                                                            <div id="center_2">
                                                                <div className="labelbebas" style={{ padding: "8px", marginTop: "5px", backgroundColor: "#4D5D6C" }}>VENTA</div>
                                                                <div className="labelbebas_2" style={{ padding: "2px", marginTop: "5px", backgroundColor: "#4D5D6C" }}>GALONES</div>
                                                                <div className="labelbebas" style={{ padding: "8px", marginTop: "8px", backgroundColor: "#4D5D6C" }}>PRECIO</div>
                                                            </div>
                                                            <div id="rigth_2">
                                                                <div id="rigthcont">
                                                                    <div id="surtiVenta_3" className="cuadnum_2">0000000</div>
                                                                    <div id="surtiVolumen_3" className="cuadnum_2">0000000</div>
                                                                    <div id="surtiPrecio_3" className="cuadnum_2">0000000</div>
                                                                </div>
                                                            </div>

                                                            <div className="totalizador">
                                                                <div id="left2b1">
                                                                    <div id="left2">
                                                                        <div className="cuadnum_2b">00.000.000</div>
                                                                    </div>
                                                                    <div id="left2b">
                                                                        <div id="estado_2" className="estado2">COLGADO</div>
                                                                    </div>
                                                                </div>

                                                                <div id="center2">
                                                                    <div style={{ height: "56px", align: "center", paddingTop: "15px", }}></div>
                                                                </div>

                                                                <div id="left2b13">
                                                                    <div id="rigth2">
                                                                        <div className="cuadnum_2b">00.000.000</div>
                                                                    </div>
                                                                    <div className="left2b3">
                                                                        <div id="estado_3" className="estado2">DESCOLGADO</div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    }
                                                </div>

                                                <div className="mx-auto">
                                                    <center style={{ marginTop: '8px' }}>
                                                        <button onClick={() => this.cerrarSurtidorTiempoReal()} style={{ backgroundColor: 'transparent', border: 0 }}>
                                                            <img src={fondoBtn}></img>
                                                        </button>
                                                    </center>
                                                </div>
                                            </DialogContent>

                                        </Dialog>
                                }

                            </Paper >
                        </div >
                    </div >
                </div >

        );
    }
}


export default Equipos;