import React from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import Person from "@material-ui/icons/ExitToApp";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import AbajoIcon from "@material-ui/icons/AccountCircle";
import Button from "components/CustomButtons/Button.jsx";
import store from "../../lib/store"
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import 'bootstrap/dist/css/bootstrap.min.css';
import Menu from '@material-ui/core/Menu';
///
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Server from '../../lib/server'
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popper from "@material-ui/core/Popper";
import Axios from 'axios'
import './navBar.css'

class HeaderLinks extends React.Component {

  state = {
    open: false,
    anchorEl: null,
    setAnchorEl: null,
    openMenu: false,
    anchorRef: null
  };
  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  Menu = () => {
    this.setState(state => ({ openMenu: !state.openMenu }));
  }



  handleClick = (event) => {
    this.setState({ setAnchorEl: event.currentTarget })
  }

  handleCloses = () => {
    this.setState({ setAnchorEl: null })
  }
  cerrarSesion = () => {
    //var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
    var ntoken = localStorage.getItem('token')
    Axios.delete(Server.hostPruebasinssl+Server.puertoAutenticacion+"/", { headers: { Authorization: ntoken } })
      .then(response => {
      })
      .catch(function (error) {
        console.log(error);
      })
    localStorage.removeItem('token');
    //window.location.reload();
    window.location.href = window.location.href = "/";
  }


  render() {
    const { classes } = this.props;
    const { open } = this.state;
    const usuario = store.getState().usuario
    var anchorRef = this.state.anchorRef
    //console.log(usuario)
    return (
      <div className="row" style={{ width: '100%', display: 'inline', flex: 1 }}>
        <div className={classes.manager}>
          <DropdownButton
            title={usuario.nombres + " " + usuario.apellidos}
            variant={"sdsddsd"}
            id={`dropdown-variants-sdsddsd`}
            key={"sdsddsd"}
            style={{ float: 'right !IMPORTANT', color: '#fff !IMPORTANT' }}
            className="logout"
          >
            <Dropdown.Header>{usuario.nombres + " " + usuario.apellidos}</Dropdown.Header>
            <Dropdown.Header>{usuario.empresas != undefined ? usuario.empresas.razon_social : 'N'}</Dropdown.Header>
            {/*<Dropdown.Item eventKey="1">Action</Dropdown.Item>*/}
            <Dropdown.Divider />
            <Dropdown.Item eventKey="4" onClick={this.cerrarSesion.bind(this)}>CERRAR SESION</Dropdown.Item>
          </DropdownButton>
          {/*<Button
            buttonRef={node => {
              this.anchorEl = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={open ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggle}
            children={null}
          >
            <AbajoIcon />
            {window.innerWidth <= 720 ? <p>{usuario.nombres + " " + usuario.apellidos}</p> : <small>{usuario.nombres + " " + usuario.apellidos}</small>}

          </Button>*/}
          <Poppers
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !open }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.handleClose}
                        className={classes.dropdownItem}
                      >
                        Se estan acabando las resoluciones
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleClose}
                        className={classes.dropdownItem}
                      >
                        Se han creado 5 productos nuevos
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
        {/*<div className={classes.manager} style={window.innerWidth > 720 ? { marginLeft: '40px' } : {}}>
          <Button
            buttonRef={node => {
              this.anchorEl = node;
            }}
            color={window.innerWidth > 420 ? "transparent" : "white"}
            justIcon={window.innerWidth > 420}
            simple={!(window.innerWidth > 420)}
            aria-owns={open ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.cerrarSesion}
            className={classes.buttonLink}
            children={null}
          >
            <Person className={classes.icons} />
            <p onClick={this.handleClick} className={classes.linkText}>
              Cerrar sesion
              </p>
          </Button>

          </div>*/}
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
