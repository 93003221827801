import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { MDBDataTable } from 'mdbreact';
import Paper from '@material-ui/core/Paper';
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide"
import store from '../../lib/store'
import Server from '../../lib/server'
import Axios from 'axios'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const idModulo = 21
var permisos = ''
class Traslados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //Variables de componente principal
            createOrdenDeCompra: false,
            actualizarDatos: false,
            movimientosbd: []
        }
        store.subscribe(() => {
            this.setState({
                createOrdenDeCompra: store.getState().trasladoBodega,
                actualizarDatos: store.getState().actualizarvista,
            })
        })
    }

    handleCrearOrdenDeCompra = () => {
        store.dispatch({
            type: "TRASLADO_BODEGA",
            trasladoBodega: true
        })
    }

    componentWillMount = () => {
        //Permisos usuarios
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
        })
        this.obtenerDatos()
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizarDatos) {
            this.obtenerDatos()
            return true
        }
        return true;
    }

    obtenerDatos = () => {
        this.setState({ actualizarDatos: false })
        var usuario = store.getState().usuario
        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/movimiento/lista/operacion/' + parseInt(usuario.empresas_id, 10) + '/5,6,11')
            .then(responsee => {
                var movimientosTotal = []
                console.log(responsee.data.movimientos)
                if (responsee.status == 200) {
                    responsee.data.movimientos.map((mov, key) => {
                        movimientosTotal.push({
                            "consecutivo": mov.movimiento.consecutivo,
                            "fecha": mov.movimiento.fecha,
                            "operacion": mov.movimiento.operacion_desc,
                            "responsable": mov.movimiento.personas_id_detalles.nombres + " " + mov.movimiento.personas_id_detalles.apellidos,
                        })
                    })
                    console.log(responsee.data.movimientos)
                    this.setState({ movimientosbd: movimientosTotal })
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })

    }

    render() {

        return (
            <div>
                <div className="row" style={{ marginTop: "15px" }}>
                    <div className="col col-md-12" style={{ padding: 0 }}>
                        <Paper style={{ padding: '5px' }}>
                            <div className="row">
                                <div className="col-md-7">

                                </div>
                                <div className="col-md-5">
                                    <div className="row">
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </div>

                </div>
            </div>
        )
    }
}
export default Traslados