
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import 'bootstrap/dist/css/bootstrap.min.css';
import CodigoIcon from '@material-ui/icons/LineWeight'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import store from '../../../lib/store'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
class dialogTelefono extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            codigos: [],
            codigoBarra: '',
            auxCodigoBarra:[],
            aucCodigoBarraCancelar:[]
        };
    }

    componentWillMount() {
        const { codigosn } = this.props
        this.setState({ codigos: codigosn })
    }

    guardarTelefonos = () => {
        store.dispatch({
            type: "AGREGAR_IDENTIFICADOR",
            codigos: this.state.codigos,
            creacionCodigo: false
        })
    }

    eliminarIdentificador = (e) => {
        var mIdentificador = this.state.codigos
        var auxiliar = this.state.auxCodigoBarra
        auxiliar.push(mIdentificador[e])
        mIdentificador.splice(e, 1)
        this.setState({ codigos: mIdentificador,auxCodigoBarra:auxiliar })
    }

    cerrarVentana = () => {
        var codigosGuardar = []
        this.state.codigos.map((codigo)=>{
            codigosGuardar.push(codigo)
        })
        this.state.auxCodigoBarra.map((codigo)=>{
            codigosGuardar.push(codigo)
        })
        store.dispatch({
            type: "AGREGAR_IDENTIFICADOR",
            codigos: codigosGuardar,
            creacionCodigo: false
        })
    }

    agregarTelefono = (codigos) => {
        var codigoNuevo = this.state.codigos
        if (store.getState().auxiliar2.includes(codigos)) {
            alert('Este codigo de barra ya existe, por favor ingrese otro')
        } else {
            codigoNuevo.push({ codigos })
            this.setState({ codigos: codigoNuevo, codigoBarra: '' })
        }

    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };


    render() {

        //this.obtenerTelefonos(telefonos)
        return (
            <Card style={{ marginTop: "0", paddingBottom: "15px" }}>
                <DialogTitle id="form-dialog-title">CODIGOS DE BARRA</DialogTitle>
                <CardContent>
                    <div className="row bottom-aligned">
                        <div className="col-md-10">
                            <label htmlFor="numordencompra" className="col-form-label">CODIGO DE BARRA</label>
                            <input id="numordencompra" value={this.state.codigoBarra} className="form-control" type="number" min="1" autoComplete="off" onChange={this.handleChange('codigoBarra')} />
                        </div>
                        <div className="col-md-2 bottom-aligned">
                        <Tooltip title="AGREGAR CODIGO DE BARRA AL PRODUCTO" placement="top">
                            <button className="btn btn-primary" disabled={this.state.codigoBarra == ''} onClick={() => this.agregarTelefono(this.state.codigoBarra)}>+</button>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="row mx-auto">
                        <List className="col col-md-10 mx-auto" >
                            {this.state.codigos.map((contacto, key) => {
                                return (
                                    <ListItem className="mx-auto border" key={key}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <CodigoIcon rotate="90" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={contacto.codigos} />
                                        <ListItemSecondaryAction>
                                            <IconButton aria-label="Delete" onClick={() => this.eliminarIdentificador(key)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </div>
                    <div className="row bottom-aligned">
                        <div className="col-md-9">
                            <button className="btn btn-danger" onClick={this.cerrarVentana} style={{ float: 'right' }}>CANCELAR</button>
                        </div>
                        <div className="col-md-3">
                            <button className="btn btn-success" onClick={this.guardarTelefonos} style={{ float: 'right' }}>GUARDAR</button>
                        </div>
                    </div>
                </CardContent>
            </Card>
        )
    }
}


export default dialogTelefono