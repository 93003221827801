import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios';
import Server from '../../../lib/server';
import store from '../../../lib/store';
import Paper from '@material-ui/core/Paper';
//import {FormHelperText ,FormControl,FormLabel ,FormGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Selects from 'react-select';
import swal from 'sweetalert';
import { MDBDataTable } from 'mdbreact';
import EditICon from '@material-ui/icons/Edit';
import DeleteIcon from '../../../assets/img/delete.png';
import SuccessIcon from '../../../assets/img/success.png';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Swal from 'sweetalert2';
//import { makeStyles } from '@material-ui/core/styles';

const idModulo = 46;
var permisos = '';
var ntoken = 'Bearer '.concat(localStorage.getItem('token'));

  
class GestionModulos extends React.Component{

	
	constructor(props){
		super(props);
		this.state = {
			descripcion: '',
			permisos: '',
			nivel: '',
			padreCheck: false,
			negocios_tipo: '',
			padreSeleccionado: {},
			modulosbd: [],
			modulosTotal: [],
			actualizarDatos: false,
			abrirDialog: false,
			id: ''
		};
		store.subscribe(() => {
			this.setState({
				actualizarDatos: store.getState().actualizarvista,
			});
		});


		this.handleDescripcion = this.handleDescripcion.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.handlePermisos = this.handlePermisos.bind(this);
		this.handleNegocios = this.handleNegocios.bind(this);
		this.handleNivel = this.handleNivel.bind(this);
	}
	
	handleDescripcion(evt){
		this.setState({
			descripcion: evt.target.value.toUpperCase()
		});
	}

	handleNivel(evt){
		this.setState({
			nivel: parseInt(evt.target.value)
		});
	}

	handleNegocios(evt) {
		this.setState({
			negocios_tipo: evt.target.value.toUpperCase()
		});
	}

	handleSelect(val){
		this.setState({
			padreSeleccionado: val
		});
	}

	handlePermisos(evt) {
		this.setState({
			permisos: evt.target.value.toUpperCase()
		});
	}

	handleChangeCheck = name => event => {
		var estado = (event.target.value == 'false' ? false : true)
		this.setState({
			[name]: !estado
		})
	}

	toast = (options) => {
		if(!options.timer){
			options.timer = 5000;
		}
		if(!options.title){
			options.title = "Ejecutado con éxito";
		}
		if(!options.icon){
			options.icon = "success";
		}
		const Toast = Swal.mixin({
			toast: true,
			position: 'bottom-end',
			showConfirmButton: false,
			timer: options.timer,
			timerProgressBar: true,
			onOpen: (toast) => {
			  toast.addEventListener('mouseenter', Swal.stopTimer);
			  toast.addEventListener('mouseleave', Swal.resumeTimer);
			}
		});
		
		Toast.fire({
			icon: options.icon,
			title: options.title
		});
	};

	obtenerEmpresas = () => {
		store.dispatch({
			type: "ACTUALIZACION_DATOS",
			actualizarvista: false
		});

        Axios.get(Server.hostPrueba + Server.puertParametrizacion + '/api/parametrizacion/entidades/modulos', { headers: { Authorization: ntoken } })
		.then(responsee => {
			var modulos = [];
			var modulosTotal = [];
			if (responsee.data.parametros.rows !== null) {
				responsee.data.parametros.rows.map((modulo) => {
					if (modulo.modulos_id == null && modulo.nivel <= 3) {
						modulos.push({
							value: modulo.id,
							label: modulo.descripcion
						});
					}
					modulosTotal.push(modulo);
					this.setState({ modulosTotal: modulosTotal });
				});
				this.setState({ modulosbd: modulos });
			}
		})
		.catch(function (error) {
			// handle error
			console.error(error);
		});
	};

	componentWillMount(){
		this.obtenerEmpresas();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.state.actualizarDatos) {
		  this.obtenerEmpresas();
		  return true;
		}
		return true;
	}

	abrirDialog(){
		this.setState({
			abrirDialog: true,
		});
	}

	validarModulo = (evt) => {
		const { descripcion, padreSeleccionado, padreCheck, permisos, negocios_tipo } = this.state;
		evt.preventDefault();
		if(!descripcion || descripcion.trim == "" || descripcion.length == 0){
			swal("ERROR", "EL CAMPO DESCRIPCION NO PUEDE QUEDAR VACIO", "warning");
			return false;
		}else if(!permisos || permisos.trim == "" || permisos.length == 0){
			swal("ERROR", "EL CAMPO PERMISOS NO PUEDE QUEDAR VACIO", "warning");
			return false;
		}else if(!negocios_tipo || negocios_tipo.trim == "" || negocios_tipo.length == 0){
			swal("ERROR", "EL CAMPO TIPO DE NEGOCIOS NO PUEDE QUEDAR VACIO", "warning");
			return false;
		}else if(padreCheck){
			if(!padreSeleccionado || JSON.stringify(padreSeleccionado) == "{}"){
				swal("ERROR", "DEBE SELECCIONAR UN MODULO PADRE PRIMERO", "warning");
				return false;
			}else{
				return this.state.abrirDialog ? this.updateForm() : this.guardarDatos();
			}
		} else {
			return this.state.abrirDialog ? this.updateForm() : this.guardarDatos();
		}
	};
	
	guardarDatos = () => {
		console.log("enviando datos...");
		var usuario = store.getState().usuario;
		var desc = this.state.descripcion;
		var permisos = this.state.permisos;
		var negocios_tipo = this.state.negocios_tipo;
		var padreSelect = this.state.padreSeleccionado;
		var data;
		var nivel = typeof(this.state.nivel) === undefined ? "" : this.state.nivel;
		var tabla = "modulos";
		if(JSON.stringify(padreSelect) == "{}"){
			data = {
				"descripcion": desc,
				"acciones": permisos,
				"create_user": usuario.p_id,
				"empresas_id": usuario.empresas_id,
				"estado": "A",
				"negocios_tipo": negocios_tipo,
				"nivel": nivel,
				"modulos_id": null
			};
		}else{
			data = {
				"descripcion": desc,
				"acciones": permisos,
				"create_user": usuario.p_id,
				"estado": "A",
				"empresas_id": usuario.empresas_id,
				"negocios_tipo": negocios_tipo,
				"nivel": nivel,
				"modulos_id": padreSelect.value
			};
		}
		var self = this;
		Axios.post(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion/entidades/' + tabla, data, { headers: { Authorization: ntoken } })
		.then(function(response){
			var mensaje = "OPERACIÓN EJECUTADA CON ÉXITO";
			if(response.status === 200 || response.status === 201){
				if(response.data.success){
					self.toast({
						title: mensaje,
					});
					store.dispatch({
						type: "ACTUALIZACION_DATOS",
						actualizarvista: true
					});
				} else {
					self.toast({
						title: "NO SE PUDO COMPLETAR LA OPERACIÓN",
						icon: "error"
					});
				}
			} else if (response.status == 205) {
				self.toast({
					title: "LA SESION HA CADUCADO",
					icon: "warning"
				});
				setTimeout(function () { window.location.href = '/'; }, 3000);
			}
		});
	};

	updateForm = () => {
		console.log("actualizando datos...");
		var usuario = store.getState().usuario;
		var desc = this.state.descripcion;
		var permisos = this.state.permisos;
		var negocios_tipo = this.state.negocios_tipo;
		var padreSelect = this.state.padreSeleccionado;
		var data;
		var nivel = typeof(this.state.nivel) === undefined ? "" : this.state.nivel;
		var tabla = "modulos";
		var id = this.state.id;
		if(JSON.stringify(padreSelect) == "{}"){
			data = {
				"descripcion": desc,
				"acciones": permisos,
				"create_user": usuario.p_id,
				"empresas_id": usuario.empresas_id,
				"estado": "A",
				"id": id,
				"negocios_tipo": negocios_tipo,
				"nivel": nivel,
				"modulos_id": null
			};
		}else{
			data = {
				"descripcion": desc,
				"acciones": permisos,
				"create_user": usuario.p_id,
				"estado": "A",
				"id": id,
				"empresas_id": usuario.empresas_id,
				"negocios_tipo": negocios_tipo,
				"nivel": nivel,
				"modulos_id": padreSelect.value
			};
		}
		var self = this;
		Axios.put(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/parametrizacion/entidades/' + tabla, data, { headers: { Authorization: ntoken } })
		.then(function(response){
			var mensaje = "OPERACIÓN EJECUTADA CON ÉXITO";
			if(response.status === 200 || response.status === 201){
				if(response.data.success){
					self.toast({
						title: mensaje,
					});
					self.cerrarDialog();
					store.dispatch({
						type: "ACTUALIZACION_DATOS",
						actualizarvista: true
					});
				} else {
					self.toast({
						title: "NO SE PUDO COMPLETAR LA OPERACIÓN",
						icon: "error"
					});
				}
			} else if (response.status == 205) {
				self.toast({
					title: "LA SESION HA CADUCADO",
					icon: "warning"
				});
				setTimeout(function () { window.location.href = '/'; }, 3000);
			}
		});
	};

	cerrarDialog = () => {
		this.setState({
			abrirDialog: false,
		});
		this.cancelar();
	};

	cancelar = () => {
		console.log("limpiar estado");
		this.setState({
			descripcion: '',
			padreSeleccionado: '',
			padreCheck: false,
			permisos: '',
			negocios_tipo: '',
			nivel: '',
			id: ''
		});
	};

	editarModulos = (modulo) => {
		this.setState({
			abrirDialog: true,
			descripcion: modulo.descripcion,
			permisos: modulo.acciones,
			padreSeleccionado: modulo.modulos_id != null ? {
				value: modulo.modulos_id,
				label: modulo.modulo_desc
			} : {},
			padreCheck: modulo.modulos_id === null ? false : true,
			negocios_tipo: modulo.negocios_tipo,
			nivel: modulo.nivel,
			id: modulo.id
		});
	};

	inactivarGeografia = (id, tabla) => {
        var usuario = store.getState().usuario;
        var datos = {
            "id": id,
            "entidad_nombre": tabla,
            "estado": "I",
            "personas_id": usuario.p_id,
            "empresas_id": usuario.empresas_id
		};
        swal({
            title: "Estas seguro de que deseas inactivar este modulo",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
			if (willDelete) {
				Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/estados/', datos, { headers: { Authorization: ntoken } })
				.then((res) => {
					if (res.status == 201) {
						swal({
							title: "OPERACIÓN EJECUTADA CON ÉXITO",
							icon: "success",
							closeOnClickOutside: false,
							buttons: {
								Seguir: {
									text: "OK",
									value: "continuar"
								}
							},
						}).then(function(value) {
							switch (value) {
								case "continuar":
									console.log("inactivar vista");
									store.dispatch({
										type: "ACTUALIZACION_DATOS",
										actualizarvista: true
									});
									break;
	
								default:
									break;
							}
						});
					} else if (res.status == 205) {
						swal("ERROR", "LA SESION HA CADUCADO", "warning");
						setTimeout(function () { window.location.href = '/'; }, 5000);
					} else {
						swal("Fallo al eliminar", "Por favor verifique los datos", "warning");
					}
				});
			}
		});
	};

	activarGeografia = (id, tabla) => {
		var usuario = store.getState().usuario;
		var datos = {
			"id": id,
			"entidad_nombre": tabla,
			"estado": "A",
			"personas_id": usuario.p_id,
			"empresas_id": usuario.empresas_id
		};
		Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/estados/', datos, { headers: { Authorization: ntoken } })
		.then((res) => {
			if (res.status == 201) {
				swal({
					title:"Modulo activado correctamente",
					icon: "success",
					closeOnClickOutside: false,
					buttons: {
						Seguir: {
							text: "OK",
							value: "continuar"
						}
					},
				}).then(function(value) {
					switch (value) {
						case "continuar":
							console.log("activar vista");
							store.dispatch({
								type: "ACTUALIZACION_DATOS",
								actualizarvista: true
							});
							break;

						default:
							break;
					}
				});
			} else if (res.status == 205) {
				swal("ERROR", "LA SESION HA CADUCADO", "warning");
				setTimeout(function () { window.location.href = '/'; }, 5000);
			} else {
				swal("Fallo al activar", "Por favor verifique los datos", "warning");
			}
		});
	};

	render(){
		var data = {
			columns: [
				{
					label: 'Descripcion',
					field: 'desc',
					sort: 'asc'
				},{
					label: 'Permisos',
					field: 'permisos',
					sort: 'asc'
				}, {
					label: 'Estado',
					field: 'estado',
					sort: 'asc'
				}, {
					label: 'Tipo de Negocios',
					field: 'negocios_tipo',
					sort: 'asc'
				}, {
					label: 'Nivel',
					field: 'nivel',
					sort: 'asc'
				}, {
					label: 'Acciones',
					field: 'acciones',
					sort: 'asc'
				}
			], rows: this.state.modulosTotal.map((modulo) => {
				return ({
					Descripcion: modulo.descripcion,
					Permisos: modulo.acciones,
					Estado: modulo.estado === 'A' ? 'ACTIVO' : 'INACTIVO',
					negocios_tipo: modulo.negocios_tipo,
					Nivel: modulo.nivel,
					acciones: [modulo.estado === 'I' ? null : <button className="btn btn-sm btn-primary" onClick={() => this.editarModulos(modulo)} style={{ marginLeft: '5px' }}><EditICon /></button>,
					modulo.estado === 'A' ? <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.inactivarGeografia(modulo.id, "modulos")}><img src={DeleteIcon}/></button>
					: <button className="btn btn-sm btn-success" style={{ marginLeft: '5px', float: "left" }} onClick={() => this.activarGeografia(modulo.id, "modulos")}><img src={SuccessIcon}/></button>]
				});
			})
		};
		return(
			<div>
				<div style={{ height: '100%', width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
					<div className="row">
						{this.state.abrirDialog ? null : 
							<div className="col col-md-12" style={{ padding: 0 }}>
								<div className="row">
									<div className="col-md-6">
										<label htmlFor="descripcion" className="col-form-label">Descripcion</label>
										<input autoComplete="off" style={{ marginTop: '5px' }} id="descripcion" value={this.state.descripcion} onChange={this.handleDescripcion} className="form-control" type="text" placeholder="Ingrese la descripción" required/>
									</div>
									<div className="col-md-6">
										<label htmlFor="estado" className="col-form-label">Modulo padre</label>
										<Checkbox
											checked={this.state.padreCheck}
											value={this.state.padreCheck}
											onChange={this.handleChangeCheck('padreCheck')}
										/>
										<Selects
											placeholder="Seleccione el modulo padre al que pertenece"
											isDisabled={!this.state.padreCheck}
											value={this.state.padreSeleccionado}
											onChange={value => this.handleSelect(value)}
											options={this.state.modulosbd}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<label htmlFor="permisos" className="col-form-label">Permisos</label>
										<input autoComplete="off" id="permisos" value={this.state.permisos} onChange={this.handlePermisos} className="form-control" type="text" placeholder="Ingrese los permisos" required/>
									</div>
									<div className="col-md-3">
										<label htmlFor="nivel" className="col-form-label">Nivel</label>
										<input autoComplete="off" id="nivel" value={this.state.nivel} onChange={this.handleNivel} className="form-control" type="number" placeholder="Ingrese un nivel" required min="0"/>
									</div>
									<div className="col-md-6">
										<label htmlFor="Negocios" className="col-form-label">Tipo de negocios</label>
										<input autoComplete="off" id="permisos" value={this.state.negocios_tipo} onChange={this.handleNegocios} className="form-control" type="text" placeholder="Ingrese el tipo de negocio" required/>
									</div> 
								</div>
								<div className="row" style={{ marginTop: '20px', marginBottom: '20px'}}>
									<div className="col-md-3">
										<button onClick={this.validarModulo} className="col-md-12 btn btn-primary" style={{ float: "right"}}>Guardar</button>
									</div>
									<div className="col-md-3">
										<button onClick={this.cancelar} className="col-md-12 btn btn-danger" style={{ float: "right" }}>Cerrar</button>
									</div>
								</div>
							</div>
						}
						<div className="col col-md-12" style={{ padding: 0 }}>
							<Paper style={{ padding: '5px' }}>
								<MDBDataTable
									//striped
									className="col-md-12"
									searchLabel="Buscar"
									paginationLabel={["Anterior", "Siguente"]}
									infoLabel={["Mostrando", "a", "de", "entradas"]}
									entriesLabel="Filas a mostrar"
									tbodyColor="primary-color"
									theadColor="#FF8800"
									exportToCSV={true}
									striped
									bordered
									style={{fontSize: '13px'}}
									order={['descripcion', 'asc']}
									autoWidth={false}
									responsiveSm={true}
									responsive={true}
									hover
									data={data}
								/>

								<Dialog
								open={this.state.abrirDialog}
								onClose={() => this.cerrarDialog()}
								aria-labelledby="form-dialog-title"
								maxWidth={'md'}
								fullWidth={true}
								style={{ padding: '0' }}
								disableBackdropClick={true}>
								<DialogTitle>Editar parametros</DialogTitle>
								<DialogContent>
									<div className="col-md-12 col-sm-12">
										<div className="row">
											<div className="col-md-6">
												<label htmlFor="descripcion" className="col-form-label">Descripcion</label>
												<input autoComplete="off" style={{ marginTop: '5px' }} id="descripcion" value={this.state.descripcion} onChange={this.handleDescripcion} className="form-control" type="text" placeholder="Ingrese la descripción" required/>
											</div>
											<div className="col-md-6">
												<label htmlFor="estado" className="col-form-label">Modulo padre</label>
												<Checkbox
													checked={this.state.padreCheck}
													value={this.state.padreCheck}
													onChange={this.handleChangeCheck('padreCheck')}
												/>
												<Selects
													placeholder="Seleccione el modulo padre al que pertenece"
													isDisabled={!this.state.padreCheck}
													value={this.state.padreSeleccionado}
													onChange={value => this.handleSelect(value)}
													options={this.state.modulosbd}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-md-3">
												<label htmlFor="permisos" className="col-form-label">Permisos</label>
												<input autoComplete="off" id="permisos" value={this.state.permisos} onChange={this.handlePermisos} className="form-control" type="text" placeholder="Ingrese los permisos" required/>
											</div>
											<div className="col-md-3">
												<label htmlFor="nivel" className="col-form-label">Nivel</label>
												<input autoComplete="off" id="nivel" value={this.state.nivel} onChange={this.handleNivel} className="form-control" type="number" placeholder="Ingrese un nivel" required min="0"/>
											</div>
											<div className="col-md-6">
												<label htmlFor="Negocios" className="col-form-label">Negocios</label>
												<input autoComplete="off" id="permisos" value={this.state.negocios_tipo} onChange={this.handleNegocios} className="form-control" type="text" placeholder="Ingrese el tipo de negocio" required/>
											</div>
										</div>
										<div className="row" style={{ marginTop: '20px', marginBottom: '20px', marginLeft: '3px'}}>
											<div className="col-md-3">
												<button onClick={this.validarModulo} className="col-md-12 btn btn-primary" style={{ float: "right"}}>Guardar</button>
											</div>
											<div className="col-md-3">
												<button onClick={this.cerrarDialog} className="col-md-12 btn btn-danger" style={{ float: "right" }}>Cerrar</button>
											</div>
										</div>
									</div>
								</DialogContent>
							</Dialog>
							</Paper>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default GestionModulos;