
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import 'bootstrap/dist/css/bootstrap.min.css';
import CardTittle from '@material-ui/core/CardHeader'
import getCroppedImg from './getCroppedImg'
import Slider from '@material-ui/lab/Slider'
import Cropper from 'react-easy-crop'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import ChangeImage from '../../assets/img/Formularios/change_foto.png'
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Server from '../../lib/server'
import store from '../../lib/store'
import Axios from 'axios';
import swal from 'sweetalert'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Selects from 'react-select'
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
var categoriaEditar = null
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class FormularioCategoria extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            croppedImage: null,
            dialogphoto: false,
            crop: { x: 0, y: 0 },
            zoom: 1,
            croppedAreaPixels: null,
            croppedImage: null,
            aspect: 1 / 1,
            imagenCreate: false,
            imageSrc: null,
            //Categorias
            ncategoria: '',
            modoEditar: false,
            checkHijo: true,
            checkPadre: false,
            //Categorias padres
            padresbd: [],
            padreSeleccionado: {},
            categoriasActuales: [],
            response: false
        };
    }

    changeSelect = name => () => {
        switch (name) {
            case 'checkPadre':
                this.setState({ checkPadre: true, checkHijo: false })
                break
            case 'checkHijo':
                this.setState({ checkPadre: false, checkHijo: true })
                break
        }
    }

    componentWillMount() {
        categoriaEditar = this.props.categoriaEditar
        if (categoriaEditar != null) {
            console.log(categoriaEditar)
            this.setState({
                modoEditar: true,
                ncategoria: categoriaEditar.grupo,
                imagePreviewUrl: 'S',
                checkPadre: categoriaEditar.grupos_id != null ? true : false,
                checkHijo: categoriaEditar.grupos_id != null ? false : true,
                padreSeleccionado: categoriaEditar.grupos_id != null ? {
                    value: categoriaEditar.grupos_id,
                    label: categoriaEditar.grupos_id_desc
                } : {}
            })
        }
        Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/grupos/categoria/' + store.getState().empresasPadres, { headers: { Authorization: ntoken } })
            .then(responsee => {
                console.log(responsee.data)
                var cat = []
                var actuales = []
                responsee.data.map((cate) => {
                    actuales.push(cate.grupo)
                    cat.push({
                        value: cate.id,
                        label: cate.grupo
                    })
                })
                console.log(actuales)
                this.setState({ padresbd: cat, categoriasActuales: actuales })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    onZoomChange = zoom => {
        this.setState({ zoom })
    }

    onZoomChange = zoom => {
        this.setState({ zoom })
    }
    onCropChange = crop => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels })
    }

    _handleImageChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        }

        reader.readAsDataURL(file)
        this.setState({ dialogphoto: !this.state.dialogphoto });
    }

    showCroppedImage = async () => {
        const croppedImage = await getCroppedImg(
            this.state.imagePreviewUrl,
            this.state.croppedAreaPixels
        )
        this.setState({ croppedImage, dialogphoto: !this.state.dialogphoto })
    }

    actualizarCategoria = (id) => {
        var usuario = store.getState().usuario
        var dominio = store.getState().dominio
        var validar = this.validarFormulario()
        var datos = {
            "grupo": this.state.ncategoria,
            "grupos_tipos_id": 1,
            "empresas_id": usuario.empresas_id,
            "grupos_id": this.state.checkPadre ? this.state.padreSeleccionado.value : null,
            "url_foto": this.state.imagePreviewUrl != null ? this.state.imagePreviewUrl : null,
            "dominios_id": dominio,
            "estado": 'A'
        }
        if (validar) {
            Axios.put(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/grupos/' + id, datos, { headers: { Authorization: ntoken } })
                .then((res) => {
                    //console.log(res)
                    this.setState({
                        response: false
                    })
                    if (res.status == 200) {
                        swal({
                            title: "Categoria actualizada correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_CATEGORIA",
                                        creacionCategoria: false,
                                    })
                                    break;
                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                    }
                })
        }

    }

    validarFormulario = () => {
        if (this.state.ncategoria.trim == "" || this.state.ncategoria.length == 0) {
            swal("FALLO AL CREAR", "EL NOMBRE ES UN CAMPO OBLIGATORIO", "warning")
            return false
        } else if (this.state.categoriasActuales.includes(this.state.ncategoria) && !this.state.modoEditar) {
            swal("FALLO AL CREAR", "ESTA CATEGORIA YA ESTA CREADA", "warning")
            return false
        } else if (this.state.checkPadre && JSON.stringify(this.state.padreSeleccionado) == '{}') {
            swal("FALLO AL CREAR", "POR FAVOR ESCOGE LA CATEGORIA PADRE", "warning")
            return false
        } else {
            this.setState({
                response: true
            })
            return true
        }
    }

    guardarCategoria = () => {
        var usuario = store.getState().usuario
        var dominio = store.getState().dominio
        var validar = this.validarFormulario()
        var datos = {
            "grupo": this.state.ncategoria,
            "grupos_tipos_id": 1,
            "empresas_id": usuario.empresas_id,
            "grupos_id": this.state.checkPadre ? this.state.padreSeleccionado.value : null,
            "url_foto": this.state.imagePreviewUrl != null ? this.state.imagePreviewUrl : null,
            "dominios_id": dominio
        }
        //console.log(datos)
        if (validar) {
            Axios.post(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/grupos/', datos, { headers: { Authorization: ntoken } })
                .then((res) => {
                    //console.log(res)
                    this.setState({
                        response: false
                    })
                    if (res.status == 200) {
                        swal({
                            title: "Categoria creada correctamente",
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "CREACION_CATEGORIA",
                                        creacionCategoria: false,
                                    })
                                    break;
                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                    }
                })
        }
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };

    CerrarFormulario = () => {
        store.dispatch({
            type: "CREACION_CATEGORIA",
            creacionCategoria: false,
        })
    }

    borrarImagen = () => {
        this.setState({ croppedImage: null })
    }

    render() {
        var dominio = store.getState().dominio
        var foto = categoriaEditar != null ? Server.hostMultimedia + dominio + '/categoria/' + (categoriaEditar.url_foto == 'N' ? 'default' : categoriaEditar.id) + '.png'+"?"+moment(new Date()).format("YYYYMMMDDHHmmss") : Server.hostMultimedia + dominio + '/categoria/default.png'
        return (
            <Card style={{ width: '100%' }} >
                <CardTittle title="CREACION DE CATEGORIA" style={{ marginBottom: "0", paddingBottom: "5px" }} />
                <CardContent style={{ overflow: 'auto' }}>
                    <div className="row">
                        <div className="col-md-3 col-sm-12 mx-auto">
                            <div className={this.state.modoEditar ? "rounded rounded-circle border border-dark mx-auto" : "categoriaImg rounded rounded-circle border border-dark mx-auto"} style={{ height: '200px', width: '200px' }} >
                                {this.state.modoEditar

                                    ?
                                    <img className={this.state.modoEditar ? "rounded-circle border border border-dark" : "img-user rounded-circle border border border-dark"} height="200" width="200" src={foto} />
                                    :
                                    <div style={{height:'100%'}}>
                                        {this.state.croppedImage != null ? <img className="img-user rounded-circle border border border-dark" height="200" width="200" src={this.state.croppedImage} /> : <input accept="image/png" type="file" onChange={(e) => this._handleImageChange(e)} style={{ opacity: "0.0", height: "100%", cursor: "pointer" }} />}
                                        {/*this.state.croppedImage != null ? <img src={ChangeImage} height="30" width="30" onClick={() => this.borrarImagen()} style={{ cursor: 'pointer', float: 'right' }}></img> :<img src={ChangeImage} height="30" width="30" onClick={() => this.borrarImagen()} style={{ cursor: 'pointer', float: 'right' }}></img>*/}
                                    </div>
                                }

                            </div>
                        </div>
                        <div className="col-md-3 mx-auto my-auto">
                            <div className="row">
                                <div className="col-md-12">
                                    <FormControlLabel
                                        control={<Tooltip title="SI SELECCIONA SIGNIFICA QUE SE VA A CREAR UNA SUBCATEGORIA" placement="top"><Checkbox value={this.state.checkPadre} checked={this.state.checkPadre} style={{ padding: '5px' }} onChange={this.changeSelect('checkPadre')} /></Tooltip>}
                                        label='CATEGORIA HIJO'
                                        style={{ margin: '0' }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <FormControlLabel
                                        control={<Tooltip title="SI SELECCIONA SIGNIFICA QUE SE VA A CREAR UNA CATEGORIA PRINCIPAL" placement="top"><Checkbox value={this.state.checkHijo} checked={this.state.checkHijo} style={{ padding: '5px' }} onChange={this.changeSelect('checkHijo')} /></Tooltip>}
                                        label='CATEGORIA PADRE'
                                        style={{ margin: '0' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 my-auto">
                            {this.state.checkPadre

                                ?
                                <div className="row">
                                    <div className="col-md-12">
                                        <label htmlFor="numordencompra" className="col-form-label">ESCOGE LA CATEGORIA PADRE</label>
                                        <Selects
                                            value={this.state.padreSeleccionado}
                                            onChange={this.handleChanges('padreSeleccionado')}
                                            options={this.state.padresbd}
                                        />
                                    </div>
                                </div>
                                : null}

                            <div className="row">
                                <div className="col-md-12" id="nombre_categoria">
                                    <label htmlFor="numordencompra" className="col-form-label">NOMBRE DE LA CATEGORIA</label>
                                    <input id="numordencompra" autoComplete="off" value={this.state.ncategoria} onChange={this.handleChange('ncategoria')} className="form-control" type="text" />
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: '10px' }}>
                                <div className="col-md-6">
                                    <button className="btn btn-danger" style={{ float: 'right' }} onClick={() => this.CerrarFormulario()}>CANCELAR</button>
                                </div>
                                <div className="col-md-6">
                                    {this.state.modoEditar ? <button className="btn btn-success" style={{ float: 'right' }} disabled={this.state.response} onClick={() => this.actualizarCategoria(categoriaEditar.id)}>GUARDAR CAMBIOS</button> : <button className="btn btn-success" style={{ float: 'right' }} disabled={this.state.response} onClick={() => this.guardarCategoria()}>CREAR CATEGORIA</button>}
                                </div>
                            </div>

                        </div>
                    </div>
                </CardContent>
                <Dialog
                    open={this.state.dialogphoto}
                    //onClose={this.handledialog('foto')}
                    aria-labelledby="form-dialog-title"
                    maxWidth={'sm'}
                    fullWidth={true}
                    disableBackdropClick={true}
                >
                    <DialogTitle id="form-dialog-title">Recortar la foto</DialogTitle>
                    <DialogContent >
                        <div className="row mx-auto">
                            <div className="col col-md-12" style={{ height: "150px" }}>
                                <Cropper
                                    image={this.state.imagePreviewUrl}
                                    crop={this.state.crop}
                                    zoom={this.state.zoom}
                                    aspect={this.state.aspect}
                                    onCropChange={this.onCropChange}
                                    onCropComplete={this.onCropComplete}
                                    onZoomChange={this.onZoomChange}
                                />
                            </div>
                        </div>
                        <div className="row mx-auto">
                            <div className="col col-md-12" style={{ height: "100%", margin: "15px" }}>
                                <Slider
                                    value={this.state.zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e, zoom) => this.onZoomChange(zoom)}
                                    classes={{ container: 'slider' }}
                                />
                            </div>
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-primary"> Cancelar
                                                 </button>
                        <button onClick={this.showCroppedImage} className="btn btn-primary">
                            Agregar foto
                                                 </button>
                    </DialogActions>
                </Dialog>
            </Card>

        )
    }
}


export default FormularioCategoria
