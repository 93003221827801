import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import 'bootstrap/dist/css/bootstrap.min.css';
import CardTittle from '@material-ui/core/CardHeader'
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from 'prop-types';
import CardActionArea from '@material-ui/core/CardActionArea';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import milkyway from '../../assets/img/logo_big.png'
import bodega from '../../assets/icons/bodega.png'
import Selects from 'react-select';
import Avatar from '@material-ui/core/Avatar';
import swal from 'sweetalert'
import Axios from 'axios'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import img from "../../assets/img/Formularios/xx.png"
import "./producto.css"
import Image from 'react-bootstrap/Image'
import Server2 from '../../lib/server'
import store from '../../lib/store'
var colorDominio = store.getState().colorDominio
const B = (props) => <p style={{ fontWeight: 'bold', marginBottom: "0" }}>{props.children}</p>
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))



function getSteps() {
    return ['Parametros de identificacion', 'Escoge el tipo de scl', 'Asignacion a un empresa', 'Asignacion de una bodega'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return 'Se le asignan los parametros principales del scl para su inicio';
        case 1:
            return 'Escoge el tipo de equipo';
        case 2:
            return 'Asignale el equipo a una empresa';
        case 3:
            return 'Asignale el equipo una bodega';
        default:
            return 'Unknown stepIndex';
    }
}


var busqueda = []
var datosMostrar = []

class FormularioEquipo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Base de datos
            empresas: [],
            equipos: [],
            almacenamientos: [],
            TiposEquipo: [],
            bodegasbd: [],
            //Stepper
            activeStep: 0,
            validado: true,
            //Datos Seleccionados
            equipo: {},
            almacenamiento: {},
            //Datos a Guardar
            tipoEquipo: null,
            tipoEquipoID: 0,
            EquipoConfigurar: "",
            EmpresaSeleccionada: 0,
            AlmacenamientoSeleccionado: 0,
            AlmacenamientoSeleccionadoSerial: "",
            tiendaSeleccionadaNombre: "",
            bodegaSeleccionadaNombre: "",
            bodegaSeleccionada: 0,
            //Datos a mostrar
            tipoEquipoRefencia: "",
            percent: 33,
            //Busqueda de datos
            busqueda: false,
            paginadorIndex: 0,
            paginadorMinimo: 0,
            paginadorMaximo: 11,
            paginadorTotal: 12,
        };
    }

    componentWillMount() {
        const n = []
        const m = []
        Axios.get(Server2.hostPrueba + Server2.puertoEquipos + '/api/tipo/equipo', { headers: { Authorization: ntoken } })
            .then(responsee => {
                this.setState({ TiposEquipo: responsee.data })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        Axios.get(Server2.hostPrueba + Server2.puertoEquipos + '/api/almacenamiento', { headers: { Authorization: ntoken } })
            .then(response => {
                console.log(response)
                response.data.data.map((dato) => (
                    m.push({
                        value: dato.id,
                        label: dato.serial
                    })
                ))
                this.setState({ almacenamientos: m })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        Axios.get(Server2.hostPrueba + Server2.puertoEquipos + '/api/equipo', { headers: { Authorization: ntoken } })
            .then(response => {
                console.log(response)
                response.data.map((dato) => (
                    n.push({
                        value: dato._id,
                        label: dato.referencia + " -- " + dato.createdAt
                    })
                ))
                this.setState({ equipos: n })
            })
        Axios.get(Server2.hostPruebasinssl + Server2.puertoEmpresas + '/api/empresa/tiendas', { headers: { Authorization: ntoken } })
            .then(responsee => {
                console.log(responsee.data.data)
                this.setState({ empresas: responsee.data.data })
            })
            .catch(function (error) {
                console.error(error);
            })
    }


    handleNext = () => {
        this.setState(() => ({
            activeStep: this.state.activeStep + 1,
            validado: true,
        }));
        /*this.setState(prevState => ({
            percent: prevState.percent >= 100 ? 0 : prevState.percent + 20,
        }))
        console.log(this.state)*/
    };

    handleBack = () => {
        this.setState(() => ({
            activeStep: this.state.activeStep - 1,
        }));
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };


    handleChangeTipo = (ref, name) => () => {
        switch (ref) {
            case "tipo":
                var n = this.state.EquipoConfigurar
                n = n.replace("*****", name.codigo_referencia)
                this.setState({
                    tipoEquipoRefencia: name.codigo_referencia,
                    EquipoConfigurar: n,
                    tipoEquipo: name.descripcion,
                    tipoEquipoID: parseInt(name.id, 10),
                    validado: true
                });
                console.log(this.state)
                break;
            case "bodega":
                this.setState({ bodegaSeleccionadaNombre: name.descripcion + ' - ' + name.codigo, bodegaSeleccionada: name.bodega_id, validado: true })
                break;
            case "bodega0":
                this.setState({ bodegaSeleccionadaNombre: "SIN BODEGA" + ' - ' + 'SNBD', bodegaSeleccionada: 0, validado: true })
                break;
            case "empresa":
                this.setState({
                    EmpresaSeleccionada: parseInt(name.id, 10),
                    tiendaSeleccionadaNombre: name.razon_social,
                    validado: true
                });
                console.log(name)
                Axios.get(Server2.hostPrueba + Server2.puertoSSLBodega + '/api/bodegas/empresa/' + name.id, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee.data)
                        var bdgtotal = []
                        responsee.data.map((bodega, key) => {
                            bdgtotal.push(bodega)
                        })
                        this.setState({ bodegasbd: bdgtotal })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break;
        }

    };

    preautorizar = () =>{
        const datos = {
            "referencia": this.state.EquipoConfigurar,
            "empresas_id": 1,
            "almacenamientos_id": this.state.AlmacenamientoSeleccionado,
            "equipos_tipos_id": this.state.tipoEquipoID,
            "bodegas_id": 0,

        }
        console.log(datos)
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        Axios.post(Server2.hostPrueba + Server2.puertoEquipos + '/api/equipo/preautorizacion', datos, { headers: { Authorization: ntoken } })
            .then(response => {
                console.log(response)
                if (response.status == 200) {
                    swal({
                        title: response.data.message,
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                store.dispatch({
                                    type: "CREACION_EQUIPO",
                                    ventanaCreacionEquipo: !this.state.ventanaCreacionEquipo
                                });
                                break;
                            default:

                        }
                    });
                } else {
                    swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                }
                
            }).catch(error => {
                console.warn(error)
            })
    }

    enviarEquipo = () => {
        const datos = {
            "referencia": this.state.EquipoConfigurar,
            "empresas_id": this.state.EmpresaSeleccionada,
            "almacenamientos_id": this.state.AlmacenamientoSeleccionado,
            "equipos_tipos_id": this.state.tipoEquipoID,
            "bodegas_id": this.state.bodegaSeleccionada,

        }
        console.log("enviando datos")
        console.log(datos)
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        Axios.post(Server2.hostPrueba + Server2.puertoEquipos + '/api/equipo/autorizacion', datos, { headers: { Authorization: ntoken } })
            .then(response => {
                console.log(response)
                if (response.status == 200) {
                    swal({
                        title: response.data.message,
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                break;
                            default:

                        }
                    });
                } else {
                    swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                }
                /*response.data.map((dato, key) => (
                    n.push({
                        value: dato._id,
                        label: dato.referencia
                    })
                ))
                this.setState({ equipos: n })*/
            }).catch(error => {
                console.warn(error)
            })
    }

    verdetallesproducto = () => {
        this.setState({ detalles: !this.state.detalles })
    }

  

    handleChanges = name => value => {
        switch (name) {
            case 'almacenamiento':
                this.setState({
                    [name]: value,
                    AlmacenamientoSeleccionado: parseInt(value.value, 10),
                    AlmacenamientoSeleccionadoSerial: value.label,
                    validado: true
                });
                break
            case 'equipo':
                this.setState({
                    [name]: value,
                    EquipoConfigurar: value.label.substr(0, 35)
                });
                break
        }
    }

    buscar = () => event => {
        var cadena = event.target.value.toUpperCase()
        if (cadena.length > 0) {
            this.setState({ busqueda: true })
        } else {
            this.setState({ busqueda: false })
        }
        busqueda = []
        this.state.TiposEquipo.map((tipo) => {
            if (tipo.descripcion.includes(cadena)) {
                busqueda.push(tipo)
            } else {

            }
        })
    }

    pagination = (name, str) => {
        switch (name) {
            case 'tipo':
                if (str == 'S') {
                    this.setState({ paginadorIndex: this.state.paginadorIndex + 1, paginadorMinimo: this.state.paginadorMaximo + 1, paginadorMaximo: this.state.paginadorMaximo + this.state.paginadorTotal })
                } else {
                    this.setState({ paginadorIndex: this.state.paginadorIndex - 1, paginadorMinimo: (this.state.paginadorMinimo - this.state.paginadorTotal), paginadorMaximo: this.state.paginadorMinimo - 1 })
                }
                this.datos()
                break;
        }
    }

    datos = () => {
        datosMostrar = []
        for (var i = this.state.paginadorMinimo; i <= this.state.paginadorMaximo; i++) {
            if (this.state.TiposEquipo[i] != null) {
                datosMostrar.push(this.state.TiposEquipo[i])
            } else {
                //console.log(this.state.tipoEquipo[i])
            }
        }
    }

    stepContentsss = () => {
        switch (this.state.activeStep) {
            case 0:
                return (
                    <div>
                        <div className="row">
                            <div className="col col-md-8">
                                <label htmlFor="nombreprov" className="col-form-label">Equipo a configurar</label>
                                <Selects
                                    placeholder="Seleccione o escriba"
                                    value={this.state.equipo}
                                    onChange={this.handleChanges('equipo')}
                                    options={this.state.equipos}
                                />
                            </div>
                            <div className="col col-md-4">
                                <label htmlFor="nombreprov" className="col-form-label">Almacenamiento asignado</label>
                                <Selects
                                    placeholder="Seleccione o escriba"
                                    value={this.state.almacenamiento}
                                    onChange={this.handleChanges('almacenamiento')}
                                    options={this.state.almacenamientos}
                                />
                            </div>
                        </div>
                    </div>
                )
            case 1:
                return (
                    <div>
                        <div className="row mx-auto">
                            <button disabled={this.state.busqueda} className="btn btn-sm btn-outline-primary mx-auto" onClick={() => this.pagination('tipo', 'N')}>{"<<"}</button>
                            <div className="col col-md-6 mx-auto">
                                <input type="text" placeholder="Buscar" className="form-control mx-auto" onChange={this.buscar('tipo')} />
                            </div>
                            <button disabled={this.state.busqueda} className="btn btn-sm btn-outline-primary mx-auto" onClick={() => this.pagination('tipo', 'S')}>{">>"}</button>
                        </div>
                        <div className="row mx-auto">{this.state.busqueda
                            ?
                            busqueda.map((tipo) => (
                                <div className="col-md-4" style={{ paddingLeft: "15px", paddingTop: "15px" }}>

                                    <Card onClick={this.handleChangeTipo("tipo", tipo)}>
                                        <CardActionArea>
                                            <div className="row">
                                                <div className="col col-md-4 rounded" style={{ paddingRight: "0" }}>
                                                    <Image src={img} rounded style={{ margin: 10, width: 60, height: 60 }} />
                                                    {/*<Avatar alt="Remy Sharp" src={img} style={{ margin: 10, width: 60, height: 60 }} />*/}
                                                </div>
                                                <div className="col col-md-8 my-auto" style={{ padding: "0" }}>
                                                    <CardContent style={{ paddingBottom: "0" }}>
                                                        <Typography component="p">
                                                            <B>{tipo.descripcion}</B>
                                                        </Typography>
                                                    </CardContent>
                                                </div>
                                            </div>

                                        </CardActionArea>

                                    </Card >
                                </div>
                            ))
                            :
                            datosMostrar.map((tipo) => (
                                <div className="col-md-4" style={{ paddingLeft: "15px", paddingTop: "15px" }}>

                                    <Card onClick={this.handleChangeTipo("tipo", tipo)}>
                                        <CardActionArea>
                                            <div className="row">
                                                <div className="col col-md-4 rounded" style={{ paddingRight: "0" }}>
                                                    <Image src={img} rounded style={{ margin: 10, width: 60, height: 60 }} />
                                                    {/*<Avatar alt="Remy Sharp" src={img} style={{ margin: 10, width: 60, height: 60 }} />*/}
                                                </div>
                                                <div className="col col-md-8 my-auto" style={{ padding: "0" }}>
                                                    <CardContent style={{ paddingBottom: "0" }}>
                                                        <Typography component="p">
                                                            <B>{tipo.descripcion}</B>
                                                        </Typography>
                                                    </CardContent>
                                                </div>
                                            </div>

                                        </CardActionArea>

                                    </Card >
                                </div>
                            ))
                        }



                        </div>
                    </div>
                )
           case 2:
                return (
                    <div className="row mx-auto">
                        {this.state.empresas.map((tipo) => (
                            <div className="col-md-4" style={{ paddingLeft: "15px", paddingTop: "15px" }}>
                                <Card onClick={this.handleChangeTipo("empresa", tipo)}>
                                    <CardActionArea>
                                        <div className="row">
                                            <div className="col col-md-3 mx-auto ">
                                                <Avatar alt="Remy Sharp" src={milkyway} style={{ margin: 10, width: 60, height: 60 }} />
                                            </div>
                                            <div className="col col-md-8" style={{ padding: "0" }}>
                                                <CardContent style={{ paddingBottom: "0" }}>
                                                    <Typography component="p">
                                                        <B>{tipo.alias}</B>
                                                    </Typography>
                                                    <Typography component="p">
                                                        {tipo.nit}
                                                    </Typography>
                                                </CardContent>
                                            </div>
                                        </div>

                                    </CardActionArea>

                                </Card >
                            </div>
                        ))}

                    </div>
                )
            case 3:
                return (
                    <div className="row mx-auto">
                        <div className="col-md-4" style={{ paddingLeft: "15px", paddingTop: "15px" }}>
                                <Card onClick={this.handleChangeTipo("bodega0", 'tipo')}>
                                    <CardActionArea>
                                        <div className="row">
                                            <div className="col col-md-3 mx-auto ">
                                                <Avatar alt="Remy Sharp" src={bodega} style={{ margin: 10, width: 60, height: 60 }} />
                                            </div>
                                            <div className="col col-md-8" style={{ padding: "0" }}>
                                                <CardContent style={{ paddingBottom: "0" }}>
                                                    <Typography component="p">
                                                        <B>{'SIN BODEGA'}</B>
                                                    </Typography>
                                                    <Typography component="p">
                                                        {'SNBD'}
                                                    </Typography>
                                                </CardContent>
                                            </div>
                                        </div>
                                    </CardActionArea>
                                </Card >
                            </div>
                        {this.state.bodegasbd.map((tipo) => (
                            <div className="col-md-4" style={{ paddingLeft: "15px", paddingTop: "15px" }}>
                                <Card onClick={this.handleChangeTipo("bodega", tipo)}>
                                    <CardActionArea>
                                        <div className="row">
                                            <div className="col col-md-3 mx-auto ">
                                                <Avatar alt="Remy Sharp" src={bodega} style={{ margin: 10, width: 60, height: 60 }} />
                                            </div>
                                            <div className="col col-md-8" style={{ padding: "0" }}>
                                                <CardContent style={{ paddingBottom: "0" }}>
                                                    <Typography component="p">
                                                        <B>{tipo.descripcion}</B>
                                                    </Typography>
                                                    <Typography component="p">
                                                        {tipo.codigo}
                                                    </Typography>
                                                </CardContent>
                                            </div>
                                        </div>
                                    </CardActionArea>
                                </Card >
                            </div>
                        ))}

                    </div>
                )
            case 4:
                return (
                    <div className="row mx-row">
                        <div className="col col-md-2"></div>
                        <div className="col col-md-8 mx-row ">
                            <Table >
                                <TableBody>
                                    <TableRow>
                                        <TableCell align='right'><B>Serial del equipo: </B></TableCell>
                                        <TableCell>{this.state.EquipoConfigurar}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align='right'><B>Serial del almacenamiento: </B></TableCell>
                                        <TableCell align='left'>{this.state.AlmacenamientoSeleccionadoSerial}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align='right'><B>Tipo de equipo: </B></TableCell>
                                        <TableCell>{this.state.tipoEquipo}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align='right'><B>Tienda asignada: </B></TableCell>
                                        <TableCell>{this.state.tiendaSeleccionadaNombre}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align='right'><B>Bodega asignada: </B></TableCell>
                                        <TableCell>{this.state.bodegaSeleccionadaNombre}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                        <div className="col col-md-2"></div>
                    </div>
                )
            default:
                return 'Unknown stepIndex';
        }
    }

    render() {
        const steps = getSteps();
        const { activeStep } = this.state;
        this.datos()
        return (
            <GridItem xs={12} sm={12} md={12} id="crearOrden" style={{ overflow: "auto", padding: "10px" }}>
                <Card style={{ marginTop: "15px", paddingBottom: "15px" }}>
                    <CardTittle title="Registro de equipos" style={{ marginBottom: "0", paddingBottom: "5px" }} />
                    <CardContent style={{ paddingTop: "5px" }}>
                        <div className="mx-auto">{this.stepContentsss(activeStep)}</div>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map(label => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            {this.state.activeStep === steps.length ? (
                                <div>
                                    <Typography >Detalles del equipo</Typography>
                                    <button onClick={this.handleBack} className="btn btn-primary">ATRAS</button>
                                    <button onClick={this.enviarEquipo.bind(this)} className="btn btn-primary">GUARDAR</button>
                                </div>
                            ) : (
                                    <div>
                                        <Typography >{getStepContent(activeStep)}</Typography>
                                        <div>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={this.handleBack}
                                            >
                                                ATRAS
                </Button>
                                            <Button variant="contained" disabled={!this.state.validado} color="primary" onClick={this.handleNext}>
                                                {activeStep === steps.length - 1 ? 'VER DETALLES' : 'SIGUIENTE'}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </CardContent>
                </Card>
            </GridItem>
        )
    }

}

FormularioEquipo.propTypes = {
    classes: PropTypes.object,
};

export default (FormularioEquipo)