import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ExpandLess from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandMore from '@material-ui/icons/ExpandMore';
import Icon from "@material-ui/core/Icon";

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";
import store from "../../lib/store";
var modulos = []
class Sidebar extends React.Component {
  constructor({ ...props }) {
    super()
    this.state = {
      open: false,
      open2: -1
    }
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  handleClick = padre => (e) => {
    if (padre === this.state.open2) {
      this.setState({ open2: -1 });
    } else {
      this.setState({ open2: padre });
    }

  };

  componentWillMount() {

  }

  render() {
    const { classes, color, logo, image, logoText, routes, modulosbd } = this.props;
    var indexModulos = []
    var tipoDominio = store.getState().tipoDominio
    //= store.getState().perfilesUSuario
    //console.log(modulos)
    modulos.map((modulo) => {
      indexModulos.push(modulo.id)
    })
    //console.log(modulosbd)
    //console.log(indexModulos)
    //console.log(routes)
    /*routes.map((modulo, key) => {
      if (modulo.submodulos == null) {
        console.log(modulo)
        if (!indexModulos.includes(modulo.id)) {
          routes.splice(key, 1)
        }
      } else {
        modulo.submodulos.map((m, key2) => {
          if (indexModulos.includes(m.id)) {
            //hijos.push(m)
          } else {
            //routes[key].submodulos.splice(key2,1)
            //console.log("indice: "+key +" llave:"+key2)
          }
        })

      }
    })*/
    //console.log(routes)
    var links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          var activePro = " ";
          var listItemClasses;
          listItemClasses = classNames({
            [" " + classes[color]]: this.activeRoute(prop.layout + prop.path)
          });

          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: this.activeRoute(prop.layout + prop.path)
          });
          if (prop.submodulos === null) {
            return (
              <NavLink
                to={prop.layout + prop.path}
                className={activePro + classes.item}
                activeClassName="active"
                key={key}
              >

                <ListItem button className={classes.itemLink + listItemClasses}>
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(classes.itemIcon, whiteFontClasses, {
                        [classes.itemIconRTL]: this.props.rtlActive
                      })}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                      prop.id === 26
                        ?
                        tipoDominio === 'E' || tipoDominio === 'V'
                          ?
                          <prop.icon2
                            className={classNames(classes.itemIcon, whiteFontClasses, {
                              [classes.itemIconRTL]: this.props.rtlActive
                            })}
                          />
                          :
                          <prop.icon
                            className={classNames(classes.itemIcon, whiteFontClasses, {
                              [classes.itemIconRTL]: this.props.rtlActive
                            })}
                          />
                        :
                        <prop.icon
                          className={classNames(classes.itemIcon, whiteFontClasses, {
                            [classes.itemIconRTL]: this.props.rtlActive
                          })}
                        />
                    )}
                  <ListItemText
                    primary={
                      this.props.rtlActive ? prop.rtlName :
                        prop.id === 26
                          ?
                          tipoDominio === 'E' || tipoDominio === 'V'
                            ?
                            prop.name2
                            :
                            prop.name
                          :
                          prop.name
                    }
                    className={classNames(classes.itemText, whiteFontClasses, {
                      [classes.itemTextRTL]: this.props.rtlActive
                    })}
                    disableTypography={true}
                  />
                </ListItem>
              </NavLink>
            )
          } else {
            return (
              <div key={key}>
                <ListItem button className={classes.itemLink + listItemClasses} onClick={this.handleClick(key)} >
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(classes.itemIcon, whiteFontClasses, {
                        [classes.itemIconRTL]: this.props.rtlActive
                      })}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                      <prop.icon
                        className={classNames(classes.itemIcon, whiteFontClasses, {
                          [classes.itemIconRTL]: this.props.rtlActive
                        })}
                      />
                    )}
                  <ListItemText
                    primary={
                      prop.name
                    }
                    style={{ display: "inline", padding: 0 }}
                    className={classNames(classes.itemText, whiteFontClasses, {
                      [classes.itemTextRTL]: this.props.rtlActive
                    })}
                    disableTypography={true}
                  />
                  {this.state.open2 === key ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.open2 === key} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding className={classes.list}>
                    {prop.submodulos.map((sub, key) => {
                      //console.log(sub)
                      var activePro = " ";
                      var listItemClasses;
                      listItemClasses = classNames({
                        [" " + classes[color]]: this.activeRoute(sub.layout + sub.path)
                      });

                      const whiteFontClasses = classNames({
                        [" " + classes.whiteFont]: this.activeRoute(sub.layout + sub.path)
                      });
                      return (
                        <NavLink
                          to={sub.layout + sub.path}
                          className={activePro + classes.item}
                          activeClassName="active"
                          key={key}
                        >
                          <ListItem button className={classes.itemLink + listItemClasses} style={{ paddingLeft: "30px" }}>
                            {typeof prop.icon === "string" ? (
                              <Icon
                                className={classNames(classes.itemIcon, whiteFontClasses, {
                                  [classes.itemIconRTL]: this.props.rtlActive
                                })}
                              >
                                {sub.icon}
                              </Icon>
                            ) : sub.id === 4
                                ?
                                tipoDominio === 'E' || tipoDominio === 'V'
                                  ?
                                  (
                                    <sub.icon2
                                      className={classNames(classes.itemIcon, whiteFontClasses, {
                                        [classes.itemIconRTL]: this.props.rtlActive
                                      })}
                                    />
                                  )
                                  :
                                  (
                                    <sub.icon
                                      className={classNames(classes.itemIcon, whiteFontClasses, {
                                        [classes.itemIconRTL]: this.props.rtlActive
                                      })}
                                    />
                                  )
                                :
                                (
                                  <sub.icon
                                    className={classNames(classes.itemIcon, whiteFontClasses, {
                                      [classes.itemIconRTL]: this.props.rtlActive
                                    })}
                                  />
                                )
                            }
                            <ListItemText
                              primary={
                                this.props.rtlActive
                                  ?
                                  sub.rtlName
                                  :
                                  sub.id === 4
                                    ?
                                    tipoDominio === 'E' || tipoDominio === 'V'
                                      ?
                                      sub.name2
                                      :
                                      sub.name
                                    :
                                    sub.name
                              }
                              className={classNames(classes.itemText, whiteFontClasses, {
                                [classes.itemTextRTL]: this.props.rtlActive
                              })}
                              disableTypography={true}
                            />

                          </ListItem>
                        </NavLink>
                      )
                    })}
                  </List>
                </Collapse>
              </div>
            )
          }

        })}
      </List>
    );
    var brand = (
      <div className={classes.logo}>
        <a
          href="/admin/dashboard"
          className={classNames(classes.logoLink, {
            [classes.logoLinkRTL]: this.props.rtlActive
          })}
        >
          <div className={classes.logoImage}>
            <img src={logo} alt="logo" className={classes.img} />
          </div>
          {logoText}
        </a>
      </div>
    );
    return (
      <div>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={this.props.rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: classNames(classes.drawerPaper, {
                [classes.drawerPaperRTL]: this.props.rtlActive
              })
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {brand}
            <div className={classes.sidebarWrapper}>
              {this.props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
              {links}
            </div>
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            anchor={this.props.rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: classNames(classes.drawerPaper, {
                [classes.drawerPaperRTL]: this.props.rtlActive
              })
            }}
          >
            {brand}
            <div className={classes.sidebarWrapper}>{links}</div>
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}
/*const Sidebar = ({ ...props }) => {
  
};
*/
Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
