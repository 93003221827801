
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import 'bootstrap/dist/css/bootstrap.min.css';
import CardTittle from '@material-ui/core/CardHeader'
import GridItem from "components/Grid/GridItem.jsx";
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import Server from '../../lib/server'
import store from '../../lib/store'
import Axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import swal from 'sweetalert'
import { Button } from 'react-bootstrap'
import Typography from '@material-ui/core/Typography';
import Selects from 'react-select'
import DeleteIcon from '@material-ui/icons/Delete';
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';
import moment from 'moment'
import "moment/locale/es";
var vistaUsuario = false
var impuestoEditar = false
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
var colorDominio = store.getState().colorDominio
class MediosPago extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: new Date(),
            bodegasbd: [],
            productosbd: [],
            productoSeleccinado: {},
            bodegaSeleccionada: {},
            costoN: '',
            cantidadN: '',
            productostotal: [],
            totalCosto: 0,
            subTotal: 0,
            idEditar: 0,
            //DAtos a guardar
            descripcionN: '',
            minimoValor: '',
            valorn: '',
            modoEditar: false,
            nimpuesto: null,
            response: false
        };
    }

    componentWillMount() {
        /*impuestoEditar = this.props.unidadActualizar
        console.log(this.props)
        if (impuestoEditar != null) {
            this.setState({ modoEdicion: true, descripcionN: impuestoEditar.descripcion, checkCambio: impuestoEditar.cambio == "S", checkBoucher: impuestoEditar.comprobante == "S", checkCredito: impuestoEditar.credito == "S", valorn: impuestoEditar.maximo_cambio + '', minimoValor: impuestoEditar.minimo_valor, idEditar: impuestoEditar.id })
        }*/

        //bODEGAS
        var usuario = store.getState().usuario
        Axios.get(Server.hostPrueba + Server.puertoSSLBodega + '/api/bodegas/empresa/' + usuario.empresas_id, { headers: { Authorization: ntoken } })
            .then(responsee => {
                console.log(responsee.data)
                var bdg = []
                responsee.data.map((bodega, key) => {
                    bdg.push({
                        value: bodega.bodega_id,
                        label: bodega.descripcion + " -- " + bodega.codigo
                    })
                })
                this.setState({ bodegasbd: bdg })
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })

        //productos
        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/inicial/' + parseInt(usuario.empresas_id, 10), { headers: { Authorization: ntoken } })
            .then((res) => {
                console.log(res.data.data[0].productos_empresas)
                var prod = []
                var respuesta = res.data.data[0].productos_empresas
                console.log(respuesta)
                if (respuesta != null) {
                    respuesta.map((tienda, key) => {
                        prod.push({
                            value: tienda.id,
                            label: tienda.plu + " -- " + tienda.descripcion,
                            producto: tienda.descripcion,
                            plu: tienda.plu
                        })
                    })
                    this.setState({ productosbd: prod })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    componentDidMount() {
        this.intervalId = setInterval(this.timer.bind(this), 300000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    timer() {
        this.setState({
            currentCount: this.state.currentCount - 1
        })
        if (this.state.currentCount < 1) {
            clearInterval(this.intervalId);
        }
        this.actualizarToken()
    }

    actualizarToken = () => {
        Axios.get(Server.hostPruebasinssl + Server.puertoAutenticacion, { headers: { Authorization: ntoken } })
            .then(response => {
                if (response.status === 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 206) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                } else if (response.status === 200) {
                    //RESPEUSTA OK
                }
            })
    }

    eliminarProducto = (e) => {
        var mproducto = this.state.productostotal
        var ntotal = this.state.totalCosto
        var nsubtotal = this.state.subTotal
        ntotal = ntotal - parseFloat(mproducto[e].costo)
        nsubtotal = nsubtotal - parseFloat(mproducto[e].subtotal)
        mproducto.splice(e, 1)
        this.setState({ productostotal: mproducto, totalCosto: ntotal, subTotal: nsubtotal })
    }

    setearMiles = (numero) => {
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case "COP":
                return (
                    (new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(3)))
                )
                break;
            case "USD":
                return (new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(numero))
                break;

        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };


    changeSelect = name => event => {
        var estado = (event.target.value == 'false' ? false : true)
        this.setState({
            [name]: !estado
        });
    }

    agregarProducto = () => {
        var p = this.state.productostotal
        console.log(this.state.totalCosto)
        console.log(this.state.cantidadN)
        console.log(this.state.productoSeleccinado)
        if (parseInt(this.state.cantidadN) == 0) {
            swal("ERROR", "LA CANTIDAD DEBE SER MAYOR A CERO", "warning")
        } else if (parseFloat(this.state.cantidadN) == 0) {
            swal("ERROR", "EL COSTO DEBE SER MAYOR A CERO", "warning")
        } else if (parseInt(this.state.cantidadN) < 0) {
            swal("ERROR", "LA CANTIDAD DEBE DEBE SER UN VALOR POSITIVO", "warning")
        } else if (parseFloat(this.state.costoN) < 0) {
            swal("ERROR", "EL COSTO DEBE SER UN VALOR POSITIVO", "warning")
        } else if (!/^([0-9])*$/.test(this.state.cantidadN)) {
            swal("ERROR", "LA CANTIDAD DEBE SER UN VALOR ENTERO", "warning")
        }else {
            p.push({
                id: this.state.productoSeleccinado.value,
                producto: this.state.productoSeleccinado.producto,
                cantidad: this.state.cantidadN,
                costo: this.state.costoN,
                subtotal: this.state.cantidadN * this.state.costoN,
                plu: this.state.productoSeleccinado.plu
            })
            this.setState({ productostotal: p, totalCosto: this.state.totalCosto + parseFloat(this.state.costoN), subTotal: this.state.subTotal + parseFloat(this.state.costoN * this.state.cantidadN), productoSeleccinado: {}, cantidadN: '', costoN: '' })
        }

    }

    validarFromulario = () => {
        if (JSON.stringify(this.state.bodegaSeleccionada) == '{}' || this.state.bodegaSeleccionada1 != null) {
            swal("Fallo al crear", "SE DEBE SELECCIONAR LA BODEGA", "warning")
            return false
        } else if (this.state.productostotal.length == 0) {
            swal("Fallo al crear", "POR LO MENOS DEBES REGISTRAR UN PRODUCTO", "warning")
            return false
        } else {
            this.setState({
                response: true
            })
            return true
        }
    }

    registrar = () => {
        var usuario = store.getState().usuario
        var validar = this.validarFromulario()
        let MOVIMIENTO_INVENTARIO_INICIAL = 1
        var datos = {
            "movimiento": {
                "empresas_id": parseInt(usuario.empresas_id),
                "operacion": MOVIMIENTO_INVENTARIO_INICIAL,
                "fecha": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "consecutivo": 0,
                "persona_id": usuario.p_id,
                "persona_nit": "",
                "persona_nombre": "",
                "tercero_id": usuario.p_id,
                "tercero_nit": "",
                "tercero_nombre": "",
                "costo_total": parseInt(this.state.subTotal),
                "venta_total": 0,
                "impuesto_total": 0,
                "descuento_total": 0,
                "origen_id": 0,
                "impreso": "N",
                "create_user": usuario.p_id,
                "create_date": "",
                "remoto_id": 0,
                "sincronizado": 0,
                "movimiento_estado": 0
            },
            "bodega": {
                "bodega_idI": this.state.bodegaSeleccionada.value,
                //"bodega_idI": 3,
                "bodega_idF": null
            },
            "detalle":
                this.state.productostotal.map((producto, key) => {
                    return ({
                        "productos_id": producto.id,
                        "cantidad": producto.cantidad,
                        "costo_producto": producto.costo,
                        "precio": 0,
                        "descuento_id": 0,
                        "descuento_producto": 0,
                        "remoto_id": 0,
                        "sincronizado": 0,
                        "subtotal": 0
                    })
                })

        }
        console.log(datos)
        if (validar) {
            Axios.post(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/movimiento', datos, { headers: { Authorization: ntoken } })
                .then(response => {
                    this.setState({
                        response: false
                    })
                    console.log(response)
                    if (response.status === 201) {
                        swal({
                            title: response.data.message,
                            icon: "success",
                            closeOnClickOutside: false,
                            buttons: {
                                Seguir: {
                                    text: "OK",
                                    value: "continuar"
                                }
                            },
                        }).then((value) => {
                            switch (value) {

                                case "continuar":
                                    store.dispatch({
                                        type: "ACTUALIZACION_DATOS",
                                        actualizarvista: true
                                    })
                                    store.dispatch({
                                        type: "AJUSTE_INICIAL",
                                        ajusteInicial: false
                                    })
                                    //this.setState({loader:true,descripcionN:'',selectedDate:new Date(),selectedDateFin:new Date(),checkIPorcentaje:true,checkIValor:false,valorn:false,cambbioInicio: false,cambbioFin:false})
                                    break;

                                default:

                            }
                        });
                    } else {
                        swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                    }
                })
        }


    }

    enviarDatos = () => {
        var usuario = store.getState().usuario
        var dominio = store.getState().dominio
        var datos = {
            "descripcion": this.state.descripcionN,
            "credito": this.state.checkCredito ? 'S' : 'N',
            "cambio": this.state.checkCambio ? 'S' : 'N',
            "minimo_valor": parseInt(this.state.minimoValor),
            "comprobante": this.state.checkBoucher ? 'S' : 'N',
            "maximo_cambio": this.state.checkCambio ? this.state.valorn : '0',
            "empresas_id": parseInt(dominio, 10)
        }
        console.log(datos)
        Axios.post(Server.hostPrueba + Server.puertSSLParametrizacion + '/api/MediosPagos', datos, { headers: { Authorization: ntoken } })
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    swal({
                        title: "Medio de pago creado correctamente",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                store.dispatch({
                                    type: "AJUSTE_INICIAL",
                                    ajusteInicial: false
                                })
                                //this.setState({loader:true,descripcionN:'',selectedDate:new Date(),selectedDateFin:new Date(),checkIPorcentaje:true,checkIValor:false,valorn:false,cambbioInicio: false,cambbioFin:false})
                                break;

                            default:

                        }
                    });
                } else {
                    swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                }
            })
    }

    /*editarImpuesto = (id) => {
        this.state.impuestosbd.map((impuesto) => {
            if (impuesto.id == id) {
                this.setState({ modoEdicion: true, createbodega: true, descripcionN: impuesto.descripcion, checkIValor: impuesto.porcentaje_valor == "$", checkIPorcentaje: impuesto.porcentaje_valor == "%", valorn: impuesto.valor + '', selectedDate: impuesto.fecha_inicio, selectedDateFin: impuesto.fecha_fin, idEditar: impuesto.id })
            }
        })
    }*/

    cancelar = () => {
        store.dispatch({
            type: "AJUSTE_INICIAL",
            ajusteInicial: false
        })
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
    };

    actualizarDatos = () => {
        var usuario = store.getState().usuario
        var dominio = store.getState().dominio
        var datos = {
            "descripcion": this.state.descripcionN,
            "credito": this.state.checkCredito ? 'S' : 'N',
            "cambio": this.state.checkCambio ? 'S' : 'N',
            "minimo_valor": parseInt(this.state.minimoValor),
            "comprobante": this.state.checkBoucher ? 'S' : 'N',
            "maximo_cambio": this.state.checkCambio ? this.state.valorn : '0',
            "empresas_id": parseInt(dominio, 10)
        }
        console.log(datos)
        Axios.put(Server.hostPrueba + Server.puertSSLParametrizacion + '/api/MediosPagos/' + this.state.idEditar, datos, { headers: { Authorization: ntoken } })
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    swal({
                        title: "Medio de pago actualizado correctamente",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                store.dispatch({
                                    type: "AJUSTE_INICIAL",
                                    ajusteInicial: false
                                })
                                this.setState({ loader: true, })
                                break;

                            default:

                        }
                    });
                } else {
                    swal("Fallo al guardar", "Por favor verifique los datos", "danger");
                }
            })
    }

    render() {
        return (
            <div>
                <AppBar style={{ backgroundColor: colorDominio }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Close"
                            onClick={this.cancelar.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            style={{ marginLeft: "theme.spacing(2)", flex: 1, color: '#fff' }}
                        >
                            {vistaUsuario ? 'Edicion de PRODUCTO' : 'AJUSTE DE INVENTARIO INICIAL'}
                        </Typography>
                        {
                            vistaUsuario
                                ?
                                <Button style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} /*onClick={this.editarProducto.bind(this)}*/>
                                    GUARDAR
              </Button>
                                :
                                <Button style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} onClick={this.registrar.bind(this)} disabled={this.state.response}>
                                    TERMINAR Y GUARDAR
              </Button>
                        }

                    </Toolbar>
                </AppBar>
                <div className="row align-middle mx-auto" style={{ marginTop: '7%' }}>
                    <div className="col col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="numordencompra" className="col-form-label">Fecha</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                    <DatePicker
                                        disabled={true}
                                        format="yyyy-MM-dd HH:mm"
                                        id="datetime"
                                        value={this.state.selectedDate}
                                        //onChange={this.handleChangemoment}
                                        onChange={this.handleChangemoment}
                                        className="rounded form-control"
                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="numordencompra" className="col-form-label">Bodega</label>
                                <Selects
                                    placeholder="Seleccione"
                                    value={this.state.bodegaSeleccionada}
                                    onChange={this.handleChanges('bodegaSeleccionada')}
                                    options={this.state.bodegasbd}
                                />
                            </div>
                        </div>
                        <div className="row mx-auto" style={{ marginTop: '10px' }}>
                            <h5 className="mx-auto">Ingrese los datos del producto a agregar</h5>
                        </div>
                        <div className="row">
                            <div className="col-md-5">
                                <label htmlFor="numordencompra" className="col-form-label">PRODUCTO</label>
                                <Selects
                                    placeholder="Seleccione"
                                    value={this.state.productoSeleccinado}
                                    onChange={this.handleChanges('productoSeleccinado')}
                                    options={this.state.productosbd}
                                />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">Costo</label>
                                <div class="input-group" >
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">$</span>
                                    </div>
                                    <input id="numordencompra" value={this.state.costoN} disabled={this.state.productoSeleccinado == null || JSON.stringify(this.state.productoSeleccinado) == '{}'} autoComplete="off" className="form-control" type="number" min="1" onChange={this.handleChange('costoN')} />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="numordencompra" className="col-form-label">Cantidad</label>
                                <input id="numordencompra" value={this.state.cantidadN} disabled={this.state.productoSeleccinado == null || JSON.stringify(this.state.productoSeleccinado) == '{}'} autoComplete="off" className="form-control" type="number" min="1" onChange={this.handleChange('cantidadN')} />
                            </div>
                            <div className="bottom-aligned">
                                <button className="btn btn-primary bottom-aligned" disabled={this.state.costoN.trim() == '' || this.state.cantidadN.trim() == ''} onClick={() => this.agregarProducto()}>AGREGAR</button>
                            </div>
                        </div>
                        <div className="row mx-auto" style={{ marginTop: '10px' }}>
                            <h5 className="mx-auto">{this.state.productostotal.length == 0 ? 'Por favor cargue los productos' : 'Productos a guardar'}</h5>
                        </div>
                        {this.state.productostotal.length == 0
                            ? null :
                            <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                <div className="col-md-3 mx-auto">
                                    <label htmlFor="numordencompra" className="col-form-label text-center">Total de la operacion</label>
                                    <input id="numordencompra" value={'$' + parseFloat(this.state.subTotal).toFixed(3)} disabled={true} className="form-control text-center" type="text" />
                                </div>
                            </div>
                        }
                        {this.state.productostotal.length == 0
                            ? null :
                            <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                <div className="col col-md-10 mx-auto">
                                    <MDBTable bordered striped>
                                        <MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
                                            <tr>
                                                <th>Producto</th>
                                                <th>Plu</th>
                                                <th >Cantidad</th>
                                                <th >Costo</th>
                                                <th >Subtotal</th>
                                                <th >Acciones</th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {this.state.productostotal.map((row, key) => {
                                                console.log(row)
                                                return(
                                                    <tr key={key}>
                                                    <td component="th" scope="row">
                                                        {row.producto}
                                                    </td>
                                                    <td>{row.plu}</td>
                                                    <td>{row.cantidad}</td>
                                                    <td>{'$ '+this.setearMiles(row.costo)}</td>
                                                    <td>{'$ '+this.setearMiles(row.subtotal)}</td>
                                                    <td>{<button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.eliminarProducto(key)}><DeleteIcon /></button>}</td>
                                                </tr>
                                                )
                                            })}
                                        </MDBTableBody>
                                    </MDBTable>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>



        )
    }
}


export default MediosPago
