/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import AdminIcon from "@material-ui/icons/BusinessCenter";
import tiendas from "@material-ui/icons/StoreMallDirectory"
import prodcutoIcon from "@material-ui/icons/Fastfood"
import bodegaIcon from "@material-ui/icons/Storage"
import ordenCompraIcon from "@material-ui/icons/LocalGroceryStore"
import equipoIcon from "@material-ui/icons/SettingsRemote"
import reportesIcon from "@material-ui/icons/Assessment"
import configuracionIcon from "@material-ui/icons/Settings"
import dominioIcon from "@material-ui/icons/LocalGasStation"
import impuestoIcon from "@material-ui/icons/AccountBalance"
import mpagoIcon from "@material-ui/icons/MonetizationOn"
import resolucionIcon from "@material-ui/icons/FormatListNumbered"
import categoriaIcon from "@material-ui/icons/Category"
import perfilesICon from "@material-ui/icons/SupervisorAccountRounded"
import estructuraIcon from "@material-ui/icons/Sort"
import proveedoresIcon from "@material-ui/icons/LocalShipping"
import acuerdoIcon from "@material-ui/icons/InsertDriveFile"
import PromocionIcon from "@material-ui/icons/Receipt"
import EntradasIcon from "@material-ui/icons/CallReceived"
import TrasladosIcon from "@material-ui/icons/SwapHoriz"
import AjusteInicialIcon from "@material-ui/icons/Build"
import SalidasIcon from "@material-ui/icons/CallMade"
import UnidadesIcon from "@material-ui/icons/ViewModule"
import ClientesIcon from "@material-ui/icons/Accessibility"
import AjustePeriodicoIcon from "@material-ui/icons/CalendarToday"
import AccionesIcon from "@material-ui/icons/PanTool"
import ProduccionIcon from "@material-ui/icons/LocalActivity"
import AnularIcon from "@material-ui/icons/Backspace"
import AuditoriaIcon from "@material-ui/icons/Assignment" 
import VehiculosIcon from "@material-ui/icons/DirectionsCar" 
import SoporteIcon from "@material-ui/icons/ContactSupport" 
import CampaniaIcon from "@material-ui/icons/LocalAtm" 
import BonosIcon from "@material-ui/icons/ConfirmationNumber" 
import ExploreIcon from '@material-ui/icons/Explore'; /* Nuevo */
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
// core components/views for Admin layout
import AutomaticoView from "./views/FormularioAutomatico"
import DashboardView from "./views/Dashboard/Dashboard"
import EquiposView from "./views/empresa/equipos/equipos"
import SeguimientoEquiposView from "./views/empresa/equipos/seguimiento"
import MonitoreoView from "./views/empresa/equipos/monitoreo"
import soporteView from "./views/empresa/soporte/soporte"
import ParametrosView from "./views/empresa/parametros/parametros"
import DominioView from "./views/empresa/dominios/dominio"
import EstructuraView from "./views/empresa/estructura/estructura"
import BodegasView from "./views/configuraciones/Bodega/Bodega"
import ProductosView from "./views/configuraciones/productos/productos"
import CategoriasView from "./views/configuraciones/categorias/categorias"
import mPagosView from "./views/configuraciones/mediosPago/mediosPago"
import consecutivosView from "./views/configuraciones/Consecutivos/consecutivos"
import FEView from "./views/configuraciones/Consecutivos/indexFE"
import unidadesView from "./views/configuraciones/unidades/unidades"
import PerfilesView from "./views/configuraciones/perfiles/perfiles"
import auditoriaView from "./views/auditorias/auditoria"
import ImpuestosView from "./views/configuraciones/impuestos/impuestos"
import ProveedoresView from "./views/configuraciones/proveedores/proveedores"
//import AcuerdosView from "./views/configuraciones/acuerdos/acuerdos"
import AcuerdosView from "./views/configuraciones/acuerdos/NuevoAcuerdos"
import vehiculosView from "./views/empresa/Vehiculos/vehiculos"
import ClientesView from "./views/empresa/Clientes/clientes"
import FlotasView from "./views/empresa/Flotas/Flotas"
import CampaniaView from "./views/empresa/Campanias/Campania"
import BonosView from "./views/empresa/Bonos/Bonos"
import ProductosVoucherView from "./views/empresa/Bonos/ProductosVoucher"
import ReportesView from "./views/reportes/reporteVentas"
import OrdenCompraView from "./views/acciones/OrdenCompra/OrdenCompra"
import ProduccionView from "./views/acciones/movimientos/produccion"
import VentaView from "./views/acciones/movimientos/Venta"
import EntradasView from "./views/acciones/movimientos/entradas"
import TrasladosView from "./views/acciones/movimientos/translados"
import SalidasView from "./views/acciones/movimientos/salidas"
import AjusteInicialView from "./views/acciones/movimientos/ajusteInventarioInicial"
import AjustePeriodicoView from "./views/acciones/movimientos/ajusteInventarioPeriodico"
import UsuariosView from "./views/usuarios"
import TiendasView from "./views/Maps/MisTiendas"
import miTiendaView from "./views/Maps/miTienda"
import PromocionView from './views/acciones/movimientos/promociones'
import AnulacionView from './views/acciones/movimientos/anulacion'
import GeografiaView from './views/geografia/creacionPais' /* Nuevo */
import ModulosView from './views/configuraciones/Modulos/Modulos'

const dashboardRoutes = [
  {
    id: 1,
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardView,
    permisos: "",
    layout: "/admin",
    submodulos: null
  },{
    id: 2,
    path: "/empresa",
    name: "Empresa",
    icon: AdminIcon,
    component: EquiposView,
    permisos: "",
    layout: "/admin",
    submodulos: [
      {
        id: 3,
        path: "/equipos",
        name: "Equipos",
        icon: equipoIcon,
        component: EquiposView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      },{
        id: 49,
        path: "/seguimiento-equipos",
        name: "Seguimiento de equipos",
        icon: equipoIcon,
        component: SeguimientoEquiposView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      }, {
        id: 38,
        path: "/monitoreo",
        name: "Monitoreo",
        icon: equipoIcon,
        component: MonitoreoView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      },
      {
        id: 4,
        path: "/dominios",
        name: "Kioscos",
        name2: "EDS",
        icon2: dominioIcon,
        icon: tiendas,
        component: DominioView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      }, {
        id: 5,
        path: "/estructura",
        name: "Estructura",
        icon: estructuraIcon,
        permisos: "",
        component: EstructuraView,
        layout: "/admin",
        submodulos: null
      }, {
        id: 35,
        path: "/parametros",
        name: "Parametros",
        icon: estructuraIcon,
        permisos: "",
        component: ParametrosView,
        layout: "/admin",
        submodulos: null
      },{
        id: 40,
        path: "/clientes",
        name: "Clientes",
        icon: ClientesIcon,
        component: ClientesView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      },{
        id: 41,
        path: "/flotas",
        name: "Flotas",
        icon: VehiculosIcon,
        component: FlotasView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      },{
        id: 39,
        path: "/vehiculos",
        name: "Vehiculos",
        icon: VehiculosIcon,
        component: vehiculosView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      },{
        id: 50,
        path: "/facturacion-electronica",
        name: "Facturacion electronica",
        icon: equipoIcon,
        component: FEView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      },{
        id: 48,
        path: "/soporte",
        name: "Soporte",
        icon: SoporteIcon,
        component: soporteView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      }
    ]
  }, {
    id: 6,
    path: "/admin",
    name: "Configuraciones",
    icon: configuracionIcon,
    component: ProductosView,
    layout: "/admin",
    permisos: "",
    estado: true,
    submodulos: [
      {
        id: 46,
        path: "/modulos",
        name: "Gest. de modulos",        /* Nuevo /  / Nuevo */
        icon: AssignmentTurnedInIcon,     /* Nuevo /  / Nuevo */
        component: ModulosView,          /* Nuevo /  / Nuevo */
        permisos: "",                   /* Nuevo /  / Nuevo */
        layout: "/admin",              /* Nuevo /  / Nuevo */
        submodulos: null              /* Nuevo /  / Nuevo */
      },{
        id: 7,
        path: "/perfiles",
        name: "Perfiles",
        icon: perfilesICon,
        component: PerfilesView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      }, {
        id: 8,
        path: "/acuerdos",
        name: "Acuerdos",
        icon: acuerdoIcon,
        component: AcuerdosView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      }, {
        id: 28,
        path: "/unidades",
        name: "Unidades",
        icon: UnidadesIcon,
        component: unidadesView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      }, {
        id: 9,
        path: "/proveedores",
        name: "Proveedores",
        icon: proveedoresIcon,
        component: ProveedoresView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      },
      {
        id: 10,
        path: "/bodega",
        name: "Bodega",
        icon: bodegaIcon,
        component: BodegasView,
        layout: "/admin",
        permisos: "",
        submodulos: null
      }, {
        id: 11,
        path: "/categorias",
        name: "Categorias",
        icon: categoriaIcon,
        component: CategoriasView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      }, {
        id: 30,
        path: "/mediosPago",
        name: "Medios de pago",
        icon: mpagoIcon,
        component: mPagosView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      }, {
        id: 12,
        path: "/impuestos",
        name: "Impuestos",
        icon: impuestoIcon,
        component: ImpuestosView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      },
      {
        id: 13,
        path: "/productos",
        name: "Productos",
        icon: prodcutoIcon,
        component: ProductosView,
        layout: "/admin",
        permisos: "",
        submodulos: null
      }, {
        id: 31,
        path: "/consecutivos",
        name: "Resoluciones",
        icon: resolucionIcon,
        component: consecutivosView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      },{
        id: 42,
        path: "/campania",
        name: "Campañas",
        icon: CampaniaIcon,
        component: CampaniaView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      },{
        id: 43,
        path: "/bonos",
        name: "Bonos",
        icon: BonosIcon,
        component: BonosView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      },{
        id: 44,
        path: "/productosvoucher",
        name: "Productos",
        icon: prodcutoIcon,
        component: ProductosVoucherView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      },{
        id: 45,
        path: "/globales",               /* Nuevo */  /* Nuevo */
        name: "Globales",               /* Nuevo */  /* Nuevo */
        icon: ExploreIcon,             /* Nuevo */  /* Nuevo */
        component: GeografiaView,     /* Nuevo */  /* Nuevo */
        permisos: "",                /* Nuevo */  /* Nuevo */
        layout: "/admin",           /* Nuevo */  /* Nuevo */
        submodulos: null
      }
    ]

  }, {
    id: 14,
    path: "/reportes",
    name: "Reportes",
    icon: reportesIcon,
    permisos: "",
    component: ReportesView,
    layout: "/admin",
    submodulos: null
  }, {
    id: 17,
    path: "/acciones",
    name: "Acciones",
    icon: AccionesIcon,
    component: OrdenCompraView,
    permisos: "",
    layout: "/admin",
    submodulos: [
      {
        id: 18,
        path: "/ordenCompra",
        name: "Orden de compra",
        icon: ordenCompraIcon,
        component: OrdenCompraView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      }, {
        id: 19,
        path: "/entradas",
        name: "Entradas",
        icon: EntradasIcon,
        component: EntradasView,
        layout: "/admin",
        permisos: "",
        submodulos: null
      }, {
        id: 20,
        path: "/traslados",
        name: "Traslados",
        icon: TrasladosIcon,
        component: TrasladosView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      }, {
        id: 21,
        path: "/salidas",
        name: "Salidas",
        icon: SalidasIcon,
        component: SalidasView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      }, {
        id: 22,
        path: "/ajuste_inventario_inicial",
        name: "Ajuste Inicial",
        icon: AjusteInicialIcon,
        component: AjusteInicialView,
        //  component: AutomaticoView,
        layout: "/admin",
        permisos: "",
        submodulos: null
      }/*,{
        id:23,
        path: "/ajuste_inventario_periodico",
        name: "Ajuste Periodico",
        icon: AjustePeriodicoIcon,
        component: AjustePeriodicoView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      },{
        id:24,
        path: "/produccion",
        name: "Produccion",
        icon: PromocionIcon,
        component: ProduccionView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      },*/, {
        id: 27,
        path: "/promociones",
        name: "Promociones",
        icon: ProduccionIcon,
        component: PromocionView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      }, {
        id: 37,
        path: "/ventas",
        name: "Ventas",
        icon: AnularIcon,
        component: AnulacionView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      }/*, {
        id: 37,
        path: "/ventas",
        name: "Venta online",
        icon: AnularIcon,
        component: VentaView,
        permisos: "",
        layout: "/admin",
        submodulos: null
      }*/
    ]
  }, {
    id: 25,
    path: "/usuarios",
    name: "Usuarios",
    icon: Person,
    permisos: "",
    component: UsuariosView,
    layout: "/admin",
    submodulos: null,
    estado: false
  }, /*{
    id: 29,
    path: "/auditoria",
    name: "Auditoria",
    icon: AuditoriaIcon,
    component: auditoriaView,
    layout: "/admin",
    permisos: "",
    submodulos: null,
    estado: false
  },
  {
    path: "/icons",
    name: "Icons",
    icon: BubbleChart,
    component: IconosView,
    layout: "/admin",
    submodulos: null
  },*/
  {
    id: 26,
    path: "/tiendas",
    name: "Mis tiendas",
    name2: "Mis EDS",
    icon: tiendas,
    icon2: dominioIcon,
    permisos: "",
    component: TiendasView,
    layout: "/admin",
    submodulos: null
  }/*, {
    id: 37,
    path: "/mitienda",
    name: "Mi tienda",
    icon: tiendas,
    permisos: "",
    component: miTiendaView,
    layout: "/admin",
    submodulos: null
  }*/
];



export default dashboardRoutes;
