import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios'
import { MDBDataTable } from 'mdbreact';
import store from '../../lib/store'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ValidarICon from '@material-ui/icons/Check'
import CancelarICon from '@material-ui/icons/Cancel'
import Tooltip from '@material-ui/core/Tooltip';
import Server from '../../lib/server';
import swal from 'sweetalert';
import Selects from 'react-select';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import 'moment/locale/es'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AjusteIcon from '@material-ui/icons/SettingsApplications'
import { Button, Form, FormLabel, FormControl, FormGroup, FormText, Row, Col } from 'react-bootstrap'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from '@material-ui/core/Checkbox';
const idModulo = 3
var permisos = ''
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
const tipoProtocolo = [
    { label: "protocolo A", value: 1 },
    { label: "protocolo B", value: 2 },
    { label: "protocolo C", value: 3 },
    { label: "protocolo D", value: 4 }
];
var tipoSurtidor = '';
var numIsla = '';
var numSurtidor = '';
var fVolumen = '';
var fImporte = '';
var fPrecio = '';
var fInventario = '';
var tipoSurtidor = '';
var lectorPort = '';
var impresoraPort = '';
var colorDominio = store.getState().colorDominio
class Equipos extends Component {
    constructor() {
        super();
        this.state = {
            ventanaCreacionSd: false,
            ventanaCreacionEquipo: false,
            ListaAlmacenamientos: [],
            ListaEquipos: [],
            actualizarDatos: false,
            tabla: false,
            loader: false,
            ventanaConfiguracion: false,
            ventanaMarcas: false,
            ventanaProtocolo: false,
            creacionProtocolo: false,
            creacionMarcas: false,
            creacionCaras: false,
            creacionSurtidor: false,
            creacionGrados: false,

            producto: '',
            checkControlador: 0,
            //Configuracion surtidor
            idEquipo: '',
            idSurtidor: '',
            numIsla: 0,
            numSurtidor: 0,
            nCara: 0,
            nGrado: 0,
            nManguera: 0,
            productoSeleccionado: {},
            productosbd: [],
            detalles: [],
            fVolumen: 0,
            fImporte: 0,
            fPrecio: 0,
            fInventario: 0,
            lectorIp: '',
            lectorIp2: '',
            lectorIp3: '',
            lectorIp4: '',
            lazoIp: '',
            lazoIp2: '',
            lazoIp3: '',
            lazoIp4: '',
            lazoPort: '',
            rfidPort: '',
            lectorPort: '',
            impresoraIp: '',
            impresoraIp2: '',
            impresoraIp3: '',
            impresoraIp4: '',
            impresoraPort: '',
            tiposSurtidoresbd: [],
            tiposProtocolossbd: [],
            value: 0,

        }
        console.log(store.getState())
        store.subscribe(() => {
            this.setState({
                ventanaCreacionSd: store.getState().ventanaCreacionSD,
                ventanaCreacionEquipo: store.getState().ventanaCreacionEquipo,
                actualizarDatos: store.getState().actualizarvista,
                ventanaConfiguracion: store.getState().creacionParametrosEquipos,
                ventanaMarcas: store.getState().ventanaMarcas,
                ventanaProtocolo: store.getState().ventanaProtocolo,
            })
        })
    }


    componentWillReceiveProps() {
        if (this.state.actualizarDatos) {
            console.log("estoy acaaa")
            this.actualizarDatos()
        }
    }

    componentWillMount() {
        this.actualizarDatos()
        var surtidorDetales = store.getState().infoSurtidor
        console.log(store.getState().infoSurtidor)
        if (surtidorDetales != null) {
            const { surtidor, surtidores_detalles } = surtidorDetales
            if (surtidor != null) {
                var impresoraIp = ""
                var lectorIp = ""
                var lazoIp = ""
                var ip = "0.0.0.0"
                if (surtidor.impresora_ip != null || surtidor.lector_ip === '') {
                    impresoraIp = surtidor.impresora_ip.split(".")
                } else {
                    impresoraIp = ip.split(".")
                }
                if (surtidor.lector_ip != null || surtidor.lector_ip === '') {
                    lectorIp = surtidor.lector_ip.split(".")
                } else {
                    lectorIp = ip.split(".")
                }
                if (surtidor.ip != null || surtidor.ip === "") {
                    lazoIp = surtidor.ip.split(".")
                } else {
                    lazoIp = ip.split(".")
                }
                this.setState({
                    idEquipo: surtidor.equipo_id,
                    idSurtidor: surtidor.id,
                    numIsla: surtidor.islas_id,
                    numSurtidor: surtidor.surtidor,
                    fVolumen: surtidor.factor_volumen_parcial != null ? surtidor.factor_volumen_parcial : 0,
                    fImporte: surtidor.factor_importe_parcial != null ? surtidor.factor_importe_parcial : 0,
                    fPrecio: surtidor.factor_precio != null ? surtidor.factor_precio : 0,
                    fInventario: surtidor.factor_inventario != null ? surtidor.factor_inventario : 0,
                    impresoraIp: impresoraIp[0] != null ? impresoraIp[0] : 0,
                    impresoraIp2: impresoraIp[1] != null ? impresoraIp[1] : 0,
                    impresoraIp3: impresoraIp[2] != null ? impresoraIp[2] : 0,
                    impresoraIp4: impresoraIp[3] != null ? impresoraIp[3] : 0,
                    lectorIp: lectorIp[0] != null ? lectorIp[0] : 0,
                    lectorIp2: lectorIp[1] != null ? lectorIp[1] : 0,
                    lectorIp3: lectorIp[2] != null ? lectorIp[2] : 0,
                    lectorIp4: lectorIp[3] != null ? lectorIp[3] : 0,
                    lazoIp: lazoIp[0] != null ? lazoIp[0] : 0,
                    lazoIp2: lazoIp[1] != null ? lazoIp[1] : 0,
                    lazoIp3: lazoIp[2] != null ? lazoIp[2] : 0,
                    lazoIp4: lazoIp[3] != null ? lazoIp[3] : 0,
                    rfidPort: surtidor.lector_rfid != null ? surtidor.lector_rfid : '',
                    checkControlador: surtidor.controlador === 1 ? 0 : 1,
                    impresoraPort: surtidor.impresora_port != null ? surtidor.impresora_port : 0,
                    lectorPort: surtidor.lector_port != null ? surtidor.lector_port : 0,
                    lazoPort: surtidor.port != null ? surtidor.port : 0,
                    tipoSurtidor: {
                        value: surtidor.surtidores_tipos_id,
                        label: surtidor.surtidores_tipos_desc
                    },
                    tipoProtocolo: {
                        value: surtidor.surtidores_protocolos_id,
                        label: surtidor.surtidores_protocolos_desc
                    }

                })
            }
            if (surtidores_detalles != null) {
                var detalless = []
                surtidores_detalles.map((detalle, key) => {
                    detalless.push({
                        'cara': detalle.cara,
                        'manguera': detalle.manguera,
                        'grado': detalle.grado,
                        'producto': detalle.productos_desc,
                        'idProducto': detalle.productos_id,
                        'id': detalle.id
                    })
                })
                this.setState({
                    detalles: detalless
                })
            }

        }
    }

    agregarGrado = () => {
        var detalless = this.state.detalles
        var stringDetalle = []
        detalless.map((detalle) => {
            stringDetalle.push(
                JSON.stringify({
                    'cara': detalle.cara,
                    'manguera': detalle.manguera,
                    'grado': detalle.grado,
                })
            )
        })
        var actual = JSON.stringify({
            'cara': parseInt(this.state.nCara),
            'manguera': parseInt(this.state.nManguera),
            'grado': parseInt(this.state.nGrado),
        })
        console.log(actual)
        console.log(stringDetalle)

        if (parseInt(this.state.nCara) === 0 || isNaN(parseInt(this.state.nCara))) {
            swal("ERROR", "LA CARA DEBE SER UN NUMERO Y MAYOR A 0", "warning")
        } else if (isNaN(parseInt(this.state.nManguera))) {
            swal("ERROR", "LA MANGUERA DEBE SER UN NUMERO", "warning")
        } else if (isNaN(parseInt(this.state.nGrado))) {
            swal("ERROR", "EL GRADO DEBE SER UN NUMERO", "warning")
        } else if (stringDetalle.includes(actual)) {
            swal("ERROR", "YA EXISTE UNA ASIGNACION CON LAS MISMAS PROPIEDADES", "warning")
        } else if (JSON.stringify(this.state.productoSeleccionado) === '{}' || this.state.productoSeleccionado === null) {
            swal("ERROR", "DEBE SELECCIONAR EL PRODUCTO", "warning")
        } else {
            detalless.push({
                'id': null,
                'cara': this.state.nCara,
                'manguera': this.state.nManguera,
                'grado': this.state.nGrado,
                'producto': this.state.productoSeleccionado.label,
                'idProducto': this.state.productoSeleccionado.value,
            })

            this.setState({
                detalles: detalless,
                nCara: 0,
                nManguera: 0,
                nGrado: 0,
                productoSeleccionado: {}
            })
        }

    }

    retirarGrado = (posicion) => {
        var detalless = this.state.detalles
        detalless.splice(posicion, 1)
        this.setState({
            detalles: detalless
        })
    }

    formularioSD = () => {
        store.dispatch({
            type: "CREACION_SD",
            ventanaCreacionSD: !this.state.ventanaCreacionSd
        })
    }

    formularioConfiguracion = () => {
        store.dispatch({
            type: "CREACION_PARAMETROS_EQUIPOS",
            creacionParametrosEquipos: !this.state.ventanaConfiguracion
        })
    }

    formularioMarcas = () => {
        console.log("Marcas");
        store.dispatch({
            type: "AGREGAR_MARCAS",
            ventanaMarcas: !this.state.ventanaMarcas
        })
    }

    formularioProtocolo = () => {
        store.dispatch({
            type: "AGREGAR_PROTOCOLO",
            ventanaProtocolo: !this.state.ventanaProtocolo
        })
    }

    formularioEquipo = () => {
        store.dispatch({
            type: "CREACION_EQUIPO",
            ventanaCreacionEquipo: !this.state.ventanaCreacionEquipo
        })
    }
    autorizarEquipo = () => {

    }
    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        switch (name) {
            case 'tipoSurtidor':

                break;
            case 'tipoProtocolo': //cambio11
                console.log("tipo protocolo")
                break;
        }
    }

    tabFiltros = (newValue) => {
        this.setState({
            value: newValue
        })
    }

    actualizarDatos() {
        var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
        var datos = store.getState().usuario
        var hijos = store.getState().empresasHijos
        this.setState({ actualizarDatos: false })
        Axios.get(Server.hostPrueba + Server.puertoEquipos + '/api/surtidor/tipos', { headers: { Authorization: ntoken } })
            .then(response => {
                var tipoSurtidor = [];
                if (response.status === 200) {
                    response.data.data.map((surtidor) => {
                        tipoSurtidor.push({
                            value: surtidor.id,
                            label: surtidor.descripcion,
                        })
                    })
                }
                this.setState({
                    tiposSurtidoresbd: tipoSurtidor
                })
                console.log(response);
            })
        Axios.get(Server.hostPrueba + Server.puertoEquipos + '/api/surtidor/protocolos', { headers: { Authorization: ntoken } })
            .then(response => {
                var tipoSurtidor = [];
                if (response.status === 200) {
                    response.data.data.map((surtidor) => {
                        tipoSurtidor.push({
                            value: surtidor.id,
                            label: surtidor.descripcion,
                        })
                    })
                }
                this.setState({
                    tiposProtocolossbd: tipoSurtidor
                })
            })
        Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/producto/' + parseInt(datos.empresas_id, 10), { headers: { Authorization: ntoken } })
            .then((res) => {
                //console.log(res)
                if (res.data != null) {
                    var productos = []
                    if (res.status === 200) {
                        res.data.Productos.map((producto, key) => {
                            if (producto.tipo === "5") {
                                productos.push({
                                    value: producto.id,
                                    label: producto.descripcion.toUpperCase() /*+ " -- " + producto.razon_social.toUpperCase()*/
                                })
                            }


                        })
                    } else if (res.status == 205) {
                        swal("ERROR", "LA SESION HA CADUCADO", "warning")
                        setTimeout(function () { window.location.href = '/'; }, 3000);
                    }
                    this.setState({ prodcutosbd: productos, loader: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }


    handleChange = (name) => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
        switch (name) {
            case 'numCaras':
                var mangeras = []
                for (let index = 0; index < event.target.value; index++) {
                    mangeras[index] = 0
                }
                this.setState({
                    numMAngeras: mangeras
                })
                break
        }
    };

    handleChangeTABLA = () => {
        this.setState({
            tabla: !this.state.tabla
        })
    };

    handledialogProtocolo = () => {
        console.log("PROTOCOLOS");
        store.dispatch({
            type: "AGREGAR_PROTOCOLO",
            tipoProtocolos: this.state.tipoProtocolo,
            creacionProtocolo: !this.state.creacionProtocolo
        })
    }

    handledialogMarcas = () => {
        console.log("MARCAS");
        store.dispatch({
            type: "AGREGAR_MARCAS",
            marcas: this.state.marcas,
            creacionMarcas: !this.state.creacionMarcas
        })
    }

    validacionesKeyUp = () => {
        const { lectorIp, lectorIp2, lectorIp3, lectorIp4, impresoraIp, impresoraIp2, impresoraIp3, impresoraIp4, lazoIp, lazoIp2, lazoIp3, lazoIp4 } = this.state;
        if (lectorIp <= 255 && lectorIp >= 0 && lectorIp2 <= 255 && lectorIp2 >= 0 && lectorIp3 <= 255 && lectorIp3 >= 0 && lectorIp4 <= 255 && lectorIp4 >= 0) {
            console.log("Utilice un formato de ip valido")
        } else if (impresoraIp <= 255 && impresoraIp >= 0 && impresoraIp2 <= 255 && impresoraIp2 >= 0 && impresoraIp3 <= 255 && impresoraIp3 >= 0 && impresoraIp4 <= 255 && impresoraIp4 >= 0) {
            console.log("Utilice un formato de ip valido")
        }
    }

    cerrarVentana() {
        store.dispatch({
            type: "CREACION_PARAMETROS_EQUIPOS",
            creacionParametrosEquipos: false,
        })
    }

    handleChangeCheck = (n) => {
        this.setState({
            checkControlador: n
        })
    }

    enviarDatos() {
        console.log("envio");
        //var validado = this.validarForm();
        var usuario = store.getState().usuario;
        var numIsla = this.state.numIsla;
        var numSurtidor = this.state.numSurtidor;
        var fVolumen = this.state.fVolumen; //cambio3
        var tipoProtocolo = this.state.tipoProtocolo;
        var fImporte = this.state.fImporte; //cambio 4
        var fPrecio = this.state.fPrecio;
        var fInventario = this.state.fInventario;
        var tipoSurtidor = this.state.tipoSurtidor; //cambio9
        var ipImpresora = this.state.impresoraIp + "." + this.state.impresoraIp2 + "." + this.state.impresoraIp3 + "." + this.state.impresoraIp4;
        var ipLector = this.state.lectorIp + "." + this.state.lectorIp2 + "." + this.state.lectorIp3 + "." + this.state.lectorIp4;
        var ipLazo = this.state.lazoIp + "." + this.state.lazoIp2 + "." + this.state.lazoIp3 + "." + this.state.lazoIp4;
        var lectorPort = this.state.lectorPort;
        var lazoPort = this.state.lazoPort;
        var impresoraPort = this.state.impresoraPort;
        var data = {
            "id": this.state.idSurtidor,
            "equipo_id": this.state.idEquipo,
            "surtidor": numSurtidor,
            "islas_id": numIsla,
            "surtidores_tipos_id": tipoSurtidor.value,
            "surtidores_protocolos_id": tipoProtocolo.value,
            "mac": '',
            "ip": ipLazo,
            "port": lazoPort,
            "update_user": usuario.p_id,
            "factor_volumen_parcial": fVolumen,
            "factor_importe_parcial": fImporte,
            "factor_precio": fPrecio,
            "lector_ip": ipLector,
            "lector_port": lectorPort,
            "impresora_ip": ipImpresora,
            "impresora_port": impresoraPort,
            "factor_inventario": fInventario,
            "controlador": this.state.checkControlador === 0 ? 1 : 2,
            "lector_rfid": this.state.rfidPort,
            "detalles_surtidor": this.state.detalles.map((detalle) => {
                return ({
                    "id": detalle.id,
                    "surtidor": this.state.idSurtidor,
                    "cara": detalle.cara,
                    "manguera": detalle.manguera,
                    "grado": detalle.grado,
                    "productos_id": detalle.idProducto
                })
            })
        }

        Axios.put(Server.hostPrueba + Server.puertoEquipos + '/api/surtidor', data, { headers: { Authorization: ntoken } })
            .then((response) => {
                console.log(response)
                var mensaje = response.data.mensaje;
                if (response.data != null) {
                    if (response.status === 201) {
                        if (response.data.success) {
                            swal({
                                title: mensaje,
                                icon: "success",
                                closeOnClickOutside: false,
                                buttons: {
                                    Seguir: {
                                        text: "OK",
                                        value: "continuar"
                                    }
                                },
                            }).then((value) => {
                                switch (value) {

                                    case "continuar":
                                        store.dispatch({
                                            type: "CREACION_PARAMETROS_EQUIPOS",
                                            creacionParametrosEquipos: false,
                                        })
                                        break;

                                    default:
                                }
                            });
                        } else {
                            swal("ERROR", mensaje, "warning");
                        }
                    } else if (response.status == 205) {
                        swal("ERROR", "LA SESION HA CADUCADO", "warning")
                        setTimeout(function () { window.location.href = '/'; }, 3000);
                    }
                }
            })
    }

    validarForm = () => {
        const { numIsla, numSurtidor, fVolumen, fImporte, fPrecio, fInventario, tipoProtocolo, tipoSurtidor, } = this.state; //cambio4
        if (!numIsla || numIsla.trim == '' || numIsla.length == 0) {
            swal("ERROR", "Debe introducir el numero de isla", "warning");
        } else if (!numSurtidor || numSurtidor.trim == '' || numSurtidor.length == 0) {
            swal("ERROR", "Debe introducir el numero de surtidor", "warning");
        } else if (!fVolumen || fVolumen.trim == '' || fVolumen.length == 0) {
            swal("ERROR", "Debe introducir primero el numero de factor de volumen", "warning");
        } else if (!tipoProtocolo || JSON.stringify(tipoProtocolo) === "{}") {
            swal("ERROR", "Debe seleccionar un protocolo", "warning");
        } else if (!fImporte || fImporte.trim == '' || fImporte.length == 0) {
            swal("ERROR", "Debe introducir primero el numero de factor de importe", "warning");
        } else if (!fPrecio || fPrecio.trim == '' || fPrecio.length == 0) { //cambio5
            swal("ERROR", "Debe introducir primero el numero de factor de precio", "warning");
        } else if (!fInventario || fInventario.trim == '' || fInventario.length == 0) {//cambio6
            swal("ERROR", "Debe introducir primero el numero de factor de inventario", "warning");
        } else if (!tipoSurtidor || JSON.stringify(tipoSurtidor) === "{}") { //cambio8
            swal("ERROR", "Seleccione un tipo de surtidor primero", "warning");
        } else {
            return this.enviarDatos();
        }
    }

    render() {
        return (
            <div>
                <AppBar style={{ backgroundColor: colorDominio }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Close"
                            onClick={this.cerrarVentana.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            style={{ marginLeft: "theme.spacing(2)", flex: 1, color: '#fff' }}
                        >
                            CONFIGURACION EQUIPO
                        </Typography>
                        <Button style={{ backgroundColor: colorDominio, border: '1px white solid', cursor: 'pointer' }} disabled={this.state.response} onClick={this.validarForm.bind(this)}>
                            GUARDAR</Button>


                    </Toolbar>
                </AppBar>
                <div className="row align-middle mx-auto" style={{ marginTop: '7%' }}>
                    <div className="col-md-12">
                        <div className="row">
                            <Tabs
                                value={this.state.tabFiltros}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={this.tabFiltros}
                                aria-label="disabled tabs example"
                                className="border"
                            >
                                <Tab label="CONFIGURACION SURTIDOR" style={this.state.value === 0 ? { backgroundColor: "#c3c3c3" } : {}} onClick={() => this.tabFiltros(0)} />
                                <Tab label="ASIGNACIONES" style={this.state.value === 1 ? { backgroundColor: "#c3c3c3" } : {}} onClick={() => this.tabFiltros(1)} />
                            </Tabs>
                        </div>

                        {
                            this.state.value === 0
                                ?
                                <div className="row" style={{ padding: '30px' }}>
                                    <div className="col-md-7">
                                        <div className="row">
                                            <h6><u>CONFIGURACION DE EQUIPO</u></h6>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="numIsla" className="col-form-label">N. DE ISLA</label>
                                                <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" min="1" max="10" value={this.state.numIsla} onChange={this.handleChange('numIsla')} />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="numSurtidor" className="col-form-label">N. DE SURTIDOR</label>
                                                <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" min="1" max="16" value={this.state.numSurtidor} onChange={this.handleChange('numSurtidor')} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12" style={{ zIndex: 561 }}>
                                                <label hmtlFor="tipoSurtidor" className="col-form-label">TIPO DE SURTIDOR</label> {/* cambio7 */}
                                                <Selects
                                                    placeholder="Seleccione el tipo de surtidor"
                                                    value={this.state.tipoSurtidor}
                                                    onChange={this.handleChanges('tipoSurtidor')}
                                                    options={this.state.tiposSurtidoresbd}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12" style={{ zIndex: 560 }}>
                                                <label hmtlFor="tipoProtocolo" className="col-form-label">PROTOCOLO</label> {/* cambio7 */}
                                                <Selects
                                                    placeholder="Seleccione el tipo de protocolo"
                                                    value={this.state.tipoProtocolo}
                                                    onChange={this.handleChanges('tipoProtocolo')}
                                                    options={this.state.tiposProtocolossbd}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="fVolumen" className="col-form-label">F. VOLUMEN</label>
                                                <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" min="0" max="9" value={this.state.fVolumen} onChange={this.handleChange('fVolumen')} />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="fImporte" className="col-form-label">F. IMPORTE P.</label>
                                                <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" min="0" max="9" value={this.state.fImporte} onChange={this.handleChange('fImporte')} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="fPrecio" className="col-form-label">F. PRECIO</label>
                                                <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" min="0" max="9" value={this.state.fPrecio} onChange={this.handleChange('fPrecio')} />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="fImporte" className="col-form-label">F. INVENTARIO</label>
                                                <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" min="0" max="9" value={this.state.fInventario} onChange={this.handleChange('fInventario')} />
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: '5px' }}>
                                            <h6><u>CONTROLADOR</u></h6>
                                        </div>
                                        <div className="row" style={{ marginTop: '5px' }}>
                                            <div className="col col-md-6" style={{ marginTop: "0" }}>
                                                <Tooltip title="SI SE MARCA INDICA QUE EL EQUIPO SE COMUNICARA POR TCP" placement="top">
                                                    <FormControlLabel control={<Checkbox style={{ padding: "0" }} checked={this.state.checkControlador === 0} value={this.state.checkControlador === 0} onChange={() => this.handleChangeCheck(0)} />} label="TCP" />
                                                </Tooltip>
                                            </div>
                                            <div className="col col-md-6" style={{ marginTop: "0" }}>
                                                <Tooltip title="SI SE MARCA INDICA QUE EL EQUIPO SE COMUNICARA PORSERIAL" placement="top">
                                                    <FormControlLabel control={<Checkbox style={{ padding: "0" }} checked={this.state.checkControlador === 1} value={this.state.checkControlador === 1} onChange={() => this.handleChangeCheck(1)} />} label="SERIAL" />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        {
                                            this.state.checkControlador === 0
                                                ?
                                                <div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <label htmlFor="ip" className="col-form-label">DIRECCION IP DEL LAZO</label>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-3" style={{ padding: '1px' }}>
                                                                    <input autocomplete="off" type="number" class="form-control" onBlur={() => this.validacionesKeyUp()} aria-describedby="basic-addon1" min="0" max="255" maxLength="3" value={this.state.lazoIp} onChange={this.handleChange('lazoIp')} />
                                                                </div>
                                                                <div className="col-md-3" style={{ padding: '1px' }}>
                                                                    <input autocomplete="off" type="number" class="form-control" onBlur={() => this.validacionesKeyUp()} aria-describedby="basic-addon1" min="0" max="255" maxLength="3" value={this.state.lazoIp2} onChange={this.handleChange('lazoIp')} />
                                                                </div>
                                                                <div className="col-md-3" style={{ padding: '1px' }}>
                                                                    <input autocomplete="off" type="number" class="form-control" onBlur={() => this.validacionesKeyUp()} aria-describedby="basic-addon1" min="0" max="255" maxLength="3" value={this.state.lazoIp3} onChange={this.handleChange('lazoIp3')} />
                                                                </div>
                                                                <div className="col-md-3" style={{ padding: '1px' }}>
                                                                    <input autocomplete="off" type="number" class="form-control" onBlur={() => this.validacionesKeyUp()} aria-describedby="basic-addon1" min="0" max="255" maxLength="3" value={this.state.lazoIp4} onChange={this.handleChange('lazoIp4')} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12" style={{ padding: '0' }}>
                                                            <label htmlFor="puerto" className="col-form-label">PUERTO DEL LAZO</label>
                                                            <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" value={this.state.lazoPort} onChange={this.handleChange('lazoPort')} />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    <div className="row">
                                                        <div className="col-md-12" style={{ padding: '0' }}>
                                                            <label htmlFor="puerto" className="col-form-label">NOMBRE DE PUERTO SERIAL</label>
                                                            <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" value={this.state.lazoPort} onChange={this.handleChange('lazoPort')} />
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    <div className="col-md-5">
                                        <div className="row">
                                            <h6><u>CONFIGURACION DEL LECTOR IBUTTON</u></h6>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <label htmlFor="ip" className="col-form-label">DIRECCION IP</label>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3" style={{ padding: '1px' }}>
                                                        <input autocomplete="off" type="number" class="form-control" onBlur={() => this.validacionesKeyUp()} aria-describedby="basic-addon1" min="0" max="255" maxLength="3" value={this.state.lectorIp} onChange={this.handleChange('lectorIp')} />
                                                    </div>
                                                    <div className="col-md-3" style={{ padding: '1px' }}>
                                                        <input autocomplete="off" type="number" class="form-control" onBlur={() => this.validacionesKeyUp()} aria-describedby="basic-addon1" min="0" max="255" maxLength="3" value={this.state.lectorIp2} onChange={this.handleChange('lectorIp2')} />
                                                    </div>
                                                    <div className="col-md-3" style={{ padding: '1px' }}>
                                                        <input autocomplete="off" type="number" class="form-control" onBlur={() => this.validacionesKeyUp()} aria-describedby="basic-addon1" min="0" max="255" maxLength="3" value={this.state.lectorIp3} onChange={this.handleChange('lectorIp3')} />
                                                    </div>
                                                    <div className="col-md-3" style={{ padding: '1px' }}>
                                                        <input autocomplete="off" type="number" class="form-control" onBlur={() => this.validacionesKeyUp()} aria-describedby="basic-addon1" min="0" max="255" maxLength="3" value={this.state.lectorIp4} onChange={this.handleChange('lectorIp4')} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12" style={{ padding: '0' }}>
                                                <label htmlFor="puerto" className="col-form-label">PUERTO</label>
                                                <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" value={this.state.lectorPort} onChange={this.handleChange('lectorPort')} />
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: '15px' }}>
                                            <h6><u>CONFIGURACION DE LA IMPRESORA</u></h6>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <label htmlFor="dirIp" className="col-form-label">DIRECCION IP</label>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3" style={{ padding: '1px' }}>
                                                        <input autocomplete="off" type="number" class="form-control" onBlur={() => this.validacionesKeyUp()} aria-describedby="basic-addon1" min="0" max="255" value={this.state.impresoraIp} onChange={this.handleChange('impresoraIp')} />
                                                    </div>
                                                    <div className="col-md-3" style={{ padding: '1px' }}>
                                                        <input autocomplete="off" type="number" class="form-control" onBlur={() => this.validacionesKeyUp()} aria-describedby="basic-addon1" min="0" max="255" value={this.state.impresoraIp2} onChange={this.handleChange('impresoraIp2')} />
                                                    </div>
                                                    <div className="col-md-3" style={{ padding: '1px' }}>
                                                        <input autocomplete="off" type="number" class="form-control" onBlur={() => this.validacionesKeyUp()} aria-describedby="basic-addon1" min="0" max="255" value={this.state.impresoraIp3} onChange={this.handleChange('impresoraIp3')} />
                                                    </div>
                                                    <div className="col-md-3" style={{ padding: '1px' }}>
                                                        <input autocomplete="off" type="number" class="form-control" onBlur={() => this.validacionesKeyUp()} aria-describedby="basic-addon1" min="0" max="255" value={this.state.impresoraIp4} onChange={this.handleChange('impresoraIp4')} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12" style={{ padding: '0' }}>
                                                <label htmlFor="surtidor" className="col-form-label">PUERTO</label>
                                                <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" value={this.state.impresoraPort} onChange={this.handleChange('impresoraPort')} />
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: '15px' }}>
                                            <h6><u>CONFIGURACION RFID</u></h6>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12" style={{ padding: '0' }}>
                                                <label htmlFor="surtidor" className="col-form-label">PUERTO</label>
                                                <input autocomplete="off" type="text" class="form-control" aria-describedby="basic-addon1" value={this.state.rfidPort} onChange={this.handleChange('rfidPort')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="row" style={{ padding: '30px', paddingBottom: '50px' }}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label htmlFor="numIsla" className="col-form-label">CARA</label>
                                                <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" min="1" max="10" value={this.state.nCara} onChange={this.handleChange('nCara')} />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="numIsla" className="col-form-label">MANGERA</label>
                                                <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" min="1" max="10" value={this.state.nManguera} onChange={this.handleChange('nManguera')} />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="numIsla" className="col-form-label">GRADO</label>
                                                <input autocomplete="off" type="number" class="form-control" aria-describedby="basic-addon1" min="1" max="10" value={this.state.nGrado} onChange={this.handleChange('nGrado')} />
                                            </div>
                                        </div>
                                        <div className="row  bottom-aligned">
                                            <div className="col-md-8" style={{ zIndex: 560 }}>
                                                <label hmtlFor="tipoProtocolo" className="col-form-label">PRODUCTO</label>
                                                <Selects
                                                    placeholder="Seleccione el tipo de protocolo"
                                                    value={this.state.productoSeleccionado}
                                                    onChange={this.handleChanges('productoSeleccionado')}
                                                    options={this.state.prodcutosbd}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <button className="btn btn-success btn-block" onClick={() => this.agregarGrado()}>AGREGAR</button>
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: '10px' }}>
                                            {
                                                this.state.detalles != null
                                                    ?
                                                    this.state.detalles.map((detalle, key) => {
                                                        console.log(detalle)
                                                        return (

                                                            <div className="col-md-6" style={{ paddingTop: '10px' }}>
                                                                <List className="border rounded">
                                                                    <ListItem alignItems="flex-start">
                                                                        <ListItemText
                                                                            primary={"Cara: " + detalle.cara + " - Manguera: " + detalle.manguera}
                                                                            secondary={
                                                                                <React.Fragment>
                                                                                    <Typography
                                                                                        component="span"
                                                                                        variant="body2"
                                                                                        color="textPrimary"
                                                                                    >
                                                                                        {"Grado: " + detalle.grado}
                                                                                    </Typography>
                                                                                    {"Producto: " + detalle.producto}
                                                                                </React.Fragment>
                                                                            }
                                                                        />
                                                                        <ListItemSecondaryAction>
                                                                            <IconButton aria-label="Delete" onClick={() => this.retirarGrado(key)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                </List>
                                                            </div>

                                                        )
                                                    })
                                                    : null

                                            }
                                        </div>
                                    </div>
                                </div>
                        }


                    </div>
                </div>

            </div>
        );
    }
}


export default Equipos;