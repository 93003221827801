import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Paper from '@material-ui/core/Paper';
import Slide from "@material-ui/core/Slide"
import store from '../../lib/store'
import Server from '../../lib/server'
import Axios from 'axios'
import Ojoicon from "@material-ui/icons/RemoveRedEye"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';
import Table from 'react-bootstrap/Table'
import ReactExport from "react-export-excel";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Selects from 'react-select';
import AppBar from '@material-ui/core/AppBar';
import ListIcon from '@material-ui/icons/List';
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker, TimePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/luxon';
import moment from 'moment'
import "moment/locale/es";
import "./reportes.css";
import DeleteIcon from '@material-ui/icons/Delete';
import swal from 'sweetalert'
import Loader from '../../components/Loader'
import { isNull } from 'util';
import pdfIcon from "../../assets/img/pdf.png";
import excelIcon from "../../assets/img/excel.png";

import logoTerpel from '../../assets/img/logos/logo_terpel.png'
import logoDeuna from '../../assets/img/logos/logoo_r2_c2.png'
import linea1 from '../../assets/img/linea.png'
import linea2 from '../../assets/img/linea2.png'
import Tooltip from '@material-ui/core/Tooltip';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const idModulo = 19
var permisos = ''
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
class Entradas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //Variables de componente principal
            createOrdenDeCompra: false,
            actualizarDatos: false,
            movimientosbd: [],
            currentCount: 10,
            ultimaActualizacion: new Date(),
            value: 0,
            valueKardex: 1,
            //Kardex
            bodegaKardexSeleccionada: [],
            ProductoKardexSeleccionado: [],
            ProductosDeBodegabds: [],
            empresaSeleccionadaKardex: {},
            ProductoKardexSeleccionadoTiendas: {},
            bodegasbd: [],
            ProductosDeBodegabd: [],
            ventanaKArdex: false,
            kRDEXpRODUCTOS: [],
            COSTOkaRDEX: '',
            COSTObODEGAkaRDEX: '',
            CANTIDADkaRDEX: '',
            bodegasporEmpresasKArdex: [],
            valueUtilidad: 0,
            //Cierres
            valueCierres: 1,
            selectedDate: new Date(),
            selectedDateFin: new Date(),
            cambbioInicio: false,
            cambbioFin: false,
            cambbioInicioVenta: false,
            cambbioFinVenta: false,
            empresasbd: [],
            empresaSeleccionadaCierre: {},
            cierresbd: [],
            empleadoCierresbd: [],
            empleadoCierreSeleccionado: {},
            vistaDetallesCierre: false,
            detallesCierre: [],
            detallesCierreVentas: [],
            valueVentas: 3,
            valueZ: 0,
            selectedDateVenta: moment(new Date()).format("YYYY-MM-DD ") + "00:00:00",
            selectedDateFinventa: moment(new Date()).format("YYYY-MM-DD ") + "23:59:59",
            selectedDatez: new Date(),
            selectedDateFinz: new Date(),
            selectedDateFinanciero: new Date(),
            cambioFinanciero: false,
            ventasXFecha: null,
            reporteZ: [],
            reporteFinananciero: [],
            multiEmpresasZ: [],
            empresasZSeleccionadas: [],
            ventaZSeleccionada: [],
            dialogdetallesZ: false,
            multiCategorias: [],
            multiCategoriasSeleccionadas: [],
            productosxutilidadSeleccionados: [],
            productosxutilidadbd: [],
            productosUtilidad: "",
            categoriasUtilidad: '',
            utilidadbd: [],
            valueUtilidadEmpresa: 0,
            valueUtilidadCategoria: 0,
            valueFacturacionEmpresa: 0,
            //Exceles
            datosExcel: [],
            datosExcelVentaDetalles: [],
            datosExcelFecha: [],
            datosExcelFechaDetalles: [],
            datosExcelFacturacion: [],
            excelKardexBodega: [],
            excelKardexTienda: [],
            excelCierreFecha: [],
            excelCierrePromotor: [],
            excelZFinanciera: [],
            excelUtilidad: [],
            multiEmpresasVentas: "",
            multiEmpresasVentasSeleccionadas: [],

            //nuevos
            excel2020Compras: [],
            excel2020ComprasDetalladas: [],
            //finnuevos
            valueEmpresasFecha: 0,
            buscoVenta: false,
            categoriasHijosbd: [],
            LibroFacturacion: [],
            bodegasVInventariobd: [],
            //Loader
            vistaCargando: false,
            //Detalles
            dialogdetalles: false,
            movimientoSeleccionado: null,
            dialogoDetallesCierres: false,
            detallesCierrre: null,
            dialogoUtilidadXVentas: false,
            detallesUtilidadXVentas: null,
            dialogoUtilidadXCategorias: false,
            detallesUtilidadXCategorias: null,
            detallesUtilidadXCategorias: null,
            dialogoArqueos: false,
            detallesArqueos: null,
            //seleccionados valor inventario
            valorInventario: null,
            valueInventarioEmpresa: 0,
            arqueos: [],
            //voucher
            bonosXempresas: null,
            bonosXpromotor: null,
            //Strings
            bodegasStrings: "",
            productosStrings: "",
            empresasStrings: "",
            imprimirPdf: false,
            //compras
            comprasbd: [],
            proveedoresbd: [],
            valueCompras: 0,
            dialogdetallesCompra: false,
            CompraSeleccionada: null,
            //ProductosxEmpresa
            productosEmpresa: null,
            dialogPEmpresa: false,
            empresaSeleccionado: null,
            //Facturacion
            facturacionbd: null,
            permisoFacturacion: false,
            entradaSeleccionada: null,
            modoDetalles: false,
            htmlDescarga: false,
            fechaActualizacion: new Date("2019-11-02 10:30:00"),
            //FACTURACION ELECTRONICA
            tipoSeleccionado: null,
            tiposBd: [
                {
                    label: "F. CONVENCIONAL",
                    value: 9
                }, {
                    label: "F. ELECTRONICA",
                    value: 15
                }
            ],
            mostrarExcelUtilidad: false,
            ventasCierreMensual: []
        }
        store.subscribe(() => {
            this.setState({
                createOrdenDeCompra: store.getState().ajusteInicial,
                actualizarDatos: store.getState().actualizarvista,
            })
        })

    }

    handleChangemoment = date => {
        this.setState({ selectedDate: date, cambbioInicio: true });
    }
    handleChangemomentFin = date => {
        this.setState({ selectedDateFin: date, cambbioFin: true });
    }
    handleChangemomentventa = date => {
        this.setState({ selectedDateVenta: date, cambbioInicioVenta: true });
    }
    handleChangemomentFinVenta = date => {
        this.setState({ selectedDateFinventa: date, cambbioFinVenta: true });
    }
    handleChangemomentZ = date => {
        this.setState({ selectedDatez: date });
    }
    handleChangemomentFinZ = date => {
        this.setState({ selectedDateFinz: date });
    }
    handleChangemomentFinanciero = date => {
        this.setState({ selectedDateFinanciero: date, cambioFinanciero: true });
    }


    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.actualizarDatos) {
            this.obtenerDatos()
            return true
        }
        return true;
    }

    imprimirMasVendidos2 = (nombre) => {
        this.setState({
            imprimirPdf: true,
        })
        let t = this
        setTimeout(function () { t.imprimirLibroFacturacion(nombre) }, 500)
    }

    imprimir = (nombre) => {
        const nd = document.getElementById('capture');
        var estilos = `<style>
        .tile {
            font-family: 'Raleway', sans-serif;
            font-family:11px;
            font-style:bold;
            text-color: #0000; 
            }
        td {
            font-family: 'Raleway', sans-serif;
            font-size:9px;
            }
        
        .numero{
            font-family: 'Roboto', sans-serif;
            font-size:14px;}
        .numero2{
            font-family: 'Roboto', sans-serif;
            font-size:11px;}
            
        .fontipo{
            color:#C60000;
            font-family: 'Raleway', sans-serif;
            font-size:11px;
            }
                        </style>`
        var download = {
            "template": estilos + nd.innerHTML,
            "filename": nombre + ".pdf"
        }
        Axios.post(Server.HostPDF + Server.puertoPdf + '/api/pdf/download', download)
            .then(response => {
                var url = response.data.url
                var link = document.createElement('a');
                link.setAttribute("target", "_blank")
                link.download = response.data.filename;
                link.href = url;
                link.click();
                this.setState({
                    imprimirPdf: false,
                    entradaSeleccionada: null,
                })
            })


    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        switch (name) {
            case 'bodegaKardexSeleccionada':
                if (this.state.value === 6) {
                    var categorias = ""
                    value.map((empresa, key) => {
                        if (key === 0) {
                            categorias += empresa.value
                        } else {
                            categorias += "," + empresa.value
                        }
                    })
                    if (categorias === "") {
                        this.setState({
                            //bodegasporEmpresasKArdex: bdg,
                            ProductoKardexSeleccionado: []
                        })
                    } else {
                        Axios.get(Server.hostPrueba + Server.puertoInventario + '/api/bodegas/productos/' + categorias, { headers: { Authorization: ntoken } })
                            .then((res) => {
                                if (res.status === 200) {
                                    if (res.data != null) {
                                        var productostotal = []
                                        var productostotalString = []
                                        res.data.map((producto, key) => {
                                            var string = JSON.stringify({
                                                value: producto.productos.id,
                                                label: producto.productos.plu + " -- " + producto.productos.descripcion
                                            })
                                            if (!productostotalString.includes(string)) {
                                                productostotal.push({
                                                    value: producto.productos.id,
                                                    label: producto.productos.plu + " -- " + producto.productos.descripcion
                                                })
                                                productostotalString.push(string)
                                            }
                                        })
                                        this.setState({ ProductosDeBodegabd: productostotal })
                                    }
                                } else if (res.status == 205) {
                                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                    setTimeout(function () { window.location.href = '/'; }, 2000);
                                }

                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                    this.setState({
                        bodegasStrings: categorias
                    })
                } else {
                    Axios.get(Server.hostPrueba + Server.puertoInventario + '/api/bodegas/productos/' + value.value, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            if (res.status === 200) {
                                if (res.data != null) {
                                    var productostotal = []
                                    res.data.map((producto, key) => {
                                        productostotal.push({
                                            value: producto.productos.id,
                                            label: producto.productos.descripcion + " -- " + producto.productos.plu
                                        })
                                    })
                                    this.setState({ ProductosDeBodegabd: productostotal })
                                }
                            } else if (res.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 2000);
                            }

                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                break;
            case 'ProductoKardexSeleccionado':
                if (this.state.value === 6) {
                    var categorias = ""
                    value.map((empresa, key) => {
                        if (key === 0) {
                            categorias += empresa.value
                        } else {
                            categorias += "," + empresa.value
                        }
                    })
                    this.setState({
                        productosStrings: categorias
                    })

                }
                break;
            case 'empresaSeleccionadaKardex':
                Axios.get(Server.hostPrueba + Server.puertoSSLBodega + '/api/bodegas/empresa/' + value.value, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        var bdg = ""
                        responsee.data.map((bodega, key) => {
                            bdg += bodega.bodega_id + ","
                        })
                        bdg = bdg.slice(0, bdg.length - 1)
                        Axios.get(Server.hostPrueba + Server.puertoInventario + '/api/bodegas/productos/' + bdg, { headers: { Authorization: ntoken } })
                            .then((res) => {
                                if (res.status == 200) {
                                    if (res.data != null) {
                                        var productostotal = []
                                        res.data.map((producto, key) => {
                                            productostotal.push({
                                                value: producto.productos.id,
                                                label: producto.productos.descripcion + " -- " + producto.productos.plu
                                            })
                                        })
                                        this.setState({ ProductosDeBodegabd: productostotal, ProductosDeBodegabds: productostotal })
                                    }
                                } else if (res.status == 205) {
                                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                    setTimeout(function () { window.location.href = '/'; }, 2000);
                                }

                            })
                            .catch((err) => {
                                console.log(err)
                            })
                        this.setState({ bodegasporEmpresasKArdex: bdg })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/convenios/productos/' + parseInt(value.value, 10) + '/contratos', { headers: { Authorization: ntoken } })
                    .then((res) => {
                        var data = []
                        if (res.status === 200) {
                            if (res.data.data != null) {
                                res.data.data.map((tienda, key) => {
                                    data.push({
                                        value: tienda.contratos_productos.productos_id,
                                        label: tienda.productos_empresas[0].descripcion + " -- " + tienda.productos_empresas[0].plu
                                    })
                                })
                                this.setState({ ProductosDeBodegabd: data })
                            }
                        } else if (res.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }

                    })
                    .catch((err) => {
                        console.log(err)
                    })
                break;
            case 'empresaSeleccionadaCierre':
                //
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/persona/empresas/' + value.value, { headers: { Authorization: ntoken } })
                    .then(response => {
                        if (response.status == 200) {
                            var empleados = []
                            if (response.data.rows != null) {
                                response.data.rows.map((empleado, key) => {
                                    empleados.push({
                                        value: empleado.persona.id,
                                        label: empleado.persona.nombres + " " + empleado.persona.apellidos
                                    })
                                })
                                this.setState({ empleadoCierresbd: empleados })
                            }
                            //onsole.log(response)
                        } else if (response.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                        //this.setState({ users: datos, loader: false })
                    })
                break;
            case 'multiEmpresasZ':
                var empresasZ = ""
                value.map((empresa, key) => {
                    if (key === 0) {
                        empresasZ += empresa.value
                    } else {
                        empresasZ += "," + empresa.value
                    }
                })
                this.setState({
                    empresasZSeleccionadas: empresasZ
                })
            case 'multiCategoriasSeleccionadas':
                if (this.state.valueUtilidad === 1) {
                    var categorias = ""
                    value.map((empresa, key) => {
                        if (key === 0) {
                            categorias += empresa.value
                        } else {
                            categorias += "," + empresa.value
                        }
                    })
                    this.setState({
                        categoriasUtilidad: categorias
                    })
                } else if (this.state.valueUtilidad === 2) {
                    Axios.get(Server.hostPruebasinssl + Server.puertSSLParametrizacion + '/api/grupos/categoria/' + store.getState().dominio, { headers: { Authorization: ntoken } })
                        .then(response => {
                            var categoriass = parseInt(value.value)
                            if (response.status == 200) {
                                var productos = []
                                if (response.data != null) {
                                    response.data.map((categoria) => {
                                        if (parseInt(categoria.id) === categoriass) {
                                            categoria.productos.map((producto) => {
                                                productos.push({
                                                    value: producto.id,
                                                    label: producto.plu + " -- " + producto.descripcion
                                                })
                                            })
                                        }

                                    })
                                    this.setState({ productosxutilidadbd: productos })
                                }
                            } else if (response.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 2000);
                            }
                            //this.setState({ users: datos, loader: false })
                        })
                }


                break;
            case 'productosxutilidadSeleccionados':
                var categorias = ""
                value.map((empresa, key) => {
                    if (key === 0) {
                        categorias += empresa.value
                    } else {
                        categorias += "," + empresa.value
                    }
                })
                //console.log(empresasZ)
                this.setState({
                    productosUtilidad: categorias
                })
                break;
            case 'multiEmpresasVentasSeleccionadas':
                if (this.state.value === 6) {
                    var categorias = ""
                    value.map((empresa, key) => {
                        if (key === 0) {
                            categorias += empresa.value
                        } else {
                            categorias += "," + empresa.value
                        }
                    })
                    if (categorias === "") {
                        this.setState({
                            //bodegasporEmpresasKArdex: bdg,
                            bodegasVInventariobd: []
                        })
                    } else {
                        Axios.get(Server.hostPrueba + Server.puertoSSLBodega + '/api/bodegas/empresa/' + categorias, { headers: { Authorization: ntoken } })
                            .then(responsee => {
                                var bdg = ""
                                var bdgs = []
                                responsee.data.map((bodega, key) => {
                                    // bdg += bodega.bodega_id + ","
                                    bdgs.push({
                                        value: bodega.bodega_id,
                                        label: bodega.descripcion
                                    })
                                })
                                bdg = bdg.slice(0, bdg.length - 1)
                                this.setState({
                                    //bodegasporEmpresasKArdex: bdg,
                                    bodegasVInventariobd: bdgs
                                })
                            })
                            .catch(function (error) {
                                // handle error
                                console.error(error);
                            })
                    }
                    this.setState({
                        empresasStrings: categorias
                    })
                    break;
                } else if (this.state.value === 7 || this.state.value === 4) {
                    var categorias = ""
                    value.map((empresa, key) => {
                        if (key === 0) {
                            categorias += empresa.value
                        } else {
                            categorias += "," + empresa.value
                        }
                    })
                    this.setState({
                        empresasStrings: categorias
                    })
                } else {
                    var categorias = ""
                    value.map((empresa, key) => {
                        if (key === 0) {
                            categorias += empresa.value
                        } else {
                            categorias += "," + empresa.value
                        }
                    })
                    this.setState({
                        multiEmpresasVentas: categorias
                    })
                    break;
                }

        }
    }

    detallesVenta = (movimiento) => {
        this.setState({ movimientoSeleccionado: movimiento, dialogdetalles: true })
    }

    cerrarVenta = () => {
        this.setState({ movimientoSeleccionado: null, dialogdetalles: false })
    }
    detallesCierrrre = (cierre) => {
        this.setState({ detallesCierrre: cierre, dialogoDetallesCierres: true })
    }

    cerrarCierre = () => {
        this.setState({ detallesCierrre: null, dialogoDetallesCierres: false })
    }
    detallesUtilidadVentas = (utilidad) => {
        this.setState({ detallesUtilidadXVentas: utilidad, dialogoUtilidadXVentas: true })
    }
    detallesUtilidadCategoria = (utilidad) => {
        this.setState({ detallesUtilidadXCategorias: utilidad, dialogoUtilidadXCategorias: true })
    }

    cerrarUtilidadVentas = () => {
        this.setState({ detallesUtilidadXVentas: null, dialogoUtilidadXVentas: false })
    }
    detalleArqueo = (arqueo) => {
        this.setState({ detallesArqueos: arqueo, dialogoArqueos: true })
    }

    cerrarAqueo = () => {
        this.setState({ detallesArqueos: null, dialogoArqueos: false })
    }
    cerrarUtilidadCategoria = () => {
        this.setState({ detallesUtilidadXCategorias: null, dialogoUtilidadXCategorias: false })
    }

    timer() {
        this.setState({
            currentCount: this.state.currentCount - 1
        })
        if (this.state.currentCount < 1) {
            clearInterval(this.intervalId);
        }
        this.obtenerDatos()
    }
    componentDidMount() {
        //this.intervalId = setInterval(this.timer.bind(this), 10000);
    }
    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    cerrarKArdex = () => {
        this.setState({ productoKArdex: null, ventanaKArdex: false, ProductoKardexSeleccionado: {}, bodegaKardexSeleccionada: {} })
    }


    consultaCierresFecha = () => {
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        var datos = {
            "accion": "cierre",
            "tipo_reporte": 3,
            "fecha_inicial": this.state.cambbioInicio ? moment(new Date(this.state.selectedDate.toString())).format("YYYY-MM-DD ") + "00:00:00" : moment(new Date()).format("YYYY-MM-DD ") + "00:00:00",
            "fecha_final": this.state.cambbioFin ? moment(new Date(this.state.selectedDateFin.toString())).format("YYYY-MM-DD ") + "23:59:59" : moment(new Date()).format("YYYY-MM-DD ") + "23:59:59",
            "empresas_id": usuario.tipo == "3" ? hijos : usuario.empresas_id
        }
        Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes/', datos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status == 200) {
                    if (responsee.data.data.length === 0 || responsee.data.data === null) {
                        swal("NO HAY DATOS SUFICIENTES", "", "warning")
                    }
                    this.setState({ cierresbd: responsee.data.data })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
                swal("NO HAY DATOS EN EL MOMENTO", "", "warning")
            })
    }

    consultaArqueo = () => {
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        var datos = {}
        if (usuario.tipo === "5") {
            datos = {
                "accion": "arqueo_detalle",
                "empresas_id": usuario.empresas_id,
                "fecha_final": moment(new Date(this.state.selectedDateVenta.toString())).format("YYYY-MM-DD ") + "24:00:00"
            }
        } else {
            datos = {
                "accion": "arqueo_detalle",
                "empresas_id": this.state.empresasStrings === "" ? hijos : this.state.empresasStrings,
                "fecha_final": moment(new Date(this.state.selectedDateVenta.toString())).format("YYYY-MM-DD ") + "24:00:00"

            }
        }

        Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes/', datos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status == 200) {
                    this.setState({ arqueos: responsee.data.data[0] })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
                swal("NO HAY DATOS EN EL MOMENTO", "", "warning")
            })
    }

    consultaZFecha = () => {
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        var datos = {}
        if (this.state.value === 3) {
            if (usuario.tipo === "5") {
                datos = {
                    "accion": "ventas_x_dia",
                    "tipo_reporte": -1,
                    "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD") + " 00:00:00",
                    "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD") + " 23:59:59",
                    "empresas_id": usuario.tipo == "3" ? hijos : usuario.empresas_id
                }
            } else {
                datos = {
                    "accion": "ventas_x_dia",
                    "tipo_reporte": -1,
                    "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD") + " 00:00:00",
                    "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD") + " 23:59:59",
                    "empresas_id": this.state.empresasZSeleccionadas
                }
            }
            this.AccionLoader()
            Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes/', datos, { headers: { Authorization: ntoken } })
                .then(responsee => {
                    this.AccionLoader()
                    if (responsee.status == 200) {
                        var reportes = responsee.data.data
                        this.setState({ reporteZ: reportes })
                    } else if (responsee.status == 205) {
                        swal("ERROR", "LA SESION HA CADUCADO", "warning")
                        setTimeout(function () { window.location.href = '/'; }, 2000);
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                    this.AccionLoader()
                    swal("NO HAY DATOS EN EL MOMENTO", "", "warning")
                })
        } else {
            if (this.state.valueCompras === 0) {
                if (usuario.tipo === "5") {
                    datos = {
                        "accion": "compras_productos",
                        "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD") + " 00:00:00",
                        "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD") + " 23:59:59",
                        "empresas_id": usuario.tipo === "3" ? hijos : usuario.empresas_id
                    }
                } else {
                    datos = {
                        "accion": "compras_productos",
                        "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD") + " 00:00:00",
                        "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD") + " 23:59:59",
                        "empresas_id": this.state.empresasZSeleccionadas
                    }
                }
                this.AccionLoader()
                Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes/', datos, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        this.AccionLoader()
                        if (responsee.status == 200) {
                            var reportes = responsee.data.data[0]
                            this.setState({ comprasbd: reportes })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                        this.AccionLoader()
                        swal("NO HAY DATOS EN EL MOMENTO", "", "warning")
                    })
            }

        }
    }


    abrirDialogProductos = (empresa) => {
        this.setState({
            dialogPEmpresa: true,
            empresaSeleccionado: empresa
        })
    }

    cerrarDialogProductos = () => {
        this.setState({
            dialogPEmpresa: false,
            empresaSeleccionado: null
        })
    }

    consultaZFecha = () => {
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        var datos = {}
        if (this.state.value === 3) {
            if (usuario.tipo === "5") {
                datos = {
                    "accion": "ventas_x_dia",
                    "tipo_reporte": -1,
                    "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD") + " 00:00:00",
                    "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD") + " 23:59:59",
                    "empresas_id": usuario.tipo == "3" ? hijos : usuario.empresas_id
                }
            } else {
                datos = {
                    "accion": "ventas_x_dia",
                    "tipo_reporte": -1,
                    "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD") + " 00:00:00",
                    "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD") + " 23:59:59",
                    "empresas_id": this.state.empresasZSeleccionadas
                }
            }
            this.AccionLoader()
            Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes/', datos, { headers: { Authorization: ntoken } })
                .then(responsee => {
                    this.AccionLoader()
                    if (responsee.status == 200) {
                        var reportes = responsee.data.data
                        this.setState({ reporteZ: reportes })
                    } else if (responsee.status == 205) {
                        swal("ERROR", "LA SESION HA CADUCADO", "warning")
                        setTimeout(function () { window.location.href = '/'; }, 2000);
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                    this.AccionLoader()
                    swal("NO HAY DATOS EN EL MOMENTO", "", "warning")
                })
        } else if (this.state.value === 9) {
            this.AccionLoader()
            Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/convenios/getAll/' + (usuario.tipo === "5" ? usuario.empresas_id : this.state.empresasZSeleccionadas) + '/productos', { headers: { Authorization: ntoken } })
                .then(responsee => {
                    this.AccionLoader()
                    var productos = []
                    if (responsee.status === 200) {
                        productos = responsee.data.empresas
                    }
                    this.setState({
                        productosEmpresa: productos

                    })
                })
                .catch(function (error) {
                    // handle error
                    this.AccionLoader()
                    console.error(error);
                })
        } else if (this.state.value === 10) {
            var dd = {
                "accion": "facturacion",
                "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD") + " 00:00:00",
                "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD") + " 23:59:59",
                "empresas_id": store.getState().empresasHijos
            }
            this.AccionLoader()
            Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes/', dd, { headers: { Authorization: ntoken } })
                .then(responsee => {
                    this.AccionLoader()
                    if (responsee.status == 200) {
                        if (responsee.data.data != null) {
                            var reportes = responsee.data.data
                            this.setState({ facturacionbd: reportes })
                        }
                    } else if (responsee.status == 205) {
                        swal("ERROR", "LA SESION HA CADUCADO", "warning")
                        setTimeout(function () { window.location.href = '/'; }, 2000);
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                    this.AccionLoader()
                    swal("NO HAY DATOS EN EL MOMENTO", "", "warning")
                })
        } else {
            if (this.state.valueCompras === 0) {

                var usuario = store.getState().usuario
                this.AccionLoader()
                var dataPost = {
                    "empresas_id": usuario.tipo === "5" ? parseInt(usuario.empresas_id, 10) : this.state.empresasZSeleccionadas,
                    "operacion": "3",
                    "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD") + " 00:00:00",
                    "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD") + " 23:59:59"
                }
                Axios.post(Server.hostPruebasinssl + Server.puertoProducto + '/api/movimiento/lista/operacion/', dataPost, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        var movimientosTotal = []
                        this.AccionLoader()
                        if (responsee.status == 200) {
                            if (responsee.data.movimientos != null) {
                                responsee.data.movimientos.map((mov, key) => {
                                    var imp = 0
                                    if (mov.detalle != null) {
                                        mov.detalle.map((det) => {
                                            if (det.impuestos != null) {
                                                det.impuestos.map((impu) => {
                                                    var coston = 0
                                                    coston = det.unidades_contenido_desc.valor * det.cantidad
                                                    coston = coston * det.costo_producto
                                                    if (impu.porcentaje_valor === "%") {
                                                        imp += (parseFloat(coston) * parseFloat(impu.valor)) / 100
                                                    }
                                                })
                                                det.impuestos.map((impu) => {
                                                    var coston = 0
                                                    coston = det.unidades_contenido_desc.valor * det.cantidad
                                                    coston = coston * det.costo_producto
                                                    if (impu.porcentaje_valor === "$") {
                                                        imp += ((parseFloat(det.cantidad) * parseFloat(det.unidades_contenido_desc.valor)) * parseFloat(impu.valor))
                                                    }
                                                })
                                            }
                                        })
                                    }
                                    movimientosTotal.push({
                                        "1": mov.movimiento.consecutivo,
                                        "2": mov.movimiento.numero_factura,
                                        "3": moment(mov.movimiento.fecha).format("DD/MM/YYYY hh:mm a"),
                                        "4": mov.empresa.razon_social,
                                        "6": mov.movimiento.tercero_id_detalles.nombres + " " + mov.movimiento.tercero_id_detalles.apellidos,
                                        "7": '$' + this.setearMiles(mov.movimiento.costo_total - imp),
                                        "7.5": '$' + this.setearMiles(imp),
                                        "7.7": '$' + this.setearMiles(mov.movimiento.costo_total),
                                        "accion": [<button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesEntrada(mov)}><Ojoicon /></button>,
                                        <button className="btn btn-sm btn-danger" style={{ marginLeft: '5px' }} onClick={moment(mov.movimiento.fecha).format("YYYY-MM-DD hh:mm:ss") > moment(this.state.fechaActualizacion).format("YYYY-MM-DD hh:mm:ss") ? () => this.descargarPDF2(mov.movimiento.id) : () => this.imprimirEntradasAnt(mov, "Entrada No. " + mov.movimiento.consecutivo)}><img src={pdfIcon} /></button>],
                                    })
                                })
                                this.setState({ comprasbd: movimientosTotal })
                            }//

                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 3000);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        this.AccionLoader()
                        console.error(error);
                    })
            } else {
                if (usuario.tipo === "5") {
                    datos = {
                        "accion": "compras_productos",
                        "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD") + " 00:00:00",
                        "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD") + " 23:59:59",
                        "empresas_id": usuario.tipo === "3" ? hijos : usuario.empresas_id
                    }
                } else {
                    datos = {
                        "accion": "compras_productos",
                        "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD") + " 00:00:00",
                        "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD") + " 23:59:59",
                        //"empresas_id": this.state.empresasZSeleccionadas
                        "empresas_id": hijos
                    }
                }
                this.AccionLoader()
                Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes/', datos, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        var exc = [{
                            "1": "CODIGO",
                            "2": "ALIAS",
                            "3": "IDENTIFICACION PROVEEDOR",
                            "4": "PROVEEDOR",
                            "5": "COSTO NETO DE LAS COMPRAS",
                            "6": "IMPUESTOS",
                            "7": "COSTO TOTAL DE LAS COMPRAS"
                        }]
                        var excDetalladas = [{
                            "1": "CODIGO",
                            "2": "ALIAS",
                            "3": "IDENTIFICACION PROVEEDOR",
                            "4": "PROVEEDOR",
                            "5": "PLU",
                            "6": "PRODUCTO",
                            "7": "CANTIDAD",
                            "8": "COSTO UNIDAD",
                            "9": "COSTO NETO TOTAL",
                            "10": "IIMPUESTOS TOTALES",
                            "11": "COSTO TOTAL DE LAS COMPRAS"
                        }]
                        this.AccionLoader()
                        if (responsee.status == 200) {
                            var reportes = responsee.data.data[0]
                            console.log(reportes)
                            if (reportes.compras_x_empresas != null) {
                                reportes.compras_x_empresas.map((emp) => {
                                    if (emp.compras_x_proveedor != null) {
                                        emp.compras_x_proveedor.map((prov) => {
                                            exc.push({
                                                "1": emp.codigo,
                                                "2": emp.alias,
                                                "3": prov.identificacion,
                                                "4": prov.nombres,
                                                "5": '$' + this.setearMiles(prov.costo_proveedor),
                                                "6": '$' + this.setearMiles(prov.impuestos_proveedor),
                                                "7": '$' + this.setearMiles(prov.costo_total_proveedor),
                                            })
                                            if (prov.compras_x_productos != null) {
                                                prov.compras_x_productos.map((prod) => {
                                                    excDetalladas.push({
                                                        "1": emp.codigo,
                                                        "2": emp.alias,
                                                        "3": prov.identificacion,
                                                        "4": prov.nombres,
                                                        "5": prod.plu,
                                                        "6": prod.descripcion,
                                                        "7": prod.cantidad,
                                                        "8": '$' + this.setearMiles(prod.costo_unidad),
                                                        "9": '$' + this.setearMiles(prod.costo_productos),
                                                        "10": '$' + this.setearMiles(prod.impuestos_productos),
                                                        "11": '$' + this.setearMiles(prod.costo_total_productos)
                                                    })
                                                })

                                            }
                                        })
                                    }
                                })
                            }
                            this.setState({
                                comprasbd: reportes,
                                excel2020Compras: exc,
                                excel2020ComprasDetalladas: excDetalladas,
                            })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                        this.AccionLoader()
                        swal("NO HAY DATOS EN EL MOMENTO", "", "warning")
                    })
            }

        }
    }

    consultaVentasPlu = () => {
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        var datos = {}
        if (usuario.tipo === "5") {
            datos = {
                "accion": "ventas_x_dia",
                "tipo_reporte": -1,
                "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD  HH:mm:ss"),
                "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD  HH:mm:ss"),
                "empresas_id": usuario.tipo == "3" ? hijos : usuario.empresas_id
            }
        } else {
            datos = {
                "accion": "ventas_x_dia",
                "tipo_reporte": -1,
                "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD  HH:mm:ss"),
                "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD  HH:mm:ss"),
                "empresas_id": this.state.empresasZSeleccionadas
            }
        }
        this.AccionLoader()
        Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes/', datos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                this.AccionLoader()
                if (responsee.status == 200) {
                    var reportes = responsee.data.data
                    this.setState({ reporteZ: reportes })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
                this.AccionLoader()
                swal("NO HAY DATOS EN EL MOMENTO", "", "warning")
            })
    }

    consultaUtilidad = (tipo) => {
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        var datos = {}
        switch (this.state.valueUtilidad) {
            case 0:
                if (usuario.tipo === "5") {
                    datos = {
                        "accion": "utilidad_ventas_x_dia",
                        "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD") + " 00:00:00",
                        "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD") + " 23:59:59",
                        "empresas_id": usuario.empresas_id
                    }
                } else {
                    datos = {
                        "accion": "utilidad_ventas_x_dia",
                        "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD") + " 00:00:00",
                        "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD") + " 23:59:59",
                        "empresas_id": this.state.empresasStrings === "" ? hijos : this.state.empresasStrings
                    }
                }
                this.AccionLoader()
                Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes/', datos, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        if (responsee.status == 200) {
                            var utilidad = responsee.data.data
                            if (isNull(utilidad)) {
                                swal("NO HAY DATOS SUFICIENTES", "", "warning")
                            } else {
                                if (isNull(utilidad[0].utilida_x_empresa)) {
                                    swal("NO HAY DATOS SUFICIENTES", "", "warning")
                                    this.setState({ mostrarExcelUtilidad: false })
                                } else {
                                    this.setState({ mostrarExcelUtilidad: true })
                                }
                            }
                            this.AccionLoader()
                            this.setState({ utilidadbd: utilidad })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        } else if (responsee.status == 500) {
                            swal("NO HAY DATOS EN EL MOMENTO", "", "warning")
                            this.AccionLoader()
                        }
                    })
                    .catch(function (error) {
                        swal("NO HAY DATOS EN EL MOMENTO", "", "warning")
                        //this.AccionLoader()
                    })
                break
            case 1:
                if (usuario.tipo === "5") {

                    datos = {
                        "accion": "ventas_x_utilidad",
                        "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD") + " 00:00:00",
                        "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD") + " 23:59:59",
                        "empresas_id": usuario.empresas_id,
                        "grupos_id": this.state.categoriasUtilidad,
                        "tipo_reporte": tipo
                    }
                } else {
                    datos = {
                        "accion": "ventas_x_utilidad",
                        "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD") + " 00:00:00",
                        "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD") + " 23:59:59",
                        "empresas_id": this.state.empresasStrings === "" ? hijos : this.state.empresasStrings,
                        "grupos_id": this.state.categoriasUtilidad,
                        "tipo_reporte": tipo
                    }
                }
                this.AccionLoader()
                Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes/', datos, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        if (responsee.status == 200) {
                            var utilidad = responsee.data.data
                            if (isNull(utilidad)) {
                                swal("NO HAY DATOS SUFICIENTES", "", "warning")
                            } else {
                                if (isNull(utilidad[0].utilida_x_empresa)) {
                                    swal("NO HAY DATOS SUFICIENTES", "", "warning")
                                }
                            }
                            this.AccionLoader()
                            this.setState({ utilidadbd: utilidad })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        } else if (responsee.status == 500) {
                            swal("NO HAY DATOS EN EL MOMENTO", "", "warning")
                            this.AccionLoader()
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        // console.error(error);
                        swal("NO HAY DATOS EN EL MOMENTO", "", "warning")
                        //this.AccionLoader()
                    })
                break
            case 2:
                if (usuario.tipo === "5") {

                    datos = {
                        "accion": "utilidad_cat_producto",
                        "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD") + " 00:00:00",
                        "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD") + " 23:59:59",
                        "empresas_id": usuario.empresas_id,
                        "grupos_id": this.state.multiCategoriasSeleccionadas.value,
                        "productos_id": this.state.productosUtilidad

                    }
                } else {
                    datos = {
                        "accion": "utilidad_cat_producto",
                        "fecha_inicial": moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD") + " 00:00:00",
                        "fecha_final": moment(new Date(this.state.selectedDateFinz.toString())).format("YYYY-MM-DD") + " 23:59:59",
                        "empresas_id": hijos,
                        "grupos_id": this.state.multiCategoriasSeleccionadas.value,
                        "productos_id": this.state.productosUtilidad

                    }
                }
                this.AccionLoader()
                Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes/', datos, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        if (responsee.status == 200) {
                            var utilidad = responsee.data.data
                            if (isNull(utilidad)) {
                                swal("NO HAY DATOS SUFICIENTES", "", "warning")
                            } else {
                                if (isNull(utilidad[0].utilidad_x_plu)) {
                                    swal("NO HAY DATOS SUFICIENTES", "", "warning")
                                }
                            }
                            this.AccionLoader()
                            this.setState({ utilidadbd: utilidad })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        } else if (responsee.status == 500) {
                            swal("NO HAY DATOS EN EL MOMENTO", "", "warning")
                            this.AccionLoader()
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        // console.error(error);
                        swal("NO HAY DATOS EN EL MOMENTO", "", "warning")
                        //this.AccionLoader()
                    })
                break
        }

    }

    consultaFinanciero = () => {

        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        var datos = {
            "accion": "ventas_x_dia",
            "tipo_reporte": -1,
            "fecha_inicial": moment(new Date(this.state.selectedDateFinanciero.toString())).format("YYYY-MM-DD") + " 00:00:00",
            "fecha_final": moment(new Date(this.state.selectedDateFinanciero.toString())).format("YYYY-MM-DD") + " 23:59:59",
            "empresas_id": usuario.empresas_id,
        }
        Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes/', datos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status == 200) {
                    var reportes = responsee.data.data
                    this.setState({ reporteFinananciero: reportes })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
                swal("NO HAY DATOS EN EL MOMENTO", "", "warning")
            })


    }


    consultaCierresUsuario = () => {
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        var datos = {
            "accion": "cierre",
            "tipo_reporte": 0,
            "fecha_inicial": null,
            "fecha_final": null,
            "empresas_id": usuario.tipo == "3" ? this.state.empresaSeleccionadaCierre.value : usuario.empresas_id,
            "personas_id": this.state.empleadoCierreSeleccionado.value
        }
        Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes/', datos, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status == 200) {
                    this.setState({ cierresbd: responsee.data.data })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }

    obtenerKardex = () => {
        this.setState({ ventanaKArdex: true })
        switch (this.state.valueKardex) {
            case 1:
                Axios.get(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/movimiento/' + this.state.ProductoKardexSeleccionado.value + '/kardex/' + this.state.bodegaKardexSeleccionada.value, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        var movimientos = []
                        var excelKardex1 = []
                        if (responsee.status === 200) {
                            var kardex = responsee.data.rows[0]
                            if (kardex.producto.movimientos != null) {
                                kardex.producto.movimientos.map((producto, key) => {
                                    movimientos.push(producto)
                                    excelKardex1.push({
                                        'movimineto': producto.consecutivo,
                                        'fecha': moment(producto.fecha).format("YYYY-MM-DD hh:mm a"),
                                        'operacion': producto.operacion,
                                        bodega: this.state.bodegaKardexSeleccionada.label,
                                        producto: this.state.ProductoKardexSeleccionado.label,
                                        'tipo': producto.tipo_operacion,
                                        'cantidad': producto.cantidad,
                                        'unidad': producto.unidad_basica_desc,
                                        'costo': '$' + this.setearMiles(parseInt(producto.costo_producto)),
                                        'costoMov': '$' + this.setearMiles(parseInt(producto.costo_movimiento))
                                    })
                                })

                            }
                            this.setState({ kRDEXpRODUCTOS: movimientos, excelKardexBodega: excelKardex1, COSTObODEGAkaRDEX: kardex.producto.bodegas_productos.costo_bodega, COSTOkaRDEX: kardex.producto.bodegas_productos.costo, CANTIDADkaRDEX: kardex.producto.bodegas_productos.cantidad })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }

                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break;
            case 2:
                //console.log(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/movimiento/' + this.state.ProductoKardexSeleccionado.value + '/kardex/' + this.state.bodegasporEmpresasKArdex)
                Axios.get(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/movimiento/' + this.state.ProductoKardexSeleccionadoTiendas.value + '/kardex/' + this.state.bodegasporEmpresasKArdex, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        var movimientos = []
                        var excelKardex2 = []
                        var cantidadEnbodega = 0
                        var cosotProducto = ''
                        var cosotProductoBodega = 0
                        var kardex = responsee.data.rows
                        if (kardex != null) {
                            kardex.map((kardex, key) => {
                                cantidadEnbodega = cantidadEnbodega + parseFloat(kardex.producto.bodegas_productos.cantidad)
                                cosotProducto = kardex.producto.bodegas_productos.costo
                                cosotProductoBodega = cosotProductoBodega + parseFloat(kardex.producto.bodegas_productos.costo_bodega)
                                kardex.producto.movimientos.map((movimiento, key2) => {
                                    movimientos.push({
                                        bodega: kardex.producto.bodegas_productos.bodega,
                                        movimiento: movimiento.consecutivo,
                                        'fecha': moment(movimiento.fecha).format("YYYY-MM-DD hh:mm a"),
                                        bodega: this.state.bodegaKardexSeleccionada.label,
                                        producto: this.state.ProductoKardexSeleccionado.label,
                                        operacion: movimiento.operacion,
                                        tipo: movimiento.tipo_operacion,
                                        cantidad: movimiento.cantidad,
                                        unidad: movimiento.unidad_basica_desc,
                                        costo: '$' + this.setearMiles(movimiento.costo_producto),
                                        costoMovimienti: '$' + this.setearMiles(movimiento.costo_movimiento)
                                    })
                                    excelKardex2.push({
                                        bodega: kardex.producto.bodegas_productos.bodega,
                                        movimiento: movimiento.consecutivo,
                                        fecha: movimiento.fecha,
                                        bodega: this.state.bodegaKardexSeleccionada.label,
                                        producto: this.state.ProductoKardexSeleccionado.label,
                                        operacion: movimiento.operacion,
                                        tipo: movimiento.tipo_operacion,
                                        cantidad: movimiento.cantidad,
                                        unidad: movimiento.unidad_basica_desc,
                                        costo: '$' + this.setearMiles(parseInt(movimiento.costo_producto)),
                                        costoMovimienti: '$' + this.setearMiles(parseInt(movimiento.costo_movimiento))
                                    })
                                })
                            })
                        }

                        this.setState({ kRDEXpRODUCTOS: movimientos, COSTObODEGAkaRDEX: this.setearMiles(cosotProductoBodega), COSTOkaRDEX: this.setearMiles(cosotProducto), CANTIDADkaRDEX: cantidadEnbodega })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break;
        }

    }

    obtenerPrimerYUltimoDiaMes = (fecha) => {
        const startOfMonth = moment(fecha).startOf('month').format('YYYY-MM-DD');
        const endOfMonth = moment(fecha).endOf('month').format('YYYY-MM-DD');
        return [startOfMonth, endOfMonth]
    }

    consultaVentasFecha = () => {
        const { usuario, empresasHijos } = store.getState()
        const { multiEmpresasVentas, selectedDateVenta, cambbioInicioVenta, cambbioFinVenta, tipoSeleccionado, selectedDateFinventa, valueVentas } = this.state
        const data = {
            "accion": "ventas",
            "empresas_id": usuario.tipo === "5" ? usuario.empresas_id : multiEmpresasVentas === "" ? empresasHijos : multiEmpresasVentas,
            "fecha_inicial": cambbioInicioVenta ? moment(new Date(selectedDateVenta.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD ") + "00:00:00",
            "fecha_final": cambbioFinVenta ? moment(selectedDateFinventa.toString()).format("YYYY-MM-DD  HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD ") + "24:00:00",
            "tipoFacturacion": tipoSeleccionado === null || JSON.stringify(tipoSeleccionado) === "{}" ? 0 : tipoSeleccionado.value
        }
        const [startOfMonth, endOfMonth] = this.obtenerPrimerYUltimoDiaMes(moment(selectedDateVenta.toString()).format("YYYY-MM-DD HH:mm:ss"))
        const dataCierre = {
            "accion": "cierre_mensual",
            "empresas_id": usuario.tipo === "5" ? usuario.empresas_id : multiEmpresasVentas === "" ? empresasHijos : multiEmpresasVentas,
            "fecha_inicial": startOfMonth,
            "fecha_final": endOfMonth,
        }
        this.AccionLoader()
        Axios.post(Server.hostPrueba + Server.puertoEmpresas + '/api/reportes', (valueVentas === 4 ? dataCierre : data), { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status === 200) {
                    try {
                        if (valueVentas === 4) {
                            const datos = responsee.data.data
                            if (datos === null) {
                                swal("No hay datos disponibles", "", "warning")
                            } else {
                                this.setState({
                                    ventasCierreMensual: datos.map((data) => ({
                                        kiosco: data.alias,
                                        razon_social: data.razon_social,
                                        codigo: data.codigo,
                                        nit: data.nit,
                                        cantidad_ventas: parseInt(data.cantidad_ventas),
                                        venta_total: '$ ' + this.setearMiles(data.venta_total),
                                        base_grabavle: '$ ' + this.setearMiles(data.base_gravable_total),
                                        impoconsumo: '$ ' + this.setearMiles(data.impoconsumo),
                                        iva: '$ ' + this.setearMiles(data.iva),
                                    }))
                                })
                            }
                        } else {
                            const datos = responsee.data.data[0].ventas_empresas
                            if (datos === null) {
                                swal("No hay datos disponibles", "", "warning")
                            } else {
                                this.setState({
                                    ventasXFecha: datos,
                                    buscoVenta: true
                                })
                            }
                        }
                    } catch (error) {
                        swal("No hay datos disponibles", "", "warning")
                    }

                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 2000);
                }
                this.AccionLoader()
            })
            .catch((err) => {
                console.log(err)
                this.AccionLoader()
            })


    }

    consultaBonosImpresos = () => {
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        this.AccionLoader()
        if (this.state.valueVentas === 3) {
            switch (usuario.tipo) {
                case "1":
                case "2":
                case "3":
                case "4":
                    var data = {
                        "empresas_id": this.state.multiEmpresasVentas === "" ? hijos : this.state.multiEmpresasVentas,
                        "accion": "reporte_vauchers",
                        "fecha_inicial": this.state.cambbioInicioVenta ? moment(new Date(this.state.selectedDateVenta.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD ") + "00:00:00",
                        "fecha_final": this.state.cambbioFinVenta ? moment(this.state.selectedDateFinventa.toString()).format("YYYY-MM-DD  HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD ") + "24:00:00",
                        "tipo_reporte": 2
                    }
                    Axios.post(Server.hostPrueba + Server.puertoEmpresas + '/api/reportes', data, { headers: { Authorization: ntoken } })
                        .then(responsee => {
                            var datos = []
                            if (responsee.status === 200) {
                                if (responsee.data.data != null) {
                                    datos = responsee.data.data

                                }
                                if (isNull(datos) || datos.length === 0) {
                                    swal("No hay datos disponibles", "", "warning")
                                } else {
                                    this.setState({
                                        bonosXempresas: datos
                                    })
                                }
                            } else if (responsee.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 2000);
                            }

                            this.AccionLoader()
                        })
                        .catch((err) => {
                            console.log(err)
                            this.AccionLoader()
                        })


                    break
                case "5":
                    var data = {
                        "empresas_id": usuario.empresa_id,
                        "accion": "reporte_vauchers",
                        "fecha_inicial": this.state.cambbioInicioVenta ? moment(new Date(this.state.selectedDateVenta.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD ") + "00:00:00",
                        "fecha_final": this.state.cambbioFinVenta ? moment(this.state.selectedDateFinventa.toString()).format("YYYY-MM-DD  HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD ") + "24:00:00",
                        "tipo_reporte": 2
                    }
                    Axios.post(Server.hostPrueba + Server.puertoEmpresas + '/api/reportes', data, { headers: { Authorization: ntoken } })
                        .then(responsee => {
                            var datos = []
                            if (responsee.status === 200) {
                                if (responsee.data.data != null) {
                                    datos = responsee.data.data
                                }
                                if (isNull(datos)) {
                                    swal("No hay datos disponibles", "", "warning")
                                }
                            } else if (responsee.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 2000);
                            }
                            this.setState({
                                bonosXempresas: datos
                            })
                            this.AccionLoader()
                        })
                        .catch((err) => {
                            console.log(err)
                            this.AccionLoader()
                        })
                    break
            }
        } else {
            switch (usuario.tipo) {
                case "1":
                case "2":
                case "3":
                case "4":
                    var data = {
                        "empresas_id": this.state.multiEmpresasVentas === "" ? hijos : this.state.multiEmpresasVentas,
                        "accion": "reporte_vauchers",
                        "fecha_inicial": this.state.cambbioInicioVenta ? moment(new Date(this.state.selectedDateVenta.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD ") + "00:00:00",
                        "fecha_final": this.state.cambbioFinVenta ? moment(this.state.selectedDateFinventa.toString()).format("YYYY-MM-DD  HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD ") + "24:00:00",
                        "tipo_reporte": 1
                    }
                    Axios.post(Server.hostPrueba + Server.puertoEmpresas + '/api/reportes', data, { headers: { Authorization: ntoken } })
                        .then(responsee => {
                            var datos = []
                            if (responsee.status === 200) {
                                if (responsee.data.data[0] != null) {
                                    datos = responsee.data.data[0]

                                }
                                if (isNull(datos) || datos.length === 0) {
                                    swal("No hay datos disponibles", "", "warning")
                                } else {
                                    this.setState({
                                        bonosXpromotor: datos
                                    })
                                }
                            } else if (responsee.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 2000);
                            }

                            this.AccionLoader()
                        })
                        .catch((err) => {
                            console.log(err)
                            this.AccionLoader()
                        })


                    break
                case "5":
                    var data = {
                        "empresas_id": usuario.empresa_id,
                        "accion": "reporte_vauchers",
                        "fecha_inicial": this.state.cambbioInicioVenta ? moment(new Date(this.state.selectedDateVenta.toString())).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD ") + "00:00:00",
                        "fecha_final": this.state.cambbioFinVenta ? moment(this.state.selectedDateFinventa.toString()).format("YYYY-MM-DD  HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD ") + "24:00:00",
                        "tipo_reporte": 1
                    }
                    Axios.post(Server.hostPrueba + Server.puertoEmpresas + '/api/reportes', data, { headers: { Authorization: ntoken } })
                        .then(responsee => {
                            var datos = []
                            if (responsee.status === 200) {
                                if (responsee.data.data[0] != null) {
                                    datos = responsee.data.data[0]
                                }
                                if (isNull(datos)) {
                                    swal("No hay datos disponibles", "", "warning")
                                }
                            } else if (responsee.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 2000);
                            }
                            this.setState({
                                bonosXpromotor: datos
                            })
                            this.AccionLoader()
                        })
                        .catch((err) => {
                            console.log(err)
                            this.AccionLoader()
                        })
                    break
            }
        }

    }

    detallesCierre = (id) => {
        //Cierres
        Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes/cierre/detalles/' + id, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status == 200) {
                    if (responsee.data.data != null) {
                        var jornada = responsee.data.data[0]
                        this.detallesCierrrre(jornada)
                    }

                }
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
        this.setState({ vistaDetallesCierre: true })
    }

    anularVentas = (venta, empresa_id) => {
        var usuario = store.getState().usuario
        var id = venta.movimiento_id
        swal({
            title: "Estás seguro de realizar la nota crédito a la venta " + venta.consecutivo + " con valor de $" + this.setearMiles(venta.venta_total) + "?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    var data = {
                        "accion": "getventa",
                        "empresas_id": empresa_id,
                        "movimientos_id": id
                    }
                    Axios.post(Server.hostPrueba + Server.puertoEmpresas + '/api/reportes', data, { headers: { Authorization: ntoken } })
                        .then(responsee => {
                            console.log(responsee)
                            var venta = []
                            if (responsee.status === 200) {
                                venta = responsee.data.data[0]
                                if (venta != null) {
                                    venta.venta.fecha = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                                    venta.venta.create_user = usuario.p_id
                                    venta.venta.operacion = 12
                                    venta.venta.bodegas_id = venta.detalle[0].bodegas_id
                                    console.log(venta)
                                    Axios.post(Server.hostPrueba + Server.puertoMovimiento + '/api/ventas', venta, { headers: { Authorization: ntoken } })
                                        .then(responsee => {
                                            console.log(responsee)
                                            if (responsee.status === 201) {
                                                if (responsee.data.success) {
                                                    swal("OK", responsee.data.message, "success")
                                                    this.consultaVentasFecha()
                                                }
                                            } else if (responsee.status == 205) {
                                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                                setTimeout(function () { window.location.href = '/'; }, 2000);
                                            }
                                        })
                                        .catch((err) => {
                                            console.log(err)
                                        })
                                }
                            } else if (responsee.status == 205) {
                                swal("ERROR", "LA SESION HA CADUCADO", "warning")
                                setTimeout(function () { window.location.href = '/'; }, 2000);
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })

                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }

    detallesVentaZ = (venta) => {
        //console.log(venta)
        this.setState({ ventaZSeleccionada: venta, dialogdetallesZ: true })
    }

    cerrarDetallesVentaZ = () => {
        this.setState({ dialogdetallesZ: false })
    }

    detallesCompras = (venta) => {
        console.log(venta)
        this.setState({ CompraSeleccionada: venta, dialogdetallesCompra: true })
    }

    cerrarDetallesCompra = () => {
        this.setState({ dialogdetallesCompra: false })
    }


    //Funcion para cambiar Tabs en general
    cambiarTabs = (tab, valor) => {
        this.setState({
            [tab]: valor
        })
        if (tab === "value") {
            this.setState({
                valueKardex: 1,
                //Kardex
                bodegaKardexSeleccionada: [],
                ProductoKardexSeleccionado: [],
                ProductosDeBodegabds: [],
                empresaSeleccionadaKardex: {},
                ProductoKardexSeleccionadoTiendas: {},
                ProductosDeBodegabd: [],
                ventanaKArdex: false,
                kRDEXpRODUCTOS: [],
                COSTOkaRDEX: '',
                COSTObODEGAkaRDEX: '',
                CANTIDADkaRDEX: '',
                bodegasporEmpresasKArdex: [],
                valueUtilidad: 0,
                //Cierres
                valueCierres: 1,
                selectedDate: new Date(),
                selectedDateFin: new Date(),
                cambbioInicio: false,
                cambbioFin: false,
                cambbioInicioVenta: false,
                cambbioFinVenta: false,
                empresaSeleccionadaCierre: {},
                empleadoCierresbd: [],
                empleadoCierreSeleccionado: {},
                vistaDetallesCierre: false,
                detallesCierre: [],
                detallesCierreVentas: [],
                valueVentas: 3,
                valueZ: 0,
                selectedDateVenta: moment(new Date()).format("YYYY-MM-DD ") + "00:00:00",
                selectedDateFinventa: moment(new Date()).format("YYYY-MM-DD ") + "23:59:59",
                selectedDatez: new Date(),
                selectedDateFinz: new Date(),
                selectedDateFinanciero: new Date(),
                cambioFinanciero: false,
                ventasXFecha: null,
                reporteZ: [],
                reporteFinananciero: [],
                multiEmpresasZ: [],
                empresasZSeleccionadas: [],
                ventaZSeleccionada: [],
                dialogdetallesZ: false,
                multiCategoriasSeleccionadas: [],
                productosxutilidadSeleccionados: [],
                productosxutilidadbd: [],
                productosUtilidad: "",
                categoriasUtilidad: '',
                utilidadbd: [],
                valueUtilidadEmpresa: 0,
                valueUtilidadCategoria: 0,
                valueFacturacionEmpresa: 0,
                bodegasVInventariobd: [],
                arqueos: [],
                //Exceles
                datosExcel: [],
                datosExcelVentaDetalles: [],
                datosExcelFecha: [],
                datosExcelFechaDetalles: [],
                excelKardexBodega: [],
                excelKardexTienda: [],
                excelCierreFecha: [],
                excelCierrePromotor: [],
                excelZFinanciera: [],
                excelUtilidad: [],
                multiEmpresasVentas: "",
                multiEmpresasVentasSeleccionadas: [],
                valueEmpresasFecha: 0,
                buscoVenta: false,
                LibroFacturacion: [],
                bodegasStrings: "",
                productosStrings: "",
                empresasStrings: "",
                valorInventario: null,
                valueInventarioEmpresa: 0,
                comprasbd: null
            })
        } else if (tab === "valueCierres") {
            this.setState({
                cierresbd: []
            })
        } else if (tab === "valueUtilidad") {
            this.setState({
                utilidadbd: []
            })
        }
    }

    AccionLoader = () => {
        this.setState({
            vistaCargando: !this.state.vistaCargando
        })
    }

    //Funcion para setear el precio
    setearMiles = (numero) => {
        var usuario = store.getState().usuario
        var moneda = usuario.empresas.paises_moneda
        switch (moneda) {
            case "COP":
                return (
                    (new Intl.NumberFormat("de-DE").format(parseFloat(numero).toFixed(2)))
                )
                break;
            case "USD":
                return (new Intl.NumberFormat("en-IN").format(parseFloat(numero).toFixed(3)))
                break;

        }
    }

    consultaLibroFacturacion = () => {
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        this.AccionLoader()
        switch (usuario.tipo) {
            case "1":
            case "2":
            case "3":
            case "4":
                var data = {
                    "accion": "libro_facturacion",
                    "empresas_id": this.state.multiEmpresasVentas === "" ? hijos : this.state.multiEmpresasVentas,
                    "fecha_inicial": this.state.cambbioInicioVenta ? moment(new Date(this.state.selectedDateVenta.toString())).format("YYYY-MM-DD ") + "00:00:00" : moment(new Date()).format("YYYY-MM-DD ") + "00:00:00",
                    "fecha_final": this.state.cambbioFinVenta ? moment(this.state.selectedDateVenta.toString()).format("YYYY-MM-DD  ") + "24:00:00" : moment(this.state.selectedDateVenta.toString()).format("YYYY-MM-DD ") + "24:00:00"
                }
                Axios.post(Server.hostPrueba + Server.puertoEmpresas + '/api/reportes', data, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        var datos = []
                        if (responsee.status === 200) {
                            datos = responsee.data.data[0].ventas_total
                            if (datos === null) {
                                swal("NO HAY DATOS DISPONIBLES", "", "warning")
                            }
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                        this.setState({
                            LibroFacturacion: datos
                        })
                        this.AccionLoader()
                    })
                    .catch((err) => {
                        console.log(err)
                        this.AccionLoader()
                    })


                break
            case "5":
                var data = {
                    "accion": "libro_facturacion",
                    "empresas_id": usuario.empresas_id,
                    "fecha_inicial": this.state.cambbioInicioVenta ? moment(new Date(this.state.selectedDateVenta.toString())).format("YYYY-MM-DD ") + "00:00:00" : moment(new Date()).format("YYYY-MM-DD ") + "00:00:00",
                    "fecha_final": this.state.cambbioFinVenta ? moment(this.state.selectedDateVenta.toString()).format("YYYY-MM-DD  ") + "24:00:00" : moment(this.state.selectedDateVenta.toString()).format("YYYY-MM-DD ") + "24:00:00"
                }
                Axios.post(Server.hostPrueba + Server.puertoEmpresas + '/api/reportes', data, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee)
                        var datos = []
                        if (responsee.status === 200) {
                            datos = responsee.data.data[0].ventas_total
                            if (datos === null) {
                                swal("NO HAY DATOS DISPONIBLES", "", "warning")
                            }
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                        this.setState({
                            LibroFacturacion: datos
                        })
                        this.AccionLoader()
                    })
                    .catch((err) => {
                        console.log(err)
                        this.AccionLoader()
                    })
                break
        }
    }

    detallesEntrada = (entrada) => {
        ////console.log(entrada)
        this.setState({
            entradaSeleccionada: entrada,
            modoDetalles: true
        })
    }

    cerrarEntrada = () => {
        this.setState({
            entradaSeleccionada: null,
            modoDetalles: false
        })
    }

    descargarPDF2 = (movimiento) => {
        this.setState({
            htmlDescarga: true
        })
        var estilos = `<style>
        .numero{
        font-family: 'Roboto', sans-serif;
        font-size:18px;}
        .numero2{
        font-family: 'Roboto', sans-serif !IMPORTANT;
        font-size:10px;}

      

        .tb-terpel td{
        font-family: 'Raleway', sans-serif;
        font-size:11px;
        border: 0;
        padding: 0;
        }
        </style>`
        Axios.get(Server.hostPruebasinssl + Server.puertoMovimiento + '/api/movimiento/' + movimiento, { headers: { Authorization: ntoken } })
            .then(responsee => {
                if (responsee.status === 201) {
                    var n = responsee.data.observacion
                    document.getElementById('descarga').innerHTML = n;
                    const nd = document.getElementById('descarga');
                    var download = {
                        "template": estilos + nd.innerHTML,
                        "filename": "ENTRADA.pdf"
                    }
                    //console.log(download)
                    Axios.post(Server.HostPDF + Server.puertoPdf + '/api/pdf/download', download)
                        .then(response => {
                            //console.log(response)
                            var url = response.data.url
                            var link = document.createElement('a');
                            link.setAttribute("target", "_blank")
                            link.download = response.data.filename;
                            link.href = url;
                            link.click();
                            this.setState({
                                htmlDescarga: false
                            })
                        })
                } else if (responsee.status == 205) {
                    swal("ERROR", "LA SESION HA CADUCADO", "warning")
                    setTimeout(function () { window.location.href = '/'; }, 3000);
                }
            })
            .catch(function (error) {
                console.error(error);
            })

    }


    imprimirLibroFacturacion = (nombre) => {
        let nda = document.getElementById('capture_cofacturacion');
        var estilos = `<style>
                        .numero{
                        font-family: 'Roboto', sans-serif;
                        font-size:16px;}
                        .numero2{
                        font-family: 'Roboto', sans-serif !IMPORTANT;
                        font-size:10px;}
        
                        td {
                            font-family: 'Raleway', sans-serif;
                            font-size:9px;
                            }

                        fontipo{
                            color:#C60000;
                            font-family: 'Raleway', sans-serif;
                            font-size:11px;
                            }

                        .tb-terpel td{
                        font-family: 'Raleway', sans-serif;
                        font-size:11px;
                        border: 0;
                        padding: 0;
                        }
                        </style>`
        console.log(nda)
        var download = {
            "template": estilos + nda.innerHTML,
            "filename": nombre + ".pdf"
        }
        Axios.post(Server.HostPDF + Server.puertoPdf + '/api/pdf/download', download)
            .then(response => {
                //console.log(response)
                var url = response.data.url
                var link = document.createElement('a');
                link.setAttribute("target", "_blank")
                link.download = response.data.filename;
                link.href = url;
                link.click();
                this.setState({
                    imprimirPdf: false,
                    entradaSeleccionada: null,
                })
            })


    }


    imprimirEntradasAnt = (entrada, nombre) => {
        this.setState({
            imprimirPdf: true,
            entradaSeleccionada: entrada,
        })
        let t = this
        setTimeout(function () { t.imprimirEntradas(nombre) }, 200)
    }

    consultaCostoInventario = () => {
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        this.AccionLoader()
        switch (usuario.tipo) {
            case "1":
            case "2":
            case "3":
            case "4":
                var data = {
                    "accion": "costo_inventario",
                    "empresas_id": this.state.empresasStrings,
                    "productos_id": this.state.productosStrings,
                    "bodegas_id": this.state.bodegasStrings
                }
                console.log(data)
                Axios.post(Server.hostPrueba + Server.puertoEmpresas + '/api/reportes', data, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee)
                        var datos = []
                        if (responsee.status === 200) {
                            datos = responsee.data.data[0].costo_y_venta_empresa
                            if (datos === null) {
                                swal("NO HAY DATOS DISPONIBLES", "", "warning")
                            }
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                        this.setState({
                            valorInventario: datos
                        })
                        this.AccionLoader()
                    })
                    .catch((err) => {
                        console.log(err)
                        this.AccionLoader()
                    })


                break
            case "5":
                var data = {
                    "accion": "costo_inventario",
                    "empresas_id": usuario.empresas_id,
                    "productos_id": this.state.productosStrings,
                    "bodegas_id": this.state.bodegasStrings
                }
                Axios.post(Server.hostPrueba + Server.puertoEmpresas + '/api/reportes', data, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee)
                        var datos = []
                        if (responsee.status === 200) {
                            datos = responsee.data.data[0].costo_y_venta_empresa
                            if (datos === null) {
                                swal("NO HAY DATOS DISPONIBLES", "", "warning")
                            }
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                        this.setState({
                            valorInventario: datos
                        })
                        this.AccionLoader()
                    })
                    .catch((err) => {
                        console.log(err)
                        this.AccionLoader()
                    })
                break
        }
    }

    mostrarReportesVoucher = (tab) => {
        var usuario = store.getState().usuario
        switch (tab) {
            case 0:
                var ColumnasEmpresas = [
                    {
                        label: 'Con.',
                        field: 'consecutivo',
                        sort: 'desc',
                        width: 150
                    }, {
                        label: 'Bono',
                        field: 'bono',
                        width: 150
                    }, {
                        label: 'Fecha',
                        field: 'cantidad',
                        width: 150
                    }, {
                        label: 'Promotor',
                        field: 'PROMOTOR',
                        width: 150
                    }, {
                        label: 'Plu',
                        field: 'plu',
                        width: 150
                    }, {
                        label: 'Descripcion',
                        field: 'descr',
                        width: 150
                    }, {
                        label: 'Total',
                        field: 'venta',
                        width: 150
                    },
                    {
                        label: 'Ver',
                        field: 'acciones',
                        width: 150
                    }
                ]
                var ColumnasventasFechaTotales = [
                    {
                        label: 'Con.',
                        field: 'consecutivo',
                        sort: 'desc',
                        width: 150
                    }, {
                        label: 'Fecha',
                        field: 'fecha',
                        width: 150
                    }, {
                        label: 'Promotor',
                        field: 'promotor',
                        width: 150
                    }/*, {
                            label: 'Sub. Venta',
                            field: 'subventa',
                            width: 150
                        }*/, {
                        label: 'Imp. venta',
                        field: 'ipventa',
                        width: 150
                    }, {
                        label: 'T. Venta',
                        field: 'tventa',
                        width: 150
                    }, {
                        label: 'Acciones',
                        field: 'acciones',
                        width: 150
                    }
                ]
                var rowsVentasFechas = []
                var ventaFecha = this.state.bonosXempresas
                console.log(ventaFecha)
                var ExcelVentaFecha = []
                var ExcelTotal = []
                ExcelTotal.push({
                    "0": "CODIGO EDS",
                    "0_5": "ESTACION",
                    "1": "Consecutivo",
                    "1_1": "Bono",
                    "2": "Fecha",
                    "3": "Promotor",
                    "4": "Isla",
                    "5": "Sutidor",
                    "6": "Cara",
                    "7": "Manguera",
                    "8": "Plu",
                    "9": "Descripcion",
                    "10": "Precio",
                    "11": "Cantidad",
                    "12": "Total venta",
                })
                var totalVentas = []
                var totalImpuestos = []
                var ventaSellecionada = null
                if (this.state.movimientoSeleccionado != null) {
                    ventaSellecionada = this.state.movimientoSeleccionado
                }
                var excel = []
                return (
                    this.state.vistaCargando ?
                        <Loader />
                        :
                        <div>
                            <div className="row">
                                <Tabs
                                    value={this.state.valueVentas}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="disabled tabs example"
                                    style={{ margin: '0px 15px' }}
                                >
                                    <Tab label="POR EMPRESA" onClick={() => this.cambiarTabs("valueVentas", 3)} />
                                    {/*<Tab label="POR PROMOTOR" onClick={() => this.cambiarTabs("valueVentas", 1)} />*/}
                                </Tabs>
                            </div>
                            {this.state.valueVentas === 1 || this.state.valueVentas === 3 ?
                                usuario.tipo === "5"
                                    ?
                                    <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                        <div className="col-md-4">
                                            <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"} >
                                                <DateTimePicker
                                                    format="yyyy-MM-dd hh:mm a"
                                                    id="datetime"
                                                    ampm={false}
                                                    disableFuture={true}
                                                    value={this.state.selectedDateVenta}
                                                    onChange={this.handleChangemomentventa}
                                                    className="rounded form-control"
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                <DateTimePicker
                                                    format="yyyy-MM-dd hh:mm a"
                                                    id="datetime"
                                                    ampm={false}
                                                    value={this.state.selectedDateFinventa}
                                                    onChange={this.handleChangemomentFinVenta}
                                                    disableFuture={true}
                                                    className="rounded form-control"
                                                    minDate={moment(new Date(this.state.selectedDateVenta.toString())).format("YYYY-MM-DD HH:mm")}
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-4  bottom-aligned">
                                            <button className="btn btn-success btn-block" onClick={() => this.consultaBonosImpresos()}>CONSULTAR</button>
                                        </div>
                                    </div>
                                    :
                                    <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                        <div className="col-md-2">
                                            <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                <DateTimePicker
                                                    format="yyyy-MM-dd hh:mm a"
                                                    id="datetime"
                                                    value={this.state.selectedDateVenta}
                                                    onChange={this.handleChangemomentventa}
                                                    ampm={false}
                                                    disableFuture={true}
                                                    className="rounded form-control"
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                <DateTimePicker
                                                    format="yyyy-MM-dd hh:mm a"
                                                    id="datetime"
                                                    value={this.state.selectedDateFinventa}
                                                    onChange={this.handleChangemomentFinVenta}
                                                    disableFuture={true}
                                                    ampm={false}
                                                    className="rounded form-control"
                                                    minDate={moment(new Date(this.state.selectedDateVenta.toString())).format("YYYY-MM-DD HH:mm")}
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-5">
                                            <label htmlFor="numordencompra" className="col-form-label">EMPRESA</label>
                                            <Selects
                                                value={this.state.multiEmpresasVentasSeleccionadas}
                                                onChange={this.handleChanges('multiEmpresasVentasSeleccionadas')}
                                                options={this.state.empresasbd}
                                                isMulti
                                            />
                                        </div>
                                        <div className="col-md-3  bottom-aligned">
                                            <button className="btn btn-success btn-block" onClick={() => this.consultaBonosImpresos()}>CONSULTAR</button>
                                        </div>
                                    </div> : null}

                            {this.state.valueVentas === 1
                                ?
                                ventaFecha != null
                                    ?
                                    <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                        <ExcelFile
                                            filename={"Reporte de ventas "}
                                            element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /> DETALLES</button>}
                                        >
                                            <ExcelSheet data={ExcelVentaFecha} name="Ventas">
                                                <ExcelColumn label="Venta" value="consecutivo" />
                                                <ExcelColumn label="Fecha" value="fecha" />
                                                <ExcelColumn label="Tienda" value="tienda" />
                                                <ExcelColumn label="Responsable" value="promotor" />
                                                <ExcelColumn label="Plu" value="plu" />
                                                <ExcelColumn label="Producto" value="producto" />
                                                <ExcelColumn label="Precio unitario" value="punitario" />
                                                <ExcelColumn label="Cantidad" value="cantidad" />
                                                <ExcelColumn label="Venta neta" value="subtotal" />
                                                <ExcelColumn label="Iva" value="iva" />
                                                <ExcelColumn label="Valor agregado" value="ipoconsumo" />
                                                <ExcelColumn label="Total" value="total" />
                                                <ExcelColumn label="Total venta" value="totalVenta" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </div>
                                    :
                                    this.state.buscoVenta
                                        ?
                                        <h5>NO HAY DATOS DISPONIBLES</h5>
                                        :
                                        null
                                :
                                null}
                            {this.state.valueVentas === 3
                                ?
                                ventaFecha != null
                                    ?
                                    <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                        <ExcelFile
                                            filename={"BONOS IMPRESOS"}
                                            element={<Tooltip title="EXPORTAR DATOS DE TODAS LAS ESTACIONES:" placement="top"><button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button></Tooltip>}
                                        >
                                            <ExcelSheet data={ExcelTotal} name="BONOS IMPRESOS">
                                                <ExcelColumn label="" value="0" />
                                                <ExcelColumn label="" value="0_5" />
                                                <ExcelColumn label="" value="1" />
                                                <ExcelColumn label="" value="1_1" />
                                                <ExcelColumn label="" value="2" />
                                                <ExcelColumn label="" value="3" />
                                                <ExcelColumn label="" value="4" />
                                                <ExcelColumn label="" value="5" />
                                                <ExcelColumn label="" value="6" />
                                                <ExcelColumn label="" value="7" />
                                                <ExcelColumn label="" value="8" />
                                                <ExcelColumn label="" value="9" />
                                                <ExcelColumn label="" value="10" />
                                                <ExcelColumn label="" value="11" />
                                                <ExcelColumn label="" value="12" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </div>
                                    :
                                    console.log("Venta por fecha es null")
                                :
                                null}
                            {this.state.valueVentas === 1 || this.state.valueVentas === 3
                                ?
                                ventaFecha != null
                                    ?
                                    <Tabs
                                        value={this.state.valueEmpresasFecha}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        aria-label="disabled tabs example"
                                    >
                                        {
                                            ventaFecha.map((venta, key) => {
                                                rowsVentasFechas[key] = []
                                                totalImpuestos[key] = 0
                                                totalVentas[key] = 0
                                                excel[key] = []
                                                excel[key].push({
                                                    "1": "RAZON SOCIAL",
                                                    "2": "ALIAS",
                                                    "3": "NIT",
                                                    "4": "CODIGO EDS",
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                    "8": "",
                                                    "9": "",
                                                    "10": "",
                                                    "11": "",
                                                    "12": "",
                                                })
                                                excel[key].push({
                                                    "1": venta.razon_social,
                                                    "2": venta.alias,
                                                    "3": venta.nit,
                                                    "4": venta.codigo,
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                    "8": "",
                                                    "9": "",
                                                    "10": "",
                                                    "11": "",
                                                    "12": "",
                                                })
                                                excel[key].push({
                                                    "1": "",
                                                    "2": "",
                                                    "3": "",
                                                    "4": "",
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                    "8": "",
                                                    "9": "",
                                                    "10": "",
                                                    "11": "",
                                                    "12": "",
                                                })
                                                excel[key].push({
                                                    "1": "Consecutivo",
                                                    "1_1": "Bono",
                                                    "2": "Fecha",
                                                    "3": "Promotor",
                                                    "4": "Isla",
                                                    "5": "Sutidor",
                                                    "6": "Cara",
                                                    "7": "Manguera",
                                                    "8": "Plu",
                                                    "9": "Descripcion",
                                                    "10": "Precio",
                                                    "11": "Cantidad",
                                                    "12": "Total venta",
                                                })
                                                venta.detalles != null
                                                    ?
                                                    venta.detalles.map((detalle) => {
                                                        ExcelTotal.push({
                                                            "0": venta.codigo,
                                                            "0_5": venta.alias,
                                                            '1': parseInt(detalle.consecutivo),
                                                            '1_1': detalle.bono,
                                                            '2': moment(new Date(detalle.fecha)).format("DD/MM/YYYY hh:mm a"),
                                                            '3': detalle.promotor,
                                                            '4': detalle.isla,
                                                            '5': detalle.surtidor,
                                                            '6': detalle.cara,
                                                            '7': detalle.manguera,
                                                            '8': detalle.producto_codigo,
                                                            '9': detalle.producto_descripcion,
                                                            '10': '$' + this.setearMiles(detalle.precio),
                                                            '11': detalle.cantidad,
                                                            '12': '$' + this.setearMiles(detalle.total_despacho),
                                                        })
                                                    })
                                                    :
                                                    console.log(venta)
                                                return (
                                                    <Tab label={venta.alias} onClick={() => this.cambiarTabs("valueEmpresasFecha", key)} />
                                                )
                                            })
                                        }

                                    </Tabs>
                                    :
                                    console.log("Venta por fecha es null")
                                :
                                null}
                            {this.state.valueVentas === 3
                                ?
                                ventaFecha != null
                                    ?
                                    ventaFecha.map((empresa, key1) => {
                                        empresa.detalles != null
                                            ?
                                            empresa.detalles.map((venta, key2) => {
                                                var ttventa = {
                                                    'consecutivo': parseInt(venta.consecutivo),
                                                    'bono': venta.bono,
                                                    'fecha': moment(new Date(venta.fecha)).format("DD/MM/YYYY hh:mm a"),
                                                    'promotor': venta.promotor,
                                                    'plu': venta.producto_codigo,
                                                    'producto': venta.producto_descripcion,
                                                    //'precio': '$' + this.setearMiles(venta.precio),
                                                    //'cantidad': venta.cantidad,
                                                    'totalVenta': '$' + this.setearMiles(venta.total_despacho),
                                                    'acciones': <button className="btn btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => this.detallesVenta(venta)}><Ojoicon /></button>,

                                                }
                                                excel[key1].push({
                                                    '1': parseInt(venta.consecutivo),
                                                    '1_1': venta.bono,
                                                    '2': moment(new Date(venta.fecha)).format("DD/MM/YYYY hh:mm a"),
                                                    '3': venta.promotor,
                                                    '4': venta.isla,
                                                    '5': venta.surtidor,
                                                    '6': venta.cara,
                                                    '7': venta.manguera,
                                                    '8': venta.producto_codigo,
                                                    '9': venta.producto_descripcion,
                                                    '10': '$' + this.setearMiles(venta.precio),
                                                    '11': venta.cantidad,
                                                    '12': '$' + this.setearMiles(venta.total_despacho),
                                                })
                                                rowsVentasFechas[key1].push(ttventa)
                                            })
                                            :
                                            console.log("No hay ventas")
                                    })

                                    :
                                    console.log("Venta por fecha es null")
                                :
                                this.state.valueVentas === 1
                                    ?

                                    ventaFecha != null
                                        ?
                                        ventaFecha.map((empresa, key1) => {
                                            empresa.ventas != null
                                                ?
                                                empresa.ventas.map((venta, key2) => {
                                                    venta.ventas_detalles.map((detalle, key3) => {
                                                        var ttventa = {
                                                            'consecutivo': parseInt(venta.consecutivo),
                                                            'fecha': moment(new Date(venta.fecha)).format("DD/MM/YYYY hh:mm a"),
                                                            'promotor': venta.nombres,
                                                            'plu': detalle.plu,
                                                            'producto': detalle.descripcion,
                                                            'punitario': '$ ' + this.setearMiles(detalle.precio),
                                                            'cantidad': detalle.cantidad,
                                                            'subtotal': '$ ' + this.setearMiles(detalle.venta_neta),
                                                            'iva': [<td>{detalle.descripcion_iva != null ? detalle.descripcion_iva : ''}</td>, <td>{'$' + (detalle.valor_iva != null ? this.setearMiles(detalle.valor_iva) : 0)}</td>],
                                                            'ipoconsumo': '$' + (detalle.valor_no_gravable != null ? this.setearMiles(detalle.valor_no_gravable) : 0),
                                                            'total': '$' + this.setearMiles(detalle.venta_bruta),
                                                            'totalVenta': '$' + this.setearMiles(venta.venta_total)
                                                        }
                                                        ExcelVentaFecha.push({
                                                            'consecutivo': parseInt(venta.consecutivo),
                                                            'fecha': moment(new Date(venta.fecha)).format("DD/MM/YYYY hh:mm a"),
                                                            'tienda': empresa.razon_social,
                                                            'promotor': venta.nombres,
                                                            'plu': detalle.plu,
                                                            'producto': detalle.descripcion,
                                                            'punitario': '$ ' + this.setearMiles(detalle.precio),
                                                            'cantidad': detalle.cantidad,
                                                            'subtotal': '$ ' + this.setearMiles(detalle.venta_neta),
                                                            'iva': '$' + (detalle.valor_iva != null ? this.setearMiles(detalle.valor_iva) : 0),
                                                            'ipoconsumo': '$' + (detalle.valor_no_gravable != null ? this.setearMiles(detalle.valor_no_gravable) : 0),
                                                            'total': '$' + this.setearMiles(detalle.venta_bruta),
                                                            'totalVenta': '$' + this.setearMiles(venta.venta_total)
                                                        })
                                                        rowsVentasFechas[key1].push(ttventa)
                                                    })
                                                })
                                                :
                                                console.log("No hay ventas")
                                        })

                                        :
                                        console.log("Venta por fecha es null")

                                    : null}
                            {this.state.valueVentas === 1
                                ?
                                ventaFecha != null
                                    ?
                                    <div>
                                        <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>

                                        </div>
                                        <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                            <Paper style={{ padding: '5px', width: '100%' }}>

                                                <MDBDataTable
                                                    //striped
                                                    className="col-md-12"
                                                    searchLabel="Buscar"
                                                    paginationLabel={["Anterior", "Siguente"]}
                                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                    entriesLabel="Filas a mostrar"
                                                    tbodyColor="primary-color"
                                                    theadColor="#FF8800"
                                                    exportToCSV={true}
                                                    striped
                                                    bordered
                                                    autoWidth={false}
                                                    responsive={true}
                                                    hover
                                                    order={['consecutivo', 'desc']}
                                                    data={
                                                        {
                                                            columns: ColumnasEmpresas,
                                                            rows: rowsVentasFechas[this.state.valueEmpresasFecha]
                                                        }
                                                    }
                                                />
                                                <Dialog
                                                    open={this.state.dialogdetalles}
                                                    aria-labelledby="form-dialog-title"
                                                    maxWidth={'lg'}
                                                    fullWidth={true}
                                                    style={{ padding: '0' }}
                                                    disableBackdropClick={false}
                                                >
                                                    <DialogContent style={{ padding: '0' }}>
                                                        <div className="row mx-auto" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                            <div className="col col-md-11 mx-auto" style={{ padding: 0 }}>
                                                                <Paper style={{ padding: '10px' }}>
                                                                    <div className="row">
                                                                        <button className="btn btn-danger " onClick={() => this.cerrarVenta()} style={{ margin: '10px', float: 'right' }}>CERRAR</button>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="numordencompra" className="col-form-label">VENTA</label>
                                                                            <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? ventaSellecionada.consecutivo : '000'} className="form-control" />
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="numordencompra" className="col-form-label">FECHA</label>
                                                                            <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? moment(new Date(ventaSellecionada.fecha)).format("DD/MM/YYYY hh:mm a") : ''} className="form-control" />
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <label htmlFor="numordencompra" className="col-form-label">PROMOTOR</label>
                                                                            <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? ventaSellecionada.promotor : ''} className="form-control" />
                                                                        </div>
                                                                    </div>
                                                                    <MDBDataTable
                                                                        //striped
                                                                        className="col-md-12 mx-auto"
                                                                        searchLabel="Buscar"
                                                                        paginationLabel={["Anterior", "Siguente"]}
                                                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                        entriesLabel="Filas a mostrar"
                                                                        tbodyColor="primary-color"
                                                                        theadColor="#FF8800"
                                                                        exportToCSV={true}
                                                                        striped
                                                                        bordered
                                                                        autoWidth={false}
                                                                        responsive={true}
                                                                        hover
                                                                        order={['plu', 'asc']}

                                                                        data={
                                                                            {
                                                                                columns: [
                                                                                    {
                                                                                        label: 'Plu',
                                                                                        field: 'plu',
                                                                                        sort: 'asc',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'Descripcion',
                                                                                        field: 'descr',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'Precio',
                                                                                        field: 'punitario',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'Cant.',
                                                                                        field: 'cantidad',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'Total',
                                                                                        field: 'venta',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'Isla',
                                                                                        field: 'isla',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'Surtidor',
                                                                                        field: 'surtidor',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'Cara',
                                                                                        field: 'cara',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'Manguera',
                                                                                        field: 'manguera',
                                                                                        width: 150
                                                                                    }
                                                                                ],
                                                                                rows: [
                                                                                    {
                                                                                        'plu': ventaSellecionada.producto_codigo,
                                                                                        'producto': ventaSellecionada.producto_descripcion,
                                                                                        'punitario': '$ ' + this.setearMiles(ventaSellecionada.precio),
                                                                                        'cantidad': ventaSellecionada.cantidad,
                                                                                        'total': '$' + this.setearMiles(ventaSellecionada.total_despacho),
                                                                                        'isla': ventaSellecionada.isla,
                                                                                        'surtidor': ventaSellecionada.surtidor,
                                                                                        'cara': ventaSellecionada.cara,
                                                                                        'manguera': ventaSellecionada.manguera,
                                                                                    }
                                                                                ]


                                                                            }
                                                                        }
                                                                    />
                                                                </Paper>
                                                            </div>
                                                        </div>
                                                        <div className="row mx-auto">
                                                            <div className="col-md-8 bottom-aligned mx-auto"></div>

                                                        </div>
                                                    </DialogContent>
                                                </Dialog>

                                            </Paper>
                                        </div>
                                    </div>
                                    :
                                    console.log("Venta por fecha es null")
                                :
                                this.state.valueVentas === 3
                                    ?
                                    ventaFecha != null
                                        ?
                                        <div>
                                            <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                                <Paper style={{ padding: '5px', width: '100%' }}>
                                                    <ExcelFile
                                                        filename={"BONOS IMPRESOS"}
                                                        element={<Tooltip title="EXPORTAR DATOS DE ESTA ESTACION:" placement="top"><button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button></Tooltip>}
                                                    >
                                                        <ExcelSheet data={excel[this.state.valueEmpresasFecha]} name="BONOS IMPRESOS">
                                                            <ExcelColumn label="" value="1" />
                                                            <ExcelColumn label="" value="1_1" />
                                                            <ExcelColumn label="" value="2" />
                                                            <ExcelColumn label="" value="3" />
                                                            <ExcelColumn label="" value="4" />
                                                            <ExcelColumn label="" value="5" />
                                                            <ExcelColumn label="" value="6" />
                                                            <ExcelColumn label="" value="7" />
                                                            <ExcelColumn label="" value="8" />
                                                            <ExcelColumn label="" value="9" />
                                                            <ExcelColumn label="" value="10" />
                                                            <ExcelColumn label="" value="11" />
                                                            <ExcelColumn label="" value="12" />
                                                        </ExcelSheet>
                                                    </ExcelFile>
                                                    <MDBDataTable
                                                        //striped
                                                        className="col-md-12"
                                                        searchLabel="Buscar"
                                                        paginationLabel={["Anterior", "Siguente"]}
                                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                        entriesLabel="Filas a mostrar"
                                                        tbodyColor="primary-color"
                                                        theadColor="#FF8800"
                                                        exportToCSV={true}
                                                        striped
                                                        bordered
                                                        autoWidth={false}
                                                        responsive={true}
                                                        hover
                                                        sorting={true}
                                                        order={['consecutivo', 'desc']}
                                                        style={{ fontSize: '13px' }}
                                                        data={
                                                            {
                                                                columns: ColumnasEmpresas,
                                                                rows: rowsVentasFechas[this.state.valueEmpresasFecha]
                                                            }
                                                        }
                                                    />
                                                    <Dialog
                                                        open={this.state.dialogdetalles}
                                                        aria-labelledby="form-dialog-title"
                                                        maxWidth={'lg'}
                                                        fullWidth={true}
                                                        style={{ padding: '0' }}
                                                        disableBackdropClick={false}
                                                    >
                                                        <DialogContent style={{ padding: '0' }}>
                                                            <div className="row mx-auto" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                                <div className="col col-md-11 mx-auto" style={{ padding: 0 }}>
                                                                    <Paper style={{ padding: '10px' }}>
                                                                        <div className="row">
                                                                            <button className="btn btn-danger " onClick={() => this.cerrarVenta()} style={{ margin: '10px', float: 'right' }}>CERRAR</button>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-3">
                                                                                <label htmlFor="numordencompra" className="col-form-label">VENTA</label>
                                                                                <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? ventaSellecionada.consecutivo : '000'} className="form-control" />
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="numordencompra" className="col-form-label">FECHA</label>
                                                                                <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? moment(new Date(ventaSellecionada.fecha)).format("DD/MM/YYYY hh:mm a") : ''} className="form-control" />
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <label htmlFor="numordencompra" className="col-form-label">PROMOTOR</label>
                                                                                <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? ventaSellecionada.nombres : ''} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                        <MDBDataTable
                                                                            //striped
                                                                            className="col-md-12 mx-auto"
                                                                            searchLabel="Buscar"
                                                                            paginationLabel={["Anterior", "Siguente"]}
                                                                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                            entriesLabel="Filas a mostrar"
                                                                            tbodyColor="primary-color"
                                                                            theadColor="#FF8800"
                                                                            exportToCSV={true}
                                                                            striped
                                                                            bordered
                                                                            autoWidth={false}
                                                                            responsive={true}
                                                                            hover
                                                                            order={['plu', 'asc']}

                                                                            data={
                                                                                {
                                                                                    columns: [
                                                                                        {
                                                                                            label: 'Plu',
                                                                                            field: 'plu',
                                                                                            sort: 'asc',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Descripcion',
                                                                                            field: 'descr',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Precio',
                                                                                            field: 'punitario',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Cant.',
                                                                                            field: 'cantidad',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Total',
                                                                                            field: 'venta',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Isla',
                                                                                            field: 'isla',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Surtidor',
                                                                                            field: 'surtidor',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Cara',
                                                                                            field: 'cara',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Manguera',
                                                                                            field: 'manguera',
                                                                                            width: 150
                                                                                        }
                                                                                    ],
                                                                                    rows: ventaSellecionada != null ? [
                                                                                        {
                                                                                            'plu': ventaSellecionada.producto_codigo,
                                                                                            'producto': ventaSellecionada.producto_descripcion,
                                                                                            'punitario': '$ ' + this.setearMiles(ventaSellecionada.precio),
                                                                                            'cantidad': ventaSellecionada.cantidad,
                                                                                            'total': '$' + this.setearMiles(ventaSellecionada.total_despacho),
                                                                                            'isla': ventaSellecionada.isla,
                                                                                            'surtidor': ventaSellecionada.surtidor,
                                                                                            'cara': ventaSellecionada.cara,
                                                                                            'manguera': ventaSellecionada.manguera,
                                                                                        }
                                                                                    ] : []


                                                                                }
                                                                            }
                                                                        />
                                                                    </Paper>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-auto">
                                                                <div className="col-md-8 bottom-aligned mx-auto"></div>

                                                            </div>
                                                        </DialogContent>
                                                    </Dialog>

                                                </Paper>
                                            </div>
                                            {/* <div className="row" style={{ margin: '10px' }}>
                                                <div className="col-md-4">
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="numordencompra" className="col-form-label">Total impuestos</label>
                                                    <input id="numordencompra" disabled={true} value={'$' + this.setearMiles(totalImpuestos[this.state.valueEmpresasFecha])} className="form-control" />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="numordencompra" className="col-form-label">Total ventas</label>
                                                    <input id="numordencompra" disabled={true} value={'$' + this.setearMiles(totalVentas[this.state.valueEmpresasFecha])} className="form-control" />
                                                </div>
                                            </div>*/}
                                        </div>
                                        :
                                        console.log("Venta por fecha es null")
                                    :
                                    null}


                        </div>
                )
                break;
        }

    }

    mostrarReportes = (tab) => {
        var usuario = store.getState().usuario
        var tipoDominio = store.getState().tipoDominio
        switch (tab) {
            case 0:
                let ColumnasventasFecha = [
                    {
                        label: 'Con.',
                        field: 'consecutivo',
                        sort: 'desc',
                        width: 150
                    }, {
                        label: 'Fecha',
                        field: 'cantidad',
                        width: 150
                    }, {
                        label: 'Promotor',
                        field: 'PROMOTOR',
                        width: 150
                    }, {
                        label: 'Plu',
                        field: 'plu',
                        width: 150
                    }, {
                        label: 'Descripcion',
                        field: 'descr',
                        width: 150
                    }, {
                        label: 'Precio',
                        field: 'punitario',
                        width: 150
                    }, {
                        label: 'Cant.',
                        field: 'cantidad',
                        width: 150
                    }, {
                        label: 'Venta neta',
                        field: 'sproducto',
                        width: 150
                    }, {
                        label: 'IVA',
                        field: 'subventa',
                        width: 150
                    }, {
                        label: 'Valor agregado',
                        field: 'imventa',
                        width: 150
                    }, {
                        label: 'Total',
                        field: 'venta',
                        width: 150
                    }, {
                        label: 'T. Venta',
                        field: 'tventa',
                        width: 150
                    }
                ]
                let ColumnasventasFechaTotales = [
                    {
                        label: 'Con.',
                        field: 'consecutivo',
                        sort: 'desc',
                        width: 150
                    }, {
                        label: 'TIPO',
                        field: 'tipo',
                        sort: 'desc',
                        width: 150
                    }, {
                        label: 'Fecha',
                        field: 'fecha',
                        width: 150
                    }, {
                        label: 'Promotor',
                        field: 'promotor',
                        width: 150
                    }/*, {
                        label: 'Sub. Venta',
                        field: 'subventa',
                        width: 150
                    }*/, {
                        label: 'Imp. venta',
                        field: 'ipventa',
                        width: 150
                    }, {
                        label: 'T. Venta',
                        field: 'tventa',
                        width: 150
                    }, {
                        label: 'Acciones',
                        field: 'acciones',
                        width: 150
                    }
                ]
                let ColumnasventasTotal = [
                    {
                        label: 'Kiosco',
                        field: 'kiosco',
                        width: 150
                    }, {
                        label: 'Con.',
                        field: 'consecutivo',
                        sort: 'desc',
                        width: 150
                    }, {
                        label: 'Tipo',
                        field: 'tipo',
                        sort: 'desc',
                        width: 150
                    }, {
                        label: 'Fecha',
                        field: 'fecha',
                        width: 150
                    }, {
                        label: 'Promotor',
                        field: 'promotor',
                        width: 150
                    }/*, {
                        label: 'Sub. Venta',
                        field: 'subventa',
                        width: 150
                    }*/, {
                        label: 'Imp. venta',
                        field: 'ipventa',
                        width: 150
                    }, {
                        label: 'T. Venta',
                        field: 'tventa',
                        width: 150
                    }, {
                        label: 'Acciones',
                        field: 'acciones',
                        width: 150
                    }
                ]
                var rowsVentasFechas = []
                var ventaFecha = this.state.ventasXFecha
                var ExcelVentaFecha = []
                var totalVentas = []
                var totalImpuestos = []
                var ventaSellecionada = null
                if (this.state.movimientoSeleccionado != null) {
                    ventaSellecionada = this.state.movimientoSeleccionado
                }
                var excel = []
                var reportes = this.state.reporteZ[0]
                var tablas = []
                var mm = []
                var mmtiendas = 0
                if (ventaFecha != null) {
                    mmtiendas = ventaFecha.length
                }
                var mmexcel = []
                var mmexcel2 = []
                var mmtotal = 0
                var mmimpuesto = 0
                return (
                    this.state.vistaCargando ?
                        <Loader />
                        :
                        <div>
                            <div className="row">
                                <Tabs
                                    value={this.state.valueVentas}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="disabled tabs example"
                                    style={{ margin: '0px 15px' }}
                                >
                                    <Tab label="TOTAL POR EMPRESA" onClick={() => this.cambiarTabs("valueVentas", 0)} />
                                    <Tab label="DETALLES POR FECHA" onClick={() => this.cambiarTabs("valueVentas", 1)} />
                                    <Tab label="VENTAS POR PLU" onClick={() => this.cambiarTabs("valueVentas", 2)} />
                                    <Tab label="TOTALES" onClick={() => this.cambiarTabs("valueVentas", 3)} />
                                    <Tab label="CIERRE MENSUAL" onClick={() => this.cambiarTabs("valueVentas", 4)} />
                                    {/*<Tab label="Por producto" onClick={() => this.tabFiltrosKardex(3)} />*/}
                                </Tabs>
                            </div>
                            {this.state.valueVentas === 1 || this.state.valueVentas === 0 || this.state.valueVentas === 3 ?
                                usuario.tipo === "5"
                                    ?
                                    <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                        <div className="col-md-3">
                                            <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"} >
                                                <DateTimePicker
                                                    format="yyyy-MM-dd hh:mm a"
                                                    id="datetime"
                                                    ampm={false}
                                                    disableFuture={true}
                                                    value={this.state.selectedDateVenta}
                                                    onChange={this.handleChangemomentventa}
                                                    className="rounded form-control"
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                <DateTimePicker
                                                    format="yyyy-MM-dd hh:mm a"
                                                    id="datetime"
                                                    ampm={false}
                                                    value={this.state.selectedDateFinventa}
                                                    onChange={this.handleChangemomentFinVenta}
                                                    disableFuture={true}
                                                    className="rounded form-control"
                                                    minDate={moment(new Date(this.state.selectedDateVenta.toString())).format("YYYY-MM-DD HH:mm")}
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="numordencompra" className="col-form-label">TIPO</label>
                                            <Selects
                                                value={this.state.tipoSeleccionado}
                                                onChange={this.handleChanges('tipoSeleccionado')}
                                                options={this.state.tiposBd}
                                                isClearable
                                            />
                                        </div>
                                        <div className="col-md-3  bottom-aligned">
                                            <button className="btn btn-success btn-block" onClick={() => this.consultaVentasFecha()}>CONSULTAR</button>
                                        </div>
                                    </div>
                                    :
                                    <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                        <div className="col-md-2">
                                            <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                <DateTimePicker
                                                    format="yyyy-MM-dd hh:mm a"
                                                    id="datetime"
                                                    value={this.state.selectedDateVenta}
                                                    onChange={this.handleChangemomentventa}
                                                    ampm={false}
                                                    disableFuture={true}
                                                    className="rounded form-control"
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                <DateTimePicker
                                                    format="yyyy-MM-dd hh:mm a"
                                                    id="datetime"
                                                    value={this.state.selectedDateFinventa}
                                                    onChange={this.handleChangemomentFinVenta}
                                                    disableFuture={true}
                                                    ampm={false}
                                                    className="rounded form-control"
                                                    minDate={moment(new Date(this.state.selectedDateVenta.toString())).format("YYYY-MM-DD HH:mm")}
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="numordencompra" className="col-form-label">TIPO</label>
                                            <Selects
                                                value={this.state.tipoSeleccionado}
                                                onChange={this.handleChanges('tipoSeleccionado')}
                                                options={this.state.tiposBd}
                                                isClearable
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="numordencompra" className="col-form-label">EMPRESA</label>
                                            <Selects
                                                value={this.state.multiEmpresasVentasSeleccionadas}
                                                onChange={this.handleChanges('multiEmpresasVentasSeleccionadas')}
                                                options={this.state.empresasbd}
                                                isMulti
                                            />
                                        </div>

                                        <div className="col-md-2 bottom-aligned">
                                            <button className="btn btn-success btn-block" onClick={() => this.consultaVentasFecha()}>CONSULTAR</button>
                                        </div>
                                    </div>
                                : this.state.valueVentas === 4
                                    ? <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                        <div className={usuario.tipo === "5" ? "col-md-3" : "col-md-2"} >
                                            <label htmlFor="numordencompra" className="col-form-label">MES</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"} >
                                                <DatePicker
                                                    format="MMM yyyy"
                                                    id="datetime"
                                                    ampm={false}
                                                    disableFuture={true}
                                                    value={this.state.selectedDateVenta}
                                                    onChange={this.handleChangemomentventa}
                                                    className="rounded form-control"
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        {usuario.tipo !== "5" && <div className="col-md-8">
                                            <label htmlFor="numordencompra" className="col-form-label">EMPRESA</label>
                                            <Selects
                                                value={this.state.multiEmpresasVentasSeleccionadas}
                                                onChange={this.handleChanges('multiEmpresasVentasSeleccionadas')}
                                                options={this.state.empresasbd}
                                                isMulti
                                            />
                                        </div>}
                                        <div className={usuario.tipo === "5" ? "col-md-9 bottom-aligned" : "col-md-2 bottom-aligned"}>
                                            <button className="btn btn-success btn-block" onClick={() => this.consultaVentasFecha()}>CONSULTAR</button>
                                        </div>
                                    </div>
                                    :
                                    usuario.tipo === "5"
                                        ?
                                        <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                            <div className="col-md-3">
                                                <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                    <DateTimePicker
                                                        format="yyyy-MM-dd hh:mm a"
                                                        id="datetime"
                                                        ampm={false}
                                                        value={this.state.selectedDatez}
                                                        onChange={this.handleChangemomentZ}
                                                        className="rounded form-control"
                                                        disableFuture={true}
                                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                    <DateTimePicker
                                                        format="yyyy-MM-dd hh:mm a"
                                                        id="datetime"
                                                        ampm={false}
                                                        value={this.state.selectedDateFinz}
                                                        minDate={moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD HH:mm")}
                                                        onChange={this.handleChangemomentFinZ}
                                                        disableFuture={true}

                                                        className="rounded form-control"
                                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="col-md-3  bottom-aligned">
                                                <button className="btn btn-success btn-block" onClick={() => this.consultaVentasPlu()}>CONSULTAR</button>
                                            </div>
                                        </div>
                                        :
                                        <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                            <div className="col-md-2">
                                                <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                    <DateTimePicker
                                                        format="yyyy-MM-dd hh:mm a"
                                                        id="datetime"
                                                        ampm={false}
                                                        value={this.state.selectedDatez}
                                                        onChange={this.handleChangemomentZ}
                                                        className="rounded form-control"
                                                        disableFuture={true}
                                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>

                                            <div className="col-md-2">
                                                <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                    <DateTimePicker
                                                        format="yyyy-MM-dd hh:mm a"
                                                        id="datetime"
                                                        ampm={false}
                                                        value={this.state.selectedDateFinz}
                                                        onChange={this.handleChangemomentFinZ}
                                                        minDate={moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD HH:mm")}
                                                        className="rounded form-control"
                                                        disableFuture={true}
                                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="col-md-5">
                                                <label htmlFor="numordencompra" className="col-form-label">EMPRESA</label>
                                                <Selects
                                                    value={this.state.multiEmpresasZ}
                                                    onChange={this.handleChanges('multiEmpresasZ')}
                                                    options={this.state.empresasbd}
                                                    isMulti
                                                />
                                            </div>

                                            <div className="col-md-3  bottom-aligned">
                                                <button className="btn btn-success btn-block" disabled={JSON.stringify(this.state.multiEmpresasZ) === "[]"} onClick={() => this.consultaVentasPlu()}>CONSULTAR</button>
                                            </div>
                                        </div>
                            }
                            {/* REPORTE DE VENTAS excel multiple*/}
                            {this.state.valueVentas === 1
                                ?
                                ventaFecha != null
                                    ?
                                    <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                        <ExcelFile
                                            filename={"Reporte de ventas "}
                                            element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /> DETALLES</button>}
                                        >
                                            <ExcelSheet data={ExcelVentaFecha} name="Ventas">
                                                <ExcelColumn label="Venta" value="consecutivo" />
                                                <ExcelColumn label="Fecha" value="fecha" />
                                                <ExcelColumn label="Tienda" value="tienda" />
                                                <ExcelColumn label="Responsable" value="promotor" />
                                                <ExcelColumn label="Cliente" value="promotor" />
                                                {/*<ExcelColumn label="Cliente" value="nit_cliente" />
                                                <ExcelColumn label="Nombre cliente" value="cliente" />*/}
                                                <ExcelColumn label="Plu" value="plu" />
                                                <ExcelColumn label="Producto" value="producto" />
                                                <ExcelColumn label="Precio unitario" value="punitario" />
                                                <ExcelColumn label="Cantidad" value="cantidad" />
                                                <ExcelColumn label="Venta neta" value="subtotal" />
                                                <ExcelColumn label="Iva" value="iva" />
                                                <ExcelColumn label="Valor agregado" value="ipoconsumo" />
                                                <ExcelColumn label="Total" value="total" />
                                                <ExcelColumn label="Total venta" value="totalVenta" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </div>
                                    :
                                    this.state.buscoVenta
                                        ?
                                        <h5>NO HAY DATOS DISPONIBLES</h5>
                                        :
                                        null
                                :
                                null}
                            {/* REPORTE DE VENTAS  TABS EMPRESAS */}
                            {console.log(ventaFecha)}
                            {this.state.valueVentas === 1 || this.state.valueVentas === 0
                                ?
                                ventaFecha != null
                                    ?
                                    <Tabs
                                        value={this.state.valueEmpresasFecha}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        scrollButtons={true}
                                        aria-label="disabled tabs"
                                    >
                                        {
                                            ventaFecha.map((venta, key) => {
                                                console.log(ventaFecha)
                                                rowsVentasFechas[key] = []
                                                totalImpuestos[key] = 0
                                                totalVentas[key] = 0
                                                excel[key] = []
                                                excel[key].push({
                                                    "1": "RAZON SOCIAL",
                                                    "2": "ALIAS",
                                                    "3": "NIT",
                                                    "4": "",
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                    "8": "",
                                                    "9": "",
                                                    "10": "",
                                                    "11": "",
                                                    "12": "",
                                                    "13": "",
                                                })
                                                excel[key].push({
                                                    "1": venta.razon_social,
                                                    "2": venta.nombre,
                                                    "3": venta.nit,
                                                    "4": "",
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                    "8": "",
                                                    "9": "",
                                                    "10": "",
                                                    "11": "",
                                                    "12": "",
                                                    "13": "",
                                                })
                                                excel[key].push({
                                                    "1": "",
                                                    "2": "",
                                                    "3": "",
                                                    "4": "",
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                    "8": "",
                                                    "9": "",
                                                    "10": "",
                                                    "11": "",
                                                    "12": "",
                                                    "13": "",
                                                })
                                                excel[key].push({
                                                    "1": "Consecutivo",
                                                    "2": "Fecha",
                                                    "3": "Promotor",
                                                    "4": "Plu",
                                                    "5": "Descripcion",
                                                    "6": "Precio",
                                                    "7": "Cantidad",
                                                    "8": "Venta neta",
                                                    "9": "IVA",
                                                    "10": "Valor agregado",
                                                    "11": "Subtotal",
                                                    "12": "Impuestos venta",
                                                    "13": "Total venta",
                                                })
                                                return (
                                                    <Tab label={venta.nombre} onClick={() => this.cambiarTabs("valueEmpresasFecha", key)} />
                                                )
                                            })
                                        }

                                    </Tabs>
                                    :
                                    console.log("Venta por fecha es null")
                                :
                                this.state.valueVentas === 3 ?
                                    ventaFecha != null
                                        ?
                                        <div>
                                            <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                                <Paper style={{ padding: '5px', width: '100%' }}>
                                                    {
                                                        ventaFecha.map((venta, key) => {
                                                            venta.ventas.map((det) => {
                                                                mm.push({
                                                                    'kiosko': venta.nombre,
                                                                    'con': det.consecutivo,
                                                                    'tipo': (det.tercero_id === 3 ? "F. ELECTRONICA" : "F. CONVENCIONAL"),
                                                                    'fecha': moment(det.fecha).format("DD/MM/YY hh:mm:ss a"),
                                                                    'promo': det.nombres,
                                                                    'imp': '$ ' + this.setearMiles(det.impuesto_total),
                                                                    'total': '$ ' + this.setearMiles(det.venta_total),
                                                                    'acciones': [
                                                                        <button className="btn btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => this.detallesVenta(det)}><Ojoicon /></button>
                                                                        /*<button className="btn btn-sm btn-danger" onClick={() => this.anularVentas(venta, empresa.empresas_id)}><DeleteIcon /></button>*/
                                                                    ]
                                                                })
                                                                mmexcel2.push({
                                                                    '0': venta.nombre,
                                                                    '1': det.consecutivo,
                                                                    '1.5': det.tercero_id === 3 ? "F. ELECTRONICA" : "F. CONVENCIONAL",
                                                                    '2': moment(det.fecha).format("DD/MM/YY hh:mm:ss a"),
                                                                    '3': det.nombres,
                                                                    '4': '$ ' + this.setearMiles(det.impuesto_total),
                                                                    '5': '$ ' + this.setearMiles(det.venta_total)
                                                                })
                                                                det.ventas_detalles.map((detalle) => {
                                                                    mmexcel.push({
                                                                        "0": venta.nombre,
                                                                        "1": det.prefijo + "-" + det.consecutivo,
                                                                        "2": moment(new Date(det.fecha)).format("DD/MM/YYYY hh:mm:ss a"),
                                                                        "3": det.nombres,
                                                                        "4": detalle.plu,
                                                                        "5": detalle.descripcion,
                                                                        "6": '$' + this.setearMiles(detalle.precio),
                                                                        "7": detalle.cantidad,
                                                                        "8": '$' + this.setearMiles(detalle.venta_neta),
                                                                        "9": detalle.valor_iva != null ? '$' + this.setearMiles(detalle.valor_iva) : '$0',
                                                                        "10": detalle.valor_no_gravable != null ? '$' + this.setearMiles(detalle.valor_no_gravable) : '$0',
                                                                        "11": '$' + this.setearMiles(detalle.venta_bruta),
                                                                        "12": '$' + this.setearMiles((det.impuesto_total).toFixed(3)),
                                                                        "13": '$' + this.setearMiles(det.venta_total),
                                                                    })
                                                                })
                                                                mmimpuesto = mmimpuesto + det.impuesto_total
                                                                mmtotal = mmtotal + det.venta_total

                                                            })

                                                        })
                                                    }
                                                    <ExcelFile
                                                        filename={"VENTAS " + moment(new Date()).format("DD/MM/YYYY")}
                                                        element={<button className="btn btn-success" style={{ margin: '10px' }}>VENTAS <img src={excelIcon} /></button>}
                                                    >
                                                        <ExcelSheet data={mmexcel2} name="VENTAS">
                                                            <ExcelColumn label="kIOSCO" value="0" />
                                                            <ExcelColumn label="CONSECUTIVO" value="1" />
                                                            <ExcelColumn label="TIPO" value="1.5" />
                                                            <ExcelColumn label="FECHA" value="2" />
                                                            <ExcelColumn label="PROMOTOR" value="3" />
                                                            <ExcelColumn label="Impuestos venta" value="4" />
                                                            <ExcelColumn label="Total venta" value="5" />
                                                        </ExcelSheet>
                                                    </ExcelFile>
                                                    <ExcelFile
                                                        filename={"VENTAS  DETALLADAS" + moment(new Date()).format("DD/MM/YYYY")}
                                                        element={<button className="btn btn-success" style={{ margin: '10px' }}>VENTAS DETALLADAS <img src={excelIcon} /></button>}
                                                    >
                                                        <ExcelSheet data={mmexcel} name="VENTAS">
                                                            <ExcelColumn label="kIOSCO" value="0" />
                                                            <ExcelColumn label="CONSECUTIVO" value="1" />
                                                            <ExcelColumn label="FECHA" value="2" />
                                                            <ExcelColumn label="PROMOTOR" value="3" />
                                                            <ExcelColumn label="PLU" value="4" />
                                                            <ExcelColumn label="PRODUCTO" value="5" />
                                                            <ExcelColumn label="PRECIO U" value="6" />
                                                            <ExcelColumn label="CANTIDAD." value="7" />
                                                            <ExcelColumn label="VENTA NETA" value="8" />
                                                            <ExcelColumn label="IVA" value="9" />
                                                            <ExcelColumn label="VALOR AGREGADO" value="10" />
                                                            <ExcelColumn label="Subtotal" value="11" />
                                                            <ExcelColumn label="Impuestos venta" value="12" />
                                                            <ExcelColumn label="Total venta" value="13" />
                                                        </ExcelSheet>
                                                    </ExcelFile>
                                                    <MDBDataTable
                                                        //striped
                                                        className="col-md-12"
                                                        searchLabel="Buscar"
                                                        paginationLabel={["Anterior", "Siguente"]}
                                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                        entriesLabel="Filas a mostrar"
                                                        tbodyColor="primary-color"
                                                        theadColor="#FF8800"
                                                        exportToCSV={true}
                                                        striped
                                                        bordered
                                                        autoWidth={false}
                                                        responsive={true}
                                                        hover
                                                        sorting={true}
                                                        order={['fecha', 'desc']}
                                                        style={{ fontSize: '13px' }}
                                                        data={
                                                            {
                                                                columns: ColumnasventasTotal,
                                                                rows: mm
                                                            }
                                                        }
                                                    />
                                                    <Dialog
                                                        open={this.state.dialogdetalles}
                                                        aria-labelledby="form-dialog-title"
                                                        maxWidth={'lg'}
                                                        fullWidth={true}
                                                        style={{ padding: '0' }}
                                                        disableBackdropClick={false}
                                                    >
                                                        <DialogContent style={{ padding: '0' }}>
                                                            <div className="row mx-auto" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                                <div className="col col-md-11 mx-auto" style={{ padding: 0 }}>
                                                                    <Paper style={{ padding: '10px' }}>
                                                                        <div className="row">
                                                                            <button className="btn btn-danger " onClick={() => this.cerrarVenta()} style={{ margin: '10px', float: 'right' }}>CERRAR</button>
                                                                        </div>
                                                                        <div className="row">
                                                                            <h5 className="mx-auto"><b>DETALLES DE LA FACTURA {ventaSellecionada != null && ventaSellecionada.tercero_id === 2 ? "CONVENCIONAL #" : "ELECTRONICA # "}{ventaSellecionada != null && ventaSellecionada.consecutivo}</b></h5>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-2">
                                                                                <label htmlFor="numordencompra" className="col-form-label">TIPO</label>
                                                                                <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? ventaSellecionada.tercero_id === 2 ? "F. CONVENCIONAL" : "F. ELECTRONICA" : "F. CONVENCIONAL"} className="form-control" />
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label htmlFor="numordencompra" className="col-form-label">VENTA</label>
                                                                                <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? ventaSellecionada.consecutivo : '000'} className="form-control" />
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <label htmlFor="numordencompra" className="col-form-label">FECHA</label>
                                                                                <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? moment(new Date(ventaSellecionada.fecha)).format("DD/MM/YYYY hh:mm a") : ''} className="form-control" />
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <label htmlFor="numordencompra" className="col-form-label">PROMOTOR</label>
                                                                                <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? ventaSellecionada.nombres : ''} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            ventaSellecionada != null && ventaSellecionada.tercero_id === 3
                                                                                ?
                                                                                <div className="row">
                                                                                    <div className="col-md-4">
                                                                                        <label htmlFor="numordencompra" className="col-form-label">IDENTIFICACION CLIENTE</label>
                                                                                        <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? ventaSellecionada.nit_cliente : ''} className="form-control" />
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <label htmlFor="numordencompra" className="col-form-label">NOMBRE CLIENTE</label>
                                                                                        <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? ventaSellecionada.cliente : ''} className="form-control" />
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }

                                                                        <MDBDataTable
                                                                            //striped
                                                                            className="col-md-12 mx-auto"
                                                                            searchLabel="Buscar"
                                                                            paginationLabel={["Anterior", "Siguente"]}
                                                                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                            entriesLabel="Filas a mostrar"
                                                                            tbodyColor="primary-color"
                                                                            theadColor="#FF8800"
                                                                            exportToCSV={true}
                                                                            striped
                                                                            bordered
                                                                            autoWidth={false}
                                                                            responsive={true}
                                                                            hover
                                                                            order={['plu', 'asc']}
                                                                            data={
                                                                                {
                                                                                    columns: [
                                                                                        {
                                                                                            label: 'Plu',
                                                                                            field: 'plu',
                                                                                            sort: 'asc',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Descripcion',
                                                                                            field: 'descr',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Precio',
                                                                                            field: 'punitario',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Cant.',
                                                                                            field: 'cantidad',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Venta Neta',
                                                                                            field: 'sproducto',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'IVA',
                                                                                            field: 'subventa',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Valor agregado',
                                                                                            field: 'imventa',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Total',
                                                                                            field: 'venta',
                                                                                            width: 150
                                                                                        }
                                                                                    ],
                                                                                    rows: ventaSellecionada != null ?
                                                                                        ventaSellecionada.ventas_detalles != null
                                                                                            ?
                                                                                            ventaSellecionada.ventas_detalles.map((detalle) => {
                                                                                                return ({
                                                                                                    'plu': detalle.plu,
                                                                                                    'producto': detalle.descripcion,
                                                                                                    'punitario': '$ ' + this.setearMiles(detalle.precio),
                                                                                                    'cantidad': detalle.cantidad,
                                                                                                    'subtotal': '$ ' + this.setearMiles(detalle.venta_neta),
                                                                                                    'iva': [<td>{detalle.descripcion_iva != null ? detalle.descripcion_iva : ''}</td>, <td>{'$' + (detalle.valor_iva != null ? this.setearMiles(detalle.valor_iva) : 0)}</td>],
                                                                                                    'ipoconsumo': '$' + (detalle.valor_no_gravable != null ? this.setearMiles(detalle.valor_no_gravable) : 0),
                                                                                                    'total': '$' + this.setearMiles(detalle.venta_bruta),
                                                                                                })
                                                                                            })
                                                                                            :
                                                                                            []
                                                                                        :
                                                                                        []
                                                                                }
                                                                            }
                                                                        />
                                                                    </Paper>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-auto">
                                                                <div className="col-md-8 bottom-aligned mx-auto"></div>

                                                            </div>
                                                        </DialogContent>
                                                    </Dialog>
                                                </Paper>
                                            </div>
                                            <div className="row" style={{ margin: '10px' }}>
                                                <div className="col-md-4">
                                                    <label htmlFor="numordencompra" className="col-form-label">Kioscos</label>
                                                    <input id="numordencompra" disabled={true} value={mmtiendas} className="form-control" />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="numordencompra" className="col-form-label">Total impuestos</label>
                                                    <input id="numordencompra" disabled={true} value={'$ ' + this.setearMiles(mmimpuesto)} className="form-control" />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="numordencompra" className="col-form-label">Total ventas</label>
                                                    <input id="numordencompra" disabled={true} value={'$ ' + this.setearMiles(mmtotal)} className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    :

                                    <Tabs
                                        value={this.state.valueZ}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        aria-label="disabled tabs example"
                                    >


                                        {
                                            reportes != null
                                                ?
                                                reportes.ventas_total != null
                                                    ?
                                                    reportes.ventas_total.map((tienda, key) => {
                                                        console.log(tienda)
                                                        excel[key] = []
                                                        excel[key].push({
                                                            "1": "RAZON SOCIAL",
                                                            "2": "ALIAS",
                                                            "3": "NIT",
                                                            "4": "",
                                                            "5": "",
                                                            "7": "",
                                                            "8": "",
                                                            "9": "",
                                                        })
                                                        excel[key].push({
                                                            "1": tienda.razon_social,
                                                            "2": tienda.nombre,
                                                            "3": tienda.nit,
                                                            "4": "",
                                                            "5": "",
                                                            "7": "",
                                                            "8": "",
                                                            "9": "",
                                                        })
                                                        excel[key].push({
                                                            "1": "",
                                                            "2": "",
                                                            "3": "",
                                                            "4": "",
                                                            "5": "",
                                                            "7": "",
                                                            "8": "",
                                                            "9": "",
                                                        })
                                                        excel[key].push({
                                                            "1": "PLU",
                                                            "2": "PRODUCTO",
                                                            "3": "PRECIO",
                                                            "4": "CANTIDAD",
                                                            "5": "SUBTOTAL",
                                                            "7": "IVA",
                                                            "8": "IMPOCONSUMO",
                                                            "9": "TOTAL",
                                                        })
                                                        console.log(excel[key])
                                                        return (
                                                            <Tab label={tienda.nombre} onClick={() => this.cambiarTabs("valueZ", key)} />
                                                        )
                                                    })
                                                    :
                                                    console.log("eror en ventas por dia   " + reportes.ventas_total)
                                                :
                                                console.log("eror en reportes " + reportes)

                                        }
                                    </Tabs>
                            }
                            {this.state.valueVentas === 0
                                ?
                                ventaFecha != null
                                    ?
                                    ventaFecha.map((empresa, key1) => {
                                        empresa.ventas != null
                                            ?
                                            empresa.ventas.map((venta, key2) => {
                                                var ttventa = {
                                                    'consecutivo': parseInt(venta.consecutivo),
                                                    'tipo': venta.tercero_id === 2 ? "F. CONVENCIONAL" : "F. ELECTRONICA",
                                                    'fecha': moment(new Date(venta.fecha)).format("DD/MM/YYYY hh:mm a"),
                                                    'promotor': venta.nombres,
                                                    /*'subtotalVenta': '$' + this.setearMiles(venta.venta_total),*/
                                                    'ImpuestosVenta': '$' + this.setearMiles((venta.impuesto_total).toFixed(3)),
                                                    'totalVenta': '$' + this.setearMiles(venta.venta_total),
                                                    'acciones': [
                                                        <button className="btn btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => this.detallesVenta(venta)}><Ojoicon /></button>
                                                        /*<button className="btn btn-sm btn-danger" onClick={() => this.anularVentas(venta, empresa.empresas_id)}><DeleteIcon /></button>*/
                                                    ]
                                                }
                                                venta.ventas_detalles != null ?
                                                    venta.ventas_detalles.map((detalles) => {
                                                        excel[key1].push({
                                                            "1": venta.prefijo + "-" + venta.consecutivo,
                                                            "2": moment(new Date(venta.fecha)).format("DD/MM/YYYY hh:mm a"),
                                                            "3": venta.nombres,
                                                            "4": detalles.plu,
                                                            "5": detalles.descripcion,
                                                            "6": '$' + this.setearMiles(detalles.precio),
                                                            "7": detalles.cantidad,
                                                            "8": '$' + this.setearMiles(detalles.venta_neta),
                                                            "9": detalles.valor_iva != null ? '$' + this.setearMiles(detalles.valor_iva) : '$0',
                                                            "10": detalles.valor_no_gravable != null ? '$' + this.setearMiles(detalles.valor_no_gravable) : '$0',
                                                            "11": '$' + this.setearMiles(detalles.venta_bruta),
                                                            "12": '$' + this.setearMiles((venta.impuesto_total).toFixed(3)),
                                                            "13": '$' + this.setearMiles(venta.venta_total),
                                                        })
                                                    })
                                                    :
                                                    console.log("n")
                                                rowsVentasFechas[key1].push(ttventa)
                                                totalImpuestos[key1] += parseFloat(venta.impuesto_total)
                                                totalVentas[key1] += parseFloat(venta.venta_total)
                                            })
                                            :
                                            console.log("No hay ventas")
                                    })

                                    :
                                    console.log("Venta por fecha es null")
                                :
                                this.state.valueVentas === 1
                                    ?

                                    ventaFecha != null
                                        ?
                                        ventaFecha.map((empresa, key1) => {
                                            empresa.ventas != null
                                                ?
                                                empresa.ventas.map((venta, key2) => {
                                                    venta.ventas_detalles.map((detalle, key3) => {
                                                        var ttventa = {
                                                            'consecutivo': parseInt(venta.consecutivo),
                                                            'fecha': moment(new Date(venta.fecha)).format("DD/MM/YYYY hh:mm a"),
                                                            'promotor': venta.nombres,
                                                            'plu': detalle.plu,
                                                            'producto': detalle.descripcion,
                                                            'punitario': '$ ' + this.setearMiles(detalle.precio),
                                                            'cantidad': detalle.cantidad,
                                                            'subtotal': '$ ' + this.setearMiles(detalle.venta_neta),
                                                            'iva': [<td>{detalle.descripcion_iva != null ? detalle.descripcion_iva : ''}</td>, <td>{'$' + (detalle.valor_iva != null ? this.setearMiles(detalle.valor_iva) : 0)}</td>],
                                                            'ipoconsumo': '$' + (detalle.valor_no_gravable != null ? this.setearMiles(detalle.valor_no_gravable) : 0),
                                                            'total': '$' + this.setearMiles(detalle.venta_bruta),
                                                            'totalVenta': '$' + this.setearMiles(venta.venta_total)
                                                        }
                                                        ExcelVentaFecha.push({
                                                            'consecutivo': parseInt(venta.consecutivo),
                                                            'fecha': moment(new Date(venta.fecha)).format("DD/MM/YYYY hh:mm a"),
                                                            'tienda': empresa.razon_social,
                                                            'promotor': venta.nombres,
                                                            /*'cliente': venta.cliente,
                                                            'nit_cliente': venta.nit_cliente,*/
                                                            'plu': detalle.plu,
                                                            'producto': detalle.descripcion,
                                                            'punitario': '$ ' + this.setearMiles(detalle.precio),
                                                            'cantidad': detalle.cantidad,
                                                            'subtotal': '$ ' + this.setearMiles(detalle.venta_neta),
                                                            'iva': '$' + (detalle.valor_iva != null ? this.setearMiles(detalle.valor_iva) : 0),
                                                            'ipoconsumo': '$' + (detalle.valor_no_gravable != null ? this.setearMiles(detalle.valor_no_gravable) : 0),
                                                            'total': '$' + this.setearMiles(detalle.venta_bruta),
                                                            'totalVenta': '$' + this.setearMiles(venta.venta_total)
                                                        })
                                                        rowsVentasFechas[key1].push(ttventa)
                                                    })
                                                })
                                                :
                                                console.log("No hay ventas")
                                        })

                                        :
                                        console.log("Venta por fecha es null")

                                    :
                                    reportes != null
                                        ?
                                        reportes.ventas_total != null
                                            ?
                                            reportes.ventas_total.map((tienda, key) => {
                                                var detalles = []
                                                if (tienda != null) {
                                                    if (tienda.ventas_detalle_gravada != null) {
                                                        tienda.ventas_detalle_gravada.map((venta, key) => {
                                                            detalles.push(venta)
                                                        })
                                                    }
                                                    if (tienda.ventas_detalle_no_gravada) {
                                                        tienda.ventas_detalle_no_gravada.map((venta, key) => {
                                                            detalles.push(venta)
                                                        })
                                                    }
                                                }
                                                tablas[key] = {
                                                    columns: [

                                                        {
                                                            label: 'PLU.',
                                                            field: 'plu',
                                                            sort: 'asc',
                                                            width: 150
                                                        }, {
                                                            label: 'PRODUCTO',
                                                            field: 'identificacion',
                                                            width: 150
                                                        }, {
                                                            label: 'PRECIO',
                                                            field: 'cant',
                                                            width: 150
                                                        }, {
                                                            label: 'CANTIDAD',
                                                            field: 'operacion',
                                                            width: 150
                                                        }, {
                                                            label: 'SUBTOTAL',
                                                            field: 'direccion',
                                                            width: 150
                                                        }, {
                                                            label: 'IVA',
                                                            field: 'accion',
                                                            width: 150
                                                        }, {
                                                            label: 'IMPOCONSUMO',
                                                            field: 'ipoconsumo',
                                                            width: 150
                                                        }, {
                                                            label: 'TOTAL',
                                                            field: 'ss',
                                                            aligned: 'center',
                                                            width: 150
                                                        }
                                                    ], rows:
                                                        detalles.map((venta) => {
                                                            excel[key].push({
                                                                '1': venta.plu,
                                                                '2': venta.descripcion,
                                                                '3': '$ ' + this.setearMiles(parseFloat(venta.precio).toFixed(3)),
                                                                '4': venta.cantidad,
                                                                '5': '$ ' + this.setearMiles(parseFloat(venta.base_gravada).toFixed(3)),
                                                                '7': venta.descripcion_iva != null ? venta.descripcion_iva : 'NO APLICA' + ' $' + (venta.impuesto_iva != null ? this.setearMiles(venta.impuesto_iva) : 0),
                                                                '8': '$ ' + this.setearMiles(parseFloat(venta.ipoconsumo).toFixed(3)),
                                                                '9': '$ ' + this.setearMiles(parseFloat(venta.ventas).toFixed(3))
                                                            })
                                                            return (
                                                                {
                                                                    'plu': venta.plu,
                                                                    'Fecha': venta.descripcion,
                                                                    'operacion': '$ ' + this.setearMiles(parseFloat(venta.precio).toFixed(3)),
                                                                    'tipo': venta.cantidad,
                                                                    'cantidad': '$ ' + this.setearMiles(parseFloat(venta.base_gravada).toFixed(3)),
                                                                    'costo': [<td>{venta.descripcion_iva != null ? venta.descripcion_iva : 'NO APLICA'}</td>, <td>{'$' + (venta.impuesto_iva != null ? this.setearMiles(venta.impuesto_iva) : 0)}</td>],
                                                                    'ipoconsumo': '$ ' + this.setearMiles(parseFloat(venta.ipoconsumo).toFixed(3)),
                                                                    'costo2': '$ ' + this.setearMiles(parseFloat(venta.ventas).toFixed(3))
                                                                }
                                                            )
                                                        })


                                                }
                                                excel[key].push({
                                                    "1": "",
                                                    "2": "",
                                                    "3": "",
                                                    "4": "",
                                                    "5": "",
                                                    "7": "",
                                                    "8": "",
                                                    "9": "",
                                                })
                                                excel[key].push({
                                                    "1": "VENTAS NO GRAVADAS",
                                                    "2": "VENTAS GRAVADAS",
                                                    "3": "TOTAL",
                                                    "4": "",
                                                    "5": "",
                                                    "7": "",
                                                    "8": "",
                                                    "9": "",
                                                })
                                                excel[key].push({
                                                    "1": "$" + this.setearMiles(tienda.ventas_no_gravadas),
                                                    "2": "$" + this.setearMiles(tienda.ventas_gravadas),
                                                    "3": "$" + this.setearMiles(tienda.ventas_total),
                                                    "4": "",
                                                    "5": "",
                                                    "7": "",
                                                    "8": "",
                                                    "9": "",
                                                })
                                                return (
                                                    this.state.valueZ === key
                                                        ?
                                                        <div>
                                                            <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                                                <ExcelFile
                                                                    filename={"VENTAS POR PLU " + moment(new Date()).format("DD/MM/YYYY")}
                                                                    element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button>}
                                                                >
                                                                    <ExcelSheet data={excel[this.state.valueZ]} name="VENTAS">
                                                                        <ExcelColumn label="" value="1" />
                                                                        <ExcelColumn label="" value="2" />
                                                                        <ExcelColumn label="" value="3" />
                                                                        <ExcelColumn label="" value="4" />
                                                                        <ExcelColumn label="" value="5" />
                                                                        <ExcelColumn label="" value="7" />
                                                                        <ExcelColumn label="" value="8" />
                                                                        <ExcelColumn label="" value="9" />
                                                                    </ExcelSheet>
                                                                </ExcelFile>

                                                            </div>

                                                            <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }} >
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numordencompra" className="col-form-label">NIT</label>
                                                                    <input id="numordencompra" disabled className="form-control" type="text" value={tienda.nit != null ? tienda.nit : '0'} />
                                                                </div>
                                                                <div className="col-md-5">
                                                                    <label htmlFor="numordencompra" className="col-form-label">RAZON SOCIAL</label>
                                                                    <input id="numordencompra" disabled className="form-control" type="text" value={tienda.razon_social != null ? tienda.razon_social : '0'} />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="numordencompra" className="col-form-label">ALIAS</label>
                                                                    <input id="numordencompra" disabled className="form-control" type="text" value={tienda.nombre != null ? tienda.nombre : '0'} />
                                                                </div>
                                                            </div>

                                                            <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                                                <div className="col col-md-12" >
                                                                    <Paper style={{ padding: '5px' }}>

                                                                        <MDBDataTable
                                                                            //striped
                                                                            className="col-md-12"
                                                                            searchLabel="Buscar"
                                                                            paginationLabel={["Anterior", "Siguente"]}
                                                                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                            entriesLabel="Filas a mostrar"
                                                                            tbodyColor="primary-color"
                                                                            theadColor="#FF8800"
                                                                            exportToCSV={true}
                                                                            striped
                                                                            bordered
                                                                            autoWidth={false}
                                                                            style={{ fontSize: '14px' }}
                                                                            responsive={true}
                                                                            hover
                                                                            data={tablas[this.state.valueZ]}
                                                                        />


                                                                    </Paper>

                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="numordencompra" className="col-form-label">VENTAS NO GRAVADAS</label>
                                                                    <input id="numordencompra" disabled className="form-control" type="text" value={tienda.ventas_no_gravadas != null ? '$ ' + this.setearMiles(parseFloat(tienda.ventas_no_gravadas).toFixed(3)) : '$ 0'} />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="numordencompra" className="col-form-label">VENTAS GRAVADAS</label>
                                                                    <input id="numordencompra" disabled className="form-control" type="text" value={tienda.ventas_gravadas != null ? '$ ' + this.setearMiles(parseFloat(tienda.ventas_gravadas).toFixed(3)) : '$ 0'} />
                                                                </div>
                                                                {/*<div className="col-md-3">
                                                            <label htmlFor="numordencompra" className="col-form-label">IMPUESTOS</label>
                                                            <input id="numordencompra" disabled className="form-control" type="text" value={tienda.ventas_gravadas_detalles != null ? '$ ' + this.setearMiles(parseFloat(tienda.ventas_gravadas_detalles.impuesto_total).toFixed(3)) : '$ 0'} />
                                                                                        </div>*/}
                                                                <div className="col-md-4">
                                                                    <label htmlFor="numordencompra" className="col-form-label">TOTAL</label>
                                                                    <input id="numordencompra" disabled className="form-control" type="text" value={tienda.ventas_total != null ? '$ ' + this.setearMiles(parseFloat(tienda.ventas_total).toFixed(3)) : '$ 0'} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                                                )

                                            })
                                            :
                                            <h5>No hay datos suficientes</h5>
                                        :
                                        console.log("eror en reportes " + reportes)
                            }
                            {this.state.valueVentas === 1
                                ?
                                ventaFecha != null
                                    ?
                                    <div>
                                        <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>

                                        </div>
                                        <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                            <Paper style={{ padding: '5px', width: '100%' }}>

                                                <MDBDataTable
                                                    //striped
                                                    className="col-md-12"
                                                    searchLabel="Buscar"
                                                    paginationLabel={["Anterior", "Siguente"]}
                                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                    entriesLabel="Filas a mostrar"
                                                    tbodyColor="primary-color"
                                                    theadColor="#FF8800"
                                                    exportToCSV={true}
                                                    striped
                                                    bordered
                                                    autoWidth={false}
                                                    responsive={true}
                                                    hover
                                                    order={['consecutivo', 'desc']}
                                                    data={
                                                        {
                                                            columns: ColumnasventasFecha,
                                                            rows: rowsVentasFechas[this.state.valueEmpresasFecha]
                                                        }
                                                    }
                                                />


                                            </Paper>
                                        </div>
                                    </div>
                                    :
                                    console.log("Venta por fecha es null")
                                :
                                this.state.valueVentas === 0
                                    ?
                                    ventaFecha != null
                                        ?
                                        <div>
                                            <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                                <Paper style={{ padding: '5px', width: '100%' }}>
                                                    <ExcelFile
                                                        filename={"VENTAS " + moment(new Date()).format("DD/MM/YYYY")}
                                                        element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button>}
                                                    >
                                                        <ExcelSheet data={excel[this.state.valueEmpresasFecha]} name="Z FINANCIERO">
                                                            <ExcelColumn label="" value="1" />
                                                            <ExcelColumn label="" value="2" />
                                                            <ExcelColumn label="" value="3" />
                                                            <ExcelColumn label="" value="4" />
                                                            <ExcelColumn label="" value="5" />
                                                            <ExcelColumn label="" value="6" />
                                                            <ExcelColumn label="" value="7" />
                                                            <ExcelColumn label="" value="8" />
                                                            <ExcelColumn label="" value="9" />
                                                            <ExcelColumn label="" value="10" />
                                                            <ExcelColumn label="" value="11" />
                                                            <ExcelColumn label="" value="12" />
                                                            <ExcelColumn label="" value="13" />
                                                        </ExcelSheet>
                                                    </ExcelFile>
                                                    <MDBDataTable
                                                        //striped
                                                        className="col-md-12"
                                                        searchLabel="Buscar"
                                                        paginationLabel={["Anterior", "Siguente"]}
                                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                        entriesLabel="Filas a mostrar"
                                                        tbodyColor="primary-color"
                                                        theadColor="#FF8800"
                                                        exportToCSV={true}
                                                        striped
                                                        bordered
                                                        autoWidth={false}
                                                        responsive={true}
                                                        hover
                                                        sorting={true}
                                                        order={['consecutivo', 'desc']}
                                                        data={
                                                            {
                                                                columns: ColumnasventasFechaTotales,
                                                                rows: rowsVentasFechas[this.state.valueEmpresasFecha]
                                                            }
                                                        }
                                                    />
                                                    <Dialog
                                                        open={this.state.dialogdetalles}
                                                        aria-labelledby="form-dialog-title"
                                                        maxWidth={'lg'}
                                                        fullWidth={true}
                                                        style={{ padding: '0' }}
                                                        disableBackdropClick={false}
                                                    >
                                                        <DialogContent style={{ padding: '0' }}>
                                                            <div className="row mx-auto" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                                <div className="col col-md-11 mx-auto" style={{ padding: 0 }}>
                                                                    <Paper style={{ padding: '10px' }}>
                                                                        <div className="row">
                                                                            <button className="btn btn-danger " onClick={() => this.cerrarVenta()} style={{ margin: '10px', float: 'right' }}>CERRAR</button>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-3">
                                                                                <label htmlFor="numordencompra" className="col-form-label">VENTA</label>
                                                                                <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? ventaSellecionada.consecutivo : '000'} className="form-control" />
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="numordencompra" className="col-form-label">FECHA</label>
                                                                                <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? moment(new Date(ventaSellecionada.fecha)).format("DD/MM/YYYY hh:mm a") : ''} className="form-control" />
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <label htmlFor="numordencompra" className="col-form-label">PROMOTOR</label>
                                                                                <input id="numordencompra" disabled={true} value={ventaSellecionada != null ? ventaSellecionada.nombres : ''} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                        <MDBDataTable
                                                                            //striped
                                                                            className="col-md-12 mx-auto"
                                                                            searchLabel="Buscar"
                                                                            paginationLabel={["Anterior", "Siguente"]}
                                                                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                            entriesLabel="Filas a mostrar"
                                                                            tbodyColor="primary-color"
                                                                            theadColor="#FF8800"
                                                                            exportToCSV={true}
                                                                            striped
                                                                            bordered
                                                                            autoWidth={false}
                                                                            responsive={true}
                                                                            hover
                                                                            order={['plu', 'asc']}
                                                                            data={
                                                                                {
                                                                                    columns: [
                                                                                        {
                                                                                            label: 'Plu',
                                                                                            field: 'plu',
                                                                                            sort: 'asc',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Descripcion',
                                                                                            field: 'descr',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Precio',
                                                                                            field: 'punitario',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Cant.',
                                                                                            field: 'cantidad',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Venta Neta',
                                                                                            field: 'sproducto',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'IVA',
                                                                                            field: 'subventa',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Valor agregado',
                                                                                            field: 'imventa',
                                                                                            width: 150
                                                                                        }, {
                                                                                            label: 'Total',
                                                                                            field: 'venta',
                                                                                            width: 150
                                                                                        }
                                                                                    ],
                                                                                    rows: ventaSellecionada != null ?
                                                                                        ventaSellecionada.ventas_detalles != null
                                                                                            ?
                                                                                            ventaSellecionada.ventas_detalles.map((detalle) => {
                                                                                                return ({
                                                                                                    'plu': detalle.plu,
                                                                                                    'producto': detalle.descripcion,
                                                                                                    'punitario': '$ ' + this.setearMiles(detalle.precio),
                                                                                                    'cantidad': detalle.cantidad,
                                                                                                    'subtotal': '$ ' + this.setearMiles(detalle.venta_neta),
                                                                                                    'iva': [<td>{detalle.descripcion_iva != null ? detalle.descripcion_iva : ''}</td>, <td>{'$' + (detalle.valor_iva != null ? this.setearMiles(detalle.valor_iva) : 0)}</td>],
                                                                                                    'ipoconsumo': '$' + (detalle.valor_no_gravable != null ? this.setearMiles(detalle.valor_no_gravable) : 0),
                                                                                                    'total': '$' + this.setearMiles(detalle.venta_bruta),
                                                                                                })
                                                                                            })
                                                                                            :
                                                                                            []
                                                                                        :
                                                                                        []
                                                                                }
                                                                            }
                                                                        />
                                                                    </Paper>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-auto">
                                                                <div className="col-md-8 bottom-aligned mx-auto"></div>

                                                            </div>
                                                        </DialogContent>
                                                    </Dialog>

                                                </Paper>
                                            </div>
                                            <div className="row" style={{ margin: '10px' }}>
                                                <div className="col-md-4">
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="numordencompra" className="col-form-label">Total impuestos</label>
                                                    <input id="numordencompra" disabled={true} value={'$' + this.setearMiles(totalImpuestos[this.state.valueEmpresasFecha])} className="form-control" />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="numordencompra" className="col-form-label">Total ventas</label>
                                                    <input id="numordencompra" disabled={true} value={'$' + this.setearMiles(totalVentas[this.state.valueEmpresasFecha])} className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        console.log("Venta por fecha es null")
                                    :
                                    null}

                            {/* REPORTE DE VENTAS CIERRE MENSUAL */}
                            {this.state.valueVentas === 4 ?
                                this.state.ventasCierreMensual.length > 0 ?
                                    <Paper style={{ padding: '5px', width: '100%' }}>
                                        <ExcelFile
                                            filename={"CIERRE DE VENTAS MENSUAL " + moment(new Date()).format("DD/MM/YYYY")}
                                            element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /> EXPORTAR VENTAS</button>}
                                        >
                                            <ExcelSheet data={this.state.ventasCierreMensual} name="CIERRE MENSUAL">
                                                <ExcelColumn label="KIOSCO" value="kiosco" />
                                                <ExcelColumn label="RAZON SOCIAL" value="razon_social" />
                                                <ExcelColumn label="CODIGO" value="codigo" />
                                                <ExcelColumn label="NIT" value="nit" />
                                                <ExcelColumn label="CANTIDAD VENTAS" value="cantidad_ventas" />
                                                <ExcelColumn label="VENTA TOTAL" value="venta_total" />
                                                <ExcelColumn label="VENTA NETA" value="base_grabavle" />
                                                <ExcelColumn label="VALOR AGREGADO" value="impoconsumo" />
                                                <ExcelColumn label="IVA" value="iva" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                        <MDBDataTable
                                            //striped
                                            className="col-md-12"
                                            searchLabel="Buscar"
                                            paginationLabel={["Anterior", "Siguente"]}
                                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                                            entriesLabel="Filas a mostrar"
                                            tbodyColor="primary-color"
                                            theadColor="#FF8800"
                                            exportToCSV={true}
                                            striped
                                            bordered
                                            autoWidth={false}
                                            responsive={true}
                                            hover
                                            order={['consecutivo', 'desc']}
                                            data={
                                                {
                                                    columns: [{
                                                        label: 'KIOSCO',
                                                        field: 'kiosco',
                                                        sort: 'asc',
                                                        width: 150
                                                    }, {
                                                        label: 'R. SOCIAL',
                                                        field: 'razon_social',
                                                        sort: 'asc',
                                                        width: 150
                                                    }, {
                                                        label: 'CODIGO',
                                                        field: 'codigo',
                                                        sort: 'asc',
                                                        width: 150
                                                    }, {
                                                        label: 'NIT',
                                                        field: 'nit',
                                                        sort: 'asc',
                                                        width: 150
                                                    }, {
                                                        label: 'CANTIDAD V.',
                                                        field: 'cantidad_ventas',
                                                        sort: 'asc',
                                                        width: 150
                                                    }, {
                                                        label: 'VENTA T.',
                                                        field: 'venta_total',
                                                        sort: 'asc',
                                                        width: 150
                                                    }, {
                                                        label: 'Venta Neta',
                                                        field: 'base_grabavle',
                                                        sort: 'asc',
                                                        width: 150
                                                    }, {
                                                        label: 'Valor agregado',
                                                        field: 'Valor Agregado',
                                                        sort: 'asc',
                                                        width: 150
                                                    }, {
                                                        label: 'IVA',
                                                        field: 'iva',
                                                        sort: 'asc',
                                                        width: 150
                                                    },],
                                                    rows: this.state.ventasCierreMensual
                                                }
                                            }
                                        />


                                    </Paper>
                                    : <div className="row">
                                        <h5 className="mx-auto">NO HAY DATOS PARA ESTE MES</h5>
                                    </div> : null
                            }

                        </div>
                )
                break;
            case 1:
                var excelKardex1 = []
                var kardexProducto = {
                    columns: [

                        {
                            label: 'Empresa',
                            field: 'celular',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Movimiento',
                            field: 'celular',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Fecha',
                            field: 'identificacion',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Operacion',
                            field: 'operacion',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Tipo',
                            field: 'direccion',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Cantidad',
                            field: 'cant',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Unidad',
                            field: 'unidad',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Costo del producto',
                            field: 'costo',
                            sort: 'asc',
                            aligned: 'center',
                            width: 150
                        }, {
                            label: 'Costo del movimiento',
                            field: 'costototal',
                            sort: 'asc',
                            aligned: 'center',
                            width: 150
                        }
                    ], rows:
                        this.state.kRDEXpRODUCTOS.map((producto, key) => {
                            console.log(producto)
                            excelKardex1.push({
                                'movimineto': producto.consecutivo,
                                'fecha': moment(new Date(producto.fecha)).format("DD/MM/YYYY hh:mm a"),
                                'bodega': this.state.bodegaKardexSeleccionada.label,
                                'producto': this.state.ProductoKardexSeleccionado.label,
                                'operacion': producto.operacion,
                                'tipo': producto.tipo_operacion,
                                'cantidad': producto.cantidad,
                                'unidad': producto.unidad_basica_desc,
                                'costo': '$' + this.setearMiles(parseInt(producto.costo_producto)),
                                'costoMov': '$' + this.setearMiles(parseInt(producto.costo_movimiento)),
                            })
                            return (
                                {

                                    'movimineto': producto.consecutivo,
                                    'Fecha': moment(new Date(producto.fecha)).format("DD/MM/YYYY hh:mm a"),
                                    'operacion': producto.operacion,
                                    'tipo': producto.tipo_operacion,
                                    'cantidad': producto.cantidad,
                                    'unidad': producto.unidad_basica_desc,
                                    'costo': '$' + this.setearMiles(parseInt(producto.costo_producto)),
                                    'costoMov': '$' + this.setearMiles(parseInt(producto.costo_movimiento)),


                                }
                            )
                        })
                }
                var kardexProductoBodega = {
                    columns: [

                        {
                            label: 'Movimiento',
                            field: 'celular',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Fecha',
                            field: 'identificacion',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Operacion',
                            field: 'operacion',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Tipo',
                            field: 'direccion',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Cantidad',
                            field: 'cant',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Unidad',
                            field: 'unidad',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Costo del producto',
                            field: 'costo',
                            sort: 'asc',
                            aligned: 'center',
                            width: 150
                        }, {
                            label: 'Costo del movimiento',
                            field: 'costototal',
                            sort: 'asc',
                            aligned: 'center',
                            width: 150
                        }
                    ], rows:
                        this.state.kRDEXpRODUCTOS.map((producto, key) => {
                            return (
                                {

                                    'movimineto': producto.consecutivo,
                                    'Fecha': moment(new Date(producto.fecha)).format("DD/MM/YYYY hh:mm a"),
                                    'operacion': producto.operacion,
                                    'tipo': producto.tipo_operacion,
                                    'cantidad': producto.cantidad,
                                    'unidad': producto.unidad_basica_desc,
                                    'costo': '$' + this.setearMiles(parseInt(producto.costo_producto)),
                                    'costoMov': '$' + this.setearMiles(parseInt(producto.costo_movimiento)),


                                }
                            )
                        })
                }
                var kardexProductoEmpresa = {
                    columns: [

                        {
                            label: 'Bodega',
                            field: 'celular',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Consecutivo',
                            field: 'celular',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Fecha',
                            field: 'movi',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Tipo',
                            field: 'identificacion',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Movimiento',
                            field: 'operacion',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Cantidad',
                            field: 'cant',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Costo del producto',
                            field: 'costo',
                            sort: 'asc',
                            aligned: 'center',
                            width: 150
                        }, {
                            label: 'Costo del movimiento',
                            field: 'costototal',
                            sort: 'asc',
                            aligned: 'center',
                            width: 150
                        }
                    ], rows: this.state.kRDEXpRODUCTOS
                }
                return (
                    <div>
                        <div className="row">
                            <Tabs
                                value={this.state.valueKardex}
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="disabled tabs example"
                            >
                                <Tab label="FILTROS" disabled />
                                <Tab label="Por bodega" onClick={() => this.cambiarTabs("valueKardex", 1)} />
                                {/*usuario.tipo == "3" ? <Tab label="Por tienda" onClick={() => this.tabFiltrosKardex(2)} /> : null*/}
                                {/*<Tab label="Por producto" onClick={() => this.tabFiltrosKardex(3)} />*/}
                            </Tabs>
                        </div>
                        {this.state.valueKardex === 1 ? <div className="row" style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }}>
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">BODEGA</label>
                                <Selects
                                    value={this.state.bodegaKardexSeleccionada}
                                    onChange={this.handleChanges('bodegaKardexSeleccionada')}
                                    options={this.state.bodegasbd}
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">PRODUCTO</label>
                                <Selects
                                    value={this.state.ProductoKardexSeleccionado}
                                    onChange={this.handleChanges('ProductoKardexSeleccionado')}
                                    options={this.state.ProductosDeBodegabd}
                                />
                            </div>
                            <div className="col-md-4  bottom-aligned">
                                <button className="btn btn-success btn-block" onClick={() => this.obtenerKardex()} disabled={JSON.stringify(this.state.ProductoKardexSeleccionado) === "[]"}>CONSULTAR</button>
                            </div>
                        </div> : null}
                        {this.state.valueKardex === 2 ? <div className="row" style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }}>
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">EMPRESA</label>
                                <Selects
                                    value={this.state.empresaSeleccionadaKardex}
                                    onChange={this.handleChanges('empresaSeleccionadaKardex')}
                                    options={this.state.empresasbd}
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">PRODUCTO</label>
                                <Selects
                                    value={this.state.ProductoKardexSeleccionadoTiendas}
                                    onChange={this.handleChanges('ProductoKardexSeleccionadoTiendas')}
                                    options={this.state.ProductosDeBodegabds}
                                />
                            </div>
                            <div className="col-md-4  bottom-aligned">
                                <button className="btn btn-success btn-block" onClick={() => this.obtenerKardex()}>CONSULTAR</button>
                            </div>
                        </div> : null}
                        {this.state.valueKardex === 3 ? <div className="row" style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }}>
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">BODEGA</label>
                                <Selects
                                    value={this.state.bodegaKardexSeleccionada}
                                    onChange={this.handleChanges('bodegaKardexSeleccionada')}
                                    options={this.state.bodegasbd}
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">PRODUCTO</label>
                                <Selects
                                    value={this.state.ProductoKardexSeleccionado}
                                    onChange={this.handleChanges('ProductoKardexSeleccionado')}
                                    options={this.state.ProductosDeBodegabd}
                                />
                            </div>
                            <div className="col-md-4  bottom-aligned">
                                <button className="btn btn-success btn-block" onClick={() => this.obtenerKardex()}>CONSULTAR</button>
                            </div>
                        </div> : null}
                        {this.state.ventanaKArdex && this.state.kRDEXpRODUCTOS.length > 0 ? <div className="row" style={{ marginTop: '10px' }}>
                            <div className="col col-md-12" >
                                <div className="row mx-auto" style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }}>
                                    {this.state.valueKardex === 1
                                        ?
                                        <ExcelFile
                                            filename={"Reporte de kardex " + + moment(new Date()).format("DD/MM/YYYY")}
                                            element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button>}
                                        >
                                            <ExcelSheet data={this.state.excelKardexBodega} name="Ventas">
                                                <ExcelColumn label="Movimiento" value="movimineto" />
                                                <ExcelColumn label="Fecha" value="fecha" />
                                                <ExcelColumn label="Bodega" value="bodega" />
                                                <ExcelColumn label="Producto" value="producto" />
                                                <ExcelColumn label="Operacion" value="operacion" />
                                                <ExcelColumn label="Tipo" value="tipo" />
                                                <ExcelColumn label="Cantidad" value="cantidad" />
                                                <ExcelColumn label="Unidad" value="unidad" />
                                                <ExcelColumn label="Costo del producto" value="costo" />
                                                <ExcelColumn label="Costo del movimiento" value="costoMov" />
                                            </ExcelSheet>
                                        </ExcelFile>

                                        :
                                        this.state.valueKardex === 2
                                            ?
                                            <ExcelFile
                                                filename={"Reporte de Kardex " + moment(new Date()).format("DD/MM/YYYY")}
                                                element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button>}
                                            >
                                                <ExcelSheet data={this.state.excelKardexBodega} name="Ventas">
                                                    <ExcelColumn label="Movimiento" value="movimineto" />
                                                    <ExcelColumn label="Fecha" value="fecha" />
                                                    <ExcelColumn label="Bodega" value="bodega" />
                                                    <ExcelColumn label="Producto" value="producto" />
                                                    <ExcelColumn label="Operacion" value="operacion" />
                                                    <ExcelColumn label="Tipo" value="tipo" />
                                                    <ExcelColumn label="Cantidad" value="cantidad" />
                                                    <ExcelColumn label="Unidad" value="unidad" />
                                                    <ExcelColumn label="Costo del producto" value="costo" />
                                                    <ExcelColumn label="Costo del movimiento" value="costoMov" />
                                                </ExcelSheet>
                                            </ExcelFile>
                                            :
                                            this.state.valueKardex === 3
                                                ?
                                                <ExcelFile
                                                    filename={"Reporte de kardex " + moment(new Date()).format("DD/MM/YYYY")}
                                                    element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button>}
                                                >
                                                    <ExcelSheet data={this.state.excelKardexBodega} name="Ventas">
                                                        <ExcelColumn label="Movimiento" value="movimineto" />
                                                        <ExcelColumn label="Fecha" value="fecha" />
                                                        <ExcelColumn label="Bodega" value="bodega" />
                                                        <ExcelColumn label="Producto" value="producto" />
                                                        <ExcelColumn label="Operacion" value="operacion" />
                                                        <ExcelColumn label="Tipo" value="tipo" />
                                                        <ExcelColumn label="Cantidad" value="cantidad" />
                                                        <ExcelColumn label="Unidad" value="unidad" />
                                                        <ExcelColumn label="Costo del producto" value="costo" />
                                                        <ExcelColumn label="Costo del movimiento" value="costoMov" />
                                                    </ExcelSheet>
                                                </ExcelFile>
                                                :
                                                null
                                    }
                                </div>
                                <div className="row mx-auto" style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }}>
                                    <div className="col col-md-6">
                                        <label htmlFor="numordencompra" className="col-form-label">{this.state.valueKardex == 1 ? 'BODEGA' : 'EMPRESA'}</label>
                                        <input id="numordencompra" disabled className="form-control" type="text" value={this.state.valueKardex == 1 ? this.state.bodegaKardexSeleccionada.label : this.state.empresaSeleccionadaKardex.label} />
                                    </div>
                                    <div className="col col-md-6">
                                        <label htmlFor="numordencompra" className="col-form-label">PRODUCTO</label>
                                        <input id="numordencompra" disabled className="form-control" type="text" value={this.state.ProductoKardexSeleccionado.label} />
                                    </div>
                                </div>
                                <Paper style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px', padding: '5px' }}>

                                    <MDBDataTable
                                        //striped
                                        className="col-md-12"
                                        searchLabel="Buscar"
                                        paginationLabel={["Anterior", "Siguente"]}
                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                        entriesLabel="Filas a mostrar"
                                        tbodyColor="primary-color"
                                        theadColor="#FF8800"
                                        exportToCSV={true}
                                        striped
                                        bordered
                                        autoWidth={false}
                                        responsive={true}
                                        hover
                                        data={this.state.valueKardex == 2 ? kardexProductoEmpresa : this.state.valueKardex == 3 ? kardexProducto : kardexProductoBodega}
                                    />


                                </Paper>
                                <div className="row mx-auto" style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }}>
                                    <div className="col col-md-4">
                                        <label htmlFor="numordencompra" className="col-form-label">saldo en bodega</label>
                                        <input id="numordencompra" disabled className="form-control" type="text" value={this.state.CANTIDADkaRDEX} />
                                    </div>
                                    <div className="col col-md-4">
                                        <label htmlFor="numordencompra" className="col-form-label">Costo unitario</label>
                                        <input id="numordencompra" disabled className="form-control" type="text" value={'$ ' + this.setearMiles(this.state.COSTOkaRDEX)} />
                                    </div>
                                    <div className="col col-md-4">
                                        <label htmlFor="numordencompra" className="col-form-label">Costo total en bodega</label>
                                        <input id="numordencompra" disabled className="form-control" type="text" value={'$ ' + (this.setearMiles(this.state.COSTObODEGAkaRDEX))} />
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                    </div >
                )
                break;
            case 2:
                var excel = []
                var Cierres = {
                    columns: [

                        {
                            label: 'Tienda',
                            field: 'celular',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Operador',
                            field: 'identificacion',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Identificacion',
                            field: 'cant',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Fecha Inicio',
                            field: 'operacion',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'Fecha Fin',
                            field: 'direccion',
                            sort: 'asc',
                            width: 150
                        }, {
                            label: 'V. Total',
                            field: 'costo',
                            sort: 'asc',
                            aligned: 'center',
                            width: 150
                        }, {
                            label: 'Acciones',
                            field: 'costo',
                            sort: 'asc',
                            aligned: 'center',
                            width: 150
                        }
                    ], rows:
                        this.state.cierresbd.map((producto, key) => {
                            excel.push({
                                //'id': producto.jornada_id,
                                'movimineto': producto.tienda,
                                'Fecha': producto.operador,
                                'operacion': producto.identificacion,
                                'tipo': moment(new Date(producto.fecha_inicio)).format("DD/MM/YYYY hh:mm a"),
                                'cantidad': moment(new Date(producto.fecha_fin)).format("DD/MM/YYYY hh:mm a"),
                                'costo': '$' + this.setearMiles(producto.venta_total),
                            })
                            return (
                                {

                                    'movimineto': producto.tienda,
                                    'Fecha': producto.operador,
                                    'operacion': producto.identificacion,
                                    'tipo': moment(new Date(producto.fecha_inicio)).format("DD/MM/YYYY hh:mm a"),
                                    'cantidad': moment(new Date(producto.fecha_fin)).format("DD/MM/YYYY hh:mm a"),
                                    'costo': '$' + this.setearMiles(producto.venta_total),
                                    'action': <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesCierre(producto.jornada_id)}><Ojoicon /></button>


                                }
                            )
                        })
                }
                var cierreDetalles = null
                if (this.state.detallesCierrre) {
                    cierreDetalles = this.state.detallesCierrre
                }
                return (
                    <div style={{ paddingRight: '25px', paddingLeft: '25px', paddingBottom: '25px' }} >
                        <div className="row">
                            <Tabs
                                value={this.state.valueCierres}
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="disabled tabs example"
                            >
                                <Tab label="FILTROS" disabled />
                                <Tab label="Por fecha" onClick={() => this.cambiarTabs("valueCierres", 1)} />
                                <Tab label="Por promotor" onClick={() => this.cambiarTabs("valueCierres", 2)} />
                            </Tabs>
                        </div>
                        {this.state.valueCierres == 1 ?
                            <div className="row">
                                <div className="col-md-4">
                                    <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                        <DatePicker
                                            format="yyyy-MM-dd"
                                            id="datetime"
                                            value={this.state.selectedDate}
                                            onChange={this.handleChangemoment}
                                            className="rounded form-control"
                                            disableFuture={true}
                                            style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                        <DatePicker
                                            format="yyyy-MM-dd"
                                            id="datetime"
                                            value={this.state.selectedDateFin}
                                            onChange={this.handleChangemomentFin}
                                            disableFuture={true}
                                            minDate={moment(new Date(this.state.selectedDate.toString())).format("YYYY-MM-DD HH:mm")}
                                            className="rounded form-control"
                                            style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-md-4  bottom-aligned">
                                    <button className="btn btn-success btn-block" onClick={() => this.consultaCierresFecha()}>CONSULTAR</button>
                                </div>
                            </div>
                            :
                            <div className="row">
                                {usuario.tipo == "3" ? <div className="col-md-4">
                                    <label htmlFor="numordencompra" className="col-form-label">TIENDA</label>
                                    <Selects
                                        value={this.state.empresaSeleccionadaCierre}
                                        onChange={this.handleChanges('empresaSeleccionadaCierre')}
                                        options={this.state.empresasbd}
                                    />
                                </div> : null}
                                <div className="col-md-4">
                                    <label htmlFor="numordencompra" className="col-form-label">PROMOTOR</label>
                                    <Selects
                                        value={this.state.empleadoCierreSeleccionado}
                                        onChange={this.handleChanges('empleadoCierreSeleccionado')}
                                        options={this.state.empleadoCierresbd}
                                    />
                                </div>
                                <div className="col-md-4  bottom-aligned">
                                    <button className="btn btn-success btn-block" onClick={() => this.consultaCierresUsuario()}>CONSULTAR</button>
                                </div>
                            </div>
                        }

                        <div className="row" style={{ marginTop: '10px' }}>
                            <div className="col col-md-12" >
                                {
                                    this.state.cierresbd.length > 0 ?
                                        <Paper style={{ padding: '5px' }}>

                                            {

                                                <ExcelFile
                                                    filename={"REPORTE DE CIERRES " + moment(new Date()).format("DD/MM/YYYY")}
                                                    element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button>}
                                                >
                                                    <ExcelSheet data={excel} name="CIERRES">
                                                        <ExcelColumn label="TIENDA" value="movimineto" />
                                                        <ExcelColumn label="OPERADOR" value="Fecha" />
                                                        <ExcelColumn label="IDENTIFICACION" value="operacion" />
                                                        <ExcelColumn label="FECHA INICIO" value="tipo" />
                                                        <ExcelColumn label="FECHA FIN" value="cantidad" />
                                                        <ExcelColumn label="VENTA TOTAL" value="costo" />
                                                    </ExcelSheet>
                                                </ExcelFile>


                                            }

                                            <MDBDataTable
                                                //striped
                                                className="col-md-12"
                                                searchLabel="Buscar"
                                                paginationLabel={["Anterior", "Siguente"]}
                                                infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                entriesLabel="Filas a mostrar"
                                                tbodyColor="primary-color"
                                                theadColor="#FF8800"
                                                exportToCSV={true}
                                                striped
                                                bordered
                                                autoWidth={false}
                                                responsive={true}
                                                hover
                                                data={Cierres}
                                            />


                                            <Dialog
                                                open={this.state.dialogoDetallesCierres}
                                                aria-labelledby="form-dialog-title"
                                                maxWidth={'md'}
                                                fullWidth={true}
                                                style={{ padding: '0' }}
                                                disableBackdropClick={false}
                                            >
                                                <DialogContent style={{ padding: '0' }}>
                                                    <div className="row mx-auto" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                        <div className="col col-md-11 mx-auto" style={{ padding: 0 }}>
                                                            <Paper style={{ padding: '10px' }}>
                                                                <div className="row">
                                                                    <button className="btn btn-danger " onClick={() => this.cerrarCierre()} style={{ margin: '10px', float: 'right' }}>CERRAR</button>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-3">
                                                                        <label htmlFor="numordencompra" className="col-form-label">FECHA INICIO</label>
                                                                        <input id="numordencompra" disabled={true} value={cierreDetalles != null ? moment(new Date(cierreDetalles.fecha_inicio)).format("DD/MM/YYYY hh:mm a") : ''} className="form-control" />
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <label htmlFor="numordencompra" className="col-form-label">FECHA FIN</label>
                                                                        <input id="numordencompra" disabled={true} value={cierreDetalles != null ? moment(new Date(cierreDetalles.fecha_fin)).format("DD/MM/YYYY hh:mm a") : ''} className="form-control" />
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <label htmlFor="numordencompra" className="col-form-label">PROMOTOR</label>
                                                                        <input id="numordencompra" disabled={true} value={cierreDetalles != null ? cierreDetalles.operador : ''} className="form-control" />
                                                                    </div>
                                                                </div>
                                                                <MDBDataTable
                                                                    //striped
                                                                    className="col-md-12 mx-auto"
                                                                    searchLabel="Buscar"
                                                                    paginationLabel={["Anterior", "Siguente"]}
                                                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                    entriesLabel="Filas a mostrar"
                                                                    tbodyColor="primary-color"
                                                                    theadColor="#FF8800"
                                                                    exportToCSV={true}
                                                                    striped
                                                                    bordered
                                                                    autoWidth={false}
                                                                    responsive={true}
                                                                    hover
                                                                    order={['consecutivo', 'desc']}
                                                                    data={
                                                                        {
                                                                            columns: [
                                                                                {
                                                                                    label: 'VENTA',
                                                                                    field: 'consecutivo',
                                                                                    sort: 'desc',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'FECHA',
                                                                                    field: 'fecha',
                                                                                    sort: 'desc',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'PLU',
                                                                                    field: 'celular',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'DESCRIPCION',
                                                                                    field: 'identificacion',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'PRECIO',
                                                                                    field: 'cant',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'CANTIDAD',
                                                                                    field: 'operacion',
                                                                                    width: 100
                                                                                }/*, {
                                                                            label: 'IMPUESTO',
                                                                            field: 'q9',
                                                                            width: 100
                                                                        }*/, {
                                                                                    label: 'VENTA NETA',
                                                                                    field: 'q8',
                                                                                    width: 100
                                                                                }/*, {
                                                                            label: 'IMPUESTOS T.',
                                                                            field: 'q88',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'VENTA TOTAL',
                                                                            field: 'q8',
                                                                            width: 100
                                                                        }*/
                                                                            ],
                                                                            rows: cierreDetalles != null ?
                                                                                cierreDetalles.detalles != null
                                                                                    ?
                                                                                    cierreDetalles.detalles.map((detalle) => {
                                                                                        return ({
                                                                                            'consecutivo': detalle.consecutivo,
                                                                                            'fecha': moment(new Date(detalle.fecha_movimiento)).format("DD/MM/YYYY hh:mm a"),
                                                                                            'plu': /*detalle.plu*/'000',
                                                                                            'producto': detalle.producto,
                                                                                            'punitario': '$ ' + this.setearMiles(detalle.ppu),
                                                                                            'cantidad': detalle.cantidad,
                                                                                            'stotal': '$ ' + this.setearMiles(parseFloat(parseFloat(detalle.ppu) * parseInt(detalle.cantidad))),
                                                                                            /*'subtotal': '$ ' + this.setearMiles(detalle.venta_neta),
                                                                                            'iva': [<td>{detalle.descripcion_iva != null ? detalle.descripcion_iva : ''}</td>, <td>{'$' + (detalle.valor_iva != null ? this.setearMiles(detalle.valor_iva) : 0)}</td>],
                                                                                            'ipoconsumo': '$' + (detalle.valor_no_gravable != null ? this.setearMiles(detalle.valor_no_gravable) : 0),
                                                                                            'total': '$' + this.setearMiles(detalle.venta_bruta),*/
                                                                                        })
                                                                                    })
                                                                                    :
                                                                                    []
                                                                                :
                                                                                []
                                                                        }
                                                                    }
                                                                />
                                                            </Paper>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-auto">
                                                        <div className="col-md-8 bottom-aligned mx-auto"></div>

                                                    </div>
                                                </DialogContent>
                                            </Dialog>



                                        </Paper>

                                        :
                                        null}
                            </div>
                        </div>
                    </div >
                )
                break;
            case 3:
                var reportes = this.state.reporteZ[0]
                var tablas = []
                var excel = []
                return (
                    this.state.vistaCargando ?
                        <Loader />
                        :
                        <div style={{ paddingRight: '25px', paddingLeft: '25px', paddingBottom: '25px' }} >
                            {usuario.tipo === "5"
                                ?
                                <div className="row">
                                    <div className="col-md-4">
                                        <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DatePicker
                                                format="yyyy-MM-dd"
                                                id="datetime"
                                                value={this.state.selectedDatez}
                                                onChange={this.handleChangemomentZ}
                                                className="rounded form-control"
                                                disableFuture={true}
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DatePicker
                                                format="yyyy-MM-dd"
                                                id="datetime"
                                                value={this.state.selectedDateFinz}
                                                minDate={moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD HH:mm")}
                                                onChange={this.handleChangemomentFinZ}
                                                disableFuture={true}

                                                className="rounded form-control"
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-md-4  bottom-aligned">
                                        <button className="btn btn-success btn-block" onClick={() => this.consultaZFecha()}>CONSULTAR</button>
                                    </div>
                                </div>
                                :
                                <div className="row">
                                    <div className="col-md-2">
                                        <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DatePicker
                                                format="yyyy-MM-dd"
                                                id="datetime"
                                                value={this.state.selectedDatez}
                                                onChange={this.handleChangemomentZ}
                                                className="rounded form-control"
                                                disableFuture={true}
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>

                                    <div className="col-md-2">
                                        <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DatePicker
                                                format="yyyy-MM-dd"
                                                id="datetime"
                                                value={this.state.selectedDateFinz}
                                                onChange={this.handleChangemomentFinZ}
                                                minDate={moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD HH:mm")}
                                                className="rounded form-control"
                                                disableFuture={true}
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-md-5">
                                        <label htmlFor="numordencompra" className="col-form-label">EMPRESA</label>
                                        <Selects
                                            value={this.state.multiEmpresasZ}
                                            onChange={this.handleChanges('multiEmpresasZ')}
                                            options={this.state.empresasbd}
                                            isMulti
                                        />
                                    </div>
                                    <div className="col-md-3  bottom-aligned">
                                        <button className="btn btn-success btn-block" disabled={JSON.stringify(this.state.multiEmpresasZ) === "[]"} onClick={() => this.consultaZFecha()}>CONSULTAR</button>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <Tabs
                                    value={this.state.valueZ}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="disabled tabs example"
                                >


                                    {
                                        reportes != null
                                            ?
                                            reportes.ventas_total != null
                                                ?
                                                reportes.ventas_total.map((tienda, key) => {
                                                    console.log(tienda)
                                                    excel[key] = []
                                                    excel[key].push({
                                                        "1": tienda.razon_social,
                                                        "2": "",
                                                        "3": "NIT. " + tienda.nit,
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                    })
                                                    excel[key].push({
                                                        "1": "",
                                                        "2": "",
                                                        "3": "",
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                    })
                                                    excel[key].push({
                                                        "3": "",
                                                        "1": "METRICA",
                                                        "2": "VALOR",
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                    })
                                                    excel[key].push({
                                                        "3": "",
                                                        "1": "PLUS VENDIDOS",
                                                        "2": tienda.metricas.plu_vendidos,
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                    })
                                                    excel[key].push({
                                                        "3": "",
                                                        "1": "UNIDADES VENDIDAS",
                                                        "2": tienda.metricas.unidades_vendidas,
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                    })
                                                    excel[key].push({
                                                        "3": "",
                                                        "1": "VENTAS REALIZADAS",
                                                        "2": tienda.metricas.ventas_realizadas,
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                    })
                                                    excel[key].push({
                                                        "1": "",
                                                        "2": "",
                                                        "3": "",
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                    })
                                                    excel[key].push({
                                                        "3": "",
                                                        "1": "MEDIOS DE PAGO",
                                                        "2": "VALOR",
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                    })
                                                    tienda.medios_pagos.map((medio) => {
                                                        excel[key].push({
                                                            "3": "",
                                                            "1": medio.descripcion,
                                                            "2": "$" + this.setearMiles(medio.pagos_valor),
                                                            "4": "",
                                                            "5": "",
                                                            "6": "",
                                                            "7": "",
                                                            "8": "",
                                                            "9": "",
                                                        })
                                                    })
                                                    excel[key].push({
                                                        "1": "",
                                                        "2": "",
                                                        "3": "",
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                    })
                                                    excel[key].push({
                                                        "5": "",
                                                        "1": "BASE GRAVADA",
                                                        "2": "DESCRIPCION IVA",
                                                        "3": "IVA",
                                                        "4": "IMPOCONSUMO",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                    })
                                                    tienda.ventas_gravadas_detalles.map((medio) => {
                                                        excel[key].push({
                                                            "5": "",
                                                            "1": "$" + this.setearMiles(medio.base_gravada),
                                                            "2": medio.descripcion_iva,
                                                            "3": "$" + this.setearMiles(medio.impuesto_valor),
                                                            "4": "$" + this.setearMiles(medio.ipocunsumo),
                                                            "6": "",
                                                            "7": "",
                                                            "8": "",
                                                            "9": "",
                                                        })
                                                    })
                                                    excel[key].push({
                                                        "1": "",
                                                        "2": "",
                                                        "3": "",
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                    })
                                                    excel[key].push({
                                                        "3": "",
                                                        "1": "IMPOCONSUMO",
                                                        "2": "VALOR",
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                    })
                                                    tienda.ipoconsumo_detalles != null ?
                                                        tienda.ipoconsumo_detalles.map((medio) => {
                                                            excel[key].push({
                                                                "3": "",
                                                                "1": medio.descripcion,
                                                                "2": "$" + this.setearMiles(medio.impuesto_valor),
                                                                "4": "",
                                                                "5": "",
                                                                "6": "",
                                                                "7": "",
                                                                "8": "",
                                                                "9": "",
                                                            })
                                                        })
                                                        :
                                                        console.log("yeah")
                                                    excel[key].push({
                                                        "1": "",
                                                        "2": "",
                                                        "3": "",
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                    })
                                                    excel[key].push({
                                                        "1": "PLU",
                                                        "2": "PRODUCTO",
                                                        "3": "PRECIO",
                                                        "4": "CANTIDAD",
                                                        "5": "SUBTOTAL",
                                                        "6": "GRAVADA",
                                                        "7": "IVA",
                                                        "8": "IMPOCONSUMO",
                                                        "9": "TOTAL",
                                                    })
                                                    console.log(excel[key])
                                                    return (
                                                        <Tab label={tienda.razon_social} onClick={() => this.cambiarTabs("valueZ", key)} />
                                                    )
                                                })
                                                :
                                                console.log("eror en ventas por dia   " + reportes.ventas_total)
                                            :
                                            console.log("eror en reportes " + reportes)

                                    }
                                </Tabs>
                            </div>
                            {
                                reportes != null
                                    ?
                                    reportes.ventas_total != null
                                        ?
                                        reportes.ventas_total.map((tienda, key) => {
                                            var detalles = []
                                            if (tienda != null) {
                                                if (tienda.ventas_detalle_gravada != null) {
                                                    tienda.ventas_detalle_gravada.map((venta, key) => {
                                                        detalles.push(venta)
                                                    })
                                                }
                                                if (tienda.ventas_detalle_no_gravada) {
                                                    tienda.ventas_detalle_no_gravada.map((venta, key) => {
                                                        detalles.push(venta)
                                                    })
                                                }
                                            }
                                            tablas[key] = {
                                                columns: [

                                                    {
                                                        label: 'PLU.',
                                                        field: 'plu',
                                                        sort: 'asc',
                                                        width: 150
                                                    }, {
                                                        label: 'PRODUCTO',
                                                        field: 'identificacion',
                                                        width: 150
                                                    }, {
                                                        label: 'PRECIO',
                                                        field: 'cant',
                                                        width: 150
                                                    }, {
                                                        label: 'CANTIDAD',
                                                        field: 'operacion',
                                                        width: 150
                                                    }, {
                                                        label: 'SUBTOTAL',
                                                        field: 'direccion',
                                                        width: 150
                                                    }, {
                                                        label: 'GRAVADA',
                                                        field: 'costo',
                                                        width: 150
                                                    }, {
                                                        label: 'IVA',
                                                        field: 'accion',
                                                        width: 150
                                                    }, {
                                                        label: 'IMPOCONSUMO',
                                                        field: 'ipoconsumo',
                                                        width: 150
                                                    }, {
                                                        label: 'TOTAL',
                                                        field: 'ss',
                                                        aligned: 'center',
                                                        width: 150
                                                    }
                                                ], rows:
                                                    detalles.map((venta) => {
                                                        excel[key].push({
                                                            '1': venta.plu,
                                                            '2': venta.descripcion,
                                                            '3': '$ ' + this.setearMiles(parseFloat(venta.precio).toFixed(3)),
                                                            '4': venta.cantidad,
                                                            '5': '$ ' + this.setearMiles(parseFloat(venta.base_gravada).toFixed(3)),
                                                            '6': parseInt(venta.impuesto) <= 0 ? 'NO' : 'SI',
                                                            '7': venta.descripcion_iva != null ? venta.descripcion_iva : 'NO APLICA' + ' $' + (venta.impuesto_iva != null ? this.setearMiles(venta.impuesto_iva) : 0),
                                                            '8': '$ ' + this.setearMiles(parseFloat(venta.ipoconsumo).toFixed(3)),
                                                            '9': '$ ' + this.setearMiles(parseFloat(venta.ventas).toFixed(3))
                                                        })
                                                        return (
                                                            {
                                                                'plu': venta.plu,
                                                                'Fecha': venta.descripcion,
                                                                'operacion': '$ ' + this.setearMiles(parseFloat(venta.precio).toFixed(3)),
                                                                'tipo': venta.cantidad,
                                                                'cantidad': '$ ' + this.setearMiles(parseFloat(venta.base_gravada).toFixed(3)),
                                                                'tipo2': parseInt(venta.impuesto) <= 0 ? 'NO' : 'SI',
                                                                'costo': [<td>{venta.descripcion_iva != null ? venta.descripcion_iva : 'NO APLICA'}</td>, <td>{'$' + (venta.impuesto_iva != null ? this.setearMiles(venta.impuesto_iva) : 0)}</td>],
                                                                'ipoconsumo': '$ ' + this.setearMiles(parseFloat(venta.ipoconsumo).toFixed(3)),
                                                                'costo2': '$ ' + this.setearMiles(parseFloat(venta.ventas).toFixed(3))
                                                            }
                                                        )
                                                    })


                                            }
                                            excel[key].push({
                                                "1": "",
                                                "2": "",
                                                "3": "",
                                                "4": "",
                                                "5": "",
                                                "6": "",
                                                "7": "",
                                                "8": "",
                                                "9": "",
                                            })
                                            excel[key].push({
                                                "1": "VENTAS NO GRAVADAS",
                                                "2": "VENTAS GRAVADAS",
                                                "3": "TOTAL",
                                                "4": "",
                                                "5": "",
                                                "6": "",
                                                "7": "",
                                                "8": "",
                                                "9": "",
                                            })
                                            excel[key].push({
                                                "1": "$" + this.setearMiles(tienda.ventas_no_gravadas),
                                                "2": "$" + this.setearMiles(tienda.ventas_gravadas),
                                                "3": "$" + this.setearMiles(tienda.ventas_total),
                                                "4": "",
                                                "5": "",
                                                "6": "",
                                                "7": "",
                                                "8": "",
                                                "9": "",
                                            })
                                            return (
                                                this.state.valueZ === key
                                                    ?
                                                    <div>
                                                        <div className="row" style={{ marginTop: '10px' }}>
                                                            <ExcelFile
                                                                filename={"REPORTE DE Z FINANCIERO " + moment(new Date()).format("DD/MM/YYYY")}
                                                                element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button>}
                                                            >
                                                                <ExcelSheet data={excel[this.state.valueZ]} name="Z FINANCIERO">
                                                                    <ExcelColumn label="" value="1" />
                                                                    <ExcelColumn label="" value="2" />
                                                                    <ExcelColumn label="" value="3" />
                                                                    <ExcelColumn label="" value="4" />
                                                                    <ExcelColumn label="" value="5" />
                                                                    <ExcelColumn label="" value="6" />
                                                                    <ExcelColumn label="" value="7" />
                                                                    <ExcelColumn label="" value="8" />
                                                                    <ExcelColumn label="" value="9" />
                                                                </ExcelSheet>
                                                            </ExcelFile>

                                                        </div>

                                                        <div className="row" >
                                                            <div className="col-md-4">
                                                                <label htmlFor="numordencompra" className="col-form-label">NIT</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={tienda.nit != null ? tienda.nit : '0'} />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <label htmlFor="numordencompra" className="col-form-label">RAZON SOCIAL</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={tienda.razon_social != null ? tienda.razon_social : '0'} />
                                                            </div>
                                                        </div>
                                                        <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                                            <div className="col-md-5 mx-auto" >
                                                                <Table striped bordered hover size="sm">
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan="2" align="center">METRICAS</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <thead>
                                                                        <tr>
                                                                            <th align="center">DESCRIPCION</th>
                                                                            <th align="middle"> VALOR</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td align="right">{'PLUS VENDIDOS'}</td>
                                                                            <td align="right">{tienda.metricas != null ? tienda.metricas.plu_vendidos : ''}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="right">{'UNIDADES VENDIDAS'}</td>
                                                                            <td align="right">{tienda.metricas != null ? tienda.metricas.unidades_vendidas : ''}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="right">{'VENTAS REALIZADAS'}</td>
                                                                            <td align="right">{tienda.metricas != null ? tienda.metricas.ventas_realizadas : ''}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                            <div className="col-md-5 mx-auto" >
                                                                <Table striped bordered hover size="sm">
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan="2" align="center">MEDIOS DE PAGO</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <thead>
                                                                        <tr>
                                                                            <th align="center">DESCRIPCION</th>
                                                                            <th align="middle"> VALOR</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            tienda.medios_pagos != null ?
                                                                                tienda.medios_pagos.map((medio) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td align="right">{medio.descripcion}</td>
                                                                                            <td align="right">{'$' + this.setearMiles(parseFloat(medio.pagos_valor).toFixed(3))}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                                :
                                                                                null

                                                                        }

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                        <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                                            <div className="col-md-5 mx-auto" >
                                                                <Table striped bordered hover size="sm">
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan="4" align="center">IMPUESTOS</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <thead>
                                                                        <tr>
                                                                            <th align="center">BASE GRAVADA</th>
                                                                            <th align="center">DESCRIPCION</th>
                                                                            <th align="center">IVA</th>
                                                                            <th align="middle">IMPOCONSUMO</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            tienda.ventas_gravadas_detalles != null ?
                                                                                tienda.ventas_gravadas_detalles.map((impuesto) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td align="right">{'$' + this.setearMiles(parseFloat(impuesto.base_gravada).toFixed(3))}</td>
                                                                                            <td align="right">{impuesto.descripcion_iva}</td>
                                                                                            <td align="right">{'$' + this.setearMiles(parseFloat(impuesto.impuesto_valor).toFixed(3))}</td>
                                                                                            <td align="right">{'$' + this.setearMiles(parseFloat(impuesto.ipocunsumo).toFixed(3))}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })

                                                                                :
                                                                                null
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                            <div className="col-md-6 mx-auto" >
                                                                <Table striped bordered hover size="sm">
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan="2" align="center">IMPOCONSUMO</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <thead>
                                                                        <tr>
                                                                            <th align="center">DESCRIPCION</th>
                                                                            <th align="middle"> VALOR</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            tienda.ipoconsumo_detalles != null ?
                                                                                tienda.ipoconsumo_detalles.map((impuesto) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td align="right">{impuesto.descripcion}</td>
                                                                                            <td align="right">{'$' + this.setearMiles(parseFloat(impuesto.impuesto_valor).toFixed(3))}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })

                                                                                :
                                                                                null
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: '10px' }}>
                                                            <div className="col col-md-12" >
                                                                <Paper style={{ padding: '5px' }}>

                                                                    <MDBDataTable
                                                                        //striped
                                                                        className="col-md-12"
                                                                        searchLabel="Buscar"
                                                                        paginationLabel={["Anterior", "Siguente"]}
                                                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                        entriesLabel="Filas a mostrar"
                                                                        tbodyColor="primary-color"
                                                                        theadColor="#FF8800"
                                                                        exportToCSV={true}
                                                                        striped
                                                                        bordered
                                                                        autoWidth={false}
                                                                        style={{ fontSize: '14px' }}
                                                                        responsive={true}
                                                                        hover
                                                                        data={tablas[this.state.valueZ]}
                                                                    />

                                                                    <Dialog
                                                                        open={this.state.dialogdetallesZ}
                                                                        onClose={this.cerrarDetallesVentaZ}
                                                                        aria-labelledby="form-dialog-title"
                                                                        maxWidth={'md'}
                                                                        fullWidth={true}
                                                                        disableBackdropClick={true}
                                                                    >
                                                                        {this.state.ventaZSeleccionada != null ? <DialogTitle id="form-dialog-title">
                                                                            <div className="row mx-auto">Detalles de la venta {this.state.ventaZSeleccionada.consecutivo}</div>
                                                                        </DialogTitle> : null}
                                                                        {this.state.ventaZSeleccionada != null ? <DialogContent >
                                                                            <div className="row mx-auto">
                                                                                <div className="col col-md-6">
                                                                                    <label htmlFor="numordencompra" className="col-form-label">Vendedor/Promotor</label>
                                                                                    <input id="numordencompra" disabled className="form-control" type="text" value={this.state.ventaZSeleccionada.nombres + " " + this.state.ventaZSeleccionada.apellidos} />
                                                                                </div>
                                                                            </div>


                                                                            {this.state.ventaZSeleccionada.venta_detalle != null ? <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                                                                <div className="col col-md-12">
                                                                                    <MDBTable bordered striped>
                                                                                        <MDBTableHead color="default-color" textWhite style={{ backgroundColor: "#4285F4" }}>
                                                                                            <tr>
                                                                                                <th>PLU</th>
                                                                                                <th>Producto</th>
                                                                                                <th >Precio</th>
                                                                                                <th >Cantidad</th>
                                                                                                <th >Total producto</th>
                                                                                            </tr>
                                                                                        </MDBTableHead>
                                                                                        <MDBTableBody>
                                                                                            {this.state.ventaZSeleccionada.venta_detalle.map((row, key) => (
                                                                                                <tr key={key}>
                                                                                                    <td component="th" scope="row">
                                                                                                        {row.plu}
                                                                                                    </td>
                                                                                                    <td>{row.descripcion}</td>
                                                                                                    <td>{"$" + this.setearMiles(parseInt(row.precio))}</td>
                                                                                                    <td>{row.cantidad}</td>
                                                                                                    <td>{"$" + this.setearMiles(parseInt(row.precio * row.cantidad))}</td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </MDBTableBody>
                                                                                    </MDBTable>
                                                                                </div>
                                                                            </div> : null}
                                                                        </DialogContent> : null}
                                                                        <DialogActions>
                                                                            <button className="btn btn-danger" onClick={this.cerrarDetallesVentaZ.bind(this)}>Cerrar</button>
                                                                        </DialogActions>
                                                                    </Dialog>
                                                                </Paper>

                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: '10px' }}>
                                                            <div className="col-md-4">
                                                                <label htmlFor="numordencompra" className="col-form-label">VENTAS NO GRAVADAS</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={tienda.ventas_no_gravadas != null ? '$ ' + this.setearMiles(parseFloat(tienda.ventas_no_gravadas).toFixed(3)) : '$ 0'} />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="numordencompra" className="col-form-label">VENTAS GRAVADAS</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={tienda.ventas_gravadas != null ? '$ ' + this.setearMiles(parseFloat(tienda.ventas_gravadas).toFixed(3)) : '$ 0'} />
                                                            </div>
                                                            {/*<div className="col-md-3">
                                                            <label htmlFor="numordencompra" className="col-form-label">IMPUESTOS</label>
                                                            <input id="numordencompra" disabled className="form-control" type="text" value={tienda.ventas_gravadas_detalles != null ? '$ ' + this.setearMiles(parseFloat(tienda.ventas_gravadas_detalles.impuesto_total).toFixed(3)) : '$ 0'} />
                                                                                        </div>*/}
                                                            <div className="col-md-4">
                                                                <label htmlFor="numordencompra" className="col-form-label">TOTAL</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={tienda.ventas_total != null ? '$ ' + this.setearMiles(parseFloat(tienda.ventas_total).toFixed(3)) : '$ 0'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            )

                                        })
                                        :
                                        <h5>No hay datos suficientes</h5>
                                    :
                                    console.log("eror en reportes " + reportes)

                            }


                        </div >
                )
                break;
            case 4:
                var utilidad = this.state.utilidadbd[0]
                var tablas = []
                var excelVentas = []
                var excelCategoria = []
                var excelProducto = []
                var empresas = []
                var numCategorias = 0
                var detalles = []
                var detallesXVenta = null
                var detallesXCategoria = null
                const excellAllCategoriasAllEmpresas = []
                let totalizadoresUtilidad = {
                    venta_neta: 0,
                    impuesto_total: 0,
                    ipoconsumo: 0,
                    venta_total: 0,
                    costo_total: 0,
                }
                excellAllCategoriasAllEmpresas.push({
                    "1": "ESTACION",
                    "2": "NIT",
                    "3": "ALIAS",
                    "4": "DESCRIPCION",
                    "5": "CATEGORIA PADRE",
                    "6": "COSTO",
                    "7": "VENTA NETA",
                    "8": "IVA",
                    "9": "IMPOCONSUMO",
                    "10": "VENTA TOTAL",
                    "11": "UTILIDAD (%)L",
                    "12": "UTILIDAD ($)",
                    "13": "RENTABILIDAD ($)",
                })
                if (this.state.detallesUtilidadXVentas) {
                    detallesXVenta = this.state.detallesUtilidadXVentas
                }
                if (this.state.detallesUtilidadXCategorias) {
                    detallesXCategoria = this.state.detallesUtilidadXCategorias
                }
                return (
                    this.state.vistaCargando ?
                        <Loader />
                        :
                        <div style={{ paddingRight: '25px', paddingLeft: '25px', paddingBottom: '25px' }} >
                            <div className="row">
                                <Tabs
                                    value={this.state.valueUtilidad}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="disabled tabs example"
                                    style={{ margin: '0px 15px' }}
                                >
                                    <Tab label="UTILIDAD POR VENTAS" onClick={() => this.cambiarTabs("valueUtilidad", 0)} />
                                    <Tab label="UTILIDAD POR CATEGORIA" onClick={() => this.cambiarTabs("valueUtilidad", 1)} />
                                    <Tab label="UTILIDAD POR PRODUCTO" onClick={() => this.cambiarTabs("valueUtilidad", 2)} />
                                    {/*<Tab label="Por producto" onClick={() => this.tabFiltrosKardex(3)} />*/}
                                </Tabs>
                            </div>
                            {
                                this.state.valueUtilidad === 0 ?
                                    usuario.tipo === "5" ?
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                    <DatePicker
                                                        format="yyyy-MM-dd"
                                                        id="datetime"
                                                        value={this.state.selectedDatez}
                                                        onChange={this.handleChangemomentZ}
                                                        className="rounded form-control"
                                                        disableFuture={true}
                                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>

                                            <div className="col-md-4">
                                                <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                    <DatePicker
                                                        format="yyyy-MM-dd"
                                                        id="datetime"
                                                        value={this.state.selectedDateFinz}
                                                        onChange={this.handleChangemomentFinZ}
                                                        minDate={moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD HH:mm")}
                                                        className="rounded form-control"
                                                        disableFuture={true}
                                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="col-md-4  bottom-aligned">
                                                <button className="btn btn-success btn-block" onClick={() => this.consultaUtilidad()}>CONSULTAR</button>
                                            </div>
                                        </div>
                                        :

                                        <div className="row">
                                            <div className="col-md-2">
                                                <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                    <DatePicker
                                                        format="yyyy-MM-dd"
                                                        id="datetime"
                                                        value={this.state.selectedDatez}
                                                        onChange={this.handleChangemomentZ}
                                                        className="rounded form-control"
                                                        disableFuture={true}
                                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>

                                            <div className="col-md-2">
                                                <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                    <DatePicker
                                                        format="yyyy-MM-dd"
                                                        id="datetime"
                                                        value={this.state.selectedDateFinz}
                                                        onChange={this.handleChangemomentFinZ}
                                                        minDate={moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD HH:mm")}
                                                        className="rounded form-control"
                                                        disableFuture={true}
                                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="col-md-5">
                                                <label htmlFor="numordencompra" className="col-form-label">EMPRESA</label>
                                                <Selects
                                                    value={this.state.multiEmpresasVentasSeleccionadas}
                                                    onChange={this.handleChanges('multiEmpresasVentasSeleccionadas')}
                                                    options={this.state.empresasbd}
                                                    isMulti
                                                />
                                            </div>
                                            <div className="col-md-3  bottom-aligned">
                                                <button className="btn btn-success btn-block" onClick={() => this.consultaUtilidad()}>CONSULTAR</button>
                                            </div>
                                        </div>
                                    :
                                    this.state.valueUtilidad === 1 ?

                                        <div className="row">
                                            <div className="col-md-2">
                                                <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                    <DatePicker
                                                        format="yyyy-MM-dd"
                                                        id="datetime"
                                                        value={this.state.selectedDatez}
                                                        onChange={this.handleChangemomentZ}
                                                        className="rounded form-control"
                                                        disableFuture={true}
                                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>

                                            <div className="col-md-2">
                                                <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                    <DatePicker
                                                        format="yyyy-MM-dd"
                                                        id="datetime"
                                                        value={this.state.selectedDateFinz}
                                                        onChange={this.handleChangemomentFinZ}
                                                        minDate={moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD HH:mm")}
                                                        className="rounded form-control"
                                                        disableFuture={true}
                                                        style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="col-md-8">
                                                <label htmlFor="numordencompra" className="col-form-label">Categorias</label>
                                                <Selects
                                                    value={this.state.multiCategoriasSeleccionadas}
                                                    onChange={this.handleChanges('multiCategoriasSeleccionadas')}
                                                    options={this.state.multiCategorias}
                                                    isMulti
                                                />
                                            </div>
                                            <div className="col-md-7">
                                                <label htmlFor="numordencompra" className="col-form-label">EMPRESA</label>
                                                <Selects
                                                    value={this.state.multiEmpresasVentasSeleccionadas}
                                                    onChange={this.handleChanges('multiEmpresasVentasSeleccionadas')}
                                                    options={this.state.empresasbd}
                                                    isMulti
                                                />
                                            </div>
                                            <div className="col-md-3  bottom-aligned">
                                                <button className="btn btn-success btn-block" onClick={() => this.consultaUtilidad(1)}>CONSULTA AVANZADA</button>
                                            </div>
                                            <div className="col-md-2  bottom-aligned">
                                                <button className="btn btn-success btn-block" onClick={() => this.consultaUtilidad(0)}>CONSULTAR</button>
                                            </div>
                                        </div>
                                        : this.state.valueUtilidad === 2 ?
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                        <DatePicker
                                                            format="yyyy-MM-dd"
                                                            id="datetime"
                                                            value={this.state.selectedDatez}
                                                            onChange={this.handleChangemomentZ}
                                                            className="rounded form-control"
                                                            disableFuture={true}
                                                            style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>

                                                <div className="col-md-3">
                                                    <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                        <DatePicker
                                                            format="yyyy-MM-dd"
                                                            id="datetime"
                                                            value={this.state.selectedDateFinz}
                                                            onChange={this.handleChangemomentFinZ}
                                                            minDate={moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD HH:mm")}
                                                            className="rounded form-control"
                                                            disableFuture={true}
                                                            style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="numordencompra" className="col-form-label">Categorias</label>
                                                    <Selects
                                                        value={this.state.multiCategoriasSeleccionadas}
                                                        onChange={this.handleChanges('multiCategoriasSeleccionadas')}
                                                        options={this.state.categoriasHijosbd}
                                                    />
                                                </div>
                                                <div className="col-md-9">
                                                    <label htmlFor="numordencompra" className="col-form-label">Productos</label>
                                                    <Selects
                                                        value={this.state.productosxutilidadSeleccionados}
                                                        onChange={this.handleChanges('productosxutilidadSeleccionados')}
                                                        options={this.state.productosxutilidadbd}
                                                        isMulti
                                                    />
                                                </div>
                                                <div className="col-md-3  bottom-aligned">
                                                    <button className="btn btn-success btn-block" onClick={() => this.consultaUtilidad()}>CONSULTAR</button>
                                                </div>
                                            </div>
                                            : null
                            }

                            {
                               /* this.state.mostrarExcelUtilidad
                                ? */<div className="row">
                                    {console.log(excellAllCategoriasAllEmpresas)}
                                    <ExcelFile
                                        filename={"UTILIDAD POR CATEGORIA DESCARGADA - " + moment(new Date()).format("DD/MM/YYYY")}
                                        element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /> Exportar para todos los kioscos</button>}
                                    >
                                        <ExcelSheet data={excellAllCategoriasAllEmpresas} name="Utilidad por categorias">
                                            <ExcelColumn label="" value="1" />
                                            <ExcelColumn label="" value="2" />
                                            <ExcelColumn label="" value="3" />
                                            <ExcelColumn label="" value="4" />
                                            <ExcelColumn label="" value="5" />
                                            <ExcelColumn label="" value="6" />
                                            <ExcelColumn label="" value="7" />
                                            <ExcelColumn label="" value="8" />
                                            <ExcelColumn label="" value="9" />
                                            <ExcelColumn label="" value="10" />
                                            <ExcelColumn label="" value="11" />
                                            <ExcelColumn label="" value="12" />
                                            <ExcelColumn label="" value="13" />
                                        </ExcelSheet>
                                    </ExcelFile>
                                </div>
                                /*:false*/
                            }

                            <div className="row">
                                <Tabs
                                    value={this.state.valueUtilidadEmpresa}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="disabled tabs example"
                                >


                                    {
                                        utilidad != null
                                            ?
                                            utilidad.utilida_x_empresa != null
                                                ?
                                                utilidad.utilida_x_empresa.map((empresa, key) => {
                                                    empresas.push(empresa)
                                                    excelVentas[key] = []
                                                    excelCategoria[key] = []
                                                    excelVentas[key].push({
                                                        "1": "RAZON SOCIAL",
                                                        "2": "ALIAS",
                                                        "3": "NIT",
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                        "10": "",
                                                    })
                                                    excelCategoria[key].push({
                                                        "1": "RAZON SOCIAL",
                                                        "2": "ALIAS",
                                                        "3": "NIT",
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                        "10": "",
                                                    })
                                                    excelVentas[key].push({
                                                        "1": empresa.razon_social,
                                                        "2": empresa.nombre,
                                                        "3": empresa.nit,
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                        "10": "",
                                                    })
                                                    excelCategoria[key].push({
                                                        "1": empresa.razon_social,
                                                        "2": empresa.tienda,
                                                        "3": empresa.nit,
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                        "10": "",
                                                    })
                                                    excelVentas[key].push({
                                                        "1": "",
                                                        "2": "",
                                                        "3": "",
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                        "10": "",
                                                    })
                                                    excelCategoria[key].push({
                                                        "1": "",
                                                        "2": "",
                                                        "3": "",
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                        "10": "",
                                                    })
                                                    excelVentas[key].push({
                                                        "1": "CONSECUTIVO",
                                                        "2": "FECHA",
                                                        "3": "OPERARIO",
                                                        "4": "COSTO",
                                                        "5": "VENTA NETA",
                                                        "6": "IMPUESTOS",
                                                        "7": "VALOR AGREGADO",
                                                        "8": "VENTA TOTAL",
                                                        "9": "UTILIDAD (%)",
                                                        "10": "UTILIDAD ($)",
                                                    })
                                                    excelCategoria[key].push({
                                                        "1": "DESCRIPCION",
                                                        "2": "CATEGORIA PADRE",
                                                        "3": "COSTO",
                                                        "4": "VENTA NETA",
                                                        "5": "IVA",
                                                        "6": "VALOR AGREGADO",
                                                        "7": "VENTA TOTAL",
                                                        "8": "UTILIDAD (%)L",
                                                        "9": "UTILIDAD ($)",
                                                        "10": "RENTABILIDAD ($)",
                                                    })
                                                    empresa.utilidad_x_venta != null ?
                                                        empresa.utilidad_x_venta.map((venta) => {
                                                            excelVentas[key].push({
                                                                '1': venta.consecutivo,
                                                                '2': moment(new Date(venta.fecha)).format("DD/MM/YYYY HH:mm"),
                                                                '3': venta.nombres,
                                                                '4': '$' + this.setearMiles(venta.costo_venta),
                                                                '5': '$' + this.setearMiles(venta.venta_neta),
                                                                '6': '$' + this.setearMiles(venta.impuesto_total),
                                                                '7': '$' + this.setearMiles(venta.ipoconsumo),
                                                                '8': '$' + this.setearMiles(venta.venta_bruta),
                                                                '9': parseFloat(venta.utilidad_porcentaje).toFixed(2) + "%",
                                                                '10': '$' + this.setearMiles(venta.utilidad_valor),
                                                            })
                                                        })
                                                        :
                                                        console.log("n")
                                                    empresa.utilidad_x_categoria != null ?
                                                        empresa.utilidad_x_categoria.map((venta) => {
                                                            excellAllCategoriasAllEmpresas.push({
                                                                "1": empresa.razon_social,
                                                                "2": empresa.nit,
                                                                "3": empresa.tienda,
                                                                "4": venta.categoria,
                                                                "5": venta.categoria_padre,
                                                                "6": '$' + this.setearMiles(venta.costo_categoria),
                                                                "7": '$' + this.setearMiles(venta.venta_neta_categoria),
                                                                '8': '$' + this.setearMiles(venta.impuesto_iva_categoria),
                                                                '9': '$' + this.setearMiles(venta.ipoconsumo_categoria),
                                                                '10': '$' + this.setearMiles(venta.venta_bruta_categoria),
                                                                '11': parseFloat(venta.utilidad_porcentaje_categoria).toFixed(2) + "%",
                                                                '12': '$' + this.setearMiles(venta.utilidad_valor_categoria),
                                                                '13': parseFloat(venta.rentabilidad_porcentaje_categoria).toFixed(2) + "%",
                                                            })
                                                            totalizadoresUtilidad.venta_total = parseFloat(totalizadoresUtilidad.venta_neta + parseFloat(venta.venta_neta))
                                                            totalizadoresUtilidad.impuesto_total = parseFloat(totalizadoresUtilidad.impuesto_total + parseFloat(venta.impuesto_total))
                                                            totalizadoresUtilidad.ipoconsumo = parseFloat(totalizadoresUtilidad.ipoconsumo + parseFloat(venta.ipoconsumo))
                                                            totalizadoresUtilidad.costo_total = parseFloat(totalizadoresUtilidad.costo_total + parseFloat(venta.costo_total))
                                                            totalizadoresUtilidad.venta_total = parseFloat(totalizadoresUtilidad.venta_total + parseFloat(venta.venta_total))
                                                            excelCategoria[key].push({
                                                                '1': venta.categoria,
                                                                '2': venta.categoria_padre,
                                                                '3': '$' + this.setearMiles(venta.costo_categoria),
                                                                '4': '$' + this.setearMiles(venta.venta_neta_categoria),
                                                                '5': '$' + this.setearMiles(venta.impuesto_iva_categoria),
                                                                '6': '$' + this.setearMiles(venta.ipoconsumo_categoria),
                                                                '7': '$' + this.setearMiles(venta.venta_bruta_categoria),
                                                                '8': parseFloat(venta.utilidad_porcentaje_categoria).toFixed(2) + "%",
                                                                '9': '$' + this.setearMiles(venta.utilidad_valor_categoria),
                                                                '10': parseFloat(venta.rentabilidad_porcentaje_categoria).toFixed(2) + "%",
                                                            })
                                                        })
                                                        :
                                                        console.log("n")
                                                    excelVentas[key].push({
                                                        "1": "",
                                                        "2": "",
                                                        "3": "",
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                        "10": "",
                                                    })
                                                    excelCategoria[key].push({
                                                        "1": "",
                                                        "2": "",
                                                        "3": "",
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                        "9": "",
                                                        "10": "",
                                                    })
                                                    excelVentas[key].push({
                                                        "1": "VENTA NETA",
                                                        "2": "IMPUESTO TOTAL",
                                                        "3": "IMPOCONSUMO",
                                                        "4": "VENTA TOTAL",
                                                        "5": "COSTO TOTAL",
                                                        "6": "PORCENTAJE UTILIDAD",
                                                        "7": "VALOR UTILIDAD",
                                                        "8": "PORCENTAJE RENTABILIDAD",
                                                        "9": "",
                                                        "10": "",
                                                    })
                                                    excelCategoria[key].push({
                                                        "1": "VENTA NETA",
                                                        "2": "IMPUESTO TOTAL",
                                                        "3": "IMPOCONSUMO",
                                                        "4": "VENTA TOTAL",
                                                        "5": "COSTO TOTAL",
                                                        "6": "PORCENTAJE UTILIDAD",
                                                        "7": "VALOR UTILIDAD",
                                                        "8": "PORCENTAJE RENTABILIDAD",
                                                        "9": "",
                                                        "10": "",
                                                    })
                                                    excelVentas[key].push({
                                                        "1": '$' + this.setearMiles(empresa.venta_neta_total),
                                                        "2": '$' + this.setearMiles(empresa.impuestos_venta),
                                                        "3": '$' + this.setearMiles(empresa.ipoconsumo_total),
                                                        "4": '$' + this.setearMiles(empresa.venta_total),
                                                        "5": '$' + this.setearMiles(empresa.costo_total),
                                                        "6": empresa.utilidad_total_porcentaje + "%",
                                                        "7": '$' + this.setearMiles(empresa.utilidad_total_valor),
                                                        "8": empresa.retabilidad_total_porcentaje + "%",
                                                        "9": "",
                                                        "10": "",
                                                    })
                                                    excelCategoria[key].push({
                                                        "1": '$' + this.setearMiles(empresa.venta_neta_total),
                                                        "2": '$' + this.setearMiles(empresa.impuestos_venta),
                                                        "3": '$' + this.setearMiles(empresa.ipoconsumo_total),
                                                        "4": '$' + this.setearMiles(empresa.venta_bruta_total),
                                                        "5": '$' + this.setearMiles(empresa.costo_total),
                                                        "6": empresa.utilidad_total_porcentaje + "%",
                                                        "7": '$' + this.setearMiles(empresa.utilidad_total_valor),
                                                        "8": empresa.retabilidad_total_porcentaje + "%",
                                                        "9": "",
                                                        "10": "",
                                                    })
                                                    return (
                                                        <Tab label={empresa.razon_social} onClick={() => this.cambiarTabs("valueUtilidadEmpresa", key)} />
                                                    )
                                                })
                                                :
                                                console.log("eror en ventas por dia   " + utilidad.utilidad)
                                            :
                                            console.log("eror en reportes " + utilidad)

                                    }
                                </Tabs>
                            </div>
                            {/*
                                excellAllCategoriasAllEmpresas.push({
                                    "1": "VENTA NETA",
                                    "2": "IMPUESTO TOTAL",
                                    "3": "IMPOCONSUMO",
                                    "4": "VENTA TOTAL",
                                    "5": "COSTO TOTAL",
                                    "6": "",
                                    "7": "",
                                    "8": "",
                                    "9": "",
                                    "10": "",
                                    "11": "",
                                    "12": "",
                                    "13": "",
                                })
                            */}
                            {/*
                                excellAllCategoriasAllEmpresas.push({
                                    "1": "$ "+this.setearMiles(totalizadoresUtilidad.venta_neta),
                                    "2": "$ "+this.setearMiles(totalizadoresUtilidad.impuesto_total),
                                    "3": "$ "+this.setearMiles(totalizadoresUtilidad.ipoconsumo),
                                    "4": "$ "+this.setearMiles(totalizadoresUtilidad.venta_total),
                                    "5": "$ "+this.setearMiles(totalizadoresUtilidad.costo_total),
                                    "6": "",
                                    "7": "",
                                    "8": "",
                                    "9": "",
                                    "10": "",
                                    "11": "",
                                    "12": "",
                                    "13": "",
                                })
                            */}
                            {
                                this.state.valueUtilidad === 0 ?
                                    empresas != null
                                        ?
                                        empresas[this.state.valueUtilidadEmpresa] != null
                                            ?
                                            empresas[this.state.valueUtilidadEmpresa].utilidad_x_venta != null
                                                ?

                                                <div>
                                                    <div className="row" style={{ marginTop: '10px' }}>
                                                        <div className="col col-md-12" >
                                                            <Paper style={{ padding: '5px', width: '100%' }}>
                                                                {
                                                                    empresas[this.state.valueUtilidadEmpresa].utilidad_x_venta.map((venta) => {
                                                                        detalles.push({
                                                                            'movimineto': venta.consecutivo,
                                                                            'Fecha': moment(new Date(venta.fecha)).format("DD/MM/YYYY HH:mm"),
                                                                            'operacion': venta.nombres,
                                                                            'tipo1': '$' + this.setearMiles(venta.costo_venta),
                                                                            'tipo2': '$' + this.setearMiles(venta.venta_neta),
                                                                            'tipo3': '$' + this.setearMiles(venta.impuesto_total),
                                                                            'tipo8': '$' + this.setearMiles(venta.ipoconsumo),
                                                                            'tipo4': '$' + this.setearMiles(venta.venta_bruta),
                                                                            'tipo5': parseFloat(venta.utilidad_porcentaje).toFixed(2) + "%",
                                                                            'tipo6': '$' + this.setearMiles(venta.utilidad_valor),
                                                                            'tipo7': <button className="btn btn-sm btn-primary" onClick={() => this.detallesUtilidadVentas(venta)}><Ojoicon /></button>
                                                                        })
                                                                    })
                                                                }
                                                                <ExcelFile
                                                                    filename={"UTILIDAD POR ÚTILIDAD " + moment(new Date()).format("DD/MM/YYYY")}
                                                                    element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button>}
                                                                >
                                                                    <ExcelSheet data={excelVentas[this.state.valueUtilidadEmpresa]} name="Utilidad">
                                                                        <ExcelColumn label="" value="1" />
                                                                        <ExcelColumn label="" value="2" />
                                                                        <ExcelColumn label="" value="3" />
                                                                        <ExcelColumn label="" value="4" />
                                                                        <ExcelColumn label="" value="5" />
                                                                        <ExcelColumn label="" value="6" />
                                                                        <ExcelColumn label="" value="7" />
                                                                        <ExcelColumn label="" value="8" />
                                                                        <ExcelColumn label="" value="9" />
                                                                        <ExcelColumn label="" value="10" />
                                                                    </ExcelSheet>
                                                                </ExcelFile>
                                                                <MDBDataTable
                                                                    //striped
                                                                    className="col-md-12 tabla_utilidad"
                                                                    searchLabel="Buscar"
                                                                    paginationLabel={["Anterior", "Siguente"]}
                                                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                    entriesLabel="Filas a mostrar"
                                                                    tbodyColor="primary-color"
                                                                    theadColor="#FF8800"
                                                                    exportToCSV={true}
                                                                    striped
                                                                    bordered
                                                                    sortable={true}
                                                                    autoWidth={false}
                                                                    responsive={true}
                                                                    responsiveSm={true}
                                                                    style={{ fontSize: '13px' }}
                                                                    hover
                                                                    data={
                                                                        {
                                                                            columns: [
                                                                                {
                                                                                    label: 'CONS.',
                                                                                    field: 'celular',
                                                                                    sort: 'asc',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'FECHA',
                                                                                    field: 'identificacion',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'OPERARIO',
                                                                                    field: 'cant',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'COSTO',
                                                                                    field: 'operacion',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'V.NETA',
                                                                                    field: 'q9',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'IMPUESTOS',
                                                                                    field: 'q8',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'IPOCON.',
                                                                                    field: 'q88',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'V.TOTAL',
                                                                                    field: 'q8',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'UTIL.(%)',
                                                                                    field: 'q6',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'UTIL.($)',
                                                                                    field: 'q5',
                                                                                    sort: 'asc',
                                                                                    width: 100
                                                                                }, {
                                                                                    label: 'ACCION',
                                                                                    field: 'q55',
                                                                                    width: 100
                                                                                }
                                                                            ], rows: detalles
                                                                        }
                                                                    }
                                                                />
                                                                <Dialog
                                                                    open={this.state.dialogoUtilidadXVentas}
                                                                    aria-labelledby="form-dialog-title"
                                                                    maxWidth={'lg'}
                                                                    fullWidth={true}
                                                                    style={{ padding: '0' }}
                                                                    disableBackdropClick={false}
                                                                >
                                                                    <DialogContent style={{ padding: '0' }}>
                                                                        <div className="row mx-auto" >
                                                                            <div className="col-md-12 mx-auto" style={{ padding: 0 }}>
                                                                                <Paper style={{ padding: '10px' }}>
                                                                                    <div className="row">
                                                                                        <button className="btn btn-danger " onClick={() => this.cerrarUtilidadVentas()} style={{ margin: '10px', float: 'right' }}>CERRAR</button>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-md-3">
                                                                                            <label htmlFor="numordencompra" className="col-form-label">VENTA</label>
                                                                                            <input id="numordencompra" disabled={true} value={detallesXVenta != null ? detallesXVenta.consecutivo : '000'} className="form-control" />
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <label htmlFor="numordencompra" className="col-form-label">FECHA</label>
                                                                                            <input id="numordencompra" disabled={true} value={detallesXVenta != null ? moment(new Date(detallesXVenta.fecha)).format("DD/MM/YYYY hh:mm a") : ''} className="form-control" />
                                                                                        </div>
                                                                                        <div className="col-md-5">
                                                                                            <label htmlFor="numordencompra" className="col-form-label">PROMOTOR</label>
                                                                                            <input id="numordencompra" disabled={true} value={detallesXVenta != null ? detallesXVenta.nombres : ''} className="form-control" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <MDBDataTable
                                                                                        //striped
                                                                                        className="col-md-12 mx-auto"
                                                                                        searchLabel="Buscar"
                                                                                        paginationLabel={["Anterior", "Siguente"]}
                                                                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                                        entriesLabel="Filas a mostrar"
                                                                                        tbodyColor="primary-color"
                                                                                        theadColor="#FF8800"
                                                                                        exportToCSV={true}
                                                                                        striped
                                                                                        bordered
                                                                                        autoWidth={false}
                                                                                        responsive={true}
                                                                                        hover
                                                                                        order={['plu', 'asc']}
                                                                                        data={
                                                                                            {
                                                                                                columns: [
                                                                                                    {
                                                                                                        label: 'Plu',
                                                                                                        field: 'plu',
                                                                                                        sort: 'asc',
                                                                                                        width: 150
                                                                                                    }, {
                                                                                                        label: 'Descripcion',
                                                                                                        field: 'descr',
                                                                                                        width: 150
                                                                                                    }, {
                                                                                                        label: 'Costo',
                                                                                                        field: 'costo',
                                                                                                        width: 150
                                                                                                    }, {
                                                                                                        label: 'Precio',
                                                                                                        field: 'punitario',
                                                                                                        width: 150
                                                                                                    }, {
                                                                                                        label: 'Cant.',
                                                                                                        field: 'cantidad',
                                                                                                        width: 150
                                                                                                    }, {
                                                                                                        label: 'V. Neta',
                                                                                                        field: 'sproducto',
                                                                                                        width: 150
                                                                                                    }, {
                                                                                                        label: 'IVA',
                                                                                                        field: 'subventa',
                                                                                                        width: 150
                                                                                                    }, {
                                                                                                        label: 'IMPOCONSUMO',
                                                                                                        field: 'imventa',
                                                                                                        width: 150
                                                                                                    }, {
                                                                                                        label: 'Total',
                                                                                                        field: 'venta',
                                                                                                        width: 150
                                                                                                    }, {
                                                                                                        label: 'UTIL. (%)',
                                                                                                        field: 'q6',
                                                                                                        width: 100
                                                                                                    }, {
                                                                                                        label: 'UTIL. ($)',
                                                                                                        field: 'q5',
                                                                                                        width: 100
                                                                                                    },
                                                                                                ],
                                                                                                rows: detallesXVenta != null ?
                                                                                                    detallesXVenta.utilidad_detalle != null
                                                                                                        ?
                                                                                                        detallesXVenta.utilidad_detalle.map((detalle) => {
                                                                                                            return ({
                                                                                                                'plu': detalle.plu,
                                                                                                                'producto': detalle.descripcion,
                                                                                                                'costo': '$' + this.setearMiles(detalle.costo_venta_detalle),
                                                                                                                'punitario': '$' + this.setearMiles(detalle.precio),
                                                                                                                'cantidad': detalle.cantidad,
                                                                                                                'subtotal': '$' + this.setearMiles(detalle.venta_detalle_neta),
                                                                                                                'iva': '$' + this.setearMiles(detalle.venta_detalle_neta),
                                                                                                                'ipoconsumo': '$' + (detalle.ipoconsumo_detalle != null ? this.setearMiles(detalle.ipoconsumo_detalle) : 0),
                                                                                                                'total': '$' + this.setearMiles(detalle.venta_detalle_bruta),
                                                                                                                'utilidad_porcentaje': parseFloat(detalle.utilidad_detalle_porcentaje).toFixed(2) + " %",
                                                                                                                'utilidad_valor': '$' + this.setearMiles(detalle.utilidad_detalle_valor),
                                                                                                            })
                                                                                                        })
                                                                                                        :
                                                                                                        []
                                                                                                    :
                                                                                                    []
                                                                                            }
                                                                                        }
                                                                                    />
                                                                                </Paper>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mx-auto">
                                                                            <div className="col-md-8 bottom-aligned mx-auto"></div>

                                                                        </div>
                                                                    </DialogContent>
                                                                </Dialog>
                                                            </Paper>

                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                console.log("eror en empresa categorias" + empresas[this.state.valueUtilidadEmpresa].utilidad_x_categoria)
                                            :
                                            console.log("eror en empresa categorias" + empresas[this.state.valueUtilidadEmpresa])
                                        :
                                        console.log("eror en empresa " + empresas)
                                    :
                                    this.state.valueUtilidad === 1 ?

                                        empresas != null
                                            ?
                                            empresas[this.state.valueUtilidadEmpresa] != null
                                                ?
                                                empresas[this.state.valueUtilidadEmpresa].utilidad_x_categoria != null
                                                    ?

                                                    <div>
                                                        <div className="row" style={{ marginTop: '10px' }}>
                                                            <div className="col col-md-12" >
                                                                <Paper style={{ padding: '5px', width: '100%' }}>
                                                                    {
                                                                        empresas[this.state.valueUtilidadEmpresa].utilidad_x_categoria.map((venta) => {
                                                                            console.log(venta)
                                                                            detalles.push({
                                                                                'descripcion': venta.categoria,
                                                                                'movimineto': venta.categoria_padre,
                                                                                'tipo1': '$' + this.setearMiles(venta.costo_categoria),
                                                                                'tipo12': '$' + this.setearMiles(venta.venta_neta_categoria),
                                                                                'tipo13': '$' + this.setearMiles(venta.impuesto_iva_categoria),
                                                                                'tipo14': '$' + this.setearMiles(venta.ipoconsumo_categoria),
                                                                                'tipo15': '$' + this.setearMiles(venta.venta_bruta_categoria),
                                                                                'tipo4': parseFloat(venta.utilidad_porcentaje_categoria).toFixed(2) + "%",
                                                                                'tipo26': '$' + this.setearMiles(venta.utilidad_valor_categoria),
                                                                                'tipo25': parseFloat(venta.rentabilidad_porcentaje_categoria).toFixed(2) + "%",
                                                                                'tipo7': <button className="btn btn-sm btn-primary" onClick={() => this.detallesUtilidadCategoria(venta)}><Ojoicon /></button>
                                                                            })
                                                                        })
                                                                    }
                                                                    <ExcelFile
                                                                        filename={"UTILIDAD POR CATEGORIAS " + moment(new Date()).format("DD/MM/YYYY")}
                                                                        element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button>}
                                                                    >
                                                                        <ExcelSheet data={excelCategoria[this.state.valueUtilidadEmpresa]} name="Utilidad">
                                                                            <ExcelColumn label="" value="1" />
                                                                            <ExcelColumn label="" value="2" />
                                                                            <ExcelColumn label="" value="3" />
                                                                            <ExcelColumn label="" value="4" />
                                                                            <ExcelColumn label="" value="5" />
                                                                            <ExcelColumn label="" value="6" />
                                                                            <ExcelColumn label="" value="7" />
                                                                            <ExcelColumn label="" value="8" />
                                                                            <ExcelColumn label="" value="9" />
                                                                            <ExcelColumn label="" value="10" />
                                                                        </ExcelSheet>
                                                                    </ExcelFile>
                                                                    <MDBDataTable
                                                                        //striped
                                                                        className="col-md-12 tabla_utilidad"
                                                                        searchLabel="Buscar"
                                                                        paginationLabel={["Anterior", "Siguente"]}
                                                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                        entriesLabel="Filas a mostrar"
                                                                        tbodyColor="primary-color"
                                                                        theadColor="#FF8800"
                                                                        exportToCSV={true}
                                                                        striped
                                                                        bordered
                                                                        autoWidth={false}
                                                                        responsive={true}
                                                                        responsiveSm={true}
                                                                        style={{ fontSize: '13px' }}
                                                                        hover
                                                                        data={
                                                                            {
                                                                                columns: [
                                                                                    {
                                                                                        label: 'DESCRIPCION',
                                                                                        field: 'celular',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'CAT. PADRE',
                                                                                        field: 'celular',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'COSTO',
                                                                                        field: 'identificacion',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'VENTA N.',
                                                                                        field: 'cant',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'IVA',
                                                                                        field: 'operacion',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'IMPOCONSUMO',
                                                                                        field: 'q19',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'VENTA T.',
                                                                                        field: 'q18',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'UTIL. (%)',
                                                                                        field: 'q6',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'UTIL. ($)',
                                                                                        field: 'q7',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'RENT. (%)',
                                                                                        field: 'q12',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'VER',
                                                                                        field: 'q12',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }
                                                                                ], rows: detalles
                                                                            }
                                                                        }
                                                                    />
                                                                    <Dialog
                                                                        open={this.state.dialogoUtilidadXCategorias}
                                                                        aria-labelledby="form-dialog-title"
                                                                        maxWidth={'lg'}
                                                                        fullWidth={true}
                                                                        style={{ padding: '0' }}
                                                                        disableBackdropClick={false}
                                                                    >
                                                                        <DialogContent style={{ padding: '0' }}>
                                                                            <div className="row mx-auto" >
                                                                                <div className="col-md-12 mx-auto" style={{ padding: 0 }}>
                                                                                    <Paper style={{ padding: '10px' }}>
                                                                                        <div className="row">
                                                                                            <button className="btn btn-danger " onClick={() => this.cerrarUtilidadCategoria()} style={{ margin: '10px', float: 'right' }}>CERRAR</button>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-6">
                                                                                                <label htmlFor="numordencompra" className="col-form-label">DESCRIPCION</label>
                                                                                                <input id="numordencompra" disabled={true} value={detallesXCategoria != null ? detallesXCategoria.categoria : '000'} className="form-control" />
                                                                                            </div>

                                                                                            <div className="col-md-6">
                                                                                                <label htmlFor="numordencompra" className="col-form-label">CATEGORIA PADRE</label>
                                                                                                <input id="numordencompra" disabled={true} value={detallesXCategoria != null ? detallesXCategoria.categoria_padre : ''} className="form-control" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <MDBDataTable
                                                                                            //striped
                                                                                            className="col-md-12 mx-auto"
                                                                                            searchLabel="Buscar"
                                                                                            paginationLabel={["Anterior", "Siguente"]}
                                                                                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                                            entriesLabel="Filas a mostrar"
                                                                                            tbodyColor="primary-color"
                                                                                            theadColor="#FF8800"
                                                                                            exportToCSV={true}
                                                                                            striped
                                                                                            bordered
                                                                                            autoWidth={false}
                                                                                            responsive={true}
                                                                                            hover
                                                                                            order={['plu', 'asc']}
                                                                                            data={
                                                                                                {
                                                                                                    columns: [
                                                                                                        {
                                                                                                            label: 'Plu',
                                                                                                            field: 'plu',
                                                                                                            sort: 'asc',
                                                                                                            width: 150
                                                                                                        }, {
                                                                                                            label: 'Descripcion',
                                                                                                            field: 'descr',
                                                                                                            width: 150
                                                                                                        }, {
                                                                                                            label: 'Costo',
                                                                                                            field: 'costo',
                                                                                                            width: 150
                                                                                                        }, {
                                                                                                            label: 'Precio',
                                                                                                            field: 'punitario',
                                                                                                            width: 150
                                                                                                        }, {
                                                                                                            label: 'Cant.',
                                                                                                            field: 'cantidad',
                                                                                                            width: 150
                                                                                                        }, {
                                                                                                            label: 'V. Neta',
                                                                                                            field: 'sproducto',
                                                                                                            width: 150
                                                                                                        }, {
                                                                                                            label: 'IVA',
                                                                                                            field: 'subventa',
                                                                                                            width: 150
                                                                                                        }, {
                                                                                                            label: 'IMPOCONSUMO',
                                                                                                            field: 'imventa',
                                                                                                            width: 150
                                                                                                        }, {
                                                                                                            label: 'Total',
                                                                                                            field: 'venta',
                                                                                                            width: 150
                                                                                                        }, {
                                                                                                            label: 'UTIL. (%)',
                                                                                                            field: 'q6',
                                                                                                            width: 100
                                                                                                        }, {
                                                                                                            label: 'UTIL. ($)',
                                                                                                            field: 'q5',
                                                                                                            width: 100
                                                                                                        },
                                                                                                    ],
                                                                                                    rows: detallesXCategoria != null ?
                                                                                                        detallesXCategoria.utilidad_x_producto != null
                                                                                                            ?
                                                                                                            detallesXCategoria.utilidad_x_producto.map((detalle) => {
                                                                                                                return ({
                                                                                                                    'plu': detalle.plu,
                                                                                                                    'producto': detalle.descripcion,
                                                                                                                    'costo': '$' + this.setearMiles(detalle.costo_venta),
                                                                                                                    'punitario': '$' + this.setearMiles(detalle.precio),
                                                                                                                    'cantidad': detalle.cantidad,
                                                                                                                    'subtotal': '$' + this.setearMiles(detalle.venta_neta),
                                                                                                                    'iva': '$' + this.setearMiles(detalle.impuesto_iva),
                                                                                                                    'ipoconsumo': '$' + (detalle.ipoconsumo != null ? this.setearMiles(detalle.ipoconsumo) : 0),
                                                                                                                    'total': '$' + this.setearMiles(detalle.venta_bruta),
                                                                                                                    'utilidad_porcentaje': parseFloat(detalle.utilidad_porcentaje).toFixed(2) + " %",
                                                                                                                    'utilidad_valor': '$' + this.setearMiles(detalle.utilidad_valor),
                                                                                                                })
                                                                                                            })
                                                                                                            :
                                                                                                            []
                                                                                                        :
                                                                                                        []
                                                                                                }
                                                                                            }
                                                                                        />
                                                                                    </Paper>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mx-auto">
                                                                                <div className="col-md-8 bottom-aligned mx-auto"></div>

                                                                            </div>
                                                                        </DialogContent>
                                                                    </Dialog>

                                                                </Paper>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    console.log("eror en empresa categorias" + empresas[this.state.valueUtilidadEmpresa].utilidad_x_categoria)
                                                :
                                                console.log("eror en empresa categorias" + empresas[this.state.valueUtilidadEmpresa])
                                            :
                                            console.log("eror en empresa " + empresas)

                                        : this.state.valueUtilidad === 2 ?


                                            utilidad != null
                                                ?
                                                utilidad.utilidad_x_plu != null
                                                    ?

                                                    <div>
                                                        <div className="row" style={{ marginTop: '10px' }}>
                                                            <div className="col col-md-12" >
                                                                <Paper style={{ padding: '5px', width: '100%' }}>
                                                                    {
                                                                        utilidad.utilidad_x_plu.map((venta) => {
                                                                            detalles.push({
                                                                                'plu': parseInt(venta.plu),
                                                                                'descripcion': venta.descripcion,
                                                                                'tienda': venta.nombre,
                                                                                'tipo122': venta.cantidad,
                                                                                'tipo1': '$' + this.setearMiles(venta.costo_venta),
                                                                                'tipo12': '$' + this.setearMiles(venta.venta_neta),
                                                                                'tipo13': '$' + this.setearMiles(venta.impuesto_iva),
                                                                                'tipo14': '$' + this.setearMiles(venta.ipoconsumo),
                                                                                'tipo15': '$' + this.setearMiles(venta.venta_bruta),
                                                                                'tipo4': parseFloat(venta.utilidad_porcentaje).toFixed(2) + "%",
                                                                                'tipo26': '$' + this.setearMiles(venta.utilidad_valor),
                                                                                'tipo25': parseFloat(venta.rentabilidad_porcentaje).toFixed(2) + "%"
                                                                            })
                                                                            excelProducto.push({
                                                                                '1': parseInt(venta.plu),
                                                                                '2': venta.descripcion,
                                                                                '3': venta.nombre,
                                                                                '4': venta.cantidad,
                                                                                '5': '$' + this.setearMiles(venta.costo_venta),
                                                                                '6': '$' + this.setearMiles(venta.venta_neta),
                                                                                '7': '$' + this.setearMiles(venta.impuesto_iva),
                                                                                '8': '$' + this.setearMiles(venta.ipoconsumo),
                                                                                '9': '$' + this.setearMiles(venta.venta_bruta),
                                                                                '10': parseFloat(venta.utilidad_porcentaje).toFixed(2) + "%",
                                                                                '11': '$' + this.setearMiles(venta.utilidad_valor),
                                                                                '12': parseFloat(venta.rentabilidad_porcentaje).toFixed(2) + "%"
                                                                            })
                                                                        })
                                                                    }
                                                                    <ExcelFile
                                                                        filename={"UTILIDAD POR PRODUCTO " + moment(new Date()).format("DD/MM/YYYY")}
                                                                        element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button>}
                                                                    >
                                                                        <ExcelSheet data={excelProducto} name="Utilidad">
                                                                            <ExcelColumn label="PLU" value="1" />
                                                                            <ExcelColumn label="PRODUCTO" value="2" />
                                                                            <ExcelColumn label="TIENDA" value="3" />
                                                                            <ExcelColumn label="CANTIDAD" value="4" />
                                                                            <ExcelColumn label="COSTO" value="5" />
                                                                            <ExcelColumn label="VENTA NETA" value="6" />
                                                                            <ExcelColumn label="IVA" value="7" />
                                                                            <ExcelColumn label="VALOR AGREGADO" value="8" />
                                                                            <ExcelColumn label="VENTA TOTAL" value="9" />
                                                                            <ExcelColumn label="UTILIDAD (%)" value="10" />
                                                                            <ExcelColumn label="UTILIDAD ($)" value="11" />
                                                                            <ExcelColumn label="RENTABILIDAD ($)" value="12" />
                                                                        </ExcelSheet>
                                                                    </ExcelFile>
                                                                    <MDBDataTable
                                                                        //striped
                                                                        className="col-md-12 tabla_utilidad"
                                                                        searchLabel="Buscar"
                                                                        paginationLabel={["Anterior", "Siguente"]}
                                                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                        entriesLabel="Filas a mostrar"
                                                                        tbodyColor="primary-color"
                                                                        theadColor="#FF8800"
                                                                        exportToCSV={true}
                                                                        striped
                                                                        bordered
                                                                        autoWidth={false}
                                                                        responsive={true}
                                                                        responsiveSm={true}
                                                                        style={{ fontSize: '13px' }}
                                                                        hover
                                                                        data={
                                                                            {
                                                                                columns: [
                                                                                    {
                                                                                        label: 'PLU',
                                                                                        field: 'plu',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'PRODUCTO',
                                                                                        field: 'celular',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'TIENDA',
                                                                                        field: 'tienda',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'CANTIDAD',
                                                                                        field: 'cantidad',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'COSTO',
                                                                                        field: 'identificacion',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'VENTA N.',
                                                                                        field: 'cant',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'IVA',
                                                                                        field: 'operacion',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'IMPOCONSUMO',
                                                                                        field: 'q19',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'VENTA T.',
                                                                                        field: 'q18',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'UTIL. (%)',
                                                                                        field: 'q6',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'UTIL. ($)',
                                                                                        field: 'q7',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }, {
                                                                                        label: 'RENT. (%)',
                                                                                        field: 'q12',
                                                                                        sort: 'asc',
                                                                                        width: 100
                                                                                    }
                                                                                ], rows: detalles
                                                                            }
                                                                        }
                                                                    />


                                                                </Paper>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    console.log("eror en empresa categorias" + empresas[this.state.valueUtilidadEmpresa])
                                                :
                                                console.log("eror en empresa categorias" + empresas[this.state.valueUtilidadEmpresa])


                                            : null

                            }
                            {
                                this.state.valueUtilidad === 0 ?
                                    empresas != null
                                        ?
                                        empresas[this.state.valueUtilidadEmpresa] != null
                                            ?
                                            this.state.valueUtilidadCategoria === numCategorias
                                                ?
                                                <div>

                                                    <div className="row" style={{ marginTop: '10px' }}>
                                                        <div className="col-md-3">
                                                            <label htmlFor="numordencompra" className="col-form-label">VENTA NETA</label>
                                                            <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueUtilidadEmpresa].venta_neta_total != null ? '$ ' + this.setearMiles(empresas[this.state.valueUtilidadEmpresa].venta_neta_total) : '$ 0'} />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="numordencompra" className="col-form-label">IMPUESTO TOTAL</label>
                                                            <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueUtilidadEmpresa].impuestos_venta != null ? '$ ' + this.setearMiles(empresas[this.state.valueUtilidadEmpresa].impuestos_venta) : '$ 0'} />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="numordencompra" className="col-form-label">IMPOCONSUMO</label>
                                                            <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueUtilidadEmpresa].ipoconsumo_total != null ? '$ ' + this.setearMiles(empresas[this.state.valueUtilidadEmpresa].ipoconsumo_total) : '$ 0'} />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="numordencompra" className="col-form-label">VENTA TOTAL</label>
                                                            <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueUtilidadEmpresa].venta_total != null ? '$ ' + this.setearMiles(empresas[this.state.valueUtilidadEmpresa].venta_total) : '$ 0'} />
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <label htmlFor="numordencompra" className="col-form-label">COSTO TOTAL</label>
                                                            <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueUtilidadEmpresa].costo_total != null ? '$ ' + this.setearMiles(empresas[this.state.valueUtilidadEmpresa].costo_total) : '$ 0'} />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="numordencompra" className="col-form-label">PORCENTAJE UTILIDAD</label>
                                                            <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueUtilidadEmpresa].utilidad_total_porcentaje != null ? parseFloat(empresas[this.state.valueUtilidadEmpresa].utilidad_total_porcentaje).toFixed(3) + "%" : '$ 0'} />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="numordencompra" className="col-form-label">VALOR UTILIDAD</label>
                                                            <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueUtilidadEmpresa].utilidad_total_valor != null ? '$ ' + this.setearMiles(empresas[this.state.valueUtilidadEmpresa].utilidad_total_valor) : '$ 0'} />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="numordencompra" className="col-form-label">PORCENTAJE RENTABILIDAD</label>
                                                            <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueUtilidadEmpresa].retabilidad_total_porcentaje != null ? parseFloat(empresas[this.state.valueUtilidadEmpresa].retabilidad_total_porcentaje).toFixed(3) + "%" : '$ 0'} />
                                                        </div>

                                                    </div>
                                                </div>
                                                : console.log(numCategorias)
                                            : console.log(numCategorias)
                                        : console.log(numCategorias)
                                    :
                                    this.state.valueUtilidad === 1 ?
                                        empresas != null
                                            ?
                                            empresas[this.state.valueUtilidadEmpresa] != null
                                                ?
                                                this.state.valueUtilidadCategoria === numCategorias
                                                    ?
                                                    <div>

                                                        <div className="row" style={{ marginTop: '10px' }}>
                                                            <div className="col-md-3">
                                                                <label htmlFor="numordencompra" className="col-form-label">VENTA NETA</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueUtilidadEmpresa].venta_neta_total != null ? '$ ' + this.setearMiles(empresas[this.state.valueUtilidadEmpresa].venta_neta_total) : '$ 0'} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="numordencompra" className="col-form-label">IMPUESTO TOTAL</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueUtilidadEmpresa].impuestos_venta != null ? '$ ' + this.setearMiles(empresas[this.state.valueUtilidadEmpresa].impuestos_venta) : '$ 0'} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="numordencompra" className="col-form-label">IMPOCONSUMO</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueUtilidadEmpresa].ipoconsumo_total != null ? '$ ' + this.setearMiles(empresas[this.state.valueUtilidadEmpresa].ipoconsumo_total) : '$ 0'} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="numordencompra" className="col-form-label">VENTA TOTAL</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueUtilidadEmpresa].venta_bruta_total != null ? '$ ' + this.setearMiles(empresas[this.state.valueUtilidadEmpresa].venta_bruta_total) : '$ 0'} />
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <label htmlFor="numordencompra" className="col-form-label">COSTO TOTAL</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueUtilidadEmpresa].costo_total != null ? '$ ' + this.setearMiles(empresas[this.state.valueUtilidadEmpresa].costo_total) : '$ 0'} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="numordencompra" className="col-form-label">PORCENTAJE UTILIDAD</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueUtilidadEmpresa].utilidad_total_porcentaje != null ? parseFloat(empresas[this.state.valueUtilidadEmpresa].utilidad_total_porcentaje).toFixed(3) + "%" : '$ 0'} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="numordencompra" className="col-form-label">VALOR UTILIDAD</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueUtilidadEmpresa].utilidad_total_valor != null ? '$ ' + this.setearMiles(empresas[this.state.valueUtilidadEmpresa].utilidad_total_valor) : '$ 0'} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="numordencompra" className="col-form-label">PORCENTAJE RENTABILIDAD</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueUtilidadEmpresa].retabilidad_total_porcentaje != null ? parseFloat(empresas[this.state.valueUtilidadEmpresa].retabilidad_total_porcentaje).toFixed(3) + "%" : '$ 0'} />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    : console.log(numCategorias)
                                                : console.log(numCategorias)
                                            : console.log(numCategorias)
                                        :
                                        null
                            }
                        </div >
                )
                break;
            case 5:
                var facturacion = this.state.LibroFacturacion
                var tablas = []
                var empresas = []
                var rowsVentasFechas = []
                var numCategorias = 0
                var excel = []
                const acumulados = {
                    ventaTotal: 0,
                    descuento: 0,
                    excluido: 0,
                    baseGravable: 0,
                    valorImpuesto: 0
                }
                return (
                    this.state.vistaCargando ?
                        <Loader />
                        :
                        <div style={{ paddingRight: '25px', paddingLeft: '25px', paddingBottom: '25px' }} >

                            {
                                usuario.tipo === "5"
                                    ?
                                    <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                        <div className="col-md-4">
                                            <label htmlFor="numordencompra" className="col-form-label">Fecha</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                <DatePicker
                                                    format="yyyy-MM-dd"
                                                    id="datetime"
                                                    value={this.state.selectedDateVenta}
                                                    onChange={this.handleChangemomentventa}
                                                    ampm={false}
                                                    disableFuture={true}
                                                    className="rounded form-control"
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-8  bottom-aligned">
                                            <button className="btn btn-success btn-block" onClick={() => this.consultaLibroFacturacion()}>CONSULTAR</button>
                                        </div>
                                    </div>
                                    :
                                    <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                        <div className="col-md-2">
                                            <label htmlFor="numordencompra" className="col-form-label">Fecha</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                <DatePicker
                                                    format="yyyy-MM-dd"
                                                    id="datetime"
                                                    value={this.state.selectedDateVenta}
                                                    onChange={this.handleChangemomentventa}
                                                    ampm={false}
                                                    disableFuture={true}
                                                    className="rounded form-control"
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-7">
                                            <label htmlFor="numordencompra" className="col-form-label">EMPRESA</label>
                                            <Selects
                                                value={this.state.multiEmpresasVentasSeleccionadas}
                                                onChange={this.handleChanges('multiEmpresasVentasSeleccionadas')}
                                                options={this.state.empresasbd}
                                                isMulti
                                            />
                                        </div>
                                        <div className="col-md-3  bottom-aligned">
                                            <button className="btn btn-success btn-block" onClick={() => this.consultaLibroFacturacion()}>CONSULTAR</button>
                                        </div>
                                    </div>
                            }

                            <div className="row">
                                <Tabs
                                    value={this.state.valueFacturacionEmpresa}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="disabled tabs example"
                                >


                                    {
                                        facturacion != null
                                            ?
                                            facturacion.map((empresa, key) => {
                                                empresas.push(empresa)
                                                rowsVentasFechas[key] = []
                                                excel[key] = []
                                                excel[key].push({
                                                    "1": "",
                                                    "2": "",
                                                    "3": "",
                                                    "4": empresa.razon_social,
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                })
                                                excel[key].push({
                                                    "1": "",
                                                    "2": "",
                                                    "3": "",
                                                    "4": "NIT. " + empresa.nit,
                                                    "5": "CODIGO " + empresa.codigo_tienda,
                                                    "6": "",
                                                    "7": "",
                                                })
                                                excel[key].push({
                                                    "1": "",
                                                    "2": "",
                                                    "3": "",
                                                    "4": "",
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                })
                                                excel[key].push({
                                                    "1": "Fecha Informe",
                                                    "2": this.state.cambbioInicioVenta ? moment(new Date(this.state.selectedDateVenta.toString())).format("DD/MM/YYYY") : moment(new Date()).format("DD/MM/YYYY"),
                                                    "3": "",
                                                    "4": "",
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                })
                                                excel[key].push({
                                                    "1": "Fecha Generacion",
                                                    "2": moment(new Date()).format("DD/MM/YYYY"),
                                                    "3": "",
                                                    "4": "",
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                })
                                                excel[key].push({
                                                    "1": "",
                                                    "2": "",
                                                    "3": "",
                                                    "4": "",
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                })
                                                excel[key].push({
                                                    "1": "No Inicial ",
                                                    "2": "No Final",
                                                    "3": "No de Transacciones",
                                                    "4": "",
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                })
                                                excel[key].push({
                                                    "1": empresa.prefijo + "-" + empresa.no_inicial,
                                                    "2": empresa.prefijo + "-" + empresa.no_final,
                                                    "3": empresa.no_ventas,
                                                    "4": "",
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                })
                                                excel[key].push({
                                                    "1": "",
                                                    "2": "",
                                                    "3": "",
                                                    "4": "",
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                })
                                                excel[key].push({
                                                    "1": "Categoria",
                                                    "2": "Valor Total",
                                                    "3": "Descuento",
                                                    "4": "Excluido",
                                                    "5": "Venta neta",
                                                    "6": "Tarifa",
                                                    "7": "Valor Impuesto ",
                                                })
                                                return (
                                                    <Tab label={empresa.nombre} onClick={() => this.cambiarTabs("valueFacturacionEmpresa", key)} />
                                                )
                                            })
                                            :
                                            console.log("eror en reportes " + utilidad)

                                    }
                                </Tabs>
                            </div>

                            {

                                empresas != null
                                    ?
                                    empresas[this.state.valueFacturacionEmpresa] != null
                                        ?

                                        <div>
                                            <div className="row" style={{ marginTop: '10px' }}>
                                                <div className="col-md-4 mx-auto">
                                                    <label htmlFor="numordencompra" className="col-form-label">RAZON SOCIAL</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueFacturacionEmpresa].razon_social != null ? empresas[this.state.valueFacturacionEmpresa].razon_social : ''} />
                                                </div>
                                                <div className="col-md-3 mx-auto">
                                                    <label htmlFor="numordencompra" className="col-form-label">{tipoDominio === "V" || tipoDominio === "E" ? "ESTACION" : "KIOSCO"}</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueFacturacionEmpresa].nombre != null ? empresas[this.state.valueFacturacionEmpresa].nombre : ''} />
                                                </div>
                                                <div className="col-md-3 mx-auto">
                                                    <label htmlFor="numordencompra" className="col-form-label">NIT</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueFacturacionEmpresa].nit != null ? empresas[this.state.valueFacturacionEmpresa].nit : ''} />
                                                </div>
                                                <div className="col-md-2 mx-auto">
                                                    <label htmlFor="numordencompra" className="col-form-label">CODIGO</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueFacturacionEmpresa].codigo_tienda != null ? empresas[this.state.valueFacturacionEmpresa].codigo_tienda : ''} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                {/* <div className="col-md-4">
                                                    <label htmlFor="numordencompra" className="col-form-label">MAQUINA REGISTRADORA</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueFacturacionEmpresa].serial_pos != null ? empresas[this.state.valueFacturacionEmpresa].serial_pos : ''} />
                            </div>*/}
                                                <div className="col-md-3">
                                                    <label htmlFor="numordencompra" className="col-form-label">FECHA GENERACION</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={moment(new Date()).format("DD/MM/YYYY")} />
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="numordencompra" className="col-form-label">FECHA INFORME</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={this.state.cambbioInicioVenta ? moment(new Date(this.state.selectedDateVenta.toString())).format("DD/MM/YYYY") : moment(new Date()).format("DD/MM/YYYY")} />
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="numordencompra" className="col-form-label">NO. INICIAL</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueFacturacionEmpresa].no_inicial != null ? empresas[this.state.valueFacturacionEmpresa].no_inicial : ''} />
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="numordencompra" className="col-form-label">NO. FINAL</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueFacturacionEmpresa].no_final != null ? empresas[this.state.valueFacturacionEmpresa].no_final : ''} />
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="numordencompra" className="col-form-label">NO. TRANSACCIONES</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueFacturacionEmpresa].no_ventas != null ? empresas[this.state.valueFacturacionEmpresa].no_ventas : ''} />
                                                </div>
                                            </div>

                                            <div className="row">

                                            </div>
                                            <div className="row">
                                                <div className="col col-md-12" >
                                                    <Paper style={{ padding: '5px', width: '100%', marginTop: '10px' }}>
                                                        {
                                                            empresas[this.state.valueFacturacionEmpresa].ventas_x_categoria.map((venta) => {
                                                                var facturacion = {
                                                                    'movimineto': venta.categoria,
                                                                    'moviminetoS': venta.categoria_padre,
                                                                    //'Fecha': moment(new Date(venta.fecha)).format("DD/MM/YYYY HH:mm"),
                                                                    //'operacion': venta.nombres,
                                                                    'tipo2': '$' + this.setearMiles(venta.valor_total),
                                                                    'tipo1': '$' + this.setearMiles(venta.descuento),
                                                                    'tipo3': '$' + this.setearMiles(venta.excluido),
                                                                    'tipo8': '$' + this.setearMiles(parseInt(venta.base_gravable)),
                                                                    'TARIFA': venta.tarifa_iva,
                                                                    'tipo4': '$' + this.setearMiles(parseInt(venta.valor_impuesto)),
                                                                }
                                                                excel[this.state.valueFacturacionEmpresa].push({
                                                                    "1": venta.categoria,
                                                                    "2": '$' + this.setearMiles(venta.valor_total),
                                                                    "3": '$' + this.setearMiles(venta.descuento),
                                                                    "4": '$' + this.setearMiles(venta.excluido),
                                                                    "5": '$' + this.setearMiles(parseInt(venta.base_gravable)),
                                                                    "6": venta.tarifa_iva,
                                                                    "7": '$' + this.setearMiles(parseInt(venta.valor_impuesto)),
                                                                })
                                                                acumulados.ventaTotal = acumulados.ventaTotal + parseFloat(venta.valor_total)
                                                                acumulados.descuento = acumulados.descuento + parseFloat(venta.descuento)
                                                                acumulados.excluido = acumulados.excluido + parseFloat(venta.excluido)
                                                                acumulados.baseGravable = acumulados.baseGravable + parseFloat(venta.base_gravable)
                                                                acumulados.valorImpuesto = acumulados.valorImpuesto + parseFloat(venta.valor_impuesto)
                                                                rowsVentasFechas[this.state.valueFacturacionEmpresa].push(facturacion)

                                                            })
                                                        }
                                                        {
                                                            excel[this.state.valueFacturacionEmpresa].push({
                                                                "1": "",
                                                                "2": "",
                                                                "3": "",
                                                                "4": "",
                                                                "5": "",
                                                                "6": "",
                                                                "7": "",
                                                            })
                                                        }
                                                        {
                                                            excel[this.state.valueFacturacionEmpresa].push({
                                                                "1": "Medios de Pago",
                                                                "2": "Valor",
                                                                "3": "",
                                                                "4": "",
                                                                "5": "",
                                                                "6": "",
                                                                "7": "",
                                                            })
                                                        }
                                                        {
                                                            empresas[this.state.valueFacturacionEmpresa].medios_pagos.map((medio) => {
                                                                excel[this.state.valueFacturacionEmpresa].push({
                                                                    "1": medio.descripcion,
                                                                    "2": "$ " + this.setearMiles(medio.pagos_valor),
                                                                    "3": "",
                                                                    "4": "",
                                                                    "5": "",
                                                                    "6": "",
                                                                    "7": "",
                                                                })
                                                            })
                                                        }
                                                        {
                                                            excel[this.state.valueFacturacionEmpresa].push({
                                                                "1": "Totales",
                                                                "2": "$" + empresas[this.state.valueFacturacionEmpresa].ventas_totales,
                                                                "3": "",
                                                                "4": "",
                                                                "5": "",
                                                                "6": "",
                                                                "7": "",
                                                            })
                                                        }
                                                        {
                                                            excel[this.state.valueFacturacionEmpresa].push({
                                                                "1": "",
                                                                "2": "",
                                                                "3": "",
                                                                "4": "",
                                                                "5": "",
                                                                "6": "",
                                                                "7": "",
                                                            })
                                                        }
                                                        {
                                                            excel[this.state.valueFacturacionEmpresa].push({
                                                                "1": "Serial",
                                                                "2": empresas[this.state.valueFacturacionEmpresa].serial_pos,
                                                                "3": "",
                                                                "4": "",
                                                                "5": "",
                                                                "6": "",
                                                                "7": "",
                                                            })
                                                        }
                                                        {
                                                            excel[this.state.valueFacturacionEmpresa].push({
                                                                "1": "Ubicación",
                                                                "2": empresas[this.state.valueFacturacionEmpresa].ubicacion,
                                                                "3": "",
                                                                "4": "",
                                                                "5": "",
                                                                "6": "",
                                                                "7": "",
                                                            })
                                                        }
                                                        <div className="row">
                                                            <ExcelFile
                                                                filename={"Libro de facturacion " + + moment(new Date()).format("DD/MM/YYYY")}
                                                                element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button>}
                                                            >
                                                                <ExcelSheet data={excel[this.state.valueFacturacionEmpresa]} name="Libro de facturacion">
                                                                    <ExcelColumn label="" value="1" />
                                                                    <ExcelColumn label="" value="2" />
                                                                    <ExcelColumn label="" value="3" />
                                                                    <ExcelColumn label="" value="4" />
                                                                    <ExcelColumn label="" value="5" />
                                                                    <ExcelColumn label="" value="6" />
                                                                    <ExcelColumn label="" value="7" />
                                                                </ExcelSheet>
                                                            </ExcelFile>
                                                            <button className="btn btn-danger" style={{ margin: '10px' }} onClick={() => this.imprimirMasVendidos2("Libro de facturacion")}>
                                                                <img src={pdfIcon} />
                                                            </button>
                                                        </div>
                                                        <MDBDataTable
                                                            //striped
                                                            className="col-md-12 tabla_utilidad"
                                                            searchLabel="Buscar"
                                                            paginationLabel={["Anterior", "Siguente"]}
                                                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                            entriesLabel="Filas a mostrar"
                                                            tbodyColor="primary-color"
                                                            theadColor="#FF8800"
                                                            exportToCSV={true}
                                                            striped
                                                            bordered
                                                            sortable={true}
                                                            autoWidth={false}
                                                            responsive={true}
                                                            responsiveSm={true}
                                                            style={{ fontSize: '13px' }}
                                                            hover
                                                            data={
                                                                {
                                                                    columns: [
                                                                        {
                                                                            label: 'CATEGORIA',
                                                                            field: 'celular',
                                                                            sort: 'asc',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'CAT. PADRE',
                                                                            field: 'identificacion',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'VALOR TOTAL',
                                                                            field: 'cant',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'DESCUENTO',
                                                                            field: 'operacion',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'EXCLUIDO',
                                                                            field: 'q9',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'BASE GRAVABLE',
                                                                            field: 'q8',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'TARIFA',
                                                                            field: 'q88',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'VALOR IMPUESTO',
                                                                            field: 'q8',
                                                                            width: 100
                                                                        }
                                                                    ], rows: rowsVentasFechas[this.state.valueFacturacionEmpresa]
                                                                }
                                                            }
                                                        />

                                                        <div id="capture_cofacturacion" style={{ width: '800px', display: 'block', marginTop: '20px' }}>
                                                            {this.state.imprimirPdf ?
                                                                <table width="712px" border="0" align="center" cellpadding="0" cellspacing="0">
                                                                    <tr>
                                                                        <td width="763px" valign="top" class="fondocuadro">
                                                                            <table width="712" border="0" cellpadding="0" cellspacing="0">
                                                                                <tr>
                                                                                    <td width="171"><img src={logoTerpel} alt="" width="138" height="63" /></td>
                                                                                    <td width="310" align="center"><font class="numero"><b>COMPROBANTE INFORME DIARIO</b></font></td>
                                                                                    <td width="131" align="center"><img src={logoDeuna} width="64" height="64" alt="" /></td>
                                                                                </tr>
                                                                            </table>
                                                                            <table width="100%" border="0" cellspacing="0" cellpadding="2">
                                                                                <tr>
                                                                                    <td><img src={linea1} width="712" height="2" alt="" /></td>
                                                                                </tr>
                                                                            </table>
                                                                            <table width="712" border="0" cellpadding="3" cellspacing="0">
                                                                                <tr>
                                                                                    <td width="124" class="fontipo"><b>RAZON SOCIAL:</b></td>
                                                                                    <td width="245" align="left" valign="top">{empresas[this.state.valueFacturacionEmpresa].razon_social}</td>
                                                                                    <td width="98" align="left" valign="top" class="fontipo"><b>NIT</b></td>
                                                                                    <td width="119" colSpan="3" align="left" valign="top"><span class="numero2">{empresas[this.state.valueFacturacionEmpresa].nit}</span></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td width="124" class="fontipo"><b>{tipoDominio === "E" ? 'ESTACION' : 'KIOSKO:'}</b></td>
                                                                                    <td width="245" align="left" valign="top">{empresas[this.state.valueFacturacionEmpresa].nombre != null ? empresas[this.state.valueFacturacionEmpresa].nombre : ''}</td>
                                                                                    <td width="80" align="left" valign="top" class="fontipo"><b>CODIGO</b></td>
                                                                                    <td width="50" align="left" valign="top"><span class="numero2">{empresas[this.state.valueFacturacionEmpresa].codigo_tienda != null ? empresas[this.state.valueFacturacionEmpresa].codigo_tienda : ''}</span></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td width="124" class="fontipo"><b>NUMERO DE MAQUINA:</b></td>
                                                                                    <td width="245" align="left" valign="top">1</td>
                                                                                </tr>
                                                                            </table>

                                                                            <table width="100%" border="0" cellspacing="0" cellpadding="2">
                                                                                <tr>
                                                                                    <td><img src={linea1} width="712" height="2" alt="" /></td>
                                                                                </tr>
                                                                            </table>
                                                                            <table width="712" border="0" cellpadding="3" cellspacing="0">

                                                                                <tr>
                                                                                    <td colspan="4"><table width="712" border="0" cellspacing="0" cellpadding="3">
                                                                                        <tr>
                                                                                            <td colSpan="2" ><b>Fecha Informe:</b></td>
                                                                                            <td align="left" valign="top" class="numero2"><font class="numero2">{this.state.cambbioInicioVenta ? moment(new Date(this.state.selectedDateVenta.toString())).format("DD/MM/YYYY") : moment(new Date()).format("DD/MM/YYYY")}</font></td>
                                                                                            <td colSpan="2" align="rigth" valign="top"><b>Fecha Generación:</b></td>
                                                                                            <td colSpan="2" align="left" valign="top"><font class="numero2">{moment(new Date()).format("DD/MM/YYYY HH:mm:ss")}</font></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td width="67" align="left"><b>No. Inicial:</b></td>
                                                                                            <td width="109" align="left"><span class="numero2">{empresas[this.state.valueFacturacionEmpresa].no_inicial != null ? empresas[this.state.valueFacturacionEmpresa].prefijo + "-" + empresas[this.state.valueFacturacionEmpresa].no_inicial : ''}</span></td>
                                                                                            <td width="65" align="left"><b>No. Final:</b></td>
                                                                                            <td width="102" align="left"><span class="numero2">{empresas[this.state.valueFacturacionEmpresa].no_final != null ? empresas[this.state.valueFacturacionEmpresa].prefijo + "-" + empresas[this.state.valueFacturacionEmpresa].no_final : ''}</span></td>
                                                                                            <td width="120" align="center"><b>No. de Transacciones:</b></td>
                                                                                            <td width="38" align="center"><span class="numero2">{empresas[this.state.valueFacturacionEmpresa].no_ventas != null ? empresas[this.state.valueFacturacionEmpresa].no_ventas : ''}</span></td>
                                                                                            <td width="90" align="center"><b>Total:</b></td>
                                                                                            <td width="83" align="center"><span class="numero2">{"$ " + this.setearMiles(empresas[this.state.valueFacturacionEmpresa].ventas_totales)}</span></td>
                                                                                        </tr>
                                                                                    </table></td>
                                                                                </tr>

                                                                            </table>
                                                                            <table width="100%" border="0" cellspacing="0" cellpadding="2">
                                                                                <tr>
                                                                                    <td><img src={linea1} width="712" height="2" alt="" /></td>
                                                                                </tr>
                                                                            </table>
                                                                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                                <tr>
                                                                                    <td>&nbsp;</td>
                                                                                </tr>
                                                                            </table>
                                                                            <table width="712" border="0" cellspacing="0" cellpadding="3">
                                                                                <tr>
                                                                                    <td width="133" align="center" bgcolor="#EEEEEE"><b>CATEGORIA</b></td>
                                                                                    <td width="75" align="right" bgcolor="#EEEEEE"><b>V. TOTAL</b></td>
                                                                                    <td width="72" align="right" bgcolor="#EEEEEE"><b>DESCUENTO</b></td>
                                                                                    <td width="68" align="right" bgcolor="#EEEEEE"><b>EXCLUIDO</b></td>
                                                                                    <td width="85" align="right" bgcolor="#EEEEEE"><b>BASE GRAV.</b></td>
                                                                                    <td width="47" align="right" bgcolor="#EEEEEE"><b>TARIFA</b></td>
                                                                                    <td width="91" align="right" bgcolor="#EEEEEE"><b>V. IMPUESTO</b></td>
                                                                                </tr>
                                                                            </table>
                                                                            {rowsVentasFechas[this.state.valueFacturacionEmpresa].map((row) => {
                                                                                return (
                                                                                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                        <tr >
                                                                                            <td width="308" valign="middle" >
                                                                                                <table width="712" border="0" cellspacing="0" cellpadding="3">
                                                                                                    <tr>
                                                                                                        <td width="134" align="left" style={{ fontSize: '9px !important' }}>{row.movimineto}</td>
                                                                                                        <td width="74" align="right" style={{ fontSize: '9px !important' }}>{row.tipo2}</td>
                                                                                                        <td width="72" align="right" style={{ fontSize: '9px !important' }}>{row.tipo1}</td>
                                                                                                        <td width="68" align="right" style={{ fontSize: '9px !important' }}>{row.tipo3}</td>
                                                                                                        <td width="85" align="right" style={{ fontSize: '9px !important' }}>{row.tipo8}</td>
                                                                                                        <td width="47" align="right" style={{ fontSize: '9px !important' }}>{row.TARIFA}</td>
                                                                                                        <td width="91" align="right" style={{ fontSize: '9px !important' }}>{row.tipo4}</td>
                                                                                                    </tr>
                                                                                                </table>
                                                                                                <table width="100%" border="0" cellspacing="0" cellpadding="2">
                                                                                                    <tr>
                                                                                                        <td><img src={linea1} width="712" height="1" alt="" /></td>
                                                                                                    </tr>
                                                                                                </table></td>
                                                                                        </tr>
                                                                                    </table>
                                                                                )
                                                                            }
                                                                            )}
                                                                            <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                <tr >
                                                                                    <td width="308" valign="middle" >
                                                                                        <table width="712" border="0" cellspacing="0" cellpadding="3">
                                                                                            <tr>
                                                                                                <td width="134" align="left" style={{ fontSize: '9px !important' }}><b>TOTALES</b></td>
                                                                                                <td width="74" align="right" style={{ fontSize: '9px !important' }}><b>{`$ ${this.setearMiles(parseInt(acumulados.ventaTotal))}`}</b></td>
                                                                                                <td width="72" align="right" style={{ fontSize: '9px !important' }}><b>{`$ ${this.setearMiles(parseInt(acumulados.descuento))}`}</b></td>
                                                                                                <td width="68" align="right" style={{ fontSize: '9px !important' }}><b>{`$ ${this.setearMiles(parseInt(acumulados.excluido))}`}</b></td>
                                                                                                <td width="85" align="right" style={{ fontSize: '9px !important' }}><b>{`$ ${this.setearMiles(parseInt(acumulados.baseGravable))}`}</b></td>
                                                                                                <td width="47" align="right" style={{ fontSize: '9px !important' }}><b>{` - `}</b></td>
                                                                                                <td width="91" align="right" style={{ fontSize: '9px !important' }}><b>{`$ ${this.setearMiles(parseInt(acumulados.valorImpuesto))}`}</b></td>
                                                                                            </tr>
                                                                                        </table>
                                                                                        <table width="100%" border="0" cellspacing="0" cellpadding="2">
                                                                                            <tr>
                                                                                                <td><img src={linea1} width="712" height="1" alt="" /></td>
                                                                                            </tr>
                                                                                        </table></td>
                                                                                </tr>
                                                                            </table>

                                                                            <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                <tr >
                                                                                    <td valign="middle" >&nbsp;</td>
                                                                                </tr>
                                                                                <tr >
                                                                                    <td valign="middle" ><img src="line.jpg" width="610" height="2" alt="" /></td>
                                                                                </tr>
                                                                                <tr >
                                                                                    <td width="308" valign="middle" height="5"></td>
                                                                                </tr>
                                                                            </table>
                                                                            <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                <tr >
                                                                                    <td width="297" valign="middle" ><table width="270" border="0" cellpadding="3" cellspacing="0">
                                                                                        <tr >
                                                                                            <td align="left" valign="middle" bgcolor="#EEEEEE"><b>MEDIOS DE PAGO</b></td>
                                                                                            <td align="center" valign="middle" class="numero2" bgcolor="#EEEEEE">VALOR</td>
                                                                                            <td align="center" valign="middle" class="numero2" bgcolor="#EEEEEE">TRANSACCIONES</td>
                                                                                        </tr>
                                                                                        {
                                                                                            empresas[this.state.valueFacturacionEmpresa].medios_pagos.map((medio) => {
                                                                                                return (
                                                                                                    <tr >
                                                                                                        <td align="left" valign="middle" bgcolor="#F7F7F7"><b>{medio.descripcion}</b></td>
                                                                                                        <td align="center" valign="middle" class="numero2" bgcolor="#F7F7F7">{"$ " + this.setearMiles(medio.pagos_valor)}</td>
                                                                                                        <td align="center" valign="middle" class="numero2" bgcolor="#F7F7F7">{empresas[this.state.valueFacturacionEmpresa].no_ventas != null ? empresas[this.state.valueFacturacionEmpresa].no_ventas : ''}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        }

                                                                                        <tr >
                                                                                            <td width="127" align="left" valign="middle" bgcolor="#EEEEEE"><b>TOTALES</b></td>
                                                                                            <td width="170" align="center" valign="middle" bgcolor="#EEEEEE" class="numero2">{"$ " + this.setearMiles(empresas[this.state.valueFacturacionEmpresa].ventas_totales)}</td>
                                                                                            <td align="center" valign="middle" class="numero2" bgcolor="#F7F7F7">{empresas[this.state.valueFacturacionEmpresa].no_ventas != null ? empresas[this.state.valueFacturacionEmpresa].no_ventas : ''}</td>
                                                                                        </tr>
                                                                                    </table></td>
                                                                                    <td width="419" valign="top" ><table width="413" border="0" cellpadding="3" cellspacing="0">
                                                                                        <tr >
                                                                                            <td width="72" align="left" valign="middle" bgcolor="#F7F7F7"><b>SERIAL</b></td>
                                                                                            <td width="229" align="left" valign="middle" class="numero2" bgcolor="#F7F7F7">{empresas[this.state.valueFacturacionEmpresa].serial_pos}</td>
                                                                                        </tr>
                                                                                        <tr >
                                                                                            <td align="left" valign="middle" bgcolor="#EEEEEE"><b>UBICACION</b></td>
                                                                                            <td align="left" valign="middle" class="numero2" bgcolor="#EEEEEE">{empresas[this.state.valueFacturacionEmpresa].ubicacion}</td>
                                                                                        </tr>
                                                                                    </table></td>
                                                                                </tr>
                                                                            </table>
                                                                            <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                <tr >
                                                                                    <td width="308" height="5" valign="middle" ></td>
                                                                                </tr>
                                                                            </table><table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                                <tr>
                                                                                    <td><img src="line.jpg" width="610" height="2" alt="" /></td>
                                                                                </tr>
                                                                            </table>
                                                                            <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                <tr >
                                                                                    <td width="308" valign="middle" >&nbsp;</td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                /*<table className="tb-terpel" width="712px" height="773px" border="0" align="center" cellpadding="0" cellspacing="0">
                                                                    <tr>
                                                                        <td width="763px" valign="top" className="fondocuadro">
                                                                            <table width="712px" border="0" cellpadding="0" cellspacing="0">
                                                                                <tr>
                                                                                    <td width="169"><img src={logoTerpel} width="138" height="63" alt="" /></td>
                                                                                    <td width="335" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                                        <tr>
                                                                                            <td align="center">{empresas[this.state.valueFacturacionEmpresa].razon_social}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td align="center" className="numero2" >{'Nit. ' + empresas[this.state.valueFacturacionEmpresa].nit}</td>
                                                                                        </tr>
                                                                                    </table></td>
                                                                                    <td width="106" align="center"><img src={logoDeuna} width="64" height="64" alt="" /></td>
                                                                                </tr>

                                                                            </table>
                                                                            <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                                                <tr>
                                                                                    <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                                                </tr>
                                                                            </table>
                                                                            <table width="712px" border="0" cellpadding="0" cellspacing="3">
                                                                                <tr>
                                                                                    <td width="299" valign="top" className="numero2" ><b>Fecha de generacion:</b> {moment(new Date()).format("DD/MM/YYYY")}</td>
                                                                                    <td width="66" align="left" valign="top"><b>&nbsp; </b></td>
                                                                                    <td width="233" align="left" valign="top">&nbsp;</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td valign="top" className="numero2" ><b >Fecha de informe: </b>{this.state.cambbioInicioVenta ? moment(new Date(this.state.selectedDateVenta.toString())).format("DD/MM/YYYY") : moment(new Date()).format("DD/MM/YYYY")}</td>
                                                                                    <td align="left" valign="top"></td>
                                                                                    <td align="left" valign="top"><font className="numero2"></font></td>
                                                                                </tr>
                                                                            </table>
                                                                            <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                                                <tr>
                                                                                    <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                                                </tr>
                                                                            </table>
                                                                            <table width="712px" border="0" cellpadding="3" cellspacing="0">
                                                                                <tr>
                                                                                    <td align="center" width="120" className="fontipo"><b>No. Inicial</b></td>
                                                                                    <td align="center" width="120" className="fontipo"><b>No. Final</b></td>
                                                                                    <td align="center" width="120" className="fontipo"><b>No. De transacciones</b></td>
                                                                                    <td width="219" align="left" valign="top">&nbsp;</td>
                                                                                    <td width="66" align="left" valign="top">&nbsp;</td>
                                                                                    <td width="233" align="left" valign="top">&nbsp;</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="center" width="120" className="numero2"><b>{empresas[this.state.valueFacturacionEmpresa].no_inicial != null ? empresas[this.state.valueFacturacionEmpresa].prefijo + "-" + empresas[this.state.valueFacturacionEmpresa].no_inicial : ''}</b></td>
                                                                                    <td align="center" width="120" className="numero2"><b>{empresas[this.state.valueFacturacionEmpresa].no_final != null ? empresas[this.state.valueFacturacionEmpresa].prefijo + "-" + empresas[this.state.valueFacturacionEmpresa].no_final : ''}</b></td>
                                                                                    <td align="center" width="120" className="numero2"><b>{empresas[this.state.valueFacturacionEmpresa].no_ventas != null ? empresas[this.state.valueFacturacionEmpresa].no_ventas : ''}</b></td>
                                                                                    <td width="219" align="left" valign="top">&nbsp;</td>
                                                                                    <td width="66" align="left" valign="top">&nbsp;</td>
                                                                                    <td width="233" align="left" valign="top">&nbsp;</td>
                                                                                </tr>
                                                                            </table>

                                                                            <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                                                <tr>
                                                                                    <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                                                </tr>
                                                                            </table>
                                                                            <table width="712px" border="0" cellspacing="0" cellpadding="3">
                                                                                <tr>
                                                                                    <td width="100" align="center" bgcolor="#EEEEEE"><b>CATEGORIA</b></td>
                                                                                    <td width="40" align="right" valign="right" bgcolor="#EEEEEE"><b>V. TOTAL</b></td>
                                                                                    <td width="37" align="right" valign="right" bgcolor="#EEEEEE"><b>DESCUENTO</b></td>
                                                                                    <td width="46" align="right" valign="right" bgcolor="#EEEEEE"><b>EXCLUIDO</b></td>
                                                                                    <td width="70" align="right" valign="right" bgcolor="#EEEEEE"><b>BASE G.</b></td>
                                                                                    <td width="36" align="right" valign="right" bgcolor="#EEEEEE"><b>TARIFA</b></td>
                                                                                    <td width="70" align="right" valign="right" bgcolor="#EEEEEE"><b>V. IMP.</b></td>
                                                                                </tr>
                                                                            </table>
                                                                            {rowsVentasFechas[this.state.valueFacturacionEmpresa].map((row) => {
                                                                                console.log(row)
                                                                                return (
                                                                                    <div>
                                                                                        <table width="712px" border="0" cellspacing="0" cellpadding="3" >
                                                                                            <tr>
                                                                                                <td width="100" align="center" valign="middle" className="numero2">{row.movimineto}</td>
                                                                                                <td width="40" valign="right" align="right" className="numero2">{row.tipo2}</td>
                                                                                                <td width="37" align="right" valign="right" className="numero2">{row.tipo1}</td>
                                                                                                <td width="46" align="right" valign="right" className="numero2">{row.tipo3}</td>
                                                                                                <td width="70" align="right" valign="right" className="numero2">{row.tipo8}</td>
                                                                                                <td width="36" align="right" valign="right" className="numero2">{row.TARIFA}</td>
                                                                                                <td width="70" align="right" valign="right" className="numero2">{row.tipo4}</td>
                                                                                            </tr>
                                                                                        </table>
                                                                                        <table className="tb-2" width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                                            <tr>
                                                                                                <td><img src={linea2} width="712px" height="5" alt="" /></td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            )}

                                                                            <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                                                <tr>
                                                                                    <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                                                </tr>
                                                                            </table>
                                                                            <table width="712px" border="0" cellpadding="3" cellspacing="0">
                                                                                <tr>
                                                                                    <td align="center" width="120" className="fontipo"><b>Medios de pago</b></td>
                                                                                    <td align="center" width="120" className="fontipo"><b>Valor</b></td>
                                                                                    <td width="219" align="left" valign="top">&nbsp;</td>
                                                                                    <td width="66" align="left" valign="top">&nbsp;</td>
                                                                                    <td width="233" align="left" valign="top">&nbsp;</td>
                                                                                </tr>
                                                                                {
                                                                                    empresas[this.state.valueFacturacionEmpresa].medios_pagos.map((medio) => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td align="center" width="120" className="numero2"><b>{medio.descripcion}</b></td>
                                                                                                <td align="center" width="120" className="numero2"><b>{"$ " + this.setearMiles(medio.pagos_valor)}</b></td>
                                                                                                <td width="219" align="left" valign="top">&nbsp;</td>
                                                                                                <td width="66" align="left" valign="top">&nbsp;</td>
                                                                                                <td width="233" align="left" valign="top">&nbsp;</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <tr>
                                                                                    <td align="center" width="120" className="numero2"><b>{'Totales'}</b></td>
                                                                                    <td align="center" width="120" className="numero2"><b>{"$ " + this.setearMiles(empresas[this.state.valueFacturacionEmpresa].ventas_totales)}</b></td>
                                                                                    <td width="219" align="left" valign="top">&nbsp;</td>
                                                                                    <td width="66" align="left" valign="top">&nbsp;</td>
                                                                                    <td width="233" align="left" valign="top">&nbsp;</td>
                                                                                </tr>
                                                                            </table>
                                                                            <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                                                <tr>
                                                                                    <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                                                </tr>
                                                                            </table>
                                                                            <table width="712px" border="0" cellpadding="0" cellspacing="3">
                                                                                <tr>
                                                                                    <td width="299" valign="top" className="numero2" ><b>Serial:</b> {empresas[this.state.valueFacturacionEmpresa].serial_pos}</td>
                                                                                    <td width="66" align="left" valign="top"><b>&nbsp; </b></td>
                                                                                    <td width="233" align="left" valign="top">&nbsp;</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td valign="top" className="numero2" ><b >Ubicacion: </b>{empresas[this.state.valueFacturacionEmpresa].ubicacion}</td>
                                                                                    <td align="left" valign="top"></td>
                                                                                    <td align="left" valign="top"><font className="numero2"></font></td>
                                                                                </tr>
                                                                            </table>

                                                                        </td>
                                                                    </tr>
                                                                </table>*/
                                                                :
                                                                null}
                                                        </div>
                                                    </Paper>

                                                </div>
                                            </div>
                                            <div className="row">
                                                {
                                                    empresas[this.state.valueFacturacionEmpresa].medios_pagos.map((medio) => {
                                                        return (
                                                            <div className="col-md-3">
                                                                <label htmlFor="numordencompra" className="col-form-label">{medio.descripcion}</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={"$ " + this.setearMiles(medio.pagos_valor)} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className="col-md-3">
                                                    <label htmlFor="numordencompra" className="col-form-label">TOTALES</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={'$' + (empresas[this.state.valueFacturacionEmpresa].ventas_totales != null ? this.setearMiles(empresas[this.state.valueFacturacionEmpresa].ventas_totales) : '')} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <label htmlFor="numordencompra" className="col-form-label">SERIAL</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueFacturacionEmpresa].serial_pos != null ? empresas[this.state.valueFacturacionEmpresa].serial_pos : ''} />
                                                </div>
                                                <div className="col-md-9">
                                                    <label htmlFor="numordencompra" className="col-form-label">UBICACION</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueFacturacionEmpresa].ubicacion != null ? empresas[this.state.valueFacturacionEmpresa].ubicacion : ''} />
                                                </div>
                                            </div>
                                        </div>

                                        :
                                        console.log("eror en empresa categorias" + empresas[this.state.valueUtilidadEmpresa])
                                    :
                                    console.log("eror en empresa " + empresas)
                            }

                        </div >
                )
                break;
            case 6:
                var inventario = this.state.valorInventario
                var tablas = []
                var excel = []
                var empresas = []
                var rowsVentasFechas = []
                var numCategorias = 0
                console.log(inventario)
                return (
                    this.state.vistaCargando ?
                        <Loader />
                        :
                        <div style={{ paddingRight: '25px', paddingLeft: '25px', paddingBottom: '25px' }} >

                            {
                                usuario.tipo === "5"
                                    ?
                                    <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                        <div className="col-md-4">
                                            <label htmlFor="numordencompra" className="col-form-label">BODEGA</label>
                                            <Selects
                                                value={this.state.bodegaKardexSeleccionada}
                                                onChange={this.handleChanges('bodegaKardexSeleccionada')}
                                                options={this.state.bodegasbd}
                                                isMulti
                                            />
                                        </div>
                                        <div className="col-md-5">
                                            <label htmlFor="numordencompra" className="col-form-label">PRODUCTO</label>
                                            <Selects
                                                value={this.state.ProductoKardexSeleccionado}
                                                onChange={this.handleChanges('ProductoKardexSeleccionado')}
                                                options={this.state.ProductosDeBodegabd}
                                                isMulti
                                            />
                                        </div>
                                        <div className="col-md-3  bottom-aligned">
                                            <button className="btn btn-success btn-block" onClick={() => this.consultaCostoInventario()}>CONSULTAR</button>
                                        </div>
                                    </div>
                                    :
                                    <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>

                                        <div className="col-md-6">
                                            <label htmlFor="numordencompra" className="col-form-label">EMPRESAS</label>
                                            <Selects
                                                value={this.state.multiEmpresasVentasSeleccionadas}
                                                onChange={this.handleChanges('multiEmpresasVentasSeleccionadas')}
                                                options={this.state.empresasbd}
                                                isMulti
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="numordencompra" className="col-form-label">BODEGA</label>
                                            <Selects
                                                value={this.state.bodegaKardexSeleccionada}
                                                onChange={this.handleChanges('bodegaKardexSeleccionada')}
                                                options={this.state.bodegasVInventariobd}
                                                isMulti
                                            />
                                        </div>
                                        <div className="col-md-8">
                                            <label htmlFor="numordencompra" className="col-form-label">PRODUCTO</label>
                                            <Selects
                                                value={this.state.ProductoKardexSeleccionado}
                                                onChange={this.handleChanges('ProductoKardexSeleccionado')}
                                                options={this.state.ProductosDeBodegabd}
                                                isMulti
                                            />
                                        </div>
                                        <div className="col-md-4  bottom-aligned">
                                            <button className="btn btn-success btn-block" onClick={() => this.consultaCostoInventario()}>CONSULTAR</button>
                                        </div>
                                    </div>
                            }

                            <div className="row">
                                <Tabs
                                    value={this.state.valueInventarioEmpresa}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="disabled tabs example"
                                >


                                    {
                                        inventario != null
                                            ?
                                            inventario.map((empresa, key) => {
                                                empresas.push(empresa)
                                                rowsVentasFechas[key] = []
                                                excel[key] = []
                                                excel[key].push({
                                                    "1": "RAZON SOCIAL",
                                                    "2": "ALIAS",
                                                    "3": "NIT",
                                                    "4": "COSTO",
                                                    "5": "VENTA",
                                                    "6": "",
                                                    "7": "",
                                                    "8": "",
                                                })
                                                excel[key].push({
                                                    "1": empresa.razon_social,
                                                    "2": empresa.nombre,
                                                    "3": empresa.nit,
                                                    "4": "$" + this.setearMiles(empresa.valor_costo_empresa),
                                                    "5": "$" + this.setearMiles(empresa.valor_venta_empresa),
                                                    "6": "",
                                                    "7": "",
                                                    "8": "",
                                                })
                                                excel[key].push({
                                                    "1": "",
                                                    "2": "",
                                                    "3": "",
                                                    "4": "",
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                    "8": "",
                                                })
                                                excel[key].push({
                                                    "1": "BODEGA",
                                                    "2": "PLU",
                                                    "3": "PRODUCTO",
                                                    "4": "CANT.",
                                                    "5": "COSTO",
                                                    "6": "COSTO EN BOD.",
                                                    "7": "PRECIO",
                                                    "8": "VENTA EN BOD.",
                                                })

                                                empresa.costo_y_venta_bodega.map((bodega) => {
                                                    bodega.costo_y_venta_producto.map((producto) => {
                                                        excel[key].push({
                                                            '1': bodega.nombre_bodega,
                                                            '2': parseInt(producto.plu),
                                                            '3': producto.nombre_producto,
                                                            '4': producto.cantidad,
                                                            '5': '$' + this.setearMiles(producto.costo_producto),
                                                            '6': '$' + this.setearMiles(producto.costo_bodega),
                                                            '7': '$' + this.setearMiles(producto.precio_producto),
                                                            '8': '$' + this.setearMiles(producto.venta_bodega),
                                                        })

                                                    })
                                                })
                                                excel[key].push({
                                                    "1": "",
                                                    "2": "",
                                                    "3": "",
                                                    "4": "",
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                    "8": "",
                                                })
                                                excel[key].push({
                                                    "1": "",
                                                    "2": "",
                                                    "3": "",
                                                    "4": "",
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                    "8": "",
                                                })
                                                excel[key].push({
                                                    "1": "BODEGA",
                                                    "2": "COSTO BODEGA",
                                                    "3": "VENTA BODEGA",
                                                    "4": "",
                                                    "5": "",
                                                    "6": "",
                                                    "7": "",
                                                    "8": "",
                                                })
                                                empresa.costo_y_venta_bodega.map((bodega) => {
                                                    excel[key].push({
                                                        "1": bodega.nombre_bodega,
                                                        "2": '$' + this.setearMiles(bodega.valor_costo_bodega),
                                                        "3": '$' + this.setearMiles(bodega.valor_venta_bodega),
                                                        "4": "",
                                                        "5": "",
                                                        "6": "",
                                                        "7": "",
                                                        "8": "",
                                                    })
                                                })

                                                console.log(empresa)
                                                return (
                                                    <Tab label={empresa.nombre} onClick={() => this.cambiarTabs("valueInventarioEmpresa", key)} />
                                                )
                                            })
                                            :
                                            console.log("eror en reportes " + inventario)

                                    }
                                </Tabs>
                            </div>

                            {

                                empresas != null
                                    ?
                                    empresas[this.state.valueInventarioEmpresa] != null
                                        ?

                                        <div>
                                            <div className="row" style={{ marginTop: '10px' }}>
                                                <ExcelFile
                                                    filename={"Valor en inventario " + moment(new Date()).format("DD/MM/YYYY")}
                                                    element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button>}
                                                >
                                                    <ExcelSheet data={excel[this.state.valueInventarioEmpresa]} name="Valor en inventario">
                                                        <ExcelColumn label="" value="1" />
                                                        <ExcelColumn label="" value="2" />
                                                        <ExcelColumn label="" value="3" />
                                                        <ExcelColumn label="" value="4" />
                                                        <ExcelColumn label="" value="5" />
                                                        <ExcelColumn label="" value="6" />
                                                        <ExcelColumn label="" value="7" />
                                                        <ExcelColumn label="" value="8" />
                                                    </ExcelSheet>
                                                </ExcelFile>
                                            </div>
                                            <div className="row" style={{ marginTop: '10px' }}>
                                                <div className="col-md-4 mx-auto">
                                                    <label htmlFor="numordencompra" className="col-form-label">RAZON SOCIAL</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueInventarioEmpresa].razon_social != null ? empresas[this.state.valueInventarioEmpresa].razon_social : ''} />
                                                </div>
                                                <div className="col-md-2 mx-auto">
                                                    <label htmlFor="numordencompra" className="col-form-label">NIT</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueInventarioEmpresa].nit != null ? empresas[this.state.valueInventarioEmpresa].nit : ''} />
                                                </div>
                                                <div className="col-md-3 mx-auto">
                                                    <label htmlFor="numordencompra" className="col-form-label">COSTO</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueInventarioEmpresa].valor_costo_empresa != null ? '$' + this.setearMiles(empresas[this.state.valueInventarioEmpresa].valor_costo_empresa) : ''} />
                                                </div>
                                                <div className="col-md-3 mx-auto">
                                                    <label htmlFor="numordencompra" className="col-form-label">VENTA</label>
                                                    <input id="numordencompra" disabled className="form-control" type="text" value={empresas[this.state.valueInventarioEmpresa].valor_venta_empresa != null ? '$' + this.setearMiles(empresas[this.state.valueInventarioEmpresa].valor_venta_empresa) : ''} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col col-md-12" >
                                                    <Paper style={{ padding: '5px', width: '100%', marginTop: '10px' }}>
                                                        {
                                                            empresas[this.state.valueInventarioEmpresa].costo_y_venta_bodega.map((bodega) => {
                                                                bodega.costo_y_venta_producto.map((producto) => {
                                                                    var facturacion = {
                                                                        'bodega': bodega.nombre_bodega,
                                                                        'plu': parseInt(producto.plu),
                                                                        'producto': producto.nombre_producto,
                                                                        'cantidad': producto.cantidad,
                                                                        'costoProducto': '$' + this.setearMiles(producto.costo_producto),
                                                                        'costoEnBodega': '$' + this.setearMiles(producto.costo_bodega),
                                                                        'precioProducto': '$' + this.setearMiles(producto.precio_producto),
                                                                        'precioEnBodega': '$' + this.setearMiles(producto.venta_bodega),
                                                                    }
                                                                    rowsVentasFechas[this.state.valueInventarioEmpresa].push(facturacion)
                                                                })
                                                            })
                                                        }
                                                        <MDBDataTable
                                                            //striped
                                                            className="col-md-12 tabla_utilidad"
                                                            searchLabel="Buscar"
                                                            paginationLabel={["Anterior", "Siguente"]}
                                                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                            entriesLabel="Filas a mostrar"
                                                            tbodyColor="primary-color"
                                                            theadColor="#FF8800"
                                                            exportToCSV={true}
                                                            striped
                                                            bordered
                                                            sortable={true}
                                                            autoWidth={false}
                                                            responsive={true}
                                                            responsiveSm={true}
                                                            style={{ fontSize: '13px' }}
                                                            order={['plu', 'asc']}
                                                            hover
                                                            data={
                                                                {
                                                                    columns: [
                                                                        {
                                                                            label: 'BODEGA',
                                                                            field: 'celular',
                                                                            sort: 'asc',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'PLU',
                                                                            field: 'plu',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'PRODUCTO',
                                                                            field: 'cant',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'CANT.',
                                                                            field: 'cantidad',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'COSTO',
                                                                            field: 'operacion',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'COSTO EN BOD.',
                                                                            field: 'q9',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'PRECIO',
                                                                            field: 'q8',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'VENTA EN BOD.',
                                                                            field: 'q88',
                                                                            width: 100
                                                                        }
                                                                    ], rows: rowsVentasFechas[this.state.valueInventarioEmpresa]
                                                                }
                                                            }
                                                        />

                                                    </Paper>

                                                </div>
                                            </div>
                                            {
                                                empresas[this.state.valueInventarioEmpresa].costo_y_venta_bodega.map((bodega) => {
                                                    return (
                                                        <div className="row" style={{ marginTop: '10px' }}>
                                                            <div className="col-md-6 mx-auto">
                                                                <label htmlFor="numordencompra" className="col-form-label">BODEGA</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={bodega.nombre_bodega != null ? bodega.nombre_bodega : ''} />
                                                            </div>
                                                            <div className="col-md-3 mx-auto">
                                                                <label htmlFor="numordencompra" className="col-form-label">COSTO BODEGA</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={bodega.valor_costo_bodega != null ? '$' + this.setearMiles(bodega.valor_costo_bodega) : ''} />
                                                            </div>
                                                            <div className="col-md-3 mx-auto">
                                                                <label htmlFor="numordencompra" className="col-form-label">VENTA BODEGA</label>
                                                                <input id="numordencompra" disabled className="form-control" type="text" value={bodega.valor_venta_bodega != null ? '$' + this.setearMiles(bodega.valor_venta_bodega) : ''} />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>

                                        :
                                        console.log("eror en empresa categorias" + empresas[this.state.valueInventarioEmpresa])
                                    :
                                    console.log("eror en empresa " + empresas)
                            }

                        </div >
                )
                break;
            case 7:
                var arqueos = this.state.arqueos
                var tablas = []
                var excel = []
                var empresas = []
                var rowsArqueo = []
                var arqueoSeleccionado = null
                if (this.state.detallesArqueos != null) {
                    arqueoSeleccionado = this.state.detallesArqueos
                }
                console.log(arqueos)
                return (
                    this.state.vistaCargando ?
                        <Loader />
                        :
                        <div style={{ paddingRight: '25px', paddingLeft: '25px', paddingBottom: '25px' }} >

                            {
                                usuario.tipo === "5"
                                    ?
                                    <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                        <div className="col-md-4">
                                            <label htmlFor="numordencompra" className="col-form-label">Fecha</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                <DatePicker
                                                    format="yyyy-MM-dd"
                                                    id="datetime"
                                                    value={this.state.selectedDateVenta}
                                                    onChange={this.handleChangemomentventa}
                                                    ampm={false}
                                                    disableFuture={true}
                                                    disabled={true}
                                                    className="rounded form-control"
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-8  bottom-aligned">
                                            <button className="btn btn-success btn-block" onClick={() => this.consultaArqueo()}>CONSULTAR</button>
                                        </div>
                                    </div>
                                    :
                                    <div className="row" style={{ padding: '10px', margin: ' 0px 10px' }}>
                                        <div className="col-md-2">
                                            <label htmlFor="numordencompra" className="col-form-label">Fecha</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                                <DatePicker
                                                    format="yyyy-MM-dd"
                                                    id="datetime"
                                                    value={this.state.selectedDateVenta}
                                                    onChange={this.handleChangemomentventa}
                                                    ampm={false}
                                                    disabled={true}
                                                    disableFuture={true}
                                                    className="rounded form-control"
                                                    style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-7">
                                            <label htmlFor="numordencompra" className="col-form-label">EMPRESA</label>
                                            <Selects
                                                value={this.state.multiEmpresasVentasSeleccionadas}
                                                onChange={this.handleChanges('multiEmpresasVentasSeleccionadas')}
                                                options={this.state.empresasbd}
                                                isMulti
                                            />
                                        </div>
                                        <div className="col-md-3  bottom-aligned">
                                            <button className="btn btn-success btn-block" onClick={() => this.consultaArqueo()}>CONSULTAR</button>
                                        </div>
                                    </div>
                            }

                            {

                                arqueos != null
                                    ?
                                    arqueos.empresas_arqueo != null
                                        ?

                                        <div>



                                            <div className="row">
                                                <div className="col col-md-12" >
                                                    <Paper style={{ padding: '5px', width: '100%', marginTop: '10px' }}>
                                                        {
                                                            arqueos.empresas_arqueo != null ?
                                                                arqueos.empresas_arqueo.map((empresa) => {
                                                                    empresa.personas_arqueo.map((arqueo) => {
                                                                        var facturacion = {
                                                                            'tienda': empresa.alias,
                                                                            'responsable': arqueo.nombres,
                                                                            'fecha': moment(arqueo.fecha_inicio).format("DD/MM/YYYY hh:mm a"),
                                                                            'base': '$' + this.setearMiles(arqueo.base),
                                                                            'tipo1': '$' + this.setearMiles(arqueo.ventas_total),
                                                                            'tipo3': <button className="btn btn-sm btn-primary" style={{ marginRight: '5px' }} onClick={() => this.detalleArqueo(arqueo)}><Ojoicon /></button>,
                                                                        }
                                                                        rowsArqueo.push(facturacion)

                                                                    })
                                                                })
                                                                : null
                                                        }
                                                        {
                                                            arqueos.empresas_arqueo != null ?
                                                                arqueos.empresas_arqueo.map((empresa) => {
                                                                    empresa.personas_arqueo.map((arqueo) => {
                                                                        arqueo.productos_arqueo.map((producto) => {
                                                                            console.log(producto)
                                                                            excel.push({
                                                                                '1': empresa.alias,
                                                                                '2': arqueo.nombres,
                                                                                '3': moment(arqueo.fecha_inicio).format("DD/MM/YYYY hh:mm a"),
                                                                                '4': producto.plu,
                                                                                '5': producto.nombre_producto,
                                                                                '6': producto.cantidad,
                                                                                '7': '$' + this.setearMiles(producto.precio),
                                                                                '8': '$' + this.setearMiles(producto.venta_plu),
                                                                                '9': '$' + this.setearMiles(arqueo.base),
                                                                                '10': '$' + this.setearMiles(arqueo.ventas_total),
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                                : null
                                                        }
                                                        <ExcelFile
                                                            filename={"Arqueo " + moment(new Date()).format("DD/MM/YYYY")}
                                                            element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button>}
                                                        >
                                                            <ExcelSheet data={excel} name="Arqueo">
                                                                <ExcelColumn label="TIENDA" value="1" />
                                                                <ExcelColumn label="PROMOTOR" value="2" />
                                                                <ExcelColumn label="FECHA DE INICIO" value="3" />
                                                                <ExcelColumn label="PLU" value="4" />
                                                                <ExcelColumn label="PRODUCTO" value="5" />
                                                                <ExcelColumn label="CANTIDAD" value="6" />
                                                                <ExcelColumn label="PRECIO PRODUCTO" value="7" />
                                                                <ExcelColumn label="VENTA PRODUCTO" value="8" />
                                                                <ExcelColumn label="BASE DE INICIO" value="9" />
                                                                <ExcelColumn label="VENTAS TOTALES" value="10" />
                                                            </ExcelSheet>
                                                        </ExcelFile>
                                                        <MDBDataTable
                                                            //striped
                                                            className="col-md-12 tabla_utilidad"
                                                            searchLabel="Buscar"
                                                            paginationLabel={["Anterior", "Siguente"]}
                                                            infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                            entriesLabel="Filas a mostrar"
                                                            tbodyColor="primary-color"
                                                            theadColor="#FF8800"
                                                            exportToCSV={true}
                                                            striped
                                                            bordered
                                                            sortable={true}
                                                            autoWidth={false}
                                                            responsive={true}
                                                            responsiveSm={true}
                                                            hover
                                                            data={
                                                                {
                                                                    columns: [
                                                                        {
                                                                            label: 'Tienda',
                                                                            field: 'celular',
                                                                            sort: 'asc',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'Responsable',
                                                                            field: 'identificacion',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'Fecha inicio',
                                                                            field: 'cant',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'Base',
                                                                            field: 'base',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'Total de ventas',
                                                                            field: 'operacion',
                                                                            width: 100
                                                                        }, {
                                                                            label: 'Ver',
                                                                            field: 'q9',
                                                                            width: 100
                                                                        }
                                                                    ], rows: rowsArqueo
                                                                }
                                                            }
                                                        />


                                                        <Dialog
                                                            open={this.state.dialogoArqueos}
                                                            aria-labelledby="form-dialog-title"
                                                            maxWidth={'lg'}
                                                            fullWidth={true}
                                                            style={{ padding: '0' }}
                                                            disableBackdropClick={false}
                                                        >
                                                            <DialogContent style={{ padding: '0' }}>
                                                                <div className="row mx-auto" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                                    <div className="col col-md-11 mx-auto" style={{ padding: 0 }}>
                                                                        <Paper style={{ padding: '10px' }}>
                                                                            <div className="row">
                                                                                <button className="btn btn-danger " onClick={() => this.cerrarAqueo()} style={{ margin: '10px', float: 'right' }}>CERRAR</button>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-3">
                                                                                    <label htmlFor="numordencompra" className="col-form-label">IDENTIFICACION</label>
                                                                                    <input id="numordencompra" disabled={true} value={arqueoSeleccionado != null ? arqueoSeleccionado.identificacion : '000'} className="form-control" />
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <label htmlFor="numordencompra" className="col-form-label">FECHA INICIO</label>
                                                                                    <input id="numordencompra" disabled={true} value={arqueoSeleccionado != null ? moment(new Date(arqueoSeleccionado.fecha_inicio)).format("DD/MM/YYYY hh:mm a") : ''} className="form-control" />
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="numordencompra" className="col-form-label">PROMOTOR</label>
                                                                                    <input id="numordencompra" disabled={true} value={arqueoSeleccionado != null ? arqueoSeleccionado.nombres : ''} className="form-control" />
                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    <label htmlFor="numordencompra" className="col-form-label">TOTAL VENTA</label>
                                                                                    <input id="numordencompra" disabled={true} value={arqueoSeleccionado != null ? '$' + this.setearMiles(arqueoSeleccionado.ventas_total) : ''} className="form-control" />
                                                                                </div>
                                                                            </div>
                                                                            <MDBDataTable
                                                                                //striped
                                                                                className="col-md-12 mx-auto"
                                                                                searchLabel="Buscar"
                                                                                paginationLabel={["Anterior", "Siguente"]}
                                                                                infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                                entriesLabel="Filas a mostrar"
                                                                                tbodyColor="primary-color"
                                                                                theadColor="#FF8800"
                                                                                exportToCSV={true}
                                                                                striped
                                                                                bordered
                                                                                autoWidth={false}
                                                                                responsive={true}
                                                                                hover
                                                                                order={['plu', 'asc']}
                                                                                data={
                                                                                    {
                                                                                        columns: [
                                                                                            {
                                                                                                label: 'Plu',
                                                                                                field: 'plu',
                                                                                                sort: 'asc',
                                                                                                width: 150
                                                                                            }, {
                                                                                                label: 'Descripcion',
                                                                                                field: 'descr',
                                                                                                width: 150
                                                                                            }, {
                                                                                                label: 'Precio',
                                                                                                field: 'punitario',
                                                                                                width: 150
                                                                                            }, {
                                                                                                label: 'Cant.',
                                                                                                field: 'cantidad',
                                                                                                width: 150
                                                                                            }, {
                                                                                                label: 'Total',
                                                                                                field: 'venta',
                                                                                                width: 150
                                                                                            }
                                                                                        ],
                                                                                        rows: arqueoSeleccionado != null ?
                                                                                            arqueoSeleccionado.productos_arqueo != null
                                                                                                ?
                                                                                                arqueoSeleccionado.productos_arqueo.map((detalle) => {
                                                                                                    return ({
                                                                                                        'plu': parseInt(detalle.plu),
                                                                                                        'producto': detalle.nombre_producto,
                                                                                                        'precio': '$ ' + this.setearMiles(detalle.precio),
                                                                                                        'cantidad': detalle.cantidad,
                                                                                                        'subtotal': '$ ' + this.setearMiles(detalle.venta_plu),
                                                                                                    })
                                                                                                })
                                                                                                :
                                                                                                []
                                                                                            :
                                                                                            []
                                                                                    }
                                                                                }
                                                                            />
                                                                        </Paper>
                                                                    </div>
                                                                </div>
                                                            </DialogContent>
                                                        </Dialog>
                                                    </Paper>

                                                </div>
                                            </div>

                                        </div>

                                        :
                                        console.log("eror en empresa categorias" + arqueos)
                                    :
                                    console.log("eror en empresa " + arqueos)
                            }

                        </div >
                )
            case 8:
                var reportes = this.state.comprasbd
                var tablas = []
                var excel = []
                var detalles = this.state.entradaSeleccionada
                var empresa = null
                var vendedor = null
                var movimiento = null
                var detalle = null
                if (detalles != null) {
                    empresa = detalles.empresa
                    vendedor = detalles.vendedor
                    movimiento = detalles.movimiento
                    detalle = detalles.detalle
                }
                return (
                    this.state.vistaCargando ?
                        <Loader />
                        :
                        <div style={{ paddingRight: '25px', paddingLeft: '25px', paddingBottom: '25px' }} >
                            <div className="row">
                                <Tabs
                                    value={this.state.valueCompras}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="disabled tabs example"
                                    style={{ margin: '0px 15px' }}
                                >
                                    <Tab label="COMPRAS POR FECHA" onClick={() => this.cambiarTabs("valueCompras", 0)} />
                                    <Tab label="COMPRAS POR PROVEEDOR" onClick={() => this.cambiarTabs("valueCompras", 1)} />
                                    {/*<Tab label="COMPAS POR PRODUCTO" onClick={() => this.cambiarTabs("valueCompras", 2)} />
                                    <Tab label="Por producto" onClick={() => this.tabFiltrosKardex(3)} />*/}
                                </Tabs>
                            </div>
                            {this.state.valueCompras === 0 || this.state.valueCompras === 1 ? usuario.tipo === "5"
                                ?
                                <div className="row">
                                    <div className="col-md-4">
                                        <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DatePicker
                                                format="yyyy-MM-dd"
                                                id="datetime"
                                                value={this.state.selectedDatez}
                                                onChange={this.handleChangemomentZ}
                                                className="rounded form-control"
                                                disableFuture={true}
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DatePicker
                                                format="yyyy-MM-dd"
                                                id="datetime"
                                                value={this.state.selectedDateFinz}
                                                minDate={moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD HH:mm")}
                                                onChange={this.handleChangemomentFinZ}
                                                disableFuture={true}

                                                className="rounded form-control"
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-md-4  bottom-aligned">
                                        <button className="btn btn-success btn-block" onClick={() => this.consultaZFecha()}>CONSULTAR</button>
                                    </div>
                                </div>
                                :
                                <div className="row">
                                    <div className="col-md-2">
                                        <label htmlFor="numordencompra" className="col-form-label">Fecha inicial</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DatePicker
                                                format="yyyy-MM-dd"
                                                id="datetime"
                                                value={this.state.selectedDatez}
                                                onChange={this.handleChangemomentZ}
                                                className="rounded form-control"
                                                disableFuture={true}
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>

                                    <div className="col-md-2">
                                        <label htmlFor="numordencompra" className="col-form-label">Fecha final</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={"es"}>
                                            <DatePicker
                                                format="yyyy-MM-dd"
                                                id="datetime"
                                                value={this.state.selectedDateFinz}
                                                onChange={this.handleChangemomentFinZ}
                                                minDate={moment(new Date(this.state.selectedDatez.toString())).format("YYYY-MM-DD HH:mm")}
                                                className="rounded form-control"
                                                disableFuture={true}
                                                style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0", border: "1px solid #ced4da" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-md-5">
                                        <label htmlFor="numordencompra" className="col-form-label">EMPRESA</label>
                                        <Selects
                                            value={this.state.multiEmpresasZ}
                                            onChange={this.handleChanges('multiEmpresasZ')}
                                            options={this.state.empresasbd}
                                            isMulti
                                        />
                                    </div>
                                    <div className="col-md-3  bottom-aligned">
                                        <button className="btn btn-success btn-block" disabled={JSON.stringify(this.state.multiEmpresasZ) === "[]"} onClick={() => this.consultaZFecha()}>CONSULTAR</button>
                                    </div>
                                </div>
                                :
                                null}

                            {
                                reportes != null
                                    ?


                                    <div>
                                        <div className="row" style={{ marginTop: '10px' }}>
                                            <ExcelFile
                                                filename={"REPORTE DE COMPRAS " + moment(new Date()).format("DD/MM/YYYY")}
                                                element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} /></button>}
                                            >
                                                <ExcelSheet data={reportes} name="COMPRAS">
                                                    <ExcelColumn label="CONSECUTIVO" value="1" />
                                                    <ExcelColumn label="N. FACTURA" value="2" />
                                                    <ExcelColumn label="FECHA" value="3" />
                                                    <ExcelColumn label="TIENDA" value="4" />
                                                    <ExcelColumn label="PROVEEDOR" value="6" />
                                                    <ExcelColumn label="COSTO NETO" value="7" />
                                                    <ExcelColumn label="IMPUESTO" value="7.5" />
                                                    <ExcelColumn label="COSTO TOTAL" value="7.7" />
                                                </ExcelSheet>
                                            </ExcelFile>
                                            <ExcelFile
                                                filename={"REPORTE DE COMPRAS " + moment(new Date()).format("DD/MM/YYYY")}
                                                element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} />COMPRAS</button>}
                                            >
                                                <ExcelSheet data={this.state.excel2020Compras} name="COMPRAS">
                                                    <ExcelColumn label="" value="1" />
                                                    <ExcelColumn label="" value="2" />
                                                    <ExcelColumn label="" value="3" />
                                                    <ExcelColumn label="" value="4" />
                                                    <ExcelColumn label="" value="5" />
                                                    <ExcelColumn label="" value="6" />
                                                    <ExcelColumn label="" value="7" />
                                                </ExcelSheet>
                                            </ExcelFile>
                                            <ExcelFile
                                                filename={"REPORTE DE COMPRAS POR PRODUCTOS" + moment(new Date()).format("DD/MM/YYYY")}
                                                element={<button className="btn btn-success" style={{ margin: '10px' }}><img src={excelIcon} />COMPRAS POR PRODUCTOS</button>}
                                            >
                                                <ExcelSheet data={this.state.excel2020ComprasDetalladas} name="COMPRAS">
                                                    <ExcelColumn label="" value="1" />
                                                    <ExcelColumn label="" value="2" />
                                                    <ExcelColumn label="" value="3" />
                                                    <ExcelColumn label="" value="4" />
                                                    <ExcelColumn label="" value="5" />
                                                    <ExcelColumn label="" value="6" />
                                                    <ExcelColumn label="" value="7" />
                                                    <ExcelColumn label="" value="8" />
                                                    <ExcelColumn label="" value="9" />
                                                    <ExcelColumn label="" value="10" />
                                                    <ExcelColumn label="" value="11" />
                                                </ExcelSheet>
                                            </ExcelFile>

                                        </div>
                                        <div className="row" style={{ marginTop: '10px' }}>
                                            <div className="col col-md-12" >
                                                <Paper style={{ padding: '5px' }}>

                                                    <MDBDataTable
                                                        //striped
                                                        className="col-md-12"
                                                        searchLabel="Buscar"
                                                        paginationLabel={["Anterior", "Siguente"]}
                                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                        entriesLabel="Filas a mostrar"
                                                        tbodyColor="primary-color"
                                                        theadColor="#FF8800"
                                                        exportToCSV={true}
                                                        striped
                                                        bordered
                                                        autoWidth={false}
                                                        style={{ fontSize: '13px' }}
                                                        responsive={true}
                                                        hover
                                                        data={
                                                            {
                                                                columns: [
                                                                    {
                                                                        label: 'CON.',
                                                                        field: 'descripcion',
                                                                        sort: 'asc',
                                                                        width: 150
                                                                    }, {
                                                                        label: 'N. FACTURA',
                                                                        field: 'nfactura',
                                                                        sort: 'asc',
                                                                        width: 150
                                                                    }, {
                                                                        label: 'FECHA',
                                                                        field: 'cantidad',
                                                                        sort: 'asc',
                                                                        width: 150
                                                                    }, {
                                                                        label: 'TIENDA',
                                                                        field: 'tienda',
                                                                        sort: 'asc',
                                                                        width: 150
                                                                    }, {
                                                                        label: 'PROVEEDOR',
                                                                        field: 'subtotal',
                                                                        sort: 'asc',
                                                                        width: 150
                                                                    }, {
                                                                        label: 'COSTO N.',
                                                                        field: 'cn',
                                                                        sort: 'asc',
                                                                        width: 150
                                                                    }, {
                                                                        label: 'IMPUESTOS',
                                                                        field: 'imp',
                                                                        sort: 'asc',
                                                                        width: 150
                                                                    }, {
                                                                        label: 'TOTAL',
                                                                        field: 'totalproducto',
                                                                        sort: 'asc',
                                                                        width: 150
                                                                    }, {
                                                                        label: 'ACCIONES',
                                                                        field: 'acciones',
                                                                        sort: 'asc',
                                                                        width: 150
                                                                    }
                                                                ],
                                                                rows: reportes
                                                            }
                                                        }
                                                    />
                                                    {this.state.htmlDescarga ? <div className="row" style={{ marginTop: "1000px" }}>
                                                        <div id="descarga" className="mx-auto my-auto" style={{ width: '800px', paddingTop: '30px' }}>
                                                            <h5>USTED NO TIENE PERMISOS PARA VER LOS DATOS</h5>
                                                        </div>
                                                    </div>
                                                        :
                                                        null}
                                                    <div id="capture_co" style={{ width: '800px', display: 'block', marginTop: '20px' }}>
                                                        {this.state.imprimirPdf ?
                                                            <table className="tb-terpel" width="712px" height="773px" border="0" align="center" cellpadding="0" cellspacing="0">
                                                                <tr>
                                                                    <td width="763px" valign="top" className="fondocuadro">
                                                                        <table width="712px" border="0" cellpadding="0" cellspacing="0">
                                                                            <tr>
                                                                                <td width="169"><img src={logoTerpel} width="138" height="63" alt="" /></td>
                                                                                <td width="335" align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                                    <tr>
                                                                                        <td align="center">ORDEN DE ENTRADA</td>
                                                                                    </tr>
                                                                                </table></td>
                                                                                <td width="106" align="center"><img src={logoDeuna} width="64" height="64" alt="" /></td>
                                                                            </tr>
                                                                        </table>
                                                                        <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                                            <tr>
                                                                                <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                                            </tr>
                                                                        </table>
                                                                        <table width="712px" border="0" cellpadding="0" cellspacing="3">
                                                                            <tr>
                                                                                <td width="299" valign="top"><b className="fontipo">Tienda:</b> {empresa.alias}</td>
                                                                                <td width="66" align="left" valign="top"><b>Razon social: </b></td>
                                                                                <td width="233" align="left" valign="top">{empresa.razon_social}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td valign="top"><b>Nit: </b>{empresa.nit}</td>
                                                                                <td align="left" valign="top"></td>
                                                                                <td align="left" valign="top"><font className="numero2"></font></td>
                                                                            </tr>
                                                                        </table>
                                                                        <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                                            <tr>
                                                                                <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                                            </tr>
                                                                        </table>
                                                                        <table width="712px" border="0" cellpadding="3" cellspacing="0">
                                                                            <tr>
                                                                                <td width="80" className="fontipo"><b>Proveedor</b></td>
                                                                                <td width="219" align="left" valign="top">{movimiento.tercero_id_detalles.nombres}</td>
                                                                                <td width="66" align="left" valign="top"><b>No.</b></td>
                                                                                <td width="233" align="left" valign="top"><font className="numero2">{movimiento.consecutivo}</font></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><b>Nit</b></td>
                                                                                <td align="left" valign="top" className="numero2">{movimiento.tercero_id_detalles.identificacion}</td>
                                                                                <td align="left" valign="top"><b>Fecha: </b></td>
                                                                                <td align="left" valign="top"><font className="numero2">{moment(movimiento.fecha).format("DD/MM/YYYY hh:mm a")}</font></td>
                                                                            </tr>
                                                                        </table>
                                                                        <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                                            <tr>
                                                                                <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                                            </tr>
                                                                        </table>
                                                                        <table width="712px" border="0" cellpadding="3" cellspacing="0">
                                                                            <tr >
                                                                                <td width="80" className="fontipo"><b>Responsable</b></td>
                                                                                <td width="219" align="left" valign="top">{movimiento.personas_id_detalles.nombres + ' ' + movimiento.personas_id_detalles.apellidos}</td>
                                                                                <td width="66" align="left" valign="top"><b>Bodega:</b></td>
                                                                                <td width="233" align="left" valign="top">{detalle[0].bodega}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><b>ID:</b></td>
                                                                                <td align="left" valign="top" className="numero2">{movimiento.personas_id_detalles.identificacion}</td>
                                                                                <td align="left" valign="top"><b>&nbsp;</b></td>
                                                                                <td align="center" valign="top">&nbsp;</td>
                                                                            </tr>
                                                                        </table>
                                                                        <table className="tb-2" width="100%" border="0" cellspacing="4" cellpadding="0">
                                                                            <tr>
                                                                                <td><img src={linea1} width="712px" height="5" alt="" /></td>
                                                                            </tr>
                                                                        </table>
                                                                        <table width="712px" border="0" cellspacing="0" cellpadding="3">
                                                                            <tr>
                                                                                <td width="40" align="center" bgcolor="#EEEEEE"><b>COD</b></td>
                                                                                <td width="182" align="left" bgcolor="#EEEEEE"><b>PRODUCTO</b></td>
                                                                                <td width="37" align="right" bgcolor="#EEEEEE"><b>CANT.</b></td>
                                                                                <td width="46" align="right" bgcolor="#EEEEEE"><b>COSTO U.</b></td>
                                                                                <td width="70" align="right" bgcolor="#EEEEEE"><b>COSTO T.</b></td>
                                                                            </tr>
                                                                        </table>
                                                                        {detalle.map((row) => {
                                                                            //console.log(row)
                                                                            return (
                                                                                <div>
                                                                                    <table width="712px" border="0" cellspacing="0" cellpadding="3" >
                                                                                        <tr>
                                                                                            <td width="40" align="center" valign="middle" className="numero2">{row.plu}</td>
                                                                                            <td width="182" valign="middle" align="left" className="numero2">{row.descripcion}</td>
                                                                                            <td width="37" align="right" valign="middle" className="numero2">{row.cantidad}</td>
                                                                                            <td width="46" align="right" valign="middle" className="numero2">{'$ ' + this.setearMiles(row.costo_producto)}</td>
                                                                                            <td width="70" align="right" valign="middle" className="numero2">{'$ ' + this.setearMiles(row.cantidad * row.costo_producto)}</td>
                                                                                        </tr>
                                                                                    </table>
                                                                                    <table className="tb-2" width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                                        <tr>
                                                                                            <td><img src={linea2} width="712px" height="5" alt="" /></td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        )}
                                                                        <table width="712px" border="0" cellpadding="0" cellspacing="0">
                                                                            <tr >
                                                                                <td width="100" valign="middle" ><table width="100" border="0" align="right" cellpadding="3" cellspacing="0">
                                                                                    <tr >
                                                                                        <td width="54" align="right" valign="middle" class="numero2" >{'$ ' + this.setearMiles(movimiento.costo_total)}</td>
                                                                                    </tr>
                                                                                </table></td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                            :
                                                            null}
                                                    </div>
                                                    <Dialog
                                                        open={this.state.modoDetalles}
                                                        onClose={this.cerrarEntrada}
                                                        aria-labelledby="form-dialog-title"
                                                        maxWidth={'md'}
                                                        fullWidth={true}
                                                        disableBackdropClick={true}
                                                    >
                                                        <DialogContent >
                                                            {
                                                                detalles != null ?
                                                                    <div className="row mx-auto">
                                                                        <div className="col col-md-2">
                                                                            <label htmlFor="numordencompra" className="col-form-label">N. FACTURA</label>
                                                                            <input id="numordencompra" disabled className="form-control" type="text" value={detalles.movimiento.numero_factura} />
                                                                        </div>
                                                                        <div className="col col-md-3">
                                                                            <label htmlFor="numordencompra" className="col-form-label">RESPONSABLE</label>
                                                                            <input id="numordencompra" disabled className="form-control" type="text" value={detalles.movimiento.personas_id_detalles.nombres + " " + detalles.movimiento.personas_id_detalles.apellidos} />
                                                                        </div>
                                                                        <div className="col col-md-3">
                                                                            <label htmlFor="numordencompra" className="col-form-label">FECHA</label>
                                                                            <input id="numordencompra" disabled className="form-control" type="text" value={moment(detalles.movimiento.fecha).format("DD/MM/YY hh:mm a")} />
                                                                        </div>
                                                                        <div className="col col-md-4">
                                                                            <label htmlFor="numordencompra" className="col-form-label">PROVEEDOR</label>
                                                                            <input id="numordencompra" disabled className="form-control" type="text" value={detalles.movimiento.tercero_id_detalles.nombres} />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }


                                                            <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                                                <div className="col col-md-12">

                                                                    <MDBDataTable
                                                                        //striped
                                                                        className="col-md-12"
                                                                        searchLabel="Buscar"
                                                                        paginationLabel={["Anterior", "Siguente"]}
                                                                        infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                        entriesLabel="Filas a mostrar"
                                                                        tbodyColor="primary-color"
                                                                        theadColor="#FF8800"
                                                                        exportToCSV={true}
                                                                        striped
                                                                        bordered
                                                                        autoWidth={false}
                                                                        responsive={true}
                                                                        hover
                                                                        data={
                                                                            {
                                                                                columns: [

                                                                                    {
                                                                                        label: 'PLU',
                                                                                        field: 'celular',
                                                                                        sort: 'asc',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'PRODUCTO',
                                                                                        field: 'celular',
                                                                                        sort: 'asc',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'BODEGA',
                                                                                        field: 'identificacion',
                                                                                        sort: 'asc',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'CANTIDAD',
                                                                                        field: 'direccion',
                                                                                        sort: 'asc',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: 'C. UNITARIO',
                                                                                        field: 'cant',
                                                                                        sort: 'asc',
                                                                                        width: 150
                                                                                    }, {
                                                                                        label: ' C. TOTAL',
                                                                                        field: 'cant',
                                                                                        sort: 'asc',
                                                                                        width: 150
                                                                                    }
                                                                                ], rows:
                                                                                    detalles != null
                                                                                        ?
                                                                                        detalles.detalle != null
                                                                                            ?
                                                                                            detalles.detalle.map((p) => {
                                                                                                return ({
                                                                                                    'plu': p.plu,
                                                                                                    'producto': p.descripcion,
                                                                                                    'bodega': p.bodega,
                                                                                                    'cantidad': p.cantidad,
                                                                                                    'costo': '$' + this.setearMiles(p.costo_producto),
                                                                                                    'costot': '$' + this.setearMiles(parseInt(p.cantidad) * parseFloat(p.costo_producto)),
                                                                                                })
                                                                                            })
                                                                                            :
                                                                                            []
                                                                                        :
                                                                                        []
                                                                            }
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <button className="btn btn-danger" onClick={this.cerrarEntrada.bind(this)}>CERRAR</button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </Paper>

                                            </div>
                                        </div>
                                    </div>


                                    :
                                    console.log("eror en reportes " + reportes)

                            }


                        </div >
                )
                break;
            case 9:
                var reportes = this.state.comprasbd
                var tabla = []
                var excel = []
                return (
                    this.state.vistaCargando ?
                        <Loader />
                        :
                        <div style={{ paddingRight: '25px', paddingLeft: '25px', paddingBottom: '25px' }} >
                            {usuario.tipo === "5"
                                ?
                                <div className="row" style={{ marginTop: '20px' }}>

                                    <div className="col-md-12  bottom-aligned">
                                        <button className="btn btn-success btn-block" onClick={() => this.consultaZFecha()}>CONSULTAR</button>
                                    </div>
                                </div>
                                :
                                <div className="row">

                                    <div className="col-md-8">
                                        <label htmlFor="numordencompra" className="col-form-label">EMPRESA</label>
                                        <Selects
                                            value={this.state.multiEmpresasZ}
                                            onChange={this.handleChanges('multiEmpresasZ')}
                                            options={this.state.empresasbd}
                                            isMulti
                                        />
                                    </div>
                                    <div className="col-md-4 bottom-aligned">
                                        <button className="btn btn-success btn-block" disabled={JSON.stringify(this.state.multiEmpresasZ) === "[]"} onClick={() => this.consultaZFecha()}>CONSULTAR</button>
                                    </div>
                                </div>
                            }
                            {this.state.productosEmpresa != null ?
                                <div className="row" style={{ marginTop: '10px' }}>
                                    <div className="col col-md-12" >
                                        <Paper style={{ padding: '5px' }}>

                                            <MDBDataTable
                                                //striped
                                                className="col-md-12"
                                                searchLabel="Buscar"
                                                paginationLabel={["Anterior", "Siguente"]}
                                                infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                entriesLabel="Filas a mostrar"
                                                tbodyColor="primary-color"
                                                theadColor="#FF8800"
                                                exportToCSV={true}
                                                striped
                                                bordered
                                                autoWidth={false}
                                                style={{ fontSize: '14px' }}
                                                responsive={true}
                                                hover
                                                data={
                                                    {
                                                        columns: [
                                                            {
                                                                label: 'Codigo',
                                                                field: 'kco',
                                                                width: 150
                                                            }, {
                                                                label: 'Kiosco',
                                                                field: 'kco',
                                                                width: 150
                                                            }, {
                                                                label: 'Razon social',
                                                                field: 'rs',
                                                                width: 150
                                                            }, {
                                                                label: 'N. Productos',
                                                                field: 'np',
                                                                width: 150
                                                            }, {
                                                                label: 'Ver',
                                                                field: 'ver',
                                                                width: 150
                                                            }
                                                        ],
                                                        rows: this.state.productosEmpresa.map((producto) => {
                                                            return ({
                                                                'codigo': producto.codigo,
                                                                'kco': producto.alias,
                                                                'rs': producto.razon_social,
                                                                'np': producto.productos.length,
                                                                'ver': <button className="btn btn-sm btn-primary" onClick={() => this.abrirDialogProductos(producto)}><Ojoicon /></button>
                                                            })
                                                        })
                                                    }
                                                }
                                            />

                                            <Dialog
                                                open={this.state.dialogPEmpresa}
                                                onClose={this.cerrarDialogProductos}
                                                aria-labelledby="form-dialog-title"
                                                maxWidth={'md'}
                                                fullWidth={true}
                                                disableBackdropClick={true}
                                            >

                                                {this.state.empresaSeleccionado != null ? <DialogContent >
                                                    <div className="row mx-auto">
                                                        <div className="col col-md-2">
                                                            <label htmlFor="numordencompra" className="col-form-label">Codigo</label>
                                                            <input id="numordencompra" disabled className="form-control" type="text" value={this.state.empresaSeleccionado.codigo} />
                                                        </div>
                                                        <div className="col col-md-5">
                                                            <label htmlFor="numordencompra" className="col-form-label">Kiosco</label>
                                                            <input id="numordencompra" disabled className="form-control" type="text" value={this.state.empresaSeleccionado.alias} />
                                                        </div>
                                                        <div className="col col-md-5">
                                                            <label htmlFor="numordencompra" className="col-form-label">Razon social</label>
                                                            <input id="numordencompra" disabled className="form-control" type="text" value={this.state.empresaSeleccionado.razon_social} />
                                                        </div>
                                                    </div>
                                                    {this.state.empresaSeleccionado != null ?
                                                        <div className="row mx-auto" style={{ marginTop: '10px' }}>
                                                            <div className="col col-md-12">
                                                                <MDBDataTable
                                                                    //striped
                                                                    className="col-md-12 mx-auto"
                                                                    searchLabel="Buscar"
                                                                    paginationLabel={["Anterior", "Siguente"]}
                                                                    infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                                    entriesLabel="Filas a mostrar"
                                                                    tbodyColor="primary-color"
                                                                    theadColor="#FF8800"
                                                                    exportToCSV={true}
                                                                    striped
                                                                    bordered
                                                                    autoWidth={false}
                                                                    responsive={true}
                                                                    hover
                                                                    order={['plu', 'asc']}
                                                                    data={
                                                                        {
                                                                            columns: [
                                                                                {
                                                                                    label: 'Plu',
                                                                                    field: 'plu',
                                                                                    sort: 'asc',
                                                                                    width: 150
                                                                                }, {
                                                                                    label: 'Producto',
                                                                                    field: 'descr',
                                                                                    width: 150
                                                                                }, {
                                                                                    label: 'Precio por defecto',
                                                                                    field: 'punitario',
                                                                                    width: 150
                                                                                }, {
                                                                                    label: 'Precio en el kiosco',
                                                                                    field: 'punitario',
                                                                                    width: 150
                                                                                }
                                                                            ],
                                                                            rows:
                                                                                this.state.empresaSeleccionado.productos != null
                                                                                    ?
                                                                                    this.state.empresaSeleccionado.productos.map((detalle) => {
                                                                                        return ({
                                                                                            'plu': parseInt(detalle.plu),
                                                                                            'producto': detalle.descripcion,
                                                                                            'rpecio': '$ ' + this.setearMiles(detalle.precio),
                                                                                            'rpecio2': '$ ' + this.setearMiles(detalle.precio_empresa),
                                                                                        })
                                                                                    })
                                                                                    :
                                                                                    []

                                                                        }
                                                                    }
                                                                />
                                                            </div>
                                                        </div> : null}
                                                </DialogContent> : null}
                                                <DialogActions>
                                                    <button className="btn btn-danger" onClick={this.cerrarDialogProductos.bind(this)}>Cerrar</button>
                                                </DialogActions>
                                            </Dialog>
                                        </Paper>
                                    </div>
                                </div>
                                :
                                null}


                        </div>)
                break;
            case 10:
                var reportes = this.state.facturacionbd
                console.log(this.state.facturacionbd)
                var tabla = []
                var excel = []
                return (
                    this.state.vistaCargando ?
                        <Loader />
                        :
                        <div style={{ paddingRight: '25px', paddingLeft: '25px', paddingBottom: '25px' }} >
                            {usuario.tipo === "5"
                                ?
                                null
                                :
                                <div className="row">
                                    <div className="col-md-12 bottom-aligned">
                                        <button className="btn btn-success btn-block" style={{ marginTop: '10px' }} onClick={() => this.consultaZFecha()}>CONSULTAR</button>
                                    </div>
                                </div>
                            }
                            {reportes != null ?
                                <div className="row" style={{ marginTop: '10px' }}>
                                    <div className="col col-md-12" >
                                        <Paper style={{ padding: '5px' }}>
                                            {
                                                reportes.map((producto) => {
                                                    excel.push({
                                                        '1': producto.codigo,
                                                        '2': producto.alias,
                                                        '2.5': producto.razon_social,
                                                        '3': moment(producto.primera_venta).format("DD/MM/YYYY hh:mm a"),
                                                        '4': moment(producto.ultima_venta).format("DD/MM/YYYY hh:mm a"),
                                                        '5': producto.numero_ventas,
                                                        '6': '$ ' + this.setearMiles(parseFloat(producto.total_ventas))
                                                    })
                                                })

                                            }
                                            <div className="row" style={{ marginTop: '10px' }}>
                                                <ExcelFile
                                                    filename={"FACTURACION "}
                                                    element={<button className="btn btn-success" style={{ margin: '15px' }}><img src={excelIcon} /></button>}
                                                >
                                                    <ExcelSheet data={excel} name="FACTURACION">
                                                        <ExcelColumn label="CODIGO" value="1" />
                                                        <ExcelColumn label="KIOSCO" value="2" />
                                                        <ExcelColumn label="RAZON SOCIAL" value="2.5" />
                                                        <ExcelColumn label="PRIMERA VENTA" value="3" />
                                                        <ExcelColumn label="ULTIMA VENTA" value="4" />
                                                        <ExcelColumn label="NUMERO DE VENTAS" value="5" />
                                                        <ExcelColumn label="TOTAL EN VENTAS" value="6" />
                                                    </ExcelSheet>
                                                </ExcelFile>

                                            </div>
                                            <MDBDataTable
                                                //striped
                                                className="col-md-12"
                                                searchLabel="Buscar"
                                                paginationLabel={["Anterior", "Siguente"]}
                                                infoLabel={["Mostrando", "a", "de", "entradas"]}
                                                entriesLabel="Filas a mostrar"
                                                tbodyColor="primary-color"
                                                theadColor="#FF8800"
                                                exportToCSV={true}
                                                striped
                                                bordered
                                                autoWidth={false}
                                                style={{ fontSize: '14px' }}
                                                responsive={true}
                                                order={['ver', 'desc']}
                                                data={
                                                    {
                                                        columns: [
                                                            {
                                                                label: 'Codigo',
                                                                field: 'kco',
                                                                width: 150
                                                            }, {
                                                                label: 'Kiosco',
                                                                field: 'kco',
                                                                width: 150
                                                            }, {
                                                                label: 'Primera venta',
                                                                field: 'rs',
                                                                width: 150
                                                            }, {
                                                                label: 'Ultima venta',
                                                                field: 'np',
                                                                width: 150
                                                            }, {
                                                                label: 'N. Ventas',
                                                                field: 'ver',
                                                                width: 150
                                                            }, {
                                                                label: 'T. Ventas',
                                                                field: 'tver',
                                                                width: 150
                                                            }
                                                        ],
                                                        rows: reportes.map((producto) => {
                                                            return ({
                                                                'codigo': producto.codigo,
                                                                'kco': producto.alias,
                                                                'rs': moment(producto.primera_venta).format("DD/MM/YYYY hh:mm a"),
                                                                'np': moment(producto.ultima_venta).format("DD/MM/YYYY hh:mm a"),
                                                                'ver': parseInt(producto.numero_ventas),
                                                                'tver': '$ ' + this.setearMiles(parseFloat(producto.total_ventas))
                                                            })
                                                        })
                                                    }
                                                }
                                            />
                                        </Paper>
                                    </div>
                                </div>
                                :
                                null}


                        </div>)
                break;

        }
    }


    obtenerDatos = () => {
        this.setState({ actualizarDatos: false })
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        var excel = []
        switch (usuario.tipo) {
            case "1":
            case "2":
            case "3":
            case "4":
                Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/movimiento/lista/operacion/' + parseInt(usuario.empresas_id, 10) + "," + hijos + '/9', { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        var movimientosTotal = []
                        //console.log(responsee.data)
                        if (responsee.status === 200) {
                            if (responsee.data != null) {
                                responsee.data.movimientos.map((mov, key) => {
                                    movimientosTotal.push({
                                        "consecutivo": mov.movimiento.consecutivo,
                                        "fecha": mov.movimiento.fecha,
                                        "tienda": mov.empresa.razon_social,
                                        "responsable": mov.vendedor != null ? mov.vendedor.nombre : 'NO DEFINIDO',
                                        "total": '$' + this.setearMiles(parseInt(mov.movimiento.venta_total)),
                                        "action": mov.detalle != null ? <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesVenta(mov)}><Ojoicon /></button> : null
                                    })

                                    excel.push({
                                        venta: mov.movimiento.consecutivo,
                                        fecha: mov.movimiento.fecha,
                                        tienda: mov.empresa.razon_social,
                                        responsable: mov.vendedor != null ? mov.vendedor.nombre : 'NO DEFINIDO',
                                        subtotal: "$" + (parseFloat(mov.movimiento.venta_total) - parseFloat(mov.movimiento.impuesto_total)),
                                        totalImpuesto: "$" + mov.movimiento.impuesto_total,
                                        totalVenta: "$" + mov.movimiento.venta_total,

                                    })
                                    //console.log(key)

                                })
                                //console.log("movimientosTotal")
                                //console.log(movimientosTotal)
                                this.setState({ movimientosbd: movimientosTotal, ultimaActualizacion: new Date(), datosExcel: excel })
                            }
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }

                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })

                break
            case "5":
                Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/movimiento/lista/operacion/' + parseInt(usuario.empresas_id, 10) + '/9', { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        var movimientosTotal = []
                        //console.log(responsee.data)
                        if (responsee.status == 200) {
                            responsee.data.movimientos.map((mov, key) => {
                                //console.log(mov)
                                movimientosTotal.push({
                                    "consecutivo": mov.movimiento != null ? mov.movimiento.consecutivo : '',
                                    "fecha": mov.movimiento != null ? mov.movimiento.fecha : '',
                                    "tienda": mov.empresa != null ? mov.empresa.razon_social : '',
                                    "responsable": mov.vendedor != null ? mov.vendedor.nombre : '',
                                    "total": '$' + (mov.movimiento != null ? this.setearMiles(parseInt(mov.movimiento.venta_total)) : 0),
                                    "action": <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesVenta(mov)}><Ojoicon /></button>
                                })

                                excel.push({
                                    venta: mov.movimiento != null ? mov.movimiento.consecutivo : '',
                                    fecha: mov.movimiento != null ? mov.movimiento.fecha : '',
                                    tienda: mov.empresa != null ? mov.empresa.razon_social : '',
                                    responsable: mov.vendedor != null ? mov.vendedor.nombre : '',
                                    subtotal: "$" + (parseFloat(mov.movimiento.venta_total) - parseFloat(mov.movimiento.impuesto_total)),
                                    totalImpuesto: "$" + (mov.movimiento != null ? mov.movimiento.impuesto_total : 0),
                                    totalVenta: "$" + (mov.movimiento != null ? mov.movimiento.venta_total : 0),

                                })


                            })
                            //console.log(movimientosTotal)
                            //console.log(excel)
                            //console.log("asjewiorhew")
                            this.setState({ movimientosbd: movimientosTotal, ultimaActualizacion: new Date(), datosExcel: excel })
                            //setInterval(this.obtenerDatos(),6000)
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })


                break
        }


    }

    componentWillMount = () => {
        //Permisos usuarios
        var modulos = store.getState().perfilesUSuario
        modulos.map((modulo, key) => {
            if (modulo.modulos_id == idModulo) {
                permisos = modulo.acciones
            }
            if (modulo.modulos_id === 48) {
                this.setState({
                    permisoFacturacion: true
                })
            }
        })
        //this.obtenerDatos()
        //
        var usuario = store.getState().usuario
        var hijos = store.getState().empresasHijos
        var excel = []
        var excelDetalles = []
        switch (usuario.tipo) {
            case "1":
            case "2":
            case "3":
            case "4":
                Axios.get(Server.hostPrueba + Server.puertoSSLBodega + '/api/bodegas/empresa/' + hijos, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        //console.log(responsee.data)
                        var bdg = []
                        if (responsee.status === 200) {
                            responsee.data.map((bodega, key) => {
                                bdg.push({
                                    value: bodega.bodega_id,
                                    label: bodega.descripcion + " -- " + bodega.razon_social
                                })
                            })
                            this.setState({ bodegasbd: bdg })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                /* Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/movimiento/lista/operacion/' + parseInt(usuario.empresas_id, 10) + "," + hijos + '/9', { headers: { Authorization: ntoken } })
                     .then(responsee => {
                         var movimientosTotal = []
                         //console.log(responsee.data)
                         if (responsee.data != null) {
                             responsee.data.movimientos.map((mov, key) => {
                                 movimientosTotal.push({
                                     "consecutivo": mov.movimiento.consecutivo,
                                     "fecha": mov.movimiento.fecha,
                                     "tienda": mov.empresa.razon_social,
                                     "responsable": mov.vendedor != null ? mov.vendedor.nombre : 'NO DEFINIDO',
                                     "total": '$' + this.setearMiles(parseInt(mov.movimiento.venta_total)),
                                     "action": mov.detalle != null ? <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesVenta(mov)}><Ojoicon /></button> : null
                                 })
 
                                 excel.push({
                                     venta: mov.movimiento.consecutivo,
                                     fecha: mov.movimiento.fecha,
                                     tienda: mov.empresa.razon_social,
                                     responsable: mov.vendedor != null ? mov.vendedor.nombre : 'NO DEFINIDO',
                                     subtotal: "$" + this.setearMiles((parseFloat(mov.movimiento.venta_total) - parseFloat(mov.movimiento.impuesto_total))),
                                     totalImpuesto: "$" + this.setearMiles(parseFloat(mov.movimiento.impuesto_total).toFixed(3)),
                                     totalVenta: "$" + this.setearMiles(parseFloat(mov.movimiento.venta_total).toFixed(3)),
 
                                 })
 
                                 if (mov.detalle != null) {
                                     mov.detalle.map((producto, key) => {
                                         excelDetalles.push({
                                             venta: mov.movimiento != null ? mov.movimiento.consecutivo : '',
                                             fecha: mov.movimiento != null ? mov.movimiento.fecha : '',
                                             tienda: mov.empresa != null ? mov.empresa.razon_social : '',
                                             responsable: mov.vendedor != null ? mov.vendedor.nombre : '',
                                             plu: producto.plu,
                                             descripcion: producto.descripcion,
                                             punitario: '$' + this.setearMiles(parseInt(producto.precio)),
                                             cantidad: producto.cantidad,
                                             subtotalProducto: '$' + this.setearMiles(parseInt(producto.subtotal)),
                                             subtotal: "$" + this.setearMiles((parseFloat(mov.movimiento.venta_total) - parseFloat(mov.movimiento.impuesto_total))),
                                             totalImpuesto: "$" + this.setearMiles(parseFloat(mov.movimiento.impuesto_total).toFixed(3)),
                                             totalVenta: "$" + this.setearMiles(parseFloat(mov.movimiento.venta_total).toFixed(3)),
                                         })
                                     })
                                 }
 
                             })
                             //console.log(excelDetalles)
                             //console.log("movimientosTotal")
                             //console.log(movimientosTotal)
                             this.setState({ movimientosbd: movimientosTotal, ultimaActualizacion: new Date(), datosExcel: excel, datosExcelVentaDetalles: excelDetalles })
                             //setInterval(this.obtenerDatos(),6000)
                         } else if (responsee.status == 205) {
                             swal("ERROR", "LA SESION HA CADUCADO", "warning")
                             setTimeout(function () { window.location.href = '/'; }, 2000);
                         }
                     })
                     .catch(function (error) {
                         // handle error
                         console.error(error);
                     })*/
                Axios.get(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/empresa/tipo/5/empresas/' + hijos + "," + usuario.empresas_id, { headers: { Authorization: ntoken } })
                    .then(responseee => {
                        var empresas = []
                        console.log(responseee)
                        if (responseee.data != null) {
                            responseee.data.empresas.map((dato) => (
                                empresas.push({
                                    value: dato.id,
                                    label: dato.alias + " -- " + dato.nit
                                })
                            ))
                        } else if (responseee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 5000);
                        }
                        this.setState({ empresasbd: empresas })

                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                //Cierres
                /*var datos = {
                    "accion": "cierre",
                    "tipo_reporte": 0,
                    "fecha_inicial": null,
                    "fecha_final": null,
                    "empresas_id": hijos
                }
                Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes/', datos, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        //console.log(responsee)
                        if (responsee.status == 200) {
                            this.setState({ cierresbd: responsee.data.data })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })*/
                //Categorias
                Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/grupos/categoria/' + store.getState().empresasPadres, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee)
                        var datos = []
                        if (responsee.status == 200) {
                            responsee.data.map((categoria, key) => {
                                datos.push({
                                    value: categoria.id,
                                    label: categoria.grupo,
                                })
                            })
                            this.setState({ multiCategorias: datos })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                //Categorias
                Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/grupos/categoria/' + store.getState().empresasPadres, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee)
                        var datos = []
                        if (responsee.status == 200) {
                            responsee.data.map((categoria, key) => {
                                if (categoria.grupos_id != null) {
                                    datos.push({
                                        value: categoria.id,
                                        label: categoria.grupo,
                                    })
                                }
                            })
                            this.setState({ categoriasHijosbd: datos })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break
            case "5":
                /*Axios.get(Server.hostPruebasinssl + Server.puertoProducto + '/api/movimiento/lista/operacion/' + parseInt(usuario.empresas_id, 10) + '/9', { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        var movimientosTotal = []
                        //console.log(responsee.data)
                        if (responsee.status == 200) {
                            responsee.data.movimientos.map((mov, key) => {
                                //console.log(mov)
                                movimientosTotal.push({
                                    "consecutivo": mov.movimiento != null ? mov.movimiento.consecutivo : '',
                                    "fecha": mov.movimiento != null ? mov.movimiento.fecha : '',
                                    "tienda": mov.empresa != null ? mov.empresa.razon_social : '',
                                    "responsable": mov.vendedor != null ? mov.vendedor.nombre : '',
                                    "total": '$' + (mov.movimiento != null ? this.setearMiles(parseInt(mov.movimiento.venta_total)) : 0),
                                    "action": <button className="btn btn-sm btn-primary" style={{ marginLeft: '5px' }} onClick={() => this.detallesVenta(mov)}><Ojoicon /></button>
                                })

                                excel.push({
                                    venta: mov.movimiento != null ? mov.movimiento.consecutivo : '',
                                    fecha: mov.movimiento != null ? mov.movimiento.fecha : '',
                                    tienda: mov.empresa != null ? mov.empresa.razon_social : '',
                                    responsable: mov.vendedor != null ? mov.vendedor.nombre : '',
                                    subtotal: "$" + this.setearMiles((parseFloat(mov.movimiento.venta_total) - parseFloat(mov.movimiento.impuesto_total))),
                                    totalImpuesto: "$" + this.setearMiles(parseFloat(mov.movimiento.impuesto_total).toFixed(3)),
                                    totalVenta: "$" + this.setearMiles(parseFloat(mov.movimiento.venta_total).toFixed(3)),

                                })
                                //console.log(mov)
                                if (mov.detalle != null) {
                                    mov.detalle.map((producto, key) => {
                                        excelDetalles.push({
                                            venta: mov.movimiento != null ? mov.movimiento.consecutivo : '',
                                            fecha: mov.movimiento != null ? mov.movimiento.fecha : '',
                                            tienda: mov.empresa != null ? mov.empresa.razon_social : '',
                                            responsable: mov.vendedor != null ? mov.vendedor.nombre : '',
                                            plu: producto.plu,
                                            descripcion: producto.descripcion,
                                            punitario: '$' + this.setearMiles(parseInt(producto.precio)),
                                            cantidad: producto.cantidad,
                                            subtotalProducto: '$' + this.setearMiles(parseInt(producto.subtotal)),
                                            subtotal: "$" + this.setearMiles((parseFloat(mov.movimiento.venta_total) - parseFloat(mov.movimiento.impuesto_total))),
                                            totalImpuesto: "$" + this.setearMiles(parseFloat(mov.movimiento.impuesto_total).toFixed(3)),
                                            totalVenta: "$" + this.setearMiles(parseFloat(mov.movimiento.venta_total).toFixed(3)),
                                        })
                                    })
                                }


                            })
                            //console.log(excelDetalles)
                            //console.log(movimientosTotal)
                            //console.log(excel)
                            //console.log("asjewiorhew")
                            this.setState({ movimientosbd: movimientosTotal, ultimaActualizacion: new Date(), datosExcel: excel, datosExcelVentaDetalles: excelDetalles })
                            //setInterval(this.obtenerDatos(),6000)
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })*/
                Axios.get(Server.hostPrueba + Server.puertoSSLBodega + '/api/bodegas/empresa/' + usuario.empresas_id, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        //console.log(responsee.data)
                        var bdg = []
                        if (responsee.status === 200) {
                            responsee.data.map((bodega, key) => {
                                bdg.push({
                                    value: bodega.bodega_id,
                                    label: bodega.descripcion + " -- " + bodega.razon_social
                                })
                            })
                            this.setState({ bodegasbd: bdg })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                //Cierres
                /*var datos = {
                    "accion": "cierre",
                    "tipo_reporte": 0,
                    "fecha_inicial": null,
                    "fecha_final": null,
                    "empresas_id": usuario.empresas_id
                }
                Axios.post(Server.hostPruebasinssl + Server.puertoEmpresas + '/api/reportes/', datos, { headers: { Authorization: ntoken } })
                    .then(responsee => {

                        console.log(responsee)
                        if (responsee.status == 200) {
                            this.setState({ cierresbd: responsee.data.data })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })*/
                //

                Axios.get(Server.hostPruebasinssl + Server.puertoEmpleado + '/api/persona/empresas/' + parseInt(usuario.empresas_id, 10), { headers: { Authorization: ntoken } })
                    .then(response => {
                        //console.log(response)
                        if (response.status == 200) {
                            var empleados = []
                            if (response.data.rows != null) {
                                response.data.rows.map((empleado, key) => {
                                    empleados.push({
                                        value: empleado.persona.id,
                                        label: empleado.persona.nombres + " " + empleado.persona.apellidos
                                    })
                                })
                                this.setState({ empleadoCierresbd: empleados })
                            }
                            console.log(response)
                        }
                        //this.setState({users: datos, loader: false })
                    })
                //Categorias
                console.log(store.getState().empresasPadres)
                Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/grupos/categoria/' + store.getState().empresasPadres, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee)
                        var datos = []
                        if (responsee.status == 200) {
                            responsee.data.map((categoria, key) => {
                                datos.push({
                                    value: categoria.id,
                                    label: categoria.grupo,
                                })
                            })
                            this.setState({ multiCategorias: datos })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                //Categorias
                console.log(store.getState().empresasPadres)
                Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/grupos/categoria/' + store.getState().empresasPadres, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee)
                        var datos = []
                        if (responsee.status == 200) {
                            responsee.data.map((categoria, key) => {
                                if (categoria.grupos_id != null) {
                                    datos.push({
                                        value: categoria.id,
                                        label: categoria.grupo,
                                    })
                                }
                            })
                            this.setState({ categoriasHijosbd: datos })
                        } else if (responsee.status == 205) {
                            swal("ERROR", "LA SESION HA CADUCADO", "warning")
                            setTimeout(function () { window.location.href = '/'; }, 2000);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break
        }
    }

    render() {
        var usuario = store.getState().usuario
        const tipoDominio = store.getState().tipoDominio
        return (
            tipoDominio === "V"
                ?
                <div>
                    <div>
                        <div className="row" style={{ marginTop: "15px" }}>
                            <AppBar position="static" color="default" style={{ zIndex: 999 }}>
                                <Tabs
                                    value={this.state.value}
                                    variant="scrollable"
                                    scrollButtons="on"
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Tab label="BONOS IMPRESOS" onClick={() => this.cambiarTabs("value", 0)} icon={<ListIcon />} />

                                </Tabs>
                            </AppBar>
                            <Paper style={{ width: '100%' }}>
                                {this.mostrarReportesVoucher(this.state.value)}
                            </Paper >

                        </div>
                    </div>
                </div>
                :
                <div>
                    <div>
                        <div className="row" style={{ marginTop: "15px" }}>
                            <AppBar position="static" color="default" style={{ zIndex: 999 }}>
                                <Tabs
                                    value={this.state.value}
                                    variant="scrollable"
                                    scrollButtons="on"
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Tab label="VENTAS" onClick={() => this.cambiarTabs("value", 0)} icon={<ListIcon />} />
                                    <Tab label="KARDEX" onClick={() => this.cambiarTabs("value", 1)} icon={<ListIcon />} />
                                    <Tab label="CIERRES" onClick={() => this.cambiarTabs("value", 2)} icon={<ListIcon />} />
                                    <Tab label="Z FINANCIERO" onClick={() => this.cambiarTabs("value", 3)} icon={<ListIcon />} />
                                    <Tab label="UTILIDAD" onClick={() => this.cambiarTabs("value", 4)} icon={<ListIcon />} />
                                    <Tab label="LIBRO DE FACTURACION" onClick={() => this.cambiarTabs("value", 5)} icon={<ListIcon />} />
                                    <Tab label="VALOR DE INVENTARIO" onClick={() => this.cambiarTabs("value", 6)} icon={<ListIcon />} />
                                    <Tab label="ARQUEO" onClick={() => this.cambiarTabs("value", 7)} icon={<ListIcon />} />
                                    <Tab label="COMPRAS" onClick={() => this.cambiarTabs("value", 8)} icon={<ListIcon />} />
                                    <Tab label="PRODUCTOS POR EMPRESAS" onClick={() => this.cambiarTabs("value", 9)} icon={<ListIcon />} />
                                    {this.state.permisoFacturacion ? <Tab label="FACTURACION" onClick={() => this.cambiarTabs("value", 10)} icon={<ListIcon />} /> : null}

                                </Tabs>
                            </AppBar>
                            <Paper style={{ width: '100%' }}>
                                {this.mostrarReportes(this.state.value)}
                            </Paper >

                        </div>
                    </div>
                </div>
        )
    }
}
export default Entradas