
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent"
import 'bootstrap/dist/css/bootstrap.min.css';
import CardTittle from '@material-ui/core/CardHeader'
import Server from '../../lib/server'
import store from '../../lib/store'
import Axios from 'axios';
import swal from 'sweetalert'
import Selects from 'react-select'
var ntoken = 'Bearer '.concat(localStorage.getItem('token'))
var categoriaEditar = null
class FormularioCategoria extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paisesbd: [],
            departamentosbd: [],
            paisSeleccionado: {},
            departamentoSeleccionado: {},
            descripcion: '',
            nomenclaturaN: '',
            monedan: '',
            indicadorn: '',
            zhorariaN: -5,
            response: false,
            paisValidar: [],
            ciudadValidar: [],
            provinciaValidar: [],
        };
    }


    cancelar = () => {
        store.dispatch({
            type: "CREACION_GEOGRAFIA",
            creacionGeografia: false
        })
    }
    validarExistentes = (tipo) => {
        var val = false
        switch (tipo) {
            case 1:
                console.log(this.state.paisValidar, this.state.descripcion.trim)
                if (this.state.paisValidar.includes(this.state.descripcion)) {
                    val = true
                }
                break;
            case 2:
                if (this.state.provinciaValidar.includes(this.state.descripcion)) {
                    val = true
                }
                break;
            case 3:
                if (this.state.ciudadValidar.includes(this.state.descripcion)) {
                    val = true
                }
                break;

        }
        return val
    }

    validarFormulario = (tipo) => {
        switch (tipo) {
            case 1:
                if (this.state.descripcion.trim == "" || this.state.descripcion.length == 0) {
                    swal("FALLO AL CREAR", "LA DESCRIPCION DEL PAIS ES NECESARIA", "warning")
                    return false
                } else if (this.state.nomenclaturaN.trim == "" || this.state.nomenclaturaN.length == 0) {
                    swal("FALLO AL CREAR", "LA NOMENCLATURA DEL PAIS ES NECESARIA", "warning")
                    return false
                } else if (this.state.monedan.trim == "" || this.state.monedan.length == 0) {
                    swal("FALLO AL CREAR", "LA MONEDA DEL PAIS ES NECESARIA", "warning")
                    return false
                } else if (this.state.indicadorn.trim == "" || this.state.indicadorn.length == 0) {
                    swal("FALLO AL CREAR", "EL INDICADOR DE PAIS ES NECESARIO", "warning")
                    return false
                } else if (this.validarExistentes(tipo)) {
                    swal("FALLO AL CREAR", "Ya existe un pais con este nombre", "warning")
                    return false
                } else {
                    this.setState({
                        response: true
                    })
                    return true
                }
                break
            case 2:
                if (this.state.descripcion.trim == "" || this.state.descripcion.length == 0) {
                    swal("FALLO AL CREAR", "LA DESCRIPCION DEL DEPARTAMENTO ES NECESARIA", "warning")
                    return false
                } else if (JSON.stringify(this.state.paisSeleccionado) === '{}') {
                    swal("FALLO AL CREAR", "POR FAVOR ESCOJA EL PAIS", "warning")
                    return false
                } else if (this.validarExistentes(tipo)) {
                    swal("FALLO AL CREAR", "Ya existe un departamento con este nombre", "warning")
                    return false
                } else {
                    this.setState({
                        response: true
                    })
                    return true
                }
                break
            case 3:
                if (this.state.descripcion.trim == "" || this.state.descripcion.length == 0) {
                    swal("FALLO AL CREAR", "LA DESCRIPCION DE LA CIUDAD ES NECESARIA", "warning")
                    return false
                } else if (JSON.stringify(this.state.paisSeleccionado) === '{}') {
                    swal("FALLO AL CREAR", "POR FAVOR ESCOJA EL PAIS", "warning")
                    return false
                } else if (JSON.stringify(this.state.departamentoSeleccionado) === '{}') {
                    swal("FALLO AL CREAR", "POR FAVOR ESCOJA EL DEPARTAMENTO", "warning")
                    return false
                } else if (this.state.indicadorn.trim == "" || this.state.indicadorn.length == 0) {
                    swal("FALLO AL CREAR", "EL INDICADOR TELEFONICO ES NECESARIO", "warning")
                    return false
                } else if (this.validarExistentes(tipo)) {
                    swal("FALLO AL CREAR", "Ya existe una ciudad con este nombre", "warning")
                    return false
                } else {
                    this.setState({
                        response: true
                    })
                    return true
                }
                break
        }

    }


    Guardar = () => {
        var tipo = this.props.tipoGeografia
        var usuario = store.getState().usuario
        var validar = false
        switch (tipo) {
            case 1:
                var datos = {
                    "descripcion": this.state.descripcion,
                    "nomenclatura": this.state.nomenclaturaN,
                    "moneda": this.state.monedan,
                    "indicador": this.state.indicadorn,
                    "estado": "A",
                    "create_user": usuario.p_id
                }
                validar = this.validarFormulario(1)
                if (validar) {
                    Axios.post(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/paises/', datos, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            //console.log(res)
                            this.setState({
                                response: false
                            })
                            if (res.status == 200) {
                                swal({
                                    title: "Pais creado correctamente",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            store.dispatch({
                                                type: "ACTUALIZACION_DATOS",
                                                actualizarvista: true
                                            })
                                            store.dispatch({
                                                type: "CREACION_GEOGRAFIA",
                                                creacionGeografia: false,
                                            })
                                            break;
                                        default:

                                    }
                                });
                            } else {
                                swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                            }
                        })
                }
                break;
            case 2:
                var datos = {
                    "descripcion": this.state.descripcion,
                    "paises_id": this.state.paisSeleccionado.value,
                    "estado": "A",
                    "create_user": usuario.p_id
                }
                validar = this.validarFormulario(2)
                if (validar) {
                    Axios.post(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/provincias/', datos, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            //console.log(res)
                            this.setState({
                                response: false
                            })
                            if (res.status == 200) {
                                swal({
                                    title: "Departamento creado correctamente",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            store.dispatch({
                                                type: "ACTUALIZACION_DATOS",
                                                actualizarvista: true
                                            })
                                            store.dispatch({
                                                type: "CREACION_GEOGRAFIA",
                                                creacionGeografia: false,
                                            })
                                            break;
                                        default:

                                    }
                                });
                            } else {
                                swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                            }
                        })
                }
                break;
            case 3:
                var datos = {
                    "descripcion": this.state.descripcion,
                    "zona_horaria": this.state.zhorariaN,
                    "provincia_id": this.state.departamentoSeleccionado.value,
                    "indicadores": this.state.indicadorn,
                    "estado": "A",
                    "create_user": usuario.p_id
                }
                validar = this.validarFormulario(3)
                console.log(datos)
                if (validar) {
                    Axios.post(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/ciudades/', datos, { headers: { Authorization: ntoken } })
                        .then((res) => {
                            this.setState({
                                response: false
                            })
                            console.log(res)
                            if (res.status == 200) {
                                swal({
                                    title: "Ciudad creada correctamente",
                                    icon: "success",
                                    closeOnClickOutside: false,
                                    buttons: {
                                        Seguir: {
                                            text: "OK",
                                            value: "continuar"
                                        }
                                    },
                                }).then((value) => {
                                    switch (value) {

                                        case "continuar":
                                            store.dispatch({
                                                type: "ACTUALIZACION_DATOS",
                                                actualizarvista: true
                                            })
                                            store.dispatch({
                                                type: "CREACION_GEOGRAFIA",
                                                creacionGeografia: false,
                                            })
                                            break;
                                        default:

                                    }
                                });
                            } else {
                                swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                            }
                        })
                        .catch(function (error) {
                            // handle error
                            console.error(error);
                        })
                }
                break;

        }
    }

    handleChangeT = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
        });
    };

    titulo = () => {
        var tipo = this.props.tipoGeografia
        switch (tipo) {
            case 1:
                return (
                    'Creacion de pais'
                )
                break;
            case 2:
                return (
                    'Creacion de departamento'
                )
                break;
            case 3:
                return (
                    'Creacion de ciudad'
                )
                break;
        }
    }

    tipoGeografia = () => {
        var tipo = this.props.tipoGeografia
        switch (tipo) {
            case 1:
                return (
                    <div className="col col-md-12">
                        <div className="row">
                            <div className="col-md-8">
                                <label htmlFor="numordencompra" className="col-form-label">Nombre del pais</label>
                                <input id="numordencompra" className="form-control" type="text" value={this.state.descripcion} onChange={this.handleChangeT('descripcion')} />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">Nomenclatura</label>
                                <input id="numordencompra" className="form-control" type="text" value={this.state.nomenclaturaN} onChange={this.handleChangeT('nomenclaturaN')} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">Moneda</label>
                                <input id="numordencompra" className="form-control" type="text" value={this.state.monedan} onChange={this.handleChangeT('monedan')} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="numordencompra" className="col-form-label">Indicador</label>
                                <input id="numordencompra" className="form-control" type="text" value={this.state.indicadorn} onChange={this.handleChangeT('indicadorn')} />
                            </div>
                            <div className="col-md-3 bottom-aligned">
                                <button className="btn btn-danger btn-lg btn-block" onClick={() => this.cancelar()}>
                                    Cancelar
                                                            </button>
                            </div>
                            <div className="col-md-3 bottom-aligned">
                                <button className="btn btn-success btn-lg btn-block" disabled={this.state.response} onClick={() => this.Guardar()}>
                                    Guardar
                                                            </button>
                            </div>
                        </div>
                    </div>
                )
                break;
            case 2:
                return (
                    <div className="col col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">Pais</label>
                                <Selects
                                    value={this.state.paisSeleccionado}
                                    onChange={this.handleChanges('paisSeleccionado')}
                                    options={this.state.paisesbd}
                                />
                            </div>
                            <div className="col-md-8">
                                <label htmlFor="numordencompra" className="col-form-label">Nombre del departamento</label>
                                <input id="numordencompra" className="form-control" type="text" value={this.state.descripcion} onChange={this.handleChangeT('descripcion')} />
                            </div>

                        </div>
                        <div className="row mx-auto" style={{ marginTop: '20px' }}>
                            <div className="col-md-5 bottom-aligned mx-auto">
                                <button className="btn btn-danger btn-lg btn-block" onClick={() => this.cancelar()}>
                                    Cancelar
                                                            </button>
                            </div>
                            <div className="col-md-5 bottom-aligned  mx-auto">
                                <button className="btn btn-success btn-lg btn-block" disabled={this.state.response} onClick={() => this.Guardar()}>
                                    Guardar
                                                            </button>
                            </div>
                        </div>
                    </div>
                )
                break;
            case 3:
                return (
                    <div className="col col-md-12">
                        <div className="row" style={{ zIndex: 100 }}>
                            <div className="col-md-6">
                                <label htmlFor="numordencompra" className="col-form-label">Pais</label>
                                <Selects
                                    value={this.state.paisSeleccionado}
                                    onChange={this.handleChanges('paisSeleccionado')}
                                    options={this.state.paisesbd}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="numordencompra" className="col-form-label">departamento</label>
                                <Selects
                                    isDisabled={JSON.stringify(this.state.paisSeleccionado) == '{}'}
                                    value={this.state.departamentoSeleccionado}
                                    onChange={this.handleChanges('departamentoSeleccionado')}
                                    options={this.state.departamentosbd}
                                />
                            </div>

                        </div>
                        <div className="row mx-auto" style={{ marginTop: '20px' }}>
                            <div className="col-md-4">
                                <label htmlFor="numordencompra" className="col-form-label">Nombre de la ciudad</label>
                                <input id="numordencompra" className="form-control" type="text" value={this.state.descripcion} onChange={this.handleChangeT('descripcion')} />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="numordencompra" className="col-form-label">Indicador</label>
                                <input id="numordencompra" className="form-control" type="text" value={this.state.indicadorn} onChange={this.handleChangeT('indicadorn')} />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="numordencompra" className="col-form-label">Zona horaria</label>
                                <input id="numordencompra" className="form-control" disabled={true} type="text" value={this.state.zhorariaN} onChange={this.handleChangeT('zhorariaN')} />
                            </div>
                            <div className="col-md-2 bottom-aligned mx-auto">
                                <button className="btn btn-danger btn-lg btn-block" onClick={() => this.cancelar()}>
                                    Cancelar
                                                            </button>
                            </div>
                            <div className="col-md-2 bottom-aligned  mx-auto">
                                <button className="btn btn-success btn-lg btn-block" disabled={this.state.response} onClick={() => this.Guardar()}>
                                    Guardar
                                                            </button>
                            </div>
                        </div>
                    </div>
                )
                break;
        }
    }


    componentWillMount() {
        var tipo = this.props.tipoGeografia
        if (tipo == 1) {
            Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/paises/', { headers: { Authorization: ntoken } })
                .then(responsee => {
                    var paises = []
                    console.log(responsee.data)
                    responsee.data.map((provincia, key) => (
                        paises.push(provincia.descripcion)
                    ))
                    this.setState({ paisValidar: paises })
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                })
        } else if (tipo == 2) {
            Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/paises/', { headers: { Authorization: ntoken } })
                .then(responsee => {
                    var provincias = []
                    console.log(responsee.data)
                    responsee.data.map((provincia, key) => (
                        provincias.push({
                            value: provincia.id,
                            label: provincia.descripcion,
                            key: key
                        })
                    ))
                    //console.log(provincias)
                    this.setState({ paisesbd: provincias })
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                })
        } else {
            Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/ciudades/', { headers: { Authorization: ntoken } })
                .then(responsee => {
                    var prov = []
                    console.log(responsee.data)
                    responsee.data.map((provincia, key) => (
                        prov.push(provincia.descripcion)
                    ))
                    this.setState({ provinciaValidar: prov })
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                })
            Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/paises/', { headers: { Authorization: ntoken } })
                .then(responsee => {
                    var provincias = []
                    console.log(responsee.data)
                    responsee.data.map((provincia, key) => (
                        provincias.push({
                            value: provincia.id,
                            label: provincia.descripcion,
                            key: key
                        })
                    ))
                    //console.log(provincias)
                    this.setState({ paisesbd: provincias })
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                })
        }
    }



    guardarGeografia = () => {
        var usuario = store.getState().usuario
        var dominio = store.getState().dominio
        var datos = {
            "grupo": this.state.ncategoria,
            "grupos_tipos_id": 1,
            "empresas_id": usuario.empresas_id,
            "grupos_id": 1,
            "url_foto": this.state.imagePreviewUrl != null ? this.state.imagePreviewUrl : null,
            "dominios_id": dominio
        }
        //console.log(datos)
        Axios.post(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/grupos/', datos, { headers: { Authorization: ntoken } })
            .then((res) => {
                //console.log(res)
                if (res.status == 200) {
                    swal({
                        title: "Categoria creada correctamente",
                        icon: "success",
                        closeOnClickOutside: false,
                        buttons: {
                            Seguir: {
                                text: "OK",
                                value: "continuar"
                            }
                        },
                    }).then((value) => {
                        switch (value) {

                            case "continuar":
                                store.dispatch({
                                    type: "ACTUALIZACION_DATOS",
                                    actualizarvista: true
                                })
                                store.dispatch({
                                    type: "CREACION_CATEGORIA",
                                    creacionCategoria: false,
                                })
                                break;
                            default:

                        }
                    });
                } else {
                    swal("Fallo al guardar", "Por favor verifique los datos", "warning");
                }
            })
    }

    handleChanges = name => value => {
        this.setState({
            [name]: value,
        });
        switch (name) {
            case 'paisSeleccionado':
                Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/provincias/pais/' + value.value, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        var provincias = []
                        var nm = []
                        console.log(responsee.data)
                        responsee.data.map((provincia, key) => {
                            provincias.push({
                                value: provincia.provincia_id,
                                label: provincia.provincia,
                                key: key
                            })
                            nm.push(provincia.provincia)
                        })
                        //console.log(provincias)
                        this.setState({ departamentosbd: provincias, provinciaValidar: nm })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })

                break
            case "departamentoSeleccionado":
                var ciudades = []
                Axios.get(Server.hostPruebasinssl + Server.puertParametrizacion + '/api/provincias/ciudades/' + value.value, { headers: { Authorization: ntoken } })
                    .then(responsee => {
                        console.log(responsee.data)
                        responsee.data.map((dato, key) => {
                            ciudades.push(dato.ciudades)
                        })
                        this.setState({ ciudadValidar: ciudades })
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    })
                break
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };


    render() {
        return (
            <Card className="my-auto" style={{ width: '100%', height: '50vh', overflow: 'auto' }} >
                <CardTittle className="my-auto" title={this.titulo()} style={{ marginTop: "20px", paddingBottom: "5px" }} />
                <CardContent>
                    <div className="row container">
                        {this.tipoGeografia()}
                    </div>
                </CardContent>
            </Card>

        )
    }
}


export default FormularioCategoria
